{
    "department": "[[Department]]",
    "idleTimeout": {
        "extendSession": "Rester connecté",
        "signOut": "Déconnexion",
        "title": "Votre session est sur le point d’expirer en raison d’une période d’inactivité."
    },
    "overview": {
        "overview": "Aperçu",
        "noDataMessage": "Les [[scope]] et [[team]] sélectionnés ne contiennent pas de données."
    },
    "hierarchy": "Hiérarchie",
    "administration": "Administration",
    "complianceCalendar": {
        "complianceCalendar": "Calendrier de conformité",
        "previousWeek": "Semaine précédente",
        "nextWeek": "Semaine suivante",
        "today": "Aujourd’hui",
        "week": "Semaine",
        "month": "Mois",
        "day": "Jour",
        "next": "Suivant",
        "previous": "Précédent",
        "allDayEvents": "Événements toute la journée",
        "noAllDayEvent": "Pas d’événements",
        "assignments": "Affectations",
        "all": "Tout",
        "mine": "À moi"
    },
    "scopes": "[[Scopes]]",
    "teams": "[[Teams]]",
    "teamMembers": "[[Team Members]]",
    "items": "[[Items]]",
    "issues": "[[Issues]]",
    "externalResources": "[[External Resources]]",
    "assets": "[[Assets]]",
    "pendingApprovals": "Autorisations en attente",
    "plans": {
        "plans": "[[Plans]]",
        "download": "Télécharger",
        "generate": "Génération",
        "name": "Nom",
        "numberOfItems": "Nombre de [[Items]]",
        "lastStructureUpdate": "Dernière mise à jour de la structure",
        "lastGeneration": "Dernière génération",
        "daysSinceGeneration": "Jours depuis la génération",
        "lblDateTimeFromTo": "Exécuter le rapport pour dernier"
    },
    "support": {
        "description": "<p>Contactez l'assistance clientèle</p><p>Dites-nous comment nous pouvons aider.</p>",
        "email": "ENVOYEZ-NOUS UN EMAIL @",
        "phone": "APPELEZ-NOUS @",
        "phoneOption": "OPTION 1"
    },
    "reports": {
        "reports": "[[Reports]]",
        "required": "{{ fieldName }} est obligatoire",
        "all": "Tout",
        "select": "Sélectionner",
        "portal": "Portail",
        "dashboard": {
            "dashboard": "Tableau de bord",
            "admin": "Administrateur",
            "messaging": "Messagerie",
            "training": "Formation",
            "lblUserByStatus": "Utilisateur par statut",
            "lblUserLoginActivity": "Activité de connexion des utilisateurs",
            "lblMessagesSent": "Messages envoyés",
            "lblRecentMessages": "Messages récents",
            "lblCourseCompletion": "Achèvement des cours",
            "lblMostCompletedCourses": "Cours les plus achevés",
            "lblTotal": "Total",
            "lblLast30Days": "(30 derniers jours)",
            "lblLast3Months": "(3 derniers mois)",
            "lblLast3Messages": "(3 derniers messages)",
            "lblLast6Months": "(6 derniers mois)",
            "lblTop5courses": "(5 premiers cours)",
            "lblTotalResponses": "Total des réponses",
            "lblTotalAudience": "Audience totale"
        },
        "errors": {
            "renderingReport": "Erreur de rendu du [[report]]",
            "loadingReport": "Erreur lors du chargement des paramètres du [[report]]"
        },
        "labels": {
            "Role": "Rôle",
            "Department": "[[Department]]",
            "Primary Location": "Emplacement principal",
            "Location": "Emplacement",
            "Groups": "Groupes",
            "Group": "Groupe",
            "Status": "Statut",
            "Select Format": "Sélectionner le format",
            "Response Required": "Réponse requise",
            "Category": "Catégorie",
            "Course": "Cours",
            "Include": "Comprendre",
            "Completed Date": "Date de fin",
            "Assignee": "Personne affectée",
            "Compliance Category": "Catégorie de conformité",
            "External Resource Type": "Type de [[External Resource]]",
            "External Resource": "[[External Resource]]",
            "Asset": "[[Asset]]",
            "Item": "[[Item]]",
            "Plan": "[[Plan]]",
            "Assignment Type": "Type d’affectation",
            "Team": "[[Team]]",
            "Scope": "[[Scope]]",
            "Include Sub Teams": "Inclure sous [[Teams]]",
            "Include Resolved Issues": "Inclure résolu [[Issues]]",
            "Include All Expired": "Inclure tous les expirés",
            "Include Archived": "Inclure les archives",
            "Incident Types": "Types d’incidents",
            "bcFunctionalArea": "[[Department]]",
            "bcProcess": "[[Processes]]",
            "bcDueDateFrom": "Date d’échéance du",
            "bcDueDateTo": "Date d’échéance au",
            "datetime_to": "À ce jour",
            "datetime_from": "À partir de la date",
            "To Date": "À ce jour",
            "From Date": "À partir de la date",
            "to Date": "À ce jour",
            "from Date": "À partir de la date",
            "Since Date": "Depuis la date",
            "User": "Utilisateur",
            "only display if days past due greater than (zero displays all)": "ne s’affiche que si le nombre de jours de retard est supérieur à (zéro affiche tout)",
            "Only display if days past due greater than (zero displays all)": "Ne s’affiche que si le nombre de jours de retard est supérieur à (zéro affiche tout)",
            "Only display if days pasrt due greater than (zero displays all)": "Ne s’affiche que si le nombre de jours de retard est supérieur à (zéro affiche tout)",
            "Sent From Date": "Date d’envoi",
            "Sent To Date": "Date de l’envoi",
            "bcAssignee": "Personne affectée",
            "bcProgressStatus": "Statut de la progression",
            "bcStrategyType": "Types de stratégie",
            "bcImpactTimespanType": "OTR",
            "Admin": "Administrateur",
            "Deleted Users": "Utilisateurs supprimés",
            "Registration Status": "Statut d'enregistrement",
            "User Login Activity": "Activité de connexion des utilisateurs",
            "User Update History": "Historique des mises à jour des utilisateurs",
            "Users": "Utilisateurs",
            "Incidents": "Incidents",
            "Message User Latest Responses": "Dernières réponses de l'utilisateur du message",
            "Message User Responses By Contact Method": "Message des réponses des utilisateurs par méthode de contact",
            "Training": "Entraînement",
            "Certification Status": "Statut de certification",
            "Course Completion": "Achèvement du cours",
            "Course Status by User": "Statut du cours par utilisateur",
            "Learner Report": "[[Report]] de l'apprenant",
            "External Resources By Type": "Ressources externes par type",
            "External Resources List": "Liste des ressources externes",
            "External Resource ": "Ressource Externe",
            "IQ Resource Changes": "Modifications des ressources IQ",
            "iQ Asset Changes": "Modifications des actifs iQ",
            "iQ External Resource Changes": "Modifications des ressources externes iQ",
            "iQ Item Changes": "Modifications des éléments iQ",
            "iQ Plan Changes": "Modifications du plan iQ",
            "Issues": "[[Issues]]",
            "Issues by Compliance": "[[Issues]] liés à la conformité",
            "Issues by Priority": "[[Issues]] par priorité",
            "Issues by Team": "[[Issues]] par équipe",
            "Items": "Items",
            "Assignment Count By Team Summary": "Nombre d'affectations par résumé de l'équipe",
            "Assignments by Compliance Category Detail": "Affectations par détail de catégorie de conformité",
            "Assignments By Physical Location": "Attributions par emplacement physique",
            "Assignments by Team Detail": "Affectations par détail d'équipe",
            "Assignments by Team Summary": "Récapitulatif des affectations par équipe",
            "Assignments By Type": "Attributions par type",
            "Assignments Emergency Distributions": "Affectations Distributions d'urgence",
            "Igor Demo": "Igor Demo",
            "Management": "La gestion",
            "Assignments Readiness Chart and Summary": "Tableau de préparation et résumé des affectations",
            "Team Member Readiness Chart": "Tableau de préparation des membres de l'équipe",
            "Verification History": "Historique des vérifications",
            "Plans": "Des [[plans]]",
            "Plan Content Cross Reference": "Référence croisée du contenu du plan",
            "Plan Recovery Structure By Team": "Planifier la structure de récupération par équipe",
            "Plan Summary By Team": "Résumé du plan par équipe",
            "Processes Status": "Statut des [[processes]]",
            "Teams": "Les équipes",
            "Section Mission Statements": "Déclarations de mission de la section",
            "Team Mission Statements": "Énoncés de mission d'équipe",
            "Team Objectives Summary": "Résumé des objectifs de l'équipe",
            "Team Summary Report": "[[Report]] de synthèse de l'équipe",
            "User Assignments List": "Liste des attributions d'utilisateurs",
            "User Assignments List By Team": "Liste des attributions d'utilisateurs par équipe",
            "User Assignments Summary": "Résumé des attributions d'utilisateurs",
            "User Detail by Name": "Détails de l'utilisateur par nom",
            "User Detail by Team": "Détails de l'utilisateur par équipe",
            "User Notifications Detail": "Détails des notifications utilisateur",
            "User Notifications Summary": "Résumé des notifications utilisateur",
            "Users Not Assigned to Teams": "Utilisateurs non attribués aux équipes",
            "Business Continuity Planner Reports": "[[Reports]] du [[Planner]]",
            "Assignment Report": "[[Report]] d'affectation",
            "Assignments by Person Report": "[[Report]] sur les affectations par personne",
            "BIA Severity Levels": "Niveaux de gravité [[BIA]]",
            "Department Status": "Statut du [[department]]",
            "Equipment Report": "[[Report]] d'[[equipment]]",
            "Strategy And RTO Employee Report": "[[Report]] sur la stratégie et les employés RTO",
            "Strategy And RTO Report": "[[Report]] sur la stratégie et le RTO",
            "Strategy By RTO Report": "[[Report]] Stratégie par RTO",
            "Technology Report": "[[Report]] [[technology]]",
            "Technology and [[Application]] Report by RTO": "[[Report]] sur la [[technology]] et les [[Applications]] par RTO",
            "Vendor Report": "[[Report]] du fournisseur",
            "generic": "Générique",
            "banking": "Bancaire",
            "legal": "Juridique",
            "government": "Gouvernemental",
            "Missed RTOs": "RTO manqués",
            "Progress over time": "Progression au fil du temps",
            "Progress over time by RTO": "Progrès au fil du temps par RTO",
            "Incident Communication": "Communication en cas d’incident",
            "Audit": "Audit",
            "Data Accessed": "Données consultées",
            "Unauthorized Access": "Échec de la connexion",
            "Application Technology Dependency Report": "[[Application]] [[Technology]] Dépendance [[Report]]",
            "Gap Report": "[[Report]] sur les écarts",
            "Initiative Report": "Initiatives [[Report]]",
            "Threats Report": "Menaces [[Report]]",
            "Incident Manager": "[[Incident Manager]]",
            "options": "Possibilités",
            "bcReportingTimeInterval": "Intervalle de temps du rapport BC",
            "Assessment Report": "[[Report]] évaluation",
            "Assessment Report By Status": "[[Report]] d'évaluation par état",
            "select_assessmentTargetType": "Type de cible",
            "select_threatType_Id": "Type de menace",
            "search_threatName_toId": "Nom de la menace",
            "Assessment Status": "Statut d'évaluation",
            "Resource Type": "Type de ressource",
            "incidentName": "Nom de l'incident",
            "escalated": "Mise à l'échelle",
            "owner": "Propriétaire",
            "sort_by": "Trier par",
            "Target Type": "Type de cible",
            "Target Name": "Nom de la cible",
            "select_riskInitiativeStatus": "État",
            "search_riskInitiativeName_toId": "nom",
            "Department Schedule": "Horaires de [[department]]",
            "Issue Action Items": "[[issue]] Éléments d'action",
            "technology": "[[Technology]]",
            "Technologies Tiers": "[[Technologies]] Niveaux",
            "Technology to Technology Tier Gap Report": "[[Technologie]] à [[Technologie]] Écart de niveau",
            "Technology Vendor Dependency Report": "[[Technologie]] Dépendance vis-à-vis du fournisseur"
        },
        "validValues": {
            "Waiting For Approval": "En attente d'approbation",
            "Closed": "Fermé",
            "In Progress": "En cours",
            "Deleted": "Supprimé",
            "Archived": "Archivé",
            "Open": "Ouvrir",
            "All": "Tous",
            "User": "Utilisateur",
            "User Email": "E-mail de l'utilisateur",
            "User Address": "Adresse de l'utilisateur",
            "User Phone": "Téléphone utilisateur",
            "User Profile": "Profil de l'utilisateur",
            "User Role": "Rôle d'utilisateur",
            "Location": "Emplacement",
            "Location Address": "Adresse de l'emplacement",
            "Location Phone": "Localisation Téléphone",
            "Department": "[[Department]]",
            "Group": "Groupe",
            "Role": "Rôle",
            "Active": "Actif",
            "Pending": "En attente",
            "Locked": "Fermé à clé",
            "Inactive": "Inactif",
            "Resolved": "Résolu",
            "Completed": "Complété",
            "Complete": "Complet",
            "Today": "Aujourd'hui",
            "Last 7 Days": "Les 7 derniers jours",
            "Last 30 Days": "Les 30 derniers jours",
            "Last 60 Days": "60 derniers jours",
            "Last 90 Days": "90 derniers jours",
            "Last 365 Days": "365 derniers jours",
            "Over 365 Days": "Plus de 365 jours",
            "Not Taken": "Pas pris",
            "Incomplete": "Incomplet",
            "PDF": "PDF",
            "CSV (comma delimited)": "CSV (délimité par des virgules)",
            "Yes": "Oui",
            "No": "Non",
            "Title": "Titre",
            "Status": "Statut",
            "Owner": "Propriétaire",
            "Departments": "[[Department]]",
            "Processes": "[[Processes]]",
            "Vendors": "Fournisseurs",
            "Vendor Addresses": "Adresses des Fournisseurs",
            "Vendor Contacts": "Contacts des Fournisseurs",
            "Technology": "[[Technology]]",
            "Equipment": "[[Equipment]]"
        },
        "contentCategories": {
            "Terrorism": "Terrorisme",
            "Health": "Santé",
            "Other": "Autre",
            "Cyber Threats": "Cybermenaces",
            "Natural Disasters": "Catastrophes naturelles",
            "Test Category": "Catégorie de test",
            "Additional Resources Parent Category": "Ressources supplémentaires Catégorie Parent",
            "First Aid": "PREMIERS SECOURS",
            "Workplace Threats": "Menaces sur le lieu de travail"
        },
        "courses": {
            "Earthquake": "Tremblement de terre",
            "Medical Emergency: Non-Traumatic ": "Urgence médicale : non traumatique ",
            "Chemical Spill ": "Déversement de produits chimiques ",
            "Explosions ": "Explosion ",
            "Guess WHo": "Devine qui",
            "Blackout ": "coupure électrique ",
            "Tornado ": "Tornade ",
            "Medical Emergency: Traumatic ": "Urgence médicale : Traumatique ",
            "Workplace Violence ": "La violence au travail ",
            "Fire Evacuation": "Évacuation en cas d'incendie",
            "Information Security": "Sécurité des informations",
            "Anthrax": "Anthrax",
            "Tornado": "Tornade",
            "Large Event ": "Grand événement ",
            "Fire Evacuation ": "Évacuation en cas d'incendie ",
            "Dirty Bomb ": "Bombe sale ",
            "Explosions": "Explosion",
            "Tenant Warden": "Gardien des locataires",
            "Certification 1: Introduction ": "Certification 1 : Introduction ",
            "Dirty Bomb": "Bombe sale",
            "Pandemic Flu ": "Pandémie de grippe ",
            "Office Creeper ": "Bureau rampant ",
            "Anthrax ": "Anthrax ",
            "Active Shooter 2013": "Tireur actif 2013",
            "Winter Storm": "Tempête hivernale",
            "Hurricanes ": "Ouragans ",
            "Office Creeper": "Bureau rampant",
            "Bomb Threat": "Alerte à la bombe",
            "Certification 3: Taking Action ": "Certification 3 : Passer à l’action ",
            "Certification 2: Your Preparedness Program ": "Certification 2 : Votre programme de préparation ",
            "Hurricanes": "Ouragans",
            "Blackout": "coupure électrique",
            "Active Shooter": "Tireur actif",
            "Flood ": "Inondation ",
            "Winter Storm ": "Tempête hivernale ",
            "Mars Module 2.1": "Module Mars 2.1",
            "Bomb Threat ": "Alerte à la bombe ",
            "Mars Module 1.1": "Module Mars 1.1",
            "Certification": "Attestation",
            "Earthquake ": "Tremblement de terre ",
            "Guess Who": "Devine qui"
        },
        "incidentTypes": {
            "Not Set": "Pas encore défini",
            "Exercise": "Exercice",
            "Panic": "Panique"
        }
    },
    "storageLocation": "Emplacement de stockage",
    "export": "Exportation",
    "bulkResend": "Renvoi groupé",
    "established": "",
    "notestablished": "Non-établi",
    "daysPastDue": "Jours de retard",
    "expired": "Expiré",
    "scope": {
        "scopeName": "Nom [[Scope]]",
        "coordinatorName": "Nom du coordinateur",
        "mission": "Mission",
        "assumptions": "Acceptations",
        "planHeader": "En-tête [[Plan]]",
        "confirmSaveHeader": "Sauvegarder",
        "confirmSaveBody": "Êtes-vous sûr(e) de vouloir sauvegarder ce [[scope]]?",
        "confirmRemoveHeader": "Éliminer [[Scope]]",
        "confirmRemoveBody": "Êtes-vous sûr(e) de vouloir éliminer ce [[scope]]?",
        "scopesFilterDesc": "Affiner les résultats par : Nom [[Scope]], en-tête ou nom du coordinateur [[Plan]]",
        "newScope": "Nouveau [[Scope]]",
        "viewTeams": "Affichage [[Teams]]",
        "actions": "Actions",
        "cannotRemoveError": "Impossible d’éliminer [[scope]] avec le/la [[teams]] actif/active"
    },
    "teamMember": {
        "teamMemberFilterDesc": "Affiner les résultats",
        "accountableForTeam": "Cette personne est responsable des [[team]].",
        "cannotBeAccountableForTeam": "Cette personne n’a accès qu’aux affectations et ne peut pas être responsable des [[team]]",
        "cannotBeApproverForTeam": "Cette personne n’a accès qu’aux affectations et ne peut pas approuver les [[team]]",
        "confirmMakeAccountableHeader": "Responsabilité [[Team]]",
        "confirmMakeAccountable": "{{oldname}} est actuellement responsable de ce [[team]]. Êtes-vous sûr(e) de vouloir attribuer à {{newname}} la responsabilité de ce [[team]]?",
        "confirmMakeAccountableButton": "OK",
        "complianceSchedule": "Échéancier de conformité",
        "teamMember": "[[Team Member]]",
        "assignment": "Affectation",
        "isAccountable": "Responsable de [[Team]]",
        "comments": "Commentaires",
        "confirmSaveHeader": "Sauvegarder [[Team Member]]",
        "confirmRemoveHeader": "Éliminer [[Team Member]]",
        "assignmentsTitle": "Affectations des membres",
        "recoveryPlanCount": "Copies du [[Plans]] délivré à ce membre",
        "recoveryPlanIssueDate": "Date d’émission [[Plan]]",
        "issuedRecoveryPlanDateRequired": "La date d’émission [[Plan]] est requise lorsque l’utilisateur a reçu au moins 1 copie du [[plan]].",
        "issuedTeamPlanDateRequired": "La date d’émission [[Team]] [[Plan]] est requise lorsque l’utilisateur a reçu au moins 1 copie du [[plan]].",
        "teamPlanCount": "Copies du [[Team]] [[Plans]] délivré à ce membre",
        "teamPlanIssueDate": "Date d’émission [[Team]] [[Plan]]",
        "plansIssuedTitle": "Émis [[Plans]]",
        "teamMemberDoesNotExist": "[[Team Member]] n’existe pas.",
        "member": "Membre",
        "verify": "Vérifier",
        "establish": "Établir",
        "verificationSuccess": "Vérification de [[Team Member]] réussie.",
        "unableToVerify": "Impossible de vérifier [[Team Member]], veuillez réessayer plus tard.",
        "name": "Nom",
        "type": "Type",
        "jobTitle": "Intitulé de poste",
        "mailCode": "Code de courrier",
        "verifiedDate": "Date de vérification",
        "verifiedStatus": "Statut",
        "teamMembers": "[[Team Members]]",
        "newTeamMember": "Nouveau [[Team Member]]",
        "removeError": "Échec de l’élimination de [[team member]]",
        "removeAccountableTeamMemberError": "Impossible d’éliminer [[team member]] qui est responsable des [[team]]",
        "removeTeamMemberWithAssignmentError": "Impossible d’éliminer [[team member]] avec des missions",
        "members": "Membres",
        "lastVerified": "Dernière vérification",
        "nextVerification": "Prochaine vérification",
        "daysPastDue": "Jours de retard",
        "dueDate": "Date d’échéance",
        "isApprover": "Approbateur de [[Team]]",
        "reassignTasks": {
            "reassignTasks": "Réaffecter des tâches",
            "modalHeader": "Réaffecter les tâches des membres de l’équipe",
            "reassign": "Réaffecter",
            "assignToTeamMember": "Attribuer à un membre de l’équipe",
            "applyToAllTeams": "Appliquer à toutes les équipes",
            "successReassigningTasksMsg": "Réussite de la réaffectation des tâches de {{ count }} d’un membre de l’équipe",
            "errorReassigningTasksMsg": "Erreur lors de la réaffectation des tâches d’un membre de l’équipe",
            "errorReassigningTasksToFromUnkownUserMsg": "Erreur lors de la réaffectation des tâches d’un membre de l’équipe à ou depuis un utilisateur inconnu",
            "errorReassigningTasksToSameUnderlyingUserMsg": "Erreur lors de la réaffectation des tâches des membres de l’équipe au même utilisateur",
            "applyToAllTeamsTooltip": "Lorsqu’elles sont appliquées à toutes les équipes, toutes les tâches iQ appartenant à ce membre de l’équipe, dans toutes les équipes, sont attribuées à l’utilisateur désigné. Si l’utilisateur désigné n’est pas déjà membre des autres équipes, il est ajouté en tant que membre à ces équipes."
        },
        "export": {
            "allTeamMembers": "Tous les membres de l’équipe",
            "displayedTeamMembers": "Membres de l’équipe affichés",
            "header": "Exporter les membres de l’équipe",
            "body": "Voulez-vous exporter uniquement les {{ x }} utilisateurs affichés ou tous les membres de l’équipe?",
            "successMsg": "Exportation générée avec succès",
            "errorMsg": "Erreur lors de la génération de l’exportation",
            "fileName": "BulkTeamMemberUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Téléversement groupé des membres de l’équipe",
            "msgBulkUploadInstructions": "Vous souhaitez ajouter ou modifier plusieurs membres de l’équipe? Choisir un fichier correctement formaté sur votre ordinateur pour démarrer le [[processes]].",
            "msgDropFile": "Déposer le fichier ici ou cliquer pour sélectionner un fichier",
            "lblSelectedFile": "Fichier sélectionné pour le téléversement",
            "lblNoFileSelected": "Aucun fichier précisé.",
            "lblNeedStartingPoint": "Point de départ nécessaire",
            "lblAddMultipleHeader": "Ajout de plusieurs nouveaux membres de l’équipe",
            "lblAddMultipleDetail": "Télécharger cette feuille de calcul contenant uniquement les informations d’en-tête et remplir les informations des nouveaux membre de votre équipe, un utilisateur par ligne, avant de la sauvegarder au format .CSV sur votre ordinateur.",
            "btnBlankFile": "Fichier de membre d’équipe vierge",
            "lblEditMultipleHeader": "Modifier ou supprimer plusieurs membres de l’équipe",
            "lblEditMultipleDetail": "Télécharger cette feuille de calcul contenant les informations sur les membres actuels de votre équipe et la modifier si nécessaire avant de la sauvegarder au format .CSV sur votre ordinateur.",
            "btnCurrent": "Membres actuels de l’équipe",
            "lblEditMultipleNotes": "REMARQUE: Pour supprimer des utilisateurs, changez leur statut en Supprimer. Les supprimer de la feuille de calcul ne les supprimera pas du système. Le chargement en bloc peut être effectué même si vous ne remplissez pas tous les champs d’un utilisateur. Pour supprimer des utilisateurs, définissez leur statut sur Supprimer. Les supprimer de la feuille de calcul ne les supprimera pas du système. Le chargement en bloc peut être effectué même si vous ne remplissez pas tous les champs d’un utilisateur.",
            "lblUpload": "Envoyer",
            "btnCancel": "Annuler",
            "errorUserExportFailed": "Impossible d’exporter le fichier d’un membre de l’équipe.",
            "msgUserExportSuccessful": "L’exportation des membres de l’équipe a été réalisé avec succès.",
            "msgTestBulkUploadFile": "Ce fichier sera validé uniquement car le nom du fichier contient le mot « test »; les mises à jour ne seront pas traitées.",
            "msgUploadSuccess": "Téléversement groupé réussi.",
            "msgValidatingFile": "Vérification du fichier...",
            "errorInvalidBulkFileType": "Le fichier sélectionné a une extension de fichier incorrecte, il doit avoir une extension .CSV.",
            "errorExportFailed": "Impossible d’exporter le fichier d’un membre de l’équipe.",
            "msgExportSuccessful": "Exportation terminée avec succès."
        },
        "accountable": "Redevable"
    },
    "delete": "Supprimer",
    "save": "Sauvegarder",
    "undo": "Annuler",
    "replace": "Remplacer",
    "reset": "Réinitialiser",
    "choose": "Choisir",
    "errorMessages": {
        "saveError": "Échec de la sauvegarde {{name}}",
        "removeError": "Échec de l’élimination de {{name}}",
        "scopeNameExist": "[[Scope]] du même nom existe déjà",
        "msgValidateEmailFormat": "Veuillez utiliser un format de courriel valide",
        "msgValidateMaxLength": "{{0}} a une longueur maximale de {{1}} caractère(s)",
        "msgValidateMaxValue": "La valeur de {{0}} ne peut pas être supérieure à {{1}}",
        "msgValidateMinLength": "{{0}} a une longueur minimale requise de {{1}} caractère(s)",
        "msgValidateMinValue": "La valeur de {{0}} ne peut pas être inférieure à {{1}}",
        "msgValidateRequired": "Veuillez remplir tous les champs obligatoires",
        "msgValidateRequiredBefore": "La valeur de {{0}} ne peut pas être avant {{1}}",
        "externalResourceContactPrimary": "Au moins un (1) contact principal est requis",
        "loadingError": "Échec du chargement {{name}}",
        "notFoundError": "{{name}} Introuvable",
        "verifyError": "Échec de la vérification de {{ name }}",
        "isInvalidPhoneNumber": "Veuillez saisir un numéro de téléphone valide",
        "formIsDirty": "Veuillez d’abord sauvegarder vos modifications",
        "dateRangeMax": "La plage de dates dépasse la plage maximale",
        "fromDateIsAfterToDate": "La date de début est postérieure à la date de fin",
        "generatePlan": "Échec de la génération de [[plan]]",
        "noBusinessContinuityPlan": "Il n'est pas prévu de prévisualiser",
        "planItemsMinimumRequirement": "Au moins un (1) document ou rapport doit être dans cette structure [[Plan]]",
        "planItemsOnlyDocumentUsedAsCoverPage": "Au moins un (1) document qui n’est pas utilisé comme page de garde ou un (1) rapport doit être dans la structure [[Plan]].",
        "checkInError": "Échec de l’enregistrement {{name}}",
        "declineError": "Échec du refus {{name}}",
        "approveError": "Échec de l’approbation {{name}}",
        "itemInUsedByPlan": "Échec de l’élimination de ce [[item]]. [[Item]] est utilisé par un [[Plan]].",
        "errorMsgInvalidFirstTeamMember": "L’utilisateur ayant uniquement accès aux affectations ne peut pas être le premier [[team member]] d’un [[team]].",
        "duplicateFilename": "Nom de fichier en double",
        "archiveError": "NON archivé {{name}}",
        "unArchiveError": "NON restauré {{name}}",
        "deleteAttachmentError": "Échec de l’élimination de la pièce jointe {{ docName }}",
        "genericError": "Une erreur s’est produite",
        "errorRemovingMessageTemplate": "Erreur lors de la suppression du message sauvegardé",
        "errorUploadFileMessage": "Échec du téléversement de la pièce jointe {{ docName }}",
        "invalidDate": "Date invalide",
        "errorAssigningResourceToSelf": "Erreur lors de l’attribution à soi-même",
        "errorArchiveResource": "Erreur d’archivage",
        "msgInvalidPersonalPhoneNumber": "Format de numéro de téléphone invalide pour le téléphone personnel (recevra tous les messages texte et appels vocaux). Pour les numéros non américains, veuillez inclure le code du pays. Par exemple +44",
        "msgInvalidAdditionalPersonalPhoneNumber": "Format de numéro de téléphone invalide pour le téléphone personnel supplémentaire (recevra tous les messages texte et appels vocaux). Pour les numéros non américains, veuillez inclure le code du pays. Par exemple +44",
        "msgInvalidMobilePhoneNumber": "Format de numéro de téléphone invalide pour le téléphone mobile (recevra tous les messages texte et appels vocaux). Pour les numéros non américains, veuillez inclure le code du pays. Par exemple +44",
        "msgInvalidOfficePhoneNumber": "Format de numéro de téléphone invalide pour le téléphone professionnel. Pour les numéros non américains, veuillez inclure le code du pays. Par exemple +44",
        "maliciousFileType": {
            "header": "Avertissement",
            "body": "Fichier potentiellement malveillant détecté. Êtes-vous sûr(e) de vouloir téléverser ce fichier?"
        },
        "noRecordsFound": "Aucun enregistrement trouvé",
        "deleteEntity": {
            "panicTrigger": "{{entityName}} utilisé par Configuration du mode Panique",
            "uii": "{{entityName}} utilisé par Incident déclenché par l’utilisateur",
            "alertSubscription": "{{entityName}} utilisé par Abonnement aux [[alerts]]",
            "savedMessage": "{{entityName}} utilisé par Message sauvegardé",
            "documentPermission": "{{entityName}} utilisé par Autorisation de document",
            "bulkUploadDefault": "{{entityName}} est la valeur par défaut de Téléversement groupé",
            "incidentCommunicationGroup": "{{entityName}} est utilisé dans la configuration de la communication d'incident"
        },
        "noElementsFound": "Aucun élément trouvé",
        "errorUpdatingPageStatus": "Erreur lors de la mise à jour de l'état de la page"
    },
    "successMessages": {
        "saveSuccess": "{{name}} sauvegardé",
        "removeSuccess": "{{name}} éliminé",
        "verifySuccess": "{{name}} vérifié",
        "checkInSuccess": "{{name}} enregistré",
        "declineSuccess": "{{name}} refusé",
        "approveSuccess": "{{name}} approuvé",
        "archiveSuccess": "{{name}} archivé",
        "unArchiveSuccess": "{{name}} restauré",
        "deleteAttachmentSuccess": "Pièce-jointe {{ docName }} éliminée.",
        "successUploadFileMessage": "La pièce-jointe {{ docName }} est téléversée"
    },
    "issue": {
        "issue": "[[Issue]]",
        "name": "Nom",
        "description": "Description",
        "priority": "Priorité",
        "dateOpened": "Date d’ouverture",
        "confirmRemoveHeader": "Éliminer [[Issue]]",
        "confirmResolveHeader": "Résoudre [[Issue]]",
        "confirmResolve": "Êtes-vous sûr(e) de vouloir résoudre ce [[issue]]?",
        "resolveError": "Impossible de résoudre [[issue]], veuillez réessayer plus tard.",
        "resolvedBy": "par",
        "hasBeenResolved": "Ce [[issue]] a été résolu.",
        "assignee": "Personne affectée",
        "resolutionDetails": "Détails de la résolution",
        "resolveSuccess": "[[Issue]] a été résolu.",
        "targetResolutionDate": "Date de résolution cible",
        "targetResolutionPastDue": "Retard",
        "resolutionDate": "Date de résolution",
        "comments": "Commentaires",
        "initiatedBy": "Initié par",
        "resolve": "Résoudre",
        "issuesFilterDesc": "Rechercher...",
        "newIssue": "Nouveau [[Issue]]",
        "reopen": "Rouvrir",
        "issueName": "Nom [[Issue]]",
        "assignedTo": "Affecté à",
        "opened": "Ouvert",
        "targetResolution": "Résolution cible",
        "daysPastDue": "Jours de retard",
        "status": "Statut",
        "closed": "Résolu",
        "open": "Ouvert",
        "issues": "[[Issues]]",
        "notFoundError": "[[Issue]] Introuvable",
        "pastDue": "Retard",
        "due": "Dû le",
        "showResolvedIssues": "Afficher [[Issues]] résolu",
        "hideResolvedIssues": "Masquer [[Issues]] résolu",
        "issueWithSameNameError": "Un [[issue]] avec le nom {{ issueName }} existe déjà.",
        "moveSuccess": "[[Issue]] déplacé",
        "moveError": "[[Issue]] non déplacé",
        "moveErrorDuplicateName": "[[Issue]] non déplacé car un [[issue]] du même nom existe déjà dans l’équipe de destination"
    },
    "item": {
        "item": "[[Item]]",
        "items": "[[Items]]",
        "scope": "[[Scope]]",
        "team": "[[Team]]",
        "name": "Nom",
        "description": "Description",
        "type": "Type",
        "assignedTo": "Affecté à",
        "archived": "Archivé",
        "lastVerified": "Dernière vérification",
        "nextVerification": "Prochaine vérification",
        "lastUpdated": "Dernière mise à jour",
        "lastPublished": "Dernière publication",
        "verifiedStatus": "Statut",
        "nextUpdate": "Prochaine mise à jour",
        "daysPastDue": "Jours de retard",
        "priority": "Priorité",
        "status": "Statut",
        "itemType": "Type",
        "itemPriority": "Priorité",
        "itemComplianceCat": "Catégorie de conformité",
        "complianceSchedule": "Échéancier de conformité",
        "storageInformation": "Informations de stockage",
        "associatedDocuments": "Document associé",
        "comments": "Commentaires",
        "primaryStorageLocation": "Emplacement de stockage principal",
        "secondaryStorageLocation": "Emplacement de stockage secondaire",
        "recoveryDestination": "Destination de récupération",
        "addStorageLocation": "Ajouter un emplacement de stockage",
        "editStorageLocation": "Modifier l’emplacement de stockage",
        "addComplianceCategory": "Ajouter une catégorie de conformité",
        "editComplianceCategory": "Modifier la catégorie de conformité",
        "createComplianceCategory": "Créer une nouvelle catégorie de conformité",
        "appendComplianceCategory": "Apposer une catégorie de conformité",
        "itemTypes": {
            "53e27db8-f119-4b73-9e84-a8b598fbd415": "Document",
            "92c579b0-36cf-46d2-b006-77d2f962c0ce": "[[Item]] Stockage",
            "2b727081-d9b1-4f92-b8b4-4e275fe9a396": "Tâche/Rappel",
            "08b4edfc-1f95-425e-a24b-016ba5cad509": "Autre"
        },
        "newItem": "Nouveau [[Item]]",
        "verify": "Vérifier",
        "establish": "Établir",
        "itemsfilterPlaceholder": "Affiner les résultats par : Nom, type ou nom attribué à",
        "documentAvailableForPlan": "Disponibilité de [[Plan]]",
        "no": "Non",
        "currentTeamOnly": "[[Team]] Actuel Seulement",
        "allTeamsAndScopes": "Tout [[Teams]] Et [[Scopes]]",
        "itemWithSameNameError": "Un [[item]] avec le nom {{ itemName }} existe déjà.",
        "storageWithSameNameError": "Un emplacement de stockage avec le nom {{ storageName }} existe déjà.",
        "confirmRemoveHeader": "Éliminer [[Item]]",
        "removeWithDocumentConfirm": "Éliminer ce [[item]] éliminera également le document associé à ce [[item]]. Êtes-vous sûr(e) de vouloir éliminer ce [[item]]?",
        "dueDate": "Date d’échéance",
        "itemWithoutDocumentWarning": "Ce [[item]] n’a pas de document et ne peut pas être vérifié ou enregistré",
        "thisTeamOnly": "Ce [[Team]] Seulement",
        "thisTeamAndSubTeams": "Ce [[Team]] et sous [[Teams]]",
        "allTeamsThisScope": "Tout [[Teams]] ce [[Scope]]",
        "allTeamsAllScopes": "Tout [[Teams]] Tout [[Scopes]]",
        "moveSuccess": "[[Item]] déplacé",
        "moveError": "[[Item]] non déplacé",
        "moveErrorDuplicateName": "[[Item]] non déplacé car un [[item]] du même nom existe déjà dans l’équipe de destination",
        "export": {
            "allTeamMembers": "Tous les articles",
            "displayedTeamMembers": "Articles affichés",
            "header": "Exporter des articles",
            "body": "Voulez-vous exporter uniquement les articles {{ x }} affichés ou tous les membres de l’équipe?",
            "successMsg": "Exportation générée avec succès",
            "errorMsg": "Erreur lors de la génération de l’exportation",
            "fileName": "BulkItemUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Articles de téléversement groupé",
            "msgBulkUploadInstructions": "Vous souhaitez ajouter ou modifier plusieurs articles? Choisir un fichier correctement formaté sur votre ordinateur pour démarrer le [[processes]].",
            "msgDropFile": "Déposer le fichier ici ou cliquer pour sélectionner un fichier",
            "lblSelectedFile": "Fichier sélectionné pour le téléversement",
            "lblNoFileSelected": "Aucun fichier précisé.",
            "lblNeedStartingPoint": "Point de départ nécessaire",
            "lblAddMultipleHeader": "Ajouter plusieurs nouveaux articles",
            "lblAddMultipleDetail": "Télécharger cette feuille de calcul contenant uniquement les informations d’en-tête et remplir les informations de votre nouvel article, un utilisateur par ligne, avant de la sauvegarder au format .CSV sur votre ordinateur.",
            "btnBlankFile": "Fichier d’article vide",
            "lblEditMultipleHeader": "Modifier ou supprimer plusieurs articles",
            "lblEditMultipleDetail": "Télécharger cette feuille de calcul contenant les informations actuelles de votre article et la modifier si nécessaire avant de la sauvegarder au format.CSV sur votre ordinateur.",
            "btnCurrent": "Articles actuels",
            "lblEditMultipleNotes": "REMARQUE : Pour supprimer des articles, passer leur statut en mode Supprimer. Les éliminer de la feuille de calcul ne les supprimera pas du système. Le téléversement groupé peut être effectué même si vous ne remplissez pas tous les champs d’un article. Pour voir quels champs sont facultatifs, reportez-vous à votre guide de l’utilisateur ou à la zone d’aide dans le coin supérieur droit de cette fenêtre.",
            "lblUpload": "Envoyer",
            "btnCancel": "Annuler",
            "errorUserExportFailed": "Impossible d’exporter un fichier d’article.",
            "msgUserExportSuccessful": "L’exportation de l’article a été réalisé avec succès.",
            "msgTestBulkUploadFile": "Ce fichier sera validé uniquement car le nom du fichier contient le mot « test »; les mises à jour ne seront pas traitées.",
            "msgUploadSuccess": "Téléversement groupé réussi.",
            "msgValidatingFile": "Vérification du fichier...",
            "errorInvalidBulkFileType": "Le fichier sélectionné a une extension de fichier incorrecte, il doit avoir une extension .CSV.",
            "errorExportFailed": "Impossible d’exporter un fichier d’article.",
            "msgExportSuccessful": "Exportation terminée avec succès."
        }
    },
    "itemDocument": {
        "dropFile": "Déposer le fichier ici",
        "selectFile": "Sélectionner le fichier",
        "selectFiles": "Sélectionnez les fichiers",
        "uploadingFile": "Téléversement du fichier",
        "uploadDocument": "Téléverser le document",
        "uploadDocuments": "Téléverser le documents",
        "selectDocument": "Sélectionner un document",
        "uploadFile": "Choisissez un fichier sur votre ordinateur à télécharger ou faites glisser et déposez votre fichier sur la fenêtre.",
        "confirmRemoveHeader": "Éliminer le document",
        "removeConfirm": "Êtes-vous sûr(e) de vouloir éliminer ce document?",
        "homeFolderName": "Accueil",
        "saveSuccess": "Document {{name}} sauvegardé",
        "saveFail": "Échec de la sauvegarde du document",
        "removeSuccess": "Document éliminé",
        "removeFail": "Échec de l’élimination du document",
        "downloadDocument": "Télécharger le document",
        "documentVersions": "Versions du document",
        "removeDocument": "Éliminer le document",
        "createdDate": "Date de création",
        "createdBy": "Créé par",
        "compare": "Comparer",
        "documentComparisonTypeNotSupport": "Seuls les documents Microsoft Word sont pris en charge à des fins de comparaison.",
        "documentComparisonFailed": "Échec de la comparaison des documents",
        "verified": "Vérifié",
        "compared": " (comparé)",
        "noAttachments": "Pas de Pièces Jointes"
    },
    "confirmMessages": {
        "saveConfirm": "Êtes-vous sûr(e) de vouloir sauvegarder ce {{name}}?",
        "removeConfirm": "Êtes-vous sûr(e) de vouloir éliminer ce {{name}}?"
    },
    "removeAttachmentConfirmation": {
        "header": "Éliminer la pièce jointe",
        "body": "Êtes-vous sûr(e) de vouloir éliminer la pièce jointe {{ docName }}?",
        "btnText": "Éliminer"
    },
    "team": {
        "teamName": "Nom [[Team]]",
        "responsibilities": "Responsabilités",
        "mission": "Mission",
        "assemblyTimeObjective": "Objectif de temps d’assemblage (OTA)",
        "recoveryTimeObjective": "Objectif de temps de récupération (OTR)",
        "recoveryPointObjective": "Objectif de point de récupération (OPR)",
        "confirmRemoveHeader": "Éliminer [[Team]]",
        "confirmSaveHeader": "Sauvegarder [[Team]]",
        "teamFilterDesc": "Affiner les résultats par nom [[team]]",
        "newTeam": "Nouveau [[Team]]",
        "teamCountMessage": "Affiche {{ x }} sur un total de {{ y }}",
        "confirmMoveMessage": "Êtes-vous sûr(e) de vouloir déplacer {{ childTeamName }} vers {{ toTeamName }}?",
        "editTeams": "Modifier [[Teams]]",
        "newTeamHeader": "Nouveau [[Team]]",
        "newTeamName": "Nouveau Nom [[Team]]",
        "addTeam": "Ajouter [[Team]]",
        "teamWithSameNameError": "Un [[team]] avec le nom {{ teamName }} existe déjà.",
        "cannotRemoveError": "Impossible d’éliminer [[team]] avec sous [[teams]] ou [[team members]] actif",
        "copyTeam": "Copier [[Team]]",
        "copyTeamModal": {
            "header": "Options de copie de [[Team]]",
            "selectDestination": "Sélectionner la destination [[scope]] ou [[team]]",
            "selectCopyOptions": "Options",
            "copyTeamMembers": "Copier tous les [[Team Members]]",
            "copyHierarchy": "Inclure sous-[[teams]]",
            "deepCopy": "Copie profonde",
            "copyItems": "Copier tous les [[Items]]",
            "copyIssues": "Copier tous les [[Issues]]",
            "copyAssets": "Copier tous les [[Assets]]",
            "copyExternalResources": "Copier tous les [[External Resources]]",
            "copyTeamSuccess": "[[team]] {{teamName}} copié(s) avec succès",
            "copyTeamError": "Échec de la copie de [[team]] {{teamName}}"
        },
        "comments": "Commentaires",
        "comment": {
            "saveError": "Commentaire non sauvegardé",
            "deleteError": "Commentaire non éliminé"
        }
    },
    "help": {
        "scope": {
            "scopeName": "Le nom [[scope]]est le groupe le plus élevé de [[teams]]. Il doit être unique et est imprimé dans l’en-tête de chaque rapport. Il peut contenir jusqu’à 50 caractères.",
            "mission": "La déclaration de mission explique le but du [[scope]] et est imprimée au début du rapport de déclaration de mission. Elle peut contenir jusqu’à 4 000 caractères.",
            "assumptions": "Les acceptations sont imprimées dans le rapport de déclaration de mission. Elles peuvent contenir jusqu’à 4 000 caractères.",
            "coordinatorName": "Le coordinateur est la personne responsable de la planification du rétablissement de ce [[scope]].  Le nom est imprimé sur la couverture de chaque [[plan]] de récupération."
        },
        "team": {
            "teamName": "Le nom [[team]] doit être unique dans chaque [[scope]]. Il peut contenir jusqu’à 50 caractères",
            "mission": "La déclaration de mission explique le but du [[team]]. Elle est également présentée dans le rapport de mission du [[team]]. Elle peut contenir jusqu’à 4 000 caractères.",
            "responsibilities": "Les responsabilités décrivent les principaux objectifs du [[team]]. Elles sont imprimées dans le rapport de mission du [[team]]. Elle peut contenir jusqu’à 4 000 caractères.",
            "planHeader": "L’en-tête [[plan]] est imprimé sur la page de garde du [[plan]] de récupération de [[team]]. Il peut contenir jusqu’à 50 caractères.",
            "assemblyTimeObjective": "L’OTA est le temps écoulé prévu entre une notification d’événement et l’assemblage [[team]]. L’assemblage [[Team]] est défini comme le moment où le [[team]] est en mesure de répondre à sa mission de récupération et à ses responsabilités envers ses utilisateurs internes et ses [[[[client]]s]] externes. Il est représenté en heures et en minutes.",
            "recoveryTimeObjective": "L’OTR est le temps écoulé prévu entre une notification d’événement et une opération intégrale de [[team]] à un niveau supportant ses utilisateurs internes et ses [[[[client]]s]] externes. Il est représenté en heures et en minutes.",
            "recoveryPointObjective": "L’OPR est la durée maximale, avant un événement, pendant laquelle la perte de données est acceptable. Les données et les informations essentielles doivent être restaurées au moins jusqu’à ce moment. Il est représenté en heures et en minutes. (Laisser vide si toutes les données et informations doivent être restaurées.)",
            "copyTeamModal": {
                "copyHierarchy": "Copier un seul [[team]] ou le [[team]] et tous les sous-[[teams]]",
                "deepCopy": "Copier [[Team Members]] et certains types de ressources ([[Assets]], [[Items]], [[Issues]], [[External Resources]]) pour les [[Teams]] copiés",
                "copyTeamMembers": "[[Team Members]] doit être copié pour les ressources de copiage ([[Assets]], [[Items]], [[Issues]], [[External Resources]])",
                "copyItems": "Copier tous les [[Items]] pour les [[Teams]] copiés",
                "copyIssues": "Copier tous les [[Issues]] pour les [[Teams]] copiés",
                "copyAssets": "Copier tous les [[Assets]] pour les [[Teams]] copiés",
                "copyExternalResources": "Copier tous les [[External Resources]] pour les [[Teams]] copiés. [[External Resources]] doit être copié pour copier [[Assets]] comme un[[Asset]] et s’appuyer sur [[External Resource]]."
            }
        },
        "teamMember": {
            "member": "Le membre représente le compte utilisateur appartenant à ce [[team member]].",
            "assignment": "L’affectation représente les responsabilités de [[team member]] dans ce [[team]].",
            "planCount": "Le nombre de copies du [[plan]] qui ont été délivrées à ce [[team member]].",
            "teamPlanCount": "Le nombre de copies du [[plan]] de [[team]] qui ont été délivrées à ce [[team member]].",
            "planIssueDate": "La date à laquelle les copies du [[plan]] ont été délivrées à ce [[team member]].",
            "teamPlanIssueDate": "La date à laquelle les copies du [[team]] [[plan]] ont été délivrées à ce [[team member]].",
            "isResponsible": "Cette personne est responsable des [[team]].",
            "expired": "Expiré, doit être vérifié.",
            "verified": "Vérifié"
        },
        "issue": {
            "descriptionTooltip": "La description du [[issue]] peut contenir jusqu’à 512 caractères.",
            "assigneeTooltip": "La personne affectée est responsable de veiller à ce que [[issue]] soit résolu.",
            "targetResolutionDateTooltip": "La date de résolution cible est la date à laquelle ce [[issue]] doit être résolu par.",
            "resolutionDateTooltip": "La date de résolution est la date à laquelle ce [[issue]] a été marqué comme résolu.",
            "priorityTooltip": "La priorité est le niveau auquel ce [[issue]] nuit à l’effort de récupération.",
            "nameTooltip": "Le nom identifie le [[issue]] et doit être unique par [[team]].",
            "dateOpened": "La date d’ouverture correspond au moment où ce [[issue]] a été enregistré.",
            "initiatedBy": "L’utilisateur qui a lancé le [[issue]]."
        },
        "externalResource": {
            "externalResourceName": "Nom d’une société, d’un emplacement ou d’une personne extérieure à l’organisation. Il peut comporter 50 caractères.",
            "typeTooltip": "Type de société, d’emplacement ou d’individu externe en dehors de l’organisation.",
            "isCritical": "Ce [[external resource]] est-il essentiel pour le [[processes]] de récupération?",
            "isRecoveryLocation": "Ce [[external resource]] est-il un emplacement de récupération?",
            "addExternalResourceType": "Ajouter un nouveau type de [[external resource]]",
            "editExternalResourceType": "Modifier le type de [[external resource]]",
            "compareWithVerified": "Comparer la dernière version sauvegardée avec la version vérifiée"
        },
        "item": {
            "primaryStorageLocationTooltip": "L’emplacement de stockage principal est l’emplacement prévu pour stocker la récupération [[item]].",
            "secondaryStorageLocationTooltip": "L’emplacement de stockage secondaire est un emplacement supplémentaire pour stocker la restauration [[item]].",
            "recoveryDestinationTooltip": "La destination de récupération est l’endroit où déplacer le stockage [[item]] pendant une urgence.",
            "documentAvailableForPlanTooltip": "Indique que le niveau de ce document [[item]] est disponible afin d’être utilisé dans [[plans]] pour d’autres [[teams]] et/ou [[scopes]]",
            "nameTooltip": "Le nom identifie la récupération [[item]] et doit être unique par [[team]]. Il peut contenir jusqu’à 50 caractères.",
            "descriptionTooltip": "La description de la récupération [[item]] peut contenir jusqu’à 512 caractères.",
            "itemTypeTooltip": "Type de récupération [[item]].",
            "itemPriorityTooltip": "Priorité de la récupération [[item]] en cas d’urgence.",
            "addStorageLocationInstructions": "Identifier et décrire le nouvel emplacement de stockage.",
            "editStorageLocationInstructions": "Identifier à nouveau ou décrire l’emplacement de stockage existant.",
            "addComplianceCategoryInstructions": "Identifier et décrire la nouvelle catégorie de conformité.",
            "editComplianceCategoryInstructions": "Ré-identifier ou décrire la catégorie de conformité existante.",
            "compareWithVerified": "Comparer la dernière version sauvegardée avec la version vérifiée"
        },
        "asset": {
            "nameTooltip": "Nom de [[asset]] nécessaire dans l’effort de récupération. Il peut contenir jusqu’à 50 caractères.",
            "assetLocationSiteTooltip": "Endroit où [[asset]] doit être localisé pendant l’effort de récupération.",
            "compareWithVerified": "Comparer la dernière version sauvegardée avec la version vérifiée"
        },
        "assetAllocation": {
            "allocationCountTooltip": "Nombre d’actifs requis sur le site de récupération dans un laps de temps associé.",
            "requirementTypeTooltip": "Laps de temps de collecte des actifs requis sur le site de récupération."
        },
        "establishByCard": {
            "dateCreatedTooltip": "Date à laquelle cette ressource a été définie pour la première fois.",
            "establishByDateTooltip": "Établir l’existence de cette ressource à cette date."
        },
        "planStructure": {
            "planHeader": "Les contenus de ce champ sont imprimés sur la page de garde de chaque restauration [[plan]] qui est généré pour le [[scope]]. ",
            "compareWithVerified": "Comparer la dernière version sauvegardée avec la version vérifiée",
            "planCover": "Cette zone peut être utilisée pour remplacer le texte qui apparaît sur la page de garde [[plan]] de récupération par défaut. Lors du remplacement du texte de couverture [[plan]], vous devrez peut-être insérer des retours chariot durs au début de cette zone (en appuyant sur la touche Entrée) afin de centrer le texte entre la couverture [[plan]] et les champs de date [[plan]]. Cette zone peut contenir un maximum de 4 000 caractères d’information. Si cette zone est vide, le texte de couverture [[plan]] du système par défaut sera utilisé.",
            "useFirstDocumentAsCoverPage": "Vous êtes autorisé(e) à fournir votre propre page de garde au [[plan]] qui est généré. En sélectionnant « Oui », le premier document de votre structure [[plan]] sera utilisé comme page de garde."
        },
        "notificationSetting": {
            "verificationFrequencyTooltip": "Sélectionner la fréquence de vérification à cibler avec cette notification",
            "notificationOverdueValueTooltip": "Préciser la valeur du temps après la date d’échéance pour déclencher cette notification",
            "notificationDueSoonValueTooltip": "Préciser la valeur de temps avant la date d’échéance pour déclencher cette notification",
            "iQEmailFromNameTooltip": "Préciser la valeur du nom de l’expéditeur de tous les courriels de notification",
            "datepartTypeTooltip": "Préciser l’unité de temps pour la valeur ci-dessus",
            "jobTypeTooltip": "Sélectionner le type de notification à envoyer",
            "editOverdueNotificationSetting": "Modifier le [[setting]] de notification de retard pour les activités {{ a }}",
            "addOverdueNotificationSetting": "Ajouter un [[setting]] de notification de retard pour les activités {{ a }}",
            "editDueSoonNotificationSetting": "Modifier le [[setting]] de notification d’échéance proche pour les activités {{ a }}",
            "addDueSoonNotificationSetting": "Ajouter un [[setting]] de notification d’échéance proche pour les activités {{ a }}"
        },
        "complianceCategory": {
            "nameTooltip": "Le nom identifie la catégorie de conformité et doit être unique dans tous le système. Il peut contenir jusqu’à 50 caractères.",
            "descriptionTooltip": "La description de la catégorie de conformité peut comporter jusqu’à 512 caractères.",
            "complianceCatTooltip": "Catégorie de conformité qui nécessite cette ressource de récupération. Sélectionner tout ce qui s’y rapporte."
        },
        "storageLocation": {
            "nameTooltip": "Le nom identifie l’emplacement de stockage et doit être unique dans l’ensemble du système. Il peut contenir jusqu’à 50 caractères.",
            "descriptionTooltip": "La description de l’emplacement de stockage peut comporter jusqu’à 512 caractères."
        },
        "externalResourceType": {
            "nameTooltip": "Le nom identifie le type [[external resource]] et doit être unique dans l’ensemble du système. Il peut contenir jusqu’à 50 caractères.",
            "descriptionTooltip": "La description du type [[external resource]] peut contenir jusqu’à 512 caractères."
        },
        "incident": {
            "incidentAttachmentsTooltip": "Joindre des documents à l’incident."
        }
    },
    "done": "Terminé",
    "finished": "Fini",
    "scopeTeamModal": {
        "header": "Veuillez faire votre sélection",
        "requireScopeDescription": "Sélectionner un [[Scope]] pour continuer. Cela affectera [[teams]] que vous pouvez voir. Pas d’inquiétude. Vous pouvez modifier cela plus tard.",
        "requireTeamDescription": "Sélectionner un [[Team]] pour continuer. Cela affectera [[team]] ressources que vous pouvez afficher. Pas d’inquiétude. Vous pouvez modifier cela plus tard.",
        "requireScopeIdAndTeamIdError": "Veuillez faire votre/vos sélection(s) avant de continuer."
    },
    "newTeamModal": {
        "header": "Créer un nouveau [[Team]]",
        "confirmButtonText": "Continuer"
    },
    "complianceSchedule": {
        "verify": "Vérifier",
        "lastUpdated": "Dernière vérification",
        "lastUpdatedTooltip": "La dernière vérification représente la date à laquelle la dernière vérification a eu lieu.",
        "nextUpdate": "Prochaine vérification",
        "nextUpdateTooltip": "La prochaine vérification représente la date à laquelle la prochaine vérification doit avoir lieu.",
        "teamMember": "[[Team Member]]",
        "teamMemberTooltip": "[[Team Member]] qui est principalement responsable de vérifier l’état de préparation de la ressource. [[Team Members]] dans cette liste doivent déjà être vérifiés.",
        "updateFrequency": "Fréquence de mise à jour",
        "updateFrequencyTooltip": "La fréquence de mise à jour représente l’intervalle de temps entre chaque vérification.",
        "notVerified": "Jamais été vérifié. Veuillez vérifier.",
        "updateFrequencyOptions_7": "Hebdomadaire",
        "updateFrequencyOptions_30": "Mensuel",
        "updateFrequencyOptions_31": "Mensuel le dernier jour du mois",
        "updateFrequencyOptions_90": "Trimestriel",
        "updateFrequencyOptions_91": "Trimestriel le dernier jour du mois",
        "updateFrequencyOptions_180": "Semestriel",
        "updateFrequencyOptions_181": "Semestriel le dernier jour du mois",
        "updateFrequencyOptions_365": "Annuel",
        "updateFrequencyOptions_365000": "S. O.",
        "complianceSchedule": "Échéancier de conformité",
        "updateLastVerified": "Mettre à jour la date de la dernière vérification",
        "updateLastVerifiedTooltip": "Mettre à jour la date de la dernière vérification",
        "nextVerificationDateBeforeLastVerified": "La date de la prochaine vérification ne peut pas être antérieure à la date de la dernière vérification. Ajuster la date de début de l’intervalle de vérification."
    },
    "comments": {
        "addComment": "Ajouter {{ name }}",
        "newComment": "Nouveau {{ name }}",
        "on": "sur",
        "deleteComment": "Supprimer",
        "deleteCommentHeader": "Supprimer {{ name }}",
        "deleteCommentBody": "Êtes-vous sûr(e) de vouloir supprimer ce {{ name }}?"
    },
    "select": "Sélectionner",
    "iQ": "iQ",
    "No": "Non",
    "new": "Nouveau",
    "change": "changement",
    "scopeTeamModalTooltip": "Modifier [[Scope]] et/ou [[Team]]",
    "ComplianceCategoriesSelector": {
        "complianceCategory": "Catégorie de conformité",
        "complianceCategoryTooltip": "Catégorie de conformité",
        "createComplianceCategory": "+",
        "complianceCategoryWithSameNameError": "Une catégorie de conformité avec le nom {{ name }} existe déjà."
    },
    "iqPriorities": {
        "086deec5-b113-4cc9-8024-d1f1ddb62e8d": "Essentiel",
        "0b75aa95-3100-43b0-9cf7-560fb4607a47": "Élevé",
        "31b51337-4a66-4cce-9c54-0daa8c6831c9": "Moyen",
        "b7e4e27d-b0de-4936-858a-3fa07e0f3b68": "Faible"
    },
    "view": "Affichage",
    "noComplianceCategory": "Aucune catégorie de conformité",
    "noItemsPopoverText": "Pas de [[Items]]",
    "externalResource": {
        "scopeAssignment": "Affectation [[Scope]]",
        "resourceName": "Nom de la ressource",
        "type": "Type",
        "assignedTo": "Affecté à",
        "recoveryLocationSite": "Site de récupération",
        "verifiedDate": "Date de vérification",
        "status": "Statut",
        "externalResources": "[[External Resources]]",
        "externalResourcesFilterPlaceholder": "Affiner les résultats par : Nom de la ressource ou Affecté à",
        "newExternalResource": "Nouveau [[External Resource]]",
        "externalResource": "[[External Resource]]",
        "externalResourceName": "Nom [[External Resource]]",
        "isCritical": "Ceci est une ressource de crise",
        "isRecoveryLocation": "Ceci est un site de récupération",
        "contacts": "Contacts",
        "resourceDetails": "Détails de la ressource",
        "comments": "Commentaires",
        "address": "Rue",
        "city": "Ville",
        "state": "État",
        "zip": "Code postal/Zip",
        "country": "Pays",
        "webSite": "Site Internet",
        "primary": "Principal",
        "name": "Nom",
        "phone": "Téléphone",
        "email": "Courriel",
        "addContact": "Ajouter le contact",
        "firstName": "Prénom",
        "lastName": "Nom de famille",
        "confirmRemoveHeader": "Éliminer [[External Resource]]",
        "removeConfirm": "Êtes-vous sûr(e) de vouloir éliminer ce [[external resource]]?",
        "addType": "Ajouter le Type de [[External Resource]]",
        "editType": "Modifier le Type de [[External Resource]]",
        "externalResourceTypeDescription": "Description",
        "externalResourceTypeName": "Nom",
        "verify": "Vérifier",
        "establish": "Établir",
        "contact": "Contact",
        "usedByAssetsAsLocationSite": "Ce [[external resource]] est actuellement utilisé par [[assets]] comme site d’emplacements",
        "confirmRemoveContactHeader": "Éliminer le contact",
        "confirmRemoveContactBody": "Êtes-vous sûr(e) de vouloir éliminer ce contact?",
        "primaryContact": "Contact principal",
        "update": "Mise à jour",
        "confirmNewPrimaryContactHeader": "Mise à jour du contact principal",
        "confirmNewPrimaryContactBody": "Un contact principal existe déjà. Ce contact sera le nouveau contact principal.",
        "lastVerified": "Dernière vérification",
        "nextVerification": "Prochaine vérification",
        "daysPastDue": "Jours de retard",
        "dueDate": "Date d’échéance",
        "externalResourceWithSameNameError": "Un [[external resource]] avec le nom {{ externalResourceTypeName }} existe déjà.",
        "getExternalResourceTypesError": "Erreur lors de la récupération des types de [[external resource]]",
        "filterExternalResourceTypesDescription": "Affiner les résultats par nom",
        "newExternalResourceType": "Nouveau Type de [[External Resource]]",
        "externalResourceType": "Type de [[External Resource]]",
        "externalResourceTypes": "Types de [[External Resource]]",
        "confirmRemoveExternalResourceType": {
            "header": "Éliminer le Type de [[External Resource]]",
            "body": "Êtes-vous sûr(e) de vouloir éliminer ce {{ objectType }} nommé {{ name }}?",
            "text": "Éliminer"
        },
        "confirmSaveExternalResourceType": {
            "header": "Sauvegarde le Type de [[External Resource]]",
            "body": "Êtes-vous sûr(e) de vouloir sauvegarder ce {{ objectType }} nommé {{ name }}?",
            "text": "Sauvegarder"
        },
        "externalResourceTypeAssignedToResourceError": "Impossible d’éliminer {{ name }} avec [[external resources]] attribué à ce type",
        "moveSuccess": "[[External Resource]] déplacé",
        "moveError": "[[External Resource]] non déplacé",
        "moveErrorDuplicateName": "[[External Resource]] non déplacé car un [[external resource]] du même nom existe déjà dans l’équipe de destination",
        "moveTeamTooltip": "Sélectionner l’équipe de destination. Si un [[external resource]] est défini comme un site de récupération et a un actif qui lui est attribué, il ne peut être déplacé que vers des équipes dans le même périmètre."
    },
    "asset": {
        "asset": "[[Asset]]",
        "assetName": "Nom [[Asset]]",
        "assignedTo": "Affecté à",
        "storageLocation": "Emplacement de stockage",
        "verifiedDate": "Date de vérification",
        "status": "Statut",
        "assetFilterPlaceHolder": "Affiner les résultats par : Nom [[Asset]] ou attribué à",
        "newAsset": "Nouveau [[Asset]]",
        "verify": "Vérifier",
        "establish": "Établir",
        "locationSite": "Site de récupération",
        "complianceSchedule": "Échéancier de conformité",
        "assetRequirement": "Exigence [[Asset]]",
        "allocation": {
            "assetRequirement": "Exigence [[Asset]]",
            "allocationCount": "Compte",
            "allocationType": "Type",
            "confirmRemoveHeader": "Éliminer l’affectation d’allocation",
            "confirmRemoveMessage": "Êtes-vous sûr(e) de vouloir éliminer cet exigence [[asset]] du compte « {{ x }} » et taper « {{ y }} »?",
            "add": "Ajouter une exigence"
        },
        "comments": "Commentaires",
        "comment": {
            "saveError": "Commentaire non sauvegardé",
            "deleteError": "Commentaire non éliminé"
        },
        "saveSuccess": "[[Asset]] sauvegardé",
        "saveError": "[[Asset]] non sauvé",
        "confirmRemoveHeader": "Éliminer [[Asset]]",
        "confirmRemoveMessage": "Êtes-vous sûr(e) de vouloir éliminer ce {{ name }}?",
        "lastVerified": "Dernière vérification",
        "nextVerification": "Prochaine vérification",
        "daysPastDue": "Jours de retard",
        "assetWithSameNameError": "Un [[asset]] avec le nom {{ assetName }} existe déjà.",
        "dueDate": "Date d’échéance",
        "moveSuccess": "[[Asset]] déplacé",
        "moveError": "[[Asset]] non déplacé",
        "moveErrorDuplicateName": "[[Asset]] non déplacé car un [[asset]] du même nom existe déjà dans l’équipe de destination",
        "moveRecoverySiteTooltip": "Sélectionner le site de récupération. Si un [[asset]] est déplacé vers une équipe appartenant à un périmètre différent, un nouveau site de récupération doit être sélectionné dans ce périmètre."
    },
    "userAssignment": {
        "title": "Affectations",
        "teamMembers": "[[Team Members]]",
        "items": "[[Items]]",
        "issues": "[[Issues]]",
        "externalResources": "[[External Resources]]",
        "assets": "[[Assets]]"
    },
    "yes": "Oui",
    "no": "Non",
    "allowed": "Autorisé",
    "notAllowed": "Interdit",
    "confirmDialog": {
        "confirmSaveAndVerify": {
            "header": "Vérification de sauvegarde {{ objectType }}",
            "body": "Vous avez des modifications non sauvegardées sur la page. Voulez-vous sauvegarder et vérifier le {{ name }}?",
            "text": "Sauvegarder et vérifier"
        },
        "confirmVerify": {
            "header": "Vérifier {{ objectType }}",
            "body": "Voulez-vous vérifier le {{ name }}?",
            "text": "Vérifier"
        },
        "confirmEstablish": {
            "header": "Établir {{ objectType }}",
            "body": "Voulez-vous établir le {{ name }}?",
            "text": "Établir",
            "establishedDateInPast": "La date de la fonction Établir par date est déjà passée. Une fois sauvegardé, le {{ name }} sera établi le {{ date }}."
        },
        "confirmApprove": {
            "header": "Approuver {{ objectType }}",
            "body": "Souhaitez-vous approuver le {{ name }}?",
            "text": "Approuver"
        },
        "confirmCheckIn": {
            "header": "Enregistrer {{ objectType }}",
            "body": "Voulez-vous enregistrer le {{ name }}?",
            "text": "Enregistrer"
        },
        "confirmDecline": {
            "header": "Refuser {{ objectType }}",
            "body": "Voulez-vous refuser le {{ name }}?",
            "text": "Refuser"
        },
        "remove": {
            "header": "Éliminer {{ name }}",
            "body": "Êtes-vous sûr(e) de vouloir éliminer ce {{ name }}?",
            "text": "Éliminer"
        },
        "confirmSave": {
            "header": "Sauvegarder {{ objectType }}",
            "body": "Voulez-vous sauvegarder le {{ name }}?",
            "text": "Sauvegarder"
        },
        "confirmUpdateLastVerified": {
            "header": "Mettre à jour la date de la dernière vérification",
            "body": "Voulez-vous mettre à jour la date de la dernière vérification?",
            "text": "Mise à jour"
        },
        "confirmUpdateLastVerifiedDateOutOfOrder": {
            "header": "Mettre à jour la date de la dernière vérification",
            "body": "La date de la prochaine vérification ne peut pas être antérieure à ou le même jour que la date de la dernière vérification. La date de début de l’intervalle de vérification sera ajustée.",
            "text": "Mise à jour"
        },
        "confirmAssignToSelf": {
            "header": "M’attribuer {{ objectType }}",
            "body": "Voulez-vous vous attribuer « {{ name }} »?",
            "text": "Attribuer"
        },
        "confirmArchiveResource": {
            "header": "Archiver {{ objectType }}",
            "body": "Voulez-vous archiver « {{ name }} »",
            "text": "Archiver"
        },
        "confirmUnArchiveResource": {
            "header": "Restaurer {{ objectType }}",
            "body": "Voulez-vous restaurer « {{ name }} »",
            "text": "Restaurer"
        },
        "confirmUnsavedChanges": {
            "header": "Modifications non sauvegardées",
            "body": "Vous avez des modifications non sauvegardées sur cette page. Êtes-vous sûr(e) de vouloir les supprimer?",
            "text": "Annuler les modifications"
        }
    },
    "rowCountMessage": "Affiche {{ x }} sur un total de {{ y }}",
    "noRowCountMessage": "Affiche 0 sur un total de 0",
    "more": "Plus",
    "all": "Tout",
    "readMore": "Lire la suite",
    "readLess": "Réduire",
    "common": {
        "scope": "[[Scope]]",
        "team": "[[Team]]",
        "complianceCategory": "Catégorie de conformité",
        "search": "Rechercher",
        "comment": "Commentaire",
        "noComments": "No Comments Available (translation required)",
        "noAttachments": "Pas de Pièces Jointes",
        "note": "Remarque",
        "edit": "Modifier",
        "deselect": "Désélectionner",
        "name": "Nom",
        "save": "Sauvegarder",
        "add": "Ajouter",
        "requireApproval": "Exiger une approbation",
        "checkIn": "Enregistrer",
        "decline": "Refuser",
        "approve": "Approuver",
        "type": "Type",
        "approvedBy": "Approuvé par {{ name }}",
        "checkedInBy": "Enregistré par {{ name }}",
        "declinedBy": "Refusé par {{ name }}",
        "msgFileExceedMaxSize": "La taille du fichier dépasse la limite maximale de {{ size }} Mb",
        "msgErrorDownloadFile": "Échec du téléchargement de la pièce jointe {{ fileName }}",
        "msgErrorAttachmentExists": "Une pièce jointe portant le même nom existe déjà, souhaitez-vous remplacer la pièce jointe existante ou la sauvegarder en tant que nouvelle pièce jointe?",
        "msgAttachmentWithSameNameExist": "Une pièce jointe avec le même nom de fichier existe déjà.",
        "msgVirusDetected": "Virus détecté dans ce fichier",
        "msgFailProcessFile": "Impossible de traiter le fichier, veuillez réessayer.",
        "msgFailCloudmersiveAPI": "Impossible de valider le fichier, veuillez réessayer plus tarde.",
        "geolocations": "Géolocalisations",
        "next": "Suivant",
        "previous": "Précédent",
        "no": "Non",
        "yes": "Oui",
        "cancel": "Annuler",
        "accept": "Accepter",
        "close": "Fermer",
        "copy": "Copier",
        "back": "Retour",
        "roles": "Rôles",
        "or": "ou",
        "ok": "OK",
        "more": "plus",
        "finish": "Terminer",
        "andAreIn": "et sont dans",
        "tenants": "Locataires",
        "groups": "Groupes",
        "departments": "[[Departments]]",
        "locations": "[[Locations]]",
        "everyone": "Tout le monde",
        "noSearchResult": "Aucun résultat de recherche",
        "role": "Rôle",
        "group": "Groupe",
        "tenant": "Locataire",
        "department": "[[Department]]",
        "location": "[[Location]]",
        "entities": "Entités",
        "enabled": "Activé",
        "disabled": "Désactivé",
        "date": "Date",
        "time": "Temps",
        "timezone": "Fuseau horaire",
        "remove": "Éliminer",
        "others": "Autres",
        "submit": "Envoyer",
        "update": "Mise à jour",
        "confirmation": "Confirmer",
        "sms": "Message texte",
        "email": "Courriel",
        "default": "Par défaut",
        "pageSize": "Taille de la page",
        "pageOptionShowAll": "Afficher tout",
        "prev": "Précédente",
        "deleted": "Supprimé",
        "attachments": "Pièces jointes",
        "assignToSelf": "M’attribuer",
        "archive": "Archiver",
        "delete": "Supprimer",
        "selected": "Sélectionné",
        "includeArchived": "Inclure les archives",
        "archived": "Archivé",
        "restore": "Restaurer",
        "loadMore": "Charger plus",
        "all": "Tout",
        "unknown": "Inconnu",
        "_emptyString": "",
        "reload": "Recharger",
        "reloading": "Rechargement",
        "showAdvancedFilter": "Afficher le filtre avancé",
        "showSimpleFilter": "Masquer le filtre avancé",
        "filterStartDate": "Date de début",
        "filterEndDate": "Date de fin",
        "listSortAriaLabel": "Activer pour trier",
        "listSortAriaLabelDesc": "Activer pour trier par ordre décroissant",
        "listSortAriaLabelAsc": "Activer pour trier par ordre croissant",
        "lblNA": "S. O.",
        "defaultLocation": "[[Location]] par défaut",
        "both": "Tous les deux",
        "displayingXOutOfY": "Afficher {{displaying}} sur {{total}}",
        "additionalLocations": "[[Locations]] supplémentaires",
        "multiple": "Multiples",
        "lblNewFolder": "Créer un nouveau dossier",
        "lblUploadFile": "Téléverser le document",
        "lblUploadFiles": "Téléverser les documents",
        "lblSelectFile": "Sélectionner un document",
        "lblDeleteDocument": "Supprimer le document",
        "lblDeleteFolder": "Supprimer le dossier",
        "lblMoveDocument": "Déplacer le document",
        "lblMoveFolder": "Déplacer le dossier",
        "lblBookmarkDocument": "Attribuer un signet au document",
        "lblDownloadDocument": "Télécharger le document",
        "lblBookmarkName": "Nom du signet",
        "lblNewDocumentBookmark": "Créer un signet de document",
        "lblNewPageBookmark": "Créer un signet de page",
        "msgSuccessAddBookmark": "Signet {{ name }} créé avec succès",
        "msgErrorAddBookmark": "Échec de la création du signet {{ name }}",
        "completed": "{{date}} Terminé",
        "bookmark": "Signet",
        "document": "Document",
        "refresh": "Actualiser",
        "showingXofX": "Affiche {{count}} sur {{total}}",
        "enterprise": "Société",
        "realEstate": "Immobilier",
        "undo": "Défaire",
        "new": "Nouveau",
        "export": "Exportation",
        "createdOn": "Créé le",
        "lastModifiedOn": "Dernière modification le",
        "Required": "(obligatoire)",
        "lastModifiedBy": "Dernière modification par",
        "createdBy": "Créé par",
        "selectOne": "Sélectionner un",
        "create": "Créer",
        "upload": "Téléverser",
        "resetTable": "Réinitialiser le tableau",
        "msgSecurityErrorTitle": "Erreur de sécurité",
        "msgSecurityErrorMessage": "Vous n’avez pas l’autorisation d’accéder à la page demandée",
        "msgBcIndustryErrorMessage": "Votre société n’a pas de secteur spécifié pour la planification de la continuité des activités",
        "msgBcAssignedProcessErrorMessage": "Vous n’avez aucun [[processes]] affecté pour ce [[department]]",
        "msgBcAssignedDepartmentErrorMessage": "Vous n’avez aucun [[department]] affecté",
        "msgBcAssignedFunctionalAreasErrorMessage": "Vous n’avez aucun [[department]] affecté",
        "msgBcPlanErrorMessage": "Pas de plan de continuité des activités créé pour la planification de la continuité des activités",
        "msgNoAssignments": "Vous n’avez aucune affectation pour le plan de continuité des activités",
        "msgGeneralErrorMessage": "Erreur du système. Veuillez réessayer plus tard.",
        "msgNoIncidentErrorMessage": "Il n'y a pas d'incident actif associé aux plans d'activation.",
        "msgRequireOpenIncidentErrorMessage": "Cet incident est résolu.",
        "msgRequireResolvedIncidentErrorMessage": "Cet incident est toujours actif.",
        "msgBcManagerErrorMessage": "Votre société n’a pas de responsable de la continuité des activités spécifié pour la planification",
        "deactivated": "Désactivé",
        "active": "Actif",
        "backToTop": "Retour en haut de la page",
        "none": "Aucun",
        "move": "Mouvement",
        "preview": "Aperçu",
        "feet": "Pieds",
        "mile": "Mile",
        "meter": "Mètre",
        "kilometer": "Kilomètre",
        "height": "Hauteur",
        "width": "Largeur",
        "today": "Aujourd’hui",
        "showMore": "Afficher plus...",
        "showLess": "Afficher moins",
        "miles": "Miles",
        "meters": "Mètres",
        "kilometers": "Kilomètres",
        "exit": "Quitter",
        "exitSave": "Quitter et sauvegarder",
        "members": "Membres",
        "dashboard": "Tableau de bord",
        "assign": "Attribuer",
        "delegate": "Déléguer",
        "unassign": "Annuler l’affectation",
        "unassigned": "Non affecté",
        "rename": "Renommer",
        "loading": "Chargement en cours...",
        "started": "Commencé",
        "status": "Statut",
        "revisit": "Revisiter",
        "continue": "Continuer",
        "start": "Commencer",
        "navigate": "Naviguer",
        "resend": "Renvoyer",
        "view": "Affichage",
        "help": "Aide",
        "closed": "Fermé",
        "open": "Ouvert",
        "title": "Titre",
        "owner": "Propriétaire",
        "description": "Description",
        "issues": "[[Issues]]",
        "reports": "[[Reports]]",
        "generate": "Génération",
        "download": "Télécharger",
        "generateAll": "Générer tous les plans",
        "downloadAll": "Tout télécharger",
        "clearFilters": "Effacer les filtres",
        "inactive": "Inactif",
        "noRecords": "Pas de dossiers",
        "created": "Créé",
        "updated": "Mis à jour",
        "removed": "Éliminé",
        "saved": "Sauvegardé",
        "downloading": "Téléchargement en cours...",
        "uploading": "Téléversement en cours...",
        "deleting": "Élimination en cours...",
        "chart": "Graphique",
        "descriptions": "Descriptions",
        "end": "Fin",
        "never": "Jamais",
        "on": "Sur",
        "until": "Jusqu'à",
        "at": "À",
        "assigned": "Affecté",
        "hide": "Masquer",
        "unhide": "Démasquer",
        "showHidden": "Afficher les éléments masqués",
        "comments": "Commentaires",
        "criticalDate": "Date critique",
        "strategy": "Stratégie",
        "goToDetails": "Aller aux détails",
        "getStarted": "Démarrer",
        "viewDetails": "Voir les détails",
        "clear": "Effacer",
        "apply": "Appliquer",
        "empty": "Vide",
        "aToZ": "De A à Z",
        "recovered": "Rétabli",
        "notActivated": "Non activé",
        "saveAndClose": "Sauvegarder et fermer",
        "nA": "S. O.",
        "saving": "Sauvegarde",
        "translate": "Traduire",
        "address": "Adresse",
        "state": "État",
        "zip": "Zip",
        "country": "Pays",
        "city": "Ville",
        "primary": "Principal",
        "contacts": "Contacts",
        "phone": "Téléphone",
        "website": "Site Internet",
        "select": "Sélectionner",
        "language": {
            "en": "Anglais",
            "es": "Espagnol",
            "fr": "Français",
            "pt": "Portugais"
        },
        "reset": "Réinitialiser",
        "hours": "Heures",
        "days": "Jours",
        "day": "Jour",
        "hour": "Heure",
        "original": "Original",
        "current": "Actuel",
        "edits": "Modifications",
        "expiration": "Expiration",
        "copyToClipboard": "Copier dans le presse-papier",
        "addLocation": "Ajouter un [[location]]",
        "addDependency": "Ajouter une dépendance",
        "survey": "Enquête",
        "system": "Système",
        "dependencies": "Dépendances",
        "processes": "[[Processes]]",
        "thereArentMoreItemsAvailable": "Il n'y a plus d'articles disponibles pour sélectionner",
        "loadingMap": "Chargement de la carte en cours...",
        "success": "Succès",
        "schedule": "Programme",
        "updatedBy": "Mis à jour par",
        "updatedDate": "Date de mise à jour",
        "launch": "Lancer",
        "resolved": "Résolu",
        "selectedItemsLabel": "{0} articles sélectionnés",
        "support": "Support",
        "low": "Faible",
        "medium": "Moyen",
        "high": "Élevé",
        "formula": "Formule",
        "company": "Société",
        "continueConfirmation": "Voulez-vous continuer?",
        "target": "Cible",
        "approver": "Approbateur",
        "dueDate": "Date d’échéance",
        "recipient": "Bénéficiaire",
        "searchResults": "Résultats de recherche",
        "headerLabel": "Sélectionner l'utilisateur",
        "searchUserLabel": "Rechercher un utilisateur",
        "currentUserLabel": "Utilisateur actuel",
        "resultLabel": "Résultat",
        "notAvailable": "Pas disponible",
        "abandoned": "Abandonné",
        "abandon": "Abandonner",
        "waitingApproval": "En attente d'approbation",
        "inProgress": "En cours",
        "selectColumns": "Sélectionner les colonnes",
        "msgrExportSuccessful": "{{targetType}} exportation terminée avec succès.",
        "awaitingApproval": "En attente d'approbation",
        "pastDue": "Retard",
        "closedOn": "Date de fermeture",
        "errorExportFailed": "Impossible d’exporter un {{targetType}} fichier.",
        "unAssociate": "Dissocier",
        "associate": "Associer",
        "approved": "Approuvé",
        "assessments": "Évaluations",
        "paginationReport": "Affiche {first} jusqu’à {last} sur {{totalRowCount}}",
        "overTime": "Au fil du temps",
        "authority": "Autorité",
        "triggers": "Déclencheurs",
        "limitations": "Limites",
        "primaries": "Primaire(s)",
        "delegates": "Délégués",
        "itemsToComplete": "Eléments à compléter",
        "details": "Détails",
        "duplicate": "Dupliquer",
        "available": "Disponible",
        "heading": "Titre",
        "body": "Corps",
        "revert": "Revenir",
        "reverted": "Inversé",
        "publish": "Publier",
        "draft": "Brouillon",
        "version": "Version",
        "published": "Publié",
        "landscape": "Paysage",
        "portrait": "Portrait",
        "enterTitle": "Entrez le titre ici",
        "enterDescription": "Entrez la description ici",
        "tags": "Mots clés",
        "orientation": "Orientation",
        "pageBreak": "Saut de page",
        "promote": "Promouvoir",
        "generalOptions": "Options Générales",
        "markCompleteContinue": "Marquer comme terminé",
        "sendEmailContinue": "Envoyer un e-mail & Continuer",
        "keyPersonnel": "Personnel clé",
        "responseTeams": "Équipes d'intervention",
        "buildManage": "Construire",
        "track": "Piste",
        "skip": "Sauter",
        "missionStatement": "Énoncé de mission",
        "introduction": "Introduction",
        "discard": "Jeter",
        "general": "Général",
        "makeSystem": "Créer un Système",
        "annex": "Annexe",
        "sections": "Sections",
        "taskedAgencies": "Agences chargées des tâches",
        "responsibilities": "Responsabilités",
        "what": "Quoi",
        "where": "Où",
        "action": "Action",
        "noAssignee": "Aucun cessionnaire",
        "emailSent": "Email envoyé",
        "bulkUpload": "Téléversement groupé",
        "replace": "Remplacer",
        "run": "Courir",
        "alternate": "Alterner",
        "assignMember": "Attribuer un membre",
        "attachment": "Attachement",
        "technologies": "[[Technologies]]",
        "DateAdded": "date ajoutée",
        "selectedOf": "{{selected}} de {{available}}",
        "minutes": "Minutes",
        "examples": "Exemples",
        "usedInPlans": "Utilisé dans les forfaits",
        "undefined": "Indéfini",
        "tier": "Étage",
        "assignedOwner": "Propriétaire attribué",
        "markComplete": "Marquer comme terminé",
        "done": "Fait",
        "unclassified": "Non classé",
        "checking": "Vérification..."
    },
    "geolocations": {
        "lblShowAddress": "Afficher l’adresse",
        "options": "Options",
        "showGeofences": "Afficher les géo-repérages",
        "hideGeofences": "Masquer les géo-repérages",
        "showLocations": "Afficher les [[locations]]",
        "hideLocations": "Masquer les [[locations]]",
        "showUserAddress": "Afficher les adresses des utilisateurs",
        "hideUserAddress": "Masquer les adresses des utilisateurs",
        "filterByZip": "Code postal",
        "showPOIs": "Afficher les POI",
        "hidePOIs": "Masquer les POI",
        "location": "Emplacement",
        "filterByLocationOption": "Inclure les options d’[[location]] de l’utilisateur"
    },
    "geofence": {
        "new": "Nouveau géo-repérage",
        "name": "Nom du géo-repérage",
        "information": "Informations",
        "address": "Adresse de géo-repérage",
        "findAddress": "Trouver une adresse",
        "create": "Créer un géo-repérage",
        "update": "Mettre à jour le géo-repérage",
        "locationAddress": "Adresse de l’emplacement",
        "remove": "Éliminer le géo-repérage",
        "errorGeocodeFailed": "Impossible de résoudre l’adresse fournie, veuillez corriger l’adresse et réessayer.",
        "msgGeocodeSuccess": "adresse résolue avec succès.",
        "noGeofenceError": "Pas de géo-repérage actif, veuillez créer un géo-repérage à sauvegarder.",
        "errorInvalidPolygon": "Ce géo-repérage n’est pas pris en charge, veuillez vous assurer que le bord du géo-repérage ne traverse pas",
        "msgSaveGeofenceSuccess": "Le géo-repérage {{ name }} a été sauvegardé avec succès.",
        "errorSaveGeofenceFailed": "Échec de la sauvegarde du géo-repérage {{ name }}",
        "errorReverseGeocodeFailed": "Impossible de résoudre l’emplacement indiqué en adresse",
        "msgRemoveGeofenceSuccess": "Le géo-repérage {{ name }} a été éliminé avec succès.",
        "errorRemoveGeofenceFailed": "Échec de l’élimination du géo-repérage {{ name }}",
        "AreYouSureDeleteGeofence": "Êtes-vous sûr(e) de vouloir supprimer ce géo-repérage?",
        "createGeofenceFromPostalCode": "Créer un géo-repérage à partir de ce code postal",
        "msgFailCreateGeofenceFromPostalCode": "Échec de la création du géo-repérage à partir du code postal",
        "userAddress": "Adresse d’utilisateur",
        "userMobileLocation": "Emplacement mobile de l’utilisateur",
        "userAssociatedLocation": "[[Location]] associé de l’utilisateur",
        "radius": "Rayon",
        "width": "Largeur",
        "height": "Hauteur",
        "msgInvalidRadius": "Rayon invalide",
        "msgInvalidDimension": "Largeur ou hauteur invalide",
        "circle": "Cercle",
        "rectangle": "Rectangle",
        "polygon": "Polygone",
        "postalCode": "Code postal",
        "newGeofence": "Nouveau géo-repérage"
    },
    "establishByCard": {
        "dateCreated": "Date de création",
        "establishByDate": "Établir par date",
        "establishOnDate": "Établir à la date",
        "establishByDatePastDue": "Retard",
        "establishByDateDetails": "Établir par date"
    },
    "charts": {
        "labels": {
            "verified": "Vérifié",
            "overview": "Aperçu",
            "preparisScore": "Score Preparis",
            "pastDue": "Retard",
            "categoryScores": "Scores de catégorie",
            "score": "Score",
            "notEstablished": "Non-établi",
            "categoryScore": "Score de catégorie",
            "total": "Total",
            "categoryProgress": "Progression de la catégorie"
        },
        "colors": {
            "verified": "#28a745",
            "unverified": "#ffc107",
            "pastDue": "#dc3545",
            "radarLevels": {
                "default": {
                    "backgroundColor": "rgba(0, 0, 0, 0.0)",
                    "borderColor": "rgba(0, 0, 0, 0)",
                    "pointBackgroundColor": "rgba(0, 0, 0, 0)",
                    "pointBorderColor": "rgba(0, 0, 0, 0)",
                    "pointHoverBackgroundColor": "rgba(0, 0, 0, 0)",
                    "pointHoverBorderColor": "rgba(0, 0, 0, 0)"
                },
                "first": {
                    "backgroundColor": "rgba(212, 239, 223, 0.2)",
                    "borderColor": "rgba(39, 174, 96, 1)",
                    "pointBackgroundColor": "rgba(39, 174, 96, 1)",
                    "pointBorderColor": "#fff",
                    "pointHoverBackgroundColor": "#fff",
                    "pointHoverBorderColor": "rgba(39, 174, 96, 1)"
                }
            }
        }
    },
    "planStructure": {
        "plan": "Plan",
        "itemsInRecoveryPlan": "Éléments dans cette structure [[Plan]]",
        "availableItems": "Articles disponibles",
        "name": "Nom",
        "type": "Type",
        "tocIndent": "Retrait de la table des matières",
        "filename": "Nom de fichier",
        "allDocuments": "Tous les documents",
        "scopeRelatedDocuments": "Documents connexes [[Scope]]",
        "teamRelatedDocuments": "Documents connexes [[Team]]",
        "teamAndSubTeamRelatedDocuments": "Documents connexes [[Team]] et sous [[Team]]",
        "allReports": "Tous les rapports",
        "add": "Ajouter",
        "description": "Description",
        "document": "Document",
        "toc": "Table des matières",
        "report": "[[Report]]",
        "remove": "Éliminer",
        "planStructure": "Structure [[Plan]]",
        "planHeader": "En-tête [[Plan]]",
        "planCover": "Remplacement du corps de couverture [[Plan]]",
        "generate": "Génération",
        "insertToc": "Insérer une table des matières",
        "lastGenerationDate": "Date de dernière génération",
        "overrideNextGenDate": "Remplacer la date",
        "overrideNextGeneration": "Remplacer la date de prochaine génération",
        "planGenerationSchedule": "Calendrier de génération [[Plan]]",
        "scheduleFrequency": "Fréquence du programme",
        "schedulePlanGeneration": "Programme de Génération [[Plan]]",
        "planGenUseLastDayOfMonth": "Le dernier jour du mois",
        "jobStartDate": "Date de début du travail",
        "nextGeneration": "Prochaine Génération [[Plan]]",
        "generatingPlan": "Génération de [[Plan]]",
        "generatingAttachmentsZip": "Génération de pièces jointes Zip",
        "generateConfirmation": {
            "header": "Générer [[Plan]]",
            "body": "Voulez-vous générer votre [[plan]] en utilisant la version actuelle du [[plan]] ou la dernière version vérifiée du [[plan]]?",
            "useLatest": "Utiliser la dernière",
            "useVerified": "Utiliser la version vérifiée"
        },
        "invalidDateDialog": {
            "header": "Date de remplacement invalide",
            "body": "La date de génération [[Plan]] suivante doit être dans le futur",
            "text": "OK"
        },
        "useFirstDocumentAsCoverPage": "Utiliser le premier document comme page de garde"
    },
    "complianceCategories": {
        "filterDescription": "Affiner les résultats par nom",
        "newComplianceCategory": "Nouvelle catégorie de conformité",
        "complianceCategories": "Catégories de conformité",
        "name": "Nom de catégorie",
        "description": "Description",
        "confirmRemoveComplianceCategory": {
            "header": "Éliminer la catégorie de conformité",
            "body": "Êtes-vous sûr(e) de vouloir éliminer ce {{ objectType }} nommé {{ name }}?",
            "text": "Éliminer"
        },
        "confirmSaveComplianceCategory": {
            "header": "Sauvegarder la catégorie de conformité",
            "body": "Êtes-vous sûr(e) de vouloir sauvegarder ce {{ objectType }} nommé {{ name }}?",
            "text": "Sauvegarder"
        },
        "complianceCategoryWithDependentsError": "Impossible d’éliminer {{ name }} lorsque d’autres enregistrements sont affectés à cette catégorie"
    },
    "storageLocations": {
        "storageLocations": "Emplacements de stockage",
        "filterDescription": "Affiner les résultats par nom",
        "newStorageLocation": "Nouvel emplacement de stockage",
        "name": "Emplacement de stockage",
        "description": "Description",
        "getStorageLocationError": "Erreur lors de la récupération des emplacements de stockage",
        "confirmRemoveStorageLocation": {
            "header": "Éliminer l’emplacement de stockage",
            "body": "Êtes-vous sûr(e) de vouloir éliminer ce {{ objectType }} nommé {{ name }}?",
            "text": "Éliminer"
        },
        "confirmSaveStorageLocation": {
            "header": "Sauvegarder l’emplacement de stockage",
            "body": "Êtes-vous sûr(e) de vouloir sauvegarder ce {{ objectType }} nommé {{ name }}?",
            "text": "Sauvegarder"
        },
        "storageLocationWithItemsError": "Impossible d’éliminer {{ storageName }} si [[items]] lui est affecté"
    },
    "timeInterval": {
        "week": "Semaine",
        "7": "Semaine",
        "month": "Mois",
        "30": "Mois",
        "quarter": "Trimestre",
        "90": "Trimestre",
        "halfYear": "6 mois",
        "180": "6 mois",
        "year": "Année",
        "365": "Année"
    },
    "saveAndEstablish": {
        "header": "Sauver les options de {{ name }}",
        "body": "Le {{ name }} sera sauvegardé et établi le {{ date }}",
        "text": "Sauvegarder et établir",
        "establishedDateInPast": "La date de la fonction Établir par date est déjà passée. Une fois sauvegardé, le {{ name }} sera établi le {{ date }}.",
        "establishedDateToday": "La date d’établissement par date sélectionnée est Aujourd’hui. Le {{ name }} sera établi le {{ date }}."
    },
    "updateVerificationDate": {
        "header": "Date {{ name }} {{dateType}} de mise à jour",
        "body": "Veuillez sélectionner une nouvelle date {{ name }} {{dateType}}",
        "dateType_next": "Prochaine vérification",
        "dateType_last": "Dernière vérification"
    },
    "saveAndCheckIn": {
        "header": "Sauver les options de {{ name }}",
        "body": "Voulez-vous sauvegarder et archiver le {{ name }} ou uniquement sauvegarder le {{ name }}?",
        "saveAndCheckIn": "Sauvegarder et enregistrer",
        "saveNotCheckIn": "Sauvegarder mais ne pas enregistrer"
    },
    "settings": {
        "settings": "Réglages",
        "resourceRequireApproval": "Exiger des approbations pour les ressources [[team]] par défaut",
        "resourceRequireApprovalTooltip": "Lorsque cette option est activée, les ressources [[team]] telles que les articles nécessitent par défaut l’approbation d’un approbateur désigné pour être vérifiées.",
        "forceNextVerificationBasedOnLastVerified": "Remplacer le calcul de la date standard",
        "portalApprovalSettingSaveSuccess": "Paramètre d’approbation des ressources du portail sauvegardé",
        "portalApprovalSettingSaveError": "Erreur lors de la sauvegarde du [[setting]] d’approbation des ressources du portail",
        "portalSettingSaveSuccess": "Paramètre de portail sauvegardé",
        "portalSettingSaveError": "Erreur lors de la sauvegarde des [[settings]] du portail",
        "forceNextVerificationBasedOnLastVerifiedWarning": "Avertissement! Si cette option est activée, la prochaine date de vérification sera calculée uniquement en fonction de la dernière date de vérification et de la fréquence de vérification. L’activation de ce paramètre ne remplacera pas les données existantes",
        "assignResourceToSelf": "Autoriser [[team members]] à assumer la propriété des ressources [[team]]",
        "assignResourceToSelfTooltip": "Lorsque cette option est activée,[[team members]] peuvent s’attribuer [[team]] ressources à eux-mêmes"
    },
    "notificationSetting": {
        "notificationSetting": "Paramètre de notification",
        "notificationSettings": "[[Settings]] de notification",
        "jobType": "Type de notification",
        "verificationFrequency": "Fréquence de vérification",
        "notificationValue": "Numéro",
        "datepartType": "Intervalle",
        "add": "Ajouter une notification",
        "dueSoon": "Date d’échéance proche",
        "overdue": "En retard",
        "months": "Mois",
        "days": "Jour(s)",
        "confirmRemoveHeader": "Éliminer le [[setting]] de notification",
        "confirmRemoveMessage": "Êtes-vous sûr(e) de vouloir éliminer cette notification {{ x }} de {{ y }} {{ z }}?",
        "saveSuccess": "Paramètre de notification pour {{ a }} sur {{ b }} dans {{ c }} {{ d }} sauvegardé",
        "saveError": "Paramètre de notification pour {{ a }} sur {{ b }} dans {{ c }} {{ d }} non sauvegardé",
        "removeSuccess": "Paramètre de notification pour {{ a }} sur {{ b }} dans {{ c }} {{ d }} éliminé",
        "removeError": "Paramètre de notification pour {{ a }} sur {{ b }} dans {{ c }} {{ d }} non éliminé",
        "notificationTypes": {
            "eeca9d34-c1bd-4a24-8562-caaef0e1f510": "Date d’échéance proche",
            "e69761d6-02ac-4156-8f46-6b56fd99614a": "En retard"
        },
        "datepartTypes": {
            "f8b89b3c-8c9e-47c8-9a2e-9c5417d1a838": "Mois",
            "baba0540-0282-4aca-a53a-fb064ce2d509": "Jour(s)"
        },
        "dueSoonDescription": "Envoyer une notification {{ a }} {{ b }} avant la date d’échéance.",
        "overdueDescription": "Envoyer une notification {{ a }} {{ b }} après la date d’échéance.",
        "dueTodayDescription": "Envoyer une notification à la date d’échéance.",
        "dueSoonTitle": "Notifications des activités à venir",
        "dueTodayTitle": "Notifications des activités à la date d’échéance",
        "overdueTitle": "Notifications des activités en retard",
        "addNotificationSettingForDatepart": "Ajouter un Paramètre de notification {{ a }}",
        "customizeNotificationSettingForDatepart": "Personnaliser le {{ a }} Paramètre de notification",
        "editNotificationSetting": "Modifier le [[setting]] de notification",
        "addNotificationSetting": "Ajouter un [[setting]] de notification",
        "confirmSave": {
            "header": "Sauvegarder le [[setting]] de notification",
            "body": "Êtes-vous sûr(e) de vouloir sauvegarder ce réglage de notification {{ notificationType }} pour les activités {{ verificationFrequency }}?",
            "text": "Sauvegarder"
        },
        "confirmRemove": {
            "header": "Éliminer le [[setting]] de notification",
            "body": "Êtes-vous sûr(e) de vouloir éliminer ce réglage de notification {{ notificationType }} pour les activités {{ verificationFrequency }}?",
            "text": "Éliminer"
        },
        "default": "par défaut",
        "confirmChangePortalSettingHeader": "Modifier les [[settings]] de messagerie du portail",
        "confirmChangePortalSettingMessage": "Êtes-vous sûr(e) de vouloir {{ action }} {{ settingName }}?",
        "enable": "Activer",
        "disable": "Désactiver",
        "portalEmailSettingSaveSuccess": "[[Settings]] de messagerie du portail sauvegardés",
        "portalEmailSettingSaveError": "Erreur lors de la sauvegarde du [[setting]] de messagerie du portail",
        "notifications": "Notifications",
        "portalSetting": "Paramètre du portail",
        "enableReminderEmails": "Envoyer des courriels de rappel pour [[Team Members]]",
        "enableSummaryEmails": "Envoyer comme courriel récapitulatif",
        "enableReminderEmailsTooltip": "Lorsque cette option est activée, des rappels pour les tâches à venir sont envoyés par courriel à [[team members]] tous les jours.",
        "enableSummaryEmailsTooltip": "Lorsque cette option est activée, les rappels quotidiens pour les tâches à venir sont résumés dans un seul courriel.",
        "savePortalSettingSuccess": "Paramètre de portail sauvegardé",
        "savePortalSettingError": "Paramètre de portail non sauvegardé",
        "iQEmailFromName": "Courriel de notification de nom"
    },
    "iqVerificationSettings": {
        "verificationIntervalThresholdLabel": "Seuil d’intervalle de vérification",
        "intervalOptions": {
            "d": "Jour(s)",
            "m": "Mois"
        }
    },
    "sendMessage": {
        "disableEnablePrefix": "Masquez le préfixe du message de réponse inclus dans les SMS et les e-mails",
        "createdBy": "Créé par",
        "sendMessage": "Envoyer le message",
        "eventName": "Nom de l’événement",
        "options": "Options",
        "messages": "Messages",
        "filters": "Filtres",
        "review": "Examiner",
        "incidentName": "Nom de l’incident",
        "incidentType": "Type d’incident",
        "howToContact": "Sélectionnez la manière dont vous souhaitez contacter les destinataires",
        "requireResponse": "Demander aux destinataires de répondre",
        "yesWithCustom": "Oui, avec des réponses personnalisées (max. : {{maximumCustomResponses}})",
        "requireResponseYes": "Oui (Preparis ajoutera des instructions à votre message demandant aux utilisateurs de répondre par {{responses}})",
        "customResponses": "Réponses personnalisées",
        "customResponse": "Réponse personnalisée",
        "customResponseDefinition": "Définition de la réponse",
        "advanced": "Avancé",
        "personalEmail": "Courriel personnel",
        "personalPhone": "Téléphone personnel",
        "additionalPersonalPhone": "Téléphone personnel supplémentaire",
        "personalContact": "Envoyer ce message aux coordonnées personnelles du destinataire",
        "autoResend": "Renvoyer automatiquement le message",
        "autoSendToManager": "Envoyer automatiquement un message au responsable du destinataire",
        "autoTranslate": "Traduire automatiquement le message",
        "showMTDDescription": "Le temps d'arrêt maximal tolérable représente le temps total que l'entreprise est prête à accepter pour une interruption des [[processes]] métier.",
        "enableMTDSuccess": "Autoriser le temps d'arrêt maximal tolérable activé",
        "enableMTDFail": "Échec de l'activation de l'autorisation d'indisponibilité maximale tolérable",
        "disableMTDSuccess": "Autoriser le temps d'arrêt maximal tolérable désactivé",
        "disableMTDFail": "Échec de la désactivation d'autoriser le temps d'arrêt maximal tolérable",
        "sms": "Message texte",
        "voice": "Voix",
        "phone": "Téléphone",
        "pushMessage": "Message instantané",
        "email": "Courriel",
        "emergencyMessage": "Message d’urgence",
        "sendAfter": "envoyer après",
        "for": "Pour",
        "ifRecipients": "si les destinataires",
        "minutes": "Minutes",
        "hour": "Heure",
        "hours": "Heures",
        "day": "Jour",
        "responded": "a répondu",
        "hasNotResponded": "n’a pas répondu",
        "times": "fois,",
        "newMessage": "Nouveau message",
        "isSimulation": "Envoyer un message de simulation",
        "usersCanReplyViaLink": "Autoriser les utilisateurs à répondre via un lien",
        "unknown": "Inconnu",
        "subject": "Objet",
        "message": "Message",
        "ctr": "Appel à enregistrer",
        "tts": "Texte à énoncer",
        "call": "Appel",
        "phoneNumber": "Numéro de téléphone",
        "preview": "Aperçu",
        "bodyPrepend": "Répondre à ce msg ({{responsesAsString}}) : ",
        "bodyPrepend-en": "Reply to this msg ({{responsesAsString}}): ",
        "bodyPrepend-es": "Responder a este mensaje ({{responsesAsString}}): ",
        "bodyPrepend-fr": "Répondre à ce msg ({{responsesAsString}}): ",
        "bodyPrepend-pt": "Responder a esta mensagem ({{responsesAsString}}): ",
        "remaining": "Restant",
        "copyToOthers": "Copier à d’autres",
        "copySubjectToOthers": "Copier l’objet à d’autres",
        "copyBodyToOthers": "Copier le message à d’autres",
        "usersWhoAreIn": "Utilisateurs qui sont dans",
        "numberOfRecipients": "Nombre de destinataires",
        "allUsers": "Tous les utilisateurs",
        "manuallyAdd": "Ajouter manuellement",
        "andOr": "et/ou",
        "noSelections": "Aucune sélection n’a été effectuée",
        "autoPopulateKey": "Remplir automatiquement la RÉPONSE PERSONNALISÉE",
        "clickAbove": "Cliquer sur l’une des options ci-dessus pour commencer à sélectionner les destinataires",
        "filterHint01": "Sélectionner parmi toutes les catégories pour créer votre liste. Un utilisateur doit correspondre à l’une des valeurs de chaque catégorie que vous sélectionnez pour être inclus. Vous pouvez également ajouter manuellement des destinataires.",
        "example": "Exemple",
        "filterExample01": "Si vous sélectionnez des options dans plusieurs catégories, un utilisateur doit correspondre à tous les critères pour être inclus. Par exemple, si vous sélectionnez Rôle : Gestionnaire de programme et emplacements : Atlanta, Nashville et [[Department]] de commercialisation. Un utilisateur doit être dans le [[department]] commercialisation d’Atlanta ou de Nashville avec un rôle de gestionnaire de programme pour recevoir ce message.",
        "selectNone": "ne rien sélectionner",
        "viewSelections": "afficher les sélections",
        "deleteShape": "Supprimer la forme",
        "zoomToShape": "Zoomer sur la forme",
        "geofenceOptions": "Options de géo-repérage des messages",
        "geofenceChoices": "Comment souhaitez-vous créer votre géo-repérage?",
        "existingGeofence": "Sélectionner parmi les géo-repérages",
        "newGeofence": "Créer à partir de l’adresse",
        "geofences": "Géo-repérages",
        "contactMethods": "Méthodes de contact",
        "included": "Inclus",
        "excluded": "Exclu",
        "search": "Rechercher",
        "addByPhoneNumber": "Ajouter par numéro de téléphone",
        "addByEmail": "Ajouter par courriel",
        "addByType": "Ajouter par type",
        "manuallyAddedRecipients": "Destinataires ajoutés manuellement",
        "invalidPhoneNumber": "Numéro de téléphone invalide",
        "allUsersSelected": "Tous les utilisateurs ont été sélectionnés",
        "errorGettingData": "Erreur lors de l’obtention des données",
        "registeredRecipients": "Destinataires enregistrés",
        "noRecipientsError": "Aucun destinataire n’a été ajouté",
        "sentTo": "Messages envoyés à",
        "automaticFollowUp": "Suivi automatique",
        "sendNow": "Envoyer maintenant",
        "saveForLater": "Garder pour plus tard",
        "schedule": "Programme",
        "scheduleToSend": "Envoyer plus tard",
        "responses": "Réponses",
        "sendToPersonalContact": "Envoyer à un contact personnel",
        "toWhom": "À qui souhaitez-vous envoyer ce message?",
        "toWhomMaxOneHundred": "À qui souhaitez-vous envoyer ce message? (Max. de 100 participants)",
        "disabledForOverOneHundredParticipants": "Certaines options sont désactivées pour plus de 100 participants",
        "everyoneLabel": "Envoyer un message à toutes les personnes associées à cet incident",
        "everyoneDescription": "Toute personne affectée par cet incident, ayant reçu un message précédent ou été incluse dans une conférence téléphonique à propos de cet incident sera pré-inclue en tant que destinataire",
        "limitedLabel": "N’envoyer le message qu’à un sous-ensemble d’utilisateurs",
        "limitedDescription": "Envoyer uniquement à ceux dont la dernière réponse a été",
        "newLabel": "Envoyer un message à un nouveau groupe d’utilisateurs",
        "newDescription": "Vous aurez la liberté de choisir les destinataires du message au lieu de les pré-remplir",
        "incidentCreatorLabel": "Envoyer un message au créateur de l’incident",
        "incidentCreatorDescription": "a créé cet incident",
        "followUp": "Suivi",
        "scheduleMessageSuccess": "Message programmé",
        "scheduleMessageError": "Erreur de planification du message",
        "deleteScheduledMessageSuccess": "Message programmé supprimé",
        "deleteScheduledMessageError": "Erreur lors de la suppression du message programmé",
        "scheduledMessage": "Message programmé",
        "confirmDeleteScheduledMessageHeader": "Éliminer le message programmé",
        "confirmDeleteScheduledMessageDescription": "Êtes-vous sûr(e) de vouloir éliminer ce message programmé?",
        "confirmDeleteMessageTemplateHeader": "Éliminer le message sauvegardé",
        "confirmDeleteMessageTemplateDescription": "Êtes-vous sûr(e) de vouloir éliminer ce message sauvegardé?",
        "getScheduleMessageError": "Erreur lors de l’obtention du message planifié",
        "updateScheduledMessage": "Mettre à jour le message programmé",
        "followUpMessage": "Suivi",
        "saveAs": "Comment voulez-vous sauvegarder ce message?",
        "overwrite": "Écraser la donnée actuelle",
        "saveAsNew": "Sauvegarder comme nouveau",
        "saveMessageName": "Sauvegarder le nom du message",
        "savedMessages": "Messages sauvegardés",
        "pendingMessages": "Messages en attente",
        "pin": "Cliquer pour épingler",
        "unpin": "Cliquer pour désépingler",
        "removeMessageTemplate": "Éliminer le message sauvegardé",
        "send": "Envoyer",
        "removeTemplateHeader": "Éliminer le message sauvegardé",
        "removeTemplateBody": "Êtes-vous sûr(e) de vouloir éliminer ce modèle de message?",
        "noOfRecipient": "N<sup>bre</sup> de destinataires",
        "responseRequired": "Réponse requise",
        "noPendingMessage": "Aucun message en attente",
        "sendMessageSuccess": "Votre message est en route. Souhaitez-vous le sauvegarder pour l’utiliser à nouveau plus tard?",
        "sendMessageSuccessAlt": "Votre message est en route",
        "contacts": "Contacts",
        "includeOfficeContactInfo": "Appeler également le téléphone professionnel des destinataires?",
        "invalidEmail": "Courriel invalide",
        "confirmCancelHeader": "Annuler l’envoi du message",
        "confirmCancelBody": "Êtes-vous sûr(e) de vouloir annuler l’envoi du message?",
        "callRecipientOfficePhone": "Appeler le téléphone professionnel",
        "noDupeResponses": "Aucune réponse en double",
        "selections": "Sélections",
        "recipients": "Destinataires",
        "composeNewMessage": "Rédiger un nouveau message",
        "progress": "Progression",
        "dupeNameError": "Ce nom existe déjà",
        "successRemovingMessageTemplate": "Message sauvegardé éliminé",
        "allCountries": "Tous les pays",
        "noManuallyAddedRecipients": "Aucun destinataire ajouté manuellement",
        "manuallyAddedLocations": "[[Locations]] ajoutés manuellement",
        "manuallyAddedDepartments": "[[Departments]] ajoutés manuellement",
        "manuallyAddedGroups": "Groupes ajoutés manuellement",
        "manuallyAddedTenants": "Locataires ajoutés manuellement",
        "manuallyAddedRoles": "Rôles ajoutés manuellement",
        "submitForApproval": "Envoyer pour approbation",
        "pendingMessageName": "Nom du message en attente",
        "messageSubmittedForApproval": "Message envoyé pour approbation",
        "removePendingMessageHeader": "Éliminer le message en attente",
        "removePendingMessageBody": "Êtes-vous sûr(e) de vouloir éliminer ce message en attente?",
        "successDeletePendingMessage": "Message en attente éliminé",
        "newSendMessage": "Envoyer un nouveau message",
        "noReply": "Ne pas répondre",
        "approvePendingMessage": "Approuver le message en attente",
        "updatePendingMessage": "Mettre à jour le message en attente",
        "declinePendingMessage": "Refuser le message en attente",
        "newEvent": "Nouvel évènement",
        "approveAndSend": "Approuver et envoyer",
        "permissionError": "Vous n’êtes pas autorisé(e) à apporter des modifications à ce message en attente",
        "everyoneWhose": "Tous ceux dont la dernière réponse a été",
        "everyoneInThisEvent": "Tout le monde dans cet événement",
        "sendEdit": "Envoyer/Modifier",
        "detectStartStep": "Étape de démarrage de la détection automatique",
        "detectStartStepDescription": "L’application peut détecter automatiquement à quelle étape du [[processes]] d’envoi de message commencer en fonction des données de votre message sauvegardé. Cela peut réduire le nombre de clics nécessaires pour envoyer votre message. En précisant « Non », la première étape du [[processes]] d’envoi de message sera toujours utilisée.",
        "startWithMessageTemplate": "Commencer par un message sauvegardé (facultatif)",
        "startWithMessageTemplateDescription": "Les destinataires des messages sauvegardés NE SERONT PAS appliqués à ce message",
        "incidentCreator": "Créateur d’incident",
        "desktop": "Bureau",
        "desktopSeverity": "Gravité",
        "desktopSeverityAlarm": "[[Alert]]",
        "desktopSeverityWarning": "Avertissement",
        "desktopSeverityInfo": "Informations",
        "desktopSendSilently": "Envoyer en silence",
        "simulationMessage": "Ceci est une simulation",
        "errorDesktopAlertWithNoResponse": "Impossible d’envoyer un message sur le bureau en tant qu’[[alert]] lorsqu’une réponse n’est pas requise de l’utilisateur.",
        "sendAMessage": "Envoyer un message",
        "entityRemoved": "Une ou plusieurs sélections ont été éliminées du système depuis la dernière sauvegarde de ce message. Veuillez vous assurer que toutes les sélections sont à jour. Les éléments suivants ont été éliminés :",
        "createANewMessage": "Créer un nouveau message",
        "alertDeclare": "Alerter/déclarer",
        "createANewMessaage": "Créer un nouveau message",
        "requireRecipientToRespondTooltip": "(Preparis ajoutera des instructions à votre message demandant aux utilisateurs de répondre par OK ou NON)",
        "selectDeliveryMethod": "Sélectionner la méthode de livraison",
        "push": "Instantané",
        "copyText": "Copier le texte",
        "selectedResponses": "Réponses sélectionnées",
        "automaticReply": {
            "automaticReply": "Auto Réponse",
            "setUpAutomaticReplies": "Configurer des réponses automatiques",
            "lblAutomaticReplies": "Messages de réponse automatique",
            "configuredAutomaticReplies": "Réponses automatiques configurées"
        },
        "uploadNewDocument": "Composer un nouveau message",
        "title": "messages",
        "searchPlaceholder": "Rechercher un message enregistré",
        "addNew": "Composer un nouveau message",
        "createFolder": "Nouveau dossier",
        "searchTitle": "Résultats de recherche",
        "or": "ou",
        "sendOrEdit": "Envoyer/Modifier",
        "buttonPin": "Épingle",
        "buttonUnPin": "Détacher",
        "pinned": "Épinglé",
        "pending": "En attente",
        "messageDetails": "Détails du message",
        "selectAllWarning": "Vous avez sélectionné 'Sélectionner tout'. Êtes-vous sûr de vouloir ajouter tous les utilisateurs à la liste des destinataires de ce message?",
        "selectAllWarning2": "Vous avez potentiellement sélectionné un grand groupe de personnes à ajouter à ce message."
    },
    "googleMapAPITermAndService": "Conditions d’utilisation de l’API Map",
    "portalResources": {
        "resourceTypes": {
            "2e2016a0-4990-4276-b2f3-01950edc13e1": "Outils des employés",
            "e956d676-9d9f-4ab3-80b0-21e670e720ad": "Modèles",
            "9904f935-b860-44fc-8a7d-2a91475e9fbb": "Infographie",
            "47905bed-1e55-4efd-baab-63014481c1cd": "Feuilles de référence",
            "e9730817-b71b-4273-9e2b-650191be2d61": "Flux RSS",
            "bb073508-593c-40bb-8c39-6bf5aa801c0c": "Simulation",
            "5bb91d95-051d-48b3-b1bc-7a8f4558c1b6": "Nouvelles et présentations",
            "8e499ec0-a8b1-4de0-b830-89adcec08483": "Formation",
            "f2a1fa9e-bfbd-4051-a95b-95f4b518a17a": "Protocoles",
            "4cbb9953-a1d9-47ab-9c86-b810a6fcf898": "Blog",
            "40e1b2f9-e47a-4c6c-93fb-c4684f88705c": "Liste de contrôle",
            "62c2f132-76ad-47f8-bd43-dbe02abba987": "Présentations exécutives",
            "148a1689-577b-4ca5-b47b-e124ab249b42": "Menace"
        },
        "distributionTypes": {
            "5729f1b5-be13-4f33-aa73-7843b5bc2fce": "Verticaux et explicites",
            "d11232ba-5525-40d5-8062-57caca5fa498": "Explicite uniquement"
        },
        "resourceType": "Type de ressource",
        "icon": "Icône d’article de contenu",
        "document": "Document d’élément de contenu",
        "categories": "Catégories",
        "requiresACL": "Requiert ACL",
        "distributionType": "Type de distribution",
        "name": "Nom",
        "title": "Titre",
        "description": "Description",
        "newItem": "Nouvel article",
        "resourcesFilterPlaceholder": "Filtrer",
        "confirmRemoveHeader": "Éliminer l’article de contenu",
        "remove": "Éliminer",
        "contentItem": "Article de contenu",
        "sortOrder": "Commander",
        "select": "Sélectionner",
        "viewOrderLists": "Afficher les listes de commandes",
        "viewOrderList": "Afficher la liste de commandes",
        "availableContentItems": "Articles de contenu disponibles",
        "availableTrainingCourses": "Cours de formation disponibles",
        "selectedContentItems": "Articles de contenu sélectionnés",
        "selectedTrainingCourses": "Cours de formation sélectionnés",
        "vertical": {
            "3089683e-a1c9-4c91-9b87-1e3888a9cfcf": "norme",
            "f1d39814-6de9-481b-9abe-d655d396a64d": "CRE"
        }
    },
    "publicAlertSubscription": {
        "lblPageHeader": "Abonnements aux [[alerts]] publiques",
        "lblPageDescription": "Gérer les abonnements aux messages d’[[alert]] publics.",
        "publicAlertSubscription": "Abonnement",
        "publicAlertSubscriptions": "Abonnements",
        "subscriptionName": "Nom de l’abonnement",
        "incidentType": "Type d’incident",
        "groupEntity": "Groupe",
        "filterPlaceHolder": "Affiner les résultats par nom d’abonnement",
        "newSubscription": "Nouvel abonnement",
        "sendSms": "Envoyer un message texte",
        "sendEmail": "Envoyer un courriel",
        "sendVoice": "Envoyer un message vocal",
        "isPush": "Activer les messages instantanés",
        "categoryType": "Types de catégories",
        "certaintyType": "Types de certitude",
        "severityType": "Types de gravité",
        "urgencyType": "Types d’urgence",
        "eventType": "Types d’événements",
        "allTypes": "Tous les types",
        "zoneCountryFilter": "Filtre de pays et de zone",
        "zoneRegionFilter": "Filtre de région et de zone",
        "zoneTypeFilter": "Filtre de type de zone",
        "automaticallySendMessages": "Envoyer les messages automatiquement",
        "describe": "Décrire",
        "filterTypes": "Types de filtres",
        "filterLocations": "Filtrer les [[locations]]",
        "availableZones": "Zones disponibles",
        "selectedZones": "Zones sélectionnées",
        "rowCountMessage": "Affiche {{ x }} abonnement sur {{ y }}",
        "createNewSubscription": "Créer un nouvel abonnement",
        "errorMessages": {
            "selectAtLeastOneChannel": "Veuillez sélectionner au moins un canal de communication avant de sauvegarder.",
            "selectAtLeastOneRegion": "Veuillez sélectionner au moins une région avant de sauvegarder.",
            "requiredFieldMissing": "Veuillez remplir tous les champs requis."
        },
        "simple": "Facile",
        "advanced": "Avancé",
        "autoSend": "Envoi automatique",
        "confirmSave": {
            "header": "Sauvegarder l’abonnement aux [[alerts]] publiques",
            "body": "Êtes-vous sûr(e) de vouloir sauvegarder ce {{ objectType }} nommé {{ name }}?",
            "text": "Sauvegarder l’abonnement"
        },
        "confirmRemove": {
            "header": "Éliminer l’abonnement aux [[alerts]] publiques",
            "body": "Êtes-vous sûr(e) de vouloir éliminer ce {{ objectType }} nommé {{ name }}?",
            "text": "Éliminer l’abonnement"
        },
        "help": {
            "subscriptionName": "Nom pour étiqueter de manière unique cet abonnement.",
            "incidentType": "Type d’incident qui catégorise le mieux les types d’événements filtrés de cet abonnement.",
            "sendSms": "Envoyer des [[alerts]] publiques correspondant à cet abonnement par message texte.",
            "sendEmail": "Envoyer des [[alerts]] publiques correspondant à cet abonnement par courriel.",
            "sendVoice": "Envoyer des [[alerts]] publiques correspondant à cet abonnement par messagerie vocale.",
            "isPush": "Envoyer des notifications instantanées pour les [[alerts]] publiques correspondant à cet abonnement.",
            "groupEntity": "Désigner le groupe qui recevra et traitera les [[alerts]] publiques correspondant à cet abonnement.",
            "categoryType": "S’abonner à ces types de catégories.",
            "certaintyType": "S’abonner à ces types de certitude.",
            "severityType": "S’abonner à ces types de gravité.",
            "urgencyType": "S’abonner à ces types d’urgence.",
            "eventType": "S’abonner à ces types événementiels.",
            "zoneCountryFilter": "Filtrer les zones disponibles par pays.",
            "zoneRegionFilter": "Filtrer les zones disponibles par région.",
            "zoneTypeFilter": "Filtrer les zones disponibles par type.",
            "autoSend": "Envoyer automatiquement des messages lorsque des [[alerts]] publiques sont générées."
        }
    },
    "incidentTypeTriggers": {
        "manageIncidentTypeTriggers": "Gérer les déclencheurs d’incident",
        "incidentTypeTriggersDescription": "Les incidents peuvent être déclenchés via des API tierces dans le système Preparis. Cela est accompli en mappant un type d’incident envoyé par l’API tierce à un message sauvegardé.",
        "incidentTypeTriggers": "Déclencheurs d’incidents",
        "help": {
            "incidentTypeTriggers": "À moins qu’ils ne soient actuellement utilisés, presque tous les déclencheurs d’incidents peuvent être éliminés."
        },
        "incidentTypeTriggersName": "Déclencheurs",
        "incidentTypeHeader": "Type d’incident",
        "apiHeader": "API",
        "savedMessageHeader": "Message sauvegardé",
        "noTriggers": "Aucun déclencheur d’incident n’a été ajouté",
        "addNewTrigger": "Ajouter un nouveau déclencheur d’incident",
        "errorDuplicatedIncidentTypes": "Impossible d’utiliser un type d’incident comme déclencheur plusieurs fois.",
        "errorNonSendableSavedMessages": "Le(s) message(s) sauvegardé(s) ({{messageNames}}) ne peut/peuvent pas être envoyé(s) car tous les champs obligatoires ne sont pas remplis. Veuillez sélectionner un/des autre(s) message(s) sauvegardé(s)."
    },
    "incidentTypes": {
        "manageIncidentTypes": "Gérer les types d’incident",
        "incidentTypesDescription": "Ajouter des types d’incident supplémentaires qui peuvent être utilisés pour classer les incidents lors de la création et de la mise à jour d’incidents dans le portail et également lors de la création d’incidents déclenchés par l’utilisateur via l’application mobile.",
        "noIncidentTypes": "Aucun type d’incident n’a été ajouté",
        "incidentType": "Type d’incident",
        "incidentTypes": "Types d’incidents",
        "systemDefaultIncidentTypes": "Types d’incident par défaut du système",
        "addNewIncidentType": "Ajouter un nouveau type d’incident",
        "addSystemIncidentType": "Ajouter un type d’incident système",
        "msgRequiredFieldsWarning": "Veuillez saisir tous les champs obligatoires ou les éliminer avant de sauvegarder.",
        "msgSaveIncidentTypesSuccess": "Les types d’incident ont bien été sauvegardés.",
        "msgDuplicateIncidentTypeNameWarning": "Un ou plusieurs types d’incident portent le même nom. Veuillez vérifier le nom de votre type d’incident.",
        "incidentTypeName": "Nom du type d’incident",
        "saveSuccess": "Les types d’incidents ont bien été sauvegardés.",
        "help": {
            "incidentTypes": "À moins qu’ils ne soient actuellement utilisés, presque tous les types d’incidents peuvent être éliminés. En outre, les types d’incidents personnalisés peuvent être renommés. (Les types d’incidents pré-remplis sont en lecture seule.) Le type d’incident par défaut « Non défini » est à la fois obligatoire et en lecture seule, il ne peut pas être renommé ou éliminé."
        },
        "errors": {
            "messagingIncidentTypeWithGroupDependencies": "Un ou plusieurs types d’incidents éliminés sont actuellement utilisés comme paramètre de groupe initié par l’utilisateur.",
            "messagingIncidentTypeWithChannelDependencies": "Un ou plusieurs types d’incidents éliminés sont actuellement utilisés comme paramètre de canal initié par l’utilisateur.",
            "messagingIncidentTypeWithPublicAlertDependencies": "Un ou plusieurs types d’incidents éliminés sont actuellement utilisés avec un paramètre d’[[alert]] publique.",
            "messagingIncidentTypeCannotRemoveSystemDefault": "« Non défini » est une valeur par défaut du système et ne peut pas être éliminé.",
            "messagingIncidentTypeNamesAreNotUnique": "Les noms ne sont pas uniques"
        }
    },
    "emailTypes": {
        "acb4f38f-3642-472a-8de2-dcead28584a2": "Personnel",
        "d440a213-3ab4-4fb9-b306-5f567141ac53": "Secondaire",
        "d2b1d156-bdf9-4223-9368-825a448025ad": "Principal"
    },
    "phoneTypes": {
        "9da9c256-8283-4a0a-bd3d-10df73e1f43b": "Sécurité",
        "20509910-5326-4057-b21f-5c694b314692": "Bureau",
        "5322a58a-8683-449b-a562-b0577de3fa74": "Urgence",
        "5eec12d5-595f-4123-a613-c63e701ae993": "Personnel",
        "33f27328-8fee-4835-b020-cd6d48bb39cd": "Mobile",
        "391dca7b-aac3-4875-94e2-2ef680c7f35f": "Personnel supplémentaire"
    },
    "responses": {
        "lblUnknown": "Inconnu",
        "lblNoResponse": "Pas de réponse",
        "lblNotOk": "NE PAS",
        "lblOk": "OK"
    },
    "publicAlerts": {
        "dateTimeSent": "Date d’envoi",
        "msgType": "Type",
        "category": "Catégorie",
        "urgency": "Urgence",
        "severity": "Gravité",
        "certainty": "Certitude",
        "dateTimeExpiration": "Expire le",
        "recentPublicAlerts": "[[Alerts]] publiques récentes",
        "status": "Statut",
        "expired": "Expiré",
        "active": "Actif",
        "recentAlerts": "[[Alerts]] récentes",
        "instruction": "Instruction",
        "description": "Description",
        "publicAlerts": "[[Alerts]] publiques",
        "responseType": "Type de réponse",
        "showMore": "Afficher plus",
        "showLess": "Afficher moins",
        "filters": "Filtres",
        "areas": "Domaines",
        "headline": "Gros titre",
        "expiresOn": "Expire le",
        "sentOn": "Envoyé sur",
        "sendMessage": "Envoyer le message",
        "errors": {
            "errorLoadingRecentAlerts": "Erreur lors du chargement des [[alerts]] récentes",
            "errorLoadingAlertInfo": "Erreur lors du chargement des informations d’[[alert]] supplémentaires"
        },
        "rowCountMessage": "Affichant {{ displayedCount }} sur {{ totalCount }} {{ status }} [[alerts]]",
        "showAdvanced": "Afficher les [[settings]] avancés",
        "showSimple": "Afficher simple",
        "previousDays": {
            "2": "Dernières 48 heures",
            "3": "Dernières 72 heures",
            "4": "4 derniers jours",
            "5": "5 derniers jours"
        },
        "noAlertsToDisplayMessage": "Pas d’[[alerts]] {{ status }} sur le {{ timeSpan }}",
        "subscriptions": "Abonnements",
        "subscription": "Abonnement"
    },
    "messageSettings": {
        "errorRequiredFields": "Veuillez remplir tous les champs obligatoires.",
        "errorResetMessageFailed": "Une erreur s’est produite lors de la réinitialisation des [[settings]] de message.",
        "errorSavMessageFailed": "Une erreur s’est produite lors de la sauvegarde des [[settings]] de message.",
        "lblAreYouSureResetSettings": "Êtes-vous sûr(e) de vouloir réinitialiser les [[settings]] des messages?",
        "lblEscalateMessageContent1": "Si vous souhaitez donner aux destinataires du message le temps de répondre par message texte, courriel ou message instantané avant qu’ils ne soient appelés, réglez cette option sur Oui. Vous pourrez alors sélectionner le temps d’attente avant l’appel.",
        "lblEscalateMessageContent2": "Si cette option est réglée sur Non, le destinataire sera appelé en même temps qu’il recevra des messages texte/courriel/instantanés.",
        "lblEscalateMessageContent3": "Remarque : Le réglage s’applique UNIQUEMENT aux messages qui nécessitent une réponse et pour lesquels les modes Message texte et/ou Courriel et/ou Message instantané ET Vocal sont sélectionnés.",
        "lblEscalateMessageTitle": "Faire remonter le message lorsqu’une réponse EST requise (Message texte/Courriel/Message instantané à Vocal)?",
        "lblIncludeOfficePhoneContent": "Si cette option est réglée sur Oui, lorsqu’un utilisateur envoie un nouveau message ET a sélectionné Vocal (appeler les destinataires), l’option d’appeler le téléphone de bureau d’un destinataire sera présélectionnée. Les utilisateurs pourront remplacer la valeur par défaut et désélectionner par message si nécessaire.",
        "lblIncludeOfficePhoneTitle": "Inclure le téléphone professionnel pour les appels vocaux par défaut?",
        "lblPersonalContactContent": "Si cette option est réglée sur Oui, lorsqu’un utilisateur envoie un nouveau message, l’option d’envoi aux coordonnées personnelles du destinataire du message sera présélectionnée. Les utilisateurs pourront remplacer cette valeur par défaut et désélectionner par message si nécessaire.",
        "lblPersonalContactTitle": "Envoyer un message aux coordonnées personnelles de l’utilisateur?",
        "lblSettingsActivity": "[[Settings]] Activité",
        "lblSettingsTitle": "[[Settings]] de message",
        "lblAutoArchive": "Archivage automatique des enregistrements d’historique des incidents",
        "lblAutoArchiveContent": "Si cette option est réglée sur Oui, les enregistrements d’historique des incidents résolus seront automatiquement archivés. Vous aurez la possibilité de choisir l’intervalle d’archivage et de résoudre et d’archiver les enregistrements qui sont anciens mais toujours actifs.",
        "lblForceResolution": "Résoudre et archiver les incidents actifs plus anciens",
        "lblIntervalDays": "Archiver les incidents plus anciens que",
        "msgResetMessageSuccess": "Les [[settings]] de message ont été réinitialisés avec succès.",
        "msgSaveMessageSuccess": "Les [[settings]] de message ont été sauvegardés avec succès.",
        "lblPersonalContactPhoneTitle": "Envoyer un message au téléphone personnel de l’utilisateur?",
        "lblPersonalContactPhoneContent": "Si cette option est réglée sur Oui, lorsqu’un utilisateur envoie un nouveau message, l’option d’envoi aux coordonnées personnelles du téléphone du destinataire du message sera présélectionnée. Les utilisateurs pourront remplacer cette valeur par défaut et désélectionner par message si nécessaire.",
        "personalContactEmailTitle": "Envoyer un message à l’adresse de courriel personnelle de l’utilisateur?",
        "lblPersonalContactEmailContent": "Si cette option est réglée sur Oui, lorsqu’un utilisateur envoie un nouveau message, l’option d’envoi aux coordonnées de courriel personnelles du destinataire du message sera présélectionnée. Les utilisateurs pourront remplacer cette valeur par défaut et désélectionner par message si nécessaire.",
        "lblOverwriteDefaultUserResponses": "Si vous remplacez les réponses utilisateur par défaut, les options de réponse d’un message auront ces valeurs",
        "lblDesablePrefixSmsVoice": "Applicable uniquement si vous demandez une réponse",
        "btnSave": "Sauvegarder",
        "btnUndo": "Annuler",
        "btnReset": "Réinitialiser",
        "lblCustomResponses": "Remplacer les réponses des utilisateurs par défaut?",
        "lblDisablePrefix": "Masquer le préfixe du message de réponse inclus dans les messages texte et les courriels.",
        "confirmReset": {
            "body": "Êtes-vous sûr(e) de vouloir réinitialiser les [[settings]] des messages?",
            "header": "Confirmer",
            "btnText": "OK"
        },
        "allowOfficePhoneSMS": "Autoriser l'envoi de SMS aux téléphones de bureau éligibles.",
        "allowOfficePhoneSMSDesc": "S'il est défini sur oui, les numéros de téléphone du bureau seront inclus dans les messages tant que le téléphone est sélectionné et n'a pas d'extension."
    },
    "conferenceCall": {
        "conferenceCall": "Conférence téléphonique",
        "selectContactTip": "Si vous choisissez Mobile, Professionnel ou Personnel, les participants sont automatiquement appelés selon la méthode de contact sélectionnée lorsque vous démarrez l’appel",
        "selectHowYouWantToContact": "Sélectionnez la manière dont vous souhaitez contacter les participants pour cette conférence téléphonique (vous ne pouvez choisir qu’une seule option)",
        "newConferenceCall": "Nouvelle conférence téléphonique",
        "mobileTip": "Meilleur moyen d’atteindre les utilisateurs",
        "mobile": "Mobile",
        "office": "Bureau",
        "personal": "Personnel",
        "optionsConferenceDetailDescription": "Sélectionner où envoyer les détails de la conférence téléphonique pour les participants",
        "noCall": "Ne pas appeler",
        "noCallTip": "Envoyer uniquement les détails de la conférence téléphonique",
        "personalEmail": "Courriel personnel",
        "personalPhone": "Téléphone personnel",
        "finalRecipientCountErrorMsg": "Max. de destinataires : {{maxRecipient}}",
        "startConferenceCall": "Démarrer la conférence téléphonique",
        "contactParticipants": "Contacter les participants",
        "sendConferenceCallDetailTo": "Envoyer les détails de la conférence téléphonique à",
        "optionsPersonalInfo": "Envoyer les détails de la conférence téléphonique aux coordonnées personnelles",
        "success": "La conférence téléphonique a démarré avec succès",
        "confirmCancelHeader": "Annuler la conférence téléphonique",
        "confirmCancelBody": "Êtes-vous sûr(e) de vouloir annuler cette conférence téléphonique?",
        "followUpConferenceCall": "Suivi",
        "optionsPersonalInfoDescription": "Envoyer ce message aux coordonnées personnelles du destinataire",
        "scheduleConferenceCall": "Programmer une conférence téléphonique",
        "scheduleConferenceCallError": "Erreur lors de la planification de la conférence téléphonique",
        "scheduleConferenceCallSuccess": "Conférence téléphonique programmée avec succès",
        "scheduledConferenceCalls": "Conférences téléphoniques programmées",
        "noScheduledConferenceCalls": "Aucune conférence téléphonique programmée",
        "dateCreated": "Date de création",
        "scheduledDate": "Date programmée",
        "scheduledTime": "Heure programmée",
        "createdBy": "Créé par",
        "deleteScheduledConferenceCallBody": "Êtes-vous sûr(e) de vouloir éliminer cette conférence téléphonique programmée?",
        "deleteScheduledConferenceCall": "Éliminer la conférence téléphonique programmée",
        "updateScheduledConferenceCall": "Mettre à jour la conférence téléphonique programmée",
        "scheduledConferenceCall": "Conférence téléphonique programmée",
        "searchScheduledConCallsPlaceholder": "Rechercher des conférences téléphoniques programmées",
        "everyoneInThisEvent": "Tout le monde dans cet événement",
        "additionalMessage": "Message supplémentaire",
        "additionalMessageDescription": "Ceci est un message facultatif qui sera ajouté à la fin du message que les participants recevront dans la notification d’une conférence téléphonique",
        "additionalSmsMessagePreview": "Une conférence téléphonique d’urgence a été établie par  {{userProfileName}}: +{{portalConferenceCallNumber}} ID : <em title=\"le code PIN sera généré lorsque l’appel est lancé\"> code pin</em> One-touche : tél. : +{{portalConferenceCallNumber}},,<em title=\"le code PIN sera généré lorsque l’appel est lancé\"> n° de code PIN</em>.",
        "additionalEmailBodyPreview": "Une conférence téléphonique d’urgence a été programmée par {{userProfileName}}. Veuillez appeler le numéro suivant pour participer à la conférence téléphonique :<br /><br />+{{portalConferenceCallNumber}}<br /><br />ID de conférence : <em title=\"Le code PIN sera généré lorsque l’appel est lancé\">n° de code PIN</em><br />One-touch : tél. : +{{portalConferenceCallNumber}},,<em title=\"Le code PIN sera généré lorsque l’appel est lancé\">n° de code PIN</em>",
        "optionsRecordCall": "Sélectionnez si vous souhaitez enregistrer cette conférence téléphonique",
        "recordCallDisclaimer": "Les lois applicables peuvent exiger que les appelants entendent une notification d’enregistrement d’appel avant qu’un appel ne soit enregistré. Il en va de votre responsabilité de vous assurer que les procédures d’enregistrement des appels de votre société sont conformes aux lois et aux réglementations applicables. Preparis n’a aucune responsabilité quant à la conformité de votre société."
    },
    "customFields": {
        "customFieldTitle": "Questions personnalisées",
        "customFieldsDescription": "Les questions personnalisées seront ajoutées à votre flux de planification et les réponses seront affichées dans votre plan et dans le suivi des problèmes.",
        "fieldName": "Nom",
        "fieldType": "Type de champ",
        "isRequired": "Requis",
        "fieldTypes": {
            "5f31785c-4d3c-4d68-a834-da37c2bc0a76": "Numérique",
            "0f6f2372-1e4c-4d67-ad13-615a0540488c": "Texte court",
            "b9847d16-19b5-4ed4-a4e3-d7f07533ab5a": "Texte long",
            "d8bd3d80-d25d-4056-8754-a740f2396792": "Vrai ou faux",
            "aad6f035-05f9-42d3-b6ea-682371340875": "Sélectionner un",
            "76c83029-8439-42ad-90e6-7f0ff54b732c": "Sélectionner plusieurs",
            "1eccbe40-1b3b-461b-95df-35f1fe6a8af2": "Date/heure"
        },
        "isRequiredOptions": {
            "true": "Oui",
            "false": "Non"
        },
        "displaySequence": "Séquence d’affichage",
        "fieldDomain": "Options",
        "success": {
            "deleteCustomFieldSuccess": "Suppression du champ personnalisé effectuée avec succès",
            "saveCustomFieldsDisplayOrderSuccess": "Sauvegarde de l’ordre d’affichage effectuée avec succès",
            "saveCustomFieldSuccess": "Sauvegarde du champ personnalisé effectué avec succès"
        },
        "errors": {
            "getCustomFieldsError": "Échec du chargement des champs personnalisés",
            "saveCustomFieldsDisplayOrderError": "Erreur lors de la sauvegarde du nouvel ordre d’affichage",
            "deleteCustomFieldError": "Erreur lors de la suppression du champ personnalisé",
            "customFieldWithSameNameError": "Erreur lors de la sauvegarde du champ personnalisé avec le même nom",
            "saveCustomFieldError": "Erreur de la sauvegarde du champ personnalisé",
            "customFieldOptionWithSameNameError": "Erreur lors de la sauvegarde du champ personnalisé avec des noms d’options en double",
            "customFieldTypeSelectOneWithoutOptions": "Des options sont requises pour les champs personnalisés « Sélectionner un »",
            "customFieldsInvalid": "Veuillez consulter les champs personnalisés"
        },
        "help": {
            "fieldNameTooltip": "Nom d’étiquette du champ personnalisé",
            "fieldTypeTooltip": "Type de données à collecter avec ce champ personnalisé",
            "isRequiredTooltip": "Désigner si ce champ personnalisé est obligatoire",
            "editCustomFieldInstructions": "Modifiez le type de données à collecter et si vous souhaitez ou non exiger ce champ personnalisé.",
            "addCustomFieldInstructions": "Ajouter un champ personnalisé pour collecter des informations.",
            "optionsTooltip": "Liste des options dans l’ordre d’affichage."
        },
        "editCustomField": "Modifier les champs personnalisés existants",
        "addCustomField": "Ajouter un nouveau champ personnalisé",
        "newCustomField": "Nouveau champ personnalisé",
        "confirmRemoveCustomField": {
            "header": "Éliminer le champ personnalisé",
            "body": "Êtes-vous sûr(e) de vouloir éliminer ce champ personnalisé?",
            "text": "Éliminer"
        },
        "confirmSaveCustomField": {
            "header": "Sauvegarder le champ personnalisé",
            "body": "Êtes-vous sûr(e) de vouloir sauvegarder ce champ personnalisé",
            "text": "Sauvegarder"
        },
        "customFields": "Champs personnalisés",
        "optionName": "Nom de l’option",
        "addOption": "Ajouter une option",
        "default": "Par défaut",
        "restore": "Restaurer",
        "options": "Options",
        "makeDefault": "Rendre par défaut",
        "saveDisplayOrder": {
            "cancelDisplayOrder": "Annuler la commande",
            "saveDisplayOrder": "Sauvegarder la commande",
            "header": "Sauvegarder le nouvel ordre d’affichage",
            "body": "Êtes-vous sûr(e) de vouloir modifier l’ordre dans lequel les champs sont affichés?",
            "text": "Sauvegarder l’ordre d’affichage"
        },
        "customFieldsActivity": "Activité des champs personnalisés",
        "deprecatedOption": "Option obsolète",
        "bcCustomQuestionsTitle": "Questions personnalisées sur la continuité des activités",
        "bcCustomQuestionsDescription": "Gérez vos questions personnalisées sur la continuité des activités.",
        "disasterRecovery": "Reprise après sinistre",
        "drCustomQuestionsTitle": "Questions personnalisées sur la reprise après sinistre.",
        "drCustomQuestionsDescription": "Gérez vos questions personnalisées de reprise après sinistre.",
        "technology": "[[Technology]]",
        "continuityOfOperationPlanning": "Continuité",
        "customQuestionsDescription": "Gérez ici vos questions de continuité douanière."
    },
    "iqStatus": {
        "0": "",
        "1": "Non-établi"
    },
    "cre": {
        "locations": "[[Locations]]",
        "location": "[[Location]]"
    },
    "apiManagement": {
        "newApi": "Nouveau",
        "thirdPartyIntegrations": "Intégrations tierces",
        "integrationName": "Nom",
        "appId": "Identifiant d’application",
        "apiKey": "Clé API",
        "addApiHeader": "Nouvelle intégration",
        "confirmButtonText": "Sauvegarder",
        "deleteApi": "Supprimer",
        "confirmRemoveHeader": "Supprimer l’intégration",
        "removeConfirm": "Cette intégration sera éliminée de toutes les société qui l’utilisent actuellement. Êtes-vous sûr(e) de vouloir éliminer cette intégration?",
        "errorUnableToLoad": "Impossible de charger les intégrations tierces pour le moment.",
        "errorUnableToDelete": "Impossible de supprimer cette intégration tierce pour le moment.",
        "successDelete": "Suppression de l’intégration tierce réussie",
        "successCreate": "Création de l’intégration tierce réussie"
    },
    "panicTriggers": {
        "panicTriggerConfiguration": "Configuration du déclencheur du mode Panique",
        "panicTriggerLocations": "[[Locations]] du déclencheur du mode Panique",
        "managePanicTriggers": "Gérer les déclencheurs du mode Panique",
        "panicTriggersDescription": "Les incidents peuvent être déclenchés via le bouton Mode Panique de l’application mobile. Cette page permet de configurer le message sauvegardé de l’incident.",
        "lblEnablePanic": "Activer le mode Panique",
        "editMessageTemplate": "Modifier le déclencheur",
        "deleteMessageTemplate": "Supprimer le déclencheur",
        "addMessageTemplate": "Ajouter un déclencheur par défaut",
        "addCustomizedByLocation": "Ajouter un déclencheur personnalisé par [[location]]",
        "contacts": "Contacts",
        "filters": "Filtres",
        "locations": "[[Locations]]",
        "groups": "Groupes",
        "recipients": "Destinataires",
        "btnSaveAndConfigureMessage": "Accepter et configurer le message",
        "btnConfigureMessage": "Configurer le message",
        "deleteConfirmation": {
            "header": "Éliminer le déclencheur de mode Panique",
            "body": "Êtes-vous sûr(e) de vouloir éliminer ce déclencheur de mode Panique?",
            "text": "Éliminer"
        },
        "enablePanicConfirmation": {
            "header": "Activer le mode Panique",
            "body": "Êtes-vous sûr(e) de vouloir activer le mode Panique?",
            "text": "Activer"
        },
        "disablePanicConfirmation": {
            "header": "Désactiver le mode Panique",
            "body": "Êtes-vous sûr(e) de vouloir désactiver le mode Panique?",
            "text": "Désactiver"
        },
        "disablePanicAndRemovePanicButtonsConfirmation": {
            "header": "Éliminer les boutons d’[[alert]] et désactiver le mode Panique",
            "body": "<p>Un bouton d’[[alert]] de mode Panique ou d’enregistrement programmé existe actuellement sur l’application mobile. (Voir Paramètres d’[[alert]] lancée par mobile.)</p><p>La désactivation du mode Panique éliminera ces boutons. Êtes-vous sûr(e) de vouloir éliminer ces boutons et désactiver le mode Panique?</p>",
            "text": "Éliminer les boutons et désactiver"
        },
        "locationsNotAvailable": "Aucun [[location]] disponible pour créer un déclencheur de mode Panique.",
        "panicEnabledSuccess": "Le mode Panique est activé",
        "panicDisabledSuccess": "Le mode Panique est désactivé",
        "panicEnableFailed": "Échec de l’activation du mode Panique",
        "panicDisableFailed": "Échec de la désactivation du mode Panique"
    },
    "panic": {
        "panic": "Mode Panique",
        "initiator": "Initiateur",
        "lastLocation": "Dernier emplacement",
        "status": "Statut",
        "startTime": "Heure de début",
        "resolutionTime": "Temps de résolution",
        "gotToIncidentDetail": "Détails de l’incident",
        "updateStatus": "Statut de mise à jour",
        "archiveSelected": "Archive sélectionnée",
        "active": "Actif",
        "scheduledCheckIn": "Enregistrement programmé",
        "archived": "Archivé",
        "resolved": "Résolu",
        "scheduled": "Programmé",
        "noPanic": "Pas de Panique",
        "resolutionReasons": {
            "mistake": "Erreur",
            "clear": "Effacer",
            "checkIn": "Enregistrement",
            "custom": "Personnaliser"
        },
        "resolve": "Résoudre",
        "comments": "Commentaires",
        "noComments": "Sans commentaires",
        "resolveComments": "Résoudre les commentaires",
        "reason": "Motif",
        "noLastLocationRecorded": "Aucun dernier emplacement enregistré",
        "archiveBody": "Êtes-vous sûr(e) de vouloir l’archiver?",
        "archive": "Archiver",
        "scheduledCheckInMinutes": "Enregistrement programmé dans {{minutes}} minutes",
        "panicDashboard": "Tableau de bord du mode Panique",
        "showHistory": "Afficher le fil d’Ariane",
        "options": "Options",
        "showLocations": "Afficher les [[locations]]",
        "showPOIs": "Afficher les POI",
        "geolocations": "Géolocalisations",
        "showUsers": "Afficher les autres utilisateurs",
        "remaining": "Restant",
        "resolveCommentsRequired": "Résoudre les commentaires requis",
        "clickToView": "Cliquer pour afficher",
        "scheduledTime": "Horaire créé à",
        "buttonName": "Nom de l’[[alert]]",
        "emergencyButtonDashboard": "[[Alerts]] lancée par mobile"
    },
    "branding": {
        "branding": "Terminologie",
        "brandingDescription": "Remplacer certains termes dans le portail. L’utilisateur doit se déconnecter et se reconnecter pour voir les nouvelles conditions dans le portail.",
        "term": "Terme",
        "brandedTerm": "Marque",
        "brandedTermCamel": "Majuscules",
        "brandedTermLower": "Terme de marque en lettres minuscules",
        "brandedTermCamelPl": "Majuscules au Pluriel",
        "brandedTermLowerPl": "Minuscules au Pluriel",
        "brandedTerms": "Termes de marque",
        "addBrandedTerm": "Ajouter un terme",
        "brandingActivity": "Activité de marque",
        "language": "Langue"
    },
    "users": {
        "users": "Utilisateurs",
        "lastName": "Nom de famille",
        "firstName": "Prénom",
        "email": "Courriel",
        "role": "Rôle",
        "language": "Langue",
        "status": "Statut",
        "usersFilterPlaceHolder": "Affiner les résultats par : Nom ou courriel",
        "newUser": "Nouvel utilisateur",
        "existingUser": "Utilisateur existant",
        "isSelfRegistered": "Coché",
        "bulkResendInviteModel": {
            "header": "Renvoyer les invitations aux utilisateurs en attente",
            "body": "{{ x }} utilisateurs affichés sont en attente. Voulez-vous renvoyer les invitations uniquement aux utilisateurs en attente affichés ou à tous les utilisateurs en attente? L’envoi d’une nouvelle invitation invalidera toutes les invitations existantes.",
            "allPending": "Tous en attente",
            "displayedPending": "Affichage en attente"
        },
        "individualResendInviteModel": {
            "header": "Renvoyer l’invitation à l’utilisateur en attente",
            "body": "Êtes-vous sûr(e) de vouloir renvoyer l’invitation à ce {{ userName }}?",
            "text": "D’accord"
        },
        "invitationSentMsg": "L’invitation a été envoyée.",
        "invitationSentError": "Impossible de renvoyer l’invitation.",
        "invitationsSentMsg": "Des invitations ont été envoyées aux utilisateurs en attente désignés.",
        "invitationsSentError": "Impossible de renvoyer les invitations aux utilisateurs en attente désignés.",
        "resendInvite": "Renvoyer l’invitation",
        "export": {
            "allUsers": "Tous les utilisateurs",
            "displayedUsers": "Utilisateurs affichés",
            "header": "Exporter les utilisateurs",
            "body": "Voulez-vous exporter uniquement les utilisateurs {{ x }} affichés ou tous les utilisateurs?",
            "successMsg": "Exportation générée avec succès",
            "errorMsg": "Erreur lors de la génération de l’exportation",
            "fileName": "BulkUserUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Téléversement groupé d’utilisateurs",
            "msgBulkUploadInstructions": "Vous souhaitez ajouter ou modifier plusieurs utilisateurs? Choisir un fichier correctement formaté sur votre ordinateur pour démarrer le [[processes]].",
            "msgDropFile": "Déposer le fichier ici ou cliquer pour sélectionner un fichier",
            "lblSelectedFile": "Fichier sélectionné pour le téléversement",
            "lblNoFileSelected": "Aucun fichier précisé.",
            "lblNeedStartingPoint": "Point de départ nécessaire",
            "lblAddMultipleHeader": "Ajout de plusieurs nouveaux utilisateurs",
            "lblAddMultipleDetail": "Téléchargez cette feuille de calcul contenant uniquement les informations d’en-tête et remplissez les informations de votre nouvel utilisateur, un utilisateur par ligne, avant de la sauvegarder au format.CSV sur votre ordinateur.",
            "btnBlankFile": "Fichier utilisateur vide",
            "lblEditMultipleHeader": "Modifier ou supprimer plusieurs utilisateurs",
            "lblEditMultipleDetail": "Télécharger cette feuille de calcul contenant vos informations d’utilisateur actuelles et la modifier si nécessaire avant de la sauvegarder au format.CSV sur votre ordinateur.",
            "btnCurrent": "Utilisateurs actuels",
            "lblEditMultipleNotes": "REMARQUE : Pour supprimer des utilisateurs, modifiez leur statut en Supprimer. Les supprimer de la feuille de calcul ne les supprimera pas du système. Le téléchargement groupé peut être effectué même si vous ne remplissez pas tous les champs d'un utilisateur.",
            "lblUpload": "Envoyer",
            "btnCancel": "Annuler",
            "errorUserExportFailed": "Impossible d’exporter un fichier utilisateur.",
            "msgUserExportSuccessful": "L’exportation de l’utilisateur a été effectuée avec succès.",
            "msgTestBulkUploadFile": "Ce fichier sera validé uniquement car le nom du fichier contient le mot « test »; les mises à jour ne seront pas traitées.",
            "msgUploadSuccess": "Téléversement groupé réussi.",
            "msgExportSuccessful": "Exportation générée avec succès.",
            "errorExportFailed": "Impossible d’exporter le fichier.",
            "msgValidatingFile": "Vérification du fichier...",
            "errorInvalidBulkFileType": "Le fichier sélectionné a une extension de fichier incorrecte, il doit avoir une extension .CSV."
        }
    },
    "variableContentTag": {
        "insert": "Insérer",
        "companyName": "Nom de la société",
        "address": "Adresse",
        "startDate": "Date de début",
        "initiatedByName": "Initié par nom",
        "resolvedDate": "Date de résolution",
        "resolvedByName": "Résolu par nom",
        "comments": "Commentaires",
        "resolveReason": "Résoudre le motif",
        "resolveComments": "Résoudre les commentaires",
        "insertButtonTooltip": "Sélectionner une valeur dans la liste pour ajouter le paramètre au message. Les valeurs réelles seront remplacées lors de l’envoi du message."
    },
    "bulkUploadHistory": {
        "dateTimeSubmitted": "Date de soumission",
        "totalCount": "Total",
        "validCount": "Valide",
        "errorCount": "Erreurs",
        "originalInputFileName": "Fichier d’entrée",
        "canonicalCsvFileName": "Fichier de rapport",
        "errorCsvFileName": "Fichier d’erreur",
        "title": "Historique des téléversements groupés"
    },
    "home": {
        "accessDocuments": "Accéder aux documents",
        "gotoTraining": "Aller à la formation",
        "gotoResources": "Aller aux ressources",
        "incidentHistory": "Historique des incidents",
        "manageCrisisTeam": "Gérer l’équipe de crise",
        "callInAnnouncement": "Annonce d’appel",
        "conferenceCalls": "Conférence téléphonique",
        "crisisTeam": "Équipe de crise",
        "viewAllCrisisTeams": "Afficher toutes les équipes de crise",
        "cyber": "Cyber",
        "documents": "Documents",
        "emergencyContacts": "Contacts d’urgence",
        "featuredCourses": "Cours en vedette",
        "firstAid": "Premiers secours",
        "health": "santé",
        "incidentManagement": "[[Incident Manager]]",
        "incidents": "incidents",
        "learn": " Apprendre",
        "naturalDisasters": "Désastres naturels",
        "newIncident": "Nouvel incident",
        "recentNews": "Nouvelles récentes",
        "resources": "Ressources",
        "respondToIncident": "Répondre à l’incident",
        "sendEmergencyMessage": "Envoyer le message",
        "splashMessage": "Message de démarrage",
        "terrorism": "Terrorisme",
        "training": "Formation",
        "workplace": "Lieu de travail",
        "featureCoursesInstructions": "Suivre nos cours de formation en ligne pour développer vos connaissances et votre préparation.",
        "incidentOptions": "Communiquer efficacement avant, pendant et après un incident",
        "incidentMgmtSummary": "Accéder aux informations, outils et équipes essentiels",
        "resourceList": "MENACES, LISTES DE CONTRÔLE, SIMULATIONS ET PLUS",
        "resourceInstructions": "Parcourir nos informations détaillées pour découvrir la meilleure façon de vous préparer et de réagir efficacement et en toute sécurité.",
        "mainPhone": "Principal",
        "securityPhone": "Sécurité",
        "emergencyPhone": "Appel d’urgence",
        "announcementsPhone": "Annonces",
        "teamMembers": "Membres de l’équipe de crise ({{count}})",
        "viewCrisisTeamPage": "Afficher la page de l’équipe",
        "iq": "iQ",
        "complianceCalendar": "Calendrier de conformité",
        "plans": "[[Plans]]",
        "iqScore": "Score iQ",
        "reports": "[[Reports]]",
        "featuredResources": "Ressources sur les fonctionnalités",
        "announcement": "Annonce",
        "noneOnRecord": "Aucun enregistré",
        "messaging": "Messagerie",
        "sinkSendMessageDescription": "Envoyer des messages d’urgence bidirectionnels contenant des notifications vocales, par message texte, par courriel, par message instantané et sur le bureau.",
        "sinkAlertDescription": "Sélectionner les emplacements à mettre en état d’[[alert]] avec Preparis.",
        "communicationsManagement": "Gestion des communications",
        "communicationsManagementDescription": "Communiquer efficacement avant, pendant et après un incident",
        "resourcesDescription": "Parcourir nos informations détaillées pour découvrir la meilleure façon de vous préparer et de réagir efficacement et en toute sécurité",
        "viewResources": "Afficher les ressources",
        "resourcesSubtitle": "Menaces, listes de contrôle, simulations, etc.",
        "trainingDescription": "Suivre nos cours de formation en ligne pour développer vos connaissances et votre préparation",
        "moreInformation": "Plus d’informations",
        "sendNotifications": "Envoyer des notifications",
        "msgLoadingWeatherData": "Chargement des informations météo...",
        "viewDocuments": "Afficher les documents",
        "viewHistory": "Afficher l’historique",
        "bcPlanningDescription": "Planifier, tester et se préparer à un incident",
        "bcDashboardDescription": "Passer en revue les progrès de vos équipes ainsi que les objectifs de temps de récupération sélectionnés",
        "goToDashboard": "Aller au tableau de bord",
        "bcPlannerDescription": "Commençons la planification! Construire votre équipe d’experts",
        "startPlanning": "Commencer la planification",
        "agilityPlanner": "[[Planner]] Preparis",
        "buildYourPlan": "Construire votre plan",
        "documentsDescription": "Stocker la documentation importante dans un emplacement en nuage.",
        "bcIncidentManagement": "Signaler un Nouvel Incident",
        "bcIncidentManagementDescription": "Signalez et gérez rapidement votre incident",
        "bcIncidentHistoryDescription": "Afficher l'historique des incidents actuels ou passés",
        "safetyPassTitle": "Pass de sécurité",
        "safetyPassDescriptions": "Présélection numérique de la santé, vérification de l'entrée dans les installations, surveillance des données de sécurité et recherche des contacts étroits"
    },
    "documents": {
        "documents": "Documents",
        "lblName": "Nom",
        "lblOwner": "Propriétaire",
        "lblDateTimeModified": "Dernière modification",
        "fileDetails": "Détails du document",
        "folderDetails": "Détails du dossier",
        "lblSelectDocumentForDetail": "Sélectionner un fichier ou un dossier pour afficher ses détails",
        "lblSelectDocumentForPermission": "Sélectionner un dossier pour afficher ses autorisations",
        "lblType": "Type",
        "lblCreatedBy": "Propriétaire/Créé par",
        "lblCreatedOn": "Créé le",
        "lblLastModifiedBy": "Modifié par",
        "lblLastModifiedOn": "Modifié le",
        "lblSize": "Taille",
        "lblFolder": "Dossier",
        "lblDetails": "Détails",
        "lblPermissions": "Autorisations",
        "lblIsPrivate": "Document privé",
        "lblSetDocumentPrivate": "Définir le document comme privé",
        "lblCurrentPermissions": "Autorisations actuelles",
        "lblNewPermissions": "Nouvelles autorisations",
        "msgIsPrivate": "Ce document est privé et n’est accessible que par le propriétaire du document et l’administrateur du document",
        "lblPages": "Pages",
        "lblInherited": "(Hérité)",
        "successRenameDocumentMessage": "Les informations du document sont mises à jour",
        "successRenameFolderMessage": "Les informations du dossier sont mises à jour",
        "errorRenameDocumentMessage": "Échec de la mise à jour des informations du document",
        "errorRenameFolderMessage": "Échec de la mise à jour des informations du dossier",
        "errorDocumentNameExist": "Un document portant le même nom existe déjà, veuillez saisir un autre nom de document.",
        "errorFolderNameExist": "Un dossier du même nom existe déjà, veuillez saisir un nom de dossier différent.",
        "errorCheckDocumentStatus": "Impossible de vérifier le statut du document.",
        "errorCheckFolderStatus": "Impossible de vérifier le statut du dossier.",
        "msgErrorDownloadFileNoContent": "Le contenu du fichier est manquant ou corrompu!",
        "successCreateFolderMessage": "Le dossier {{ name }} est créé",
        "errorCreateFolderMessage": "Échec de la création du dossier {{ name }}",
        "lblAreYouSureDeleteDocument": "Êtes-vous sûr(e) que vous voulez supprimer ",
        "msgAccessDenied": "Vous ne disposez pas des autorisations nécessaires pour supprimer ce dossier",
        "msgCannotDeleteRoot": "Le dossier racine ne peut pas être supprimé",
        "msgFolderHasContents": "Ce dossier ne peut pas être éliminé car il n’est pas vide, veuillez éliminer tous les dossiers et les documents du dossier avant d’éliminer le dossier.",
        "successDeleteDocumentMessage": "Déposer {{ name }} A été supprimée.",
        "successDeleteFolderMessage": "Dossier {{ name }} A été supprimée.",
        "errorDeleteDocumentMessage": "Échec de la suppression du fichier {{ name }}",
        "errorDeleteFolderMessage": "Échec de la suppression du dossier {{ name }}",
        "successMoveFileMessage": "Le fichier {{ name1 }} est déplacé vers le dossier {{ name2 }}",
        "successMoveFolderMessage": "Le dossier {{ name1 }} est déplacé sous le dossier {{ name2 }}",
        "errorMoveFileMessage": "Échec du déplacement du fichier {{ name1 }} vers le dossier {{ name2 }}",
        "errorMoveFolderMessage": "Échec du déplacement du dossier {{ name1 }} sous le dossier {{ name2 }}",
        "noPermissionMoveFileMessage": "Vous ne pouvez pas déplacer ce fichier vers le dossier {{ name1 }}",
        "noPermissionMoveFolderMessage": "Vous ne pouvez pas déplacer ce dossier vers le dossier {{ name1 }}",
        "msgDocumentExists": "Un document portant le même nom existe déjà dans le dossier sélectionné, veuillez renommer le document ou sélectionner un autre document.",
        "msgFolderExists": "Un dossier du même nom existe déjà dans le dossier sélectionné, veuillez renommer le dossier ou sélectionner un dossier différent.",
        "successUploadFileMessage": "Le fichier {{ name }} est téléversé",
        "errorUploadFileMessage": "Échec du téléversement du fichier {{ name }}",
        "msgSameFolder": "Impossible de déplacer le dossier sous lui-même",
        "msgCrisisTeamPage": "Autoriser l’accès au document à partir de toutes les pages des équipes de crise suivantes.",
        "msgLoadingCrisisTeams": "Chargement des équipes de crise",
        "msgNoCrisisTeam": "Aucune équipe de crise",
        "successSetDocumentPrivateMsg": "La confidentialité du document a été mise à jour avec succès",
        "failSetDocumentPrivateMsg": "Échec de la mise à jour de la confidentialité du document",
        "successDeleteCrisisTeamMessage": "Le document est éliminé de la page d’équipe de crise pour {{ name }}",
        "errorDeleteCrisisTeamMessage": "Échec de l’élimination du document de la page d’équipe de crise pour {{ name }}",
        "successAddCrisisTeamsMessage": "Les équipes de crise sont ajoutées",
        "warningDocumentExist": "Une pièce jointe portant le même nom existe déjà, souhaitez-vous remplacer la pièce jointe existante ou la sauvegarder en tant que nouvelle pièce jointe?",
        "warningReplaceDocument": "Êtes-vous sûr(e) de vouloir remplacer le document existant par le document sélectionné?",
        "lblViewOnlyAccess": "Afficher accès uniquement",
        "lblEditAccess": "Modifier l’accès",
        "lblCrisisTeamPages": "Pages de l’équipe de crise",
        "errorAddCrisisTeamsMessage": "Échec de l’ajout des équipes de crise",
        "successSetDocumentAccessGroupsMessage": "L’autorisation d’accès aux documents est sauvegardée",
        "errorSetDocumentAccessGroupsMessage": "Échec de la sauvegarde de l’autorisation d’accès au document",
        "successSetFolderAccessGroupsMessage": "L’autorisation d’accès au dossier est sauvegardée",
        "errorSetFolderAccessGroupsMessage": "Échec de la sauvegarde de l’autorisation d’accès au dossier",
        "lblCrisisTeams": "Équipes de crise",
        "lblConfirmDeleteCrisisTeam": "Êtes-vous sûr(e) de vouloir éliminer ce document de la page de l’équipe de crise {{ name }}?",
        "lblConfirmPrivateDocument": "<p>Êtes-vous sûr(e) de vouloir rendre ce document privé?</p><p> Un document privé n’est accessible que par le propriétaire et l’utilisateur ayant le rôle d’administration des documents.</p>",
        "lblConfirmNonPrivateDocument": "<p>Êtes-vous sûr de vouloir que ce document hérite des autorisations d'accès du dossier qui le contient ?</p><p> Un document non privé héritera par défaut des groupes d’accès de son dossier.</p>",
        "lblMoveDocumentTo": "Déplacer vers",
        "lblNewFolder": "Créer un nouveau dossier",
        "lblDocumentDescription": "Description du document",
        "folderName": "Nom de dossier",
        "lblEveryoneHasAccess": "Vous avez choisi de donner à tout le monde l’accès en lecture à ce fichier",
        "lblViewAccessGroupsHeader": "Mettre à jour les autorisations ACCÈS UNIQUEMENT pour : {{ name }}",
        "lblEditAccessGroupsHeader": "Mettre à jour les autorisations MODIFICATION pour : {{ name }}",
        "lblViewAccessGroupInformation": "Sélectionner l’une des catégories ci-dessous ou en choisir une seule pour définir qui peut voir le fichier.",
        "lblEditAccessGroupInformation": "Sélectionner l’une des catégories ci-dessous ou en choisir une seule pour définir qui peut modifier le fichier.",
        "lblAccessFor": "Accorder l’accès aux utilisateurs présents",
        "lblAccessGroupNone": "Aucun utilisateur",
        "lblKeepPermissions": "Conserver les autorisations actuelles",
        "lblUseNewPermissions": "Utiliser de nouvelles autorisations",
        "noDocuments": "Aucun document",
        "msgPermissionChanges": "Le message relatif à l’autorisation modifiée s’affiche ici",
        "msgNoPermissionToMoveDocument": "Vous ne disposez pas des autorisations suffisantes pour déplacer vers ce dossier",
        "msgFolderItself": "Vous ne pouvez pas déplacer le dossier vers lui-même",
        "msgSameAsParentFolder": "C’est le même dossier parent",
        "msgKeepPermissionWarnings": "conserver les autorisations actuelles peut entraîner des conflits avec les nouvelles autorisations de dossier, ce qui peut rendre le document ou le dossier inaccessible aux utilisateurs, à l’exception des administrateurs de documents.",
        "msgFolderPermissionWarnings": "La modification des autorisations de ce dossier affectera les autorisations héritées de tous les fichiers du dossier, mais les autorisations de tous les sous-dossiers resteront les mêmes",
        "folderPath": "Chemin",
        "successUploadFilesMessage": "Los archivos se cargaron exitosamente",
        "errorUploadFilesMessage": "No se pudieron cargar archivos",
        "errorDocumentNameExistInReplace": "Un document portant le même nom existe déjà, veuillez télécharger un document portant un nom différent.",
        "lblReplaceTitle": "Remplacer le document",
        "fileType": {
            "unknown": "Inconnu",
            "MP3": "Fichier audio",
            "MP4": "Fichier audio",
            "BMP": "Image bitmap",
            "CSS": "Feuille de style CSS",
            "CSV": "Document Excel",
            "DOC": "Document Word",
            "DOCX": "Document Word",
            "HTM": "Fichier HTML",
            "HTML": "Fichier HTML",
            "HTMLS": "Fichier HTML",
            "ICO": "Image d’icône",
            "JPE": "Image jpeg",
            "JPEG": "Image jpeg",
            "JPG": "Fichier jpeg",
            "JS": "Fichier Javascript",
            "PDF": "Fichier PDF Acrobat",
            "PNG": "Image png",
            "GIF": "Image gif",
            "PPT": "Document Powerpoint",
            "RTF": "Fichier texte",
            "TEXT": "Document texte",
            "TIF": "Image Tif",
            "TIFF": "Image Tif",
            "TXT": "Document texte",
            "Unknown": "Inconnu",
            "WAV": "Wave Audio",
            "XLC": "Document Excel",
            "XLM": "Document Excel",
            "XLS": "Document Excel",
            "XLSX": "Document Excel",
            "XLT": "Document Excel",
            "PPTX": "Document PowerPoint",
            "XML": "Fichier XML",
            "ZIP": "Fichiers compressés",
            "RAR": "Fichiers compressés",
            "EXE": "Application"
        },
        "itemsCategories": {
            "image": "Image",
            "wordDocument": "Document Word",
            "spreadsheet": "Tableur",
            "document": "Document",
            "folder": "Dossier",
            "message": "Message",
            "audio": "l'audio",
            "text": "Texte"
        },
        "uploadNewDocument": "Télécharger un nouveau document",
        "title": "Documents",
        "searchPlaceholder": "Rechercher un document",
        "addNew": "Télécharger un nouveau document",
        "createFolder": "Nouveau dossier",
        "searchTitle": "Résultats de recherche",
        "or": "ou",
        "allUsers": "Tous les utilisateurs",
        "searchResults": "Résultats de recherche",
        "lblPermissionsHeader": "Autorisations de documents"
    },
    "myPreparis": {
        "lblMyPreparis": "Mon Preparis",
        "lblMyDocuments": "Mes documents",
        "lblMyBookmarks": "Mes signets",
        "lblDeleteBookmark": "Supprimer le signet",
        "lblNavigatePage": "Accéder à la page",
        "lblAreYouSureDeleteBookmark": "Êtes-vous sûr(e) de vouloir éliminer le favori {{ name }}?",
        "msgSuccessDeleteBookmark": "Le signet {{bookmark}} est supprimé",
        "msgErrorDeleteBookmark": "Échec de la suppression du signet {{bookmark}}",
        "lblSelectBookmarkForDetail": "Sélectionner un signet pour afficher ses détails",
        "lblBookmarkDetails": "Détails du signet",
        "lblNote": "Remarque",
        "bookmarkTypePage": "Page",
        "lblBookmarkOn": "Signet créé le",
        "msgSuccessUpdateBookmark": "Le signet {{ name }} est mis à jour",
        "msgErrorUpdateBookmark": "Échec de la mise à jour du signet {{ name }}"
    },
    "resources": {
        "resources": "Ressources",
        "threats": "Menaces",
        "tabletops": "Simulations",
        "checklists": "Listes de contrôle",
        "webinars": "Webinaires",
        "more": "Plus",
        "featuredThreats": "Menaces en vedette",
        "otherFeaturedResources": "Autres ressources en vedette",
        "byCategory": "Par catégorie",
        "sort": "Trier",
        "searchCurrentSelections": "Rechercher les sélections actuelles",
        "featured": "En vedette",
        "noResources": "Aucune ressource",
        "supportingResources": "Ressources de soutien",
        "relatedResources": "Ressources associées",
        "newResources": "Nouvelles ressources",
        "webinarsDescription": "Preparis organise régulièrement des webinaires sur les menaces les plus sérieuses concernant les sociétés et les bâtiments, créés par une équipe d’experts en la matière dans un large éventail de secteurs. Cliquer ci-dessous pour voir certaines de nos sessions les plus populaires et les plus récentes :",
        "threatsDescriptionOverview": "Informations de base sur un large éventail de menaces, y compris les signes ou les symptômes, les méthodes de préparation, les étapes de réponse et les directives de récupération.",
        "checklistsDescriptionOverview": "Des listes de contrôle basées sur des actions couvrant un éventail de menaces et de sujets de politique visant à vous aider à construire votre programme de continuité des activités.",
        "tabletopsDescriptionOverview": "Testez vos [[plans]] et votre niveau de préparation en groupe à l'aide de nos exercices de simulation.",
        "moreResourcesDescriptionOverview": "Apprenez-en davantage sur votre rôle et vos protocoles, et trouvez des informations et des outils supplémentaires qui peuvent vous aider pendant une crise, tels que des infographies, des modèles et des fiches de référence.",
        "webinarsDescriptionOverview": "Sessions de webinaires sur les menaces les plus critiques pour les sociétés et les bâtiments, créées par une équipe d'experts en la matière dans un large éventail de secteurs.",
        "inTheSpotLight": "À l'honneur"
    },
    "training": {
        "training": "Formation",
        "brandNewCourses": "Nouveaux cours",
        "featuredCourses": "Cours en vedette",
        "noNewCourses": "Aucun nouveau cours",
        "noFeaturedCourses": "Aucun cours en vedette",
        "noTrainingCourses": "Aucun cours de formation",
        "startedOn": "Commencé le",
        "completedOn": "Terminé le",
        "resume": "Reprendre",
        "retake": "Suivre à nouveau",
        "launch": "Lancer",
        "similarCourses": "Cours similaires",
        "noSimilarCourses": "Aucun cours similaire",
        "courseHistory": "Historique des cours",
        "firstAccessDate": "Première date d'accès",
        "lastAccessDate": "Date du dernier accès",
        "completedDate": "Date d’achèvement",
        "errorLaunchingTrainingCourse": "Une erreur est survenue lors du lancement de cette formation",
        "filter": "Filtre",
        "incomplete": "Inachevé",
        "completed": "Achevé",
        "inProgress": "En cours",
        "status": "Statut",
        "completionCertificate": "Certificat d'achèvement"
    },
    "portal": {
        "navigate": {
            "navigatePortal": "Naviguer sur le portail",
            "changePortal": "Changer de portail",
            "msgSwitchPortalFailed": "Échec du basculement vers le portail {{ portalName }}.",
            "msgSwitchPortalSucceed": "Basculement vers le portail {{ portalName }} réussi",
            "msgErrorRetrievingPortalList": "Échec de la récupération d'une liste de portails.",
            "portalName": "Nom du portail...",
            "searchResults": "Seuls les 50 premiers enregistrements sont affichés"
        },
        "modules": {
            "errorSavePortalModules": "Échec de la mise à jour des modules du portail.",
            "msgSavePortalSettingsSuccess": "Les [[settings]] du portail sont sauvegardés.",
            "lblModulesSetup": "Configuration des modules",
            "lblAlwaysAllowed": "Toujours autorisé",
            "lblAllowed": "Autorisé",
            "lblNotAllowed": "Non autorisé"
        },
        "portal": "Portail",
        "portals": "Portails",
        "newPortal": "Nouveau portail",
        "name": "Nom",
        "parentName": "Nom du parent",
        "parentPortalName": "Nom du portail parent",
        "verticalType": "Type vertical",
        "portalSubdomain": "Sous-domaine du portail",
        "portalName": "Nom du portail",
        "numberOfUsersAssignedTo": "N<sup>bre</sup> d’utilisateurs affectés à",
        "callInNumberSetup": "Configuration du numéro d’appel",
        "childPortal": "Portail enfant",
        "modulesSetup": "Configuration des modules",
        "portalsFilterPlaceHolder": "Affiner les résultats par : Nom ou parent",
        "errorLoadingPortals": "Erreur lors du chargement de la liste des portails",
        "createPortal": "Créer un portail",
        "createChildPortal": "Créer un portail enfant",
        "savePortalSuccess": "Le portail a été sauvegardé avec succès",
        "savePortalError": "Les modifications du portail n’ont pas été sauvegardées",
        "industry": "Secteur",
        "callInNumber": {
            "errorRegisteringNewNumber": "Un problème est survenu lors de l’enregistrement de votre nouveau numéro.",
            "successRegisteringNewNumber": "Le nouveau numéro a été enregistré avec succès.",
            "errorDeregisteringExistingNumber": "Un problème est survenu lors du désenregistrement de votre ancien numéro.",
            "phoneNumberReleaseSuccess": "{{ currentPhoneNumber }} a été publié.",
            "modalTitle": "Configuration des annonces d’appel",
            "newTelephoneNumberInstructions": "Configurer un nouveau numéro de téléphone pour les annonces d’appel.",
            "editTelephoneNumberInstructions": "Modifiez votre numéro de téléphone pour les annonces d’appel.",
            "announcementsNumber": "Numéro d’annonces",
            "loading": "Chargement",
            "releaseNumber": "Publier le numéro",
            "noNumberAssigned": "Aucun numéro attribué",
            "availableNumbers": "Numéros disponibles",
            "chooseFromAvailableNumbers": "Choisir parmi les numéros de téléphone suivants :",
            "saveAndUpdate": "Sauvegarder et mettre à jour",
            "current": "Actuel",
            "new": "Nouveau",
            "selectAvailableNumber": "Sélectionner un numéro disponible ci-dessous",
            "containsNumber": "Contient",
            "country": "Pays",
            "areaCode": "Indicatif régional",
            "numberType": "Type de numéro",
            "local": "Local",
            "tollFree": "Gratuit",
            "phoneNumberContains": "Saisir les chiffres (ou les caractères) souhaités",
            "search": "Rechercher",
            "saveSuccessMsg": "Nouveau numéro d’appel sauvegardé pour {{ portalName }}",
            "configureNewNumber": "Configurer un nouveau numéro"
        },
        "portalSetting": {
            "portalSettings": "[[Settings]] du portail",
            "filterSettingsPlaceHolder": "Filtrer sur le nom du [[setting]]...",
            "settingName": "Nom",
            "settingValue": "Valeur",
            "portalSpecific": "Spécifique au portail",
            "showAllSettings": "Afficher tous les [[settings]]",
            "saveSuccess": "Les [[settings]] du portail sont sauvegardés.",
            "saveError": "Échec de la mise à jour des [[settings]] du portail."
        },
        "portalIndustry": "Secteur",
        "lblSelectIndustry": "Veuillez sélectionner un secteur",
        "msgFailSaveIndustry": "Échec de la sauvegarde du secteur du portail"
    },
    "scheduledMessages": {
        "scheduledMessages": "Messages programmés",
        "searchScheduledMessagesPlaceholder": "Rechercher des messages programmés",
        "newScheduledMessage": "Nouveau message programmé",
        "dateCreated": "Date créée",
        "scheduledDate": "Date prévue",
        "scheduledTime": "Heure prévue",
        "timeZone": "Fuseau horaire",
        "createdBy": "Créé par",
        "errorLoadingScheduledMessagesMsg": "Erreur lors du chargement des messages programmés.",
        "deleteScheduledMessageTooltip": "Supprimer le message programmé",
        "confirmDelete": {
            "header": "Éliminer le message programmé",
            "body": "Êtes-vous sûr(e) de vouloir éliminer ce message programmé?",
            "text": "Éliminer"
        },
        "noScheduledMessages": "Aucun message programmé"
    },
    "manage": {
        "nav": {
            "lblOverview": "Aperçu",
            "lblManage": "Gérer",
            "lblSetup": "Configuration",
            "lblMessaging": "Messagerie",
            "lblSystem": "Système",
            "lblRoles": "Rôles",
            "lblUsers": "Utilisateurs",
            "lblLocations": "[[Locations]]",
            "lblDepartments": "[[Departments]]",
            "lblGroups": "Groupes",
            "lblTenants": "Locataires",
            "lblGeofences": "Géo-repérages",
            "lblPOIs": "Point d’intérêt",
            "lblMessageSettings": "[[Settings]] de messagerie",
            "lblUserInitiatedIncident": "Incidents déclenchés par l’utilisateur",
            "lblIncidentTypes": "Types d’incidents",
            "lblAlertSubscription": "Abonnements aux [[alerts]]",
            "lblIncidentTriggers": "Déclencheurs d’incidents",
            "lblPanicConfiguration": "Configuration de panique",
            "lblMobileEmergencyButtons": "[[Settings]] d’[[alert]] lancée par mobile",
            "lblCompanyProfile": "Profil de la société",
            "lblSecurity": "Sécurité",
            "lblSystemEmails": "Courriels système",
            "lblCustomFields": "Champs personnalisés",
            "lblBranding": "Personnalisation",
            "lblSelfRegistration": "Activer la réception",
            "lblConfigureBulkUpload": "Configurer le téléchargement groupé"
        },
        "manageEntitySidebar": {
            "lblNA": "S. O.",
            "lblCreatedOn": "Créé le",
            "lblCreatedBy": "Créé par",
            "lblLastModifiedOn": "Dernière modification le",
            "lblLastModifiedBy": "Dernière modification par",
            "lblUserCount": "N<sup>bre</sup> d’utilisateurs"
        },
        "manageMembers": {
            "manageMembers": "Gérer les membres",
            "lblManageMembersHeader": "Gérer les membres pour {{entityTypeName}}",
            "lblManageMembersDirections": "Sélectionner les utilisateurs, puis utiliser les flèches gauche et droite pour configurer qui fait partie de ce {{entityTypeName}}.",
            "btnSaveAndUpdate": "Sauvegarder et mettre à jour",
            "updateMembersSuccess": "Membres mis à jour avec succès",
            "usersNotInEntity": "Utilisateurs absents de {{entityTypeName}}",
            "currentEntityMembers": "Membres actuels dans {{entityTypeName}}"
        },
        "bulkUpload": {
            "lblBulkUpload": "Téléchargement groupé",
            "lblBulkUploadUsers": "Téléchargement groupé d’utilisateurs",
            "msgBulkUploadInstructions": "Vous souhaitez voulez-vous ajouter ou modifier plusieurs {{entityTypeName}}s? Choisissez un fichier correctement formaté sur votre ordinateur pour démarrer le [[processes]].",
            "msgDropFile": "Déposer le fichier ici ou cliquer pour sélectionner un fichier",
            "lblSelectedFile": "Fichier sélectionné pour le téléchargement",
            "lblNoFileSelected": "Aucun fichier spécifié.",
            "lblNeedStartingPoint": "Point de départ nécessaire",
            "lblAddMultipleUsersHeader": "Ajout de plusieurs nouveaux utilisateurs",
            "lblAddMultipleUsersDetail": "Téléchargez cette feuille de calcul contenant uniquement les informations d’en-tête et remplissez les informations de votre nouvel utilisateur, un utilisateur par ligne, avant de la sauvegarder au format.CSV sur votre ordinateur.",
            "btnBlankUserFile": "Fichier utilisateur vide",
            "lblUpload": "Envoyer",
            "btnCancel": "Annuler",
            "errorUserExportFailed": "Impossible d’exporter un fichier utilisateur.",
            "msgUserExportSuccessful": "L’exportation de l’utilisateur effectuée avec succès.",
            "msgTestBulkUploadFile": "Ce fichier sera validé uniquement car le nom du fichier contient le mot « test »; les mises à jour ne seront pas traitées.",
            "msgUploadUsersSuccess": "Téléchargement groupé réussi.",
            "msgValidatingFile": "Vérification du fichier...",
            "errorInvalidBulkFileType": "Le fichier sélectionné a une extension de fichier incorrecte, il doit avoir une extension .CSV.",
            "btnBlankEntitiesFile": "Fichier {{entityTypeName}} vide",
            "lblEditMultipleEntitiesDetail": "Téléchargez cette feuille de calcul contenant vos informations d’[[location]] actuelles et modifiez-la si nécessaire avant de la sauvegarder au format .CSV sur votre ordinateur.",
            "lblEditMultipleEntitiesHeader": "Modifier ou supprimer plusieurs {{entityTypeName}}s",
            "lblEditMultipleEntitiesNotes": "REMARQUE : Supprimer {{entityTypeName}}s, changer leur statut à Supprimer. Les éliminer de la feuille de calcul ne les supprimera pas du système. Le téléchargement groupé peut être effectuée même si vous ne remplissez pas tous les champs d’un {{entityTypeName}}. Pour voir quels champs sont facultatifs, reportez-vous à votre guide de l’utilisateur ou à la zone d’aide dans le coin supérieur droit de cette fenêtre.",
            "btnCurrentEntities": "{{entityTypeName}}s actuels",
            "lblAddMultipleEntitiesHeader": "Ajout de plusieurs nouveaux {{entityTypeName}}",
            "lblAddMultipleEntitiesDetail": "Téléchargez cette feuille de calcul contenant uniquement les informations d’en-tête et remplissez les informations de votre nouveau {{entityTypeName}}, un {{entityTypeName}} par ligne, avant de la sauvegarder au format .CSV sur votre ordinateur.",
            "msgExportSuccessful": "Exportation terminée avec succès.",
            "errorExportFailed": "Impossible d’exporter un fichier utilisateur.",
            "fileName": "Téléchargement{{entityTypeName}}groupé de .csv"
        },
        "roles": {
            "btnCreateRole": "Nouveau rôle",
            "btnSaveRole": "Sauvegarder le rôle",
            "errorCannotDeleteWithUsers": "Ce rôle ne peut pas être supprimé car il y a des utilisateurs avec ce rôle.",
            "errorCannotDeleteDefaultForBulkUpload": "Ce rôle ne peut pas être supprimé car il est défini comme rôle par défaut pour le téléchargement groupé.",
            "errorDeleteRoleFailed": "Une erreur s’est produite. Supprimer le rôle.",
            "errorRequiredFields": "Veuillez remplir tous les champs obligatoires.",
            "errorSaveRoleFailed": "Une erreur s’est produite lors de la sauvegarde du rôle.",
            "errorUnableToLoadRole": "Impossible de charger les détails du rôle, veuillez réessayer plus tard.",
            "lblAccessReports": "Accéder aux [[reports]]",
            "lblAccessReportsDesc": "Autoriser les utilisateurs à exécuter et à exporter des [[reports]].",
            "lblAddUploadDocs": "Ajouter/télécharger des documents",
            "lblAddUploadOnly": "Ajouter/télécharger uniquement",
            "lblAddUploadOnlyDesc": "Autoriser les utilisateurs à ajouter/télécharger UNIQUEMENT de nouveaux documents et à affecter des autorisations à ces documents.",
            "lblAllowed": "Autorisé",
            "lblAlwaysAllowed": "Toujours autorisé",
            "lblAreYouSureRemoveRole": "Êtes-vous sûr(e) de vouloir éliminer ce rôle?",
            "associatedRole": "Rôle associé",
            "lblBasedOnRole": "Basé sur le rôle",
            "lblReportIncident": "Signaler un incident",
            "lblReportIncidentDesc": "Permettre à l’utilisateur de signaler des incidents depuis l’application mobile",
            "lblPanicDesc": "Permettre à l’utilisateur de signaler des incidents de panique depuis l’application mobile",
            "lblManageMessages": "Gérer les messages",
            "lblManageMessagesDesc": "Autoriser l'utilisateur à modifier, déplacer, supprimer un message et configurer les autorisations des dossiers",
            "lblCommunication": "Communication",
            "lblSendMessageDesc": "Autoriser les utilisateurs à envoyer un message.",
            "lblConfigureMessageDesc": "Autoriser les utilisateurs à créer et à mettre à jour le message mais pas à envoyer le message.",
            "lblConferenceCallDesc": "Autoriser les utilisateurs à créer une conférence téléphonique.",
            "lblCallInAnnouncementDesc": "Autoriser les utilisateurs à créer une annonce d’appel.",
            "lblSplashMessageDesc": "Autoriser les utilisateurs à créer un message de démarrage.",
            "lblCompanySettings": "[[Settings]] de la société",
            "lblCompanySettingsDesc": "Gérer les [[settings]] qui s’appliquent à l’ensemble de la société",
            "lblCompanySettingsExplain": "Autoriser les utilisateurs à modifier le nom et l’adresse électronique de la société, le numéro d’affichage vocal, la marque, les [[settings]] de sécurité et les courriels système.",
            "lblCorePerms": "Autorisations principales (non modifiables)",
            "lblCrisisTeamContacts": "Contacts de l’équipe de crise",
            "lblDocumentMgmt": "Administration des documents",
            "lblDocumentMgmtDesc": "Permettre aux utilisateurs de gérer globalement tous les documents du portail, y compris la possibilité d’ajouter, de modifier et de supprimer n’importe quel document ainsi que de créer, modifier et supprimer des dossiers.",
            "lblDocumentMgmtiQDisclaimer": "L’autorisation iQ est requise pour accéder aux dossiers et aux documents liés à iQ dans le portail.",
            "lblDocuments": "Documents",
            "lblEditEntities": "Modifier les entités",
            "lblEntityMgmt": "Gestion d’entités",
            "lblEntityMgmtExplain": "Autoriser les utilisateurs à créer/modifier/supprimer des [[locations]], des groupes et des [[departments]].",
            "lblHelp": "Aide",
            "lblIncidentMgmt": "Gestion des incidents",
            "lblIncidentMgmtDesc": "Permettre aux utilisateurs de créer/modifier/résoudre des incidents.",
            "lblIncludeCrisisDescription": "Afficher les coordonnées des utilisateurs ayant ce rôle sur la page Équipe de crise de chaque [[location]].",
            "lblIncludeCrisisTeamPage": "Inclure sur la page Équipe de crise",
            "lblManagePlansDesc": "Allow users to view, activate, deactivate, standby, or stand down department [[plans]]",
            "lblViewPlansDesc": "Provide access to users to view available department [[plans]] and processes",
            "lblListItems": "articles",
            "lblLocalEmergencyContact": "Contact d’urgence local",
            "lblLoginAccess": "Accès à la connexion",
            "lblManage": "GÉRER",
            "lblManageDocuments": "Gérer les documents",
            "lblManagePortal": "Gérer le portail",
            "lblManageUsers": "Gérer les utilisateurs",
            "lblMngIncidents": "Gérer les incidents",
            "lblAlerts": "[[Alerts]]",
            "lblIncidentManagement": "[[Incident Manager]]",
            "lblManagePlans": "Gestion du plan",
            "lblViewPlans": "Voir seulement",
            "lblLiteMessaging": "Messagerie simplifiée",
            "lblMsgSettings": "[[Settings]] de message",
            "lblMsgSetup": "Configuration de la messagerie",
            "lblMsgSetupDesc": "Autoriser les utilisateurs à créer des messages sauvegardés et à définir les valeurs par défaut des messages et les escalades.",
            "lblMyPreparis": "Mon Preparis",
            "lblMyProfile": "Mon profil",
            "lblNamePlaceholder": "Saisir un nom pour ce nouveau rôle",
            "lblNeverAllowed": "Jamais autorisé",
            "lblNewRoleTitle": "Nouveau rôle personnalisé",
            "lblNone": "Aucun",
            "lblNotAllowed": "Non autorisé",
            "lblNumbersDisplayed": "de",
            "permissionAndFeatures": "Autorisations et fonctionnalités",
            "lblReports": "[[Reports]]",
            "lblResources": "Ressources",
            "lblRoleFeaturesPermissions": "Fonctionnalités et autorisations du rôle",
            "lblRoleList": "Rôles",
            "roleName": "Nom du rôle",
            "lblSearch": "Rechercher",
            "lblSelectRoleType": "- Sélectionner un rôle -",
            "lblSendMessages": "Envoyer des messages",
            "lblSendMessagesDesc": "Autoriser les utilisateurs à envoyer des messages d’urgence",
            "lblConfigureMessages": "Configurer les messages",
            "lblConferenceCalls": "Créer des conférences téléphoniques",
            "lblCallInAnnouncements": "Créer des annonces d’appel",
            "lblSplashMessages": "Créer des messages de démarrage",
            "lblUserInitiateIncident": "Incident déclenché par l’utilisateur",
            "lblPanic": "Panique déclenchée par l’utilisateur",
            "lblStatus": "Statut",
            "lblTraining": "Formation",
            "lblTrainingClasses": "Cours de formation",
            "lblTrainingClassesDesc": "Permettre aux utilisateurs de consulter et de suivre des cours de formation.",
            "lblType": "Type",
            "lblUserCannotLogin": "Les utilisateurs ne peuvent pas se connecter.",
            "userCount": "N<sup>bre</sup> d’utilisateurs",
            "lblUserMgmtDesc": "Gestion des utilisateurs",
            "lblUserMgmtExplain": "Autoriser les utilisateurs à créer/modifier/supprimer des utilisateurs et des rôles.",
            "lblRoleMgmtDesc": "Gestion des rôles",
            "lblRoleMgmtExplain": "Autoriser les utilisateurs à créer/modifier/supprimer des rôles.",
            "lblUserReceivesMessages": "Les utilisateurs peuvent uniquement recevoir des messages.",
            "lblViewEditDocs": "Afficher et modifier des documents",
            "lblViewEditDocsDesc": "Remarque : Accès individuel aux documents en fonction des droits affectés à chaque document individuel",
            "lblViewReports": "Afficher les [[reports]]",
            "lblViewTraining": "Afficher la formation",
            "viewPermissions": "Afficher les autorisations et les fonctionnalités",
            "lbliQ": "iQ",
            "lbliQAdmin": "Administration iQ",
            "lbliQAdminDesc": "Permettre aux utilisateurs de gérer tous les champs d’application, équipes, membres d’équipe, ressources d’équipe et fonctionnalités d’administration dans iQ",
            "lbliQManagement": "Gestion iQ",
            "lbliQManagementDesc": "Permettre aux utilisateurs d’afficher toutes les champs d’application, de gérer toutes les équipes, les membres de l’équipe et les ressources de l’équipe sans fonctionnalités d’administration dans iQ",
            "lbliQTeamAccess": "Accès à l’équipe iQ",
            "lbliQTeamAccessDesc": "Autoriser les utilisateurs à gérer les membres de l’équipe et les ressources d’équipe pour les équipes associées dans iQ",
            "lbliQAssignmentAccess": "Accès aux affectations iQ",
            "lbliQAssignmentAccessDesc": "Autoriser les utilisateurs à gérer les ressources d’équipe affectées uniquement dans iQ",
            "lblBusinessContinuity": "Continuité des activités",
            "lblContinuity": "Continuité",
            "lblBusinessContinuityManagement": "Gestion de la continuité des activités",
            "lblBusinessContinuityManagementDesc": "Permettre aux utilisateurs de démarrer une évaluation de la continuité des activités et d’affecter des chefs d’équipe",
            "lblBusinessContinuityAudit": "Affichage de la continuité des activités uniquement",
            "lblBusinessContinuityAuditDesc": "Permettre aux utilisateurs d’afficher toutes les informations du plan de continuité des activités sans apporter de modifications",
            "msgDeleteRoleSuccess": "Le rôle a été supprimé avec succès.",
            "msgSaveRoleSuccess": "Rôle {{roleBundleName}} a été enregistré avec succès.",
            "phSearchRoles": "Affiner les résultats par : Nom de rôle",
            "msgErrorUpdateiQRole": "Impossible de modifier l’autorisation iQ car les utilisateurs avec ce rôle sont responsables des équipes iQ",
            "msgErrorRemoveiQRole": "Impossible d’éliminer l’autorisation iQ car les utilisateurs avec ce rôle ont des affectations iQ",
            "roles": "Rôles",
            "lblRoleName": "Nom du rôle",
            "msgErrorDuplicateRoleName": "Un rôle existe déjà avec ce nom",
            "role": "Rôle",
            "removeRole": "Éliminer le rôle",
            "lblRecoverProfiles": "Profils de récupération",
            "lblViewRecoverProfiles": "Afficher les profils de récupération",
            "lblViewRecoverProfilesDesc": "Autoriser les utilisateurs à afficher les profils de récupération",
            "lblEditRecoveryProfiles": "Modifier les profils de récupération",
            "lblEditRecoveryProfilesDesc": "Autoriser les utilisateurs à modifier les profils de récupération",
            "lblEditRecoveryProfileContacts": "Modifier les contacts du profil de récupération",
            "lblEditRecoveryProfileContactsDesc": "Autoriser les utilisateurs à modifier les contacts du profil de récupération",
            "lblEditRecoveryProfileGenerators": "Modifier les générateurs de profils de récupération",
            "lblEditRecoveryProfileGeneratorsDesc": "Autoriser les utilisateurs à modifier les générateurs de profils de récupération",
            "lblEditRecoveryProfileSites": "Modifier les sites de profil de récupération",
            "lblEditRecoveryProfileSitesDesc": "Autoriser les utilisateurs à modifier les sites de profil de récupération"
        },
        "locations": {
            "btnLocationCreate": "Ajouter un nouvel [[location]]",
            "btnMultiLocationCreate": "Téléchargement groupé",
            "btnSaveLocation": "Sauvegarder l’[[location]]",
            "btnViewAll": "Tout afficher",
            "duplicateLocationName": "Un [[location]] avec le même nom existe déjà.",
            "errorLocationReferenced": "L’[[location]] est référencé comme [[location]] principal par les utilisateurs",
            "errorRemoveLocationFailed": "Une erreur s’est produite lors de l’élimination de l’[[location]].",
            "errorSaveLocationFailed": "Une erreur s’est produite lors de la sauvegarde de l’[[location]].",
            "lblAddressTitle": "Adresse physique",
            "lblAreYouSureRemoveLocation": "Êtes-vous vous sûr(e) de vouloir éliminer cet [[location]]?",
            "lblEmergencyPhone": "Numéro d’appel d’urgence",
            "lblFax": "Numéro de télécopieur",
            "lblListItems": "articles",
            "lblLocationActivity": "Activité de l’[[location]]",
            "lblLocationInfo": "Informations sur l’[[location]]",
            "lblLocationListTitle": "[[Locations]]",
            "lblLocationMembers": "Utilisateurs associés à cet [[location]]",
            "lblLocationName": "Nom de l’[[location]]",
            "lblMainPhone": "Numéro de téléphone principal",
            "lblNewLocation": "Nouvel [[location]]",
            "lblNumbersDisplayed": "de",
            "lblSecurityPhone": "Numéro de sécurité",
            "lblUserCount": "N<sup>bre</sup> d’utilisateurs",
            "lblConfirmUpdateGeofence": "Mettre à jour le géo-repérage",
            "msgUpdateGeofence": "L’adresse de l’[[location]] a changé, souhaitez-vous mettre à jour le géo-repérage de cet [[location]]?",
            "menuSortByName": "Nom",
            "msgBulkUploadSubmitted": "Le téléchargement groupé a été envoyé pour traitement.",
            "msgRemoveLocationSuccess": "L’[[location]] a été éliminé avec succès.",
            "msgSaveLocationSuccess": "L’[[location]] {{entityName}} a été enregistré avec succès.",
            "phSearchLocations": "Affiner les résultats par : Nom, adresse, ville, province ou État",
            "name": "Nom",
            "address": "Adresse",
            "city": "Ville",
            "stateProvinceRegion": "Province/État/région",
            "country": "Pays",
            "lblAddress2": "Adresse 2 (suite, bâtiment, etc.)",
            "lblState": "Province/État/région",
            "lblZip": "Code postal/Zip",
            "email": "Courriel",
            "status": "Statut",
            "editGeofence": "Modifier le géo-repérage",
            "createGeofence": "Créer un géo-repérage",
            "deleteLocation": "Éliminer l’[[location]]",
            "saveAndFinish": "Sauvegarder et terminer",
            "removeLocation": "Éliminer l’[[location]]",
            "lblAvailableSeats": "Places libres",
            "lblNotes": "Remarques",
            "lblEmergencyReadiness": "Préparation aux Situations D'urgence",
            "lblLocationType": "Type de lieu",
            "selectEmergencyReadiness": "Sélectionnez une Préparation D'urgence",
            "selectLocationType": "Sélectionnez un Type D'[[location]]"
        },
        "groups": {
            "bulkUpload": "Téléchargement groupé",
            "btnGroupCreate": "Ajouter un nouveau groupe",
            "btnSaveGroup": "Sauvegarder le groupe",
            "btnSaveGroupAddMember": "Sauvegarder et ajouter des membres",
            "duplicateGroupName": "Un groupe ayant le même nom existe déjà.",
            "errorRemoveGroupFailed": "Une erreur s’est produite lors de l’élimination du groupe.",
            "errorRequiredFields": "Veuillez remplir tous les champs obligatoires.",
            "errorSavGroupFailed": "Une erreur s’est produite lors de la sauvegarde du groupe.",
            "lblAreYouSureDeleteGroup": "Êtes-vous sûr(e) de vouloir supprimer ce groupe?",
            "lblGroupActivity": "Activité du groupe",
            "lblGroupMembers": "Membres du groupe",
            "lblGroupName": "Nom du groupe",
            "lblListItems": "articles",
            "lblNewGroup": "Nouveau groupe",
            "lblNumbersDisplayed": "de",
            "lblType": "Type de groupe",
            "lblUserCount": "N<sup>bre</sup> d’utilisateurs",
            "menuSortByName": "Nom",
            "msgBulkUploadSubmitted": "Le téléchargement groupé a été envoyé pour traitement.",
            "msgRemoveGroupSuccess": "Le groupe a été éliminé avec succès.",
            "msgSaveGroupSuccess": "Le groupe {{entityName}} a été sauvegardé avec succès.",
            "phSearchGroups": "Affiner les résultats par : Nom ou description",
            "msgGroupInUsedByUserInitiatedMessages": "Ce groupe est actuellement utilisé pour les incidents déclenchés par l’utilisateur. Veuillez dissocier avant de supprimer ce groupe.",
            "groups": "Groupes",
            "name": "Nom",
            "description": "Description",
            "group": "Groupe",
            "groupType": {
                "groupType": "Type de groupe",
                "1": "Continuité des activités",
                "2": "Gestion des incidents de la société",
                "3": "Gestion locale des incidents",
                "4": "Récupération après sinistre",
                "Other": "Autre",
                "5": "Autre"
            },
            "saveAndFinish": "Sauvegarder et terminer",
            "deleteGroup": "Supprimer le groupe",
            "pleaseSelect": "Veuillez sélectionner"
        },
        "departments": {
            "btnMultiDepartmentCreate": "Téléchargement groupé",
            "btnDepartmentCreate": "Ajouter un nouveau [[department]]",
            "btnSaveDepartment": "Sauvegarder le [[department]]",
            "btnSaveDepartmentAddMember": "Sauvegarder et ajouter un membre",
            "duplicateDepartmentName": "Un [[department]] ayant le même nom existe déjà.",
            "errorRemoveDepartmentFailed": "Une erreur s’est produite lors de la suppression du [[department]].",
            "errorRequiredFields": "Veuillez remplir tous les champs obligatoires.",
            "errorSavDepartmentFailed": "Une erreur s’est produite lors de la sauvegarde du [[department]].",
            "lblAreYouSureDeleteDepartment": "Êtes-vous sûr(e) de vouloir supprimer ce [[department]]?",
            "lblDepartmentActivity": "Activité du [[department]]",
            "lblDepartmentListTitle": "[[Departments]]",
            "lblDepartmentMembers": "Membres du [[department]]",
            "lblDepartmentName": "Nom du [[department]]",
            "lblDepartmentTitle": "Informations du [[department]]",
            "lblListItems": "articles",
            "lblNewDepartment": "Nouveau [[department]]",
            "lblNumbersDisplayed": "de",
            "lblUserCount": "N<sup>bre</sup> d’utilisateurs",
            "menuSortByName": "Nom",
            "msgBulkUploadSubmitted": "Le téléchargement groupé a été envoyé pour traitement.",
            "msgRemoveDepartmentSuccess": "Le [[department]] a été éliminé avec succès.",
            "msgSaveDepartmentSuccess": "Le [[department]] {{entityName}} a été sauvegardé avec succès.",
            "phSearchDepartments": "Affiner les résultats par : Nom ou description",
            "departments": "[[Departments]]",
            "department": "[[Department]]",
            "name": "Nom",
            "description": "Description",
            "deleteDepartment": "Supprimer le [[department]]",
            "unassociated": "Pas associé",
            "renameAndAssociateDepartmentToFAConfirmation": {
                "header": "Renommer et associer",
                "body": "Êtes-vous sûr de vouloir renommer le [[Planner]] [[Department]] et créer l'association ?"
            },
            "lblBCPortalFunctionalAreaAssociated": "[[Planner]] [[Department]] associés",
            "createNewPortalFunctionalAreaTooltip": "Créer un nouveau [[Planner]] [[Department]] et associer à ce [[department]]",
            "createNewPlannerFunctionalArea": "Créer un nouveau département de planificateur"
        },
        "tenants": {
            "btnAddBuilding": "Ajouter un bâtiment",
            "btnAddTenant": "Ajouter un nouveau locataire",
            "btnMultiTenantCreate": "Téléchargement groupé",
            "btnSaveTenantAddBuilding": "Sauvegarder et ajouter un bâtiment",
            "duplicateTenantName": "Un locataire ayant le même nom existe déjà",
            "errorRemoveTenantFailed": "Une erreur s’est produite lors de la suppression du locataire.",
            "errorSaveTenantFailed": "Une erreur s’est produite lors de la sauvegarde du locataire.",
            "lblAddBuilding": "Ajouter un bâtiment",
            "lblBuilding": "Bâtiment",
            "lblBuildings": "Bâtiments",
            "lblDeleteTenant": "Supprimer le locataire",
            "lblDeleteTenantModalMessage": "Êtes-vous sûr(e) de vouloir supprimer ce locataire?",
            "lblFloor": "Étage(s)",
            "lblNewTenant": "Nouveau locataire",
            "lblRemoveBuilding": "Éliminer le bâtiment",
            "lblRemoveBuildingModalMessage": "Êtes-vous sûr(e) de vouloir éliminer ce bâtiment?",
            "lblSelectBuilding": "Sélectionner le bâtiment",
            "lblTenantName": "Nom du locataire",
            "msgBulkUploadSubmitted": "Le téléchargement groupé a été envoyé pour traitement.",
            "msgRemoveBuildingFailed": "Erreur lors de l’élimination du bâtiment",
            "msgRemoveBuildingSuccess": "Le bâtiment a été éliminé avec succès",
            "msgRemoveTenantSuccess": "Le locataire a été éliminé avec succès",
            "msgSaveTenantSuccess": "Le locataire {{entityName}} a été sauvegardé avec succès.",
            "msgUserAssociatedWithThisBuilding": "Ce bâtiment ne peut pas être éliminé car des utilisateurs sont associés à ce bâtiment",
            "phSearchTenants": "Affiner les résultats par : Nom ou description",
            "lblMainPhoneNumber": "Numéro de téléphone principal",
            "lblEditBuilding": "Modifier le bâtiment",
            "errorUsersAssociation": "Des utilisateurs sont associés à ce locataire. Impossible de supprimer ce locataire pour le moment.",
            "errorBuildingAssociation": "Il y a des bâtiments associés à ce locataire. Impossible de supprimer ce locataire pour le moment.",
            "tenants": "Locataires",
            "name": "Nom",
            "description": "Description",
            "tenantMembers": "Membres du locataire",
            "tenantActivity": "Activité du locataire",
            "tenant": "Locataire"
        },
        "geofences": {
            "geofences": "Géo-repérages",
            "btnGeofenceCreate": "Créer un nouveau géo-repérage",
            "name": "Nom",
            "type": "Type",
            "location": "Emplacement",
            "circle": "Cercle",
            "rectangle": "Rectangle",
            "polygon": "Polygone"
        },
        "overview": {
            "btnNew": "Nouveau",
            "btnViewAll": "voir tout",
            "lblCompanyProfile": "Profil de la société",
            "lblManage": "Gérer",
            "lblMessagingSettings": "[[Settings]] de messagerie",
            "lblMessagingSetup": "Configuration de la messagerie",
            "lblSavedMessages": "Messages sauvegardés",
            "lblSecurity": "Sécurité",
            "lblStatus": "Statut",
            "lblSystemEmails": "Courriels système",
            "lblSystemSettings": "[[Settings]] du système",
            "lblTotal": "Total",
            "lblUserCount": "N<sup>bre</sup> d’utilisateurs",
            "txtMessagingSetupDescription": "Les paramètres de comportement de messagerie ainsi que les messages sauvegardés pouvant être réutilisés sont accessibles ici.",
            "txtSystemSettings": "Les [[settings]] contiennent des fonctionnalités système de base qui s’appliquent à l’ensemble du portail.",
            "lblCustomFields": "Champs personnalisés",
            "lblBranding": "Personnalisation",
            "lblUserDeleted": "Supprimé",
            "lblUserInactive": "Inactif",
            "lblUserLocked": "Verrouillé",
            "lblUserPending": "En attente",
            "lblUserActive": "Actif"
        },
        "POI": {
            "pointsOfInterest": "Points d’intérêt",
            "name": "Nom",
            "latitude": "Latitude",
            "longitude": "Longitude",
            "address": "Adresse",
            "btnCreatePOI": "Créer un nouveau point d’intérêt",
            "remove": "Éliminer un point d’intérêt",
            "confirmRemove": "Êtes-vous sûr(e) de vouloir éliminer ce point d’intérêt?",
            "msgRemovePOISuccess": "Le point d’intérêt {{ name }} a été éliminé avec succès.",
            "msgRemovePOIFailed": "Échec de l’élimination du point d’intérêt {{ name }}",
            "noPOIError": "Aucun point d’intérêt actif, veuillez créer un point d’intérêt à sauvegarder.",
            "msgSavePOISuccess": "Le point d’intérêt {{ name }} a été sauvegardé avec succès.",
            "msgSavePOIFailed": "Échec de la sauvegarde du point d’intérêt {{ name }}",
            "createPOI": "Créer un point d’intérêt à partir de cet emplacement",
            "new": "Nouveau point d’intérêt"
        },
        "companyProfile": {
            "btnSaveCompany": "Sauvegarder la société",
            "duplicateCompanyName": "Une société ayant le même nom existe déjà.",
            "errorRequiredFields": "Veuillez remplir tous les champs obligatoires.",
            "errorSavCompanyFailed": "Une erreur s’est produite lors de la sauvegarde de la société.",
            "errorSavCompanyLogoFailed": "Échec de la sauvegarde du logo de la société.",
            "lblChangeLogo": "Modifier le logo",
            "lblCompanyActivity": "Activité de la société",
            "lblCompanymentEmailFromAddress": "Courriel - À partir de l’adresse",
            "lblCompanymentEmailFromName": "Courriel - À partir du nom",
            "lblCompanymentVoiceNumber": "Affichage du numéro de voix",
            "lblCompanyName": "Nom de la société",
            "lblCompanyTitle": "Profil de la société",
            "lblIndustry": "Secteur",
            "msgSaveCompanyLogoSuccess": "Le logo de la société a été sauvegardé avec succès.",
            "msgSaveCompanySuccess": "La société {{entityName}} a été sauvegardée avec succès.",
            "msgSaveCompanySuccessSSOFailed": "La société {{entityName}} a été sauvegardée mais les paramètres d’identification unique n’ont pas pu être sauvegardés.",
            "msgSaveCompanySuccessAPIFailed": "La société {{entityName}} a été sauvegardée mais les paramètres d’intégration tiers n’ont pas pu être sauvegardés.",
            "lblCompanySSoSettingIdpUrl": "URL Idp",
            "lblCompanySSoSettingIsIdpMetadataUrlPublic": "L’URL des métadonnées Idp est publique",
            "lblCompanySSoSettingIdpMetadataUrl": "URL des métadonnées Idp",
            "lblCompanySSoSettingIdpMetadata": "Métadonnées Idp (XML)",
            "lblCompanySSoSettingReturnUrl": "URL de retour de la société",
            "lblCompanySSoSettingUseSso": "Identification unique",
            "lblCompanySSoSettingLoginUrl": "URL de connexion à identification unique",
            "lblCompanySSoSettingAcsUrl": "URL du consommateur ACS",
            "lblCompanySSoSettingAcsValidator": "Outil de validation RegEx URL du consommateur ACS",
            "lblCompanySSoSettingAudience": "Public",
            "lblCompanySSoSettingRecipient": "Bénéficiaire",
            "lblCompanySSoSettingAllowNonSso": "Autoriser les connexions sans identification unique",
            "lblCompanyApiSettingApi": "Intégrations tierces",
            "lblCompanyApiSettingNoneAvailable": "Aucune intégration tierce n’est disponible pour votre société.",
            "lblApiNotAllowed": "Non autorisé",
            "lblApiActive": "Actif",
            "lblApiInactive": "Inactif",
            "companyProfile": "Profil de la société",
            "msgInvalidImageType": "Type de fichier image non valide, veuillez sélectionner un fichier image valide.",
            "deleteCompanyHeader": "Supprimer la société",
            "deleteCompanyBody": "Êtes-vous sûr(e) de vouloir supprimer cette société? Veuillez saisir le mot « {{deleteCompanyVerificationPhrase}} » pour activer le bouton Supprimer.",
            "deleteCompanyVerificationPhrase": "supprimer",
            "msgSuccessDeletingCompany": "La société a été supprimée avec succès.",
            "deletePortalErrors": {
                "7016": "Le portail dispose d’un numéro de téléphone pour l’annonce d’appel.",
                "7017": "Le portail a un/des portail(s) enfant(s) actif(s)",
                "msgErrorDeletingCompany": "Erreur lors de la suppression du portail."
            },
            "lblUploadMetadata": "Télécharger les métadonnées",
            "lblChangeMetadata": "Modifier les métadonnées",
            "tooltipMetadataEmpty": "Vide",
            "msgInvalidFileType": "Type de fichier de métadonnées non valide, un fichier XML est attendu.",
            "industryTypes": {
                "generic": "Générique",
                "banking": "Bancaire",
                "legal": "Juridique",
                "credit union": "Mutuelle de crédit",
                "finance and insurance": "Finance et assurance",
                "retail trade": "Commerce de détail"
            },
            "lblCompanyHRIntegration": "Intégration RH",
            "lblSubscriptionCode": "Code d’abonnement ADP :",
            "btnGenerate": "Générer"
        },
        "security": {
            "errorRequiredFields": "Veuillez remplir tous les champs obligatoires.",
            "errorResetSecurityFailed": "Une erreur s’est produite lors de la réinitialisation des [[settings]] de sécurité.",
            "errorSavSecurityFailed": "Une erreur s’est produite lors de la sauvegarde des [[settings]] de sécurité.",
            "lblAdditionalSettings": "[[Settings]] de sécurité supplémentaires",
            "lblAllowUserManageLocation": "Permettre aux utilisateurs de gérer leurs [[locations]]",
            "lblAllowUserManagePassword": "Permettre aux utilisateurs de gérer leurs propres mots de passe",
            "lblAllowUserManageProfile": "Permettre aux utilisateurs de gérer leurs coordonnées",
            "lblHideUserEmail": "Masquer l’adresse électronique de l’utilisateur (nom d’utilisateur) de l’affichage dans les courriels système",
            "lblLowercaseLetters": "Lettres minuscules (0-10)",
            "lblMinimumLength": "Longueur minimale (1-15)",
            "lblMobilePhoneRequired": "Obliger l’utilisateur à fournir un numéro de téléphone mobile par défaut",
            "lblNumbers": "Numéros (0-10)",
            "lblPasswordSecurity": "Personnaliser les exigences de sécurité des mots de passe",
            "lblPasswordSecurityInfoPopoverTitle": "Sécurité par mot de passe par défaut",
            "lblPasswordSettings": "[[Settings]] de mot de passe",
            "lblSecurityActivity": "Activité de sécurité",
            "lblSecurityTitle": "Sécurité",
            "lblSecurityDescription": "Gérer les règles de mot de passe personnalisées et autres [[settings]] de sécurité du système",
            "lblSymbols": "Symboles (0-10)",
            "lblUppercaseLetters": "Lettres majuscules (0-10)",
            "msgPasswordSecurityInfoPopoverText": "Par défaut, tous les mots de passe ont une longueur minimale de 6 à moins qu’ils ne soient modifiés",
            "msgResetSecuritySuccess": "Les [[settings]] de sécurité ont été réinitialisés avec succès.",
            "msgSaveSecuritySuccess": "Les [[settings]] de sécurité ont été sauvegardés avec succès.",
            "lblAllowOptOutSms": "Autoriser les utilisateurs à désactiver les messages texte",
            "lblAllowOptOutEmail": "Autoriser les utilisateurs à désactiver les courriels",
            "lblAllowOptOutVoice": "Autoriser les utilisateurs à désactiver les messages vocaux",
            "confirmReset": {
                "body": "Êtes-vous sûr(e) de vouloir réinitialiser les [[settings]] de sécurité?",
                "header": "Confirmer",
                "btnText": "OK"
            }
        },
        "systemEmail": {
            "btnEdit": "Modifier",
            "btnSave": "Sauvegarder",
            "btnOk": "OK",
            "errorRevertEmailFailed": "Impossible de rétablir le courriel à l’adresse de courriel système par défaut. Veuillez réessayer.",
            "lblBody": "Corps du courriel",
            "lblBulkUploadFailureEmail": "Échec du téléchargement groupé",
            "lblBulkUploadIncorrectPasswordEmail": "Téléchargement groupé mot de passe incorrect",
            "lblBulkUploadResultEmail": "Résultats de téléchargement groupé",
            "lblBulkUploadUnknownUserEmail": "Téléchargement groupé nom d’utilisateur inconnu",
            "lblChangedPasswordEmail": "Mot de passe modifié",
            "lblConfirmation": "Confirmer",
            "lblEmailChangedEmail": "Courriel modifié",
            "lblEmailListLabel": "Afficher le modèle pour :",
            "lblForgotPasswordEmail": "Mot de passe oublié",
            "lblInviteUserEmail": "Enregistrement d’un nouvel utilisateur",
            "lblLastModifiedBy": "Dernière modification par",
            "lblLastModifiedOn": "Dernière modification le",
            "lblLoading": "Veuillez patienter...",
            "lblNA": "S. O.",
            "lblResetTemplateLink": "Réinitialiser les paramètres par défaut du système",
            "lblSubject": "Objet du courriel",
            "lblSystemEmailActivity": "Activité de courriels système",
            "lblSystemEmailsTitle": "Courriels système",
            "lblTemplateLastModified": "Modèle modifié en dernier",
            "lblUserGroupAdditionEmail": "Ajout de groupe d’utilisateurs",
            "lblUserGroupRemovalEmail": "Élimination d’un groupe d’utilisateurs",
            "lblUsingCustom": "Ce modèle a été personnalisé.",
            "msgRevertEmailSuccess": "Le courriel système a été rétabli à l’adresse électronique système par défaut.",
            "msgSavedSystemEmailSuccess": "Le courriel système a été sauvegardé avec succès",
            "confirmReset": {
                "body": "Êtes-vous sûr(e) de vouloir revenir au courriel système par défaut?",
                "header": "Confirmer",
                "btnText": "OK"
            },
            "sendMessageEmail": "Envoyer un message par courriel",
            "insert": "Insérer",
            "reset": "Réinitialiser",
            "resizeImageDescription": "Ajuster la largeur et la hauteur de votre image à votre guise. Vous avez la possibilité de conserver le rapport hauteur/largeur de l’image afin qu’il ne soit pas déformé.",
            "doYouWantResizeImageDescription": "Nous remarquons que votre image est assez grande. Souhaitez-vous la redimensionner?",
            "preserveAspectRatio": "Conserver les proportions",
            "bcTeamLeadIntroductoryEmail": "Courriel d’introduction à la continuité des activités pour le chef d’équipe",
            "bcTeamMemberIntroductoryEmail": "Courriel d’introduction à la continuité des activités pour les membres de l’équipe",
            "bcNewTeamLeadIntroductoryEmail": "Courriel d’introduction à la continuité des activités pour le chef d’équipe (nouvel utilisateur)",
            "bcNewTeamMemberIntroductoryEmail": "Courriel d’introduction à la continuité des activités pour les membres de l’équipe (nouvel utilisateur)",
            "bcTeamLeadDueDateReminderEmail": "Courriel date limite prochaine pour la continuité des activités pour le chef d'équipe",
            "bcTeamLeadDueDateNowEmail": "Courriel date limite maintenant pour la continuité des activités pour le chef d'équipe",
            "bcTeamMemberDueDateReminderEmail": "Courriel date limite prochaine pour la continuité des activités pour les membres d'équipe",
            "bcTeamMemberDueDateNowEmail": "Courriel date limite maintenant pour la continuité des activités pour les membres d'équipe",
            "notificationEmergencyResponseEmail": "Email de notification de réponse d'urgene",
            "bcNewAnnexLeadIntroductoryEmail": "E-mail de présentation du plan opérationnel d'urgence pour le collaborateur (nouvel utilisateur)",
            "bcAnnexLeadIntroductoryEmail": "Email de présentation du plan d'urgence opérationnel du collaborateur",
            "bcAnnexLeadDueDateReminderEmail": "Courriel date limite prochaine pour la continuité des activités pour le chef d'équipe",
            "bcAnnexLeadDueDateNowEmail": "Courriel date limite maintenant pour la continuité des activités pour le chef d'équipe",
            "managerNotificationEmergencyResponseEmail": "E-mail d’intervention d’urgence de notification du gestionnaire",
            "bcTechnologyNewLeadEmail": "E-mail d'introduction pour les responsables [[technology]] (nouvel utilisateur)",
            "bcTechnologyLeadEmail": "E-mail d'introduction destiné au responsables[[technology]]"
        },
        "messageSettings": {
            "errorRequiredFields": "Veuillez remplir tous les champs obligatoires.",
            "errorResetMessageFailed": "Une erreur s’est produite lors de la réinitialisation des [[settings]] de message.",
            "errorSavMessageFailed": "Une erreur s’est produite lors de la sauvegarde des [[settings]] de message.",
            "lblAreYouSureResetSettings": "Êtes-vous sûr(e) de vouloir réinitialiser les [[settings]] des messages?",
            "lblEscalateMessageContent1": "Si vous souhaitez donner aux destinataires du message le temps de répondre par message texte, courriel ou message instantané avant qu’ils ne soient appelés, réglez cette option sur Oui. Vous pourrez alors sélectionner le temps d’attente avant l’appel.",
            "lblEscalateMessageContent2": "Si cette option est réglée sur Non, le destinataire sera appelé en même temps qu’il recevra des messages texte/courriel/instantanés.",
            "lblEscalateMessageContent3": "Remarque : Le réglage s’applique UNIQUEMENT aux messages qui nécessitent une réponse et pour lesquels les modes Message texte et/ou Courriel et/ou Message instantané ET Vocal sont sélectionnés.",
            "lblEscalateMessageTitle": "Faire remonter le message lorsqu’une réponse EST requise (Message texte/Courriel/Message instantané à Vocal)?",
            "lblIncludeOfficePhoneContent": "Si cette option est réglée sur Oui, lorsqu’un utilisateur envoie un nouveau message ET a sélectionné Vocal (appeler les destinataires), l’option d’appeler le téléphone de bureau d’un destinataire sera présélectionnée. Les utilisateurs pourront remplacer la valeur par défaut et désélectionner par message si nécessaire.",
            "lblIncludeOfficePhoneTitle": "Inclure le téléphone de bureau pour les appels vocaux par défaut?",
            "lblPersonalContactContent": "Si cette option est réglée sur Oui, lorsqu’un utilisateur envoie un nouveau message, l’option d’envoi aux coordonnées personnelles du destinataire du message sera présélectionnée. Les utilisateurs pourront remplacer cette valeur par défaut et désélectionner par message si nécessaire.",
            "lblPersonalContactTitle": "Envoyer un message aux coordonnées personnelles de l’utilisateur?",
            "lblSettingsActivity": "[[Settings]] Activité",
            "lblSettingsTitle": "[[Settings]] de message",
            "msgResetMessageSuccess": "Les [[settings]] de message ont été réinitialisés avec succès.",
            "msgSaveMessageSuccess": "Les [[settings]] de message ont été sauvegardés avec succès.",
            "lblPersonalContactPhoneTitle": "Envoyer un message au téléphone personnel de l’utilisateur?",
            "lblPersonalContactPhoneContent": "Si cette option est réglée sur Oui, lorsqu’un utilisateur envoie un nouveau message, l’option d’envoi aux coordonnées personnelles du téléphone du destinataire du message sera présélectionnée. Les utilisateurs pourront remplacer cette valeur par défaut et désélectionner par message si nécessaire.",
            "personalContactEmailTitle": "Envoyer un message à l’adresse de courriel personnelle de l’utilisateur?",
            "lblPersonalContactEmailContent": "Si cette option est réglée sur Oui, lorsqu’un utilisateur envoie un nouveau message, l’option d’envoi aux coordonnées de courriel personnelles du destinataire du message sera présélectionnée. Les utilisateurs pourront remplacer cette valeur par défaut et désélectionner par message si nécessaire."
        },
        "userInitiatedMessage": {
            "lblPageHeader": " Paramètres des incidents déclenchés par l’utilisateur",
            "lblPageDescription": "Gérer les utilisateurs qui recevront les messages des incidents déclenchés par les utilisateurs.",
            "lblDefaultGroup": "Groupe par défaut",
            "lblDefaultGroupTip": "Les utilisateurs du groupe sélectionné recevront des messages par COURRIEL, MESSAGE TEXTE et MESSAGE INSTANTANÉ par défaut. Ce paramètre peut être remplacé par type d’incident ci-dessous.",
            "lblIncidentTypes": "Types d’incidents",
            "lblDefaultGroupPerIncident": "Groupe par défaut pour ",
            "lblDefaultGroupPerIncidentTip": "Les utilisateurs du groupe sélectionné recevront toujours des messages pour ce type d’incident. Des groupes supplémentaires peuvent recevoir des messages à l’aide des paramètres d’emplacements facultatifs ci-dessous.",
            "lblOptionLocations": "[[Locations]] facultatifs",
            "lblOptionLocationsTip": "Des groupes supplémentaires peuvent recevoir des messages en fonction de l’[[location]] signalé par défaut des utilisateurs.",
            "btnAddNewOptionalLocation": "Ajouter un nouvel [[location]] facultatif",
            "msgUserInitiatedMessagesSettingsSaved": "Paramètres déclenchés par l’utilisateur sauvegardés.",
            "msgUserInitiatedMessagesSettingsNotSaved": "Une erreur s’est produite lors de la sauvegarde des [[settings]] déclenchés par l’utilisateur. Veuillez réessayer plus tard.",
            "msgRequiredFieldError": "Un ou plusieurs champs doivent être sélectionnés.",
            "lblSendTo": "Envoyer des messages à",
            "lblChannelsPerIncidentTip": "Sélectionner les méthodes par lesquelles l’utilisateur recevra les messages. Les utilisateurs qui ne disposent pas de ces méthodes ne recevront pas de messages.",
            "lblEmail": "Courriel",
            "lblSms": "Message texte",
            "lblVoice": "Voix",
            "lblPush": "Messages instantanés",
            "lblLocation": "[[Location]]",
            "lblGroup": "Groupe",
            "lblChangesHaveBeenMadeHeader": "Des modifications ont été apportées",
            "lblChangesHaveBeenMadeMessage": "Vous avez apporté des modifications sur cette page. Si vous quittez cette page sans sauvegarder au préalable vos données, les modifications seront perdues.",
            "lblNoGroupsHeader": "Aucun <strong>groupe</strong> n’a été configuré",
            "lblNoGroupsDescription": "Pour gérer les paramètres des incidents déclenchés par l’utilisateur, au moins un (1) ou plusieurs groupes doivent être configurés.",
            "btnGoToCreateGroup": "Créer un groupe",
            "lblGroupsRemoved": "REMARQUE : Les groupes auxquels aucun utilisateur n’a été affecté ont été éliminés de la liste.",
            "lblGroupsAndLocationsRemoved": "REMARQUE : Les groupes et les [[locations]] auxquels aucun utilisateur n’a été affecté ont été éliminés de la liste.",
            "msgCannotDisableAllChannels": "Au moins une (1) méthode de contact doit être activée.",
            "confirmReset": {
                "body": "La modification du groupe par défaut affectera tous les types d’incident sans qu’un groupe spécifiquement configuré soit également mis à jour.",
                "header": "Êtes-vous sûr(e) de vouloir modifier le groupe par défaut?",
                "btnText": "OK"
            }
        },
        "user": {
            "lblDefaultNewUserTitle": "Nouvel utilisateur",
            "btnSaveAndFinish": "Sauvegarder et terminer",
            "lblUserActivity": "Activité de l’utilisateur",
            "lblUserStatus": "Statut",
            "lblIQAssignments": "Affectations iQ",
            "lblInitRegSent": "Enregistrement initial envoyé",
            "lblUserFirstLogin": "Première connexion de l’utilisateur",
            "lblUsersLastTermAcceptance": "Acceptation du dernier terme de l’utilisateur",
            "lblUserLastLogin": "Dernière connexion de l’utilisateur",
            "lblUserRole": "Rôle",
            "lblUniqueId": "Identifiant unique",
            "lblSelfRegistered": "Réception activée",
            "errorSaveUser": "Échec de la sauvegarde des informations utilisateur",
            "lblSendRegEmail": "Envoyer un courriel d’enregistrement",
            "menuPickRole": "Sélectionner un rôle...",
            "errorIQAssignmentsRequireLogin": "L’utilisateur doit avoir un rôle avec des capacités de connexion lorsqu’il a des affectations iQ",
            "errorCannotInactivateWithIQAssignments": "L’utilisateur ne peut pas être rendu inactif car il a des affectations iQ. Éliminer toutes les affectations iQ avant de tenter de rendre l’utilisateur inactif.",
            "errorCannotDeleteWithIQAssignments": "L’utilisateur ne peut pas être supprimé car il a des affectations iQ. Éliminer toutes les affectations iQ avant de tenter de supprimer l’utilisateur.",
            "errorCannotDeleteBCManager": "L’utilisateur ne peut pas être supprimé car il est Responsable de la continuité des activités. Sélectionner un nouveau Responsable de la continuité des activités avant de tenter de supprimer l’utilisateur.",
            "lblAreYouSureUpgradeUser": "Êtes-vous sûr(e) de vouloir mettre à niveau cet utilisateur inscrit? Cette modification ne peut pas être annulée",
            "lblAreYouSureDeleteUser": "Êtes-vous sûr(e) de vouloir supprimer cet utilisateur?",
            "msgDeleteUserSuccess": "L’utilisateur a été supprimé avec succès.",
            "msgCannotDeleteEssentialContactUser": "L’utilisateur ne peut pas être supprimé. C’est un contact essentiel.",
            "errorDeleteUserFailed": "Une erreur s’est produite lors de la tentative de suppression de cet utilisateur.",
            "lblManageUserPasswordHeader": "Réinitialiser le mot de passe",
            "lblInitiateUserPasswordReset": "Envoyer à l’utilisateur un courriel qui permet à l’utilisateur de créer son propre mot de passe.",
            "lblManuallySetUserPassword": "Mot de passe défini manuellement (aucun courriel envoyé, vous devrez communiquer verbalement le mot de passe).",
            "lblResetAndSendEmail": "Réinitialiser et envoyer un courriel",
            "lblManuallySetPassword": "Définir manuellement le mot de passe",
            "msgNoUser": "Impossible de demander la réinitialisation du mot de passe, l’utilisateur est vide.",
            "msgRequestPasswordResetFailed": "Le mot de passe n’a pas pu être réinitialisé pour cet utilisateur.",
            "msgRequestPasswordResetSuccess": "L’utilisateur recevra un courriel avec des instructions pour réinitialiser son mot de passe.",
            "lblAreYouSureResendInvite": "Êtes-vous sûr(e) de vouloir renvoyer l’invitation à cet utilisateur?",
            "msgInvitationSent": "L’invitation a été envoyée à l’utilisateur.",
            "errorInvitationSent": "Impossible de renvoyer l’invitation à l’utilisateur.",
            "lblLastInviteNotSent": "aucune invitation envoyée",
            "lblLastInviteSent": "dernière invitation envoyée",
            "btnResendInvite": "Renvoyer l’invitation",
            "lblAreYouSureUnlock": "Êtes-vous sûr(e) de vouloir déverrouiller cet utilisateur?",
            "msgUserUnlocked": "L’utilisateur a été déverrouillé avec succès.",
            "errorUserUnlocked": "Impossible de déverrouiller cet utilisateur.",
            "btnUnlock": "Déverrouiller"
        },
        "selfRegistration": {
            "lblSelfRegistrationTitle": "Activer la réception",
            "lblSelfRegistrationDescription": "Gérer les configurations et les paramètres de réception activée",
            "lblSelfRegistrationFlag": "Autoriser les utilisateurs à activer la réception",
            "lblSelfRegistrationUrl": "URL / code QR de réception activée",
            "lblSelfRegistrationCode": "Code d’enregistrement",
            "lblRequireAddress": "Autoriser les utilisateurs à fournir une adresse",
            "lblUserRole": "Sélectionner un rôle pour les utilisateurs qui ont activé la réception",
            "lblUserLocations": "Sélectionner à quel(s) [[location]](s) les utilisateurs peuvent recevoir des messages",
            "lblLocations": "[[Locations]]",
            "lblSelectNone": "ne rien sélectionner",
            "lblSelectAll": "tout sélectionner",
            "lblConfirmDeleteTitle": "Éliminer le compte",
            "lblConfirmDelete": "Êtes-vous sûr(e) de vouloir éliminer votre compte?",
            "optInUrlTooltip": "Copiez le lien ou téléchargez le code QR et fournissez-le aux utilisateurs qui ont activé la réception",
            "registrationCodeTooltip": "Fournissez ce code aux utilisateurs qui ont activé la réception pour terminer l’enregistrement",
            "copyOptInUrlTooltip": "Copier l’URL d’activation",
            "downloadOptInQrCodeTooltip": "Télécharger le code QR",
            "requireAddress": "Oui, obliger les utilisateurs à fournir une adresse",
            "allowAddress": "Oui, autoriser les utilisateurs à fournir une adresse",
            "noAddress": "Non, ne pas demander d’adresse",
            "allLocations": "Tous les [[locations]]",
            "selectedLocations": "[[Locations]] sélectionnés",
            "msgSaveSuccess": "Paramètres de réception activée sauvegardés",
            "msgSaveFail": "Échec de la sauvegarde des paramètres de réception activée",
            "confirmReset": "Êtes-vous sûr(e) de vouloir réinitialiser les paramètres de réception activée par défaut",
            "msgResetFail": "Échec de la réinitialisation des paramètres de réception activée",
            "msgLocationRequired": "Veuillez sélectionner au moins un [[location]] pour que l’utilisateur puisse s’inscrire",
            "msgRoleBundleRequired": "Un rôle est requis pour les utilisateurs qui ont activé la réception",
            "msgNoRoles": "Aucun rôle qualifié pour les utilisateurs qui ont activé la réception, veuillez créer au moins un rôle de message uniquement.",
            "lblNoRoles": "Aucun rôle disponible"
        },
        "mobileEmergencyButtons": {
            "alerts": "[[Alerts]]",
            "manageMobileEmergencyButtons": "Paramètres d’[[alert]] lancée par mobile",
            "manageMobileEmergencyButtonsDescription": "Les boutons d’[[alert]] lancée par mobile peuvent être configurés sur l’application mobile. Cette page vous permet de configurer ces boutons et les utilisateurs qui recevront la communication lorsque le bouton sera enfoncé.",
            "sharedSettings": "Paramètres partagés d’[[alert]] personnalisée",
            "sharedSettingsDescription": "Les [[settings]] partagés d’[[alert]] personnalisée ne s’appliquent qu’aux boutons d’[[alert]] personnalisée. Des boutons plus spécialisés tels que Panique, Vérification programmée et Signaler un incident sont gérés par d’autres paramètres de la société.",
            "buttonShouldBePress": "Autoriser les utilisateurs à appuyer sur une [[alert]] uniquement lorsqu’ils se trouvent à proximité d’un [[location]] de la société",
            "howCloseShouldTheLocation": "Identifier à quel point les utilisateurs doivent être proches d’un [[location]] de la société pour que l’[[alert]] soit déclenchée",
            "canSamePersonPressResolve": "Autoriser les utilisateurs qui activent une [[alert]] à résoudre l’incident",
            "emergencyButtonsConfiguration": "Configuration des [[alerts]] lancée par mobile",
            "saveSuccess": "Boutons d’[[alert]] lancée par mobile sauvegardés avec succès",
            "saveError": "Erreur lors de la sauvegarde des boutons d’[[alert]] lancée par mobile",
            "loadError": "Erreur lors du chargement des boutons d’[[alert]] lancée par mobile",
            "invalidContactMethodsError": "Au moins une méthode de contact est requise",
            "addButton": "Ajouter un bouton",
            "configurationModal": {
                "title": "Configurer {{ x }} un bouton d’[[alert]] lancée par mobile",
                "lblButtonType": "Sélectionner le type de bouton",
                "lblButtonName": "Donner un nom au bouton d’[[alert]] lancée par mobile",
                "lblButtonNameTooltip": "Les noms des boutons d’[[alert]] sont limités à 50 caractères",
                "lblCohortTypeToNotify": "Qui doit être averti lorsque ce bouton est enfoncé?",
                "lblTypesOfNearbyUsers": "Comment souhaitez-vous identifier les personnes à proximité?",
                "lblProximity": "À quelle distance les destinataires à proximité doivent-ils être?",
                "lblCustomButtonType": "Personnaliser",
                "lblPanicButtonType": "Panique",
                "lblScheduledCheckinButtonType": "Vérification programmée",
                "lblReportIncidentButtonType": "Signaler un incident",
                "lblCohortProximityType": "Personnes à proximité",
                "lblCohortDesignatedRecipientsType": "Un ensemble spécifique de personnes",
                "lblSearchUser": "Rechercher",
                "lblAddByPhoneNumber": "Ajouter par numéro de téléphone",
                "lblAddByEmail": "Ajouter par courriel",
                "lblSelectedRecipients": "Destinataires sélectionnés",
                "lblAddByEntity": "Ajouter par type",
                "lblEntityTypeRoles": "Rôles",
                "lblEntityTypeLocations": "[[Locations]]",
                "lblEntityTypeDepartments": "[[Departments]]",
                "lblEntityTypeGroups": "Groupes",
                "panicDesc": "<p>Un bouton Panique sera placé sur l’écran d’accueil du mobile.</p><p>Appuyer sur le bouton déclenchera un incident et enverra un message à une liste désignée de destinataires. Cette liste de messages et de destinataires peut être personnalisée par emplacement.</p><p>Pour configurer les messages de panique et les listes de destinataires prévus, rendez-vous sur la page <a href=\"{{panicConfigurationLink}}\" target=\"_blank\">Configuration de panique</a>.</p>",
                "scheduledCheckinDesc": "<p>Un bouton de vérification programmé sera placé sur l’écran d’accueil du mobile.</p><p>Appuyer sur le bouton déclenchera un compte à rebours qui crée un incident de panique et envoie un message à une liste désignée de destinataires. Ce message et cette liste de destinataires peuvent être personnalisés par emplacement.</p><p>Pour configurer le message de panique et la liste de destinataires associée, rendez-vous sur la page <a href=\"{{panicConfigurationLink}}\" target=\"_blank\">Configuration de panique</a>.</p>",
                "reportIncidentDesc": "<p>Un bouton Signaler un incident sera placé sur l’écran d’accueil du mobile.</p><p>Appuyer sur le bouton permettra aux utilisateurs de saisir librement une description et un emplacement de l’incident en plus de prendre une photo.</p><p>Pour configurer la liste des destinataires du rapport d’incident, rendez-vous sur <a href=\"{{manageSettingsLink}}\" target=\"_blank\">Paramètres des incidents déclenchés par l’utilisateur</a></p>",
                "duplicatePanicButtonError": "Un bouton de panique existe déjà",
                "duplicateScheduledCheckInError": "Un bouton de vérification programmée existe déjà",
                "duplicateReportIncidentError": "Un bouton Signaler un incident existe déjà",
                "duplicateAlertButtonError": "Le bouton {{ buttonName }} existe déjà",
                "saveSuccess": "Bouton mobile sauvegardé avec succès.",
                "saveError": "Erreur lors de la sauvegarde du bouton mobile.",
                "removeSuccess": "Le bouton mobile a été éliminé avec succès.",
                "removeError": "Erreur lors de l’élimination du bouton mobile.",
                "selectUnitOfMeasure": "Sélectionner l’unité de mesure",
                "byUserAddresses": "Par adresses utilisateur",
                "byAssignedLocation": "Par [[location]] affecté",
                "byMobileLocation": "Par emplacement mobile",
                "lblIncidentType": "Type d’incident"
            },
            "confirmRemove": {
                "header": "Éliminer le bouton d’[[alert]] lancée par mobile",
                "body": "Êtes-vous sûr(e) de vouloir éliminer le bouton d’[[alert]] lancée par mobile {{ buttonName }} de l’application mobile?",
                "text": "Éliminer"
            }
        },
        "bulkUploadConfig": {
            "configureBulkUploadTitle": "Configuration de téléchargement groupé",
            "lblDisableEmailAttachments": "Inclure les fichiers source, de résultat et de rapport d’erreur détaillé dans le courriel de notification",
            "disableEmailAttachmentsTooltip": "Envoyer par courriel de téléchargement groupé le fichier source, le fichier de résultats et le fichier d’erreur détaillé en tant que pièce jointe au courriel de notification",
            "lblDisableEmailErrors": "Inclure le fichier d’erreurs sans détails personnels de l’utilisateur dans le courriel de notification",
            "disableEmailErrorsTooltip": "Inclure le fichier d’erreurs de téléchargement groupé sans les détails personnels de l’utilisateur en tant que pièce jointe au courriel de notification.",
            "lblNotificationEmails": "Courriels de notification",
            "notificationEmailsTooltip": "Adresses de courriel pour envoyer les notifications sur les résultats de téléchargement groupé.",
            "lblDefaultRole": "Rôle par défaut",
            "defaultRolePlaceHolder": "Sélectionner le rôle par défaut",
            "defaultRoleTooltip": "Ce rôle sera affecté aux nouveaux utilisateurs qui n’ont pas de rôle spécifié dans leur fichier de téléchargement groupé.",
            "lblDefaultLocation": "[[Location]] par défaut",
            "defaultLocationPlaceHolder": "Sélectionner l’[[location]] par défaut",
            "defaultDropdownPlaceholder": "Aucune sélection",
            "defaultLocationTooltip": "Cet [[location]] sera affecté aux nouveaux utilisateurs qui n’ont pas d’[[location]] spécifié dans leur fichier de téléchargement groupé.",
            "invalidEmailFormatError": "Format de courriel invalide. La valeur ne peut pas être ajoutée à la liste des courriels."
        }
    },
    "login": {
        "lblLoggingIn": "Connexion en cours...",
        "lblLoginId": "Courriel",
        "phUsername": "Saisir votre adresse électronique",
        "phRegistrationCode": "Saisir le code d’enregistrement de la société",
        "lblPassword": "Mot de passe",
        "lblConfirmPassword": "Confirmer le mot de passe",
        "phPassword": "Saisir votre mot de passe",
        "btnLogin": "Connexion",
        "btnBackToLogin": "retour à la connexion",
        "lblSupport": "Support",
        "btnForgotPortalUrl": "Vous avez oublié l’URL du portail?",
        "btnForgotPassword": "Mot de passe oublié?",
        "btnRegister": "Activer la réception",
        "msgChangedPasswordSuccess": "Votre mot de passe a été modifié avec succès.",
        "msgSessionTimeout": "Votre session a expiré en raison d’une période inactivité. Veuillez vous reconnecter.",
        "lblNeedHelp": "Besoin d’aide?",
        "msgRequestSendPortalsLinksSuccess": "Nous vous avons envoyé un courriel avec les liens vers les portails disponibles. Vous devriez le recevoir dans quelques minutes.",
        "msgRequestSendPortalsLinksFailed": "Échec de l’envoi d’un courriel.",
        "lblResetPassword": "Réinitialiser le mot de passe",
        "lblChangePassword": "Modifier le mot de passe",
        "lblCreatePassword": "Créer un mot de passe",
        "lblRegisterAccount": "Créer un compte",
        "lblRegisterProfile": "Coordonnées",
        "lblContactInfo": "Coordonnées",
        "msgRegisterInstructions": "Veuillez saisir le code d’enregistrement de la société avec lequel vous souhaitez vous inscrire",
        "msgResetPasswordInstructions": "Saisir votre adresse de courriel, puis cliquer ci-dessous pour envoyer une demande de réinitialisation de votre mot de passe.",
        "msgRegisterAccountInstructions": "Créer un nom d’utilisateur et un mot de passe à utiliser pour accéder aux informations de votre profil",
        "btnRequestPasswordReset": "Demander la réinitialisation du mot de passe",
        "msgForgotPassword1": "Si le nom d’utilisateur fourni est valide, dans quelques minutes, vous devriez recevoir un courriel vous permettant de créer un nouveau mot de passe.",
        "msgForgotPassword2": "Si vous n’en voyez pas, consultez votre dossier de courriel indésirable. Si vous n’en avez toujours pas reçu,",
        "msgForgotPassword3": "réessayez",
        "msgForgotPassword4": "ou contactez votre administrateur.",
        "lblNewPassword": "Nouveau mot de passe",
        "lblConfirmNewPassword": "Confirmer le nouveau mot de passe",
        "btnChangePassword": "Modifier le mot de passe",
        "btnCreatePassword": "Créer un mot de passe",
        "passwordErrorMessage": "Le mot de passe ne répond pas aux règles de complexité.",
        "msgUsernameExist": "Ce courriel existe déjà dans le système, veuillez utiliser un autre courriel.",
        "errors": {
            "999": "Erreur système. Veuillez réessayer plus tard.",
            "1005": "Impossible de se connecter, veuillez vérifier votre adresse électronique ou votre mot de passe.",
            "1006": "Soit le lien a expiré, soit le lien n’est pas valide. Veuillez demander un nouveau lien.",
            "1010": "Vous avez dépassé le nombre maximal de mauvaises tentatives de connexion.  Votre compte a été temporairement verrouillé.  Veuillez réessayer plus tard.",
            "1015": "L’adresse électronique que vous avez saisie ne correspond pas à un compte d’utilisateur actif.",
            "1017": "Impossible d’utiliser l’authentification multifacteur, l’utilisateur n’a pas de téléphone principal",
            "1019": "Le code NIP d’authentification n’est pas valide.",
            "1022": "L’utilisateur doit se connecter via identification unique",
            "1023": "L’utilisateur doit se connecter à l’aide de l’URL du portail",
            "1024": "L’utilisateur doit se connecter à l’aide de l’URL du portail ou d’une identification unique",
            "msgMFAFailed": "L’authentification multifacteur a échoué.",
            "tacDeclined": "Conditions générales refusées.",
            "msgTACFailed": "Échec des conditions générales.",
            "msgValidateEmailFormat": "L’adresse électronique saisie n’est pas une adresse électronique valide.",
            "msgInvalidUsername": "L’adresse électronique que vous avez saisie ne correspond pas à un compte d’utilisateur actif."
        }
    },
    "mfa": {
        "lblMultifactorTitle": "Authentification multifactorielle",
        "lblMfaSecurityCode": "Code de sécurité",
        "lblRequired": "(obligatoire)",
        "msgMultifactorInstructions": "Un code de sécurité a été envoyé à votre numéro de téléphone enregistré. Veuillez le saisir ci-dessous pour continuer votre inscription.",
        "msgMultifactorWithExpirationInstructions": "Un code de sécurité a été envoyé à votre numéro de téléphone enregistré. Veuillez le saisir ci-dessous pour continuer votre inscription. Le code de sécurité expirera dans {{mfaPinExpirationMinutes}} minutes."
    },
    "splashMessage": {
        "msgMustAgreeToSplash": "Vous devez accuser réception de ce message en cochant la case.",
        "lblAcknowledgedSplash": "Réception accusée"
    },
    "recoveryProfiles": {
        "recoveryProfile": "Profil de récupération",
        "recoveryProfiles": "Profils de récupération",
        "recoveryProfilesFilterPlaceHolder": "Filtrer sur le nom du profil...",
        "export": "Exporter",
        "name": "Nom",
        "essentialContactsDesc": "Le personnel clé qui est autorisé à lancer une réponse Agility en cas de besoin.",
        "generator": "Générateur",
        "comments": "Commentaires",
        "generatorDesc": "Exigences d’alimentation nécessaires pour récupérer votre espace de bureau",
        "sites": "Sites",
        "sitesDesc": "Sites de bureaux mobiles et autres options d’espace de vente au détail",
        "technologyAndConnectivity": "[[Technology]] et connectivité",
        "technologyAndConnectivityDesc": "[[Applications]] d’équipements technologiques pour la récupération",
        "generatorSize": "Taille du générateur",
        "generatorPhase": "Phase du générateur",
        "generatorVoltage": "Tension du générateur",
        "totalAmperage": "Ampérage total",
        "requiredCable": "Câble requis",
        "powerService": "Service d’alimentation",
        "generatorTitle": "Fournir les exigences d’alimentation nécessaires pour récupérer votre espace de bureau",
        "requestChange": "Demander une modification",
        "site": "Site",
        "primary": "Principal",
        "errorExportingRecoveryProfile": "Erreur lors de l’exportation du profil de récupération",
        "rename": "Renommer",
        "renameRecoveryProfile": "Renommer le profil de récupération",
        "renameRecoveryProfileTooltip": "Un nom de profil de récupération peut comporter jusqu’à 200 caractères. Il doit être unique.",
        "errorRenamingRecoveryProfile": "Erreur lors de la modification du nom de profil de récupération",
        "successRenamingRecoveryProfile": "Profil de récupération renommé avec succès",
        "duplicateProfileName": "Les noms de profil de récupération doivent être uniques",
        "errorLoadingProfiles": "Erreur lors du chargement des profils de récupération",
        "errorCreatingProfile": "Erreur lors de la création du profil de récupération",
        "manuallyCreate": "Créer manuellement",
        "migrateMyAgilityDocuments": "Migrer des documents",
        "errorMigratingMyAgilityDocuments": "Erreur lors de la migration des documents",
        "successMigratingMyAgilityDocuments": "Migration des documents demandée avec succès",
        "validation": {
            "duplicateProfileNameMessage": "Ce nom a déjà été utilisé",
            "missingValues": "Veuillez remplir toutes les valeurs requises"
        },
        "recoverySites": {
            "recoverySites": "Sites de récupération",
            "recoverySitesFilterPlaceHolder": "Filtrer sur le nom du site...",
            "recoverySitesLoadingError": "Erreur lors du chargement des sites de récupération",
            "unitType": "Type d’unité",
            "unitNumber": "Numéro d’unité",
            "streetNumber": "Numéro de rue",
            "streetName": "Nom de rue",
            "city": "Ville",
            "state": "État",
            "postalCode": "Code postal",
            "country": "Pays",
            "contactName": "Nom du contact",
            "phone": "Téléphone",
            "comments": "Commentaires",
            "saveChanges": {
                "header": "Sauvegarder le site de récupération",
                "body": "Êtes-vous sûr(e) de vouloir sauvegarder les modifications?",
                "text": "Sauvegarder"
            },
            "errorLoadingRecoverySite": "Erreur lors du chargement du site de récupération",
            "errorSavingRecoverySite": "Erreur lors de la sauvegarde du site de récupération",
            "successSavingRecoverySite": "Sauvegarde du site de récupération effectuée avec succès",
            "errorDeletingRecoverySite": "Erreur lors de la suppression du site de récupération",
            "successDeletingRecoverySite": "Succès de la suppression du site de récupération",
            "filterPlaceHolder": "Filtrer sur le nom du site...",
            "deleteSite": {
                "header": "Supprimer le site de récupération",
                "body": "Êtes-vous sûr(e) de vouloir supprimer ce site?",
                "text": "Supprimer"
            },
            "manuallyCreate": {
                "header": "Créer manuellement un profil de récupération",
                "body": "Êtes-vous sûr(e) de vouloir créer manuellement un nouveau profil?",
                "text": "Créer manuellement"
            },
            "siteName": "Nom du site",
            "newSite": "Nouveau site",
            "recoverySitesDescription": "Déterminer les sites de bureaux mobiles principaux et secondaires ainsi que les options alternatives d’espace de vente au détail.",
            "checkRequiredFields": "Veuillez cocher tous les champs obligatoires"
        },
        "technicalInfo": {
            "description": "Avec votre adhésion, vous avez accès aux équipements énumérés ci-dessous",
            "saveSuccess": "Commentaire sauvegardé",
            "comment": "Commentaire",
            "technologyRecovery": "Récupération de la [[technology]]",
            "comments": "Commentaires"
        },
        "recoveryGenerator": {
            "saveGeneratorSuccess": "Générateur sauvegardé",
            "errorLoadingGeneratorInfo": "Erreur lors du chargement des informations du générateur",
            "checkRequiredFields": "Veuillez cocher tous les champs obligatoires",
            "saveChanges": {
                "header": "Sauvegarder le générateur de récupération",
                "body": "Êtes-vous sûr(e) de vouloir sauvegarder les modifications?",
                "text": "Sauvegarder"
            }
        },
        "errorRetrievingRecoveryProfile": "Erreur lors du chargement du profil de récupération",
        "recoveryProfilesDescription": "Vos profils de récupération vous aident à récupérer rapidement votre organisation en cas de sinistre.",
        "activityInformation": "Informations sur l’activité",
        "essentialContacts": {
            "essentialContacts": "Contacts essentiels",
            "name": "Nom",
            "title": "Titre",
            "primary": "Principal",
            "email": "Courriel",
            "mobilePhone": "Téléphone mobile",
            "officePhone": "Téléphone de bureau",
            "personalPhone": "Téléphone personnel",
            "addContact": "Ajouter le contact",
            "essentialContactsDescription": "Personnel clé autorisé à lancer une réponse Agility en cas de besoin",
            "isPrimaryContact": "Est le contact principal",
            "newContact": "Nouveau contact",
            "essentialContact": "Contact essentiel",
            "removeEssentialContact": "Éliminer contact essentiel",
            "removeEssentialContactDescription": "Êtes-vous sûr(e) de vouloir éliminer ce contact essentiel?",
            "removeEssentialContactSuccess": "Le contact essentiel a été éliminé avec succès",
            "saveEssentialContactSuccess": "Contact essentiel sauvegardé avec succès",
            "essentialContactActivity": "Activité de contact essentielle",
            "noEssentialContacts": "Aucun contact essentiel"
        },
        "documents": {
            "documents": "Documents",
            "manageYourDocuments": "Gérer les documents de votre plan en ligne",
            "manageYourDocumentsInstruction": "Gérer les documents de votre plan en ligne ici.",
            "manageYourDocumentsInstruction2": "Vous êtes actuellement autorisé(e) à avoir {{maxUploads}} documents. Si vous souhaitez télécharger plus de documents, veuillez contacter votre responsable de compte.",
            "manageYourDocumentInstruction": "Gérer votre document de plan en ligne ici.",
            "manageYourDocumentInstruction2": "Vous êtes actuellement autorisé(e) à avoir 1 document. Si vous souhaitez télécharger plus de documents, veuillez contacter votre responsable de compte.",
            "document": "Document",
            "noDocuments": "Aucun document",
            "uploadSuccessful": "Téléchargement réussi",
            "uploadFailed": "Téléchargement réussi",
            "maxUploadsReached": "Max. ({{maxUploads}}) atteint",
            "documentDeleted": "Document supprimé",
            "documentDeletedError": "Le document n’a pas été supprimé",
            "getDocumentsError": "Impossible de récupérer les documents"
        },
        "deleteConfirmation": {
            "header": "Éliminer le profil de récupération",
            "body": "Êtes-vous sûr de vouloir éliminer le profil de récupération « {{ recoveryProfileName }} »?",
            "text": "Éliminer"
        },
        "confirmMigrateDocumentsForAllRPS": {
            "header": "Migrer les documents myAgility pour tous les profils de récupération",
            "body": "Êtes-vous sûr(e) de vouloir migrer les documents myAgility pour tous les profils de récupération? Tous les documents existants dont les noms correspondent aux noms des documents migrés seront écrasés.",
            "text": "Migrer"
        },
        "confirmMigrateDocumentsForOneRP": {
            "header": "Migrer les documents myAgility pour le profil de récupération",
            "body": "Êtes-vous sûr(e) de vouloir migrer les documents de myAgility pour le profil de récupération « {{ recoveryProfileName }} »? Tous les documents existants dont les noms correspondent aux noms des documents migrés seront écrasés.",
            "text": "Migrer"
        },
        "salesforceId": "Saisir l’identifiant de compte Salesforce",
        "associatePortalToSalesforceTooltip": "Identifiant de compte de Salesforce",
        "associate": "Associer",
        "associatePortalToSalesforceAccount": "Associer le portail au compte Salesforce",
        "associateToSalesforceAccount": "Associer le portail au compte Salesforce",
        "msgSuccessAssociatedToSalesforceAccount": "Portail associé avec succès au compte Salesforce",
        "msgErrorAssociatedToSalesforceAccount": "Erreur lors de l’association du portail au compte Salesforce",
        "alert": {
            "clarifyAlertAction": {
                "header": "Statut d'[[alert]]",
                "body": "Veuillez confirmer que vous souhaitez passer en état d'[[alert]]. Si vous sélectionnez Oui, un responsable de déclaration vous contactera sous peu par téléphone ou par e-mail.",
                "text": "Oui"
            },
            "title": "Sélectionner les [[locations]] couverts à mettre en [[alert]]",
            "searchTooltip": "Rechercher des lieux couverts",
            "confirmAlert": {
                "header": "Envoyer une demande d’[[alert]] pour les lieux couverts",
                "body": "Êtes-vous sûr(e) de vouloir envoyer une demande d’[[alert]] pour {{ numLocations }} [[location]](s) couvert(s)?",
                "text": "[[Alert]]"
            },
            "msgSuccessAlert": "Demande d’[[alert]] envoyée avec succès",
            "msgErrorAlert": "Échec de l’envoi de la demande d’[[alert]]",
            "msgSelectRecoveryProfiles": "Sélectionner au moins un emplacement couvert"
        }
    },
    "passwordPolicies": {
        "lblPasswordPolicyHeader": "Votre mot de passe doit contenir les éléments suivants :",
        "msgPasswordPolicyFail": "Le mot de passe ne répond pas aux règles de complexité.",
        "msgPasswordPolicyLoading": "Chargement des politiques...",
        "msgPasswordPolicyMinCharacters": "Au moins {{number}} caractères",
        "msgPasswordPolicyMinLowercaseCharacters": "Au moins {{number}} lettres minuscule(s)",
        "msgPasswordPolicyMinNumberCharacters": "Au moins {{number}} numéro(s)",
        "msgPasswordPolicyMinUppercaseCharacters": "Au moins {{number}} lettres majuscule(s)",
        "msgPasswordPolicyNoneFound": "Aucune politique de mot de passe trouvée.",
        "msgPasswordPolicySpecialCharacters": "Au moins {{number}} caractère(s) spécial(ux)",
        "msgPasswordsMustMatch": "Les mots de passe ne correspondent pas."
    },
    "profile": {
        "btnChangePassword": "Modifier le mot de passe",
        "btnSaveProfile": "Sauvegarder et terminer",
        "btnValidateAddress": "Valider l’adresse",
        "lblGroups": "Groupes",
        "lblDepartments": "[[Departments]]",
        "lblNoOtherLocations": "Aucun autre [[location]]",
        "lblNonSelected": "Aucune sélection",
        "lblDefaultLocation": "DÉFAULT ([[location]] principal de l’utilisateur s’il travaille à plusieurs [[locations]])",
        "lblOfficePhone": "Téléphone de bureau",
        "lblOfficePhoneExt": "Poste",
        "lblOfficePhoneAllowSMS": "Téléphone de bureau",
        "lblPersonalEmail": "Courriel personnel",
        "lblPersonalPhone": "Téléphone personnel (téléphone qui prend en charge les messages texte recommandé)",
        "lblAdditionalPersonalPhone": "Téléphone personnel supplémentaire",
        "lblMessagingEmail": "Courriel (recevra tous les courriels)",
        "lblMobilePhone": "Téléphone mobile (recevra tous les messages texte et appels vocaux)",
        "lblPersonal": "Autre",
        "lblDefaultContact": "Défaut",
        "lblWorkTitle": "Intitulé d’emploi",
        "lblUserFirstName": "Prénom",
        "lblUserLastName": "Nom de famille",
        "lblAccessMyBookmarks": "Accéder à mes signets",
        "lblAccessMyDocuments": "Accéder à mes documents",
        "lblProfileInformation": "Informations sur le profil",
        "lblMyProfileHeader": "Mon profil",
        "lblMyCrisisTeam": "Mon équipe de crise",
        "lblEmailAddress": "Courriel",
        "lblCoreInformation": "Informations de base",
        "lblNoDepartmentMembership": "Vous n’êtes membre d’aucun [[department]]",
        "lblNoGroupMembership": "Vous n’êtes membre d’aucun groupe",
        "lblQuickLinks": "Liens rapides",
        "msgSaveProfile": "Votre profil a été sauvegardé",
        "errorSaveUserCustomFieldsFailed": "Échec de la sauvegarde des champs personnalisés de l’utilisateur.",
        "errorSaveUserProfile": "Échec de la sauvegarde du profil utilisateur.",
        "lblChangePhoto": "modifier la photo",
        "lblUserProfileImage": "Image du profil utilisateur",
        "lblTenant": "Locataire",
        "lblSms": "Message texte",
        "lblVoice": "Voix",
        "lblAddressMain": "Adresse principale",
        "lblAddressMainCountry": "Pays",
        "lblAddressMainAddress1": "Adresse",
        "lblAddressMainAddress2": "Adresse 2 (suite, bâtiment, etc.)",
        "lblAddressMainCity": "Ville",
        "lblAddressMainState": "Province/État/région",
        "lblAddressMainZip": "Code postal/Zip",
        "lblAddressMainAdditionalLocationInfo": "Informations supplémentaires sur l’[[location]]",
        "lblAddressMainDepartment": "[[Department]]",
        "lblAddressMainFloor": "Étage",
        "lblUserLanguage": "Langue",
        "menuPickCountry": "Choisir un pays...",
        "errorCommunicationPreferencesMinimumOne": "Au moins une (1) préférence de communication doit être active",
        "errorNoPhoneNumberCannotDisableEmail": "Aucun numéro de téléphone par défaut n’a été saisi, les préférences de communication par courriel ne peuvent pas être désactivées",
        "errorNoEmailsCannotDisableVoiceSms": "Aucun courriel par défaut n’a été saisi, les messages texte et les messages VOCAUX ne peuvent pas être désactivés tous les deux",
        "errorNoContactMethodsSupplied": "Au moins une méthode de contact doit être fournie pour un utilisateur",
        "errorCustomFieldsInvalid": "Remplir les champs personnalisés requis",
        "errorUnableToLoadCountries": "Impossible de récupérer les pays",
        "lblWelcomeToPreparis": "Bienvenue chez Preparis",
        "lblInitialLoginMessage": "Merci d’avoir choisi Preparis. Nous voyons que c’est la première fois que vous vous connectez. Veuillez prendre un moment pour examiner les informations sur cette page afin de vous assurer qu’elles sont exactes.",
        "lblOptInMobileSms": "Recevoir des messages texte mobiles?",
        "lblOptInMobileVoice": "Recevoir des messages vocaux mobiles?",
        "lblOptInPersonalSms": "Recevoir des messages texte personnels?",
        "lblOptInAdditionalPersonalSms": "Recevoir des messages texte personnels supplémentaires?",
        "lblOptInPersonalVoice": "Recevoir des messages vocaux personnels?",
        "lblOptInAdditionalPersonalVoice": "Recevoir des messages vocaux personnels supplémentaires?",
        "lblOptInPersonalEmail": "Recevoir des courriels personnels?",
        "lblOptInBusinessEmail": "Recevoir des courriels de bureau?",
        "lblOptInWorkSms": "Recevoir des messages texte de bureau?",
        "lblOptInWorkVoice": "Recevoir des messages vocaux de bureau?",
        "lblManuallySetPassword": "Définir manuellement le mot de passe",
        "lblFloor": "Étage",
        "lblAdditionalInfo": "Informations additionnelles",
        "lblAdditionalLocations": "Autres [[locations]] où l’utilisateur travaille",
        "lblUserActiveStatus": "Actif",
        "lblUserDeleteStatus": "Supprimé",
        "lblUserInactiveStatus": "Inactif",
        "lblUserPendingStatus": "En attente",
        "lblUserLockedStatus": "Verrouillé",
        "lblUserStatusLegendActiveMessageOnly": "(l’utilisateur recevra des messages)",
        "lblUserStatusLegendDefault": "(l’utilisateur est enregistré et peut se connecter)",
        "lblUserStatusLegendInactive": "(l’utilisateur ne peut pas accéder au portail ni recevoir de messages)",
        "lblUserStatusLegendPending": "(l’utilisateur n’a pas accepté son invitation)",
        "msgManuallySetPassword1": "Normalement, les nouveaux utilisateurs reçoivent un courriel d’invitation contenant un lien leur permettant de créer leur propre mot de passe.",
        "msgManuallySetPassword2": "Si vous saisissez un mot de passe ici, vous devrez communiquer verbalement le mot de passe.",
        "msgUserPasswordChangedSuccess": "Le mot de passe de l’utilisateur a été modifié avec succès.",
        "msgUserPasswordChangedFail": "Échec de la modification du mot de passe utilisateur.",
        "msgSaveUser": "L’utilisateur {{firstName}} {{lastName}} a été sauvegardé avec succès.",
        "msgSaveUserImageSuccess": "La photo de l’utilisateur a été sauvegardée avec succès.",
        "errorSavUserImageFailed": "Échec de la sauvegarde de la photo de l’utilisateur.",
        "magUserNameExist": "Ce nom d’utilisateur est déjà utilisé par un autre utilisateur",
        "msgInvalidName": "Veuillez remplacer le nom généré pour mettre à niveau cet utilisateur activé",
        "save": "Sauvegarder",
        "initialLogin": {
            "btnText": "OK"
        }
    },
    "register": {
        "lblMyInformation": "Mes informations",
        "lblContactInformation": "Coordonnées",
        "lblAddress": "Adresse",
        "lblAddress2": "Adresse 2",
        "lblCity": "Ville",
        "lblState": "État",
        "lblZip": "Code postal/Zip",
        "lblCountry": "Pays",
        "lblLocationBuilding": "[[Locations]]/bâtiments au sujet desquels recevoir des notifications",
        "firstName": "Prénom",
        "lastName": "Nom de famille",
        "lblLastName": "Nom de famille",
        "lblFirstName": "Prénom",
        "lblMobilePhone": "Téléphone mobile",
        "lblReceiveSMS": "Recevoir un message texte",
        "lblReceiveVoice": "Recevoir un message vocal",
        "lblReceiveEmail": "Recevoir un courriel",
        "lblLoading": "Chargement...",
        "lbSelectLocations": "Sur quels [[locations]] souhaitez-vous recevoir des notifications importantes?",
        "lblValidateAddress": "Valider l’adresse",
        "msgRegisterCodeInvalid": "Impossible de s’enregistrer avec le code d’enregistrement, veuillez essayer un autre code d’enregistrement",
        "msgRegisterCodeFail": "Échec de l’enregistrement avec le code d’enregistrement, veuillez réessayer plus tard.",
        "msgLocationRequired": "Au moins un [[location]] est requis pour recevoir des notifications",
        "msgCommunicationMethodRequired": "Au moins une méthode de communication est requise pour recevoir des notifications",
        "msgRegisterFailed": "Échec de l’enregistrement du compte utilisateur, veuillez réessayer plus tard.",
        "msgRegistrationComplete": "Félicitations! Vous êtes maintenant inscrit(e) pour recevoir les notifications importantes de {{ portalName }}. Pour modifier vos préférences à tout moment, vous pouvez vous connecter à l’adresse {{ portalUrl }}",
        "msgRegisterUser": "Enregistrement du compte utilisateur, veuillez patienter...",
        "msgSaveUser": "Sauvegarde des informations utilisateur, veuillez patienter...",
        "msgSaveUserSuccess": "Informations utilisateur sauvegardées avec succès",
        "msgSaveUserFailed": "Échec de la sauvegarde des informations utilisateur, veuillez réessayer plus tard.",
        "msgRemoveUser": "Élimination du compte utilisateur, veuillez patienter...",
        "msgRemoveUserSuccess": "Votre compte est éliminé.",
        "msgRemoveUserFail": "Échec de l’élimination de votre compte, veuillez réessayer plus tard."
    },
    "layout": {
        "aAllCallinAnnouncements": "Toutes les annonces d’appel",
        "aAllSplashMessage": "Tous les messages de démarrage",
        "aCommunicationsDashboard": "Tableau de bord des communications",
        "aCreateSlashMsg": "Créer un message de démarrage",
        "aCrisisTeam": "Équipe de crise",
        "aEmergencyContacts": "Contacts d’urgence",
        "aRecordCallinAnnouncement": "Enregistrer une annonce d’appel",
        "aSendEmergencyMessage": "Envoyer le message",
        "aStartConcall": "Démarrer une conférence téléphonique",
        "lblCommunicateIncident": "Communiquer sur un événement",
        "lblHelpAndAssistance": "Aide et assistance",
        "lblIncidentHistory": "Historique des incidents",
        "lblIncidents": "Incidents",
        "lblManageIncidents": "Gérer les communications",
        "lblNewIncident": "Nouvel incident",
        "lblQuickSnapshot": "Instantané rapide",
        "lblViewOverview": "Voir l’aperçu",
        "aPrivacy": "Confidentialité",
        "aScheduledMessages": "Messages programmés",
        "aSupport": "Support",
        "aTermsOfService": "Conditions d’utilisation",
        "lblAccessAndPermissions": "Droit d’accès",
        "lblAdditionalResources": "Plus de ressources",
        "lblBookmark": "Signet",
        "lblChecklists": "Listes de contrôle",
        "lblCompanyProfile": "Profil de la société",
        "lblDepartments": "[[Departments]]",
        "lblDocuments": "DOCUMENTS",
        "lblEntities": "Entités",
        "lblFeaturedResources": "Ressource en vedette",
        "lblGroups": "Groupes",
        "lblHelp": "Aide",
        "lblHome": "Accueil",
        "lblInTouch": "Nous sommes toujours en contact avec notre [[client]].",
        "lblLoading": "Chargement",
        "lblManage": "Gérer",
        "lblMessagingSettings": "[[Settings]] de messagerie",
        "lblMessagingSetup": "Configuration de la messagerie",
        "lblMyPreparis": "Mon Preparis",
        "lblMyProfile": "Mon profil",
        "lblNavigatePortal": "Naviguer sur le portail",
        "lblNewResources": "Nouvelles ressources",
        "lblReports": "[[Reports]]",
        "lblResources": "Ressources",
        "lblResourceTypes": "Types de ressources",
        "lblRoles": "Rôles",
        "lblSavedMessages": "Messages sauvegardés",
        "lblSecurity": "Sécurité",
        "lblSettings": "[[Settings]]",
        "lblSignout": "Désinscription",
        "lblSystemEmails": "Courriels système",
        "lblSystemSettings": "[[Settings]] du système",
        "lblTabletops": "Simulations",
        "lblTenants": "Locataires",
        "lblThreats": "Menaces",
        "lblTraining": "FORMATION",
        "lblUserInitiatedMessages": "Incidents déclenchés par l’utilisateur",
        "lblUsers": "Utilisateurs",
        "lblWebinars": "Webinaires",
        "lblHierarchy": "Hiérarchie",
        "lblComplianceCalendar": "Calendrier de conformité",
        "lblScopes": "[[Scopes]]",
        "lblTeams": "[[Teams]]",
        "lblTeamMembers": "[[Team Members]]",
        "lblItems": "[[Items]]",
        "lblIssues": "[[Issues]]",
        "lblExternalResources": "[[External Resources]]",
        "lblAssets": "[[Assets]]",
        "lblPlans": "[[Plans]]",
        "lblAdministration": "Administration",
        "lblNotifications": "Notifications",
        "lblStorageLocations": "Emplacements de stockage",
        "lblComplianceCategories": "Catégories de conformité",
        "lblExternalResourceTypes": "Types de [[External Resource]]",
        "lblIq": "iQ",
        "lblPortalSnapshot": "Instantané des portails",
        "lblPortalsManagement": "Gestion des portails",
        "lblPortalManagement": "Gestion du portail",
        "lblPortalResources": "Ressources du portail",
        "lblPortalResourceViews": "Vue des ressources du portail",
        "lblBulkUploadHistory": "Historique des téléversements groupés",
        "lblApiManagement": "Gestion des API",
        "lblApiManagementView": "Intégrations tierces",
        "lblPlannerManagement": "Gestion du [[Planner]]",
        "lblCustomFields": "Champs personnalisés",
        "panic": "Tableau de bord du mode Panique",
        "lblEmergencyButtonDashboard": "[[Alerts]] lancée par mobile",
        "lblBranding": "Personnalisation",
        "lblSelfRegistration": "Activer la réception",
        "scheduledConferenceCalls": "Conférences téléphoniques programmées",
        "messaging": "Messagerie",
        "lblRecoveryProfiles": "PROFILS DE RÉCUPÉRATION",
        "recoveryProfiles": "Profils de récupération",
        "lblGeofences": "Géo-repérages",
        "lblPointsOfInterest": "Points d’intérêt",
        "lblIncidentTypes": "Types d’incidents",
        "lblPublicAlertSubscriptions": "Abonnements aux [[alerts]]",
        "lblIncidentTriggers": "Déclencheurs d’incidents",
        "lblPanicConfig": "Configuration de panique",
        "lblPublicAlerts": "[[Alerts]] publiques",
        "lblGeolocations": "Géolocalisations",
        "lblPortals": "Portails",
        "Alerts": "[[Alerts]]",
        "planManagement": "Gestion du plan",
        "training": "Formation",
        "reports": "[[Reports]]",
        "settings": "[[Settings]]",
        "alert": "[[Alert]]",
        "sendMessage": "Envoyer le message",
        "admin": "Administrateur",
        "privacy": "Confidentialité",
        "aboutUs": "À propos de nous",
        "incidentManagement": "Gerente de Incidentes",
        "lblMobileEmergencyButtons": "Paramètres d’[[alert]] lancée par mobile",
        "lblConfigureBulkUpload": "Configurer le téléchargement groupé",
        "planning": "Planification",
        "lblChangeLanguage": "Changer de langue",
        "lblExercises": "Des Exercices",
        "lblExerciseDashboard": "Tableau de Bord",
        "lblExerciseRoadmap": "Feuille de Route",
        "lblExerciseScenarioManager": "Scénarios",
        "lblExerciseReports": "[[Reports]]",
        "lblExerciseSystemScenarios": "Scénarios Système",
        "lblExerciseExerciseTypes": "Types d'Exercices",
        "lblExerciseThreadHazards": "Menace/Dangers",
        "lblIncidentManagementHistory": "Historique des incidents",
        "language": {
            "en": "English",
            "es": "Español",
            "fr": "Français",
            "pt": "Português"
        },
        "changeLanguageError": "Une erreur inattendue s’est produite sur le serveur.  Veuillez contacter le support technique.",
        "calendar": {
            "dayNames": {
                "sunday": "dimanche",
                "monday": "lundi",
                "tuesday": "mardi",
                "wednesday": "mercredi",
                "Thursday": "jeudi",
                "friday": "vendredi",
                "saturday": "samedi"
            },
            "dayNamesShort": {
                "sunday": "dim.",
                "monday": "lun.",
                "tuesday": "mar.",
                "wednesday": "mer.",
                "thursday": "jeu.",
                "friday": "ven.",
                "saturday": "sam."
            },
            "dayNamesMin": {
                "sunday": "dim.",
                "monday": "lu",
                "tuesday": "ma",
                "wednesday": "me",
                "thursday": "je",
                "friday": "ve",
                "saturday": "sa"
            },
            "monthNames": {
                "january": "janvier",
                "february": "février",
                "march": "mars",
                "april": "avril",
                "may": "mai",
                "june": "juin",
                "july": "juillet",
                "august": "août",
                "september": "septembre",
                "october": "octobre",
                "november": "novembre",
                "december": "décembre"
            },
            "monthNamesShort": {
                "january": "janv.",
                "february": "févr.",
                "march": "mars",
                "april": "avr.",
                "may": "mai",
                "june": "juin",
                "july": "juill.",
                "august": "août",
                "september": "sept.",
                "october": "oct.",
                "november": "nov.",
                "december": "déc"
            },
            "today": "Aujourd’hui",
            "clear": "Effacer",
            "weekHeader": "Sem"
        },
        "planner": "[[Planner]]",
        "exerciseManager": "[[Exercise Manager]]",
        "alerts": "[[Alerts]]",
        "footerCopyRight": "Copyright© 2007-{{year}} Preparis. Tous droits réservés."
    },
    "callinAnnouncements": {
        "btnCall": "Appel",
        "btnDeactivate": "Désactiver",
        "btnMakeActive": "Rendre actif",
        "btnNewAnnouncement": "Nouvelle annonce",
        "btnSaveNewAnnouncement": "Sauvegarder l’annonce",
        "errorAnnouncementDeleted": "Un problème est survenu lors de la suppression de cette annonce. Veuillez réessayer plus tard.",
        "errorAtleastOneRecordingOption": "Vous devez spécifier au moins une option d’enregistrement",
        "errorCallToRecordPhoneNumberRequired": "Veuillez indiquer un numéro de téléphone pour votre enregistrement.",
        "errorUnableToRecordMessage": "Impossible d’enregistrer le message pour le moment, veuillez réessayer plus tard.",
        "lblActivatedOn": "Activé le",
        "lblActiveAnnouncement": "Annonce active",
        "lblActiveAnnouncementDtl": "Les utilisateurs entendront ce message lorsqu’ils appelleront.",
        "lblActiveAnnouncementNote": "Il ne peut y avoir qu’une seule annonce active à la fois.",
        "lblAnnouncementActive": "Activation de l’annonce terminée",
        "lblAnnouncementActivity": "Activité d’annonce d’appel",
        "lblAnnouncementDeactivated": "Désactivation de l’annonce réussie.",
        "lblAreYourSureActivate": "Activer l’annonce d’appel",
        "lblAreYouSureActivateWithoutReplace": "{{selectedDescriptionEncoded}} deviendra l’annonce d’appel active.",
        "lblAreYouSureActiveWithReplace": "{{selectedDescriptionEncoded}} deviendra l’annonce d’appel active. {{activeDescriptionEncoded}} sera désactivée.",
        "lblCallToRecord": "Appel à enregistrer",
        "lblCallToRecordInstructions": "Saisir le numéro de téléphone à appeler, puis appuyer sur appel. Vous recevrez un appel dans quelques instants pour ensuite enregistrer votre message. Après avoir enregistré votre message, vous pourrez l’écouter et le réenregistrer si vous le souhaitez.",
        "lblChooseRecordingOption": "Choisir l’une des options suivantes pour enregistrer votre annonce.",
        "lblConfirmDelete": "Confirmation de la suppression",
        "lblDefaultNewAnnouncementTitle": "Nouvelle annonce d’appel",
        "lblHeader": "Annonces d’appel pour {{callInAnnouncementPhoneNumber}}",
        "lblInactiveAnnouncement": "Annonces inactives",
        "lblLastModifiedOn": "Dernière modification le",
        "lblLoading": "Veuillez patienter...",
        "lblMessageColHdr": "Message",
        "lblMessageNameDescColHdr": "Nom/Description",
        "lblNameDesc": "Nom/Description",
        "lblNoActiveAnnouncementsBody": "Le message par défaut du système sera utilisé pour indiquer qu’il n’y a pas d’annonce pour le moment.",
        "lblNoActiveAnnouncementsHdr": "Aucune annonce active",
        "lblNone": "Aucun",
        "lblNote": "Remarque",
        "lblRecordedBy": "Enregistré par",
        "lblRecordedByColHdr": "Enregistré par",
        "lblRecordedColHdr": "Enregistré",
        "lblRecording": "Enregistrement",
        "lblRecordingCanceled": "L’enregistrement a été annulé.",
        "lblStatusColHdr": "Statut",
        "lblTextToSpeech": "Texte à énoncer",
        "mgsCallinNotSetupHdr": "Vos annonces d’appel ne sont pas configurées",
        "msgAnnouncementDeleted": "L’annonce a été supprimée avec succès.",
        "msgAnnouncementSaved": "L’annonce d’appel a été sauvegardée avec succès.",
        "msgCallinNotSetup": "Les annonces d’appel intégrées permettent à vos équipes de crise et à vos employés de se maintenir au courant plus facilement et plus rapidement du développement des informations lors d’un incident. Les annonces d’appel sont une excellente plateforme pour communiquer les nouvelles telles que les informations sur les tempêtes hivernales et les fermetures de bureaux. En enregistrant simplement un message et en l’activant, vos collaborateurs peuvent appeler pour entendre les dernières informations.",
        "msgCallinNotSetup2": "Demander l’ajout de ce service sans frais supplémentaires en contactant",
        "callinAnnouncements": "Annonces d’appels",
        "removeConfirm": {
            "header": "Éliminer l’annonce d’appel",
            "body": "Êtes-vous sûr(e) de vouloir éliminer cette annonce d’appel?",
            "text": "Oui"
        },
        "deactivateConfirm": {
            "header": "Désactiver l’annonce d’appel",
            "body": "L’annonce d’appel {{description}} sera désactivée. L’annonce d’appel par défaut sera utilisée à moins que vous n’activiez une autre annonce.",
            "text": "Oui"
        },
        "showAdvanced": "Autoriser plusieurs annonces d’appel actives",
        "addNewOption": "Ajouter une nouvelle option enfant",
        "addNewSiblingRight": "Ajouter une nouvelle option à droite",
        "addNewSiblingLeft": "Ajouter une nouvelle option à gauche",
        "addNewSiblingAbove": "Ajouter une nouvelle option au-dessus",
        "addNewSiblingBelow": "Ajouter une nouvelle option en dessous",
        "copyOption": "Option de copie",
        "editOption": "Modifier {{ optionPrompt }}",
        "callinAnnouncement": "Annonce d’appel",
        "callInAnnouncementOption": "Option d’annonce d’appel",
        "lblCallInAnnouncementValue": "Appuyer sur {{optionValue}}",
        "lblInstructions": "Instructions",
        "resetToDefaultInstructions": "Réinitialiser les instructions par défaut",
        "newCallinOptionModal": {
            "headerNew": "Créer une nouvelle option d’appel",
            "headerEdit": "Modifier l’option d’appel « {{ optionPrompt }} »",
            "optionValue": "Valeur",
            "optionPrompt": "Invite",
            "optionInstructions": "Instructions",
            "optionCallInAnnouncement": "Annonce d’appel",
            "selectCallInAnnouncementConfirmation": {
                "header": "Sélectionner Annonce d’appel pour l’option",
                "body": "Êtes-vous sûr(e) de vouloir sélectionner « {{ announcementDescription }} » pour l’option?",
                "text": "Sélectionner"
            },
            "btnUnselect": "Désélectionner",
            "btnSelect": "Sélectionner"
        },
        "saveCallinAnnouncementOptionConfirm": {
            "header": "Sauvegarder l’option d’annonce d’appel",
            "body": "Êtes-vous sûr(e) de vouloir sauvegarder l’option d’annonce d’appel « {{ optionPrompt }} »",
            "text": "Sauvegarder"
        },
        "deleteCallinAnnouncementOptionConfirm": {
            "header": "Supprimer l’option d’annonce d’appel",
            "body": "Êtes-vous sûr(e) de vouloir supprimer l’option d’annonce d’appel « {{ optionPrompt }} »",
            "text": "Supprimer"
        },
        "deleteAllCallinAnnouncementOptionsConfirm": {
            "header": "Supprimer toutes les options d’annonce d’appel",
            "body": "Le passage au mode d’annonce d’appel active unique supprimera les options d’annonce d’appel. Êtes-vous sûr(e) de vouloir passer en mode d’annonce d’appel active unique et supprimer les options?",
            "text": "Supprimer et modifier"
        },
        "errors": {
            "optionWithSamePromptError": "Une option d’appel avec l’invite « {{ optionPrompt }} » existe déjà."
        },
        "copyCallinAnnouncementOption": {
            "header": "Copier option d’annonce d’appel « {{ optionPrompt }} »",
            "body": "Sélectionner l’option d’annonce d’appel cible",
            "copySubHierarchy": "Copier la sous-hiérarchie",
            "errorSameOption": "Cette option est en cours de copie",
            "errorDuplicatePrompt": "« {{ optionPrompt }} » a déjà une option enfant avec la même invite",
            "errorMaxChildNodesNumber": "« {{ optionPrompt }} » a déjà {{ maxChildNodes }} des options enfants"
        },
        "msgCopyCallinAnnouncement": {
            "success": "L’option d’annonce d’appel est copiée",
            "error": "Échec de la copie de l’option d’annonce d’appel"
        }
    },
    "iq": {
        "common": {
            "moveError": "Ressource iQ non déplacée",
            "moveTeamTooltip": "Sélectionner l’équipe de destination.",
            "assignToTeamMember": "Affecter à un membre de l’équipe de destination."
        },
        "itemType": {
            "Document": "Document",
            "Storage Item": "Storage Item",
            "Other": "Autre",
            "Task/Reminder": "Tâche/Rappel"
        },
        "columnsSelected": "{0} colonnes sélectionnées"
    },
    "incidentManagement": {
        "lblManageIncident": "Gérer l’incident",
        "createNewIncident": "Créer un nouvel incident",
        "recentIncidents": "Incidents récents",
        "noRecentIncidents": "Aucun incident récent/actif",
        "lblIncidentDetails": "Détails de l’incident",
        "lblIncidentRetrospective": "Rétrospective des Incidents",
        "lblNewIncident": "Créer un nouvel incident",
        "communicationLog": "Journal de communication",
        "addIncidentNotes": "Ajouter des notes d’incident",
        "incidentNotes": "Notes d’incident",
        "addNotes": "Ajouter des notes",
        "resolveIncident": "Résoudre l'incident",
        "viewPlans": "Voir les plans",
        "lblDepartmentPlans": "Plans [[department]]aux",
        "standByAll": "Tout mettre en réserve",
        "standBySelected": "Mettre en réserve la sélection",
        "standDownSelected": "Mettre en retrait la sélection",
        "standDownAll": "Tout mettre en retrait",
        "activateAll": "Tout activer",
        "activateSelected": "Activer la sélection",
        "deactivateSelected": "Désactiver la sélection",
        "deactivateAll": "Tout désactiver",
        "active": "Actif",
        "inactive": "Inactif",
        "standBy": "Mettre en réserve",
        "StandDown": "Mettre en retrait",
        "StandDownAll": "Tout mettre en retrait",
        "Active": "Actif",
        "Stand By": "Mettre en réserve",
        "departmentPlanActivationHeader": "Activation du plan de [[department]]al",
        "locationPlanActivationHeader": "Activation du plan d’[[location]]",
        "departmentPlanActivationBodyMsg": "Êtes-vous sûr(e) de vouloir activer tous les [[departments]]?",
        "locationPlanActivationBodyMsg": "Êtes-vous sûr(e) de vouloir activer tous les [[locations]]?",
        "departmentPlanActivationAssignedBodyMsg": "Êtes-vous sûr de vouloir activer tous vos [[departments]] affectés?",
        "locationPlanActivationAssignedBodyMsg": "Êtes-vous sûr de vouloir activer tous vos [[locations]] affectés?",
        "departmentPlanStandByHeader": "Mise en réserve du plan de [[department]]",
        "locationPlanStandByHeader": "Mise en réserve du plan d’[[location]]",
        "departmentPlanStandByBodyMsg": "Êtes-vous sûr de vouloir mettre en Réserve tous les [[departments]]?",
        "locationPlanStandByBodyMsg": "Êtes-vous sûr de vouloir mettre en Réserve tous les [[locations]]?",
        "departmentPlanStandByAssignedBodyMsg": "Êtes-vous sûr de vouloir mettre en Réserve tous vos [[departments]] affectés?",
        "locationPlanStandByAssignedBodyMsg": "Êtes-vous sûr de vouloir mettre en Réserve tous vos [[locations]] affectés?",
        "departmentPlanDeactivateAllHeader": "Désactivation du plan de [[department]]",
        "locationPlanDeactivateAllHeader": "Désactivation du plan d’[[location]]",
        "departmentPlanDeactivateAllBody": "Êtes-vous sûr de vouloir désactiver tous les [[departments]]?",
        "locationPlanDeactivateAllBody": "Êtes-vous sûr de vouloir désactiver tous les [[locations]]?",
        "departmentPlanDeactivateAssignedBodyMsg": "Êtes-vous sûr de vouloir désactiver tous vos [[departments]] affectés?",
        "locationPlanDeactivateAssignedBodyMsg": "Êtes-vous sûr de vouloir désactiver tous vos [[locations]] affectés?",
        "departmentPlanStandDownAllHeader": "Mise en retrait du plan [[department]]al",
        "locationPlanStandDownAllHeader": "Mise en retrait du plan d’[[location]]",
        "departmentPlanStandDownAllBody": "Êtes-vous sûr de vouloir mettre en Retrait tous les [[departments]]?",
        "locationPlanStandDownAllBody": "Êtes-vous sûr de vouloir mettre en Retrait tous les [[locations]]?",
        "departmentPlanStandDownAssignedBodyMsg": "Êtes-vous sûr de vouloir mettre en Retrait tous vos [[departments]] affectés?",
        "locationPlanStandDownAssignedBodyMsg": "Êtes-vous sûr de vouloir mettre en Retrait tous vos [[locations]] affectés?",
        "notDepartmentPlansAvailable": "Il n'y a pas de plans disponibles.",
        "startHere": "Commencer ici",
        "selectedMembers": "Membres Sélectionnés",
        "incidentHistory": "Historique des incidents",
        "plansActivated": "Plan(s) activé(s)",
        "pagingDescription": "{{ pageStart }}-{{ pageEnd }} sur {{ totalCount }}",
        "enterExerciseMode": "Passer en mode Exercice",
        "exitExerciseMode": "Quitter le mode Exercice",
        "exerciseMode": "Mode Exercice",
        "leaveExerciseTitle": "Mode Exercice",
        "areYouSureLeaveExercise": "Êtes-vous sûr de vouloir quitter l'exercice?",
        "liveIncidents": "Incidents en direct",
        "incidentExercises": "Exercices d'incident",
        "exercise": "Exercice",
        "portalExerciseAssociateToIncident": "Exercice déjà associé à un autre incident",
        "dependencies": {
            "dependentProcesses": "[[Processes]] dépendants",
            "noDependentProcesses": "Aucun [[processes]] dépendant",
            "dependencyListIntoMessage": "Liste des dépendances dans le message",
            "dependencies": "Dépendances"
        },
        "states": {
            "viewAll": "Afficher tout",
            "active": "Actif",
            "resolved": "Résolu",
            "archived": "Archivé",
            "activated": "Activé",
            "deactivated": "Désactivé"
        },
        "conferenceCallStarted": "Conférence téléphonique commencée",
        "emailSent": "Courriel envoyé",
        "messageSent": "Message envoyé",
        "export": {
            "allIncidents": "Tous les incidents",
            "displayedIncidents": "Incidents affichés",
            "header": "Exporter les incidents",
            "body": "Voulez-vous exporter uniquement les {{ x }} incidents affichés ou tous les incidents?",
            "successMsg": "Exportation générée avec succès",
            "errorMsg": "Erreur lors de la génération de l’exportation",
            "fileName": "Incidents.csv"
        },
        "noNotes": "Aucune note d’incident",
        "actionPlans": "Plans d'action de continuité des activités",
        "planActivationProcessStateTypes": {
            "notStarted": "Pas commencé",
            "inProgress": "En cours",
            "skip": "Sauter",
            "done": "Prêt"
        },
        "authorized": "Autorisé à contacter les fournisseurs",
        "contacted": "Fournisseur(s) contacté(s)",
        "mobilized": "Des salariés mobilisés",
        "verified": "Vérifié",
        "applications": "[[Application]](s)",
        "equipment": "[[Equipements]]",
        "confirmReadiness": "Confirmer la préparation",
        "passDue": "En retard",
        "activateDetails": {
            "activateDetails": "Activer les détails",
            "calendarDescription": "Ce calendrier filtrera les prochaines dates critiques."
        },
        "noCriticalDate": "Aucune date critique",
        "noAssignees": "Aucun employé autorisé",
        "noEmployees": "Aucun employé mobilisé",
        "noEquipment": "Aucun équipement vérifié",
        "noApplications": "Aucune [[application]] vérifiée",
        "noVendors": "Aucun fournisseur de contact",
        "noLocation": "Aucun [[location]]",
        "msgUpdateStatusSuccess": "Statut d'activation enregistré",
        "msgUpdateStatusFailed": "Échec de l'enregistrement du statut d'activation",
        "msgActionPlanReady": "Le plan d'action pour {{ process }} est prêt",
        "communicationSidebar": {
            "include": "Comprendre",
            "includeApplicationList": "Liste des applications dans le message",
            "includeEquipmentList": "Liste des équipements dans le message",
            "includeAdditionalInstructions": "Instructions supplémentaires dans le message",
            "attachments": "Pièces jointes",
            "emailAttachmentsSizeError": "La taille des pièces jointes aux courriels dépasse la limite autorisée {{maxSize}} Mo. Le courriel ne peut pas être envoyé.",
            "loadingMessageParticipantsError": "Erreur lors du chargement des participants à la communication"
        },
        "dashboard": {
            "overallProgress": "Progrès global",
            "progressByRto": "Progrès par OTR",
            "escalatedActionPlans": "Plans d'action remontés",
            "escalatedActionPlansDescription": "Plans d'action avec problèmes remontés",
            "departmentProgress": "Progrès du [[department]]",
            "locationProgress": "Progrès de l’[[location]]",
            "escalatedIssues": "[[Issues]] remontés",
            "allIssues": "Tous les problèmes"
        },
        "issues": {
            "issue": "[[Issue]]",
            "issuesListTitle": "Mes problèmes",
            "reportIssue": "Signaler un problème",
            "issueTitle": "Nom des problèmes",
            "resolved": "Résolu",
            "dateTimeCreated": "Date/heure",
            "lblOwner": "Propriétaire",
            "lblCreatedOn": "Créé le",
            "lblStatus": "Statut",
            "lblDepartment": "[[Department]]",
            "lblProcess": "[[Processes]]",
            "lblCategory": "Catégorie",
            "lblDescription": "Description",
            "addComment": "Ajouter un commentaire",
            "escalateToManagement": "Transférer à la direction",
            "resolveIssue": "Résoudre le problème",
            "reopenIssue": "Rouvrir le problème",
            "edit": "Modifier",
            "status": {
                "70cd9cf8-947a-43a1-8f19-f27b58da065e": "Ouvert",
                "2e70fb00-5551-42f8-8057-a3a45dcfbb19": "Résolu"
            },
            "lblChangeStatus": {
                "70cd9cf8-947a-43a1-8f19-f27b58da065e": "Résoudre le problème",
                "2e70fb00-5551-42f8-8057-a3a45dcfbb19": "Rouvrir le problème"
            },
            "comment": "Commentaire",
            "msgErrorLoadingComments": "Erreur lors du chargement des commentaires sur le problème d'activation du plan.",
            "escalatedIssuesOnly": "[[Issues]] remontés uniquement",
            "issueDetails": "Détails du problème"
        },
        "incidentRetrospective": {
            "incidentName": "Nom de l’incident",
            "incidentType": "Type d’incident",
            "incidentDuration": "Durée de l'incident",
            "incidentCreator": "Incident créé par",
            "description": "Description",
            "activePlans": "Plans activés",
            "issues": "[[Issues]]",
            "Communications": "Communications",
            "executiveSummary": "Résumé",
            "retrospective": "Rétrospective",
            "incidentNotes": "Notes d’incident",
            "department": "[[Department]]",
            "location": "[[Location]]",
            "process": "[[Processes]]",
            "title": "Titre",
            "teamLead": "Chef d’équipe",
            "deliveryMethod": "Méthode de livraison",
            "dateSent": "Date d’envoi",
            "totalRecipients": "Destinataires totaux",
            "viewPlans": "Voir les plans",
            "publishIncidentRetrospective": "Publier la rétrospective des incidents",
            "unResolveIncident": "Réactiver l'incident",
            "startNote": "Commencer à faire ceci",
            "continueNote": "Continuer à faire ceci",
            "stopNote": "Arrêter de faire ceci",
            "incidentInvalid": "L'identifiant de l'incident n'est pas valide",
            "theIncidentNeedsToBeResolve": "L'incident doit être résolu pour voir sa rétrospective",
            "generateAuditReport": "[[Report]] d'audit",
            "errorGeneratingAuditReport": "Erreur lors de la génération du [[report]] d'audit",
            "modalHeader": "Publier la rétrospective des incidents",
            "modalBody": "Êtes-vous sûr de vouloir publier cette rétrospective?",
            "publish": "Publier",
            "modalEditor": "{{field}} pour la rétrospective",
            "retrospectiveSaveSuccessfully": "La rétrospective a été enregistrée avec succès",
            "retrospectiveSaveUnsuccessfully": "La rétrospective n’a pas été enregistrée avec succès",
            "summaryPlaceholder": "Saisir les informations de résumé relatives à l'incident / à l'exercice",
            "startPlaceholder": "P. ex., nous devrions programmer plus d'exercices",
            "continuePlaceholder": "P. ex., communiquer efficacement",
            "stopPlaceholder": "P. ex., Utiliser le même scénario pour les exercices",
            "incidentReactivatedSuccessfully": "Incident réactivé avec succès",
            "incidentReactivatedUnsuccessfully": "Incident pas réactivé avec succès",
            "reactivateModalHeader": "Réactiver l'incident",
            "reactivateModalBody": "Êtes-vous sûr(e) de vouloir réactiver cet incident?"
        },
        "contactTeamLead": "Contacter [[Team Lead]]",
        "contactSubjectMatterExpert": "Contacter SME",
        "subjectMatterExpert": "SME"
    },
    "exerciseManager": {
        "scenarios": "Scénarios",
        "systemScenarios": "Scénarios de système",
        "launchExercise": "Lancer l’exercice",
        "exerciseManager": "[[Exercise Manager]]",
        "assignedScenarios": "Scénarios affectés",
        "assignedExercises": "Exercices affectés",
        "newExerciseType": "Nouveau type d'exercice",
        "newSystemExerciseType": "Nouveau type d'exercice de système",
        "newThreadHazard": "Nouveau/nouvelle menace/risque",
        "newSystemThreadHazard": "Nouveau/nouvelle menace/risque pour le système",
        "systemExerciseType": "Type d'exercice de système",
        "systemThreadHazard": "Menace/risque de système",
        "roadmap": {
            "theWeekOf": "La semaine de...",
            "exerciseRoadmap": "Feuille de route de l'exercice",
            "weekOf": "Semaine de",
            "roadmapDescription": "Cette feuille de route fournit un aperçu rapide de vos exercices programmés. Pour programmer un nouvel exercice, ouvrez le panneau de droite et sélectionnez le plan que vous souhaitez programmer pour démarrer le [[processes]].",
            "scheduleExerciseDescription": "Planifiez le plan sur lequel vous souhaitez effectuer un exercice ou un test.",
            "openExercise": "Ouvrir l’exercice",
            "firstScheduled": "Aller au premier exercice programmé",
            "noFirstScheduledExercise": "Aucun exercice futur n'est prévu pour {{name}}",
            "weekOption": "4 Semaines",
            "monthOption": "12 Mois"
        },
        "scenarioManager": {
            "60024": "Un scénario d'exercice existant porte déjà ce nom",
            "scenarioName": "Nom du scénario",
            "exerciseType": "Type d'exercice",
            "hazardType": "Menace/risque",
            "description": "Description",
            "assumptions": "Acceptations",
            "objectives": "Objectifs",
            "expectations": "Attentes",
            "metrics": "Métrique",
            "selectAExerciseType": "Sélectionner un type d'exercice",
            "selectAHazardType": "Sélectionner un type de menace/risque",
            "successOnSave": "Le scénario a été enregistré avec succès",
            "clear": "Effacer"
        },
        "sideMenu": {
            "deleteScenario": "Supprimer le scénario",
            "loadScenario": "Charger le scénario",
            "successOnDelete": "Le scénario a été supprimé avec succès",
            "failedToDelete": "Le scénario n’a pas été supprimé avec succès",
            "removeConfirmationHeader": "Éliminer le scénario",
            "removeConfirmationBody": "Êtes-vous sûr(e) de vouloir éliminer le scénario{{ scenarioName }}?",
            "sideMenuTitleInfo": "Créer ou charger un scénario afin qu'il puisse être utilisé dans de futurs exercices.",
            "scenarios": "Scénarios",
            "overrideBody": "Un scénario du même nom existe déjà",
            "numberOfProcesses": "Nombre de [[processes]]",
            "failedToGetExerciseTargetsInfo": "Une erreur s'est produite lors de la tentative d'obtention des informations sur les objectifs d'exercice.",
            "notScheduled": "Non programmé",
            "newScenario": "Créer un scénario",
            "copyScenario": "Copier le scénario comme nouveau"
        },
        "modalEditor": "{{field}} pour le scénario",
        "exercises": {
            "60033": "Calendrier d'exercices qui se chevauchent",
            "60035": " L'exercice est associé à un incident",
            "exercises": "Exercices",
            "updateScenario": "Mettre à jour le scénario",
            "exerciseDate": "Date d'exercice",
            "datePlaceholder": "Sélectionner la date de début et de fin",
            "exerciseName": "Nom de l'exercice",
            "details": "Détails",
            "selectedTesters": "Testeurs sélectionnés",
            "modalEditor": "{{field}} pour l'exercice",
            "startTime": "Heure de début",
            "endTime": "Heure de fin",
            "startAndEndDate": "Date de début/fin",
            "startDatePlaceholder": "Sélectionner une date de début",
            "endDatePlaceholder": "Sélectionner une date de fin",
            "processes": "[[Processes]]",
            "successOnSave": "L'exercice a été enregistré avec succès",
            "departmentInvalid": "Le [[department]] n’est pas valide",
            "locationInvalid": "L'[[location]] n'est pas valide",
            "testerAddedSuccessfully": "Testeur ajouté avec succès",
            "testerRemoveSuccessfully": "Le testeur a été éliminé avec succès",
            "testerAddedUnsuccessfully": "Testeur pas ajouté avec succès",
            "testerRemoveUnsuccessfully": "Testeur pas supprimé avec succès",
            "scheduleAnExerciseFor": "Programmer un exercice pour",
            "deleteExerciseSuccess": "Exercice éliminé",
            "deleteExerciseFail": "Échec de l’élimination de l'exercice",
            "saveScenario": "Enregistrer le scénario"
        },
        "exerciseTypeModal": {
            "title": "Type d'exercice",
            "desc": "Ajouter ou modifier un type d'exercice à utiliser pour ce [[exercise manager]]",
            "typeNamePlaceholder": "Nom du type d'exercice",
            "exerciseTypes": "Exercices",
            "exerciseTypeInUseError": "L'exercice est affecté à des scénarios ou à des exercices actifs",
            "duplicateExerciseTypeNameError": "Nom du type d'exercice en double",
            "systemExerciseTypeEditMessage": "Les types d'exercices de système ne peuvent pas être modifiés.",
            "system": {
                "header": "Éliminer l'exercice",
                "body": "Voulez-vous éliminer ce type d'exercice uniquement de ce portail ou de tous les portails?",
                "removeFromThisPortal": "Supprimer de ce portail",
                "removeFromAllPortals": "Supprimer de tous les portails"
            },
            "portal": {
                "header": "Éliminer l'exercice",
                "body": "Êtes-vous sûr(e) de vouloir éliminer ce type d’exercice?",
                "ok": "OK"
            }
        },
        "threadHazardModal": {
            "title": "Menace/risque",
            "desc": "Ajouter ou modifier un(e) menace/risque à utiliser pour ce [[exercise manager]]",
            "threadHazardNamePlaceholder": "Nom de la menace / du risque",
            "threadHazards": "menace/risques",
            "threadHazardInUseError": "Le risque/menace est affecté à des scénarios ou exercices actifs",
            "duplicateThreadHazardNameError": "Nom de mance/risque en double",
            "systemThreadHazardEditMessage": "Les menaces/risques de système ne peuvent pas être modifié(e)s.",
            "system": {
                "header": "Supprimer la menace / le risque",
                "body": "Voulez-vous éliminer ce(tte) menace/risque uniquement de ce portail ou de tous les portails?",
                "removeFromThisPortal": "Supprimer de ce portail",
                "removeFromAllPortals": "Supprimer de tous les portails"
            },
            "portal": {
                "header": "Supprimer la menace / le risque",
                "body": "Êtes-vous sûr(e) de vouloir éliminer ce(tte) menace/risque?",
                "ok": "OK"
            }
        }
    },
    "selectPortalFunctionalAreaForDepartmentModal": {
        "headerLabel": "Associer le [[department]] de [[planner]]",
        "searchItemLabel": "Rechercher les [[departments]] de [[planner]]",
        "currentItemLabel": "[[Department]] de [[planner]] actuellement associé",
        "resultLabel": "[[Departments]] de [[planner]]",
        "explanationText": "Le nom du [[department]] de [[planner]] sélectionné sera modifié pour correspondre au nom du [[department]].",
        "matchingNameFAAssociationMessage": "Un [[Department]] de [[planner]] du même nom « {{name}} » a été trouvé. Souhaitez-vous lier ces [[departments]]?",
        "UnAssociateMessage": "Êtes-vous sûr(e) de vouloir dissocier le « {{name}} » de ce [[department]]?",
        "errFailedToLoad": "Erreur lors du chargement des [[departments]] de [[planner]] disponibles",
        "linkDescription": "Créer un nouveau [[department]] de continuité des activités{{departmentName}} et le lier au [[department]]."
    },
    "textEditor": {
        "tags": "Mots Clés",
        "element": "Élément"
    }
}
