{
    "risk": {
        "lblRiskMenuTitle": "[[Risk]]",
        "lblRiskSetupMenuTitle": "Setup",
        "msgNoRiskConfiguration": "[[Risk]] RPN [[settings]] are not configured",
        "headerMenu": {
            "riskLanding": "Dashboard",
            "riskSetup": "Setup",
            "riskDistribution": "Distribution",
            "riskThreats": "Threats",
            "rpnConfiguration": "Configuration",
            "riskAnalytics": "Analytics",
            "assessments": "Assessments",
            "assessment": "Assessment",
            "assessmentDetails": "Assessment Details",
            "riskInitiative": "Initiatives",
            "initiativeDetails": "Initiative Details"
        },
        "threats": {
            "typicalImpactDescription": "Typical Impact Description",
            "rpnConfiguration": "RPN Configuration",
            "riskAnalytics": "[[Risk]] Analytics",
            "assessments": "Assessments",
            "impactDescription": "Impact Description",
            "threats": "Threats",
            "newThreat": "New Threat",
            "deleteThreat": "Delete Threat",
            "selectType": "Select a Type",
            "duplicateThreatTypeName": "Duplicate type name",
            "systemThreat": "System Threat",
            "newSystemThreat": "New System Threat",
            "system": {
                "header": "Remove Threat",
                "body": "Do you want to remove this threat only from this portal or all portals?",
                "removeFromThisPortal": "Remove from this portal",
                "removeFromAllPortals": "Remove from all portals"
            },
            "portal": {
                "header": "Remove Threat",
                "body": "Are you sure you want to remove this threat?",
                "ok": "OK"
            },
            "copyThreatAsNew": "Copy threat as new"
        },
        "rpnConfiguration": {
            "formulaPlaceholder": "Select a Formula",
            "statusPlaceholder": "Select a Status",
            "approvalSystem": "Approval System",
            "prePopulateAnswers": "Use Prior Assessment Responses",
            "prePopulatePlaceholder": "Pre-Populate",
            "formulaSaveSuccessfully": "The formula has been save successfully",
            "formulaSaveUnSuccessfully": "The formula has been save unsuccessfully",
            "riskBandConfiguration": "[[Risk]] bands organize your [[risk]] priority numbers into clear terms. Set the number of [[risk]] bands you want to use for your [[risk]] assessments.",
            "equations": {
                "e0783df6-7ea6-496e-a3ae-0bd61e3d06d2": {},
                "e0783df6-7ea6-496e-a3ae-0bd61e3d06d": {},
                "13fcb83c-d1a2-4135-9e79-50bddff5dcdc": {},
                "f84ff46e-15a3-4348-b958-9ef58a434738": {},
                "610af901-b8cd-4ec2-8673-ca62dcf6c595": {},
                "dc52ab2d-ae1f-4e80-b11c-fc921cb6e2af": {}
            },
            "riskBandsKeys": {
                "1": "Low",
                "2": "Medium",
                "3": "High",
                "4": "Medium-Low",
                "5": "Medium-High"
            },
            "verbiage": {
                "riskBand": "[[Risk]] bands organize your [[risk]] priority numbers into clear terms. Set the number of [[risk]] bands you want to use for your [[risk]] assessments.",
                "approvalSystem": "If set to Yes, an assessment won't be deemed finished until an approver has looked it over and given their approval. Once the participant submits their responses, an email will be sent to the approver notifying them.",
                "usePriorAssessmentResponses": "If set to Yes, answers from prior assessments will be used for new assessments. The person taking the assessment has the ability to alter the values as needed before submitting their answers.",
                "equation": "Select the assessment equation you want to use for your business continuity assessments. It is applied in the RPN calculation, which indicates the relative [[risk]] profile."
            },
            "impact": "Impact",
            "probability": "Probability",
            "mitigation": "Mitigation",
            "multiplier": "Multiplier",
            "detection": "Detection",
            "riskBands": "[[Risk]] Bands",
            "assessmentEquation": "Assessment Equation",
            "numberOfRiskBands": "Number of [[Risk]] Bands"
        },
        "assessment": {
            "welcomeAssessment": "Welcome to the assessment creation wizard",
            "typeQuestion": "What type of assessment would you like to perform?",
            "selectAnApprover": "Select an Approver",
            "selectApprover": "Select Approver",
            "reviewEmail": "Review E-mail",
            "pickTargetType": "Pick your Target Type",
            "pickTargets": "Pick your Targets",
            "sendAssessments": "Send Assessments",
            "selectedTargets": "Selected Targets",
            "pickTargetTypeDescription": "Please select the type of assessment you would like to create.",
            "pickTargetsDescription": "Please select the {{entity}} that you wish to do an assessment on.",
            "sendAssessmentsDescription": "Please set the recipient, Approver and due date for the assessment, then click Send & Finish to send the assessment invitation.",
            "selectRecipient": "Select recipient",
            "sendAssessmentRequirementsError": "Please make sure each assessment has a recipient, approver, and due date",
            "create": "Create Assessment",
            "riskPriorityNumber": "[[Risk]] Priority Number",
            "assessmentInvalid": "The assessment is invalid",
            "saveAndContinue": "Save & Continue",
            "selectStatus": "Select Status",
            "targetName": "Target Name",
            "welcomeAssessmentUser": "Welcome, {{user}}",
            "createAssessmentWizard": "Create Assessment Wizard",
            "welcomeAssessmentDescription": "Below you will see the number of business threats that you have been assigned to analyze within this assessment. Please review each threat and answer the [[risk]] factors the best that you can, these values are used to calculate the [[Risk]] Priority Number (RPN) value of the target.",
            "welcomeAssessmentConditions": [
                "Your answers are automatically saved, you can take a break while performing the assessment.",
                "You will have a chance to review and edit your answers before submitting them."
            ],
            "threatsToAssess": "Threats to assess",
            "timeForAssessments": "Should take less than:",
            "getStarted": "Get Started",
            "minutesToCompleteAssessment": "{{minutes}} minutes",
            "minuteToCompleteAssessment": "{{minute}} minute",
            "estimatedTimeToCompleteAssessment": "Estimated time to complete",
            "accountingThreats": "{{completed}} of {{available}}",
            "removeAssessment": "Remove Assessment",
            "removeAssessmentDescription": "Are you sure you want to remove this assessment?",
            "searchTargetName": "Search Target Name",
            "searchRecipientName": "Search Recipient Name",
            "searchPriorityNumber": "Search Priority Number",
            "selectCreationDate": "Select Creation Date",
            "deleteAssessment": "Delete Assessment",
            "completeAssessment": "Complete Assessment",
            "statusList": {
                "open": "Open",
                "waitingApproval": "Waiting Approval",
                "abandoned": "Abandoned",
                "closed": "Closed",
                "archived": "Archived",
                "deleted": "Deleted",
                "inProgress": "In Progress"
            },
            "sendEmailDescription": {
                "selectDueDate": "",
                "selectApprover": "",
                "reviewEmail": "",
                "sendFinish": ""
            },
            "loadingAssessment": "Loading assessment...",
            "assessmentExistHelperText": "When search results are highlighted in yellow, it means they have an open assessment. If a new assessment is created, the old ones will be closed and marked as abandoned.",
            "assessmentNotFound": "Unable to find assessment.",
			"assessmentFailedStart": "Failed to start assessment.",
            "targetType": {
                "Department": "[[Department]]",
                "Location": "[[Location]]",
                "Company": "Company"
            }
        },
        "threatDetail": {
            "maximum": "Maximum",
            "minimum": "Minimum",
            "submitAssessment": "Submit Assessment",
            "factorsMinMax": {
                "impact": {
                    "min": "Least amount of Impact",
                    "max": "High Impact",
                    "verbiage": "[[Risk]] Impact provides an estimation of the possible losses that are connected with a [[risk]]."
                },
                "probability": {
                    "min": "Minimal Likelihood",
                    "max": "Very Likely",
                    "verbiage": "[[Risk]] Probability is figuring out how likely it is that a [[risk]] will happen. This possibility could be based on what has happened in the past."
                },
                "detection": {
                    "min": "Prolonged warning time",
                    "max": "Little to no warning time",
                    "verbiage": "[[Risk]] Detection is used to measure the ability to find [[risks]] before they happen."
                },
                "mitigation": {
                    "min": "Few to no mitigation in place",
                    "max": "Highly Mitigated",
                    "verbiage": "[[Risk]] mitigation is when a company reduces the [[risks]] to its business by making operations more efficient and exposing itself to less [[risk]]."
                },
                "multiplier": {
                    "min": "Minimum ",
                    "max": "Maximum ",
                    "verbiage": "Use to show how you feel about the business threat."
                }
            }
        },
        "assessmentDetails": {
            "assessmentDetails": "Assessment Details",
            "approveAssessment": "Approve Assessment",
            "completionDate": "Completion Date",
            "riskScore": "[[Risk]] Score",
            "threatHazard": "Threat/Hazard",
            "emailRecipient": "Email Recipient",
            "emailApprover": "Email Approver",
            "assessmentSubmittedSuccessfully": "The assessment has been submitted successfully",
            "assessmentSubmittedUnsuccessfully": "The assessment has been submitted unsuccessfully",
            "mainVerbiage": "Below you will see the number of threats that you have been assigned to analyze within this assessment. Please review each threat and answer the [[risk]] factors the best that you can and add comments when clarity is needed, these values are used to calculate the [[Risk]] Priority Number (RPN) value of the {{targetType}}.",
            "titleVerbiage": "[[Risk]] Assessment For {{target}}",
            "dirtyMessageTitle": "Save Changes",
            "dirtyMessageBody": "Would you like to save your changes for {{threatName}}?",
            "msgSaveAssessmentResultFailed": "Failed to save assessment.",
            "assessmentComplete": "This assessment has already been answered, thank you.",
            "declineAssessmentDescription": "Are you certain you want to decline this assessment? The recipient will be sent the comments submitted below.",
            "declineAssessment": "Decline Assessment",
            "assessmentDeclined": "Assessment Declined",
            "approvalComments": "Approval Comments",
            "archiveMessage": "Are you sure you want to archive this assessment?",
            "abandonMessage": "Are you sure you want to abandon this assessment?",
            "closeMessage": "Are you sure you want to close this assessment?"
        },
        "analytics": {
            "highestRiskValue": "Highest [[Risk]] Value",
            "columnsSelected": "{{columns}} columns selected",
            "searchAssessment": "Search Assessment",
            "targetTypeName": "Target Type",
            "closeDate": "Close Date"
        },
        "initiative": {
            "newInitiative": "New Initiative",
            "listHelperText": "Welcome to Preparis [[Risk]]! Below is a list of [[risk]] initiatives where you can create and track the outcome of your [[risk]] assessments cycle.",
            "removeInitiative": "Remove Initiative",
            "removeInitiativeDescription": "Are you sure you want to remove this initiative?",
            "searchCompletionDate": "Search Completion Date"
        },
        "initiativeDetails": {
            "initiatives": "Initiatives",
            "name": "Name",
            "dueDate": "Due Date",
            "assessmentFinding": "Assessment Findings",
            "goal": "Goal",
            "findingsPlaceholder": "Enter your assessment findings here",
            "findingsGoal": "Enter your goals here",
            "modalMsg": "{{field}} for the initiative",
            "selectAssessment": "Select Assessments",
            "targets": "Targets",
            "rpn": "RPN",
            "type": "Type",
            "creationDate": "Creation Date",
            "selectAStatus": "Select a Status",
            "assessmentSelectVerbiage": "Select the assessments you want to include within this [[Risk]] Initiative.",
            "targetTypeName": "Target Type",
            "completedOn": "Completed on",
            "initiativeSavedSuccessfully": "The initiative has been saved successfully",
            "initiativeSavedUnsuccessfully": "The initiative has been saved unsuccessfully",
            "topVerbiage": "[[Risk]] initiatives lets you document what was discovered during the assessment period and set goals for future mitigation."
        },
        "dashboard": {
            "assessmentsByRpn": "Assessments by [[Risk]] Priority Number",
            "riskBand": "[[Risk]] Band",
            "riskMatrix": "[[Risk]] Matrix",
            "riskOverTime": "[[Risk]] Over Time",
            "helperText": "Within the Assessment by [[Risk]] Priority Number (RPN) chart, choose the assessment you wish to view.",
            "rpnOverTimeDescription": "Within the Assessment by [[Risk]] Priority Number (RPN) chart, choose the assessment you wish to view."
        }
    }
}
