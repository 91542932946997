{
    "incident": {
        "incident": "Incidente",
        "incidents": "Incidentes",
        "incidentHistory": "Historial de incidentes",
        "communicate": "Comunicar",
        "communicateToAll": "Comunicarse con todos",
        "crisisTeam": "[[Team]] de crisis",
        "history": "Historial",
        "incidentName": "Nombre del incidente",
        "incidentNotes": "Notas de incidentes",
        "incidentType": "Tipo de incidente",
        "originalSender": "Remitente original",
        "originalLocation": "Ubicación original",
        "created": "Creado",
        "startedDate": "Empezado",
        "resolved": "Resuelto",
        "criteria": "Afectando a los usuarios que están en",
        "roles": "Roles",
        "groups": "Grupos",
        "departments": "[[Departments]]",
        "locations": "Ubicaciones",
        "incidentResolved": "Resuelto",
        "incidentAttachments": "Archivos adjuntos",
        "detailsTabText": "Detalle",
        "communicationLogTabText": "Registro de comunicación",
        "btnArchive": "Archivar",
        "btnUnArchive": "Restaurar",
        "btnSaveAsNewAttachment": "Guardar como nuevo archivo adjunto",
        "btnReplaceExistingAttachment": "Reemplazar el archivo adjunto existente",
        "btnUploadAttachment": "Cargar archivo adjunto",
        "uploadingFile": "Cargando archivo",
        "dropFile": "Suelte el archivo aquí",
        "dropFiles": "Suelte los archivos aquí",
        "msgNoteAdded": "Nota agregada",
        "msgNoteAddError": "No se pudo agregar la nota",
        "msgNoteDeleted": "Nota eliminada",
        "msgNoteDeleteError": "No se pudo eliminar la nota",
        "btnSendMessage": "Enviar mensaje",
        "btnConferenceCall": "Llamada de conferencia",
        "recipients": "Destinatarios",
        "messageContent": "Contenido del mensaje",
        "attachments": "Archivos adjuntos",
        "totalRecipients": "Destinatarios totales",
        "totalMessages": "Mensajes totales",
        "totalResends": "Total de reenvíos",
        "totalDevices": "Dispositivos totales",
        "remainingResends": "Restante",
        "summary": "Resumen",
        "responseSummary": "Resumen de respuesta",
        "email": "Correo electrónico",
        "phone": "Teléfono",
        "contactPreference": "Preferencia de contacto",
        "responseDateTime": "Fecha de respuesta",
        "response": "Respuesta",
        "cancelResendSuccess": "Cancelar reenvío exitoso",
        "cancelResendFailed": "Cancelar reenvío fallido",
        "recipientMessage": "Mensaje del destinatario",
        "respondedFrom": "Respondido desde",
        "allResponses": "Todas las respuestas",
        "allRoles": "Todos los roles",
        "allLocations": "Todas las localizaciones",
        "allDepartments": "Todos los [[departments]]",
        "allGroups": "Todos los grupos",
        "allTenants": "Todos los inquilinos",
        "unknown": "Desconocido",
        "noResponse": "Ninguna respuesta",
        "name": "Nombre",
        "message": "Mensaje",
        "messages": "Mensajes",
        "events": "Eventos",
        "location": "[[Location]]",
        "lastResponseFrom": "Última respuesta de",
        "latestResponseDate": "Fecha de la última respuesta",
        "updateResponse": "Respuesta de actualización",
        "lblSearch": "Buscar",
        "btnExport": "Exportar",
        "refresh": "Refrescar",
        "details": "Detalles",
        "affectedUsers": "Usuarios afectados",
        "startDate": "Fecha de inicio",
        "createIncident": "Crear incidente",
        "btnCreateEvent": "Nuevo evento",
        "lblAdditionalRoles": "Roles agregados manualmente",
        "lblAdditionalLocations": "Ubicaciones agregadas manualmente",
        "lblAdditionalGroups": "Grupos agregados manualmente",
        "lblAdditionalDepartments": "[[Departments]] agregados manualmente",
        "lblAdditionalTenants": "Inquilinos agregados manualmente",
        "invalidUserResponseType": "Tipo de respuesta no válido",
        "saveEventNameChangeSuccess": "El cambio de nombre del evento se completó exitosamente",
        "saveEventNameChangeFailed": "Error al cambiar el nombre del evento",
        "desktop": "Escritorio",
        "mobileApp": "Aplicación móvil",
        "errorLoadingResponseSummaryMsg": "Error al cargar el resumen de respuesta",
        "errorLoadingMessageRecipients": "Error al cargar los destinatarios del mensaje",
        "errorLoadingMessageRecipientSummary": "Error al cargar el resumen de destinatarios del mensaje",
        "errorLoadingMessageJobDetails": "Error al cargar detalles de trabajos de mensajes individuales",
        "archiveConfirmation": {
            "header": "¿Está seguro de que desea archivar este incidente?",
            "body": "Una vez que se archiva un incidente, ya no podrá verlo en la lista de incidentes activos.",
            "btnText": "Sí",
            "text": "Sí"
        },
        "unArchiveConfirmation": {
            "header": "¿Está seguro de que desea restaurar este incidente?",
            "body": "Al restaurar el incidente, podrá verlo en la lista de incidentes activos.",
            "btnText": "Sí",
            "text": "Sí"
        },
        "resolveConfirmation": {
            "header": "¿Está seguro de que quiere resolver este incidente?",
            "body": "Una vez que se resuelve un incidente, se puede archivar.",
            "btnText": "OK"
        },
        "deleteDocumentConfirmation": {
            "header": "Eliminar confirmación",
            "body": "¿Está seguro de que quiere eliminar el archivo adjunto {{ docName }}?",
            "btnText": "OK"
        },
        "cancelConfirmation": {
            "header": "¿Cancelar incidente?",
            "body": "¿Está seguro de que desea cancelar este incidente? Todos los cambios se perderán.",
            "text": "Sí"
        },
        "cancelResendConfirmation": {
            "header": "Confirmar reenviar cancelación",
            "body": "¿Está seguro de que desea cancelar los reenvíos restantes de este mensaje?",
            "text": "Sí"
        },
        "attachmentDialog": {
            "select": "Seleccione",
            "selectDocument": "Seleccione el documento",
            "selectFile": "Seleccione el archivo",
            "chooseFile": "Elija el archivo",
            "replaceExisting": "",
            "saveAsNew": ""
        },
        "viewParticipants": "Ver destinatarios",
        "responsesSettings": {
            "errors": {
                "definitionsAreRequired": "Se requieren definiciones de respuesta.",
                "keysAreRequired": "Se requieren claves de respuesta.",
                "duplicatesAreNotAllowed": "No se permiten claves de respuesta duplicadas.",
                "definitionIsRequiredIfKeyIsNumeric": "La definición de respuesta es necesaria si la clave de respuesta es numérica."
            }
        },
        "showArchived": "Mostrar archivado",
        "showCurrent": "Mostrar actual",
        "newIncident": "Nuevo incidente",
        "status": "Estado",
        "type": "Tipo",
        "dateCreated": "Fecha de creación",
        "participantsCriteria": "Criterios para los participantes",
        "messageTypes": "Tipos de mensajes",
        "numberOfRecipients": "Número de destinatarios",
        "viewCriteria": "Ver criterios",
        "lblSms": "SMS",
        "lblDesktop": "Escritorio",
        "lblMobile": "Móvil",
        "lblVoice": "Voz",
        "lblConferenceCall": "Llamada de conferencia",
        "lblPushMessage": "Mensaje Push",
        "lblEmail": "Correo electrónico",
        "incidentsFilterPlaceHolder": "Refinar los resultados por: Estado, tipo, nombre o criterios",
        "restoreSuccess": "Incidente restaurado con éxito",
        "archiveSuccess": "Incidente archivado exitosamente",
        "restoreError": "Error al intentar restaurar el incidente",
        "archiveError": "Error al intentar archivar el incidente",
        "incidentCommunication": "Comunicación",
        "nav": {
            "lblIncidents": "Incidentes",
            "lblOverview": "Visión general",
            "lblCommunicate": "Comunicar",
            "lblDashboard": "Tablero de control",
            "lblSendEmergencyMessage": "Enviar mensaje",
            "lblStartAConferenceCall": "Iniciar una llamada de conferencia",
            "lblSplashMessages": "Mensajes Splash",
            "lblScheduledConferenceCalls": "Llamadas de conferencia programadas",
            "lblPublicAlerts": "Alertas públicas",
            "lblGeolocations": "Geolocalizaciones",
            "lblHistory": "Historial",
            "lblCrisisTeam": "[[Team]] de crisis",
            "lblPanicDashboard": "Tablero de pánico",
            "lblCallInAnnouncements": "Anuncios de llamadas entrantes",
            "lblScheduledMessages": "Mensajes programados",
            "lblEmergencyButtonDashboard": "Alertas iniciadas por dispositivos móviles"
        },
        "incidentLocation": "Geolocalización",
        "overview": {
            "lblActiveAlerts": "Mensajes de bienvenida activos",
            "lblIncidentHistory": "Historial de incidentes",
            "lblSendEmergencyMessage": "Enviar mensaje",
            "lblNotAnEmergency": "¿No es una emergencia? Comunicarse sobre un evento con cualquiera de estas opciones:",
            "lblStartAConferenceCall": "Iniciar una llamada de conferencia",
            "lblCreateCallInAnnouncement": "Crear anuncio de llamada entrante",
            "lblCallInAnnouncements": "Anuncios de llamadas entrantes",
            "lblCreateSplashMessage": "Crear mensaje Splash",
            "lblNew": "Nuevo",
            "lblViewAll": "Ver todos",
            "lblHelpfulToolsAndResources": "Herramientas y recursos útiles",
            "lblSplashMessages": "Mensaje Splash",
            "loadingIncidentHistoryError": "Error al cargar el historial de incidentes recientes",
            "downloadFileNoContentError": "No hay contenido disponible para descargar",
            "downloadResourceError": "Error al descargar el recurso",
            "loadingSplashMessagesError": "Error al cargar mensajes Splash",
            "loadingCallInAnnoucementsError": "Error al cargar los anuncios de llamada entrante",
            "lblNoSplashMessage": "No hay mensajes Splash activos."
        },
        "messageProcessingStatus": {
            "lblSubmitting": "Entregando",
            "lblSending": "Enviando",
            "lblCompleted": "Completado",
            "lblTitle": "Su mensaje está en camino.",
            "btnViewCommLog": "Ver registro de comunicación"
        },
        "messaging": "Mensajería",
        "recentMessages": "Mensajes recientes",
        "dateTime": "Fecha y hora",
        "sender": "Remitente",
        "crisisTeams": {
            "btnDownload": "Descargar",
            "lblCrisisTeams": "[[Team]]s de crisis",
            "lblCrisisTeamTotalMembers": "[[members]] totales",
            "btnFinishedViewing": "Visualización finalizada",
            "lblMembers": "[[Members]]",
            "lblCrisisTeamSelected": "No se seleccionó ningún [[team]] de crisis",
            "lblTeamDocuments": "Documentos del [[team]]",
            "lblLocationInfo": "Información de [[location]]",
            "lblMainPhone": "Principal",
            "lblSecurityPhone": "Seguridad",
            "lblEmergencyPhone": "Llamada de emergencia",
            "lblAnnouncementsPhone": "Anuncios",
            "lblTeamMembers": "[[Members]] del [[team]] de crisis",
            "lblMember": "[[Member]]",
            "lblPhoneNumbers": "Números de teléfono",
            "lblEmail": "Correo electrónico",
            "lblFloor": "Piso",
            "lblRole": "Rol",
            "lblPhone": "Teléfono",
            "lblOffice": "Oficina",
            "lblNoCrisisTeamMembers": "No se han agregado [[members]] a este [[team]].",
            "lblTeamDocumentsTitle": "Documentos del [[team]]",
            "msgNoDescription": "Sin descripción",
            "msgNoDocument": "No se han agregado documentos a este [[team]].",
            "phSearchFilter": "Refinar los resultados por: [[Location]]"
        },
        "splashMessage": {
            "splashMessage": "Mensaje Splash",
            "splashMessages": "Mensajes Splash",
            "newSplashMessage": "Nuevo mensaje Splash",
            "numberOfResponses": "# de respuestas",
            "displayedToUsersIn": "Mostrado a los usuarios en",
            "message": "Mensaje",
            "expires": "Expira",
            "startTime": "Hora de inicio",
            "displayDate": "Mostrar fecha",
            "title": "Título",
            "status": "Estado",
            "errorLoadingSplashMessages": "Error al cargar mensajes Splash",
            "errorLoadingSplashMessage": "Error al cargar el mensaje Splash",
            "filterSplashMessagesPlaceholder": "Refinar los resultados por: Título, mensaje o mostrado en",
            "active": "Activo",
            "inactive": "Inactivo",
            "never": "Nunca",
            "preview": "Vista previa",
            "confirmDeactivate": {
                "header": "Confirmar Desactivar",
                "body": "<p>¿Está seguro de que desea desactivar: <strong>{{ name }}</strong>?</p><p>Puede haber usuarios que aún no han respondido. Una vez desactivado, el mensaje ya no se mostrará.</p>",
                "text": "Sí, desactivar"
            },
            "confirmActivate": {
                "header": "Reactivar mensaje Splash",
                "body": "¿Está seguro de que desea reactivar este mensaje Splash?",
                "text": "Sí, reactivar"
            },
            "confirmSave": {
                "header": "Guardar mensaje Splash",
                "body": "¿Está seguro de que desea guardar este mensaje Splash?",
                "text": "Guardar"
            },
            "confirmSaveMidStream": {
                "header": "El mensaje Splash ya se muestra",
                "body": "Este mensaje Splash ya se ha mostrado a algunos usuarios. ¿Continuar?",
                "text": "Continuar"
            },
            "confirmDelete": {
                "header": "Eliminar mensaje Splash",
                "body": "¿Está seguro de que desea eliminar este mensaje Splash?",
                "text": "Eliminar"
            },
            "successChangeStatus": "Éxito al cambiar el estado del mensaje Splash",
            "errorChangeStatus": "Error al cambiar el estado del mensaje Splash",
            "manual": "Manual",
            "expired": "Vencido",
            "helpAcknowledgementType": "Utilice la opción SÍ/NO o CHECK BOX W/OK para confirmar el reconocimiento. Utilice la opción predeterminada OK para fines informativos.",
            "lblAcknowledgementType": "Tipo de reconocimiento",
            "lblCancelSplashMessage": "Cancelar mensaje Splash",
            "lblOptionsSubtitle": "Opciones",
            "lblDetailsSubtitle": "Detalles del mensaje",
            "lblDisplaySubtitle": "Visualización del mensaje",
            "lblDontExpire": "Que no expire",
            "lblEdit": "Editar",
            "lblExpireMessage": "Hacer que el mensaje expire el",
            "lblExpireMessageDontExpireInfo": "(El mensaje se mostrará hasta esta fecha, en la que se desactivará automáticamente incluso si hay usuarios que no han respondido).",
            "lblExpireMessageExpireOnInfo": "(El mensaje seguirá mostrándose a los usuarios que no hayan respondido, hasta que desactive manualmente el mensaje).",
            "lblExpireOn": "Expira el",
            "lblMessageContent": "Contenido del mensaje",
            "lblMessageTitle": "Título del mensaje",
            "lblNumberRecipients": "Destinatarios totales",
            "lblNoResponses": "Ningún usuario ha respondido a este mensaje.",
            "lblOptions": "OPCIONES",
            "lblRecipients": "DESTINATARIOS",
            "lblRecipientsSubtitle": "Elija destinatarios",
            "lblShowMessage": "Mostrar mensaje el",
            "lblShowOnDate": "Mostrar el",
            "lblShowOnInfo": "(El mensaje se mostrará a los usuarios seleccionados este día)",
            "lblSendMessageTo": "Mostrar a los usuarios que están en",
            "lblSplashDetails": "Detalles de Splash",
            "lblSplashMessageTitle": "Nuevo mensaje Splash",
            "lblSplashRecipients": "Destinatarios de Splash",
            "lblSummary": "RESUMEN",
            "lblSummarySubtitle": "Resumen",
            "msgOptions": "Ingrese la siguiente información y luego haga clic en el botón siguiente para continuar con el siguiente paso.",
            "msgRecipients": "Seleccione cualquiera de las categorías disponibles a continuación o elija solo una para decidir quién debería ver este mensaje Splash.",
            "msgRecipientsTip1": "Seleccione cualquier categoría para determinar quién verá este mensaje Splash. Para ser incluido, un usuario debe coincidir con uno de los valores en cada categoría que seleccione. O puede optar por permitir que todos vean este mensaje Splash.",
            "msgRecipientsTip2": "Si selecciona Atlanta y Chicago en [[Location]], y también selecciona Contabilidad y Ventas en [[Departments]], este mensaje Splash solo será visualizado por los usuarios que se encuentran en Atlanta O Chicago, pero que también trabajan en Contabilidad O Ventas.",
            "msgSaveNewSplashFail": "No se pudo crear el mensaje Splash. Vuelva a intentarlo más tarde.",
            "msgSaveNewSplashSuccess": "Nuevo mensaje Splash creado exitosamente.",
            "msgSaveUpdateSplashFail": "Error al actualizar el mensaje Splash. Vuelva a intentarlo más tarde.",
            "msgSaveUpdateSplashSuccess": "El mensaje Splash se guardó exitosamente.",
            "msgSplashBodyRequiredError": "El campo de contenido del mensaje está vacío",
            "msgDeleteSplashSuccess": "Mensaje Splash eliminado exitosamente",
            "msgDeleteSplashFail": "Error al eliminar el mensaje Splash.",
            "msgSummary": "Revise la siguiente información para asegurarse de que todo sea correcto antes de hacer clic en Guardar.",
            "msgUserRequired": "Debe seleccionar de al menos una categoría.",
            "msgErrorLoadingSplashMessage": "No se pudo cargar el mensaje Splash.",
            "phMessageTitle": "Ingrese un título que se mostrará en el encabezado del mensaje Splash",
            "confirmCancelSplashMessage": {
                "header": "Cancelar mensaje Splash",
                "body": "¿Está seguro de que desea cancelar este mensaje Splash?",
                "text": "Sí"
            },
            "btnSaveFinished": "Guardar y finalizar",
            "lblMessageSubtitle": "Mensaje",
            "lblPreviewSplashMessage": "Mensaje de vista previa",
            "lblStatus": "Estado",
            "lblAffectingUsers": "Mostrar mensaje a los usuarios en",
            "lblAndAreIn": "y están en",
            "lblEveryone": "Todos",
            "lblSplashResponsesSubtitle": "Respuestas Splash",
            "lblNumUsersResponded": "# de usuarios que han respondido",
            "phSearchFilter": "Refinar los resultados por: Nombre, función o [[location]]",
            "response": {
                "name": "Nombre",
                "role": "Rol",
                "location": "[[Location]]",
                "responded": "Respondido",
                "response": "Respuesta"
            },
            "responseCountMessage": "Mostrando {{ x }} de {{ y }} respuestas de los usuarios",
            "startEndDateError": "La fecha de vencimiento debe ser posterior a la fecha de inicio"
        },
        "dashboard": {
            "sendMessageDescription": "En caso de emergencia, envíe un mensaje a quienes necesiten estar informados. Después de enviar un mensaje, podrá rastrear el incidente y las respuestas (si se han seleccionado) para estar al tanto de los eventos.",
            "conferenceCallDescription": "¿Necesita hablar con un grupo de personas para debatir sobre un incidente? ¡Lo tenemos resuelto! Con nuestra llamada de conferencia no hay necesidad de esperar a que la gente llame, ya que nuestro servicio llama a todas las personas que usted seleccione y las une inmediatamente a la llamada de conferencia cuando responden.",
            "splashMessagesDescription": "Los mensajes Splash son una excelente herramienta para comunicar una variedad de situaciones, desde incidentes hasta recordatorios importantes. Si crea un mensaje Splash, aquellos que haya designado para recibir el mensaje de bienvenida se presentarán al iniciar sesión en el mensaje. También podrá realizar un seguimiento de su respuesta.",
            "currentlyActive": "Actualmente activo",
            "viewAllSplashMessages": "Ver todos los mensajes Splash",
            "callInAnnouncementsDescription": "El uso de un anuncio de llamada entrante es otra gran herramienta para comunicar información. Grabe un mensaje y cualquiera que llame al número especificado lo escuchará. Los anuncios de llamadas entrantes son excelentes para cierres de oficinas o cuando Internet no funciona o no es confiable.",
            "noCallInAnnouncements": "Sin anuncios de llamadas entrantes",
            "viewAllCallInAnnouncements": "Ver todos los anuncios de llamadas entrantes",
            "newCallInAnnouncement": "Nuevo anuncio de llamada entrante"
        },
        "locationDescription": "Descripción de la [[location]]",
        "enableWatchIncidentBtn": "Ver incidente",
        "stopWatchingIncidentBtn": "Dejar de ver incidente",
        "enableWatchIncident": "Notificación de respuesta de emergencia habilitada correctamente",
        "disableWatchIncident": "Notificación de respuesta de emergencia desactivada con éxito",
        "updateWatchIncidentFailed": "Se ha producido un error al actualizar la notificación de respuesta de emergencia",
        "viewCommunicationLog": "Ver registro de comunicación",
        "exceptionExport": "Exportación de excepción",
        "sendToAll": "Enviado a todos los usuarios",
        "manuallyAdded": "Usuarios agregados manualmente"
    }
}
