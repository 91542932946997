{
    "incident": {
        "incident": "Incidente",
        "incidents": "Incidentes",
        "incidentHistory": "Histórico de Incidentes",
        "communicate": "Comunicar",
        "communicateToAll": "Comunicar a todos",
        "crisisTeam": "Equipe de Crise",
        "history": "Histórico",
        "incidentName": "Nome do Incidente",
        "incidentNotes": "Notas do Incidente",
        "incidentType": "Tipo de Incidente",
        "originalSender": "Remetente Original",
        "originalLocation": "Localização original",
        "created": "Criada",
        "startedDate": "Começado",
        "resolved": "Resolvido",
        "criteria": "Afetando usuários que estão em",
        "roles": "Funções",
        "groups": "Grupos",
        "departments": "[[Departments]]",
        "locations": "[[Locations]]",
        "incidentResolved": "Resolvido",
        "incidentAttachments": "Anexos",
        "detailsTabText": "Detalhe",
        "communicationLogTabText": "Log de comunicação",
        "btnArchive": "Arquivo",
        "btnUnArchive": "Restaurar",
        "btnSaveAsNewAttachment": "Salvar como novo anexo",
        "btnReplaceExistingAttachment": "Substitua o anexo existente",
        "btnUploadAttachment": "Carregar anexo",
        "uploadingFile": "Carregando arquivo",
        "dropFile": "Solte o arquivo aqui",
        "dropFiles": "Solte os arquivos aqui",
        "msgNoteAdded": "Nota adicionada",
        "msgNoteAddError": "Falha ao adicionar nota",
        "msgNoteDeleted": "Nota excluída",
        "msgNoteDeleteError": "Falha ao excluir nota",
        "btnSendMessage": "Enviar mensagem",
        "btnConferenceCall": "Teleconferência",
        "recipients": "Destinatários",
        "messageContent": "Conteúdo da mensagem",
        "attachments": "Anexos",
        "totalRecipients": "Destinatários totais",
        "totalMessages": "Total de Mensagens",
        "totalResends": "Total de reenvios",
        "totalDevices": "Total de dispositivos",
        "remainingResends": "Remanescente",
        "summary": "Resumo",
        "responseSummary": "Resumo da Resposta",
        "email": "E-mail",
        "phone": "Telefone",
        "contactPreference": "Preferência de contato",
        "responseDateTime": "Data de Resposta",
        "response": "Resposta",
        "cancelResendSuccess": "Cancelar reenviar com sucesso",
        "cancelResendFailed": "Falha ao cancelar reenvio",
        "recipientMessage": "Mensagem do Destinatário",
        "respondedFrom": "Respondido de",
        "allResponses": "Todas as respostas",
        "allRoles": "Todas as funções",
        "allLocations": "Todos os locais",
        "allDepartments": "Todos os [[Departments]]",
        "allGroups": "Todos os grupos",
        "allTenants": "Todos os inquilinos",
        "unknown": "Desconhecido",
        "noResponse": "Sem resposta",
        "name": "Nome",
        "message": "Mensagem",
        "messages": "Mensagens",
        "events": "Eventos",
        "location": "Localização",
        "lastResponseFrom": "Última resposta de",
        "latestResponseDate": "Última Data de Resposta",
        "updateResponse": "Resposta de atualização",
        "lblSearch": "Pesquisar",
        "btnExport": "Exportar",
        "refresh": "Atualizar",
        "details": "Detalhes",
        "affectedUsers": "Usuários Afetados",
        "startDate": "Data de início",
        "createIncident": "Criar Incidente",
        "btnCreateEvent": "Novo evento",
        "lblAdditionalRoles": "Funções adicionadas manualmente",
        "lblAdditionalLocations": "Locais adicionados manualmente",
        "lblAdditionalGroups": "Grupos adicionados manualmente",
        "lblAdditionalDepartments": "[[Departments]] adicionados manualmente",
        "lblAdditionalTenants": "Inquilinos adicionados manualmente",
        "invalidUserResponseType": "Tipo de resposta inválido",
        "saveEventNameChangeSuccess": "Renomeação de evento concluída com sucesso",
        "saveEventNameChangeFailed": "A renomeação do evento falhou",
        "desktop": "Área de Trabalho",
        "mobileApp": "Aplicativo móvel",
        "errorLoadingResponseSummaryMsg": "Erro ao carregar o resumo da resposta",
        "errorLoadingMessageRecipients": "Erro ao carregar destinatários da mensagem",
        "errorLoadingMessageRecipientSummary": "Erro ao carregar o resumo do destinatário da mensagem",
        "errorLoadingMessageJobDetails": "Erro ao carregar detalhes de trabalhos de mensagens individuais",
        "archiveConfirmation": {
            "header": "Tem certeza de que deseja arquivar este incidente?",
            "body": "Depois que um incidente for arquivado, você não poderá mais vê-lo na lista de incidentes ativos.",
            "btnText": "Sim",
            "text": "Sim"
        },
        "unArchiveConfirmation": {
            "header": "Tem certeza de que deseja restaurar este incidente?",
            "body": "Ao restaurar o incidente, você poderá vê-lo na lista de incidentes ativos.",
            "btnText": "Sim",
            "text": "Sim"
        },
        "resolveConfirmation": {
            "header": "Tem certeza de que deseja resolver este incidente?",
            "body": "Assim que um incidente for resolvido, ele pode ser arquivado.",
            "btnText": "OK"
        },
        "deleteDocumentConfirmation": {
            "header": "Confirmação de remoção",
            "body": "Tem certeza que deseja excluir o anexo{{ docName }} ?",
            "btnText": "OK"
        },
        "cancelConfirmation": {
            "header": "Cancelar incidente?",
            "body": "Tem certeza de que deseja cancelar este incidente? Todas as alterações serão perdidas.",
            "text": "Sim"
        },
        "cancelResendConfirmation": {
            "header": "Confirmar cancelamento de reenvio",
            "body": "Tem certeza de que deseja cancelar os reenvios restantes desta mensagem?",
            "text": "Sim"
        },
        "attachmentDialog": {
            "select": "Selecione",
            "selectDocument": "Selecione o Documento",
            "selectFile": "Selecione o arquivo",
            "chooseFile": "Escolher arquivo",
            "replaceExisting": "",
            "saveAsNew": ""
        },
        "viewParticipants": "Ver Destinatários",
        "responsesSettings": {
            "errors": {
                "definitionsAreRequired": "Definições de resposta são obrigatórias.",
                "keysAreRequired": "Chaves de resposta são obrigatórias.",
                "duplicatesAreNotAllowed": "Chaves de resposta duplicadas não são permitidas.",
                "definitionIsRequiredIfKeyIsNumeric": "A definição de resposta é necessária se a chave de resposta for numérica."
            }
        },
        "showArchived": "Mostrar arquivado",
        "showCurrent": "Mostrar atual",
        "newIncident": "Novo Incidente",
        "status": "Status",
        "type": "Tipo",
        "dateCreated": "Data Criada",
        "participantsCriteria": "Critérios de Participantes",
        "messageTypes": "Tipos de Mensagem",
        "numberOfRecipients": "Número de Destinatários",
        "viewCriteria": "Ver Critérios",
        "lblSms": "SMS",
        "lblDesktop": "Área de Trabalho",
        "lblMobile": "Móvel",
        "lblVoice": "Voz",
        "lblConferenceCall": "Teleconferência",
        "lblPushMessage": "Mensagem Push",
        "lblEmail": "E-mail",
        "incidentsFilterPlaceHolder": "Filtre os resultados por: Status, tipo, nome ou critérios",
        "restoreSuccess": "Incidente restaurado com sucesso",
        "archiveSuccess": "Incidente arquivado com sucesso",
        "restoreError": "Erro ao tentar restaurar o incidente",
        "archiveError": "Erro ao tentar arquivar o incidente",
        "incidentCommunication": "Comunicação",
        "nav": {
            "lblIncidents": "Incidentes",
            "lblOverview": "Visão geral",
            "lblCommunicate": "Comunicar",
            "lblDashboard": "Painel de controle",
            "lblSendEmergencyMessage": "Enviar mensagem",
            "lblStartAConferenceCall": "Iniciar uma teleconferência",
            "lblSplashMessages": "Mensagens iniciais",
            "lblScheduledConferenceCalls": "Chamadas de teleconferência",
            "lblPublicAlerts": "[[Alerts]] Públicos",
            "lblGeolocations": "Geolocalização",
            "lblHistory": "Histórico",
            "lblCrisisTeam": "Equipe de Crise",
            "lblPanicDashboard": "Painel de Pânico",
            "lblCallInAnnouncements": "Anúncios de Chamada",
            "lblScheduledMessages": "Mensagens Agendadas",
            "lblEmergencyButtonDashboard": "[[Alerts]] iniciado por celular"
        },
        "incidentLocation": "Geolocalização",
        "overview": {
            "lblActiveAlerts": "Mensagens iniciais ativas",
            "lblIncidentHistory": "Histórico de Incidentes",
            "lblSendEmergencyMessage": "Enviar mensagem",
            "lblNotAnEmergency": "Não é uma emergência? Comunique-se sobre um evento com qualquer uma destas opções:",
            "lblStartAConferenceCall": "Iniciar uma teleconferência",
            "lblCreateCallInAnnouncement": "Criar anúncio de chamada",
            "lblCallInAnnouncements": "Anúncios de Chamada",
            "lblCreateSplashMessage": "Criar mensagem inicial",
            "lblNew": "Novo",
            "lblViewAll": "Ver tudo",
            "lblHelpfulToolsAndResources": "Ferramentas e recursos úteis",
            "lblSplashMessages": "Mensagem Inicial",
            "loadingIncidentHistoryError": "Erro ao carregar o histórico de incidentes recentes",
            "downloadFileNoContentError": "Nenhum conteúdo disponível para download",
            "downloadResourceError": "Erro ao baixar o recurso",
            "loadingSplashMessagesError": "Erro ao carregar mensagens iniciais",
            "loadingCallInAnnoucementsError": "Erro ao carregar anúncios de chamada",
            "lblNoSplashMessage": "Não há mensagens iniciais ativas."
        },
        "messageProcessingStatus": {
            "lblSubmitting": "Enviando",
            "lblSending": "Enviando",
            "lblCompleted": "Concluído",
            "lblTitle": "Sua mensagem está a caminho.",
            "btnViewCommLog": "Ver Log de Comunicação"
        },
        "messaging": "Mensagens",
        "recentMessages": "Mensagens Recentes",
        "dateTime": "Data hora",
        "sender": "Remetente",
        "crisisTeams": {
            "btnDownload": "Baixar",
            "lblCrisisTeams": "Equipes de crise",
            "lblCrisisTeamTotalMembers": "total de membros",
            "btnFinishedViewing": "Visualização concluída",
            "lblMembers": "[[Members]]",
            "lblCrisisTeamSelected": "Nenhuma equipe de crise selecionada",
            "lblTeamDocuments": "Documentos da Equipe",
            "lblLocationInfo": "Informações de localização",
            "lblMainPhone": "Principal",
            "lblSecurityPhone": "Segurança",
            "lblEmergencyPhone": "Chamada de Emergência",
            "lblAnnouncementsPhone": "Anúncios",
            "lblTeamMembers": "Membros da equipe de crise",
            "lblMember": "Membro",
            "lblPhoneNumbers": "Números de telefone",
            "lblEmail": "E-mail",
            "lblFloor": "Andar",
            "lblRole": "Função",
            "lblPhone": "Telefone",
            "lblOffice": "Escritório",
            "lblNoCrisisTeamMembers": "Nenhum membro foi adicionado a esta equipe.",
            "lblTeamDocumentsTitle": "Documentos da Equipe",
            "msgNoDescription": "Sem descrição",
            "msgNoDocument": "Nenhum documento foi adicionado a esta equipe.",
            "phSearchFilter": "Filtre os resultados por: Localização"
        },
        "splashMessage": {
            "splashMessage": "Mensagem Inicial",
            "splashMessages": "Mensagens iniciais",
            "newSplashMessage": "Nova Mensagem Inicial",
            "numberOfResponses": "nº de respostas",
            "displayedToUsersIn": "Exibido para usuários em",
            "message": "Mensagem",
            "expires": "Expira",
            "startTime": "Hora de início",
            "displayDate": "Data de exibição",
            "title": "Título",
            "status": "Status",
            "errorLoadingSplashMessages": "Erro ao carregar mensagens iniciais",
            "errorLoadingSplashMessage": "Erro ao carregar mensagem inicial",
            "filterSplashMessagesPlaceholder": "Filtre os resultados por: Título, mensagem ou exibido em",
            "active": "Ativo",
            "inactive": "Inativo",
            "never": "Nunca",
            "preview": "Pré-visualização",
            "confirmDeactivate": {
                "header": "Confirmar Desativar",
                "body": "<p>Tem certeza de que deseja desativar: <strong>{{ name }}</strong>?</p><p>Pode haver usuários que ainda não responderam. Depois de desativada, a mensagem não será mais exibida.</p>",
                "text": "Sim, desative"
            },
            "confirmActivate": {
                "header": "Reativar mensagem inicial",
                "body": "Tem certeza de que deseja reativar esta mensagem inicial?",
                "text": "Sim, reativar"
            },
            "confirmSave": {
                "header": "Salvar mensagem inicial",
                "body": "Tem certeza de que deseja salvar esta mensagem inicial?",
                "text": "Salvar"
            },
            "confirmSaveMidStream": {
                "header": "A mensagem inicial já está sendo exibida",
                "body": "Esta mensagem inicial já foi exibida para alguns usuários. Continuar?",
                "text": "Continuar"
            },
            "confirmDelete": {
                "header": "Excluir mensagem inicial",
                "body": "Tem certeza de que deseja remover esta mensagem inicial?",
                "text": "Remover"
            },
            "successChangeStatus": "Sucesso ao alterar o status da mensagem inicial",
            "errorChangeStatus": "Erro ao alterar o status da mensagem inicial",
            "manual": "Manual",
            "expired": "Expirado",
            "helpAcknowledgementType": "Use a opção SIM / NÃO ou CAIXA DE SELEÇÃO W / OK para confirmar o reconhecimento. Use a opção OK padrão para fins informativos.",
            "lblAcknowledgementType": "Tipo de Reconhecimento",
            "lblCancelSplashMessage": "Cancelar mensagem inicial",
            "lblOptionsSubtitle": "Opções",
            "lblDetailsSubtitle": "detalhes da mensagem",
            "lblDisplaySubtitle": "Tela de mensagem",
            "lblDontExpire": "Não expira",
            "lblEdit": "Editar",
            "lblExpireMessage": "Expirar mensagem em",
            "lblExpireMessageDontExpireInfo": "(A mensagem será exibida até essa data, quando será desativada automaticamente, mesmo se houver usuários que não responderam.)",
            "lblExpireMessageExpireOnInfo": "(A mensagem continuará a ser exibida para os usuários que não responderam até que você desative a mensagem manualmente.)",
            "lblExpireOn": "Expira em",
            "lblMessageContent": "Conteúdo da mensagem",
            "lblMessageTitle": "Título da mensagem",
            "lblNumberRecipients": "Destinatários totais",
            "lblNoResponses": "Nenhum usuário respondeu a esta mensagem.",
            "lblOptions": "OPÇÕES",
            "lblRecipients": "DESTINATÁRIOS",
            "lblRecipientsSubtitle": "Escolha os destinatários",
            "lblShowMessage": "Mostrar mensagem em",
            "lblShowOnDate": "Mostrar no",
            "lblShowOnInfo": "(A mensagem será exibida para usuários selecionados neste dia)",
            "lblSendMessageTo": "Mostrar para usuários que estão em",
            "lblSplashDetails": "Detalhes de início",
            "lblSplashMessageTitle": "Nova Mensagem Inicial",
            "lblSplashRecipients": "Destinatários de início",
            "lblSummary": "RESUMO",
            "lblSummarySubtitle": "Resumo",
            "msgOptions": "Insira as seguintes informações e clique no botão próximo para prosseguir para a próxima etapa.",
            "msgRecipients": "Selecione uma das categorias abaixo ou escolha apenas uma para decidir quem deve ver esta mensagem inicial.",
            "msgRecipientsTip1": "Selecione em qualquer categoria para determinar quem verá esta mensagem inicial. Um usuário deve corresponder a um dos valores em cada categoria que você selecionar para ser incluído. Ou você pode escolher permitir que todos vejam esta mensagem inicial.",
            "msgRecipientsTip2": "Se você selecionar Atlanta e Chicago em Local e também selecionar Contabilidade e Vendas em [[Departments]], essa mensagem inicial será vista apenas por usuários localizados em Atlanta OU Chicago, mas que também trabalham em Contabilidade ou Vendas.",
            "msgSaveNewSplashFail": "Falha ao criar a mensagem inicial, tente novamente mais tarde.",
            "msgSaveNewSplashSuccess": "Nova mensagem inicial criada com sucesso.",
            "msgSaveUpdateSplashFail": "Falha ao atualizar a mensagem inicial, tente novamente mais tarde.",
            "msgSaveUpdateSplashSuccess": "Mensagem inicial salva com sucesso.",
            "msgSplashBodyRequiredError": "O campo de conteúdo da mensagem está vazio",
            "msgDeleteSplashSuccess": "Mensagem inicial removida com sucesso",
            "msgDeleteSplashFail": "Falha ao remover a mensagem inicial.",
            "msgSummary": "Revise as informações abaixo para certificar-se de que tudo está correto antes de clicar em Salvar.",
            "msgUserRequired": "Você deve selecionar pelo menos uma categoria.",
            "msgErrorLoadingSplashMessage": "Não foi possível carregar a mensagem inicial.",
            "phMessageTitle": "Insira um título que será exibido no cabeçalho da mensagem inicial",
            "confirmCancelSplashMessage": {
                "header": "Cancelar mensagem inicial",
                "body": "Tem certeza de que deseja cancelar esta mensagem inicial?",
                "text": "Sim"
            },
            "btnSaveFinished": "Salvar e finalizar",
            "lblMessageSubtitle": "Mensagem",
            "lblPreviewSplashMessage": "Mensagem de visualização",
            "lblStatus": "Status",
            "lblAffectingUsers": "Exibir mensagem para usuários em",
            "lblAndAreIn": "e estão em",
            "lblEveryone": "Todos",
            "lblSplashResponsesSubtitle": "Respostas iniciais",
            "lblNumUsersResponded": "Nº de usuários que responderam",
            "phSearchFilter": "Filtre os resultados por: Nome, função ou localização",
            "response": {
                "name": "Nome",
                "role": "Função",
                "location": "Localização",
                "responded": "Respondeu",
                "response": "Resposta"
            },
            "responseCountMessage": "Mostrando{{ x }} de{{ y }} respostas de usuário",
            "startEndDateError": "A data de expiração deve ser posterior à data de início"
        },
        "dashboard": {
            "sendMessageDescription": "Em caso de emergência, envie uma mensagem para aqueles que precisam ser informados. Depois de enviar uma mensagem, você poderá rastrear o incidente e as respostas (se selecionado) para ficar por dentro dos eventos.",
            "conferenceCallDescription": "Precisa falar com um grupo de pessoas para discutir um incidente? Você pode contar com a gente! Com a nossa teleconferência, não há necessidade de esperar que as pessoas liguem, pois o nosso serviço chama todos aqueles que você selecionar e os une imediatamente à teleconferência quando eles atendem.",
            "splashMessagesDescription": "As mensagens de abertura são uma ótima ferramenta para comunicar uma variedade de situações, desde incidentes a lembretes importantes. Se você criar uma mensagem inicial, aqueles que você designou para receber a mensagem inicial serão apresentados no login da mensagem. Você também poderá rastrear a resposta deles.",
            "currentlyActive": "Atualmente ativo",
            "viewAllSplashMessages": "Ver todas as mensagens de abertura",
            "callInAnnouncementsDescription": "Usar um anúncio de chamada é outra ótima ferramenta para comunicar informações. Grave uma mensagem e qualquer pessoa que ligar para o número especificado ouvirá sua mensagem. Os anúncios de chamadas são ótimos para fechamentos de escritórios ou quando a Internet está inativa ou não é confiável.",
            "noCallInAnnouncements": "Sem anúncios de chamada",
            "viewAllCallInAnnouncements": "Ver todos os anúncios de chamadas",
            "newCallInAnnouncement": "Anúncio de nova chamada"
        },
        "locationDescription": "Descrição da localização",
        "enableWatchIncidentBtn": "Ver incidente",
        "stopWatchingIncidentBtn": "Pare de assistir o incidente",
        "enableWatchIncident": "Notificação de resposta de emergência habilitada com sucesso",
        "disableWatchIncident": "Notificação de resposta de emergência desativada com sucesso",
        "updateWatchIncidentFailed": "Ocorreu um erro ao atualizar a notificação de resposta de emergência",
        "viewCommunicationLog": "Ver registro de comunicação",
        "exceptionExport": "Exportação de exceção",
        "sendToAll": "Enviado para todos os usuários",
        "manuallyAdded": "Usuários adicionados manualmente"
    }
}
