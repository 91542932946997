{
    "risk": {
        "lblRiskMenuTitle": "[[Risk]]",
        "lblRiskSetupMenuTitle": "Configurar",
        "msgNoRiskConfiguration": "As configurações de RPN de [[risk]] não estão configuradas",
        "headerMenu": {
            "riskLanding": "Painel de controle",
            "riskSetup": "Configurar",
            "riskDistribution": "Distribuição",
            "riskThreats": "Ameaças",
            "rpnConfiguration": "Configuração",
            "riskAnalytics": "Análises",
            "assessments": "Avaliações",
            "assessment": "Avaliação",
            "assessmentDetails": "Detalhes da avaliação",
            "riskInitiative": "Iniciativas",
            "initiativeDetails": "Detalhes da iniciativa"
        },
        "threats": {
            "typicalImpactDescription": "Descrição típica do impacto",
            "rpnConfiguration": "Configuração RPN",
            "riskAnalytics": "Análise de [[risk]]",
            "assessments": "Avaliações",
            "impactDescription": "Descrição do impacto",
            "threats": "Ameaças",
            "newThreat": "Nova ameaça",
            "deleteThreat": "Excluir ameaça",
            "selectType": "Selecione um tipo",
            "duplicateThreatTypeName": "Nome do tipo duplicado",
            "systemThreat": "Ameaça do sistema",
            "newSystemThreat": "Nova ameaça do sistema",
            "system": {
                "header": "Remover ameaça",
                "body": "Deseja remover esta ameaça apenas deste portal ou de todos os portais?",
                "removeFromThisPortal": "Remover deste portal",
                "removeFromAllPortals": "Remover de todos os portais"
            },
            "portal": {
                "header": "Remover ameaça",
                "body": "Tem certeza de que deseja remover esta ameaça?",
                "ok": "OK"
            },
            "copyThreatAsNew": "Copier la menace comme nouvelle"
        },
        "rpnConfiguration": {
            "formulaPlaceholder": "Selecione uma fórmula",
            "statusPlaceholder": "Selecione um status",
            "approvalSystem": "Sistema de aprovação",
            "prePopulateAnswers": "Usar respostas da avaliação anterior",
            "prePopulatePlaceholder": "Preencher de forma antecipada",
            "formulaSaveSuccessfully": "A fórmula foi salva com sucesso",
            "formulaSaveUnSuccessfully": "A fórmula não foi salva com sucesso",
            "riskBandConfiguration": "As faixas de [[risk]] organizam seus números de prioridade de [[risk]] em termos claros. Defina o número de faixas de [[risk]] que deseja usar para suas avaliações de [[risk]].",
            "equations": {
                "e0783df6-7ea6-496e-a3ae-0bd61e3d06d2": {},
                "e0783df6-7ea6-496e-a3ae-0bd61e3d06d": {},
                "13fcb83c-d1a2-4135-9e79-50bddff5dcdc": {},
                "f84ff46e-15a3-4348-b958-9ef58a434738": {},
                "610af901-b8cd-4ec2-8673-ca62dcf6c595": {},
                "dc52ab2d-ae1f-4e80-b11c-fc921cb6e2af": {}
            },
            "riskBandsKeys": {
                "1": "Baixo(a)",
                "2": "Médio(a)",
                "3": "Alto(a)",
                "4": "Médio(a)-Baixo(a)",
                "5": "Médio(a)-Alto(a)"
            },
            "verbiage": {
                "riskBand": "As faixas de [[risk]] organizam seus números de prioridade de [[risk]] em termos claros. Defina o número de faixas de [[risk]] que deseja usar para suas avaliações de [[risk]].",
                "approvalSystem": "Se definido como Sim, uma avaliação não será considerada concluída até que um aprovador a examine e dê sua aprovação. Assim que o participante enviar suas respostas, um e-mail será enviado ao aprovador, notificando-o.",
                "usePriorAssessmentResponses": "Se definido como Sim, as respostas de avaliações anteriores serão usadas para novas avaliações. A pessoa que faz a avaliação pode alterar os valores conforme necessário antes de enviar suas respostas.",
                "equation": "Selecione a equação de avaliação que deseja usar para suas avaliações de continuidade comercial. É aplicado no cálculo do RPN, que indica o perfil de [[risk]] relativo."
            },
            "impact": "Impacto",
            "probability": "Probabilidade",
            "mitigation": "Mitigação",
            "multiplier": "Multiplicador",
            "detection": "Detecção",
            "riskBands": "Faixas de [[risk]]",
            "assessmentEquation": "Equação de avaliação",
            "numberOfRiskBands": "Número de faixas de [[risk]]"
        },
        "assessment": {
            "welcomeAssessment": "Bem-vindo ao assistente de criação de avaliação",
            "typeQuestion": "Que tipo de avaliação você gostaria de realizar?",
            "selectAnApprover": "Selecionar um aprovador",
            "selectApprover": "Selecionar aprovador",
            "reviewEmail": "Revisar e-mail",
            "pickTargetType": "Escolher o seu tipo de alvo/meta",
            "pickTargets": "Escolher seus alvos/metas",
            "sendAssessments": "Enviar avaliações",
            "selectedTargets": "Alvos/Metas selecionados(as)",
            "pickTargetTypeDescription": "Selecione o tipo de avaliação que você gostaria de criar.",
            "pickTargetsDescription": "Selecione o {{entity}} sobre o qual você deseja fazer uma avaliação.",
            "sendAssessmentsDescription": "Defina o destinatário, o aprovador e a data final da avaliação e clique em Enviar e Concluir para enviar o convite da avaliação.",
            "selectRecipient": "Selecionar destinatário",
            "sendAssessmentRequirementsError": "Certifique-se de que cada avaliação tenha um destinatário, aprovador e data final",
            "create": "Criar avaliação",
            "riskPriorityNumber": "Número de prioridade de [[risk]]",
            "assessmentInvalid": "A avaliação é inválida",
            "saveAndContinue": "Salvar e continuar",
            "selectStatus": "Selecionar status",
            "targetName": "Nome do alvo/meta",
            "welcomeAssessmentUser": "Bem-vindo, {{user}}",
            "createAssessmentWizard": "Assistente de criação de avaliação",
            "welcomeAssessmentDescription": "Abaixo, você verá o número de ameaças comerciais que lhe foram atribuídas para análise nesta avaliação. Revise cada ameaça e responda aos fatores de [[risk]] da melhor maneira possível. Esses valores são usados para calcular o valor do Número de prioridade de [[risk]] (RPN) do alvo/meta.",
            "welcomeAssessmentConditions": [
                "Suas respostas são salvas automaticamente, você pode fazer uma pausa enquanto realiza a avaliação.",
                "Você terá a chance de revisar e editar suas respostas antes de enviá-las."
            ],
            "threatsToAssess": "Ameaças para avaliar",
            "timeForAssessments": "Deve levar menos de:",
            "getStarted": "Iniciar",
            "minutesToCompleteAssessment": "{{minutes}} minutos",
            "minuteToCompleteAssessment": "{{minute}} minuto",
            "estimatedTimeToCompleteAssessment": "Tempo estimado para conclusão",
            "accountingThreats": "{{completed}} de {{available}}",
            "removeAssessment": "Remover avaliação",
            "removeAssessmentDescription": "Tem certeza de que deseja remover esta avaliação?",
            "searchTargetName": "Pesquisar nome do alvo/meta",
            "searchRecipientName": "Pesquisar nome do destinatário",
            "searchPriorityNumber": "Pesquisar número de prioridade",
            "selectCreationDate": "Selecionar data de criação",
            "deleteAssessment": "Excluir avaliação",
            "completeAssessment": "Avaliação concluída",
            "statusList": {
                "open": "Abrir/Aberto",
                "waitingApproval": "Aguardando aprovação",
                "abandoned": "Abandonado(a)",
                "closed": "Fechado(a)",
                "archived": "Arquivado(a)",
                "deleted": "Excluído(a)",
                "inProgress": "Em andamento"
            },
            "sendEmailDescription": {
                "selectDueDate": "",
                "selectApprover": "",
                "reviewEmail": "",
                "sendFinish": ""
            },
            "loadingAssessment": "Carregando avaliação...",
            "assessmentExistHelperText": "Quando os resultados da pesquisa estão destacados em amarelo, significa que eles têm uma avaliação em aberto. Se uma nova avaliação for criada, as antigas serão fechadas e marcadas como abandonadas.",
            "assessmentNotFound": "Não foi possível encontrar a avaliação.",
			"assessmentFailedStart": "Falha ao iniciar a avaliação.",
            "targetType": {
                "Department": "[[Department]]",
                "Location": "Localização",
                "Company": "Empresa"
            }
        },
        "threatDetail": {
            "maximum": "Máximo(a)",
            "minimum": "Mínimo(a)",
            "submitAssessment": "Enviar avaliação",
            "factorsMinMax": {
                "impact": {
                    "min": "Menor quantidade de impacto",
                    "max": "Alto impacto",
                    "verbiage": "O impacto do [[risk]] fornece uma estimativa das possíveis perdas relacionadas a um [[risk]]."
                },
                "probability": {
                    "min": "Probabilidade mínima",
                    "max": "Muito provável",
                    "verbiage": "Probabilidade de [[risk]] é descobrir a probabilidade de um [[risk]] acontecer. Essa possibilidade pode ser baseada no que aconteceu no passado."
                },
                "detection": {
                    "min": "Tempo de aviso longo",
                    "max": "Pouco ou nenhum tempo de aviso",
                    "verbiage": "A detecção de [[risk]]s é usada para medir a capacidade de encontrar [[risk]]s antes que eles aconteçam."
                },
                "mitigation": {
                    "min": "Pouca ou nenhuma mitigação em curso",
                    "max": "Altamente mitigado",
                    "verbiage": "Mitigação de [[risk]]s é quando uma empresa reduz os [[risk]]s de seus negócios ao tornar as operações mais eficientes e se expondo a menos [[risk]]s."
                },
                "multiplier": {
                    "min": "Mínimo(a) ",
                    "max": "Máximo(a) ",
                    "verbiage": "Use para mostrar como você se sente sobre a ameaça comercial."
                }
            }
        },
        "assessmentDetails": {
            "assessmentDetails": "Detalhes da avaliação",
            "approveAssessment": "Aprovar avaliação",
            "completionDate": "Data de conclusão",
            "riskScore": "Pontuação de [[risk]]",
            "threatHazard": "Ameaça/[[risk]]",
            "emailRecipient": "Enviar e-mail ao destinatário",
            "emailApprover": "Enviar e-mail ao aprovador",
            "assessmentSubmittedSuccessfully": "A avaliação foi enviada com sucesso",
            "assessmentSubmittedUnsuccessfully": "A avaliação não foi enviada com sucesso",
            "mainVerbiage": "Abaixo, você verá o número de ameaças que lhe foram atribuídas para análise nesta avaliação. Revise cada ameaça e responda aos fatores de [[risk]] da melhor maneira possível e adicione comentários conforme necessário. Esses valores são usados para calcular o valor do Número de prioridade de [[risk]] (RPN) do {{targetType}}.",
            "titleVerbiage": "Avaliação de [[risk]] para {{target}}",
            "dirtyMessageTitle": "Salvar alterações",
            "dirtyMessageBody": "Deseja salvar suas alterações para {{threatName}}?",
            "msgSaveAssessmentResultFailed": "Falha ao salvar avaliação.",
            "assessmentComplete": "Esta avaliação já foi respondida, obrigado.",
            "declineAssessmentDescription": "Tem certeza de que deseja recusar esta avaliação? O destinatário receberá os comentários enviados abaixo.",
            "declineAssessment": "Recusar avaliação",
            "assessmentDeclined": "Avaliação recusada",
            "approvalComments": "Comentários de aprovação",
            "archiveMessage": "Tem certeza de que deseja arquivar esta avaliação?",
            "abandonMessage": "Tem certeza de que deseja abandonar esta avaliação?",
            "closeMessage": "Tem certeza de que deseja fechar esta avaliação?"
        },
        "analytics": {
            "highestRiskValue": "Valor de [[risk]] mais alto",
            "columnsSelected": "{{columns}} colunas selecionadas",
            "searchAssessment": "Pesquisar avaliação",
            "targetTypeName": "Tipo de alvo/meta",
            "closeDate": "Data de encerramento"
        },
        "initiative": {
            "newInitiative": "Nova iniciativa",
            "listHelperText": "Bem-vindo(a) ao Preparis Risk! Abaixo está uma lista de iniciativas de [[risk]] onde você pode criar e acompanhar o resultado do seu ciclo de avaliações de [[risk]].",
            "removeInitiative": "Remover iniciativa",
            "removeInitiativeDescription": "Tem certeza de que deseja remover esta iniciativa?",
            "searchCompletionDate": "Pesquisar data de conclusão"
        },
        "initiativeDetails": {
            "initiatives": "Iniciativas",
            "name": "Nome",
            "dueDate": "Data final",
            "assessmentFinding": "Descobertas da avaliação",
            "goal": "Meta",
            "findingsPlaceholder": "Insira suas descobertas da avaliação aqui",
            "findingsGoal": "Insira suas metas aqui",
            "modalMsg": "{{field}} para a iniciativa",
            "selectAssessment": "Selecionar avaliações",
            "targets": "Alvos/Metas",
            "rpn": "RPN",
            "type": "Tipo",
            "creationDate": "Data de criação",
            "selectAStatus": "Selecione um status",
            "assessmentSelectVerbiage": "Selecione as avaliações que deseja incluir nesta iniciativa de [[risk]].",
            "targetTypeName": "Tipo de alvo/meta",
            "completedOn": "Concluído(a) em",
            "initiativeSavedSuccessfully": "A iniciativa foi salva com sucesso",
            "initiativeSavedUnsuccessfully": "A iniciativa não foi salva com sucesso",
            "topVerbiage": "As iniciativas de [[risk]] permitem documentar o que foi descoberto durante o período de avaliação e definir metas para mitigação futura."
        },
        "dashboard": {
            "assessmentsByRpn": "Avaliações por Número de prioridade de [[risk]]",
            "riskBand": "Faixa de [[risk]]",
            "riskMatrix": "Matriz de [[risk]]",
            "riskOverTime": "[[Risk]] com o passar do tempo",
            "helperText": "No gráfico Avaliação por Número de prioridade de [[risk]] (RPN), escolha a avaliação que deseja visualizar.",
            "rpnOverTimeDescription": "No gráfico Avaliação por Número de prioridade de [[risk]] (RPN), escolha a avaliação que deseja visualizar."
        }
    }
}
