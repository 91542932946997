{
    "businessContinuity": {
        "planner": "[[Planner]]",
        "bcPlanner": {
            "businessContinuity": "Continuidade de Negócios [[Planner]]",
            "continuityOfOperationPlanning": "Continuidade das Operações [[Planner]]",
            "emergencyOperations": "Operações de emergência [[Planner]]",
            "disasterRecovery": "Recuperação de desastres [[Planner]]",
            "businessContinuityNoPlanner": "Continuidade de Negócios",
            "continuityOfOperationPlanningNoPlanner": "Continuidade das Operações",
            "emergencyOperationsNoPlanner": "Operações de Emergência",
            "introductionHeader": "Monte seu plano. Vamos começar.",
            "introductionSecondaryDescription": "Você foi selecionado como administrador da sua organização.",
            "introductionDescription": "Você será orientado a configurar seu plano de continuidade de [[Departments]]. Abaixo estão as etapas para concluir seu plano. Você receberá dados pré-carregados para criar seu plano.",
            "responseTeamsDescription": "As organizações precisam estabelecer equipes de resposta de continuidade para lidar com emergências e continuar operando durante interrupções não planejadas. Essas equipes comprometidas, que possuem habilidades e recursos especializados, garantem que as decisões sejam tomadas com agilidade e as ações sejam organizadas para manter a perenidade dos negócios e reduzir perdas.",
            "buildAndManageDescription": "Identifique os [[Departments]] da sua organização para alocar recursos adequadamente e estabelecer uma comunicação clara, garantindo resiliência e minimizando possíveis interrupções durante um incidente.",
            "sendEmailDescription": "Notifique sua equipe enviando um e-mail de apresentação.",
            "trackDepartmentsDescription": "Depois de criar e atribuir seus [[Departments]], acompanhe o progresso deles.",
            "departmentSecondaryDescription": "Você foi selecionado como líder de equipe para o seguinte [[Department]]",
            "departmentSecondaryDescriptionSme": "Você foi selecionado como especialista no assunto para o seguinte [[Department]]",
            "departmentIntroductionDescription": "Você será orientado a configurar seu plano de continuidade de negócios do [[Department]]. Abaixo estão as etapas para concluir seu plano. Você receberá dados pré-carregados para criar seu plano.",
            "departmentDetails": "[[Department]] Detalhes",
            "oosDescription": "Fornecer uma assunção ordenada e predefinida de responsabilidades durante uma emergência ou evento. Eles devem ser desenvolvidos para apoiar diretamente as operações do dia-a-dia.",
            "doaDescription": " As delegações de autoridade garantem que os [[processes]] da entidade possam continuar e garantir uma resposta rápida a qualquer situação de emergência que exija a implementação do plano.",
            "departmentResponseTeamsDescription": "A entidade tem equipes pré-identificadas de continuidade de operações relacionadas à execução deste plano. As seguintes equipes foram designadas e são parte integrante deste plano.",
            "buildManageDescription": "Os [[processes]] organizacionais determinados como atividades críticas são usados para identificar tarefas e recursos de suporte que devem ser incluídos no processo de [[planner]] de continuidade da organização.",
            "processesTrackDescription": "Depois de criar e atribuir seus [[processes]], acompanhe o progresso deles.",
            "processesTrackDescriptionSme": "Aqui você pode acompanhar",
            "departmentInformation": "[[Department]] Informações",
            "departmentDescriptionLabel": "[[Department]] Descrição",
            "departmentAlternateLabel": "[[Team Lead]] Alternativo",
            "departmentDescription": "A descrição do [[Department]] define o que seu [[Department]] faz. É uma visão geral concisa e de uma linha de por que a organização existe e por que a identidade da sua cooperativa é importante.",
            "departmentTeamLeadAlternateDescription": "Designe um [[Team Lead]] alternativo para este departamento para garantir a continuidade operacional no caso de um incidente.",
            "myDepartments": "Meus [[Departments]]",
            "backToMyDepartments": "Voltar para meus [[Departments]]",
            "myDepartmentsSecondaryDescription": "Selecione um dos seus [[departments]] abaixo para começar.",
            "myDepartmentsDescription": "Depois de selecionar um [[department]], você começará a passar pelo processo de construção de seu plano para esse [[department]]. Você poderá voltar a esta seção a qualquer momento para mudar para outro departamento, se necessário.",
            "departmentDescriptionStepSecondary": "Insira a descrição do seu [[department]].",
            "departmentDescriptionStepLead": "Você pode optar por inserir uma descrição concisa e de uma linha do seu [[department]] ou área de negócios.",
            "departmentAlternateStepSecondary": "Defina sua alternativa aqui.",
            "departmentAlternateStepLead": "Selecione um indivíduo como seu Líder de Equipe Suplente que estará disponível e preparado para assumir as responsabilidades de Líder de Equipe em sua ausência. Um Suplente não é obrigado a continuar e pode ser definido posteriormente.",
            "selectPlanType": "Selecione um plano",
            "eopIntroductionSecondaryDescription": "Você foi selecionado como administrador ou líder anexo da sua organização.",
            "eopIntroductionDescription": "Você será orientado na configuração de seu plano de operações de emergência. ",
            "eopSupersessionDescription": "Documente a substituição deste documento.",
            "eopGeneralQuestionsDescription": "Esta seção abordará diversas questões gerais relacionadas ao Planejamento de Operações de Emergência (EOP).",
            "eopResponseTeamsDescription": "As Equipes de Resposta são fundamentais para as operações ou a organização para a execução deste plano. ",
            "mobileBrowserWarning": "No momento, o Preparis Planner não oferece suporte a navegadores móveis. Mude para um navegador de desktop para continuar.",
            "annexBuildMangeDescription": "Os anexos organizacionais para atividades críticas são usados para identificar as agências primárias e de apoio e as tarefas pelas quais são responsáveis.",
            "annexesTrackDescription": "Resposta Depois de criar e atribuir seus anexos, acompanhe seu progresso.",
            "disasterRecoveryNoPlanner": "Recuperação de desastres",
            "itdrIntroductionSecondaryDescription": "Você foi selecionado como administrador de TI da sua organização.",
            "itdrIntroductionDescription": "Você será orientado na configuração do seu plano de recuperação de desastres. Veja a seguir as etapas para concluir seu plano.",
            "crisisManagementTeam": "Equipes de gestão de crises",
            "itdrCrisisManagementTeamDescription": "Estas equipas desempenham um papel crucial no planeamento da continuidade, garantindo que uma organização possa responder e recuperar eficazmente de várias crises ou perturbações. ",
            "incidentResponseTeams": "Equipes de resposta a incidentes",
            "itdrIncidentResponseTeamsDescription": "Crie equipes de resposta a incidentes que serão necessárias para colocar seu plano de DR em ação e gerenciar a resposta.",
            "itdrBuildAndManageDescription": "Identifique as principais [[technologies]] utilizadas em sua organização para criar runbooks de recuperação de desastres de maneira eficaz.",
            "itdrsendEmailDescription": "Defina uma data de vencimento e notifique sua equipe enviando um e-mail de apresentação.",
            "trackTechnologiesDescription": "Depois de desenvolver e atribuir suas [[technologies]], acompanhe seu progresso.",
            "itdrTechnologyOwnerSecondaryDescription": "Você foi selecionado como a PME de [[technology]] da sua organização.",
            "itdrTechnologyOwnerDescription": "Você será orientado na configuração de sua [[technology]]. ",
            "itdrVendorsDescription": "Selecione os fornecedores que oferecem suporte ou fornecem serviços exigidos por esta [[technology]].",
            "itdrUpstreamDependencies": "Dependências a montante",
            "itdrUpstreamDependenciesDescription": "Identifique e documente dependências upstream que mantêm essa [[technology]].",
            "itdrApplicationDetailsAndAttachments": "Detalhes e anexos de [[Technology]]",
            "itdrApplicationDetailsAndAttachmentsDescription": "Documente os principais detalhes e anexe documentos de suporte para esta [[technology]].",
            "itdrTaskSets": "Conjuntos de tarefas",
            "itdrTaskSetsDescription": "Defina conjuntos de tarefas com procedimentos detalhados e passo a passo para a recuperação ou restauração da [[technology]].",
            "itdrEstimatedRecoveryTime": "Tempo estimado de recuperação",
            "itdrEstimatedRecoveryTimeDescription": "Revise e ajuste o tempo estimado de recuperação para restauração tecnológica, fornecendo um cronograma realista com base em avaliações calculadas."
        },
        "bcDashboard": "Painel de controle",
        "bcAdmin": "Admin",
        "admin": {
            "confirmReset": "Tem certeza de que deseja redefinir seus dados? Eles não poderão ser recuperados.",
            "title": "Administração",
            "departmentPlanTemplateFileName": "DepartmentPlanTemplate.docx",
            "downloadTemplateFailed": "Não foi possível baixar o modelo de [[plan]] de [[Departments]].",
            "saveDepartmentPlanTemplateFailed": "Não foi possível salvar o modelo de [[plan]] de [[Departments]]",
            "saveDepartmentPlanTemplateSuccess": "Modelo de [[plan]] de [[Departments]] salvo com sucesso.",
            "freshStartHeader": "Novo começo",
            "manageIndustries": "Gerenciar indústrias",
            "manageIndustriesDescription": "Crie novas indústrias, gerencie modelos de [[Departments]] e [[processes]] e atualize impactos de [[processes]], RTO e estratégias. (Apenas para administradores do Preparis.)",
            "freshStartDescription": "Gostaria de começar do zero com seu planejamento? Ao pressionar o botão Reset, você limpará todos os dados de planejamento. Lembre-se de que essa ação excluirá permanentemente seus dados, tornando-os irrecuperáveis. Tenha cuidado, pois essa decisão é irreversível.",
            "resetSuccess": "Redefinição de dados de continuidade de negócios bem-sucedida",
            "resetFailed": "Falha na redefinição de dados de continuidade de negócios",
            "browseIndustries": "Navegar pelos setores",
            "manageGenericIndustry": "Gerenciar setor genérico",
            "genericIndustry": "Setor genérico",
            "showMTD": "Mostrar tempo de inatividade máximo tolerável",
            "showMTDDescription": "O tempo máximo de inatividade tolerável representa a quantidade total de tempo que a empresa está disposta a aceitar para uma interrupção do [[process]] de negócios.",
            "enableMTDSuccess": "Permitir tempo máximo de inatividade tolerável ativado",
            "enableMTDFail": "Falha ao permitir tempo máximo de inatividade tolerável",
            "disableMTDSuccess": "Permitir tempo máximo de inatividade tolerável desativado",
            "disableMTDFail": "Falha ao desativar o tempo máximo de inatividade tolerável",
            "template": {
                "industries": "Setores",
                "industry": "Setor",
                "newIndustryFromGeneric": "Novo setor de genérico",
                "newIndustryFromScratch": "Novo setor do zero",
                "newIndustry": "Novo setor",
                "renameIndustry": "Renomear setor",
                "copyGenericIndustry": "Copiar setor genérico",
                "industryName": "Nome do setor",
                "msgSuccessSaveIndustry": "{{ industryName }} salvo com sucesso",
                "msgFailSaveIndustry": "Erro ao salvar o setor",
                "duplicateIndustryName": "Este nome de setor já está sendo usado",
                "newIndustryFromCsv": "Setor de importação",
                "errorGettingDepartmentsMsg": "Erro ao recuperar [[Departments]]",
                "errorGettingProcessesMsg": "Erro ao recuperar [[processes]]",
                "errorGettingProcessDetails": "Erro ao recuperar detalhes do [[process]]",
                "errorGettingIndustriesMsg": "Erro ao recuperar setores",
                "errorGettingContinuityStrategy": "Erro ao recuperar a estratégia de continuidade",
                "newDepartment": "Novo [[Departments]]",
                "department": "[[Departments]]",
                "newProcess": "Novo [[process]]",
                "process": "[[processes]]",
                "departments": "[[Departments]]",
                "processes": "[[processes]]",
                "continuityStrategy": "Estratégia de continuidade",
                "details": "Detalhes",
                "errorInvalidIndustryFileType": "Tipo de arquivo inválido",
                "noeFileSelectedMsg": "Nenhum arquivo selecionado",
                "uploadIndustrySuccess": "Setor importado com sucesso",
                "uploadIndustryDescription": "Faça upload de um arquivo de configuração do setor",
                "uploadIndustry": "Setor de importação",
                "dropFileInstruction": "Solte um arquivo de configuração aqui",
                "loadingIndustryFile": "Carregando arquivo",
                "selectedFileLabel": "Arquivo selecionado",
                "noFileSelectedLabel": "Nenhum arquivo selecionado",
                "noFileSelectedMsg": "Selecione um arquivo"
            },
            "revisionPeriodHeader": {
                "continuityOfOperationPlanning": "Ciclo de Revisão COOP",
                "emergencyOperations": "Ciclo de Revisão EOP",
                "businessContinuity": "Ciclo de Revisão",
                "disasterRecovery": "Ciclo de Revisão"
            },
            "revisionPeriodDescription": "Iniciar um novo ciclo de revisão manterá todos os dados existentes, mas redefinirá o progresso do planejador para Não iniciado. Você pode criar apenas um ciclo futuro.",
            "btnStartNewRevisionPeriod": "Novo ciclo de revisão",
            "lblRevisionPeriodName": "Nome",
            "lblRevisionPeriodStartDate": "Data de início",
            "lblRevisionPeriodEndDate": "Data final",
            "msgErrorLoadingCurrentPortalRevision": "Erro ao carregar o ciclo de revisão atual",
            "spinnerMsgStartingNewRevisionPeriod": "Salvando o ciclo de revisão de continuidade de negócios...",
            "currentPeriodText": "Ciclo atual: '{{ periodName }}' começado em {{ periodStartDate }}",
            "nextPeriodText": "Próximo ciclo: '{{ periodName }}' começa em {{ periodStartDate }}",
            "msgSuccessCreatingNewRevisionPeriod": "Novo ciclo de revisão criado",
            "msgErrorCreatingNewRevisionPeriod": "Falha ao criar novo ciclo de revisão",
            "newRevisionPeriodModal": {
                "title": "Novo ciclo de revisão",
                "defaultName": "Ciclo de revisão {{ date }}",
                "msgInvalidDateRange": "A data de início deve ser anterior à data de término"
            },
            "bcImpactSeverityDescriptions": {
                "editImpactSeverityDescriptions": "Editar descrições de gravidade de impacto",
                "impactSeverityDescriptionsSettingUpdated": "Configuração das descrições de gravidade do impacto atualizada",
                "impactSeverityDescriptions": "Descrições da gravidade do impacto"
            },
            "incidentCommunications": {
                "menu": "Comunicações de Incidentes",
                "title": "Comunicações de Incidentes",
                "description": "Veuillez configurer ces boutons en ajoutant un groupe à chaque bouton. Ces boutons seront ajoutés à chaque incident et peuvent être sélectionnés pour envoyer à chaque individu une conférence téléphonique, un e-mail ou un SMS.",
                "msgLabel": "Configuration des communications d'incident",
                "msgErrorLoadingSetting": "Erreur lors du chargement de la configuration des communications d'incident",
                "msgErrorDuplicateGroups": "Des groupes en double sont sélectionnés",
                "confirmRemoveGroup": {
                    "header": "Supprimer le groupe de communication d'incident",
                    "body": "Voulez-vous vraiment supprimer le groupe de communication d'incident?",
                    "text": "Supprimer"
                },
                "selectPlaceholder": "Sélectionnez un groupe",
                "selectedTeamMembers": "Membres de l'équipe sélectionnés",
                "noGroupSelected": "Nenhum grupo selecionado"
            },
            "planWatermark": "Marca d'água do plano",
            "bcPlanWatermarkIsOnSettingUpdated": "A marca d'água do plano BC está ativada atualizada",
            "visualTimelineOfRto": "Linha do tempo visual do RTO",
            "rtoDescription": "Os objetivos de tempo de recuperação definem a maior quantidade de tempo que um recurso do sistema pode permanecer indisponível antes que haja um impacto inaceitável em outros recursos do sistema e [[processes]] de negócios.  ",
            "rtoHeader": "Definir Intervalos RTO",
            "rtoWithMtdHeader": "Definir intervalos RTO e MTD",
            "saveRtoErrorMessage": "Você tem RTO inválido.",
            "saveRto": "Salvar RTO",
            "saveRtoDescription": "Ao alterar os RTOs dos sistemas, todos os dados de impacto do [[Process]] salvos serão perdidos e isso também desativará a pré-seleção de impactos de acordo com as melhores práticas. ",
            "saveImpactCategory": "Salvar categoria de impacto",
            "saveImpactCategoryDescription": "Ao alterar as categorias de impacto do sistema, todos os dados de impacto do [[Process]] salvos podem ser perdidos. ",
            "cancelHeader": "Alterações não salvas",
            "cancelDescription": "Você tem alterações não salvas. ",
            "resetRto": "Reiniciar RTO",
            "resetRtoDescription": "Isso redefinirá os RTOs do sistema para seus padrões, o que permitirá que os valores de impactos sejam pré-selecionados de acordo com as melhores práticas. ",
            "addCategory": "Adicionar categoria",
            "editImpactCategories": " Editar categorias de impacto",
            "resetImpactCategoriesHeader": "Redefinir categorias de impacto",
            "resetImpactCategoriesMsg": "Isso redefinirá as categorias de impacto do sistema para seus padrões e todas as categorias personalizadas serão removidas.  ",
            "resetImpactSeverity": "Redefinir gravidade do impacto",
            "resetImpactSeverityDescription": "Isso redefinirá os nomes dos níveis de gravidade para seus valores padrão.  ",
            "confirmDisableBcScenarioBasedStrategy": {
                "header": "Desativar estratégia baseada em cenário",
                "body": "Tem certeza de que deseja desabilitar as Estratégias Baseadas em Cenário BC?"
            },
            "confirmDisableBcLocationBasedStrategy": {
                "header": "Desativar estratégia baseada em localização",
                "body": "Todas as estratégias associadas ao local serão removidas.\n\n "
            },
            "updateScenarioBasedSettingToastr": {
                "success": "Estratégia BC baseada em cenário atualizada.",
                "failed": "Falha ao atualizar estratégia BC baseada em cenário"
            },
            "updateLocationBasedSettingToastr": {
                "success": "Estratégia BC baseada em localização atualizada.",
                "failed": "Falha ao atualizar a estratégia BC baseada em localização"
            },
            "updatingStrategy": "Atualização da definição da estratégia de continuidade de negócios",
            "errorInBCTemplateHeader": "Erro(s) no Modelo",
            "errorInBCTemplateBody": "O modelo que você está tentando carregar contém alguns erros.<br />Corrija os erros no modelo e tente novamente ou entre em contato com o suporte técnico.<br />O erro: <b>{{error}}</b>",
            "errorValidatingBCTemplateHeader": "Erro ao validar modelo",
            "errorValidatingBCTemplateBody": "Pelo menos um [[department]] precisa ser configurado antes que você possa carregar um modelo personalizado.",
            "strategiesHeader": "Estratégias",
            "scenarioBasedStrategies": "Estratégias de continuidade baseadas em cenários",
            "locationBasedStrategies": "Estratégias de Continuidade Baseadas na Localização",
            "planTypeHeader": "Tipos de Plano",
            "msgPlanTypeUpdatedSuccess": "Tipo de plano atualizado",
            "msgPlanTypeUpdatedFail": "Falha ao atualizar o tipo de plano",
            "msgPlanTypeRequired": "É necessário pelo menos 1 tipo de plano"
        },
        "welcome": {
            "welcomeBc": "Bem-vindo, {{userProfileName}}",
            "welcomeManagementDescription": "Vamos construir sua equipe de continuidade de negócios. Fornecemos um vídeo abaixo para explicar o que você está prestes a fazer.",
            "welcomeTeamLeadDescription": "Como líder de equipe, você foi selecionado para criar o [[plan]] de continuidade de negócios para {{functionalAreaName}}. Clique no vídeo abaixo para obter breves instruções sobre como começar a usar o Preparis Planner.",
            "errorRetrievingVideo": "Erro ao recuperar vídeo",
            "welcomeTeamMemberDescription": "Como um valioso especialista no assunto, você foi selecionado para criar o [[plan]] de continuidade de negócios para {{businessProcessName}}.  Clique no vídeo abaixo para obter breves instruções sobre como começar a usar o Preparis Planner."
        },
        "buildYourTeam": {
            "buildYourTeam": "Construa sua equipe",
            "buildYourTeamDescription": "Abaixo, você encontrará uma lista de [[Departments]] comuns em seu setor. Clique em um [[Departments]] para designar um líder de equipe que será responsável por validar os [[processes]] dentro daquela área e adicionar quaisquer [[Departments]] adicionais que possam estar faltando.",
            "teamLeads": "Líderes de equipe",
            "contactTeamLeadsAndAlternates": [
                "Contate os [[Team Leads]]",
                "e suplentes"
            ],
            "teamLeadModal": {
                "headerLabel": "Selecionar um líder de equipe para {{name}}",
                "searchUserLabel": "Procurar líder de equipe",
                "currentUserLabel": "Líder de equipe atual"
            },
            "teamMembers": "Membros da equipe",
            "msgSuccessSaveFunctionalArea": "{{functionalAreaName}} salvo com sucesso",
            "msgFailSaveFunctionalArea": "Falha ao salvar [[Departments]].",
            "msgFailSaveFunctionalAreaPermission": "Seu login não tem permissão para salvar este [[Departments]].",
            "msgFailSaveFunctionalAreaDuplicateName": "Um [[Departments]] existente já tem este nome.",
            "removePortalFunctionalAreaSuccess": "{{functionalAreaName}} removido",
            "removePortalFunctionalAreaError": "Falha ao remover {{functionalAreaName}}",
            "unassignPortalFunctionalAreaSuccess": "{{userName}} desatribuído de {{functionalAreaName}}",
            "unassignPortalFunctionalAreaError": "Falha ao cancelar a atribuição de {{userName}} de {{functionalAreaName}}",
            "assignPortalFunctionalAreaSuccess": "{{userName}} atribuído a {{functionalAreaName}}",
            "confirmRemoveFunctionalArea": {
                "header": "Remover [[Departments]]",
                "body": "Tem certeza de que deseja remover '{{ functionalAreaName }}'?",
                "text": "Remover"
            },
            "removeDepartmentSuccess": "[[Departments]] removido com sucesso",
            "removeDepartmentError": "Erro ao remover o [[Departments]]",
            "confirmRemovePlan": {
                "header": "Remover [[plan]]",
                "body": "Tem certeza de que deseja remover '{{ planName }}'?",
                "text": "Remover"
            },
            "newFunctionalArea": "Novo [[Departments]]",
            "renameFunctionalArea": "Renomear [[Departments]]",
            "newPlan": "Novo [[plan]]",
            "msgLimitedResult": "Exibir os 100 melhores",
            "noPermission": "O usuário não tem as permissões adequadas",
            "titleFunctionalArea": "[[Departments]]",
            "functionalAreaDescription": "Abaixo, você pode editar o nome do [[Departments]] para refletir de perto as funções de negócios dentro de sua empresa. Você também verá uma lista dos [[processes]] de negócios associados a este [[Departments]]. Isso fornecerá mais informações ao construir sua equipe",
            "newFunctionalAreaDescription": "Insira o nome do novo [[Departments]] abaixo e adicione quaisquer [[processes]] aplicáveis na seção de [[processes]]. Você também pode optar por ignorar a seção de [[processes]] e permitir que o líder da equipe responsável adicione os [[processes]] necessários posteriormente. Basta clicar em criar para salvar sua entrada.",
            "lblFunctionalAreaName": "Nome do [[Departments]]",
            "functionalAreaName": "Inserir nome do [[Departments]]",
            "lblProcesses": "[[processes]]",
            "duplicateFunctionalAreaName": "Já existe um [[Departments]] com o mesmo nome.",
            "validateProcess": "Validar os [[processes]] para {{functionalAreaName}}",
            "validateProcessDescription": "Abaixo está a lista inicial dos [[processes]] em sua área. Remova aqueles que não são de sua responsabilidade ou de sua equipe. Adicione quaisquer outros [[processes]] que estejam faltando.",
            "titlePlan": "[[Plans]]",
            "planName": "Nome",
            "lblPlanName": "Inserir nome do [[plan]]",
            "duplicatePlanName": "Já existe um [[plan]] com o mesmo nome.",
            "confirmUnassignTeamLead": {
                "header": "Cancelar atribuição do líder da equipe",
                "body": "Tem certeza de que deseja cancelar a atribuição de '{{ userName }}' deste [[Departments]]?",
                "text": "Cancelar atribuição"
            },
            "assignTeamLead": "Atribuir líder da equipe",
            "unassignTeamLead": "Cancelar atribuição do líder da equipe",
            "noTeamLeadAssigned": "Nenhum líder de equipe atribuído",
            "setDueDate": {
                "header": "Data de Vencimento",
                "body": "Alguns [[Departments]] já têm uma data de vencimento. Deseja definir esta data de vencimento <b>{{dueDate}}</b> para todos os [[Departments]]?",
                "allFunctionalAreas": "Sim",
                "newFunctionalAreas": "Não",
                "msgConfirmBody": "Tem certeza que deseja definir esta nova data de vencimento <b>{{dueDate}}</b> para todos os [[Departments]]?"
            },
            "msgDueDateRequired": "A data de vencimento é necessária para todos os [[Departments]]",
            "introductoryEmailDisclaimer": "O contexto mudará por nome de usuário e [[Departments]]",
            "welcomeManagementDescription": "Clique no vídeo abaixo para obter breves instruções sobre como começar a usar o Preparis Planner.",
            "reviewDepartmentsTooltip": "Certifique-se de que todos os [[Departments]] essenciais em sua organização sejam contabilizados. Para adicionar um novo [[Departments]], clique no cartão Novo [[Departments]].",
            "assignTeamLeaderTooltip": "Para cada [[Departments]] exibido abaixo, selecione um responsável pelos [[processes]] dos [[Departments]]. Para encontrar a pessoa apropriada, clique em Atribuir líder de equipe e pesquise seu nome na barra de pesquisa.",
            "isYourTeamCompleteTooltip": "Depois de atribuir um líder de equipe a cada [[Departments]], clique em Avançar para continuar para a última etapa.",
            "selectDueDateTooltip": "Selecione uma data de vencimento da atribuição. Clique em uma data de vencimento individual para fazer alterações.",
            "reviewEmailTooltip": "Revise o modelo de e-mail e faça as alterações necessárias clicando em Editar. Os modelos de e-mail aplicáveis serão enviados aos usuários com base em seu status (Usuário existente ou Novo usuário). Se forem feitas alterações, observe que elas se aplicarão a todos os destinatários de e-mail.",
            "sendAndFinishTooltip": "Vamos notificar sua equipe enviando um e-mail introdutório. Clique em Enviar e terminar para concluir a construção da sua equipe de continuidade de negócios.",
            "reviewDepartments": "1. Revisar os [[Departments]]",
            "assignTeamLeader": "2. Atribuir um líder de equipe",
            "isYourTeamComplete": "3. Confirmar se sua equipe está completa",
            "selectDueDate": "1. Selecionar uma data de vencimento",
            "reviewEmail": "2. Revisar e-mail",
            "sendAndFinish": "3. Enviar e terminar",
            "emailDescription": "Quaisquer alterações feitas nos e-mails abaixo não serão salvas para uso futuro. Se fizer alterações, certifique-se de que ambos os modelos sejam atualizados com precisão. Observe que a alteração de qualquer informação entre colchetes pode fazer com que as informações pertinentes sejam distorcidas.",
            "manageDepartment": "Gerenciar [[Departments]]",
            "bcmLicenseOverage": "Você atingiu o limite do Business Continuity Manager. ",
            "teamLeadLicenseOverage": "Você atingiu o limite de [[Team Leads]]. ",
            "teamMemberLicenseOverage": "Você atingiu o limite de Especialista no Assunto. ",
            "navigateToSettingDepartment": "Navegue até o [[Department]] vinculado para alterar o nome do [[Department]] do [[Planner]]",
            "buildManageSecondaryDescription": "Defina aqui os seus departamentos.",
            "buildManageDescription": "Um \"[[department]]\" pode ser uma entidade organizacional, um grupo de entidades ou uma área da organização. A cada \"[[department]]\" definido deve ser atribuído um Líder de Equipa responsável por definir os processos do departamento, executar a Análise de Impacto e definir o plano.",
            "introductionEmail": "Atribua uma data de vencimento e notifique os líderes da equipe",
            "introductionEmailSecondaryDescription": "Deve ser definida uma data de vencimento para a conclusão das atividades de desenvolvimento do plano. Você também pode enviar um e-mail (ou pular) para os Líderes de Equipe anunciando sua atribuição.",
            "introductionEmailSecondaryDescriptionUpdated": "Deve ser definida uma data de vencimento para a conclusão das atividades de desenvolvimento do plano. Você também pode enviar um e-mail (ou pular) para os Líderes de Equipe anunciando sua atribuição.",
            "trackDepartmentSecondaryDescription": "Acompanhe e abra os [[Departments]] da sua equipe aqui.",
            "disasterRecoveryIntroductionEmailTitle": "Atribuir uma data de vencimento e notificar os proprietários de [[Technology]]",
            "disasterRecoveryIntroductionEmailDescription": "Deve ser definida uma data de vencimento para a conclusão das atividades de desenvolvimento do plano. Você também pode enviar um e-mail (ou skip) para os proprietários anunciando a atribuição deles."
        },
        "validateProcess": {
            "confirmUnassignTeamMember": {
                "header": "Cancelar atribuição de [[team member]]",
                "body": "Tem certeza de que deseja cancelar a atribuição de '{{ userName }}' deste [[process]]?",
                "text": "Cancelar atribuição"
            },
            "confirmUnassignTeamMemberAndAlternate": {
                "header": "Cancelar atribuição de [[team member]] e suplente",
                "body": "Tem certeza de que deseja cancelar a atribuição '{{ userName }}' e '{{ alternateUserName }}'deste [[process]]?",
                "text": "Cancelar atribuição"
            },
            "confirmRemoveProcess": {
                "header": "Remover [[process]]",
                "body": "Tem certeza de que deseja remover '{{ processName }}'?",
                "text": "Remover"
            },
            "removeProcessSuccess": "[[processes]] removido com sucesso",
            "removeProcessError": "Erro ao remover o [[process]]",
            "teamMemberModal": {
                "headerLabel": "Selecione um especialista no assunto para {{name}}",
                "searchUserLabel": "Procure um membro da equipe",
                "currentUserLabel": "Especialista no assunto atual",
                "teamMembers": "Membros da equipe"
            },
            "titleNewProcess": "Novo [[process]]",
            "processName": "Nome do [[process]]",
            "newProcess": "Novo [[process]]",
            "assignTeamMember": "Delegar",
            "unassignTeamMember": "Cancelar atribuição",
            "noTeamMemberAssigned": "Nenhum delegado atribuído",
            "removeProcess": "Remover [[process]]",
            "renameProcess": "Renomear [[process]]",
            "titleProcess": "[[processes]]",
            "lblProcessName": "Insira o nome do [[process]]",
            "duplicateProcessName": "Já existe um [[process]] com o mesmo nome.",
            "msgSuccessSaveProcess": "{{processName}} salvo com sucesso",
            "msgFailSaveProcess": "Falha ao salvar {{processName}}.",
            "msgFailSaveProcessPermission": "Seu login não tem permissão para salvar este [[process]].",
            "msgFailSaveProcessDuplicateName": "Um [[process]] existente já tem este nome.",
            "removePortalProcessSuccess": "{{processName}} removido",
            "removePortalProcessError": "Falha ao remover {{processName}}",
            "unassignProcessUserSuccess": "{{userName}} não atribuído de {{processName}}",
            "unassignProcessUserError": "Falha ao cancelar a atribuição de {{userName}} de {{processName}}",
            "assignProcessUserSuccess": "{{userName}} atribuído a {{processName}}",
            "assignProcessUserError": "Falha ao atribuir {{userName}} a {{processName}}",
            "AssignmentsDescription": "Para avaliar o impacto em nosso negócio, precisamos de sua opinião. Para cada [[process]], considere e selecione o impacto mais apropriado para o negócio geral se o [[process]] não puder ser executado nos prazos listados. Continue sua avaliação para cada um dos cinco tipos de impacto. Fizemos uma avaliação inicial com base em nossa experiência e nas melhores práticas do setor.",
            "functionalAreas": "[[Departments]]",
            "noProcessesForDepartment": "Nenhum [[process]] foi definido para este [[Departments]]",
            "plans": "[[Plans]]",
            "teamLead": "Líder da equipe",
            "setDueDate": {
                "header": "Data de Vencimento",
                "body": "Alguns [[processes]] já têm uma data de vencimento. Deseja definir esta data de vencimento {{dueDate}} para todos os [[processes]]?",
                "allProcesses": "Sim",
                "newProcesses": "Não",
                "msgConfirmBody": "Tem certeza de que deseja definir esta nova data de vencimento {{dueDate}} para todos os [[processes]]?"
            },
            "msgDueDateRequired": "A data de vencimento é necessária para todos os [[processes]]",
            "introductoryEmailDisclaimer": "O contexto mudará por nome de usuário e [[process]]",
            "reviewGetStarted": "Revisar e começar",
            "trackYourProgress": "Acompanhar o progresso",
            "reviewProcess": "1. Revisar os [[processes]]",
            "assignExpert": "2. Designar um especialista",
            "reviewGetStartedTooltip": "Reveja suas atribuições abaixo, juntamente com as datas de vencimento aplicáveis. Para começar, clique em Iniciar ao lado do [[Departments]] de sua escolha e a qualquer momento. Depois de clicar em Iniciar, você assistirá a um breve vídeo introdutório que fornecerá instruções adicionais.",
            "trackYourProgressTooltip": "Assim que uma atribuição for iniciada, você poderá acompanhar o andamento de cada [[Departments]]. Você também pode acompanhar o andamento dos [[processes]] em cada [[Departments]] nesta tela.",
            "reviewProcessTooltip": "Assegure-se de que todos os [[processes]] essenciais neste [[Departments]] sejam contabilizados. Para adicionar um [[process]] ausente, clique no cartão Novo [[process]]. Para remover qualquer um que não seja aplicável ao seu [[Departments]], clique nas reticências no lado superior direito do cartão do [[process]] e selecione Remover.",
            "assignExpertTooltip": "Para cada [[process]] exibido abaixo, você foi automaticamente designado como o SME (especialista no assunto). Você pode reatribuir qualquer [[process]] da lista a outro SME. Para fazer isso, clique no nome no cartão do [[process]] que deseja atualizar. Para localizar o novo SME, pesquise seu nome na barra de pesquisa.",
            "isYourTeamCompleteTooltip": "Depois de atribuir um especialista no assunto para cada [[process]], clique em Avançar para continuar para a última etapa.",
            "selectDueDateTooltip": "Selecione uma data de vencimento da atribuição. Clique em uma data de vencimento individual para fazer alterações.",
            "sendAndFinishTooltip": "Vamos notificar sua equipe enviando um e-mail introdutório. Clique em Enviar e terminar para concluir a construção de sua equipe de especialistas em continuidade de negócios.",
            "reviewEmailTooltip": "Revise o modelo de e-mail e faça as alterações necessárias clicando no botão Editar. Os modelos de e-mail aplicáveis serão enviados aos usuários com base em seu status (Usuário existente ou Novo usuário). Se forem feitas alterações, observe que as alterações se aplicarão a todos os destinatários que receberem o e-mail.",
            "portalProcessLocationModal": {
                "header": "Escolha a localização",
                "exceedMaxPortalProcessLocations": "Máximo de locais para o [[Process]] excedido",
                "assignPortalProcessLocationError": "falhou ao atribuir localização para o [[Process]]"
            },
            "portalProcessDependenciesModal": {
                "header": "Escolha a dependência",
                "exceedMaxPortalProcessDependencies": "Dependências máximas para o [[Process]] excedidas",
                "assignPortalProcessProcessDependencyError": "Falha ao atribuir dependência para o [[Process]]",
                "PortalProcessProcessDependencySaveSuccessfully": "[[Process]] dependência salva com sucesso",
                "PortalProcessProcessDependencyDeletedSuccessfully": "[[Process]] dependência deletada com sucesso",
                "PortalProcessProcessDependencyDeletedUnSuccessfully": "Falha ao excluir dependência de [[Process]]",
                "currentSelectedProcessDependencies": "Dependências [[Process]] Selecionadas Atuais",
                "availableProcessDependencies": "Dependências [[Process]] Disponíveis",
                "addDependencies": "Adicionar dependências"
            },
            "removeLocationConfirmationModal": {
                "header": "Remover {{locationName}}",
                "body": "Tem certeza de que deseja remover este local deste [[Process]]?  {{locationName}} será perdido."
            },
            "removeDependencyConfirmationModal": {
                "header": "Remover {{dependencyName}}",
                "body": "Tem certeza de que deseja remover esta dependência deste [[Process]]?"
            },
            "removeContinuityStrategyConfirmationModal": {
                "header": "Remover Estratégia de Continuidade",
                "body": "Tem certeza de que deseja remover esta estratégia de continuidade?"
            },
            "dependenciesPageHeader": "Quais são os [[processes]] dependentes para {{ name }}?",
            "dependenciesPageDescription": "Selecione todos os [[Processes]] em seu ou em outros [[departments]] que devem ser executados para que este [[Process]] seja concluído com sucesso.",
            "locationsPageHeader": "Onde esse [[Process]] é usado?",
            "locationsPageDescription": "Selecione os locais onde este [[Process]] está sendo executado para que possam ser atribuídas estratégias de continuidade.",
            "selectProcessSecondaryDescription": "Defina seus [[Processes]] aqui.",
            "selectProcessDescriptionBC": "Um processo de negócios define uma função ou conjunto de funções executadas como parte das operações comerciais diárias em seu departamento ou área de negócios. Você pode “atribuir” a responsabilidade por um Processo a um assunto ou pode reter a responsabilidade primária.",
            "selectProcessDescriptionCOOP": " Uma função essencial define uma função ou conjunto de funções executadas como parte das operações diárias em seu departamento ou organização. Você pode “atribuir” a responsabilidade por uma Função Essencial a um assunto ou pode manter a responsabilidade primária.",
            "tracKProcessesSecondaryDescription": "Acompanhe seus [[Processes]] aqui.",
            "tracKProcessesDescription": "Acompanhe e conclua seus [[Processes]] de [[Departments]].",
            "noPrimary": "Nenhum primário atribuído",
            "noAlternate": "Nenhuma alternativa atribuída",
            "addPrimary": "Atribuir Primário",
            "addAlternate": "Atribuir alternativa",
            "unAssignBothUserSuccess": "{{ userName }} e {{ alternate }} não foram atribuídos de {{ processName }}",
            "unAssignBothProcessUserError": "Falha ao cancelar atribuição {{ userName }} e {{ alternate }} de {{ processName }}"
        },
        "impact": {
            "impactTitle": "Qual é o impacto em <b>{{name}}</b>?",
            "impactDescription": "Para avaliar o impacto em seu negócio, precisamos de sua opinião. Fornecemos uma avaliação inicial com base em nossa experiência e práticas recomendadas do setor. Revise nossas seleções e faça os ajustes conforme necessário, selecionando o impacto apropriado em seu negócio geral se o [[process]] não puder ser concluído nos prazos listados. Continue a avaliação para cada uma das seis categorias de impacto até que todas sejam concluídas para obter seu objetivo de tempo de recuperação na próxima seção.",
            "category": {
                "financial": "Impacto financeiro",
                "financialSeverityLegend": {
                    "insignificant": "Redução potencial para dinheiro em caixa",
                    "significant": "Redução potencial no capital operacional disponível",
                    "catastrophic": "Potencial para reduzir a disponibilidade para continuar operações"
                },
                "memberImpact": "Impacto do membro",
                "clientImpact": "Impacto do [[clients]]",
                "customer": "Membro, [[clients]], impacto do [[clients]]",
                "customerImpact": "Impacto do [[clients]]",
                "customerSeverityLegend": {
                    "insignificant": "Membro, [[clients]], impacto do [[clients]] - insignificante",
                    "significant": "Membro, [[clients]], impacto do [[clients]] - significativo",
                    "catastrophic": "Membro, [[clients]], impacto do [[clients]] - catastrófico"
                },
                "employee": "Moral do funcionário e impacto de retenção",
                "employeeSeverityLegend": {
                    "insignificant": "Moral do funcionário e impacto de retenção - insignificante",
                    "significant": "Moral do funcionário e impacto de retenção - significativo",
                    "catastrophic": "Moral do funcionário e impacto de retenção - catastrófico"
                },
                "reputational": "Impacto na marca ou reputação",
                "reputationalSeverityLegend": {
                    "insignificant": "Impacto na marca ou reputação - insignificante",
                    "significant": "Impacto na marca ou reputação - significativo",
                    "catastrophic": "Impacto na marca ou reputação - catastrófico"
                },
                "operational": "Impacto na entrega de serviços",
                "operationalSeverityLegend": {
                    "insignificant": "Impacto na entrega de serviços - insignificante",
                    "significant": "Impacto na entrega de serviços - significativo",
                    "catastrophic": "Impacto na entrega de serviços - catastrófico"
                },
                "compliance": "Impacto regulatório de conformidade",
                "complianceSeverityLegend": {
                    "insignificant": "Impacto regulatório de conformidade - insignificante",
                    "significant": "Impacto regulatório de conformidade - significativo",
                    "catastrophic": "Impacto regulatório de conformidade - catastrófico"
                }
            },
            "severity": {
                "none": "Sem impacto",
                "insignificant": "Insignificante",
                "significant": "Significativo",
                "catastrophic": "Catastrófico"
            },
            "validationInfo": "Revise cada categoria de impacto antes de prosseguir para a próxima etapa",
            "saveErrorMsg": "Erro ao salvar a análise de impacto",
            "impactAnalysis": "Análise de impacto",
            "impactCategories": "Categorias de impacto",
            "viewImpactCategories": "1. Exibir categorias de impacto",
            "reviewOurSelections": "2. Revisar nossas seleções",
            "makeChanges": "3.  Fazer mudanças",
            "viewImpactCategoriesTooltip": "Categorias de impacto são áreas de impacto que podem afetar seus negócios durante uma crise. Considere o impacto em cada categoria de impacto mostrada se o [[process]] de negócios não puder ser concluído a tempo.",
            "reviewOurSelectionsTooltip": "Com base em nossa experiência e nas melhores práticas do setor, fornecemos uma avaliação inicial para cada categoria de impacto. Essa avaliação é baseada no [[process]] individual e no impacto no negócio como um todo. Reveja nossas seleções para cada categoria e os níveis de gravidade selecionados para cada dia de impacto.",
            "makeChangesTooltip": "Faça os ajustes necessários revisando cada categoria de impacto e selecionando o nível de gravidade apropriado para cada dia de impacto se o [[process]] não puder ser concluído. Depois de concluir cada categoria, clique em Avançar para obter seu objetivo de tempo de recuperação."
        },
        "rto": {
            "rtoTitle": " Qual é o <b>objetivo de tempo de recuperação</b> para <b>{{processName}}</b>",
            "rtoDescription": "Com base nas seleções que você forneceu na análise de impacto, seu tempo de recuperação para <b>{{processName}}</b> é mostrado abaixo. Para editar facilmente suas seleções de impacto, clique no nome da categoria de impacto ao lado do gráfico abaixo para fazer alterações. Você também pode atualizar manualmente o RTO no lado direito da tela.",
            "rto": "RTO",
            "returnToImpactAnalysis": "Retornar para análise de impacto",
            "missingRto": "RTO ausente",
            "countOfProcesses": "Contagem de [[processes]]",
            "recoveryTimeObjective": "1. Objetivo de tempo de recuperação",
            "impactAnalysis": "2. Análise de impacto",
            "impactCategories": "3. Categorias de impacto",
            "recoveryTimeObjectiveTooltip": "Com base nas seleções fornecidas na seção anterior, seu objetivo de tempo de recuperação (RTO) é mostrado abaixo. Você pode atualizar manualmente seu RTO clicando na imagem.",
            "impactAnalysisTooltip": "O gráfico de análise de impacto é uma representação visual das categorias de impacto e seus níveis de gravidade ao longo de cada dia de impacto. Esses níveis foram selecionados na seção anterior.",
            "impactCategoriesTooltip": "Se desejar fazer alterações em qualquer um dos dados da categoria de impacto mostrados no gráfico de análise de impacto, você pode fazer isso clicando no nome da categoria de impacto."
        },
        "processAnalysis": {
            "AssignmentsDescription": "Para avaliar o impacto em nosso negócio, precisamos de sua opinião. Para cada [[process]], considere e selecione o impacto mais apropriado para o negócio geral se o [[process]] não puder ser executado nos prazos listados. Continue sua avaliação para cada um dos cinco tipos de impacto. Fizemos uma avaliação inicial com base em nossa experiência e nas melhores práticas do setor.",
            "task": "Tarefa",
            "teamMember": "Membro da equipe",
            "process": "[[processes]]"
        },
        "criticalDates": {
            "criticalDatesTitle": "Quais são as datas críticas para {{ name }}?",
            "criticalDateSecondary": "Uma data crítica é uma data ou período de tempo específico e recorrente dentro de um processo que requer maior atenção ou pode exigir um aumento no Objetivo de Tempo de Recuperação (RTO) durante um incidente.",
            "criticalDatesDescription": "Para avaliar a prioridade desse [[process]], durante uma possível interrupção, é útil saber quando ele é normalmente executado. Selecione uma categoria de data crítica que se aplique ao seu [[process]] de negócios e edite as datas, se aplicável. Você também pode adicionar uma nova data crítica se não for fornecida na lista abaixo.",
            "newCriticalDate": "Nova data crítica",
            "name": "Nome da data crítica",
            "frequency": "Frequência",
            "frequencyTypes": {
                "weekly": "Semanal",
                "biWeekly": "Quinzenal",
                "monthly": "Mensal",
                "quarterly": "Trimestral",
                "semiAnnually": "Semestral",
                "annually": "Anual",
                "oneTimeEvent": "Uma vez"
            },
            "atTheEndOfFrequencyTypes": {
                "weekly": "No final da semana",
                "biWeekly": "No final de duas semanas",
                "monthly": "No fim do mês",
                "quarterly": "No final do trimestre",
                "semiAnnually": "No final de junho e dezembro",
                "annually": "No fim do ano",
                "oneTimeEvent": "Uma vez"
            },
            "onSpecificDayFrequencyTypes": {
                "weekly": "A cada {{ datePartDesc }}",
                "biWeekly": "A cada 2 semanas em {{ datePartDesc }}",
                "monthly": "No {{ datePartDesc }}",
                "quarterly": "A cada 3 meses no {{ datePartDesc }}",
                "semiAnnually": "A cada 6 meses no {{ datePartDesc }}",
                "annually": "Em {{ datePartDesc }}",
                "oneTimeEvent": "Em {{ datePartDesc }}"
            },
            "beginningOn": "Começando em",
            "dayOfWeek": "Dia da semana",
            "beginningOnDate": "Começando em {{ dateFormat }}",
            "sinceDate": "Desde {{ dateFormat }}",
            "specificDate": "Data específica",
            "lastDayOf": "Último dia de...",
            "plusOrMinus": "Mais ou menos alguns dias antes ou depois",
            "nextOccurrence": "Próxima ocorrência",
            "nextOccurrencePhrase": "Entre {{startDate}} e {{endDate}}",
            "addEditModal": {
                "addCriticalDate": "Adicionar data crítica",
                "editCriticalDate": "Editar data crítica",
                "description": "Abaixo você pode editar o nome da data crítica e configurar a frequência, o tipo e a data de início.",
                "duplicateCriticalDatesName": "Já existe uma data crítica com o mesmo nome",
                "criticalDateName": "Nome da data crítica",
                "exampleCriticalDate": "Exemplo de data crítica"
            },
            "confirmRemove": {
                "header": "Remover data crítica",
                "body": "Tem certeza de que deseja remover '{{ name }}'?",
                "text": "Remover"
            },
            "confirmDeselect": {
                "header": "Desmarcar data crítica",
                "body": "Tem certeza de que deseja desmarcar '{{ name }}'?",
                "text": "Deselecionar"
            },
            "saveSuccessMsg": "Datas críticas salvas com sucesso",
            "saveErrorMsg": "Erro ao salvar as datas críticas",
            "loadingErrorMsg": "Erro ao carregar as informações de data crítica",
            "clickToAdd": "Clique para adicionar",
            "nextOccurrenceMsg": "Entre {{ beginning }} e {{ end }}",
            "daysOfWeek": {
                "mondayAbbr": "Seg",
                "tuesdayAbbr": "Ter",
                "wednesdayAbbr": "Qua",
                "thursdayAbbr": "Qui",
                "fridayAbbr": "Sex",
                "saturdayAbbr": "Sáb",
                "sundayAbbr": "Domingo"
            },
            "examples": {
                "seeExampleWeeklyPayroll": "Veja o exemplo: Folha de pagamento semanal",
                "seeExampleMonthlyRent": "Veja o exemplo: Pagamento de aluguel mensal",
                "seeExampleOnetimeInvoice": "Veja o exemplo: Fatura única",
                "payroll": "Folha de pagamento",
                "rentPayment": "Pagamento de aluguel",
                "invoice1320": "Fatura 1320"
            },
            "criticalDates": "Datas críticas",
            "viewExamples": "1. Ver exemplos",
            "createCriticalDates": "2. Criar novas datas críticas",
            "reviewCriticalDates": "3. Revisar suas datas críticas",
            "viewExamplesTooltip": "Durante uma interrupção, é útil saber quando esse [[process]] é executado. Também é importante listar quaisquer outras datas críticas para continuar este [[process]] de negócios durante uma possível interrupção. Fornecemos três exemplos para você ver",
            "createCriticalDatesTooltip": "Para adicionar uma nova data crítica, você pode copiar um cartão de exemplo ou criar um novo cartão.",
            "reviewCriticalDatesTooltip": "Você capturou todas as datas necessárias para este [[process]] de negócios? Se você capturou todas as datas críticas necessárias, clique em Avançar para continuar criando a estratégia de continuidade para este [[process]] de negócios.",
            "errorLoadingCriticalDates": "Falha ao carregar datas críticas",
            "month": {
                "1": "Janeiro",
                "2": "Fevereiro",
                "3": "Março",
                "4": "Abril",
                "5": "Maio",
                "6": "Junho",
                "7": "Julho",
                "8": "Agosto",
                "9": "Setembro",
                "10": "Outubro",
                "11": "Novembro",
                "12": "Dezembro"
            }
        },
        "continuityStrategy": {
            "continuityStrategyTitle": "Estratégia de continuidade para {{ name }}",
            "continuityStrategyForDesc": "A estratégia de continuidade para {{ name }} é para",
            "responsibleEmployeesTemporaryLocationDesc": "Funcionários autorizados a aprovar o espaço de trabalho temporário",
            "responsibleEmployeesMoveTheWorkDesc": "Funcionários que entrarão em contato com o fornecedor e poderão autorizar a transferência",
            "responsibleEmployeesSuspendDesc": "Funcionários autorizados a suspender o [[process]]",
            "designatedEmployeesWorkFromHomeDesc": "Funcionários que serão necessários",
            "designatedEmployeesTemporaryLocationDesc": "Funcionários designados para trabalhar no espaço de trabalho temporário",
            "equipmentNeededLabel": "Selecione os equipamentos de que cada funcionário precisará ou adicione os que estiverem faltando",
            "selectedEquipments": "Equipamentos de que cada funcionário precisará",
            "saveSuccessMsg": "Estratégia de continuidade salva",
            "saveErrorMsg": "Falha ao salvar a estratégia de continuidade",
            "saveEquipmentSuccessMsg": "Equipamento {{names}} adicionado",
            "saveEquipmentErrorMsg": "Falha ao adicionar o equipamento {{names}}",
            "removeEquipmentSuccessMsg": "Equipamento {{names}} removido",
            "removeEquipmentErrorMsg": "Falha ao remover o equipamento {{names}}",
            "saveVendorSuccessMsg": "Fornecedor {{names}} adicionado",
            "saveVendorErrorMsg": "Falha ao adicionar o fornecedor {{names}}",
            "removeVendorSuccessMsg": "Fornecedor {{names}} removido",
            "removeVendorErrorMsg": "Falha ao remover o fornecedor {{names}}",
            "saveAssigneeSuccessMsg": "Funcionário autorizado {{names}} adicionado",
            "saveAssigneeErrorMsg": "Falha ao adicionar o funcionário autorizado {{names}}",
            "removeAssigneeSuccessMsg": "Funcionário autorizado {{names}} removido",
            "removeAssigneeErrorMsg": "Falha ao remover o funcionário autorizado {{names}}",
            "saveAssigneesSuccessMsg": "Funcionários autorizados {{names}} adicionados",
            "saveAssigneesErrorMsg": "Falha ao adicionar funcionários autorizados {{names}}",
            "removeAssigneesSuccessMsg": "Funcionários autorizados {{names}} removidos",
            "removeAssigneesErrorMsg": "Falha ao remover funcionários autorizados {{names}}",
            "saveEmployeeSuccessMsg": "Funcionário designado {{names}} adicionado",
            "saveEmployeeErrorMsg": "Falha ao adicionar o funcionário designado {{names}}",
            "removeEmployeeSuccessMsg": "Funcionário designado {{names}} removido",
            "removeEmployeeErrorMsg": "Falha ao remover o funcionário designado {{names}}",
            "saveEmployeesSuccessMsg": "Funcionários designados {{names}} adicionados",
            "saveEmployeesErrorMsg": "Falha ao adicionar os funcionários designados {{names}}",
            "removeEmployeesSuccessMsg": "Funcionários designados {{names}} removidos",
            "removeEmployeesErrorMsg": "Falha ao remover os funcionários designados {{names}}",
            "saveLocationSuccessMsg": "Localização {{names}} atualizada",
            "saveLocationErrorMsg": "Falha ao atualizar a localização {{names}}",
            "removeLocationSuccessMsg": "Localização {{names}} removida",
            "removeLocationErrorMsg": "Falha ao remover a localização {{names}}",
            "saveTechnologySuccessMsg": "[[Technology]] ou [[application]] {{names}} adicionada",
            "saveTechnologyErrorMsg": "Falha ao adicionar a [[technology]] ou [[application]] {{names}}",
            "removeTechnologySuccessMsg": "[[Technology]] ou [[application]] {{names}} removido",
            "removeTechnologyErrorMsg": "Falha ao remover a [[technology]] ou [[application]] {{names}}",
            "saveInstructionsSuccessMsg": "Instruções adicionais salvas",
            "saveInstructionsErrorMsg": "Falha ao salvar as instruções adicionais",
            "loadErrorMsg": "Erro ao carregar a estratégia de continuidade",
            "missingRtoImpactErrorMsg": "Antes de atribuir a estratégia de continuidade, atribua o RTO",
            "vendorsSelected": "{0} fornecedores selecionados",
            "selectAVendor": "Selecione um fornecedor",
            "technologiesSelected": "{0} [[technologies]] selecionadas",
            "selectATechnology": "Selecione uma [[technology]]",
            "strategyTypes": {
                "workFromAnywhere": "Trabalhe de qualquer lugar",
                "temporaryLocation": "Espaço de trabalho temporário",
                "moveTheWork": "Mover o trabalho",
                "suspend": "Suspender",
                "9c004e84-4eb4-44f1-a008-cd33f5211014": "Trabalhe de qualquer lugar",
                "704acb8f-ed9b-4f11-ab37-0f0139895d5b": "Espaço de trabalho temporário",
                "e56b1600-219c-4985-b8fa-2b31228d201c": "Mover o trabalho",
                "984817b1-c6ee-4c5e-ace7-e558843bdfcb": "Suspender"
            },
            "equipmentTypeModal": {
                "title": "Tipo de equipamento",
                "desc": "Adicionar ou editar um tipo de equipamento a ser usado para esta estratégia de continuidade",
                "typeNamePlaceholder": "Nome do tipo de equipamento",
                "equipments": "[[Equipments]]",
                "equipmentInUseError": "O equipamento é atribuído a uma estratégia de continuidade ativa",
                "deleteEquipmentHeader": "Remover equipamento",
                "deleteEquipmentMessage": "Tem certeza de que deseja remover este equipamento?",
                "duplicateEquipmentNameError": "Nome de equipamento duplicado"
            },
            "searchForVendor": "Procurar por fornecedor",
            "vendorModal": {
                "title": "Fornecedor",
                "desc": "Adicionar ou editar o fornecedor a ser usado para esta estratégia de continuidade",
                "typeNamePlaceholder": "Nome do fornecedor",
                "contactName": "Nome de contato",
                "contactNamePlaceholder": "Nome e sobrenome",
                "contactPhoneNumber": "Telefone para contato",
                "contactPhoneNumberPlaceholder": "(123) 456-7890",
                "additionalNotes": "Notas adicionais",
                "additionalNotesPlaceholder": "Instruções para entrar em contato com o fornecedor",
                "deleteVendorHeader": "Remover fornecedor",
                "deleteVendorMessage": "Tem certeza de que deseja remover este fornecedor?",
                "addVendor": "Adicionar fornecedor",
                "vendors": "Fornecedores",
                "vendorInUseError": "O fornecedor está atribuído a um plano ativo",
                "duplicateVendorNameError": "Nome do fornecedor duplicado",
                "vendorAddress": "Endereço do fornecedor",
                "contractStartDate": "Data de início do contrato",
                "contractExpirationDate": "Data de vencimento do contrato",
                "bulkUploadVendors": "Fornecedores de upload em massa",
                "bulkUploadContacts": "Carregar contatos em massa",
                "seconds": "Segundos",
                "minutes": "Minutos",
                "hours": "Horas",
                "days": "Dias",
                "second": "Segundo",
                "minute": "Minuto",
                "hour": "Hora",
                "day": "Dia",
                "selectRPOTime": "Selecione o horário",
                "bulkUpload": "Carregamento em massa"
            },
            "agencyModal": {
                "title": "Agência",
                "desc": "Adicione ou edite a agência a ser usada para esta estratégia de continuidade",
                "typeNamePlaceholder": "Nome da Agência",
                "contactName": "Nome de Contato",
                "contactNamePlaceholder": "Primeiro e Ultimo Nome",
                "contactPhoneNumber": "Número de Telefone de Contato",
                "contactPhoneNumberPlaceholder": "(123) 456-7890",
                "additionalNotes": "Notas Adicionais",
                "additionalNotesPlaceholder": "Instruções para entrar em contato com a agência",
                "deleteAgencyHeader": "Remover agência",
                "deleteAgencyMessage": "Tem certeza de que deseja remover esta agência?",
                "addAgency": "Adicionar Agência",
                "agencies": "Agências",
                "agencyInUseError": "Agência é atribuída a uma estratégia de continuidade ativa",
                "duplicateAgencyNameError": "Nome da agência duplicado",
                "agencyAddress": "Endereço da Agência",
                "bulkUploadAgencies": "Agências de Upload em Massa",
                "bulkUploadContacts": "Carregar Contatos em Massa",
                "new": "Adicionar Nova Agência",
                "agencyType": "Tipo de Agência",
                "selectType": "Selecione um tipo de agência",
                "rolesFilter": "Filtro de funções"
            },
            "agencyAssignModal": {
                "assignAgency": "Atribuir agência",
                "assignLocation": "Atribuir localização",
                "resultLabel": "Selecione Agência",
                "resultLabelLocation": "Selecione o local",
                "search": "Procure aqui",
                "currentAgenciesLabel": "Agências atuais",
                "assignButton": "Atribuir",
                "currentAgency": "Agência Atual",
                "resultLabelSingle": "Selecione Agência"
            },
            "confirmRemoveVendor": {
                "header": "Remover fornecedor do [[process]]",
                "body": "Tem certeza de que deseja remover este fornecedor deste [[process]]?",
                "text": "Remover"
            },
            "searchForTechnology": "Procurar [[application]] ou [[technology]]",
            "confirmRemoveTechnology": {
                "header": "Remover [[application]] ou [[technology]] do [[process]]",
                "body": "Tem certeza de que deseja remover este [[application]] ou [[technology]] deste [[process]]?",
                "text": "Remover"
            },
         
            "technologyModal": {
              "title": "[[Technology]] ou [[Application]]",
              "desc": "Selecione qualquer [[application]] ou [[technology]] que será necessária ou adicione qualquer que esteja faltando",
              "deleteTechnologyHeader": "Remover [[Technology]]",
              "deleteTechnologyMessage": "Tem certeza de que deseja remover esta [[technology]]?",
              "technologies": "[[Technologies]]",
              "technologyInUseError": "[[Technology]] está atribuída a um plano ativo",
              "technology": "[[Technology]]",
              "duplicateTechnologyNameError": "Nome de [[technology]] duplicado",
              "seconds": "Segundos",
              "minutes": "Minutos",
              "hours": "Horas",
              "days": "Dias",
              "second": "Segundo",
              "minute": "Minuto",
              "hour": "Hora",
              "day": "Dia",
              "selectRPOTime": "Selecione a hora",
              "bulkUpload": "Upload em massa",
              "manageTechnologyTypes": "Gerenciar tipos de [[technology]]",
              "addTechnology": "Adicionar nova [[technology]]",
              "typeNamePlaceholder": "[[Technologies]]",
              "technologyType": "[[Technology]] Tipo",
              "technologyTypes": "[[Technology]] Tipos",
              "technologyUsed": "[[Technology]] Usada",
              "technologyTypesTitle": "Gerencie seus tipos de [[technology]] aqui",
              "tier": "Nível",
              "technologyTypeDeleteHeader": "Excluir tipo [[Technology]]",
              "technologyTypeDeleteBody": "Tem certeza de que deseja excluir este tipo de [[Technology]]?",
              "technologyTypeSavedSuccessfully": "O tipo [[Technology]] foi salvo com sucesso",
              "technologyTypeSavedUnSuccessfully": "O tipo [[Technology]] foi salvo sem sucesso",
              "technologyTypeDeletedSuccessfully": "O tipo [[Technology]] foi excluído com sucesso",
              "technologyTypeDeletedUnSuccessfully": "O tipo [[Technology]] foi excluído sem sucesso"
            },
            "instructionsModal": {
                "title": "Instruções adicionais",
                "instructionsPLaceHolder": "Inserir instruções adicionais"
            },
            "addEmployee": "Adicionar funcionário",
            "pickOrAddVendor": "Selecione um fornecedor ou adicione um novo fornecedor da lista",
            "pickOrAddTechnology": "Selecione qualquer [[application]] ou [[technology]] que será necessário ou adicione qualquer um que esteja faltando",
            "pickOrAddLocation": "Selecione um [[location]] ou adicione um novo local da lista.",
            "searchForLocationPlaceholder": "Localização do espaço de trabalho temporário",
            "externalLocationModal": {
                "title": "Localização do espaço de trabalho",
                "desc": "Adicionar ou editar um local de espaço de trabalho temporário"
            },
            "companyLocation": "Localização da empresa",
            "vendorLocation": "Localização temporária",
            "addVendor": "Adicionar fornecedor",
            "confirmRemoveAssignee": {
                "header": "Remover funcionário do [[process]]",
                "body": "Tem certeza de que deseja remover este funcionário deste [[process]]?",
                "text": "Remover"
            },
            "assigneeModal": {
                "headerLabel": "Selecionar um funcionário",
                "searchUserLabel": "Procurar um funcionário",
                "currentUserLabel": "Funcionários atualmente atribuídos"
            },
            "managerModal": {
                "headerLabel": "Selecione um gerente",
                "searchUserLabel": "Procure um gerente",
                "currentUserLabel": "Gerente Atualmente Atribuído",
                "resultLabel": "Gerentes"
            },
            "continuityStrategy": "Estratégia de continuidade",
            "vendorAlreadySelected": "Fornecedor já selecionado",
            "selectedVendors": "Fornecedores selecionados",
            "technologyAlreadySelected": "[[Application]] ou [[technology]] já selecionado",
            "selectedTechnologies": "[[Applications]] ou [[technologies]] selecionados",
            "selectedLocation": "Localização selecionada",
            "continuityStrategiesByRto": "Estratégias de continuidade por RTO",
            "strategy": "Estratégia",
            "missingRequiredFields": "Reveja os campos que faltam antes de continuar",
            "continuityStrategyInstructions": "Para completar o [[plan]] de continuidade de negócios para este [[process]], selecione uma estratégia de continuidade abaixo e preencha as seções aplicáveis. Depois de terminado, clique em Concluído para finalizar este [[process]] de negócios.",
            "lblAdditionalInstructions": "Instruções adicionais",
            "noAdditionalInstructions": "Sem instruções adicionais",
            "updateInstructions": "Instruções de atualização",
            "uploadDocument": "Upload Documento",
            "noAttachments": "Sem anexos",
            "lblAdditionalQuestions": "Perguntas adicionais",
            "noAdditionalQuestions": "Sem perguntas adicionais",
            "createStrategy": "Criar estratégia",
            "strategyCreated": "Estratégia criada",
            "strategyDeleted": "Estratégia deletada"
        },
        "noAssignedFunctionalArea": "Nenhum [[Departments]] atribuído",
        "noAssignedProcess": "Nenhum [[process]] atribuído",
        "searchUsers": "Digite o nome, a função, o [[Departments]] ou grupo para pesquisar",
        "noAssignedUserError": "Faça uma seleção antes de continuar",
        "sendIntroductoryEmail": "Enviar um e-mail introdutório",
        "sendTeamLeadEmailTitle": "Agora que você criou sua equipe, vamos enviar um e-mail. Um modelo de e-mail foi fornecido para sua revisão no lado direito da tela. Quando estiver pronto para enviar o e-mail, atribua uma data de vencimento e clique em Enviar e terminar.",
        "sendTeamMemberEmailTitle": "Agora que você criou sua equipe, vamos enviar um e-mail. Um modelo de e-mail foi fornecido para sua revisão no lado direito da tela. Quando estiver pronto para enviar o e-mail, atribua uma data de vencimento e clique em Enviar e terminar.",
        "titleAssignments": "{{userProfileName}} atribuições",
        "functionalAreas": "[[Departments]]",
        "titleNavigatePlan": "[[Plans]] de continuidade de negócios",
        "introductoryVideo": "Vídeo introdutório",
        "plans": {
            "plans": "[[Plans]]",
            "generatePlansByDepartment": "Gerar [[plans]] por [[Departments]]",
            "lastGeneratedDate": "Data da última geração",
            "planTemplate": "Modelo de [[plan]]",
            "generatePlans": "Gerar [[plans]]",
            "errGeneratePlanCanceled": "A geração do [[plan]] foi cancelada.",
            "errorDownloadingPlan": "Ocorreu um erro inesperado ao baixar o [[plan]].",
            "errGeneratePlanNoTemplate": "Não é possível iniciar a geração do [[plan]]. O modelo de [[Plan]] não é atribuído.",
            "noPlans": "Sem [[plans]].",
            "generateDepartmentPlans": "Gerar todos os planos do [[Department]]",
            "generateTechnologyPlans": "Gerar todos os planos do [[Technology]]",
            "generateLocationPlans": "Gerar Plantas de Localização",
            "generateCompanyPlan": "Gerar plano da empresa",
            "continuityOfOperationPlanning": "Plano COG",
            "emergencyOperations": "Plano EOP",
            "continuityOfGovernment": "Continuidade de Governo (COG)",
            "businessContinuity": "Plano da empresa",
            "disasterRecovery": "Plano da empresa",
            "templateName": "Nome do Modelo",
            "goToTemplates": "Ir para modelos"
        },
        "functions": "Funções",
		"essentialFunctions": "Funções essenciais",
        "processes": "[[processes]]",
        "manageFunctionalAreas": "Gerenciar [[Departments]]",
        "viewFunctionalAreas": "Ver [[Departments]]",
        "summary": "Resumo",
        "titleOverview": "Visão geral da equipe",
        "overviewDescription": "Abaixo está uma lista dos [[Departments]] com seus respectivos líderes de equipe que agora são responsáveis por completar suas seções individuais do [[plan]] de continuidade. Se você se designou como líder de equipe de um [[Departments]], pode começar a qualquer momento clicando em iniciar. Caso contrário, você pode ver o progresso de cada [[Departments]] individual, visualizando a seção de status.",
        "overviewDescriptionHeader": "Descrição do [[Departments]], Declaração de Missão ou Visão",
        "descriptionSavedSuccessfully": "Descrição salva com sucesso",
        "descriptionSavedUnsuccessfully": "Descrição salva sem sucesso",
        "teamLeadDescription": "Abaixo está uma lista de [[Departments]] atribuídos nos quais você é especialista. Clique no botão iniciar para começar a validar os [[processes]] dentro do [[Departments]] atribuído e delegar os que forem necessários.",
        "processesHeader": "[[Processes]]",
        "reviewAndManage": "1. Revisar e gerenciar",
        "trackTeamsProgress": "2. Acompanhar o progresso de sua equipe",
        "reviewAndManageTooltip": "Reveja seus [[Departments]] e os líderes de equipe previamente selecionados. Faça alterações quando necessário clicando no botão Gerenciar [[Departments]].",
        "trackTeamsProgressTooltip": "Acompanhe o progresso do [[planner]] de continuidade de negócios de sua equipe. Quando as atribuições forem concluídas, uma marca de seleção aparecerá",
        "teamLead": "Líder da equipe",
        "sendAndFinish": "Enviar e terminar",
        "lblSelectDueDate": "Selecionar data de vencimento",
        "lblDueDate": "Data de Vencimento",
        "exampleEmail": "E-mail de exemplo",
        "msgWelcomeAgilityPlanner": "Bem-vindo ao Preparis Planner! Abaixo está uma visão geral da equipe que você criou. Nesta seção, você pode fazer o seguinte:",
        "msgWelcomeAgilityPlannerTeamLead": "Bem-vindo ao Preparis Planner! Abaixo está uma visão geral de suas atribuições. Nesta seção, você pode fazer o seguinte:",
        "msgWelcomeAgilityPlannerTeamMember": "Bem-vindo ao Preparis Planner! Abaixo está uma visão geral de suas atribuições. Nesta seção, você pode fazer o seguinte:",
        "reviewAssignmentsTeamMember": "1. Revisar atribuições",
        "getStartedTeamMember": "2. Iniciar",
        "reviewAssignmentsTeamMemberTooltip": "Revise seus [[processes]] atribuídos abaixo, juntamente com as datas de vencimento aplicáveis. Os [[processes]] listados nesta seção foram atribuídos a você como especialista no assunto pelo seu líder de equipe. ",
        "getStartedTeamMemberTooltip": "Comece suas atribuições a qualquer momento clicando no botão Iniciar para um [[process]] específico. Depois de clicar no botão Iniciar, você será direcionado a um breve vídeo introdutório que fornecerá instruções adicionais.",
        "msgFailSendEmail": "Falha ao enviar e-mails introdutórios",
        "msgSuccessSendEmail": "E-mail enviado para {{name}}",
        "msgDueDateSaved": "Data de vencimento atualizada",
        "lblSentEmailOn": "Enviado em",
        "emailTemplate": {
            "userName": "John Doe",
            "departmentNames": "Recursos humanos",
            "processNames": "Folha de pagamento",
            "loginName": "john.doe@preparis.com",
            "placeHolders": {
                "recipientName": "Nome do destinatário",
                "senderName": "Nome do remetente",
                "departmentNames": "Nomes de [[Departments]]",
                "processNames": "Nomes de [[process]]",
                "portalUrl": "Link do portal de recuperação do Preparis",
                "activateUrl": "Link de ativação da conta do usuário",
                "userName": "Login da conta do usuário",
                "technologyNames": "Nomes de [[technologies]]"
            },
            "annexNames": "seguintes anexos",
            "technologyNames": "seguintes tecnologias"
        },
        "status": {
            "none": "Não iniciado",
            "inProcess": "Em andamento",
            "complete": "Concluído"
        },
        "sentDate": "Data de envio",
        "percentComplete": "Porcentagem completa",
        "dashboard": {
            "overallProgressAndIssueTracking": "Progresso geral e rastreamento de [[issues]]",
            "progressByDepartment": "Progresso por [[Departments]]",
            "progressByProcess": "Progresso por [[process]]",
            "bcDashboardTitle": "Painel de controle do [[plan]] de continuidade de negócios",
            "quickView": "Olhada rápida",
            "assignedTeamMember": "Membro designado da equipe",
            "progress": "Progresso",
            "errorLoadingProcesses": "Erro ao carregar [[processes]]",
            "viewAll": "Ver tudo",
            "bcAnalytics": "Análises",
            "authorizedEmployees": "Funcionários autorizados",
            "affectedEmployees": "Funcionários afetados",
            "temporaryWorkspaceAddress": "Endereço do espaço de trabalho temporário",
            "errorLoadingData": "Erro ao carregar dados do painel de controle",
            "issues": "[[Issues]]",
            "blank": "Em branco",
            "processGlobalSearchPlaceholder": "Pesquisar em todas as colunas",
            "selectableColumns": "Colunas selecionáveis",
            "plannerDashboard": "Painel do planejador",
            "technologyToProcess": "[[Technology]] para Processar",
            "technologyToTechnology": "[[Technology]] para [[Technology]]", 
            "total": "Total",
            "gaps": "Lacunas",
            "technologyNoRunbook": "[[Technologies]] atribuídas sem runbook",
            "gapType": "Tipo de lacuna",
            "criticalVendors": "Fornecedores críticos",
            "disasterRecoveryTeamsToTechnology": "Equipes de recuperação de desastres para [[technology]]",
            "progressByOwner": "Progresso por proprietário",
            "progressByTier": "Progresso por nível",
            "technologyOwner": "Proprietário de [[Technology]]"
        },
        "bcIssue": {
            "userSelection": {
                "searchUserLabel": "Alterar proprietário",
                "currentUserLabel": "Proprietário atual"
            },
            "issue": "Nota ou [[issues]",
            "issues": "Notas ou [[issues]]",
            "addNewIssue": "Adicionar nova nota ou [[issues]",
            "createIssue": "Criar nota ou [[issues]",
            "manageIssues": "Gerenciar notas ou [[issues]]",
            "viewIssues": "Ver notas ou [[issues]]",
            "changeOwner": "Alterar proprietário",
            "lblTitle": "Título",
            "lblDescription": "Descrição",
            "lblIssueStatus": "Status",
            "lblIssueOwner": "Proprietário",
            "lblManagementAttention": "Atenção de gerenciamento",
            "lblManagementAttentionAbbr": "Gerenciamento",
            "lblRevisionItemType": "Categoria",
            "lblFunctionalArea": "[[Departments]]",
            "lblProcess": "[[processes]]",
            "lblComments": "Comentários",
            "newComment": "Novo comentário",
            "addComment": "Adicionar comentário",
            "commentOn": "em",
            "bcIssueStateType_6171be3d-bd24-4f9c-a9cd-d571c513780d": "Abrir",
            "bcIssueStateType_8c7d2b2e-c08c-4ef0-881c-e386d86c2245": "Fechadas",
            "bcIssueStateType_1ed8f21b-edcc-4408-9e7c-ebb6447bafe0": "Arquivado",
            "getIssueCommentsErrorMsg": "Erro ao obter comentários do [[issues]",
            "loadingIssuesErrorMsg": "Erro ao carregar os [[issues]]",
            "ownerModal": {
                "headerLabel": "Selecionar um proprietário para o [[issues]",
                "searchUserLabel": "Procurar proprietário",
                "currentUserLabel": "Proprietário atual",
                "successMsg": "Alterado com sucesso o proprietário do [[issues] para {{ newOwnerName }}",
                "errorMsg": "Erro ao alterar o proprietário"
            },
            "saveIssueComment": {
                "successMsg": "A nota ou o comentário do [[issues] foi salvo com sucesso",
                "errorMsg": "Erro ao salvar a nota ou o comentário do [[issues]"
            },
            "saveIssue": {
                "successMsg": "A nota ou o [[issues] foi salvo com sucesso",
                "errorMsg": "Erro ao salvar a nota ou o [[issues]",
                "errorPopulateRequiredFieldsMsg": "Preencha todos os campos obrigatórios"
            },
            "confirmDeleteIssueCommentModal": {
                "header": "Excluir nota ou comentário do [[issues]",
                "body": "Tem certeza de que deseja excluir a nota ou o comentário do [[issues] criado em {{ createdOn }}?",
                "text": "Excluir",
                "successMsg": "Excluído com sucesso a nota ou o comentário do [[issues]",
                "errorMsg": "Erro ao excluir a nota ou o comentário do [[issues]"
            },
            "noIssues": "Nenhum [[issues]",
            "businessContinuityIssues": "[[Issues]] de continuidade de negócios",
            "attentionOnlyTitle": "Precisa de atenção do gerenciamento",
            "allIssueTitle": "Número total",
            "msgErrorLoadingFunctionalAreas": "Erro ao carregar [[Departments]]",
            "status": {
                "6171be3d-bd24-4f9c-a9cd-d571c513780d": "Abrir",
                "8c7d2b2e-c08c-4ef0-881c-e386d86c2245": "Fechadas"
            },
            "lblDisplayClosed": "Mostrar fechados"
        },
        "bcRevisionItemTypes": {
            "9a8ca3b0-c29c-429a-bae3-5106ebc9eef6": "Construir sua equipe",
            "8075edbe-224e-4af0-accf-095cc08a650d": "Enviar e-mail para o líder da equipe",
            "3001b73a-eff1-442a-9bf0-31ef0002f995": "Validar [[process]]",
            "e7bd0842-eadc-483f-b9c5-9a3d5047b1f4": "Enviar e-mail de atribuição",
            "d731177f-8663-44e8-a243-b058b4bf9527": "Avaliação de impacto",
            "be1d75e6-dac5-459f-ae5c-d565b20022de": "RTO",
            "a190d9cc-bf54-4cd4-80ba-9d1ac8028b3e": "Datas críticas",
            "056594f5-b6f0-46fb-9855-1303b611e293": "Estratégia de continuidade",
            "6c046473-8b67-4037-b8e5-d806800d0b06": "Impacto financeiro",
            "70d4a412-d41f-4c98-962f-7a45ffeebeeb": "[[clients]] do impacto",
            "886647d0-77ba-4cc1-9366-70d15506f63e": "Funcionário do impacto",
            "c3c00b14-80dd-4325-8849-2aebd26bd2d5": "Marca do impacto",
            "881236ae-0445-4c49-9356-30910be262b4": "Serviços de impacto",
            "92a17340-8be5-455d-81be-7cc58228eaa5": "Conformidade do impacto",
            "79272c21-fcdf-4624-94c4-e6c95ba7c03d": "Dependências do [[Process]]",
            "951b6fb4-14db-4cd2-92ab-f2d24aa38b4f": "[[Locations]] do [[Process]]",
            "f8e8fb86-f246-4892-a9cc-a889b99fe2b1": "Delegações de autoridade",
            "8169c5dd-85c0-4861-9983-1c7bf24e72fb": "Ordem de Sucessão",
            "b2c8a5da-8e90-4657-8e9b-000962841860": "[[Department]] [[teams]]",
            "5ddccee3-fe67-4206-8f80-4ac92b7b6382": "[[Locations]]",
            "8ee5ce4f-5fba-43a9-95b1-42f668a6e97c": "[[Department]] descrições",
            "3cba5e70-bd1b-4cb8-8eea-dce7e515db94": "[[Team]] Líder Suplente",
            "b7b3c55a-bcb9-4846-ab25-faa09ddc6254": "Organizacional [[teams]]",
            "184fb5cd-a848-4b5e-9384-94057e62a2b1": "Rastrear [[Departments]]",
            "78eab4a2-ac01-415a-b5f9-a859b64387a4": "Rastrear [[Processes]]",
            "139e56cf-346e-4bdd-90b5-02e55fd3d25d": "Anexo Agências",
            "7fdf1dcc-df3d-4394-b9e6-96ca9a91b290": "Lista de Ações do Anexo",
            "94caacc3-832a-4aec-b5da-63a1679e4eff": "Questões gerais",
            "f7518654-5f95-47c7-a01c-6cd833423f2e": "Acompanhar anexos",
            "451cd7cd-e99a-42ff-aa2e-213063801e55": "Ordem Organizacional de Sucessão",
            "d6686ed4-ef96-48f4-8f58-3c98227baaa7": "Delegação Organizacional de Autoridade",
            "dfd9839e-9f89-4d18-8442-cf4920068326": "Secções do Anexo",
            "fd9bc961-2d0b-4fc3-932a-894f55463717": "Supersessão",
            "9866e964-4fd7-4b93-a247-afd1b2abae50": "Construa e gerencie seus anexos",
            "deb3139e-5f75-441d-982c-cf0ae4d61c5f": "Crie e gerencie suas tecnologias",
            "bb95d0b1-482b-4c98-8006-165082f396b5": "E-mail de introdução",
            "2c9d3e1d-b028-4859-b2cd-40860d103670": "Fornecedores de tecnologia",
            "637b9412-ead5-4656-9bfd-9c856ac152aa": "Conjuntos de tarefas tecnológicas",
            "9f52df24-261e-4967-85ad-65c5299fa6fe": "Dependências tecnológicas",
            "7c484880-6dc1-4413-8954-010db5e6ee52": "Tempo de recuperação tecnológica",
			"ba765aaf-562e-4f9a-8e18-e9748a13f180": "Enviar e-mails para proprietários de tecnologia",
			"f07ab2cd-4f19-428f-8b9f-54982abfa079": "Rastrear tecnologias",
			"4c04b488-542b-49aa-9b99-851a44f4e22b": "Detalhes de aplicação de tecnologia"
        },
        "bcProgressStatusTypes": {
            "notStarted": "Não iniciado",
            "inProcess": "Em andamento",
            "complete": "Concluído"
        },
        "bcCustomFields": {
            "customFieldsLabel": "Perguntas Customizadas"
        },
        "ordersOfSuccession": {
            "title": "Ordens de Sucessão",
            "description": "Abaixo você pode manter as ordens de sucessão dentro de sua organização. ",
            "newSuccession": "Nova Sucessão",
            "AddPrimary": "Adicionar principal",
            "AddAlternate": "Adicionar Alternativo",
            "AddAlternates": "Adicionar Alternativos",
            "selectPrimaryUser": {
                "headerLabel": "Selecione um agente principal para esta ordem de sucessão",
                "searchUserLabel": "Procure um agente principal para esta ordem de sucessão",
                "currentUserLabel": "Agente primário atual",
                "resultLabel": "Agentes"
            },
            "selectAlternateUser": {
                "headerLabel": "Selecione um agente alternativo para esta ordem de sucessão",
                "searchUserLabel": "Procure um agente alternativo para esta ordem de sucessão",
                "currentUserLabel": "Agente alternativo atual",
                "resultLabel": "Agentes"
            },
            "orderSavedSuccessfully": "A Ordem de Sucessão foi salva com sucesso",
            "orderSavedUnsuccessfully": "A Ordem de Sucessão foi salva sem sucesso",
            "removeOrderOfSuccession": "Remover Ordem de Sucessão",
            "removeOrderOfSuccessionDescription": "Tem certeza de que deseja remover esta Ordem de Sucessão",
            "successOnDelete": "A Ordem de Sucessão foi excluída com sucesso",
            "failedToDelete": "A ordem de sucessão foi excluída sem sucesso",
            "titleDescription": "Um nome descritivo da ordem de sucessão.",
            "primaryDescription": "A pessoa principal que é para esta Ordem de Sucessão.",
            "alternatesDescription": "Uma lista ordenada de quem sucederia no caso de a pessoa principal não poder desempenhar as suas funções.",
            "alternate": "Alternar",
            "titleDetails": "Detalhes da Ordem de Sucessão",
            "description2": "Adicione a ordem padrão de sucessões para este setor.",
            "oosSecondaryDescription": "Defina aqui suas ordens de sucessão.",
            "oosDescription": "Gerencie as ordens de sucessão que dão suporte ao seu departamento. ",
            "oosOrgDescription": "Abaixo você pode manter as ordens de sucessão dentro de sua organização."
        },
        "authorityDelegation": {
            "headerSummary": "Delegações de autoridade",
            "header": "Delegação de autoridade",
            "title": "Título",
            "triggerCondition": "Acionar",
            "triggersPlaceholder": "Insira seus gatilhos aqui",
            "limitation": "Limitações",
            "limitationsPlaceholder": "Insira suas limitações aqui",
            "actingPerson": "Pessoa em exercício",
            "delegatedPerson": "Pessoa delegada",
            "modalMsg": "{{field}} para a delegação de autoridade",
            "saveSuccess": "Delegação de autoridade salva com sucesso",
            "saveError": "Delegação de autoridade salva sem sucesso",
            "titleVerbiage": "É aqui que você registra uma função específica que um indivíduo está autorizado a executar.",
            "triggersVerbiage": "É aqui que você registra os pontos de gatilho nos quais precisaria fazer a transição das responsabilidades de um Agente Interino para um Agente Delegado.",
            "limitationsVerbiage": "É aqui que você registra as limitações que um Agente Delegado teria que cumprir quando as responsabilidades estão sendo transferidas de um Agente Interino.",
            "actingVerbiage": "É aqui que você registra os nomes da(s) pessoa(s) principal(ais) que são responsáveis pela Delegação especificada.",
            "delegatedVerbiage": "É aqui que você registra os nomes dos delegados/pessoas secundárias que são responsáveis pela Delegação especificada.",
            "addActing": "Nova atuação",
            "addDelegated": "Nova delegação",
            "headerList": "Delegações de autoridade",
            "titleListVerbiage": "Abaixo você pode manter as delegações de autoridade dentro de sua organização. ",
            "confirmDeleteHeader": "Excluir {{ name }}",
            "confirmDeleteVerbiage": "Tem certeza de que deseja excluir esta delegação de autoridade?",
            "successOnDelete": "A delegação foi excluída com sucesso",
            "failedToDelete": "A delegação foi excluída sem sucesso",
            "actives": "Ativos",
            "delegates": "Delegados",
            "lastUpdated": "Ultima atualização: {{date}}",
            "backToSummary": "Voltar ao Resumo",
            "markComplete": "Marcar como concluído",
            "searchUser": {
                "headerLabel": "Selecione um agente para esta Delegações de Autoridade",
                "searchUserLabel": "Procure um agente para esta Delegações de Autoridade",
                "currentUserLabel": "Agentes atuais",
                "resultLabel": "Agentes"
            },
            "limitationsTooltip": "É aqui que você registra as limitações que um Agente Delegado teria que cumprir quando as responsabilidades estão sendo transferidas de um Agente Interino.",
            "actionPersonTooltip": "É aqui que você registra os nomes da(s) pessoa(s) principal(ais) responsável(eis) pela Delegação especificada.",
            "delegatedPersonTooltip": "É aqui que você registra os nomes dos delegados/pessoas secundárias que são responsáveis ​​pela Delegação especificada.",
            "authorityDescription": "É aqui que você registra uma função específica que um indivíduo está autorizado a executar.",
            "triggersDescription": "É aqui que você registra os pontos de gatilho nos quais precisaria fazer a transição das responsabilidades de um Agente Interino para um Agente Delegado.",
            "limitationsDescription": "É aqui que você registra as limitações que um Agente Delegado teria que cumprir quando as responsabilidades estão sendo transferidas de um Agente Interino.",
            "primaryDescription": "É aqui que você registra os nomes da(s) pessoa(s) principal(ais) responsável(eis) pela Delegação especificada.",
            "delegatesDescription": "É aqui que você registra os nomes dos delegados/pessoas secundárias que são responsáveis ​​pela Delegação especificada.",
            "actingAgent": "agente interino",
            "delegatingAgent": "Agente delegante",
            "actingAgentDesc": "É aqui que você registra os nomes das pessoas atuantes/principais responsáveis ​​pela delegação especificada",
            "backToDepartments": "Voltar aos Departamentos",
            "headerDetails": "Detalhes da Delegação de Autoridade",
            "titleListVerbiage2": "Adicione as delegações de autoridade padrão para este setor.",
            "authorityOfDelegationSecondaryDescription": "Defina suas delegações de autoridade aqui.",
            "authorityOfDelegationDescription": "Gerenciar as delegações de autoridade do departamento. ",
            "authorityOfDelegationOrgDescription": "Abaixo você pode manter as delegações de autoridade dentro de sua organização. "
        },
        "summarySidebar": {
            "question01": "O que são [[processes]]?",
            "answer0100": "Os [[processes]] são as responsabilidades/tarefas que sua organização deve concluir para ser considerada \"operacional\". ",
            "answer0401": "As [[processes]] são as responsabilidades/tarefas que sua organização deve cumprir para ser considerada \"operacional\". ",
            "answer0402": "Várias [[processes]]:",
            "answer0403": "Realizar auditoria diária do log de contas a pagar (Contabilidade)",
            "answer0404": "Criar e enviar auditorias diárias de benefícios aos funcionários (Recursos Humanos)",
            "answer0405": "Detectar e suprimir incêndios urbanos, rurais e selvagens (Fogo)",
            "answer0406": "Responder a chamadas do 911 e lesões veiculares (EMS)",
            "answer0407": "Distribuir medicamentos para Tuberculose/HlV (Saúde)",
            "answer0408": "Inspecionar e manter o sistema de água/águas residuais (Obras Públicas)",
            "question02": "O que são Delegações de Autoridade?",
            "answer0201": "Dentro de quase todas as organizações, há pessoas designadas com autoridade para fazer certas coisas que muito poucos dentro da organização estão autorizados a fazer. ",
            "answer0202": "Dica: a seção Delegação de autoridade é MUITO semelhante à seção Ordens de sucessão do seu [[plan]]; ",
            "answer0203": "Aqui estão alguns exemplos de autoridades a serem consideradas que podem ser aplicáveis:",
            "answer0204": "Quem pode assinar cheques, contratos ou outros documentos legais?",
            "answer0205": "Quem pode conceder autorização para viagem, férias ou licença prolongada?",
            "answer0206": "Quem pode fazer compras de determinados valores acima e além dos limites normais de compra?",
            "answer0207": "Quem pode fechar o local de trabalho e/ou determinar a abertura atrasada e por quanto tempo?",
            "answer0208": "Quem pode contratar e/ou dispensar pessoal?",
            "answer0209": "Existem autoridades ou capacidades especiais desempenhadas por pessoal específico dentro da organização? ",
            "question03": "O que são Ordens de Sucessão?",
            "answer0301": "As ordens de sucessão podem ser resumidas na pergunta: \"Quem vem a seguir na cadeia de comando?\"",
            "answer0302": "Dentro de cada organização, há pessoas-chave que têm certos deveres autorizados, funções e responsabilidades específicas para sua posição. ",
            "answer0303": "Ter uma ordem de sucessão em vigor ajudará a facilitar uma transição ordenada e predefinida de cargos de liderança em sua organização. ",
            "answer0304": "Dica: Algumas sucessões pré-identificadas são criadas para você e tudo o que você precisa fazer é atribuir pessoal. ",
            "question04": "O que são funções essenciais?"
        },
        "planTemplates": {
            "header": "Modelos de planos",
            "continuityOfOperationPlanning": "[[Planner]] de Continuidade de Operações (COOP)",
            "emergencyOperations": "Plano de Operações de Emergência (EOP)",
            "continuityOfGovernment": "Continuidade de Governo (COG)",
            "businessContinuity": "[[Planner]] de Continuidade de Negócios (BCP)",
            "disasterRecovery": "Planejamento de recuperação de desastres (DRP)",
            "lossOfServicesEmergency": "Plano de Operação de Emergência de Perda de Serviços (LSEOP)",
            "tabInfo": "Abaixo, você pode ver seus modelos que estão em uso no momento.",
            "newTemplates": "Novos modelos",
            "assignedTemplates": "Modelos em uso",
            "unAssignedTemplates": "Modelos não em uso",
            "draftTemplates": "Rascunhos não publicados",
            "confirmDeleteHeader": "Excluir {{ name }}",
            "confirmDeleteVerbiage": "Tem certeza de que deseja excluir este modelo do sistema?",
            "successOnDelete": "O modelo de plano foi excluído com sucesso",
            "failedToDelete": "O modelo de plano foi excluído sem sucesso",
            "exportTemplate": "Exportando modelo de plano...",
            "successOnExport": "O modelo de plano é exportado",
            "failedOnExport": "Falha ao exportar modelo de plano",
            "importTemplate": "Modelo de importação",
            "uploadPlanTemplate": "Télécharger le modèle de plan",
            "dropFileInstruction": "Déposez ou cliquez pour sélectionner un fichier de modèle de plan",
            "loadingTemplate": "Importation d'un modèle de plan",
            "successOnImport": "O modelo de plano é importado",
            "failedOnImport": "Falha ao importar modelo de plano",
            "successOnSave": "O modelo de plano foi salvo com sucesso",
            "failedToSave": "O modelo de plano foi excluído sem sucesso",
			"duplicateTemplateName": "Já existe um modelo de plano com o mesmo nome, escolha um nome diferente.",
            "createDuplicate": "Criar duplicado",
            "duplicateTemplate": "Modelo Duplicado",
            "assignDefaultTemplatesDescription": [
                "Tornar Plano de {{entityType}} Padrão",
                "(atribuirá a todos os {{entityType}}s não atribuídos)",
                "(atribuirá a todos os {{entityType}} não atribuídos)"
            ],
            "assignTemplate": "Atribuir Modelo",
            "availableDepartments": "Departamentos Disponíveis",
            "selectedDepartments": "Departamentos Selecionados",
            "targetsAssignedSuccessfully": "{{targetType}} atribuídos com sucesso",
            "targetsAssignedUnsuccessfully": "{{targetType}} atribuídos sem sucesso",
            "lastUpdated": "Ultima atualização: {{name}} - {{date}}",
            "availableCompanies": "Empresas disponíveis",
            "availableLocations": "Locais Disponíveis",
            "selectedCompanies": "Empresas selecionadas",
            "selectedLocations": "Locais selecionados",
            "availableTechnologies": "[[Technologies]] Disponíveis",
            "selectedTechnologies": "[[Technologies]] selecionadas",
            "successOnDuplicate": "O modelo de plano foi duplicado com sucesso",
            "failedToDuplicate": "O modelo de plano foi duplicado sem sucesso",
            "templateDetails": "Detalhes do modelo",
            "setTemplateAsDefault": "Definir modelo como Padrão",
            "setTemplateDefaultConfirmation": "Este modelo agora será usado como modelo padrão para {{planName}}",
            "promoteModalHeader": "Modelo de Promoção",
            "promoteModalBody": "Tem certeza de que deseja promover este modelo a um modelo de sistema para este setor? <br/> {{industryName}} tipo industrial.",
            "planLevel": "Nível do Plano",
            "dataTables": "Tabelas de dados",
            "insertTable": "Insira a tabela",
            "selectModalVerbiage": "Selecione abaixo para visualizar e inserir sua tabela de dados.",
            "planLevels": {
                "department": "[[Department]]",
                "location": "Localização",
                "company": "Empresa",
                "companyITDR": "Empresa",
                "continuityOfGovernment": "Organização (COG)",
                "continuityOfOperationPlanning": "[[Department]]",
                "emergencyOperations": "Operações de Emergência",
                "technology": "[[Technology]]"
            },
            "enterName": "Digite o nome aqui",
            "duplicatePublishedWarning": "Isso duplicará a versão publicada deste modelo. Quaisquer alterações no rascunho não serão incluídas.",
            "unpublishedChanges": "Alterações não publicadas"
        },
        "planBuilder": {
            "planTemplate": {
                "draftVersion": "Versão preliminar",
                "planTemplateTargetType": "Modelo de Plano de {{planTargetType}}",
                "discard": "Descartar todas as alterações / Reverter para a última publicação",
                "planTemplate": "Modelo de plano",
                "publishTemplate": "Publicar modelo",
                "createNewSection": "Criar nova seção",
                "sectionSettings": "Configurações da seção",
                "saveSection": "Salvar Seção",
                "removeSection": "Remover seção",
                "removeSectionDescription": "Tem certeza de que deseja remover esta seção?",
                "unsavedChanges": "Alterações não salvas",
                "unsavedChangesDescription": "Você tem alterações não salvas. ",
                "revertDescription": "Tem certeza de que deseja reverter este rascunho para o original e começar de novo, isso não pode ser desfeito?",
                "publishDescription": "Tem certeza que deseja publicar?",
                "pageBreakTrueLabel": "Iniciar em nova página",
                "pageBreakFalseLabel": "Continuar na última seção",
                "noSections": "Não existem seções",
                "addNewSection": "Adicionar nova seção",
                "insertTag": "Inserir Marca",
                "insertTagDescription": "Selecione uma das tags abaixo para inserir.",
                "insertElementDescription": "Selecione abaixo para visualizar e inserir sua tabela de dados.",
                "tags": {
                    "departmentLead": "Líder de Departamento",
                    "departmentLeadAlternate": "Líder de Departamento Suplente",
                    "departmentName": "Nome do Departamento",
                    "departmentDescription": "Descrição do Departamento",
                    "departmentDueDate": "Data de Vencimento do Departamento",
                    "companyAddress": "Endereço da Companhia",
                    "companyPhone": "Número de Telefone Principal da Empresa",
                    "companyName": "Nome da Empresa",
                    "departmentLeadDescription": "O líder do departamento.",
                    "departmentLeadAlternateDescription": "O suplente líder do departamento.",
                    "departmentNameDescription": "O nome do departamento.",
                    "departmentDueDateDescription": "A data de vencimento para o líder da equipe terminar de definir seu departamento.",
                    "companyAddressDescription": "O endereço onde o escritório principal está localizado.",
                    "companyPhoneDescription": "O número de telefone principal da empresa.",
                    "companyNameDescription": "O nome da empresa.",
                    "departmentDescriptionDescription": "A descrição do departamento",
                    "eMALocation": "Localização EMA",
                    "eMALocationDescription": "Onde está localizada a sua Agência de Gestão de Emergências.",
                    "eOCLocation": "Localização do EOC",
                    "eOCLocationDescription": "Onde está localizado o seu Centro de Operações de Emergência.",
                    "massCare": "Cuidados em Massa",
                    "massCareDescription": "Que agência/departamento é responsável pelos cuidados em massa e alimentação.",
                    "trainingTesting": "Testes de Treinamento",
                    "trainingTestingDescription": "Que agência/departamento é responsável pelo seu Plano de Operações de Emergência.",
                    "unmetNeeds": "Necessidades não Satisfeitas",
                    "unmetNeedsDescription": "A agência/departamento que coordena é responsável pelas necessidades não satisfeitas.",
                    "supersession": "Supersessão",
                    "supersessionDescription": "Informações de supersessão do Plano de Operações de Emergência.",
                    "planGenerationDate": "Data de Geração do Plano",
                    "planGenerationDateDescription": "Saída da data e hora em que o documento do plano foi gerado.",
                    "planTemplatePublished": "Modelo de plano publicado",
                    "planTemplatePublishedDescription": "Saída da data e hora em que o modelo de plano foi publicado pela última vez.",
                    "technologyERT": "Tempo de recuperação estimado",
                    "technologyERTDescription": "O tempo de recuperação estimado atribuído no runbook de [[technology]].",
                    "technologyName": "Nome da [[Technology]]",
                    "technologyNameDescription": "O nome da [[technology]].",
                    "technologyOwnerAlternate": "Proprietário de [[Technology]] Suplente",
                    "technologyOwnerAlternateDescription": "O nome do proprietário da [[technology]] alternativa.",
                    "technologyOwner": "Proprietário de [[Technology]]",
                    "technologyOwnerDescription": "O nome do proprietário principal da [[technology]].",
                    "technologyDueDate": "Data de Vencimento da [[Technology]]",
                    "technologyDueDateDescription": "A data de vencimento para o proprietário da [[technology]] terminar de definir a [[technology]]."
                },
                "elements": {
                    "customFields": "Perguntas e respostas sobre estratégia",
                    "vendors": "Principais fornecedores ou fornecedores",
                    "assessments": "Risco",
                    "criticalDates": "Datas Críticas",
                    "portalTeams": "[[Teams]] de [[Department]]",
                    "rTO": "Estratégias de Recuperação de [[process]]",
                    "attachments": "Avaliações de Risco",
                    "technologies": "[[Technologies]]",
                    "processDependencies": "[[Process]] Dependências",
                    "employeeDeployment": "Implantação de funcionários",
                    "subjectMatterExperts": "Membros da [[Team]] de Continuidade",
                    "customFieldsDes": "Todas as perguntas e respostas sobre estratégia de processo do [[department]] incluem a estratégia associada.",
                    "vendorsDes": "Todo o [[process]] do [[department]], incluindo fornecedor, datas de contrato, endereços e contatos.",
                    "assessmentsDes": "Todas as ameaças para o [[department]] provenientes da última avaliação de risco concluída para o [[department]]. ",
                    "criticalDatesDes": "Todas as datas críticas do [[process]] em todos os [[departments]].",
                    "portalTeamsDes": "Uma tabela que lista todas as [[Teams]] associadas ao [[department]], inclui os seus membros ou cargos e as responsabilidades correspondentes.",
                    "rTODes": "Lista todos os [[department]] e seus [[process]], as tabelas são agrupadas por estratégia de continuidade.",
                    "attachmentsDes": "Todos os anexos de [[processes]] do [[department]] incluem o tempo de recuperação do [[process]].",
                    "technologiesDes": "Todo o [[department]] de Aplicação/[[Technology]] inclui Objetivo de Ponto de Recuperação (RPO), [[processes]] associados e Tempo de Recuperação.",
                    "processDependenciesDes": "Todos os [[departments]] [[Process]] que possuem uma dependência incluem Objetivo de Ponto de Recuperação (RPO), nome de [[Process]] dependente e Objetivo de Tempo de Recuperação (RTO) do [[process]] dependente.",
                    "employeeDeploymentDes": "Todos os funcionários da Empresa atribuídos a um [[process]] de [[department]] ordenado pela RTO. ",
                    "subjectMatterExpertsDes": "Uma tabela que lista todos os especialistas no assunto e suas informações de contato, juntamente com os [[processes]] que eles apoiam no [[department]].",
                    "departments": "[[Departments]]",
                    "departmentsDes": "Todos os [[Departments]] da empresa junto com sua descrição",
                    "equipment": "[[Equipment]] Dependências",
                    "equipmentDes": "Todos os [[Equipment]] da empresa necessários para a continuidade do [[Process]] em todos os [[Departments]], incluem o tempo de recuperação do [[Process]]",
                    "teamLeaders": "Líderes de [[Department]]",
                    "teamLeadersDes": "Todos os líderes de [[department]] e suas informações de contato",
                    "businessContinuityManagers": "Gerentes de Continuidade de Negócios",
                    "businessContinuityManagersDes": "Todos os gerentes de continuidade de negócios da empresa e suas informações de contato",
                    "delegationOfAuthority": "Delegações de Autoridades",
                    "delegationOfAuthorityDes": "Esta tabela listará todas as delegações de autoridades dentro do [[department]], gatilhos, limitações, incluindo membros ativos e delegados.",
                    "ordersOfSuccession": "Ordens de Sucessão",
                    "ordersOfSuccessionDes": "Esta tabela listará todas as ordens de sucessão dentro do [[department]].",
                    "maximumTolerableDowntime": "Tempo de inatividade máximo tolerável",
                    "maximumTolerableDowntimeDes": "A tabela de MTD (Tempo de inatividade máximo tolerável) nos [[processes]] de continuidade de negócios.",
                    "processLocations": "[[Process]] [[Locations]]",
                    "processLocationsDes": "Esta tabela lista todos os [[processes]] dentro do [[department]] e os [[locations]] em que são executados.",
                    "annexes": "Anexos",
                    "annexesDes": "Os anexos definidos no seu Plano de Operações de Emergência.",
                    "emergencyOperations": {
                        "portalTeams": "Resposta [[Teams]]",
                        "portalTeamsDes": "Esta tabela listará todas as respostas do Plano de Operações de Emergência [[teams]], incluindo cargos, informações de contato dos membros e suas responsabilidades."
                    },
                    "iTDRTechnologyAttachments": "Anexos",
                    "iTDRTechnologyAttachmentsDes": "Lista de todos os anexos para a [[technology]] selecionada.",
                    "iTDRTechnologySupportingVendors": "Suporte a fornecedores",
                    "iTDRTechnologySupportingVendorsDes": "Uma tabela lista todos os fornecedores que oferecem suporte à [[technology]] selecionada, inclui informações de contato do fornecedor e informações de SLA de [[technology]].",
                    "iTDRTechnologyTaskSets": "Conjuntos de tarefas",
                    "iTDRTechnologyTaskSetsDes": "Uma tabela lista as tarefas para a recuperação da [[technology]] selecionada agrupadas por Conjunto de Tarefas, inclui Tempo Atribuído, Equipas, Membros e Tarefas.",
                    "iTDRTechnologyDetails": "Detalhes da [[technology]]",
                    "iTDRTechnologyDetailsDes": "Uma tabela lista todas as perguntas personalizadas do tipo de [[technology]], suas respostas e quaisquer instruções adicionais.",
                    "iTDRUpstreamTechnologyDependencies": "Dependências de [[technology]] upstream",
                    "iTDRUpstreamTechnologyDependenciesDes": "Lista todas as [[technologies]] dependentes upstream para essa [[technology]], RPO (Recovery Point Objetive, objetivo de ponto de recuperação), nome da [[technology]] e RPO (Recovery Point Objetive, objetivo de ponto de recuperação).",
                    "iTDRTechnologyDisasterRecoveryTeams": "Equipes de recuperação de desastres",
                    "iTDRTechnologyDisasterRecoveryTeamsDes": "Uma tabela que lista todas as equipes de recuperação de desastres associadas à [[technology]], inclui nomes de membros, cargos e as responsabilidades correspondentes.",
                    "iTDRCompanyTechnologiesAttachments": "Anexos",
                    "iTDRCompanyTechnologiesAttachmentsDes": "Uma lista de todos os anexos de [[technology]] na sua organização, incluindo o nome da [[technology]] e o nome do anexo.",
                    "iTDRCompanytechnologiesByVendors": "[[Technology]] por Fornecedor",
                    "iTDRCompanytechnologiesByVendorsDes": "Uma tabela lista todos os fornecedores que oferecem suporte a uma [[technology]] dentro da organização.",
                    "iTDRCompanyTechnologiesByOwner": "[[Technology]] por Proprietário",
                    "iTDRCompanyTechnologiesByOwnerDes": "Uma tabela listando todas as [[technologies]] com planos de recuperação, agrupadas por seus respetivos proprietários de [[technology]].",
                    "iTDRCompanyDisasterRecoveryTeams": "Equipes de recuperação de desastres",
                    "iTDRCompanyDisasterRecoveryTeamsDes": "Esta tabela irá listar toda a Equipes de recuperação de desastres dentro da organização, incluindo cargos, informações de contato do membro e suas responsabilidades."
                }
            }
        },
        "bcPlanBuilder": "Construtor de planos",
        "teams": {
            "title": "Equipes",
            "titleVerbiage": "Abaixo estão as equipes organizacionais.",
            "titleVerbiage2": "Abaixo estão as equipes que foram associadas ao seu departamento.",
            "addTeam": "Novo Time",
            "linked": "vinculado",
            "confirmDeleteHeader": "Excluir {{ name }}",
            "confirmDeleteVerbiage": "Tem certeza de que deseja excluir esta equipe?",
            "successOnDelete": "A equipe foi excluída com sucesso",
            "failedToDelete": "A equipe foi excluída sem sucesso",
            "assignMembers": "Atribuir membros",
            "newTeam": "Novo Time",
            "createNew": "Crie um Novo",
            "associateTeam": "Associar uma Equipe",
            "selectATeam": "Selecione uma equipe",
            "members": "Membros",
            "addMembers": "Adicionar Membro(s)",
            "responsibilities": "Responsabilidades",
            "noMembersAdded": "Nenhum membro adicionado",
            "addMember": "Adicionar Membro",
            "position": "Posição",
            "positionPlaceholder": "Insira a posição aqui",
            "responsibilitiesPlaceholder": "Insira as responsabilidades aqui",
            "assignMember": "Atribuir membro",
            "enterTeamName": "Digite o nome da equipe aqui",
            "TeamDetailsTitle": "Detalhes das Equipes",
            "teamName": "Nome do time",
            "successOnSave": "A equipe foi salva com sucesso",
            "failedToSave": "A equipe foi salva sem sucesso",
            "noDescription": "sem descrição",
            "slideOut": {
                "team": "Equipe",
                "teamName": "Insira o nome oficial da equipe da sua organização. Os exemplos incluem \"Equipe de gerenciamento de incidentes\", \"Equipe de gerenciamento de crises\" ou \"Equipe de planejamento\".",
                "teamDescription": "Forneça um breve resumo das funções e responsabilidades da equipe em sua organização. Inclua funções-chave, objetivos principais e quaisquer áreas críticas de foco.",
                "members": "Membros",
                "memberName": "Nome do membro da equipe",
                "position": "O trabalho ou posição do membro dentro da equipe.",
                "responsibilities": "A responsabilidade do membro ou cargo dentro da equipe e do grupo."
            },
            "createPosition": {
                "confirmDeleteVerbiage": "Tem certeza de que deseja excluir esta posição?",
                "successOnDeletePosition": "A posição foi excluída com sucesso",
                "failedToDeletePosition": "A posição foi excluída sem sucesso",
                "searchUserLabel": "Procurar um membro da equipe",
                "resultLabel": "Selecione Membro",
                "currentUserLabel": "Membro atual da equipe"
            },
            "teamLinkedSuccessfully": "Equipe parceira de sucesso",
            "removeTeamConfirmationHeader": "Remover equipe",
            "removeTeamConfirmationMessage": "Tem certeza de que deseja remover esta equipe do departamento?",
            "teamRemovedSuccessfully": "Equipe removida com sucesso",
            "teamRemovedUnsuccessfully": "Equipe removida sem sucesso",
            "positions": "Posições",
            "titleVerbiage3": "Adicione as equipes padrão para este setor.",
            "addPositions": "Adicionar Posição(s)",
            "addPosition": "Adicionar Posição",
            "noPositionsAdded": "Nenhuma posição adicionada",
            "organizationalTeams": "Equipes Organizacionais",
            "organizationalTeamsDescription": "A criação de equipes de resposta de continuidade é essencial para que as organizações gerenciem crises com eficácia e mantenham as operações durante interrupções inesperadas. ",
            "departmentalTeamsDescription": "Gerenciar as equipes que dão suporte a este [[Department]]. ",
            "technologyTeamsDescription": "As equipes de recuperação de desastres desempenham um papel fundamental no gerenciamento e na mitigação de interrupções nos sistemas e serviços de TI",
            "departmentalTeams": "Equipes Departamentais",
            "technologyTeams": "Equipes de recuperação de desastres",
            "organizationalTeamsSecondaryDescription": "Adicione suas equipes organizacionais aqui.",
            "departmentalTeamsSecondaryDescription": "Adicione suas equipes de [[Department]] aqui.",
            "technologyTeamsSecondaryDescription": "Identifique suas equipes de recuperação de desastres aqui",
            "associateModal": {
                "title": "Atribuir Equipe",
                "switchDes": "Atribuir a todos [[Departments]]",
                "assignToAllSuccessfully": "A equipe foi atribuída a todos os [[departments]] com sucesso",
                "assignToAllUnsuccessfully": "A equipe foi atribuída a todos os [[departments]] sem sucesso",
                "setTeamAsDefault": "Atribuir equipe a todos os [[Departments]].",
                "setTeamDefaultConfirmation": "Tem certeza de que deseja atribuir esta equipe a cada [[department]]?"
            }
        },
        "agencies": "Agências",
        "annex": {
            "annexBuildManage": "Construir",
            "annexSecondaryDescription": "Selecione os anexos que deseja incluir no seu plano",
            "addFromLibrary": "Adicionar da biblioteca",
            "creatFromScratch": "Crie do zero",
            "addAnnexDescription": "Adicione um anexo para começar",
            "annexTitle": "Título do Anexo",
            "selectAnnex": "Selecione Anexo",
            "selectAnnexSecondaryDescription": "Selecione abaixo os anexos que você gostaria de usar em seu plano.",
            "annexes": "Anexos",
            "edit": "Editar Anexo",
            "trackDescription": "Acompanhe e complete seus cronogramas aqui.",
            "dueDateRequired": "Data de vencimento obrigatória para todos os anexos.",
            "removeAnnex": "Remover anexo",
            "removeAnnexDescription": "Tem certeza de que deseja remover este anexo?",
            "manageLibrary": "Gerenciar biblioteca",
            "selectAnnexesToDeleteDescription": "Selecione os anexos abaixo que você gostaria de excluir da bibliotecay.",
            "removeSystemAnnex": "Remover anexo da biblioteca",
            "removeSystemAnnexesDescription": "Tem certeza de que deseja remover estes anexos da biblioteca?",
            "removeSystemAnnexDescription": "Tem certeza de que deseja excluir este anexo do sistema da biblioteca? Isto não pode ser desfeito.",
            "setDueDate": {
                "header": "Data de Vencimento",
                "body": "Alguns annexos já têm uma data de vencimento. Deseja definir esta data de vencimento {{dueDate}} para todos os annexos?",
                "allProcesses": "Sim",
                "newProcesses": "Não",
                "msgConfirmBody": "Tem certeza de que deseja definir esta nova data de vencimento {{dueDate}} para todos os annexos?"
            },
            "configureAnnex": {
                "label": "Configurar o anexo",
                "removeAgency": "Remover agência",
                "removeAgencyDescription": "Tem certeza de que deseja remover esta agência desta função? As ações atribuídas a esta agência permanecerão na lista de ações.",
                "taskAgenciesSecondaryDescription": "Identifique suas agências aqui.",
                "annexSectionsDescription": "Abaixo estão as seções de conteúdo que serão incluídas em seu anexo. Você pode editar, reordenar ou optar por não incluir.",
                "saveAnnexSystemLibrary": "Salvar anexo na biblioteca do sistema",
                "removeAction": "Remover ação",
                "removeActionDescription": "Tem certeza de que deseja excluir esta ação?",
                "responsibilitiesSecondaryDescription": "Identifique aqui as funções e responsabilidades de sua agência.",
                "addAction": "Adicionar ação",
                "saveNewSystemAnnex": "Tem certeza de que deseja salvar este anexo na biblioteca do sistema?",
                "overWriteSystemAnnex": "Tem certeza de que deseja substituir um anexo existente na biblioteca do sistema por esta versão? ",
                "saveSystemAnnexSuccess": "Anexo salvo na biblioteca do sistema",
                "saveSystemSuccessFail": "Falha ao salvar o anexo na biblioteca do sistema",
                "sectionsDescription": "Documente o propósito, o escopo, os conceitos, a gestão financeira e as autoridades de referência que são fundamentais para este anexo.",
                "agenciesDescription": "Documente as agências designadas que desempenham as funções de coordenação, primárias e de apoio neste anexo.",
                "responsibilitiesDescription": "Identifique e documente as responsabilidades da agência durante as múltiplas fases deste anexo."
            }
        },
        "supersession": {
            "supersession": "Supersessão",
            "supersessionSecondaryDescription": "Uma declaração de substituição deve esclarecer se este plano ou quaisquer documentos contidos neste plano substituirão algo anterior.",
            "supersessionSaveSuccessfully": "Substituição salva",
            "supersessionSaveUnsuccessfully": "Falha ao salvar a supersessão",
			"noSupersession": "Sem Supersessão"
        },
        "generalQuestions": {
            "generalQuestions": "Questões gerais",
            "description": "Você também listará entidades a partir das informações inseridas em seu banco de dados de contatos, departamentos e instalações da ferramenta.",
            "what": "O que",
            "whatDescription": "Atribua suas agências apropriadas aqui",
            "where": "Onde",
            "whereDescription": "Atribua seus locais apropriados aqui",
            "assignedAgency": "Agência Designada",
            "assignAgency": "Atribuir agência",
            "assignedLocation": "Localização atribuída",
            "assignLocation": "Atribuir localização",
            "questions": {
                "massCare": "Cuidado em massa",
                "massCareDes": "Qual agência/departamento responsável pelo atendimento em massa",
                "unmet": "Necessidades não atendidas",
                "unmetDes": "Qual agência/departamento coordena as necessidades não atendidas?",
                "EOC": "COE",
                "EOCDes": "Onde estão localizados seus Centros de Operações de Emergência (EOC)?",
                "EMA": "EMA",
                "EMADes": "Onde está localizada a sua agência de gestão de emergências (EMA)?",
                "training": "Teste e treinamento",
                "trainingDes": "Qual agência/departamento é responsável pelo treinamento"
            },
            "saveSuccess": "Resposta das perguntas salva",
            "saveFailed": "Falha ao salvar a resposta das perguntas"
        },
        "customFields": {
            "customFieldTitle": "Perguntas sobre estratégia do plano de continuidade",
            "fieldName": "Nome do Campo",
            "fieldType": "Tipo de pergunta",
            "isRequired": "Necessário",
            "fieldTypes": {
                "5f31785c-4d3c-4d68-a834-da37c2bc0a76": "Numérico",
                "0f6f2372-1e4c-4d67-ad13-615a0540488c": "Texto curto",
                "b9847d16-19b5-4ed4-a4e3-d7f07533ab5a": "Texto longo",
                "d8bd3d80-d25d-4056-8754-a740f2396792": "Verdadeiro ou falso",
                "aad6f035-05f9-42d3-b6ea-682371340875": "Selecione um",
                "76c83029-8439-42ad-90e6-7f0ff54b732c": "Selecione muitos",
                "1eccbe40-1b3b-461b-95df-35f1fe6a8af2": "Data / hora"
            },
            "isRequiredOptions": {
                "true": "Sim",
                "false": "Não"
            },
            "displaySequence": "Sequência de exibição",
            "fieldDomain": "Opções",
            "success": {
                "deleteCustomFieldSuccess": "Sucesso ao excluir o campo personalizado",
                "saveCustomFieldsDisplayOrderSuccess": "Sucesso ao salvar pedido de exibição",
                "saveCustomFieldSuccess": "Sucesso ao salvar o campo personalizado"
            },
            "errors": {
                "getCustomFieldsError": "Falha ao carregar campos personalizados",
                "saveCustomFieldsDisplayOrderError": "Erro ao salvar novo pedido de exibição",
                "deleteCustomFieldError": "Erro ao excluir campo personalizado",
                "customFieldWithSameNameError": "Erro ao salvar campo personalizado com o mesmo nome",
                "saveCustomFieldError": "Erro ao salvar campo personalizado",
                "customFieldOptionWithSameNameError": "Erro ao salvar campo personalizado com nomes de opção duplicados",
                "customFieldTypeSelectOneWithoutOptions": "As opções são necessárias para os campos personalizados \"Selecionar um\"",
                "customFieldsInvalid": "Reveja os campos personalizados"
            },
            "help": {
                "fieldNameTooltip": "Nome do rótulo do campo personalizado",
                "fieldTypeTooltip": "Tipo de dados a coletar com este campo personalizado",
                "isRequiredTooltip": "Indique se este campo personalizado é obrigatório",
                "editCustomFieldInstructions": "Edite o tipo de dados a coletar e se esse campo personalizado deve ou não ser exigido.",
                "addCustomFieldInstructions": "Adicione um campo personalizado para coletar informações.",
                "optionsTooltip": "Liste as opções na ordem de exibição."
            },
            "editCustomField": "Editar campos personalizados existentes",
            "addCustomField": "Adicionar Novo Campo Personalizado",
            "newCustomField": "Novo Campo Personalizado",
            "confirmRemoveCustomField": {
                "header": "Remover campo personalizado",
                "body": "Tem certeza que deseja remover este campo personalizado?",
                "text": "Remover"
            },
            "confirmSaveCustomField": {
                "header": "Salvar campo personalizado",
                "body": "Tem certeza que deseja salvar este campo personalizado",
                "text": "Salvar"
            },
            "customFields": "Campos personalizados",
            "optionName": "Nome da Opção",
            "addOption": "Adicionar Opção",
            "default": "Padrão",
            "restore": "Restaurar",
            "options": "Opções",
            "makeDefault": "Tornar padrão",
            "saveDisplayOrder": {
                "cancelDisplayOrder": "Cancelar ordem",
                "saveDisplayOrder": "Salvar ordem",
                "header": "Salvar nova ordem de exibição",
                "body": "Tem certeza de que deseja alterar a ordem em que os campos são exibidos?",
                "text": "Salvar ordem de exibição"
            },
            "customFieldsActivity": "Atividade de campos personalizados",
            "deprecatedOption": "Opção obsoleta",
            "bcCustomQuestionsTitle": "Perguntas personalizadas sobre continuidade de negócios",
            "bcCustomQuestionsDescription": "Gerencie suas questões personalizadas de continuidade de negócios.",
            "disasterRecovery": "Recuperação de desastres",
            "drCustomQuestionsTitle": "Perguntas personalizadas sobre recuperação de desastres.",
            "drCustomQuestionsDescription": "Gerencie suas perguntas personalizadas sobre recuperação de [[Technology]].",
            "technology": "[[Technology]]",
            "technologyTypes": "[[Technology]] Types",
            "continuityOfOperationPlanning": "Continuidade",
            "customQuestionsDescription": "Gerencie suas questões alfandegárias de continuidade aqui."
        },
        "bulkUpload": {
            "vendor": {
                "msgBulkUploadInstructions": "Tem vários fornecedores que deseja adicionar ou modificar? ",
                "lblAddMultipleVendorsHeader": "Adicionando vários novos fornecedores",
                "lblAddMultipleVendorsDetail": "Baixe esta planilha contendo apenas informações de cabeçalho e preencha as informações do seu novo fornecedor, um fornecedor por linha, antes de salvá-las no formato .CSV em seu computador.",
                "btnBlankVendorsFile": "Arquivo de fornecedor em branco",
                "lblEditMultipleVendorsHeader": "Editar ou excluir vários fornecedores",
                "lblEditMultipleVendorsDetail": "Baixe esta planilha contendo as informações atuais do seu fornecedor e modifique conforme necessário antes de salvá-la no formato .CSV em seu computador.",
                "btnCurrentVendors": "Fornecedores Atuais",
                "lblEditMultipleVendorsNotes": "NOTA: Para excluir fornecedores, altere seu status para Excluir. ",
                "fileName": "BulkVendorUpload.csv",
                "msgVendorExportSuccessful": "Exportação de fornecedores concluída com sucesso.",
                "errorVendorExportFailed": "Não é possível exportar um arquivo de fornecedores.",
                "msgUploadVendorsSuccess": "Upload em massa para fornecedores bem-sucedido.",
                "lblNoFileSelected": "Nenhum arquivo especificado."
            },
            "vendorContact": {
                "msgBulkUploadInstructions": "Tem vários contatos que deseja adicionar ou modificar? ",
                "lblAddMultipleContactsHeader": "Adicionando vários novos contatos",
                "lblAddMultipleContactsDetail": "Baixe esta planilha contendo apenas informações de cabeçalho e preencha as informações do seu novo contato, um contato por linha, antes de salvá-lo no formato .CSV em seu computador.",
                "btnBlankContactsFile": "Arquivo de contato em branco",
                "lblEditMultipleContactsHeader": "Editar ou excluir vários contatos",
                "lblEditMultipleContactsDetail": "Baixe esta planilha contendo suas informações de contato atuais e modifique conforme necessário antes de salvá-la no formato .CSV em seu computador.",
                "btnCurrentContacts": "Contatos atuais",
                "lblEditMultipleContactsNotes": "NOTA: Para excluir contatos, altere seu status para Excluir. ",
                "fileName": "BulkVendorContactUpload.csv",
                "msgContactExportSuccessful": "Exportação de contatos concluída com sucesso.",
                "errorContactExportFailed": "Não é possível exportar um arquivo de contatos.",
                "msgUploadContactsSuccess": "Upload em massa de contatos bem-sucedido.",
                "lblNoFileSelected": "Nenhum arquivo especificado."
            },
            "agency": {
                "msgBulkUploadInstructions": "Tem várias agências que deseja adicionar ou modificar? ",
                "lblAddMultipleHeader": "Adicionando várias novas agências",
                "lblAddMultipleDetail": "Baixe esta planilha contendo apenas informações de cabeçalho e preencha as informações da sua nova agência, uma agência por linha, antes de salvá-la no formato .CSV em seu computador.",
                "btnBlankFile": "Arquivo de agência em branco",
                "lblEditMultipleHeader": "Editar ou excluir várias agências",
                "lblEditMultipleDetail": "Baixe esta planilha contendo as informações atuais da sua agência e modifique conforme necessário antes de salvá-la no formato .CSV em seu computador.",
                "btnCurrent": "Agências atuais",
                "lblEditMultipleNotes": "NOTA: Para excluir agências, altere seu status para Excluir. ",
                "fileName": "BulkAgencyUpload.csv",
                "msgExportSuccessful": "Exportação de agências concluída com sucesso.",
                "errorExportFailed": "Não é possível exportar um arquivo de agências.",
                "msgUploadSuccess": "Upload em massa para agências bem-sucedido.",
                "lblNoFileSelected": "Nenhum arquivo especificado."
            },
            "agencyContact": {
                "msgBulkUploadInstructions": "Tem vários contatos que deseja adicionar ou modificar? ",
                "lblAddMultipleHeader": "Adicionando vários novos contatos",
                "lblAddMultipleDetail": "Baixe esta planilha contendo apenas informações de cabeçalho e preencha as informações do seu novo contato, um contato por linha, antes de salvá-lo no formato .CSV em seu computador.",
                "btnBlankFile": "Arquivo de contato em branco",
                "lblEditMultipleHeader": "Editar ou excluir vários contatos",
                "lblEditMultipleDetail": "Baixe esta planilha contendo suas informações de contato atuais e modifique conforme necessário antes de salvá-la no formato .CSV em seu computador.",
                "btnCurrent": "Contatos atuais",
                "lblEditMultipleNotes": "NOTA: Para excluir contatos, altere seu status para Excluir. ",
                "fileName": "BulkVendorContactUpload.csv",
                "msgExportSuccessful": "Exportação de contatos concluída com sucesso.",
                "errorExportFailed": "Não é possível exportar um arquivo de contatos.",
                "msgUploadSuccess": "Upload em massa de contatos bem-sucedido.",
                "lblNoFileSelected": "Nenhum arquivo especificado."
            }
        },
        "departmentAlternate": {
            "MemberModal": {
                "headerLabel": "Selecione um membro suplente para {{name}}",
                "searchUserLabel": "Procure uma alternativa",
                "currentUserLabel": "Alternativa Atual",
                "resultLabel": "Suplentes"
            },
            "alternateSaveSuccessfully": "Alternativa salva",
            "alternateSaveUnsuccessfully": "Falha ao salvar a alternativa",
            "alternateRequired": "A alternativa é obrigatória",
            "alternate": "Alternar",
            "noAlternate": "Sem alternativa"
        },
        "technology": {
            "manageTechnology": "[[Technologies]]",
            "technologyVendors": "Fornecedor",
            "technologyDependencies": "[[Technology]] Dependências",
            "technologyApplicationDetails": "[[Technology]] Detalhes",
            "technologyTasks": "[[Technology]] Tarefas",
            "technologyRecoveryTime": "[[Technology]] Tempo de recuperação",
            "configureTechnology": "Configurar [[Technology]]",
            "technologyBuildManage": "Crie e gerencie suas [[technologies]]",
            "technologyDescription": "Construir e gerenciar [[technologies]] para um plano de recuperação de desastres de TI envolve uma abordagem sistemática para garantir que [[technologies]] críticas possam ser restauradas de forma rápida e eficiente em caso de desastre.",
            "technologySecondaryDescription": "Identifique suas [[technologies]] críticas para o negócio com base em sua importância para as funções organizacionais.",
            "searchPlaceHolder": "Pesquise aqui",
            "removeTechnology": "Remover [[Technology]]",
            "owner": "Proprietário",
            "addTechnology": "Adicionar [[Technology]]",
            "filterByTier": "Filtrar por nível",
            "filterByType": "Filtrar por tipo",
            "manageTechnologies": "Gerenciar [[Technologies]]",
            "MemberSelectionModal": {
              "headerLabel": "Atribuir proprietário",
              "searchUserLabel": "Selecione os membros",
              "currentUserLabel": "Membro Selecionado",
              "customCurrentUserLabel": "Funcionários atualmente selecionados",
              "resultLabel": "Membros",
              "assignButton": "Adicionar"
            },
            "removeConfirmation1": "Tem certeza de que deseja remover esta [[technology]] do seu planejamento de TI/DR? ",
            "removeConfirmation2": "Tem certeza de que deseja remover essas [[technologies]] do seu planejamento de TI/DR? ",
            "unAssignConfirmation": "A atribuição alternativa será apagada porque não há atribuição primária. ",
            "unAssignConfirmationHeader": "A tarefa alternativa será removida",
            "trackDescription": "Acompanhe e complete suas [[technologies]] aqui.",
            "noTechnologies": "Não [[Technologies]]",
            "name": "Nome",
            "status": "Status",
            "dueDate": "Data de vencimento",
            "technologySME": "[[Technology]] Proprietário",
            "open": "Abrir",
            "edit": "Editar",
            "selectTechnologies": "Selecione [[Technologies]]",
            "vendors": {
              "title": "Fornecedor",
              "description": "Selecione os fornecedores que oferecem suporte ou fornecem serviços exigidos por esta [[technology]].",
              "secondaryDesc": "Identifique os fornecedores de suporte.",
              "addVendor": "Adicionar fornecedor",
              "confirmDeleteHeader": "Remover {{ name }}",
              "confirmDeleteVerbiage": "Tem certeza de que deseja remover este fornecedor desta [[technology]]?",
              "vendorDetails": "Detalhes do fornecedor",
              "lblAdditionalInstructions": "Acordos de SLA",
              "updateInstructions": "Atualizar acordos de SLA",
              "instructionsModal": {
                "title": "Detalhes do SLA",
                "instructionsPLaceHolder": "Insira os detalhes do SLA"
              },
              "successOnSave": "O fornecedor foi salvo com sucesso",
              "failedToSave": "O fornecedor foi salvo sem sucesso",
              "successOnDelete": "O fornecedor foi excluído com sucesso",
              "failedToDelete": "O fornecedor foi excluído sem sucesso",
              "modalHeader": "Selecione o fornecedor"
            },
            "taskSets": {
              "title": "Conjuntos de tarefas",
              "description": "A recuperação de uma [[technology]] em um plano de recuperação de desastres de TI envolve uma série de etapas bem definidas para minimizar o tempo de inatividade e \n ",
              "secondaryDesc": "Personalize essas etapas com base nas necessidades e características específicas desta [[technology]].",
              "tasks": "Tarefas",
              "addTask": "Adicionar tarefa",
              "task": "Tarefa",
              "removeTask": "Remover tarefa",
              "removeTaskDescription": "Tem certeza de que deseja remover esta tarefa?",
              "removeTaskSet": "Remover conjunto de tarefas",
              "removeTaskSetDescription": "Tem certeza de que deseja remover este conjunto de tarefas?",
              "successOnSaveTaskSet": "O conjunto de tarefas foi salvo com sucesso",
              "failedToSaveTaskSet": "O conjunto de tarefas foi salvo sem sucesso",
              "successOnDeleteTaskSet": "O conjunto de tarefas foi excluído com sucesso",
              "failedToDeleteTaskSet": "O conjunto de tarefas foi excluído sem sucesso",
              "successOnSaveTask": "A tarefa foi salva com sucesso",
              "failedToSaveTask": "A tarefa foi salva sem sucesso",
              "successOnDeleteTask": "A tarefa foi excluída com sucesso",
              "failedToDeleteTask": "A tarefa foi excluída sem sucesso",
              "newTaskSet": "Novo conjunto de tarefas",
              "teams": "Equipes",
              "taskSetRecoveryTime": "Tempo de recuperação do conjunto de tarefas",
              "taskSet": "Conjunto de tarefas",
              "assignTime": "Atribuir tempo",
              "assignTimeTitle": "Atribuir horário",
              "assignMember": "Atribuir membro",
              "assignTeam": "Atribuir equipe",
              "memberSaveSuccessfully": "O membro foi salvo com sucesso",
              "memberSaveUnsuccessfully": "O membro foi salvo sem sucesso",
              "memberSaveSuccessfully2": "Os membros foram salvos com sucesso",
              "memberSaveUnsuccessfully2": "Os membros foram salvos sem sucesso",
              "teamSaveSuccessfully": "A equipe foi salva com sucesso",
              "teamSaveUnsuccessfully": "A equipe foi salva sem sucesso",
              "teamSaveSuccessfully2": "As equipes foram salvas com sucesso",
              "teamSaveUnsuccessfully2": "As equipes foram salvas sem sucesso",
              "assigneeModal": {
                "headerLabel": "Selecione um membro",
                "searchUserLabel": "Procure um membro",
                "currentUserLabel": "Membros atualmente atribuídos",
                "resultLabel": "Membros"
              }
            },
            "upstreamDependencies": {
              "pageDescription": "Compreender as dependências upstream permite que as organizações avaliem riscos, priorizem recursos e desenvolvam planos de contingência para mitigar o impacto de interrupções em operações críticas durante cenários de recuperação de desastres.",
              "sub-text": "Identifique todas as [[technologies]] das quais esta [[technology]] depende.",
              "addDependency": "Nova Dependência",
              "successOnSave": "A dependência foi salva com sucesso",
              "failedToSave": "A dependência foi salva sem sucesso",
              "successOnSavePlural": "As dependências foram salvas com sucesso",
              "failedToSavePlural": "As dependências foram salvas sem sucesso",
              "successOnDelete": "A dependência foi excluída com sucesso",
              "failedToDelete": "A dependência foi excluída sem sucesso",
              "removeDependencyConfirmationModal": {
                "header": "Remover {{dependencyName}}",
                "body": "Tem certeza de que deseja remover esta dependência desta [[technology]]?"
              }
            },
            "recoveryTime": {
              "title": "Tempo estimado de recuperação",
              "pageDescription": "Escolher o tempo estimado de recuperação é uma decisão crucial para organizações que buscam garantir a continuidade dos negócios diante de possíveis desastres ou interrupções. ",
              "sub-text": "Selecione o tempo de recuperação estimado para sua [[technology]].",
              "earliestRTO": "RTO mais antigo para esta [[technology]]",
              "estimatedTime": "Seu tempo de recuperação estimado com base em suas tarefas",
              "successOnSave": "O tempo de recuperação foi salvo com sucesso",
              "failedToSave": "O tempo de recuperação foi salvo sem sucesso"
            },
            "myTechnologies": {
              "header": "Minhas [[Technologies]]",
              "subHeader": "Selecione uma das suas [[technologies]] abaixo para começar.",
              "description": "Depois de selecionar uma [[technology]], você começará a passar pelo processo de construção de seu Runbook de TI/DR para essa [[technology]]. "
            },
            "backToMyTechnologies": "Voltar para minhas [[Technologies]]",
            "setDueDate": {
              "header": "Data de vencimento",
              "body": "Algumas [[technologies]] já possuem data de vencimento, deseja definir esta data de vencimento <b>{{dueDate}}</b> para todas [[technologies]]?",
              "allTechnologies": "Sim",
              "newTechnologies": "Não",
              "msgConfirmBody": "Tem certeza de que deseja definir esta nova data de vencimento <b>{{dueDate}}</b> para todas [[technologies]]?"
            },
            "sendEmail": {
              "dueDateRequired": "Data de vencimento exigida para todas [[technologies]]"
            },
            "detailsAndAttachments": {
              "label": "[[Technology]] Detalhes e Anexos",
              "additionalDetails": "Detalhes Adicionais",
              "enterAdditionalDetails": "Insira detalhes adicionais",
              "additionalDetailsSavedSuccessfully": "Detalhes adicionais salvos com sucesso",
              "customQuestionSavedSuccessfully": "Perguntas personalizadas salvas com sucesso"
            },
            "technologyAnalytics": {
              "label": "[[Technology]] Análise",
              "assignedOwner": "Proprietário atribuído",
              "progress": "Progresso",
              "rpo": "RPO",
              "earliestRto": "RTO mais antigo",
              "technologyType": "[[Technology]] Tipo",
              "vendors": "Fornecedores",
              "assignedDisasterRecoveryTeams": "Equipes de recuperação designadas",
              "processes": "[[Processos]]"
            }
          },
        "confirmationCodeModal": {
            "header": "Tem certeza de que deseja começar a editar os seguintes dados do sistema?",
            "body": "Qualquer alteração IRÁ afetar TODOS os portais e clientes. ",
            "btn": "Eu entendo",
            "codeError": "Confirmação de código sem sucesso"
        },
        "teamSelectionModal": {
            "header": "Atribuir equipe",
            "searchLabel": "Selecione a(s) equipe(s)",
            "searchPlaceholder": "Procure aqui",
            "selectedTeams": "Equipes atualmente selecionadas",
            "teams": "Equipes"
        },
        "processDependenciesInfoSidebar": {
            "dependencies": "Dependências",
            "introDesc": "As dependências são essenciais para identificar, pois destacam processos gerenciados por outros departamentos ou grupos que são cruciais para a continuidade de suas operações. ",
            "examples": "Exemplos",
            "payroll": "Folha de pagamento (RH)",
            "payrollInfo": "Dependência: Financiamento (Finanças)",
            "proposalDevelop": "Desenvolvimento de Propostas (Vendas)",
            "proposalDevelopInfo": "Dependência: Embalagem (Marketing)",
            "employee": "Integração de funcionários (RH)",
            "employeeInfo": "Dependência: Operações de Segurança (TI)",
            "conclusion": "Ao reconhecer essas dependências, você pode se preparar e coordenar melhor com os departamentos relevantes para garantir a continuidade perfeita de seus processos críticos."
        },
        "criticalDateInfoSidebar": {
            "importantDates": "Datas importantes para continuidade",
            "introDesc": "Ao planejar a continuidade, é crucial identificar e adicionar datas críticas ao seu processo. ",
            "timeReporting": "Relatório de tempo",
            "timeReportingInfo": "Frequência: Semanal (toda quarta-feira)",
            "financialReporting": "Relatórios Financeiros (Arquivamento Trimestral)",
            "financialReportingInfo": "Frequência: Trimestralmente (dentro de 4 dias úteis após o final do trimestre)",
            "backup": "Pagamentos retidos na fonte para IRS",
            "backupInfo": "Frequência: Quinzenal (todas as sextas-feiras)",
            "irsTax": "Relatórios de impostos do IRS para fornecedores (1099-MISC) e retenção na fonte (Formulário 945)",
            "irsTaxInfo": "Frequência: Anualmente (31 de janeiro, dentro de 3 dias úteis)",
            "conclusion": "Garantir que essas datas críticas façam parte do seu planejamento de continuidade ajudará a manter as operações tranquilas e a conformidade durante interrupções."
        },
        "continuityStrategiesInfoSidebar": {
            "introDesc": "Uma estratégia de continuidade descreve os métodos pelos quais se espera que um processo continue durante um incidente. ",
            "work": "Trabalhe de qualquer lugar",
            "workInfo": "Continue trabalhando em casa ou em um espaço remoto semelhante.",
            "temporary": "Espaço de trabalho temporário",
            "temporaryInfo": "Realocar operações inteiras para um local temporário.",
            "move": "Mova o trabalho",
            "moveInfo": "Transfira o trabalho para um fornecedor externo ou outra equipe interna.",
            "suspend": "Suspender",
            "suspendInfo": "Suspenda temporariamente o trabalho.",
            "conclusion": "A implementação e validação antecipada dessas estratégias de continuidade ajudará a garantir que sua empresa possa manter as operações durante interrupções inesperadas."
        }
    }
}
