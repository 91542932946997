{
    "department": "[[Department]]",
    "idleTimeout": {
        "extendSession": "Mantener iniciada la sesión",
        "signOut": "Cerrar sesión",
        "title": "Su sesión está a punto de terminar por inactividad."
    },
    "overview": {
        "overview": "Visión general",
        "noDataMessage": "Los [[scope]] y [[team]] seleccionados no contienen datos."
    },
    "hierarchy": "Jerarquía",
    "administration": "Administración",
    "complianceCalendar": {
        "complianceCalendar": "Calendario de cumplimiento",
        "previousWeek": "Semana anterior",
        "nextWeek": "Semana siguiente",
        "today": "Hoy",
        "week": "Semana",
        "month": "Mes",
        "day": "Día",
        "next": "Siguiente",
        "previous": "Anterior",
        "allDayEvents": "Eventos de todo el día",
        "noAllDayEvent": "No hay eventos",
        "assignments": "Asignaciones",
        "all": "Todas",
        "mine": "Mía"
    },
    "scopes": "[[Scopes]]",
    "teams": "[[Teams]]",
    "teamMembers": "[[Team Members]]",
    "items": "[[Items]]",
    "issues": "[[Issues]]",
    "externalResources": "[[External Resources]]",
    "assets": "[[Assets]]",
    "pendingApprovals": "Aprobaciones pendientes",
    "plans": {
        "plans": "[[Plans]]",
        "download": "Descargar",
        "generate": "Generar",
        "name": "Nombre",
        "numberOfItems": "Número de [[Items]]",
        "lastStructureUpdate": "Última actualización de estructura",
        "lastGeneration": "Última generación",
        "daysSinceGeneration": "Días desde la generación",
        "lblDateTimeFromTo": "Ejecutar informe para el último"
    },
    "support": {
        "description": "<p>Póngase en contacto con atención al cliente</p><p>Dinos como podemos ayudar.</p>",
        "email": "ENVÍANOS UN CORREO ELECTRÓNICO @",
        "phone": "LLÁMANOS @",
        "phoneOption": "OPCIÓN 1"
    },
    "reports": {
        "reports": "[[Reports]]",
        "required": "Se requiere {{ fieldName }}",
        "all": "Todas",
        "select": "Seleccione",
        "portal": "Portal",
        "dashboard": {
            "dashboard": "Tablero de control",
            "admin": "Administrador",
            "messaging": "Mensajería",
            "training": "Formación",
            "lblUserByStatus": "Usuario por estado",
            "lblUserLoginActivity": "Actividad de inicio de sesión de usuario",
            "lblMessagesSent": "Mensajes enviados",
            "lblRecentMessages": "Mensajes recientes",
            "lblCourseCompletion": "Finalizaciones del curso",
            "lblMostCompletedCourses": "Cursos más completados",
            "lblTotal": "Total",
            "lblLast30Days": "(últimos 30 días)",
            "lblLast3Months": "(últimos 3 meses)",
            "lblLast3Messages": "(últimos 3 mensajes)",
            "lblLast6Months": "(últimos 6 meses)",
            "lblTop5courses": "(5 mejores cursos)",
            "lblTotalResponses": "Respuestas totales",
            "lblTotalAudience": "Audiencia total"
        },
        "errors": {
            "renderingReport": "Informe de error de renderización",
            "loadingReport": "Error al cargar los parámetros del [[report]]"
        },
        "labels": {
            "Role": "Rol",
            "Department": "[[Department]]",
            "Primary Location": "[[Location]] primaria",
            "Location": "[[Location]]",
            "Groups": "Grupos",
            "Group": "Grupo",
            "Status": "Estado",
            "Select Format": "Seleccionar formato",
            "Response Required": "Respuesta requerida",
            "Category": "Categoría",
            "Course": "Curso",
            "Include": "Incluir",
            "Completed Date": "Fecha en que se completó",
            "Assignee": "Asignador",
            "Compliance Category": "Categoría de cumplimiento",
            "External Resource Type": "Tipo de [[External Resource]]",
            "External Resource": "[[External Resource]]",
            "Asset": "[[Asset]]",
            "Item": "[[Item]]",
            "Plan": "[[Plan]]",
            "Assignment Type": "Tipo de asignación",
            "Team": "[[Team]]",
            "Scope": "[[Scope]]",
            "Include Sub Teams": "Incluir Sub [[Teams]]",
            "Include Resolved Issues": "Incluir [[Issues]] resueltos",
            "Include All Expired": "Incluir todos los vencidos",
            "Include Archived": "Incluir archivados",
            "Incident Types": "Tipos de incidentes",
            "bcFunctionalArea": "[[Department]]",
            "bcProcess": "[[Process]]",
            "bcDueDateFrom": "Fecha de vencimiento desde",
            "bcDueDateTo": "Fecha de vencimiento hasta",
            "datetime_to": "Hasta la fecha",
            "datetime_from": "Desde de la fecha",
            "To Date": "Hasta la fecha",
            "From Date": "Desde de la fecha",
            "to Date": "Hasta la fecha",
            "from Date": "Desde de la fecha",
            "Since Date": "Desde la fecha",
            "User": "Usuario",
            "only display if days past due greater than (zero displays all)": "mostrar solo si los días de atraso son mayores que (cero muestra todo)",
            "Only display if days past due greater than (zero displays all)": "Mostrar solo si los días de atraso son mayores que (cero muestra todo)",
            "Only display if days pasrt due greater than (zero displays all)": "Mostrar solo si los días de atraso son mayores que (cero muestra todo)",
            "Sent From Date": "Enviado desde la fecha",
            "Sent To Date": "Enviado hasta la fecha",
            "bcAssignee": "Asignador",
            "bcProgressStatus": "Estado del progreso",
            "bcStrategyType": "Tipos de estrategia",
            "bcImpactTimespanType": "RTO",
            "Admin": "Administrador",
            "Deleted Users": "Usuarios eliminados",
            "Registration Status": "Estado de registro",
            "User Login Activity": "Actividad de inicio de sesión de usuario",
            "User Update History": "Historial de actualizaciones de usuario",
            "Users": "Usuarios",
            "Incidents": "Mensajería",
            "Message User Latest Responses": "Mensaje de usuario Últimas respuestas",
            "Message User Responses By Contact Method": "Mensaje de respuestas del usuario por método de contacto",
            "Training": "Formación",
            "Certification Status": "Estado de certificación",
            "Course Completion": "Finalización del curso",
            "Course Status by User": "Estado del curso por usuario",
            "Learner Report": "Informe del alumno",
            "External Resources By Type": "Recursos externos por tipo",
            "External Resources List": "Lista de recursos externos",
            "External Resource ": "Recurso Externo",
            "IQ Resource Changes": "Cambios de recursos de IQ",
            "iQ Asset Changes": "Cambios en activos de iQ",
            "iQ External Resource Changes": "Cambios de recursos externos de iQ",
            "iQ Item Changes": "Cambios de elementos de iQ",
            "iQ Plan Changes": "Cambios en el [[plan]] iQ",
            "Issue": "[[Issues]]",
            "Issues by Compliance": "[[Issues]] por cumplimiento",
            "Issues by Priority": "[[Issues]] por prioridad",
            "Issues by Team": "[[Issues]] por [[team]]",
            "Items": "Artículos",
            "Assignment Count By Team Summary": "Resumen del recuento de asignaciones por [[team]]",
            "Assignments by Compliance Category Detail": "Asignaciones por detalle de categoría de cumplimiento",
            "Assignments By Physical Location": "Asignaciones por ubicación física",
            "Assignments by Team Detail": "Asignaciones por detalle del [[team]]",
            "Assignments by Team Summary": "Resumen de asignaciones por [[team]]",
            "Assignments By Type": "Asignaciones por tipo",
            "Assignments Emergency Distributions": "Asignaciones Distribuciones de emergencia",
            "Management": "Gestión",
            "Assignments Readiness Chart and Summary": "Cuadro de preparación de asignaciones y resumen",
            "Team Member Readiness Chart": "Cuadro de preparación de [[team members]]",
            "Verification History": "Historial de verificación",
            "Planes": "Planes",
            "Plan Content Cross Reference": "Referencia cruzada de contenido del [[plan]]",
            "Plan Recovery Structure By Team": "Estructura de recuperación del [[plan]] por [[team]]",
            "Plan Summary By Team": "Resumen del [[plan]] por [[team]]",
            "Processes Status": "Estado de los [[processes]]",
            "Teams": "[[Team]]s",
            "Section Mission Statements": "Declaraciones de misión de la sección",
            "Team Mission Statements": "Declaraciones de misión del [[team]]",
            "Team Objectives Summary": "Resumen de objetivos del [[team]]",
            "Team Summary Report": "Informe de resumen del [[team]]",
            "User Assignments List": "Lista de asignaciones de usuarios",
            "User Assignments List By Team": "Lista de asignaciones de usuarios por [[team]]",
            "User Assignments Summary": "Resumen de asignaciones de usuario",
            "User Detail by Name": "Detalles de usuario por nombre",
            "User Detail by Team": "Detalles de usuario por [[team]]",
            "User Notifications Detail": "Detalle de notificaciones de usuario",
            "User Notifications Summary": "Resumen de notificaciones de usuario",
            "Users Not Assigned to Teams": "Usuarios no asignados a [[team]]s",
            "Business Continuity Planner Reports": "[[Reports]] de [[Planner]]",
            "Assignment Report": "Informe de asignación",
            "Assignments by Person Report": "Informe de asignaciones por persona",
            "BIA Severity Levels": "Niveles de gravedad [[BIA]]",
            "Department Status": "Estado del [[department]]",
            "Equipment Report": "Informe de [[team]]",
            "Strategy And RTO Employee Report": "Informe de empleados de estrategia y RTO",
            "Strategy And RTO Report": "Informe de estrategia y RTO",
            "Strategy By RTO Report": "Informe de estrategia por RTO",
            "Technology Report": "Informe [[Technology]]",
            "Technology and Application Report by RTO": "Informe de [[technology]] y [[application]] de RTO",
            "Vendor Report": "Informe de proveedor",
            "generic": "Genérica",
            "banking": "Bancaria",
            "legal": "Legal",
            "government": "Gubernamental",
            "Missed RTOs": "RTO perdidos",
            "Progress over time": "Progreso a lo largo del tiempo",
            "Progress over time by RTO": "Progreso a lo largo del tiempo por RTO",
            "Incident Communication": "Comunicación de incidencias",
            "Audit": "Auditoría",
            "Data Accessed": "Datos a los que se accede",
            "Unauthorized Access": "Inicio de sesión fallido",
            "Application Technology Dependency Report": "[[Application]] [[Technology]] Dependencia [[Report]]",
            "Gap Report": "[[Report]] de brechas",
            "Initiative Report": "Iniciativas [[Report]]",
            "Threats Report": "Amenazas [[Report]]",
            "Incident Manager": "[[Incident Manager]]",
            "options": "Opciones",
            "bcReportingTimeInterval": "Intervalo de tiempo de informe de BC",
            "Assessment Report": "[[Report]] de evaluación",
            "Assessment Report By Status": "[[Report]] de evaluación por estado",
            "select_assessmentTargetType": "Tipo de objetivo",
            "select_threatType_Id": "Tipo de amenaza",
            "search_threatName_toId": "Nombre de la amenaza",
            "Assessment Status": "Estado de evaluación",
            "Resource Type": "Tipo de recurso",
            "incidentName": "Nombre del incidente",
            "escalated": "Escalado",
            "owner": "Dueño",
            "sort_by": "Ordenar por",
            "Target Type": "Tipo de objetivo",
            "Target Name": "Nombre de destino",
            "select_riskInitiativeStatus": "Estado",
            "search_riskInitiativeName_toId": "Nombre",
            "Department Schedule": "Horario de [[department]]",
            "Issue Action Items": "Elementos de acción del [[issue]]",
            "Technology": "[[Technology]]",
            "Technologies Tiers": "[[Technologies]] Niveles",
            "Technology to Technology Tier Gap Report": "[[Technology]] a [[Technology]] Brecha de niveles",
            "Technology Vendor Dependency Report": "[[Technology]] Dependencia del proveedor"
        },
        "validValues": {
            "Waiting For Approval": "A la espera de la aprobación",
            "Closed": "Cerrado",
            "In Progress": "En curso",
            "Deleted": "Eliminado",
            "Archived": "Archivado",
            "Open": "Abierto",
            "All": "Todo",
            "User": "Usuario",
            "User Email": "Correo electrónico del usuario",
            "User Address": "Dirección de usuario",
            "User Phone": "Teléfono de usuario",
            "User Profile": "Perfil del usuario",
            "User Role": "Rol del usuario",
            "Location": "Ubicación",
            "Location Address": "Dirección del sitio",
            "Location Phone": "Ubicación Teléfono",
            "Department": "[[Department]]",
            "Group": "Grupo",
            "Role": "Rol",
            "Active": "Activo",
            "Pending": "Pendiente",
            "Locked": "bloqueado",
            "Inactive": "Inactivo",
            "Resolved": "Resuelto",
            "Completed": "Terminado",
            "Complete": "Completo",
            "Today": "Hoy",
            "Last 7 Days": "Los últimos 7 días",
            "Last 30 Days": "Últimos 30 días",
            "Last 60 Days": "Últimos 60 días",
            "Last 90 Days": "Últimos 90 días",
            "Last 365 Days": "Últimos 365 días",
            "Over 365 Days": "Más de 365 días",
            "Not Taken": "No tomado",
            "Incomplete": "Incompleto",
            "PDF": "PDF",
            "CSV (comma delimited)": "CSV (delimitado por comas)",
            "Yes": "Sí",
            "No": "No",
            "Title": "Título",
            "Status": "Estado",
            "Owner": "Dueño",
            "Departments": "[[Department]]",
            "Processes": "[[Processes]]",
            "Vendors": "Proveedores",
            "Vendor Addresses": "Direcciones de Proveedores",
            "Vendor Contacts": "Contactos de Proveedores",
            "Technology": "[[Technology]]",
            "Equipment": "[[Equipment]]"
        },
        "contentCategories": {
            "Terrorism": "Terrorismo",
            "Health": "Salud",
            "Other": "Otro",
            "Cyber Threats": "Amenazas cibernéticas",
            "Natural Disasters": "Desastres naturales",
            "Test Category": "Categoría de prueba",
            "Additional Resources Parent Category": "Recursos adicionales Categoría principal",
            "First Aid": "Primeros auxilios",
            "Workplace Threats": "Amenazas en el lugar de trabajo"
        },
        "courses": {
            "Earthquake": "Terremoto",
            "Medical Emergency: Non-Traumatic ": "Emergencia Médica: No Traumática ",
            "Chemical Spill ": "Derrame de sustancias químicas ",
            "Explosions ": "Explosiones ",
            "Guess WHo": "Adivina quién",
            "Blackout ": "Apagón ",
            "Tornado ": "Tornado ",
            "Medical Emergency: Traumatic ": "Emergencia Médica: Traumática ",
            "Workplace Violence ": "Violencia en el trabajo ",
            "Fire Evacuation": "Evacuación de incendios",
            "Information Security": "Seguridad de información",
            "Anthrax": "Ántrax",
            "Tornado": "Tornado",
            "Large Event ": "Gran Evento ",
            "Fire Evacuation ": "Evacuación de incendios ",
            "Dirty Bomb ": "bomba sucia ",
            "Explosions": "Explosiones",
            "Tenant Warden": "Guardián de inquilinos",
            "Certification 1: Introduction ": "Certificación 1: Introducción ",
            "Dirty Bomb": "bomba sucia",
            "Pandemic Flu ": "Gripe pandémica ",
            "Office Creeper ": "Enredadera de oficina ",
            "Anthrax ": "Ántrax ",
            "Active Shooter 2013": "Tirador activo 2013",
            "Winter Storm": "Tormenta de invierno",
            "Hurricanes ": "Huracanes ",
            "Office Creeper": "Enredadera de oficina",
            "Bomb Threat": "Amenaza de bomba",
            "Certification 3: Taking Action ": "Certificación 3: Tomar medidas ",
            "Certification 2: Your Preparedness Program ": "Certificación 2: Su programa de preparación ",
            "Hurricanes": "Huracanes",
            "Blackout": "Apagón",
            "Active Shooter": "Tirador activo",
            "Flood ": "Inundación ",
            "Winter Storm ": "Tormenta de invierno ",
            "Mars Module 2.1": "Módulo de Marte 2.1",
            "Bomb Threat ": "Amenaza de bomba ",
            "Mars Module 1.1": "Módulo de Marte 1.1",
            "Certification": "Certificación",
            "Earthquake ": "Terremoto ",
            "Guess Who": "Adivina quién"
        },
        "incidentTypes": {
            "Not Set": "No establecido",
            "Exercise": "Ejercicio",
            "Panic": "Pánico"
        }
    },
    "storageLocation": "Ubicación de almacenamiento",
    "export": "Exportar",
    "bulkUpload": "Carga masiva",
    "bulkResend": "Reenvío masivo",
    "established": "",
    "notestablished": "No establecido",
    "daysPastDue": "Días de retraso",
    "expired": "Vencido",
    "scope": {
        "scopeName": "Nombre de [[Scope]]",
        "coordinatorName": "Nombre del coordinador",
        "mission": "Misión",
        "assumptions": "Supuestos",
        "planHeader": "Encabezado de [[Plan]]",
        "confirmSaveHeader": "Guardar",
        "confirmSaveBody": "¿Está seguro de que quiere guardar este [[scope]]?",
        "confirmRemoveHeader": "Eliminar [[Scope]]",
        "confirmRemoveBody": "¿Está seguro de que quiere eliminar este [[scope]]?",
        "scopesFilterDesc": "Refinar los resultados por: Nombre de [[Scope]], nombre del encabezado o coordinador del [[Plan]]",
        "newScope": "Nuevo [[Scope]]",
        "viewTeams": "Ver [[Teams]]",
        "actions": "Acciones",
        "cannotRemoveError": "No se puede eliminar [[scope]] con [[teams]] activos"
    },
    "teamMember": {
        "teamMemberFilterDesc": "Refinar resultados",
        "accountableForTeam": "Esta persona es responsable de [[team]].",
        "cannotBeAccountableForTeam": "Esta persona solo tiene acceso a las asignaciones y no puede ser responsable de [[team]]",
        "cannotBeApproverForTeam": "Esta persona solo tiene acceso a las asignaciones y no puede aprobar [[team]]",
        "confirmMakeAccountableHeader": "Responsabilidad por [[Team]]",
        "confirmMakeAccountable": "{{oldname}} actualmente es responsable de este [[team]]. ¿Está seguro de que quiere hacer a {{newname}} responsable de este [[team]]?",
        "confirmMakeAccountableButton": "OK",
        "complianceSchedule": "Calendario de cumplimiento",
        "teamMember": "[[Team Member]]",
        "assignment": "Asignación",
        "isAccountable": "Responsable de [[Team]]",
        "comments": "Comentarios",
        "confirmSaveHeader": "Guardar [[Team Member]]",
        "confirmRemoveHeader": "Eliminar [[Team Member]]",
        "assignmentsTitle": "Asignaciones de [[members]]",
        "recoveryPlanCount": "Copias de los [[Plans]] emitidos a este [[member]]",
        "recoveryPlanIssueDate": "Fecha de emisión del [[Plan]]",
        "issuedRecoveryPlanDateRequired": "La fecha de emisión de [[Plan]] es obligatoria cuando el usuario ha recibido al menos 1 copia de [[plan]].",
        "issuedTeamPlanDateRequired": "La fecha de emisión de [[Team]] [[Plan]] es obligatoria cuando el usuario ha recibido al menos 1 copia de [[plan]].",
        "teamPlanCount": "Copias de [[Team]] [[Plans]] emitidos a este [[member]]",
        "teamPlanIssueDate": "Fecha de emisión de [[Team]] [[Plan]]",
        "plansIssuedTitle": "[[Plans]] emitidos",
        "teamMemberDoesNotExist": "[[Team Member]] no existe.",
        "member": "[[Member]]",
        "verify": "Verificar",
        "establish": "Establecer",
        "verificationSuccess": "Verificación exitosa de [[Team Member]].",
        "unableToVerify": "No se puede verificar a [[Team Member]], inténtelo de nuevo más tarde.",
        "name": "Nombre",
        "type": "Tipo",
        "jobTitle": "Cargo",
        "mailCode": "Código de correo",
        "verifiedDate": "Fecha verificada",
        "verifiedStatus": "Estado",
        "teamMembers": "[[Team Members]]",
        "newTeamMember": "Nuevo [[Team Member]]",
        "removeError": "No se pudo eliminar a [[team member]]",
        "removeAccountableTeamMemberError": "No se puede eliminar a [[team member]], que es responsable de [[team]]",
        "removeTeamMemberWithAssignmentError": "No se puede eliminar a [[team member]] con asignaciones",
        "members": "[[Members]]",
        "lastVerified": "Verificado por última vez",
        "nextVerification": "Siguiente verificación",
        "daysPastDue": "Días de retraso",
        "dueDate": "Fecha de vencimiento",
        "isApprover": "Aprobador por [[Team]]",
        "reassignTasks": {
            "reassignTasks": "Reasignar tareas",
            "modalHeader": "Reasignar tareas de [[team members]]",
            "reassign": "Reasignar",
            "assignToTeamMember": "Asignar a [[team member]]",
            "applyToAllTeams": "Aplicar a todos los [[team]]s",
            "successReassigningTasksMsg": "Reasignación exitosa de tareas {{ count }} de [[team members]]",
            "errorReassigningTasksMsg": "Error al reasignar las tareas de los [[team members]]",
            "errorReassigningTasksToFromUnkownUserMsg": "Error al reasignar las tareas de los [[team members]] hacia o desde un usuario desconocido",
            "errorReassigningTasksToSameUnderlyingUserMsg": "Error al reasignar las tareas de los [[team members]] al mismo usuario",
            "applyToAllTeamsTooltip": "Cuando se aplica a todos los [[team]]s, todas las tareas de iQ que pertenecen a este [[team member]], en todos los [[team]]s, se asignan al usuario designado. Si el usuario designado aún no es [[member]] de los otros [[team]]s, se agrega como [[member]] a esos [[team]]s."
        },
        "export": {
            "allTeamMembers": "Todos los [[Team Members]]",
            "displayedTeamMembers": "[[Members]] del [[team]] mostrados",
            "header": "Exportar [[team members]]",
            "body": "¿Desea exportar solo los usuarios {{ x }} mostrados o todos los [[team members]]?",
            "successMsg": "Exportación generada con éxito",
            "errorMsg": "Error al generar la exportación",
            "fileName": "BulkTeamMemberUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Carga masiva de [[team members]]",
            "msgBulkUploadInstructions": "¿Tiene varios [[team members]] que desea agregar o modificar? Para iniciar el [[process]], elija un archivo de su computadora que tenga el formato correcto.",
            "msgDropFile": "Suelte el archivo aquí o haga clic para seleccionar un archivo",
            "lblSelectedFile": "Archivo seleccionado para cargar",
            "lblNoFileSelected": "Ningún archivo especificado.",
            "lblNeedStartingPoint": "Necesita un punto de partida",
            "lblAddMultipleHeader": "Agregar varios [[members]] nuevos al [[team]]",
            "lblAddMultipleDetail": "Descargue esta hoja de cálculo que contiene solo la información del encabezado y complete la información de su nuevo [[team member]], un usuario por fila, antes de guardarla en su computadora en formato .CSV.",
            "btnBlankFile": "Archivo de [[team member]] en blanco",
            "lblEditMultipleHeader": "Editar o eliminar varios [[team members]]",
            "lblEditMultipleDetail": "Descargue esta hoja de cálculo que contiene la información actual de los [[team members]] y modifíquela según sea necesario antes de guardarla en su computadora en formato .CSV.",
            "btnCurrent": "[[Members]] actuales del [[team]]",
            "lblEditMultipleNotes": "NOTA: Para eliminar usuarios, cambie su estado a Eliminar. Eliminarlos de la hoja de cálculo no los eliminará del sistema. La carga masiva se puede completar incluso si no completa todos los campos para un usuario.",
            "lblUpload": "Enviar",
            "btnCancel": "Cancelar",
            "errorUserExportFailed": "No se puede exportar un archivo de [[team member]].",
            "msgUserExportSuccessful": "La exportación de [[team members]] se completó exitosamente.",
            "msgTestBulkUploadFile": "Este archivo solo se validará porque el nombre del archivo contiene la palabra \"test\"; las actualizaciones no se procesarán.",
            "msgUploadSuccess": "Carga masiva exitosa.",
            "msgValidatingFile": "Verificando archivo...",
            "errorInvalidBulkFileType": "El archivo seleccionado tiene una extensión de archivo incorrecta: debe tener una extensión .CSV.",
            "errorExportFailed": "No se puede exportar un archivo de [[team member]].",
            "msgExportSuccessful": "Exportación completada con éxito."
        },
        "accountable": "Responsable"
    },
    "delete": "Eliminar",
    "save": "Guardar",
    "undo": "Deshacer",
    "replace": "Reemplazar",
    "reset": "Reiniciar",
    "choose": "Seleccionar",
    "errorMessages": {
        "saveError": "Error al guardar {{name}}",
        "removeError": "No se pudo eliminar {{name}}",
        "scopeNameExist": "Ya existe [[Scope]] con el mismo nombre",
        "msgValidateEmailFormat": "Utilice un formato de correo electrónico válido",
        "msgValidateMaxLength": "{{0}} tiene una longitud máxima de {{1}} caracter(es)",
        "msgValidateMaxValue": "El valor de {{0}} no puede ser mayor que {{1}}",
        "msgValidateMinLength": "{{0}} tiene una longitud mínima requerida de {{1}} caracter(es)",
        "msgValidateMinValue": "El valor de {{0}} no puede ser menor que {{1}}",
        "msgValidateRequired": "Ingrese todos los campos obligatorios",
        "msgValidateRequiredBefore": "El valor de {{0}} no puede estar antes que {{1}}",
        "externalResourceContactPrimary": "Se requiere al menos un (1) contacto principal",
        "loadingError": "No se cargó {{name}}",
        "notFoundError": "No se encontró {{name}}",
        "verifyError": "No se pudo verificar {{ name }}",
        "isInvalidPhoneNumber": "Ingrese un número de teléfono válido",
        "formIsDirty": "Primero, guarde sus cambios",
        "dateRangeMax": "El intervalo de fechas supera el intervalo máximo",
        "fromDateIsAfterToDate": "Desde la fecha es posterior a Hasta la fecha",
        "generatePlan": "No se pudo generar [[plan]]",
        "noBusinessContinuityPlan": "No hay planes para obtener una vista previa",
        "planItemsMinimumRequirement": "Al menos un (1) documento o informe debe estar en esta estructura de [[Plan]]",
        "planItemsOnlyDocumentUsedAsCoverPage": "Al menos un (1) documento que no se utilice como portada o un (1) informe debe estar en la estructura de [[Plan]].",
        "checkInError": "No se pudo registrar {{name}}",
        "declineError": "No se pudo rechazar {{name}}",
        "approveError": "No se pudo aprobar {{name}}",
        "itemInUsedByPlan": "No se pudo eliminar este [[item]]. El [[Item]] es utilizado por un [[Plan]].",
        "errorMsgInvalidFirstTeamMember": "El usuario con acceso exclusivo a las asignaciones no puede ser el primer [[team member]] de un [[team]].",
        "duplicateFilename": "Nombre de archivo duplicado",
        "archiveError": "{{name}} NO archivado",
        "unArchiveError": "{{name}} NO restaurado",
        "deleteAttachmentError": "No se pudo eliminar el archivo adjunto {{ docName }}",
        "genericError": "Se produjo un error",
        "errorRemovingMessageTemplate": "Error al eliminar el mensaje guardado",
        "errorUploadFileMessage": "No se pudo cargar el archivo adjunto {{ docName }}",
        "invalidDate": "Fecha inválida",
        "errorAssigningResourceToSelf": "Error al asignarse a uno mismo",
        "errorArchiveResource": "Error al archivar",
        "msgInvalidPersonalPhoneNumber": "Formato de número de teléfono no válido para el teléfono personal (recibirá todos los SMS y llamadas de voz). Para números que no sean de EE. UU., incluya el código de país. Por ejemplo, +44",
        "msgInvalidAdditionalPersonalPhoneNumber": "Formato de número de teléfono no válido para teléfono personal adicional (recibirá todos los SMS y llamadas de voz). Para números que no sean de EE. UU., incluya el código de país. Por ejemplo, +44",
        "msgInvalidMobilePhoneNumber": "Formato de número de teléfono no válido para teléfono móvil (recibirá todos los SMS y llamadas de voz). Para números que no sean de EE. UU., incluya el código de país. Por ejemplo, +44",
        "msgInvalidOfficePhoneNumber": "Formato de número de teléfono no válido para Office Phone. Para números que no sean de EE. UU., incluya el código de país. Por ejemplo, +44",
        "maliciousFileType": {
            "header": "Advertencia",
            "body": "Posible archivo malicioso detectado. ¿Está seguro de que desea cargar este archivo?"
        },
        "noRecordsFound": "No se encontraron registros",
        "deleteEntity": {
            "panicTrigger": "{{entityName}} en uso por configuración de pánico",
            "uii": "{{entityName}} en uso por el incidente iniciado por el usuario",
            "alertSubscription": "{{entityName}} en uso por suscripción de [[alert]]",
            "savedMessage": "{{entityName}} en uso por mensaje guardado",
            "documentPermission": "{{entityName}} en uso con permiso de documento",
            "bulkUploadDefault": "{{entityName}} está predeterminado para carga masiva",
            "incidentCommunicationGroup": "{{entityName}} se utiliza en la configuración de comunicación de incidentes"
        },
        "noElementsFound": "No se encontraron elementos",
        "errorUpdatingPageStatus": "Error al actualizar el estado de la página"
    },
    "successMessages": {
        "saveSuccess": "{{name}} guardado",
        "removeSuccess": "{{name}} eliminado",
        "verifySuccess": "{{name}} verificado",
        "checkInSuccess": "{{name}} registrado",
        "declineSuccess": "{{name}} denegado",
        "approveSuccess": "{{name}} aprobado",
        "archiveSuccess": "{{name}} archivado",
        "unArchiveSuccess": "{{name}} restaurado",
        "deleteAttachmentSuccess": "El adjunto {{ docName }} fue eliminado.",
        "successUploadFileMessage": "El adjunto {{ docName }} está cargado"
    },
    "issue": {
        "issue": "[[Issue]]",
        "name": "Nombre",
        "description": "Descripción",
        "priority": "Prioridad",
        "dateOpened": "Fecha de apertura",
        "confirmRemoveHeader": "Eliminar [[Issue]]",
        "confirmResolveHeader": "Resolver [[Issue]]",
        "confirmResolve": "¿Está seguro de que quiere resolver este [[issue]]?",
        "resolveError": "No se ha podido resolver este [[issue]]. Inténtelo de nuevo más tarde.",
        "resolvedBy": "por",
        "hasBeenResolved": "Este [[issue]] ha sido resuelto.",
        "assignee": "Asignador",
        "resolutionDetails": "Detalle de la resolución",
        "resolveSuccess": "[[Issue]] ha sido resuelto.",
        "targetResolutionDate": "Fecha de resolución objetivo",
        "targetResolutionPastDue": "Vencido",
        "resolutionDate": "Fecha de resolución",
        "comments": "Comentarios",
        "initiatedBy": "Iniciado por",
        "resolve": "Resolver",
        "issuesFilterDesc": "Buscar...",
        "newIssue": "Nuevo [[Issue]]",
        "reopen": "Reabrir",
        "issueName": "Nombre de [[Issue]]",
        "assignedTo": "Asignado a",
        "opened": "Abierto",
        "targetResolution": "Resolución objetivo",
        "daysPastDue": "Días de retraso",
        "status": "Estado",
        "closed": "Resuelto",
        "open": "Abierto",
        "issues": "[[Issues]]",
        "notFoundError": "[[Issue]] no encontrado",
        "pastDue": "Vencido",
        "due": "Por vencer",
        "showResolvedIssues": "Mostrar [[Issues]] resueltos",
        "hideResolvedIssues": "Ocultar [[Issues]] resueltos",
        "issueWithSameNameError": "Un [[issue]] con el nombre {{ issueName }} ya existe.",
        "moveSuccess": "[[Issue]] movido",
        "moveError": "[[Issue]] no movido",
        "moveErrorDuplicateName": "[[Issue]] no movido porque un [[issue]] con el mismo nombre ya existe en el [[team]] de destino"
    },
    "item": {
        "item": "[[Item]]",
        "items": "[[Items]]",
        "scope": "[[Scope]]",
        "team": "[[Team]]",
        "name": "Nombre",
        "description": "Descripción",
        "type": "Tipo",
        "assignedTo": "Asignado a",
        "archived": "Archivado",
        "lastVerified": "Verificado por última vez",
        "nextVerification": "Siguiente verificación",
        "lastUpdated": "Última actualización",
        "lastPublished": "Última publicación",
        "verifiedStatus": "Estado",
        "nextUpdate": "Próxima actualización",
        "daysPastDue": "Días de retraso",
        "priority": "Prioridad",
        "status": "Estado",
        "itemType": "Tipo",
        "itemPriority": "Prioridad",
        "itemComplianceCat": "Categoría de cumplimiento",
        "complianceSchedule": "Calendario de cumplimiento",
        "storageInformation": "Información de almacenamiento",
        "associatedDocuments": "Documento asociado",
        "comments": "Comentarios",
        "primaryStorageLocation": "Ubicación de almacenamiento principal",
        "secondaryStorageLocation": "Ubicación de almacenamiento secundaria",
        "recoveryDestination": "Destino de recuperación",
        "addStorageLocation": "Agregar ubicación de almacenamiento",
        "editStorageLocation": "Editar ubicación de almacenamiento",
        "addComplianceCategory": "Agregar categoría de cumplimiento",
        "editComplianceCategory": "Editar categoría de cumplimiento",
        "createComplianceCategory": "Crear nueva categoría de cumplimiento",
        "appendComplianceCategory": "Agregar categoría de cumplimiento",
        "itemTypes": {
            "53e27db8-f119-4b73-9e84-a8b598fbd415": "Documento",
            "92c579b0-36cf-46d2-b006-77d2f962c0ce": "Almacenar [[Item]]",
            "2b727081-d9b1-4f92-b8b4-4e275fe9a396": "Tarea/recordatorio",
            "08b4edfc-1f95-425e-a24b-016ba5cad509": "Otro"
        },
        "newItem": "Nuevo [[Item]]",
        "verify": "Verificar",
        "establish": "Establecer",
        "itemsfilterPlaceholder": "Refinar los resultados por: Nombre, tipo o asignado al nombre",
        "documentAvailableForPlan": "Disponibilidad de [[Plan]]",
        "no": "No",
        "currentTeamOnly": "Solo [[Team]] actual",
        "allTeamsAndScopes": "Todos los [[Teams]] y [[Scopes]]",
        "itemWithSameNameError": "Un [[item]] con el nombre {{ itemName }} ya existe.",
        "storageWithSameNameError": "Una ubicación de almacenamiento con el nombre {{ storageName }} ya existe.",
        "confirmRemoveHeader": "Eliminar [[Item]]",
        "removeWithDocumentConfirm": "Eliminar este [[item]] también eliminará el documento asociado a [[item]]. ¿Está seguro de que quiere eliminar este [[item]]?",
        "dueDate": "Fecha de vencimiento",
        "itemWithoutDocumentWarning": "[[item]] no tiene un documento y no se puede verificar o registrar",
        "thisTeamOnly": "Solo este [[Team]]",
        "thisTeamAndSubTeams": "Este [[Team]] y Sub [[Teams]]",
        "allTeamsThisScope": "Todos los [[Teams]] este [[Scope]]",
        "allTeamsAllScopes": "Todos los [[Teams]] todos los [[Scopes]]",
        "moveSuccess": "[[Item]] movido",
        "moveError": "[[Item]] no movido",
        "moveErrorDuplicateName": "[[Item]] no movido porque un [[item]] con el mismo nombre ya existe en el [[team]] de destino",
        "export": {
            "allTeamMembers": "Todos los artículos",
            "displayedTeamMembers": "Artículos mostrados",
            "header": "Exportar artículos",
            "body": "¿Desea exportar solo los artículos {{ x }} mostrados o todos los [[team members]]?",
            "successMsg": "Exportación generada con éxito",
            "errorMsg": "Error al generar la exportación",
            "fileName": "BulkItemUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Artículos de carga masiva",
            "msgBulkUploadInstructions": "¿Tiene varios artículos que desea agregar o modificar? Para iniciar el [[process]], elija un archivo de su computadora que tenga el formato correcto.",
            "msgDropFile": "Suelte el archivo aquí o haga clic para seleccionar un archivo",
            "lblSelectedFile": "Archivo seleccionado para cargar",
            "lblNoFileSelected": "Ningún archivo especificado.",
            "lblNeedStartingPoint": "Necesita un punto de partida",
            "lblAddMultipleHeader": "Agregar varios artículos nuevos",
            "lblAddMultipleDetail": "Descargue esta hoja de cálculo que contiene solo la información del encabezado y complete la información de su nuevo artículo, un usuario por fila, antes de guardarla en formato .CSV en su computadora.",
            "btnBlankFile": "Archivo de artículo en blanco",
            "lblEditMultipleHeader": "Editar o eliminar varios artículos",
            "lblEditMultipleDetail": "Descargue esta hoja de cálculo que contiene la información de su artículo actual y modifíquela según sea necesario antes de guardarla en formato .CSV en su computadora.",
            "btnCurrent": "Artículos actuales",
            "lblEditMultipleNotes": "NOTA: Para eliminar artículos, cambie su estado a Eliminar. Eliminarlos de la hoja de cálculo no los eliminará del sistema. La carga masiva se puede completar incluso si no completa todos los campos de un artículo. Para ver qué campos son opcionales, consulte la guía del usuario o el área de ayuda en la esquina superior derecha de esta ventana.",
            "lblUpload": "Enviar",
            "btnCancel": "Cancelar",
            "errorUserExportFailed": "No se puede exportar un archivo de artículo.",
            "msgUserExportSuccessful": "La exportación del artículo se completó correctamente.",
            "msgTestBulkUploadFile": "Este archivo solo se validará porque el nombre del archivo contiene la palabra \"test\"; las actualizaciones no se procesarán.",
            "msgUploadSuccess": "Carga masiva exitosa.",
            "msgValidatingFile": "Verificando archivo...",
            "errorInvalidBulkFileType": "El archivo seleccionado tiene una extensión de archivo incorrecta: debe tener una extensión .CSV.",
            "errorExportFailed": "No se puede exportar un archivo de artículo.",
            "msgExportSuccessful": "Exportación completada con éxito."
        }
    },
    "itemDocument": {
        "dropFile": "Suelte el archivo aquí",
        "selectFile": "Seleccione el archivo",
        "selectFiles": "Seleccione los archivos",
        "uploadingFile": "Cargando archivo",
        "uploadDocument": "Cargar documento",
        "uploadDocuments": "Cargar documentos",
        "selectDocument": "Seleccionar documento",
        "uploadFile": "Elija un archivo de su computadora para cargarlo o arrastre y suelte su archivo en la ventana.",
        "confirmRemoveHeader": "Eliminar documento",
        "removeConfirm": "¿Está seguro de que desea eliminar este documento?",
        "homeFolderName": "Inicio",
        "saveSuccess": "Documento {{name}} guardado",
        "saveFail": "No se pudo guardar el documento",
        "removeSuccess": "Documento eliminado",
        "removeFail": "No se pudo eliminar el documento",
        "downloadDocument": "Descargar documento",
        "documentVersions": "Versiones de documentos",
        "removeDocument": "Eliminar documento",
        "createdDate": "Fecha de creación",
        "createdBy": "Creado por",
        "compare": "Comparar",
        "documentComparisonTypeNotSupport": "Solo se admiten documentos de Microsoft Word para la comparación.",
        "documentComparisonFailed": "No se pudieron comparar los documentos",
        "verified": "Verificado",
        "compared": " (comparado)",
        "noAttachments": "Sin Adjuntos"
    },
    "confirmMessages": {
        "saveConfirm": "¿Está seguro de que quiere guardar este {{name}}?",
        "removeConfirm": "¿Está seguro de que quiere eliminar este {{name}}?"
    },
    "removeAttachmentConfirmation": {
        "header": "Eliminar archivo adjunto",
        "body": "¿Está seguro de que quiere eliminar el archivo adjunto {{ docName }}?",
        "btnText": "Eliminar"
    },
    "team": {
        "teamName": "Nombre de [[Team]]",
        "responsibilities": "Responsabilidades",
        "mission": "Misión",
        "assemblyTimeObjective": "Objetivo de tiempo de ensamblaje (ATO)",
        "recoveryTimeObjective": "Objetivo de tiempo de recuperación (RTO)",
        "recoveryPointObjective": "Objetivo de punto de recuperación (RPO)",
        "confirmRemoveHeader": "Eliminar [[Team]]",
        "confirmSaveHeader": "Guardar [[Team]]",
        "teamFilterDesc": "Refinar los resultados por nombre de [[team]]",
        "newTeam": "Nuevo [[Team]]",
        "teamCountMessage": "Demostración {{ x }} de {{ y }} total",
        "confirmMoveMessage": "¿Está seguro de que quiere mover {{ childTeamName }} a {{ toTeamName }}?",
        "editTeams": "Editar [[Teams]]",
        "newTeamHeader": "Nuevo [[Team]]",
        "newTeamName": "Nuevo nombre de [[Team]]",
        "addTeam": "Añadir [[Team]]",
        "teamWithSameNameError": "Un [[team]] con el nombre {{ teamName }} ya existe.",
        "cannotRemoveError": "No se puede eliminar [[team]] con sub-[[teams]] activo o [[team members]]",
        "copyTeam": "Copiar [[Team]]",
        "copyTeamModal": {
            "header": "Copiar opciones de [[Team]]",
            "selectDestination": "Seleccionar destino [[scope]] o [[team]]",
            "selectCopyOptions": "Opciones",
            "copyTeamMembers": "Copiar todos los [[Team Members]]",
            "copyHierarchy": "Incluir Sub-[[teams]]",
            "deepCopy": "Copia de profundidad",
            "copyItems": "Copiar todos los [[Items]]",
            "copyIssues": "Copiar todos los [[Issues]]",
            "copyAssets": "Copiar todos los [[Assets]]",
            "copyExternalResources": "Copiar todos los [[External Resources]]",
            "copyTeamSuccess": "[[team]] {{teamName}} copiados exitosamente",
            "copyTeamError": "No se pudo copiar [[team]] {{teamName}}"
        },
        "comments": "Comentarios",
        "comment": {
            "saveError": "Comentario no guardado",
            "deleteError": "Comentario no eliminado"
        }
    },
    "help": {
        "scope": {
            "scopeName": "El nombre de [[scope]] es la agrupación más alta de [[teams]]. Debe ser único y está impreso en el encabezado de cada informe. Puede contener hasta 50 caracteres.",
            "mission": "La declaración de misión explica el propósito de [[scope]] y se imprime al comienzo del informe de declaración de misión. Puede contener hasta 4,000 caracteres.",
            "assumptions": "Los supuestos se imprimen en el informe de declaración de misión. Pueden contener hasta 4,000 caracteres.",
            "coordinatorName": "El nombre del coordinador es la persona responsable de la planificación de la recuperación de este [[scope]].  El nombre está impreso en la portada de cada [[plan]] de recuperación."
        },
        "team": {
            "teamName": "El nombre de [[team]] debe ser único dentro de cada [[scope]]. Puede contener hasta 50 caracteres",
            "mission": "La declaración de misión explica el propósito de [[team]]. También se muestra en el informe de misión de [[team]]. Puede contener hasta 4,000 caracteres.",
            "responsibilities": "Las responsabilidades describen los objetivos principales de [[team]]. Está impreso en el informe de misión de [[team]]. Puede contener hasta 4,000 caracteres.",
            "planHeader": "El encabezado de [[plan]] está impreso en la portada de [[plan]] de recuperación de [[team]]. Puede contener hasta 50 caracteres.",
            "assemblyTimeObjective": "El ATO es el tiempo transcurrido planificado entre la notificación de un evento y el montaje de [[team]]. El montaje de [[Team]] se define como el momento en el tiempo en el que [[team]] está posicionado para abordar su misión de recuperación y responsabilidades para sus usuarios internos y [[clients]] externos. Está representado en horas y minutos.",
            "recoveryTimeObjective": "El RTO es el tiempo transcurrido planificado entre la notificación de un evento y la operación de [[team]] a nivel de soporte a sus usuarios internos y [[client]]s externos. Está representado en horas y minutos.",
            "recoveryPointObjective": "El RPO es la cantidad máxima de tiempo antes de un evento en el que la pérdida de datos es aceptable. Los datos y la información esenciales deben restaurarse hasta al menos este momento. Está representado en horas y minutos. (Dejar en blanco si se deben restaurar todos los datos e información).",
            "copyTeamModal": {
                "copyHierarchy": "Copiar solo un [[team]] o [[team]] y todos los sub-[[teams]]",
                "deepCopy": "Copiar [[Team Members]] y tipos de recursos seleccionados ([[Assets]] ,[[Items]],[[Issues]], [[External Resources]]) para [[Teams]] copiados",
                "copyTeamMembers": "Los [[Team Members]] deben copiarse para recursos de afrontamiento ([[Assets]],[[Items]],[[Issues]], [[External Resources]])",
                "copyItems": "Copiar todos los [[Items]] para [[Teams]] copiados",
                "copyIssues": "Copiar todos los [[Issues]] para [[Teams]] copiados",
                "copyAssets": "Copiar todos los [[Assets]] para [[Teams]] copiados",
                "copyExternalResources": "Copie todos los [[External Resources]] para [[Teams]] copiados. Los [[External Resources]] deben ser copiados para poder copiar [[Assets]] como un [[Asset]] puede depender de [[External Resource]]."
            }
        },
        "teamMember": {
            "member": "[[Member]] representa la cuenta de usuario que pertenece a este [[team member]].",
            "assignment": "La asignación representa las responsabilidades de este [[team member]] en [[team]].",
            "planCount": "La cantidad de copias de [[plan]] que se han emitido a este [[team member]].",
            "teamPlanCount": "La cantidad de copias del [[plan]] de [[team]] que se han enviado a este [[team member]].",
            "planIssueDate": "La fecha en que las copias de [[plan]] fueron emitidas a este [[team member]].",
            "teamPlanIssueDate": "La fecha en que las copias de [[plan]] del [[team]] fueron emitidas a este [[team member]].",
            "isResponsible": "Esta persona es responsable de [[team]].",
            "expired": "Vencido, necesita verificación.",
            "verified": "Verificado"
        },
        "issue": {
            "descriptionTooltip": "La descripción de [[issue]] puede contener hasta 512 caracteres.",
            "assigneeTooltip": "El cesionario es responsable de que [[issue]] se resuelva.",
            "targetResolutionDateTooltip": "La fecha de resolución objetivo es la fecha en que [[issue]] necesita ser resuelto.",
            "resolutionDateTooltip": "La fecha de resolución es la fecha en que [[issue]] fue marcado como resuelto.",
            "priorityTooltip": "La prioridad es el nivel en que este [[issue]] afecta negativamente el esfuerzo de recuperación.",
            "nameTooltip": "El nombre identifica el [[issue]] y debe ser único por [[team]].",
            "dateOpened": "La fecha de apertura es cuando este [[issue]] fue registrado.",
            "initiatedBy": "El usuario que inició el [[issue]]."
        },
        "externalResource": {
            "externalResourceName": "Nombre de una compañía, ubicación o persona ajena a la organización. Puede contener 50 caracteres de longitud.",
            "typeTooltip": "Tipo de compañía externa, ubicación o persona ajena a la organización.",
            "isCritical": "¿Es este [[external resource]] crítico para el [[process]] de recuperación?",
            "isRecoveryLocation": "¿Es este [[external resource]] una ubicación de recuperación?",
            "addExternalResourceType": "Agregar un nuevo tipo de [[external resource]]",
            "editExternalResourceType": "Editar tipo de [[external resource]]",
            "compareWithVerified": "Comparar la última versión guardada con la versión verificada"
        },
        "item": {
            "primaryStorageLocationTooltip": "La ubicación de almacenamiento principal es el lugar previsto para almacenar el [[item]] de recuperación.",
            "secondaryStorageLocationTooltip": "La ubicación de almacenamiento secundaria es un lugar adicional para almacenar el [[item]] de recuperación.",
            "recoveryDestinationTooltip": "El destino de recuperación es el lugar para reubicar el [[item]] de almacenamiento durante una emergencia.",
            "documentAvailableForPlanTooltip": "Indica el nivel en el que este documento [[item]] está disponible para ser utilizado en [[plans]] para otros [[teams]] y/o [[scopes]].",
            "nameTooltip": "El nombre identifica la recuperación del [[item]] y debe ser único por [[team]]. Puede contener hasta 50 caracteres.",
            "descriptionTooltip": "La descripción de la recuperación del [[item]] puede contener hasta 512 caracteres.",
            "itemTypeTooltip": "Tipo de recuperación de [[item]].",
            "itemPriorityTooltip": "Prioridad de la recuperación de [[item]] en caso de emergencia.",
            "addStorageLocationInstructions": "Identifique y describa la nueva ubicación de almacenamiento.",
            "editStorageLocationInstructions": "Vuelva a identificar o describa la ubicación de almacenamiento existente.",
            "addComplianceCategoryInstructions": "Identifique y describa la nueva categoría de cumplimiento.",
            "editComplianceCategoryInstructions": "Vuelva a identificar o describa la categoría de cumplimiento existente.",
            "compareWithVerified": "Comparar la última versión guardada con la versión verificada"
        },
        "asset": {
            "nameTooltip": "El nombre de [[asset]] es necesario en el esfuerzo de recuperación. Puede contener hasta 50 caracteres.",
            "assetLocationSiteTooltip": "Lugar donde es necesario ubicar el [[asset]] durante el esfuerzo de recuperación.",
            "compareWithVerified": "Comparar la última versión guardada con la versión verificada"
        },
        "assetAllocation": {
            "allocationCountTooltip": "Recuento de activos necesarios en el sitio de recuperación dentro de un período asociado.",
            "requirementTypeTooltip": "Marco de tiempo para reunir los activos necesarios en el sitio de recuperación."
        },
        "establishByCard": {
            "dateCreatedTooltip": "Fecha en que este recurso se definió por primera vez.",
            "establishByDateTooltip": "Establecer la existencia de este recurso para esta fecha."
        },
        "planStructure": {
            "planHeader": "El contenido de este campo está impreso en la portada de cada [[plan]] de recuperación que se genera para [[scope]]. ",
            "compareWithVerified": "Comparar la última versión guardada con la versión verificada",
            "planCover": "Esta área puede utilizarse para anular el texto que aparece en la portada de [[plan]] de recuperación predeterminado. Al anular el texto de la portada de [[plan]], es posible que deba insertar saltos de línea duros al comienzo de esta área (presionando la tecla Enter) para centrar el texto entre los campos de la portada de [[plan]] y la fecha de [[plan]]. Esta área puede contener un máximo de 4,000 caracteres de información. Si esta área está vacía, se utilizará el texto de portada de [[plan]] del sistema predeterminado.",
            "useFirstDocumentAsCoverPage": "Se le permite proporcionar su propia portada al [[plan]] que se genera. Si selecciona 'Sí', el primer documento en la estructura de su [[plan]] se utilizará como portada."
        },
        "notificationSetting": {
            "verificationFrequencyTooltip": "Seleccionar la frecuencia de verificación para tomar como objetivo con esta notificación",
            "notificationOverdueValueTooltip": "Especificar el valor de tiempo después de la fecha de vencimiento para activar esta notificación",
            "notificationDueSoonValueTooltip": "Especificar el valor de tiempo antes de la fecha de vencimiento para activar esta notificación",
            "iQEmailFromNameTooltip": "Especificar el valor de nombre del remitente para todos los correos electrónicos de notificación",
            "datepartTypeTooltip": "Especificar la unidad de tiempo para el valor anterior",
            "jobTypeTooltip": "Seleccionar el tipo de notificación a enviar",
            "editOverdueNotificationSetting": "Editar la [[setting]] de notificación de vencimiento para actividades {{ a }}",
            "addOverdueNotificationSetting": "Agregar una [[setting]] de notificación de vencimiento para actividades {{ a }}",
            "editDueSoonNotificationSetting": "Editar la [[setting]] de notificación de vence pronto para actividades {{ a }}",
            "addDueSoonNotificationSetting": "Agregar la [[setting]] de notificación de vence pronto para actividades {{ a }}"
        },
        "complianceCategory": {
            "nameTooltip": "El nombre identifica la categoría de cumplimiento y debe ser único en todo el sistema. Puede contener hasta 50 caracteres.",
            "descriptionTooltip": "La descripción de la categoría de cumplimiento puede contener hasta 512 caracteres.",
            "complianceCatTooltip": "Categoría de cumplimiento que requiere este recurso de recuperación. Seleccionar todas las que correspondan."
        },
        "storageLocation": {
            "nameTooltip": "El nombre identifica la ubicación de almacenamiento y debe ser único en todo el sistema. Puede contener hasta 50 caracteres.",
            "descriptionTooltip": "La descripción de la ubicación de almacenamiento puede contener hasta 512 caracteres."
        },
        "externalResourceType": {
            "nameTooltip": "El nombre identifica el tipo de [[external resource]] y debe ser único en todo el sistema. Puede contener hasta 50 caracteres.",
            "descriptionTooltip": "La descripción del tipo de [[external resource]] puede contener hasta 512 caracteres."
        },
        "incident": {
            "incidentAttachmentsTooltip": "Adjuntar documentos al incidente."
        }
    },
    "done": "Realizado",
    "finished": "Terminado",
    "scopeTeamModal": {
        "header": "Haga su selección",
        "requireScopeDescription": "Seleccionar un [[Scope]] para continuar. Esto afectará los [[teams]] que puede ver. No se preocupe. Puede cambiar esto más tarde.",
        "requireTeamDescription": "Seleccione un [[Team]] para continuar. Esto afectará los recursos de [[team]] que puede ver. No se preocupe. Puede cambiar esto más tarde.",
        "requireScopeIdAndTeamIdError": "Haga sus selecciones antes de continuar."
    },
    "newTeamModal": {
        "header": "Crear nuevo [[Team]]",
        "confirmButtonText": "Continuar"
    },
    "complianceSchedule": {
        "verify": "Verificar",
        "lastUpdated": "Verificado por última vez",
        "lastUpdatedTooltip": "Verificado por última vez representa la fecha en que ocurrió la última verificación.",
        "nextUpdate": "Siguiente verificación",
        "nextUpdateTooltip": "La siguiente verificación representa la fecha en la que debería ocurrir la próxima verificación.",
        "teamMember": "[[Team Member]]",
        "teamMemberTooltip": "[[Team Member]] que es el principal responsable de verificar la disponibilidad del recurso. Los [[Team Members]] en esta lista ya deben estar verificados.",
        "updateFrequency": "Frecuencia de actualización",
        "updateFrequencyTooltip": "La frecuencia de actualización representa el intervalo de tiempo entre cada verificación.",
        "notVerified": "Nunca ha sido verificado. Por favor, verifíquelo.",
        "updateFrequencyOptions_7": "Semanal",
        "updateFrequencyOptions_30": "Mensual",
        "updateFrequencyOptions_31": "Mensualmente el último día del mes",
        "updateFrequencyOptions_90": "Trimestral",
        "updateFrequencyOptions_91": "Trimestralmente el último día del mes",
        "updateFrequencyOptions_180": "Semianual",
        "updateFrequencyOptions_181": "Semianual el último día del mes",
        "updateFrequencyOptions_365": "Anual",
        "updateFrequencyOptions_365000": "N/A",
        "complianceSchedule": "Calendario de cumplimiento",
        "updateLastVerified": "Actualizar la fecha de la última verificación",
        "updateLastVerifiedTooltip": "Actualizar la fecha de la última verificación",
        "nextVerificationDateBeforeLastVerified": "La próxima fecha de verificación no puede ser anterior a la última fecha de verificación. Ajuste de la fecha de inicio del intervalo de verificación."
    },
    "comments": {
        "addComment": "Añadir {{ name }}",
        "newComment": "Nuevo {{ name }}",
        "on": "en",
        "deleteComment": "Eliminar",
        "deleteCommentHeader": "Eliminar {{ name }}",
        "deleteCommentBody": "¿Está seguro de que quiere eliminar este {{ name }}?"
    },
    "select": "Seleccione",
    "iQ": "iQ",
    "No": "No",
    "new": "Nuevo",
    "change": "cambio",
    "scopeTeamModalTooltip": "Cambio de [[Scope]] y/o [[Team]]",
    "ComplianceCategoriesSelector": {
        "complianceCategory": "Categoría de cumplimiento",
        "complianceCategoryTooltip": "Categoría de cumplimiento",
        "createComplianceCategory": "+",
        "complianceCategoryWithSameNameError": "Ya existe una categoría de cumplimiento con el nombre {{ name }}."
    },
    "iqPriorities": {
        "086deec5-b113-4cc9-8024-d1f1ddb62e8d": "Crítico",
        "0b75aa95-3100-43b0-9cf7-560fb4607a47": "Alto",
        "31b51337-4a66-4cce-9c54-0daa8c6831c9": "Medio",
        "b7e4e27d-b0de-4936-858a-3fa07e0f3b68": "Bajo"
    },
    "view": "Ver",
    "noComplianceCategory": "Sin categoría de cumplimiento",
    "noItemsPopoverText": "Sin [[Items]]",
    "externalResource": {
        "scopeAssignment": "Asignación de [[Scope]]",
        "resourceName": "Nombre del recurso",
        "type": "Tipo",
        "assignedTo": "Asignado a",
        "recoveryLocationSite": "Sitio de recuperación",
        "verifiedDate": "Fecha verificada",
        "status": "Estado",
        "externalResources": "[[External Resources]]",
        "externalResourcesFilterPlaceholder": "Refinar los resultados por: Nombre del recurso o Asignado a",
        "newExternalResource": "Nuevo [[External Resource]]",
        "externalResource": "[[External Resource]]",
        "externalResourceName": "Nombre de [[External Resource]]",
        "isCritical": "Este es un recurso de crisis",
        "isRecoveryLocation": "Este es un sitio de recuperación",
        "contacts": "Contactos",
        "resourceDetails": "Detalles del recurso",
        "comments": "Comentarios",
        "address": "Calle",
        "city": "Ciudad",
        "state": "Estado",
        "zip": "Código postal",
        "country": "País",
        "webSite": "Sitio web",
        "primary": "Primario",
        "name": "Nombre",
        "phone": "Teléfono",
        "email": "Correo electrónico",
        "addContact": "Agregar contacto",
        "firstName": "Nombre",
        "lastName": "Apellido",
        "confirmRemoveHeader": "Eliminar [[External Resource]]",
        "removeConfirm": "¿Está seguro de que quiere eliminar este [[external resource]]?",
        "addType": "Añadir tipo de [[External Resource]]",
        "editType": "Editar tipo de [[External Resource]]",
        "externalResourceTypeDescription": "Descripción",
        "externalResourceTypeName": "Nombre",
        "verify": "Verificar",
        "establish": "Establecer",
        "contact": "Contacto",
        "usedByAssetsAsLocationSite": "Este [[external resource]] es utilizado actualmente por [[assets]] como sitio de ubicaciones",
        "confirmRemoveContactHeader": "Eliminar contacto",
        "confirmRemoveContactBody": "¿Está seguro de que desea eliminar este contacto?",
        "primaryContact": "Contacto primario",
        "update": "Actualizar",
        "confirmNewPrimaryContactHeader": "Actualizar contacto principal",
        "confirmNewPrimaryContactBody": "Ya existe un contacto principal. Este contacto será el nuevo contacto principal.",
        "lastVerified": "Verificado por última vez",
        "nextVerification": "Siguiente verificación",
        "daysPastDue": "Días de retraso",
        "dueDate": "Fecha de vencimiento",
        "externalResourceWithSameNameError": "Un [[external resource]] con el nombre {{ externalResourceTypeName }} ya existe.",
        "getExternalResourceTypesError": "Error al recuperar tipos de [[external resource]]",
        "filterExternalResourceTypesDescription": "Refinar resultados por nombre",
        "newExternalResourceType": "Nuevo tipo de [[External Resource]]",
        "externalResourceType": "Tipo de [[External Resource]]",
        "externalResourceTypes": "Tipos de [[External Resource]]",
        "confirmRemoveExternalResourceType": {
            "header": "Eliminar tipo de [[External Resource]]",
            "body": "¿Está seguro de que quiere eliminar este {{ objectType }} llamado {{ name }}?",
            "text": "Eliminar"
        },
        "confirmSaveExternalResourceType": {
            "header": "Guardar tipo de [[External Resource]]",
            "body": "¿Está seguro de que quiere guardar este {{ objectType }} llamado {{ name }}?",
            "text": "Guardar"
        },
        "externalResourceTypeAssignedToResourceError": "No se puede eliminar {{ name }} con [[external resources]] asignados a este tipo",
        "moveSuccess": "[[External Resource]] movido",
        "moveError": "[[External Resource]] no movido",
        "moveErrorDuplicateName": "[[External Resource]] no movido porque un [[external resource]] con el mismo nombre ya existe en el [[team]] de destino",
        "moveTeamTooltip": "Seleccione el [[team]] de destino. Si un [[external resource]] se define como un sitio de recuperación y tiene un activo asignado, solo se puede mover a [[team]]s dentro del mismo ámbito."
    },
    "asset": {
        "asset": "[[Asset]]",
        "assetName": "Nombre de [[Asset]]",
        "assignedTo": "Asignado a",
        "storageLocation": "Ubicación de almacenamiento",
        "verifiedDate": "Fecha verificada",
        "status": "Estado",
        "assetFilterPlaceHolder": "Refinar los resultados por: Nombre de [[Asset]] o asignado a",
        "newAsset": "Nuevo [[Asset]]",
        "verify": "Verificar",
        "establish": "Establecer",
        "locationSite": "Sitio de recuperación",
        "complianceSchedule": "Calendario de cumplimiento",
        "assetRequirement": "Requisito de [[Asset]]",
        "allocation": {
            "assetRequirement": "Requisito de [[Asset]]",
            "allocationCount": "Contar",
            "allocationType": "Tipo",
            "confirmRemoveHeader": "Eliminar distribución de asignación",
            "confirmRemoveMessage": "¿Está seguro de que desea eliminar este requisito de [[asset]] de contar \"{{ x }}\" y tipo \"{{ y }}\"?",
            "add": "Agregar requisito"
        },
        "comments": "Comentarios",
        "comment": {
            "saveError": "Comentario no guardado",
            "deleteError": "Comentario no eliminado"
        },
        "saveSuccess": "[[Asset]] guardado",
        "saveError": "[[Asset]] no guardado",
        "confirmRemoveHeader": "Eliminar [[Asset]]",
        "confirmRemoveMessage": "¿Está seguro de que quiere eliminar este {{ name }}?",
        "lastVerified": "Verificado por última vez",
        "nextVerification": "Siguiente verificación",
        "daysPastDue": "Días de retraso",
        "assetWithSameNameError": "Un [[asset]] con el nombre {{ assetName }} ya existe.",
        "dueDate": "Fecha de vencimiento",
        "moveSuccess": "[[Asset]] movido",
        "moveError": "[[Asset]] no movido",
        "moveErrorDuplicateName": "[[Asset]] no movido porque un [[asset]] con el mismo nombre ya existe en el [[team]] de destino",
        "moveRecoverySiteTooltip": "Seleccione el sitio de recuperación. Si un [[asset]] se mueve a un [[team]] que pertenece a un ámbito diferente, se debe seleccionar un nuevo sitio de recuperación dentro de ese ámbito."
    },
    "userAssignment": {
        "title": "Asignaciones",
        "teamMembers": "[[Team Members]]",
        "items": "[[Items]]",
        "issues": "[[Issues]]",
        "externalResources": "[[External Resources]]",
        "assets": "[[Assets]]"
    },
    "yes": "Sí",
    "no": "No",
    "allowed": "Permitido",
    "notAllowed": "No permitido",
    "confirmDialog": {
        "confirmSaveAndVerify": {
            "header": "Guardar Verificar {{ objectType }}",
            "body": "Tiene cambios sin guardar en la página. ¿Quiere guardar y verificar el {{ name }}?",
            "text": "Guardar y verificar"
        },
        "confirmVerify": {
            "header": "Verificar {{ objectType }}",
            "body": "¿Quiere verificar el {{ name }}?",
            "text": "Verificar"
        },
        "confirmEstablish": {
            "header": "Establecer {{ objectType }}",
            "body": "¿Quiere establecer el {{ name }}?",
            "text": "Establecer",
            "establishedDateInPast": "La fecha seleccionada para Establecer por es una fecha pasada. Cuando guarde el {{ name }} se establecerá en {{ date }}."
        },
        "confirmApprove": {
            "header": "Aprobar {{ objectType }}",
            "body": "¿Quiere aprobar el {{ name }}?",
            "text": "Aprobar"
        },
        "confirmCheckIn": {
            "header": "Registrar {{ objectType }}",
            "body": "¿Quiere registrar el {{ name }}?",
            "text": "Registrar"
        },
        "confirmDecline": {
            "header": "Denegar {{ objectType }}",
            "body": "¿Quiere denegar el {{ name }}?",
            "text": "Denegar"
        },
        "remove": {
            "header": "Eliminar {{ name }}",
            "body": "¿Está seguro de que quiere eliminar este {{ name }}?",
            "text": "Eliminar"
        },
        "confirmSave": {
            "header": "Guardar {{ objectType }}",
            "body": "¿Quiere guardar el {{ name }}?",
            "text": "Guardar"
        },
        "confirmUpdateLastVerified": {
            "header": "Actualizar la última fecha verificada",
            "body": "¿Quiere actualizar la fecha de la última verificación?",
            "text": "Actualizar"
        },
        "confirmUpdateLastVerifiedDateOutOfOrder": {
            "header": "Actualizar la última fecha verificada",
            "body": "La próxima fecha de verificación no puede ser anterior ni igual a la fecha de la última verificación. Se ajustará la fecha de inicio del intervalo de verificación.",
            "text": "Actualizar"
        },
        "confirmAssignToSelf": {
            "header": "Asignar {{ objectType }} a Mí",
            "body": "¿Quiere asignar \"{{ name }}\" a usted?",
            "text": "Asignar"
        },
        "confirmArchiveResource": {
            "header": "Archivar {{ objectType }}",
            "body": "¿Quiere archivar \"{{ name }}\"?",
            "text": "Archivar"
        },
        "confirmUnArchiveResource": {
            "header": "Restaurar {{ objectType }}",
            "body": "¿Quiere restaurar \"{{ name }}\"?",
            "text": "Restaurar"
        },
        "confirmUnsavedChanges": {
            "header": "Cambios no guardados",
            "body": "Tiene cambios sin guardar en esta página. ¿Está seguro de que quiere descartarlos?",
            "text": "Descartar los cambios"
        }
    },
    "rowCountMessage": "Demostración {{ x }} de {{ y }} total",
    "noRowCountMessage": "Mostrando 0 de 0 total",
    "more": "Más",
    "all": "Todas",
    "readMore": "Leer más",
    "readLess": "Leer menos",
    "common": {
        "scope": "[[Scope]]",
        "team": "[[Team]]",
        "complianceCategory": "Categoría de cumplimiento",
        "search": "Buscar",
        "comment": "Comentario",
        "noComments": "No hay comentarios disponibles",
        "noAttachments": "Sin Adjuntos",
        "note": "Nota",
        "edit": "Editar",
        "deselect": "Deseleccionar",
        "name": "Nombre",
        "save": "Guardar",
        "add": "Añadir",
        "requireApproval": "Requiere aprobación",
        "checkIn": "Registrar",
        "decline": "Denegar",
        "approve": "Aprobar",
        "type": "Tipo",
        "approvedBy": "Aprobado por {{ name }}",
        "checkedInBy": "Comprobado por {{ name }}",
        "declinedBy": "Denegado por {{ name }}",
        "msgFileExceedMaxSize": "El tamaño del archivo supera el límite máximo de {{ size }} MB",
        "msgErrorDownloadFile": "No se pudo descargar el archivo adjunto {{ fileName }}",
        "msgErrorAttachmentExists": "El archivo adjunto con el mismo nombre ya existe, ¿le gustaría reemplazar el archivo adjunto existente o guardarlo como un nuevo archivo adjunto?",
        "msgAttachmentWithSameNameExist": "Ya existe un archivo adjunto con el mismo nombre de archivo.",
        "msgVirusDetected": "Virus detectado en este archivo",
        "msgFailProcessFile": "No se puede procesar el archivo, inténtelo de nuevo.",
        "msgFailCloudmersiveAPI": "No se puede validar el archivo, inténtelo de nuevo más tarde.",
        "geolocations": "Geolocalizaciones",
        "next": "Siguiente",
        "previous": "Anterior",
        "no": "No",
        "yes": "Sí",
        "cancel": "Cancelar",
        "accept": "Aceptar",
        "close": "Cerrar",
        "copy": "Copiar",
        "back": "Volver",
        "roles": "Roles",
        "or": "o",
        "ok": "OK",
        "more": "más",
        "finish": "Terminar",
        "andAreIn": "y están en",
        "tenants": "Inquilinos",
        "groups": "Grupos",
        "departments": "[[Departments]]",
        "locations": "Ubicaciones",
        "everyone": "Todos",
        "noSearchResult": "Sin resultado de búsqueda",
        "role": "Rol",
        "group": "Grupo",
        "tenant": "Inquilino",
        "department": "[[Department]]",
        "location": "[[Location]]",
        "entities": "Entidades",
        "enabled": "Habilitado",
        "disabled": "Deshabilitado",
        "date": "Fecha",
        "time": "Hora",
        "timezone": "Zona horaria",
        "remove": "Eliminar",
        "others": "Otros",
        "submit": "Enviar",
        "update": "Actualizar",
        "confirmation": "Confirmar",
        "sms": "SMS",
        "email": "Correo electrónico",
        "default": "Defecto",
        "pageSize": "Tamaño de página",
        "pageOptionShowAll": "Mostrar todo",
        "prev": "Anterior",
        "deleted": "Eliminado",
        "attachments": "Archivos adjuntos",
        "assignToSelf": "Asignar a mí",
        "archive": "Archivar",
        "delete": "Eliminar",
        "selected": "Seleccionado",
        "includeArchived": "Incluir archivados",
        "archived": "Archivado",
        "restore": "Restaurar",
        "loadMore": "Cargar más",
        "all": "Todas",
        "unknown": "Desconocido",
        "_emptyString": "",
        "reload": "Volver a cargar",
        "reloading": "Volviendo a cargar",
        "showAdvancedFilter": "Mostrar filtro avanzado",
        "showSimpleFilter": "Ocultar filtro avanzado",
        "filterStartDate": "Fecha de inicio",
        "filterEndDate": "Fecha de finalización",
        "listSortAriaLabel": "Activar para ordenar",
        "listSortAriaLabelDesc": "Activar para ordenar en orden descendente",
        "listSortAriaLabelAsc": "Activar para ordenar en orden ascendente",
        "lblNA": "N/A",
        "defaultLocation": "[[Location]] predeterminada",
        "both": "Ambos",
        "displayingXOutOfY": "Visualización de {{displaying}} fuera del {{total}}",
        "additionalLocations": "Ubicaciones adicionales",
        "multiple": "Múltiple",
        "lblNewFolder": "Crear nueva carpeta",
        "lblUploadFile": "Cargar documento",
        "lblUploadFiles": "Cargar documentos",
        "lblSelectFile": "Seleccionar documento",
        "lblDeleteDocument": "Eliminar documento",
        "lblDeleteFolder": "Eliminar carpeta",
        "lblMoveDocument": "Mover documento",
        "lblMoveFolder": "Mover carpeta",
        "lblBookmarkDocument": "Marcar documento",
        "lblDownloadDocument": "Descargar documento",
        "lblBookmarkName": "Nombre del marcador",
        "lblNewDocumentBookmark": "Crear marcador de documento",
        "lblNewPageBookmark": "Crear marcador de página",
        "msgSuccessAddBookmark": "Marcador {{ name }} creado con éxito",
        "msgErrorAddBookmark": "No se pudo crear el marcador {{ name }}",
        "completed": "Completado el {{date}}",
        "bookmark": "Marcador",
        "document": "Documento",
        "refresh": "Refrescar",
        "showingXofX": "Demostración {{count}} de {{total}}",
        "enterprise": "Proyecto",
        "realEstate": "Bienes raíces",
        "undo": "Deshacer",
        "new": "Nuevo",
        "export": "Exportar",
        "createdOn": "Creado el",
        "lastModifiedOn": "Última modificación el",
        "Required": "(requerido)",
        "lastModifiedBy": "Última modificación por",
        "createdBy": "Creado por",
        "selectOne": "Seleccione uno",
        "create": "Crear",
        "upload": "Cargar",
        "resetTable": "Restablecer tabla",
        "msgSecurityErrorTitle": "Error de seguridad",
        "msgSecurityErrorMessage": "No tiene permiso para acceder a la página solicitada",
        "msgBcIndustryErrorMessage": "Su compañía no tiene una industria especificada para la planificación de la continuidad del negocio",
        "msgBcAssignedProcessErrorMessage": "No tiene ningún [[process]] asignado para este [[department]]",
        "msgBcAssignedDepartmentErrorMessage": "No tiene ningún [[department]] asignado",
        "msgBcAssignedFunctionalAreasErrorMessage": "No tiene ningún [[department]] asignado",
        "msgBcPlanErrorMessage": "No existe un [[plan]] de continuidad empresarial creado para la planificación de la continuidad empresarial",
        "msgNoAssignments": "No tiene asignaciones para el [[plan]] de continuidad empresarial",
        "msgGeneralErrorMessage": "Error del sistema. Por favor, inténtelo de nuevo más tarde.",
        "msgNoIncidentErrorMessage": "No hay ningún incidente activo asociado a los [[plans]] de activación.",
        "msgRequireOpenIncidentErrorMessage": "Este incidente está resuelto.",
        "msgRequireResolvedIncidentErrorMessage": "Este incidente sigue activo.",
        "msgBcManagerErrorMessage": "Su compañía no tiene un gerente de continuidad del negocio especificado para la planificación.",
        "deactivated": "Desactivado",
        "active": "Activo",
        "backToTop": "Volver arriba",
        "none": "Ninguno",
        "move": "Mover",
        "preview": "Vista previa",
        "feet": "Pies",
        "mile": "Milla",
        "meter": "Metro",
        "kilometer": "Kilómetro",
        "height": "Altura",
        "width": "Ancho",
        "today": "Hoy",
        "showMore": "Mostrar más...",
        "showLess": "Mostrar menos",
        "miles": "Millas",
        "meters": "Metros",
        "kilometers": "Kilómetros",
        "exit": "Salir",
        "exitSave": "Salir y guardar",
        "members": "Miembros",
        "dashboard": "Tablero de control",
        "assign": "Asignar",
        "delegate": "Delegar",
        "unassign": "Anular asignación",
        "unassigned": "Sin asignar",
        "rename": "Renombrar",
        "loading": "Cargando...",
        "started": "Empezado",
        "status": "Estado",
        "revisit": "Volver a visitar",
        "continue": "Continuar",
        "start": "Comenzar",
        "navigate": "Navegar",
        "resend": "Reenviar",
        "view": "Ver",
        "help": "Ayuda",
        "closed": "Cerrado",
        "open": "Abierto",
        "title": "Título",
        "owner": "Propietario",
        "description": "Descripción",
        "issues": "[[Issues]]",
        "reports": "[[Reports]]",
        "generate": "Generar",
        "download": "Descargar",
        "generateAll": "Generar todos los [[plans]]",
        "downloadAll": "Descargar todo",
        "clearFilters": "Borrar filtros",
        "inactive": "Inactivo",
        "noRecords": "No hay registros",
        "created": "Creado",
        "updated": "Actualizado",
        "removed": "Quitado",
        "saved": "Guardado",
        "downloading": "Descargando...",
        "uploading": "Subiendo...",
        "deleting": "Eliminando...",
        "chart": "Cuadro",
        "descriptions": "Descripciones",
        "end": "Fin",
        "never": "Nunca",
        "on": "Acerca de",
        "until": "Hasta",
        "at": "En",
        "assigned": "Asignado",
        "hide": "Esconder",
        "unhide": "Mostrar",
        "showHidden": "Mostrar oculto",
        "comments": "Comentarios",
        "criticalDate": "Fecha crítica",
        "strategy": "Estrategia",
        "goToDetails": "Ir a detalles",
        "getStarted": "Comenzar",
        "viewDetails": "Ver detalles",
        "clear": "Eliminar",
        "apply": "Aplicar",
        "empty": "Vacío",
        "aToZ": "De la A a la Z",
        "recovered": "Recuperado",
        "notActivated": "No está activado",
        "saveAndClose": "Guardar y cerrar",
        "nA": "N/A",
        "saving": "Guardando",
        "translate": "Traducir",
        "address": "Dirección",
        "state": "Estado",
        "zip": "Código postal",
        "country": "País",
        "city": "Ciudad",
        "primary": "Principal",
        "contacts": "Contactos",
        "phone": "Teléfono",
        "website": "Sitio web",
        "select": "Seleccionar",
        "language": {
            "en": "English",
            "es": "Español",
            "fr": "Francés",
            "pt": "Portugués"
        },
        "reset": "Reiniciar",
        "hours": "Horas",
        "days": "Días",
        "day": "Día",
        "hour": "Hora",
        "original": "Original",
        "current": "Actual",
        "edits": "Ediciones",
        "expiration": "Vencimiento",
        "copyToClipboard": "Copiar al portapapeles",
        "addLocation": "Agregar [[location]]",
        "addDependency": "Agregar dependencia",
        "survey": "Encuesta",
        "system": "Sistema",
        "dependencies": "Dependencias",
        "processes": "[[Processes]]",
        "thereArentMoreItemsAvailable": "No hay más elementos disponibles para seleccionar.",
        "loadingMap": "Cargando mapa...",
        "success": "Éxito",
        "schedule": "Calendario",
        "updatedBy": "Actualizado por",
        "updatedDate": "Fecha de actualización",
        "launch": "Lanzamiento",
        "resolved": "Resuelto",
        "selectedItemsLabel": "{0} elementos seleccionados",
        "support": "Asistencia",
        "low": "Bajo",
        "medium": "Medio",
        "high": "Alto",
        "formula": "Fórmula",
        "company": "Empresa",
        "continueConfirmation": "¿Quiere continuar?",
        "target": "Objetivo",
        "approver": "Aprobador",
        "dueDate": "Fecha límite",
        "recipient": "Receptor",
        "searchResults": "Resultados de búsqueda",
        "headerLabel": "Seleccionar usuario",
        "searchUserLabel": "Buscar usuario",
        "currentUserLabel": "Usuario actual",
        "resultLabel": "Resultado",
        "notAvailable": "No disponible",
        "abandoned": "Abandonado",
        "abandon": "Abandonar",
        "waitingApproval": "Esperando aprobación",
        "selectColumns": "Seleccionar columnas",
        "msgrExportSuccessful": "La exportación {{targetType}} se completó correctamente.",
        "awaitingApproval": "Esperando aprobación",
        "inProgress": "En Curso",
        "pastDue": "Vencido",
        "closedOn": "Fecha de cierre",
        "errorExportFailed": "No se puede exportar el archivo {{targetType}}.",
        "unAssociate": "Desasociar",
        "associate": "Asociar",
        "approved": "Aprobado",
        "assessments": "Evaluaciones",
        "paginationReport": "Mostrando {first} a {last} de {{totalRowCount}}",
        "overTime": "A lo largo del tiempo",
        "alternates": "suplentes",
        "authority": "Autoridad",
        "triggers": "disparadores",
        "limitations": "Limitaciones",
        "primaries": "Primaria(s)",
        "delegates": "delegados",
        "itemsToComplete": "Elementos para completar",
        "details": "Detalles",
        "duplicate": "Duplicar",
        "available": "Disponible",
        "heading": "Título",
        "body": "Cuerpo",
        "revert": "Revertir",
        "reverted": "revertido",
        "publish": "Publicar",
        "draft": "Borrador",
        "version": "Versión",
        "published": "Publicado",
        "landscape": "Paisaje",
        "portrait": "Retrato",
        "enterTitle": "Introduzca el título aquí",
        "enterDescription": "Ingrese la descripción aquí",
        "tags": "Etiquetas",
        "orientation": "Orientación",
        "pageBreak": "Salto de página",
        "generalOptions": "Opciones Generales",
        "promote": "Promover",
        "markCompleteContinue": "Marcar como completo",
        "sendEmailContinue": "Enviar correo electrónico & Continuar",
        "keyPersonnel": "Personal clave",
        "responseTeams": "Equipos de respuesta",
        "buildManage": "Construir",
        "track": "Pista",
        "skip": "Saltar",
        "missionStatement": "Estado de la misión",
        "introduction": "Introducción",
        "discard": "Desechar",
        "general": "General",
        "makeSystem": "Hacer Sistema",
        "annex": "Anexo",
        "sections": "Secciones",
        "taskedAgencies": "Agencias encargadas",
        "responsibilities": "Responsabilidades",
        "what": "Qué",
        "where": "Dónde",
        "action": "Acción",
        "noAssignee": "Sin cesionario",
        "emailSent": "Email enviado",
        "bulkUpload": "Carga masiva",
        "replace": "Reemplazar",
        "run": "Correr",
        "alternate": "Alterno",
        "assignMember": "Asignar miembro",
        "attachment": "Archivo adjunto",
        "technologies": "[[Technologies]]",
        "DateAdded": "Fecha Agregada",
        "selectedOf": "{{selected}} de {{available}}",
        "technology": "[[Technology]]",
        "minutes": "Minutos",
        "examples": "Ejemplos",
        "usedInPlans": "Utilizado en planes",
        "undefined": "Indefinido",
        "tier": "Nivel",
        "assignedOwner": "Propietario asignado",
        "markComplete": "Marcar completo",
        "done": "Hecho",
        "unclassified": "Desclasificado",
        "checking": "Verificando..."
    },
    "geolocations": {
        "lblShowAddress": "Mostrar dirección",
        "options": "Opciones",
        "showGeofences": "Mostrar geocercas",
        "hideGeofences": "Ocultar geocercas",
        "showLocations": "Mostrar ubicaciones",
        "hideLocations": "Ocultar ubicaciones",
        "showUserAddress": "Mostrar direcciones de usuario",
        "hideUserAddress": "Ocultar direcciones de usuario",
        "filterByZip": "Código postal",
        "showPOIs": "Mostrar POI",
        "hidePOIs": "Ocultar POI",
        "location": "Ubicación",
        "filterByLocationOption": "Incluir opciones de [[location]] del usuario"
    },
    "geofence": {
        "new": "Nueva geocerca",
        "name": "Nombre de la geocerca",
        "information": "Información",
        "address": "Dirección de la geocerca",
        "findAddress": "Encontrar la dirección",
        "create": "Crear geocerca",
        "update": "Actualizar geocerca",
        "locationAddress": "Dirección del sitio",
        "remove": "Quitar geocerca",
        "errorGeocodeFailed": "No se puede determinar la dirección proporcionada; corrija la dirección y vuelva a intentarlo.",
        "msgGeocodeSuccess": "dirección determinada con éxito.",
        "noGeofenceError": "No hay geocerca activa; cree una geocerca para guardar.",
        "errorInvalidPolygon": "Esta geocerca no es compatible; asegúrese de que el límite de la geocerca no se cruce",
        "msgSaveGeofenceSuccess": "La geocerca {{ name }} se ha guardado exitosamente.",
        "errorSaveGeofenceFailed": "No se pudo guardar la geocerca {{ name }}",
        "errorReverseGeocodeFailed": "No se puede determinar la ubicación proporcionada a la dirección",
        "msgRemoveGeofenceSuccess": "La geocerca {{ name }} ha sido eliminada con éxito.",
        "errorRemoveGeofenceFailed": "No se pudo eliminar la geocerca {{ name }}",
        "AreYouSureDeleteGeofence": "¿Está seguro de que desea eliminar esta geocerca?",
        "createGeofenceFromPostalCode": "Crear geocerca a partir de este código postal",
        "msgFailCreateGeofenceFromPostalCode": "No se pudo crear la geocerca a partir del código postal",
        "userAddress": "Dirección de usuario",
        "userMobileLocation": "Ubicación móvil del usuario",
        "userAssociatedLocation": "[[Location]] asociada al usuario",
        "radius": "Radio",
        "width": "Ancho",
        "height": "Altura",
        "msgInvalidRadius": "Radio no válido",
        "msgInvalidDimension": "Ancho o altura no válidos",
        "circle": "Círculo",
        "rectangle": "Rectángulo",
        "polygon": "Polígono",
        "postalCode": "Código postal",
        "newGeofence": "Nueva geocerca"
    },
    "establishByCard": {
        "dateCreated": "Fecha de creación",
        "establishByDate": "Establecer por fecha",
        "establishOnDate": "Establecer fecha",
        "establishByDatePastDue": "Vencido",
        "establishByDateDetails": "Establecer por fecha"
    },
    "charts": {
        "labels": {
            "verified": "Verificado",
            "overview": "Visión general",
            "preparisScore": "Puntuación de Preparis",
            "pastDue": "Vencido",
            "categoryScores": "Puntuaciones de categoría",
            "score": "Puntuación",
            "notEstablished": "No establecido",
            "categoryScore": "Puntuación de categoría",
            "total": "Total",
            "categoryProgress": "Progreso de la categoría"
        },
        "colors": {
            "verified": "#28a745",
            "unverified": "#ffc107",
            "pastDue": "#dc3545",
            "radarLevels": {
                "default": {
                    "backgroundColor": "rgba(0, 0, 0, 0.0)",
                    "borderColor": "rgba(0, 0, 0, 0)",
                    "pointBackgroundColor": "rgba(0, 0, 0, 0)",
                    "pointBorderColor": "rgba(0, 0, 0, 0)",
                    "pointHoverBackgroundColor": "rgba(0, 0, 0, 0)",
                    "pointHoverBorderColor": "rgba(0, 0, 0, 0)"
                },
                "first": {
                    "backgroundColor": "rgba(212, 239, 223, 0.2)",
                    "borderColor": "rgba(39, 174, 96, 1)",
                    "pointBackgroundColor": "rgba(39, 174, 96, 1)",
                    "pointBorderColor": "#fff",
                    "pointHoverBackgroundColor": "#fff",
                    "pointHoverBorderColor": "rgba(39, 174, 96, 1)"
                }
            }
        }
    },
    "planStructure": {
        "plan": "[[Plan]]",
        "itemsInRecoveryPlan": "Artículos en esta estructura de [[Plan]]",
        "availableItems": "Artículos disponibles",
        "name": "Nombre",
        "type": "Tipo",
        "tocIndent": "Marca de TOC",
        "filename": "Nombre del archivo",
        "allDocuments": "Todos los documentos",
        "scopeRelatedDocuments": "Documentos relacionados con [[Scope]]",
        "teamRelatedDocuments": "Documentos relacionados con [[Team]]",
        "teamAndSubTeamRelatedDocuments": "Documentos relacionados con [[Team]] y Sub-[[Team]]",
        "allReports": "Todos los [[reports]]",
        "add": "Añadir",
        "description": "Descripción",
        "document": "Documento",
        "toc": "Tabla de contenidos",
        "report": "Reportar",
        "remove": "Eliminar",
        "planStructure": "Estructura de [[Plan]]",
        "planHeader": "Encabezado de [[Plan]]",
        "planCover": "Anulación del cuerpo de la cubierta de [[Plan]]",
        "generate": "Generar",
        "insertToc": "Insertar tabla de contenido",
        "lastGenerationDate": "Fecha de última generación",
        "overrideNextGenDate": "Fecha de anulación",
        "overrideNextGeneration": "Anular la fecha de la próxima generación",
        "planGenerationSchedule": "Programa de generación de [[Plan]]",
        "scheduleFrequency": "Frecuencia de programación",
        "schedulePlanGeneration": "Calendario de generación de [[Plan]]",
        "planGenUseLastDayOfMonth": "El último día del mes",
        "jobStartDate": "Fecha de inicio del trabajo",
        "nextGeneration": "Próxima generación de [[Plan]]",
        "generatingPlan": "Generación de [[Plan]]",
        "generatingAttachmentsZip": "Generación de archivos adjuntos Zip",
        "generateConfirmation": {
            "header": "Generar [[Plan]]",
            "body": "¿Quiere generar su [[plan]] utilizando la versión actual de [[plan]] o la última versión verificada de [[plan]]?",
            "useLatest": "Utilice el último",
            "useVerified": "Utilice el verificado"
        },
        "invalidDateDialog": {
            "header": "Fecha de anulación no válida",
            "body": "La próxima fecha de generación de [[Plan]] debe ser en el futuro",
            "text": "OK"
        },
        "useFirstDocumentAsCoverPage": "Usar el primer documento como portada"
    },
    "complianceCategories": {
        "filterDescription": "Refinar resultados por nombre",
        "newComplianceCategory": "Nueva categoría de cumplimiento",
        "complianceCategories": "Categorías de cumplimiento",
        "name": "Nombre de la categoría",
        "description": "Descripción",
        "confirmRemoveComplianceCategory": {
            "header": "Eliminar categoría de cumplimiento",
            "body": "¿Está seguro de que quiere eliminar este {{ objectType }} llamado {{ name }}?",
            "text": "Eliminar"
        },
        "confirmSaveComplianceCategory": {
            "header": "Guardar categoría de cumplimiento",
            "body": "¿Está seguro de que quiere guardar este {{ objectType }} llamado {{ name }}?",
            "text": "Guardar"
        },
        "complianceCategoryWithDependentsError": "No se puede eliminar {{ name }} cuando a otros registros se les asigna esta categoría"
    },
    "storageLocations": {
        "storageLocations": "Ubicaciones de almacenamiento",
        "filterDescription": "Refinar resultados por nombre",
        "newStorageLocation": "Nueva ubicación de almacenamiento",
        "name": "Ubicación de almacenamiento",
        "description": "Descripción",
        "getStorageLocationError": "Error al recuperar ubicaciones de almacenamiento",
        "confirmRemoveStorageLocation": {
            "header": "Eliminar ubicación de almacenamiento",
            "body": "¿Está seguro de que quiere eliminar este {{ objectType }} llamado {{ name }}?",
            "text": "Eliminar"
        },
        "confirmSaveStorageLocation": {
            "header": "Guardar ubicación de almacenamiento",
            "body": "¿Está seguro de que quiere guardar este {{ objectType }} llamado {{ name }}?",
            "text": "Guardar"
        },
        "storageLocationWithItemsError": "No se puede eliminar {{ storageName }} con [[items]] asignados a él"
    },
    "timeInterval": {
        "week": "Semana",
        "7": "Semana",
        "month": "Mes",
        "30": "Mes",
        "quarter": "Trimestre",
        "90": "Trimestre",
        "halfYear": "6 meses",
        "180": "6 meses",
        "year": "Año",
        "365": "Año"
    },
    "saveAndEstablish": {
        "header": "Guardar opciones de {{ name }}",
        "body": "El {{ name }} será guardado y establecido en {{ date }}",
        "text": "Guardar y establecer",
        "establishedDateInPast": "La fecha seleccionada para Establecer por es una fecha pasada. Cuando guarde el {{ name }} se establecerá en {{ date }}.",
        "establishedDateToday": "La fecha seleccionada para Establecer por es hoy. El {{ name }} se establecerá el {{ date }}."
    },
    "updateVerificationDate": {
        "header": "Actualizar fecha de {{ name }} {{dateType}}",
        "body": "Seleccione nueva fecha de {{ name }} {{dateType}}",
        "dateType_next": "Siguiente verificación",
        "dateType_last": "Verificado por última vez"
    },
    "saveAndCheckIn": {
        "header": "Guardar opciones de {{ name }}",
        "body": "¿Quiere guardar y registrar el {{ name }}, o simplemente guardar el {{ name }}?",
        "saveAndCheckIn": "Guardar y registrar",
        "saveNotCheckIn": "Guardar pero no registrar"
    },
    "settings": {
        "settings": "Configuraciones",
        "resourceRequireApproval": "Requerir aprobaciones para recursos de [[team]] por defecto",
        "resourceRequireApprovalTooltip": "Cuando están habilitados, los recursos de [[team]], tales como los artículos, requerirán de forma predeterminada la aprobación de un aprobador designado para poder ser verificados.",
        "forceNextVerificationBasedOnLastVerified": "Anular el cálculo de fecha estándar",
        "portalApprovalSettingSaveSuccess": "Se ha guardado la [[setting]] de aprobación de recursos del portal",
        "portalApprovalSettingSaveError": "Error al guardar la [[setting]] de aprobación de recursos del portal",
        "portalSettingSaveSuccess": "[[setting]] del portal guardada",
        "portalSettingSaveError": "Error al guardar la [[setting]] del portal",
        "forceNextVerificationBasedOnLastVerifiedWarning": "¡Advertencia! Si está habilitada, la próxima fecha de verificación se calculará basándose únicamente en la última fecha de verificación y la frecuencia de verificación. Habilitar esta configuración no anulará los datos existentes",
        "assignResourceToSelf": "Permitir a los [[team members]] asumir la propiedad de recursos de [[team]]",
        "assignResourceToSelfTooltip": "Cuando está habilitado, los [[team members]] pueden asignar recursos de [[team]] para ellos mismos"
    },
    "notificationSetting": {
        "notificationSetting": "[[setting]] de la notificación",
        "notificationSettings": "[[setting]] de las notificaciones",
        "jobType": "Tipo de notificación",
        "verificationFrequency": "Frecuencia de verificación",
        "notificationValue": "Número",
        "datepartType": "Intervalo",
        "add": "Agregar notificación",
        "dueSoon": "Vence pronto",
        "overdue": "Vencido",
        "months": "Mes(es)",
        "days": "Día(s)",
        "confirmRemoveHeader": "Eliminar [[setting]] de notificación",
        "confirmRemoveMessage": "¿Está seguro de que quiere eliminar esta notificación {{ x }} de {{ y }}{{ z }}?",
        "saveSuccess": "[[setting]] de notificación para {{ a }} de {{ b }} dentro de {{ c }} {{ d }} guardada",
        "saveError": "[[setting]] de notificación para {{ a }} de {{ b }} dentro de {{ c }} {{ d }} no guardada",
        "removeSuccess": "[[setting]] de notificación para {{ a }} de {{ b }} dentro de {{ c }} {{ d }} eliminada",
        "removeError": "[[setting]] de notificación para {{ a }} de {{ b }} dentro de {{ c }} {{ d }} no eliminada",
        "notificationTypes": {
            "eeca9d34-c1bd-4a24-8562-caaef0e1f510": "Vence pronto",
            "e69761d6-02ac-4156-8f46-6b56fd99614a": "Vencido"
        },
        "datepartTypes": {
            "f8b89b3c-8c9e-47c8-9a2e-9c5417d1a838": "Mes(es)",
            "baba0540-0282-4aca-a53a-fb064ce2d509": "Día(s)"
        },
        "dueSoonDescription": "Enviar notificación {{ a }} {{ b }} antes de la fecha de vencimiento.",
        "overdueDescription": "Enviar notificación {{ a }} {{ b }} después de la fecha de vencimiento.",
        "dueTodayDescription": "Enviar notificación en la fecha de vencimiento.",
        "dueSoonTitle": "Notificaciones para próximas actividades",
        "dueTodayTitle": "Notificaciones de actividades en la fecha de vencimiento",
        "overdueTitle": "Notificaciones de actividades vencidas",
        "addNotificationSettingForDatepart": "Añadir notificación {{ a }} de [[setting]]",
        "customizeNotificationSettingForDatepart": "Personalizar notificación {{ a }} de [[setting]]",
        "editNotificationSetting": "Editar [[setting]] de notificación",
        "addNotificationSetting": "Agregar [[setting]] de notificación",
        "confirmSave": {
            "header": "Guardar [[setting]] de notificación",
            "body": "¿Está seguro de que desea guardar esta [[setting]] de notificación de {{ notificationType }} para las actividades de {{ verificationFrequency }}?",
            "text": "Guardar"
        },
        "confirmRemove": {
            "header": "Eliminar [[setting]] de notificación",
            "body": "¿Está seguro de que desea eliminar esta [[setting]] de notificación de {{ notificationType }} para las actividades de {{ verificationFrequency }}?",
            "text": "Eliminar"
        },
        "default": "defecto",
        "confirmChangePortalSettingHeader": "Cambiar la [[setting]] del correo electrónico del portal",
        "confirmChangePortalSettingMessage": "¿Está seguro de que quiere {{ action }} {{ settingName }}?",
        "enable": "Habilitar",
        "disable": "Inhabilitar",
        "portalEmailSettingSaveSuccess": "[[setting]] de correo electrónico del portal guardada",
        "portalEmailSettingSaveError": "Error al guardar la [[setting]] del correo electrónico del portal",
        "notifications": "Notificaciones",
        "portalSetting": "[[setting]] del portal",
        "enableReminderEmails": "Enviar correos electrónicos recordatorios para [[Team Members]]",
        "enableSummaryEmails": "Enviar como correo electrónico de resumen",
        "enableReminderEmailsTooltip": "Cuando están habilitados, los recordatorios de las próximas tareas se envían por correo electrónico a los [[team members]] cada día.",
        "enableSummaryEmailsTooltip": "Cuando están habilitado, los recordatorios diarios para las próximas tareas se resumen en un correo electrónico.",
        "savePortalSettingSuccess": "[[setting]] del portal guardada",
        "savePortalSettingError": "[[setting]] del portal no guardada",
        "iQEmailFromName": "Notificación de correo electrónico de nombre"
    },
    "iqVerificationSettings": {
        "verificationIntervalThresholdLabel": "Umbral de intervalo de verificación",
        "intervalOptions": {
            "d": "Día(s)",
            "m": "Mes(es)"
        }
    },
    "sendMessage": {
        "disableEnablePrefix": "Ocultar el prefijo del mensaje de respuesta incluido en SMS y correo electrónico",
        "createdBy": "Creado por",
        "sendMessage": "Enviar mensaje",
        "eventName": "Nombre del evento",
        "options": "Opciones",
        "messages": "Mensajes",
        "filters": "Filtros",
        "review": "Revisión",
        "incidentName": "Nombre del incidente",
        "incidentType": "Tipo de incidente",
        "howToContact": "Seleccione de qué manera desea contactar a los destinatarios",
        "requireResponse": "Requerir a los destinatarios que respondan",
        "yesWithCustom": "Sí, con respuestas personalizadas (máx .: {{maximumCustomResponses}})",
        "requireResponseYes": "Sí (Preparis agregará instrucciones a su mensaje pidiendo a los usuarios que respondan con {{responses}})",
        "customResponses": "Respuestas personalizadas",
        "customResponse": "Respuesta personalizada",
        "customResponseDefinition": "Definición de respuesta",
        "advanced": "Avanzado",
        "personalEmail": "Correo electrónico personal",
        "personalPhone": "Teléfono personal",
        "additionalPersonalPhone": "Teléfono personal adicional",
        "personalContact": "Envíe este mensaje a la información de contacto personal del destinatario",
        "autoResend": "Reenviar automáticamente el mensaje",
        "autoSendToManager": "Enviar automáticamente un mensaje al administrador del destinatario",
        "autoTranslate": "Traducir mensaje automáticamente",
        "showMTDDescription": "El tiempo de inactividad máximo tolerable representa la cantidad total de tiempo que la empresa está dispuesta a aceptar por una interrupción del [[process]] empresarial.",
        "enableMTDSuccess": "Permitir el tiempo de inactividad máximo tolerable habilitado",
        "enableMTDFail": "No se pudo habilitar permitir el tiempo de inactividad máximo tolerable",
        "disableMTDSuccess": "Permitir el tiempo de inactividad máximo tolerable desactivado",
        "disableMTDFail": "No se pudo deshabilitar permitir el tiempo de inactividad máximo tolerable",
        "sms": "SMS",
        "voice": "Voz",
        "phone": "Teléfono",
        "pushMessage": "Mensaje Push",
        "email": "Correo electrónico",
        "emergencyMessage": "Mensaje de emergencia",
        "sendAfter": "enviar después",
        "for": "Para",
        "ifRecipients": "si los destinatarios",
        "minutes": "Minutos",
        "hour": "Hora",
        "hours": "Horas",
        "day": "Día",
        "responded": "respondió",
        "hasNotResponded": "no respondió",
        "times": "veces,",
        "newMessage": "Nuevo mensaje",
        "isSimulation": "Enviar mensaje como simulación",
        "usersCanReplyViaLink": "Permitir a las usuarios responder a través de un enlace",
        "unknown": "Desconocido",
        "subject": "Asunto",
        "message": "Mensaje",
        "ctr": "Llamar para grabar",
        "tts": "Texto a voz",
        "call": "Llamada",
        "phoneNumber": "Número de teléfono",
        "preview": "Vista previa",
        "bodyPrepend": "Responder a este mensaje ({{responsesAsString}}): ",
        "bodyPrepend-en": "Reply to this msg ({{responsesAsString}}): ",
        "bodyPrepend-es": "Responder a este mensaje ({{responsesAsString}}): ",
        "bodyPrepend-fr": "Répondre à ce msg ({{responsesAsString}}): ",
        "bodyPrepend-pt": "Responder a esta mensagem ({{responsesAsString}}): ",
        "remaining": "Restante",
        "copyToOthers": "Copiar a otros",
        "copySubjectToOthers": "Copiar tema a otros",
        "copyBodyToOthers": "Copiar mensaje a otros",
        "usersWhoAreIn": "Usuarios que están en",
        "numberOfRecipients": "Numero de destinatarios",
        "allUsers": "Todos los usuarios",
        "manuallyAdd": "Agregar manualmente",
        "andOr": "y/o",
        "noSelections": "No se han realizado selecciones",
        "autoPopulateKey": "Completar automáticamente RESPUESTA PERSONALIZADA",
        "clickAbove": "Haga clic en cualquier opción de las anteriores para comenzar a seleccionar destinatarios",
        "filterHint01": "Seleccione cualquier categoría para crear su lista. Para ser incluido, un usuario debe coincidir con uno de los valores en cada categoría que seleccione. También puede agregar destinatarios manualmente.",
        "example": "Ejemplo",
        "filterExample01": "Si selecciona opciones de varias categorías, un usuario debe coincidir con todos los criterios para ser incluido. Por ejemplo, si selecciona Rol: Gerente de Programa y Ubicaciones: Atlanta, Nashville y [[Department]] de Marketing. Para recibir este mensaje, un usuario debe estar en el [[Department]] de Marketing de Atlanta o Nashville con una función de Gerente de Programas.",
        "selectNone": "no seleccionar ninguno",
        "viewSelections": "ver selecciones",
        "deleteShape": "Eliminar forma",
        "zoomToShape": "Hacer zoom a forma",
        "geofenceOptions": "Opciones de mensajes de geocerca",
        "geofenceChoices": "¿Cómo le gustaría crear su geocerca?",
        "existingGeofence": "Seleccionar entre geocercas",
        "newGeofence": "Crear desde dirección",
        "geofences": "Geocercas",
        "contactMethods": "Métodos de contacto",
        "included": "Incluido",
        "excluded": "Excluido",
        "search": "Buscar",
        "addByPhoneNumber": "Agregar por número de teléfono",
        "addByEmail": "Agregar por correo electrónico",
        "addByType": "Agregar por tipo",
        "manuallyAddedRecipients": "Destinatarios agregados manualmente",
        "invalidPhoneNumber": "Numero de teléfono inválido",
        "allUsersSelected": "Todos los usuarios han sido seleccionados",
        "errorGettingData": "Error al obtener datos",
        "registeredRecipients": "Destinatarios registrados",
        "noRecipientsError": "No se ha agregado ningún destinatario",
        "sentTo": "Mensajes enviados a",
        "automaticFollowUp": "Seguimiento automático",
        "sendNow": "Enviar ahora",
        "saveForLater": "Guardar para más adelante",
        "schedule": "Calendario",
        "scheduleToSend": "Enviar después",
        "responses": "Respuestas",
        "sendToPersonalContact": "Enviar a contacto personal",
        "toWhom": "¿A quién quiere enviar este mensaje?",
        "toWhomMaxOneHundred": "¿A quién quiere enviar este mensaje? (Máximo de 100 participantes)",
        "disabledForOverOneHundredParticipants": "Algunas opciones están deshabilitadas para más de 100 participantes",
        "everyoneLabel": "Envíe un mensaje a todos los asociados a este incidente",
        "everyoneDescription": "Cualquiera que se vea afectado por este incidente, haya recibido un mensaje previo o haya sido incluido en una llamada de conferencia sobre este incidente se preingresará como destinatario.",
        "limitedLabel": "Enviar mensaje solo a un subconjunto de usuarios",
        "limitedDescription": "Enviar solo a aquellos cuya última respuesta fue",
        "newLabel": "Enviar un mensaje a un nuevo grupo de usuarios",
        "newDescription": "Podrá elegir los destinatarios del mensaje en lugar de ingresarlos previamente",
        "incidentCreatorLabel": "Enviar un mensaje al creador del incidente",
        "incidentCreatorDescription": "creó este incidente",
        "followUp": "Seguimiento",
        "scheduleMessageSuccess": "Mensaje programado",
        "scheduleMessageError": "Error en la programación del mensaje",
        "deleteScheduledMessageSuccess": "Mensaje programado eliminado",
        "deleteScheduledMessageError": "Error en la eliminación del mensaje programado",
        "scheduledMessage": "Mensaje programado",
        "confirmDeleteScheduledMessageHeader": "Eliminar mensaje programado",
        "confirmDeleteScheduledMessageDescription": "¿Está seguro de que desea eliminar este mensaje programado?",
        "confirmDeleteMessageTemplateHeader": "Eliminar mensaje guardado",
        "confirmDeleteMessageTemplateDescription": "¿Está seguro de que desea eliminar este mensaje guardado?",
        "getScheduleMessageError": "Error al obtener el mensaje programado",
        "updateScheduledMessage": "Actualizar mensaje programado",
        "followUpMessage": "Seguimiento",
        "saveAs": "¿Cómo quiere guardar este mensaje?",
        "overwrite": "Sobrescribir el actual",
        "saveAsNew": "Guardar como nuevo",
        "saveMessageName": "Guardar el nombre del mensaje",
        "savedMessages": "Mensajes guardados",
        "pendingMessages": "Mensajes pendientes",
        "pin": "Haga clic para anclar",
        "unpin": "Haga clic para desanclar",
        "removeMessageTemplate": "Eliminar mensaje guardado",
        "send": "Enviar",
        "removeTemplateHeader": "Eliminar mensaje guardado",
        "removeTemplateBody": "¿Está seguro de que desea eliminar esta plantilla de mensaje?",
        "noOfRecipient": "# de destinatarios",
        "responseRequired": "Respuesta requerida",
        "noPendingMessage": "Ningún mensaje pendiente",
        "sendMessageSuccess": "Su mensaje está en camino. ¿Le gustaría guardar esto para usarlo de nuevo más tarde?",
        "sendMessageSuccessAlt": "Su mensaje está en camino",
        "contacts": "Contactos",
        "includeOfficeContactInfo": "¿Llamar también al teléfono de la oficina de los destinatarios?",
        "invalidEmail": "Correo electrónico inválido",
        "confirmCancelHeader": "Cancelar el envío del mensaje",
        "confirmCancelBody": "¿Está seguro de que desea cancelar el envío del mensaje?",
        "callRecipientOfficePhone": "Llamar al teléfono de la oficina",
        "noDupeResponses": "Sin respuestas duplicadas",
        "selections": "Selecciones",
        "recipients": "Destinatarios",
        "composeNewMessage": "Redactar nuevo mensaje",
        "progress": "Progreso",
        "dupeNameError": "Este nombre ya existe",
        "successRemovingMessageTemplate": "Mensaje guardado eliminado",
        "allCountries": "Todos los países",
        "noManuallyAddedRecipients": "Sin destinatarios agregados manualmente",
        "manuallyAddedLocations": "Ubicaciones agregadas manualmente",
        "manuallyAddedDepartments": "[[Departments]] agregados manualmente",
        "manuallyAddedGroups": "Grupos agregados manualmente",
        "manuallyAddedTenants": "Inquilinos agregados manualmente",
        "manuallyAddedRoles": "Roles agregados manualmente",
        "submitForApproval": "Enviar para aprobación",
        "pendingMessageName": "Nombre del mensaje pendiente",
        "messageSubmittedForApproval": "Mensaje enviado para aprobación",
        "removePendingMessageHeader": "Eliminar mensaje pendiente",
        "removePendingMessageBody": "¿Está seguro de que desea eliminar este mensaje pendiente?",
        "successDeletePendingMessage": "Mensaje pendiente eliminado",
        "newSendMessage": "Enviar un mensaje nuevo",
        "noReply": "Sin respuesta",
        "approvePendingMessage": "Aprobar mensaje pendiente",
        "updatePendingMessage": "Actualizar mensaje pendiente",
        "declinePendingMessage": "Rechazar mensaje pendiente",
        "newEvent": "Nuevo evento",
        "approveAndSend": "Aprobar y enviar",
        "permissionError": "No tiene permiso para realizar cambios en este mensaje pendiente",
        "everyoneWhose": "Todos cuya última respuesta fue",
        "everyoneInThisEvent": "Todos en este evento",
        "sendEdit": "Enviar/Editar",
        "detectStartStep": "Paso inicial de detección automática",
        "detectStartStepDescription": "La aplicación puede detectar automáticamente con qué paso del [[process]] de Enviar mensaje comenzar, en función de los datos de su Mensaje guardado. Esto puede reducir la cantidad de clics necesarios para enviar su mensaje. Al especificar \"No\", siempre se utilizará el primer paso del [[process]] de envío de mensajes.",
        "startWithMessageTemplate": "Comience con un mensaje guardado (opcional)",
        "startWithMessageTemplateDescription": "Los destinatarios de los mensajes guardados NO se aplicarán a este mensaje",
        "incidentCreator": "Creador de incidentes",
        "desktop": "Escritorio",
        "desktopSeverity": "Gravedad",
        "desktopSeverityAlarm": "[[Alert]]",
        "desktopSeverityWarning": "Advertencia",
        "desktopSeverityInfo": "Información",
        "desktopSendSilently": "Enviar silenciosamente",
        "simulationMessage": "Esta es una simulación",
        "errorDesktopAlertWithNoResponse": "No se puede enviar un mensaje de escritorio como una [[alert]] cuando no se requiere una respuesta del usuario.",
        "sendAMessage": "Enviar un mensaje",
        "entityRemoved": "Una o más selecciones se han eliminado del sistema desde la última vez que se guardó este mensaje. Asegúrese de que todas las selecciones estén actualizadas. Se eliminaron los siguientes:",
        "createANewMessage": "Crear un mensaje nuevo",
        "alertDeclare": "[[Alert]]/Anunciar",
        "createANewMessaage": "Crear un mensaje nuevo",
        "requireRecipientToRespondTooltip": "(Preparis agregará instrucciones a su mensaje pidiendo a los usuarios que respondan OK o NO)",
        "selectDeliveryMethod": "Seleccione el método de entrega",
        "push": "Push",
        "copyText": "Copiar el texto",
        "selectedResponses": "Respuestas seleccionadas",
        "automaticReply": {
            "automaticReply": "Auto Respuesta",
            "setUpAutomaticReplies": "Configurar respuestas automáticas",
            "lblAutomaticReplies": "Mensajes de respuesta automática",
            "configuredAutomaticReplies": "Respuestas automáticas configuradas"
        },
        "uploadNewDocument": "Redactar un mensaje nuevo",
        "title": "Mensajes",
        "searchPlaceholder": "Buscar un mensaje guardado",
        "addNew": "Redactar un mensaje nuevo",
        "createFolder": "Nueva carpeta",
        "searchTitle": "Resultados de la búsqueda",
        "or": "o",
        "sendOrEdit": "Enviar/Editar",
        "buttonPin": "Alfiler",
        "buttonUnPin": "Desprender",
        "pinned": "Fijado",
        "pending": "Pendiente",
        "messageDetails": "Detalles del mensaje",
        "selectAllWarning": "Ha seleccionado 'Seleccionar todo'. ¿Está seguro de que desea agregar todos los usuarios a la lista de destinatarios de este mensaje?",
        "selectAllWarning2": "Potencialmente ha seleccionado un grupo grande de personas para agregar a este mensaje."
    },
    "googleMapAPITermAndService": "Condiciones de uso de la API de mapas",
    "portalResources": {
        "resourceTypes": {
            "2e2016a0-4990-4276-b2f3-01950edc13e1": "Herramientas para empleados",
            "e956d676-9d9f-4ab3-80b0-21e670e720ad": "Plantillas",
            "9904f935-b860-44fc-8a7d-2a91475e9fbb": "Infografías",
            "47905bed-1e55-4efd-baab-63014481c1cd": "Hojas de referencia",
            "e9730817-b71b-4273-9e2b-650191be2d61": "RSS Feed",
            "bb073508-593c-40bb-8c39-6bf5aa801c0c": "Tabletop",
            "5bb91d95-051d-48b3-b1bc-7a8f4558c1b6": "Noticias y resúmenes",
            "8e499ec0-a8b1-4de0-b830-89adcec08483": "Formación",
            "f2a1fa9e-bfbd-4051-a95b-95f4b518a17a": "Protocolos",
            "4cbb9953-a1d9-47ab-9c86-b810a6fcf898": "Blog",
            "40e1b2f9-e47a-4c6c-93fb-c4684f88705c": "Lista de verificación",
            "62c2f132-76ad-47f8-bd43-dbe02abba987": "Resúmenes ejecutivos",
            "148a1689-577b-4ca5-b47b-e124ab249b42": "Amenaza"
        },
        "distributionTypes": {
            "5729f1b5-be13-4f33-aa73-7843b5bc2fce": "Verticales y explícito",
            "d11232ba-5525-40d5-8062-57caca5fa498": "Solo explícito"
        },
        "resourceType": "Tipo de recurso",
        "icon": "Ícono de artículo de contenido",
        "document": "Documento de artículo de contenido",
        "categories": "Categorías",
        "requiresACL": "Requiere ACL",
        "distributionType": "Tipo de distribución",
        "name": "Nombre",
        "title": "Título",
        "description": "Descripción",
        "newItem": "Nuevo artículo",
        "resourcesFilterPlaceholder": "Filtrar",
        "confirmRemoveHeader": "Eliminar artículo de contenido",
        "remove": "Eliminar",
        "contentItem": "Artículo de contenido",
        "sortOrder": "Pedido",
        "select": "Seleccione",
        "viewOrderLists": "Ver listas de pedidos",
        "viewOrderList": "Ver lista de pedidos",
        "availableContentItems": "Artículos de contenido disponibles",
        "availableTrainingCourses": "Cursos de formación disponibles",
        "selectedContentItems": "Artículos de contenido seleccionados",
        "selectedTrainingCourses": "Cursos de formación seleccionados",
        "vertical": {
            "3089683e-a1c9-4c91-9b87-1e3888a9cfcf": "estándar",
            "f1d39814-6de9-481b-9abe-d655d396a64d": "CRE"
        }
    },
    "publicAlertSubscription": {
        "lblPageHeader": "Suscripciones a [[alerts]] públicas",
        "lblPageDescription": "Administre las suscripciones a mensajes de [[alert]] públicos.",
        "publicAlertSubscription": "Suscripción",
        "publicAlertSubscriptions": "Suscripciones",
        "subscriptionName": "Nombre de suscripción",
        "incidentType": "Tipo de incidente",
        "groupEntity": "Grupo",
        "filterPlaceHolder": "Refinar resultados por nombre de suscripción",
        "newSubscription": "Suscripción nueva",
        "sendSms": "Enviar SMS",
        "sendEmail": "Enviar correo electrónico",
        "sendVoice": "Enviar mensaje de voz",
        "isPush": "Habilitar mensaje Push",
        "categoryType": "Tipos de categoría",
        "certaintyType": "Tipos de certeza",
        "severityType": "Tipos de gravedad",
        "urgencyType": "Tipos de urgencia",
        "eventType": "Tipos de eventos",
        "allTypes": "Todos los tipos",
        "zoneCountryFilter": "Filtro de zona de país",
        "zoneRegionFilter": "Filtro de zona de región",
        "zoneTypeFilter": "Filtro de tipo de zona",
        "automaticallySendMessages": "Enviar mensajes automáticamente",
        "describe": "Describir",
        "filterTypes": "Tipos de filtros",
        "filterLocations": "Filtrar ubicaciones",
        "availableZones": "Zonas disponibles",
        "selectedZones": "Zonas seleccionadas",
        "rowCountMessage": "Mostrar {{ x }} de {{ y }} suscripciones",
        "createNewSubscription": "Crear nueva suscripción",
        "errorMessages": {
            "selectAtLeastOneChannel": "Seleccione al menos un canal de comunicación antes de guardar.",
            "selectAtLeastOneRegion": "Seleccione al menos una región antes de guardar.",
            "requiredFieldMissing": "Llene todos los campos requeridos."
        },
        "simple": "Simple",
        "advanced": "Avanzado",
        "autoSend": "Envío automático",
        "confirmSave": {
            "header": "Guardar suscripción de [[alert]] pública",
            "body": "¿Está seguro de que quiere guardar este {{ objectType }} llamado {{ name }}?",
            "text": "Guardar suscripción"
        },
        "confirmRemove": {
            "header": "Eliminar suscripción de [[alert]] pública",
            "body": "¿Está seguro de que quiere eliminar este {{ objectType }} llamado {{ name }}?",
            "text": "Eliminar suscripción"
        },
        "help": {
            "subscriptionName": "Nombre para etiquetar de forma única esta suscripción.",
            "incidentType": "Tipo de incidente que mejor categoriza los eventos filtrados de esta suscripción.",
            "sendSms": "Envíe [[alerts]] públicas que coincidan con esta suscripción por SMS.",
            "sendEmail": "Envíe [[alerts]] públicas que coincidan con esta suscripción por correo electrónico.",
            "sendVoice": "Envíe [[alerts]] públicas que coincidan con esta suscripción por correo de voz.",
            "isPush": "Envíe notificaciones automáticas para [[alerts]] públicas que coincidan con esta suscripción.",
            "groupEntity": "Designe el grupo que recibirá y actuará sobre las [[alerts]] públicas que coincidan con esta suscripción.",
            "categoryType": "Suscríbase a estos tipos de categorías.",
            "certaintyType": "Suscríbase a estos tipos de certeza.",
            "severityType": "Suscríbase a estos tipos de gravedad.",
            "urgencyType": "Suscríbase a estos tipos de urgencia.",
            "eventType": "Suscríbase a estos tipos de eventos.",
            "zoneCountryFilter": "Filtre las zonas disponibles por país.",
            "zoneRegionFilter": "Filtre las zonas disponibles por región.",
            "zoneTypeFilter": "Filtre las zonas disponibles por tipo.",
            "autoSend": "Envíe mensajes automáticamente a medida que se generen [[alerts]] públicas."
        }
    },
    "incidentTypeTriggers": {
        "manageIncidentTypeTriggers": "Gestionar disparadores de incidentes",
        "incidentTypeTriggersDescription": "Los incidentes pueden ser disparados a través de API de terceros en el sistema Preparis. Esto se logra mapeando un tipo de incidente enviado por la API de terceros a un mensaje guardado.",
        "incidentTypeTriggers": "Disparadores de incidentes",
        "help": {
            "incidentTypeTriggers": "A menos que estén actualmente en uso, se pueden eliminar casi todos los disparadores de incidentes."
        },
        "incidentTypeTriggersName": "Disparadores",
        "incidentTypeHeader": "Tipo de incidente",
        "apiHeader": "API",
        "savedMessageHeader": "Mensaje guardado",
        "noTriggers": "No se han agregado disparadores de incidentes",
        "addNewTrigger": "Agregar nuevo disparador de incidente",
        "errorDuplicatedIncidentTypes": "No se puede utilizar un tipo de incidente como disparador varias veces.",
        "errorNonSendableSavedMessages": "Los mensaje(s) guardado(s) ({{messageNames}}) no se puede(n) enviar porque no se han completado todos los campos obligatorios. Seleccione los mensajes alternativos guardados."
    },
    "incidentTypes": {
        "manageIncidentTypes": "Administrar tipos de incidentes",
        "incidentTypesDescription": "Agregue tipos de incidentes adicionales que se pueden usar para clasificar incidentes al crear y actualizar incidentes en el portal y también al crear incidentes iniciados por el usuario a través de la aplicación móvil.",
        "noIncidentTypes": "No se agregó ningún tipo de incidente",
        "incidentType": "Tipo de incidente",
        "incidentTypes": "Tipos de incidentes",
        "systemDefaultIncidentTypes": "Tipos de incidentes predeterminados del sistema",
        "addNewIncidentType": "Agregar nuevo tipo de incidente",
        "addSystemIncidentType": "Agregar tipo de incidente del sistema",
        "msgRequiredFieldsWarning": "Introduzca todos los campos obligatorios o elimínelos antes de guardar.",
        "msgSaveIncidentTypesSuccess": "Tipos de incidentes guardados exitosamente.",
        "msgDuplicateIncidentTypeNameWarning": "Uno o más tipos de incidentes tienen el mismo nombre. Compruebe el nombre de su tipo de incidente.",
        "incidentTypeName": "Nombre del tipo de incidente",
        "saveSuccess": "Los tipos de incidentes se guardaron exitosamente.",
        "help": {
            "incidentTypes": "A menos que estén actualmente en uso, se pueden eliminar casi todos los tipos de incidentes. Además, se puede cambiar el nombre de los tipos de incidentes personalizados. (Los tipos de incidentes preingresados son de solo lectura). El tipo de incidente predeterminado \"No establecido\" es requerido y de solo lectura; no se puede cambiar de nombre ni eliminar."
        },
        "errors": {
            "messagingIncidentTypeWithGroupDependencies": "Uno o más tipos de incidentes eliminados se están utilizando actualmente como [[setting]] de grupo iniciada por el usuario.",
            "messagingIncidentTypeWithChannelDependencies": "Uno o más tipos de incidentes eliminados se están utilizando actualmente como [[setting]] de canal iniciada por el usuario.",
            "messagingIncidentTypeWithPublicAlertDependencies": "Actualmente se están utilizando uno o más tipos de incidentes eliminados con una [[setting]] de [[alert]] pública.",
            "messagingIncidentTypeCannotRemoveSystemDefault": "\"No establecido\" es un valor predeterminado del sistema y no se puede eliminar.",
            "messagingIncidentTypeNamesAreNotUnique": "Los nombres no son únicos"
        }
    },
    "emailTypes": {
        "acb4f38f-3642-472a-8de2-dcead28584a2": "Personal",
        "d440a213-3ab4-4fb9-b306-5f567141ac53": "Secundario",
        "d2b1d156-bdf9-4223-9368-825a448025ad": "Primario"
    },
    "phoneTypes": {
        "9da9c256-8283-4a0a-bd3d-10df73e1f43b": "Seguridad",
        "20509910-5326-4057-b21f-5c694b314692": "Oficina",
        "5322a58a-8683-449b-a562-b0577de3fa74": "Emergencia",
        "5eec12d5-595f-4123-a613-c63e701ae993": "Personal",
        "33f27328-8fee-4835-b020-cd6d48bb39cd": "Móvil",
        "391dca7b-aac3-4875-94e2-2ef680c7f35f": "Personal adicional"
    },
    "responses": {
        "lblUnknown": "Desconocido",
        "lblNoResponse": "Ninguna respuesta",
        "lblNotOk": "NO",
        "lblOk": "OK"
    },
    "publicAlerts": {
        "dateTimeSent": "Fecha de envío",
        "msgType": "Tipo",
        "category": "Categoría",
        "urgency": "Urgencia",
        "severity": "Gravedad",
        "certainty": "Certeza",
        "dateTimeExpiration": "Expira el",
        "recentPublicAlerts": "Alertas públicas recientes",
        "status": "Estado",
        "expired": "Vencido",
        "active": "Activo",
        "recentAlerts": "Alertas recientes",
        "instruction": "Instrucción",
        "description": "Descripción",
        "publicAlerts": "Alertas públicas",
        "responseType": "Tipo de respuesta",
        "showMore": "Mostrar más",
        "showLess": "Mostrar menos",
        "filters": "Filtros",
        "areas": "Áreas",
        "headline": "Encabezado",
        "expiresOn": "Expira el",
        "sentOn": "Enviado el",
        "sendMessage": "Enviar mensaje",
        "errors": {
            "errorLoadingRecentAlerts": "Error al cargar [[alerts]] recientes",
            "errorLoadingAlertInfo": "Error al cargar más información de [[alert]]"
        },
        "rowCountMessage": "Mostrando {{ displayedCount }} de {{ totalCount }} [[alerts]] {{ status }}",
        "showAdvanced": "Mostrar avanzado",
        "showSimple": "Mostrar simple",
        "previousDays": {
            "2": "Últimas 48 horas",
            "3": "Últimas 72 horas",
            "4": "Últimos 4 días",
            "5": "Últimos 5 días"
        },
        "noAlertsToDisplayMessage": "No hay [[alerts]] {{ status }} en {{ timeSpan }}",
        "subscriptions": "Suscripciones",
        "subscription": "Suscripción"
    },
    "messageSettings": {
        "errorRequiredFields": "Ingrese todos los campos obligatorios.",
        "errorResetMessageFailed": "Se produjo un error al restablecer la [[setting]] de los mensajes.",
        "errorSavMessageFailed": "Se produjo un error al guardar la [[setting]] del mensaje.",
        "lblAreYouSureResetSettings": "¿Está seguro de que desea restablecer la [[setting]] de los mensajes?",
        "lblEscalateMessageContent1": "Si desea dar tiempo a los destinatarios de los mensajes para que respondan por SMS, correo electrónico o mensajes Push antes de que se los llame, configúrelo en Sí. Luego podrá seleccionar la cantidad de tiempo de espera antes de llamar.",
        "lblEscalateMessageContent2": "Si se establece en No, se llamará al destinatario al mismo tiempo que este reciba mensajes de texto/correo electrónico/Push.",
        "lblEscalateMessageContent3": "Nota: La [[setting]] SOLO se aplica a los mensajes que requieren una respuesta y que tienen seleccionados SMS y/o correo electrónico y/o Push Y mensajes de voz.",
        "lblEscalateMessageTitle": "¿Escalar el mensaje cuando se REQUIERE una respuesta (SMS/correo electrónico/Push a voz)?",
        "lblIncludeOfficePhoneContent": "Si se establece en Sí, cuando un usuario envía un nuevo mensaje Y ha seleccionado la opción Voz (llamar a los destinatarios), se preseleccionará la opción de llamar al teléfono de la oficina de un destinatario. Los usuarios podrán anular el valor predeterminado y anular la selección de cada mensaje si lo desean.",
        "lblIncludeOfficePhoneTitle": "¿Incluir teléfono de oficina para llamadas de voz de forma predeterminada?",
        "lblPersonalContactContent": "Si se establece en Sí, cuando un usuario envía un nuevo mensaje se preseleccionará la opción para enviar a la información de contacto personal del destinatario del mensaje. Los usuarios podrán anular este valor predeterminado y la selección de cada mensaje si lo desean.",
        "lblPersonalContactTitle": "¿Enviar mensaje a la información de contacto personal del usuario?",
        "lblSettingsActivity": "Actividad de [[setting]]",
        "lblSettingsTitle": "[[Setting]] de mensajes",
        "lblAutoArchive": "Archivo automático de registros del historial de incidentes",
        "lblAutoArchiveContent": "Si se establece en Sí, los registros del historial de incidentes resueltos se archivarán automáticamente. Se le dará la oportunidad de elegir el intervalo de archivo y si resolver y archivar los registros que son antiguos pero que aún están activos.",
        "lblForceResolution": "Resolver y archivar incidentes activos más antiguos",
        "lblIntervalDays": "Archivar incidentes anteriores a",
        "msgResetMessageSuccess": "La [[setting]] de mensajes se ha restablecido exitosamente.",
        "msgSaveMessageSuccess": "La [[setting]] de mensajes se ha guardado exitosamente.",
        "lblPersonalContactPhoneTitle": "¿Enviar mensaje al teléfono personal del usuario?",
        "lblPersonalContactPhoneContent": "Si se establece en Sí, cuando un usuario envíe un nuevo mensaje se preseleccionará la opción de enviar a la información del teléfono personal del destinatario del mensaje. Los usuarios podrán anular este valor predeterminado y la selección de cada mensaje si lo desean.",
        "personalContactEmailTitle": "¿Enviar mensaje al correo electrónico personal del usuario?",
        "lblPersonalContactEmailContent": "Si se establece en Sí, cuando un usuario envíe un nuevo mensaje se preseleccionará la opción para enviar a la información de correo electrónico personal del destinatario del mensaje. Los usuarios podrán anular este valor predeterminado y la selección de cada mensaje si lo desean.",
        "lblOverwriteDefaultUserResponses": "Si sobrescribe las respuestas de usuario predeterminadas, las opciones de respuesta de un mensaje tendrán estos valores",
        "lblDesablePrefixSmsVoice": "Solo aplicable si solicita una respuesta",
        "btnSave": "Guardar",
        "btnUndo": "Deshacer",
        "btnReset": "Reiniciar",
        "lblCustomResponses": "¿Sobrescribir las respuestas de usuario predeterminadas?",
        "lblDisablePrefix": "Ocultar el prefijo del mensaje de respuesta incluido en SMS y correo electrónico.",
        "confirmReset": {
            "body": "¿Está seguro de que desea restablecer la [[setting]] de los mensajes?",
            "header": "Confirmar",
            "btnText": "OK"
        },
        "allowOfficePhoneSMS": "Permitir que se envíen SMS a teléfonos de oficina elegibles.",
        "allowOfficePhoneSMSDesc": "Si se establece en sí, los números de teléfono de la oficina se incluirán en los mensajes siempre que el teléfono esté habilitado y no tenga una extensión."
    },
    "conferenceCall": {
        "conferenceCall": "Llamada de conferencia",
        "selectContactTip": "Si elige Móvil, Oficina o Personal, automáticamente se llamará a los participantes a través del método de contacto seleccionado al iniciar la llamada",
        "selectHowYouWantToContact": "Seleccione cómo desea comunicarse con los participantes para esta llamada de conferencia (solo puede elegir 1 opción)",
        "newConferenceCall": "Nueva llamada de conferencia",
        "mobileTip": "La mejor forma de llegar a los usuarios",
        "mobile": "Móvil",
        "office": "Oficina",
        "personal": "Personal",
        "optionsConferenceDetailDescription": "Seleccione a dónde enviar los detalles de la llamada de conferencia para los asistentes",
        "noCall": "No llamar",
        "noCallTip": "Enviar solo detalles de llamadas de conferencia",
        "personalEmail": "Correo electrónico personal",
        "personalPhone": "Teléfono personal",
        "finalRecipientCountErrorMsg": "Cantidad máxima de destinatarios: {{maxRecipient}}",
        "startConferenceCall": "Iniciar llamada de conferencia",
        "contactParticipants": "Contactar a los participantes",
        "sendConferenceCallDetailTo": "Enviar detalles de la llamada de conferencia a",
        "optionsPersonalInfo": "Enviar detalles de la llamada de conferencia a la información de contacto personal",
        "success": "Llamada de conferencia iniciada con éxito",
        "confirmCancelHeader": "Cancelar llamada de conferencia",
        "confirmCancelBody": "¿Está seguro de que desea cancelar esta llamada de conferencia?",
        "followUpConferenceCall": "Seguimiento",
        "optionsPersonalInfoDescription": "Envíe este mensaje a la información de contacto personal del destinatario",
        "scheduleConferenceCall": "Programar llamada de conferencia",
        "scheduleConferenceCallError": "Error al programar la llamada de conferencia",
        "scheduleConferenceCallSuccess": "Llamada de conferencia programada exitosamente",
        "scheduledConferenceCalls": "Llamadas de conferencia programadas",
        "noScheduledConferenceCalls": "No hay llamadas de conferencia programadas",
        "dateCreated": "Fecha de creación",
        "scheduledDate": "Fecha programada",
        "scheduledTime": "Hora programada",
        "createdBy": "Creado por",
        "deleteScheduledConferenceCallBody": "¿Está seguro de que desea eliminar esta llamada de conferencia programada?",
        "deleteScheduledConferenceCall": "Eliminar llamada de conferencia programada",
        "updateScheduledConferenceCall": "Actualizar llamada de conferencia programada",
        "scheduledConferenceCall": "Llamada de conferencia programada",
        "searchScheduledConCallsPlaceholder": "Buscar llamadas de conferencia programadas",
        "everyoneInThisEvent": "Todos en este evento",
        "additionalMessage": "Mensaje adicional",
        "additionalMessageDescription": "Este es un mensaje opcional que se agregará al final del mensaje que los participantes recibirán en la notificación de una llamada de conferencia.",
        "additionalSmsMessagePreview": "Una llamada de conferencia de emergencia ha sido configurada por {{userProfileName}}: +{{portalConferenceCallNumber}} ID: el <em title=\"pin se generará cuando se inicie la llamada\"> pin</em> One-touch: tel: +{{portalConferenceCallNumber}},, El <em title=\"pin se generará cuando se inicie la llamada #\"> pin</em>.",
        "additionalEmailBodyPreview": "Una llamada de conferencia de emergencia ha sido configurada por {{userProfileName}}. Llame al siguiente número para unirse a la llamada de conferencia:<br /><br />+{{portalConferenceCallNumber}}<br /><br />ID de conferencia: el <em title=\"PIN se generará cuando se inicie la llamada\">pin</em><br />One-touch: tel: +{{portalConferenceCallNumber}},,<em title=\"El pin se generará cuando se inicie la llamada #\">pin</em>",
        "optionsRecordCall": "Seleccione si desea grabar esta llamada de conferencia",
        "recordCallDisclaimer": "Las leyes aplicables pueden requerir que las personas que llaman escuchen una notificación de grabación de llamadas antes de que se grabe una llamada. Es su responsabilidad asegurarse de que los procedimientos de grabación de llamadas de su compañía cumplan con las leyes y regulaciones aplicables. Preparis no es responsable del cumplimiento de su compañía."
    },
    "customFields": {
        "customFieldTitle": "Preguntas sobre la estrategia del plan de continuidad",
        "fieldName": "Nombre",
        "fieldType": "Tipo de campo",
        "isRequired": "Requerido",
        "fieldTypes": {
            "5f31785c-4d3c-4d68-a834-da37c2bc0a76": "Numérico",
            "0f6f2372-1e4c-4d67-ad13-615a0540488c": "Texto corto",
            "b9847d16-19b5-4ed4-a4e3-d7f07533ab5a": "Texto largo",
            "d8bd3d80-d25d-4056-8754-a740f2396792": "Verdadero o falso",
            "aad6f035-05f9-42d3-b6ea-682371340875": "Seleccione uno",
            "76c83029-8439-42ad-90e6-7f0ff54b732c": "Seleccione varios",
            "1eccbe40-1b3b-461b-95df-35f1fe6a8af2": "Fecha/hora"
        },
        "isRequiredOptions": {
            "true": "Sí",
            "false": "No"
        },
        "displaySequence": "Secuencia de visualización",
        "fieldDomain": "Opciones",
        "success": {
            "deleteCustomFieldSuccess": "Eliminación exitosa del campo personalizado",
            "saveCustomFieldsDisplayOrderSuccess": "Se ha guardado exitosamente el orden de visualización",
            "saveCustomFieldSuccess": "Se ha guardado exitosamente el campo personalizado"
        },
        "errors": {
            "getCustomFieldsError": "No se pudieron cargar los campos personalizados",
            "saveCustomFieldsDisplayOrderError": "Error al guardar el nuevo orden de visualización",
            "deleteCustomFieldError": "Error al eliminar el campo personalizado",
            "customFieldWithSameNameError": "Error al guardar el campo personalizado con el mismo nombre",
            "saveCustomFieldError": "Error al guardar el campo personalizado",
            "customFieldOptionWithSameNameError": "Error al guardar el campo personalizado con nombres de opciones duplicados",
            "customFieldTypeSelectOneWithoutOptions": "Las opciones son obligatorias para los campos personalizados \"Seleccione uno\"",
            "customFieldsInvalid": "Revisar los campos personalizados"
        },
        "help": {
            "fieldNameTooltip": "Nombre de etiqueta del campo personalizado",
            "fieldTypeTooltip": "Tipo de datos para recopilar con este campo personalizado",
            "isRequiredTooltip": "Designar si este campo personalizado es requerido",
            "editCustomFieldInstructions": "Editar qué tipo de datos recopilar y si se requiere o no este campo personalizado.",
            "addCustomFieldInstructions": "Agregar un campo personalizado para recopilar información.",
            "optionsTooltip": "Enumerar las opciones en orden de visualización."
        },
        "editCustomField": "Editar campos personalizados existentes",
        "addCustomField": "Agregar nuevo campo personalizado",
        "newCustomField": "Nuevo campo personalizado",
        "confirmRemoveCustomField": {
            "header": "Eliminar campo personalizado",
            "body": "¿Está seguro de que desea eliminar este campo personalizado?",
            "text": "Eliminar"
        },
        "confirmSaveCustomField": {
            "header": "Guardar campo personalizado",
            "body": "¿Está seguro de que desea guardar este campo personalizado?",
            "text": "Guardar"
        },
        "customFields": "Campos personalizados",
        "optionName": "Nombre de opción",
        "addOption": "Agregar opción",
        "default": "Defecto",
        "restore": "Restaurar",
        "options": "Opciones",
        "makeDefault": "Configurar como predeterminado",
        "saveDisplayOrder": {
            "cancelDisplayOrder": "Cancelar orden",
            "saveDisplayOrder": "Guardar orden",
            "header": "Guardar nuevo orden de visualización",
            "body": "¿Está seguro de que desea cambiar el orden en que se muestran los campos?",
            "text": "Guardar orden de visualización"
        },
        "customFieldsActivity": "Actividad de campos personalizados",
        "deprecatedOption": "Opción obsoleta",
        "customFieldsDescription": "Se agregarán preguntas personalizadas al flujo de su planificador y las respuestas se mostrarán en su plan y en su administrador de incidentes.",
        "businessContinuity": "Continuidad del negocio",
        "disasterRecovery": "Recuperación de desastres",
        "bcCustomQuestionsTitle": "Preguntas personalizadas sobre continuidad del negocio",
        "bcCustomQuestionsDescription": "Gestione sus preguntas aduaneras sobre continuidad del negocio aquí.",
        "drCustomQuestionsTitle": "Preguntas personalizadas sobre recuperación ante desastres",
        "drCustomQuestionsDescription": "Administre aquí sus preguntas personalizadas sobre recuperación de desastres por tipo de [[technology]].",
        "technology": "[[Technology]]",
        "technologyTypes": "Tipos de [[Technology]]",
        "continuityOfOperationPlanning": "Continuidad",
        "customQuestionsDescription": "Gestiona aquí tus dudas sobre continuidad aduanera."
    },
    "iqStatus": {
        "0": "",
        "1": "No establecido"
    },
    "cre": {
        "locations": "[[Locations]]",
        "location": "[[Location]]"
    },
    "apiManagement": {
        "newApi": "Nuevo",
        "thirdPartyIntegrations": "Integraciones de terceros",
        "integrationName": "Nombre",
        "appId": "ID de aplicación",
        "apiKey": "Clave API",
        "addApiHeader": "Nueva integración",
        "confirmButtonText": "Guardar",
        "deleteApi": "Eliminar",
        "confirmRemoveHeader": "Eliminar integración",
        "removeConfirm": "Esta integración se eliminará de todas las compañías que la utilizan actualmente. ¿Está seguro de que desea eliminar esta integración?",
        "errorUnableToLoad": "No se pueden cargar integraciones de terceros en este momento.",
        "errorUnableToDelete": "No se puede eliminar esta integración de terceros en este momento.",
        "successDelete": "Se eliminó exitosamente la integración de terceros",
        "successCreate": "Se creó exitosamente la integración de terceros"
    },
    "panicTriggers": {
        "panicTriggerConfiguration": "Configuración del disparador de pánico",
        "panicTriggerLocations": "Ubicaciones del disparador de pánico",
        "managePanicTriggers": "Gestionar disparador de pánico",
        "panicTriggersDescription": "Los incidentes se pueden disparar a través del botón de pánico de la aplicación móvil. Esta página permite configurar el mensaje guardado del incidente.",
        "lblEnablePanic": "Habilitar [[alert]] de pánico",
        "editMessageTemplate": "Editar disparador",
        "deleteMessageTemplate": "Eliminar disparador",
        "addMessageTemplate": "Agregar disparador predeterminado",
        "addCustomizedByLocation": "Agregar disparador personalizado por ubicaciones",
        "contacts": "Contactos",
        "filters": "Filtros",
        "locations": "Ubicaciones",
        "groups": "Grupos",
        "recipients": "Destinatarios",
        "btnSaveAndConfigureMessage": "Aceptar y configurar mensaje",
        "btnConfigureMessage": "Configurar mensaje",
        "deleteConfirmation": {
            "header": "Eliminar disparador de pánico",
            "body": "¿Está seguro de que desea eliminar este disparador de pánico?",
            "text": "Eliminar"
        },
        "enablePanicConfirmation": {
            "header": "Habilitar [[alert]] de pánico",
            "body": "¿Está seguro de que desea habilitar la [[alert]] de pánico?",
            "text": "Habilitar"
        },
        "disablePanicConfirmation": {
            "header": "Deshabilitar [[alert]] de pánico",
            "body": "¿Está seguro de que desea deshabilitar la [[alert]] de pánico?",
            "text": "Inhabilitar"
        },
        "disablePanicAndRemovePanicButtonsConfirmation": {
            "header": "Elimine los botones de [[alert]] y desactive la [[alert]] de pánico",
            "body": "<p>Actualmente existe un botón de [[alert]] de pánico o de registro programado en la aplicación móvil. (Consulte Configuración de [[alert]] iniciados por el móvi.).</p><p>Desactivar [[alert]] de pánico eliminará estos botones. ¿Está seguro de que desea eliminar estos botones y deshabilitar la [[alert]] de pánico?</p>",
            "text": "Eliminar botones y deshabilitar"
        },
        "locationsNotAvailable": "No hay ubicaciones disponibles para crear un disparador de pánico.",
        "panicEnabledSuccess": "La [[alert]] de pánico está habilitada",
        "panicDisabledSuccess": "La [[alert]] de pánico está deshabilitada",
        "panicEnableFailed": "No se pudo habilitar la [[alert]] de pánico",
        "panicDisableFailed": "No se pudo deshabilitar la [[alert]] de pánico"
    },
    "panic": {
        "panic": "[[Alert]] de pánico",
        "initiator": "Iniciador",
        "lastLocation": "Última ubicación",
        "status": "Estado",
        "startTime": "Hora de inicio",
        "resolutionTime": "Tiempo de resolución",
        "gotToIncidentDetail": "Detalles del incidente",
        "updateStatus": "Estado de actualización",
        "archiveSelected": "Archivo seleccionado",
        "active": "Activo",
        "scheduledCheckIn": "Registro programado",
        "archived": "Archivado",
        "resolved": "Resuelto",
        "scheduled": "Programado",
        "noPanic": "Sin [[alert]] de pánico",
        "resolutionReasons": {
            "mistake": "Error",
            "clear": "Quitado",
            "checkIn": "Registro",
            "custom": "Personalizado"
        },
        "resolve": "Resolver",
        "comments": "Comentarios",
        "noComments": "Sin comentarios",
        "resolveComments": "Resolver comentarios",
        "reason": "Razón",
        "noLastLocationRecorded": "No se registró la última ubicación",
        "archiveBody": "¿Está seguro de que desea archivar esto?",
        "archive": "Archivar",
        "scheduledCheckInMinutes": "Registro programado en {{minutes}} minutos",
        "panicDashboard": "Tablero de pánico",
        "showHistory": "Mostrar Breadcrumb",
        "options": "Opciones",
        "showLocations": "Mostrar ubicaciones",
        "showPOIs": "Mostrar POI",
        "geolocations": "Geolocalizaciones",
        "showUsers": "Mostrar otros usuarios",
        "remaining": "Restante",
        "resolveCommentsRequired": "Resolver comentarios requeridos",
        "clickToView": "Hacer clic para ver",
        "scheduledTime": "Programar Creado en",
        "buttonName": "Nombre de [[alert]]",
        "emergencyButtonDashboard": "Alertas iniciados por el móvil",
        "language": "Idioma"
    },
    "branding": {
        "branding": "Terminología",
        "brandingDescription": "Sustituir algunos términos en el portal. El usuario debe cerrar la sesión y volver a iniciar sesión para ver los nuevos términos en el portal.",
        "term": "Término",
        "brandedTerm": "Marca",
        "brandedTermCamel": "Mayúsculas",
        "brandedTermLower": "Minúsculas",
        "brandedTermCamelPl": "Plural en mayúsculas",
        "brandedTermLowerPl": "Plural en minúsculas",
        "brandedTerms": "Términos de marca",
        "addBrandedTerm": "Agregar término",
        "brandingActivity": "Actividad de marcado",
        "language": "Idioma"
    },
    "users": {
        "users": "Usuarios",
        "lastName": "Apellido",
        "firstName": "Nombre",
        "email": "Correo electrónico",
        "role": "Rol",
        "status": "Estado",
        "usersFilterPlaceHolder": "Refinar los resultados por: Nombre o correo electrónico",
        "newUser": "Nuevo usuario",
        "existingUser": "Usuario Existente",
        "isSelfRegistered": "Incorporado en",
        "bulkResendInviteModel": {
            "header": "Reenviar invitaciones a usuarios pendientes",
            "body": "Hay {{ x }} usuarios mostrados que están en estado pendiente. ¿Quiere reenviar invitaciones solo a los usuarios pendientes mostrados o a todos los usuarios pendientes? El envío de una nueva invitación invalidará todas las invitaciones existentes.",
            "allPending": "A todos los pendientes",
            "displayedPending": "A los pendientes mostrados"
        },
        "individualResendInviteModel": {
            "header": "Reenviar la invitación a un usuario pendiente",
            "body": "¿Está seguro de que desea volver a enviar la invitación a este {{ userName }}?",
            "text": "OK"
        },
        "invitationSentMsg": "Se ha enviado la invitación.",
        "invitationSentError": "No se puede reenviar la invitación.",
        "invitationsSentMsg": "Se han enviado invitaciones a los usuarios pendientes designados.",
        "invitationsSentError": "No se pueden reenviar las invitaciones a los usuarios pendientes designados.",
        "resendInvite": "Reenviar invitación",
        "export": {
            "allUsers": "A todos los usuarios",
            "displayedUsers": "A los usuarios mostrados",
            "header": "Exportar usuarios",
            "body": "¿Desea exportar solo los usuarios {{ x }} mostrados o todos los usuarios?",
            "successMsg": "Exportación generada con éxito",
            "errorMsg": "Error al generar la exportación",
            "fileName": "BulkUserUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Usuarios de carga masiva",
            "msgBulkUploadInstructions": "¿Tiene varios usuarios que desea agregar o modificar? Para iniciar el proceso, elija un archivo de su computadora que tenga el formato correcto.",
            "msgDropFile": "Suelte el archivo aquí o haga clic para seleccionar un archivo",
            "lblSelectedFile": "Archivo seleccionado para cargar",
            "lblNoFileSelected": "Ningún archivo especificado.",
            "lblNeedStartingPoint": "Necesita un punto de partida",
            "lblAddMultipleHeader": "Agregar varios usuarios nuevos",
            "lblAddMultipleDetail": "Descargue esta hoja de cálculo que contiene solo la información del encabezado y complete la información de su usuario nuevo, un usuario por fila, antes de guardarla en formato .CSV en su computadora",
            "btnBlankFile": "Archivo de usuario en blanco",
            "lblEditMultipleHeader": "Editar o eliminar varios usuarios",
            "lblEditMultipleDetail": "Descargue esta hoja de cálculo que contiene su información de usuario actual y modifíquela según sea necesario antes de guardarla en formato .CSV en su computadora.",
            "btnCurrent": "Usuarios actuales",
            "lblEditMultipleNotes": "NOTA: Para eliminar usuarios, cambie su estado a Eliminar. Eliminarlos de la hoja de cálculo no los eliminará del sistema. La carga masiva se puede completar incluso si no rellena todos los campos de un usuario. Para eliminar usuarios, cambie su estado a Eliminar. Eliminarlos de la hoja de cálculo no los eliminará del sistema. La carga masiva se puede completar incluso si no rellena todos los campos de un usuario.",
            "lblUpload": "Enviar",
            "btnCancel": "Cancelar",
            "errorUserExportFailed": "No se puede exportar un archivo de usuario.",
            "msgUserExportSuccessful": "La exportación del usuario se completó exitosamente.",
            "msgTestBulkUploadFile": "Este archivo solo se validará porque el nombre del archivo contiene la palabra \"test\"; las actualizaciones no se procesarán.",
            "msgUploadSuccess": "Carga masiva exitosa.",
            "msgExportSuccessful": "Exportación generada con éxito.",
            "errorExportFailed": "No se puede exportar el archivo.",
            "msgValidatingFile": "Verificando archivo...",
            "errorInvalidBulkFileType": "El archivo seleccionado tiene una extensión de archivo incorrecta: debe tener una extensión .CSV."
        }
    },
    "variableContentTag": {
        "insert": "Insertar",
        "companyName": "Nombre de la compañía",
        "address": "Dirección",
        "startDate": "Fecha de inicio",
        "initiatedByName": "Iniciado por nombre",
        "resolvedDate": "Fecha de resolución",
        "resolvedByName": "Resuelto por nombre",
        "comments": "Comentarios",
        "resolveReason": "Resolver razón",
        "resolveComments": "Resolver comentarios",
        "insertButtonTooltip": "Seleccione un valor en la lista para agregar el parámetro al mensaje. Los valores actuales se sustituirán cuando se envíe el mensaje."
    },
    "bulkUploadHistory": {
        "dateTimeSubmitted": "Fecha de envío",
        "totalCount": "Total",
        "validCount": "Válido",
        "errorCount": "Errores",
        "originalInputFileName": "Fichero de entrada",
        "canonicalCsvFileName": "Archivo de informe",
        "errorCsvFileName": "Archivo de error",
        "title": "Historial de carga masiva"
    },
    "home": {
        "accessDocuments": "Documentos de acceso",
        "gotoTraining": "Ir a Entrenamiento",
        "gotoResources": "Ir a Recursos",
        "incidentHistory": "Historial de incidentes",
        "manageCrisisTeam": "Gestionar el [[team]] de crisis",
        "callInAnnouncement": "Anuncio de llamada",
        "conferenceCalls": "Llamada de conferencia",
        "crisisTeam": "[[Team]] de crisis",
        "viewAllCrisisTeams": "Ver todos los [[team]]s de crisis",
        "cyber": "Cyber",
        "documents": "Documentos",
        "emergencyContacts": "Contactos de emergencia",
        "featuredCourses": "Cursos destacados",
        "firstAid": "Primeros auxilios",
        "health": "salud",
        "incidentManagement": "[[Incident Manager]]",
        "incidents": "incidentes",
        "learn": " Aprender",
        "naturalDisasters": "Desastres naturales",
        "newIncident": "Nuevo incidente",
        "recentNews": "Noticias recientes",
        "resources": "Recursos",
        "respondToIncident": "Responder al incidente",
        "sendEmergencyMessage": "Enviar mensaje",
        "splashMessage": "Mensaje Splash",
        "terrorism": "Terrorismo",
        "training": "Formación",
        "workplace": "Lugar de trabajo",
        "featureCoursesInstructions": "Realice nuestros cursos de formación en línea para desarrollar sus conocimientos y preparación.",
        "incidentOptions": "Comuníquese con eficacia antes, durante y después de un incidente",
        "incidentMgmtSummary": "Acceda a información, herramientas y [[team]]s críticos",
        "resourceList": "AMENAZAS, LISTAS DE VERIFICACIÓN, TABLEROS Y MÁS",
        "resourceInstructions": "Explore nuestra información detallada para aprender la mejor manera de prepararse y responder de manera efectiva y segura.",
        "mainPhone": "Principal",
        "securityPhone": "Seguridad",
        "emergencyPhone": "Llamada de emergencia",
        "announcementsPhone": "Anuncios",
        "teamMembers": "[[Members]] del [[team]] de crisis ({{count}})",
        "viewCrisisTeamPage": "Ver página del [[team]]",
        "iq": "iQ",
        "complianceCalendar": "Calendario de cumplimiento",
        "plans": "[[Plans]]",
        "iqScore": "Puntaje iQ",
        "reports": "[[Reports]]",
        "featuredResources": "Recursos de funciones",
        "announcement": "Anuncio",
        "noneOnRecord": "Ninguno registrado",
        "messaging": "Mensajería",
        "sinkSendMessageDescription": "Envíe mensajes de emergencia bidireccionales con voz, texto SMS, correo electrónico, notificaciones Push y de escritorio.",
        "sinkAlertDescription": "Seleccione ubicaciones para colocarlas en estado de [[alert]] con Preparis.",
        "communicationsManagement": "Gestión de comunicaciones",
        "communicationsManagementDescription": "Comuníquese con eficacia antes, durante y después de un incidente",
        "resourcesDescription": "Explore nuestra información detallada para aprender la mejor manera de prepararse y responder de manera eficaz y segura",
        "viewResources": "Ver recursos",
        "resourcesSubtitle": "Amenazas, listas de verificación, tableros y más",
        "trainingDescription": "Realice nuestros cursos de formación en línea para desarrollar sus conocimientos y preparación",
        "moreInformation": "Más información",
        "sendNotifications": "Enviar notificaciones",
        "msgLoadingWeatherData": "Cargando información meteorológica...",
        "viewDocuments": "Ver documentos",
        "viewHistory": "Ver historial",
        "bcPlanningDescription": "Planifique, pruebe y esté preparado para un incidente",
        "bcDashboardDescription": "Revise el progreso de su [[team]] junto con los objetivos de tiempo de recuperación seleccionados",
        "goToDashboard": "Ir al panel de control",
        "bcPlannerDescription": "¡Empecemos a planificar! Forme su [[team]] de expertos",
        "startPlanning": "Empiece a planificar",
        "agilityPlanner": "[[Planner]] Preparis",
        "buildYourPlan": "Desarrolle su [[plan]]",
        "documentsDescription": "Almacene documentación importante en una ubicación basada en la nube.",
        "bcIncidentManagement": "Informar Nuevo Incidente",
        "bcIncidentManagementDescription": "Informe y gestione rápidamente su incidente",
        "bcIncidentHistoryDescription": "Ver el historial de incidentes actuales o pasados",
        "safetyPassTitle": "Pase de seguridad",
        "safetyPassDescriptions": "Evaluación previa de salud digital, verificación de ingreso a las instalaciones, monitoreo de datos de seguridad y rastreo de contactos cercanos"
    },
    "documents": {
        "documents": "Documentos",
        "lblName": "Nombre",
        "lblOwner": "Propietario",
        "lblDateTimeModified": "Última modificación",
        "fileDetails": "Detalles del documento",
        "folderDetails": "Detalles de la carpeta",
        "lblSelectDocumentForDetail": "Seleccione un archivo o carpeta para ver sus detalles",
        "lblSelectDocumentForPermission": "Seleccione una carpeta para ver sus permisos",
        "lblType": "Tipo",
        "lblCreatedBy": "Propietario/Creado por",
        "lblCreatedOn": "Creado en",
        "lblLastModifiedBy": "Modificado por",
        "lblLastModifiedOn": "Modificado en",
        "lblSize": "Tamaño",
        "lblFolder": "Carpeta",
        "lblDetails": "Detalles",
        "lblPermissions": "Permisos",
        "lblIsPrivate": "Documento privado",
        "lblSetDocumentPrivate": "Establecer documento privado",
        "lblCurrentPermissions": "Permisos actuales",
        "lblNewPermissions": "Nuevos permisos",
        "msgIsPrivate": "Este documento es privado y solo puede ser consultado por el propietario y el administrador del documento",
        "lblPages": "Páginas",
        "lblInherited": "(Heredado)",
        "successRenameDocumentMessage": "La información del documento se ha actualizado",
        "successRenameFolderMessage": "La información de la carpeta se ha actualizado",
        "errorRenameDocumentMessage": "No se pudo actualizar la información del documento",
        "errorRenameFolderMessage": "No se pudo actualizar la información de la carpeta",
        "errorDocumentNameExist": "Ya existe un documento con el mismo nombre; ingrese un nombre de documento diferente.",
        "errorFolderNameExist": "Ya existe una carpeta con el mismo nombre; ingrese un nombre de carpeta diferente.",
        "errorCheckDocumentStatus": "No se pudo verificar el estado del documento.",
        "errorCheckFolderStatus": "No se pudo verificar el estado de la carpeta.",
        "msgErrorDownloadFileNoContent": "¡Falta el contenido del archivo o está dañado!",
        "successCreateFolderMessage": "Se creó la carpeta {{ name }}",
        "errorCreateFolderMessage": "No se pudo crear la carpeta {{ name }}",
        "lblAreYouSureDeleteDocument": "¿Está seguro de que quiere eliminarlo? ",
        "msgAccessDenied": "No tiene los permisos para eliminar esta carpeta",
        "msgCannotDeleteRoot": "La carpeta raíz no se puede eliminar",
        "msgFolderHasContents": "Esta carpeta no se puede eliminar porque no está vacía; elimine todas las carpetas y documentos que contiene antes de eliminar la carpeta.",
        "successDeleteDocumentMessage": "Archivo {{ name }} fué borrado.",
        "successDeleteFolderMessage": "Carpeta {{ name }} fué borrada.",
        "errorDeleteDocumentMessage": "No se pudo eliminar el archivo {{ name }}",
        "errorDeleteFolderMessage": "No se pudo eliminar la carpeta {{ name }}",
        "successMoveFileMessage": "El archivo {{ name1 }} se mueve a la carpeta {{ name2 }}",
        "successMoveFolderMessage": "La carpeta {{ name1 }} se mueve a la carpeta {{ name2 }}",
        "errorMoveFileMessage": "No se pudo mover el archivo {{ name1 }} a la carpeta {{ name2 }}",
        "errorMoveFolderMessage": "No se pudo mover la carpeta {{ name1 }} bajo la carpeta {{ name2 }}",
        "noPermissionMoveFileMessage": "No puede mover este archivo a la carpeta {{ name1 }}",
        "noPermissionMoveFolderMessage": "No puede mover esta carpeta a la carpeta {{ name1 }}",
        "msgDocumentExists": "El documento con el mismo nombre ya existe en la carpeta seleccionada; cambie el nombre del documento o seleccione un documento diferente.",
        "msgFolderExists": "La carpeta con el mismo nombre ya existe en la carpeta seleccionada; cambie el nombre de la carpeta o seleccione una carpeta diferente.",
        "successUploadFileMessage": "El archivo {{ name }} está cargado",
        "errorUploadFileMessage": "No se pudo cargar el archivo {{ name }}",
        "msgSameFolder": "No se puede mover la carpeta dentro de sí misma",
        "msgCrisisTeamPage": "Permita el acceso al documento desde cualquiera de las siguientes páginas de [[team]]s de crisis.",
        "msgLoadingCrisisTeams": "Cargando [[team]]s de crisis",
        "msgNoCrisisTeam": "Sin [[team]] de crisis",
        "successSetDocumentPrivateMsg": "La privacidad del documento se actualizó exitosamente",
        "failSetDocumentPrivateMsg": "No se pudo actualizar la privacidad del documento",
        "successDeleteCrisisTeamMessage": "El documento se elimina de la página del [[team]] de crisis para {{ name }}",
        "errorDeleteCrisisTeamMessage": "No se pudo eliminar el documento de la página del [[team]] de crisis para {{ name }}",
        "successAddCrisisTeamsMessage": "Se agregan [[team]]s de crisis",
        "warningDocumentExist": "El archivo adjunto con el mismo nombre ya existe, ¿le gustaría reemplazar el archivo adjunto existente o guardarlo como un nuevo archivo adjunto?",
        "warningReplaceDocument": "¿Está seguro de que desea reemplazar el documento existente con el documento seleccionado?",
        "lblViewOnlyAccess": "Ver solo acceso",
        "lblEditAccess": "Editar acceso",
        "lblCrisisTeamPages": "Páginas del [[team]] de crisis",
        "errorAddCrisisTeamsMessage": "Error al agregar [[team]]s de crisis",
        "successSetDocumentAccessGroupsMessage": "Se guardó el permiso de acceso al documento",
        "errorSetDocumentAccessGroupsMessage": "No se pudo guardar el permiso de acceso al documento",
        "successSetFolderAccessGroupsMessage": "Se guardó el permiso de acceso a la carpeta",
        "errorSetFolderAccessGroupsMessage": "No se pudo guardar el permiso de acceso a la carpeta",
        "lblCrisisTeams": "[[Team]]s de crisis",
        "lblConfirmDeleteCrisisTeam": "¿Está seguro de que desea eliminar este documento de la página de {{ name }} del [[team]] de crisis?",
        "lblConfirmPrivateDocument": "<p>¿Está seguro de que desea que este documento sea privado?</p><p> Un documento privado solo es accesible por el propietario y el usuario con función de administración de documentos.</p>",
        "lblConfirmNonPrivateDocument": "<p>¿Estás seguro de que quieres que este documento herede los permisos de acceso de la carpeta que lo contiene?</p><p> Un documento no privado heredará los grupos de acceso de su carpeta de forma predeterminada.</p>",
        "lblMoveDocumentTo": "Mover a",
        "lblNewFolder": "Crear nueva carpeta",
        "lblDocumentDescription": "Descripción del documento",
        "folderName": "Nombre de la carpeta",
        "lblEveryoneHasAccess": "Ha seleccionado dar acceso a todos para ver este archivo.",
        "lblViewAccessGroupsHeader": "Actualizar los permisos VER SOLO para: {{ name }}",
        "lblEditAccessGroupsHeader": "Actualizar los permisos para EDITAR para: {{ name }}",
        "lblViewAccessGroupInformation": "Seleccione de cualquier categoría de las indicadas a continuación o elija solo una para establecer quién puede ver el archivo.",
        "lblEditAccessGroupInformation": "Seleccione de cualquier categoría de las indicadas a continuación o elija solo una para establecer quién puede editar el archivo.",
        "lblAccessFor": "Otorgue acceso a los usuarios que están en",
        "lblAccessGroupNone": "Sin usuarios",
        "lblKeepPermissions": "Mantener los permisos actuales",
        "lblUseNewPermissions": "Usar nuevos permisos",
        "noDocuments": "Sin documentos",
        "msgPermissionChanges": "El mensaje relacionado con el cambio de permiso va aquí",
        "msgNoPermissionToMoveDocument": "No tiene los permisos necesarios para moverse a esta carpeta.",
        "msgFolderItself": "No puede mover la carpeta a sí misma",
        "msgSameAsParentFolder": "Esta es la misma carpeta principal",
        "msgKeepPermissionWarnings": "Mantener los permisos actuales puede ocasionar el [[risk]] de conflictos con los permisos de la nueva carpeta. Esto puede hacer que el documento o la carpeta sean inaccesible para los usuarios, excepto para los administradores de documentos.",
        "msgFolderPermissionWarnings": "Cambiar los permisos de esta carpeta afectará los permisos heredados de todos los archivos en la carpeta, pero los permisos de todas las subcarpetas seguirán siendo los mismos",
        "folderPath": "Camino",
        "successUploadFilesMessage": "Los archivos se cargaron exitosamente",
        "errorUploadFilesMessage": "No se pudieron cargar archivos",
        "errorDocumentNameExistInReplace": "El documento con el mismo nombre ya existe; cargue un documento con un nombre diferente.",
        "lblReplaceTitle": "Reemplazar documento",
        "fileType": {
            "unknown": "Desconocido",
            "MP3": "Archivo de audio",
            "MP4": "Archivo de audio",
            "BMP": "Imagen de mapa de bits",
            "CSS": "Hoja de estilo CSS",
            "CSV": "Documento de Excel",
            "DOC": "Documento de Word",
            "DOCX": "Documento de Word",
            "HTM": "Archivo HTML",
            "HTML": "Archivo HTML",
            "HTMLS": "Archivo HTML",
            "ICO": "Imagen de icono",
            "JPE": "Imagen jpeg",
            "JPEG": "Imagen jpeg",
            "JPG": "Archivo jpeg",
            "JS": "Archivo Javascript",
            "PDF": "Archivo PDF de Acrobat",
            "PNG": "Imagen png",
            "GIF": "Imagen gif",
            "PPT": "Documento de Powerpoint",
            "RTF": "Archivo de texto",
            "TEXT": "Documento de texto",
            "TIF": "Imagen Tif",
            "TIFF": "Imagen Tif",
            "TXT": "Documento de texto",
            "Unknown": "Desconocido",
            "WAV": "Audio Wave",
            "XLC": "Documento de Excel",
            "XLM": "Documento de Excel",
            "XLS": "Documento de Excel",
            "XLSX": "Documento de Excel",
            "XLT": "Documento de Excel",
            "PPTX": "Documento de PowerPoint",
            "XML": "Archivo XML",
            "ZIP": "Archivos comprimidos",
            "RAR": "Archivos comprimidos",
            "EXE": "Aplicación"
        },
        "itemsCategories": {
            "image": "Imagen",
            "wordDocument": "Documento de Word",
            "spreadsheet": "Hoja de cálculo",
            "document": "Documento",
            "folder": "Carpeta",
            "message": "Mensaje",
            "audio": "Audio",
            "text": "Texto"
        },
        "uploadNewDocument": "Cargar nuevo documento",
        "title": "Documentos",
        "searchPlaceholder": "Buscar un documento",
        "addNew": "Cargar nuevo documento",
        "createFolder": "Nueva carpeta",
        "searchTitle": "Resultados de la búsqueda",
        "or": "o",
        "allUsers": "Todos los usuarios",
        "lblPermissionsHeader": "Permisos de documentos"
    },
    "myPreparis": {
        "lblMyPreparis": "Mi Preparis",
        "lblMyDocuments": "Mis documentos",
        "lblMyBookmarks": "Mis marcadores",
        "lblDeleteBookmark": "Eliminar marcador",
        "lblNavigatePage": "Navegar a la página",
        "lblAreYouSureDeleteBookmark": "¿Está seguro de que quiere eliminar el marcador {{ name }}?",
        "msgSuccessDeleteBookmark": "El marcador {{bookmark}} ha sido eliminado",
        "msgErrorDeleteBookmark": "No se pudo eliminar el marcador {{bookmark}}",
        "lblSelectBookmarkForDetail": "Seleccione un marcador para ver sus detalles",
        "lblBookmarkDetails": "Detalles del marcador",
        "lblNote": "Nota",
        "bookmarkTypePage": "Página",
        "lblBookmarkOn": "Marcado como favorito",
        "msgSuccessUpdateBookmark": "El marcador {{ name }} está actualizado",
        "msgErrorUpdateBookmark": "No se pudo actualizar el marcador {{ name }}"
    },
    "resources": {
        "resources": "Recursos",
        "threats": "Amenazas",
        "tabletops": "Tabletops",
        "checklists": "Listas de verificación",
        "webinars": "Seminarios web",
        "more": "Más",
        "featuredThreats": "Amenazas destacadas",
        "otherFeaturedResources": "Otros recursos destacados",
        "byCategory": "Por categoría",
        "sort": "Ordenar",
        "searchCurrentSelections": "Buscar selecciones actuales",
        "featured": "Destacados",
        "noResources": "Sin recursos",
        "supportingResources": "Recursos de apoyo",
        "relatedResources": "Recursos relacionados",
        "newResources": "Nuevos recursos",
        "webinarsDescription": "Preparis organiza regularmente sesiones de seminarios web sobre las amenazas más críticas para las empresas y los edificios, creadas por un [[team]] de expertos en la materia para una variedad de industrias. Haga clic a continuación para ver algunas de nuestras sesiones más populares y recientes:",
        "threatsDescriptionOverview": "Información fundamental sobre una amplia gama de amenazas, incluidos signos o síntomas, métodos de preparación, pasos de respuesta y pautas de recuperación.",
        "checklistsDescriptionOverview": "Listas de verificación basadas en acciones que cubren una variedad de amenazas y temas de políticas orientadas a ayudarlo a elaborar su programa de continuidad empresarial.",
        "tabletopsDescriptionOverview": "Ponga a prueba sus [[plans]] y su nivel de preparación en un entorno grupal usando nuestros ejercicios de mesa.",
        "moreResourcesDescriptionOverview": "Obtenga más información sobre su rol y protocolos y encuentre información y herramientas adicionales que pueden ayudarlo durante una crisis, como infografías, plantillas y hojas de referencia.",
        "webinarsDescriptionOverview": "Sesiones de seminarios web sobre las amenazas más críticas para las empresas y los edificios, creadas por un [[team]] de expertos en la materia en una variedad de industrias.",
        "inTheSpotLight": "En el punto de mira"
    },
    "training": {
        "training": "Capacitación",
        "brandNewCourses": "Cursos nuevos",
        "featuredCourses": "Cursos destacados",
        "noNewCourses": "No hay cursos nuevos",
        "noFeaturedCourses": "No hay cursos destacados",
        "noTrainingCourses": "No hay cursos de capacitación",
        "startedOn": "Comenzó el",
        "completedOn": "Completado el",
        "resume": "Currículum",
        "retake": "Recuperación",
        "launch": "Lanzamiento",
        "similarCourses": "Cursos similares",
        "noSimilarCourses": "No hay cursos similares",
        "courseHistory": "Historia del curso",
        "firstAccessDate": "Fecha de primer acceso",
        "lastAccessDate": "Fecha de último acceso",
        "completedDate": "Fecha de completado",
        "errorLaunchingTrainingCourse": "Se produjo un error al iniciar este curso de capacitación",
        "filter": "Filtrar",
        "incomplete": "Incompleto",
        "completed": "Completado",
        "inProgress": "En curso",
        "status": "Estado",
        "completionCertificate": "Certificado de finalización"
    },
    "portal": {
        "navigate": {
            "navigatePortal": "Navegar portal",
            "changePortal": "Cambiar el portal",
            "msgSwitchPortalFailed": "No se pudo cambiar al portal {{ portalName }}.",
            "msgSwitchPortalSucceed": "Cambio al portal {{ portalName }} realizado con éxito",
            "msgErrorRetrievingPortalList": "No se pudo recuperar una lista de portales.",
            "portalName": "Nombre del portal...",
            "searchResults": "Sólo se muestran los primeros 50 registros."
        },
        "modules": {
            "errorSavePortalModules": "No se pudo actualizar los módulos del portal.",
            "msgSavePortalSettingsSuccess": "La [[setting]] del portal se guarda.",
            "lblModulesSetup": "Configuración de módulos",
            "lblAlwaysAllowed": "Siempre permitido",
            "lblAllowed": "Permitido",
            "lblNotAllowed": "No permitido"
        },
        "portal": "Portal",
        "portals": "Portales",
        "newPortal": "Nuevo portal",
        "name": "Nombre",
        "parentName": "Nombre de la carpeta principal",
        "parentPortalName": "Nombre del portal principal",
        "verticalType": "Tipo vertical",
        "portalSubdomain": "Subdominio del portal",
        "portalName": "Nombre del portal",
        "numberOfUsersAssignedTo": "cantidad de usuarios asignados a",
        "callInNumberSetup": "Configuración del número de llamada entrante",
        "childPortal": "Portal infantil",
        "modulesSetup": "Configuración de módulos",
        "portalsFilterPlaceHolder": "Refinar los resultados por: Nombre o carpeta principal",
        "errorLoadingPortals": "Error al cargar la lista de portales",
        "createPortal": "Crear portal",
        "createChildPortal": "Crear portal secundario",
        "savePortalSuccess": "El portal se ha guardado correctamente",
        "savePortalError": "No se guardaron los cambios del portal",
        "industry": "Industria",
        "callInNumber": {
            "errorRegisteringNewNumber": "Hubo un [[issue]] al registrar su nuevo número.",
            "successRegisteringNewNumber": "Número nuevo registrado correctamente.",
            "errorDeregisteringExistingNumber": "Hubo un [[issue]] al cancelar el registro de su número anterior.",
            "phoneNumberReleaseSuccess": "{{ currentPhoneNumber }} ha sido liberado.",
            "modalTitle": "Configuración de anuncios de llamadas entrantes",
            "newTelephoneNumberInstructions": "Configure un nuevo número de teléfono de anuncios de llamadas entrantes.",
            "editTelephoneNumberInstructions": "Cambie su número de teléfono de anuncios de llamadas entrantes.",
            "announcementsNumber": "Número de anuncios",
            "loading": "Cargando",
            "releaseNumber": "Número de versión",
            "noNumberAssigned": "Sin número asignado",
            "availableNumbers": "Números disponibles",
            "chooseFromAvailableNumbers": "Elija entre los siguientes números de teléfono:",
            "saveAndUpdate": "Guardar y actualizar",
            "current": "Actual",
            "new": "Nuevo",
            "selectAvailableNumber": "Seleccione un número disponible a continuación",
            "containsNumber": "Contiene",
            "country": "País",
            "areaCode": "Código de área",
            "numberType": "Tipo de número",
            "local": "Local",
            "tollFree": "Gratuito",
            "phoneNumberContains": "Ingrese los dígitos (o caracteres) deseados",
            "search": "Buscar",
            "saveSuccessMsg": "Nuevo número de llamada entrante guardado para {{ portalName }}",
            "configureNewNumber": "Configurar nuevo número"
        },
        "portalSetting": {
            "portalSettings": "[[Setting]] del portal",
            "filterSettingsPlaceHolder": "Filtrar por nombre de [[setting]]...",
            "settingName": "Nombre",
            "settingValue": "Valor",
            "portalSpecific": "Portal específico",
            "showAllSettings": "Mostrar todas las configuraciones",
            "saveSuccess": "La [[setting]] del portal se guarda.",
            "saveError": "No se pudo actualizar la [[setting]] del portal."
        },
        "portalIndustry": "Industria",
        "lblSelectIndustry": "Seleccione una industria",
        "msgFailSaveIndustry": "No se pudo guardar la industria del portal"
    },
    "scheduledMessages": {
        "scheduledMessages": "Mensajes programados",
        "searchScheduledMessagesPlaceholder": "Buscar mensajes programados",
        "newScheduledMessage": "Nuevo mensaje programado",
        "dateCreated": "Fecha de creación",
        "scheduledDate": "Fecha programada",
        "scheduledTime": "Hora programada",
        "timeZone": "Zona horaria",
        "createdBy": "Creado por",
        "errorLoadingScheduledMessagesMsg": "Error al cargar mensajes programados.",
        "deleteScheduledMessageTooltip": "Eliminar mensaje programado",
        "confirmDelete": {
            "header": "Quitar el mensaje programado",
            "body": "¿Está seguro de que desea quitar este mensaje programado?",
            "text": "Quitar"
        },
        "noScheduledMessages": "No hay mensajes programados"
    },
    "manage": {
        "nav": {
            "lblOverview": "Vista general",
            "lblManage": "Gestionar",
            "lblSetup": "Configurar",
            "lblMessaging": "Mensajería",
            "lblSystem": "Sistema",
            "lblRoles": "Roles",
            "lblUsers": "Usuarios",
            "lblLocations": "Ubicaciones",
            "lblDepartments": "[[Departments]]",
            "lblGroups": "Grupos",
            "lblTenants": "Inquilinos",
            "lblGeofences": "Geocercas",
            "lblPOIs": "Punto de interés",
            "lblMessageSettings": "[[Setting]] de mensajería",
            "lblUserInitiatedIncident": "Incidentes iniciados por el usuario",
            "lblIncidentTypes": "Tipos de incidentes",
            "lblAlertSubscription": "Suscripciones a [[alerts]]",
            "lblIncidentTriggers": "Disparadores de incidentes",
            "lblPanicConfiguration": "Configuración de incidentes de pánico",
            "lblMobileEmergencyButtons": "Configuración de [[alert]] iniciados por el móvil",
            "lblCompanyProfile": "Perfil de la empresa",
            "lblSecurity": "Seguridad",
            "lblSystemEmails": "Correos electrónicos del sistema",
            "lblCustomFields": "Campos personalizados",
            "lblBranding": "Personalización",
            "lblSelfRegistration": "Adhesión",
            "lblConfigureBulkUpload": "Configurar la carga masiva"
        },
        "manageEntitySidebar": {
            "lblNA": "N/A",
            "lblCreatedOn": "Creado el",
            "lblCreatedBy": "Creado por",
            "lblLastModifiedOn": "Última modificación el",
            "lblLastModifiedBy": "Última modificación por",
            "lblUserCount": "Cantidad de usuarios"
        },
        "manageMembers": {
            "manageMembers": "Administrar [[members]]",
            "lblManageMembersHeader": "Administrar [[members]] para {{entityTypeName}}",
            "lblManageMembersDirections": "Seleccione usuario(s) y luego use las flechas izquierda y derecha para configurar quién es parte de este {{entityTypeName}} .",
            "btnSaveAndUpdate": "Guardar y actualizar",
            "updateMembersSuccess": "[[Members]] actualizados correctamente",
            "usersNotInEntity": "Usuarios que no están en {{entityTypeName}}",
            "currentEntityMembers": "[[Members]] actuales en {{entityTypeName}}"
        },
        "bulkUpload": {
            "lblBulkUpload": "Carga masiva",
            "lblBulkUploadUsers": "Usuarios de carga masiva",
            "msgBulkUploadInstructions": "¿Desea agregar o modificar múltiples {{entityTypeName}}? Elija un archivo con el formato correcto de su computadora para iniciar el proceso.",
            "msgDropFile": "Suelte el archivo aquí o haga clic para seleccionar un archivo",
            "lblSelectedFile": "Archivo seleccionado para cargar",
            "lblNoFileSelected": "Ningún archivo especificado.",
            "lblNeedStartingPoint": "Se necesita un punto de partida",
            "lblAddMultipleUsersHeader": "Agregar varios usuarios nuevos",
            "lblAddMultipleUsersDetail": "Descargue esta hoja de cálculo que contiene solo la información del encabezado y complete la información de su usuario nuevo, un usuario por fila, antes de guardarla en formato .CSV en su computadora.",
            "btnBlankUserFile": "Archivo de usuario en blanco",
            "lblUpload": "Enviar",
            "btnCancel": "Cancelar",
            "errorUserExportFailed": "No se puede exportar un archivo de usuario.",
            "msgUserExportSuccessful": "La exportación del usuario se completó correctamente.",
            "msgTestBulkUploadFile": "Este archivo solo se validará porque el nombre del archivo contiene la palabra \"test\"; las actualizaciones no se procesarán.",
            "msgUploadUsersSuccess": "Carga masiva exitosa.",
            "msgValidatingFile": "Verificando archivo...",
            "errorInvalidBulkFileType": "El archivo seleccionado tiene una extensión de archivo incorrecta: debe tener una extensión .CSV.",
            "btnBlankEntitiesFile": "Archivo de {{entityTypeName}} en blanco",
            "lblEditMultipleEntitiesDetail": "Descargue esta hoja de cálculo que contiene la información de su [[location]] actual y modifíquela según sea necesario antes de guardarla en formato .CSV en su computador.",
            "lblEditMultipleEntitiesHeader": "Editar o eliminar varios{{entityTypeName}}s",
            "lblEditMultipleEntitiesNotes": "NOTA: Para borrar {{entityTypeName}}s, cambie su estado a Eliminar. Eliminarlos de la hoja de cálculo no los eliminará del sistema. La carga masiva se puede completar incluso si no llena todos los campos para un {{entityTypeName}}. Para ver qué campos son opcionales, consulte la guía del usuario o el área de ayuda en la esquina superior derecha de esta ventana.",
            "btnCurrentEntities": "{{entityTypeName}}s actuales",
            "lblAddMultipleEntitiesHeader": "Agregar varios nuevos {{entityTypeName}}",
            "lblAddMultipleEntitiesDetail": "Descargue esta hoja de cálculo que contiene solo la información del encabezado y complete la información de su nuevO {{entityTypeName}}, un {{entityTypeName}} por fila, antes de guardarla en formato .CSV en su computadora.",
            "msgExportSuccessful": "La exportación se completó correctamente.",
            "errorExportFailed": "No se puede exportar un archivo de usuario.",
            "fileName": "Carga masiva de {{entityTypeName}} en.CSV"
        },
        "roles": {
            "btnCreateRole": "Nuevo rol",
            "btnSaveRole": "Guardar rol",
            "errorCannotDeleteWithUsers": "Este rol no se puede eliminar porque hay usuarios con este rol.",
            "errorCannotDeleteDefaultForBulkUpload": "Este rol no se puede eliminar porque está configurado como rol predeterminado para Carga masiva.",
            "errorDeleteRoleFailed": "Se ha producido un error; eliminar rol.",
            "errorRequiredFields": "Ingrese todos los campos obligatorios.",
            "errorSaveRoleFailed": "Se ha producido un error al guardar el rol.",
            "errorUnableToLoadRole": "No se pueden cargar los detalles del rol; inténtelo de nuevo más tarde.",
            "lblAccessReports": "[[Reports]] de acceso",
            "lblAccessReportsDesc": "Permitir a los usuarios ejecutar y exportar [[reports]].",
            "lblAddUploadDocs": "Agregar/cargar documentos",
            "lblAddUploadOnly": "Solo agregar/cargar",
            "lblAddUploadOnlyDesc": "Permitir a los usuarios SOLAMENTE agregar/cargar nuevos documentos y asignar permisos a esos documentos.",
            "lblAllowed": "Permitido",
            "lblAlwaysAllowed": "Siempre permitido",
            "lblAreYouSureRemoveRole": "¿Está seguro de que desea quitar este rol?",
            "associatedRole": "Rol asociado",
            "lblBasedOnRole": "Basado en rol",
            "lblReportIncident": "Informar incidente",
            "lblReportIncidentDesc": "Permitir al usuario informar incidentes desde la aplicación móvil",
            "lblPanicDesc": "Permitir al usuario informar incidentes de pánico desde la aplicación móvil",
            "lblManageMessages": "Administrar mensajes",
            "lblManageMessagesDesc": "Permitir al usuario editar, mover, eliminar mensajes y configurar permisos de carpeta",
            "lblCommunication": "Comunicación",
            "lblSendMessageDesc": "Permitir a los usuarios enviar mensajes.",
            "lblConfigureMessageDesc": "Permitir a los usuarios crear, actualizar mensajes, pero no permitir que envíen el mensaje.",
            "lblConferenceCallDesc": "Permitir a los usuarios crear llamadas de conferencia.",
            "lblCallInAnnouncementDesc": "Permitir a los usuarios crear anuncios de llamadas entrantes.",
            "lblSplashMessageDesc": "Permitir a los usuarios crear un mensaje de bienvenida.",
            "lblCompanySettings": "[[Setting]] de la empresa",
            "lblCompanySettingsDesc": "Administrar las configuraciones que se aplican a toda la empresa",
            "lblCompanySettingsExplain": "Permitir a los usuarios cambiar el nombre y el correo electrónico de la empresa, el número de visualización de voz, la marca, la [[setting]] de seguridad y los correos electrónicos del sistema.",
            "lblCorePerms": "Permisos básicos (no editables)",
            "lblCrisisTeamContacts": "Contactos del [[team]] de crisis",
            "lblDocumentMgmt": "Administración de documentos",
            "lblDocumentMgmtDesc": "Permitir a los usuarios administrar globalmente todos los documentos en el portal, incluida la capacidad de agregar, modificar y eliminar cualquier documento, así como crear, modificar y eliminar carpetas.",
            "lblDocumentMgmtiQDisclaimer": "Se requiere permiso de iQ para acceder a las carpetas y documentos relacionados con iQ en el portal.",
            "lblDocuments": "Documentos",
            "lblEditEntities": "Editar entidades",
            "lblEntityMgmt": "Gestión de entidades",
            "lblEntityMgmtExplain": "Permitir a los usuarios crear/modificar/eliminar ubicaciones, grupos y [[departments]].",
            "lblHelp": "Ayuda",
            "lblIncidentMgmt": "[[Incident Manager]]",
            "lblIncidentMgmtDesc": "Permitir a los usuarios crear/modificar/resolver incidentes.",
            "lblIncludeCrisisDescription": "Muestra la información de contacto de los usuarios con este rol en la página [[Team]] de crisis para cada [[location]].",
            "lblIncludeCrisisTeamPage": "Incluir en la página del [[team]] de crisis",
            "lblManagePlansDesc": "Allow users to view, activate, deactivate, standby, or stand down department [[plans]]",
            "lblViewPlansDesc": "Provide access to users to view available department [[plans]] and processes",
            "lblListItems": "artículos",
            "lblLocalEmergencyContact": "Contacto de emergencia local",
            "lblLoginAccess": "Acceso de inicio de sesión",
            "lblManage": "GESTIONAR",
            "lblManageDocuments": "Gestionar documentos",
            "lblManagePortal": "Gestionar portal",
            "lblManageUsers": "Gestionar usuarios",
            "lblMngIncidents": "Gestionar incidentes",
            "lblAlerts": "Alertas",
            "lblIncidentManagement": "[[Incident Manager]]",
            "lblManagePlans": "Gestión del [[plan]]",
            "lblViewPlans": "Sólo vista",
            "lblLiteMessaging": "Mensajería Lite",
            "lblMsgSettings": "[[Setting]] de mensajes",
            "lblMsgSetup": "Configuración de mensajería",
            "lblMsgSetupDesc": "Permitir a los usuarios crear mensajes guardados, establecer mensajes predeterminados y escaladas.",
            "lblMyPreparis": "Mi Preparis",
            "lblMyProfile": "Mi perfil",
            "lblNamePlaceholder": "Ingrese un nombre para este nuevo rol",
            "lblNeverAllowed": "Nunca permitido",
            "lblNewRoleTitle": "Nuevo rol personalizado",
            "lblNone": "Ninguno",
            "lblNotAllowed": "No permitido",
            "lblNumbersDisplayed": "de",
            "permissionAndFeatures": "Permisos y funciones",
            "lblReports": "[[Reports]]",
            "lblResources": "Recursos",
            "lblRoleFeaturesPermissions": "Funciones y permisos de rol",
            "lblRoleList": "Roles",
            "roleName": "Nombre de rol",
            "lblSearch": "Buscar",
            "lblSelectRoleType": "- Seleccionar rol -",
            "lblSendMessages": "Enviar mensajes",
            "lblSendMessagesDesc": "Permitir a los usuarios enviar mensajes de emergencia",
            "lblConfigureMessages": "Configurar mensajes",
            "lblConferenceCalls": "Crear llamadas de conferencia",
            "lblCallInAnnouncements": "Crear anuncios de llamadas entrantes",
            "lblSplashMessages": "Crear mensajes Splash",
            "lblUserInitiateIncident": "Incidente iniciado por el usuario",
            "lblPanic": "[[Alert]] de pánico iniciada por el usuario",
            "lblStatus": "Estado",
            "lblTraining": "Capacitación",
            "lblTrainingClasses": "Clases de capacitación",
            "lblTrainingClassesDesc": "Permita que los usuarios vean y tomen clases de capacitación.",
            "lblType": "Tipo",
            "lblUserCannotLogin": "Los usuarios no pueden iniciar sesión.",
            "userCount": "Cantidad de usuarios",
            "lblUserMgmtDesc": "Gestión de usuarios",
            "lblUserMgmtExplain": "Permitir a los usuarios crear/modificar/eliminar usuarios y roles.",
            "lblRoleMgmtDesc": "Gestión de roles",
            "lblRoleMgmtExplain": "Permitir a los usuarios crear/modificar/eliminar roles.",
            "lblUserReceivesMessages": "Los usuarios solo pueden recibir mensajes.",
            "lblViewEditDocs": "Ver y editar documentos",
            "lblViewEditDocsDesc": "Nota: acceso a documentos individuales según los derechos asignados a cada documento individual",
            "lblViewReports": "Ver los [[reports]]",
            "lblViewTraining": "Ver capacitación",
            "viewPermissions": "Ver permisos y funciones",
            "lbliQ": "iQ",
            "lbliQAdmin": "Administración de iQ",
            "lbliQAdminDesc": "Permitir a los usuarios administrar todos los ámbitos, [[team]]s, [[members]] de [[team]], recursos de [[team]] y funciones de administración en iQ",
            "lbliQManagement": "Gestión de iQ",
            "lbliQManagementDesc": "Permitir a los usuarios ver todos los ámbitos, administrar todos los [[team]]s, [[members]] de [[team]] y recursos de [[team]] sin funciones de administración en iQ",
            "lbliQTeamAccess": "Acceso al [[team]] iQ",
            "lbliQTeamAccessDesc": "Permitir a los usuarios administrar a los [[members]] de [[team]] y los recursos de [[team]] para los [[team]]s asociados en iQ",
            "lbliQAssignmentAccess": "Acceso a la asignación de iQ",
            "lbliQAssignmentAccessDesc": "Permitir a los usuarios administrar los recursos de [[team]] asignados solo en iQ",
            "lblBusinessContinuity": "Continuidad empresarial",
            "lblContinuity": "Continuidad",
            "lblBusinessContinuityManagement": "Gestión de la continuidad empresarial",
            "lblBusinessContinuityManagementDesc": "Permitir a los usuarios iniciar una evaluación de la continuidad empresarial y asignar líderes de [[team]]",
            "lblBusinessContinuityAudit": "Solo vista de continuidad del negocio",
            "lblBusinessContinuityAuditDesc": "Permitir a los usuarios ver toda la información del [[plan]] de continuidad empresarial sin realizar cambios",
            "msgDeleteRoleSuccess": "El rol se ha eliminado correctamente.",
            "msgSaveRoleSuccess": "El rol {{roleBundleName}} se ha guardado correctamente.",
            "phSearchRoles": "Refinar los resultados por: nombre del rol",
            "msgErrorUpdateiQRole": "No se puede cambiar el permiso de iQ porque los usuarios con este rol son responsables de los [[team]]s de iQ",
            "msgErrorRemoveiQRole": "No se puede quitar el permiso de iQ porque los usuarios con este rol tienen asignaciones de iQ",
            "roles": "Roles",
            "lblRoleName": "Nombre de rol",
            "msgErrorDuplicateRoleName": "Ya existe un rol con este nombre",
            "role": "Papel",
            "removeRole": "Eliminar rol",
            "lblRecoverProfiles": "Perfiles de recuperación",
            "lblViewRecoverProfiles": "Ver perfiles de recuperación",
            "lblViewRecoverProfilesDesc": "Permitir a los usuarios ver perfiles de recuperación",
            "lblEditRecoveryProfiles": "Editar perfiles de recuperación",
            "lblEditRecoveryProfilesDesc": "Permitir a los usuarios editar perfiles de recuperación",
            "lblEditRecoveryProfileContacts": "Editar contactos del perfil de recuperación",
            "lblEditRecoveryProfileContactsDesc": "Permitir a los usuarios editar los contactos del perfil de recuperación",
            "lblEditRecoveryProfileGenerators": "Editar generadores de perfiles de recuperación",
            "lblEditRecoveryProfileGeneratorsDesc": "Permitir a los usuarios editar generadores de perfiles de recuperación",
            "lblEditRecoveryProfileSites": "Editar sitios de perfil de recuperación",
            "lblEditRecoveryProfileSitesDesc": "Permitir a los usuarios editar sitios de perfiles de recuperación"
        },
        "locations": {
            "btnLocationCreate": "Agregar nueva [[location]]",
            "btnMultiLocationCreate": "Carga masiva",
            "btnSaveLocation": "Guardar dirección",
            "btnViewAll": "Ver todo",
            "duplicateLocationName": "Ya existe una [[location]] con el mismo nombre.",
            "errorLocationReferenced": "Los usuarios hacen referencia a la [[location]] como [[location]] principal",
            "errorRemoveLocationFailed": "Se ha producido un error al quitar la [[location]].",
            "errorSaveLocationFailed": "Ha ocurrido un error al guardar la [[location]].",
            "lblAddressTitle": "Dirección física",
            "lblAreYouSureRemoveLocation": "¿Está seguro de que desea eliminar esta [[location]]?",
            "lblEmergencyPhone": "Número de llamada entrante de emergencia",
            "lblFax": "Número de fax",
            "lblListItems": "artículos",
            "lblLocationActivity": "Actividad de la [[location]]",
            "lblLocationInfo": "Información sobre la [[location]]",
            "lblLocationListTitle": "Ubicaciones",
            "lblLocationMembers": "Usuarios asociados a esta [[location]]",
            "lblLocationName": "Nombre de la [[location]]",
            "lblMainPhone": "Número de teléfono principal",
            "lblNewLocation": "Nueva [[location]]",
            "lblNumbersDisplayed": "de",
            "lblSecurityPhone": "Numero de seguridad",
            "lblUserCount": "Cantidad de usuarios",
            "lblConfirmUpdateGeofence": "Actualizar geocerca",
            "msgUpdateGeofence": "La dirección de la [[location]] ha cambiado, ¿le gustaría actualizar la geocerca para esta [[location]]?",
            "menuSortByName": "Nombre",
            "msgBulkUploadSubmitted": "La carga masiva fue enviada para su procesamiento.",
            "msgRemoveLocationSuccess": "La [[location]] fue eliminada correctamente.",
            "msgSaveLocationSuccess": "La [[location]] {{entityName}} se ha guardado correctamente.",
            "phSearchLocations": "Refinar los resultados por: Nombre, dirección, ciudad o estado",
            "name": "Nombre",
            "address": "Dirección",
            "city": "Ciudad",
            "stateProvinceRegion": "Estado/Provincia/Región",
            "country": "País",
            "lblAddress2": "Dirección 2 (Suite, Edificio, etc.)",
            "lblState": "Estado/ Provincia/Región",
            "lblZip": "Código postal",
            "email": "Correo electrónico",
            "status": "Estado",
            "editGeofence": "Editar geocerca",
            "createGeofence": "Crear geocerca",
            "deleteLocation": "Eliminar [[location]]",
            "saveAndFinish": "Guardar y terminar",
            "removeLocation": "Eliminar [[location]]",
            "lblAvailableSeats": "Asientos disponibles",
            "lblNotes": "Notas",
            "lblEmergencyReadiness": "Preparación para Emergencias",
            "lblLocationType": "Tipo de Ubicacion",
            "selectEmergencyReadiness": "Seleccione una Preparación para Emergencias",
            "selectLocationType": "Seleccione un Tipo de [[location]]"
        },
        "groups": {
            "bulkUpload": "Carga masiva",
            "btnGroupCreate": "Agregar nuevo grupo",
            "btnSaveGroup": "Guardar grupo",
            "btnSaveGroupAddMember": "Guardar y agregar [[members]]",
            "duplicateGroupName": "Ya existe un grupo con el mismo nombre.",
            "errorRemoveGroupFailed": "Se ha producido un error al quitar el grupo.",
            "errorRequiredFields": "Ingrese todos los campos obligatorios.",
            "errorSavGroupFailed": "Se ha producido un error al guardar el grupo.",
            "lblAreYouSureDeleteGroup": "¿Está seguro de que desea eliminar este grupo?",
            "lblGroupActivity": "Actividad del grupo",
            "lblGroupMembers": "[[Members]] del grupo",
            "lblGroupName": "Nombre del grupo",
            "lblListItems": "artículos",
            "lblNewGroup": "Nuevo grupo",
            "lblNumbersDisplayed": "de",
            "lblType": "Tipo de grupo",
            "lblUserCount": "Cantidad de usuarios",
            "menuSortByName": "Nombre",
            "msgBulkUploadSubmitted": "La carga masiva fue enviada para su procesamiento.",
            "msgRemoveGroupSuccess": "El grupo fue eliminado correctamente.",
            "msgSaveGroupSuccess": "El grupo {{entityName}} se ha guardado correctamente.",
            "phSearchGroups": "Refinar los resultados por: nombre o descripción",
            "msgGroupInUsedByUserInitiatedMessages": "Este grupo se utiliza actualmente para incidentes iniciados por el usuario. Anular la asociación antes de eliminar este grupo.",
            "groups": "Grupos",
            "name": "Nombre",
            "description": "Descripción",
            "group": "Grupo",
            "groupType": {
                "groupType": "Tipo de grupo",
                "1": "Continuidad empresarial",
                "2": "Gestión de incidencias corporativas",
                "3": "Gestión de incidentes locales",
                "4": "Recuperación de desastres",
                "Other": "Otro",
                "5": "Otro"
            },
            "saveAndFinish": "Guardar y terminar",
            "deleteGroup": "Eliminar grupo",
            "pleaseSelect": "Seleccione"
        },
        "departments": {
            "btnMultiDepartmentCreate": "Carga masiva",
            "btnDepartmentCreate": "Agregar nuevo [[department]]",
            "btnSaveDepartment": "Guardar [[department]]",
            "btnSaveDepartmentAddMember": "Guardar y agregar [[member]]",
            "duplicateDepartmentName": "Ya existe un [[department]] con el mismo nombre.",
            "errorRemoveDepartmentFailed": "Se produjo un error al eliminar el [[department]].",
            "errorRequiredFields": "Ingrese todos los campos obligatorios.",
            "errorSavDepartmentFailed": "Se ha producido un error al guardar el [[department]].",
            "lblAreYouSureDeleteDepartment": "¿Está seguro de que desea eliminar este [[department]]?",
            "lblDepartmentActivity": "Actividad del [[department]]",
            "lblDepartmentListTitle": "[[Departments]]",
            "lblDepartmentMembers": "[[Members]] del [[department]]",
            "lblDepartmentName": "Nombre de [[department]]",
            "lblDepartmentTitle": "Información del [[department]]",
            "lblListItems": "artículos",
            "lblNewDepartment": "[[Department]] nuevo",
            "lblNumbersDisplayed": "de",
            "lblUserCount": "Cantidad de usuarios",
            "menuSortByName": "Nombre",
            "msgBulkUploadSubmitted": "La carga masiva fue enviada para su procesamiento.",
            "msgRemoveDepartmentSuccess": "El [[department]] fue eliminado correctamente.",
            "msgSaveDepartmentSuccess": "El [[department]] {{entityName}} se ha guardado correctamente.",
            "phSearchDepartments": "Refinar los resultados por: nombre o descripción",
            "departments": "[[Departments]]",
            "department": "[[Department]]",
            "name": "Nombre",
            "description": "Descripción",
            "deleteDepartment": "Eliminar [[department]]",
            "unassociated": "No asociado",
            "renameAndAssociateDepartmentToFAConfirmation": {
                "header": "Cambiar nombre y asociar",
                "body": "¿Está seguro de que desea cambiar el nombre del [[Planner]] [[Department]] y crear la asociación?"
            },
            "lblBCPortalFunctionalAreaAssociated": "[[Planner]] [[Department]] asociado",
            "createNewPortalFunctionalAreaTooltip": "Crear nuevo [[Planner]] [[Department]] y asociarlo a este [[department]]",
            "createNewPlannerFunctionalArea": "Crear nuevo departamento de planificación"
        },
        "tenants": {
            "btnAddBuilding": "Agregar edificio",
            "btnAddTenant": "Agregar nuevo inquilino",
            "btnMultiTenantCreate": "Carga masiva",
            "btnSaveTenantAddBuilding": "Guardar y agregar edificio",
            "duplicateTenantName": "Ya existe un inquilino con el mismo nombre",
            "errorRemoveTenantFailed": "Se produjo un error al eliminar el inquilino.",
            "errorSaveTenantFailed": "Se ha producido un error al guardar el inquilino.",
            "lblAddBuilding": "Agregar edificio",
            "lblBuilding": "Edificio",
            "lblBuildings": "Edificios",
            "lblDeleteTenant": "Eliminar inquilino",
            "lblDeleteTenantModalMessage": "¿Está seguro de que desea eliminar este inquilino?",
            "lblFloor": "Piso(s)",
            "lblNewTenant": "Nuevo inquilino",
            "lblRemoveBuilding": "Eliminar edificio",
            "lblRemoveBuildingModalMessage": "¿Está seguro de que desea eliminar este edificio?",
            "lblSelectBuilding": "Seleccionar edificio",
            "lblTenantName": "Nombre del inquilino",
            "msgBulkUploadSubmitted": "La carga masiva fue enviada para su procesamiento.",
            "msgRemoveBuildingFailed": "Error al eliminar el edificio",
            "msgRemoveBuildingSuccess": "El edificio fue eliminado correctamente",
            "msgRemoveTenantSuccess": "El inquilino fue eliminado correctamente",
            "msgSaveTenantSuccess": "El inquilino {{entityName}} se ha guardado correctamente.",
            "msgUserAssociatedWithThisBuilding": "Este edificio no se puede eliminar porque hay usuarios asociados a este edificio",
            "phSearchTenants": "Refinar los resultados por: nombre o descripción",
            "lblMainPhoneNumber": "Número de teléfono principal",
            "lblEditBuilding": "Editar edificio",
            "errorUsersAssociation": "Hay usuarios asociados con este inquilino. No se puede eliminar este inquilino en este momento.",
            "errorBuildingAssociation": "Hay edificios asociados con este inquilino. No se puede eliminar este inquilino en este momento.",
            "tenants": "Inquilinos",
            "name": "Nombre",
            "description": "Descripción",
            "tenantMembers": "[[Members]] inquilinos",
            "tenantActivity": "Actividad de inquilinos",
            "tenant": "Inquilino"
        },
        "geofences": {
            "geofences": "Geocercas",
            "btnGeofenceCreate": "Crear nueva geocerca",
            "name": "Nombre",
            "type": "Tipo",
            "location": "Ubicación",
            "circle": "Círculo",
            "rectangle": "Rectángulo",
            "polygon": "Polígono"
        },
        "overview": {
            "btnNew": "Nuevo",
            "btnViewAll": "Ver todo",
            "lblCompanyProfile": "Perfil de la empresa",
            "lblManage": "Gestionar",
            "lblMessagingSettings": "[[Setting]] de mensajería",
            "lblMessagingSetup": "Ajustes de mensajería",
            "lblSavedMessages": "Mensajes guardados",
            "lblSecurity": "Seguridad",
            "lblStatus": "Estado",
            "lblSystemEmails": "Correos electrónicos del sistema",
            "lblSystemSettings": "[[Setting]] del sistema",
            "lblTotal": "Total",
            "lblUserCount": "Cantidad de usuarios",
            "txtMessagingSetupDescription": "Aquí se accede a la [[setting]] del comportamiento de los mensajes, así como a los mensajes guardados que se pueden reutilizar.",
            "txtSystemSettings": "La configuración contiene la funcionalidad principal del sistema que se aplica a todo el portal.",
            "lblCustomFields": "Campos personalizados",
            "lblBranding": "Personalización",
            "lblUserDeleted": "Eliminado",
            "lblUserInactive": "Inactivo",
            "lblUserLocked": "Bloqueado",
            "lblUserPending": "Pendiente",
            "lblUserActive": "Activo"
        },
        "POI": {
            "pointsOfInterest": "Puntos de interés",
            "name": "Nombre",
            "latitude": "Latitud",
            "longitude": "Longitud",
            "address": "Dirección",
            "btnCreatePOI": "Crear nuevo punto de interés",
            "remove": "Quitar punto de interés",
            "confirmRemove": "¿Está seguro de que desea quitar este punto de interés?",
            "msgRemovePOISuccess": "El punto de interés {{ name }} se ha quitado correctamente.",
            "msgRemovePOIFailed": "No se pudo quitar el punto de interés {{ name }}",
            "noPOIError": "No hay ningún punto de interés activo; cree un punto de interés para guardar.",
            "msgSavePOISuccess": "El punto de interés {{ name }} se ha guardado correctamente.",
            "msgSavePOIFailed": "No se pudo guardar el punto de interés {{ name }}",
            "createPOI": "Cree un punto de interés desde esta ubicación",
            "new": "Nuevo punto de interés"
        },
        "companyProfile": {
            "btnSaveCompany": "Guardar empresa",
            "duplicateCompanyName": "Ya existe una empresa con el mismo nombre.",
            "errorRequiredFields": "Ingrese todos los campos obligatorios.",
            "errorSavCompanyFailed": "Se ha producido un error al guardar la empresa.",
            "errorSavCompanyLogoFailed": "No se pudo guardar el logotipo de la empresa.",
            "lblChangeLogo": "Cambiar logotipo",
            "lblCompanyActivity": "Actividad de la empresa",
            "lblCompanymentEmailFromAddress": "Correo electrónico: dirección del remitente",
            "lblCompanymentEmailFromName": "Correo electrónico: nombre del remitente",
            "lblCompanymentVoiceNumber": "Visualización del número de voz",
            "lblCompanyName": "Nombre de empresa",
            "lblCompanyTitle": "Perfil de la empresa",
            "lblIndustry": "Industria",
            "msgSaveCompanyLogoSuccess": "El logotipo de la empresa se ha guardado correctamente.",
            "msgSaveCompanySuccess": "La empresa {{entityName}} se ha guardado correctamente.",
            "msgSaveCompanySuccessSSOFailed": "La empresa {{entityName}} se ha guardado, pero la [[setting]] de SSO no se pudo guardar.",
            "msgSaveCompanySuccessAPIFailed": "La empresa {{entityName}} se ha guardado, pero la [[setting]] de integración de terceros no se pudo guardar.",
            "lblCompanySSoSettingIdpUrl": "URL de Idp",
            "lblCompanySSoSettingIsIdpMetadataUrlPublic": "La URL de metadatos de Idp es pública",
            "lblCompanySSoSettingIdpMetadataUrl": "URL de metadatos de Idp",
            "lblCompanySSoSettingIdpMetadata": "Metadatos de Idp (XML)",
            "lblCompanySSoSettingReturnUrl": "URL de devolución de la empresa",
            "lblCompanySSoSettingUseSso": "Inicio de sesión único",
            "lblCompanySSoSettingLoginUrl": "URL de inicio de sesión único",
            "lblCompanySSoSettingAcsUrl": "URL de consumidor de ACS",
            "lblCompanySSoSettingAcsValidator": "Validador de RegEx de URL de consumidor de ACS",
            "lblCompanySSoSettingAudience": "Destinatarios",
            "lblCompanySSoSettingRecipient": "Receptor",
            "lblCompanySSoSettingAllowNonSso": "Permitir inicios de sesión sin SSO",
            "lblCompanyApiSettingApi": "Integraciones de terceros",
            "lblCompanyApiSettingNoneAvailable": "No hay integraciones de terceros disponibles para su empresa.",
            "lblApiNotAllowed": "No permitido",
            "lblApiActive": "Activo",
            "lblApiInactive": "Inactivo",
            "companyProfile": "Perfil de la empresa",
            "msgInvalidImageType": "Tipo de archivo de imagen no válido; seleccione un archivo de imagen válido.",
            "deleteCompanyHeader": "Eliminar empresa",
            "deleteCompanyBody": "¿Está seguro de que desea eliminar esta empresa? Por favor escriba la palabra '{{deleteCompanyVerificationPhrase}}' para habilitar el botón Eliminar.",
            "deleteCompanyVerificationPhrase": "Eliminar",
            "msgSuccessDeletingCompany": "La empresa se ha eliminado correctamente.",
            "deletePortalErrors": {
                "7016": "Portal tiene asignado un número de teléfono de anuncio de llamada entrante.",
                "7017": "El portal tiene portal(es) infantil(es) activo(s)",
                "msgErrorDeletingCompany": "Error al eliminar el portal."
            },
            "lblUploadMetadata": "Cargar metadatos",
            "lblChangeMetadata": "Cambiar metadatos",
            "tooltipMetadataEmpty": "Vacío",
            "msgInvalidFileType": "Tipo de archivo de metadatos no válido; se espera un archivo XML.",
            "industryTypes": {
                "generic": "Genérico",
                "banking": "Bancario",
                "legal": "Legal",
                "credit union": "Institución financiera",
                "finance and insurance": "Finanzas y seguros",
                "retail trade": "Comercio minorista"
            },
            "lblCompanyHRIntegration": "Integración de RRHH",
            "lblSubscriptionCode": "Código de suscripción ADP:",
            "btnGenerate": "generar"
        },
        "security": {
            "errorRequiredFields": "Ingrese todos los campos obligatorios.",
            "errorResetSecurityFailed": "Se produjo un error al restablecer la [[setting]] de seguridad.",
            "errorSavSecurityFailed": "Se produjo un error al guardar la [[setting]] de seguridad.",
            "lblAdditionalSettings": "[[Setting]] de seguridad adicional",
            "lblAllowUserManageLocation": "Permitir a los usuarios la capacidad de administrar sus ubicaciones",
            "lblAllowUserManagePassword": "Permitir a los usuarios la capacidad de administrar sus propias contraseñas",
            "lblAllowUserManageProfile": "Permitir a los usuarios la capacidad de administrar su información de contacto",
            "lblHideUserEmail": "Ocultar el correo electrónico del usuario (nombre de usuario) para que no se muestre en los correos electrónicos del sistema",
            "lblLowercaseLetters": "Letras minúsculas (0-10)",
            "lblMinimumLength": "Largo mínimo (1-15)",
            "lblMobilePhoneRequired": "Solicitar al usuario que proporcione el número de teléfono móvil predeterminado",
            "lblNumbers": "Números (0-10)",
            "lblPasswordSecurity": "Personalice los requisitos de seguridad de la contraseña",
            "lblPasswordSecurityInfoPopoverTitle": "Seguridad de contraseña predeterminada",
            "lblPasswordSettings": "[[Setting]] de contraseña",
            "lblSecurityActivity": "Actividad de seguridad",
            "lblSecurityTitle": "Seguridad",
            "lblSecurityDescription": "Administrar reglas de contraseña personalizadas y otras configuraciones de seguridad del sistema",
            "lblSymbols": "Símbolos (0-10)",
            "lblUppercaseLetters": "Letras mayúsculas (0-10)",
            "msgPasswordSecurityInfoPopoverText": "De forma predeterminada, todas las contraseñas tienen un largo mínimo de 6 a menos que se modifiquen",
            "msgResetSecuritySuccess": "La [[setting]] de seguridad se ha restablecido correctamente.",
            "msgSaveSecuritySuccess": "La [[setting]] de seguridad se ha guardado correctamente.",
            "lblAllowOptOutSms": "Permitir a los usuarios optar por no recibir mensajes SMS",
            "lblAllowOptOutEmail": "Permitir a los usuarios optar por no recibir mensajes de correo electrónico",
            "lblAllowOptOutVoice": "Permitir a los usuarios optar por no recibir mensajes de voz",
            "confirmReset": {
                "body": "¿Está seguro de que desea restablecer la [[setting]] de seguridad?",
                "header": "Confirmar",
                "btnText": "OK"
            }
        },
        "systemEmail": {
            "btnEdit": "Editar",
            "btnSave": "Guardar",
            "btnOk": "OK",
            "errorRevertEmailFailed": "No se puede revertir el correo electrónico al correo electrónico del sistema predeterminado. Inténtelo de nuevo.",
            "lblBody": "Cuerpo del correo electrónico",
            "lblBulkUploadFailureEmail": "Error de carga masiva",
            "lblBulkUploadIncorrectPasswordEmail": "Contraseña incorrecta de carga masiva",
            "lblBulkUploadResultEmail": "Resultados de carga masiva",
            "lblBulkUploadUnknownUserEmail": "Nombre de usuario desconocido de carga masiva",
            "lblChangedPasswordEmail": "Contraseña cambiada",
            "lblConfirmation": "Confirmar",
            "lblEmailChangedEmail": "Correo electrónico cambiado",
            "lblEmailListLabel": "Ver plantilla para:",
            "lblForgotPasswordEmail": "Olvidó su contraseña",
            "lblInviteUserEmail": "Registro de nuevo usuario",
            "lblLastModifiedBy": "Última modificación por",
            "lblLastModifiedOn": "Última modificación el",
            "lblLoading": "Por favor espere...",
            "lblNA": "N/A",
            "lblResetTemplateLink": "Restablecer los valores predeterminados del sistema",
            "lblSubject": "Asunto del correo electrónico",
            "lblSystemEmailActivity": "Actividad de correo electrónico del sistema",
            "lblSystemEmailsTitle": "Correos electrónicos del sistema",
            "lblTemplateLastModified": "Última modificación de la plantilla",
            "lblUserGroupAdditionEmail": "Adición de grupos de usuarios",
            "lblUserGroupRemovalEmail": "Eliminación de grupos de usuarios",
            "lblUsingCustom": "Esta plantilla ha sido personalizada.",
            "msgRevertEmailSuccess": "El correo electrónico del sistema se ha revertido al correo electrónico predeterminado del sistema.",
            "msgSavedSystemEmailSuccess": "El correo electrónico del sistema se ha guardado correctamente",
            "confirmReset": {
                "body": "¿Está seguro de que desea volver al correo electrónico predeterminado del sistema?",
                "header": "Confirmar",
                "btnText": "OK"
            },
            "sendMessageEmail": "Enviar mensaje de correo electrónico",
            "insert": "Insertar",
            "reset": "Reiniciar",
            "resizeImageDescription": "Ajuste el ancho y el alto de su imagen a su gusto. Tiene la opción de conservar la relación de aspecto de la imagen para que no se vea distorsionada.",
            "doYouWantResizeImageDescription": "Notamos que su imagen es bastante grande. ¿Le gustaría cambiar el tamaño?",
            "preserveAspectRatio": "Preservar la relación de aspecto",
            "bcTeamLeadIntroductoryEmail": "Correo electrónico introductorio de continuidad empresarial para el líder del [[team]]",
            "bcTeamMemberIntroductoryEmail": "Correo electrónico de presentación de continuidad empresarial para [[team members]]",
            "bcNewTeamLeadIntroductoryEmail": "Correo electrónico de presentación de continuidad empresarial para el líder de [[team]] (nuevo usuario)",
            "bcNewTeamMemberIntroductoryEmail": "Correo electrónico de presentación de continuidad empresarial para [[team members]] (nuevo usuario)",
            "bcTeamLeadDueDateReminderEmail": "Correo electrónico de continuidad empresarial Próximo a vencer para el líder de [[team lead]]",
            "bcTeamLeadDueDateNowEmail": "Correo electrónico de fecha de vencimiento inmediata de continuidad empresarial para líder del [[team lead]]",
            "bcTeamMemberDueDateReminderEmail": "Correo electrónico de continuidad empresarial Próximo a vencer para [[team members]]",
            "bcTeamMemberDueDateNowEmail": "Correo electrónico de fecha de vencimiento inmediato de la continuidad empresarial para [[team members]]",
            "notificationEmergencyResponseEmail": "Correo electrónico de notificación de respuesta de emergencia",
            "bcNewAnnexLeadIntroductoryEmail": "Correo electrónico de presentación de plan de emergencia operacional para el colaborador (nuevo usuario)",
            "bcAnnexLeadIntroductoryEmail": "Correo electrónico de presentación de plan de emergencia operacional para el colaborador",
            "bcAnnexLeadDueDateReminderEmail": "Correo electrónico de continuidad empresarial Próximo a vencer para el líder de",
            "bcAnnexLeadDueDateNowEmail": "Correo electrónico de fecha de vencimiento inmediata de continuidad empresarial para líder del",
            "managerNotificationEmergencyResponseEmail": "Correo electrónico de respuesta de emergencia de notificación del gerente",
            "bcTechnologyNewLeadEmail": "Correo electrónico introductorio los líderes de [[technology]] (nuevo usuario)",
            "bcTechnologyLeadEmail": "Correo electrónico introductorio para los líderes de [[technology]]"
        },
        "messageSettings": {
            "errorRequiredFields": "Ingrese todos los campos obligatorios.",
            "errorResetMessageFailed": "Se ha producido un error al restablecer la [[setting]] de los mensajes.",
            "errorSavMessageFailed": "Se ha producido un error al guardar la [[setting]] de los mensajes.",
            "lblAreYouSureResetSettings": "¿Está seguro de que desea restablecer la [[setting]] de los mensajes?",
            "lblEscalateMessageContent1": "Si desea dar tiempo a los destinatarios de los mensajes para que respondan por SMS, correo electrónico o mensajes Push antes de que se los llame, configúrelo en Sí. Luego podrá seleccionar la cantidad de tiempo de espera antes de llamar.",
            "lblEscalateMessageContent2": "Si se establece en No, se llamará al destinatario al mismo tiempo que este reciba mensajes de texto/correo electrónico/Push.",
            "lblEscalateMessageContent3": "Nota: La [[setting]] SOLO se aplica a los mensajes que requieren una respuesta y que tienen seleccionados SMS y/o correo electrónico y/o Push Y mensajes de voz.",
            "lblEscalateMessageTitle": "¿Escalar el mensaje cuando se REQUIERE una respuesta (SMS/correo electrónico/Push a voz)?",
            "lblIncludeOfficePhoneContent": "Si se establece en Sí, cuando un usuario envía un nuevo mensaje Y ha seleccionado la opción Voz (llamar a los destinatarios), se preseleccionará la opción de llamar al teléfono de la oficina de un destinatario. Los usuarios podrán anular el valor predeterminado y anular la selección de cada mensaje si lo desean.",
            "lblIncludeOfficePhoneTitle": "¿Incluir teléfono de oficina para llamadas de voz de forma predeterminada?",
            "lblPersonalContactContent": "Si se establece en Sí, cuando un usuario envía un nuevo mensaje se preseleccionará la opción para enviar a la información de contacto personal del destinatario del mensaje. Los usuarios podrán anular este valor predeterminado y la selección de cada mensaje si lo desean.",
            "lblPersonalContactTitle": "¿Enviar mensaje a la información de contacto personal del usuario?",
            "lblSettingsActivity": "Actividad de [[setting]]",
            "lblSettingsTitle": "[[Setting]] de mensajes",
            "msgResetMessageSuccess": "La [[setting]] de mensajes se ha restablecido exitosamente.",
            "msgSaveMessageSuccess": "La [[setting]] de mensajes se ha guardado exitosamente.",
            "lblPersonalContactPhoneTitle": "¿Enviar mensaje al teléfono personal del usuario?",
            "lblPersonalContactPhoneContent": "Si se establece en Sí, cuando un usuario envíe un nuevo mensaje se preseleccionará la opción de enviar a la información del teléfono personal del destinatario del mensaje. Los usuarios podrán anular este valor predeterminado y la selección de cada mensaje si lo desean.",
            "personalContactEmailTitle": "¿Enviar mensaje al correo electrónico personal del usuario?",
            "lblPersonalContactEmailContent": "Si se establece en Sí, cuando un usuario envíe un nuevo mensaje se preseleccionará la opción para enviar a la información de correo electrónico personal del destinatario del mensaje. Los usuarios podrán anular este valor predeterminado y la selección de cada mensaje si lo desean."
        },
        "userInitiatedMessage": {
            "lblPageHeader": " [[Setting]] de incidentes iniciados por el usuario",
            "lblPageDescription": "Administre qué usuarios recibirán mensajes de incidentes iniciados por usuarios.",
            "lblDefaultGroup": "Grupo predeterminado",
            "lblDefaultGroupTip": "Los usuarios del grupo seleccionado recibirán mensajes por CORREO ELECTRÓNICO, SMS y MENSAJES PUSH de forma predeterminada. Esta [[setting]] se puede anular por tipo de incidente a continuación.",
            "lblIncidentTypes": "Tipos de incidentes",
            "lblDefaultGroupPerIncident": "Grupo predeterminado para ",
            "lblDefaultGroupPerIncidentTip": "Los usuarios del grupo seleccionado siempre recibirán mensajes para este tipo de incidente. Los grupos adicionales pueden recibir mensajes utilizando la [[setting]] de ubicaciones opcionales a continuación.",
            "lblOptionLocations": "Ubicaciones opcionales",
            "lblOptionLocationsTip": "Los grupos adicionales pueden recibir mensajes según la [[location]] predeterminada de los usuarios de informes.",
            "btnAddNewOptionalLocation": "Agregar nueva [[location]] opcional",
            "msgUserInitiatedMessagesSettingsSaved": "Se guardó la [[setting]] iniciada por el usuario.",
            "msgUserInitiatedMessagesSettingsNotSaved": "Se produjo un error al guardar la [[setting]] iniciada por el usuario. Por favor, inténtelo de nuevo más tarde.",
            "msgRequiredFieldError": "Tiene campo(s) que requieren selecciones.",
            "lblSendTo": "Enviar mensajes a",
            "lblChannelsPerIncidentTip": "Seleccione los métodos mediante los cuales el usuario recibirá mensajes. Los usuarios que no tengan estos métodos de contacto no recibirán mensajes.",
            "lblEmail": "Correo electrónico",
            "lblSms": "SMS",
            "lblVoice": "Voz",
            "lblPush": "Mensajes push",
            "lblLocation": "[[Location]]",
            "lblGroup": "Grupo",
            "lblChangesHaveBeenMadeHeader": "Se han realizado cambios",
            "lblChangesHaveBeenMadeMessage": "Ha realizado cambios en esta página. Si navega fuera de esta página sin guardar sus datos, los cambios se perderán.",
            "lblNoGroupsHeader": "No se ha configurado ningún <strong>grupo</strong>",
            "lblNoGroupsDescription": "Para administrar la [[setting]] de incidentes iniciados por el usuario, se debe configurar al menos uno (1) o más grupos.",
            "btnGoToCreateGroup": "Crear un grupo",
            "lblGroupsRemoved": "NOTA: Los grupos sin usuarios asignados se han quitado de la lista.",
            "lblGroupsAndLocationsRemoved": "NOTA: Los grupos y ubicaciones sin usuarios asignados se han quitado de la lista.",
            "msgCannotDisableAllChannels": "Se debe habilitar al menos un (1) método de contacto.",
            "confirmReset": {
                "body": "Cambiar el grupo predeterminado afectará a cualquier tipo de incidente sin un grupo configurado específicamente para actualizarse.",
                "header": "¿Está seguro de que desea cambiar el grupo predeterminado?",
                "btnText": "OK"
            }
        },
        "user": {
            "lblDefaultNewUserTitle": "Usuario nuevo",
            "btnSaveAndFinish": "Guardar y finalizar",
            "lblUserActivity": "Actividad del usuario",
            "lblUserStatus": "Estado",
            "lblIQAssignments": "Asignaciones de iQ",
            "lblInitRegSent": "Registro inicial enviado",
            "lblUserFirstLogin": "Primer inicio de sesión del usuario",
            "lblUsersLastTermAcceptance": "Aceptación del último plazo del usuario",
            "lblUserLastLogin": "Último inicio de sesión del usuario",
            "lblUserRole": "Papel",
            "lblUniqueId": "Identificación única",
            "lblSelfRegistered": "Optado por adhesión",
            "errorSaveUser": "No se pudo guardar la información del usuario",
            "lblSendRegEmail": "Enviar correo electrónico de registro",
            "menuPickRole": "Seleccione un rol...",
            "errorIQAssignmentsRequireLogin": "El usuario debe tener un rol con capacidades de inicio de sesión cuando tenga asignaciones de iQ",
            "errorCannotInactivateWithIQAssignments": "El usuario no puede quedar inactivo porque tiene asignaciones de iQ. Elimine todas las asignaciones de iQ antes de intentar que el usuario esté inactivo.",
            "errorCannotDeleteWithIQAssignments": "El usuario no se puede eliminar porque tiene asignaciones de iQ. Elimine todas las asignaciones de iQ antes de intentar eliminar al usuario.",
            "errorCannotDeleteBCManager": "El usuario no se puede eliminar porque es Gerente de continuidad comercial. Seleccione un nuevo Gerente de continuidad comercial antes de intentar eliminar al usuario.",
            "lblAreYouSureUpgradeUser": "¿Está seguro de que desea actualizar este usuario habilitado? Este cambio no se puede revertir",
            "lblAreYouSureDeleteUser": "¿Está seguro de que desea eliminar este usuario?",
            "msgDeleteUserSuccess": "Usuario eliminado correctamente.",
            "msgCannotDeleteEssentialContactUser": "No se puede eliminar al usuario. Es un Contacto Esencial.",
            "errorDeleteUserFailed": "Se produjo un error al intentar eliminar este usuario.",
            "lblManageUserPasswordHeader": "Restablecer la contraseña",
            "lblInitiateUserPasswordReset": "Envíe al usuario un correo electrónico que le permita crear su propia contraseña.",
            "lblManuallySetUserPassword": "Establezca manualmente la contraseña (no se envía ningún correo electrónico; deberá comunicar verbalmente la contraseña).",
            "lblResetAndSendEmail": "Restablecer y enviar correo electrónico",
            "lblManuallySetPassword": "Establecer contraseña manualmente",
            "msgNoUser": "No se pudo solicitar el restablecimiento de la contraseña; el usuario está vacío.",
            "msgRequestPasswordResetFailed": "No se pudo restablecer la contraseña para este usuario.",
            "msgRequestPasswordResetSuccess": "El usuario recibirá un correo electrónico con instrucciones para restablecer su contraseña.",
            "lblAreYouSureResendInvite": "¿Está seguro de que desea reenviar la invitación a este usuario?",
            "msgInvitationSent": "Se ha enviado la invitación del usuario.",
            "errorInvitationSent": "No se pudo reenviar la invitación del usuario.",
            "lblLastInviteNotSent": "no se enviaron invitaciones",
            "lblLastInviteSent": "última invitación enviada",
            "btnResendInvite": "Reenviar invitación",
            "lblAreYouSureUnlock": "¿Está seguro de que desea desbloquear a este usuario?",
            "msgUserUnlocked": "El usuario se ha desbloqueado correctamente.",
            "errorUserUnlocked": "No se puede desbloquear a este usuario.",
            "btnUnlock": "Desbloquear"
        },
        "selfRegistration": {
            "lblSelfRegistrationTitle": "Adhesión",
            "lblSelfRegistrationDescription": "Administrar configuraciones y ajustes de adhesión",
            "lblSelfRegistrationFlag": "Permitir que los usuarios opten por la adhesión",
            "lblSelfRegistrationUrl": "URL/código QR de adhesión",
            "lblSelfRegistrationCode": "Código de registro",
            "lblRequireAddress": "Permitir a los usuarios que proporcionen una dirección",
            "lblUserRole": "Seleccione un rol para los usuarios de adhesión",
            "lblUserLocations": "Seleccione las ubicaciones de las que los usuarios con opción de adhesión pueden recibir mensajes",
            "lblLocations": "Ubicaciones",
            "lblSelectNone": "seleccionar ninguno",
            "lblSelectAll": "seleccionar todo",
            "lblConfirmDeleteTitle": "Quitar cuenta",
            "lblConfirmDelete": "¿Está seguro de que desea quitar su cuenta?",
            "optInUrlTooltip": "Copie el enlace o descargue el código QR y proporciónelo a los usuarios con opción de adhesión",
            "registrationCodeTooltip": "Proporcione este código a los usuarios con opción de adhesión para completar el registro",
            "copyOptInUrlTooltip": "Copiar la URL de adhesión",
            "downloadOptInQrCodeTooltip": "Descargar código QR",
            "requireAddress": "Sí, solicitar a los usuarios que proporcionen una dirección",
            "allowAddress": "Sí, permitir que los usuarios proporcionen una dirección",
            "noAddress": "No, no solicite una dirección",
            "allLocations": "Cualquier [[location]]",
            "selectedLocations": "Ubicaciones seleccionadas",
            "msgSaveSuccess": "Se guardó la [[setting]] de adhesión",
            "msgSaveFail": "No se pudo guardar la [[setting]] de adhesión",
            "confirmReset": "¿Está seguro de que desea restablecer la [[setting]] de adhesión a la predeterminada?",
            "msgResetFail": "No se pudo restablecer la [[setting]] de adhesión",
            "msgLocationRequired": "Seleccione al menos una [[location]] para que el usuario se registre",
            "msgRoleBundleRequired": "Se requiere el rol del usuario con opción de adhesión",
            "msgNoRoles": "No hay roles calificados para los usuarios con opción de adhesión; cree al menos un rol de solo mensaje.",
            "lblNoRoles": "No hay roles disponibles"
        },
        "mobileEmergencyButtons": {
            "alerts": "Alertas",
            "manageMobileEmergencyButtons": "Configuración de [[alert]] iniciados por el móvil",
            "manageMobileEmergencyButtonsDescription": "Los botones de [[alert]] iniciados por el móvi se pueden configurar en la aplicación móvil. Esta página le permite configurar estos botones y los usuarios con los que se comunicará cuando se presione el botón.",
            "sharedSettings": "[[Setting]] compartida de [[alerts]] personalizadas",
            "sharedSettingsDescription": "La [[setting]] compartida de [[alerts]] personalizadas solo se aplica a los botones de [[alerts]] personalizadas. Los botones más especializados como Pánico, Registro programado e Informe de incidentes son administrados por otras configuraciones de la empresa.",
            "buttonShouldBePress": "Permitir que los usuarios presionen una [[alert]] solo cuando estén cerca de una [[location]] de la empresa",
            "howCloseShouldTheLocation": "Identificar qué tan cerca deben estar los usuarios de la [[location]] de una empresa para que se active la [[alert]]",
            "canSamePersonPressResolve": "Permitir a los usuarios que activan una [[alert]] que resuelvan el incidente",
            "emergencyButtonsConfiguration": "Configuración de [[alert]] iniciados por el móvil",
            "saveSuccess": "Los botones de [[alert]] iniciados por dispositivos móviles se guardaron correctamente",
            "saveError": "Error al guardar los botones de [[alert]] iniciados por el móvil",
            "loadError": "Error al cargar los botones de [[alert]] iniciados por el móvil",
            "invalidContactMethodsError": "Se requiere al menos un método de contacto",
            "addButton": "Agregar botón",
            "configurationModal": {
                "title": "Configurar el botón de [[alert]] iniciados por el móvil {{ x }}",
                "lblButtonType": "Seleccionar el tipo de botón",
                "lblButtonName": "Proporcione un nombre para el botón de [[alert]] iniciados por el móvil",
                "lblButtonNameTooltip": "Los nombres de los botones de [[alert]] están limitados a 50 caracteres",
                "lblCohortTypeToNotify": "¿Quién debe ser notificado cuando se presione este botón?",
                "lblTypesOfNearbyUsers": "¿Cómo le gustaría identificar a las personas cercanas?",
                "lblProximity": "¿Qué tan cerca deben estar los destinatarios cercanos?",
                "lblCustomButtonType": "Personalizado",
                "lblPanicButtonType": "[[Alert]] de pánico",
                "lblScheduledCheckinButtonType": "Registro programado",
                "lblReportIncidentButtonType": "Informar incidente",
                "lblCohortProximityType": "Gente cercana",
                "lblCohortDesignatedRecipientsType": "Un conjunto específico de personas",
                "lblSearchUser": "Buscar",
                "lblAddByPhoneNumber": "Agregar por número de teléfono",
                "lblAddByEmail": "Agregar por correo electrónico",
                "lblSelectedRecipients": "Destinatarios seleccionados",
                "lblAddByEntity": "Agregar por tipo",
                "lblEntityTypeRoles": "Roles",
                "lblEntityTypeLocations": "Ubicaciones",
                "lblEntityTypeDepartments": "[[Departments]]",
                "lblEntityTypeGroups": "Grupos",
                "panicDesc": "<p>Se colocará un botón de pánico en la pantalla de inicio del móvil.</p><p>Al presionar el botón, se activará un incidente y se enviará un mensaje a una lista designada de destinatarios. Estos mensajes y la lista de destinatarios se pueden personalizar por ubicación.</p><p>Para configurar los mensajes de pánico previstos y las listas de destinatarios, visite <a href=\"{{panicConfigurationLink}}\" target=\"_blank\">Configuración de pánico</a>.</p>",
                "scheduledCheckinDesc": "<p>Se colocará un botón de Registro programado en la pantalla de inicio del dispositivo móvil.</p><p>Al presionar el botón, se activará una cuenta regresiva que crea un incidente de pánico y envía un mensaje a una lista designada de destinatarios. Este mensaje y la lista de destinatarios se pueden personalizar por ubicación.</p><p>Para configurar el mensaje de pánico asociado y la lista de destinatarios, visite <a href=\"{{panicConfigurationLink}}\" target=\"_blank\">Configuración de pánico</a>.</p>",
                "reportIncidentDesc": "<p>Se colocará un botón de Informar incidente en la pantalla de inicio del dispositivo móvil.</p><p>Al presionar el botón, los usuarios podrán escribir libremente una descripción y ubicación del incidente, además de tomar una foto.</p><p>Para configurar la lista de destinatarios del informe de incidentes, visite la <a href=\"{{manageSettingsLink}}\" target=\"_blank\">[[setting]] de incidentes iniciados por el usuario</a></p>",
                "duplicatePanicButtonError": "Ya existe un botón de pánico",
                "duplicateScheduledCheckInError": "Ya existe un botón de registro programado",
                "duplicateReportIncidentError": "Ya existe un botón Informar incidente",
                "duplicateAlertButtonError": "El botón {{ buttonName }} ya existe",
                "saveSuccess": "El botón del dispositivo móvil fue guardado correctamente.",
                "saveError": "Error al guardar el botón del dispositivo móvil.",
                "removeSuccess": "El botón del dispositivo móvil fue quitado correctamente.",
                "removeError": "Error al quitar el botón del dispositivo móvil.",
                "selectUnitOfMeasure": "Seleccionar la unidad de medida",
                "byUserAddresses": "Por direcciones de usuario",
                "byAssignedLocation": "Por [[location]] asignada",
                "byMobileLocation": "Por ubicación del dispositivo móvil",
                "lblIncidentType": "Tipo de incidente"
            },
            "confirmRemove": {
                "header": "Quitar el botón de [[alert]] iniciados por el móvil",
                "body": "¿Está seguro de que desea quitar el botón de [[alert]] iniciados por el móvil {{ buttonName }} desde la aplicación móvil?",
                "text": "Quitar"
            }
        },
        "bulkUploadConfig": {
            "configureBulkUploadTitle": "Configuración de carga masiva",
            "lblDisableEmailAttachments": "Incluya archivos de informes de errores detallados, de origen y de resultados en el correo electrónico de notificación",
            "disableEmailAttachmentsTooltip": "Carga masiva por correo electrónico del archivo de origen, el archivo de resultados y el archivo de error detallado como adjunto del correo electrónico de notificación",
            "lblDisableEmailErrors": "Incluya el archivo de errores sin los datos personales del usuario en el correo electrónico de notificación",
            "disableEmailErrorsTooltip": "Incluya el archivo de errores de carga masiva sin los datos personales del usuario como archivo adjunto del correo electrónico de notificación.",
            "lblNotificationEmails": "Correos electrónicos de notificación",
            "notificationEmailsTooltip": "Direcciones de correo electrónico para enviar notificaciones sobre los resultados de la carga masiva.",
            "lblDefaultRole": "Rol predeterminado",
            "defaultRolePlaceHolder": "Seleccionar el rol predeterminado",
            "defaultRoleTooltip": "Este rol se asignará a los nuevos usuarios que no tengan un rol especificado en su archivo de carga masiva.",
            "lblDefaultLocation": "[[Location]] predeterminada",
            "defaultLocationPlaceHolder": "Seleccionar la [[location]] predeterminada",
            "defaultDropdownPlaceholder": "Ninguna seleccionada",
            "defaultLocationTooltip": "Esta [[location]] se asignará a los nuevos usuarios que no tengan una [[location]] especificada en su archivo de carga masiva.",
            "invalidEmailFormatError": "Formato de correo inválido. El valor no se puede agregar a la lista de correos electrónicos."
        }
    },
    "login": {
        "lblLoggingIn": "Iniciando sesión...",
        "lblLoginId": "Correo electrónico",
        "phUsername": "Ingrese su dirección de correo electrónico",
        "phRegistrationCode": "Ingrese el código de registro de la empresa",
        "lblPassword": "Contraseña",
        "lblConfirmPassword": "Confirmar contraseña",
        "phPassword": "Ingrese su contraseña",
        "btnLogin": "Iniciar sesión",
        "btnBackToLogin": "volver para iniciar sesión",
        "lblSupport": "Asistencia",
        "btnForgotPortalUrl": "¿Olvidó la URL del portal?",
        "btnForgotPassword": "¿Olvidó su contraseña?",
        "btnRegister": "Adhesión",
        "msgChangedPasswordSuccess": "Su contraseña ha sido cambiada correctamente.",
        "msgSessionTimeout": "Su sesión ha caducado por inactividad. Regístrese nuevamente.",
        "lblNeedHelp": "¿Necesita ayuda?",
        "msgRequestSendPortalsLinksSuccess": "Le enviamos un correo electrónico con los enlaces de los portales disponibles. Debería recibirlo en unos minutos.",
        "msgRequestSendPortalsLinksFailed": "No se pudo enviar un correo electrónico.",
        "lblResetPassword": "Restablecer la contraseña",
        "lblChangePassword": "Cambiar la contraseña",
        "lblCreatePassword": "Crear contraseña",
        "lblRegisterAccount": "Crear una cuenta",
        "lblRegisterProfile": "Información del contacto",
        "lblContactInfo": "Información del contacto",
        "msgRegisterInstructions": "Ingrese el código de registro de la empresa con la que desea registrarse",
        "msgResetPasswordInstructions": "Ingrese su correo electrónico y luego haga clic a continuación para enviar una solicitud para restablecer su contraseña.",
        "msgRegisterAccountInstructions": "Cree un nombre de usuario y contraseña que utilizará para acceder a la información de su perfil",
        "btnRequestPasswordReset": "Petición para la recuperación de contraseña",
        "msgForgotPassword1": "Si el nombre de usuario proporcionado es válido, en unos minutos debería recibir un correo electrónico para que pueda crear una nueva contraseña.",
        "msgForgotPassword2": "Si no lo encuentra, revise su carpeta de correo no deseado. Si aún no lo ha recibido,",
        "msgForgotPassword3": "inténtalo de nuevo",
        "msgForgotPassword4": "o comuníquese con su administrador.",
        "lblNewPassword": "Contraseña nueva",
        "lblConfirmNewPassword": "Confirmar contraseña nueva",
        "btnChangePassword": "Cambiar la contraseña",
        "btnCreatePassword": "Crear contraseña",
        "passwordErrorMessage": "La contraseña no cumple con las reglas de complejidad.",
        "msgUsernameExist": "El correo electrónico ya existe en el sistema; utilice otro correo electrónico.",
        "errors": {
            "999": "Error del sistema. Por favor, inténtelo de nuevo más tarde.",
            "1005": "No se puede iniciar sesión; verifique su correo electrónico o contraseña.",
            "1006": "El enlace ha caducado o el enlace no es válido. Solicite un nuevo enlace.",
            "1010": "Ha superado el máximo de intentos de inicio de sesión incorrectos.  Su cuenta ha sido bloqueada temporalmente.  Por favor, inténtelo de nuevo más tarde.",
            "1015": "La dirección de correo electrónico que ingresó no coincide con una cuenta de usuario activa.",
            "1017": "No se puede utilizar la autenticación multifactor; el usuario no tiene un teléfono principal",
            "1019": "El PIN de autenticación no es válido.",
            "1022": "El usuario debe iniciar sesión a través de SSO",
            "1023": "El usuario debe iniciar sesión utilizando la URL del portal",
            "1024": "El usuario debe iniciar sesión utilizando la URL del portal o del SSO",
            "msgMFAFailed": "Error en la autenticación multifactor.",
            "tacDeclined": "Términos y condiciones rechazados.",
            "msgTACFailed": "Error en términos y condiciones.",
            "msgValidateEmailFormat": "El correo electrónico ingresado no es una dirección de correo electrónico válida.",
            "msgInvalidUsername": "La dirección de correo electrónico que ingresó no coincide con una cuenta de usuario activa."
        }
    },
    "mfa": {
        "lblMultifactorTitle": "Autenticación multifactor",
        "lblMfaSecurityCode": "Código de seguridad",
        "lblRequired": "(requerido)",
        "msgMultifactorInstructions": "Se ha enviado un código de seguridad a su número de teléfono registrado. Por favor, ingréselo a continuación para continuar con su inicio de sesión.",
        "msgMultifactorWithExpirationInstructions": "Se ha enviado un código de seguridad a su número de teléfono registrado. Por favor, ingréselo a continuación para continuar con su inicio de sesión. El código de seguridad caducará en {{mfaPinExpirationMinutes}} minutos."
    },
    "splashMessage": {
        "msgMustAgreeToSplash": "Debe acusar recibo de este mensaje marcando la casilla.",
        "lblAcknowledgedSplash": "Acuse de recibo"
    },
    "recoveryProfiles": {
        "recoveryProfile": "Perfil de recuperación",
        "recoveryProfiles": "Perfiles de recuperación",
        "recoveryProfilesFilterPlaceHolder": "Filtrar por nombre de perfil...",
        "export": "Exportar",
        "name": "Nombre",
        "essentialContactsDesc": "Personal clave que está autorizado para iniciar una respuesta de agilidad cuando sea necesario.",
        "generator": "Generador",
        "comments": "Comentarios",
        "generatorDesc": "Requisitos de energía necesarios para recuperar su espacio de oficina",
        "sites": "Sitios",
        "sitesDesc": "Sitios de oficinas móviles, así como opciones alternativas de espacio comercial",
        "technologyAndConnectivity": "[[Technology]] y conectividad",
        "technologyAndConnectivityDesc": "Aplicaciones de [[team]]s tecnológicos para recuperación",
        "generatorSize": "Tamaño del generador",
        "generatorPhase": "Fase del generador",
        "generatorVoltage": "Voltaje del generador",
        "totalAmperage": "Amperaje total",
        "requiredCable": "Cable requerido",
        "powerService": "Servicio de energía",
        "generatorTitle": "Proporcione los requisitos de energía necesarios para recuperar el espacio de su oficina",
        "requestChange": "Solicitar cambio",
        "site": "Sitio",
        "primary": "principal",
        "errorExportingRecoveryProfile": "Error al exportar el perfil de recuperación",
        "rename": "Cambiar nombre",
        "renameRecoveryProfile": "Cambiar el nombre del perfil de recuperación",
        "renameRecoveryProfileTooltip": "Un nombre de perfil de recuperación puede contener hasta 200 caracteres de largo. Debe ser único.",
        "errorRenamingRecoveryProfile": "Error al cambiar el nombre del perfil de recuperación",
        "successRenamingRecoveryProfile": "Perfil de recuperación renombrado correctamente",
        "duplicateProfileName": "Los nombres de los perfiles de recuperación deben ser únicos",
        "errorLoadingProfiles": "Error al cargar perfiles de recuperación",
        "errorCreatingProfile": "Error al crear el perfil de recuperación",
        "manuallyCreate": "Crear manualmente",
        "migrateMyAgilityDocuments": "Migrar documentos",
        "errorMigratingMyAgilityDocuments": "Error al migrar documentos",
        "successMigratingMyAgilityDocuments": "Migración de documentos solicitada correctamente",
        "validation": {
            "duplicateProfileNameMessage": "Este nombre ya ha sido usado",
            "missingValues": "Complete todos los valores obligatorios"
        },
        "recoverySites": {
            "recoverySites": "Sitios de recuperación",
            "recoverySitesFilterPlaceHolder": "Filtrar por nombre del sitio...",
            "recoverySitesLoadingError": "Error al cargar sitios de recuperación",
            "unitType": "Tipo de unidad",
            "unitNumber": "Unidad numérica",
            "streetNumber": "Número de calle",
            "streetName": "Nombre de la calle",
            "city": "Ciudad",
            "state": "Estado",
            "postalCode": "Código postal",
            "country": "País",
            "contactName": "Nombre de contacto",
            "phone": "Teléfono",
            "comments": "Comentarios",
            "saveChanges": {
                "header": "Guardar sitio de recuperación",
                "body": "¿Está seguro de que desea guardar los cambios?",
                "text": "Guardar"
            },
            "errorLoadingRecoverySite": "Error al cargar el sitio de recuperación",
            "errorSavingRecoverySite": "Error al guardar el sitio de recuperación",
            "successSavingRecoverySite": "Sitio de recuperación guardado correctamente",
            "errorDeletingRecoverySite": "Error al eliminar el sitio de recuperación",
            "successDeletingRecoverySite": "Sitio de recuperación eliminado correctamente",
            "filterPlaceHolder": "Filtrar por nombre del sitio...",
            "deleteSite": {
                "header": "Eliminar sitio de recuperación",
                "body": "¿Está seguro de que desea eliminar este sitio?",
                "text": "Eliminar"
            },
            "manuallyCreate": {
                "header": "Crear perfil de recuperación manualmente",
                "body": "¿Está seguro de que desea crear manualmente un nuevo perfil?",
                "text": "Crear manualmente"
            },
            "siteName": "Nombre del sitio",
            "newSite": "Sitio nuevo",
            "recoverySitesDescription": "Determine los sitios de oficinas móviles primarios y secundarios, así como las opciones alternativas de espacio comercial.",
            "checkRequiredFields": "Marque todos los campos obligatorios"
        },
        "technicalInfo": {
            "description": "Con su membresía tiene acceso a los [[team]]s que se enumeran a continuación",
            "saveSuccess": "Comentario guardado",
            "comment": "Comentario",
            "technologyRecovery": "Recuperación de [[technology]]",
            "comments": "Comentarios"
        },
        "recoveryGenerator": {
            "saveGeneratorSuccess": "Generador guardado",
            "errorLoadingGeneratorInfo": "Error al cargar la información del generador",
            "checkRequiredFields": "Marque todos los campos obligatorios",
            "saveChanges": {
                "header": "Guardar generador de recuperación",
                "body": "¿Está seguro de que desea guardar los cambios?",
                "text": "Guardar"
            }
        },
        "errorRetrievingRecoveryProfile": "Error al cargar el perfil de recuperación",
        "recoveryProfilesDescription": "Sus perfiles de recuperación lo ayudan a recuperar rápidamente su organización durante un desastre.",
        "activityInformation": "Información de la actividad",
        "essentialContacts": {
            "essentialContacts": "Contactos esenciales",
            "name": "Nombre",
            "title": "Título",
            "primary": "principal",
            "email": "Correo electrónico",
            "mobilePhone": "Teléfono móvil",
            "officePhone": "Teléfono comercial",
            "personalPhone": "Teléfono personal",
            "addContact": "Agregar contacto",
            "essentialContactsDescription": "Personal clave que está autorizado para iniciar una respuesta de agilidad cuando sea necesario",
            "isPrimaryContact": "Es el contacto principal",
            "newContact": "Contacto nuevo",
            "essentialContact": "Contacto esencial",
            "removeEssentialContact": "Quitar contacto esencial",
            "removeEssentialContactDescription": "¿Está seguro de que desea quitar este contacto esencial?",
            "removeEssentialContactSuccess": "Contacto esencial quitado exitosamente",
            "saveEssentialContactSuccess": "Contacto esencial guardado exitosamente",
            "essentialContactActivity": "Actividad de contacto esencial",
            "noEssentialContacts": "Sin contactos esenciales"
        },
        "documents": {
            "documents": "Documentos",
            "manageYourDocuments": "Administre los documentos de su plan en línea",
            "manageYourDocumentsInstruction": "Administre los documentos de su plan en línea aquí.",
            "manageYourDocumentsInstruction2": "Actualmente se le permite tener {{maxUploads}} documentos. Si desea cargar más documentos, comuníquese con su administrador de cuentas.",
            "manageYourDocumentInstruction": "Administre el documento de su plan en línea aquí.",
            "manageYourDocumentInstruction2": "Actualmente se le permite tener 1 documento. Si desea cargar más documentos, comuníquese con su administrador de cuentas.",
            "document": "Documento",
            "noDocuments": "Sin documentos",
            "uploadSuccessful": "Carga exitosa",
            "uploadFailed": "Carga exitosa",
            "maxUploadsReached": "Máx. de ({{maxUploads}} ) alcanzado",
            "documentDeleted": "Documento eliminado",
            "documentDeletedError": "El documento no fue eliminado",
            "getDocumentsError": "No se pueden recuperar los documentos"
        },
        "deleteConfirmation": {
            "header": "Quitar perfil de recuperación",
            "body": "¿Está seguro de que desea quitar el perfil de recuperación '{{ recoveryProfileName }}'?",
            "text": "Quitar"
        },
        "confirmMigrateDocumentsForAllRPS": {
            "header": "Migre documentos myAgility para todos los perfiles de recuperación",
            "body": "¿Está seguro de que desea migrar documentos de myAgility para todos los perfiles de recuperación? Se sobrescribirán todos los documentos existentes cuyos nombres coincidan con los nombres de los documentos migrados.",
            "text": "Migrar"
        },
        "confirmMigrateDocumentsForOneRP": {
            "header": "Migrar documentos myAgility para el perfil de recuperación",
            "body": "¿Está seguro de que desea migrar los documentos del perfil myAgility para el perfil de recuperación '{{ recoveryProfileName }}'? Se sobrescribirán todos los documentos existentes cuyos nombres coincidan con los nombres de los documentos migrados.",
            "text": "Migrar"
        },
        "salesforceId": "Ingrese el ID de la cuenta de Salesforce",
        "associatePortalToSalesforceTooltip": "ID de cuenta de Salesforce",
        "associate": "Asociar",
        "associatePortalToSalesforceAccount": "Asociar el portal con la cuenta de Salesforce",
        "associateToSalesforceAccount": "Portal asociado con cuenta de Salesforce",
        "msgSuccessAssociatedToSalesforceAccount": "Portal asociado correctamente a la cuenta de Salesforce",
        "msgErrorAssociatedToSalesforceAccount": "Error al asociar el portal a la cuenta de Salesforce",
        "alert": {
            "clarifyAlertAction": {
                "header": "Estado de [[alert]]",
                "body": "Confirme que desea pasar al estado de [[Alert]]. Si selecciona Sí, un administrador de declaraciones se comunicará con usted en breve por teléfono o correo electrónico.",
                "text": "Sí"
            },
            "title": "Seleccione ubicaciones cubiertas para poner en [[alert]]",
            "searchTooltip": "Buscar ubicaciones cubiertas",
            "confirmAlert": {
                "header": "Enviar solicitud de [[alert]] para ubicaciones cubiertas",
                "body": "¿Está seguro de que desea enviar una solicitud de [[alert]] para [[location]](es) cubierta (s) {{ numLocations }}?",
                "text": "[[Alert]]"
            },
            "msgSuccessAlert": "Solicitud de [[alert]] enviada correctamente",
            "msgErrorAlert": "No se pudo enviar la solicitud de [[alert]]",
            "msgSelectRecoveryProfiles": "Seleccione al menos una ubicación cubierta"
        }
    },
    "passwordPolicies": {
        "lblPasswordPolicyHeader": "Su contraseña debe contener lo siguiente:",
        "msgPasswordPolicyFail": "La contraseña no cumple con las reglas de complejidad.",
        "msgPasswordPolicyLoading": "Cargando políticas...",
        "msgPasswordPolicyMinCharacters": "Al menos {{number}} caracteres",
        "msgPasswordPolicyMinLowercaseCharacters": "Al menos {{number}} letra(s) minuscula(s)",
        "msgPasswordPolicyMinNumberCharacters": "Al menos {{number}} número(s)",
        "msgPasswordPolicyMinUppercaseCharacters": "Al menos {{number}} letras mayúscula(s)",
        "msgPasswordPolicyNoneFound": "No se encontraron políticas de contraseña.",
        "msgPasswordPolicySpecialCharacters": "Al menos {{number}} caracter(es) especial(es)",
        "msgPasswordsMustMatch": "Las contraseñas no coinciden."
    },
    "profile": {
        "btnChangePassword": "Cambiar la contraseña",
        "btnSaveProfile": "Guardar y finalizar",
        "btnValidateAddress": "Validar dirección",
        "lblGroups": "Grupos",
        "lblDepartments": "[[Departments]]",
        "lblNoOtherLocations": "No hay otras ubicaciones",
        "lblNonSelected": "Ninguna seleccionada",
        "lblDefaultLocation": "PREDETERMINADA ([[location]] principal del usuario si trabaja en varias ubicaciones)",
        "lblOfficePhone": "Teléfono comercial",
        "lblOfficePhoneExt": "Extensión",
        "lblOfficePhoneAllowSMS": "Teléfono comercial",
        "lblPersonalEmail": "Correo electrónico personal",
        "lblPersonalPhone": "Teléfono personal (se recomienda un teléfono habilitado para SMS)",
        "lblAdditionalPersonalPhone": "Teléfono personal adicional",
        "lblMessagingEmail": "Correo electrónico (recibirá todos los mensajes de correo electrónico)",
        "lblMobilePhone": "Teléfono móvil (recibirá todos los SMS y llamadas de voz)",
        "lblPersonal": "Otro",
        "lblDefaultContact": "Predeterminado",
        "lblWorkTitle": "Puesto de trabajo",
        "lblUserFirstName": "Primer nombre",
        "lblUserLastName": "Apellido",
        "lblAccessMyBookmarks": "Acceder a mis marcadores",
        "lblAccessMyDocuments": "Acceder a mis documentos",
        "lblProfileInformation": "información del perfil",
        "lblMyProfileHeader": "Mi perfil",
        "lblMyCrisisTeam": "Mi [[team]] de crisis",
        "lblEmailAddress": "Correo electrónico",
        "lblCoreInformation": "Información básica",
        "lblNoDepartmentMembership": "Usted no es [[member]] de ningún [[department]]",
        "lblNoGroupMembership": "Usted no es [[member]] de ningún grupo",
        "lblQuickLinks": "Enlaces rápidos",
        "msgSaveProfile": "Su perfil se ha guardado",
        "errorSaveUserCustomFieldsFailed": "No se pudieron guardar los campos personalizados del usuario.",
        "errorSaveUserProfile": "No se pudo guardar el perfil de usuario.",
        "lblChangePhoto": "Cambiar foto",
        "lblUserProfileImage": "Imagen de perfil de usuario",
        "lblTenant": "Inquilino",
        "lblSms": "SMS",
        "lblVoice": "Voz",
        "lblAddressMain": "Dirección principal",
        "lblAddressMainCountry": "País",
        "lblAddressMainAddress1": "Dirección",
        "lblAddressMainAddress2": "Dirección 2 (suite, edificio, etc.)",
        "lblAddressMainCity": "Ciudad",
        "lblAddressMainState": "Estado/Provincia/Región",
        "lblAddressMainZip": "Código postal",
        "lblAddressMainAdditionalLocationInfo": "Información adicional de [[location]]",
        "lblAddressMainDepartment": "[[Department]]",
        "lblAddressMainFloor": "Piso",
        "lblUserLanguage": "Idioma",
        "menuPickCountry": "Seleccione un país...",
        "errorCommunicationPreferencesMinimumOne": "Al menos una (1) preferencia de comunicación debe estar activa",
        "errorNoPhoneNumberCannotDisableEmail": "No se ha ingresado un número de teléfono predeterminado; la preferencia de comunicación por correo electrónico no se puede deshabilitar",
        "errorNoEmailsCannotDisableVoiceSms": "No se ingresó un correo electrónico predeterminado; no se pueden deshabilitar los mensajes SMS ni de VOZ",
        "errorNoContactMethodsSupplied": "Se debe proporcionar al menos un método de contacto para un usuario",
        "errorCustomFieldsInvalid": "Complete los campos personalizados obligatorios",
        "errorUnableToLoadCountries": "No se pueden recuperar los países",
        "lblWelcomeToPreparis": "Bienvenido a Preparis",
        "lblInitialLoginMessage": "Gracias por elegir Preparis. Vemos que esta es la primera vez que inicia sesión. Tómese un momento para revisar la información de esta página para asegurarse de que sea precisa.",
        "lblOptInMobileSms": "¿Recibe mensajes SMS móviles?",
        "lblOptInMobileVoice": "¿Recibe mensajes de voz móviles?",
        "lblOptInPersonalSms": "¿Recibe mensajes SMS personales?",
        "lblOptInAdditionalPersonalSms": "¿Recibe mensajes SMS personales adicionales?",
        "lblOptInPersonalVoice": "¿Recibe mensajes de voz personales?",
        "lblOptInAdditionalPersonalVoice": "¿Recibe mensajes de voz personales adicionales?",
        "lblOptInPersonalEmail": "¿Recibe mensajes de correo electrónico personales?",
        "lblOptInBusinessEmail": "¿Recibe mensajes de correo electrónico en la oficina?",
        "lblOptInWorkSms": "¿Recibe mensajes SMS de la oficina?",
        "lblOptInWorkVoice": "¿Recibe mensajes de voz de la oficina?",
        "lblManuallySetPassword": "Establecer contraseña manualmente",
        "lblFloor": "Piso",
        "lblAdditionalInfo": "Información adicional",
        "lblAdditionalLocations": "Trabajos del usuario de otras ubicaciones",
        "lblUserActiveStatus": "Activo",
        "lblUserDeleteStatus": "Eliminado",
        "lblUserInactiveStatus": "Inactivo",
        "lblUserPendingStatus": "Pendiente",
        "lblUserLockedStatus": "Bloqueado",
        "lblUserStatusLegendActiveMessageOnly": "(el usuario recibirá mensajes)",
        "lblUserStatusLegendDefault": "(usuario registrado y puede iniciar sesión)",
        "lblUserStatusLegendInactive": "(el usuario no puede acceder al portal ni recibir mensajes)",
        "lblUserStatusLegendPending": "(el usuario no ha aceptado su invitación)",
        "msgManuallySetPassword1": "Normalmente, a los nuevos usuarios se les envía un correo electrónico de invitación que contiene un enlace que les permite crear su propia contraseña.",
        "msgManuallySetPassword2": "Si ingresa una contraseña aquí, deberá comunicarla verbalmente.",
        "msgUserPasswordChangedSuccess": "La contraseña de usuario se cambió correctamente.",
        "msgUserPasswordChangedFail": "No se pudo cambiar la contraseña del usuario.",
        "msgSaveUser": "El usuario {{firstName}}{{lastName}} se ha guardado correctamente.",
        "msgSaveUserImageSuccess": "La foto del usuario se ha guardado correctamente.",
        "errorSavUserImageFailed": "No se pudo guardar la foto del usuario.",
        "magUserNameExist": "El nombre de usuario ya está en uso por otro usuario",
        "msgInvalidName": "Reemplace el nombre generado para actualizar este usuario habilitado",
        "save": "Guardar",
        "initialLogin": {
            "btnText": "OK"
        }
    },
    "register": {
        "lblMyInformation": "Mi información",
        "lblContactInformation": "Información del contacto",
        "lblAddress": "Dirección",
        "lblAddress2": "Dirección 2",
        "lblCity": "Ciudad",
        "lblState": "Estado",
        "lblZip": "Código postal",
        "lblCountry": "País",
        "lblLocationBuilding": "Ubicaciones/edificios sobre los que recibir notificaciones",
        "firstName": "Primer nombre",
        "lastName": "Apellido",
        "lblLastName": "Apellido",
        "lblFirstName": "Primer nombre",
        "lblMobilePhone": "Teléfono móvil",
        "lblReceiveSMS": "Recibir mensaje SMS",
        "lblReceiveVoice": "Recibir mensaje de voz",
        "lblReceiveEmail": "Recibir mensaje de correo electrónico",
        "lblLoading": "Cargando...",
        "lbSelectLocations": "¿Sobre qué ubicaciones le gustaría recibir notificaciones importantes?",
        "lblValidateAddress": "Validar dirección",
        "msgRegisterCodeInvalid": "No se puede registrar con el código de registro; intente con otro código de registro",
        "msgRegisterCodeFail": "No se pudo registrar con el código de registro; inténtelo de nuevo más tarde.",
        "msgLocationRequired": "Se requiere al menos una [[location]] para recibir notificaciones",
        "msgCommunicationMethodRequired": "Se requiere al menos un método de comunicación para recibir notificaciones",
        "msgRegisterFailed": "No se pudo registrar la cuenta de usuario; inténtelo de nuevo más tarde.",
        "msgRegistrationComplete": "¡Felicidades! Ahora está registrado para recibir notificaciones importantes de {{ portalName }}. Para realizar cambios en sus preferencias en cualquier momento, puede iniciar sesión en {{ portalUrl }}",
        "msgRegisterUser": "Registrando cuenta de usuario, espere...",
        "msgSaveUser": "Guardando la información del usuario, espere...",
        "msgSaveUserSuccess": "La información del usuario se guardó correctamente",
        "msgSaveUserFailed": "No se pudo guardar la información del usuario; inténtelo de nuevo más tarde.",
        "msgRemoveUser": "Eliminando la cuenta de usuario, espere...",
        "msgRemoveUserSuccess": "Su cuenta se elimina.",
        "msgRemoveUserFail": "No se pudo quitar su cuenta; inténtelo de nuevo más tarde."
    },
    "layout": {
        "aAllCallinAnnouncements": "Todos los anuncios de llamadas entrantes",
        "aAllSplashMessage": "Todos los mensajes de bienvenida",
        "aCommunicationsDashboard": "Panel de comunicaciones",
        "aCreateSlashMsg": "Crear un mensaje de bienvenida",
        "aCrisisTeam": "[[Team]] de crisis",
        "aEmergencyContacts": "Contactos de emergencia",
        "aRecordCallinAnnouncement": "Grabar un anuncio de llamada entrante",
        "aSendEmergencyMessage": "Enviar mensaje",
        "aStartConcall": "Iniciar una llamada de conferencia",
        "lblCommunicateIncident": "Comunicarse sobre un evento",
        "lblHelpAndAssistance": "Ayuda y asistencia",
        "lblIncidentHistory": "Historial de incidentes",
        "lblIncidents": "Incidentes",
        "lblManageIncidents": "Gestionar comunicaciones",
        "lblNewIncident": "Nuevo incidente",
        "lblQuickSnapshot": "Instantánea rápida",
        "lblViewOverview": "Ver descripción general",
        "aPrivacy": "Privacidad",
        "aScheduledMessages": "Mensajes programados",
        "aSupport": "Asistencia",
        "aTermsOfService": "Términos de servicio",
        "lblAccessAndPermissions": "Permiso de acceso",
        "lblAdditionalResources": "Más recursos",
        "lblBookmark": "Marcador",
        "lblChecklists": "Listas de comprobación",
        "lblCompanyProfile": "Perfil de la empresa",
        "lblDepartments": "[[Departments]]",
        "lblDocuments": "DOCUMENTOS",
        "lblEntities": "Entidades",
        "lblFeaturedResources": "Recurso destacado",
        "lblGroups": "Grupos",
        "lblHelp": "Ayuda",
        "lblHome": "Inicio",
        "lblInTouch": "Siempre estamos en contacto con nuestro [[client]].",
        "lblLoading": "Cargando",
        "lblManage": "Gestionar",
        "lblMessagingSettings": "[[Setting]] de mensajería",
        "lblMessagingSetup": "[[Setting]] de mensajería",
        "lblMyPreparis": "Mi Preparis",
        "lblMyProfile": "Mi perfil",
        "lblNavigatePortal": "Navegar portal",
        "lblNewResources": "Nuevos recursos",
        "lblReports": "[[Reports]]",
        "lblResources": "Recursos",
        "lblResourceTypes": "Tipos de recursos",
        "lblRoles": "Roles",
        "lblSavedMessages": "Mensajes guardados",
        "lblSecurity": "Seguridad",
        "lblSettings": "Configuraciones",
        "lblSignout": "Cerrar sesión",
        "lblSystemEmails": "Correos electrónicos del sistema",
        "lblSystemSettings": "[[Setting]] del sistema",
        "lblTabletops": "Tabletops",
        "lblTenants": "Inquilinos",
        "lblThreats": "Amenazas",
        "lblTraining": "CAPACITACIÓN",
        "lblUserInitiatedMessages": "Incidentes iniciados por el usuario",
        "lblUsers": "Usuarios",
        "lblWebinars": "Seminarios web",
        "lblHierarchy": "Jerarquía",
        "lblComplianceCalendar": "Calendario de cumplimiento",
        "lblScopes": "[[Scopes]]",
        "lblTeams": "[[Teams]]",
        "lblTeamMembers": "[[Team Members]]",
        "lblItems": "[[Items]]",
        "lblIssues": "[[Issues]]",
        "lblExternalResources": "[[External Resources]]",
        "lblAssets": "[[Assets]]",
        "lblPlans": "[[Plans]]",
        "lblAdministration": "Administración",
        "lblNotifications": "Notificaciones",
        "lblStorageLocations": "Ubicaciones de almacenamiento",
        "lblComplianceCategories": "Categorías de cumplimiento",
        "lblExternalResourceTypes": "Tipos de [[External Resource]]",
        "lblIq": "iQ",
        "lblPortalSnapshot": "Instantánea de portales",
        "lblPortalsManagement": "Gestión de portales",
        "lblPortalManagement": "Gestión del portal",
        "lblPortalResources": "Recursos del portal",
        "lblPortalResourceViews": "Vista de recursos del portal",
        "lblBulkUploadHistory": "Historial de carga masiva",
        "lblApiManagement": "Gestión de API",
        "lblApiManagementView": "Integraciones de terceros",
        "lblPlannerManagement": "Gestión del [[Planner]]",
        "lblCustomFields": "Campos personalizados",
        "panic": "Tablero de pánico",
        "lblEmergencyButtonDashboard": "[[Alerts]] iniciados por el móvil",
        "lblBranding": "Personalización",
        "lblSelfRegistration": "Adhesión",
        "scheduledConferenceCalls": "Llamadas de conferencia programadas",
        "messaging": "Mensajería",
        "navLinkAlertsForOverview": "[[Alerts]]",
        "lblRecoveryProfiles": "PERFILES DE RECUPERACIÓN",
        "recoveryProfiles": "Perfiles de recuperación",
        "lblGeofences": "Geocercas",
        "lblPointsOfInterest": "Puntos de interés",
        "lblIncidentTypes": "Tipos de incidentes",
        "lblPublicAlertSubscriptions": "Suscripciones a [[alerts]]",
        "lblIncidentTriggers": "Disparadores de incidentes",
        "lblPanicConfig": "Configuración de incidentes de pánico",
        "lblPublicAlerts": "[[Alerts]] públicas",
        "lblGeolocations": "Geolocalizaciones",
        "lblPortals": "Portales",
        "alerts": "[[Alerts]]",
        "planManagement": "Gestión del [[plan]]",
        "training": "Capacitación",
        "reports": "[[Reports]]",
        "settings": "Configuraciones",
        "alert": "[[Alert]]",
        "sendMessage": "Enviar mensaje",
        "admin": "Administración",
        "privacy": "Privacidad",
        "aboutUs": "Sobre nosotros",
        "incidentManagement": "[[Incident Manager]]",
        "lblMobileEmergencyButtons": "Configuración de [[alert]] iniciados por el móvil",
        "lblConfigureBulkUpload": "Configurar la carga masiva",
        "planner": "Planificación",
        "exerciseManager": "[[Exercise Manager]]",
        "lblChangeLanguage": "Cambiar idioma",
        "lblExercises": "Ejercicios",
        "lblExerciseDashboard": "Panel",
        "lblExerciseRoadmap": "Mapa de Ruta",
        "lblExerciseScenarioManager": "Escenarios",
        "lblExerciseReports": "[[Reports]]",
        "lblExerciseSystemScenarios": "Escenarios del Sistema",
        "lblExerciseExerciseTypes": "Tipos de Ejercicios",
        "lblExerciseThreadHazards": "Amenaza/Peligros",
        "lblIncidentManagementHistory": "Historial de incidentes",
        "risk": "[[Risk]]",
        "language": {
            "en": "English",
            "es": "Español",
            "fr": "Français",
            "pt": "Português"
        },
        "changeLanguageError": "Ha ocurrido un error inesperado en el servidor.  Comuníquese con el soporte técnico.",
        "calendar": {
            "dayNames": {
                "sunday": "Domingo",
                "monday": "Lunes",
                "tuesday": "Martes",
                "wednesday": "Miércoles",
                "Thursday": "Jueves",
                "friday": "Viernes",
                "saturday": "Sábado"
            },
            "dayNamesShort": {
                "sunday": "Dom",
                "monday": "Lun",
                "tuesday": "Mar",
                "wednesday": "Mié",
                "thursday": "Jue",
                "friday": "Vie",
                "saturday": "Sáb"
            },
            "dayNamesMin": {
                "sunday": "Do",
                "monday": "Lu",
                "tuesday": "Ma",
                "wednesday": "Mi",
                "thursday": "Ju",
                "friday": "Vi",
                "saturday": "Sá"
            },
            "monthNames": {
                "january": "Enero",
                "february": "Febrero",
                "march": "Marzo",
                "april": "Abril",
                "may": "Mayo",
                "june": "Junio",
                "july": "Julio",
                "august": "Agosto",
                "september": "Septiembre",
                "october": "Octubre",
                "november": "Noviembre",
                "december": "Diciembre"
            },
            "monthNamesShort": {
                "january": "Ene",
                "february": "Feb",
                "march": "Mar",
                "april": "Abr",
                "may": "Mayo",
                "june": "Jun",
                "july": "Jul",
                "august": "Ago",
                "september": "Sep",
                "october": "Oct",
                "november": "Nov",
                "december": "Dic"
            },
            "today": "Hoy",
            "clear": "Eliminar",
            "weekHeader": "Semana"
        },
        "footerCopyRight": "Copyright© 2007-{{year}} Preparis. Reservados todos los derechos."
    },
    "callinAnnouncements": {
        "btnCall": "Llamada",
        "btnDeactivate": "Desactivar",
        "btnMakeActive": "Activar",
        "btnNewAnnouncement": "Nuevo anuncio",
        "btnSaveNewAnnouncement": "Guardar anuncio",
        "errorAnnouncementDeleted": "Hubo un [[issue]] al eliminar este anuncio; inténtelo de nuevo más tarde.",
        "errorAtleastOneRecordingOption": "Debe especificar al menos una opción de grabación",
        "errorCallToRecordPhoneNumberRequired": "Especifique un número de teléfono para su grabación.",
        "errorUnableToRecordMessage": "No se puede grabar el mensaje en este momento; inténtelo de nuevo más tarde.",
        "lblActivatedOn": "Activado el",
        "lblActiveAnnouncement": "Anuncio activo",
        "lblActiveAnnouncementDtl": "Los usuarios escucharán este mensaje cuando llamen.",
        "lblActiveAnnouncementNote": "Solo puede haber 1 anuncio activo a la vez.",
        "lblAnnouncementActive": "Activación de anuncio completa",
        "lblAnnouncementActivity": "Actividad de anuncio de llamada entrante",
        "lblAnnouncementDeactivated": "Desactivación del anuncio correcta.",
        "lblAreYourSureActivate": "Activar anuncio de llamada entrante",
        "lblAreYouSureActivateWithoutReplace": "{{selectedDescriptionEncoded}} se convertirá en el anuncio de llamada entrante activo.",
        "lblAreYouSureActiveWithReplace": "{{selectedDescriptionEncoded}} se convertirá en el anuncio de llamada entrante activo. {{activeDescriptionEncoded}} será desactivado.",
        "lblCallToRecord": "Llamar para grabar",
        "lblCallToRecordInstructions": "Ingrese el número de teléfono al que desea que lo llamen y luego presione llamar. Recibirá una llamada en unos momentos para luego grabar su mensaje. Después de grabar su mensaje, podrá escucharlo y volver a grabarlo si lo desea.",
        "lblChooseRecordingOption": "Elija una de las siguientes opciones para grabar su anuncio.",
        "lblConfirmDelete": "Confirmar eliminación",
        "lblDefaultNewAnnouncementTitle": "Nuevo anuncio de llamada entrante",
        "lblHeader": "Anuncios de llamada para {{callInAnnouncementPhoneNumber}}",
        "lblInactiveAnnouncement": "Anuncios inactivos",
        "lblLastModifiedOn": "Última modificación el",
        "lblLoading": "Por favor espere...",
        "lblMessageColHdr": "Mensaje",
        "lblMessageNameDescColHdr": "Nombre/Descripción",
        "lblNameDesc": "Nombre/Descripción",
        "lblNoActiveAnnouncementsBody": "El mensaje predeterminado del sistema se utilizará indicando que no hay ningún anuncio en este momento.",
        "lblNoActiveAnnouncementsHdr": "No hay anuncios activos",
        "lblNone": "Ninguno",
        "lblNote": "Nota",
        "lblRecordedBy": "Grabado por",
        "lblRecordedByColHdr": "Grabado por",
        "lblRecordedColHdr": "Grabado",
        "lblRecording": "Grabación",
        "lblRecordingCanceled": "Se canceló la grabación.",
        "lblStatusColHdr": "Estado",
        "lblTextToSpeech": "Texto a voz",
        "mgsCallinNotSetupHdr": "Sus anuncios de llamada entrante no están configurados",
        "msgAnnouncementDeleted": "El anuncio se ha eliminado exitosamente.",
        "msgAnnouncementSaved": "El anuncio de llamada entrante se ha guardado exitosamente.",
        "msgCallinNotSetup": "Los anuncios integrados de llamadas hacen que sea más fácil y rápido mantener a sus [[team]]s de crisis y empleados actualizados con la información en desarrollo durante un incidente. Los anuncios de llamadas entrantes son una gran plataforma para comunicar actualizaciones de información sobre tormentas de invierno y cierres de oficinas. Simplemente al grabar y activar un mensaje, su gente puede llamar para escuchar la información más reciente.",
        "msgCallinNotSetup2": "Solicite agregar este servicio sin cargo adicional comunicándose con",
        "callinAnnouncements": "Anuncios de llamadas entrantes",
        "removeConfirm": {
            "header": "Quitar anuncio de llamada entrante",
            "body": "¿Está seguro de que desea quitar este anuncio de llamada entrante?",
            "text": "Sí"
        },
        "deactivateConfirm": {
            "header": "Desactivar el anuncio de llamada entrante",
            "body": "El anuncio de llamada entrante {{description}} se desactivará. Se utilizará el anuncio de llamada entrante predeterminado a menos que active otro anuncio.",
            "text": "Sí"
        },
        "showAdvanced": "Permitir varios anuncios de llamadas entrantes activas",
        "addNewOption": "Agregar nueva opción secundaria",
        "addNewSiblingRight": "Agregar nueva opción a la derecha",
        "addNewSiblingLeft": "Agregar nueva opción a la izquierda",
        "addNewSiblingAbove": "Agregar nueva opción arriba",
        "addNewSiblingBelow": "Agregar nueva opción a continuación",
        "copyOption": "Opción de copia",
        "editOption": "Editar {{ optionPrompt }}",
        "callinAnnouncement": "Anuncio de llamada",
        "callInAnnouncementOption": "Opción de anuncio de llamada entrante",
        "lblCallInAnnouncementValue": "Presionar {{optionValue}}",
        "lblInstructions": "Instrucciones",
        "resetToDefaultInstructions": "Restablecer las instrucciones predeterminadas",
        "newCallinOptionModal": {
            "headerNew": "Crear nueva opción de llamada",
            "headerEdit": "Editar la opción de llamada {{ optionPrompt }}",
            "optionValue": "Valor",
            "optionPrompt": "Mensaje",
            "optionInstructions": "Instrucciones",
            "optionCallInAnnouncement": "Anuncio de llamada",
            "selectCallInAnnouncementConfirmation": {
                "header": "Seleccione Anuncio de llamada entrante para la opción",
                "body": "¿Está seguro de que desea seleccionar '{{ announcementDescription }}' para la opción?",
                "text": "Seleccionar"
            },
            "btnUnselect": "Deseleccionar",
            "btnSelect": "Seleccionar"
        },
        "saveCallinAnnouncementOptionConfirm": {
            "header": "Guardar opción de anuncio de llamada",
            "body": "¿Está seguro de que desea guardar la opción de anuncio de llamada entrante '{{ optionPrompt }}'?",
            "text": "Guardar"
        },
        "deleteCallinAnnouncementOptionConfirm": {
            "header": "Eliminar la opción de anuncio de llamada entrante",
            "body": "¿Está seguro de que desea eliminar la opción de anuncio de llamada entrante '{{ optionPrompt }}'?",
            "text": "Eliminar"
        },
        "deleteAllCallinAnnouncementOptionsConfirm": {
            "header": "Eliminar todas las opciones de anuncio de llamada entrante",
            "body": "El cambio al modo de anuncio de llamada entrante activo único eliminará las opciones de anuncio de llamada entrante. ¿Está seguro de que desea cambiar al modo de anuncio de llamada entrante activo único y eliminar las opciones?",
            "text": "Eliminar y cambiar"
        },
        "errors": {
            "optionWithSamePromptError": "Una opción de llamada entrante con el mensaje '{{ optionPrompt }}' ya existe."
        },
        "copyCallinAnnouncementOption": {
            "header": "Copiar la opción de anuncio de llamada '{{ optionPrompt }}'",
            "body": "Seleccione la opción de anuncio de llamada entrante de destino",
            "copySubHierarchy": "Copiar subjerarquía",
            "errorSameOption": "Esta opción se está copiando",
            "errorDuplicatePrompt": "'{{ optionPrompt }}' ya tiene una opción secundaria con el mismo mensaje",
            "errorMaxChildNodesNumber": "'{{ optionPrompt }}' ya tiene opciones secundarias {{ maxChildNodes }}"
        },
        "msgCopyCallinAnnouncement": {
            "success": "Se copia la opción de anuncio de llamada entrante",
            "error": "No se pudo copiar la opción de anuncio de llamada entrante"
        }
    },
    "iq": {
        "common": {
            "moveError": "Recurso iQ no movido",
            "moveTeamTooltip": "Seleccionar el [[team]] de destino.",
            "assignToTeamMember": "Asignar a un [[team member]] en el [[team]] de destino."
        },
        "itemType": {
            "Document": "Documento",
            "Storage Item": "Elemento de almacenamiento",
            "Other": "Otro",
            "Task/Reminder": "Tarea/Recordatorio"
        },
        "columnsSelected": "{0} columnas seleccionadas"
    },
    "incidentManagement": {
        "createNewIncident": "Crear nuevo incidente",
        "recentIncidents": "Incidentes recientes",
        "noRecentIncidents": "No reciente/activos Incidentes",
        "lblIncidentDetails": "Detalles del incidente",
        "lblIncidentRetrospective": "Retrospectiva de Incidentes",
        "lblNewIncident": "Create New Incident",
        "communicationLog": "Crear nuevo incidente",
        "addIncidentNotes": "Agregar notas de incidentes",
        "addNotes": "Agregar notas",
        "resolveIncident": "Resolver incidente",
        "viewPlans": "Ver planes",
        "conferenceCallStarted": "Llamada de conferencia iniciada",
        "emailSent": "Email enviado",
        "messageSent": "Mensaje enviado",
        "selectedMembers": "Miembros Seleccionados",
        "export": {
            "allIncidents": "Todos los incidentes",
            "displayedIncidents": "Incidentes mostrados",
            "header": "Exportar incidentes",
            "body": "¿Desea exportar solo los {{ x }} incidentes mostrados o todos los incidentes?",
            "successMsg": "Exportación generada con éxito",
            "errorMsg": "Error al generar la exportación",
            "fileName": "Incidentes.csv"
        },
        "noNotes": "Sin notas de incidentes",
        "actionPlans": "Planes de acción de BC",
        "planActivationProcessStateTypes": {
            "notStarted": "No iniciado",
            "inProgress": "En curso",
            "skip": "Saltear",
            "done": "Listo"
        },
        "authorized": "Autorizado para contactar proveedores",
        "contacted": "Proveedor/es contactado/s",
        "mobilized": "Empleados movilizados",
        "verified": "Verificado",
        "applications": "[[Applications]]",
        "equipment": "[[Equipment]]",
        "confirmReadiness": "Confirmar disponibilidad",
        "passDue": "Fecha límite del pase",
        "activateDetails": {
            "activateDetails": "Activar detalles",
            "calendarDescription": "Este calendario filtrará las próximas fechas críticas."
        },
        "noCriticalDate": "Agregar fecha crítica",
        "noAssignees": "Sin empleados autorizados",
        "noEmployees": "Sin empleados movilizados",
        "noEquipment": "Sin equipo verificado",
        "noApplications": "Sin aplicaciones verificadas",
        "noVendors": "Sin proveedores de contacto",
        "noLocation": "Sin [[location]]",
        "msgUpdateStatusSuccess": "Estado de activación guardado",
        "msgUpdateStatusFailed": "Error al guardar el estado de activación",
        "msgActionPlanReady": "Plan de acción para {{ process }} está listo",
        "communicationSidebar": {
            "include": "Incluir",
            "includeApplicationList": "Lista de aplicaciones en el mensaje",
            "includeEquipmentList": "Lista de equipos en el mensaje",
            "includeAdditionalInstructions": "Instrucciones adicionales en el mensaje",
            "attachments": "Archivos adjuntos",
            "emailAttachmentsSizeError": "El tamaño de los archivos adjuntos de correo electrónico supera los {{maxSize}} Mb permitidos. No se puede enviar el correo electrónico.",
            "loadingMessageParticipantsError": "Error al cargar los participantes de la comunicación"
        },
        "dashboard": {
            "overallProgress": "Progreso general",
            "progressByRto": "Progreso por RTO",
            "escalatedActionPlans": "Planes de acción escalados",
            "escalatedActionPlansDescription": "Planes de acción con problemas escalados",
            "departmentProgress": "Progreso del [[department]]",
            "locationProgress": "Progreso de la [[location]]",
            "escalatedIssues": "Problemas escalados",
            "allIssues": "Todos los problemas"
        },
        "issues": {
            "issue": "Problema",
            "issuesListTitle": "Mis problemas",
            "reportIssue": "Reportar problema",
            "issueTitle": "Nombre de los problemas",
            "resolved": "Resuelto",
            "dateTimeCreated": "Fecha/hora",
            "lblOwner": "Propietario",
            "lblCreatedOn": "Creado el",
            "lblStatus": "Estado",
            "lblDepartment": "[[Department]]",
            "lblProcess": "[[Process]]",
            "lblCategory": "Categoría",
            "lblDescription": "Descripción",
            "addComment": "Agregar comentario",
            "escalateToManagement": "Escalar a la gerencia",
            "resolveIssue": "Resolver problema",
            "reopenIssue": "Reabrir problema",
            "edit": "Editar",
            "status": {
                "70cd9cf8-947a-43a1-8f19-f27b58da065e": "Abierto",
                "2e70fb00-5551-42f8-8057-a3a45dcfbb19": "Resuelto"
            },
            "lblChangeStatus": {
                "70cd9cf8-947a-43a1-8f19-f27b58da065e": "Resolver problema",
                "2e70fb00-5551-42f8-8057-a3a45dcfbb19": "Reabrir problema"
            },
            "comment": "Comentario",
            "msgErrorLoadingComments": "Error al cargar los comentarios del problema de activación del plan.",
            "escalatedIssuesOnly": "Solo problemas escalados",
            "issueDetails": "Detalles del problema"
        },
        "incidentRetrospective": {
            "incidentName": "Nombre del incidente",
            "incidentType": "Tipo de incidente",
            "incidentDuration": "Duración del incidente",
            "incidentCreator": "Incidente creado por",
            "description": "Descripción",
            "activePlans": "Planes Activados",
            "issues": "[[Issues]]",
            "Communications": "Comunicaciones",
            "executiveSummary": "Resumen ejecutivo",
            "retrospective": "Retrospectivo",
            "incidentNotes": "Notas de incidentes",
            "department": "[[Department]]",
            "location": "[[Location]]",
            "process": "[[Process]]",
            "title": "Título",
            "teamLead": "Jefe de equipo",
            "deliveryMethod": "Método de entrega",
            "dateSent": "Fecha de envío",
            "totalRecipients": "Destinatarios totales",
            "viewPlans": "Ver planes",
            "publishIncidentRetrospective": "Publicar retrospectiva de incidentes",
            "unResolveIncident": "Reactivar incidente",
            "startNote": "Empezar a hacer esto",
            "continueNote": "Continuar haciendo esto",
            "stopNote": "Dejar de hacer esto",
            "incidentInvalid": "El ID del incidente no es válido",
            "theIncidentNeedsToBeResolve": "El incidente necesita ser resuelto para ver su retrospectiva.",
            "generateAuditReport": "Informe de auditoría",
            "errorGeneratingAuditReport": "Error al generar el [[report]] de auditoría",
            "modalHeader": "Publicar retrospectiva de incidentes",
            "modalBody": "¿Está seguro de que desea publicar esta retrospectiva?",
            "publish": "Publicar",
            "draft": "Borrador",
            "version": "Versión",
            "modalEditor": "{{field}} para la retrospectiva",
            "retrospectiveSaveSuccessfully": "La retrospectiva se guardó correctamente.",
            "retrospectiveSaveUnsuccessfully": "La retrospectiva no se guardó correctamente.",
            "summaryPlaceholder": "Ingresar la información del resumen ejecutivo que pertenece al incidente/ejercicio",
            "startPlaceholder": "por ejemplo, deberíamos programar más ejercicios",
            "continuePlaceholder": "por ejemplo, comunicarse de manera efectiva",
            "stopPlaceholder": "por ejemplo, usar el mismo escenario para los ejercicios",
            "incidentReactivatedSuccessfully": "Incidente reactivado con éxito",
            "incidentReactivatedUnsuccessfully": "Incidente reactivado sin éxito",
            "reactivateModalHeader": "Reactivar incidente",
            "reactivateModalBody": "¿Está seguro de que desea reactivar este incidente?"
        },
        "liveIncidents": "Incidentes en vivo",
        "incidentExercises": "Ejercicios de incidentes",
        "contactTeamLead": "Contacto [[Team Lead]]",
        "contactSubjectMatterExpert": "Contactar SME",
        "subjectMatterExpert": "SME",
        "incidentHistory": "Historial de incidentes",
        "enterExerciseMode": "Ingrese al modo de ejercicio",
        "exitExerciseMode": "Salir del modo de ejercicio"
    },
    "exerciseManager": {
        "scenarios": "Escenarios",
        "systemScenarios": "Escenarios del sistema",
        "launchExercise": "Ejercicio de lanzamiento",
        "exerciseManager": "[[Exercise Manager]]",
        "assignedScenarios": "Escenarios asignados",
        "assignedExercises": "Ejercicios asignados",
        "newExerciseType": "Nuevo tipo de ejercicio",
        "newSystemExerciseType": "Nuevo tipo de ejercicio del sistema",
        "newThreadHazard": "Nueva amenaza/peligro",
        "newSystemThreadHazard": "Nueva amenaza/peligro del sistema",
        "systemExerciseType": "Tipo de ejercicio del sistema",
        "systemThreadHazard": "Amenaza/peligro del sistema",
        "roadmap": {
            "theWeekOf": "La semana de...",
            "exerciseRoadmap": "Hoja de ruta del ejercicio",
            "weekOf": "Semana de",
            "roadmapDescription": "Esta hoja de ruta proporciona una vista rápida de sus ejercicios programados. Para programar un nuevo ejercicio, abra el panel derecho y seleccione el plan que desea programar para iniciar el [[process]].",
            "scheduleExerciseDescription": "Programe el plan en el que le gustaría realizar un ejercicio o prueba.",
            "openExercise": "Abrir ejercicio",
            "firstScheduled": "Ir al primer ejercicio programado",
            "noFirstScheduledExercise": "No se han programado ejercicios futuros para {{name}}",
            "weekOption": "4 Semanas",
            "monthOption": "12 Meses"
        },
        "scenarioManager": {
            "60024": "Un escenario de ejercicio existente ya tiene este nombre",
            "scenarioName": "Nombre del escenario",
            "exerciseType": "Tipo de ejercicio",
            "hazardType": "Amenaza/Peligro",
            "description": "Descripción",
            "assumptions": "Supuestos",
            "objectives": "Objetivos",
            "expectations": "Expectativas",
            "metrics": "Métrica",
            "selectAExerciseType": "Seleccione un tipo de ejercicio",
            "selectAHazardType": "Seleccione un tipo de amenaza/peligro",
            "successOnSave": "El escenario se guardó con éxito",
            "clear": "Eliminar"
        },
        "sideMenu": {
            "deleteScenario": "Borrar escenario",
            "loadScenario": "Cargar escenario",
            "successOnDelete": "El escenario fue eliminado con éxito",
            "failedToDelete": "El escenario se eliminó sin éxito",
            "removeConfirmationHeader": "Eliminar escenario",
            "removeConfirmationBody": "¿Está seguro de que desea eliminar el marcador {{ scenarioName }}?",
            "sideMenuTitleInfo": "Cree o cargue un escenario para que pueda usarse en ejercicios futuros.",
            "scenarios": "Escenarios",
            "overrideBody": "Ya existe un escenario con el mismo nombre",
            "numberOfProcesses": "Número de [[processes]]",
            "failedToGetExerciseTargetsInfo": "Se produjo un error al intentar obtener la información de los objetivos del ejercicio.",
            "notScheduled": "No programada",
            "newScenario": "Crear escenario",
            "copyScenario": "Copiar escenario como nuevo"
        },
        "modalEditor": "{{field}} para el escenario",
        "exercises": {
            "60033": "Horario de ejercicio superpuesto",
            "60035": " El ejercicio está asociado a un incidente",
            "exercises": "Ejercicios",
            "updateScenario": "Actualizar escenario",
            "exerciseDate": "Fecha del ejercicio",
            "datePlaceholder": "Seleccione la fecha de inicio y finalización",
            "exerciseName": "Nombre del ejercicio",
            "details": "Detalles",
            "selectedTesters": "Probadores seleccionados",
            "modalEditor": "{{field}} para el ejercicio",
            "startTime": "Hora de inicio",
            "endTime": "Hora de finalización",
            "startAndEndDate": "Fecha de inicio/finalización",
            "startDatePlaceholder": "Seleccione una fecha de inicio",
            "endDatePlaceholder": "Seleccione una fecha de finalización",
            "processes": "[[Processes]]",
            "successOnSave": "El ejercicio se guardó con éxito",
            "departmentInvalid": "El [[department]] no es válido",
            "locationInvalid": "La [[location]] no es válida",
            "testerAddedSuccessfully": "Probador agregado con éxito",
            "testerRemoveSuccessfully": "Probador quitado exitosamente",
            "testerAddedUnsuccessfully": "Probador añadido sin éxito",
            "testerRemoveUnsuccessfully": "Probador eliminado sin éxito",
            "scheduleAnExerciseFor": "Programe un ejercicio para",
            "deleteExerciseSuccess": "Ejercicio eliminado",
            "deleteExerciseFail": "No se pudo quitar el ejercicio",
            "saveScenario": "Guardar escenario"
        },
        "exerciseTypeModal": {
            "title": "Tipo de ejercicio",
            "desc": "Agregue o edite un tipo de ejercicio para usar con este [[exercise manager]]",
            "typeNamePlaceholder": "Nombre del tipo de ejercicio",
            "exerciseTypes": "Ejercicios",
            "exerciseTypeInUseError": "El ejercicio está asignado a escenarios o ejercicios activos",
            "duplicateExerciseTypeNameError": "Nombre del tipo de ejercicio duplicado",
            "systemExerciseTypeEditMessage": "Los tipos de ejercicios del sistema no se pueden editar.",
            "system": {
                "header": "Eliminar ejercicio",
                "body": "¿Desea eliminar este tipo de ejercicio solo de este portal o de todos los portales?",
                "removeFromThisPortal": "Eliminar de este portal",
                "removeFromAllPortals": "Eliminar de todos los portales"
            },
            "portal": {
                "header": "Eliminar ejercicio",
                "body": "¿Está seguro de que desea quitar este tipo de ejercicio?",
                "ok": "OK"
            }
        },
        "threadHazardModal": {
            "title": "Amenaza/Peligro",
            "desc": "Agregue o edite una amenaza/peligro que se usará para este [[exercise manager]]",
            "threadHazardNamePlaceholder": "Nombre de amenaza/peligro",
            "threadHazards": "amenaza/peligros",
            "threadHazardInUseError": "amenaza/peligros está asignado a escenarios o ejercicios activos",
            "duplicateThreadHazardNameError": "Nombre de amenaza/peligro duplicado",
            "systemThreadHazardEditMessage": "Las amenazas/peligros del sistema no se pueden editar.",
            "system": {
                "header": "Eliminar amenaza/peligro",
                "body": "¿Desea eliminar esta amenaza/peligro solo de este portal o de todos los portales?",
                "removeFromThisPortal": "Eliminar de este portal",
                "removeFromAllPortals": "Eliminar de todos los portales"
            },
            "portal": {
                "header": "Eliminar amenaza/peligro",
                "body": "¿Está seguro de que desea quitar esta amenaza/peligro?",
                "ok": "OK"
            }
        }
    },
    "textEditor": {
        "tags": "Etiquetas",
        "element": "Elemento"
    }
}
