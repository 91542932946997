{
    "cre": {
        "locations": "[[Locations]]",
        "location": "[[Location]]"
    },
    "common": {
        "locations": "[[Locations]]",
        "location": "[[Location]]",
        "defaultLocation": "[[Location]] predeterminado"
    },
    "panicTriggers": {
        "addCustomizedByLocation": "Agregar disparador personalizado por [[locations]]"
    },
    "reports": {
        "labels": {
            "Tenant": "Inquilino",
            "Primary Location": "[[Location]] Primario",
            "Location": "[[Location]]"
        }
    },
    "manage": {
        "nav": {
            "lblLocations": "[[Locations]]"
        },
        "locations": {
            "btnLocationCreate": "Agregar nuevo [[location]]",
            "btnSaveLocation": "Guardar [[location]]",
            "duplicateLocationName": "Ya existe un [[location]] con el mismo nombre.",
            "errorLocationReferenced": "Los usuarios hacen referencia al [[location]] como [[location]] primario",
            "errorRemoveLocationFailed": "Se produjo un error al eliminar el [[location]].",
            "errorSaveLocationFailed": "Se produjo un error al guardar el [[location]].",
            "lblAreYouSureRemoveLocation": "¿Está seguro de que desea eliminar este [[location]]?",
            "lblBuildingAssignedToTenantsAreYouSureRemoveLocation": "El [[location]] está asociado a inquilinos. Eliminar el [[location]] lo quitará de los inquilinos. ¿Está seguro de que desea eliminar este [[location]]?",
            "lblLocationActivity": "Actividad de construcción",
            "lblLocationInfo": "Información de construcción",
            "lblLocationListTitle": "[[Locations]]",
            "lblLocationMembers": "Usuarios asociados a este [[location]]",
            "lblLocationName": "Nombre del [[location]]",
            "lblNewLocation": "Nuevo [[location]]",
            "msgRemoveLocationSuccess": "El [[location]] se ha eliminado exitosamente.",
            "msgSaveLocationSuccess": "El [[location]] {0} se ha guardado exitosamente.",
            "msgUpdateGeofence": "La dirección del [[location]] ha cambiado, ¿le gustaría actualizar la geocerca de este [[location]]?",
            "deleteLocation": "Eliminar [[location]]",
            "removeLocation": "Eliminar [[location]]"
        },
        "roles": {
            "lblEntityMgmtExplain": "Permita a los usuarios crear/modificar/eliminar [[locations]], grupos y departamentos.",
            "lblIncludeCrisisDescription": "Muestre la información de contacto de los usuarios con este rol en la página Equipo de crisis de cada [[location]]."
        },
        "bulkUploadConfig": {
            "lblDefaultLocation": "[[Location]] predeterminado",
            "defaultLocationPlaceHolder": "Seleccionar [[location]] predeterminado",
            "defaultLocationTooltip": "Este [[location]] se asignará a los nuevos usuarios que no tengan un [[location]] especificado en su archivo de carga masiva."
        }
    }
}
