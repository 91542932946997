import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { LocalStorageService } from '@app/providers/local-storage.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChildrenOutletContexts } from '@angular/router';
import { ConfirmationCommentModalComponent } from '@app/components/modals/confirmation-comment-modal/confirmation-comment-modal.component';
import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { AddNotificationSettingModalComponent } from '@app/components/add-notification-setting-modal/add-notification-setting-modal.component';
import { ConfirmationModalMultiOptionComponent } from '@app/components/modals/confirmation-modal-multioption/confirmation-modal-multioption.component';
import { AddBookmarkModalComponent } from '@app/components/add-bookmark-modal/add-bookmark-modal.component';
import { DynamicHtmlComponent } from '@app/components/reports/report-parameter-controls/dynamic-html.component';
import { IdleTimeoutModalComponent } from '@app/components/modals/idle-timeout-modal/idle.timeout.modal';
import { LoginComponent } from '@app/components/login/login/login.component';
import { MfaDialogComponent } from '@app/components/login/mfa-dialog/mfa-dialog.component';
import { TacDialogComponent } from '@app/components/login/tac-dialog/tac-dialog.component';
import { ForgotPasswordComponent } from '@app/components/login/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from '@app/components/login/change-password/change-password.component';
import { NewApiModalComponent } from '@app/components/api-management/new-api-modal/new-api-modal.component';
import { NavigatePortalModalComponent } from '@app/components/portal/navigate-portal-modal/navigate-portal-modal.component';
import { BuildingModalComponent } from '@app/components/manage/modals/building-modal/building-modal.component';
import { EntityBulkUploadModalComponent } from '@app/components/manage/modals/entity-bulk-upload-modal/entity-bulk-upload-modal.component';
import { PanicTriggersLocationsModalComponent } from '@app/components/panic/panic-triggers-locations-modal/panic-triggers-locations-modal.component';
import { CallInNumberSetupModalComponent } from '@app/components/portal/call-in-number-setup-modal/call-in-number-setup-modal.component';
import { SetPasswordComponent } from '@app/components/profile/set-password/set-password.component';
import { PanicResolveModalComponent } from '@app/components/panic/panic-resolve-modal/panic-resolve-modal.component';
import { ManagePasswordComponent } from '@app/components/manage/user/manage-password/manage-password.component';
import { ManageEntityMembersModalComponent } from '@app/components/manage/modals/manage-entity-members-modal/manage-entity-members-modal.component';
import { UploadIncidentFileModalComponent } from '@app/components/agility-alerts/incident-details/upload-incident-file-modal/upload-incident-file-modal.component';
import { ManageRecipientsModalComponent } from '@app/components/agility-alerts/shared/manage-recipients-modal/manage-recipients-modal.component';
import { BulkUploadModalComponent } from '@app/components/manage/user/bulk-upload-modal/bulk-upload-modal.component';
import { LogoutComponent } from '@app/components/login/logout/logout.component';
import { SsoComponent } from '@app/components/login/sso/sso.component';
import { SsoResponseComponent } from '@app/components/login/sso/sso-response.component';
import { SsoERRORComponent } from '@app/components/login/sso/sso-error.component';
import { LoggerService } from '@app/providers/logger.service';
import { AppInsightsService } from '@app/providers/app-insights.service';
import { ConfirmationWithValidationModalComponent } from '@app/components/modals/confirmation-modal-with-validation/confirmation-with-validation-modal.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/modules/shared/shared.module';
import { CustomTranslateLoader } from '@app/providers/custom-translate-loader';
import { BcBulkUploadTechnologyModalComponent } from './components/business-continuity/modals/bc-bulk-upload-technology-modal/bc-bulk-upload-technology-modal.component';

import { AssignTemplatesModalComponent } from './components/modals/assign-templates-modal/assign-templates-modal.component';
import { SupportModalComponent } from '@app/components/modals/support-modal/support-modal.component';
import { BcSharedModule } from '@app/modules/business-continuity/bc-shared.module';

export function createTranslateLoader(storageSvc: LocalStorageService): CustomTranslateLoader {
	return new CustomTranslateLoader(storageSvc);
}

@NgModule({ declarations: [
	AddBookmarkModalComponent,
	AddNotificationSettingModalComponent,
	AppComponent,
	AssignTemplatesModalComponent,
	BcBulkUploadTechnologyModalComponent,
	BuildingModalComponent,
	BulkUploadModalComponent,
	CallInNumberSetupModalComponent,
	ChangePasswordComponent,
	ConfirmationCommentModalComponent,
	ConfirmationModalComponent,
	ConfirmationModalMultiOptionComponent,
	ConfirmationWithValidationModalComponent,
	DynamicHtmlComponent,
	EntityBulkUploadModalComponent,
	ForgotPasswordComponent,
	IdleTimeoutModalComponent,
	LoginComponent,
	LogoutComponent,
	ManageEntityMembersModalComponent,
	ManagePasswordComponent,
	ManageRecipientsModalComponent,
	MfaDialogComponent,
	NavigatePortalModalComponent,
	NewApiModalComponent,
	PanicResolveModalComponent,
	PanicTriggersLocationsModalComponent,
	SetPasswordComponent,
	SsoComponent,
	SsoERRORComponent,
	SsoResponseComponent,
	SupportModalComponent,
	TacDialogComponent,
	UploadIncidentFileModalComponent
],
schemas: [
	CUSTOM_ELEMENTS_SCHEMA
],
bootstrap: [AppComponent], imports: [SharedModule,
	BrowserModule,
	AppRoutingModule,
	TranslateModule.forRoot({
		loader: {
			provide: TranslateLoader,
			useFactory: createTranslateLoader,
			deps: [LocalStorageService]
		}
	}),
	BrowserAnimationsModule,
	BcSharedModule], providers: [
	ChildrenOutletContexts,
	{ provide: LoggerService, useClass: AppInsightsService },
	LocalStorageService,
	provideHttpClient(withInterceptorsFromDi())
] })
export class AppModule {
}
