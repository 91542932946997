{
    "cre": {
        "locations": "[[Locations]]",
        "location": "[[Location]]"
    },
    "common": {
        "locations": "[[Locations]]",
        "location": "[[Location]]",
        "defaultLocation": "[[Location]] Padrão"
    },
    "panicTriggers": {
        "addCustomizedByLocation": "Adicionar gatilho personalizado por [[locations]]"
    },
    "reports": {
        "labels": {
            "Tenant": "Inquilino",
            "Primary Location": "[[Location]] Principal",
            "Location": "[[Location]]"
        }
    },
    "manage": {
        "nav": {
            "lblLocations": "[[Locations]]"
        },
        "locations": {
            "btnLocationCreate": "Adicionar novo [[location]]",
            "btnSaveLocation": "Salvar [[location]]",
            "duplicateLocationName": "Já existe um [[location]] com o mesmo nome.",
            "errorLocationReferenced": "O [[location]] é referido como [[location]] principal pelos usuários",
            "errorRemoveLocationFailed": "Ocorreu um erro ao remover o [[location]].",
            "errorSaveLocationFailed": "Ocorreu um erro ao salvar o [[location]].",
            "lblAreYouSureRemoveLocation": "Tem certeza de que deseja excluir este [[location]]?",
            "lblBuildingAssignedToTenantsAreYouSureRemoveLocation": "O [[location]] está associado a inquilinos. Excluir o [[location]] irá removê-lo dos inquilinos. Tem certeza de que deseja remover este [[location]]?",
            "lblLocationActivity": "Atividade do [[location]]",
            "lblLocationInfo": "Informação do [[location]]",
            "lblLocationListTitle": "[[Locations]]",
            "lblLocationMembers": "Usuários associados a este [[location]]",
            "lblLocationName": "Nome do [[location]]",
            "lblNewLocation": "Novo [[location]]",
            "msgRemoveLocationSuccess": "O [[location]] foi removido com sucesso.",
            "msgSaveLocationSuccess": "O [[location]]{0} foi salvo com sucesso.",
            "msgUpdateGeofence": "o endereço do [[location]] mudou, gostaria de atualizar a cerca virtual deste [[location]]?",
            "deleteLocation": "Remover [[Location]]",
            "removeLocation": "Remover [[location]]"
        },
        "roles": {
            "lblEntityMgmtExplain": "Permitir que os usuários criem / modifiquem / excluam [[locations]], grupos e departamentos.",
            "lblIncludeCrisisDescription": "Exibir as informações de contato dos usuários com esta função na página Equipe de crise de cada [[location]]."
        },
        "bulkUploadConfig": {
            "lblDefaultLocation": "[[Location]] Padrão",
            "defaultLocationPlaceHolder": "Selecione o [[Location]] Padrão",
            "defaultLocationTooltip": "Esta construção será atribuída a novos usuários que não possuem um [[location]] especificado em seu arquivo de upload em massa."
        }
    }
}
