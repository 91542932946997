{
    "department": "[[Departments]]",
    "idleTimeout": {
        "extendSession": "Permaneça logado",
        "signOut": "Sair",
        "title": "Sua sessão está prestes a expirar devido à inatividade."
    },
    "overview": {
        "overview": "Visão geral",
        "noDataMessage": "O selecionado[[scope]] e[[team]] não contém dados."
    },
    "hierarchy": "Hierarquia",
    "administration": "Administração",
    "complianceCalendar": {
        "complianceCalendar": "Calendário de Conformidade",
        "previousWeek": "Semana anterior",
        "nextWeek": "Próxima semana",
        "today": "Hoje",
        "week": "Semana",
        "month": "Mês",
        "day": "Dia",
        "next": "Próximo",
        "previous": "Anterior",
        "allDayEvents": "Eventos de dia inteiro",
        "noAllDayEvent": "Sem eventos",
        "assignments": "Tarefas",
        "all": "Tudo",
        "mine": "Meu"
    },
    "scopes": "[[Scopes]]",
    "teams": "[[Teams]]",
    "teamMembers": "[[Team Members]]",
    "items": "[[Items]]",
    "issues": "[[Issues]]",
    "externalResources": "[[External Resources]]",
    "assets": "[[Assets]]",
    "pendingApprovals": "Aprovações pendentes",
    "plans": {
        "plans": "[[Plans]]",
        "download": "Baixar",
        "generate": "Gerar",
        "name": "Nome",
        "numberOfItems": "Número de [[Items]]",
        "lastStructureUpdate": "Última atualização da estrutura",
        "lastGeneration": "Última geração",
        "daysSinceGeneration": "Dias desde a geração",
        "lblDateTimeFromTo": "Executar relatório para o último"
    },
    "support": {
        "description": "<p>Entre em contato com o suporte ao cliente</p><p>Diga-nos como podemos ajudar.</p>",
        "email": "ENVIA-NOS UM EMAIL @",
        "phone": "LIGUE PARA NÓS @",
        "phoneOption": "OPÇÃO 1"
    },
    "reports": {
        "reports": "Relatórios",
        "required": "{{ fieldName }} é necessário",
        "all": "Tudo",
        "select": "Selecione",
        "portal": "Portal",
        "dashboard": {
            "dashboard": "Painel de controle",
            "admin": "Admin",
            "messaging": "Mensagens",
            "training": "Treinamento",
            "lblUserByStatus": "Usuário por status",
            "lblUserLoginActivity": "Atividade de login do usuário",
            "lblMessagesSent": "Mensagens Enviadas",
            "lblRecentMessages": "Mensagens Recentes",
            "lblCourseCompletion": "Conclusões do curso",
            "lblMostCompletedCourses": "Cursos Mais Concluídos",
            "lblTotal": "Total",
            "lblLast30Days": "(últimos 30 dias)",
            "lblLast3Months": "(últimos 3 meses)",
            "lblLast3Messages": "(últimas 3 mensagens)",
            "lblLast6Months": "(últimos 6 meses)",
            "lblTop5courses": "(5 cursos principais)",
            "lblTotalResponses": "Respostas totais",
            "lblTotalAudience": "Público Total"
        },
        "errors": {
            "renderingReport": "[[Reports]] de processamento de erros",
            "loadingReport": "Erro ao carregar parâmetros de [[reports]]"
        },
        "labels": {
            "Role": "Função",
            "Department": "[[Departments]]",
            "Primary Location": "Localização primária",
            "Location": "Localização",
            "Groups": "Grupos",
            "Group": "Grupo",
            "Status": "Status",
            "Select Format": "Selecione o formato",
            "Response Required": "Resposta exigida",
            "Category": "Categoria",
            "Course": "Curso",
            "Include": "Incluir",
            "Completed Date": "Data de Conclusão",
            "Assignee": "Cessionário",
            "Compliance Category": "Categoria de Conformidade",
            "External Resource Type": "[[External Resource]] Tipo",
            "External Resource": "[[External Resource]]",
            "Asset": "[[Asset]]",
            "Item": "[[Item]]",
            "Plan": "[[Plan]]",
            "Assignment Type": "Tipo de tarefa",
            "Team": "[[Team]]",
            "Scope": "[[Scope]]",
            "Include Sub Teams": "Incluir Sub [[Teams]]",
            "Include Resolved Issues": "Incluir Resolvido [[Issues]]",
            "Include All Expired": "Incluir todos expirados",
            "Include Archived": "Incluir Arquivado",
            "Incident Types": "Tipos de Incidentes",
            "bcFunctionalArea": "[[Departments]]",
            "bcProcess": "[[processes]]",
            "bcDueDateFrom": "Data de Vencimento de",
            "bcDueDateTo": "Data de Vencimento até",
            "datetime_to": "Até a presente data",
            "datetime_from": "Da data",
            "To Date": "Até a presente data",
            "From Date": "Da data",
            "to Date": "Até a presente data",
            "from Date": "Da data",
            "Since Date": "Desde a data",
            "User": "Usuário",
            "only display if days past due greater than (zero displays all)": "exibir apenas se os dias vencidos forem maiores que (zero exibe todos)",
            "Only display if days past due greater than (zero displays all)": "Exibir apenas se os dias vencidos forem maiores que (zero exibe todos)",
            "Only display if days pasrt due greater than (zero displays all)": "Exibir apenas se os dias vencidos forem maiores que (zero exibe todos)",
            "Sent From Date": "Enviado da data",
            "Sent To Date": "Enviado até a data",
            "bcAssignee": "Cessionário",
            "bcProgressStatus": "Status de progresso",
            "bcStrategyType": "Tipos de estratégia",
            "bcImpactTimespanType": "RTO",
            "Admin": "Admin",
            "Deleted Users": "Usuários excluídos",
            "Registration Status": "Status de registro",
            "User Login Activity": "Atividade de login do usuário",
            "User Update History": "Histórico de atualização do usuário",
            "Users": "Comercial",
            "Incidents": "Incidentes",
            "Message User Latest Responses": "Respostas mais recentes do usuário da mensagem",
            "Message User Responses By Contact Method": "Respostas do usuário da mensagem por método de contato",
            "Training": "Treinamento",
            "Certification Status": "Status de certificação",
            "Course Completion": "Conclusão do Curso",
            "Course Status by User": "Status do curso por usuário",
            "Learner Report": "[[Reports]] de Aluno",
            "External Resources By Type": "Recursos externos por tipo",
            "External Resources List": "Lista de recursos externos",
            "External Resource ": "Recurso Externo",
            "IQ Resource Changes": "Alterações de recursos de QI",
            "iQ Asset Changes": "Alterações de ativos iQ",
            "iQ External Resource Changes": "Alterações de recursos externos iQ",
            "iQ Item Changes": "Alterações do item iQ",
            "iQ Plan Changes": "Alterações no [[plan]] iQ",
            "Issues": "[[Issues]]",
            "Issues by Compliance": "[[Issues]] por Conformidade",
            "Issues by Priority": "[[Issues]] por prioridade",
            "Issues by Team": "[[Issues]] por equipe",
            "Items": "Itens",
            "Assignment Count By Team Summary": "Contagem de atribuição por resumo de equipe",
            "Assignments by Compliance Category Detail": "Atribuições por Detalhe da Categoria de Conformidade",
            "Assignments By Physical Location": "Atribuições por localização física",
            "Assignments by Team Detail": "Atribuições por detalhe da equipe",
            "Assignments by Team Summary": "Resumo de Tarefas por Equipe",
            "Assignments By Type": "Atribuições por tipo",
            "Assignments Emergency Distributions": "Distribuições de emergência de atribuições",
            "Igor Demo": "Igor Demo",
            "Management": "Gestão",
            "Assignments Readiness Chart and Summary": "Resumo e gráfico de prontidão para atribuições",
            "Team Member Readiness Chart": "Quadro de preparação dos membros da equipe",
            "Verification History": "Histórico de Verificação",
            "Plans": "[[Plans]]",
            "Plan Content Cross Reference": "Plan Content Cross Reference",
            "Plan Recovery Structure By Team": "[[Plans]] de estrutura de recuperação por equipe",
            "Plan Summary By Team": "Resumo do [[plan]] por equipe",
            "Processes Status": "Status de [[processes]]",
            "Teams": "Equipes",
            "Section Mission Statements": "Declarações de missão da seção",
            "Team Mission Statements": "Declarações de missão da equipe",
            "Team Objectives Summary": "Resumo dos objetivos da equipe",
            "Team Summary Report": "[[Reports]] de resumo da equipe",
            "User Assignments List": "Lista de atribuições do usuário",
            "User Assignments List By Team": "Lista de atribuições de usuário por equipe",
            "User Assignments Summary": "Resumo das atribuições do usuário",
            "User Detail by Name": "Detalhes do usuário por nome",
            "User Detail by Team": "Detalhes do usuário por equipe",
            "User Notifications Detail": "Detalhe de notificações do usuário",
            "User Notifications Summary": "Resumo de notificações do usuário",
            "Users Not Assigned to Teams": "Usuários não atribuídos a equipes",
            "Business Continuity Planner Reports": "[[Reports]] do [[Planner]]",
            "Assignment Report": "[[Reports]] de Atribuição",
            "Assignments by Person Report": "[[Reports]] de Tarefas por Pessoa",
            "BIA Severity Levels": "Níveis de gravidade [[BIA]]",
            "Department Status": "Status do [[Departments]]",
            "Equipment Report": "[[Reports]] de [[Equipment]]",
            "Strategy And RTO Employee Report": "[[Reports]] de Estratégia e Funcionário RTO",
            "Strategy And RTO Report": "[[Reports]] de estratégia e RTO",
            "Strategy By RTO Report": "[[Reports]] de Estratégia por RTO",
            "Technology Report": "[[Reports]] de [[Technology]]",
            "Technology and Application Report by RTO": "[[Reports]] de [[Technology]] e Aplicação da RTO",
            "Vendor Report": "[[Reports]] de Fornecedor",
            "generic": "Genérico",
            "banking": "Bancário",
            "legal": "Jurídico",
            "government": "Governamental",
            "Missed RTOs": "RTOs perdidos",
            "Progress over time": "Progressos ao longo do tempo",
            "Progress over time by RTO": "Progressos ao longo do tempo por RTO",
            "Incident Communication": "Comunicação de Incidentes",
            "Audit": "Auditoria",
            "Data Accessed": "Dados Acedidos",
            "Unauthorized Access": "Login sem sucesso",
            "Application Technology Dependency Report": "[[Application]] [[Technology]] Dependência [[Report]]",
            "Gap Report": "[[Report]] de lacunas",
            "Initiative Report": "Iniciativas [[Report]]",
            "Threats Report": "Ameaças [[Report]]",
            "Incident Manager": "[[Incident Manager]]",
            "options": "Opções",
            "bcReportingTimeInterval": "Intervalo de tempo do relatório BC",
            "Assessment Report": "[[Report]] avaliação",
            "Assessment Report By Status": "[[Report]] de avaliação por estado",
            "select_assessmentTargetType": "Tipo de destino",
            "select_threatType_Id": "Tipo de ameaça",
            "search_threatName_toId": "Nome da ameaça",
            "Assessment Status": "Status da avaliação",
            "Resource Type": "Tipo de recurso",
            "incidentName": "Nome do incidente",
            "escalated": "Dimensionamento",
            "owner": "Proprietário",
            "sort_by": "Organizar por",
            "Target Type": "Tipo de destino",
            "Target Name": "Nome do destino",
            "select_riskInitiativeStatus": "Estado",
            "search_riskInitiativeName_toId": "Nome",
            "Department Schedule": "Horário de [[department]]",
            "Issue Action Items": "[[issue]] Itens de ação",
            "technology": "[[Technology]]",
            "Technologies Tiers": "[[Technologies]] Níveis",
            "Technology to Technology Tier Gap Report": "[[Technology]] para [[Technology]] Lacuna de Nível",
            "Technology Vendor Dependency Report": "[[Technology]] Dependência do Fornecedor"
        },
        "validValues": {
            "Waiting For Approval": "Esperando aprovação",
            "Closed": "Fechado",
            "In Progress": "Em andamento",
            "Deleted": "Excluído",
            "Archived": "Arquivado",
            "Open": "Abrir",
            "All": "Todos",
            "User": "Do utilizador",
            "User Email": "E-mail do usuário",
            "User Address": "Endereço do usuário",
            "User Phone": "Telefone do usuário",
            "User Profile": "Perfil de usuário",
            "User Role": "Papel do usuário",
            "Location": "Localização",
            "Location Address": "Endereço de localização",
            "Location Phone": "Telefone de localização",
            "Department": "[[Department]]",
            "Group": "Grupo",
            "Role": "Papel",
            "Active": "Ativo",
            "Pending": "Pendente",
            "Locked": "Bloqueado",
            "Inactive": "Inativo",
            "Resolved": "Resolvido",
            "Completed": "Concluído",
            "Complete": "Completo",
            "Today": "Hoje",
            "Last 7 Days": "Últimos 7 dias",
            "Last 30 Days": "Últimos 30 dias",
            "Last 60 Days": "Últimos 60 dias",
            "Last 90 Days": "Últimos 90 dias",
            "Last 365 Days": "Últimos 365 dias",
            "Over 365 Days": "Mais de 365 dias",
            "Not Taken": "Não pego",
            "Incomplete": "Incompleto",
            "PDF": "PDF",
            "CSV (comma delimited)": "CSV (delimitado por vírgula)",
            "Yes": "Sim",
            "No": "Não",
            "Title": "Título",
            "Status": "Status",
            "Owner": "Proprietário",
            "Departments": "[[Department]]",
            "Processes": "[[Processes]]",
            "Vendors": "Fornecedores",
            "Vendor Addresses": "Endereços de Fornecedores",
            "Vendor Contacts": "Contatos do Fornecedor",
            "Technology": "[[Technology]]",
            "Equipment": "[[Equipment]]"
        },
        "contentCategories": {
            "Terrorism": "Terrorismo",
            "Health": "Saúde",
            "Other": "Outro",
            "Cyber Threats": "Ameaças cibernéticas",
            "Natural Disasters": "Desastres naturais",
            "Test Category": "Categoria de teste",
            "Additional Resources Parent Category": "Categoria pai de recursos adicionais",
            "First Aid": "Primeiro socorro",
            "Workplace Threats": "Ameaças no local de trabalho"
        },
        "courses": {
            "Earthquake": "Terremoto",
            "Medical Emergency: Non-Traumatic ": "Emergência Médica: Não Traumática ",
            "Chemical Spill ": "Derramamento Químico ",
            "Explosions ": "Explosões ",
            "Guess WHo": "Adivinha quem",
            "Blackout ": "Queda de energia ",
            "Tornado ": "Tornado ",
            "Medical Emergency: Traumatic ": "Emergência Médica: Traumática ",
            "Workplace Violence ": "Violência no Trabalho ",
            "Fire Evacuation": "Evacuação de Incêndio",
            "Information Security": "Segurança da Informação",
            "Anthrax": "Antraz",
            "Tornado": "Tornado",
            "Large Event ": "Grande Evento ",
            "Fire Evacuation ": "Evacuação de Incêndio ",
            "Dirty Bomb ": "Bomba Suja ",
            "Explosions": "Explosões",
            "Tenant Warden": "Diretor de Inquilino",
            "Certification 1: Introduction ": "Certificação 1: Introdução ",
            "Dirty Bomb": "Bomba Suja",
            "Pandemic Flu ": "Gripe pandémica ",
            "Office Creeper ": "Trepadeira de escritório ",
            "Anthrax ": "Antraz ",
            "Active Shooter 2013": "Atirador Ativo 2013",
            "Winter Storm": "Tempestade de inverno",
            "Hurricanes ": "Furacões ",
            "Office Creeper": "Trepadeira de escritório",
            "Bomb Threat": "Ameaça de bomba",
            "Certification 3: Taking Action ": "Certificação 3: Agindo ",
            "Certification 2: Your Preparedness Program ": "Certificação 2: Seu Programa de Preparação ",
            "Hurricanes": "Furacões",
            "Blackout": "Queda de energia",
            "Active Shooter": "Atirador Ativo",
            "Flood ": "Enchente ",
            "Winter Storm ": "Tempestade de inverno ",
            "Mars Module 2.1": "Módulo Marte 2.1",
            "Bomb Threat ": "Ameaça de bomba ",
            "Mars Module 1.1": "Módulo Marte 1.1",
            "Certification": "Certificação",
            "Earthquake ": "Terremoto ",
            "Guess Who": "Adivinha quem"
        },
        "incidentTypes": {
            "Not Set": "Não configurado",
            "Exercise": "Exercício",
            "Panic": "Pânico"
        }
    },
    "storageLocation": "Local de armazenamento",
    "export": "Exportar",
    "bulkResend": "Reenviar em massa",
    "established": "",
    "notestablished": "Não estabelecido",
    "daysPastDue": "Dias desde o vencimento",
    "expired": "Expirado",
    "scope": {
        "scopeName": "[[Scope]] Nome",
        "coordinatorName": "Nome do Coordenador",
        "mission": "Missão",
        "assumptions": "Premissas",
        "planHeader": "[[Plan]] Cabeçalho",
        "confirmSaveHeader": "Salvar",
        "confirmSaveBody": "Tem certeza que deseja salvar este[[scope]]?",
        "confirmRemoveHeader": "Remover [[Scope]]",
        "confirmRemoveBody": "Tem certeza que deseja remover este [[scope]]?",
        "scopesFilterDesc": "Filtre os resultados por: [[Scope]] Nome,[[Plan]] Cabeçalho ou nome do coordenador",
        "newScope": "Novo [[Scope]]",
        "viewTeams": "Ver [[Teams]]",
        "actions": "Ações",
        "cannotRemoveError": "Não pode remover[[scope]] com ativo [[teams]]"
    },
    "teamMember": {
        "teamMemberFilterDesc": "Filtrar resultados",
        "accountableForTeam": "Esta pessoa é responsável pelo[[team]].",
        "cannotBeAccountableForTeam": "Esta pessoa só tem acesso a tarefas e não pode ser responsável pelo [[team]]",
        "cannotBeApproverForTeam": "Esta pessoa só tem acesso às tarefas e não pode aprovar para o [[team]]",
        "confirmMakeAccountableHeader": "[[Team]] Prestação de contas",
        "confirmMakeAccountable": "{{oldname}} é atualmente responsável por isso[[team]] . Tem certeza que deseja fazer{{newname}} responsável por isso[[team]] ?",
        "confirmMakeAccountableButton": "OK",
        "complianceSchedule": "Cronograma de Conformidade",
        "teamMember": "[[Team Member]]",
        "assignment": "Tarefa",
        "isAccountable": "Responsável por [[Team]]",
        "comments": "Comentários",
        "confirmSaveHeader": "Salvar [[Team Member]]",
        "confirmRemoveHeader": "Remover [[Team Member]]",
        "assignmentsTitle": "Atribuição de membros",
        "recoveryPlanCount": "Cópias do[[Plans]] emitido para este membro",
        "recoveryPlanIssueDate": "[[Plan]] Data de emissão",
        "issuedRecoveryPlanDateRequired": "[[Plan]] A data de emissão é necessária quando o usuário tiver recebido pelo menos 1 cópia do[[plan]] .",
        "issuedTeamPlanDateRequired": "[[Team]] [[Plan]] A data de emissão é necessária quando o usuário tiver recebido pelo menos 1 cópia do[[plan]] .",
        "teamPlanCount": "Cópias do[[Team]][[Plans]] emitido para este membro",
        "teamPlanIssueDate": "[[Team]] [[Plan]] Data de emissão",
        "plansIssuedTitle": "[[Plans]] Emitido",
        "teamMemberDoesNotExist": "[[Team Member]] não existe.",
        "member": "Membro",
        "verify": "Verificar",
        "establish": "Estabelecer",
        "verificationSuccess": "[[Team Member]] verificação bem-sucedida.",
        "unableToVerify": "Incapaz de verificar[[Team Member]], por favor, tente novamente mais tarde.",
        "name": "Nome",
        "type": "Tipo",
        "jobTitle": "Titulo do trabalho",
        "mailCode": "Código Postal",
        "verifiedDate": "Data de Verificação",
        "verifiedStatus": "Status",
        "teamMembers": "[[Team Members]]",
        "newTeamMember": "Novo [[Team Member]]",
        "removeError": "Falha ao remover [[team member]]",
        "removeAccountableTeamMemberError": "Não pode remover[[team member]] quem é responsável pelo [[team]]",
        "removeTeamMemberWithAssignmentError": "Não pode remover[[team member]] com atribuições",
        "members": "Membros",
        "lastVerified": "Última verificação",
        "nextVerification": "Próxima Verificação",
        "daysPastDue": "Dias desde o vencimento",
        "dueDate": "Data de Vencimento",
        "isApprover": "Aprovador para [[Team]]",
        "reassignTasks": {
            "reassignTasks": "Reatribuir Tarefas",
            "modalHeader": "Reatribuir tarefas de membros da equipe",
            "reassign": "Reatribuir",
            "assignToTeamMember": "Atribuir a um membro da equipe",
            "applyToAllTeams": "Aplicar a todas as equipes",
            "successReassigningTasksMsg": "Sucesso na reatribuição e {{ count }} tarefas do membro da equipe",
            "errorReassigningTasksMsg": "Erro ao reatribuir as tarefas do membro da equipe",
            "errorReassigningTasksToFromUnkownUserMsg": "Erro ao reatribuir as tarefas do membro da equipe para ou de um usuário desconhecido",
            "errorReassigningTasksToSameUnderlyingUserMsg": "Erro ao reatribuir as tarefas do membro da equipe ao mesmo usuário",
            "applyToAllTeamsTooltip": "Quando aplicado a todas as equipes, todas as tarefas iQ pertencentes a este membro da equipe, em todas as equipes, são atribuídas ao usuário designado. Se o usuário designado ainda não for membro das outras equipes, ele será adicionado como membro a essas equipes."
        },
        "export": {
            "allTeamMembers": "Todos os membros da equipe",
            "displayedTeamMembers": "Membros da equipe exibidos",
            "header": "Exportar membros da equipe",
            "body": "Você deseja exportar apenas os {{ x }} usuários exibidos ou todos os membros da equipe?",
            "successMsg": "Exportação gerada com sucesso",
            "errorMsg": "Erro ao gerar exportação",
            "fileName": "BulkTeamMemberUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Upload em massa de membros da equipe",
            "msgBulkUploadInstructions": "Você deseja adicionar ou modificar vários membros da equipe? Escolha um arquivo formatado corretamente em seu computador para iniciar o [[process]].",
            "msgDropFile": "Solte o arquivo aqui ou clique para selecionar um arquivo",
            "lblSelectedFile": "Arquivo selecionado para upload",
            "lblNoFileSelected": "Nenhum arquivo especificado.",
            "lblNeedStartingPoint": "Precisa de um ponto de partida",
            "lblAddMultipleHeader": "Adicionando vários novos membros da equipe",
            "lblAddMultipleDetail": "Baixe esta planilha contendo apenas as informações do cabeçalho e preencha as informações do novo membro da equipe, um usuário por linha, antes de salvá-la no formato .CSV em seu computador.",
            "btnBlankFile": "Arquivo de membro da equipe em branco",
            "lblEditMultipleHeader": "Editar ou excluir vários membros da equipe",
            "lblEditMultipleDetail": "Baixe esta planilha contendo as informações do membro da equipe atual e modifique conforme necessário antes de salvá-la no formato .CSV em seu computador.",
            "btnCurrent": "Membros atuais da equipe",
            "lblEditMultipleNotes": "NOTA: para excluir usuários, altere seu status para excluir. Removê-los da planilha não os excluirá do sistema. O upload em massa pode ser concluído mesmo se você não preencher todos os campos de um usuário. Para excluir usuários, altere seu status para Excluir. Removê-los da planilha não os excluirá do sistema. O upload em massa pode ser concluído mesmo se você não preencher todos os campos de um usuário.",
            "lblUpload": "Enviar",
            "btnCancel": "Cancelar",
            "errorUserExportFailed": "Não foi possível exportar um arquivo de membro da equipe.",
            "msgUserExportSuccessful": "Exportação de membro da equipe concluída com sucesso.",
            "msgTestBulkUploadFile": "Este arquivo só será validado porque o nome do arquivo contém a palavra \"teste\"; as atualizações não serão processadas.",
            "msgUploadSuccess": "Upload em massa bem-sucedido.",
            "msgValidatingFile": "Verificando arquivo ...",
            "errorInvalidBulkFileType": "O arquivo selecionado tem uma extensão de arquivo incorreta, ele deve ter uma extensão .CSV.",
            "errorExportFailed": "Não foi possível exportar um arquivo de membro da equipe.",
            "msgExportSuccessful": "Exportação concluída com sucesso."
        },
        "accountable": "Responsável"
    },
    "delete": "Excluir",
    "save": "Salvar",
    "undo": "Desfazer",
    "replace": "Substituir",
    "reset": "Redefinir",
    "choose": "Escolher",
    "errorMessages": {
        "saveError": "Falha ao salvar {{name}}",
        "removeError": "Falha ao remover {{name}}",
        "scopeNameExist": "[[Scope]] com o mesmo nome já existe",
        "msgValidateEmailFormat": "Use um formato de e-mail válido",
        "msgValidateMaxLength": "{{0}} tem um comprimento máximo de{{1}} caractere(s)",
        "msgValidateMaxValue": "O valor de{{0}} não pode ser maior que {{1}}",
        "msgValidateMinLength": "{{0}} tem um comprimento mínimo exigido de{{1}} caractere(s)",
        "msgValidateMinValue": "O valor de{{0}} não pode ser menor que {{1}}",
        "msgValidateRequired": "Por favor, preencha todos os campos obrigatórios",
        "msgValidateRequiredBefore": "O valor de{{0}} não pode ser antes {{1}}",
        "externalResourceContactPrimary": "É necessário pelo menos um (1) contato principal",
        "loadingError": "Falha ao carregar {{name}}",
        "notFoundError": "{{name}} Não encontrado",
        "verifyError": "Falha ao verificar {{ name }}",
        "isInvalidPhoneNumber": "Por favor insira um número de telefone válido",
        "formIsDirty": "Salve suas alterações primeiro",
        "dateRangeMax": "O intervalo de datas excede o intervalo máximo",
        "fromDateIsAfterToDate": "A data de início é posterior à data de término",
        "generatePlan": "Falha ao gerar [[plan]]",
        "noBusinessContinuityPlan": "Não há planos para visualização",
        "planItemsMinimumRequirement": "Pelo menos um (1) Documento ou Relatório deve estar nesta[[Plan]] Estrutura",
        "planItemsOnlyDocumentUsedAsCoverPage": "Pelo menos um (1) documento que não é usado como página de rosto ou um (1) relatório deve estar na[[Plan]] Estrutura.",
        "checkInError": "Falha ao fazer check-in {{name}}",
        "declineError": "Falha ao recusar {{name}}",
        "approveError": "Falha ao aprovar {{name}}",
        "itemInUsedByPlan": "Falha ao remover este[[item]] .[[Item]] está em uso por um[[Plan]] .",
        "errorMsgInvalidFirstTeamMember": "O usuário com acesso apenas às atribuições não pode ser o primeiro[[team member]] de um[[team]] .",
        "duplicateFilename": "Nome de arquivo duplicado",
        "archiveError": "{{name}} NÃO arquivado",
        "unArchiveError": "{{name}} NÃO restaurado",
        "deleteAttachmentError": "Falha ao remover anexo {{ docName }}",
        "genericError": "Ocorreu um erro",
        "errorRemovingMessageTemplate": "Erro ao remover mensagem salva",
        "errorUploadFileMessage": "Falha ao carregar anexo {{ docName }}",
        "invalidDate": "Data inválida",
        "errorAssigningResourceToSelf": "Erro ao atribuir a si mesmo",
        "errorArchiveResource": "Erro ao arquivar",
        "msgInvalidPersonalPhoneNumber": "Formato de número de telefone inválido para telefone pessoal (receberá todos os SMS e chamadas de voz). Para números fora dos EUA, inclua o código do país. Por exemplo, +44",
        "msgInvalidAdditionalPersonalPhoneNumber": "Formato de número de telefone inválido para telefone pessoal adicional (receberá todos os SMS e chamadas de voz). Para números fora dos EUA, inclua o código do país. Por exemplo, +44",
        "msgInvalidMobilePhoneNumber": "Formato de número de telefone inválido para celular (receberá todos os SMS e chamadas de voz). Para números fora dos EUA, inclua o código do país. Por exemplo, +44",
        "msgInvalidOfficePhoneNumber": "Formato de número de telefone inválido para telefone comercial. Para números fora dos EUA, inclua o código do país. Por exemplo, +44",
        "maliciousFileType": {
            "header": "Aviso",
            "body": "Arquivo malicioso em potencial detectado. Tem certeza de que deseja enviar este arquivo?"
        },
        "noRecordsFound": "Sem registros encontrados",
        "deleteEntity": {
            "panicTrigger": "{{entityName}} em uso por Configuração de Pânico",
            "uii": "{{entityName}} em uso por Incidente Iniciado pelo Usuário",
            "alertSubscription": "{{entityName}} em uso por Assinatura de [[Alert]]",
            "savedMessage": "{{entityName}} em uso por Mensagem Salva",
            "documentPermission": "{{entityName}} em uso por Permissão de Documento",
            "bulkUploadDefault": "{{entityName}} é o padrão para Upload em Massa",
            "incidentCommunicationGroup": "{{entityName}} é usado na configuração de comunicação de incidentes"
        },
        "noElementsFound": "Nenhum elemento encontrado",
        "errorUpdatingPageStatus": "Erro ao atualizar o status da página"
    },
    "successMessages": {
        "saveSuccess": "{{name}} salvo",
        "removeSuccess": "{{name}} removido",
        "verifySuccess": "{{name}} verificado",
        "checkInSuccess": "{{name}} check-in realizado",
        "declineSuccess": "{{name}} recusado",
        "approveSuccess": "{{name}} aprovado",
        "archiveSuccess": "{{name}} arquivado",
        "unArchiveSuccess": "{{name}} restaurado",
        "deleteAttachmentSuccess": "Anexo{{ docName }} removido.",
        "successUploadFileMessage": "Anexo{{ docName }} é carregado"
    },
    "issue": {
        "issue": "[[Issue]]",
        "name": "Nome",
        "description": "Descrição",
        "priority": "Prioridade",
        "dateOpened": "Data de Abertura",
        "confirmRemoveHeader": "Remover [[Issue]]",
        "confirmResolveHeader": "Resolver [[Issue]]",
        "confirmResolve": "Tem certeza que deseja resolver isso[[issue]]?",
        "resolveError": "Incapaz de resolver[[issue]], por favor, tente novamente mais tarde.",
        "resolvedBy": "por",
        "hasBeenResolved": "este[[issue]] foi resolvido.",
        "assignee": "Cessionário",
        "resolutionDetails": "Detalhe de resolução",
        "resolveSuccess": "[[Issue]] foi resolvido.",
        "targetResolutionDate": "Data Prevista de Resolução",
        "targetResolutionPastDue": "Vencidos",
        "resolutionDate": "Data de Resolução",
        "comments": "Comentários",
        "initiatedBy": "Iniciado por",
        "resolve": "Resolver",
        "issuesFilterDesc": "Pesquisar...",
        "newIssue": "Novo [[Issue]]",
        "reopen": "Reabrir",
        "issueName": "[[Issue]] Nome",
        "assignedTo": "Atribuído a",
        "opened": "Aberto",
        "targetResolution": "Resolução Alvo",
        "daysPastDue": "Dias desde o vencimento",
        "status": "Status",
        "closed": "Resolvido",
        "open": "Abrir",
        "issues": "[[Issues]]",
        "notFoundError": "[[Issue]] Não encontrado",
        "pastDue": "Vencidos",
        "due": "Vencimento",
        "showResolvedIssues": "Mostrar resolvido [[Issues]]",
        "hideResolvedIssues": "Esconder Resolvido [[Issues]]",
        "issueWithSameNameError": "Um[[issue]] com o nome{{ issueName }} já existe.",
        "moveSuccess": "[[Issue]] movido",
        "moveError": "[[Issue]] não movido",
        "moveErrorDuplicateName": "[[Issue]] não movido porque um[[issue]] com o mesmo nome já existe na equipe de destino"
    },
    "item": {
        "item": "[[Item]]",
        "items": "[[Items]]",
        "scope": "[[Scope]]",
        "team": "[[Team]]",
        "name": "Nome",
        "description": "Descrição",
        "type": "Tipo",
        "assignedTo": "Atribuído a",
        "archived": "Arquivado",
        "lastVerified": "Última verificação",
        "nextVerification": "Próxima Verificação",
        "lastUpdated": "Ultima atualização",
        "lastPublished": "Última publicação",
        "verifiedStatus": "Status",
        "nextUpdate": "Próxima atualização",
        "daysPastDue": "Dias desde o vencimento",
        "priority": "Prioridade",
        "status": "Status",
        "itemType": "Tipo",
        "itemPriority": "Prioridade",
        "itemComplianceCat": "Categoria de Conformidade",
        "complianceSchedule": "Cronograma de Conformidade",
        "storageInformation": "Informação de Armazenamento",
        "associatedDocuments": "Documento Associado",
        "comments": "Comentários",
        "primaryStorageLocation": "Local de armazenamento primário",
        "secondaryStorageLocation": "Local de armazenamento secundário",
        "recoveryDestination": "Destino de Recuperação",
        "addStorageLocation": "Adicionar local de armazenamento",
        "editStorageLocation": "Editar local de armazenamento",
        "addComplianceCategory": "Adicionar categoria de conformidade",
        "editComplianceCategory": "Editar categoria de conformidade",
        "createComplianceCategory": "Criar nova categoria de conformidade",
        "appendComplianceCategory": "Anexar categoria de conformidade",
        "itemTypes": {
            "53e27db8-f119-4b73-9e84-a8b598fbd415": "Documento",
            "92c579b0-36cf-46d2-b006-77d2f962c0ce": "Armazenamento [[Item]]",
            "2b727081-d9b1-4f92-b8b4-4e275fe9a396": "Tarefa / Lembrete",
            "08b4edfc-1f95-425e-a24b-016ba5cad509": "Outros"
        },
        "newItem": "Novo [[Item]]",
        "verify": "Verificar",
        "establish": "Estabelecer",
        "itemsfilterPlaceholder": "Filtre os resultados por: Nome, tipo ou atribuído ao nome",
        "documentAvailableForPlan": "[[Plan]] Disponibilidade",
        "no": "Não",
        "currentTeamOnly": "Atual[[Team]] Somente",
        "allTeamsAndScopes": "Todos[[Teams]] E [[Scopes]]",
        "itemWithSameNameError": "A[[item]] com o nome{{ itemName }} já existe.",
        "storageWithSameNameError": "Um local de armazenamento com o nome{{ storageName }} já existe.",
        "confirmRemoveHeader": "Remover [[Item]]",
        "removeWithDocumentConfirm": "Remover este[[item]] também irá remover o documento associado a este[[item]] . Tem certeza que deseja remover este[[item]] ?",
        "dueDate": "Data de Vencimento",
        "itemWithoutDocumentWarning": "Este[[item]] não tem um documento e não pode ser verificado ou realizado o check-in",
        "thisTeamOnly": "Este[[Team]] Somente",
        "thisTeamAndSubTeams": "Este[[Team]] e Sub [[Teams]]",
        "allTeamsThisScope": "Todos[[Teams]] este [[Scope]]",
        "allTeamsAllScopes": "Todos[[Teams]] Todos [[Scopes]]",
        "moveSuccess": "[[Item]] movido",
        "moveError": "[[Item]] não movido",
        "moveErrorDuplicateName": "[[Item]] não movido porque um[[item]] com o mesmo nome já existe na equipe de destino",
        "export": {
            "allTeamMembers": "Todos os itens",
            "displayedTeamMembers": "Itens Exibidos",
            "header": "Exportar Itens",
            "body": "Você deseja exportar apenas os exibidos{{ x }} itens ou todos os membros da equipe?",
            "successMsg": "Exportação gerada com sucesso",
            "errorMsg": "Erro ao gerar exportação",
            "fileName": "BulkItemUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Itens de upload em massa",
            "msgBulkUploadInstructions": "Tem vários itens que você deseja adicionar ou modificar? Escolha um arquivo formatado corretamente em seu computador para iniciar o [[process]].",
            "msgDropFile": "Solte o arquivo aqui ou clique para selecionar um arquivo",
            "lblSelectedFile": "Arquivo selecionado para upload",
            "lblNoFileSelected": "Nenhum arquivo especificado.",
            "lblNeedStartingPoint": "Precisa de um ponto de partida",
            "lblAddMultipleHeader": "Adicionando vários novos itens",
            "lblAddMultipleDetail": "Baixe esta planilha contendo apenas as informações do cabeçalho e preencha as informações do seu novo item, um usuário por linha, antes de salvá-lo no formato .CSV no seu computador.",
            "btnBlankFile": "Arquivo de item em branco",
            "lblEditMultipleHeader": "Editar ou excluir vários itens",
            "lblEditMultipleDetail": "Baixe esta planilha contendo as informações do item atual e modifique conforme necessário antes de salvá-la no formato .CSV em seu computador.",
            "btnCurrent": "Itens Atuais",
            "lblEditMultipleNotes": "NOTA: Para excluir itens, altere seu status para Excluir. Removê-los da planilha não os excluirá do sistema. O upload em massa pode ser concluído mesmo se você não preencher todos os campos de um item. Para ver quais campos são opcionais, consulte o guia do usuário ou a área de ajuda no canto superior direito desta janela.",
            "lblUpload": "Enviar",
            "btnCancel": "Cancelar",
            "errorUserExportFailed": "Não foi possível exportar um arquivo de item.",
            "msgUserExportSuccessful": "Exportação de item concluída com sucesso.",
            "msgTestBulkUploadFile": "Este arquivo só será validado porque o nome do arquivo contém a palavra \"teste\"; as atualizações não serão processadas.",
            "msgUploadSuccess": "Upload em massa bem-sucedido.",
            "msgValidatingFile": "Verificando arquivo ...",
            "errorInvalidBulkFileType": "O arquivo selecionado tem uma extensão de arquivo incorreta, ele deve ter uma extensão .CSV.",
            "errorExportFailed": "Não foi possível exportar um arquivo de item.",
            "msgExportSuccessful": "Exportação concluída com sucesso."
        }
    },
    "itemDocument": {
        "dropFile": "Solte o arquivo aqui",
        "selectFile": "Selecione o arquivo",
        "uploadingFile": "Carregando arquivo",
        "uploadDocument": "Upload Documento",
        "uploadDocuments": "Upload Documentos",
        "selectDocument": "Selecione o Documento",
        "uploadFile": "Escolha um arquivo do seu computador para fazer upload ou arraste e solte o arquivo na janela.",
        "confirmRemoveHeader": "Remover Documento",
        "removeConfirm": "Tem certeza de que deseja remover este documento?",
        "homeFolderName": "Início",
        "saveSuccess": "Documento{{name}} salvo",
        "saveFail": "Falha ao salvar o documento",
        "removeSuccess": "Documento removido",
        "removeFail": "Falha ao remover documento",
        "downloadDocument": "Baixar Documento",
        "documentVersions": "Versões de documentos",
        "removeDocument": "Remover Documento",
        "createdDate": "Data de criação",
        "createdBy": "Criado por",
        "compare": "Comparar",
        "documentComparisonTypeNotSupport": "Apenas documentos do Microsoft Word são suportados para comparação.",
        "documentComparisonFailed": "Falha ao comparar documentos",
        "verified": "Verificado",
        "compared": " (comparado)",
        "noAttachments": "Sem Anexos"
    },
    "confirmMessages": {
        "saveConfirm": "Tem certeza que deseja salvar este {{name}}?",
        "removeConfirm": "Tem certeza que deseja remover este{{name}}?"
    },
    "removeAttachmentConfirmation": {
        "header": "Remover anexo",
        "body": "Tem certeza que deseja remover o anexo{{ docName }}?",
        "btnText": "Remover"
    },
    "team": {
        "teamName": "[[Team]] Nome",
        "responsibilities": "Responsabilidades",
        "mission": "Missão",
        "assemblyTimeObjective": "Objetivo de tempo de montagem (OTM)",
        "recoveryTimeObjective": "Objetivo de tempo de recuperação (OTR)",
        "recoveryPointObjective": "Objetivo de ponto de recuperação (OPR)",
        "confirmRemoveHeader": "Remover [[Team]]",
        "confirmSaveHeader": "Salvar [[Team]]",
        "teamFilterDesc": "Filtre os resultados por[[team]] nome",
        "newTeam": "Novo [[Team]]",
        "teamCountMessage": "Mostrando{{ x }} do{{ y }} total",
        "confirmMoveMessage": "Tem certeza que deseja mover{{ childTeamName }} para{{ toTeamName }}?",
        "editTeams": "Editar [[Teams]]",
        "newTeamHeader": "Novo [[Team]]",
        "newTeamName": "Novo[[Team]] Nome",
        "addTeam": "Adicionar [[Team]]",
        "teamWithSameNameError": "Uma[[team]] com o nome{{ teamName }} já existe.",
        "cannotRemoveError": "Não é possível remover[[team]] com sub ativo[[teams]] ou [[team members]]",
        "copyTeam": "Cópia de [[Team]]",
        "copyTeamModal": {
            "header": "Cópia de[[Team]] opções",
            "selectDestination": "Selecione o destino[[scope]] ou [[team]]",
            "selectCopyOptions": "Opções",
            "copyTeamMembers": "Copiar tudo [[Team Members]]",
            "copyHierarchy": "Incluir Sub-[[teams]]",
            "deepCopy": "Cópia profunda",
            "copyItems": "Copiar tudo [[Items]]",
            "copyIssues": "Copiar tudo [[Issues]]",
            "copyAssets": "Copiar tudo [[Assets]]",
            "copyExternalResources": "Copiar tudo [[External Resources]]",
            "copyTeamSuccess": "Copiado com sucesso [[team]] {{teamName}}",
            "copyTeamError": "Falha ao copiar [[team]] {{teamName}}"
        },
        "comments": "Comentários",
        "comment": {
            "saveError": "Comentário não salvo",
            "deleteError": "Comentário não removido"
        }
    },
    "help": {
        "scope": {
            "scopeName": "O [[scope]] nome é o primeiro agrupamento de[[teams]] . Deve ser único e está impresso no cabeçalho de cada relatório. Pode ter até 50 caracteres.",
            "mission": "A declaração de missão explica o propósito do[[scope]] e é impresso no início do relatório de declaração de missão. Pode ter até 4.000 caracteres.",
            "assumptions": "As suposições são impressas no relatório de declaração de missão. Eles podem ter até 4.000 caracteres.",
            "coordinatorName": "O nome do coordenador é a pessoa responsável pelo [[planner]] de recuperação deste[[scope]] .  O nome é impresso na capa da frente de cada recuperação[[plan]]."
        },
        "team": {
            "teamName": "O nome da[[team]] deve ser único dentro de cada[[scope]] . Pode ter até 50 caracteres",
            "mission": "A declaração de missão explica o propósito do[[team]] . Também é exibido no relatório da missão da [[team]]. Pode ter até 4.000 caracteres.",
            "responsibilities": "Responsabilidades descrevem os objetivos primários da [[team]]. Está impresso no relatório da missão da [[team]]. Pode ter até 4.000 caracteres.",
            "planHeader": "O cabeçalho do [[plan]] é impresso na capa do [[plan]] de recuperação da [[team]]. Pode ter até 50 caracteres.",
            "assemblyTimeObjective": "O OTM é o tempo decorrido planejado entre uma notificação de evento e[[team]] montagem.[[Team]] montagem é definida como o momento em que o[[team]] está posicionada para cumprir sua missão de recuperação e responsabilidades para seus usuários internos e [[clients]] externos. É representado em horas e minutos.",
            "recoveryTimeObjective": "O OTR é o tempo decorrido planejado entre a notificação de um evento e o total[[team]] operação em um nível que apóia seus usuários internos e [[clients]] externos. É representado em horas e minutos.",
            "recoveryPointObjective": "O OPR é a quantidade máxima de tempo, antes de um evento, em que a perda de dados é aceitável. Dados e informações essenciais devem ser restaurados pelo menos até este ponto no tempo. É representado em horas e minutos. (Deixe em branco se todos os dados e informações devem ser restaurados.)",
            "copyTeamModal": {
                "copyHierarchy": "Copie apenas um[[team]] ou o[[team]] e todos os sub-[[teams]]",
                "deepCopy": "Cópia de[[Team Members]] e tipos de recursos selecionados ([[Assets]] ,[[Items]] ,[[Issues]] ,[[External Resources]] ) para copiado [[Teams]]",
                "copyTeamMembers": "[[Team Members]] deve ser copiado para recursos copiados ([[Assets]] ,[[Items]] ,[[Issues]] ,[[External Resources]] )",
                "copyItems": "Copiar tudo[[Items]] para copiado [[Teams]]",
                "copyIssues": "Copiar tudo[[Issues]] para copiado [[Teams]]",
                "copyAssets": "Copiar tudo[[Assets]] para copiado [[Teams]]",
                "copyExternalResources": "Copiar tudo[[External Resources]] para copiado[[Teams]] .[[External Resources]] deve ser copiado para poder copiar[[Assets]] como um[[Asset]] pode contar com o[[External Resource]] ."
            }
        },
        "teamMember": {
            "member": "Membro representa a conta de usuário pertencente a este[[team member]] .",
            "assignment": "A atribuição representa isso[[team member]] responsabilidades de no[[team]] .",
            "planCount": "O número de cópias do[[plan]] que foram emitidos para este[[team member]] .",
            "teamPlanCount": "O número de cópias do[[team]] de[[plan]] que foram emitidos para este[[team member]] .",
            "planIssueDate": "A data em que as cópias do[[plan]] foram emitidos para este[[team member]] .",
            "teamPlanIssueDate": "A data em que as cópias do[[team]][[plan]] foram emitidos para este[[team member]].",
            "isResponsible": "Esta pessoa é responsável pelo[[team]].",
            "expired": "Expirado, precisa de verificação.",
            "verified": "Verificado"
        },
        "issue": {
            "descriptionTooltip": "Descrição de[[issue]] pode ter até 512 caracteres.",
            "assigneeTooltip": "O cessionário é responsável por garantir que o[[issue]] fica resolvido.",
            "targetResolutionDateTooltip": "A data de resolução prevista é a data em que[[issue]] precisa ser resolvido por.",
            "resolutionDateTooltip": "A data de resolução é a data em que[[issue]] foi marcado como resolvido.",
            "priorityTooltip": "Prioridade é o nível neste[[issue]] afeta adversamente o esforço de recuperação.",
            "nameTooltip": "O nome identifica o[[issue]] e deve ser único por[[team]] .",
            "dateOpened": "A data de abertura é quando este[[issue]] foi gravado.",
            "initiatedBy": "O usuário que iniciou o[[issue]] ."
        },
        "externalResource": {
            "externalResourceName": "Nome de uma empresa, localidade ou pessoa que está fora da organização. Pode ter 50 caracteres de comprimento.",
            "typeTooltip": "Tipo de empresa externa, local ou indivíduo fora da organização.",
            "isCritical": "Este [[external resource]] é crítico para o [[processes]] de recuperação?",
            "isRecoveryLocation": "Este [[external resource]] é um local de recuperação?",
            "addExternalResourceType": "Adicionar um novo[[external resource]] tipo",
            "editExternalResourceType": "Editar[[external resource]] tipo",
            "compareWithVerified": "Compare a última versão salva com a versão verificada"
        },
        "item": {
            "primaryStorageLocationTooltip": "O local de armazenamento principal é o local pretendido para armazenar a recuperação[[item]] .",
            "secondaryStorageLocationTooltip": "O local de armazenamento secundário é um local adicional para armazenar a recuperação[[item]] .",
            "recoveryDestinationTooltip": "O destino de recuperação é o local para realocar o armazenamento[[item]] durante uma emergência.",
            "documentAvailableForPlanTooltip": "Denota o nível deste documento[[item]] está disponível para ser usado em[[plans]] para outro[[teams]] e / ou [[scopes]]",
            "nameTooltip": "O nome identifica a recuperação[[item]] e deve ser único por[[team]] . Pode ter até 50 caracteres.",
            "descriptionTooltip": "Descrição da recuperação[[item]] pode ter até 512 caracteres.",
            "itemTypeTooltip": "Tipo de recuperação[[item]] .",
            "itemPriorityTooltip": "Prioridade da recuperação[[item]] em caso de emergência.",
            "addStorageLocationInstructions": "Identifique e descreva o novo local de armazenamento.",
            "editStorageLocationInstructions": "Reidentifique ou descreva o local de armazenamento existente.",
            "addComplianceCategoryInstructions": "Identifique e descreva a nova categoria de conformidade.",
            "editComplianceCategoryInstructions": "Reidentifique ou descreva a categoria de conformidade existente.",
            "compareWithVerified": "Compare a última versão salva com a versão verificada"
        },
        "asset": {
            "nameTooltip": "Nome de[[asset]] necessários no esforço de recuperação. Pode ter até 50 caracteres.",
            "assetLocationSiteTooltip": "Lugar onde[[asset]] deve ser localizado durante o esforço de recuperação.",
            "compareWithVerified": "Compare a última versão salva com a versão verificada"
        },
        "assetAllocation": {
            "allocationCountTooltip": "Contagem de ativos necessários no local de recuperação dentro de um período de tempo associado.",
            "requirementTypeTooltip": "Prazo para reunir os ativos necessários no local de recuperação."
        },
        "establishByCard": {
            "dateCreatedTooltip": "Data em que este recurso foi definido pela primeira vez.",
            "establishByDateTooltip": "Estabeleça a existência deste recurso até esta data."
        },
        "planStructure": {
            "planHeader": "O conteúdo deste campo é impresso na capa de cada recuperação[[plan]] que é gerado para o[[scope]] . ",
            "compareWithVerified": "Compare a última versão salva com a versão verificada",
            "planCover": "Esta área pode ser usada para substituir o texto que aparece na capa da recuperação padrão[[plan]]. Ao substituir o[[plan]] texto da capa, você pode precisar inserir uma quebra e linha no início desta área (pressionando a tecla Enter) para centralizar o texto entre os[[plan]] cobrir e[[plan]] campos de data. Esta área pode conter no máximo 4.000 caracteres de informação. Se esta área estiver vazia, o sistema padrão[[plan]] o texto da capa será usado.",
            "useFirstDocumentAsCoverPage": "Você tem permissão para fornecer sua própria capa para o[[plan]] que é gerado. Selecionando 'Sim' o primeiro documento em seu[[plan]] estrutura será usada como página de rosto."
        },
        "notificationSetting": {
            "verificationFrequencyTooltip": "Selecione a frequência de verificação para atingir com esta notificação",
            "notificationOverdueValueTooltip": "Especifique o valor de tempo após a data de vencimento para acionar esta notificação",
            "notificationDueSoonValueTooltip": "Especifique o valor de tempo antes da data de vencimento para acionar esta notificação",
            "iQEmailFromNameTooltip": "Especifique o valor do Nome do remetente para todos os e-mails de notificação",
            "datepartTypeTooltip": "Especifique a unidade de tempo para o valor acima",
            "jobTypeTooltip": "Selecione que tipo de notificação enviar",
            "editOverdueNotificationSetting": "Edite a configuração de notificação de atraso para{{ a }} Atividades",
            "addOverdueNotificationSetting": "Adicionar uma configuração de notificação de atraso para{{ a }} Atividades",
            "editDueSoonNotificationSetting": "Edite a configuração de notificação de vencimento em breve para{{ a }} Atividades",
            "addDueSoonNotificationSetting": "Adicionar uma configuração de notificação com vencimento em breve para{{ a }} Atividades"
        },
        "complianceCategory": {
            "nameTooltip": "O nome identifica a categoria de conformidade e deve ser exclusivo em todo o sistema. Pode ter até 50 caracteres.",
            "descriptionTooltip": "A descrição da categoria de conformidade pode ter até 512 caracteres.",
            "complianceCatTooltip": "Categoria de conformidade que requer este recurso de recuperação. Selecione tudo que se aplica."
        },
        "storageLocation": {
            "nameTooltip": "O nome identifica o local de armazenamento e deve ser exclusivo no sistema. Pode ter até 50 caracteres.",
            "descriptionTooltip": "A descrição do local de armazenamento pode ter até 512 caracteres."
        },
        "externalResourceType": {
            "nameTooltip": "O nome identifica o[[external resource]] tipo e deve ser exclusivo em todo o sistema. Pode ter até 50 caracteres.",
            "descriptionTooltip": "Descrição do tipo de[[external resource]] pode ter até 512 caracteres."
        },
        "incident": {
            "incidentAttachmentsTooltip": "Anexe documentos ao incidente."
        }
    },
    "done": "Feito",
    "finished": "Acabado",
    "scopeTeamModal": {
        "header": "Por favor, faça sua seleção",
        "requireScopeDescription": "Selecione uma[[Scope]] para continuar. Isso afetará[[teams]] que você pode ver. Não se preocupe. Você pode mudar isso mais tarde.",
        "requireTeamDescription": "Selecione uma[[Team]] para continuar. Isso afetará[[team]] recursos que você pode visualizar. Não se preocupe. Você pode mudar isso mais tarde.",
        "requireScopeIdAndTeamIdError": "Faça sua (s) seleção (ões) antes de continuar."
    },
    "newTeamModal": {
        "header": "Crie um novo [[Team]]",
        "confirmButtonText": "Continuar"
    },
    "complianceSchedule": {
        "verify": "Verificar",
        "lastUpdated": "Última verificação",
        "lastUpdatedTooltip": "Última verificação representa a data em que ocorreu a última verificação.",
        "nextUpdate": "Próxima Verificação",
        "nextUpdateTooltip": "A próxima verificação representa a data em que a próxima verificação deve ocorrer.",
        "teamMember": "[[Team Member]]",
        "teamMemberTooltip": "[[Team Member]] quem é o principal responsável por verificar a disponibilidade do recurso.[[Team Members]] nesta lista já devem ser verificados.",
        "updateFrequency": "Frequência de atualização",
        "updateFrequencyTooltip": "A frequência de atualização representa o intervalo de tempo entre cada verificação.",
        "notVerified": "Nunca foi verificado. Por favor verifique.",
        "updateFrequencyOptions_7": "Semanal",
        "updateFrequencyOptions_30": "Mensal",
        "updateFrequencyOptions_31": "Mensalmente no último dia do mês",
        "updateFrequencyOptions_90": "Trimestral",
        "updateFrequencyOptions_91": "Trimestralmente no último dia do mês",
        "updateFrequencyOptions_180": "Semestral",
        "updateFrequencyOptions_181": "Semestral no último dia do mês",
        "updateFrequencyOptions_365": "Anual",
        "updateFrequencyOptions_365000": "N / D",
        "complianceSchedule": "Cronograma de Conformidade",
        "updateLastVerified": "Atualizar a data da última verificação",
        "updateLastVerifiedTooltip": "Atualizar a data da última verificação",
        "nextVerificationDateBeforeLastVerified": "A próxima data de verificação não pode ser anterior à data da última verificação. Ajustando a data de início do intervalo de verificação."
    },
    "comments": {
        "addComment": "Adicionar {{ name }}",
        "newComment": "Novo {{ name }}",
        "on": "em",
        "deleteComment": "Excluir",
        "deleteCommentHeader": "Excluir {{ name }}",
        "deleteCommentBody": "Tem certeza que deseja excluir este {{ name }}?"
    },
    "select": "Selecione",
    "iQ": "iQ",
    "No": "Não",
    "new": "Novo",
    "change": "mudança",
    "scopeTeamModalTooltip": "Mudança[[Scope]] e / ou [[Team]]",
    "ComplianceCategoriesSelector": {
        "complianceCategory": "Categoria de Conformidade",
        "complianceCategoryTooltip": "Categoria de Conformidade",
        "createComplianceCategory": "+",
        "complianceCategoryWithSameNameError": "Uma categoria de conformidade com o nome{{ name }} já existe."
    },
    "iqPriorities": {
        "086deec5-b113-4cc9-8024-d1f1ddb62e8d": "Crítico",
        "0b75aa95-3100-43b0-9cf7-560fb4607a47": "Alto",
        "31b51337-4a66-4cce-9c54-0daa8c6831c9": "Médio",
        "b7e4e27d-b0de-4936-858a-3fa07e0f3b68": "Baixo"
    },
    "view": "Visão",
    "noComplianceCategory": "Sem categoria de conformidade",
    "noItemsPopoverText": "Não [[Items]]",
    "externalResource": {
        "scopeAssignment": "[[Scope]] Tarefa",
        "resourceName": "Nome do recurso",
        "type": "Tipo",
        "assignedTo": "Atribuído a",
        "recoveryLocationSite": "Site de recuperação",
        "verifiedDate": "Data de Verificação",
        "status": "Status",
        "externalResources": "[[External Resources]]",
        "externalResourcesFilterPlaceholder": "Filtre os resultados por: Nome do recurso ou atribuído a",
        "newExternalResource": "Novo [[External Resource]]",
        "externalResource": "[[External Resource]]",
        "externalResourceName": "[[External Resource]] Nome",
        "isCritical": "Este é um recurso de crise",
        "isRecoveryLocation": "Este é um site de recuperação",
        "contacts": "Contatos",
        "resourceDetails": "Detalhes do recurso",
        "comments": "Comentários",
        "address": "Rua",
        "city": "City",
        "state": "State",
        "zip": "CEP / Código Postal",
        "country": "País",
        "webSite": "Site",
        "primary": "Primário",
        "name": "Nome",
        "phone": "Telefone",
        "email": "E-mail",
        "addContact": "Adicionar contato",
        "firstName": "Primeiro nome",
        "lastName": "Último nome",
        "confirmRemoveHeader": "Remover [[External Resource]]",
        "removeConfirm": "Tem certeza que deseja remover este [[external resource]]?",
        "addType": "Adicionar[[External Resource]] Tipo",
        "editType": "Editar[[External Resource]] Tipo",
        "externalResourceTypeDescription": "Descrição",
        "externalResourceTypeName": "Nome",
        "verify": "Verificar",
        "establish": "Estabelecer",
        "contact": "Contato",
        "usedByAssetsAsLocationSite": "Este[[external resource]] atualmente é usado por[[assets]] como local de localização",
        "confirmRemoveContactHeader": "Remover contato",
        "confirmRemoveContactBody": "Tem certeza de que deseja remover este contato?",
        "primaryContact": "Contato primário",
        "update": "Atualizar",
        "confirmNewPrimaryContactHeader": "Atualizar contato principal",
        "confirmNewPrimaryContactBody": "Já existe um contato principal. Este contato será o novo contato principal.",
        "lastVerified": "Última verificação",
        "nextVerification": "Próxima Verificação",
        "daysPastDue": "Dias desde o vencimento",
        "dueDate": "Data de Vencimento",
        "externalResourceWithSameNameError": "A[[external resource]] com o nome{{ externalResourceTypeName }} já existe.",
        "getExternalResourceTypesError": "Erro ao recuperar[[external resource]] tipos",
        "filterExternalResourceTypesDescription": "Filtre os resultados por nome",
        "newExternalResourceType": "Novo[[External Resource]] Tipo",
        "externalResourceType": "[[External Resource]] Tipo",
        "externalResourceTypes": "[[External Resource]] Tipos",
        "confirmRemoveExternalResourceType": {
            "header": "Retirar[[External Resource]] Tipo",
            "body": "Tem certeza que deseja remover esto{{ objectType }} nomeado{{ name }}?",
            "text": "Remover"
        },
        "confirmSaveExternalResourceType": {
            "header": "Salvar [[External Resource]] Tipo",
            "body": "Tem certeza que deseja salvar este{{ objectType }} nomeado{{ name }} ?",
            "text": "Salvar"
        },
        "externalResourceTypeAssignedToResourceError": "Não é possível remover{{ name }} com[[external resources]] atribuído a este tipo",
        "moveSuccess": "[[External Resource]] movido",
        "moveError": "[[External Resource]] não movido",
        "moveErrorDuplicateName": "[[External Resource]] não movido porque um[[external resource]] com o mesmo nome já existe na equipe de destino",
        "moveTeamTooltip": "Selecione a equipe de destino. Se um[[external resource]] é definido como um site de recuperação e tem um ativo atribuído a ele, ele só pode ser movido para equipes no mesmo escopo."
    },
    "asset": {
        "asset": "[[Asset]]",
        "assetName": "[[Asset]] Nome",
        "assignedTo": "Atribuído a",
        "storageLocation": "Local de armazenamento",
        "verifiedDate": "Data de Verificação",
        "status": "Status",
        "assetFilterPlaceHolder": "Filtre os resultados por: [[Asset]] Nome ou atribuído a",
        "newAsset": "Novo [[Asset]]",
        "verify": "Verificar",
        "establish": "Estabelecer",
        "locationSite": "Site de recuperação",
        "complianceSchedule": "Cronograma de Conformidade",
        "assetRequirement": "[[Asset]] Requerimento",
        "allocation": {
            "assetRequirement": "[[Asset]] Requerimento",
            "allocationCount": "Contagem",
            "allocationType": "Tipo",
            "confirmRemoveHeader": "Remover Atribuição de Alocação",
            "confirmRemoveMessage": "Tem certeza que deseja remover este[[asset]] exigência de contagem \"{{ x }} \"e digite\"{{ y }} \"?",
            "add": "Adicionar Requisito"
        },
        "comments": "Comentários",
        "comment": {
            "saveError": "Comentário não salvo",
            "deleteError": "Comentário não removido"
        },
        "saveSuccess": "[[Asset]] salvo",
        "saveError": "[[Asset]] não salvo",
        "confirmRemoveHeader": "Remover [[Asset]]",
        "confirmRemoveMessage": "Tem certeza que deseja remover este{{ name }}?",
        "lastVerified": "Última verificação",
        "nextVerification": "Próxima Verificação",
        "daysPastDue": "Dias desde o vencimento",
        "assetWithSameNameError": "Um [[asset]] com o nome{{ assetName }} já existe.",
        "dueDate": "Data de Vencimento",
        "moveSuccess": "[[Asset]] movido",
        "moveError": "[[Asset]] não movido",
        "moveErrorDuplicateName": "[[Asset]] não movido porque um[[asset]] com o mesmo nome já existe na equipe de destino",
        "moveRecoverySiteTooltip": "Selecione o local de recuperação. Se um[[asset]] for movido para uma equipe pertencente a um escopo diferente, um novo local de recuperação deve ser selecionado dentro desse escopo."
    },
    "userAssignment": {
        "title": "Tarefas",
        "teamMembers": "[[Team Members]]",
        "items": "[[Items]]",
        "issues": "[[Issues]]",
        "externalResources": "[[External Resources]]",
        "assets": "[[Assets]]"
    },
    "yes": "Sim",
    "no": "Não",
    "allowed": "Permitido",
    "notAllowed": "Não permitido",
    "confirmDialog": {
        "confirmSaveAndVerify": {
            "header": "Salvar Verificar {{ objectType }}",
            "body": "Você tem alterações não salvas na página. Você deseja salvar e verificar o{{ name }}?",
            "text": "Salvar e verificar"
        },
        "confirmVerify": {
            "header": "Verificar {{ objectType }}",
            "body": "Você quer verificar o{{ name }}?",
            "text": "Verificar"
        },
        "confirmEstablish": {
            "header": "Estabelecer {{ objectType }}",
            "body": "Você quer estabelecer o{{ name }}?",
            "text": "Estabelecer",
            "establishedDateInPast": "Estabelecer por data selecionado está no passado. Quando salvo o{{ name }} será estabelecido em{{ date }} ."
        },
        "confirmApprove": {
            "header": "Aprovar {{ objectType }}",
            "body": "Você deseja aprovar o{{ name }} ?",
            "text": "Aprovar"
        },
        "confirmCheckIn": {
            "header": "Check-in {{ objectType }}",
            "body": "Você quer fazer check-in no{{ name }} ?",
            "text": "Check-in"
        },
        "confirmDecline": {
            "header": "Declínio {{ objectType }}",
            "body": "Você quer recusar o{{ name }}?",
            "text": "Declínio"
        },
        "remove": {
            "header": "Remover {{ name }}",
            "body": "Tem certeza que deseja remover este {{ name }}?",
            "text": "Remover"
        },
        "confirmSave": {
            "header": "Salve {{ objectType }}",
            "body": "Você quer salvar o{{ name }}?",
            "text": "Salvar"
        },
        "confirmUpdateLastVerified": {
            "header": "Atualizar a última data verificada",
            "body": "Você quer atualizar a data da última verificação?",
            "text": "Atualizar"
        },
        "confirmUpdateLastVerifiedDateOutOfOrder": {
            "header": "Atualizar a última data verificada",
            "body": "A próxima data de verificação não pode ser anterior ou igual à data da última verificação. A data de início do intervalo de verificação será ajustada.",
            "text": "Atualizar"
        },
        "confirmAssignToSelf": {
            "header": "Atribuir{{ objectType }} para mim",
            "body": "Você quer atribuir \"{{ name }}\" para você?",
            "text": "Atribuir"
        },
        "confirmArchiveResource": {
            "header": "Arquivo {{ objectType }}",
            "body": "Você quer arquivar \"{{ name }}”",
            "text": "Arquivo"
        },
        "confirmUnArchiveResource": {
            "header": "Restaurar {{ objectType }}",
            "body": "Você quer restaurar \"{{ name }}\"",
            "text": "Restaurar"
        },
        "confirmUnsavedChanges": {
            "header": "Mudanças não salvas",
            "body": "Você tem alterações não salvas nesta página. Tem certeza que deseja descartá-los?",
            "text": "Descartar mudanças"
        }
    },
    "rowCountMessage": "Mostrando{{ x }} do{{ y }} total",
    "noRowCountMessage": "Mostrando 0 de 0 no total",
    "more": "Mais",
    "all": "Tudo",
    "readMore": "Mais informações",
    "readLess": "Menos informaçoes",
    "common": {
        "scope": "[[Scope]]",
        "team": "[[Team]]",
        "complianceCategory": "Categoria de Conformidade",
        "search": "Pesquisar",
        "comment": "Comentar",
        "noComments": "No Comments Available (translation required)",
        "noAttachments": "Sem Anexos",
        "note": "Nota",
        "edit": "Editar",
        "deselect": "Deselecionar",
        "name": "Nome",
        "save": "Salvar",
        "add": "Adicionar",
        "requireApproval": "Requer Aprovação",
        "checkIn": "Check-in",
        "decline": "Declínio",
        "approve": "Aprovar",
        "type": "Tipo",
        "approvedBy": "Aprovado por {{ name }}",
        "checkedInBy": "Check-in realizado por {{ name }}",
        "declinedBy": "Recusado por {{ name }}",
        "msgFileExceedMaxSize": "O tamanho do arquivo excede o limite máximo de {{ size }}MB",
        "msgErrorDownloadFile": "Falha ao baixar anexo {{ fileName }}",
        "msgErrorAttachmentExists": "Já existe um anexo com o mesmo nome, gostaria de substituir o anexo existente ou salvar como um novo anexo?",
        "msgAttachmentWithSameNameExist": "Já existe um anexo com o mesmo nome de arquivo.",
        "msgVirusDetected": "Vírus detectado neste arquivo",
        "msgFailProcessFile": "Não foi possível processar o arquivo, tente novamente.",
        "msgFailCloudmersiveAPI": "Não foi possível validar o arquivo, tente novamente mais tarde.",
        "geolocations": "Geolocalizações",
        "next": "Próximo(a)",
        "previous": "Anterior",
        "no": "Não",
        "yes": "Sim",
        "cancel": "Cancelar",
        "accept": "Aceitar",
        "close": "Fechar",
        "copy": "Cópia",
        "back": "Voltar",
        "roles": "Funções",
        "or": "ou",
        "ok": "OK",
        "more": "mais",
        "finish": "Terminar",
        "andAreIn": "e estão em",
        "tenants": "Inquilinos",
        "groups": "Grupos",
        "departments": "[[Departments]]",
        "locations": "Locais",
        "everyone": "Todos(as)",
        "noSearchResult": "Sem resultado de pesquisa",
        "role": "Função",
        "group": "Grupo",
        "tenant": "Inquilino",
        "department": "[[Department]]",
        "location": "Local",
        "entities": "Entidades",
        "enabled": "Habilitado",
        "disabled": "Desabilitado",
        "date": "Data",
        "time": "Horário",
        "timezone": "Fuso horário",
        "remove": "Remover",
        "others": "Outros(as)",
        "submit": "Enviar",
        "update": "Atualizar",
        "confirmation": "Confirmar",
        "sms": "SMS",
        "email": "E-mail",
        "default": "Padrão",
        "pageSize": "Tamanho da página",
        "pageOptionShowAll": "Mostrar todos(as)",
        "prev": "Ant",
        "deleted": "Excluído(a)",
        "attachments": "Anexos",
        "assignToSelf": "Atribuir para mim",
        "archive": "Arquivar",
        "delete": "Excluir",
        "selected": "Selecionado(a)",
        "includeArchived": "Incluir arquivados(as)",
        "archived": "Arquivado(a)",
        "restore": "Restaurar",
        "loadMore": "Carregar mais",
        "all": "Todos(as)",
        "unknown": "Desconhecido",
        "_emptyString": "",
        "reload": "Recarregar",
        "reloading": "Recarregando",
        "showAdvancedFilter": "Mostrar filtro avançado",
        "showSimpleFilter": "Ocultar filtro avançado",
        "filterStartDate": "Data de início",
        "filterEndDate": "Data de término",
        "listSortAriaLabel": "Ativar para classificar",
        "listSortAriaLabelDesc": "Ativar para classificar em ordem decrescente",
        "listSortAriaLabelAsc": "Ativar para classificar em ordem crescente",
        "lblNA": "N/D",
        "defaultLocation": "Local padrão",
        "both": "Ambos(as)",
        "displayingXOutOfY": "Exibindo {{displaying}} de {{total}}",
        "additionalLocations": "Locais adicionais",
        "multiple": "Múltiplo(a)",
        "lblNewFolder": "Criar nova pasta",
        "lblUploadFile": "Carregar documento",
        "lblUploadFiles": "Carregar documentos",
        "lblSelectFile": "Selecionar documento",
        "lblDeleteDocument": "Excluir documento",
        "lblDeleteFolder": "Excluir pasta",
        "lblMoveDocument": "Mover documento",
        "lblMoveFolder": "Mover pasta",
        "lblBookmarkDocument": "Favoritar documento",
        "lblDownloadDocument": "Baixar documento",
        "lblBookmarkName": "Favoritar nome",
        "lblNewDocumentBookmark": "Criar marcação de documento",
        "lblNewPageBookmark": "Criar marcação de página",
        "msgSuccessAddBookmark": "Favorito(a) {{ name }} criado com sucesso",
        "msgErrorAddBookmark": "Falha ao criar favorito(a) {{ name }}",
        "completed": "Concluído(a) {{date}}",
        "bookmark": "Favoritar",
        "document": "Documento",
        "refresh": "Atualizar",
        "showingXofX": "Mostrando {{count}} de {{total}}",
        "enterprise": "Empreendimento",
        "realEstate": "Imóveis",
        "undo": "Desfazer",
        "new": "Novo(a)",
        "export": "Exportar",
        "createdOn": "Criado(a) em",
        "lastModifiedOn": "Última modificação em",
        "Required": "(exigido)",
        "lastModifiedBy": "Modificado(a) pela última vez por",
        "createdBy": "Criado(a) por",
        "selectOne": "Selecione um",
        "create": "Criar",
        "upload": "Carregar",
        "resetTable": "Redefinir tabela",
        "msgSecurityErrorTitle": "Erro de segurança",
        "msgSecurityErrorMessage": "Você não tem permissão para acessar a página solicitada",
        "msgBcIndustryErrorMessage": "Sua empresa não possui setor especificado para [[planner]] de continuidade comercial",
        "msgBcAssignedProcessErrorMessage": "Você não possui nenhum [[process]] atribuído a este departamento",
        "msgBcAssignedDepartmentErrorMessage": "Você não possui departamentos atribuídos",
        "msgBcAssignedFunctionalAreasErrorMessage": "Você não possui departamentos atribuídos",
        "msgBcPlanErrorMessage": "Não há plano de continuidade comercial criado para o [[planner]] de continuidade comercial",
        "msgNoAssignments": "Você não possui atribuições para plano de continuidade comercial",
        "msgGeneralErrorMessage": "Erro no sistema. Tente novamente mais tarde.",
        "msgNoIncidentErrorMessage": "Não há nenhum incidente ativo associado aos planos de ativação.",
        "msgRequireOpenIncidentErrorMessage": "Este incidente foi resolvido.",
        "msgRequireResolvedIncidentErrorMessage": "Este incidente ainda está ativo.",
        "msgBcManagerErrorMessage": "Sua empresa não possui gerente de continuidade comercial especificado para [[planner]]",
        "deactivated": "Desativado",
        "active": "Ativo",
        "backToTop": "Voltar ao topo",
        "none": "Nenhum(a)",
        "move": "Mover",
        "preview": "Pré-visualização",
        "feet": "Pés",
        "mile": "Milha",
        "meter": "Metro",
        "kilometer": "Quilômetro",
        "height": "Altura",
        "width": "Largura",
        "today": "Hoje",
        "showMore": "Mostrar mais...",
        "showLess": "Mostrar menos",
        "miles": "Milhas",
        "meters": "Metros",
        "kilometers": "Quilômetros",
        "exit": "Sair",
        "exitSave": "Sair e salvar",
        "members": "Membros",
        "dashboard": "Painel de controle",
        "assign": "Atribuir",
        "delegate": "Delegar",
        "unassign": "Cancelar atribuição",
        "unassigned": "Atribuição cancelada",
        "rename": "Renomear",
        "loading": "Carregando...",
        "started": "Começado",
        "status": "Status",
        "revisit": "Rever",
        "continue": "Continuar",
        "start": "Começar",
        "navigate": "Navegar",
        "resend": "Reenviar",
        "view": "Visualizar",
        "help": "Ajuda(r)",
        "closed": "Fechado(a)",
        "open": "Abrir/Aberto",
        "title": "Título",
        "owner": "Proprietário",
        "description": "Descrição",
        "issues": "[[Issues]]",
        "reports": "Relatórios",
        "generate": "Gerar",
        "download": "Baixar",
        "generateAll": "Gerar todos os planos",
        "downloadAll": "Baixar todos(as)",
        "clearFilters": "Limpar filtros",
        "inactive": "Inativo",
        "noRecords": "Não há registros",
        "created": "Criado(a)",
        "updated": "Atualizado(a)",
        "removed": "Removido(a)",
        "saved": "Salvo(a)",
        "downloading": "Baixando...",
        "uploading": "Carregando...",
        "deleting": "Removendo...",
        "chart": "Gráfico",
        "descriptions": "Descrições",
        "end": "Fim/Finalizar",
        "never": "Nunca",
        "on": "Em",
        "until": "Até",
        "at": "Em/Às",
        "assigned": "Atribuído",
        "hide": "Ocultar",
        "unhide": "Mostrar",
        "showHidden": "Mostrar oculto(a)",
        "comments": "Comentários",
        "criticalDate": "Data crítica",
        "strategy": "Estratégia",
        "goToDetails": "Ir para detalhes",
        "getStarted": "Iniciar",
        "viewDetails": "Visualizar detalhes",
        "clear": "Limpar",
        "apply": "Aplicar",
        "empty": "Esvaziar",
        "aToZ": "A a Z",
        "recovered": "Recuperado(a)",
        "notActivated": "Não ativado(a)",
        "saveAndClose": "Salvar e fechar",
        "nA": "N/D",
        "saving": "Salvando",
        "translate": "Traduzir",
        "address": "Endereço",
        "state": "Estado",
        "zip": "CEP",
        "country": "País",
        "city": "Cidade",
        "primary": "Primário(a)",
        "contacts": "Contatos",
        "phone": "Telefone",
        "website": "Site",
        "select": "Selecionar",
        "language": {
            "en": "Inglês",
            "es": "Espanhol",
            "fr": "Francês",
            "pt": "Português"
        },
        "reset": "Redefinir",
        "hours": "Horas",
        "days": "Dias",
        "day": "Dia",
        "hour": "Hora",
        "original": "Original",
        "current": "Atual",
        "edits": "Edições",
        "expiration": "Validade",
        "copyToClipboard": "Copiar para área de transferência",
        "addLocation": "Adicionar local",
        "addDependency": "Adicionar dependência",
        "survey": "Pesquisar",
        "system": "Sistema",
        "dependencies": "Dependências",
        "processes": "[[Processes]]",
        "thereArentMoreItemsAvailable": "Não há mais itens disponíveis para selecionar",
        "loadingMap": "Carregando mapa...",
        "success": "Sucesso",
        "schedule": "Cronograma",
        "updatedBy": "Atualizado(a) por",
        "updatedDate": "Data da atualização",
        "launch": "Iniciar",
        "resolved": "Resolvido(a)",
        "selectedItemsLabel": "{0} itens selecionados",
        "support": "Suporte",
        "low": "Baixo(a)",
        "medium": "Médio(a)",
        "high": "Alto(a)",
        "formula": "Fórmula",
        "company": "Companhia",
        "continueConfirmation": "Você deseja continuar?",
        "target": "Alvo/Meta",
        "approver": "Aprovador",
        "dueDate": "Data final",
        "recipient": "Destinatário",
        "searchResults": "Resultados da pesquisa",
        "headerLabel": "Selecionar usuário",
        "searchUserLabel": "Pesquisar usuário",
        "currentUserLabel": "Usuário atual",
        "resultLabel": "Resultado",
        "notAvailable": "Não disponível",
        "abandoned": "Abandonado(a)",
        "abandon": "Abandonar",
        "waitingApproval": "Aguardando aprovação",
        "inProgress": "Em andamento",
        "selectColumns": "Selecionar colunas",
        "msgrExportSuccessful": "{{targetType}} exportação(ões) concluída(s) com sucesso.",
        "awaitingApproval": "Aguardando aprovação",
        "pastDue": "Vencido(a)",
        "closedOn": "Data de encerramento",
        "errorExportFailed": "Não foi possível exportar {{targetType}} arquivo(s).",
        "unAssociate": "Desassociar",
        "associate": "Associar",
        "approved": "Aprovado(a)",
        "assessments": "Avaliações",
        "paginationReport": "Mostrando {first} a {last} de {{totalRowCount}}",
        "overTime": "Com o passar do tempo",
        "authority": "Autoridade",
        "triggers": "Gatilhos",
        "limitations": "Limitações",
        "primaries": "Primário(s)",
        "delegates": "Delegados",
        "itemsToComplete": "Itens a serem concluídos",
        "details": "Detalhes",
        "duplicate": "Duplicado",
        "available": "Disponível",
        "heading": "Überschrift",
        "body": "Körper",
        "revert": "Zurückkehren",
        "reverted": "Zurückgesetzt",
        "publish": "Veröffentlichen",
        "draft": "Rascunho",
        "version": "Versão",
        "published": "Veröffentlicht",
        "landscape": "Paisagem",
        "portrait": "Retrato",
        "enterTitle": "Digite o título aqui",
        "enterDescription": "Insira a descrição aqui",
        "tags": "Tag",
        "orientation": "Orientação",
        "pageBreak": "Quebra de página",
        "promote": "Promover",
        "generalOptions": "Opções Gerais",
        "markCompleteContinue": "Marcar como concluído",
        "sendEmailContinue": "Enviar email & Continuar",
        "keyPersonnel": "Pessoal-chave",
        "responseTeams": "Equipes de resposta",
        "buildManage": "Construir",
        "track": "Acompanhar",
        "skip": "Pular",
        "missionStatement": "Declaração de missão",
        "introduction": "Introdução",
        "discard": "Descartar",
        "general": "Em geral",
        "makeSystem": "Criar Sistema",
        "annex": "Anexo",
        "sections": "Seções",
        "taskedAgencies": "Agências Encarregadas",
        "responsibilities": "Responsabilidades",
        "what": "Quoi",
        "where": "Où",
        "action": "Ação",
        "noAssignee": "Nenhum cessionário",
        "emailSent": "E-mail enviado",
        "bulkUpload": "Upload em massa",
        "replace": "Substituir",
        "run": "Correr",
        "alternate": "Alternar",
        "assignMember": "Atribuir membro",
        "attachment": "Anexo",
        "technologies": "[[Technologies]]",
        "DateAdded": "data adicionada",
        "selectedOf": "{{selected}} de {{available}}",
        "technology": "[[Technology]]",
        "minutes": "Minutos",
        "usedInPlans": "Usado em planos",
        "undefined": "Indefinido",
        "tier": "Nível",
        "assignedOwner": "Proprietário atribuído",
        "examples": "Exemplos",
        "markComplete": "Marcar como concluído",
        "done": "Feito",
        "unclassified": "Não classificado",
        "checking": "Verificando..."
    },
    "geolocations": {
        "lblShowAddress": "Mostrar endereço",
        "options": "Opções",
        "showGeofences": "Mostrar cercas virtuais",
        "hideGeofences": "Escondendo cercas virtuais",
        "showLocations": "Mostrar locais",
        "hideLocations": "Escondendo locais",
        "showUserAddress": "Mostrar endereços de usuários",
        "hideUserAddress": "Escondendo endereços de usuários",
        "filterByZip": "Código postal",
        "showPOIs": "Mostrar POIs",
        "hidePOIs": "Escondendo POIs",
        "location": "Localização",
        "filterByLocationOption": "Incluir opções de localização do usuário"
    },
    "geofence": {
        "new": "Nova cerca virtual",
        "name": "Nome da cerca virtual",
        "information": "Informação",
        "address": "Endereço da cerca virtual",
        "findAddress": "Encontrar endereço",
        "create": "Criar cerca virtual",
        "update": "Atualizar cerca virtual",
        "locationAddress": "Endereço de localização",
        "remove": "Remover cerca virtual",
        "errorGeocodeFailed": "Não foi possível resolver o endereço fornecido, corrija o endereço e tente novamente.",
        "msgGeocodeSuccess": "endereço resolvido com sucesso.",
        "noGeofenceError": "Nenhuma cerca virtual ativa, crie uma cerca virtual para salvar.",
        "errorInvalidPolygon": "Esta cerca virtual não é suportada, certifique-se de que a fronteira da cerca virtual não atravesse",
        "msgSaveGeofenceSuccess": "Cerca virtual{{ name }} foi salva com sucesso.",
        "errorSaveGeofenceFailed": "Falha ao salvar cerca virtual {{ name }}",
        "errorReverseGeocodeFailed": "Incapaz de resolver o local fornecido para o endereço",
        "msgRemoveGeofenceSuccess": "Cerca virtual {{ name }} foi removida com sucesso.",
        "errorRemoveGeofenceFailed": "Falha ao remover cerca virtual {{ name }}",
        "AreYouSureDeleteGeofence": "Tem certeza de que deseja excluir esta cerca virtual?",
        "createGeofenceFromPostalCode": "Crie cerca virtual a partir deste código postal",
        "msgFailCreateGeofenceFromPostalCode": "Falha ao criar cerca virtual do código postal",
        "userAddress": "Endereço do usuário",
        "userMobileLocation": "Localização móvel do usuário",
        "userAssociatedLocation": "Local associado ao usuário",
        "radius": "Raio",
        "width": "Largura",
        "height": "Altura",
        "msgInvalidRadius": "Raio inválido",
        "msgInvalidDimension": "Largura ou altura inválida",
        "circle": "Círculo",
        "rectangle": "Retângulo",
        "polygon": "Polígono",
        "postalCode": "Código postal",
        "newGeofence": "Nova cerca virtual"
    },
    "establishByCard": {
        "dateCreated": "Data Criada",
        "establishByDate": "Estabelecer por Data",
        "establishOnDate": "Estabelecer na data",
        "establishByDatePastDue": "Vencidos",
        "establishByDateDetails": "Estabelecer por Data"
    },
    "charts": {
        "labels": {
            "verified": "Verificado",
            "overview": "Visão geral",
            "preparisScore": "Pontuação Preparis",
            "pastDue": "Vencidos",
            "categoryScores": "Pontuações da categoria",
            "score": "Ponto",
            "notEstablished": "Não estabelecido",
            "categoryScore": "Pontuação da categoria",
            "total": "Total",
            "categoryProgress": "Progresso da Categoria"
        },
        "colors": {
            "verified": "#28a745",
            "unverified": "#ffc107",
            "pastDue": "#dc3545",
            "radarLevels": {
                "default": {
                    "backgroundColor": "rgba(0, 0, 0, 0.0)",
                    "borderColor": "rgba(0, 0, 0, 0)",
                    "pointBackgroundColor": "rgba(0, 0, 0, 0)",
                    "pointBorderColor": "rgba(0, 0, 0, 0)",
                    "pointHoverBackgroundColor": "rgba(0, 0, 0, 0)",
                    "pointHoverBorderColor": "rgba(0, 0, 0, 0)"
                },
                "first": {
                    "backgroundColor": "rgba(212, 239, 223, 0.2)",
                    "borderColor": "rgba(39, 174, 96, 1)",
                    "pointBackgroundColor": "rgba(39, 174, 96, 1)",
                    "pointBorderColor": "#fff",
                    "pointHoverBackgroundColor": "#fff",
                    "pointHoverBorderColor": "rgba(39, 174, 96, 1)"
                }
            }
        }
    },
    "planStructure": {
        "plan": "[[Plans]]",
        "itemsInRecoveryPlan": "Itens neste [[Plan]] Estrutura",
        "availableItems": "Itens Disponíveis",
        "name": "Nome",
        "type": "Tipo",
        "tocIndent": "Recuo TOC",
        "filename": "Nome do arquivo",
        "allDocuments": "Todos os documentos",
        "scopeRelatedDocuments": "[[Scope]] Documentos relacionados",
        "teamRelatedDocuments": "[[Team]] Documentos relacionados",
        "teamAndSubTeamRelatedDocuments": "[[Team]] e Sub[[Team]] Documentos relacionados",
        "allReports": "Todos os [[reports]]",
        "add": "Adicionar",
        "description": "Descrição",
        "document": "Documento",
        "toc": "Tabela de conteúdo",
        "report": "[[Reports]]",
        "remove": "Remover",
        "planStructure": "[[Plan]] Estrutura",
        "planHeader": "[[Plan]] Cabeçalho",
        "planCover": "[[Plan]] Substituição do corpo da capa",
        "generate": "Gerar",
        "insertToc": "Inserir índice",
        "lastGenerationDate": "Data da Última Geração",
        "overrideNextGenDate": "Data de Substituição",
        "overrideNextGeneration": "Substituir a data da próxima geração",
        "planGenerationSchedule": "[[Plan]] Cronograma de Geração",
        "scheduleFrequency": "Frequência de programação",
        "schedulePlanGeneration": "Cronograma[[Plan]] Geração",
        "planGenUseLastDayOfMonth": "O último dia do mês",
        "jobStartDate": "Data de Início do Trabalho",
        "nextGeneration": "Próximo[[Plan]] Geração",
        "generatingPlan": "Gerando [[Plan]]",
        "generatingAttachmentsZip": "Gerando anexos Zip",
        "generateConfirmation": {
            "header": "Gerar [[Plan]]",
            "body": "Você quer gerar o seu[[plan]] usando a versão atual do[[plan]] ou a última versão verificada do[[plan]] ?",
            "useLatest": "Use o mais recente",
            "useVerified": "Uso verificado"
        },
        "invalidDateDialog": {
            "header": "Data de Substituição Inválida",
            "body": "Nas próximas[[Plan]] A data de geração deve ser no futuro",
            "text": "OK"
        },
        "useFirstDocumentAsCoverPage": "Use o primeiro documento como capa"
    },
    "complianceCategories": {
        "filterDescription": "Filtre os resultados por nome",
        "newComplianceCategory": "Nova Categoria de Conformidade",
        "complianceCategories": "Categorias de Conformidade",
        "name": "Nome da Categoria",
        "description": "Descrição",
        "confirmRemoveComplianceCategory": {
            "header": "Remover categoria de conformidade",
            "body": "Tem certeza que deseja remover esto{{ objectType }} nomeado{{ name }}?",
            "text": "Remover"
        },
        "confirmSaveComplianceCategory": {
            "header": "Salvar categoria de conformidade",
            "body": "Tem certeza que deseja salvar este{{ objectType }} nomeado{{ name }} ?",
            "text": "Salvar"
        },
        "complianceCategoryWithDependentsError": "Não pode remover{{ name }} quando outros registros são atribuídos a esta categoria"
    },
    "storageLocations": {
        "storageLocations": "Locais de armazenamento",
        "filterDescription": "Filtre os resultados por nome",
        "newStorageLocation": "Novo local de armazenamento",
        "name": "Local de armazenamento",
        "description": "Descrição",
        "getStorageLocationError": "Erro ao recuperar locais de armazenamento",
        "confirmRemoveStorageLocation": {
            "header": "Remover local de armazenamento",
            "body": "Tem certeza que deseja remover esto{{ objectType }} nomeado{{ name }}?",
            "text": "Remover"
        },
        "confirmSaveStorageLocation": {
            "header": "Salvar local de armazenamento",
            "body": "Tem certeza que deseja salvar este{{ objectType }} nomeado{{ name }} ?",
            "text": "Salvar"
        },
        "storageLocationWithItemsError": "Não pode remover{{ storageName }} com[[items]] atribuído a ele"
    },
    "timeInterval": {
        "week": "Semana",
        "7": "Semana",
        "month": "Mês",
        "30": "Mês",
        "quarter": "Trimestre",
        "90": "Trimestre",
        "halfYear": "6 meses",
        "180": "6 meses",
        "year": "Ano",
        "365": "Ano"
    },
    "saveAndEstablish": {
        "header": "Salvar {{ name }} Opções",
        "body": "O{{ name }} será salvo e estabelecido em {{ date }}",
        "text": "Salvar e estabelecer",
        "establishedDateInPast": "Estabelecer por data selecionado está no passado. Quando salvo o{{ name }} será estabelecido em{{ date }} .",
        "establishedDateToday": "O estabelecimento selecionado por data é Hoje. O{{ name }} será estabelecido em{{ date }} ."
    },
    "updateVerificationDate": {
        "header": "Atualizar{{ name }}{{dateType}} encontro",
        "body": "Selecione um novo{{ name }}{{dateType}} encontro",
        "dateType_next": "Próxima Verificação",
        "dateType_last": "Última verificação"
    },
    "saveAndCheckIn": {
        "header": "Salvar {{ name }} Opções",
        "body": "Você deseja salvar e fazer check-in no{{ name }} ou apenas salvar o{{ name }}?",
        "saveAndCheckIn": "Salvar e fazer check-in",
        "saveNotCheckIn": "Salvar, mas não fazer check-in"
    },
    "settings": {
        "settings": "Configurações",
        "resourceRequireApproval": "Exigir aprovações para[[team]] recursos por padrão",
        "resourceRequireApprovalTooltip": "Quando habilitado,[[team]] recursos, como itens, por padrão, exigem aprovações de um aprovador designado para serem verificados.",
        "forceNextVerificationBasedOnLastVerified": "Substituir cálculo de data padrão",
        "portalApprovalSettingSaveSuccess": "Configuração de aprovação de recurso do portal salva",
        "portalApprovalSettingSaveError": "Erro ao salvar configuração de aprovação de recurso do portal",
        "portalSettingSaveSuccess": "Configuração do portal salva",
        "portalSettingSaveError": "Erro ao salvar a configuração do portal",
        "forceNextVerificationBasedOnLastVerifiedWarning": "Atenção! Se ativado, a próxima data de verificação será calculada com base unicamente na última data verificada e na frequência de verificação. Ativar esta configuração não substituirá os dados existentes",
        "assignResourceToSelf": "Permitir[[team members]] assumir a propriedade de[[team]] Recursos",
        "assignResourceToSelfTooltip": "Quando habilitado,[[team members]] pode atribuir[[team]] recursos para si próprios"
    },
    "notificationSetting": {
        "notificationSetting": "Configuração de Notificação",
        "notificationSettings": "Configurações de notificação",
        "jobType": "Tipo de notificação",
        "verificationFrequency": "Frequência de verificação",
        "notificationValue": "Número",
        "datepartType": "Intervalo",
        "add": "Adicionar Notificação",
        "dueSoon": "Vencimento em breve",
        "overdue": "Em atraso",
        "months": "Mês(es)",
        "days": "Dia(s)",
        "confirmRemoveHeader": "Remover configuração de notificação",
        "confirmRemoveMessage": "Tem certeza que deseja remover esta{{ x }} notificação de{{ y }}{{ z }} ?",
        "saveSuccess": "Configuração de notificação para{{ a }} do{{ b }} dentro{{ c }}{{ d }} salva",
        "saveError": "Configuração de notificação para{{ a }} do{{ b }} dentro{{ c }}{{ d }} não salva",
        "removeSuccess": "Configuração de notificação para{{ a }} do{{ b }} dentro{{ c }}{{ d }} removida",
        "removeError": "Configuração de notificação para{{ a }} do{{ b }} dentro{{ c }}{{ d }} não removida",
        "notificationTypes": {
            "eeca9d34-c1bd-4a24-8562-caaef0e1f510": "Vencimento em breve",
            "e69761d6-02ac-4156-8f46-6b56fd99614a": "Em atraso"
        },
        "datepartTypes": {
            "f8b89b3c-8c9e-47c8-9a2e-9c5417d1a838": "Mês(es)",
            "baba0540-0282-4aca-a53a-fb064ce2d509": "Dia(s)"
        },
        "dueSoonDescription": "Enviar notificação{{ a }}{{ b }} antes da data de vencimento.",
        "overdueDescription": "Enviar notificação{{ a }}{{ b }} após a data de vencimento.",
        "dueTodayDescription": "Envie uma notificação na data de vencimento.",
        "dueSoonTitle": "Notificações para atividades futuras",
        "dueTodayTitle": "Notificações para atividades na data de vencimento",
        "overdueTitle": "Notificações para atividades vencidas",
        "addNotificationSettingForDatepart": "Adicionar{{ a }} Configuração de Notificação",
        "customizeNotificationSettingForDatepart": "Customizar{{ a }} Configuração de Notificação",
        "editNotificationSetting": "Editar configuração de notificação",
        "addNotificationSetting": "Adicionar configuração de notificação",
        "confirmSave": {
            "header": "Salvar configuração de notificação",
            "body": "Tem certeza que deseja salvar esta{{ notificationType }} configuração de notificação para{{ verificationFrequency }} Atividades?",
            "text": "Salvar"
        },
        "confirmRemove": {
            "header": "Remover configuração de notificação",
            "body": "Tem certeza que deseja remover esta{{ notificationType }} configuração de notificação para{{ verificationFrequency }} Atividades?",
            "text": "Remover"
        },
        "default": "padrão",
        "confirmChangePortalSettingHeader": "Alterar configuração de e-mail do portal",
        "confirmChangePortalSettingMessage": "Você tem certeza que quer{{ action }}{{ settingName }} ?",
        "enable": "Habilitar",
        "disable": "Desabilitar",
        "portalEmailSettingSaveSuccess": "Configuração de e-mail do portal salva",
        "portalEmailSettingSaveError": "Erro ao salvar configuração de e-mail do portal",
        "notifications": "Notificações",
        "portalSetting": "Configuração do portal",
        "enableReminderEmails": "Enviar lembretes para [[Team Members]]",
        "enableSummaryEmails": "Enviar como e-mail de resumo",
        "enableReminderEmailsTooltip": "Quando habilitado, os lembretes das próximas tarefas são enviados por e-mail para[[team members]] a cada dia.",
        "enableSummaryEmailsTooltip": "Quando ativado, os lembretes diários para as próximas tarefas são resumidos em um e-mail.",
        "savePortalSettingSuccess": "Configuração do portal salva",
        "savePortalSettingError": "Configuração do portal não salva",
        "iQEmailFromName": "E-mail de notificação do nome"
    },
    "iqVerificationSettings": {
        "verificationIntervalThresholdLabel": "Limite de intervalo de verificação",
        "intervalOptions": {
            "d": "Dia(s)",
            "m": "Mês(es)"
        }
    },
    "sendMessage": {
        "disableEnablePrefix": "Oculte o prefixo da mensagem de resposta incluído no SMS e no e-mail",
        "createdBy": "Criado por",
        "sendMessage": "Enviar mensagem",
        "eventName": "Nome do evento",
        "options": "Opções",
        "messages": "Mensagens",
        "filters": "Filtros",
        "review": "Revisar",
        "incidentName": "Nome do Incidente",
        "incidentType": "Tipo de Incidente",
        "howToContact": "Selecione como deseja entrar em contato com os destinatários",
        "requireResponse": "Exigir que os destinatários respondam",
        "yesWithCustom": "Sim, com respostas personalizadas (máx.: {{maximumCustomResponses}})",
        "requireResponseYes": "Sim (o Preparis adicionará instruções à sua mensagem, pedindo aos usuários que respondam com{{responses}})",
        "customResponses": "Respostas personalizadas",
        "customResponse": "Resposta personalizada",
        "customResponseDefinition": "Definição de Resposta",
        "advanced": "Avançado",
        "personalEmail": "E-mail pessoal",
        "personalPhone": "Telefone pessoal",
        "additionalPersonalPhone": "Telefone Pessoal Adicional",
        "personalContact": "Envie esta mensagem para as informações de contato pessoal do destinatário",
        "autoResend": "Reenviar mensagem automaticamente",
        "autoSendToManager": "Envie automaticamente uma mensagem ao gerente do destinatário",
        "autoTranslate": "Traduzir mensagem automaticamente",
        "sms": "SMS",
        "voice": "Voz",
        "phone": "Telefone",
        "pushMessage": "Mensagem Push",
        "email": "E-mail",
        "emergencyMessage": "Mensagem de Emergência",
        "sendAfter": "enviar depois",
        "for": "Para",
        "ifRecipients": "se destinatários",
        "minutes": "Minutos",
        "hour": "Hora",
        "hours": "Horas",
        "day": "Dia",
        "responded": "respondeu",
        "hasNotResponded": "não respondeu",
        "times": "vez(es),",
        "newMessage": "Nova mensagem",
        "isSimulation": "Envie mensagem como uma simulação",
        "usersCanReplyViaLink": "Permitir que os usuários respondam por meio de um link",
        "unknown": "Desconhecido",
        "subject": "Sujeito",
        "message": "Mensagem",
        "ctr": "Chamada para gravar",
        "tts": "Texto para fala",
        "call": "Ligar",
        "phoneNumber": "Número de telefone",
        "preview": "Pré-visualização",
        "bodyPrepend": "Responder a esta mensagem ({{responsesAsString}}): ",
        "bodyPrepend-en": "Reply to this msg ({{responsesAsString}}): ",
        "bodyPrepend-es": "Responder a este mensaje ({{responsesAsString}}): ",
        "bodyPrepend-fr": "Répondre à ce msg ({{responsesAsString}}): ",
        "bodyPrepend-pt": "Responder a esta mensagem ({{responsesAsString}}): ",
        "remaining": "Remanescente",
        "copyToOthers": "Copiar para outros",
        "copySubjectToOthers": "Copiar assunto para outros",
        "copyBodyToOthers": "Copiar mensagem para outras pessoas",
        "usersWhoAreIn": "Usuários que estão em",
        "numberOfRecipients": "Número de destinatários",
        "allUsers": "Todos os usuários",
        "manuallyAdd": "Adicionar manualmente",
        "andOr": "e / ou",
        "noSelections": "Nenhuma seleção foi feita",
        "autoPopulateKey": "Preencher automaticamente a RESPOSTA PERSONALIZADA",
        "clickAbove": "Clique em qualquer opção acima para começar a selecionar os destinatários",
        "filterHint01": "Selecione qualquer categoria para criar sua lista. Um usuário deve corresponder a um dos valores em cada categoria que você selecionar para ser incluído. Você também pode adicionar destinatários manualmente.",
        "example": "Exemplo",
        "filterExample01": "Se você selecionar opções de várias categorias, um usuário deve atender a todos os critérios para ser incluído. Por exemplo, se você selecionar Função: Gerente de programa e locais: Atlanta, Nashville e [[Departments]] de Marketing. Um usuário deve estar no [[Departments]] de marketing em Atlanta ou Nashville com a função de gerente de programa para receber esta mensagem.",
        "selectNone": "selecione nenhum",
        "viewSelections": "ver as seleções",
        "deleteShape": "Excluir forma",
        "zoomToShape": "Zoom para moldar",
        "geofenceOptions": "Opções de cerca virtual da mensagem",
        "geofenceChoices": "Como você gostaria de criar sua cerca virtual?",
        "existingGeofence": "Selecione a partir de cercas virtuais",
        "newGeofence": "Criar a partir do endereço",
        "geofences": "Cercas virtuais",
        "contactMethods": "Métodos de contato",
        "included": "Incluído",
        "excluded": "Excluído",
        "search": "Pesquisar",
        "addByPhoneNumber": "Adicionar por número de telefone",
        "addByEmail": "Adicionar por e-mail",
        "addByType": "Adicionar por tipo",
        "manuallyAddedRecipients": "Destinatários adicionados manualmente",
        "invalidPhoneNumber": "Número de telefone inválido",
        "allUsersSelected": "Todos os usuários foram selecionados",
        "errorGettingData": "Erro ao obter dados",
        "registeredRecipients": "Destinatários registrados",
        "noRecipientsError": "Nenhum destinatário foi adicionado",
        "sentTo": "Mensagens enviadas para",
        "automaticFollowUp": "Acompanhamento Automático",
        "sendNow": "Envie agora",
        "saveForLater": "Guardar para depois",
        "schedule": "Cronograma",
        "scheduleToSend": "Enviar mais tarde",
        "responses": "Respostas",
        "sendToPersonalContact": "Enviar para contato pessoal",
        "toWhom": "Para quem você quer enviar esta mensagem?",
        "toWhomMaxOneHundred": "Para quem você quer enviar esta mensagem? (Máximo de 100 participantes)",
        "disabledForOverOneHundredParticipants": "Algumas opções estão desativadas para mais de 100 participantes",
        "everyoneLabel": "Envie uma mensagem a todos os associados a este incidente",
        "everyoneDescription": "Qualquer pessoa que for afetada por este incidente, tiver recebido uma mensagem anterior ou incluída em uma chamada em conferência sobre este incidente será preenchida como destinatários",
        "limitedLabel": "Envie mensagens apenas para um subconjunto de usuários",
        "limitedDescription": "Envie apenas para aqueles cuja última resposta foi",
        "newLabel": "Envie uma mensagem para um novo conjunto de usuários",
        "newDescription": "Você será livre para escolher os destinatários da mensagem, em vez de pré-preencher",
        "incidentCreatorLabel": "Envie uma mensagem ao criador do incidente",
        "incidentCreatorDescription": "criou este incidente",
        "followUp": "Acompanhamento",
        "scheduleMessageSuccess": "Mensagem agendada",
        "scheduleMessageError": "Mensagem de erro de agendamento",
        "deleteScheduledMessageSuccess": "Mensagem agendada excluída",
        "deleteScheduledMessageError": "Erro ao excluir mensagem agendada",
        "scheduledMessage": "Mensagem agendada",
        "confirmDeleteScheduledMessageHeader": "Remover mensagem agendada",
        "confirmDeleteScheduledMessageDescription": "Tem certeza de que deseja remover esta mensagem agendada?",
        "confirmDeleteMessageTemplateHeader": "Remover mensagem salva",
        "confirmDeleteMessageTemplateDescription": "Tem certeza de que deseja remover esta mensagem salva?",
        "getScheduleMessageError": "Erro ao obter mensagem agendada",
        "updateScheduledMessage": "Atualizar mensagem agendada",
        "followUpMessage": "Acompanhamento",
        "saveAs": "Como você deseja salvar esta mensagem?",
        "overwrite": "Sobrescrever atual",
        "saveAsNew": "Salvar como nova",
        "saveMessageName": "Salvar o nome da mensagem",
        "savedMessages": "Mensagens salvas",
        "pendingMessages": "Mensagens pendentes",
        "pin": "Clique para fixar",
        "unpin": "Clique para liberar",
        "removeMessageTemplate": "Remover mensagem salva",
        "send": "Enviar",
        "removeTemplateHeader": "Remover mensagem salva",
        "removeTemplateBody": "Tem certeza de que deseja remover este modelo de mensagem?",
        "noOfRecipient": "nº de destinatários",
        "responseRequired": "Resposta exigida",
        "noPendingMessage": "Sem mensagem pendente",
        "sendMessageSuccess": "Sua mensagem está a caminho. Você gostaria de salva-la para ser usado novamente mais tarde?",
        "sendMessageSuccessAlt": "Sua mensagem está a caminho",
        "contacts": "Contatos",
        "includeOfficeContactInfo": "Também ligar para o telefone do escritório dos destinatários?",
        "invalidEmail": "E-mail inválido",
        "confirmCancelHeader": "Cancelar envio de mensagem",
        "confirmCancelBody": "Tem certeza que deseja cancelar o envio da mensagem?",
        "callRecipientOfficePhone": "Ligar para o telefone do escritório",
        "noDupeResponses": "Sem respostas duplicadas",
        "selections": "Seleções",
        "recipients": "Destinatários",
        "composeNewMessage": "Escrever nova mensagem",
        "progress": "Progresso",
        "dupeNameError": "Este nome já existe",
        "successRemovingMessageTemplate": "Mensagem salva removida",
        "allCountries": "Todos os países",
        "noManuallyAddedRecipients": "Nenhum destinatário adicionado manualmente",
        "manuallyAddedLocations": "Locais adicionados manualmente",
        "manuallyAddedDepartments": "[[Departments]] adicionados manualmente",
        "manuallyAddedGroups": "Grupos adicionados manualmente",
        "manuallyAddedTenants": "Inquilinos adicionados manualmente",
        "manuallyAddedRoles": "Funções adicionadas manualmente",
        "submitForApproval": "Enviar para aprovação",
        "pendingMessageName": "Nome da mensagem pendente",
        "messageSubmittedForApproval": "Mensagem enviada para aprovação",
        "removePendingMessageHeader": "Remover mensagem pendente",
        "removePendingMessageBody": "Tem certeza de que deseja remover esta mensagem pendente?",
        "successDeletePendingMessage": "Mensagem pendente removida",
        "newSendMessage": "Envie uma nova mensagem",
        "noReply": "Sem resposta",
        "approvePendingMessage": "Aprovar mensagem pendente",
        "updatePendingMessage": "Atualizar mensagem pendente",
        "declinePendingMessage": "Recusar mensagem pendente",
        "newEvent": "Novo evento",
        "approveAndSend": "Aprovar e enviar",
        "permissionError": "Você não tem permissão para fazer alterações nesta mensagem pendente",
        "everyoneWhose": "Todos cuja última resposta foi",
        "everyoneInThisEvent": "Todos neste evento",
        "sendEdit": "Enviar / Editar",
        "detectStartStep": "Etapa inicial de detecção automática",
        "detectStartStepDescription": "O aplicativo pode detectar automaticamente qual etapa do [[processes]] de Enviar Mensagem deve começar com base nos dados da Mensagem Salva. Isso pode reduzir o número de cliques necessários para enviar sua mensagem. Ao especificar 'Não', a primeira etapa do [[process]] de Enviar Mensagem sempre será usada.",
        "startWithMessageTemplate": "Comece com uma mensagem salva (opcional)",
        "startWithMessageTemplateDescription": "Destinatários de mensagens salvas NÃO SERÃO aplicados a esta mensagem",
        "incidentCreator": "Criador do incidente",
        "desktop": "Área de Trabalho",
        "desktopSeverity": "Gravidade",
        "desktopSeverityAlarm": "[[Alert]]",
        "desktopSeverityWarning": "Aviso",
        "desktopSeverityInfo": "Informação",
        "desktopSendSilently": "Enviar silenciosamente",
        "simulationMessage": "Esta é uma simulação",
        "errorDesktopAlertWithNoResponse": "Não é possível enviar uma mensagem da área de trabalho como um [[Alert]] quando uma resposta não é exigida do usuário.",
        "sendAMessage": "Envie uma mensagem",
        "entityRemoved": "Uma ou mais seleções foram removidas do sistema desde que esta mensagem foi salva pela última vez. Certifique-se de que todas as seleções estejam atualizadas. Os seguintes foram removidos:",
        "createANewMessage": "Crie uma nova mensagem",
        "alertDeclare": "Alertar / Declarar",
        "createANewMessaage": "Crie uma nova mensagem",
        "requireRecipientToRespondTooltip": "(O Preparis adicionará instruções à sua mensagem, pedindo aos usuários que respondam com OK ou NÃO)",
        "selectDeliveryMethod": "Selecione o método de entrega",
        "push": "Push",
        "copyText": "Copiar Texto",
        "selectedResponses": "Respostas selecionadas",
        "automaticReply": {
            "automaticReply": "Auto Resposta",
            "setUpAutomaticReplies": "Configurar respostas automáticas",
            "lblAutomaticReplies": "Mensagens de resposta automática",
            "configuredAutomaticReplies": "Respuestas automáticas configuradas"
        },
        "uploadNewDocument": "Composer un nouveau message",
        "title": "messages",
        "searchPlaceholder": "Rechercher un message enregistré",
        "addNew": "Composer un nouveau message",
        "createFolder": "Nouveau dossier",
        "searchTitle": "Résultats de recherche",
        "or": "ou",
        "sendOrEdit": "Envoyer/Modifier",
        "buttonPin": "Épingle",
        "buttonUnPin": "Détacher",
        "pinned": "Épinglé",
        "pending": "En attente",
        "messageDetails": "detalhes da mensagem",
        "selectAllWarning": "Você selecionou 'Selecionar tudo'. Tem certeza de que deseja adicionar todos os usuários à lista de destinatários desta mensagem?",
        "selectAllWarning2": "Você selecionou potencialmente um grande grupo de pessoas para adicionar a esta mensagem."
    },
    "googleMapAPITermAndService": "Termo de uso da API do mapa",
    "portalResources": {
        "resourceTypes": {
            "2e2016a0-4990-4276-b2f3-01950edc13e1": "Ferramentas do empregado",
            "e956d676-9d9f-4ab3-80b0-21e670e720ad": "Modelos",
            "9904f935-b860-44fc-8a7d-2a91475e9fbb": "Infográficos",
            "47905bed-1e55-4efd-baab-63014481c1cd": "Folhas de Referência",
            "e9730817-b71b-4273-9e2b-650191be2d61": "Feed RSS",
            "bb073508-593c-40bb-8c39-6bf5aa801c0c": "Tabletop",
            "5bb91d95-051d-48b3-b1bc-7a8f4558c1b6": "Notícias e resumos",
            "8e499ec0-a8b1-4de0-b830-89adcec08483": "Treinamento",
            "f2a1fa9e-bfbd-4051-a95b-95f4b518a17a": "Protocolos",
            "4cbb9953-a1d9-47ab-9c86-b810a6fcf898": "Blog",
            "40e1b2f9-e47a-4c6c-93fb-c4684f88705c": "Lista de verificação",
            "62c2f132-76ad-47f8-bd43-dbe02abba987": "Resumos Executivos",
            "148a1689-577b-4ca5-b47b-e124ab249b42": "Ameaça"
        },
        "distributionTypes": {
            "5729f1b5-be13-4f33-aa73-7843b5bc2fce": "Verticais e explícitos",
            "d11232ba-5525-40d5-8062-57caca5fa498": "Apenas explícito"
        },
        "resourceType": "Tipo de recurso",
        "icon": "Ícone de item de conteúdo",
        "document": "Documento de item de conteúdo",
        "categories": "Categorias",
        "requiresACL": "Requer ACL",
        "distributionType": "Tipo de Distribuição",
        "name": "Nome",
        "title": "Título",
        "description": "Descrição",
        "newItem": "Novo item",
        "resourcesFilterPlaceholder": "Filtro",
        "confirmRemoveHeader": "Remover item de conteúdo",
        "remove": "Remover",
        "contentItem": "Item de Conteúdo",
        "sortOrder": "Ordem",
        "select": "Selecione",
        "viewOrderLists": "Ver listas de pedidos",
        "viewOrderList": "Ver lista de pedidos",
        "availableContentItems": "Itens de conteúdo disponíveis",
        "availableTrainingCourses": "Cursos de treinamento disponíveis",
        "selectedContentItems": "Itens de conteúdo selecionados",
        "selectedTrainingCourses": "Cursos de treinamento selecionados",
        "vertical": {
            "3089683e-a1c9-4c91-9b87-1e3888a9cfcf": "padrão",
            "f1d39814-6de9-481b-9abe-d655d396a64d": "CRE"
        }
    },
    "publicAlertSubscription": {
        "lblPageHeader": "Assinaturas de [[alert]] público",
        "lblPageDescription": "Gerenciar assinaturas de mensagens de [[alert]] públicas.",
        "publicAlertSubscription": "Assinatura",
        "publicAlertSubscriptions": "Assinaturas",
        "subscriptionName": "Nome da Assinatura",
        "incidentType": "Tipo de Incidente",
        "groupEntity": "Grupo",
        "filterPlaceHolder": "Filtre os resultados por nome de inscrição",
        "newSubscription": "Nova Assinatura",
        "sendSms": "Enviar SMS",
        "sendEmail": "Enviar e-mail",
        "sendVoice": "Enviar voz",
        "isPush": "Ativar push",
        "categoryType": "Tipos de categoria",
        "certaintyType": "Tipos de certeza",
        "severityType": "Tipos de gravidade",
        "urgencyType": "Tipos de Urgência",
        "eventType": "Tipos de Eventos",
        "allTypes": "Todos os tipos",
        "zoneCountryFilter": "Filtro de zona de país",
        "zoneRegionFilter": "Filtro de região de zona",
        "zoneTypeFilter": "Filtro de tipo de zona",
        "automaticallySendMessages": "Enviar mensagens automaticamente",
        "describe": "Descrever",
        "filterTypes": "Tipos de filtro",
        "filterLocations": "[[Locations]] de filtro",
        "availableZones": "Zonas Disponíveis",
        "selectedZones": "Zonas Selecionadas",
        "rowCountMessage": "Mostrando{{ x }} de {{ y }} assinaturas",
        "createNewSubscription": "Criar nova assinatura",
        "errorMessages": {
            "selectAtLeastOneChannel": "Selecione pelo menos um canal de comunicação antes de salvar.",
            "selectAtLeastOneRegion": "Selecione pelo menos uma região antes de salvar.",
            "requiredFieldMissing": "Por favor preencha todos os campos obrigatórios."
        },
        "simple": "Simples",
        "advanced": "Avançado",
        "autoSend": "Envio automático",
        "confirmSave": {
            "header": "Salvar assinatura de [[alert]] público",
            "body": "Tem certeza que deseja salvar este{{ objectType }} nomeado{{ name }} ?",
            "text": "Salvar assinatura"
        },
        "confirmRemove": {
            "header": "Remover assinatura de [[alert]] público",
            "body": "Tem certeza que deseja remover esto{{ objectType }} nomeado{{ name }}?",
            "text": "Remover assinatura"
        },
        "help": {
            "subscriptionName": "Nome para rotular exclusivamente esta assinatura.",
            "incidentType": "Tipo de incidente que melhor categoriza os tipos de eventos filtrados desta assinatura.",
            "sendSms": "Envie [[alerts]] públicos que correspondam a esta assinatura via SMS.",
            "sendEmail": "Envie [[alerts]] públicos que correspondam a esta assinatura por e-mail.",
            "sendVoice": "Envie [[alerts]] públicos que correspondam a esta assinatura via correio de voz.",
            "isPush": "Envie notificações push para [[alerts]] públicos que correspondam a esta assinatura.",
            "groupEntity": "Designe o grupo que receberá e agirá de acordo com os [[alerts]] públicos que correspondem a esta assinatura.",
            "categoryType": "Assinar esses tipos de categoria.",
            "certaintyType": "Assinar esses tipos de certeza.",
            "severityType": "Assinar esses tipos de gravidade.",
            "urgencyType": "Assina esses tipos de urgência.",
            "eventType": "Assinar esses tipos de eventos.",
            "zoneCountryFilter": "Filtrar as zonas disponíveis por país.",
            "zoneRegionFilter": "Filtrar as zonas disponíveis por região.",
            "zoneTypeFilter": "Filtrar as zonas disponíveis por tipo.",
            "autoSend": "Enviar mensagens automaticamente à medida que [[alerts]] públicos são gerados."
        }
    },
    "incidentTypeTriggers": {
        "manageIncidentTypeTriggers": "Gerenciar gatilhos de incidentes",
        "incidentTypeTriggersDescription": "Os incidentes podem ser acionados por meio de APIs de terceiros no sistema Preparis. Isso é feito mapeando um tipo de incidente enviado pela API de terceiros para uma mensagem salva.",
        "incidentTypeTriggers": "Gatilhos de incidentes",
        "help": {
            "incidentTypeTriggers": "A menos que estejam em uso, quase todos os gatilhos de incidentes podem ser removidos."
        },
        "incidentTypeTriggersName": "Gatilhos",
        "incidentTypeHeader": "Tipo de Incidente",
        "apiHeader": "API",
        "savedMessageHeader": "Mensagem Salva",
        "noTriggers": "Nenhum gatilho de incidente foi adicionado",
        "addNewTrigger": "Adicionar novo gatilho de incidente",
        "errorDuplicatedIncidentTypes": "Não é possível usar um tipo de incidente como gatilho várias vezes.",
        "errorNonSendableSavedMessages": "Mensagem(ns) salva(s) ({{messageNames}}) não pode ser enviada(s) porque todos os campos obrigatórios não foram preenchidos. Selecione mensagens salvas alternativas."
    },
    "incidentTypes": {
        "manageIncidentTypes": "Gerenciar tipos de incidentes",
        "incidentTypesDescription": "Adicione outros tipos de incidentes que podem ser usados para classificar incidentes ao criar e atualizar incidentes no portal e também ao criar incidentes iniciados pelo usuário por meio do aplicativo móvel.",
        "noIncidentTypes": "Nenhum tipo de incidente foi adicionado",
        "incidentType": "Tipo de Incidente",
        "incidentTypes": "Tipos de Incidentes",
        "systemDefaultIncidentTypes": "Tipos de incidentes padrão do sistema",
        "addNewIncidentType": "Adicionar Novo Tipo de Incidente",
        "addSystemIncidentType": "Adicionar tipo de incidente do sistema",
        "msgRequiredFieldsWarning": "Insira todos os campos obrigatórios ou remova-os antes de salvar.",
        "msgSaveIncidentTypesSuccess": "Tipos de incidentes salvos com sucesso.",
        "msgDuplicateIncidentTypeNameWarning": "Um ou mais tipos de incidente têm o mesmo nome. Verifique o nome do seu tipo de incidente.",
        "incidentTypeName": "Nome do tipo de incidente",
        "saveSuccess": "Tipos de incidentes salvos com sucesso.",
        "help": {
            "incidentTypes": "A menos que estejam atualmente em uso, quase todos os tipos de incidentes podem ser removidos. Além disso, os tipos de incidentes personalizados podem ser renomeados. (Os tipos de incidentes pré-preenchidos são somente leitura.) O tipo de incidente padrão \"Não definido\" é obrigatório e somente leitura, não pode ser renomeado ou removido."
        },
        "errors": {
            "messagingIncidentTypeWithGroupDependencies": "Um ou mais tipos de incidentes removidos estão sendo usados como uma configuração de grupo iniciada pelo usuário.",
            "messagingIncidentTypeWithChannelDependencies": "Um ou mais tipos de incidentes removidos estão sendo usados atualmente como uma configuração de canal iniciada pelo usuário.",
            "messagingIncidentTypeWithPublicAlertDependencies": "Um ou mais tipos de incidentes removidos estão sendo usados com uma configuração de [[alert]] público.",
            "messagingIncidentTypeCannotRemoveSystemDefault": "\"Não definido\" é um padrão do sistema e não pode ser removido.",
            "messagingIncidentTypeNamesAreNotUnique": "Os nomes não são únicos"
        }
    },
    "emailTypes": {
        "acb4f38f-3642-472a-8de2-dcead28584a2": "Pessoal",
        "d440a213-3ab4-4fb9-b306-5f567141ac53": "Secundário",
        "d2b1d156-bdf9-4223-9368-825a448025ad": "Primário"
    },
    "phoneTypes": {
        "9da9c256-8283-4a0a-bd3d-10df73e1f43b": "Segurança",
        "20509910-5326-4057-b21f-5c694b314692": "Escritório",
        "5322a58a-8683-449b-a562-b0577de3fa74": "Emergência",
        "5eec12d5-595f-4123-a613-c63e701ae993": "Pessoal",
        "33f27328-8fee-4835-b020-cd6d48bb39cd": "Móvel",
        "391dca7b-aac3-4875-94e2-2ef680c7f35f": "Pessoal Adicional"
    },
    "responses": {
        "lblUnknown": "Desconhecido",
        "lblNoResponse": "Sem resposta",
        "lblNotOk": "NÃO",
        "lblOk": "OK"
    },
    "publicAlerts": {
        "dateTimeSent": "Data enviada",
        "msgType": "Tipo",
        "category": "Categoria",
        "urgency": "Urgência",
        "severity": "Gravidade",
        "certainty": "Certeza",
        "dateTimeExpiration": "Expira em",
        "recentPublicAlerts": "[[Alerts]] Públicos Recentes",
        "status": "Status",
        "expired": "Expirado",
        "active": "Ativo",
        "recentAlerts": "[[Alerts]] Recentes",
        "instruction": "Instrução",
        "description": "Descrição",
        "publicAlerts": "[[Alerts]] Públicos",
        "responseType": "Tipo de Resposta",
        "showMore": "Mostre mais",
        "showLess": "Mostre menos",
        "filters": "Filtros",
        "areas": "Áreas",
        "headline": "Título",
        "expiresOn": "Expira em",
        "sentOn": "Enviado em",
        "sendMessage": "Enviar mensagem",
        "errors": {
            "errorLoadingRecentAlerts": "Erro ao carregar [[alerts]] recentes",
            "errorLoadingAlertInfo": "Erro ao carregar mais informações de [[alert]]"
        },
        "rowCountMessage": "Mostrando{{ displayedCount }} de{{ totalCount }} {{ status }} [[alerts]]",
        "showAdvanced": "Mostrar avançado",
        "showSimple": "Mostrar simples",
        "previousDays": {
            "2": "Últimas 48 horas",
            "3": "Últimas 72 horas",
            "4": "Últimos 4 dias",
            "5": "Últimos 5 dias"
        },
        "noAlertsToDisplayMessage": "Não{{ status }} [[alerts]] sobre o {{ timeSpan }}",
        "subscriptions": "Assinaturas",
        "subscription": "Assinatura"
    },
    "messageSettings": {
        "errorRequiredFields": "Por favor, preencha todos os campos obrigatórios.",
        "errorResetMessageFailed": "Ocorreu um erro ao redefinir as configurações da mensagem.",
        "errorSavMessageFailed": "Ocorreu um erro ao salvar as configurações da mensagem.",
        "lblAreYouSureResetSettings": "Tem certeza de que deseja redefinir as configurações de mensagens?",
        "lblEscalateMessageContent1": "Se você quiser dar aos destinatários da mensagem tempo para responder por SMS, e-mail ou mensagens push antes de serem chamados, defina como Sim. Você poderá então selecionar o tempo de espera antes de ligar.",
        "lblEscalateMessageContent2": "Se definido como não, o destinatário será chamado ao mesmo tempo que receber mensagens de texto / e-mail / push.",
        "lblEscalateMessageContent3": "Nota: A configuração APENAS se aplica a mensagens que requerem uma resposta e têm SMS e / ou e-mail e / ou Push e voz selecionados.",
        "lblEscalateMessageTitle": "Escalonar a mensagem quando a resposta for necessária (SMS / e-mail / Push to Voice)?",
        "lblIncludeOfficePhoneContent": "Se definido como Sim, quando um usuário envia uma nova mensagem E seleciona para Voz (destinatários da chamada) a opção de ligar para o telefone do escritório de um destinatário será pré-selecionada. Os usuários poderão substituir o padrão e desmarcar por mensagem, se desejado.",
        "lblIncludeOfficePhoneTitle": "Incluir telefone do escritório para chamadas de voz por padrão?",
        "lblPersonalContactContent": "Se definido como Sim, quando um usuário enviar uma nova mensagem, a opção de enviar para as informações de contato pessoal do destinatário da mensagem será pré-selecionada. Os usuários poderão substituir esse padrão e desmarcar por mensagem, se desejado.",
        "lblPersonalContactTitle": "Enviar mensagem para as informações de contato pessoal do usuário?",
        "lblSettingsActivity": "Atividade de configurações",
        "lblSettingsTitle": "Configurações de mensagem",
        "lblAutoArchive": "Arquivo automático de registros de histórico de incidentes",
        "lblAutoArchiveContent": "Se definido como Sim, os registros do histórico de incidentes resolvidos serão arquivados automaticamente. Você terá a oportunidade de escolher o intervalo de arquivamento e se deseja resolver e arquivar registros antigos, mas ainda ativos.",
        "lblForceResolution": "Resolver e arquivar incidentes ativos mais antigos",
        "lblIntervalDays": "Arquivar incidentes anteriores",
        "msgResetMessageSuccess": "As configurações de mensagem foram redefinidas com sucesso.",
        "msgSaveMessageSuccess": "As configurações de mensagem foram salvas com sucesso.",
        "lblPersonalContactPhoneTitle": "Enviar mensagem para o telefone pessoal do usuário?",
        "lblPersonalContactPhoneContent": "Se definido como Sim, quando um usuário enviar uma nova mensagem, a opção de enviar para as informações pessoais do telefone do destinatário da mensagem será pré-selecionada. Os usuários poderão substituir esse padrão e desmarcar por mensagem, se desejado.",
        "personalContactEmailTitle": "Enviar mensagem para o e-mail pessoal do usuário?",
        "lblPersonalContactEmailContent": "Se definido como Sim, quando um usuário enviar uma nova mensagem, a opção de enviar para o e-mail pessoal do destinatário da mensagem será pré-selecionada. Os usuários poderão substituir esse padrão e desmarcar por mensagem, se desejado.",
        "lblOverwriteDefaultUserResponses": "Se você sobrescrever as respostas padrão do usuário, as opções de resposta da mensagem terão esses valores",
        "lblDesablePrefixSmsVoice": "Aplicável apenas se você estiver solicitando uma resposta",
        "btnSave": "Salvar",
        "btnUndo": "Desfazer",
        "btnReset": "Redefinir",
        "lblCustomResponses": "Substituir as respostas padrão do usuário?",
        "lblDisablePrefix": "Oculte o prefixo da mensagem de resposta incluído no SMS e no e-mail.",
        "confirmReset": {
            "body": "Tem certeza de que deseja redefinir as configurações de mensagens?",
            "header": "Confirme",
            "btnText": "OK"
        },
        "allowOfficePhoneSMS": "Permitir que SMS sejam enviados para telefones de escritório qualificados.",
        "allowOfficePhoneSMSDesc": "Se definido como sim, os números de telefone do escritório serão incluídos nas mensagens, desde que o telefone seja aceito e não tenha uma extensão."
    },
    "conferenceCall": {
        "conferenceCall": "Teleconferência",
        "selectContactTip": "Se você escolher Celular, Escritório ou Pessoal, os participantes são chamados automaticamente no método de contato selecionado quando você inicia a chamada",
        "selectHowYouWantToContact": "Selecione como deseja entrar em contato com os participantes desta teleconferência (você pode escolher apenas uma opção)",
        "newConferenceCall": "Nova teleconferência",
        "mobileTip": "Melhor forma de alcançar usuários",
        "mobile": "Móvel",
        "office": "Escritório",
        "personal": "Pessoal",
        "optionsConferenceDetailDescription": "Selecione para onde enviar os detalhes da teleconferência para os participantes",
        "noCall": "Não ligar",
        "noCallTip": "Enviar apenas detalhes da teleconferência",
        "personalEmail": "E-mail pessoal",
        "personalPhone": "Telefone pessoal",
        "finalRecipientCountErrorMsg": "Máximo de destinatários: {{maxRecipient}}",
        "startConferenceCall": "Iniciar teleconferência",
        "contactParticipants": "Contactar participantes",
        "sendConferenceCallDetailTo": "Enviar detalhes da teleconferência para",
        "optionsPersonalInfo": "Envie detalhes da teleconferência para informações de contato pessoal",
        "success": "Teleconferência iniciada com sucesso",
        "confirmCancelHeader": "Cancelar teleconferência",
        "confirmCancelBody": "Tem certeza de que deseja cancelar esta teleconferência?",
        "followUpConferenceCall": "Acompanhamento",
        "optionsPersonalInfoDescription": "Envie esta mensagem para as informações de contato pessoal do destinatário",
        "scheduleConferenceCall": "Agendar teleconferência",
        "scheduleConferenceCallError": "Erro ao agendar teleconferência",
        "scheduleConferenceCallSuccess": "Teleconferência agendada com sucesso",
        "scheduledConferenceCalls": "Chamadas de teleconferência",
        "noScheduledConferenceCalls": "Nenhuma teleconferência agendada",
        "dateCreated": "Data Criada",
        "scheduledDate": "Data agendada",
        "scheduledTime": "Horário agendado",
        "createdBy": "Criado por",
        "deleteScheduledConferenceCallBody": "Tem certeza de que deseja remover esta teleconferência agendada?",
        "deleteScheduledConferenceCall": "Remover teleconferência agendada",
        "updateScheduledConferenceCall": "Atualizar teleconferência agendada",
        "scheduledConferenceCall": "Teleconferência agendada",
        "searchScheduledConCallsPlaceholder": "Pesquisar teleconferência agendadas",
        "everyoneInThisEvent": "Todos neste evento",
        "additionalMessage": "Mensagem adicional",
        "additionalMessageDescription": "Esta é uma mensagem opcional que será adicionada ao final da mensagem que os participantes receberão na notificação de uma teleconferência",
        "additionalSmsMessagePreview": "Uma teleconferência de emergência foi configurada por{{userProfileName}}: +{{portalConferenceCallNumber}} ID: O <em title=\"PIN será gerado quando a chamada for iniciada\"> pin</em> Um toque: tel: +{{portalConferenceCallNumber}},,<em title=\"Pin será gerado quando a chamada for iniciada\"> pin</em>#.",
        "additionalEmailBodyPreview": "Uma teleconferência de emergência foi configurada por{{userProfileName}}. Ligue para o seguinte número para participar da teleconferência:<br /><br />+{{portalConferenceCallNumber}}<br /><br />ID da conferência: o <em title=\"PIN será gerado quando a chamada for iniciada\"></em><br />Um toque: tel:+{{portalConferenceCallNumber}},,O <em title=\"pin será gerado quando a chamada for iniciada\"> pin</em>#",
        "optionsRecordCall": "Selecione se deseja gravar esta teleconferência",
        "recordCallDisclaimer": "As leis aplicáveis podem exigir que os participantes ouçam uma notificação de gravação de chamada antes de uma chamada ser gravada. É sua responsabilidade garantir que os procedimentos de gravação de chamadas de sua empresa estejam em conformidade com as leis e regulamentos aplicáveis. A Preparis não se responsabiliza pela conformidade de sua empresa."
    },
    "customFields": {
        "customFieldTitle": "Questões Personalizadas",
        "technologyTypes": "Tipos de [[Technology]]",
        "customFieldsDescription": "As perguntas personalizadas serão adicionadas ao seu fluxo de planejamento e as respostas serão exibidas no seu plano e no rastreador de problemas.",
        "fieldName": "Nome",
        "fieldType": "Tipo de campo",
        "isRequired": "Necessário",
        "fieldTypes": {
            "5f31785c-4d3c-4d68-a834-da37c2bc0a76": "Numérico",
            "0f6f2372-1e4c-4d67-ad13-615a0540488c": "Texto curto",
            "b9847d16-19b5-4ed4-a4e3-d7f07533ab5a": "Texto longo",
            "d8bd3d80-d25d-4056-8754-a740f2396792": "Verdadeiro ou falso",
            "aad6f035-05f9-42d3-b6ea-682371340875": "Selecione um",
            "76c83029-8439-42ad-90e6-7f0ff54b732c": "Selecione muitos",
            "1eccbe40-1b3b-461b-95df-35f1fe6a8af2": "Data / hora"
        },
        "isRequiredOptions": {
            "true": "Sim",
            "false": "Não"
        },
        "displaySequence": "Sequência de exibição",
        "fieldDomain": "Opções",
        "success": {
            "deleteCustomFieldSuccess": "Sucesso ao excluir o campo personalizado",
            "saveCustomFieldsDisplayOrderSuccess": "Sucesso ao salvar pedido de exibição",
            "saveCustomFieldSuccess": "Sucesso ao salvar o campo personalizado"
        },
        "errors": {
            "getCustomFieldsError": "Falha ao carregar campos personalizados",
            "saveCustomFieldsDisplayOrderError": "Erro ao salvar novo pedido de exibição",
            "deleteCustomFieldError": "Erro ao excluir campo personalizado",
            "customFieldWithSameNameError": "Erro ao salvar campo personalizado com o mesmo nome",
            "saveCustomFieldError": "Erro ao salvar campo personalizado",
            "customFieldOptionWithSameNameError": "Erro ao salvar campo personalizado com nomes de opção duplicados",
            "customFieldTypeSelectOneWithoutOptions": "As opções são necessárias para os campos personalizados \"Selecionar um\"",
            "customFieldsInvalid": "Reveja os campos personalizados"
        },
        "help": {
            "fieldNameTooltip": "Nome do rótulo do campo personalizado",
            "fieldTypeTooltip": "Tipo de dados a coletar com este campo personalizado",
            "isRequiredTooltip": "Indique se este campo personalizado é obrigatório",
            "editCustomFieldInstructions": "Edite o tipo de dados a coletar e se esse campo personalizado deve ou não ser exigido.",
            "addCustomFieldInstructions": "Adicione um campo personalizado para coletar informações.",
            "optionsTooltip": "Liste as opções na ordem de exibição.",
            "selectTechnologyTypesToolTip": "Lista de tipos de [[technology]] disponíveis."
        },
        "editCustomField": "Editar campos personalizados existentes",
        "addCustomField": "Adicionar Novo Campo Personalizado",
        "newCustomField": "Novo Campo Personalizado",
        "confirmRemoveCustomField": {
            "header": "Remover campo personalizado",
            "body": "Tem certeza que deseja remover este campo personalizado?",
            "text": "Remover"
        },
        "confirmSaveCustomField": {
            "header": "Salvar campo personalizado",
            "body": "Tem certeza que deseja salvar este campo personalizado",
            "text": "Salvar"
        },
        "customFields": "Campos personalizados",
        "optionName": "Nome da Opção",
        "addOption": "Adicionar Opção",
        "default": "Padrão",
        "restore": "Restaurar",
        "options": "Opções",
        "makeDefault": "Tornar padrão",
        "saveDisplayOrder": {
            "cancelDisplayOrder": "Cancelar ordem",
            "saveDisplayOrder": "Salvar ordem",
            "header": "Salvar nova ordem de exibição",
            "body": "Tem certeza de que deseja alterar a ordem em que os campos são exibidos?",
            "text": "Salvar ordem de exibição"
        },
        "customFieldsActivity": "Atividade de campos personalizados",
        "deprecatedOption": "Opção obsoleta",
        "bcCustomQuestionsTitle": "Perguntas personalizadas sobre continuidade de negócios",
        "bcCustomQuestionsDescription": "Gerencie suas questões personalizadas de continuidade de negócios.",
        "disasterRecovery": "Recuperação de desastres",
        "drCustomQuestionsTitle": "Perguntas personalizadas sobre recuperação de desastres.",
        "drCustomQuestionsDescription": "Gerencie suas perguntas personalizadas sobre recuperação de desastres.",
        "technology": "[[Technology]]",
        "continuityOfOperationPlanning": "Continuidade",
        "customQuestionsDescription": "Gerencie suas questões alfandegárias de continuidade aqui."
    },
    "iqStatus": {
        "0": "",
        "1": "Não estabelecido"
    },
    "cre": {
        "locations": "[[Locations]]",
        "location": "[[Location]]"
    },
    "apiManagement": {
        "newApi": "Novo",
        "thirdPartyIntegrations": "Integrações de terceiros",
        "integrationName": "Nome",
        "appId": "Id do aplicativo",
        "apiKey": "Chave API",
        "addApiHeader": "Nova Integração",
        "confirmButtonText": "Salvar",
        "deleteApi": "Excluir",
        "confirmRemoveHeader": "Excluir integração",
        "removeConfirm": "Essa integração será removida de todas as empresas que a utilizam atualmente. Tem certeza de que deseja remover esta integração?",
        "errorUnableToLoad": "Não foi possível carregar integrações de terceiros no momento.",
        "errorUnableToDelete": "Não foi possível excluir esta integração de terceiros no momento.",
        "successDelete": "Integração de terceiros excluída com sucesso",
        "successCreate": "Integração de terceiros criada com sucesso"
    },
    "panicTriggers": {
        "panicTriggerConfiguration": "Configuração do gatilho de pânico",
        "panicTriggerLocations": "Locais de gatilho de pânico",
        "managePanicTriggers": "Gerenciar os gatilhos de pânico",
        "panicTriggersDescription": "Os incidentes podem ser acionados através do botão de pânico do aplicativo móvel. Esta página permite configurar a mensagem salva do incidente.",
        "lblEnablePanic": "Habilitar Pânico",
        "editMessageTemplate": "Editar gatilho",
        "deleteMessageTemplate": "Excluir gatilho",
        "addMessageTemplate": "Adicionar gatilho padrão",
        "addCustomizedByLocation": "Adicionar gatilho personalizado por locais",
        "contacts": "Contatos",
        "filters": "Filtros",
        "locations": "[[Locations]]",
        "groups": "Grupos",
        "recipients": "Destinatários",
        "btnSaveAndConfigureMessage": "Aceitar e configurar a mensagem",
        "btnConfigureMessage": "Configurar Mensagem",
        "deleteConfirmation": {
            "header": "Remover o gatilho de pânico",
            "body": "Tem certeza de que deseja remover este gatilho de pânico?",
            "text": "Remover"
        },
        "enablePanicConfirmation": {
            "header": "Habilitar Pânico",
            "body": "Tem certeza de que deseja ativar o Pânico?",
            "text": "Habilitar"
        },
        "disablePanicConfirmation": {
            "header": "Desabilitar pânico",
            "body": "Tem certeza de que deseja desativar o Pânico?",
            "text": "Desabilitar"
        },
        "disablePanicAndRemovePanicButtonsConfirmation": {
            "header": "Remover os botões de [[alert]] e desativar o pânico",
            "body": "<p>Um botão de [[alert]] de Pânico ou Check-in programado existe, atualmente, no aplicativo móvel. (Consulte Configurações de [[alert]] iniciado por celular.)</p><p>Desativar o Pânico removerá esses botões. Tem certeza de que deseja remover esses botões e desativar o Pânico?</p>",
            "text": "Remover botões e desativar"
        },
        "locationsNotAvailable": "Não há locais disponíveis para criar um gatilho de pânico.",
        "panicEnabledSuccess": "O pânico está habilitado",
        "panicDisabledSuccess": "O pânico está desativado",
        "panicEnableFailed": "Falha ao habilitar o Pânico",
        "panicDisableFailed": "Falha ao desativar o Pânico"
    },
    "panic": {
        "panic": "Pânico",
        "initiator": "Iniciador",
        "lastLocation": "Última localização",
        "status": "Status",
        "startTime": "Hora de início",
        "resolutionTime": "Tempo de resolução",
        "gotToIncidentDetail": "Detalhes do incidente",
        "updateStatus": "Atualizar o status",
        "archiveSelected": "Arquivo selecionado",
        "active": "Ativo",
        "scheduledCheckIn": "Check-in agendado",
        "archived": "Arquivado",
        "resolved": "Resolvido",
        "scheduled": "Agendado",
        "noPanic": "Nada de pânico",
        "resolutionReasons": {
            "mistake": "Erro",
            "clear": "Claro",
            "checkIn": "Check-in",
            "custom": "Personalizadas"
        },
        "resolve": "Resolver",
        "comments": "Comentários",
        "noComments": "Sem comentários",
        "resolveComments": "Resolver comentários",
        "reason": "Razão",
        "noLastLocationRecorded": "Nenhuma última localização registrada",
        "archiveBody": "Tem certeza de que deseja arquivar isso?",
        "archive": "Arquivo",
        "scheduledCheckInMinutes": "Check-in agendado em{{minutes}} minutos",
        "panicDashboard": "Painel de Pânico",
        "showHistory": "Mostrar breadcrumb",
        "options": "Opções",
        "showLocations": "Mostrar locais",
        "showPOIs": "Mostrar POIs",
        "geolocations": "Geolocalização",
        "showUsers": "Mostrar outros usuários",
        "remaining": "Remanescente",
        "resolveCommentsRequired": "Resolver comentários necessários",
        "clickToView": "Clique para ver",
        "scheduledTime": "Agendamento Criado em",
        "buttonName": "Nome do [[Alert]]",
        "emergencyButtonDashboard": "[[Alerts]] iniciado por celular"
    },
    "branding": {
        "branding": "Terminologia",
        "brandingDescription": "Substitua alguns termos no portal. O usuário deve fazer logout e login novamente para ver os novos termos no portal.",
        "term": "Termo",
        "brandedTerm": "Termo",
        "brandedTermCamel": "Maiúscula",
        "brandedTermLower": "inferior",
        "brandedTermCamelPl": "Maiúsculas no plural",
        "brandedTermLowerPl": "Mnúsculas no plural",
        "brandedTerms": "Termos de marca",
        "addBrandedTerm": "Adicionar termo",
        "brandingActivity": "Atividade de marca",
        "language": "Língua"
    },
    "users": {
        "users": "Usuários",
        "lastName": "Último nome",
        "firstName": "Primeiro nome",
        "email": "E-mail",
        "role": "Função",
        "language": "Língua",
        "status": "Status",
        "usersFilterPlaceHolder": "Filtre os resultados por: Nome ou E-mail",
        "newUser": "Novo usuário",
        "existingUser": "Usuário existente",
        "isSelfRegistered": "Optado",
        "bulkResendInviteModel": {
            "header": "Reenviar convites para usuários pendentes",
            "body": "Existem {{ x }} usuários exibidos que estão em um status pendente. Você deseja reenviar convites apenas para os usuários pendentes exibidos ou para todos os usuários pendentes? Enviar um novo convite invalidará todos os convites existentes.",
            "allPending": "Todos pendentes",
            "displayedPending": "Exibido Pendente"
        },
        "individualResendInviteModel": {
            "header": "Reenviar convite para usuário pendente",
            "body": "Tem certeza que deseja reenviar o convite para este{{ userName }}?",
            "text": "OK"
        },
        "invitationSentMsg": "O convite foi enviado.",
        "invitationSentError": "Não foi possível reenviar o convite.",
        "invitationsSentMsg": "Os convites foram enviados para usuários pendentes designados.",
        "invitationsSentError": "Não é possível reenviar convites para usuários pendentes designados.",
        "resendInvite": "Reenviar convite",
        "export": {
            "allUsers": "Todos os usuários",
            "displayedUsers": "Usuários exibidos",
            "header": "Exportar usuários",
            "body": "Você deseja exportar apenas os usuários{{ x }} exibidos ou todos os usuários?",
            "successMsg": "Exportação gerada com sucesso",
            "errorMsg": "Erro ao gerar exportação",
            "fileName": "BulkUserUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Upload de usuários em massa",
            "msgBulkUploadInstructions": "Você deseja adicionar ou modificar vários usuários? Escolha um arquivo formatado corretamente em seu computador para iniciar o [[process]].",
            "msgDropFile": "Solte o arquivo aqui ou clique para selecionar um arquivo",
            "lblSelectedFile": "Arquivo selecionado para upload",
            "lblNoFileSelected": "Nenhum arquivo especificado.",
            "lblNeedStartingPoint": "Precisa de um ponto de partida",
            "lblAddMultipleHeader": "Adicionar vários novos usuários",
            "lblAddMultipleDetail": "Baixe esta planilha contendo apenas as informações do cabeçalho e preencha as informações do novo usuário, um usuário por linha, antes de salvá-la no formato .CSV em seu computador.",
            "btnBlankFile": "Arquivo de usuário em branco",
            "lblEditMultipleHeader": "Editar ou excluir vários usuários",
            "lblEditMultipleDetail": "Baixe esta planilha contendo suas informações de usuário atuais e modifique conforme necessário antes de salvá-la no formato .CSV em seu computador.",
            "btnCurrent": "Usuários atuais",
            "lblEditMultipleNotes": "NOTA: Para excluir usuários, altere seu status para Excluir. Removê-los da planilha não os excluirá do sistema. O upload em massa pode ser concluído mesmo que você não preencha todos os campos de um usuário.",
            "lblUpload": "Enviar",
            "btnCancel": "Cancelar",
            "errorUserExportFailed": "Não foi possível exportar um arquivo do usuário.",
            "msgUserExportSuccessful": "Exportação do usuário concluída com sucesso.",
            "msgTestBulkUploadFile": "Este arquivo só será validado porque o nome do arquivo contém a palavra \"teste\"; as atualizações não serão processadas.",
            "msgUploadSuccess": "Upload em massa bem-sucedido.",
            "msgExportSuccessful": "Exportação gerada com sucesso.",
            "errorExportFailed": "Não foi possível exportar o arquivo.",
            "msgValidatingFile": "Verificando arquivo ...",
            "errorInvalidBulkFileType": "O arquivo selecionado tem uma extensão de arquivo incorreta, ele deve ter uma extensão .CSV."
        }
    },
    "variableContentTag": {
        "insert": "Inserir",
        "companyName": "Nome da empresa",
        "address": "Endereço",
        "startDate": "Data de início",
        "initiatedByName": "Iniciados por nome",
        "resolvedDate": "Data de resolução",
        "resolvedByName": "Resolvidos por nome",
        "comments": "Comentários",
        "resolveReason": "Resolver o motivo",
        "resolveComments": "Resolver comentários",
        "insertButtonTooltip": "Selecione um valor na lista para adicionar o parâmetro à mensagem. Os valores reais serão substituídos quando a mensagem for enviada."
    },
    "bulkUploadHistory": {
        "dateTimeSubmitted": "Data Enviada",
        "totalCount": "Total",
        "validCount": "Válido",
        "errorCount": "Erros",
        "originalInputFileName": "Arquivo de entrada",
        "canonicalCsvFileName": "Arquivo de Relatório",
        "errorCsvFileName": "Arquivo de Erro",
        "title": "Histórico de upload em massa"
    },
    "home": {
        "accessDocuments": "Documentos de acesso",
        "gotoTraining": "Vá para o treinamento",
        "gotoResources": "Vá para Recursos",
        "incidentHistory": "Histórico de Incidentes",
        "manageCrisisTeam": "Gerenciar equipe de crise",
        "callInAnnouncement": "Anúncio de chamada",
        "conferenceCalls": "Teleconferência",
        "crisisTeam": "Equipe de Crise",
        "viewAllCrisisTeams": "Ver todas as equipes de crise",
        "cyber": "Cibernético",
        "documents": "Documentos",
        "emergencyContacts": "Contatos de emergência",
        "featuredCourses": "Cursos em destaque",
        "firstAid": "Primeiros socorros",
        "health": "saúde",
        "incidentManagement": "[[Incident Manager]]",
        "incidents": "incidentes",
        "learn": " Aprender",
        "naturalDisasters": "Desastres naturais",
        "newIncident": "Novo Incidente",
        "recentNews": "Notícias recentes",
        "resources": "Recursos",
        "respondToIncident": "Responder ao incidente",
        "sendEmergencyMessage": "Enviar mensagem",
        "splashMessage": "Mensagem Inicial",
        "terrorism": "Terrorismo",
        "training": "Treinamento",
        "workplace": "Ambiente de trabalho",
        "featureCoursesInstructions": "Faça nossos cursos de treinamento online para desenvolver seu conhecimento e preparação.",
        "incidentOptions": "Comunique-se efetivamente antes, durante e depois de um incidente",
        "incidentMgmtSummary": "Acesse informações críticas, ferramentas e equipes",
        "resourceList": "AMEAÇAS, LISTAS E VERIFICAÇÃO, TABLETOPS E MAIS",
        "resourceInstructions": "Navegue por nossas informações detalhadas para saber a melhor forma de se preparar e responder com eficácia e segurança.",
        "mainPhone": "Principal",
        "securityPhone": "Segurança",
        "emergencyPhone": "Chamada de Emergência",
        "announcementsPhone": "Anúncios",
        "teamMembers": "Membros da equipe de crise ({{count}})",
        "viewCrisisTeamPage": "Ver página da equipe",
        "iq": "iQ",
        "complianceCalendar": "Calendário de Conformidade",
        "plans": "[[Plans]]",
        "iqScore": "Pontuação iQ",
        "reports": "[[Reports]]",
        "featuredResources": "Recursos de funções",
        "announcement": "Anúncio",
        "noneOnRecord": "Nenhum registrado",
        "messaging": "Mensagens",
        "sinkSendMessageDescription": "Envie mensagens de emergência bidirecionais com voz, texto SMS, e-mail, notificações push e na área de trabalho.",
        "sinkAlertDescription": "Selecione os locais a serem colocados em status de [[alert]] com o Preparis.",
        "communicationsManagement": "Gestão de Comunicações",
        "communicationsManagementDescription": "Comunique-se efetivamente antes, durante e depois de um incidente",
        "resourcesDescription": "Navegue por nossas informações detalhadas para saber a melhor forma de se preparar e responder com eficácia e segurança",
        "viewResources": "Ver recursos",
        "resourcesSubtitle": "Ameaças, listas de verificação, tabletops e mais",
        "trainingDescription": "Faça nossos cursos de treinamento online para construir seu conhecimento e preparação",
        "moreInformation": "Mais Informações",
        "sendNotifications": "Enviar notificações",
        "msgLoadingWeatherData": "Carregando informações meteorológicas...",
        "viewDocuments": "Ver documentos",
        "viewHistory": "Ver histórico",
        "bcPlanningDescription": "Planeje, teste e esteja preparado para um incidente",
        "bcDashboardDescription": "Analise o progresso de suas equipes junto com os objetivos de tempo de recuperação selecionados",
        "goToDashboard": "Ir para a painel de controle",
        "bcPlannerDescription": "Vamos começar a planejar! Monte sua equipe de especialistas",
        "startPlanning": "Comece a planejar",
        "agilityPlanner": "Preparis Planner",
        "buildYourPlan": "Construa Seu [[Plans]]",
        "documentsDescription": "Armazene documentação importante em um local baseado em nuvem.",
        "bcIncidentManagement": "Reportar Novo Incidente",
        "bcIncidentManagementDescription": "Relate e gerencie rapidamente seu incidente",
        "bcIncidentHistoryDescription": "Ver histórico de incidentes atuais ou passados",
        "safetyPassTitle": "Passe de Segurança",
        "safetyPassDescriptions": "Pré-triagem digital de saúde, verificação de entrada nas instalações, monitoramento de dados de segurança e rastreamento de contatos próximos"
    },
    "documents": {
        "documents": "Documentos",
        "lblName": "Nome",
        "lblOwner": "Proprietário",
        "lblDateTimeModified": "Última modificação",
        "fileDetails": "Detalhes do Documento",
        "folderDetails": "Detalhes da pasta",
        "lblSelectDocumentForDetail": "Selecione um arquivo ou pasta para ver seus detalhes",
        "lblSelectDocumentForPermission": "Selecione uma pasta para ver suas permissões",
        "lblType": "Tipo",
        "lblCreatedBy": "Proprietário / Criado por",
        "lblCreatedOn": "Criado em",
        "lblLastModifiedBy": "Modificado por",
        "lblLastModifiedOn": "Modificado em",
        "lblSize": "Tamanho",
        "lblFolder": "Pasta",
        "lblDetails": "Detalhes",
        "lblPermissions": "Permissões",
        "lblIsPrivate": "Documento privado",
        "lblSetDocumentPrivate": "Definir documento como privado",
        "lblCurrentPermissions": "Permissões Atuais",
        "lblNewPermissions": "Novas Permissões",
        "msgIsPrivate": "Este documento é privado e só pode ser acessado pelo proprietário do documento e pelo administrador do documento",
        "lblPages": "Páginas",
        "lblInherited": "(Herdado)",
        "successRenameDocumentMessage": "As informações do documento são atualizadas",
        "successRenameFolderMessage": "As informações da pasta são atualizadas",
        "errorRenameDocumentMessage": "Falha ao atualizar as informações do documento",
        "errorRenameFolderMessage": "Falha ao atualizar as informações da pasta",
        "errorDocumentNameExist": "Já existe um documento com o mesmo nome, insira um nome de documento diferente.",
        "errorFolderNameExist": "Já existe uma pasta com o mesmo nome, insira um nome de pasta diferente.",
        "errorCheckDocumentStatus": "Não foi possível verificar o status do documento.",
        "errorCheckFolderStatus": "Não foi possível verificar o status da pasta.",
        "msgErrorDownloadFileNoContent": "O conteúdo do arquivo está ausente ou corrompido!",
        "successCreateFolderMessage": "Pasta{{ name }} criada",
        "errorCreateFolderMessage": "Falha ao criar pasta {{ name }}",
        "lblAreYouSureDeleteDocument": "Tem certeza de que deseja excluir ",
        "msgAccessDenied": "Você não tem permissão para excluir esta pasta",
        "msgCannotDeleteRoot": "A pasta raiz não pode ser excluída",
        "msgFolderHasContents": "Esta pasta não pode ser excluída porque não está vazia. Remova todas as pastas e documentos da pasta antes de excluí-la.",
        "successDeleteDocumentMessage": "Arquivo {{ name }} Foi apagado.",
        "successDeleteFolderMessage": "Pasta {{ name }} Foi apagado.",
        "errorDeleteDocumentMessage": "Falha ao excluir arquivo {{ name }}",
        "errorDeleteFolderMessage": "Falha ao excluir pasta {{ name }}",
        "successMoveFileMessage": "Arquivo{{ name1 }} movido para a pasta {{ name2 }}",
        "successMoveFolderMessage": "Pasta{{ name1 }} movida para a pasta {{ name2 }}",
        "errorMoveFileMessage": "Falha ao mover o arquivo{{ name1 }} para a pasta {{ name2 }}",
        "errorMoveFolderMessage": "Falha ao mover pasta{{ name1 }} sob a pasta {{ name2 }}",
        "noPermissionMoveFileMessage": "Você não pode mover este arquivo para a pasta {{ name1 }}",
        "noPermissionMoveFolderMessage": "Você não pode mover esta pasta para outra {{ name1 }}",
        "msgDocumentExists": "Já existe um documento com o mesmo nome na pasta selecionada, renomeie o documento ou selecione um documento diferente.",
        "msgFolderExists": "Já existe uma pasta com o mesmo nome na pasta selecionada, renomeie a pasta ou selecione uma pasta diferente.",
        "successUploadFileMessage": "Arquivo{{ name }} carregado",
        "errorUploadFileMessage": "Falha ao carregar o arquivo {{ name }}",
        "msgSameFolder": "Não é possível mover a pasta nela mesma",
        "msgCrisisTeamPage": "Permita o acesso ao documento de qualquer uma das seguintes páginas das equipes de crise.",
        "msgLoadingCrisisTeams": "Carregando equipes de crise",
        "msgNoCrisisTeam": "Sem equipe de crise",
        "successSetDocumentPrivateMsg": "Privacidade do documento atualizada com sucesso",
        "failSetDocumentPrivateMsg": "Falha ao atualizar a privacidade do documento",
        "successDeleteCrisisTeamMessage": "O documento foi removido da página da equipe de crise para {{ name }}",
        "errorDeleteCrisisTeamMessage": "Falha ao remover documento da página da equipe de crise para {{ name }}",
        "successAddCrisisTeamsMessage": "Equipes de crise adicionadas",
        "warningDocumentExist": "Já existe um anexo com o mesmo nome, gostaria de substituir o anexo existente ou salvar como um novo anexo?",
        "warningReplaceDocument": "Tem certeza de que deseja substituir o documento existente pelo documento selecionado?",
        "lblViewOnlyAccess": "Acesso apenas para visualização",
        "lblEditAccess": "Editar acesso",
        "lblCrisisTeamPages": "Páginas da equipe de crise",
        "errorAddCrisisTeamsMessage": "Falha ao adicionar equipes de crise",
        "successSetDocumentAccessGroupsMessage": "A permissão de acesso ao documento foi salva",
        "errorSetDocumentAccessGroupsMessage": "Falha ao salvar permissão de acesso ao documento",
        "successSetFolderAccessGroupsMessage": "A permissão de acesso à pasta foi salva",
        "errorSetFolderAccessGroupsMessage": "Falha ao salvar permissão de acesso à pasta",
        "lblCrisisTeams": "Equipes de crise",
        "lblConfirmDeleteCrisisTeam": "Tem certeza que deseja remover este documento {{ name }} da página da equipe de crise?",
        "lblConfirmPrivateDocument": "<p>Tem certeza de que deseja tornar este documento privado?</p><p> Um documento privado só pode ser acessado pelo proprietário e pelo usuário com função de administração de documentos.</p>",
        "lblConfirmNonPrivateDocument": "<p>Tem certeza de que deseja que esse documento herde as permissões de acesso da pasta que o contém?</p><p> Um documento não privado herdará grupos de acesso de sua pasta por padrão.</p>",
        "lblMoveDocumentTo": "Mover para",
        "lblNewFolder": "Criar nova pasta",
        "lblDocumentDescription": "Descrição do documento",
        "folderName": "Nome da pasta",
        "lblEveryoneHasAccess": "Você optou por dar a todos acesso de visualização a este arquivo",
        "lblViewAccessGroupsHeader": "Atualize as permissões APENAS VISUALIZAÇÃO para: {{ name }}",
        "lblEditAccessGroupsHeader": "Atualizar permissões de EDIÇÃO para: {{ name }}",
        "lblViewAccessGroupInformation": "Selecione qualquer categoria abaixo ou escolha apenas uma para definir quem pode ver o arquivo.",
        "lblEditAccessGroupInformation": "Selecione qualquer categoria abaixo ou escolha apenas uma para definir quem pode editar o arquivo.",
        "lblAccessFor": "Conceda acesso a usuários que estão em",
        "lblAccessGroupNone": "Sem usuários",
        "lblKeepPermissions": "Manter as permissões atuais",
        "lblUseNewPermissions": "Use novas permissões",
        "noDocuments": "Sem Documentos",
        "msgPermissionChanges": "A mensagem relacionada de permissão alterada vai aqui",
        "msgNoPermissionToMoveDocument": "Você não tem permissões suficientes para mover para esta pasta",
        "msgFolderItself": "Você não pode mover a pasta para ela mesma",
        "msgSameAsParentFolder": "Esta é a mesma pasta mãe",
        "msgKeepPermissionWarnings": "manter as permissões atuais pode causar conflitos com as novas permissões da pasta, o que pode tornar o documento ou a pasta inacessível aos usuários, exceto aos administradores de documentos.",
        "msgFolderPermissionWarnings": "Alterar as permissões desta pasta afetará as permissões herdadas de todos os arquivos na pasta, mas as permissões de todas as subpastas permanecerão as mesmas",
        "folderPath": "Caminho",
        "successUploadFilesMessage": "Os arquivos foram enviados com sucesso",
        "errorUploadFilesMessage": "Falha ao enviar arquivos",
        "errorDocumentNameExistInReplace": "Já existe um documento com o mesmo nome. Faça upload de um documento com um nome diferente.",
        "lblReplaceTitle": "Substituir documento",
        "fileType": {
            "unknown": "Desconhecido",
            "MP3": "Arquivo de áudio",
            "MP4": "Arquivo de áudio",
            "BMP": "Imagem em formato bitmap",
            "CSS": "Folha de estilo CSS",
            "CSV": "Documento Excel",
            "DOC": "Documento do Word",
            "DOCX": "Documento do Word",
            "HTM": "Arquivo Html",
            "HTML": "Arquivo Html",
            "HTMLS": "Arquivo Html",
            "ICO": "Imagem do ícone",
            "JPE": "imagem jpeg",
            "JPEG": "imagem jpeg",
            "JPG": "arquivo jpeg",
            "JS": "Arquivo Javascript",
            "PDF": "Arquivo Acrobat PDF",
            "PNG": "imagem png",
            "GIF": "imagem gif",
            "PPT": "Documento de PowerPoint",
            "RTF": "Arquivo de texto",
            "TEXT": "Documento de texto",
            "TIF": "Imagem Tif",
            "TIFF": "Imagem Tif",
            "TXT": "Documento de texto",
            "Unknown": "Desconhecido",
            "WAV": "Áudio Wave",
            "XLC": "Documento Excel",
            "XLM": "Documento Excel",
            "XLS": "Documento Excel",
            "XLSX": "Documento Excel",
            "XLT": "Documento Excel",
            "PPTX": "Documento PowerPoint",
            "XML": "Arquivo Xml",
            "ZIP": "Arquivos compactados",
            "RAR": "Arquivos compactados",
            "EXE": "Aplicativo"
        },
        "itemsCategories": {
            "image": "Imagem",
            "wordDocument": "Documento do Word",
            "spreadsheet": "Planilha",
            "document": "Documento",
            "folder": "Pasta",
            "message": "Mensagem",
            "audio": "Áudio",
            "text": "Texto"
        },
        "uploadNewDocument": "Carregar novo documento",
        "title": "Documentos",
        "searchPlaceholder": "Procure um documento",
        "addNew": "Carregar novo documento",
        "createFolder": "Nova pasta",
        "searchTitle": "Procurar Resultados",
        "or": "ou",
        "allUsers": "Todos os usuários",
        "searchResults": "Procurar Resultados",
        "lblPermissionsHeader": "Permissões de documentos"
    },
    "myPreparis": {
        "lblMyPreparis": "Meu Preparis",
        "lblMyDocuments": "Meus documentos",
        "lblMyBookmarks": "Meus favoritos",
        "lblDeleteBookmark": "Excluir favorito",
        "lblNavigatePage": "Navegue para a página",
        "lblAreYouSureDeleteBookmark": "Tem certeza que deseja remover o favorito{{ name }}?",
        "msgSuccessDeleteBookmark": "Favorito {{bookmark}} deletado",
        "msgErrorDeleteBookmark": "Falha ao excluir o favorito {{bookmark}}",
        "lblSelectBookmarkForDetail": "Selecione um favorito para ver seus detalhes",
        "lblBookmarkDetails": "Detalhes do favorito",
        "lblNote": "Nota",
        "bookmarkTypePage": "Página",
        "lblBookmarkOn": "Favorito salvo em",
        "msgSuccessUpdateBookmark": "Favorito {{ name }} atualizado",
        "msgErrorUpdateBookmark": "Falha ao atualizar o favorito {{ name }}"
    },
    "resources": {
        "resources": "Recursos",
        "threats": "Ameaças",
        "tabletops": "Tampos de mesa",
        "checklists": "Listas de verificação",
        "webinars": "Webinars",
        "more": "Mais",
        "featuredThreats": "Ameaças em destaque",
        "otherFeaturedResources": "Outros recursos apresentados",
        "byCategory": "Por categoria",
        "sort": "Ordenar",
        "searchCurrentSelections": "Pesquisar as seleções atuais",
        "featured": "Em destaque",
        "noResources": "Sem recursos",
        "supportingResources": "Recursos de Apoio",
        "relatedResources": "Recursos Relacionados",
        "newResources": "Novos Recursos",
        "webinarsDescription": "O Preparis hospeda regularmente sessões de webinar sobre as ameaças mais críticas a empresas e edifícios, criadas por uma equipe de especialistas no assunto em uma variedade de setores. Clique abaixo para ver algumas de nossas sessões mais populares e recentes:",
        "threatsDescriptionOverview": "Informações básicas sobre uma ampla gama de ameaças, incluindo sinais ou sintomas, métodos de preparação, etapas de resposta e diretrizes de recuperação.",
        "checklistsDescriptionOverview": "Listas de verificação baseadas em ações que cobrem uma variedade de ameaças e tópicos de políticas voltadas para ajudá-lo a construir seu programa de continuidade de negócios.",
        "tabletopsDescriptionOverview": "Teste seus [[plans]] e nível de preparação em um ambiente de grupo usando nossos exercícios de mesa.",
        "moreResourcesDescriptionOverview": "Saiba mais sobre sua função e protocolos e também encontre informações e ferramentas adicionais que podem ajudá-lo durante uma crise, como infográficos, modelos e planilhas de referência.",
        "webinarsDescriptionOverview": "Sessões de webinar sobre as ameaças mais críticas a empresas e edifícios, criadas por uma equipe de especialistas no assunto em uma variedade de setores.",
        "inTheSpotLight": "No centro das atenções"
    },
    "training": {
        "training": "Treinamento",
        "brandNewCourses": "Cursos novos",
        "featuredCourses": "Cursos em destaque",
        "noNewCourses": "Nenhum curso novo",
        "noFeaturedCourses": "Nenhum curso apresentado",
        "noTrainingCourses": "Nenhum curso de treinamento",
        "startedOn": "Iniciado em",
        "completedOn": "Concluído em",
        "resume": "Reiniciar",
        "retake": "Retomar",
        "launch": "Iniciar",
        "similarCourses": "Cursos semelhantes",
        "noSimilarCourses": "Nenhum curso semelhante",
        "courseHistory": "História do curso",
        "firstAccessDate": "Primeira data de acesso",
        "lastAccessDate": "Data do último acesso",
        "completedDate": "Data de conclusão",
        "errorLaunchingTrainingCourse": "Ocorreu um erro ao iniciar este curso de treinamento",
        "filter": "Filtro",
        "incomplete": "Incompleto",
        "completed": "Concluído",
        "inProgress": "Em andamento",
        "status": "Status",
        "completionCertificate": "Certificado de conclusão"
    },
    "portal": {
        "navigate": {
            "navigatePortal": "Navegar no portal",
            "changePortal": "Alterar portal",
            "msgSwitchPortalFailed": "Falha ao alternar para o portal {{ portalName }}.",
            "msgSwitchPortalSucceed": "Alternado para o portal {{ portalName }} com sucesso",
            "msgErrorRetrievingPortalList": "Falha ao recuperar uma lista de portais.",
            "portalName": "Nome do portal...",
            "searchResults": "Apenas os primeiros 50 registros são exibidos"
        },
        "modules": {
            "errorSavePortalModules": "Falha ao atualizar os módulos do portal.",
            "msgSavePortalSettingsSuccess": "As configurações do portal foram salvas.",
            "lblModulesSetup": "Configuração de módulos",
            "lblAlwaysAllowed": "Sempre permitido",
            "lblAllowed": "Permitido",
            "lblNotAllowed": "Não permitido"
        },
        "portal": "Portal",
        "portals": "Portais",
        "newPortal": "Novo portal",
        "name": "Nome",
        "parentName": "Nome dos pais",
        "parentPortalName": "Nome do portal do pai",
        "verticalType": "Tipo vertical",
        "portalSubdomain": "Subdomínio do portal",
        "portalName": "Nome do portal",
        "numberOfUsersAssignedTo": "Nº de usuários atribuídos a",
        "callInNumberSetup": "Configuração do número de chamada",
        "childPortal": "Portal filho",
        "modulesSetup": "Configuração de módulos",
        "portalsFilterPlaceHolder": "Filtre os resultados por: Nome ou pai",
        "errorLoadingPortals": "Erro ao carregar a lista de portais",
        "createPortal": "Criar portal",
        "createChildPortal": "Criar portal filho",
        "savePortalSuccess": "O portal foi salvo com sucesso",
        "savePortalError": "As alterações feitas no portal não foram salvas",
        "industry": "Setor",
        "callInNumber": {
            "errorRegisteringNewNumber": "Ocorreu um problema ao registrar seu novo número.",
            "successRegisteringNewNumber": "Sucesso ao registrar o novo número.",
            "errorDeregisteringExistingNumber": "Ocorreu um problema ao cancelar o registro do seu número antigo.",
            "phoneNumberReleaseSuccess": "{{ currentPhoneNumber }} foi lançado.",
            "modalTitle": "Configuração de anúncios em chamada",
            "newTelephoneNumberInstructions": "Configure um novo número de telefone de anúncios em chamada.",
            "editTelephoneNumberInstructions": "Altere o número de telefone de anúncios em chamada.",
            "announcementsNumber": "Número de anúncios",
            "loading": "Carregando",
            "releaseNumber": "Número de liberação",
            "noNumberAssigned": "Nenhum número atribuído",
            "availableNumbers": "Números disponíveis",
            "chooseFromAvailableNumbers": "Escolha um dos seguintes números de telefone:",
            "saveAndUpdate": "Salvar e atualizar",
            "current": "Atual",
            "new": "Novo",
            "selectAvailableNumber": "Selecione um número disponível abaixo",
            "containsNumber": "Contém",
            "country": "País",
            "areaCode": "Código de área",
            "numberType": "Tipo de número",
            "local": "Local",
            "tollFree": "Grátis",
            "phoneNumberContains": "Insira os dígitos (ou caracteres) desejados",
            "search": "Pesquisar",
            "saveSuccessMsg": "Novo número em chamada salvo para {{ portalName }}",
            "configureNewNumber": "Configurar novo número"
        },
        "portalSetting": {
            "portalSettings": "Configurações do portal",
            "filterSettingsPlaceHolder": "Filtrar por nome de configuração...",
            "settingName": "Nome",
            "settingValue": "Valor",
            "portalSpecific": "Específico do portal",
            "showAllSettings": "Mostrar todas as configurações",
            "saveSuccess": "As configurações do portal foram salvas.",
            "saveError": "Falha ao atualizar as configurações do portal."
        },
        "portalIndustry": "Setor",
        "lblSelectIndustry": "Selecione um setor",
        "msgFailSaveIndustry": "Falha ao salvar o setor do portal"
    },
    "scheduledMessages": {
        "scheduledMessages": "Mensagens Agendadas",
        "searchScheduledMessagesPlaceholder": "Pesquisar mensagens agendadas",
        "newScheduledMessage": "Nova mensagem agendada",
        "dateCreated": "Data Criada",
        "scheduledDate": "Data marcada",
        "scheduledTime": "Horário marcado",
        "timeZone": "Fuso horário",
        "createdBy": "Criado por",
        "errorLoadingScheduledMessagesMsg": "Erro ao carregar mensagens agendadas.",
        "deleteScheduledMessageTooltip": "Excluir mensagem agendada",
        "confirmDelete": {
            "header": "Remover mensagem agendada",
            "body": "Tem certeza de que deseja remover esta mensagem agendada?",
            "text": "Remover"
        },
        "noScheduledMessages": "Nenhuma mensagem agendada"
    },
    "manage": {
        "nav": {
            "lblOverview": "Visão geral",
            "lblManage": "Gerenciar",
            "lblSetup": "Configuração",
            "lblMessaging": "Mensagens",
            "lblSystem": "Sistema",
            "lblRoles": "Funções",
            "lblUsers": "Usuários",
            "lblLocations": "[[Locations]]",
            "lblDepartments": "[[Departments]]",
            "lblGroups": "Grupos",
            "lblTenants": "Inquilinos",
            "lblGeofences": "Cercas virtuais",
            "lblPOIs": "Ponto de interesse",
            "lblMessageSettings": "Configurações de mensagens",
            "lblUserInitiatedIncident": "Incidentes iniciados pelo usuário",
            "lblIncidentTypes": "Tipos de Incidentes",
            "lblAlertSubscription": "Assinaturas de [[alert]]",
            "lblIncidentTriggers": "Gatilhos de incidentes",
            "lblPanicConfiguration": "Configuração de pânico",
            "lblMobileEmergencyButtons": "Configurações de [[alert]] iniciado por celular",
            "lblCompanyProfile": "Perfil da empresa",
            "lblSecurity": "Segurança",
            "lblSystemEmails": "E-mails do sistema",
            "lblCustomFields": "Campos personalizados",
            "lblBranding": "Personalização",
            "lblSelfRegistration": "Adesão",
            "lblConfigureBulkUpload": "Configurar upload em massa"
        },
        "manageEntitySidebar": {
            "lblNA": "N / D",
            "lblCreatedOn": "Criado em",
            "lblCreatedBy": "Criado por",
            "lblLastModifiedOn": "Última modificação em",
            "lblLastModifiedBy": "Última modificação por",
            "lblUserCount": "Nº de usuários"
        },
        "manageMembers": {
            "manageMembers": "Gerenciar membros",
            "lblManageMembersHeader": "Gerenciar membros para {{entityTypeName}}",
            "lblManageMembersDirections": "Selecione o(s) usuário(s) e use as setas para a esquerda e para a direita para configurar quem faz parte deste {{entityTypeName}}.",
            "btnSaveAndUpdate": "Salvar e atualizar",
            "updateMembersSuccess": "Membros atualizados com sucesso",
            "usersNotInEntity": "Usuários que não estão em {{entityTypeName}}",
            "currentEntityMembers": "Membros atuais em {{entityTypeName}}"
        },
        "bulkUpload": {
            "lblBulkUpload": "Upload em massa",
            "lblBulkUploadUsers": "Upload de usuários em massa",
            "msgBulkUploadInstructions": "Tem vários {{entityTypeName}} que você deseja adicionar ou modificar? Escolha um arquivo formatado corretamente em seu computador para iniciar o [[process]].",
            "msgDropFile": "Solte o arquivo aqui ou clique para selecionar um arquivo",
            "lblSelectedFile": "Arquivo selecionado para upload",
            "lblNoFileSelected": "Nenhum arquivo especificado.",
            "lblNeedStartingPoint": "Precisa de um ponto de partida",
            "lblAddMultipleUsersHeader": "Adicionar vários novos usuários",
            "lblAddMultipleUsersDetail": "Baixe esta planilha contendo apenas as informações do cabeçalho e preencha as informações do novo usuário, um usuário por linha, antes de salvá-la no formato .CSV em seu computador.",
            "btnBlankUserFile": "Arquivo de usuário em branco",
            "lblUpload": "Enviar",
            "btnCancel": "Cancelar",
            "errorUserExportFailed": "Não foi possível exportar um arquivo do usuário.",
            "msgUserExportSuccessful": "Exportação do usuário concluída com sucesso.",
            "msgTestBulkUploadFile": "Este arquivo só será validado porque o nome do arquivo contém a palavra \"teste\"; as atualizações não serão processadas.",
            "msgUploadUsersSuccess": "Upload em massa bem-sucedido.",
            "msgValidatingFile": "Verificando arquivo ...",
            "errorInvalidBulkFileType": "O arquivo selecionado tem uma extensão de arquivo incorreta, ele deve ter uma extensão .CSV.",
            "btnBlankEntitiesFile": "Arquivo {{entityTypeName}} em branco",
            "lblEditMultipleEntitiesDetail": "Baixe esta planilha contendo suas informações atuais de localização e modifique conforme necessário antes de salvá-la no formato .CSV em seu computador.",
            "lblEditMultipleEntitiesHeader": "Editar ou excluir vários {{entityTypeName}}",
            "lblEditMultipleEntitiesNotes": "NOTA: Para excluir {{entityTypeName}}s, altere seu status para Excluir. Removê-los da planilha não os excluirá do sistema. O upload em massa pode ser concluído mesmo se você não preencher todos os campos de um {{entityTypeName}}. Para ver quais campos são opcionais, consulte o guia do usuário ou a área de ajuda no canto superior direito desta janela.",
            "btnCurrentEntities": "{{entityTypeName}}s atuais",
            "lblAddMultipleEntitiesHeader": "Adicionando vários {{entityTypeName}} novos",
            "lblAddMultipleEntitiesDetail": "Baixe esta planilha contendo apenas informações de cabeçalho e preencha as informações do seu novo {{entityTypeName}}, um {{entityTypeName}} por linha, antes de salvá-lo no formato .CSV no computador.",
            "msgExportSuccessful": "Exportação concluída com sucesso.",
            "errorExportFailed": "Não foi possível exportar um arquivo do usuário.",
            "fileName": "Bulk{{entityTypeName}}Upload.csv"
        },
        "roles": {
            "btnCreateRole": "Nova função",
            "btnSaveRole": "Salvar função",
            "errorCannotDeleteWithUsers": "Esta função não pode ser excluída porque existem usuários com esta função.",
            "errorCannotDeleteDefaultForBulkUpload": "Esta função não pode ser excluída porque está definida como função padrão para Upload em massa.",
            "errorDeleteRoleFailed": "Ocorreu um erro ao excluir a função.",
            "errorRequiredFields": "Por favor, preencha todos os campos obrigatórios.",
            "errorSaveRoleFailed": "Ocorreu um erro ao salvar a função.",
            "errorUnableToLoadRole": "Não foi possível carregar os detalhes da função. Tente novamente mais tarde.",
            "lblAccessReports": "[[Reports]] de acesso",
            "lblAccessReportsDesc": "Permitir que os usuários executem e exportem [[reports]].",
            "lblAddUploadDocs": "Adicionar/fazer upload de documentos",
            "lblAddUploadOnly": "Adicionar/fazer upload apenas",
            "lblAddUploadOnlyDesc": "Permita que os usuários SOMENTE adicionem/carreguem novos documentos e atribua permissões a esses documentos.",
            "lblAllowed": "Permitido",
            "lblAlwaysAllowed": "Sempre permitido",
            "lblAreYouSureRemoveRole": "Tem certeza de que deseja remover esta função?",
            "associatedRole": "Função associada",
            "lblBasedOnRole": "Com base na função",
            "lblReportIncident": "Reportar incidente",
            "lblReportIncidentDesc": "Permitir que o usuário relate incidentes do aplicativo móvel",
            "lblPanicDesc": "Permitir que o usuário relate incidentes de pânico do aplicativo móvel",
            "lblManageMessages": "Gerenciar mensagens",
            "lblManageMessagesDesc": "Permitir que o usuário edite, mova, exclua mensagens e configure permissões de pasta",
            "lblCommunication": "Comunicação",
            "lblSendMessageDesc": "Permite que os usuários enviem mensagens.",
            "lblConfigureMessageDesc": "Permite que os usuários criem e atualizem a mensagem, mas não permitir o envio da mensagem.",
            "lblConferenceCallDesc": "Permite que os usuários criem chamadas de conferência.",
            "lblCallInAnnouncementDesc": "Permite que os usuários criem anúncio em chamada.",
            "lblSplashMessageDesc": "Permite que os usuários criem uma mensagem inicial.",
            "lblCompanySettings": "Configurações da empresa",
            "lblCompanySettingsDesc": "Gerenciar configurações que se aplicam a toda a empresa",
            "lblCompanySettingsExplain": "Permite que os usuários alterem o nome e o e-mail da empresa, o número de exibição de voz, a marca, as configurações de segurança e os e-mails do sistema.",
            "lblCorePerms": "Permissões principais (não editáveis)",
            "lblCrisisTeamContacts": "Contatos da equipe de crise",
            "lblDocumentMgmt": "Administração de documentos",
            "lblDocumentMgmtDesc": "Permite que os usuários gerenciem globalmente todos os documentos do portal, incluindo a capacidade de adicionar, modificar e excluir qualquer documento, bem como criar, modificar e excluir pastas.",
            "lblDocumentMgmtiQDisclaimer": "A permissão do iQ é necessária para acessar pastas e documentos relacionados ao iQ no portal.",
            "lblDocuments": "Documentos",
            "lblEditEntities": "Editar entidades",
            "lblEntityMgmt": "Gestão de entidades",
            "lblEntityMgmtExplain": "Permite que os usuários criem/modifiquem/excluam locais, grupos e [[Departments]].",
            "lblHelp": "Ajuda",
            "lblIncidentMgmt": "Gestão de incidentes",
            "lblIncidentMgmtDesc": "Permitir que os usuários criem/modifiquem/resolvam incidentes.",
            "lblIncludeCrisisDescription": "Exiba as informações de contato dos usuários com esta função na página Equipe de crise de cada local.",
            "lblIncludeCrisisTeamPage": "Incluir na página da equipe de crise",
            "lblManagePlansDesc": "Allow users to view, activate, deactivate, standby, or stand down department plans",
            "lblViewPlansDesc": "Provide access to users to view available department plans and processes",
            "lblListItems": "itens",
            "lblLocalEmergencyContact": "Contato de emergência local",
            "lblLoginAccess": "Acesso de login",
            "lblManage": "GERENCIAR",
            "lblManageDocuments": "Gerenciar Documentos",
            "lblManagePortal": "Gerenciar portal",
            "lblManageUsers": "Gerenciar usuários",
            "lblMngIncidents": "Gerenciar incidentes",
            "lblAlerts": "[[Alerts]]",
            "lblIncidentManagement": "Gerente de incidentes",
            "lblManagePlans": "Gestão do [[plan]]",
            "lblViewPlans": "Somente visualização",
            "lblLiteMessaging": "Mensagens Lite",
            "lblMsgSettings": "Configurações de mensagem",
            "lblMsgSetup": "Configuração de mensagens",
            "lblMsgSetupDesc": "Permite aos usuários criar mensagens salvas, definir padrões de mensagem e escalonamentos.",
            "lblMyPreparis": "Meu Preparis",
            "lblMyProfile": "Meu perfil",
            "lblNamePlaceholder": "Insira um nome para esta nova função",
            "lblNeverAllowed": "Nunca permitido",
            "lblNewRoleTitle": "Nova função personalizada",
            "lblNone": "Nenhum",
            "lblNotAllowed": "Não permitido",
            "lblNumbersDisplayed": "de",
            "permissionAndFeatures": "Permissões e recursos",
            "lblReports": "[[Reports]]",
            "lblResources": "Recursos",
            "lblRoleFeaturesPermissions": "Recursos e permissões da função",
            "lblRoleList": "Funções",
            "roleName": "Nome da função",
            "lblSearch": "Pesquisar",
            "lblSelectRoleType": "- Selecionar função -",
            "lblSendMessages": "Enviar mensagens",
            "lblSendMessagesDesc": "Permitir que os usuários enviem mensagens de emergência",
            "lblConfigureMessages": "Configurar mensagens",
            "lblConferenceCalls": "Criar chamadas de conferência",
            "lblCallInAnnouncements": "Criar anúncios em chamada",
            "lblSplashMessages": "Criar mensagens iniciais",
            "lblUserInitiateIncident": "Incidente iniciado pelo usuário",
            "lblPanic": "Pânico iniciado pelo usuário",
            "lblStatus": "Status",
            "lblTraining": "Treinamento",
            "lblTrainingClasses": "Aulas de treinamento",
            "lblTrainingClassesDesc": "Permite que os usuários visualizem e participem de aulas de treinamento.",
            "lblType": "Tipo",
            "lblUserCannotLogin": "Os usuários não podem fazer login.",
            "userCount": "Nº de usuários",
            "lblUserMgmtDesc": "Gerenciamento de usuários",
            "lblUserMgmtExplain": "Permite que os usuários criem/modifiquem/excluam usuários e funções.",
            "lblRoleMgmtDesc": "Gerenciamento de funções",
            "lblRoleMgmtExplain": "Permitir que os usuários criem/modifiquem/excluam funções.",
            "lblUserReceivesMessages": "Os usuários só podem receber mensagens.",
            "lblViewEditDocs": "Ver e editar documentos",
            "lblViewEditDocsDesc": "Nota: Acesso individual a documentos com base nos direitos atribuídos a cada documento individual",
            "lblViewReports": "Ver [[reports]]",
            "lblViewTraining": "Ver treinamento",
            "viewPermissions": "Ver permissões e recursos",
            "lbliQ": "iQ",
            "lbliQAdmin": "Administração do iQ",
            "lbliQAdminDesc": "Permitir que os usuários gerenciem todos os escopos, equipes, membros da equipe, recursos da equipe e recursos de administração no iQ",
            "lbliQManagement": "Gerenciamento do iQ",
            "lbliQManagementDesc": "Permitir que os usuários visualizem todos os escopos, gerenciem todas as equipes, membros da equipe e recursos da equipe sem recursos de administração no iQ",
            "lbliQTeamAccess": "Acesso à equipe do iQ",
            "lbliQTeamAccessDesc": "Permitir que os usuários gerenciem membros e recursos da equipe para as equipes associadas no iQ",
            "lbliQAssignmentAccess": "Acesso de atribuição iQ",
            "lbliQAssignmentAccessDesc": "Permitir que os usuários gerenciem os recursos da equipe atribuídos apenas no iQ",
            "lblBusinessContinuity": "Continuidade de negócios",
            "lblContinuity": "Continuidade",
            "lblBusinessContinuityManagement": "Gerenciamento de continuidade de negócios",
            "lblBusinessContinuityManagementDesc": "Permitir que os usuários iniciem uma avaliação de continuidade de negócios e designem líderes de equipe",
            "lblBusinessContinuityAudit": "Somente visualização de continuidade de negócios",
            "lblBusinessContinuityAuditDesc": "Permitir que os usuários vejam todas as informações do [[plan]] de continuidade de negócios sem fazer alterações",
            "msgDeleteRoleSuccess": "A função foi excluída com sucesso.",
            "msgSaveRoleSuccess": "A função{{roleBundleName}} foi salva com sucesso.",
            "phSearchRoles": "Filtre os resultados por: Nome da função",
            "msgErrorUpdateiQRole": "Não é possível alterar a permissão do iQ porque os usuários com essa função são responsáveis pelas equipes do iQ",
            "msgErrorRemoveiQRole": "Não é possível remover a permissão do iQ porque os usuários com essa função têm atribuições do iQ",
            "roles": "Funções",
            "lblRoleName": "Nome da função",
            "msgErrorDuplicateRoleName": "Já existe uma função com este nome",
            "role": "Função",
            "removeRole": "Remover função",
            "lblRecoverProfiles": "Perfis de recuperação",
            "lblViewRecoverProfiles": "Ver perfis de recuperação",
            "lblViewRecoverProfilesDesc": "Permitir que os usuários vejam perfis de recuperação",
            "lblEditRecoveryProfiles": "Editar perfis de recuperação",
            "lblEditRecoveryProfilesDesc": "Permitir que os usuários editem perfis de recuperação",
            "lblEditRecoveryProfileContacts": "Editar contatos do perfil de recuperação",
            "lblEditRecoveryProfileContactsDesc": "Permitir que os usuários editem contatos do perfil de recuperação",
            "lblEditRecoveryProfileGenerators": "Editar geradores de perfil de recuperação",
            "lblEditRecoveryProfileGeneratorsDesc": "Permitir que os usuários editem geradores de perfil de recuperação",
            "lblEditRecoveryProfileSites": "Editar sites de perfil de recuperação",
            "lblEditRecoveryProfileSitesDesc": "Permitir que os usuários editem sites de perfis de recuperação"
        },
        "locations": {
            "btnLocationCreate": "Adicionar novo local",
            "btnMultiLocationCreate": "Upload em massa",
            "btnSaveLocation": "Salvar local",
            "btnViewAll": "ViewAll",
            "duplicateLocationName": "Já existe um local com o mesmo nome.",
            "errorLocationReferenced": "O local é referido como local principal pelos usuários",
            "errorRemoveLocationFailed": "Ocorreu um erro ao remover o local.",
            "errorSaveLocationFailed": "Ocorreu um erro ao salvar o local.",
            "lblAddressTitle": "Endereço físico",
            "lblAreYouSureRemoveLocation": "Tem certeza de que deseja remover este local?",
            "lblEmergencyPhone": "Número de chamada de emergência",
            "lblFax": "Número de fax",
            "lblListItems": "itens",
            "lblLocationActivity": "Atividade de localização",
            "lblLocationInfo": "Informação de localização",
            "lblLocationListTitle": "[[Locations]]",
            "lblLocationMembers": "Usuários associados a este local",
            "lblLocationName": "Nome do local",
            "lblMainPhone": "Número de telefone principal",
            "lblNewLocation": "Novo local",
            "lblNumbersDisplayed": "de",
            "lblSecurityPhone": "Número de segurança",
            "lblUserCount": "Nº de usuários",
            "lblConfirmUpdateGeofence": "Atualizar cerca virtual",
            "msgUpdateGeofence": "O endereço do local mudou. Deseja atualizar a cerca virtual deste local?",
            "menuSortByName": "Nome",
            "msgBulkUploadSubmitted": "O upload em massa foi enviado para processamento.",
            "msgRemoveLocationSuccess": "O local foi removido com sucesso.",
            "msgSaveLocationSuccess": "O local {{entityName}} foi salvo com sucesso.",
            "phSearchLocations": "Filtre os resultados por: Nome, endereço, cidade ou estado",
            "name": "Nome",
            "address": "Endereço",
            "city": "City",
            "stateProvinceRegion": "Estado/Província/Região",
            "country": "País",
            "lblAddress2": "Endereço 2 (conjunto, edifício, etc)",
            "lblState": "Estado/província/região",
            "lblZip": "Código postal",
            "email": "E-mail",
            "status": "Status",
            "editGeofence": "Editar cerca virtual",
            "createGeofence": "Criar cerca virtual",
            "deleteLocation": "Remover local",
            "saveAndFinish": "Salvar e terminar",
            "removeLocation": "Remover local",
            "lblAvailableSeats": "Assentos Disponíveis",
            "lblNotes": "Notas",
            "lblEmergencyReadiness": "Prontidão de Emergência",
            "lblLocationType": "Tipo de Localização",
            "selectEmergencyReadiness": "Selecione uma Prontidão de Emergência",
            "selectLocationType": "Selecione um Tipo de Local"
        },
        "groups": {
            "bulkUpload": "Upload em massa",
            "btnGroupCreate": "Adicionar novo grupo",
            "btnSaveGroup": "Salvar grupo",
            "btnSaveGroupAddMember": "Salvar e adicionar membros",
            "duplicateGroupName": "Já existe um grupo com o mesmo nome.",
            "errorRemoveGroupFailed": "Ocorreu um erro ao remover o grupo.",
            "errorRequiredFields": "Por favor, preencha todos os campos obrigatórios.",
            "errorSavGroupFailed": "Ocorreu um erro ao salvar o grupo.",
            "lblAreYouSureDeleteGroup": "Tem certeza de que deseja excluir este grupo?",
            "lblGroupActivity": "Atividade do grupo",
            "lblGroupMembers": "Membros do grupo",
            "lblGroupName": "Nome do grupo",
            "lblListItems": "itens",
            "lblNewGroup": "Novo grupo",
            "lblNumbersDisplayed": "de",
            "lblType": "Tipo de grupo",
            "lblUserCount": "Nº de usuários",
            "menuSortByName": "Nome",
            "msgBulkUploadSubmitted": "O upload em massa foi enviado para processamento.",
            "msgRemoveGroupSuccess": "O grupo foi removido com sucesso.",
            "msgSaveGroupSuccess": "O grupo {{entityName}} foi salvo com sucesso.",
            "phSearchGroups": "Filtre os resultados por: Nome ou descrição",
            "msgGroupInUsedByUserInitiatedMessages": "Este grupo está atualmente em uso para incidentes iniciados pelo usuário. Desassocie-se antes de excluir este grupo.",
            "groups": "Grupos",
            "name": "Nome",
            "description": "Descrição",
            "group": "Grupo",
            "groupType": {
                "groupType": "Tipo de grupo",
                "1": "Continuidade de negócios",
                "2": "Gerenciamento de incidentes corporativos",
                "3": "Gerenciamento de incidentes locais",
                "4": "Recuperação de desastres",
                "Other": "Outros",
                "5": "Outros"
            },
            "saveAndFinish": "Salvar e terminar",
            "deleteGroup": "Excluir grupo",
            "pleaseSelect": "Selecione"
        },
        "departments": {
            "btnMultiDepartmentCreate": "Upload em massa",
            "btnDepartmentCreate": "Adicionar novo [[Departments]]",
            "btnSaveDepartment": "Salvar [[Departments]]",
            "btnSaveDepartmentAddMember": "Salvar e adicionar membro",
            "duplicateDepartmentName": "Já existe um [[Departments]] com o mesmo nome.",
            "errorRemoveDepartmentFailed": "Ocorreu um erro ao excluir o [[Departments]].",
            "errorRequiredFields": "Por favor, preencha todos os campos obrigatórios.",
            "errorSavDepartmentFailed": "Ocorreu um erro ao salvar o [[Departments]].",
            "lblAreYouSureDeleteDepartment": "Tem certeza de que deseja excluir este [[Departments]]?",
            "lblDepartmentActivity": "Atividade do [[Departments]]",
            "lblDepartmentListTitle": "[[Departments]]",
            "lblDepartmentMembers": "Membros do [[Departments]]",
            "lblDepartmentName": "Nome do [[Departments]]",
            "lblDepartmentTitle": "Informação do [[Departments]]",
            "lblListItems": "itens",
            "lblNewDepartment": "Novo [[Departments]]",
            "lblNumbersDisplayed": "de",
            "lblUserCount": "Nº de usuários",
            "menuSortByName": "Nome",
            "msgBulkUploadSubmitted": "O upload em massa foi enviado para processamento.",
            "msgRemoveDepartmentSuccess": "O [[Departments]] foi removido com sucesso.",
            "msgSaveDepartmentSuccess": "O [[Departments]] {{entityName}} foi salvo com sucesso.",
            "phSearchDepartments": "Filtre os resultados por: Nome ou descrição",
            "departments": "[[Departments]]",
            "department": "[[Departments]]",
            "name": "Nome",
            "description": "Descrição",
            "deleteDepartment": "Excluir [[Departments]]",
            "unassociated": "Não associado",
            "renameAndAssociateDepartmentToFAConfirmation": {
                "header": "Renomear e associar",
                "body": "Tem certeza de que deseja renomear o [[Planner]] [[Department]] e criar a associação?"
            },
            "lblBCPortalFunctionalAreaAssociated": "Associado [[Planner]] [[Department]]",
            "createNewPortalFunctionalAreaTooltip": "Criar novo [[Planner]] [[Department]] e associar a este [[department]]",
            "createNewPlannerFunctionalArea": "Criar novo departamento de planejamento"
        },
        "tenants": {
            "btnAddBuilding": "Adicionar edifício",
            "btnAddTenant": "Adicionar novo inquilino",
            "btnMultiTenantCreate": "Upload em massa",
            "btnSaveTenantAddBuilding": "Salvar e adicionar edifício",
            "duplicateTenantName": "Já existe um inquilino com o mesmo nome",
            "errorRemoveTenantFailed": "Ocorreu um erro ao excluir o inquilino.",
            "errorSaveTenantFailed": "Ocorreu um erro ao salvar o inquilino.",
            "lblAddBuilding": "Adicionar edifício",
            "lblBuilding": "Edifício",
            "lblBuildings": "Edifícios",
            "lblDeleteTenant": "Excluir inquilino",
            "lblDeleteTenantModalMessage": "Tem certeza de que deseja excluir este inquilino?",
            "lblFloor": "Andar(es)",
            "lblNewTenant": "Novo inquilino",
            "lblRemoveBuilding": "Remover edifício",
            "lblRemoveBuildingModalMessage": "Tem certeza de que deseja remover este edifício?",
            "lblSelectBuilding": "Selecionar edifício",
            "lblTenantName": "Nome do inquilino",
            "msgBulkUploadSubmitted": "O upload em massa foi enviado para processamento.",
            "msgRemoveBuildingFailed": "Erro ao remover o edifício",
            "msgRemoveBuildingSuccess": "Edifício removido com sucesso",
            "msgRemoveTenantSuccess": "Inquilino removido com sucesso",
            "msgSaveTenantSuccess": "O inquilino {{entityName}} foi salvo com sucesso.",
            "msgUserAssociatedWithThisBuilding": "Este edifício não pode ser removido porque há usuários associados a este edifício",
            "phSearchTenants": "Filtre os resultados por: Nome ou descrição",
            "lblMainPhoneNumber": "Número de telefone principal",
            "lblEditBuilding": "Editar edifício",
            "errorUsersAssociation": "Existem usuários associados a este inquilino. Não é possível excluir este inquilino no momento.",
            "errorBuildingAssociation": "Existem edifícios associados a este inquilino. Não é possível excluir este inquilino no momento.",
            "tenants": "Inquilinos",
            "name": "Nome",
            "description": "Descrição",
            "tenantMembers": "Membros inquilinos",
            "tenantActivity": "Atividade do inquilino",
            "tenant": "Inquilino"
        },
        "geofences": {
            "geofences": "Cercas virtuais",
            "btnGeofenceCreate": "Criar nova cerca virtual",
            "name": "Nome",
            "type": "Tipo",
            "location": "Localização",
            "circle": "Círculo",
            "rectangle": "Retângulo",
            "polygon": "Polígono"
        },
        "overview": {
            "btnNew": "Novo",
            "btnViewAll": "ver tudo",
            "lblCompanyProfile": "Perfil da empresa",
            "lblManage": "Gerenciar",
            "lblMessagingSettings": "Configurações de mensagens",
            "lblMessagingSetup": "Configuração de mensagens",
            "lblSavedMessages": "Mensagens salvas",
            "lblSecurity": "Segurança",
            "lblStatus": "Status",
            "lblSystemEmails": "E-mails do sistema",
            "lblSystemSettings": "Configurações do sistema",
            "lblTotal": "Total",
            "lblUserCount": "Nº de usuários",
            "txtMessagingSetupDescription": "As configurações para o comportamento das mensagens, bem como as mensagens salvas que podem ser reutilizadas, são acessadas aqui.",
            "txtSystemSettings": "As configurações contêm a funcionalidade do sistema central que se aplica a todo o portal.",
            "lblCustomFields": "Campos personalizados",
            "lblBranding": "Personalização",
            "lblUserDeleted": "Excluído",
            "lblUserInactive": "Inativo",
            "lblUserLocked": "Bloqueado",
            "lblUserPending": "Pendente",
            "lblUserActive": "Ativo"
        },
        "POI": {
            "pointsOfInterest": "Pontos de interesse",
            "name": "Nome",
            "latitude": "Latitude",
            "longitude": "Longitude",
            "address": "Endereço",
            "btnCreatePOI": "Criar novo ponto de interesse",
            "remove": "Remover ponto de interesse",
            "confirmRemove": "Tem certeza de que deseja remover este ponto de interesse?",
            "msgRemovePOISuccess": "O ponto de interesse {{ name }} foi removido com sucesso.",
            "msgRemovePOIFailed": "Falha ao remover o ponto de interesse {{ name }}",
            "noPOIError": "Nenhum ponto de interesse ativo. Crie um ponto de interesse para salvar.",
            "msgSavePOISuccess": "O ponto de interesse {{ name }} foi salvo com sucesso.",
            "msgSavePOIFailed": "Falha ao salvar o ponto de interesse {{ name }}",
            "createPOI": "Criar um ponto de interesse a partir deste local",
            "new": "Novo ponto de interesse"
        },
        "companyProfile": {
            "btnSaveCompany": "Salvar empresa",
            "duplicateCompanyName": "Já existe uma empresa com o mesmo nome.",
            "errorRequiredFields": "Por favor, preencha todos os campos obrigatórios.",
            "errorSavCompanyFailed": "Ocorreu um erro ao salvar a empresa.",
            "errorSavCompanyLogoFailed": "Falha ao salvar o logotipo da empresa.",
            "lblChangeLogo": "Alterar logotipo",
            "lblCompanyActivity": "Atividade da empresa",
            "lblCompanymentEmailFromAddress": "E-mail - Endereço de origem",
            "lblCompanymentEmailFromName": "E-mail - Nome de origem",
            "lblCompanymentVoiceNumber": "Exibição do número de voz",
            "lblCompanyName": "Nome da empresa",
            "lblCompanyTitle": "Perfil da empresa",
            "lblIndustry": "Setor",
            "msgSaveCompanyLogoSuccess": "O logotipo da empresa foi salvo com sucesso.",
            "msgSaveCompanySuccess": "A empresa {{entityName}} foi salva com sucesso.",
            "msgSaveCompanySuccessSSOFailed": "A empresa {{entityName}} foi salva, mas as configurações de SSO não foram salvas.",
            "msgSaveCompanySuccessAPIFailed": "A empresa {{entityName}} foi salva, mas não foi possível salvar as configurações de integração de terceiros.",
            "lblCompanySSoSettingIdpUrl": "URL LDP",
            "lblCompanySSoSettingIsIdpMetadataUrlPublic": "A URL de metadados LDP é pública",
            "lblCompanySSoSettingIdpMetadataUrl": "URL de metadados LDP",
            "lblCompanySSoSettingIdpMetadata": "Metadados LDP (XML)",
            "lblCompanySSoSettingReturnUrl": "URL de retorno da empresa",
            "lblCompanySSoSettingUseSso": "Single Sign On",
            "lblCompanySSoSettingLoginUrl": "URL de login SSO",
            "lblCompanySSoSettingAcsUrl": "URL do consumidor ACS",
            "lblCompanySSoSettingAcsValidator": "Validador RegEx de URL do consumidor ACS",
            "lblCompanySSoSettingAudience": "Público-alvo",
            "lblCompanySSoSettingRecipient": "Destinatário",
            "lblCompanySSoSettingAllowNonSso": "Permitir logins sem SSO",
            "lblCompanyApiSettingApi": "Integrações de terceiros",
            "lblCompanyApiSettingNoneAvailable": "Não há integrações de terceiros disponíveis para sua empresa.",
            "lblApiNotAllowed": "Não permitido",
            "lblApiActive": "Ativo",
            "lblApiInactive": "Inativo",
            "companyProfile": "Perfil da empresa",
            "msgInvalidImageType": "Tipo de arquivo de imagem inválido. Selecione um arquivo de imagem válido.",
            "deleteCompanyHeader": "Excluir empresa",
            "deleteCompanyBody": "Tem certeza de que deseja excluir esta empresa? Digite a palavra '{{deleteCompanyVerificationPhrase}}' para habilitar o botão Excluir.",
            "deleteCompanyVerificationPhrase": "excluir",
            "msgSuccessDeletingCompany": "A empresa foi excluída com sucesso.",
            "deletePortalErrors": {
                "7016": "O portal tem um número de telefone de anúncio em chamada atribuído.",
                "7017": "O portal tem portal(is) filho(s) ativo(s)",
                "msgErrorDeletingCompany": "Erro ao excluir o portal."
            },
            "lblUploadMetadata": "Carregar metadados",
            "lblChangeMetadata": "Alterar metadados",
            "tooltipMetadataEmpty": "Vazio",
            "msgInvalidFileType": "Tipo de arquivo de metadados inválido; esperando um arquivo XML.",
            "industryTypes": {
                "generic": "Genérico",
                "banking": "Bancário",
                "legal": "Legal",
                "credit union": "Cooperativa de crédito",
                "finance and insurance": "Finanças e seguros",
                "retail trade": "Comércio varejista"
            },
            "lblCompanyHRIntegration": "Integração de RH",
            "lblSubscriptionCode": "Código de assinatura ADP:",
            "btnGenerate": "Gerar"
        },
        "security": {
            "errorRequiredFields": "Por favor, preencha todos os campos obrigatórios.",
            "errorResetSecurityFailed": "Ocorreu um erro ao redefinir as configurações de segurança.",
            "errorSavSecurityFailed": "Ocorreu um erro ao salvar as configurações de segurança.",
            "lblAdditionalSettings": "Configurações adicionais de segurança",
            "lblAllowUserManageLocation": "Permitir que os usuários gerenciem seus locais",
            "lblAllowUserManagePassword": "Permitir que os usuários gerenciem suas próprias senhas",
            "lblAllowUserManageProfile": "Permitir que os usuários gerenciem suas informações de contato",
            "lblHideUserEmail": "Ocultar o e-mail do usuário (nome de usuário) para exibição nos e-mails do sistema",
            "lblLowercaseLetters": "Letras minúsculas (0-10)",
            "lblMinimumLength": "Comprimento mínimo (1-15)",
            "lblMobilePhoneRequired": "Exigir que o usuário forneça um número de telefone celular padrão",
            "lblNumbers": "Números (0-10)",
            "lblPasswordSecurity": "Personalizar requisitos de segurança de senha",
            "lblPasswordSecurityInfoPopoverTitle": "Segurança de senha padrão",
            "lblPasswordSettings": "Configurações de senha",
            "lblSecurityActivity": "Atividade de segurança",
            "lblSecurityTitle": "Segurança",
            "lblSecurityDescription": "Gerenciar regras de senha personalizadas e outras configurações de segurança do sistema",
            "lblSymbols": "Símbolos (0-10)",
            "lblUppercaseLetters": "Letras maiúsculas (0-10)",
            "msgPasswordSecurityInfoPopoverText": "Por padrão, todas as senhas têm um comprimento mínimo de 6, a menos que sejam alteradas",
            "msgResetSecuritySuccess": "As configurações de segurança foram redefinidas com sucesso.",
            "msgSaveSecuritySuccess": "As configurações de segurança foram salvas com sucesso.",
            "lblAllowOptOutSms": "Permitir que os usuários optem por não receber mensagens SMS",
            "lblAllowOptOutEmail": "Permitir que os usuários optem por não receber mensagens de e-mail",
            "lblAllowOptOutVoice": "Permitir que os usuários optem por não receber mensagens de voz",
            "confirmReset": {
                "body": "Tem certeza de que deseja redefinir as configurações de segurança?",
                "header": "Confirme",
                "btnText": "OK"
            }
        },
        "systemEmail": {
            "btnEdit": "Editar",
            "btnSave": "Salvar",
            "btnOk": "OK",
            "errorRevertEmailFailed": "Não foi possível reverter o e-mail para o e-mail padrão do sistema. Tente novamente.",
            "lblBody": "Corpo do e-mail",
            "lblBulkUploadFailureEmail": "Falha no upload em massa",
            "lblBulkUploadIncorrectPasswordEmail": "Senha incorreta do upload em massa",
            "lblBulkUploadResultEmail": "Resultados do upload em massa",
            "lblBulkUploadUnknownUserEmail": "Nome de usuário desconhecido para upload em massa",
            "lblChangedPasswordEmail": "Senha alterada",
            "lblConfirmation": "Confirme",
            "lblEmailChangedEmail": "E-mail alterado",
            "lblEmailListLabel": "Ver modelo para:",
            "lblForgotPasswordEmail": "Esqueceu a senha",
            "lblInviteUserEmail": "Registro de novo usuário",
            "lblLastModifiedBy": "Última modificação por",
            "lblLastModifiedOn": "Última modificação em",
            "lblLoading": "Aguarde...",
            "lblNA": "N / D",
            "lblResetTemplateLink": "Redefinir para padrão do sistema",
            "lblSubject": "Assunto do e-mail",
            "lblSystemEmailActivity": "Atividade de e-mail do sistema",
            "lblSystemEmailsTitle": "E-mails do sistema",
            "lblTemplateLastModified": "Modelo modificado pela última vez",
            "lblUserGroupAdditionEmail": "Adição de grupo de usuários",
            "lblUserGroupRemovalEmail": "Remoção de grupo de usuários",
            "lblUsingCustom": "Este modelo foi personalizado.",
            "msgRevertEmailSuccess": "O e-mail do sistema foi revertido para o e-mail do sistema padrão.",
            "msgSavedSystemEmailSuccess": "O e-mail do sistema foi salvo com sucesso",
            "confirmReset": {
                "body": "Tem certeza de que deseja reverter para o e-mail padrão do sistema?",
                "header": "Confirme",
                "btnText": "OK"
            },
            "sendMessageEmail": "Enviar e-mail de mensagem",
            "insert": "Inserir",
            "reset": "Redefinir",
            "resizeImageDescription": "Ajuste a largura e a altura da imagem ao seu gosto. Você tem a opção de preservar a proporção da imagem para que não pareça distorcida.",
            "doYouWantResizeImageDescription": "Notamos que sua imagem é muito grande. Deseja redimensioná-la?",
            "preserveAspectRatio": "Preservar proporção",
            "bcTeamLeadIntroductoryEmail": "E-mail introdutório de continuidade de negócios para líder da equipe",
            "bcTeamMemberIntroductoryEmail": "E-mail introdutório de continuidade de negócios para membro da equipe",
            "bcNewTeamLeadIntroductoryEmail": "E-mail introdutório de continuidade de negócios para líder da equipe (novo usuário)",
            "bcNewTeamMemberIntroductoryEmail": "E-mail introdutório de continuidade de negócios para membro da equipe (novo usuário)",
            "bcTeamLeadDueDateReminderEmail": "E-mail de data de vencimento em breve de continuidade de negócios para líder da equipe",
            "bcTeamLeadDueDateNowEmail": "E-mail de data de vencimento agora de continuidade de negócios para líder da equipe",
            "bcTeamMemberDueDateReminderEmail": "E-mail de data de vencimento em breve para membro da equipe",
            "bcTeamMemberDueDateNowEmail": "E-mail de data de vencimento agora para membro da equipe",
            "bcNewAnnexLeadIntroductoryEmail": "E-mail para apresentação do plano de emergência operacional do colaborador (novo usuário)",
            "bcAnnexLeadIntroductoryEmail": "Email para apresentação do plano de emergência operacional do colaborador",
            "bcAnnexLeadDueDateReminderEmail": "E-mail de data de vencimento em breve de continuidade de negócios para líder da equipe",
            "bcAnnexLeadDueDateNowEmail": "E-mail de data de vencimento agora de continuidade de negócios para líder da equipe",
            "managerNotificationEmergencyResponseEmail": "E-mail de Resposta de Emergência de Notificação do Gerente",
            "bcTechnologyNewLeadEmail": "Email introdutório líderes em [[technology]] (novo usuário)",
            "bcTechnologyLeadEmail": "Email introdutório para líderes em [[technology]]"
        },
        "messageSettings": {
            "errorRequiredFields": "Por favor, preencha todos os campos obrigatórios.",
            "errorResetMessageFailed": "Ocorreu um erro ao redefinir as configurações da mensagem.",
            "errorSavMessageFailed": "Ocorreu um erro ao salvar as configurações da mensagem.",
            "lblAreYouSureResetSettings": "Tem certeza de que deseja redefinir as configurações de mensagens?",
            "lblEscalateMessageContent1": "Se você quiser dar aos destinatários da mensagem tempo para responder por SMS, e-mail ou mensagens push antes de serem chamados, defina como Sim. Você poderá então selecionar o tempo de espera antes de ligar.",
            "lblEscalateMessageContent2": "Se definido como não, o destinatário será chamado ao mesmo tempo que receber mensagens de texto / e-mail / push.",
            "lblEscalateMessageContent3": "Nota: A configuração APENAS se aplica a mensagens que requerem uma resposta e têm SMS e / ou e-mail e / ou Push e voz selecionados.",
            "lblEscalateMessageTitle": "Escalonar a mensagem quando a resposta for necessária (SMS / e-mail / Push to Voice)?",
            "lblIncludeOfficePhoneContent": "Se definido como Sim, quando um usuário envia uma nova mensagem E seleciona para Voz (destinatários da chamada) a opção de ligar para o telefone do escritório de um destinatário será pré-selecionada. Os usuários poderão substituir o padrão e desmarcar por mensagem, se desejado.",
            "lblIncludeOfficePhoneTitle": "Incluir telefone do escritório para chamadas de voz por padrão?",
            "lblPersonalContactContent": "Se definido como Sim, quando um usuário enviar uma nova mensagem, a opção de enviar para as informações de contato pessoal do destinatário da mensagem será pré-selecionada. Os usuários poderão substituir esse padrão e desmarcar por mensagem, se desejado.",
            "lblPersonalContactTitle": "Enviar mensagem para as informações de contato pessoal do usuário?",
            "lblSettingsActivity": "Atividade de configurações",
            "lblSettingsTitle": "Configurações de mensagem",
            "msgResetMessageSuccess": "As configurações de mensagem foram redefinidas com sucesso.",
            "msgSaveMessageSuccess": "As configurações de mensagem foram salvas com sucesso.",
            "lblPersonalContactPhoneTitle": "Enviar mensagem para o telefone pessoal do usuário?",
            "lblPersonalContactPhoneContent": "Se definido como Sim, quando um usuário enviar uma nova mensagem, a opção de enviar para as informações pessoais do telefone do destinatário da mensagem será pré-selecionada. Os usuários poderão substituir esse padrão e desmarcar por mensagem, se desejado.",
            "personalContactEmailTitle": "Enviar mensagem para o e-mail pessoal do usuário?",
            "lblPersonalContactEmailContent": "Se definido como Sim, quando um usuário enviar uma nova mensagem, a opção de enviar para o e-mail pessoal do destinatário da mensagem será pré-selecionada. Os usuários poderão substituir esse padrão e desmarcar por mensagem, se desejado."
        },
        "userInitiatedMessage": {
            "lblPageHeader": " Configurações de incidentes iniciados pelo usuário",
            "lblPageDescription": "Gerenciar quais usuários receberão mensagens de incidentes iniciados pelo usuário.",
            "lblDefaultGroup": "Grupo padrão",
            "lblDefaultGroupTip": "Os usuários do grupo selecionado receberão mensagens por E-MAIL, SMS e MENSAGENS PUSH por padrão. Esta configuração pode ser substituída pelo tipo de incidente abaixo.",
            "lblIncidentTypes": "Tipos de Incidentes",
            "lblDefaultGroupPerIncident": "Grupo padrão para ",
            "lblDefaultGroupPerIncidentTip": "Os usuários no grupo selecionado sempre receberão mensagens para este tipo de incidente. Grupos adicionais podem receber mensagens usando as configurações de locais opcionais abaixo.",
            "lblOptionLocations": "Locais opcionais",
            "lblOptionLocationsTip": "Grupos adicionais podem receber mensagens com base no local padrão dos usuários de relatórios.",
            "btnAddNewOptionalLocation": "Adicionar novo local opcional",
            "msgUserInitiatedMessagesSettingsSaved": "Configurações iniciadas pelo usuário salvas.",
            "msgUserInitiatedMessagesSettingsNotSaved": "Ocorreu um erro ao salvar as configurações iniciadas pelo usuário. Tente novamente mais tarde.",
            "msgRequiredFieldError": "Você tem campo(s) que requere(m) seleções.",
            "lblSendTo": "Enviar mensagens para",
            "lblChannelsPerIncidentTip": "Selecione os métodos em que o usuário receberá mensagens. Os usuários que não possuem esses métodos não receberão mensagens.",
            "lblEmail": "E-mail",
            "lblSms": "SMS",
            "lblVoice": "Voz",
            "lblPush": "Mensagens push",
            "lblLocation": "Localização",
            "lblGroup": "Grupo",
            "lblChangesHaveBeenMadeHeader": "Foram feitas alterações",
            "lblChangesHaveBeenMadeMessage": "Você fez alterações nesta página. Se você sair desta página sem primeiro salvar seus dados, as alterações serão perdidas.",
            "lblNoGroupsHeader": "Nenhum <strong>grupo</strong> foi configurado",
            "lblNoGroupsDescription": "Para gerenciar as configurações de incidentes iniciados pelo usuário, pelo menos um (1) ou mais grupos devem ser configurados.",
            "btnGoToCreateGroup": "Criar um grupo",
            "lblGroupsRemoved": "NOTA: Grupos sem usuários atribuídos foram removidos da lista.",
            "lblGroupsAndLocationsRemoved": "NOTA: Grupos e locais sem usuários atribuídos foram removidos da lista.",
            "msgCannotDisableAllChannels": "Pelo menos um (1) método de contato deve ser ativado.",
            "confirmReset": {
                "body": "Alterar o grupo padrão afetará todos os tipos de incidente sem um grupo configurado especificamente também para ser atualizado.",
                "header": "Tem certeza de que deseja alterar o grupo padrão?",
                "btnText": "OK"
            }
        },
        "user": {
            "lblDefaultNewUserTitle": "Novo usuário",
            "btnSaveAndFinish": "Salvar e finalizar",
            "lblUserActivity": "Atividade do usuário",
            "lblUserStatus": "Status",
            "lblIQAssignments": "Atribuições do iQ",
            "lblInitRegSent": "Registro inicial enviado",
            "lblUserFirstLogin": "Primeiro login do usuário",
            "lblUsersLastTermAcceptance": "Aceitação do último termo do usuário",
            "lblUserLastLogin": "Último login do usuário",
            "lblUserRole": "Função",
            "lblUniqueId": "ID único",
            "lblSelfRegistered": "Optado",
            "errorSaveUser": "Falha ao salvar as informações do usuário",
            "lblSendRegEmail": "Enviar e-mail de registro",
            "menuPickRole": "Selecione uma função...",
            "errorIQAssignmentsRequireLogin": "O usuário deve ter uma função com recursos de login ao ter atribuições do iQ",
            "errorCannotInactivateWithIQAssignments": "O usuário não pode ficar inativo porque tem atribuições do iQ. Remova todas as atribuições do iQ antes de tentar tornar o usuário inativo.",
            "errorCannotDeleteWithIQAssignments": "O usuário não pode ser excluído porque possui atribuições do iQ. Remova todas as atribuições do iQ antes de tentar excluir o usuário.",
            "errorCannotDeleteBCManager": "O usuário não pode ser excluído porque é o Business Continuity Manager. Selecione o novo Business Continuity Manager antes de tentar excluir o usuário.",
            "lblAreYouSureUpgradeUser": "Tem certeza de que deseja atualizar este usuário aderente? Esta alteração não pode ser revertida",
            "lblAreYouSureDeleteUser": "Tem certeza de que deseja excluir este usuário?",
            "msgDeleteUserSuccess": "Usuário excluído com sucesso.",
            "msgCannotDeleteEssentialContactUser": "O usuário não pode ser excluído. Esse é um contato essencial.",
            "errorDeleteUserFailed": "Ocorreu um erro ao tentar excluir este usuário.",
            "lblManageUserPasswordHeader": "Redefinir senha",
            "lblInitiateUserPasswordReset": "Envie ao usuário um e-mail que permite que ele crie sua própria senha.",
            "lblManuallySetUserPassword": "Senha definida manualmente (nenhum e-mail enviado, você precisará comunicar verbalmente a senha).",
            "lblResetAndSendEmail": "Redefinir e enviar e-mail",
            "lblManuallySetPassword": "Definir senha manualmente",
            "msgNoUser": "Não foi possível solicitar a redefinição da senha; o usuário está vazio.",
            "msgRequestPasswordResetFailed": "Não foi possível redefinir a senha para este usuário.",
            "msgRequestPasswordResetSuccess": "O usuário receberá um e-mail com instruções para redefinir sua senha.",
            "lblAreYouSureResendInvite": "Tem certeza de que deseja reenviar o convite para este usuário?",
            "msgInvitationSent": "O convite do usuário foi enviado.",
            "errorInvitationSent": "Não foi possível reenviar o convite do usuário.",
            "lblLastInviteNotSent": "nenhum convite enviado",
            "lblLastInviteSent": "último convite enviado",
            "btnResendInvite": "Reenviar convite",
            "lblAreYouSureUnlock": "Tem certeza de que deseja desbloquear este usuário?",
            "msgUserUnlocked": "O usuário foi desbloqueado com sucesso.",
            "errorUserUnlocked": "Não foi possível desbloquear este usuário.",
            "btnUnlock": "Desbloquear"
        },
        "selfRegistration": {
            "lblSelfRegistrationTitle": "Adesão",
            "lblSelfRegistrationDescription": "Gerenciar definições e configurações de adesão",
            "lblSelfRegistrationFlag": "Permitir que os usuários optem pela adesão",
            "lblSelfRegistrationUrl": "URL/código QR de adesão",
            "lblSelfRegistrationCode": "Código de registro",
            "lblRequireAddress": "Permitir que os usuários forneçam um endereço",
            "lblUserRole": "Selecionar uma função para os usuários aderentes",
            "lblUserLocations": "Selecione de quais locais os usuários aderentes podem receber mensagens",
            "lblLocations": "[[Locations]]",
            "lblSelectNone": "selecione nenhum",
            "lblSelectAll": "selecionar tudo",
            "lblConfirmDeleteTitle": "Remover conta",
            "lblConfirmDelete": "Tem certeza de que deseja remover sua conta?",
            "optInUrlTooltip": "Copie o link ou baixe o código QR e forneça aos usuários que optaram pela adesão",
            "registrationCodeTooltip": "Forneça aos usuários aderentes este código para concluir o registro",
            "copyOptInUrlTooltip": "Copiar URL de adesão",
            "downloadOptInQrCodeTooltip": "Baixar código QR",
            "requireAddress": "Sim, exigir que os usuários forneçam um endereço",
            "allowAddress": "Sim, permitir que os usuários forneçam um endereço",
            "noAddress": "Não, não pedir um endereço",
            "allLocations": "Quaisquer locais",
            "selectedLocations": "Locais selecionados",
            "msgSaveSuccess": "Configurações de adesão salvas",
            "msgSaveFail": "Falha ao salvar as configurações de adesão",
            "confirmReset": "Tem certeza de que deseja redefinir as configurações de adesão para o padrão?",
            "msgResetFail": "Falha ao redefinir as configurações de adesão",
            "msgLocationRequired": "Selecione pelo menos um local para o usuário se inscrever",
            "msgRoleBundleRequired": "A função de usuário aderente é necessária",
            "msgNoRoles": "Nenhuma função qualificada para usuários aderentes. Crie pelo menos uma função apenas para mensagens.",
            "lblNoRoles": "Nenhuma função disponível"
        },
        "mobileEmergencyButtons": {
            "alerts": "[[Alerts]]",
            "manageMobileEmergencyButtons": "Configurações de [[alert]] iniciado por celular",
            "manageMobileEmergencyButtonsDescription": "Os botões de [[alert]] iniciado por celular podem ser configurados no aplicativo móvel. Esta página permite que você configure esses botões e os usuários que serão comunicados quando o botão for pressionado.",
            "sharedSettings": "Configurações compartilhadas de [[alert]] personalizado",
            "sharedSettingsDescription": "As configurações compartilhadas de [[alerts]] personalizados são aplicáveis apenas aos botões de [[alerts]] personalizados. Botões mais especializados, como Pânico, Check-in programado e Relatório de incidentes, são gerenciados por outras configurações da empresa.",
            "buttonShouldBePress": "Permitir que os usuários pressionem um [[alert]] apenas quando perto de um local da empresa",
            "howCloseShouldTheLocation": "Identificar o quão perto os usuários devem estar de um local da empresa para que o [[alert]] seja acionado",
            "canSamePersonPressResolve": "Permitir que os usuários que ativam um [[alert]] resolvam o incidente",
            "emergencyButtonsConfiguration": "Configuração de [[Alerts]] iniciado por celular",
            "saveSuccess": "Botões de [[alert]] iniciado por celular salvos com sucesso",
            "saveError": "Erro ao salvar botões de [[alert]] iniciado por celular",
            "loadError": "Erro ao carregar botões de [[alert]] iniciado por celular",
            "invalidContactMethodsError": "É necessário pelo menos um método de contato",
            "addButton": "Adicionar botão",
            "configurationModal": {
                "title": "Configurar {{ x }} botão de [[alert]] iniciado por celular",
                "lblButtonType": "Selecionar tipo de botão",
                "lblButtonName": "Forneça um nome para o botão de [[alert]] iniciado por celular",
                "lblButtonNameTooltip": "Os nomes dos botões de [[alert]] são limitados a 50 caracteres",
                "lblCohortTypeToNotify": "Quem deve ser notificado quando este botão for pressionado?",
                "lblTypesOfNearbyUsers": "Como você gostaria de identificar as pessoas próximas?",
                "lblProximity": "Quão perto devem estar os destinatários próximos?",
                "lblCustomButtonType": "Personalizadas",
                "lblPanicButtonType": "Pânico",
                "lblScheduledCheckinButtonType": "Check-in programado",
                "lblReportIncidentButtonType": "Reportar incidente",
                "lblCohortProximityType": "Pessoas próximas",
                "lblCohortDesignatedRecipientsType": "Um conjunto específico de pessoas",
                "lblSearchUser": "Pesquisar",
                "lblAddByPhoneNumber": "Adicionar por número de telefone",
                "lblAddByEmail": "Adicionar por e-mail",
                "lblSelectedRecipients": "Destinatários selecionados",
                "lblAddByEntity": "Adicionar por tipo",
                "lblEntityTypeRoles": "Funções",
                "lblEntityTypeLocations": "[[Locations]]",
                "lblEntityTypeDepartments": "[[Departments]]",
                "lblEntityTypeGroups": "Grupos",
                "panicDesc": "<p>Um botão de pânico será colocado na tela inicial do celular.</p><p>Pressionar o botão irá acionar um incidente e enviar uma mensagem a uma lista designada de destinatários. Essas mensagens e lista de destinatários podem ser personalizadas por local.</p><p>Para configurar as mensagens de pânico e as listas de destinatários desejadas, visite <a href=\"{{panicConfigurationLink}}\" target=\"_blank\">Configuração de pânico</a>.</p>",
                "scheduledCheckinDesc": "<p>Um botão de Check-in programado será colocado na tela inicial do celular.</p><p>Pressionar o botão irá disparar uma contagem regressiva que cria um incidente de pânico e envia uma mensagem para uma lista designada de destinatários. Esta mensagem e lista de destinatários podem ser personalizadas por local.</p><p>Para configurar a mensagem de pânico e a lista de destinatários associadas, visite <a href=\"{{panicConfigurationLink}}\" target=\"_blank\">Configuração de pânico</a>.</p>",
                "reportIncidentDesc": "<p>Um botão Relatar incidente será colocado na tela inicial do celular.</p><p>Pressionar o botão permitirá que os usuários digitem livremente uma descrição e localização do incidente, além de tirar uma foto.</p><p>Para configurar a lista de destinatários de Relatar incidente, visite a <a href=\"{{manageSettingsLink}}\" target=\"_blank\">Configuração de incidentes iniciados pelo usuário</a></p>",
                "duplicatePanicButtonError": "Já existe um botão de pânico",
                "duplicateScheduledCheckInError": "Já existe um botão de check-in programado",
                "duplicateReportIncidentError": "Já existe um botão Relatar incidente",
                "duplicateAlertButtonError": "O botão {{ buttonName }} já existe",
                "saveSuccess": "Botão móvel salvo com sucesso.",
                "saveError": "Erro ao salvar o botão móvel.",
                "removeSuccess": "Botão móvel removido com sucesso.",
                "removeError": "Erro ao remover o botão móvel.",
                "selectUnitOfMeasure": "Selecionar unidade de medida",
                "byUserAddresses": "Por endereços de usuário",
                "byAssignedLocation": "Por localização atribuída",
                "byMobileLocation": "Por localização móvel",
                "lblIncidentType": "Tipo de Incidente"
            },
            "confirmRemove": {
                "header": "Remover botão de [[alert]] iniciado por celular",
                "body": "Tem certeza de que deseja remover o {{ buttonName }} botão de [[alert]] iniciado por celular do aplicativo móvel?",
                "text": "Remover"
            }
        },
        "bulkUploadConfig": {
            "configureBulkUploadTitle": "Configuração de upload em massa",
            "lblDisableEmailAttachments": "Incluir arquivos de origem, resultado e relatórios de erros detalhados no e-mail de notificação",
            "disableEmailAttachmentsTooltip": "Arquivo de origem, arquivo de resultado e arquivo de erros detalhados como anexo de e-mail de notificação do upload em massa de e-mail",
            "lblDisableEmailErrors": "Incluir arquivo de erros sem detalhes pessoais do usuário no e-mail de notificação",
            "disableEmailErrorsTooltip": "Incluir arquivo de erros de upload em massa sem detalhes pessoais do usuário como anexo de e-mail de notificação.",
            "lblNotificationEmails": "E-mails de notificação",
            "notificationEmailsTooltip": "Endereços de e-mail para enviar notificações sobre resultados de upload em massa.",
            "lblDefaultRole": "Função padrão",
            "defaultRolePlaceHolder": "Selecionar função padrão",
            "defaultRoleTooltip": "Esta função será atribuída a novos usuários que não possuem uma função especificada em seu arquivo de upload em massa.",
            "lblDefaultLocation": "Localização padrão",
            "defaultLocationPlaceHolder": "Selecionar local padrão",
            "defaultDropdownPlaceholder": "Nenhum selecionado",
            "defaultLocationTooltip": "Este local será atribuído a novos usuários que não possuem um local especificado em seu arquivo de upload em massa.",
            "invalidEmailFormatError": "Formato de e-mail inválido. O valor não pode ser adicionado à lista de e-mails."
        }
    },
    "login": {
        "lblLoggingIn": "Fazendo login...",
        "lblLoginId": "E-mail",
        "phUsername": "Insira seu endereço de e-mail",
        "phRegistrationCode": "Insira o código de registro da empresa",
        "lblPassword": "Senha",
        "lblConfirmPassword": "Confirmar senha",
        "phPassword": "Digite sua senha",
        "btnLogin": "Fazer login",
        "btnBackToLogin": "voltar ao login",
        "lblSupport": "Suporte",
        "btnForgotPortalUrl": "Esqueceu a URL do portal?",
        "btnForgotPassword": "Esqueceu a senha?",
        "btnRegister": "Adesão",
        "msgChangedPasswordSuccess": "Sua senha foi alterada com sucesso.",
        "msgSessionTimeout": "Sua sessão expirou devido a inatividade. Faça login novamente.",
        "lblNeedHelp": "Precisa de ajuda?",
        "msgRequestSendPortalsLinksSuccess": "Enviamos a você um e-mail com os links dos portais disponíveis. Você deve recebê-lo em alguns minutos.",
        "msgRequestSendPortalsLinksFailed": "Falha ao enviar um e-mail.",
        "lblResetPassword": "Redefinir senha",
        "lblChangePassword": "Alterar senha",
        "lblCreatePassword": "Criar senha",
        "lblRegisterAccount": "Criar conta",
        "lblRegisterProfile": "Informações de contato",
        "lblContactInfo": "Informações de contato",
        "msgRegisterInstructions": "Insira o código de registro da empresa na qual você gostaria de se registrar",
        "msgResetPasswordInstructions": "Digite seu e-mail e clique abaixo para enviar uma solicitação de redefinição de senha.",
        "msgRegisterAccountInstructions": "Crie um nome de usuário e uma senha que você usará para acessar as informações do seu perfil",
        "btnRequestPasswordReset": "Solicitar redefinição de senha",
        "msgForgotPassword1": "Se o nome de usuário fornecido for válido, em alguns minutos você deverá receber um e-mail para criar uma nova senha.",
        "msgForgotPassword2": "Se você não encontrá-lo, verifique sua pasta de spam. Se você ainda não recebeu,",
        "msgForgotPassword3": "tente novamente",
        "msgForgotPassword4": "ou entre em contato com o administrador.",
        "lblNewPassword": "Nova senha",
        "lblConfirmNewPassword": "Confirmar nova senha",
        "btnChangePassword": "Alterar senha",
        "btnCreatePassword": "Criar senha",
        "passwordErrorMessage": "A senha não atende às regras de complexidade.",
        "msgUsernameExist": "o e-mail já existe no sistema; use outro e-mail.",
        "errors": {
            "999": "Erro no sistema. Tente novamente mais tarde.",
            "1005": "Não foi possível fazer o login. Verifique seu e-mail ou senha.",
            "1006": "O link expirou ou é inválido. Solicite um novo link.",
            "1010": "Você excedeu o número máximo de tentativas incorretas de login.  Sua conta foi temporariamente bloqueada.  Tente novamente mais tarde.",
            "1015": "O endereço de e-mail inserido não corresponde a uma conta de usuário ativa.",
            "1017": "Não é possível usar a autenticação multifator; o usuário não possui um telefone principal",
            "1019": "O PIN de autenticação é inválido.",
            "1022": "O usuário deve fazer login via SSO",
            "1023": "O usuário deve fazer o login usando a URL do portal",
            "1024": "O usuário deve fazer o login usando a URL ou o SSO do portal",
            "msgMFAFailed": "A autenticação multifator falhou.",
            "tacDeclined": "Termos e condições recusados.",
            "msgTACFailed": "Os termos e condições falharam.",
            "msgValidateEmailFormat": "O e-mail inserido não é um endereço de e-mail válido.",
            "msgInvalidUsername": "O endereço de e-mail inserido não corresponde a uma conta de usuário ativa."
        }
    },
    "mfa": {
        "lblMultifactorTitle": "Autenticação multifator",
        "lblMfaSecurityCode": "Código de segurança",
        "lblRequired": "(requeridos)",
        "msgMultifactorInstructions": "Um código de segurança foi enviado para o seu número de telefone registrado. Insira-o abaixo para continuar com o login.",
        "msgMultifactorWithExpirationInstructions": "Um código de segurança foi enviado para o seu número de telefone registrado. Insira-o abaixo para continuar com o login. O código de segurança expirará em {{mfaPinExpirationMinutes}} minutos."
    },
    "splashMessage": {
        "msgMustAgreeToSplash": "Você deve confirmar esta mensagem marcando a caixa.",
        "lblAcknowledgedSplash": "Confirmado"
    },
    "recoveryProfiles": {
        "recoveryProfile": "Perfil de recuperação",
        "recoveryProfiles": "Perfis de recuperação",
        "recoveryProfilesFilterPlaceHolder": "Filtrar por nome de perfil...",
        "export": "Exportar",
        "name": "Nome",
        "essentialContactsDesc": "Pessoal-chave autorizado a iniciar uma resposta de agilidade quando necessário.",
        "generator": "Gerador",
        "comments": "Comentários",
        "generatorDesc": "Requisitos de energia necessários para recuperar seu espaço de escritório",
        "sites": "Locais",
        "sitesDesc": "Locais de escritórios móveis, bem como opções alternativas de espaço de varejo",
        "technologyAndConnectivity": "[[Technology]] e conectividade",
        "technologyAndConnectivityDesc": "Aplicações de equipamentos de [[technology]] para recuperação",
        "generatorSize": "Tamanho do gerador",
        "generatorPhase": "Fase do gerador",
        "generatorVoltage": "Tensão do gerador",
        "totalAmperage": "Amperagem total",
        "requiredCable": "Cabo requerido",
        "powerService": "Serviço de energia",
        "generatorTitle": "Forneça os requisitos de energia necessários para recuperar o espaço do seu escritório",
        "requestChange": "Solicitar alteração",
        "site": "Local",
        "primary": "Primário",
        "errorExportingRecoveryProfile": "Erro ao exportar o perfil de recuperação",
        "rename": "Renomear",
        "renameRecoveryProfile": "Renomear perfil de recuperação",
        "renameRecoveryProfileTooltip": "Um nome de perfil de recuperação pode ter até 200 caracteres de comprimento. Deve ser único.",
        "errorRenamingRecoveryProfile": "Erro ao renomear o perfil de recuperação",
        "successRenamingRecoveryProfile": "Perfil de recuperação renomeado com sucesso",
        "duplicateProfileName": "Os nomes dos perfis de recuperação devem ser exclusivos",
        "errorLoadingProfiles": "Erro ao carregar os perfis de recuperação",
        "errorCreatingProfile": "Erro ao criar o perfil de recuperação",
        "manuallyCreate": "Criar manualmente",
        "migrateMyAgilityDocuments": "Migrar documentos",
        "errorMigratingMyAgilityDocuments": "Erro ao migrar documentos",
        "successMigratingMyAgilityDocuments": "Migração de documentos solicitada com sucesso",
        "validation": {
            "duplicateProfileNameMessage": "Este nome já foi usado",
            "missingValues": "Preencha todos os valores obrigatórios"
        },
        "recoverySites": {
            "recoverySites": "Locais de recuperação",
            "recoverySitesFilterPlaceHolder": "Filtrar pelo nome do local...",
            "recoverySitesLoadingError": "Erro ao carregar os locais de recuperação",
            "unitType": "Tipo de unidade",
            "unitNumber": "Número de unidade",
            "streetNumber": "Número da rua",
            "streetName": "Nome da rua",
            "city": "City",
            "state": "State",
            "postalCode": "Código postal",
            "country": "País",
            "contactName": "Nome de contato",
            "phone": "Telefone",
            "comments": "Comentários",
            "saveChanges": {
                "header": "Salvar local de recuperação",
                "body": "Tem certeza que deseja salvar as alterações?",
                "text": "Salvar"
            },
            "errorLoadingRecoverySite": "Erro ao carregar o local de recuperação",
            "errorSavingRecoverySite": "Erro ao salvar o local de recuperação",
            "successSavingRecoverySite": "Local de recuperação salvo com sucesso",
            "errorDeletingRecoverySite": "Erro ao excluir o local de recuperação",
            "successDeletingRecoverySite": "Local de recuperação excluído com sucesso",
            "filterPlaceHolder": "Filtrar pelo nome do local...",
            "deleteSite": {
                "header": "Excluir local de recuperação",
                "body": "Tem certeza de que deseja excluir este local?",
                "text": "Excluir"
            },
            "manuallyCreate": {
                "header": "Criar perfil de recuperação manualmente",
                "body": "Tem certeza de que deseja criar um novo perfil manualmente?",
                "text": "Criar manualmente"
            },
            "siteName": "Nome do local",
            "newSite": "Novo local",
            "recoverySitesDescription": "Determine locais de escritórios móveis primários e secundários, bem como opções alternativas de espaço de varejo.",
            "checkRequiredFields": "Marque todos os campos obrigatórios"
        },
        "technicalInfo": {
            "description": "Com sua assinatura, você tem acesso aos equipamentos listados abaixo",
            "saveSuccess": "Comentário salvo",
            "comment": "Comentar",
            "technologyRecovery": "Recuperação de [[technology]]",
            "comments": "Comentários"
        },
        "recoveryGenerator": {
            "saveGeneratorSuccess": "Gerador salvo",
            "errorLoadingGeneratorInfo": "Erro ao carregar as informações do gerador",
            "checkRequiredFields": "Marque todos os campos obrigatórios",
            "saveChanges": {
                "header": "Salvar gerador de recuperação",
                "body": "Tem certeza que deseja salvar as alterações?",
                "text": "Salvar"
            }
        },
        "errorRetrievingRecoveryProfile": "Erro ao carregar o perfil de recuperação",
        "recoveryProfilesDescription": "Seus perfis de recuperação ajudam a recuperar rapidamente sua organização durante um desastre.",
        "activityInformation": "Informações da atividade",
        "essentialContacts": {
            "essentialContacts": "Contatos essenciais",
            "name": "Nome",
            "title": "Título",
            "primary": "Primário",
            "email": "E-mail",
            "mobilePhone": "Telefone celular",
            "officePhone": "Telefone comercial",
            "personalPhone": "Telefone pessoal",
            "addContact": "Adicionar contato",
            "essentialContactsDescription": "Pessoal-chave que está autorizado a iniciar uma resposta de agilidade quando necessário",
            "isPrimaryContact": "É o contato principal",
            "newContact": "Novo contato",
            "essentialContact": "Contato essencial",
            "removeEssentialContact": "Remover contato essencial",
            "removeEssentialContactDescription": "Tem certeza de que deseja remover este contato essencial?",
            "removeEssentialContactSuccess": "Contato essencial removido com sucesso",
            "saveEssentialContactSuccess": "Contato essencial salvo com sucesso",
            "essentialContactActivity": "Atividade do contato essencial",
            "noEssentialContacts": "Nenhum contato essencial"
        },
        "documents": {
            "documents": "Documentos",
            "manageYourDocuments": "Gerenciar seus documentos de plano on-line",
            "manageYourDocumentsInstruction": "Gerencie seus documentos de plano on-line aqui.",
            "manageYourDocumentsInstruction2": "Você está atualmente autorizado a ter {{maxUploads}} documentos. Se desejar fazer upload de mais documentos, entre em contato com o gerente da sua conta.",
            "manageYourDocumentInstruction": "Gerencie seu documento de plano on-line aqui.",
            "manageYourDocumentInstruction2": "No momento, você tem permissão para ter 1 documento. Se desejar fazer upload de mais documentos, entre em contato com o gerente da sua conta.",
            "document": "Documento",
            "noDocuments": "Nenhum documento",
            "uploadSuccessful": "Upload bem-sucedido",
            "uploadFailed": "Upload bem-sucedido",
            "maxUploadsReached": "Máximo ({{maxUploads}}) alcançado",
            "documentDeleted": "Documento excluído",
            "documentDeletedError": "O documento não foi excluído",
            "getDocumentsError": "Não é possível recuperar documentos"
        },
        "deleteConfirmation": {
            "header": "Remover perfil de recuperação",
            "body": "Tem certeza que deseja remover o perfil de recuperação '{{ recoveryProfileName }}'?",
            "text": "Remover"
        },
        "confirmMigrateDocumentsForAllRPS": {
            "header": "Migrar documentos do myAgility para todos os perfis de recuperação",
            "body": "Tem certeza de que deseja migrar documentos do myAgility para todos os perfis de recuperação? Todos os documentos existentes com os nomes correspondentes aos nomes dos documentos migrados serão substituídos.",
            "text": "Migrar"
        },
        "confirmMigrateDocumentsForOneRP": {
            "header": "Migrar documentos do myAgility para o perfil de recuperação",
            "body": "Tem certeza de que deseja migrar documentos do myAgility para o perfil de recuperação '{{ recoveryProfileName }}'? Todos os documentos existentes com os nomes correspondentes aos nomes dos documentos migrados serão substituídos.",
            "text": "Migrar"
        },
        "salesforceId": "Inserir ID da conta do Salesforce",
        "associatePortalToSalesforceTooltip": "ID da conta do Salesforce",
        "associate": "Associar",
        "associatePortalToSalesforceAccount": "Associar o portal à conta do Salesforce",
        "associateToSalesforceAccount": "Associar portal à conta do Salesforce",
        "msgSuccessAssociatedToSalesforceAccount": "Portal associado com sucesso à conta do Salesforce",
        "msgErrorAssociatedToSalesforceAccount": "Erro ao associar o portal à conta do Salesforce",
        "alert": {
            "clarifyAlertAction": {
                "header": "Status de [[Alert]]",
                "body": "Por favor, confirme que você deseja ir para o status de [[Alert]]. Se você selecionar Sim, um gerente de declaração entrará em contato com você em breve por telefone ou e-mail.",
                "text": "Sim"
            },
            "title": "Selecionar locais cobertos para colocar em [[alert]]",
            "searchTooltip": "Pesquisar locais cobertos",
            "confirmAlert": {
                "header": "Enviar solicitação de [[alert]] para locais cobertos",
                "body": "Tem certeza de que deseja enviar solicitação de [[alert]] para {{ numLocations }} local(is) coberto(s)?",
                "text": "[[Alert]]"
            },
            "msgSuccessAlert": "Solicitação de [[alert]] enviada com sucesso",
            "msgErrorAlert": "Falha ao enviar a solicitação de [[alert]]",
            "msgSelectRecoveryProfiles": "Selecionar pelo menos um local coberto"
        }
    },
    "passwordPolicies": {
        "lblPasswordPolicyHeader": "Sua senha deve conter o seguinte:",
        "msgPasswordPolicyFail": "A senha não atende às regras de complexidade.",
        "msgPasswordPolicyLoading": "Carregando políticas...",
        "msgPasswordPolicyMinCharacters": "Pelo menos {{number}} caracteres",
        "msgPasswordPolicyMinLowercaseCharacters": "Pelo menos {{number}} letra(s) minúscula(s)",
        "msgPasswordPolicyMinNumberCharacters": "Pelo menos {{number}} número(s)",
        "msgPasswordPolicyMinUppercaseCharacters": "Pelo menos {{number}} letra(s) maiúscula(s)",
        "msgPasswordPolicyNoneFound": "Nenhuma política de senha encontrada.",
        "msgPasswordPolicySpecialCharacters": "Pelo menos {{number}} caractere(s) especial(is)",
        "msgPasswordsMustMatch": "As senhas não coincidem."
    },
    "profile": {
        "btnChangePassword": "Alterar senha",
        "btnSaveProfile": "Salvar e finalizar",
        "btnValidateAddress": "Validar endereço",
        "lblGroups": "Grupos",
        "lblDepartments": "[[Departments]]",
        "lblNoOtherLocations": "Nenhum outro local",
        "lblNonSelected": "Nenhum selecionado",
        "lblDefaultLocation": "PADRÃO (local principal do usuário se trabalhar em vários locais)",
        "lblOfficePhone": "Telefone do escritório",
        "lblOfficePhoneExt": "Ramal",
        "lblOfficePhoneAllowSMS": "Telefone do escritório",
        "lblPersonalEmail": "E-mail pessoal",
        "lblPersonalPhone": "Telefone pessoal (telefone habilitado para SMS recomendado)",
        "lblAdditionalPersonalPhone": "Telefone Pessoal Adicional",
        "lblMessagingEmail": "E-mail (receberá todas as mensagens de e-mail)",
        "lblMobilePhone": "Telefone celular (receberá todos os SMS e chamadas de voz)",
        "lblPersonal": "Outros",
        "lblDefaultContact": "Padrão",
        "lblWorkTitle": "Cargo de trabalho",
        "lblUserFirstName": "Primeiro nome",
        "lblUserLastName": "Último nome",
        "lblAccessMyBookmarks": "Acessar meus favoritos",
        "lblAccessMyDocuments": "Acessar meus documentos",
        "lblProfileInformation": "Informação do perfil",
        "lblMyProfileHeader": "Meu perfil",
        "lblMyCrisisTeam": "Minha equipe de crise",
        "lblEmailAddress": "E-mail",
        "lblCoreInformation": "Informação principal",
        "lblNoDepartmentMembership": "Você não é membro de nenhum [[Departments]]",
        "lblNoGroupMembership": "Você não é membro de nenhum grupo",
        "lblQuickLinks": "Links rápidos",
        "msgSaveProfile": "Seu perfil foi salvo",
        "errorSaveUserCustomFieldsFailed": "Falha ao salvar os campos personalizados do usuário.",
        "errorSaveUserProfile": "Falha ao salvar o perfil do usuário.",
        "lblChangePhoto": "alterar foto",
        "lblUserProfileImage": "Imagem de perfil do usuário",
        "lblTenant": "Inquilino",
        "lblSms": "SMS",
        "lblVoice": "Voz",
        "lblAddressMain": "Endereço principal",
        "lblAddressMainCountry": "País",
        "lblAddressMainAddress1": "Endereço",
        "lblAddressMainAddress2": "Endereço 2 (conjunto, edifício, etc)",
        "lblAddressMainCity": "City",
        "lblAddressMainState": "Estado/Província/Região",
        "lblAddressMainZip": "Código postal",
        "lblAddressMainAdditionalLocationInfo": "Informações adicionais de localização",
        "lblAddressMainDepartment": "[[Departments]]",
        "lblAddressMainFloor": "Andar",
        "lblUserLanguage": "Língua",
        "menuPickCountry": "Selecione um país...",
        "errorCommunicationPreferencesMinimumOne": "Pelo menos uma (1) preferência de comunicação deve estar ativa",
        "errorNoPhoneNumberCannotDisableEmail": "Nenhum número de telefone padrão foi inserido; a preferência de comunicação por e-mail não pode ser desativada",
        "errorNoEmailsCannotDisableVoiceSms": "Nenhum e-mail padrão foi inserido; SMS e VOICE não podem ser desativados",
        "errorNoContactMethodsSupplied": "Deve ser fornecido pelo menos um método de contato para um usuário",
        "errorCustomFieldsInvalid": "Preencher campos personalizados obrigatórios",
        "errorUnableToLoadCountries": "Não é possível recuperar países",
        "lblWelcomeToPreparis": "Bem-vindo ao Preparis",
        "lblInitialLoginMessage": "Obrigado por escolher o Preparis. Vemos que é seu primeiro login. Reserve um momento para revisar as informações nesta página para assegurar que sejam precisas.",
        "lblOptInMobileSms": "Receber mensagens SMS de celular?",
        "lblOptInMobileVoice": "Receber mensagens de voz de celular?",
        "lblOptInPersonalSms": "Receber mensagens SMS pessoais?",
        "lblOptInAdditionalPersonalSms": "Receber mensagens SMS pessoais adicionais?",
        "lblOptInPersonalVoice": "Receber mensagens de voz pessoais?",
        "lblOptInAdditionalPersonalVoice": "Receber mensagens de voz pessoais adicionais?",
        "lblOptInPersonalEmail": "Receber mensagens de e-mail pessoais?",
        "lblOptInBusinessEmail": "Receber mensagens de e-mail do escritório?",
        "lblOptInWorkSms": "Receber mensagens SMS do escritório?",
        "lblOptInWorkVoice": "Receber mensagens de voz do escritório?",
        "lblManuallySetPassword": "Definir senha manualmente",
        "lblFloor": "Andar",
        "lblAdditionalInfo": "Informação adicional",
        "lblAdditionalLocations": "Outros locais em que o usuário trabalha",
        "lblUserActiveStatus": "Ativo",
        "lblUserDeleteStatus": "Excluído",
        "lblUserInactiveStatus": "Inativo",
        "lblUserPendingStatus": "Pendente",
        "lblUserLockedStatus": "Bloqueado",
        "lblUserStatusLegendActiveMessageOnly": "(o usuário receberá mensagens)",
        "lblUserStatusLegendDefault": "(usuário registrado e pode fazer login)",
        "lblUserStatusLegendInactive": "(o usuário não pode acessar o portal nem receber mensagens)",
        "lblUserStatusLegendPending": "(o usuário não aceitou o convite)",
        "msgManuallySetPassword1": "Normalmente, novos usuários recebem um e-mail de convite contendo um link que lhes permite criar suas próprias senhas.",
        "msgManuallySetPassword2": "Se você inserir uma senha aqui, precisará comunicá-la verbalmente.",
        "msgUserPasswordChangedSuccess": "Senha do usuário alterada com sucesso.",
        "msgUserPasswordChangedFail": "Falha ao alterar a senha do usuário.",
        "msgSaveUser": "O usuário {{firstName}} {{lastName}} foi salvo com sucesso.",
        "msgSaveUserImageSuccess": "A foto do usuário foi salva com sucesso.",
        "errorSavUserImageFailed": "Falha ao salvar a foto do usuário.",
        "magUserNameExist": "Nome de usuário já em uso por outro usuário",
        "msgInvalidName": "Substitua o nome gerado para atualizar este usuário aderente",
        "save": "Salvar",
        "initialLogin": {
            "btnText": "OK"
        }
    },
    "register": {
        "lblMyInformation": "Minha informação",
        "lblContactInformation": "Informações de contato",
        "lblAddress": "Endereço",
        "lblAddress2": "Endereço 2",
        "lblCity": "City",
        "lblState": "State",
        "lblZip": "Código postal",
        "lblCountry": "País",
        "lblLocationBuilding": "Locais/edifícios para receber notificações",
        "firstName": "Nome",
        "lastName": "Sobrenome",
        "lblLastName": "Último nome",
        "lblFirstName": "Primeiro nome",
        "lblMobilePhone": "Telefone celular",
        "lblReceiveSMS": "Receber mensagem SMS",
        "lblReceiveVoice": "Receber mensagem de voz",
        "lblReceiveEmail": "Receber mensagem de e-mail",
        "lblLoading": "Carregando...",
        "lbSelectLocations": "Sobre quais locais você gostaria de receber notificações importantes?",
        "lblValidateAddress": "Validar endereço",
        "msgRegisterCodeInvalid": "Não é possível se registrar com o código de registro. Tente outro código de registro",
        "msgRegisterCodeFail": "Falha ao se registrar com o código de registro. Tente novamente mais tarde.",
        "msgLocationRequired": "É necessário pelo menos um local para receber notificações",
        "msgCommunicationMethodRequired": "É necessário pelo menos um método de comunicação para receber notificações",
        "msgRegisterFailed": "Falha ao registrar a conta do usuário. Tente novamente mais tarde.",
        "msgRegistrationComplete": "Parabéns! Você agora está inscrito para receber notificações importantes de {{ portalName }}. Para fazer alterações em suas preferências a qualquer momento, você pode fazer login em {{ portalUrl }}",
        "msgRegisterUser": "Registrando a conta de usuário; aguarde...",
        "msgSaveUser": "Salvando informações do usuário; aguarde...",
        "msgSaveUserSuccess": "Informações do usuário salvas com sucesso",
        "msgSaveUserFailed": "Falha ao salvar as informações do usuário. Tente novamente mais tarde.",
        "msgRemoveUser": "Removendo a conta de usuário; aguarde...",
        "msgRemoveUserSuccess": "Sua conta foi removida.",
        "msgRemoveUserFail": "Falha ao remover sua conta. Tente novamente mais tarde."
    },
    "layout": {
        "aAllCallinAnnouncements": "Todos os anúncios em chamada",
        "aAllSplashMessage": "Todas as mensagens iniciais",
        "aCommunicationsDashboard": "Painel de controle de comunicações",
        "aCreateSlashMsg": "Criar uma mensagem inicial",
        "aCrisisTeam": "Equipe de Crise",
        "aEmergencyContacts": "Contatos de emergência",
        "aRecordCallinAnnouncement": "Gravar um anúncio em chamada",
        "aSendEmergencyMessage": "Enviar mensagem",
        "aStartConcall": "Iniciar uma teleconferência",
        "lblCommunicateIncident": "Comunique-se sobre um evento",
        "lblHelpAndAssistance": "Ajuda e assistência",
        "lblIncidentHistory": "Histórico de Incidentes",
        "lblIncidents": "Incidentes",
        "lblManageIncidents": "Gerenciar comunicações",
        "lblNewIncident": "Novo Incidente",
        "lblQuickSnapshot": "Instantâneo rápido",
        "lblViewOverview": "Ver visão geral",
        "aPrivacy": "Privacidade",
        "aScheduledMessages": "Mensagens Agendadas",
        "aSupport": "Suporte",
        "aTermsOfService": "Termos de serviço",
        "lblAccessAndPermissions": "Acesso e permissão",
        "lblAdditionalResources": "Mais recursos",
        "lblBookmark": "Favorito",
        "lblChecklists": "Listas de verificação",
        "lblCompanyProfile": "Perfil da empresa",
        "lblDepartments": "[[Departments]]",
        "lblDocuments": "DOCUMENTOS",
        "lblEntities": "Entidades",
        "lblFeaturedResources": "Recurso em destaque",
        "lblGroups": "Grupos",
        "lblHelp": "Ajuda",
        "lblHome": "Início",
        "lblInTouch": "Estamos sempre em contato com nosso [[clients]].",
        "lblLoading": "Carregando",
        "lblManage": "Gerenciar",
        "lblMessagingSettings": "Configurações de mensagens",
        "lblMessagingSetup": "Configuração de mensagens",
        "lblMyPreparis": "Meu Preparis",
        "lblMyProfile": "Meu perfil",
        "lblNavigatePortal": "Navegar no portal",
        "lblNewResources": "Novos Recursos",
        "lblReports": "[[Reports]]",
        "lblResources": "Recursos",
        "lblResourceTypes": "Tipos de recurso",
        "lblRoles": "Funções",
        "lblSavedMessages": "Mensagens salvas",
        "lblSecurity": "Segurança",
        "lblSettings": "Configurações",
        "lblSignout": "Sair",
        "lblSystemEmails": "E-mails do sistema",
        "lblSystemSettings": "Configurações do sistema",
        "lblTabletops": "Tampos de mesa",
        "lblTenants": "Inquilinos",
        "lblThreats": "Ameaças",
        "lblTraining": "TREINAMENTO",
        "lblUserInitiatedMessages": "Incidentes iniciados pelo usuário",
        "lblUsers": "Usuários",
        "lblWebinars": "Webinars",
        "lblHierarchy": "Hierarquia",
        "lblComplianceCalendar": "Calendário de Conformidade",
        "lblScopes": "[[Scopes]]",
        "lblTeams": "[[Teams]]",
        "lblTeamMembers": "[[Team Members]]",
        "lblItems": "[[Items]]",
        "lblIssues": "[[Issues]]",
        "lblExternalResources": "[[External Resources]]",
        "lblAssets": "[[Assets]]",
        "lblPlans": "[[Plans]]",
        "lblAdministration": "Administração",
        "lblNotifications": "Notificações",
        "lblStorageLocations": "Locais de armazenamento",
        "lblComplianceCategories": "Categorias de Conformidade",
        "lblExternalResourceTypes": "[[External Resource]] Tipos",
        "lblIq": "iQ",
        "lblPortalSnapshot": "Instantâneo de portais",
        "lblPortalsManagement": "Gerenciamento de portais",
        "lblPortalManagement": "Gerenciamento do portal",
        "lblPortalResources": "Recursos do portal",
        "lblPortalResourceViews": "Visualização de recursos do portal",
        "lblBulkUploadHistory": "Histórico de upload em massa",
        "lblApiManagement": "Gerenciamento de API",
        "lblApiManagementView": "Integrações de terceiros",
        "lblPlannerManagement": "Gestão do Planejador",
        "lblCustomFields": "Campos personalizados",
        "panic": "Painel de Pânico",
        "lblEmergencyButtonDashboard": "[[Alerts]] iniciado por celular",
        "lblBranding": "Personalização",
        "lblSelfRegistration": "Adesão",
        "scheduledConferenceCalls": "Chamadas de teleconferência",
        "messaging": "Mensagens",
        "lblRecoveryProfiles": "PERFIS DE RECUPERAÇÃO",
        "recoveryProfiles": "Perfis de recuperação",
        "lblGeofences": "Cercas virtuais",
        "lblPointsOfInterest": "Pontos de interesse",
        "lblIncidentTypes": "Tipos de Incidentes",
        "lblPublicAlertSubscriptions": "Assinaturas de [[alert]]",
        "lblIncidentTriggers": "Gatilhos de incidentes",
        "lblPanicConfig": "Configuração de pânico",
        "lblPublicAlerts": "[[Alerts]] Públicos",
        "lblGeolocations": "Geolocalização",
        "lblPortals": "Portais",
        "Alerts": "Alertes",
        "planManagement": "Gerenciamento do [[plan]]",
        "training": "Treinamento",
        "reports": "[[Reports]]",
        "settings": "Configurações",
        "alert": "[[Alert]]",
        "sendMessage": "Enviar mensagem",
        "admin": "Admin",
        "privacy": "Privacidade",
        "aboutUs": "Sobre nós",
        "incidentManagement": "[[Incident Manager]]",
        "lblMobileEmergencyButtons": "Configurações de [[alert]] iniciado por celular",
        "lblConfigureBulkUpload": "Configurar upload em massa",
        "planning": "Planejamento",
        "lblChangeLanguage": "Alterar idioma",
        "lblExercises": "Exercícios",
        "lblExerciseDashboard": "Painel",
        "lblExerciseRoadmap": "Roteiro",
        "lblExerciseScenarioManager": "Cenários",
        "lblExerciseReports": "[[Reports]]",
        "lblExerciseSystemScenarios": "Cenários do Sistema",
        "lblExerciseExerciseTypes": "Tipos de Exercício",
        "lblExerciseThreadHazards": "Ameaça/Perigos",
        "lblIncidentManagementHistory": "Histórico de incidentes",
        "language": {
            "en": "English",
            "es": "Español",
            "fr": "Français",
            "pt": "Português"
        },
        "changeLanguageError": "Ocorreu um erro inesperado no servidor.  Entre em contato com o suporte técnico.",
        "calendar": {
            "dayNames": {
                "sunday": "Domingo",
                "monday": "Segunda-feira",
                "tuesday": "Terça-feira",
                "wednesday": "Quarta-feira",
                "Thursday": "Quinta-feira",
                "friday": "Sexta-feira",
                "saturday": "Sábado"
            },
            "dayNamesShort": {
                "sunday": "Domingo",
                "monday": "Seg",
                "tuesday": "Ter",
                "wednesday": "Qua",
                "thursday": "Qui",
                "friday": "Sex",
                "saturday": "Sáb"
            },
            "dayNamesMin": {
                "sunday": "Dom",
                "monday": "Se",
                "tuesday": "Te",
                "wednesday": "Qu",
                "thursday": "Qui",
                "friday": "Se",
                "saturday": "Sá"
            },
            "monthNames": {
                "january": "Janeiro",
                "february": "Fevereiro",
                "march": "Março",
                "april": "Abril",
                "may": "Maio",
                "june": "Junho",
                "july": "Julho",
                "august": "Agosto",
                "september": "Setembro",
                "october": "Outubro",
                "november": "Novembro",
                "december": "Dezembro"
            },
            "monthNamesShort": {
                "january": "Jan",
                "february": "Fev",
                "march": "Mar",
                "april": "Abr",
                "may": "Maio",
                "june": "Jun",
                "july": "Jul",
                "august": "Ago",
                "september": "Set",
                "october": "Out",
                "november": "Nov",
                "december": "Dez"
            },
            "today": "Hoje",
            "clear": "Claro",
            "weekHeader": "Sem"
        },
        "planner": "[[Planner]]",
        "exerciseManager": "[[Exercise Manager]]",
        "alerts": "[[Alerts]]",
        "footerCopyRight": "Copyright© 2007-{{year}} Preparis. Todos os direitos reservados."
    },
    "callinAnnouncements": {
        "btnCall": "Ligar",
        "btnDeactivate": "Desativar",
        "btnMakeActive": "Tornar ativo",
        "btnNewAnnouncement": "Novo anúncio",
        "btnSaveNewAnnouncement": "Salvar anúncio",
        "errorAnnouncementDeleted": "Ocorreu um problema ao excluir este anúncio. Tente novamente mais tarde.",
        "errorAtleastOneRecordingOption": "Você deve especificar pelo menos uma opção de gravação",
        "errorCallToRecordPhoneNumberRequired": "Especifique um número de telefone para sua gravação.",
        "errorUnableToRecordMessage": "Não foi possível gravar a mensagem neste momento. Tente novamente mais tarde.",
        "lblActivatedOn": "Ativado em",
        "lblActiveAnnouncement": "Ativar anúncio",
        "lblActiveAnnouncementDtl": "Os usuários ouvirão esta mensagem quando ligarem.",
        "lblActiveAnnouncementNote": "Só pode haver 1 anúncio ativo por vez.",
        "lblAnnouncementActive": "Ativação de anúncio concluída",
        "lblAnnouncementActivity": "Atividade de anúncio em chamada",
        "lblAnnouncementDeactivated": "A desativação do anúncio foi bem-sucedida.",
        "lblAreYourSureActivate": "Ativar anúncio em chamada",
        "lblAreYouSureActivateWithoutReplace": "{{selectedDescriptionEncoded}} se tornará o anúncio em chamada ativo.",
        "lblAreYouSureActiveWithReplace": "{{selectedDescriptionEncoded}} se tornará o anúncio em chamada ativo. {{activeDescriptionEncoded}} será desativado.",
        "lblCallToRecord": "Chamada para gravar",
        "lblCallToRecordInstructions": "Digite o número de telefone para o qual deseja ligar e clique em Ligar. Você receberá uma ligação em alguns instantes para gravar sua mensagem. Depois de gravar sua mensagem, você poderá ouvi-la e regravá-la, se desejar.",
        "lblChooseRecordingOption": "Escolha uma das seguintes opções para gravar seu anúncio.",
        "lblConfirmDelete": "Confirmar exclusão",
        "lblDefaultNewAnnouncementTitle": "Anúncio de nova chamada",
        "lblHeader": "Anúncios em chamada para {{callInAnnouncementPhoneNumber}}",
        "lblInactiveAnnouncement": "Anúncios inativos",
        "lblLastModifiedOn": "Última modificação em",
        "lblLoading": "Aguarde...",
        "lblMessageColHdr": "Mensagem",
        "lblMessageNameDescColHdr": "Nome/Descrição",
        "lblNameDesc": "Nome/Descrição",
        "lblNoActiveAnnouncementsBody": "A mensagem padrão do sistema será usada informando que não há anúncio neste momento.",
        "lblNoActiveAnnouncementsHdr": "Nenhum anúncio ativo",
        "lblNone": "Nenhum",
        "lblNote": "Nota",
        "lblRecordedBy": "Gravado por",
        "lblRecordedByColHdr": "Gravado por",
        "lblRecordedColHdr": "Gravado",
        "lblRecording": "Gravando",
        "lblRecordingCanceled": "A gravação foi cancelada.",
        "lblStatusColHdr": "Status",
        "lblTextToSpeech": "Texto para fala",
        "mgsCallinNotSetupHdr": "Seus anúncios em chamada não estão configurados",
        "msgAnnouncementDeleted": "O anúncio foi excluído com sucesso.",
        "msgAnnouncementSaved": "O anúncio em chamada foi salvo com sucesso.",
        "msgCallinNotSetup": "Anúncios em chamada integrados facilitam e agilizam manter suas equipes de crise e funcionários atualizados com o desenvolvimento de informações durante um incidente. Anúncios em chamada são uma ótima plataforma para comunicar atualizações de informações sobre tempestades de inverno e fechamentos de escritórios. Basta gravar uma mensagem e ativá-la; seu pessoal pode ligar para ouvir as informações mais recentes.",
        "msgCallinNotSetup2": "Solicite a adição deste serviço sem custo adicional entrando em contato com",
        "callinAnnouncements": "Anúncios de Chamada",
        "removeConfirm": {
            "header": "Remover anúncio em chamada",
            "body": "Tem certeza de que deseja remover este anúncio em chamada?",
            "text": "Sim"
        },
        "deactivateConfirm": {
            "header": "Desativar anúncio em chamada",
            "body": "O anúncio em chamada {{description}} será desativado. O anúncio em chamada padrão será usado, a menos que você ative outro anúncio.",
            "text": "Sim"
        },
        "showAdvanced": "Permitir vários anúncios em chamada ativos",
        "addNewOption": "Adicionar nova opção filho",
        "addNewSiblingRight": "Adicionar nova opção à direita",
        "addNewSiblingLeft": "Adicionar nova opção à esquerda",
        "addNewSiblingAbove": "Adicionar nova opção acima",
        "addNewSiblingBelow": "Adicionar nova opção abaixo",
        "copyOption": "Opção de cópia",
        "editOption": "Editar {{ optionPrompt }}",
        "callinAnnouncement": "Anúncio de chamada",
        "callInAnnouncementOption": "Opção de anúncio em chamada",
        "lblCallInAnnouncementValue": "Pressione {{optionValue}}",
        "lblInstructions": "Instruções",
        "resetToDefaultInstructions": "Redefinir para instruções padrão",
        "newCallinOptionModal": {
            "headerNew": "Criar nova opção em chamada",
            "headerEdit": "Editar opção em chamada '{{ optionPrompt }}'",
            "optionValue": "Valor",
            "optionPrompt": "Prompt",
            "optionInstructions": "Instruções",
            "optionCallInAnnouncement": "Anúncio em chamada",
            "selectCallInAnnouncementConfirmation": {
                "header": "Selecionar anúncio em chamada para a opção",
                "body": "Tem certeza que deseja selecionar '{{ announcementDescription }}' para a opção?",
                "text": "Selecione"
            },
            "btnUnselect": "Desmarcar",
            "btnSelect": "Selecione"
        },
        "saveCallinAnnouncementOptionConfirm": {
            "header": "Salvar opção de anúncio em chamada",
            "body": "Tem certeza de que deseja salvar a opção de anúncio em chamada '{{ optionPrompt }}'?",
            "text": "Salvar"
        },
        "deleteCallinAnnouncementOptionConfirm": {
            "header": "Excluir opção de anúncio em chamada",
            "body": "Tem certeza de que deseja excluir a opção de anúncio em chamada '{{ optionPrompt }}'?",
            "text": "Excluir"
        },
        "deleteAllCallinAnnouncementOptionsConfirm": {
            "header": "Excluir todas as opções de anúncio em chamada",
            "body": "Alternar para o modo único de anúncio em chamada ativo excluirá as opções de anúncio em chamada. Tem certeza de que deseja alternar para o modo único de anúncio em chamada ativo e excluir as opções?",
            "text": "Excluir e alternar"
        },
        "errors": {
            "optionWithSamePromptError": "Uma opção em chamada com o prompt '{{ optionPrompt }}' já existe."
        },
        "copyCallinAnnouncementOption": {
            "header": "Copiar a opção de anúncio em chamada '{{ optionPrompt }}'",
            "body": "Selecionar opção de anúncio em chamada de destino",
            "copySubHierarchy": "Copiar sub-hierarquia",
            "errorSameOption": "Esta opção está sendo copiada",
            "errorDuplicatePrompt": "'{{ optionPrompt }}' já tem uma opção filho com o mesmo prompt",
            "errorMaxChildNodesNumber": "'{{ optionPrompt }}' já tem {{ maxChildNodes }} opções filho"
        },
        "msgCopyCallinAnnouncement": {
            "success": "A opção de anúncio em chamada foi copiada",
            "error": "Falha ao copiar a opção de anúncio em chamada"
        }
    },
    "iq": {
        "common": {
            "moveError": "Recurso iQ não movido",
            "moveTeamTooltip": "Selecione a equipe de destino.",
            "assignToTeamMember": "Atribua a um membro da equipe de destino na equipe de destino."
        },
        "itemType": {
            "Document": "Documento",
            "Storage Item": "Item de armazenamento",
            "Other": "Outro",
            "Task/Reminder": "Tarefa/Lembrete"
        },
        "columnsSelected": "{0} colunas selecionadas"
    },
    "incidentManagement": {
        "lblManageIncident": "Gerenciar incidente",
        "createNewIncident": "Criar novo incidente",
        "recentIncidents": "Incidentes recentes",
        "noRecentIncidents": "Nenhum incidente recente/ativo",
        "lblIncidentDetails": "Detalhes do incidente",
        "lblIncidentRetrospective": "Retrospectiva do Incidente",
        "lblNewIncident": "Criar novo incidente",
        "communicationLog": "Log de comunicação",
        "addIncidentNotes": "Adicionar notas do incidente",
        "incidentNotes": "Notas do incidente",
        "addNotes": "Adicionar notas",
        "resolveIncident": "Resolver incidente",
        "viewPlans": "Visualizar planos",
        "lblDepartmentPlans": "Planos do departamento",
        "standByAll": "Colocar todos em [[alert]]",
        "standBySelected": "Estado de [[alert]] selecionado",
        "standDownSelected": "Liberar selecionados do estado de [[alert]]",
        "standDownAll": "Liberar tudo/todos(as) do estado de [[alert]]",
        "activateAll": "Ativar tudo/todos(as)",
        "activateSelected": "Ativar selecionado(s)",
        "deactivateSelected": "Desativar selecionado(s)",
        "deactivateAll": "Desativar tudo/todos(as)",
        "active": "Ativo",
        "inactive": "Inativo",
        "standBy": "Colocar em estado de [[alert]]",
        "StandDown": "Liberar do estado de [[alert]]",
        "StandDownAll": "Liberar tudo/todos(as) do estado de [[alert]]",
        "Active": "Ativo",
        "Stand By": "Colocar em estado de [[alert]]",
        "departmentPlanActivationHeader": "Ativação do plano do departamento",
        "locationPlanActivationHeader": "Ativação do plano do local",
        "departmentPlanActivationBodyMsg": "Tem certeza de que deseja ativar todos os departamentos?",
        "locationPlanActivationBodyMsg": "Tem certeza de que deseja ativar todos os locais?",
        "departmentPlanActivationAssignedBodyMsg": "Tem certeza de que deseja ativar todos os seus departamentos atribuídos?",
        "locationPlanActivationAssignedBodyMsg": "Tem certeza de que deseja ativar todos os seus locais atribuídos?",
        "departmentPlanStandByHeader": "Colocar em [[alert]] o plano do departamento",
        "locationPlanStandByHeader": "Colocar em [[alert]] o plano do local",
        "departmentPlanStandByBodyMsg": "Tem certeza de que deseja colocar todos os departamentos em Estado de [[alert]]?",
        "locationPlanStandByBodyMsg": "Tem certeza de que deseja colocar todos os locais em Estado de [[alert]]?",
        "departmentPlanStandByAssignedBodyMsg": "Tem certeza de que deseja colocar todos os seus departamentos atribuídos em Estado de [[alert]]?",
        "locationPlanStandByAssignedBodyMsg": "Tem certeza de que deseja colocar todos os locais atribuídos em Estado de [[alert]]?",
        "departmentPlanDeactivateAllHeader": "Desativação do plano do departamento",
        "locationPlanDeactivateAllHeader": "Desativação do plano do local",
        "departmentPlanDeactivateAllBody": "Tem certeza de que deseja desativar todo o departamento?",
        "locationPlanDeactivateAllBody": "Tem certeza de que deseja desativar todo o local?",
        "departmentPlanDeactivateAssignedBodyMsg": "Tem certeza de que deseja desativar todo o seu departamento atribuído?",
        "locationPlanDeactivateAssignedBodyMsg": "Tem certeza de que deseja desativar todo local atribuído?",
        "departmentPlanStandDownAllHeader": "Liberar o plano do departamento do estado de [[alert]]",
        "locationPlanStandDownAllHeader": "Liberar o plano do local do estado de [[alert]]",
        "departmentPlanStandDownAllBody": "Tem certeza de que deseja liberar todos os departamentos do estado de [[alert]]?",
        "locationPlanStandDownAllBody": "Tem certeza de que deseja liberar todos os locais do estado de [[alert]]?",
        "departmentPlanStandDownAssignedBodyMsg": "Tem certeza de que deseja liberar todos os seus departamentos atribuídos do estado de [[alert]]?",
        "locationPlanStandDownAssignedBodyMsg": "Tem certeza de que deseja liberar todos os locais atribuídos do estado de [[alert]]?",
        "notDepartmentPlansAvailable": "Não há planos disponíveis.",
        "startHere": "Comece aqui",
        "selectedMembers": "Membros Selecionados",
        "incidentHistory": "Histórico de incidentes",
        "plansActivated": "Plano(s) ativado(s)",
        "pagingDescription": "{{ pageStart }}-{{ pageEnd }} de {{ totalCount }}",
        "enterExerciseMode": "Entrar no modo de exercício",
        "exitExerciseMode": "Sair do modo de exercício",
        "exerciseMode": "Modo de exercício",
        "leaveExerciseTitle": "Modo de exercício",
        "areYouSureLeaveExercise": "Tem certeza de que deseja sair do exercício?",
        "liveIncidents": "Incidentes ao vivo",
        "incidentExercises": "Exercícios do incidente",
        "exercise": "Exercício",
        "portalExerciseAssociateToIncident": "Exercício já associado a outro incidente",
        "dependencies": {
            "dependentProcesses": "Processos dependentes",
            "noDependentProcesses": "Sem [[processes]] dependentes",
            "dependencyListIntoMessage": "Lista de dependências na mensagem",
            "dependencies": "Dependências"
        },
        "states": {
            "viewAll": "Visualizar tudo/todos(as)",
            "active": "Ativo",
            "resolved": "Resolvido(a)",
            "archived": "Arquivado(a)",
            "activated": "Ativado(a) em",
            "deactivated": "Desativado"
        },
        "conferenceCallStarted": "Chamada de conferência iniciada",
        "emailSent": "E-mail enviado",
        "messageSent": "Mensagem enviada",
        "export": {
            "allIncidents": "Todos os incidentes",
            "displayedIncidents": "Incidentes exibidos",
            "header": "Exportar incidentes",
            "body": "Deseja exportar apenas os incidentes {{ x }} exibidos ou todos os incidentes?",
            "successMsg": "Exportação gerada com sucesso",
            "errorMsg": "Erro ao gerar exportação",
            "fileName": "Incidentes.csv"
        },
        "noNotes": "Sem notas do incidente",
        "actionPlans": "Planos de ação BC",
        "planActivationProcessStateTypes": {
            "notStarted": "Não iniciado(a)",
            "inProgress": "Em andamento",
            "skip": "Pular",
            "done": "Pronto(a)"
        },
        "authorized": "Autorizado a entrar em contato com fornecedores",
        "contacted": "Fornecedor(es) contatado(s)",
        "mobilized": "Colaboradores Mobilizados",
        "verified": "Verificado(a)",
        "applications": "[[Application]](s)",
        "equipment": "[[Equipment]]",
        "confirmReadiness": "Confirmar prontidão",
        "passDue": "Vencido(a)",
        "activateDetails": {
            "activateDetails": "Ativar detalhes",
            "calendarDescription": "Este calendário irá filtrar as próximas datas críticas."
        },
        "noCriticalDate": "Nenhuma data crítica",
        "noAssignees": "Nenhum funcionário autorizado",
        "noEmployees": "Nenhum funcionário mobilizado",
        "noEquipment": "Nenhum equipamento verificado",
        "noApplications": "Nenhum aplicativo verificado",
        "noVendors": "Nenhum fornecedor de contato",
        "noLocation": "Nenhum local",
        "msgUpdateStatusSuccess": "Status de ativação salvo",
        "msgUpdateStatusFailed": "Falha ao salvar o status de ativação",
        "msgActionPlanReady": "Plano de ação para {{ process }} está pronto",
        "communicationSidebar": {
            "include": "Incluir",
            "includeApplicationList": "Lista de aplicativos na mensagem",
            "includeEquipmentList": "Lista de equipamentos na mensagem",
            "includeAdditionalInstructions": "Instruções adicionais na mensagem",
            "attachments": "Anexos",
            "emailAttachmentsSizeError": "O tamanho dos anexos de e-mail excede o permitido de {{maxSize}} Mb. O e-mail não pode ser enviado.",
            "loadingMessageParticipantsError": "Erro ao carregar os participantes da comunicação"
        },
        "dashboard": {
            "overallProgress": "Progresso geral",
            "progressByRto": "Progresso por RTO",
            "escalatedActionPlans": "Planos de ação encaminhados",
            "escalatedActionPlansDescription": "Planos de ação com [[issues]] encaminhados",
            "departmentProgress": "Progresso do departamento",
            "locationProgress": "Progresso do local",
            "escalatedIssues": "[[Issues]] encaminhados",
            "allIssues": "Todos os [[issues]]"
        },
        "issues": {
            "issue": "[[Issue]]",
            "issuesListTitle": "Meus [[issues]]",
            "reportIssue": "Denunciar [[issues]",
            "issueTitle": "Nome dos [[issues]]",
            "resolved": "Resolvido(a)",
            "dateTimeCreated": "Data/hora",
            "lblOwner": "Proprietário",
            "lblCreatedOn": "Criado(a) em",
            "lblStatus": "Status",
            "lblDepartment": "Departamento",
            "lblProcess": "Processo",
            "lblCategory": "Categoria",
            "lblDescription": "Descrição",
            "addComment": "Adicionar comentário",
            "escalateToManagement": "Encaminhar para gerência",
            "resolveIssue": "Resolver [[issues]",
            "reopenIssue": "Reabrir [[issues]",
            "edit": "Editar",
            "status": {
                "70cd9cf8-947a-43a1-8f19-f27b58da065e": "Abrir/Aberto",
                "2e70fb00-5551-42f8-8057-a3a45dcfbb19": "Resolvido(a)"
            },
            "lblChangeStatus": {
                "70cd9cf8-947a-43a1-8f19-f27b58da065e": "Resolver [[issues]",
                "2e70fb00-5551-42f8-8057-a3a45dcfbb19": "Reabrir [[issues]"
            },
            "comment": "Comentar",
            "msgErrorLoadingComments": "Erro ao carregar os comentários do [[issues] de ativação do plano.",
            "escalatedIssuesOnly": "Somente [[issues]] encaminhados",
            "issueDetails": "Detalhes do [[issues]"
        },
        "incidentRetrospective": {
            "incidentName": "Nome do incidente",
            "incidentType": "Tipo de incidente",
            "incidentDuration": "Duração do incidente",
            "incidentCreator": "Incidente criado por",
            "description": "Descrição",
            "activePlans": "Planos ativados",
            "issues": "[[Issues]]",
            "Communications": "Comunicações",
            "executiveSummary": "Resumo",
            "retrospective": "Retrospectiva",
            "incidentNotes": "Notas do incidente",
            "department": "Departamento",
            "location": "Localização",
            "process": "Processo",
            "title": "Título",
            "teamLead": "Líder da equipe",
            "deliveryMethod": "Método de entrega",
            "dateSent": "Data de envio",
            "totalRecipients": "Destinatários totais",
            "viewPlans": "Visualizar planos",
            "publishIncidentRetrospective": "Publicar retrospectiva do incidente",
            "unResolveIncident": "Reativar incidente",
            "startNote": "Começar a fazer isso",
            "continueNote": "Continuar fazendo isso",
            "stopNote": "Parar de fazer isso",
            "incidentInvalid": "O ID do incidente é inválido",
            "theIncidentNeedsToBeResolve": "O incidente precisa ser resolvido para ver sua retrospectiva",
            "generateAuditReport": "[[Reports]] de auditoria",
            "errorGeneratingAuditReport": "Erro ao gerar [[reports]] de auditoria",
            "modalHeader": "Publicar retrospectiva do incidente",
            "modalBody": "Tem certeza de que deseja publicar esta retrospectiva?",
            "publish": "Publicar",
            "modalEditor": "{{field}} para a retrospectiva",
            "retrospectiveSaveSuccessfully": "A retrospectiva foi salva com sucesso",
            "retrospectiveSaveUnsuccessfully": "A retrospectiva não foi salva com sucesso",
            "summaryPlaceholder": "Insira as informações do resumo que pertencem ao incidente/exercício",
            "startPlaceholder": "por exemplo, Devemos agendar mais exercícios",
            "continuePlaceholder": "por exemplo, Comunicar de forma eficaz",
            "stopPlaceholder": "por exemplo, Usar o mesmo cenário para exercícios",
            "incidentReactivatedSuccessfully": "Incidente reativado com sucesso",
            "incidentReactivatedUnsuccessfully": "Incidente não reativado com sucesso",
            "reactivateModalHeader": "Reativar incidente",
            "reactivateModalBody": "Tem certeza de que deseja reativar este incidente?"
        },
        "contactTeamLead": "Contate [[Team Lead]]",
        "contactSubjectMatterExpert": "Contactar SME",
        "subjectMatterExpert": "SME"
    },
    "exerciseManager": {
        "scenarios": "Cenários",
        "systemScenarios": "Cenários do sistema",
        "launchExercise": "Rodar exercício",
        "exerciseManager": "[[Exercise Manager]]",
        "assignedScenarios": "Cenários atribuídos",
        "assignedExercises": "Exercícios atribuídos",
        "newExerciseType": "Novo tipo de exercício",
        "newSystemExerciseType": "Novo tipo de exercício do sistema",
        "newThreadHazard": "Nova ameaça/[[risk]]",
        "newSystemThreadHazard": "Nova ameaça/[[risk]] do sistema",
        "systemExerciseType": "Tipo de exercício do sistema",
        "systemThreadHazard": "Ameaça/[[risk]] do sistema",
        "roadmap": {
            "theWeekOf": "A semana de...",
            "exerciseRoadmap": "Roteiro de exercícios",
            "weekOf": "Semana de",
            "roadmapDescription": "Este roteiro fornece uma visão rápida de seus exercícios programados. Para agendar um novo exercício, abra o painel direito e selecione o plano que deseja agendar para iniciar o [[process]].",
            "scheduleExerciseDescription": "Agende o plano no qual você gostaria de realizar um exercício ou teste.",
            "openExercise": "Abrir exercício",
            "firstScheduled": "Ir para o primeiro exercício agendado",
            "noFirstScheduledExercise": "Nenhum exercício futuro foi agendado para {{name}}",
            "weekOption": "4 Semanas",
            "monthOption": "12 Meses"
        },
        "scenarioManager": {
            "60024": "Um cenário de exercício existente já tem este nome",
            "scenarioName": "Nome do cenário",
            "exerciseType": "Tipo de exercício",
            "hazardType": "Ameaça/[[risk]]",
            "description": "Descrição",
            "assumptions": "Premissas",
            "objectives": "Objetivos",
            "expectations": "Expectativas",
            "metrics": "Métricas",
            "selectAExerciseType": "Selecione um tipo de exercício",
            "selectAHazardType": "Selecione um tipo de ameaça/[[risk]]",
            "successOnSave": "O cenário foi salvo com sucesso",
            "clear": "Limpar"
        },
        "sideMenu": {
            "deleteScenario": "Excluir cenário",
            "loadScenario": "Carregar cenário",
            "successOnDelete": "O cenário foi excluído com sucesso",
            "failedToDelete": "O cenário não foi excluído com sucesso",
            "removeConfirmationHeader": "Remover cenário",
            "removeConfirmationBody": "Tem certeza de que deseja remover o cenário {{ scenarioName }}?",
            "sideMenuTitleInfo": "Crie ou carregue um cenário para que ele possa ser utilizado em exercícios futuros.",
            "scenarios": "Cenários",
            "overrideBody": "Já existe um cenário com o mesmo nome",
            "numberOfProcesses": "Número de [[processes]]",
            "failedToGetExerciseTargetsInfo": "Ocorreu um erro ao tentar obter as informações dos alvos do exercício.",
            "notScheduled": "Não agendado(a)",
            "newScenario": "Criar cenário",
            "copyScenario": "Copiar cenário como novo"
        },
        "modalEditor": "{{field}} para o cenário",
        "exercises": {
            "60033": "Cronograma de exercícios sobreposto",
            "60035": " O exercício está associado a um incidente",
            "exercises": "Exercícios",
            "updateScenario": "Atualizar cenário",
            "exerciseDate": "Data do exercício",
            "datePlaceholder": "Selecione a data de início e término",
            "exerciseName": "Nome do exercício",
            "details": "Detalhes",
            "selectedTesters": "Testadores selecionados",
            "modalEditor": "{{field}} para o exercício",
            "startTime": "Horário de início",
            "endTime": "Horário de término",
            "startAndEndDate": "Data de início/término",
            "startDatePlaceholder": "Selecione uma data de início",
            "endDatePlaceholder": "Selecione uma data de término",
            "processes": "Processos",
            "successOnSave": "O exercício foi salvo com sucesso",
            "departmentInvalid": "O departamento é inválido",
            "locationInvalid": "O local é inválido",
            "testerAddedSuccessfully": "Testador adicionado com sucesso",
            "testerRemoveSuccessfully": "Testador removido com sucesso",
            "testerAddedUnsuccessfully": "Testador não adicionado com sucesso",
            "testerRemoveUnsuccessfully": "Testador não removido com sucesso",
            "scheduleAnExerciseFor": "Agendar um exercício para",
            "deleteExerciseSuccess": "Exercício removido",
            "deleteExerciseFail": "Falha ao remover o exercício",
            "saveScenario": "Salvar cenário"
        },
        "exerciseTypeModal": {
            "title": "Tipo de exercício",
            "desc": "Adicione ou edite um tipo de exercício a ser usado para este [[exercise manager]]",
            "typeNamePlaceholder": "Nome do tipo de exercício",
            "exerciseTypes": "Exercícios",
            "exerciseTypeInUseError": "O exercício está atribuído a cenários ou exercícios ativos",
            "duplicateExerciseTypeNameError": "Nome do tipo de exercício duplicado",
            "systemExerciseTypeEditMessage": "Os tipos de exercício do sistema não podem ser editados.",
            "system": {
                "header": "Remover exercício",
                "body": "Deseja remover este tipo de exercício apenas deste portal ou de todos os portais?",
                "removeFromThisPortal": "Remover deste portal",
                "removeFromAllPortals": "Remover de todos os portais"
            },
            "portal": {
                "header": "Remover exercício",
                "body": "Tem certeza de que deseja remover este tipo de exercício?",
                "ok": "OK"
            }
        },
        "threadHazardModal": {
            "title": "Ameaça/[[risk]]",
            "desc": "Adicione ou edite uma ameaça/[[risk]] a ser usado para este [[exercise manager]]",
            "threadHazardNamePlaceholder": "Nome da ameaça/[[risk]]",
            "threadHazards": "ameaça/[[risk]]s",
            "threadHazardInUseError": "threadHazard está atribuído a cenários ou exercícios ativos",
            "duplicateThreadHazardNameError": "Nome da ameaça/[[risk]] duplicado",
            "systemThreadHazardEditMessage": "A ameaça/[[risk]]s do sistema não podem ser editados.",
            "system": {
                "header": "Remover ameaça/[[risk]]",
                "body": "Deseja remover esta ameaça/[[risk]] apenas deste portal ou de todos os portais?",
                "removeFromThisPortal": "Remover deste portal",
                "removeFromAllPortals": "Remover de todos os portais"
            },
            "portal": {
                "header": "Remover ameaça/[[risk]]",
                "body": "Tem certeza de que deseja remover esta ameaça/[[risk]]?",
                "ok": "OK"
            }
        }
    },
    "selectPortalFunctionalAreaForDepartmentModal": {
        "headerLabel": "Associar departamento do responsável pelo [[planner]]",
        "searchItemLabel": "Pesquisar departamentos do responsável pelo [[planner]]",
        "currentItemLabel": "Departamento do responsável pelo [[planner]] atualmente associado",
        "resultLabel": "Departamentos do responsável pelo [[planner]]",
        "explanationText": "O nome do departamento do responsável pelo [[planner]] selecionado será alterado para corresponder ao nome do departamento.",
        "matchingNameFAAssociationMessage": "Um departamento do responsável pelo [[planner]] com o mesmo nome '{{name}}' foi encontrado. Gostaria de vincular esses departamentos?",
        "UnAssociateMessage": "Tem certeza de que deseja desvincular o '{{name}}' deste departamento?",
        "errFailedToLoad": "Erro ao carregar os departamentos do responsável pelo [[planner]] disponíveis",
        "linkDescription": "Criar novo departamento bc {{departmentName}} e vinculá-lo ao departamento."
    },
    "textEditor": {
        "tags": "Tag",
        "element": "Elemento"
    }
}
