{
    "incident": {
        "incident": "Incident",
        "incidents": "Incidents",
        "incidentHistory": "Incident History",
        "communicate": "Communicate",
        "communicateToAll": "Communicate to all",
        "crisisTeam": "Crisis Team",
        "history": "History",
        "incidentName": "Incident Name",
        "incidentNotes": "Incident Notes",
        "incidentType": "Incident Type",
        "originalSender": "Original Sender",
        "originalLocation": "Original [[location]]",
        "created": "Created",
        "startedDate": "Started",
        "resolved": "Resolved",
        "criteria": "Affecting users who are in",
        "roles": "Roles",
        "groups": "Groups",
        "departments": "[[Departments]]",
        "locations": "Locations",
        "incidentResolved": "Resolved",
        "incidentAttachments": "Attachments",
        "detailsTabText": "Detail",
        "communicationLogTabText": "Communication Log",
        "replyTabTooltip": "Reply",
        "btnArchive": "Archive",
        "btnUnArchive": "Restore",
        "btnSaveAsNewAttachment": "Save as new attachment",
        "btnReplaceExistingAttachment": "Replace existing attachment",
        "btnUploadAttachment": "Upload Attachment",
        "uploadingFile": "Uploading File",
        "dropFile": "Drop File Here",
        "dropFiles": "Drop Files Here",
        "msgNoteAdded": "Note added",
        "msgNoteAddError": "Failed to add note",
        "msgNoteDeleted": "Note deleted",
        "msgNoteDeleteError": "Failed to delete note",
        "btnSendMessage": "Send Message",
        "btnConferenceCall": "Conference Call",
        "recipients": "Recipients",
        "messageContent": "Message Content",
        "attachments": "Attachments",
        "totalRecipients": "Total Recipients",
        "totalMessages": "Total Messages",
        "totalResends": "Total Resends",
        "totalDevices": "Total Devices",
        "remainingResends": "Remaining",
        "summary": "Summary",
        "responseSummary": "Response Summary",
        "email": "Email",
        "phone": "Phone",
        "contactPreference": "Contact Preference",
        "languagePreference": "Language Preference",
        "responseDateTime": "Response Date",
        "response": "Response",
        "cancelResendSuccess": "Cancel Resend Successful",
        "cancelResendFailed": "Cancel Resend Failed",
        "recipientMessage": "Recipient Message",
        "respondedFrom": "Responded From",
        "allResponses": "All Responses",
        "allRoles": "All Roles",
        "allLocations": "All Locations",
        "allDepartments": "All [[Departments]]",
        "allGroups": "All Groups",
        "allTenants": "All Tenants",
        "unknown": "Unknown",
        "noResponse": "No Response",
        "name": "Name",
        "message": "Message",
        "messages": "Messages",
        "events": "Events",
        "location": "[[Location]]",
        "lastResponseFrom": "Last Response From",
        "latestResponseDate": "Latest Response Date",
        "updateResponse": "Update Response",
        "lblSearch": "Search",
        "btnExport": "Export",
        "refresh": "Refresh",
        "details": "Details",
        "affectedUsers": "Affected Users",
        "startDate": "Start Date",
        "createIncident": "Create Incident",
        "btnCreateEvent": "New Event",
        "lblAdditionalRoles": "Manually Added Roles",
        "lblAdditionalLocations": "Manually Added Locations",
        "lblAdditionalGroups": "Manually Added Groups",
        "lblAdditionalDepartments": "Manually Added [[Departments]]",
        "lblAdditionalTenants": "Manually Added Tenants",
        "invalidUserResponseType": "Invalid response type",
        "saveEventNameChangeSuccess": "Event rename completed successfully",
        "saveEventNameChangeFailed": "Event rename failed",
        "desktop": "Desktop",
        "mobileApp": "Mobile App",
        "errorLoadingResponseSummaryMsg": "Error loading response summary",
        "errorLoadingMessageRecipients": "Error loading message recipients",
        "errorLoadingMessageRecipientSummary": "Error loading message recipient summary",
        "errorLoadingMessageJobDetails": "Error loading details of individual message jobs",
        "noAutoReplyMessage": "No auto reply message",
        "archiveConfirmation": {
            "header": "Are you sure you want to archive this incident?",
            "body": "Once an incident is archived you will no longer be able to see it in the active incidents list.",
            "btnText": "Yes",
            "text": "Yes"
        },
        "unArchiveConfirmation": {
            "header": "Are you sure you want to restore this incident?",
            "body": "By restoring the incident you will be able to see it in the active incidents list.",
            "btnText": "Yes",
            "text": "Yes"
        },
        "resolveConfirmation": {
            "header": "Are you sure you want to resolve this incident?",
            "body": "Once an incident is resolved it can be archived.",
            "btnText": "OK"
        },
        "deleteDocumentConfirmation": {
            "header": "Remove Confirmation",
            "body": "Are you sure you want to delete attachment {{ docName }}?",
            "btnText": "OK"
        },
        "cancelConfirmation": {
            "header": "Cancel incident?",
            "body": "Are you sure you want to cancel this incident? All changes will be lost.",
            "text": "Yes"
        },
        "cancelResendConfirmation": {
            "header": "Confirm Resend Cancellation",
            "body": "Are you sure you to want cancel the remaining resends of this message?",
            "text": "Yes"
        },
        "attachmentDialog": {
            "select": "Select",
            "selectDocument": "Select Document",
            "selectFile": "Select File",
            "chooseFile": "Choose File",
            "replaceExisting": "",
            "saveAsNew": ""
        },
        "viewParticipants": "View Recipients",
        "responsesSettings": {
            "errors": {
                "definitionsAreRequired": "Response Definitions are required.",
                "keysAreRequired": "Response Keys are required.",
                "duplicatesAreNotAllowed": "Duplicate Response Keys are not allowed.",
                "definitionIsRequiredIfKeyIsNumeric": "Response Definition is required if Response Key is numeric."
            }
        },
        "showArchived": "Show Archived",
        "showCurrent": "Show Current",
        "newIncident": "New Incident",
        "status": "Status",
        "type": "Type",
        "dateCreated": "Date Created",
        "participantsCriteria": "Participants Criteria",
        "messageTypes": "Message Types",
        "numberOfRecipients": "Number of Recipients",
        "viewCriteria": "View Criteria",
        "lblSms": "SMS",
        "lblDesktop": "Desktop",
        "lblMobile": "Mobile",
        "lblVoice": "Voice",
        "lblConferenceCall": "Conference Call",
        "lblPushMessage": "Push Message",
        "lblEmail": "Email",
        "incidentsFilterPlaceHolder": "Refine results by: Status, Type, Name or Criteria",
        "restoreSuccess": "Incident successfully restored",
        "archiveSuccess": "Incident successfully archived",
        "restoreError": "Error while attempting to restore incident",
        "archiveError": "Error while attempting to archive incident",
        "incidentCommunication": "Communication",
        "nav": {
            "lblIncidents": "Incidents",
            "lblOverview": "Overview",
            "lblCommunicate": "Communicate",
            "lblDashboard": "Dashboard",
            "lblSendEmergencyMessage": "Send Message",
            "lblStartAConferenceCall": "Start a Conference Call",
            "lblSplashMessages": "Splash Messages",
            "lblScheduledConferenceCalls": "Scheduled Conference Calls",
            "lblPublicAlerts": "Public [[Alerts]]",
            "lblGeolocations": "Geolocations",
            "lblHistory": "History",
            "lblCrisisTeam": "Crisis Team",
            "lblPanicDashboard": "Panic Dashboard",
            "lblCallInAnnouncements": "Call-In Announcements",
            "lblScheduledMessages": "Scheduled Messages",
            "lblEmergencyButtonDashboard": "Mobile Initiated [[Alerts]]"
        },
        "incidentLocation": "Geolocation",
        "overview": {
            "lblActiveAlerts": "Active Splash Messages",
            "lblIncidentHistory": "Incident History",
            "lblSendEmergencyMessage": "Send Message",
            "lblNotAnEmergency": "Not an emergency? Communicate about an event with any of these options:",
            "lblStartAConferenceCall": "Start a Conference Call",
            "lblCreateCallInAnnouncement": "Create Call-In Announcement",
            "lblCallInAnnouncements": "Call-In Announcements",
            "lblCreateSplashMessage": "Create Splash Message",
            "lblNew": "New",
            "lblViewAll": "View All",
            "lblHelpfulToolsAndResources": "Helpful Tools & Resources",
            "lblSplashMessages": "Splash Message",
            "loadingIncidentHistoryError": "Error loading recent incident history",
            "downloadFileNoContentError": "No content available for download",
            "downloadResourceError": "Error downloading resource",
            "loadingSplashMessagesError": "Error loading splash messages",
            "loadingCallInAnnoucementsError": "Error loading call-in announcements",
            "lblNoSplashMessage": "There are no active Splash Messages."
        },
        "messageProcessingStatus": {
            "lblSubmitting": "Submitting",
            "lblSending": "Sending",
            "lblCompleted": "Completed",
            "lblTitle": "Your message is on its way.",
            "btnViewCommLog": "View Communication Log"
        },
        "messaging": "Messaging",
        "recentMessages": "Recent Messages",
        "dateTime": "Date time",
        "sender": "Sender",
        "crisisTeams": {
            "btnDownload": "Download",
            "lblCrisisTeams": "Crisis Teams",
            "lblCrisisTeamTotalMembers": "total members",
            "btnFinishedViewing": "Finished Viewing",
            "lblMembers": "[[Members]]",
            "lblCrisisTeamSelected": "No Crisis Team selected",
            "lblTeamDocuments": "Team Documents",
            "lblLocationInfo": "[[Location]] Info",
            "lblMainPhone": "Main",
            "lblSecurityPhone": "Security",
            "lblEmergencyPhone": "Emergency Call-In",
            "lblAnnouncementsPhone": "Announcements",
            "lblTeamMembers": "Crisis Team Members",
            "lblMember": "Member",
            "lblPhoneNumbers": "Phone Numbers",
            "lblEmail": "Email",
            "lblFloor": "Floor",
            "lblRole": "Role",
            "lblPhone": "Phone",
            "lblOffice": "Office",
            "lblNoCrisisTeamMembers": "No members have been added to this team.",
            "lblTeamDocumentsTitle": "Team Documents",
            "msgNoDescription": "No Description",
            "msgNoDocument": "No documents have been added to this team.",
            "phSearchFilter": "Refine results by: [[Location]]"
        },
        "splashMessage": {
            "splashMessage": "Splash Message",
            "splashMessages": "Splash Messages",
            "newSplashMessage": "New Splash Message",
            "numberOfResponses": "# of Responses",
            "displayedToUsersIn": "Displayed to Users In",
            "message": "Message",
            "expires": "Expires",
            "startTime": "Start Time",
            "displayDate": "Display Date",
            "title": "Title",
            "status": "Status",
            "errorLoadingSplashMessages": "Error loading splash messages",
            "errorLoadingSplashMessage": "Error loading splash message",
            "filterSplashMessagesPlaceholder": "Refine results by: Title, Message or Displayed in",
            "active": "Active",
            "inactive": "Inactive",
            "never": "Never",
            "preview": "Preview",
            "confirmDeactivate": {
                "header": "Confirm Deactivate",
                "body": "<p>Are you sure you want to deactivate: <strong>{{ name }}</strong>?</p><p>There may be users who have not yet responded. Once deactivated the message will no longer display.</p>",
                "text": "Yes, Deactivate"
            },
            "confirmActivate": {
                "header": "Reactivate Splash Message",
                "body": "Are you sure you want to reactivate this Splash Message?",
                "text": "Yes, Reactivate"
            },
            "confirmSave": {
                "header": "Save Splash Message",
                "body": "Are you sure you want to save this Splash Message?",
                "text": "Save"
            },
            "confirmSaveMidStream": {
                "header": "Splash message already being shown",
                "body": "This Splash message has already been shown to some users. Continue?",
                "text": "Continue"
            },
            "confirmDelete": {
                "header": "Delete Splash Message",
                "body": "Are you sure you want to remove this Splash Message?",
                "text": "Remove"
            },
            "successChangeStatus": "Success changing splash message status",
            "errorChangeStatus": "Error changing splash message status",
            "manual": "Manual",
            "expired": "Expired",
            "helpAcknowledgementType": "Use the YES/NO or CHECK BOX W/ OK option to confirm acknowledgement. Use the default OK option for informational purposes.",
            "lblAcknowledgementType": "Acknowledgement Type",
            "lblCancelSplashMessage": "Cancel Splash message",
            "lblOptionsSubtitle": "Options",
            "lblDetailsSubtitle": "Message Details",
            "lblDisplaySubtitle": "Message Display",
            "lblDontExpire": "Don't Expire",
            "lblEdit": "Edit",
            "lblExpireMessage": "Expire message on",
            "lblExpireMessageDontExpireInfo": "(Message will show until this date when it will automatically deactivate even if there are users who have not responded.)",
            "lblExpireMessageExpireOnInfo": "(Message will continue to display to users who have not responded until you manually deactivate the message.)",
            "lblExpireOn": "Expire On",
            "lblMessageContent": "Message content",
            "lblMessageTitle": "Message title",
            "lblNumberRecipients": "Total Recipients",
            "lblNoResponses": "No users have responded to this message.",
            "lblOptions": "OPTIONS",
            "lblRecipients": "RECIPIENTS",
            "lblRecipientsSubtitle": "Choose Recipients",
            "lblShowMessage": "Show message on",
            "lblShowOnDate": "Show on",
            "lblShowOnInfo": "(Message will be display to selected users on this day)",
            "lblSendMessageTo": "Show to users who are in",
            "lblSplashDetails": "Splash Details",
            "lblSplashMessageTitle": "New Splash Message",
            "lblSplashRecipients": "Splash Recipients",
            "lblSummary": "SUMMARY",
            "lblSummarySubtitle": "Summary",
            "msgOptions": "Enter the following information and then click the next button to proceed to the next step.",
            "msgRecipients": "Select from any category below or pick from just one to decide who should see this splash message.",
            "msgRecipientsTip1": "Select from any category to determine who will see this splash message. A user must match one of the values in each category you select to be included. Or you can choose to allow everyone to view this splash message.",
            "msgRecipientsTip2": "If you select Atlanta and Chicago under [[Location]], and also select Accounting and Sales under [[Departments]], this splash message will only be seen by users who are located in Atlanta OR Chicago but also work in EITHER Accounting or Sales.",
            "msgSaveNewSplashFail": "Failed to create splash message, please try again later.",
            "msgSaveNewSplashSuccess": "New splash message created successfully.",
            "msgSaveUpdateSplashFail": "Failed to update splash message, please try again later.",
            "msgSaveUpdateSplashSuccess": "Splash message saved successfully.",
            "msgSplashBodyRequiredError": "Message content field is empty",
            "msgDeleteSplashSuccess": "Splash message removed successfully",
            "msgDeleteSplashFail": "Failed to remove splash message.",
            "msgSummary": "Review the information below to make sure everything is correct before clicking Save.",
            "msgUserRequired": "You must select from at least one category.",
            "msgErrorLoadingSplashMessage": "Could not load splash message.",
            "phMessageTitle": "Enter a title which will display in the header of the splash message",
            "confirmCancelSplashMessage": {
                "header": "Cancel Splash Message",
                "body": "Are you sure you want to cancel this Splash Message?",
                "text": "Yes"
            },
            "btnSaveFinished": "Save and Finish",
            "lblMessageSubtitle": "Message",
            "lblPreviewSplashMessage": "Preview Message",
            "lblStatus": "Status",
            "lblAffectingUsers": "Display Message to Users in",
            "lblAndAreIn": "and are in",
            "lblEveryone": "Everyone",
            "lblSplashResponsesSubtitle": "Splash Responses",
            "lblNumUsersResponded": "# of Users Who Have Responded",
            "phSearchFilter": "Refine results by: Name, Role or [[Location]]",
            "response": {
                "name": "Name",
                "role": "Role",
                "location": "[[Location]]",
                "responded": "Responded",
                "response": "Response"
            },
            "responseCountMessage": "Showing {{ x }} of {{ y }} user responses",
            "startEndDateError": "Expiration date must be after start date"
        },
        "dashboard": {
            "sendMessageDescription": "In an emergency send out a message to those who need to be informed. After sending out a message you'll be able to track the incident and responses (if selected) to stay on top of events.",
            "conferenceCallDescription": "Need to speak with a group of people to discuss an incident? We've got you covered! With our conference call there is no need to wait for people to call in as our service calls all those you select and immediately joins them to the conference call when they answer.",
            "splashMessagesDescription": "Splash messages are a great tool to use to communicate a variety of situations from incidents to important reminders. If you create a splash message, those you have designated to receive the splash message will be presented at login the message. You'll also be able to track their response.",
            "currentlyActive": "Currently Active",
            "viewAllSplashMessages": "View all Splash Messages",
            "callInAnnouncementsDescription": "Using a Call-In Announcement is another great tool to use to communicate information. Record a message and anyone who calls into the specified number will hear your message. Call-In announcements are great for office closures or when the internet is down or unreliable.",
            "noCallInAnnouncements": "No Call-In Announcements",
            "viewAllCallInAnnouncements": "View all Call-In Announcements",
            "newCallInAnnouncement": "New Call-In Announcement"
        },
        "locationDescription": "[[Location]] Description",
        "enableWatchIncidentBtn": "Watch Incident",
        "stopWatchingIncidentBtn": "Stop Watching Incident",
        "enableWatchIncident": "Notification emergency response enabled successfully",
        "disableWatchIncident": "Notification emergency response disabled successfully",
        "updateWatchIncidentFailed": "An error has occurred while updating notification emergency response ",
        "exceptionExport": "Exception Export",
        "sendToAll": "Sent to All users",
        "manuallyAdded": "Users Manually Added"
    }
}
