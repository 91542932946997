{
    "businessContinuity": {
        "planner": "[[Planner]]",
        "bcPlanner": {
            "businessContinuity": "Continuidad Empresarial",
            "continuityOfOperationPlanning": "Continuidad de Operaciones",
            "emergencyOperations": "Operaciones de emergencia",
            "disasterRecovery": "Recuperación ante desastres",
            "businessContinuityNoPlanner": "Continuidad del negocio",
            "continuityOfOperationPlanningNoPlanner": "Continuidad de Operaciones",
            "emergencyOperationsNoPlanner": "Operaciones de emergencia",
            "introductionHeader": "Construya su plan. ",
            "introductionSecondaryDescription": "Ha sido seleccionado como administrador de su organización.",
            "introductionDescription": "Se le guiará a través de la configuración de su plan de continuidad de [[Departments]]. A continuación se detallan los pasos para completar su plan. Se le proporcionarán datos precargados para construir su plan.",
            "responseTeamsDescription": "Las organizaciones necesitan establecer equipos de respuesta de continuidad para manejar emergencias y seguir funcionando durante interrupciones no planificadas. Estos equipos comprometidos, que cuentan con habilidades y recursos especializados, se aseguran de que las decisiones se tomen rápidamente y las acciones se organicen para mantener la continuidad del negocio y reducir las pérdidas.",
            "buildAndManageDescription": "Identifique los [[Departments]] de su organización para asignar adecuadamente los recursos y establecer una comunicación clara, asegurando la resiliencia y minimizando las posibles interrupciones durante un incidente.",
            "sendEmailDescription": "Notifique a su equipo enviando un correo electrónico de presentación.",
            "trackDepartmentsDescription": "Una vez que haya creado y asignado sus [[Departments]], realice un seguimiento de su progreso.",
            "departmentSecondaryDescription": "Ha sido seleccionado como líder de equipo para el siguiente [[Department]]",
            "departmentSecondaryDescriptionSme": "Ha sido seleccionado como experto en la materia para el siguiente [[Department]]",
            "departmentIntroductionDescription": "Se le guiará a través de la configuración de su [[department]] plan de continuidad comercial. A continuación se detallan los pasos para completar su plan. Se le proporcionarán datos precargados para construir su plan.",
            "departmentDetails": "[[Department]] Detalles",
            "oosDescription": "Prever una asunción ordenada y predefinida de responsabilidades durante una emergencia o evento. Deben desarrollarse para apoyar directamente las operaciones diarias.",
            "doaDescription": " Las delegaciones de autoridad aseguran que los [[processes]] de la entidad puedan continuar y asegurar una respuesta rápida a cualquier situación de emergencia que requiera la implementación del plan.",
            "departmentResponseTeamsDescription": "La entidad cuenta con equipos de continuidad de operaciones preidentificados que están relacionados con la ejecución de este plan. Los siguientes equipos han sido designados y son parte integral de este plan.",
            "buildManageDescription": "Los [[processes]] organizacionales determinados como actividades críticas se utilizan para identificar tareas y recursos de apoyo que deben incluirse en el proceso de planificación de continuidad de la organización.",
            "processesTrackDescription": "Una vez que haya construido y asignado sus [[processes]], realice un seguimiento de su progreso.",
            "processesTrackDescriptionSme": "Aquí puedes rastrear",
            "departmentInformation": "[[Department]] Información",
            "departmentDescriptionLabel": "[[Department]] Descripción",
            "departmentAlternateLabel": "Suplente de [[Team Lead]]",
            "departmentDescription": "La descripción del [[Department]] define lo que hace su [[Department]]. Es una descripción general concisa de una sola línea de por qué existe la organización y por qué es importante la identidad de su cooperativa.",
            "departmentTeamLeadAlternateDescription": "Designe un suplente de [[Team Lead]] para este departamento para garantizar la continuidad operativa en caso de un incidente.",
            "backToMyDepartments": "Volver a mis [[Departments]]",
            "myDepartmentsSecondaryDescription": "Seleccione uno de sus [[departments]] a continuación para comenzar.",
            "myDepartmentsDescription": "Una vez que seleccione un [[department]], comenzará el proceso de elaboración de su plan para ese [[department]]. Podrá volver a esta sección en cualquier momento para cambiar a otro departamento si es necesario.",
            "departmentDescriptionStepSecondary": "Ingrese la descripción de su [[department]].",
            "departmentDescriptionStepLead": "Puede optar por ingresar una descripción concisa de una línea de su [[department]] o área de la empresa.",
            "departmentAlternateStepSecondary": "Define tu alternativa aquí.",
            "departmentAlternateStepLead": "Seleccione a una persona como su líder de equipo alternativo que estará disponible y preparada para asumir las responsabilidades de líder de equipo en su ausencia. No es necesario que un suplente continúe y puede definirse más adelante.",
            "selectPlanType": "Seleccione un plan",
            "eopIntroductionSecondaryDescription": "Ha sido seleccionado como administrador o líder del anexo de su organización.",
            "eopIntroductionDescription": "Se le guiará en la configuración de su plan de operaciones de emergencia. ",
            "eopSupersessionDescription": "Documente la sustitución de este documento.",
            "eopGeneralQuestionsDescription": "Esta sección abordará varias preguntas generales relacionadas con la planificación de operaciones de emergencia (EOP).",
            "eopResponseTeamsDescription": "Los Equipos de Respuesta son clave para las operaciones o la organización para la ejecución de este plan. ",
            "mobileBrowserWarning": "En este momento, Preparis Planner no es compatible con navegadores móviles. Cambie a un navegador de escritorio para continuar.",
            "annexBuildMangeDescription": "Los anexos organizativos para actividades críticas se utilizan para identificar las agencias primarias y de apoyo y las tareas de las que son responsables.",
            "annexesTrackDescription": "Respuesta Una vez que haya creado y asignado sus anexos, realice un seguimiento de su progreso.",
            "disasterRecoveryNoPlanner": "Recuperación de desastres",
            "itdrIntroductionSecondaryDescription": "Ha sido seleccionado como administrador de TI para su organización.",
            "itdrIntroductionDescription": "Se le guiará en la configuración de su plan de recuperación en caso de catástrofe. A continuación se indican los pasos para completar su plan.",
            "crisisManagementTeam": "Equipos de gestión de crisis",
            "itdrCrisisManagementTeamDescription": "Estos equipos desempeñan un papel crucial en la planificación de la continuidad al garantizar que una organización pueda responder y recuperarse eficazmente de diversas crisis o interrupciones. ",
            "incidentResponseTeams": "Equipos de respuesta a incidentes",
            "itdrIncidentResponseTeamsDescription": "Cree los equipos de respuesta a incidentes que serán necesarios para poner en práctica su plan de recuperación ante desastres y gestionar la respuesta.",
            "itdrBuildAndManageDescription": "Identifique las [[technologies]] clave utilizadas dentro de su organización para crear runbooks de recuperación ante desastres de manera efectiva.",
            "itdrsendEmailDescription": "Establezca una fecha de vencimiento y luego notifique a su equipo enviando un correo electrónico de presentación.",
            "trackTechnologiesDescription": "Una vez que haya creado y asignado sus [[technologies]], realice un seguimiento de su progreso.",
            "itdrTechnologyOwnerSecondaryDescription": "Has sido seleccionada como la PYME [[technology]] de tu organización.",
            "itdrTechnologyOwnerDescription": "Se le guiará a través de la configuración de su [[technology]]. ",
            "itdrVendorsDescription": "Seleccione los proveedores que respaldan o brindan los servicios que requiere esta [[technology]].",
            "itdrUpstreamDependencies": "Dependencias ascendentes",
            "itdrUpstreamDependenciesDescription": "Identificar y documentar las dependencias upstream que mantienen esta [[technology]].",
            "itdrApplicationDetailsAndAttachments": "Detalles de [[Technology]] y archivos adjuntos",
            "itdrApplicationDetailsAndAttachmentsDescription": "Documente los detalles clave y adjunte documentos de respaldo para esta [[technology]].",
            "itdrTaskSets": "Conjuntos de tareas",
            "itdrTaskSetsDescription": "Definir conjuntos de tareas con procedimientos detallados paso a paso para la recuperación o restauración de la [[technology]].",
            "itdrEstimatedRecoveryTime": "Tiempo estimado de recuperación",
            "itdrEstimatedRecoveryTimeDescription": "Revisar y ajustar el tiempo de recuperación estimado para la restauración de [[technology]], proporcionando un cronograma realista basado en evaluaciones calculadas."
        },
        "bcDashboard": "Tablero",
        "bcAdmin": "Administración",
        "admin": {
            "confirmReset": "¿Está seguro de que desea restablecer sus datos? No se podrán recuperar.",
            "title": "Administración",
            "departmentPlanTemplateFileName": "DepartmentPlanTemplate.docx",
            "downloadTemplateFailed": "No se puede descargar la plantilla del [[plan]] del [[department]].",
            "saveDepartmentPlanTemplateFailed": "No se puede guardar la plantilla del [[plan]] del [[department]]",
            "saveDepartmentPlanTemplateSuccess": "Plantilla de [[plan]] de [[department]] guardada exitosamente.",
            "freshStartHeader": "Volver a empezar",
            "manageIndustries": "Administrar industrias",
            "manageIndustriesDescription": "Crear nuevas industrias, administrar plantillas de [[processes]] y [[departments]] y actualizar los impactos de los [[processes]], RTO y estrategias. (Solo para administradores de Preparis).",
            "freshStartDescription": "¿Le gustaría empezar de cero con su Planificación? Pulsando el botón Reestablecer, borrará todos los datos de planificación. Tenga en cuenta que esta acción borrará permanentemente sus datos, haciéndolos irrecuperables. Tenga cuidado, ya que esta acción es irreversible.",
            "resetSuccess": "Restablecimiento de datos de continuidad comercial correcto",
            "resetFailed": "Falló el restablecimiento de datos de continuidad empresarial",
            "browseIndustries": "Explorar industrias",
            "manageGenericIndustry": "Gestionar la industria genérica",
            "genericIndustry": "Industria genérica",
            "showMTD": "Muestre el tiempo de inactividad máximo tolerable",
            "template": {
                "industries": "Industrias",
                "industry": "Industria",
                "newIndustryFromGeneric": "Nueva industria desde genérico",
                "newIndustryFromScratch": "Nueva industria desde cero",
                "newIndustry": "Nueva industria",
                "renameIndustry": "Cambiar el nombre de la industria",
                "copyGenericIndustry": "Copiar industria genérica",
                "industryName": "Nombre de la industria",
                "msgSuccessSaveIndustry": "{{ industryName }} guardada correctamente",
                "msgFailSaveIndustry": "Error al guardar la industria",
                "duplicateIndustryName": "Este nombre de industria ya se está utilizando",
                "newIndustryFromCsv": "Importar industria",
                "errorGettingDepartmentsMsg": "Error al recuperar [[departments]]",
                "errorGettingProcessesMsg": "Error al recuperar [[processes]]",
                "errorGettingProcessDetails": "Error al recuperar detalles del [[process]]",
                "errorGettingIndustriesMsg": "Error al recuperar industrias",
                "errorGettingContinuityStrategy": "Error al recuperar la estrategia de continuidad",
                "newDepartment": "[[Department]] nuevo",
                "department": "[[Department]]",
                "newProcess": "[[Process]] nuevo",
                "process": "[[Process]]",
                "departments": "[[Departments]]",
                "processes": "[[Processes]]",
                "continuityStrategy": "Estrategia de continuidad",
                "details": "Detalles",
                "errorInvalidIndustryFileType": "Tipo de archivo inválido",
                "noeFileSelectedMsg": "Ningún archivo seleccionado",
                "uploadIndustrySuccess": "Industria importada correctamente",
                "uploadIndustryDescription": "Cargar un archivo de configuración de industria",
                "uploadIndustry": "Importar industria",
                "dropFileInstruction": "Suelte un archivo de configuración aquí",
                "loadingIndustryFile": "Cargando archivo",
                "selectedFileLabel": "Archivo seleccionado",
                "noFileSelectedLabel": "Ningún archivo seleccionado",
                "noFileSelectedMsg": "Por favor seleccione un archivo"
            },
            "revisionPeriodHeader": {
                "continuityOfOperationPlanning": "Ciclo de Revisión COOP",
                "emergencyOperations": "Ciclo de Revisión de EOP",
                "businessContinuity": "Ciclo de Revisión",
                "disasterRecovery": "Ciclo de Revisión"
            },
            "revisionPeriodDescription": "El inicio de un nuevo ciclo de revisión conservará todos los datos existentes, pero restablecerá el avance de su planificador a No iniciado. Puede crear solo un ciclo futuro.",
            "btnStartNewRevisionPeriod": "Nuevo ciclo de revisión",
            "lblRevisionPeriodName": "Nombre",
            "lblRevisionPeriodStartDate": "Fecha de inicio",
            "lblRevisionPeriodEndDate": "Fecha de finalización",
            "strategiesHeader": "Estrategias",
            "scenarioBasedStrategies": "Estrategias de continuidad basadas en escenarios",
            "locationBasedStrategies": "Estrategias de continuidad basadas en la [[location]]",
            "planTypeHeader": "Tipos de planes",
            "msgPlanTypeUpdatedSuccess": "Tipo de plan actualizado",
            "msgPlanTypeUpdatedFail": "Error al actualizar el tipo de plan",
            "msgPlanTypeRequired": "Se requiere al menos 1 tipo de plan",
            "msgErrorLoadingCurrentPortalRevision": "Error al cargar el ciclo de revisión actual",
            "spinnerMsgStartingNewRevisionPeriod": "Ahorro del ciclo de revisión de continuidad empresarial...",
            "currentPeriodText": "Ciclo actual: '{{ periodName }}' empezado el {{ periodStartDate }}",
            "nextPeriodText": "Siguiente ciclo: '{{ periodName }}' empieza el {{ periodStartDate }}",
            "msgSuccessCreatingNewRevisionPeriod": "Nuevo ciclo de revisión creado",
            "msgErrorCreatingNewRevisionPeriod": "No se pudo crear un nuevo ciclo de revisión",
            "newRevisionPeriodModal": {
                "title": "Nuevo ciclo de revisión",
                "defaultName": "Ciclo de revisión del {{ date }}",
                "msgInvalidDateRange": "La fecha de inicio debe ser anterior a la fecha de finalización"
            },
            "bcImpactSeverityDescriptions": {
                "editImpactSeverityDescriptions": "Editar descripciones de gravedad del impacto",
                "impactSeverityDescriptionsSettingUpdated": "Se actualizó la [[setting]] de descripciones de gravedad del impacto",
                "impactSeverityDescriptions": "Descripciones de la gravedad del impacto"
            },
            "incidentCommunications": {
                "menu": "Comunicaciones de incidentes",
                "title": "Comunicaciones de incidentes",
                "description": "Configure estos botones agregando un grupo a cada botón. Estos botones se agregarán a cada incidente y se pueden seleccionar para enviar a cada individuo una llamada en conferencia, correo electrónico o SMS.",
                "msgLabel": "Configuración de comunicaciones de incidentes",
                "msgErrorLoadingSetting": "Error al cargar la [[setting]] de comunicaciones de incidentes",
                "msgErrorDuplicateGroups": "Hay grupos duplicados seleccionados",
                "confirmRemoveGroup": {
                    "header": "Eliminar grupo de comunicación de incidentes",
                    "body": "¿Está seguro de que desea eliminar el grupo de comunicación de incidentes?",
                    "text": "Eliminar"
                },
                "selectPlaceholder": "Selecciona grupo",
                "selectedTeamMembers": "[[Members]] del [[team]] seleccionados",
                "noGroupSelected": "Ningún grupo seleccionado"
            },
            "planWatermark": "Planificar marca de agua",
            "bcPlanWatermarkIsOnSettingUpdated": "La marca de agua del plan BC está activada actualizada",
            "visualTimelineOfRto": "Cronología visual de RTO",
            "rtoDescription": "Los objetivos de tiempo de recuperación definen la mayor cantidad de tiempo que un recurso del sistema puede permanecer no disponible antes de que haya un impacto inaceptable en otros recursos del sistema y [[processes]] comerciales.",
            "rtoHeader": "Definir intervalos de RTO",
            "rtoWithMtdHeader": "Definir intervalos RTO y MTD",
            "saveRtoErrorMessage": "Tiene un RTO no válido.",
            "saveRto": "Guardar RTO",
            "saveRtoDescription": "Al cambiar los RTO de los sistemas, se perderán todos los [[processes]] datos de impacto guardados, y esto también deshabilitará la preselección de impactos según las mejores prácticas. ",
            "saveImpactCategory": "Guardar categoría de impacto",
            "saveImpactCategoryDescription": "Al cambiar las categorías de impacto del sistema, es posible que se pierdan todos los datos de impacto [[process]] guardados. ",
            "cancelHeader": "Cambios no guardados",
            "cancelDescription": "Tiene cambios sin guardar. ",
            "resetRto": "Restablecer RTO",
            "resetRtoDescription": "Esto restablecerá los RTO del sistema a sus valores predeterminados, lo que permitirá que los valores de impacto se preseleccionen según las mejores prácticas. ",
            "addCategory": "añadir categoría",
            "editImpactCategories": " Editar categorías de impacto",
            "resetImpactCategoriesHeader": "Restablecer categorías de impacto",
            "resetImpactCategoriesMsg": "Esto restablecerá las categorías de impacto del sistema a sus valores predeterminados y se eliminarán todas las categorías personalizadas.  ",
            "resetImpactSeverity": "Restablecer la gravedad del impacto",
            "resetImpactSeverityDescription": "Esto restablecerá los nombres de los niveles de gravedad a sus valores predeterminados.  ",
            "confirmDisableBcScenarioBasedStrategy": {
                "header": "Desactivar estrategia basada en escenarios",
                "body": "¿Está seguro de que desea deshabilitar las estrategias basadas en escenarios de BC?"
            },
            "confirmDisableBcLocationBasedStrategy": {
                "header": "Deshabilitar la estrategia basada en la [[location]]",
                "body": "Se eliminarán todas las estrategias asociadas a la [[location]]."
            },
            "updateScenarioBasedSettingToastr": {
                "success": "Estrategia BC basada en escenarios actualizada.",
                "failed": "Error al actualizar la estrategia BC basada en escenarios"
            },
            "updateLocationBasedSettingToastr": {
                "success": "Se actualizó la estrategia BC basada en la [[location]].",
                "failed": "No se pudo actualizar la estrategia BC basada en la [[location]]"
            },
            "updatingStrategy": "Actualización de la [[setting]] de la estrategia de continuidad del negocio",
            "errorInBCTemplateHeader": "Error(es) en la Plantilla",
            "errorInBCTemplateBody": "La plantilla que está intentando cargar tiene algunos errores.<br />Corrija los errores dentro de la plantilla y vuelva a intentarlo o comuníquese con el soporte técnico.<br />El error: <b>{{error}}</b>",
            "errorValidatingBCTemplateHeader": "Plantilla de validación de errores",
            "errorValidatingBCTemplateBody": "Se debe configurar al menos un [[department]] antes de poder cargar una plantilla personalizada."
        },
        "welcome": {
            "welcomeBc": "Bienvenido: {{userProfileName}}",
            "welcomeManagementDescription": "Construyamos su [[team]] de continuidad empresarial. Proporcionamos un video a continuación para explicar lo que está a punto de hacer.",
            "welcomeTeamLeadDescription": "Como líder de [[team]], ha sido seleccionado para crear el plan de continuidad empresarial para {{functionalAreaName}}. Haga clic en el video a continuación para obtener instrucciones breves sobre cómo comenzar con Preparis Planner.",
            "errorRetrievingVideo": "Error al recuperar el video",
            "welcomeTeamMemberDescription": "Como valioso experto en la materia, ha sido seleccionado para crear el plan de continuidad empresarial para {{businessProcessName}}.  Haga clic en el video a continuación para obtener instrucciones breves sobre cómo comenzar con Preparis Planner."
        },
        "buildYourTeam": {
            "buildYourTeam": "Forme su [[team]]",
            "buildYourTeamDescription": "A continuación, encontrará una lista de [[departments]] que son comunes dentro de su industria. Haga clic en un [[department]] para asignar un líder de [[team]] que será responsable de validar los [[processes]] dentro de esa área y agregar los [[departments]] adicionales que puedan faltar.",
            "teamLeads": "Jefes de [[team]]",
            "contactTeamLeadsAndAlternates": [
                "Contactar a los [[Team Leads]]",
                "y suplentes"
            ],
            "teamLeadModal": {
                "headerLabel": "Seleccione un líder de [[team]] para {{name}}",
                "searchUserLabel": "Buscar líder de [[team]]",
                "currentUserLabel": "Líder actual del [[team]]"
            },
            "teamMembers": "[[Members]] del [[team]]",
            "msgSuccessSaveFunctionalArea": "{{functionalAreaName}} guardado correctamente",
            "msgFailSaveFunctionalArea": "No se pudo guardar el [[department]].",
            "msgFailSaveFunctionalAreaPermission": "Su inicio de sesión no tiene permiso para guardar este [[department]].",
            "msgFailSaveFunctionalAreaDuplicateName": "Un [[department]] existente ya tiene este nombre.",
            "removePortalFunctionalAreaSuccess": "{{functionalAreaName}} remoto",
            "removePortalFunctionalAreaError": "No se pudo quitar {{functionalAreaName}}",
            "unassignPortalFunctionalAreaSuccess": "{{userName}} no asignado de {{functionalAreaName}}",
            "unassignPortalFunctionalAreaError": "No se pudo cancelar la asignación de {{userName}} desde {{functionalAreaName}}",
            "assignPortalFunctionalAreaSuccess": "{{userName}} asignado a {{functionalAreaName}}",
            "confirmRemoveFunctionalArea": {
                "header": "Quitar [[department]]",
                "body": "¿Está seguro de que desea quitar '{{ functionalAreaName }}'?",
                "text": "Quitar"
            },
            "removeDepartmentSuccess": "[[Department]] quitado exitosamente",
            "removeDepartmentError": "Error al quitar el [[department]]",
            "confirmRemovePlan": {
                "header": "Quitar [[plan]]",
                "body": "¿Está seguro de que desea quitar '{{ planName }}'?",
                "text": "Quitar"
            },
            "newFunctionalArea": "[[Department]] nuevo",
            "renameFunctionalArea": "Cambiar nombre de [[department]]",
            "newPlan": "Plan nuevo",
            "msgLimitedResult": "Mostrar los 100 primeros",
            "noPermission": "El usuario no tiene los permisos adecuados",
            "titleFunctionalArea": "[[Department]]",
            "functionalAreaDescription": "A continuación, puede editar el nombre del [[department]] para reflejar claramente las funciones comerciales dentro de su empresa. También verá una lista de los [[processes]] comerciales asociados con este [[department]]. Esto proporcionará más información al crear su [[team]]",
            "newFunctionalAreaDescription": "Ingrese el nombre del nuevo [[department]] a continuación y agregue cualquier [[process]] aplicable en la sección de [[processes]]. También puede optar por omitir la sección de [[processes]] y permitir que el líder del [[team]] responsable agregue los [[processes]] necesarios en un momento posterior. Simplemente haga clic en crear para guardar su entrada.",
            "lblFunctionalAreaName": "Nombre de [[department]]",
            "functionalAreaName": "Ingrese el nombre del [[department]]",
            "lblProcesses": "[[Processes]]",
            "duplicateFunctionalAreaName": "Ya existe un [[department]] con el mismo nombre.",
            "validateProcess": "Validar los [[processes]] para {{functionalAreaName}}",
            "validateProcessDescription": "A continuación se muestra la lista de inicio de los [[processes]] en su área. Elimine aquellos que no sean su responsabilidad o la de su [[team]]. Agregue cualquier otro [[process]] que falte.",
            "titlePlan": "Plan",
            "planName": "Nombre",
            "lblPlanName": "Ingrese el nombre del [[plan]]",
            "duplicatePlanName": "Ya existe un [[plan]] con el mismo nombre.",
            "confirmUnassignTeamLead": {
                "header": "Anular la asignación de jefe de [[team]]",
                "body": "¿Está seguro de que quiere anular la asignación '{{ userName }}' de este [[department]]?",
                "text": "Anular asignación"
            },
            "assignTeamLead": "Asignar líder de [[team]]",
            "unassignTeamLead": "Anular la asignación de jefe de [[team]]",
            "noTeamLeadAssigned": "Ningún líder de [[team]] asignado",
            "setDueDate": {
                "header": "Fecha límite",
                "body": "Algunos [[departments]] ya tienen una fecha límite, ¿desea establecer esta fecha límite <b>{{dueDate}}</b> a todos los [[departments]]?",
                "allFunctionalAreas": "Sí",
                "newFunctionalAreas": "No",
                "msgConfirmBody": "¿Está seguro de que desea establecer esta nueva fecha límite <b>{{dueDate}}</b> a todos los [[departments]]?"
            },
            "msgDueDateRequired": "La fecha límite es obligatoria para todos los [[departments]]",
            "introductoryEmailDisclaimer": "El contexto cambiará por nombre de usuario y [[department]]",
            "welcomeManagementDescription": "Haga clic en el video a continuación para obtener instrucciones breves sobre cómo comenzar con Preparis Planner.",
            "reviewDepartmentsTooltip": "Asegúrese de tener en cuenta todos los [[departments]] esenciales de su organización. Para agregar un nuevo [[department]], haga clic en la tarjeta Nuevo [[department]].",
            "assignTeamLeaderTooltip": "Para cada [[department]] que se muestra a continuación, seleccione una persona responsable de los [[processes]] de los [[departments]]. Para encontrar a la persona adecuada, haga clic en Asignar líder de [[team]] y busque su nombre en la barra de búsqueda.",
            "isYourTeamCompleteTooltip": "Una vez que haya asignado un líder de [[team]] a cada [[department]], haga clic en siguiente para continuar con el último paso.",
            "selectDueDateTooltip": "Seleccione una fecha límite para la tarea. Haga clic en una fecha límite individual para realizar cambios.",
            "reviewEmailTooltip": "Revise la plantilla de correo electrónico y haga los cambios necesarios haciendo clic en Editar. Las plantillas de correo electrónico correspondientes se enviarán a los usuarios en función de su estado (Usuario existente o Usuario nuevo). Si se realizan cambios, tenga en cuenta que se aplicarán a todos los destinatarios de correo electrónico.",
            "sendAndFinishTooltip": "Notifiquemos a su [[team]] enviándoles un correo electrónico de presentación. Haga clic en Enviar y finalizar para completar la creación de su [[team]] de continuidad comercial.",
            "reviewDepartments": "1. Revise los [[departments]]",
            "assignTeamLeader": "2. Asigne un líder de [[team]]",
            "isYourTeamComplete": "3. Confirme que su [[team]] está completo",
            "selectDueDate": "1. Seleccione una fecha límite",
            "reviewEmail": "2. Revisar correo electrónico",
            "sendAndFinish": "3. Enviar y finalizar",
            "emailDescription": "Cualquier cambio realizado en los correos electrónicos a continuación no se guardará para uso futuro. Si realiza cambios, asegúrese de que ambas plantillas se actualicen correctamente. Tenga en cuenta que cambiar cualquier información dentro de los corchetes puede provocar que la información pertinente se distorsione.",
            "manageDepartment": "Administrar [[department]]",
            "bcmLicenseOverage": "Ha alcanzado el límite para Business Continuity Manager. ",
            "teamLeadLicenseOverage": "Has alcanzado el límite de [[Team Leads]]. ",
            "teamMemberLicenseOverage": "Ha alcanzado el límite de experto en la materia. ",
            "navigateToSettingDepartment": "Navegue al [[Department]] vinculado para cambiar el nombre del [[Department]] del [[Planner]]",
            "buildManageSecondaryDescription": "Defina sus departamentos aquí.",
            "buildManageDescription": "Un \"[[department]]\" puede ser una entidad organizacional, un grupo de entidades o un área de la organización. A cada \"[[department]]\" definido se le debe asignar un líder de equipo responsable de definir los procesos del departamento, ejecutar el análisis de impacto y definir el plan.",
            "introductionEmail": "Asigne una fecha de vencimiento y notifique a los líderes del equipo",
            "introductionEmailSecondaryDescription": "Se debe definir una fecha límite para la finalización de las actividades de desarrollo del plan. También puede enviar un correo electrónico (u omitir) a los líderes de equipo anunciando su asignación.",
            "introductionEmailSecondaryDescriptionUpdated": "Se debe definir una fecha límite para la finalización de las actividades de desarrollo del plan. También puede enviar un correo electrónico (u omitir) a los líderes de equipo anunciando su asignación.",
            "trackDepartmentSecondaryDescription": "Rastree y abra los [[Departments]] de su equipo aquí.",
            "disasterRecoveryIntroductionEmailTitle": "Asignar una fecha de vencimiento y notificar a los propietarios de [[Technology]]",
            "disasterRecoveryIntroductionEmailDescription": "Debe definirse una fecha límite para la finalización de las actividades de desarrollo del plan. También puede enviar un correo electrónico (o saltar) a los propietarios anunciándoles su asignación."
        },
        "validateProcess": {
            "confirmUnassignTeamMember": {
                "header": "Anular asignación de [[team member]]",
                "body": "¿Está seguro de que quiere anular la asignación de '{{ userName }}' de este [[process]]?",
                "text": "Anular asignación"
            },
            "confirmUnassignTeamMemberAndAlternate": {
                "header": "Desasignar [[team member]] y Alternar",
                "body": "¿Estás seguro de que quieres cancelar la asignación?{{ userName }}' y '{{ alternateUserName }}' de este [[process]]?",
                "text": "Desasignar"
            },
            "confirmRemoveProcess": {
                "header": "Quitar [[process]]",
                "body": "Estás seguro de que desea quitar '{{ processName }}'?",
                "text": "Quitar"
            },
            "removeProcessSuccess": "[[Process]] quitado exitosamente",
            "removeProcessError": "Error al quitar el [[process]]",
            "teamMemberModal": {
                "headerLabel": "Seleccione un experto en la materia para {{name}}",
                "searchUserLabel": "Buscar un [[team member]]",
                "currentUserLabel": "Experto actual en la materia",
                "teamMembers": "[[Members]] del [[team]]"
            },
            "titleNewProcess": "[[Process]] nuevo",
            "processName": "Nombre del [[process]]",
            "newProcess": "[[Process]] nuevo",
            "assignTeamMember": "Delegar",
            "unassignTeamMember": "Anular asignación",
            "noTeamMemberAssigned": "Ningún delegado asignado",
            "removeProcess": "Quitar [[process]]",
            "renameProcess": "[[Process]] de cambio de nombre",
            "titleProcess": "[[Process]]",
            "lblProcessName": "Ingrese el nombre del [[process]]",
            "duplicateProcessName": "Ya existe un [[process]] con el mismo nombre.",
            "msgSuccessSaveProcess": "{{processName}} guardado exitosamente",
            "msgFailSaveProcess": "Error al guardar {{processName}}.",
            "msgFailSaveProcessPermission": "Su inicio de sesión no tiene permiso para guardar este [[process]].",
            "msgFailSaveProcessDuplicateName": "Un [[process]] existente ya tiene este nombre.",
            "removePortalProcessSuccess": "{{processName}} quitado",
            "removePortalProcessError": "No se pudo quitar {{processName}}",
            "unassignProcessUserSuccess": "{{userName}} no asignado desde {{processName}}",
            "unassignProcessUserError": "No se pudo cancelar la asignación de {{userName}} desde {{processName}}",
            "assignProcessUserSuccess": "{{userName}} asignado a {{processName}}",
            "assignProcessUserError": "No se pudo asignar a {{userName}} a {{processName}}",
            "AssignmentsDescription": "Para medir el impacto en nuestro negocio, necesitamos su opinión. Para cada [[process]], considere y luego seleccione el impacto más apropiado para el negocio en general si el [[process]] no se puede ejecutar en los plazos indicados. Continúe su evaluación para cada uno de los cinco tipos de impacto. Hemos realizado una evaluación inicial basada en nuestra experiencia y las mejores prácticas de la industria.",
            "functionalAreas": "[[Departments]]",
            "noProcessesForDepartment": "No se han definido [[processes]] para este [[department]]",
            "plans": "[[Plans]]",
            "teamLead": "Jefe de [[team]]",
            "setDueDate": {
                "header": "Fecha límite",
                "body": "Algunos [[processes]] ya tienen una fecha límite, ¿desea establecer esta fecha límite {{dueDate}} para todos los [[processes]]?",
                "allProcesses": "Sí",
                "newProcesses": "No",
                "msgConfirmBody": "¿Está seguro de que desea establecer esta nueva fecha límite {{dueDate}} para todos los [[processes]]?"
            },
            "msgDueDateRequired": "Se requiere una fecha límite para todos los [[processes]]",
            "introductoryEmailDisclaimer": "El contexto cambiará por nombre de usuario y [[process]]",
            "reviewGetStarted": "Revise y comience",
            "trackYourProgress": "Seguimiento del avance",
            "reviewProcess": "1. Revisar los [[processes]]",
            "assignExpert": "2. Asignar un experto",
            "reviewGetStartedTooltip": "Revise sus asignaciones a continuación junto con las fechas límite correspondientes. Para empezar, haga clic en Comenzar junto al [[department]] de su elección y preferencia en cualquier momento. Una vez que haya hecho clic en Comenzar, verá un breve video introductorio que le proporcionará instrucciones adicionales.",
            "trackYourProgressTooltip": "Una vez que se inicia una tarea, podrá hacer el seguimiento del avance de cada [[department]]. También puede realizar un seguimiento del avance de los [[processes]] dentro de cada [[department]] desde esta pantalla.",
            "reviewProcessTooltip": "Asegúrese de que se tengan en cuenta todos los [[processes]] esenciales de este [[department]]. Para agregar un [[process]] faltante, haga clic en la tarjeta [[Process]] nuevo. Para quitar cualquiera que no sea aplicable a su [[department]], haga clic en la elipsis en el lado superior derecho de la tarjeta de [[process]] y seleccione Quitar.",
            "assignExpertTooltip": "Para cada [[process]] que se muestra a continuación, se le ha asignado automáticamente como SME (experto en la materia). Puede reasignar cualquier [[process]] de la lista a otros SME. Para hacerlo, haga clic en el nombre dentro de la tarjeta de [[process]] que le gustaría actualizar. Para localizar el nuevo SME, busque su nombre en la barra de búsqueda.",
            "isYourTeamCompleteTooltip": "Una vez que haya asignado un experto en la materia a cada [[process]], haga clic en Siguiente para continuar con el último paso.",
            "selectDueDateTooltip": "Seleccione una fecha límite para la tarea. Haga clic en una fecha límite individual para realizar cambios.",
            "sendAndFinishTooltip": "Notifiquemos a su [[team]] enviándoles un correo electrónico de presentación. Haga clic en Enviar y finalizar para completar la creación de su [[team]] de expertos en continuidad empresarial.",
            "reviewEmailTooltip": "Revise la plantilla de correo electrónico y realice los cambios necesarios haciendo clic en el botón editar. Las plantillas de correo electrónico aplicables se enviarán a los usuarios según su estado (Usuario existente o Usuario nuevo). Si se realizan cambios, tenga en cuenta que se aplicarán a todos los destinatarios que reciban el correo electrónico.",
            "portalProcessLocationModal": {
                "header": "Elegir la [[location]]",
                "exceedMaxPortalProcessLocations": "Se excedieron las ubicaciones máximas para el [[process]]",
                "assignPortalProcessLocationError": "no se pudo asignar la [[location]] para el [[process]]"
            },
            "portalProcessDependenciesModal": {
                "header": "Elija Dependencia",
                "exceedMaxPortalProcessDependencies": "Se excedieron las dependencias máximas para el [[process]]",
                "assignPortalProcessProcessDependencyError": "No se pudo asignar la dependencia para el [[process]]",
                "PortalProcessProcessDependencySaveSuccessfully": "[[process]] dependencia guardada con éxito",
                "PortalProcessProcessDependencyDeletedSuccessfully": "[[process]] dependencia eliminada con éxito",
                "PortalProcessProcessDependencyDeletedUnSuccessfully": "No se pudo eliminar la dependencia [[process]]",
                "currentSelectedProcessDependencies": "Dependencias del [[process]] actualmente seleccionado",
                "availableProcessDependencies": "Dependencias de [[process]] disponibles",
                "addDependencies": "Agregar dependencias"
            },
            "removeLocationConfirmationModal": {
                "header": "Eliminar {{locationName}}",
                "body": "¿Está seguro de que desea eliminar esta [[location]] de este [[process]]?  {{locationName}} se perderá."
            },
            "removeDependencyConfirmationModal": {
                "header": "Eliminar {{dependencyName}}",
                "body": "¿Está seguro de que desea eliminar esta dependencia de este [[process]]?"
            },
            "removeContinuityStrategyConfirmationModal": {
                "header": "Eliminar estrategia de continuidad",
                "body": "¿Está seguro de que desea eliminar esta estrategia de continuidad?"
            },
            "dependenciesPageHeader": "¿Cuáles son los [[processes]] dependientes para {{ name }}?",
            "dependenciesPageDescription": "Seleccione todos los [[processes]] en su [[department]] o en otros [[departments]] que deben ejecutarse para que este [[process]] se complete con éxito.",
            "locationsPageHeader": "¿Dónde se usa este [[process]]?",
            "locationsPageDescription": "Seleccione las ubicaciones donde se está ejecutando este [[process]] para que se puedan asignar estrategias de continuidad.",
            "selectProcessSecondaryDescription": "Defina sus [[Processes]] aquí.",
            "selectProcessDescriptionBC": "Un proceso de negocio define una función o conjunto de funciones ejecutadas como parte de las operaciones comerciales diarias en su departamento o área del negocio. Puede \"asignar\" la responsabilidad de un Proceso a un tema o puede conservar la responsabilidad principal.",
            "selectProcessDescriptionCOOP": " Una función esencial define una función o un conjunto de funciones ejecutadas como parte de las operaciones diarias en su departamento u organización. Puede \"asignar\" la responsabilidad de una Función Esencial a un tema o puede conservar la responsabilidad principal.",
            "tracKProcessesSecondaryDescription": "Haga un seguimiento de sus [[Processes]] aquí.",
            "tracKProcessesDescription": "Realice un seguimiento y complete los [[Processes]] de sus [[Departments]].",
            "noPrimary": "No hay primaria asignada",
            "noAlternate": "No hay alternativa asignada",
            "addPrimary": "Asignar principal",
            "addAlternate": "Asignar alternativo",
            "unAssignBothUserSuccess": "{{ userName }} y {{ alternate }} fueron desasignados de {{ processName }}",
            "unAssignBothProcessUserError": "No se pudo cancelar la asignación {{ userName }} y {{ alternate }} de {{ processName }}"
        },
        "impact": {
            "impactTitle": "¿Cuál es el impacto en <b>{{name}}</b>?",
            "impactDescription": "Para medir el impacto en su negocio, necesitamos su opinión. Hemos proporcionado una evaluación inicial basada en nuestra experiencia y las mejores prácticas de la industria. Revise nuestras selecciones y realice los ajustes necesarios seleccionando el impacto apropiado en su negocio general si el [[process]] no se puede completar en los plazos indicados. Continúe la evaluación para cada una de las seis categorías de impacto hasta que estén todas completas para obtener su Objetivo de tiempo de recuperación en la siguiente sección.",
            "category": {
                "financial": "Impacto financiero",
                "financialSeverityLegend": {
                    "insignificant": "Reducción potencial del efectivo disponible",
                    "significant": "Reducción potencial del capital operativo disponible",
                    "catastrophic": "Potencial para reducir la disponibilidad para continuar las operaciones"
                },
                "memberImpact": "Impacto en los [[members]]",
                "clientImpact": "Impacto en el [[client]]",
                "customer": "Impacto en el [[client]] y en los [[members]]",
                "customerImpact": "Impacto en el [[client]]",
                "customerSeverityLegend": {
                    "insignificant": "Impacto en el [[client]] y en los [[members]]: no significativo",
                    "significant": "Impacto en el [[client]] y en los [[members]]: significativo",
                    "catastrophic": "Impacto en el [[client]] y en el [[member]]: catastrófico"
                },
                "employee": "Impacto en la moral y la retención de los empleados",
                "employeeSeverityLegend": {
                    "insignificant": "Impacto en la moral y la retención de los empleados: no significativo",
                    "significant": "Impacto en la moral y la retención de los empleados: significativo",
                    "catastrophic": "Impacto en la moral y la retención de los empleados: catastrófico"
                },
                "reputational": "Impacto en la marca o la reputación",
                "reputationalSeverityLegend": {
                    "insignificant": "Impacto en la marca o la reputación: no significativo",
                    "significant": "Impacto en la marca o la reputación: significativo",
                    "catastrophic": "Impacto en la marca o la reputación: catastrófico"
                },
                "operational": "Impacto en la prestación de servicios",
                "operationalSeverityLegend": {
                    "insignificant": "Impacto en la prestación de servicios: no significativo",
                    "significant": "Impacto en la prestación de servicios: significativo",
                    "catastrophic": "Impacto en la prestación de servicios: catastrófico"
                },
                "compliance": "Impacto en el cumplimiento regulatorio",
                "complianceSeverityLegend": {
                    "insignificant": "Impacto en el cumplimiento regulatorio: no significativo",
                    "significant": "Impacto en el cumplimiento regulatorio: significativo",
                    "catastrophic": "Impacto en el cumplimiento regulatorio: catastrófico"
                }
            },
            "severity": {
                "none": "Sin impacto",
                "insignificant": "No significativo",
                "significant": "Significativo",
                "catastrophic": "Catastrófico"
            },
            "validationInfo": "Revise cada categoría de impacto antes de continuar con el siguiente paso",
            "saveErrorMsg": "Error al guardar el análisis de impacto",
            "impactAnalysis": "Análisis de impacto",
            "impactCategories": "Categorías de impacto",
            "viewImpactCategories": "1. Ver categorías de impacto",
            "reviewOurSelections": "2. Revise nuestras selecciones",
            "makeChanges": "3.  Hacer cambios",
            "viewImpactCategoriesTooltip": "Las categorías de impacto son áreas que pueden afectar su negocio durante una crisis. Considere el impacto respecto de cada categoría que se muestra si el [[process]] comercial no se puede completar a tiempo.",
            "reviewOurSelectionsTooltip": "En función de nuestra experiencia y las mejores prácticas de la industria, hemos proporcionado una evaluación inicial para cada categoría de impacto. Esta evaluación se basa en el [[process]] individual y el impacto en el negocio en general. Revise nuestras selecciones en cada categoría y los niveles de gravedad para cada día de impacto.",
            "makeChangesTooltip": "Haga los ajustes necesarios revisando cada categoría de impacto y seleccionando el nivel de gravedad apropiado para cada día de impacto si el [[process]] no se puede completar. Una vez que haya completado cada categoría, haga clic en Siguiente para obtener su Objetivo de tiempo de recuperación."
        },
        "rto": {
            "rtoTitle": " Cuál es el <b>objetivo de tiempo de recuperación para </b><b>{{processName}}</b>",
            "rtoDescription": "Según las selecciones que proporcionó en el Análisis de impacto, su tiempo de recuperación para <b>{{processName}}</b> se muestra a continuación. Para editar fácilmente sus selecciones de impacto, haga clic en el nombre de la Categoría de Impacto que se encuentra junto al gráfico a continuación para realizar cambios. También puede actualizar manualmente el RTO en el lado derecho de la pantalla.",
            "rto": "RTO",
            "returnToImpactAnalysis": "Volver al análisis de impacto",
            "missingRto": "Falta RTO",
            "countOfProcesses": "Recuento de [[processes]]",
            "recoveryTimeObjective": "1. Objetivo de tiempo de recuperación",
            "impactAnalysis": "2. Análisis de impacto",
            "impactCategories": "3. Categorías de impacto",
            "recoveryTimeObjectiveTooltip": "Según las selecciones que proporcionó en la sección anterior, a continuación se muestra su Objetivo de tiempo de recuperación (RTO). Puede actualizar manualmente su RTO haciendo clic en la imagen.",
            "impactAnalysisTooltip": "El gráfico del análisis de impacto es una representación visual de las categorías de impacto y sus niveles de gravedad a lo largo de cada día de impacto. Estos niveles fueron seleccionados en la sección anterior.",
            "impactCategoriesTooltip": "Si desea realizar cambios en cualquiera de los datos de la categoría de impacto que se muestran en el gráfico del análisis de impacto, puede hacerlo haciendo clic en el nombre de la categoría de impacto."
        },
        "processAnalysis": {
            "AssignmentsDescription": "Para medir el impacto en nuestro negocio, necesitamos su opinión. Para cada [[process]], considere y luego seleccione el impacto más apropiado para el negocio en general si el [[process]] no se puede ejecutar en los plazos indicados. Continúe su evaluación para cada uno de los cinco tipos de impacto. Hemos realizado una evaluación inicial basada en nuestra experiencia y las mejores prácticas de la industria.",
            "task": "Tarea",
            "teamMember": "[[Team Member]]",
            "process": "[[Process]]"
        },
        "criticalDates": {
            "criticalDatesTitle": "¿Cuáles son las fechas críticas para {{ name }}?",
            "criticalDateSecondary": "Una fecha crítica es una fecha o período de tiempo específico y recurrente dentro de un proceso que requiere mayor atención o puede requerir un aumento en el objetivo de tiempo de recuperación (RTO) durante un incidente.",
            "criticalDatesDescription": "Para medir la prioridad de este [[process]] durante una posible interrupción, es útil saber cuándo se realiza normalmente. Seleccione una categoría de fecha crítica que se aplique a su [[process]] comercial y edite las fechas si corresponde. También puede agregar una nueva fecha crítica si no se proporciona en la lista a continuación.",
            "newCriticalDate": "Nueva fecha crítica",
            "name": "Nombre de la fecha crítica",
            "frequency": "Frecuencia",
            "frequencyTypes": {
                "weekly": "Semanal",
                "biWeekly": "Quincenal",
                "monthly": "Mensual",
                "quarterly": "Trimestral",
                "semiAnnually": "Semianual",
                "annually": "Anual",
                "oneTimeEvent": "Una vez"
            },
            "atTheEndOfFrequencyTypes": {
                "weekly": "Al final de la semana",
                "biWeekly": "Al cabo de dos semanas",
                "monthly": "Al final del mes",
                "quarterly": "Al final del trimestre",
                "semiAnnually": "A finales de junio y diciembre",
                "annually": "Al final del año",
                "oneTimeEvent": "Una vez"
            },
            "onSpecificDayFrequencyTypes": {
                "weekly": "Cada {{ datePartDesc }}",
                "biWeekly": "Cada 2 semanas en {{ datePartDesc }}",
                "monthly": "El {{ datePartDesc }}",
                "quarterly": "Cada 3 meses el {{ datePartDesc }}",
                "semiAnnually": "Cada 6 meses el {{ datePartDesc }}",
                "annually": "El {{ datePartDesc }}",
                "oneTimeEvent": "El {{ datePartDesc }}"
            },
            "beginningOn": "Comenzando en",
            "dayOfWeek": "Día de la semana",
            "beginningOnDate": "Comenzando en {{ dateFormat }}",
            "sinceDate": "Desde {{ dateFormat }}",
            "specificDate": "Fecha específica",
            "lastDayOf": "Último día de...",
            "plusOrMinus": "Más o menos días antes o después",
            "nextOccurrence": "Evento siguiente",
            "nextOccurrencePhrase": "Entre{{startDate}} y {{endDate}}",
            "addEditModal": {
                "addCriticalDate": "Agregar fecha crítica",
                "editCriticalDate": "Editar fecha crítica",
                "description": "A continuación puede editar el nombre de la fecha crítica y configurar la frecuencia, el tipo y la fecha de inicio.",
                "duplicateCriticalDatesName": "Ya existe una fecha crítica con el mismo nombre",
                "criticalDateName": "Nombre de la fecha crítica",
                "exampleCriticalDate": "Ejemplo de fecha crítica"
            },
            "confirmRemove": {
                "header": "Quitar fecha crítica",
                "body": "¿Está seguro de que desea quitar '{{ name }}'?",
                "text": "Quitar"
            },
            "confirmDeselect": {
                "header": "Anular la selección de la fecha crítica",
                "body": "¿Está seguro de que desea anular la selección de '{{ name }}'?",
                "text": "Anual la selección"
            },
            "saveSuccessMsg": "Las fechas críticas se guardaron correctamente",
            "saveErrorMsg": "Error al guardar fechas críticas",
            "loadingErrorMsg": "Error al cargar información de fecha crítica",
            "clickToAdd": "Haga clic para agregar",
            "nextOccurrenceMsg": "Entre {{ beginning }} y {{ end }}",
            "daysOfWeek": {
                "mondayAbbr": "Lun",
                "tuesdayAbbr": "Mar",
                "wednesdayAbbr": "Mié",
                "thursdayAbbr": "Jue",
                "fridayAbbr": "Vie",
                "saturdayAbbr": "Sáb",
                "sundayAbbr": "Dom"
            },
            "examples": {
                "seeExampleWeeklyPayroll": "Ver ejemplo: Nómina semanal",
                "seeExampleMonthlyRent": "Ver ejemplo: Pago de alquiler mensual",
                "seeExampleOnetimeInvoice": "Ver ejemplo: Factura única",
                "payroll": "Nómina de sueldos",
                "rentPayment": "Pago de alquiler",
                "invoice1320": "Factura 1320"
            },
            "criticalDates": "Fechas críticas",
            "viewExamples": "1. Ver ejemplos",
            "createCriticalDates": "2. Crear nuevas fechas críticas",
            "reviewCriticalDates": "3. Revise sus fechas críticas",
            "viewExamplesTooltip": "Durante una interrupción, es útil saber cuándo se realiza este [[process]]. También es importante enumerar cualquier otra fecha crítica para continuar este [[process]] comercial durante una posible interrupción. Le hemos proporcionado tres ejemplos para su análisis",
            "createCriticalDatesTooltip": "Para agregar una nueva fecha crítica, puede copiar una tarjeta de ejemplo o crear una nueva tarjeta.",
            "reviewCriticalDatesTooltip": "¿Ha capturado todas las fechas necesarias para este [[process]] empresarial? Si ha capturado todas las fechas críticas necesarias, haga clic en Siguiente para continuar creando la estrategia de continuidad para este [[process]] empresarial.",
            "errorLoadingCriticalDates": "No se pudieron cargar las fechas críticas",
            "month": {
                "1": "Enero",
                "2": "Febrero",
                "3": "Marzo",
                "4": "Abril",
                "5": "Mayo",
                "6": "Junio",
                "7": "Julio",
                "8": "Agosto",
                "9": "Septiembre",
                "10": "Octubre",
                "11": "Noviembre",
                "12": "Diciembre"
            }
        },
        "continuityStrategy": {
            "continuityStrategyTitle": "Estrategia de continuidad para {{ name }}",
            "continuityStrategyForDesc": "La estrategia de continuidad para {{ name }} es para",
            "responsibleEmployeesTemporaryLocationDesc": "Empleados autorizados para aprobar el espacio de trabajo temporal",
            "responsibleEmployeesMoveTheWorkDesc": "Empleados que se pondrán en contacto con el proveedor y podrán autorizar la transferencia.",
            "responsibleEmployeesSuspendDesc": "Empleados autorizados a suspender el [[process]]",
            "designatedEmployeesWorkFromHomeDesc": "Empleados que serán necesarios",
            "designatedEmployeesTemporaryLocationDesc": "Empleados designados para trabajar en el espacio de trabajo temporal",
            "equipmentNeededLabel": "Seleccionar los [[team]]s que necesitará cada empleado o agregar los que falten",
            "selectedEquipments": "[[Team]]s que necesitará cada empleado",
            "saveSuccessMsg": "Estrategia de continuidad guardada",
            "saveErrorMsg": "No se pudo guardar la estrategia de continuidad",
            "saveEquipmentSuccessMsg": "[[Team]] {{names}} adicional",
            "saveEquipmentErrorMsg": "No se pudo agregar el [[team]] {{names}}",
            "removeEquipmentSuccessMsg": "[[Team]] {{names}} quitado",
            "removeEquipmentErrorMsg": "No se pudo quitar el [[team]] {{names}}",
            "saveVendorSuccessMsg": "Vendedor {{names}} adicional",
            "saveVendorErrorMsg": "No se pudo agregar el proveedor {{names}}",
            "removeVendorSuccessMsg": "Vendedor {{names}} quitado",
            "removeVendorErrorMsg": "No se pudo quitar el proveedor {{names}}",
            "saveAssigneeSuccessMsg": "Empleado autorizado {{names}} agregado",
            "saveAssigneeErrorMsg": "No se pudo agregar un empleado autorizado {{names}}",
            "removeAssigneeSuccessMsg": "Empleado autorizado {{names}} quitado",
            "removeAssigneeErrorMsg": "No se pudo quitar al empleado autorizado {{names}}",
            "saveAssigneesSuccessMsg": "Empleados autorizados {{names}} agregados",
            "saveAssigneesErrorMsg": "No se pudo agregar empleados autorizados {{names}}",
            "removeAssigneesSuccessMsg": "Empleados autorizados {{names}} quitados",
            "removeAssigneesErrorMsg": "No se pudo quitar a los empleados autorizados {{names}}",
            "saveEmployeeSuccessMsg": "Empleado designado {{names}} agregado",
            "saveEmployeeErrorMsg": "No se pudo agregar al empleado designado {{names}}",
            "removeEmployeeSuccessMsg": "Empleado designado {{names}} quitado",
            "removeEmployeeErrorMsg": "No se pudo quitar al empleado designado {{names}}",
            "saveEmployeesSuccessMsg": "Empleados designados {{names}} agregados",
            "saveEmployeesErrorMsg": "No se pudo agregar empleados designados {{names}}",
            "removeEmployeesSuccessMsg": "Empleados designados {{names}} quitados",
            "removeEmployeesErrorMsg": "No se pudo quitar a los empleados designados {{names}}",
            "saveLocationSuccessMsg": "[[Location]] {{names}} actualizada",
            "saveLocationErrorMsg": "No se pudo actualizar la [[location]] {{names}}",
            "removeLocationSuccessMsg": "[[Location]] {{names}} quitada",
            "removeLocationErrorMsg": "No se pudo quitar la [[location]] {{names}}",
            "saveTechnologySuccessMsg": "[[Technology]] o [[application]] {{names}} agregada",
            "saveTechnologyErrorMsg": "No se pudo agregar [[technology]] o [[application]] {{names}}",
            "removeTechnologySuccessMsg": "[[Technology]] o [[application]] {{names}} quitada",
            "removeTechnologyErrorMsg": "No se pudo quitar la [[technology]] o la [[application]] {{names}}",
            "saveInstructionsSuccessMsg": "Instrucciones adicionales guardadas",
            "saveInstructionsErrorMsg": "No se pudieron guardar instrucciones adicionales",
            "loadErrorMsg": "Error al cargar la estrategia de continuidad",
            "missingRtoImpactErrorMsg": "Antes de asignar la estrategia de continuidad, asigne el RTO",
            "vendorsSelected": "{0} proveedores seleccionados",
            "selectAVendor": "Seleccione un proveedor",
            "technologiesSelected": "{0} [[technology]] seleccionadas",
            "selectATechnology": "Seleccione una [[technology]]",
            "strategyTypes": {
                "workFromAnywhere": "Trabaje desde cualquier lugar",
                "temporaryLocation": "Espacio de trabajo temporal",
                "moveTheWork": "Traslade el trabajo",
                "suspend": "Suspender",
                "9c004e84-4eb4-44f1-a008-cd33f5211014": "Trabaje desde cualquier lugar",
                "704acb8f-ed9b-4f11-ab37-0f0139895d5b": "Espacio de trabajo temporal",
                "e56b1600-219c-4985-b8fa-2b31228d201c": "Traslade el trabajo",
                "984817b1-c6ee-4c5e-ace7-e558843bdfcb": "Suspender"
            },
            "equipmentTypeModal": {
                "title": "Tipo de [[team]]",
                "desc": "Agregar o editar un tipo de [[team]] que se utilizará para esta estrategia de continuidad",
                "typeNamePlaceholder": "Nombre del tipo de [[team]]",
                "equipments": "[[Equipment]]",
                "equipmentInUseError": "El [[team]] está asignado a una estrategia de continuidad activa",
                "deleteEquipmentHeader": "Quitar [[team]]",
                "deleteEquipmentMessage": "¿Está seguro de que desea quitar este [[team]]?",
                "duplicateEquipmentNameError": "Nombre de [[team]] duplicado"
            },
            "searchForVendor": "Buscar proveedor",
            "vendorModal": {
                "title": "Proveedor",
                "desc": "Agregar o editar el proveedor que se utilizará para esta estrategia de continuidad",
                "typeNamePlaceholder": "Nombre del proveedor",
                "contactName": "Nombre de contacto",
                "contactNamePlaceholder": "Nombre y apellido",
                "contactPhoneNumber": "Teléfono de contacto",
                "contactPhoneNumberPlaceholder": "(123) 456-7890",
                "additionalNotes": "Notas adicionales",
                "additionalNotesPlaceholder": "Instrucciones para contactar al proveedor",
                "deleteVendorHeader": "Quitar proveedor",
                "deleteVendorMessage": "¿Está seguro de que desea quitar este proveedor?",
                "addVendor": "Agregar proveedor",
                "vendors": "Proveedores",
                "vendorInUseError": "El proveedor está asignado a un plan activo",
                "duplicateVendorNameError": "Nombre de proveedor duplicado",
                "vendorAddress": "Dirección del proveedor",
                "contractStartDate": "Fecha de inicio del contrato",
                "contractExpirationDate": "Fecha de expiración de contrato",
                "bulkUploadVendors": "Proveedores de carga masiva",
                "bulkUploadContacts": "Carga masiva de contactos"
            },
            "agencyModal": {
                "title": "Agencia",
                "desc": "Agregar o editar la agencia que se utilizará para esta estrategia de continuidad",
                "typeNamePlaceholder": "Nombre de Agencia",
                "contactName": "Nombre de Contacto",
                "contactNamePlaceholder": "Nombre y Apellido",
                "contactPhoneNumber": "Teléfono de Contacto",
                "contactPhoneNumberPlaceholder": "(123) 456-7890",
                "additionalNotes": "Notas Adicionales",
                "additionalNotesPlaceholder": "Instrucciones para contactar con la agencia",
                "deleteAgencyHeader": "Quitar agencia",
                "deleteAgencyMessage": "¿Estás seguro de que deseas eliminar esta agencia?",
                "addAgency": "Agregar Agencia",
                "agencies": "Agencias",
                "agencyInUseError": "La agencia está asignada a una estrategia de continuidad activa.",
                "duplicateAgencyNameError": "Nombre de agencia duplicado",
                "agencyAddress": "Dirección de la Agencia",
                "contractStartDate": "Fecha de inicio del contrato",
                "contractExpirationDate": "Fecha de Expiración de Contrato",
                "bulkUploadAgencies": "Agencias de Carga Masiva",
                "bulkUploadContacts": "Carga Masiva de Contactos",
                "new": "Agregar Nueva Agencia",
                "agencyType": "Tipo de Agencia",
                "selectType": "Seleccione un tipo de agencia",
                "rolesFilter": "Filtro de roles"
            },
            "agencyAssignModal": {
                "assignAgency": "Asignar agencia",
                "assignLocation": "Asignar ubicación",
                "resultLabel": "Seleccionar Agencia",
                "resultLabelLocation": "Seleccionar Ubicación",
                "search": "Busca aquí",
                "currentAgenciesLabel": "Agencias actuales",
                "assignButton": "Asignar",
                "currentAgency": "Agencia actual",
                "resultLabelSingle": "Seleccionar Agencia"
            },
            "confirmRemoveVendor": {
                "header": "Quitar proveedor del [[process]]",
                "body": "¿Está seguro de que desea quitar a este proveedor de este [[process]]?",
                "text": "Quitar"
            },
            "searchForTechnology": "Buscar [[application]] o [[technology]]",
            "confirmRemoveTechnology": {
                "header": "Quitar [[application]] o [[technology]] del [[process]]",
                "body": "¿Está seguro de que desea quitar esta [[application]] o [[technology]] de este [[process]]?",
                "text": "Quitar"
            },
            "technologyModal": {
                "title": "[[Technology]] o [[Application]]",
                "desc": "Seleccione cualquier [[application]] o [[technology]] que sea necesaria o agregue las que falten",
                "deleteTechnologyHeader": "Eliminar [[Technology]]",
                "deleteTechnologyMessage": "¿Estás seguro de que deseas eliminar esta [[Technology]]?",
                "technologies": "[[Technologies]]",
                "technologyInUseError": "[[Technology]] está asignada a un plan activo",
                "technology": "[[Technology]]",
                "duplicateTechnologyNameError": "Nombre de [[Technology]] duplicado",
                "seconds": "Artículos de segunda clase",
                "minutes": "Minutos",
                "hours": "Horas",
                "days": "Días",
                "second": "Segundo",
                "minute": "Minuto",
                "hour": "Hora",
                "day": "Día",
                "selectRPOTime": "Seleccionar hora",
                "bulkUpload": "Carga masiva",
                "manageTechnologyTypes": "Administrar tipos de [[Technology]]",
                "addTechnology": "Agregar nueva [[Technology]]",
                "typeNamePlaceholder": "[[Technologies]]",
                "technologyType": "[[Technology]] Tipo",
                "technologyTypes": "[[Technology]] Tipos",
                "technologyUsed": "[[Technology]] Usada",
                "technologyTypesTitle": "Administre sus tipos de [[Technology]] aquí",
                "tier": "Nivel",
                "technologyTypeDeleteHeader": "Eliminar [[Technology]] Tipo",
                "technologyTypeDeleteBody": "¿Está seguro de que desea eliminar este tipo de [[Technology]]?",
                "technologyTypeSavedSuccessfully": "El tipo [[Technology]] se ha guardado correctamente",
                "technologyTypeSavedUnSuccessfully": "El tipo [[Technology]] se ha guardado sin éxito",
                "technologyTypeDeletedSuccessfully": "El tipo [[Technology]] se ha eliminado correctamente",
                "technologyTypeDeletedUnSuccessfully": "El tipo [[Technology]] se ha eliminado sin éxito"
              },
            "instructionsModal": {
                "title": "Instrucciones adicionales",
                "instructionsPLaceHolder": "Ingresar instrucciones adicionales"
            },
            "addEmployee": "Agregar empleado",
            "pickOrAddVendor": "Seleccionar un proveedor o agregar un nuevo proveedor de la lista",
            "pickOrAddTechnology": "Seleccione cualquier [[application]] o [[technology]] que necesite o agregue las que faltan",
            "pickOrAddLocation": "Seleccione una [[location]] o agregue una nueva ubicación de la lista.",
            "searchForLocationPlaceholder": "[[Location]] del espacio de trabajo temporal",
            "externalLocationModal": {
                "title": "Ubicación del espacio de trabajo",
                "desc": "Agregar o editar una ubicación de espacio de trabajo temporal"
            },
            "companyLocation": "Ubicación de la empresa",
            "vendorLocation": "Ubicación temporal",
            "addVendor": "Agregar proveedor",
            "confirmRemoveAssignee": {
                "header": "Quitar empleado del [[process]]",
                "body": "¿Está seguro de que desea quitar a este empleado de este [[process]]?",
                "text": "Quitar"
            },
            "assigneeModal": {
                "headerLabel": "Seleccionar un empleado",
                "searchUserLabel": "Buscar un empleado",
                "currentUserLabel": "Empleados asignados actualmente"
            },
            "managerModal": {
                "headerLabel": "Seleccione un gerente",
                "searchUserLabel": "Buscar un gerente",
                "currentUserLabel": "Gerente actualmente asignado",
                "resultLabel": "Gerentes"
            },
            "continuityStrategy": "Estrategia de continuidad",
            "vendorAlreadySelected": "Proveedor ya seleccionado",
            "selectedVendors": "Proveedores seleccionados",
            "technologyAlreadySelected": "[[Application]] o [[technology]] ya seleccionada",
            "selectedTechnologies": "Aplicaciones o [[technologies]] seleccionadas",
            "selectedLocation": "[[Location]] seleccionada",
            "continuityStrategiesByRto": "Estrategias de continuidad por RTO",
            "strategy": "Estrategia",
            "missingRequiredFields": "Revise los campos que faltan antes de continuar",
            "continuityStrategyInstructions": "Para completar el Plan de continuidad empresarial para este [[process]], seleccione una estrategia de continuidad a continuación y complete las secciones correspondientes. Una vez completado, haga clic en Realizado para finalizar este [[process]] comercial.",
            "lblAdditionalInstructions": "Instrucciones adicionales",
            "noAdditionalInstructions": "Sin instrucciones adicionales",
            "updateInstructions": "Actualizar instrucciones",
            "uploadDocument": "Cargar documento",
            "noAttachments": "Sin adjuntos",
            "lblAdditionalQuestions": "Preguntas adicionales",
            "noAdditionalQuestions": "Sin preguntas adicionales",
            "createStrategy": "Crear estrategia",
            "strategyCreated": "Estrategia creada",
            "strategyDeleted": "Estrategia eliminada"
        },
        "noAssignedFunctionalArea": "No hay [[departments]] asignados",
        "noAssignedProcess": "Sin [[process]] asignado",
        "searchUsers": "Ingrese el nombre, rol, [[department]] o grupo para buscar",
        "noAssignedUserError": "Haga una selección antes de continuar",
        "sendIntroductoryEmail": "Envíe un correo electrónico de presentación",
        "sendTeamLeadEmailTitle": "Ahora que ha creado su [[team]], vamos a enviarles un correo electrónico. Se ha proporcionado una plantilla de correo electrónico para su revisión en el lado derecho de la pantalla. Cuando esté listo para enviar el correo electrónico, asigne una fecha límite y haga clic en Enviar y finalizar.",
        "sendTeamMemberEmailTitle": "Ahora que ha creado su [[team]], vamos a enviarles un correo electrónico. Se ha proporcionado una plantilla de correo electrónico para su revisión en el lado derecho de la pantalla. Cuando esté listo para enviar el correo electrónico, asigne una fecha límite y haga clic en Enviar y finalizar.",
        "titleAssignments": "{{userProfileName}} Asignaciones",
        "functionalAreas": "[[Departments]]",
        "titleNavigatePlan": "Planes de continuidad empresarial",
        "introductoryVideo": "Video introductorio",
        "plans": {
            "plans": "Planes",
            "generatePlansByDepartment": "Generar planes por [[department]]",
            "lastGeneratedDate": "Última fecha generada",
            "planTemplate": "Plantilla de plan",
            "generatePlans": "Generar [[Plans]]",
            "errGeneratePlanCanceled": "Se canceló la generación del plan.",
            "errorDownloadingPlan": "Se produjo un error inesperado al descargar el plan.",
            "errGeneratePlanNoTemplate": "No se puede iniciar la generación del [[plan]]. La plantilla de [[Plan]] no está asignada.",
            "noPlans": "Sin planes.",
            "generateDepartmentPlans": "Generar todos los planes de [[Department]]",
            "generateTechnologyPlans": "Generar todos los planes de [[Technology]]",
            "generateLocationPlans": "Generar Planos de [[Location]]",
            "generateCompanyPlan": "Generar Plan de Empresa",
            "continuityOfOperationPlanning": "Plan COG",
            "emergencyOperations": "Plan EOP",
            "continuityOfGovernment": "Continuidad de Gobierno (COG)",
            "businessContinuity": "Plan de Empresa",
            "disasterRecovery": "Plan de Empresa",
            "templateName": "Nombre de la Plantilla",
            "goToTemplates": "Ir a Plantillas"
        },
        "functions": "Funciones",
		"essentialFunctions": "Funciones esenciales",
        "processes": "[[Processes]]",
        "manageFunctionalAreas": "Administrar [[departments]]",
        "viewFunctionalAreas": "Ver [[departments]]",
        "summary": "Resumen",
        "titleOverview": "Resumen del [[team]]",
        "overviewDescription": "A continuación se muestra una lista de los [[departments]] con sus respectivos líderes de [[team]] que ahora están a cargo de completar sus secciones individuales del plan de continuidad. Si se ha asignado a sí mismo como líder de [[team]] para un [[department]]; puede comenzar en cualquier momento haciendo clic en Comenzar. De lo contrario, puede ver el avance de cada [[Department]] individual en la sección de estado.",
        "overviewDescriptionHeader": "Descripción, declaración de misión o visión del [[department]]",
        "descriptionSavedSuccessfully": "Descripción guardada con éxito",
        "descriptionSavedUnsuccessfully": "Descripción guardada sin éxito",
        "teamLeadDescription": "A continuación, se muestra una lista de los [[departments]] asignados en los que es experto. Haga clic en el botón de inicio para comenzar a validar los [[processes]] dentro del [[department]] asignado y delegar los que sean necesarios.",
        "processesHeader": "[[Processes]]",
        "reviewAndManage": "1. Revisar y administrar",
        "trackTeamsProgress": "2. Seguimiento del avance de su [[team]]",
        "reviewAndManageTooltip": "Revise sus [[departments]] y los Líderes de [[team]] previamente seleccionados. Haga cambios cuando sea necesario haciendo clic en el botón Administrar [[departments]].",
        "trackTeamsProgressTooltip": "Haga un seguimiento del avance de la planificación de la continuidad empresarial de su [[team]]. Cuando las asignaciones estén terminadas, aparecerá una marca de comprobación",
        "teamLead": "Jefe de [[team]]",
        "sendAndFinish": "Enviar y finalizar",
        "lblSelectDueDate": "Seleccionar la fecha de límite",
        "lblDueDate": "Fecha límite",
        "exampleEmail": "Ejemplo de correo electrónico",
        "msgWelcomeAgilityPlanner": "¡Bienvenido al Preparis Planner! A continuación se muestra una descripción general del [[team]] que creó. En esta sección puede hacer lo siguiente:",
        "msgWelcomeAgilityPlannerTeamLead": "¡Bienvenido al Preparis Planner! A continuación se muestra una descripción general de sus asignaciones. En esta sección puede hacer lo siguiente:",
        "msgWelcomeAgilityPlannerTeamMember": "¡Bienvenido al Preparis Planner! A continuación se muestra una descripción general de sus asignaciones. En esta sección puede hacer lo siguiente:",
        "reviewAssignmentsTeamMember": "1. Revisar asignaciones",
        "getStartedTeamMember": "2. Comenzar",
        "reviewAssignmentsTeamMemberTooltip": "Revise sus [[processes]] asignados a continuación junto con las fechas límite correspondientes. Los [[processes]] enumerados en esta sección le han sido asignados como experto en la materia por su líder de [[team]]. ",
        "getStartedTeamMemberTooltip": "Comience sus asignaciones en cualquier momento haciendo clic en el botón Iniciar para un [[process]] en particular. Una vez que haya hecho clic en el botón de Inicio, será dirigido a un breve video introductorio que le proporcionará instrucciones adicionales.",
        "msgFailSendEmail": "Error al enviar correos electrónicos de presentación",
        "msgSuccessSendEmail": "Correo electrónico enviado a {{name}}",
        "msgDueDateSaved": "Fecha límite actualizada",
        "lblSentEmailOn": "Enviado",
        "emailTemplate": {
            "userName": "John Doe",
            "departmentNames": "Recursos humanos",
            "processNames": "Nómina de sueldos",
            "loginName": "john.doe@preparis.com",
            "placeHolders": {
                "recipientName": "Nombre del destinatario",
                "senderName": "Nombre del remitente",
                "departmentNames": "Nombres de [[departments]]",
                "processNames": "Nombres del [[process]]",
                "portalUrl": "Enlace del portal de recuperación de Preparis",
                "activateUrl": "Enlace de activación de cuenta de usuario",
                "userName": "Inicio de sesión de cuenta de usuario",
                "technologyNames": "Nombres de [[technology]]"
            },
            "annexNames": "siguientes anexos",
            "technologyNames": "siguientes tecnologías"
        },
        "status": {
            "none": "No iniciado",
            "inProcess": "En curso",
            "complete": "Completar"
        },
        "sentDate": "Fecha enviado",
        "percentComplete": "Porcentaje completo",
        "dashboard": {
            "overallProgressAndIssueTracking": "Avance general y seguimiento de [[issue]]s",
            "progressByDepartment": "Avance por [[department]]",
            "progressByProcess": "Avance por [[process]]",
            "bcDashboardTitle": "Panel de control del plan de continuidad empresarial",
            "quickView": "Vista rápida",
            "assignedTeamMember": "[[Team Member]] asignado",
            "progress": "Avance",
            "errorLoadingProcesses": "Error al cargar [[processes]]",
            "viewAll": "Ver todo",
            "bcAnalytics": "Datos analíticos",
            "authorizedEmployees": "Empleados autorizados",
            "affectedEmployees": "Empleados afectados",
            "temporaryWorkspaceAddress": "Dirección del espacio de trabajo temporal",
            "errorLoadingData": "Error al cargar los datos del tablero",
            "issues": "[[Issues]]",
            "blank": "En blanco",
            "processGlobalSearchPlaceholder": "Buscar en todas las columnas",
            "selectableColumns": "Columnas seleccionables",
            "plannerDashboard": "Panel del planificador",
            "technologyToProcess": "[[Technology]] para procesar",
            "technologyToTechnology": "[[Technology]] a [[Technology]]",
            "total": "Total",
            "gaps": "Brechas",
            "technologyNoRunbook": "[[Technologies]] asignadas sin Runbook",
            "gapType": "Tipo de espacio",
            "criticalVendors": "Proveedores críticos",
            "disasterRecoveryTeamsToTechnology": "Equipos de recuperación de desastres a la [[technology]]",
            "progressByOwner": "Progreso por propietario",
            "progressByTier": "Progreso por nivel",
            "technologyOwner": "Propietario de [[Technology]]"
        },
        "bcIssue": {
            "userSelection": {
                "searchUserLabel": "Cambio de propietario",
                "currentUserLabel": "Propietario actual"
            },
            "issue": "Nota o [[issue]]",
            "issues": "Notas o [[issues]",
            "addNewIssue": "Agregar nota o [[issue]] nuevo",
            "createIssue": "Crear nota o emisión",
            "manageIssues": "Administrar notas o [[issue]]s",
            "viewIssues": "Ver notas o [[issue]]s",
            "changeOwner": "Cambio de propietario",
            "lblTitle": "Título",
            "lblDescription": "Descripción",
            "lblIssueStatus": "Estado",
            "lblIssueOwner": "Propietario",
            "lblManagementAttention": "Atención gerencial",
            "lblManagementAttentionAbbr": "Gcia.",
            "lblRevisionItemType": "Categoría",
            "lblFunctionalArea": "[[Department]]",
            "lblProcess": "[[Process]]",
            "lblComments": "Comentarios",
            "newComment": "Nuevo comentario",
            "addComment": "Agregar comentario",
            "commentOn": "acerca de",
            "bcIssueStateType_6171be3d-bd24-4f9c-a9cd-d571c513780d": "Abierto",
            "bcIssueStateType_8c7d2b2e-c08c-4ef0-881c-e386d86c2245": "Cerrado",
            "bcIssueStateType_1ed8f21b-edcc-4408-9e7c-ebb6447bafe0": "Archivado",
            "getIssueCommentsErrorMsg": "Error al obtener los comentarios del [[issue]]",
            "loadingIssuesErrorMsg": "Error al cargar los [[issue]]s",
            "ownerModal": {
                "headerLabel": "Seleccionar un propietario para el [[issue]]",
                "searchUserLabel": "Buscar propietario",
                "currentUserLabel": "Dueño actual",
                "successMsg": "Se cambió exitosamente el propietario del [[issue]] a {{ newOwnerName }}",
                "errorMsg": "Error al cambiar de propietario"
            },
            "saveIssueComment": {
                "successMsg": "Se guardó correctamente la nota o el comentario del [[issue]]",
                "errorMsg": "Error al guardar la nota o el comentario del [[issue]]"
            },
            "saveIssue": {
                "successMsg": "Se guardó exitosamente la nota o el [[issue]]",
                "errorMsg": "Error al guardar la nota o el [[issue]]",
                "errorPopulateRequiredFieldsMsg": "Completar todos los campos obligatorios"
            },
            "confirmDeleteIssueCommentModal": {
                "header": "Eliminar nota o emitir comentario",
                "body": "¿Está seguro de que desea eliminar la nota o el comentario del [[issue]] creado el {{ createdOn }}?",
                "text": "Eliminar",
                "successMsg": "Se eliminó correctamente la nota o el comentario del [[issue]]",
                "errorMsg": "Error al eliminar la nota o el comentario del [[issue]]"
            },
            "noIssues": "Sin [[issue]]s",
            "businessContinuityIssues": "[[Issues]] de continuidad empresarial",
            "attentionOnlyTitle": "Atención de gestión de necesidades",
            "allIssueTitle": "Número total",
            "msgErrorLoadingFunctionalAreas": "Error al cargar [[departments]]",
            "status": {
                "6171be3d-bd24-4f9c-a9cd-d571c513780d": "Abierto",
                "8c7d2b2e-c08c-4ef0-881c-e386d86c2245": "Cerrado"
            },
            "lblDisplayClosed": "Mostrar cerrado"
        },
        "bcRevisionItemTypes": {
            "9a8ca3b0-c29c-429a-bae3-5106ebc9eef6": "Forme su [[team]]",
            "8075edbe-224e-4af0-accf-095cc08a650d": "Enviar correo electrónico del líder del [[team]]",
            "3001b73a-eff1-442a-9bf0-31ef0002f995": "Validar [[process]]",
            "e7bd0842-eadc-483f-b9c5-9a3d5047b1f4": "Enviar correo electrónico de asignación",
            "d731177f-8663-44e8-a243-b058b4bf9527": "Evaluación de impacto",
            "be1d75e6-dac5-459f-ae5c-d565b20022de": "RTO",
            "a190d9cc-bf54-4cd4-80ba-9d1ac8028b3e": "Fechas críticas",
            "056594f5-b6f0-46fb-9855-1303b611e293": "Estrategia de continuidad",
            "6c046473-8b67-4037-b8e5-d806800d0b06": "Impacto en las finanzas",
            "70d4a412-d41f-4c98-962f-7a45ffeebeeb": "Impacto en el [[client]]",
            "886647d0-77ba-4cc1-9366-70d15506f63e": "Impacto en el empleado",
            "c3c00b14-80dd-4325-8849-2aebd26bd2d5": "Impacto en la marca",
            "881236ae-0445-4c49-9356-30910be262b4": "Impacto en los servicios",
            "92a17340-8be5-455d-81be-7cc58228eaa5": "Impacto en el cumplimiento",
            "79272c21-fcdf-4624-94c4-e6c95ba7c03d": "Dependencias del [[Process]]",
            "951b6fb4-14db-4cd2-92ab-f2d24aa38b4f": "Ubicaciones de [[Process]]",
            "f8e8fb86-f246-4892-a9cc-a889b99fe2b1": "Delegaciones de autoridad",
            "8169c5dd-85c0-4861-9983-1c7bf24e72fb": "Orden de Sucesión",
            "b2c8a5da-8e90-4657-8e9b-000962841860": "[[Department]] [[teams]]",
            "5ddccee3-fe67-4206-8f80-4ac92b7b6382": "[[Locations]]",
            "8ee5ce4f-5fba-43a9-95b1-42f668a6e97c": "[[Department]] descripciones",
            "3cba5e70-bd1b-4cb8-8eea-dce7e515db94": "[[Team]] Líder suplente",
            "b7b3c55a-bcb9-4846-ab25-faa09ddc6254": "Organizativo [[teams]]",
            "184fb5cd-a848-4b5e-9384-94057e62a2b1": "Seguimiento de [[Departments]]",
            "78eab4a2-ac01-415a-b5f9-a859b64387a4": "Seguimiento de [[Processes]]",
            "139e56cf-346e-4bdd-90b5-02e55fd3d25d": "Anexo Agencias",
            "7fdf1dcc-df3d-4394-b9e6-96ca9a91b290": "Lista de acciones del anexo",
            "94caacc3-832a-4aec-b5da-63a1679e4eff": "Preguntas generales",
            "f7518654-5f95-47c7-a01c-6cd833423f2e": "Anexos de seguimiento",
            "451cd7cd-e99a-42ff-aa2e-213063801e55": "Orden organizacional de sucesión",
            "d6686ed4-ef96-48f4-8f58-3c98227baaa7": "Delegación organizacional de autoridad",
            "dfd9839e-9f89-4d18-8442-cf4920068326": "Secciones del Anexo",
            "fd9bc961-2d0b-4fc3-932a-894f55463717": "Sustitución",
            "9866e964-4fd7-4b93-a247-afd1b2abae50": "Construya y administre sus anexos",
            "deb3139e-5f75-441d-982c-cf0ae4d61c5f": "Construya y administre sus tecnologías",
            "bb95d0b1-482b-4c98-8006-165082f396b5": "Correo electrónico de introducción",
            "2c9d3e1d-b028-4859-b2cd-40860d103670": "Proveedores de tecnología",
            "637b9412-ead5-4656-9bfd-9c856ac152aa": "Conjuntos de tareas [[technologies]]s",
            "9f52df24-261e-4967-85ad-65c5299fa6fe": "Dependencias tecnológicas",
            "7c484880-6dc1-4413-8954-010db5e6ee52": "Tiempo de recuperación de la tecnología",
			"ba765aaf-562e-4f9a-8e18-e9748a13f180": "Enviar correos electrónicos al propietario de la tecnología",
			"f07ab2cd-4f19-428f-8b9f-54982abfa079": "Seguimiento de tecnologías",
			"4c04b488-542b-49aa-9b99-851a44f4e22b": "Detalles de la aplicación de tecnología"
        },
        "bcProgressStatusTypes": {
            "notStarted": "No iniciado",
            "inProcess": "En curso",
            "complete": "Completar"
        },
        "bcCustomFields": {
            "customFieldsLabel": "Preguntas personalizadas",
            "technologyTypes": "Tipos de tecnología",
            "help": {
                "selectTechnologyTypesToolTip": "Lista de tipos de tecnología disponibles."
            }
        },
        "ordersOfSuccession": {
            "title": "Órdenes de Sucesión",
            "description": "A continuación puede mantener los órdenes de sucesión dentro de su organización. ",
            "newSuccession": "Nueva Sucesión",
            "AddPrimary": "Agregar principal",
            "AddAlternate": "Agregar alternativa",
            "AddAlternates": "Agregar suplentes",
            "selectPrimaryUser": {
                "headerLabel": "Seleccione un agente principal para este orden de sucesión",
                "searchUserLabel": "Buscar un agente principal para este orden de sucesión",
                "currentUserLabel": "Agente principal actual",
                "resultLabel": "Agentes"
            },
            "selectAlternateUser": {
                "headerLabel": "Seleccione un agente alternativo para este orden de sucesión",
                "searchUserLabel": "Buscar un agente alternativo para este orden de sucesión",
                "currentUserLabel": "Agente alterno actual",
                "resultLabel": "Agentes"
            },
            "orderSavedSuccessfully": "La Orden de Sucesión ha sido guardada con éxito",
            "orderSavedUnsuccessfully": "La Orden de Sucesión se ha guardado sin éxito",
            "removeOrderOfSuccession": "Eliminar orden de sucesión",
            "removeOrderOfSuccessionDescription": "¿Está seguro de que desea eliminar esta orden de sucesión?",
            "successOnDelete": "La Orden de Sucesión se eliminó con éxito",
            "failedToDelete": "La orden de sucesión se eliminó sin éxito",
            "titleDescription": "Un nombre descriptivo del orden de sucesión.",
            "primaryDescription": "La persona principal que es para esta Orden de Sucesión.",
            "alternatesDescription": "Una lista ordenada de quién tendría éxito en un evento en el que la persona principal no pueda desempeñar sus funciones.",
            "alternate": "Alterno",
            "titleDetails": "Detalles del orden de sucesión",
            "description2": "Agregue el orden predeterminado de sucesiones para esta industria.",
            "oosSecondaryDescription": "Defina aquí sus órdenes de sucesión.",
            "oosDescription": "Gestiona las órdenes de sucesión que dan soporte a tu departamento. Las órdenes de sucesión documentan la línea de personas para un cargo en caso de que la persona principal no esté presente.",
            "oosOrgDescription": "A continuación podrá mantener los órdenes de sucesión dentro de su organización."
        },
        "authorityDelegation": {
            "headerSummary": "Delegaciones de autoridad",
            "header": "Delegación de autoridad",
            "title": "Título",
            "triggerCondition": "Desencadenar",
            "triggersPlaceholder": "Ingrese sus factores desencadenantes aquí",
            "limitation": "Limitaciones",
            "limitationsPlaceholder": "Ingrese sus limitaciones aquí",
            "actingPerson": "Persona interina",
            "delegatedPerson": "Persona Delegada",
            "modalMsg": "{{field}} por la delegación de autoridad",
            "saveSuccess": "Delegación de autoridad guardada con éxito",
            "saveError": "Delegación de autoridad guardada sin éxito",
            "titleVerbiage": "Aquí es donde registra una función particular que un individuo está autorizado a realizar.",
            "triggersVerbiage": "Aquí es donde registra los puntos desencadenantes en los que necesitaría hacer la transición de las responsabilidades de un Agente Interino a un Agente Delegado.",
            "limitationsVerbiage": "Aquí es donde registra las limitaciones que un Agente Delegado tendría que cumplir cuando las responsabilidades se transfieran de un Agente Interino.",
            "actingVerbiage": "Aquí es donde registra los nombres de la(s) persona(s) principal(es) en funciones que son responsables de la Delegación especificada.",
            "delegatedVerbiage": "Aquí es donde registra los nombres de las personas delegadas/secundarias que son responsables de la Delegación especificada.",
            "addActing": "nueva actuación",
            "addDelegated": "Nueva Delegación",
            "headerList": "Delegaciones de autoridad",
            "titleListVerbiage": "A continuación puede mantener las delegaciones de autoridad dentro de su organización. ",
            "confirmDeleteHeader": "Borrar {{ name }}",
            "confirmDeleteVerbiage": "¿Está seguro de que desea eliminar esta delegación de autoridad?",
            "successOnDelete": "La delegación fue eliminada con éxito",
            "failedToDelete": "La delegación fue eliminada sin éxito",
            "actives": "Activo% s",
            "delegates": "delegados",
            "lastUpdated": "Última actualización: {{date}}",
            "backToSummary": "Volver al Resumen",
            "markComplete": "Marcar completo",
            "searchUser": {
                "headerLabel": "Seleccione un agente para esta delegación de autoridad",
                "searchUserLabel": "Buscar un agente para esta Delegación de Autoridad",
                "currentUserLabel": "Agentes actuales",
                "resultLabel": "Agentes"
            },
            "limitationsTooltip": "Aquí es donde registra las limitaciones que un Agente Delegado tendría que cumplir cuando las responsabilidades se transfieran de un Agente Interino.",
            "actionPersonTooltip": "Aquí es donde registra los nombres de la(s) persona(s) principal(es) en funciones que son responsables de la Delegación especificada.",
            "delegatedPersonTooltip": "Aquí es donde registra los nombres de las personas delegadas/secundarias que son responsables de la Delegación especificada.",
            "authorityDescription": "Aquí es donde registra una función particular que un individuo está autorizado a realizar.",
            "triggersDescription": "Aquí es donde registra los puntos desencadenantes en los que necesitaría hacer la transición de las responsabilidades de un Agente Interino a un Agente Delegado.",
            "limitationsDescription": "Aquí es donde registra las limitaciones que un Agente Delegado tendría que cumplir cuando las responsabilidades se transfieran de un Agente Interino.",
            "primaryDescription": "Aquí es donde registra los nombres de la(s) persona(s) principal(es) en funciones que son responsables de la Delegación especificada.",
            "delegatesDescription": "Aquí es donde registra los nombres de las personas delegadas/secundarias que son responsables de la Delegación especificada.",
            "actingAgent": "Agente interino",
            "delegatingAgent": "Agente Delegante",
            "actingAgentDesc": "Aquí es donde usted registra los nombres de las personas interinas/principales que son responsables de la Delegación especificada.",
            "backToDepartments": "Volver a Departamentos",
            "headerDetails": "Detalles de la delegación de autoridad",
            "titleListVerbiage2": "Agregue las delegaciones de autoridad predeterminadas para esta industria.",
            "authorityOfDelegationSecondaryDescription": "Defina sus delegaciones de autoridad aquí.",
            "authorityOfDelegationDescription": "Gestionar las delegaciones de autoridad del departamento. ",
            "authorityOfDelegationOrgDescription": "A continuación puede mantener las delegaciones de autoridad dentro de su organización. "
        },
        "summarySidebar": {
            "question01": "¿Qué son los [[Processes]]?",
            "answer0100": "Los [[processes]] son las responsabilidades/tareas que su organización debe completar para ser considerada \"operativa\". ",
            "answer0101": "Las [[processes]] son las responsabilidades/tareas que su organización debe completar para ser considerada \"operativa\". ",
            "answer0102": "Varias [[processes]]:",
            "answer0103": "Realizar auditoría diaria del registro de cuentas por pagar (Contabilidad)",
            "answer0104": "Crear y enviar auditorías diarias de los beneficios de los empleados (Recursos Humanos)",
            "answer0105": "Detectar y extinguir incendios terrestres urbanos, rurales y salvajes (Fuego)",
            "answer0106": "Responder a llamadas al 911 y lesiones vehiculares (EMS)",
            "answer0107": "Entregar medicamentos a pacientes con Tuberculosis/VIH (Salud)",
            "answer0108": "Inspeccionar y mantener el sistema de agua/aguas residuales (Obras Públicas)",
            "question02": "¿Qué son las Delegaciones de Autoridad?",
            "answer0201": "Dentro de casi todas las organizaciones, hay personal designado con la autoridad para hacer ciertas cosas que muy pocos dentro de la organización están autorizados a hacer. ",
            "answer0202": "Consejo: La sección Delegación de autoridad es MUY similar a la sección Órdenes de sucesión dentro de su plan; ",
            "answer0203": "Aquí hay algunos ejemplos de autoridades para que usted considere que podrían aplicarse:",
            "answer0204": "¿Quién puede firmar cheques, contratos u otros documentos legales?",
            "answer0205": "¿Quién puede otorgar autorización de viaje, vacaciones o licencia extendida?",
            "answer0206": "¿Quién puede hacer compras de ciertas cantidades por encima y más allá de los límites normales de compra?",
            "answer0207": "¿Quién puede cerrar el lugar de trabajo y/o determinar una apertura retrasada y por cuánto tiempo?",
            "answer0208": "¿Quién puede contratar y/o despedir personal?",
            "answer0209": "¿Existen facultades o capacidades especiales realizadas por personal específico dentro de la organización? ",
            "question03": "¿Qué son las Órdenes de Sucesión?",
            "answer0301": "Las órdenes de sucesión se pueden resumir con la pregunta: \"¿Quién sigue en la cadena de mando?\"",
            "answer0302": "Dentro de cada organización hay personal clave que tiene ciertos deberes, roles y responsabilidades autorizados específicos para su puesto. ",
            "answer0303": "Tener una Orden de Sucesión ayudará a facilitar una transición ordenada y predefinida de los puestos de liderazgo dentro de su organización. ",
            "answer0304": "Sugerencia: Algunas sucesiones identificadas por persona se crean para usted y todo lo que necesita hacer es asignar personal. ",
            "question04": "¿Qué son las funciones esenciales?"
        },
        "planTemplates": {
            "header": "Plantillas de planes",
            "continuityOfOperationPlanning": "Planificación de la Continuidad de las Operaciones (COOP)",
            "emergencyOperations": "Plan de Operaciones de Emergencia (EOP)",
            "continuityOfGovernment": "Continuidad de Gobierno (COG)",
            "disasterRecovery": "Planificación de recuperación de desastres (DRP)",
            "businessContinuity": "Planificación de la Continuidad del Negocio (BCP)",
            "lossOfServicesEmergency": "Plan de Operación de Emergencia por Pérdida de Servicios (LSEOP)",
            "tabInfo": "A continuación puede ver las plantillas que están actualmente en uso.",
            "newTemplates": "Nuevas plantillas",
            "assignedTemplates": "Plantillas en uso",
            "unAssignedTemplates": "Plantillas no en uso",
            "draftTemplates": "Borradores no publicados",
            "confirmDeleteHeader": "Borrar {{ name }}",
            "confirmDeleteVerbiage": "¿Está seguro de que desea eliminar esta plantilla del sistema?",
            "successOnDelete": "La plantilla del plan se eliminó con éxito",
            "failedToDelete": "La plantilla del plan se eliminó sin éxito",
            "exportTemplate": "Plantilla de plan de exportación...",
            "successOnExport": "Se exporta la plantilla del plan",
            "failedOnExport": "No se pudo exportar la plantilla del plan",
            "importTemplate": "Importar plantilla",
            "uploadPlanTemplate": "Cargar plantilla de plan",
            "dropFileInstruction": "Suelte o haga clic para seleccionar un archivo de plantilla de plan",
            "loadingTemplate": "Plantilla de plan de importación",
            "successOnImport": "Se importa la plantilla del plan",
            "failedOnImport": "No se pudo importar la plantilla del plan",
            "successOnSave": "La plantilla del plan se guardó correctamente",
            "failedToSave": "La plantilla del plan se eliminó sin éxito",
			"duplicateTemplateName": "Ya existe una plantilla de plan con el mismo nombre; elija un nombre diferente.",
            "createDuplicate": "Crear duplicado",
            "duplicateTemplate": "Plantilla duplicada",
            "assignDefaultTemplatesDescription": [
                "Hacer un plan de {{entityType}} predeterminado",
                "(se asignará a todos los {{entityType}}s no asignados)",
                "(se asignará a todos los {{entityType}} no asignados)"
            ],
            "assignTemplate": "Asignar Plantilla",
            "availableDepartments": "Departamentos Disponibles",
            "selectedDepartments": "Departamentos Seleccionados",
            "departmentsAssignedSuccessfully": "Departamentos asignados con éxito",
            "departmentsAssignedUnsuccessfully": "Departamentos asignados sin éxito",
            "lastUpdated": "Última actualización: {{name}} - {{date}}",
            "availableCompanies": "Empresas Disponibles",
            "availableLocations": "Ubicaciones disponibles",
            "selectedCompanies": "Empresas seleccionadas",
            "selectedLocations": "Ubicaciones seleccionadas",
            "targetsAssignedSuccessfully": "{{targetType}} asignados con éxito",
            "targetsAssignedUnsuccessfully": "{{targetType}} asignados sin éxito",
            "availableTechnologies": "[[Technologies]] disponibles",
            "selectedTechnologies": "[[Technologies]] seleccionadas",
            "successOnDuplicate": "La plantilla del plan se duplicó correctamente",
            "failedToDuplicate": "La plantilla del plan se duplicó sin éxito",
            "templateDetails": "Detalles de la plantilla",
            "setTemplateAsDefault": "Establecer plantilla como Predeterminada",
            "setTemplateDefaultConfirmation": "Esta plantilla ahora se usará como la plantilla predeterminada para {{planName}}",
            "promoteModalHeader": "Promover Plantilla",
            "promoteModalBody": "¿Está seguro de que desea promover esta plantilla a una plantilla de sistema para esta industria? <br/> {{industryName}} Tipo de industria.",
            "planLevel": "Nivel de Plan",
            "dataTables": "Tablas de datos",
            "insertTable": "Insertar tabla",
            "selectModalVerbiage": "Seleccione de abajo para ver e insertar su tabla de datos.",
            "planLevels": {
                "department": "[[Department]]",
                "location": "[[Location]]",
                "company": "Compañía",
                "companyITDR": "Compañía",
                "continuityOfGovernment": "Organización (COG)",
                "continuityOfOperationPlanning": "[[Department]]",
                "emergencyOperations": "Operaciones de Emergencia",
                "technology": "[[Technology]]"
            },
            "enterName": "Introduzca nombre aquí",
            "duplicatePublishedWarning": "Esto duplicará la versión publicada de esta plantilla. No se incluirá ningún cambio en el borrador.",
            "unpublishedChanges": "Cambios no publicados"
        },
        "planBuilder": {
            "planTemplate": {
                "draftVersion": "Versión preliminar",
                "planTemplateTargetType": "Plantilla de plan de {{planTargetType}}",
                "discard": "Descartar todos los cambios / Volver a la última publicación",
                "planTemplate": "Plantilla de planes",
                "publishTemplate": "Publicar plantilla",
                "createNewSection": "Crear nueva sección",
                "sectionSettings": "Ajustes de sección",
                "saveSection": "Guardar sección",
                "removeSection": "Eliminar sección",
                "removeSectionDescription": "¿Está seguro de que desea eliminar esta sección?",
                "unsavedChanges": "Cambios no guardados",
                "unsavedChangesDescription": "Tiene cambios sin guardar. ",
                "revertDescription": "¿Está seguro de que desea revertir este borrador al original y comenzar de nuevo, esto no se puede deshacer?",
                "publishDescription": "¿Estás seguro de que quieres publicar?",
                "pageBreakTrueLabel": "Empezar en nueva página",
                "pageBreakFalseLabel": "Continuar en la última sección",
                "noSections": "No existen secciones",
                "addNewSection": "Añadir nueva sección",
                "insertTag": "Insertar etiqueta",
                "insertTagDescription": "Seleccione entre las etiquetas a continuación para insertar.",
                "insertElementDescription": "Seleccione de abajo para ver e insertar su tabla de datos.",
                "tags": {
                    "departmentLead": "Jefe de Departamento",
                    "departmentLeadAlternate": "Suplente de Jefe de Departamento",
                    "departmentName": "Nombre de Departamento",
                    "departmentDescription": "Departamento Descripción",
                    "departmentDueDate": "Fecha de Vencimiento del Departamento",
                    "companyAddress": "Dirección de la Empresa",
                    "companyPhone": "Número de Teléfono Principal de la Empresa",
                    "companyName": "Nombre de Empresa",
                    "departmentLeadDescription": "El líder del departamento.",
                    "departmentLeadAlternateDescription": "El líder de departamento suplente.",
                    "departmentNameDescription": "El nombre del departamento.",
                    "departmentDueDateDescription": "La fecha límite para que el líder del equipo termine de definir su departamento.",
                    "companyAddressDescription": "La dirección donde se encuentra la oficina principal.",
                    "companyPhoneDescription": "El número de teléfono principal de la empresa.",
                    "companyNameDescription": "El nombre de la compañía.",
                    "departmentDescriptionDescription": "La descripción del departamento.",
                    "eMALocation": "Ubicación de la EMA",
                    "eMALocationDescription": "Dónde se encuentra su Agencia de Manejo de Emergencias.",
                    "eOCLocation": "Ubicación de EOC",
                    "eOCLocationDescription": "Dónde se encuentra su Centro de Operaciones de Emergencia.",
                    "massCare": "Cuidado de Masas",
                    "massCareDescription": "Qué agencia / departamento es responsable del cuidado y la alimentación masiva.",
                    "trainingTesting": "Pruebas de Entrenamiento",
                    "trainingTestingDescription": "Qué agencia/departamento es responsable de su Plan de Operaciones de Emergencia.",
                    "unmetNeeds": "Necesidades Insatisfechas",
                    "unmetNeedsDescription": "La agencia/departamento que coordina es responsable de las necesidades no satisfechas.",
                    "supersession": "Supersesión",
                    "supersessionDescription": "Información de supersesión del Plan de Operaciones de Emergencia.",
                    "planGenerationDate": "Fecha de generación del plan",
                    "planGenerationDateDescription": "Generar la fecha y hora en que se generó el documento del plan.",
                    "planTemplatePublished": "Plantilla de plan publicada",
                    "planTemplatePublishedDescription": "Generar la fecha y hora en que se publicó por última vez la plantilla de plan.",
                    "technologyERT": "Tiempo estimado de recuperación",
                    "technologyERTDescription": "El tiempo de recuperación estimado asignado en el runbook de [[technology]].",
                    "technologyName": "Nombre de la [[technology]]",
                    "technologyNameDescription": "El nombre de la [[Technology]].",
                    "technologyOwnerAlternate": "Propietario de [[Technology]] alternativo",
                    "technologyOwnerAlternateDescription": "El nombre del propietario alternativo de la [[technology]].",
                    "technologyOwner": "Propietaria de [[Technology]]",
                    "technologyOwnerDescription": "El nombre del propietario principal de la [[technology]].",
                    "technologyDueDate": "Fecha de vencimiento de la [[Technology]]",
                    "technologyDueDateDescription": "La fecha de vencimiento para que la propietaria de la [[technology]] termine de definir la [[technology]]."
                },
                "elements": {
                    "customFields": "Preguntas y respuestas sobre estrategia",
                    "vendors": "Vendedores o proveedores clave",
                    "assessments": "Evaluaciones de Riesgos",
                    "criticalDates": "Fechas críticas",
                    "portalTeams": "[[Teams]] [[Department]]",
                    "rTO": "Estrategias de Recuperación del [[Process]]",
                    "attachments": "Archivos adjuntos",
                    "technologies": "[[Technologies]]",
                    "processDependencies": "[[Process]] Dependencias",
                    "employeeDeployment": "Despliegue de empleados",
                    "subjectMatterExperts": "Miembros del [[Team]] de continuidad",
                    "customFieldsDes": "Todas las preguntas y respuestas sobre la estrategia de proceso del [[department]], incluyen la estrategia asociada.",
                    "vendorsDes": "Todo el [[process]] del [[department]], incluido el proveedor, las fechas de los contratos, las direcciones y los contactos.",
                    "assessmentsDes": "Todas las amenazas para el [[department]] desde la última evaluación de riesgos completada para el [[department]]. ",
                    "criticalDatesDes": "Todos los [[process]] de fechas críticas en todos los [[departments]].",
                    "portalTeamsDes": "Una tabla que enumera todos los [[Teams]] asociados al [[department]], incluye sus miembros o cargos y las responsabilidades correspondientes.",
                    "rTODes": "Enumera todos los [[department]] y sus [[process]], las tablas están agrupadas por estrategia de continuidad.",
                    "attachmentsDes": "Todos los anexos de [[process]] del [[department]], incluye el tiempo de recuperación del proceso. ",
                    "technologiesDes": "Todo el [[department]] de Aplicación/[[Technology]], incluye Objetivo de Punto de Recuperación (RPO), procesos asociados y Tiempo de Recuperación.",
                    "processDependenciesDes": "Todos los [[department]] [[process]] que tienen una dependencia, incluyen el Objetivo de Punto de Recuperación (RPO), el nombre del [[Process]] dependiente y el Objetivo de Tiempo de Recuperación (RTO) del [[process]] dependiente.",
                    "employeeDeploymentDes": "Todos los empleados de la Compañía que están asignados a un [[process]] departamental ordenado por RTO. ",
                    "subjectMatterExpertsDes": "Una tabla que enumera todos los expertos en la materia y su información de contacto junto con los [[processes]] que respaldan dentro del [[department]].",
                    "departments": "[[Departments]]",
                    "departmentsDes": "Todos los [[Departments]] dentro de la empresa junto con su descripción",
                    "equipment": "[[Equipment]] Dependencias",
                    "equipmentDes": "Todo el [[Equipment]] de la empresa que se requiere para la continuidad del [[Process]] en todos los [[Departments]], incluye el tiempo de recuperación del [[process]]",
                    "teamLeaders": "Lideres de [[Department]]",
                    "teamLeadersDes": "Todos los líderes de [[department]] y su información de contacto.",
                    "businessContinuityManagers": "Gerentes de Continuidad del Negocio",
                    "businessContinuityManagersDes": "Todos los responsables de continuidad del negocio de la empresa y su información de contacto.",
                    "delegationOfAuthority": "Delegaciones de Autoridades",
                    "delegationOfAuthorityDes": "Esta tabla enumerará todas las delegaciones de autoridades dentro del [[department]], factores desencadenantes y limitaciones, incluidos los miembros activos y delegados.",
                    "ordersOfSuccession": "Órdenes de sucesión",
                    "ordersOfSuccessionDes": "Esta tabla enumerará todos los órdenes de sucesión dentro del [[department]].",
                    "maximumTolerableDowntime": "Máximo tiempo de inactividad tolerable",
                    "maximumTolerableDowntimeDes": "La tabla de MTD (Máximo tiempo de inactividad tolerable) en los [[processes]] de continuidad del negocio.",
                    "processLocations": "[[Process]] [[Locations]]",
                    "processLocationsDes": "Esta tabla enumera todos los [[processes]] dentro del [[department]] y las [[locations]] en las que se realizan.",
                    "annexes": "Anexos",
                    "annexesDes": "Los anexos definidos dentro de su Plan de Operaciones de Emergencia.",
                    "emergencyOperations": {
                        "portalTeams": "Respuesta [[Teams]]",
                        "portalTeamsDes": "Esta tabla enumerará todos los [[teams]] de respuesta del Plan de Operaciones de Emergencia, incluidos los puestos, la información de contacto de los miembros y sus responsabilidades."
                    },
                    "iTDRTechnologyAttachments": "Accesorios",
                    "iTDRTechnologyAttachmentsDes": "Lista completa de todos los accesorios para la [[technology]] seleccionada.",
                    "iTDRTechnologySupportingVendors": "Proveedores de apoyo",
                    "iTDRTechnologySupportingVendorsDes": "Una tabla enumera todos los proveedores que admiten la [[technology]] seleccionada, incluye información de contacto del proveedor e información de SLA de [[technology]].",
                    "iTDRTechnologyTaskSets": "Conjuntos de tareas",
                    "iTDRTechnologyTaskSetsDes": "Una tabla enumera las tareas para la recuperación de la [[technology]] seleccionada agrupadas por Conjunto de tareas, incluye Tiempo asignado, Equipos, Miembros y Tareas.",
                    "iTDRTechnologyDetails": "Detalles de la [[technology]]",
                    "iTDRTechnologyDetailsDes": "Una tabla enumera todas las preguntas personalizadas de tipo de [[technology]], sus respuestas y las instrucciones de adición.",
                    "iTDRUpstreamTechnologyDependencies": "Dependencias de la [[technology]] ascendente",
                    "iTDRUpstreamTechnologyDependenciesDes": "Enumera todas las [[technologies]] dependientes ascendentes de esta [[technology]], el objetivo de punto de recuperación (RPO), el nombre de la [[technology]] y el objetivo de punto de recuperación (RPO).",
                    "iTDRTechnologyDisasterRecoveryTeams": "Equipos de recuperación ante desastres",
                    "iTDRTechnologyDisasterRecoveryTeamsDes": "Una tabla que enumera todos los equipos de recuperación ante desastres asociados a la [[technology]], incluye los nombres de los miembros, los cargos y las responsabilidades correspondientes.",
                    "iTDRCompanyTechnologiesAttachments": "Accesorios",
                    "iTDRCompanyTechnologiesAttachmentsDes": "Una lista de todos los datos adjuntos de [[technology]] de la organización, incluidos el nombre de la [[technology]] y el nombre de los datos adjuntos.",
                    "iTDRCompanytechnologiesByVendors": "[[Technology]] por proveedor",
                    "iTDRCompanytechnologiesByVendorsDes": "Una tabla enumera todos los proveedores que admiten una [[technology]] dentro de la organización.",
                    "iTDRCompanyTechnologiesByOwner": "[[Technology]] por propietario",
                    "iTDRCompanyTechnologiesByOwnerDes": "Una tabla que enumera todas las [[technologies]] con planes de recuperación, agrupadas por sus respectivos propietarios de tecnología.",
                    "iTDRCompanyDisasterRecoveryTeams": "Equipos de Recuperación ante Desastres",
                    "iTDRCompanyDisasterRecoveryTeamsDes": "Esta tabla enumerará todo el Equipos de Recuperación ante Desastres dentro de la organización, incluidos los puestos, la información de contacto de los miembros y sus responsabilidades."
                }
            }
        },
        "teams": {
            "title": "Equipos",
            "titleVerbiage": "A continuación se muestran los equipos organizativos.",
            "titleVerbiage2": "A continuación se muestran los equipos que se han asociado a su departamento.",
            "addTeam": "Nuevo Equipo",
            "linked": "Vinculado",
            "confirmDeleteHeader": "Borrar {{ name }}",
            "confirmDeleteVerbiage": "¿Está seguro de que desea eliminar este equipo?",
            "successOnDelete": "El equipo fue eliminado con éxito.",
            "failedToDelete": "El equipo fue eliminado sin éxito",
            "assignMembers": "Asignar miembros",
            "newTeam": "Nuevo Equipo",
            "createNew": "Crear Nuevo",
            "associateTeam": "Asociar un Equipo",
            "selectATeam": "Selecciona un equipo",
            "members": "Miembros",
            "addMembers": "Añadir Miembro(s)",
            "responsibilities": "Responsabilidades",
            "noMembersAdded": "No se agregaron miembros",
            "addMember": "Añadir Miembro",
            "position": "Posición",
            "positionPlaceholder": "Ingrese la posición aquí",
            "responsibilitiesPlaceholder": "Ingrese las responsabilidades aquí",
            "assignMember": "Asignar miembro",
            "enterTeamName": "Ingrese el nombre del equipo aquí",
            "TeamDetailsTitle": "Detalles de los Equipos",
            "teamName": "Nombre del equipo",
            "successOnSave": "El equipo se guardó con éxito.",
            "failedToSave": "El equipo se salvó sin éxito.",
            "noDescription": "Sin descripción",
            "slideOut": {
                "team": "Equipo",
                "teamName": "Ingrese el nombre oficial del equipo dentro de su organización. Los ejemplos incluyen \"Equipo de gestión de incidentes\", \"Equipo de gestión de crisis\" o \"Equipo de planificación\".",
                "teamDescription": "Proporcione un breve resumen de las funciones y responsabilidades del equipo dentro de su organización. Incluya funciones clave, objetivos principales y áreas críticas de enfoque.",
                "members": "Miembros",
                "memberName": "Nombre del miembro del equipo",
                "position": "El trabajo o posición del miembro dentro del equipo.",
                "responsibilities": "La responsabilidad del miembro o cargo dentro del equipo y grupo."
            },
            "createPosition": {
                "confirmDeleteVerbiage": "¿Está seguro de que desea eliminar esta posición?",
                "successOnDeletePosition": "La posición fue eliminada con éxito",
                "failedToDeletePosition": "La posición fue eliminada sin éxito",
                "searchUserLabel": "Buscar un miembro del equipo",
                "resultLabel": "Seleccionar miembro",
                "currentUserLabel": "Miembro actual del equipo"
            },
            "teamLinkedSuccessfully": "Equipo asociado con éxito",
            "removeTeamConfirmationHeader": "Remover equipo",
            "removeTeamConfirmationMessage": "¿Está seguro de que desea remover este equipo del departamento?",
            "teamRemovedSuccessfully": "Equipo removido con éxito",
            "teamRemovedUnsuccessfully": "Equipo removido sin éxito",
            "positions": "Posiciones",
            "titleVerbiage3": "Agregue los equipos predeterminados para esta industria.",
            "addPositions": "Añadir Puesto(s)",
            "addPosition": "Agregar Posición",
            "noPositionsAdded": "No se agregaron posiciones",
            "organizationalTeams": "Equipos organizacionales",
            "technologyTeams": "Equipos de recuperación de desastres",
            "organizationalTeamsDescription": "La creación de equipos de respuesta de continuidad es esencial para que las organizaciones gestionen las crisis de manera efectiva y mantengan las operaciones durante interrupciones inesperadas. Estos equipos dedicados, equipados con habilidades y recursos especializados, aseguran una toma de decisiones rápida y acciones coordinadas, salvaguardando la continuidad y minimizando las pérdidas potenciales.",
            "departmentalTeamsDescription": "Gestiona los equipos que dan soporte a este [[Department]]. Los equipos están compuestos por personas, puestos y responsabilidades, y se utilizan para planificar y abordar incidentes.",
            "technologyTeamsDescription": "Los equipos de recuperación ante desastres desempeñan un papel fundamental en la gestión y mitigación de las interrupciones en los sistemas y servicios de TI.",
            "departmentalTeams": "Equipos Departamentales",
            "organizationalTeamsSecondaryDescription": "Agregue sus equipos organizacionales aquí.",
            "departmentalTeamsSecondaryDescription": "Agregue sus equipos de [[Department]] aquí.",
            "technologyTeamsSecondaryDescription": "Identifique sus equipos de recuperación de desastres aquí",
            "associateModal": {
                "title": "Asignar Equipo",
                "switchDes": "Asignar a todos los [[Departments]]",
                "assignToAllSuccessfully": "El equipo ha sido asignado a todos los [[departments]] con éxito.",
                "assignToAllUnsuccessfully": "El equipo ha sido asignado a todos los [[departments]] sin éxito",
                "setTeamAsDefault": "Asignar equipo a todos los [[Departments]].",
                "setTeamDefaultConfirmation": "¿Está seguro de que desea asignar este equipo a cada [[department]]?"
            }
        },
        "bcPlanBuilder": "Generador de planes",
        "agencies": "Agencias",
        "annex": {
            "annexBuildManage": "Construir",
            "annexSecondaryDescription": "Selecciona los anexos que quieres incluir en tu plan",
            "addFromLibrary": "Agregar desde la biblioteca",
            "creatFromScratch": "Crear desde cero",
            "addAnnexDescription": "Agregue un anexo para comenzar",
            "annexTitle": "Título del anexo",
            "selectAnnex": "Seleccionar Anexo",
            "selectAnnexSecondaryDescription": "Seleccione los anexos a continuación que le gustaría utilizar en su plan.",
            "annexes": "Anexos",
            "edit": "Editar Anexo",
            "trackDescription": "Rastrea y completa sus anexos aquí.",
            "dueDateRequired": "Fecha de vencimiento requerida para todos los anexos.",
            "removeAnnex": "Eliminar anexo",
            "removeAnnexDescription": "¿Está seguro de que desea eliminar este anexo?",
            "manageLibrary": "Administrar biblioteca",
            "selectAnnexesToDeleteDescription": "Seleccione los anexos a continuación que desea eliminar de la biblioteca.",
            "removeSystemAnnex": "Eliminar anexo de la biblioteca",
            "removeSystemAnnexesDescription": "¿Está seguro de que desea eliminar estos anexos de la biblioteca??",
            "removeSystemAnnexDescription": "¿Está seguro de que desea eliminar este anexo del sistema de la biblioteca? Esto no se puede deshacer.",
            "setDueDate": {
                "header": "Fecha límite",
                "body": "Algunos annexos ya tienen una fecha límite, ¿desea establecer esta fecha límite {{dueDate}} para todos los annexos?",
                "allProcesses": "Sí",
                "newProcesses": "No",
                "msgConfirmBody": "¿Está seguro de que desea establecer esta nueva fecha límite {{dueDate}} para todos los annexos?"
            },
            "configureAnnex": {
                "label": "Configurar anexo",
                "removeAgency": "Quitar agencia",
                "removeAgencyDescription": "¿Está seguro de que desea eliminar esta agencia de esta función? Las acciones asignadas a esta agencia permanecerán en la lista de acciones.",
                "taskAgenciesSecondaryDescription": "Identifique sus agencias aquí.",
                "annexSectionsDescription": "A continuación se muestran las secciones de contenido que se incluirán en su anexo. Puede editar, reordenar o elegir no incluir.",
                "saveAnnexSystemLibrary": "Guardar anexo a la biblioteca del sistema",
                "removeAction": "Eliminar acción",
                "removeActionDescription": "¿Estás seguro de que deseas eliminar esta acción?",
                "responsibilitiesSecondaryDescription": "Identifique aquí las funciones y responsabilidades de su agencia.",
                "addAction": "Agregar acción",
                "saveNewSystemAnnex": "¿Está seguro de que desea guardar este anexo en la biblioteca del sistema?",
                "overWriteSystemAnnex": "¿Está seguro de que desea sobrescribir un anexo existente en la biblioteca del sistema con esta versión? ",
                "saveSystemAnnexSuccess": "Anexo guardado en la biblioteca del sistema",
                "saveSystemSuccessFail": "No se pudo guardar el anexo en la biblioteca del sistema",
                "sectionsDescription": "Documentar el propósito, alcance, conceptos, gestión financiera, y las autoridades de referencia que son clave para este anexo.",
                "agenciesDescription": "Documentar las agencias asignadas que desempeñan los roles de coordinación, primario y soporte dentro de este anexo.",
                "responsibilitiesDescription": "Identificar y documentar las responsabilidades de las agencias durante las múltiples fases de este anexo."
            }
        },
        "supersession": {
            "supersession": "Sustitución",
            "supersessionSecondaryDescription": "Una declaración de sustitución debe aclarar si este plan o cualquier documento dentro de este plan reemplazará algo anterior.",
            "supersessionSaveSuccessfully": "Supersesión guardada",
            "supersessionSaveUnsuccessfully": "No se pudo guardar la supersesión",
			"noSupersession": "Sin superación"
        },
        "generalQuestions": {
            "generalQuestions": "Preguntas generales",
            "description": "También enumerará las entidades a partir de la información que ingrese en las partes de la herramienta de la base de datos de contactos, departamentos e instalaciones.",
            "what": "Qué",
            "whatDescription": "Asigne sus agencias apropiadas aquí",
            "where": "Dónde",
            "whereDescription": "Asigne sus ubicaciones apropiadas aquí",
            "assignedAgency": "Agencia asignada",
            "assignAgency": "Asignar agencia",
            "assignedLocation": "Ubicación asignada",
            "assignLocation": "Asignar ubicación",
            "questions": {
                "massCare": "Atención masiva",
                "massCareDes": "¿Qué agencia/departamento es responsable de la atención masiva?",
                "unmet": "Necesidades no satisfechas",
                "unmetDes": "¿Qué agencia/departamento coordina las necesidades insatisfechas?",
                "EOC": "COE",
                "EOCDes": "¿Dónde están ubicados sus Centros de Operaciones de Emergencia (EOC)?",
                "EMA": "EMA",
                "EMADes": "¿Dónde está ubicada su agencia de gestión de emergencias (EMA)?",
                "training": "Pruebas y entrenamiento",
                "trainingDes": "¿Qué agencia/departamento es responsable de la capacitación?"
            },
            "saveSuccess": "Respuesta de preguntas guardada",
            "saveFailed": "No se pudo guardar la respuesta a las preguntas"
        },
        "customFields": {
            "customFieldTitle": "Preguntas Personalizadas",
            "customFieldsDescription": "Las preguntas personalizadas se añadirán a su flujo de planificación y las respuestas se mostrarán en su plan y en el gestor de incidencias.",
            "fieldName": "Nombre del campo",
            "fieldType": "Tipo de pregunta",
            "isRequired": "Requerido",
            "fieldTypes": {
                "5f31785c-4d3c-4d68-a834-da37c2bc0a76": "Numérico",
                "0f6f2372-1e4c-4d67-ad13-615a0540488c": "Texto corto",
                "b9847d16-19b5-4ed4-a4e3-d7f07533ab5a": "Texto largo",
                "d8bd3d80-d25d-4056-8754-a740f2396792": "Verdadero o falso",
                "aad6f035-05f9-42d3-b6ea-682371340875": "Seleccione uno",
                "76c83029-8439-42ad-90e6-7f0ff54b732c": "Seleccione varios",
                "1eccbe40-1b3b-461b-95df-35f1fe6a8af2": "Fecha/hora"
            },
            "isRequiredOptions": {
                "true": "Sí",
                "false": "No"
            },
            "displaySequence": "Secuencia de visualización",
            "fieldDomain": "Opciones",
            "success": {
                "deleteCustomFieldSuccess": "Eliminación exitosa del campo personalizado",
                "saveCustomFieldsDisplayOrderSuccess": "Se ha guardado exitosamente el orden de visualización",
                "saveCustomFieldSuccess": "Se ha guardado exitosamente el campo personalizado"
            },
            "errors": {
                "getCustomFieldsError": "No se pudieron cargar los campos personalizados",
                "saveCustomFieldsDisplayOrderError": "Error al guardar el nuevo orden de visualización",
                "deleteCustomFieldError": "Error al eliminar el campo personalizado",
                "customFieldWithSameNameError": "Error al guardar el campo personalizado con el mismo nombre",
                "saveCustomFieldError": "Error al guardar el campo personalizado",
                "customFieldOptionWithSameNameError": "Error al guardar el campo personalizado con nombres de opciones duplicados",
                "customFieldTypeSelectOneWithoutOptions": "Las opciones son obligatorias para los campos personalizados \"Seleccione uno\"",
                "customFieldsInvalid": "Revisar los campos personalizados"
            },
            "help": {
                "fieldNameTooltip": "Nombre de etiqueta del campo personalizado",
                "fieldTypeTooltip": "Tipo de datos para recopilar con este campo personalizado",
                "isRequiredTooltip": "Designar si este campo personalizado es requerido",
                "editCustomFieldInstructions": "Editar qué tipo de datos recopilar y si se requiere o no este campo personalizado.",
                "addCustomFieldInstructions": "Agregar un campo personalizado para recopilar información.",
                "optionsTooltip": "Enumerar las opciones en orden de visualización."
            },
            "editCustomField": "Editar campos personalizados existentes",
            "addCustomField": "Agregar nuevo campo personalizado",
            "newCustomField": "Nuevo campo personalizado",
            "confirmRemoveCustomField": {
                "header": "Eliminar campo personalizado",
                "body": "¿Está seguro de que desea eliminar este campo personalizado?",
                "text": "Eliminar"
            },
            "confirmSaveCustomField": {
                "header": "Guardar campo personalizado",
                "body": "¿Está seguro de que desea guardar este campo personalizado?",
                "text": "Guardar"
            },
            "customFields": "Campos personalizados",
            "optionName": "Nombre de opción",
            "addOption": "Agregar opción",
            "default": "Defecto",
            "restore": "Restaurar",
            "options": "Opciones",
            "makeDefault": "Configurar como predeterminado",
            "saveDisplayOrder": {
                "cancelDisplayOrder": "Cancelar orden",
                "saveDisplayOrder": "Guardar orden",
                "header": "Guardar nuevo orden de visualización",
                "body": "¿Está seguro de que desea cambiar el orden en que se muestran los campos?",
                "text": "Guardar orden de visualización"
            },
            "customFieldsActivity": "Actividad de campos personalizados",
            "deprecatedOption": "Opción obsoleta",
            "bcCustomQuestionsTitle": "Preguntas personalizadas de continuidad de negocios",
            "bcCustomQuestionsDescription": "Gestione sus preguntas personalizadas sobre continuidad de negocio.",
            "disasterRecovery": "Recuperación en caso de catástrofe",
            "drCustomQuestionsTitle": "Preguntas personalizadas de recuperación en caso de catástrofe.",
            "drCustomQuestionsDescription": "Gestione sus preguntas personalizadas de recuperación en caso de catástrofe.",
            "technology": "[[Technology]]",
            "continuityOfOperationPlanning": "Continuidad",
            "customQuestionsDescription": "Gestiona aquí tus dudas sobre continuidad aduanera."
        },
        "bulkUpload": {
            "vendor": {
                "msgBulkUploadInstructions": "¿Tiene varios proveedores que desea agregar o modificar? ",
                "lblAddMultipleVendorsHeader": "Agregar múltiples proveedores nuevos",
                "lblAddMultipleVendorsDetail": "Descargue esta hoja de cálculo que contiene únicamente información del encabezado y complete la información de su nuevo proveedor, un proveedor por fila, antes de guardarla en formato .CSV en su computadora.",
                "btnBlankVendorsFile": "Archivo de proveedor en blanco",
                "lblEditMultipleVendorsHeader": "Editar o eliminar múltiples proveedores",
                "lblEditMultipleVendorsDetail": "Descargue esta hoja de cálculo que contiene la información de su proveedor actual y modifíquela según sea necesario antes de guardarla en formato .CSV en su computadora.",
                "btnCurrentVendors": "Proveedores actuales",
                "lblEditMultipleVendorsNotes": "NOTA: Para eliminar proveedores, cambie su estado a Eliminar. ",
                "fileName": "BulkVendorUpload.csv",
                "msgVendorExportSuccessful": "La exportación de proveedores se completó correctamente.",
                "errorVendorExportFailed": "No se puede exportar un archivo de proveedores.",
                "msgUploadVendorsSuccess": "Carga masiva para proveedores exitosa.",
                "lblNoFileSelected": "No se ha especificado ningún archivo."
            },
            "vendorContact": {
                "msgBulkUploadInstructions": "¿Tiene varios contactos que desea agregar o modificar? ",
                "lblAddMultipleContactsHeader": "Agregar múltiples contactos nuevos",
                "lblAddMultipleContactsDetail": "Descargue esta hoja de cálculo que contiene solo información del encabezado y complete la información de su nuevo contacto, un contacto por fila, antes de guardarla en formato .CSV en su computadora.",
                "btnBlankContactsFile": "Archivo de contacto en blanco",
                "lblEditMultipleContactsHeader": "Editar o eliminar múltiples contactos",
                "lblEditMultipleContactsDetail": "Descargue esta hoja de cálculo que contiene su información de contacto actual y modifíquela según sea necesario antes de guardarla en formato .CSV en su computadora.",
                "btnCurrentContacts": "Contactos actuales",
                "lblEditMultipleContactsNotes": "NOTA: Para eliminar contactos, cambie su estado a Eliminar. ",
                "fileName": "BulkVendorContactUpload.csv",
                "msgContactExportSuccessful": "La exportación de contactos se completó correctamente.",
                "errorContactExportFailed": "No se puede exportar un archivo de contactos.",
                "msgUploadContactsSuccess": "Carga masiva de contactos exitosa.",
                "lblNoFileSelected": "No se ha especificado ningún archivo."
            },
            "agency": {
                "msgBulkUploadInstructions": "¿Tiene varias agencias que desea agregar o modificar? ",
                "lblAddMultipleHeader": "Agregar varias agencias nuevas",
                "lblAddMultipleDetail": "Descargue esta hoja de cálculo que contiene únicamente la información del encabezado y complete la información de su nueva agencia, una agencia por fila, antes de guardarla en formato .CSV en su computadora.",
                "btnBlankFile": "Archivo de agencia en blanco",
                "lblEditMultipleHeader": "Editar o eliminar varias agencias",
                "lblEditMultipleDetail": "Descargue esta hoja de cálculo que contiene la información actual de su agencia y modifíquela según sea necesario antes de guardarla en formato .CSV en su computadora.",
                "btnCurrent": "Agencias actuales",
                "lblEditMultipleNotes": "NOTA: Para eliminar agencias, cambie su estado a Eliminar. ",
                "fileName": "BulkAgencyUpload.csv",
                "msgExportSuccessful": "La exportación de agencias se completó con éxito.",
                "errorExportFailed": "No se puede exportar un archivo de agencias.",
                "msgUploadSuccess": "Carga masiva para agencias exitosa.",
                "lblNoFileSelected": "No se ha especificado ningún archivo."
            },
            "agencyContact": {
                "msgBulkUploadInstructions": "¿Tiene varios contactos que desea agregar o modificar? ",
                "lblAddMultipleHeader": "Agregar múltiples contactos nuevos",
                "lblAddMultipleDetail": "Descargue esta hoja de cálculo que contiene solo información del encabezado y complete la información de su nuevo contacto, un contacto por fila, antes de guardarla en formato .CSV en su computadora.",
                "btnBlankFile": "Archivo de contacto en blanco",
                "lblEditMultipleHeader": "Editar o eliminar múltiples contactos",
                "lblEditMultipleDetail": "Descargue esta hoja de cálculo que contiene su información de contacto actual y modifíquela según sea necesario antes de guardarla en formato .CSV en su computadora.",
                "btnCurrent": "Contactos actuales",
                "lblEditMultipleNotes": "NOTA: Para eliminar contactos, cambie su estado a Eliminar. ",
                "fileName": "BulkVendorContactUpload.csv",
                "msgExportSuccessful": "La exportación de contactos se completó correctamente.",
                "errorExportFailed": "No se puede exportar un archivo de contactos.",
                "msgUploadSuccess": "Carga masiva de contactos exitosa.",
                "lblNoFileSelected": "No se ha especificado ningún archivo."
            }
        },
        "departmentAlternate": {
            "MemberModal": {
                "headerLabel": "Seleccione un miembro suplente para {{name}}",
                "searchUserLabel": "Buscar una alternativa",
                "currentUserLabel": "Suplente actual",
                "resultLabel": "Suplentes"
            },
            "alternateSaveSuccessfully": "Alternativo guardado",
            "alternateSaveUnsuccessfully": "No se pudo guardar la alternativa",
            "alternateRequired": "Se requiere el suplente",
            "alternate": "Alterno",
            "noAlternate": "Sin alternativa"
        },
        "technology": {
            "manageTechnology": "[[Technologies]]",
            "technologyVendors": "Proveedor",
            "technologyDependencies": "[[Technology]] Dependencias",
            "technologyApplicationDetails": "[[Technology]] Detalles",
            "technologyTasks": "[[Technology]] Tareas",
            "technologyRecoveryTime": "[[Technology]] Tiempo de recuperación",
            "configureTechnology": "Configurar [[Technology]]",
            "technologyBuildManage": "Construya y administre sus [[technologies]]",
            "technologyDescription": "La creación y gestión de [[technologies]] para un plan de recuperación de desastres de TI implica un enfoque sistemático para garantizar que las [[technologies]] críticas puedan restaurarse de manera rápida y eficiente en caso de un desastre.",
            "technologySecondaryDescription": "Identifique sus [[technologies]] comerciales críticas en función de su importancia para las funciones organizativas.",
            "searchPlaceHolder": "Busca aquí",
            "removeTechnology": "Eliminar [[Technology]]",
            "owner": "Dueño",
            "addTechnology": "Agregar [[Technology]]",
            "filterByTier": "Filtrar por nivel",
            "filterByType": "Filtrar por tipo",
            "manageTechnologies": "Gestionar [[Technologies]]",
            "MemberSelectionModal": {
              "headerLabel": "Asignar propietario",
              "searchUserLabel": "Seleccionar miembro(s)",
              "currentUserLabel": "Miembro seleccionado",
              "customCurrentUserLabel": "Empleados actualmente seleccionados",
              "resultLabel": "Miembros",
              "assignButton": "Agregar"
            },
            "removeConfirmation1": "¿Está seguro de que desea eliminar esta [[technology]] de su planificación de TI/DR? ",
            "removeConfirmation2": "¿Está seguro de que desea eliminar estas [[technologies]] de su planificación de TI/DR? ",
            "unAssignConfirmation": "La asignación alternativa se borrará debido a que no hay una asignación principal. ",
            "unAssignConfirmationHeader": "Se eliminará la asignación alternativa.",
            "trackDescription": "Realice un seguimiento y complete sus [[technologies]] aquí.",
            "noTechnologies": "Sin [[Technologies]]",
            "name": "Nombre",
            "status": "Estado",
            "dueDate": "Fecha de vencimiento",
            "technologySME": "[[Technology]] Propietario",
            "open": "Abierto",
            "edit": "Editar",
            "selectTechnologies": "Seleccione [[Technologies]]",
            "vendors": {
              "title": "Proveedor",
              "description": "Seleccione los proveedores que respaldan o brindan los servicios que requiere esta [[technology]].",
              "secondaryDesc": "Identifique a los proveedores de apoyo.",
              "addVendor": "Agregar proveedor",
              "confirmDeleteHeader": "Eliminar {{ name }}",
              "confirmDeleteVerbiage": "¿Está seguro de que desea eliminar a este proveedor de esta [[technology]]?",
              "vendorDetails": "Detalles del proveedor",
              "lblAdditionalInstructions": "Acuerdos SLA",
              "updateInstructions": "Actualizar acuerdos SLA",
              "instructionsModal": {
                "title": "Detalles del SLA",
                "instructionsPLaceHolder": "Ingrese los detalles del SLA"
              },
              "successOnSave": "El proveedor se salvó exitosamente",
              "failedToSave": "El proveedor se salvó sin éxito.",
              "successOnDelete": "El proveedor fue eliminado exitosamente",
              "failedToDelete": "El proveedor fue eliminado sin éxito.",
              "modalHeader": "Seleccionar proveedor"
            },
            "taskSets": {
              "title": "Conjuntos de tareas",
              "description": "Recuperar una [[technology]] en un plan de recuperación ante desastres de TI implica una serie de pasos bien definidos para minimizar el tiempo de inactividad y \n ",
              "secondaryDesc": "Personalice estos pasos en función de las necesidades y características específicas de esta [[technology]].",
              "tasks": "Tareas",
              "addTask": "Agregar tarea",
              "task": "Tarea",
              "removeTask": "Eliminar tarea",
              "removeTaskDescription": "¿Estás seguro de que deseas eliminar esta tarea?",
              "removeTaskSet": "Eliminar conjunto de tareas",
              "removeTaskSetDescription": "¿Está seguro de que desea eliminar este conjunto de tareas?",
              "successOnSaveTaskSet": "El conjunto de tareas se guardó correctamente",
              "failedToSaveTaskSet": "El conjunto de tareas no se guardó correctamente",
              "successOnDeleteTaskSet": "El conjunto de tareas se eliminó correctamente",
              "failedToDeleteTaskSet": "El conjunto de tareas se eliminó sin éxito",
              "successOnSaveTask": "La tarea se guardó correctamente.",
              "failedToSaveTask": "La tarea se guardó sin éxito.",
              "successOnDeleteTask": "La tarea se eliminó correctamente.",
              "failedToDeleteTask": "La tarea se eliminó sin éxito.",
              "newTaskSet": "Nuevo conjunto de tareas",
              "teams": "equipos",
              "taskSetRecoveryTime": "Tiempo de recuperación del conjunto de tareas",
              "taskSet": "Conjunto de tareas",
              "assignTime": "Asignar tiempo",
              "assignTimeTitle": "Asignar tiempo",
              "assignMember": "Asignar miembro",
              "assignTeam": "Asignar equipo",
              "memberSaveSuccessfully": "El miembro fue guardado exitosamente.",
              "memberSaveUnsuccessfully": "El miembro se salvó sin éxito.",
              "memberSaveSuccessfully2": "Los miembros fueron salvados exitosamente.",
              "memberSaveUnsuccessfully2": "Los miembros fueron salvados sin éxito.",
              "teamSaveSuccessfully": "El equipo se salvó con éxito.",
              "teamSaveUnsuccessfully": "El equipo se salvó sin éxito.",
              "teamSaveSuccessfully2": "Los equipos se salvaron con éxito.",
              "teamSaveUnsuccessfully2": "Los equipos se salvaron sin éxito.",
              "assigneeModal": {
                "headerLabel": "Seleccione un miembro",
                "searchUserLabel": "Buscar un miembro",
                "currentUserLabel": "Miembros actualmente asignados",
                "resultLabel": "Miembros"
              }
            },
            "upstreamDependencies": {
              "pageDescription": "Comprender las dependencias ascendentes permite a las organizaciones evaluar riesgos, priorizar recursos y desarrollar planes de contingencia para mitigar el impacto de las interrupciones en operaciones críticas durante escenarios de recuperación ante desastres.",
              "sub-text": "Identificar todas las [[technologies]] de las que depende esta [[technology]].",
              "addDependency": "Nueva dependencia",
              "successOnSave": "La dependencia se salvó exitosamente.",
              "failedToSave": "La dependencia se salvó sin éxito.",
              "successOnSavePlural": "Las dependencias se guardaron exitosamente.",
              "failedToSavePlural": "Las dependencias se guardaron sin éxito.",
              "successOnDelete": "La dependencia se eliminó exitosamente.",
              "failedToDelete": "La dependencia se eliminó sin éxito.",
              "removeDependencyConfirmationModal": {
                "header": "Eliminar {{dependencyName}}",
                "body": "¿Está seguro de que desea eliminar esta dependencia de esta [[technology]]?"
              }
            },
            "recoveryTime": {
              "title": "Tiempo estimado de recuperación",
              "pageDescription": "Elegir el tiempo de recuperación estimado es una decisión crucial para las organizaciones que buscan garantizar la continuidad del negocio ante posibles desastres o interrupciones. ",
              "sub-text": "Seleccione el tiempo de recuperación estimado para su [[technology]].",
              "earliestRTO": "Primer RTO para esta [[technology]]",
              "estimatedTime": "Su tiempo de recuperación estimado según sus tareas",
              "successOnSave": "El tiempo de recuperación se guardó correctamente",
              "failedToSave": "El tiempo de recuperación no se guardó correctamente"
            },
            "myTechnologies": {
              "header": "Mis [[Technologies]]",
              "subHeader": "Seleccione entre sus [[technologies]] a continuación para comenzar.",
              "description": "Una vez que seleccione una [[technology]], comenzará el proceso de creación de su Runbook de TI/DR para esa [[technology]]. "
            },
            "backToMyTechnologies": "Volver a mis [[Technologies]]",
            "setDueDate": {
              "header": "Fecha de vencimiento",
              "body": "Algunas [[technologies]] ya tienen una fecha de vencimiento, ¿quieres establecer esta fecha de vencimiento? <b>{{dueDate}}</b> a todas las [[technologies]]?",
              "allTechnologies": "Sí",
              "newTechnologies": "No",
              "msgConfirmBody": "¿Está seguro de que desea establecer esta nueva fecha de vencimiento? <b>{{dueDate}}</b> a todas las [[technologies]]?"
            },
            "sendEmail": {
              "dueDateRequired": "Fecha de vencimiento requerida para todas las [[technologies]]"
            },
            "detailsAndAttachments": {
              "label": "[[Technology]] Detalles y Anexos",
              "additionalDetails": "Detalles adicionales",
              "enterAdditionalDetails": "Ingrese detalles adicionales",
              "additionalDetailsSavedSuccessfully": "Detalles adicionales guardados exitosamente",
              "customQuestionSavedSuccessfully": "Preguntas personalizadas guardadas correctamente"
            },
            "technologyAnalytics": {
              "label": "[[Technology]] Análisis",
              "assignedOwner": "Propietario asignado",
              "progress": "Progreso",
              "rpo": "RPO",
              "earliestRto": "RTO más temprano",
              "technologyType": "[[Technology]] Tipo",
              "vendors": "Vendedores",
              "assignedDisasterRecoveryTeams": "Equipos de recuperación asignados",
              "processes": "[[Procesos]]"
            }
          },
        "confirmationCodeModal": {
            "header": "¿Está seguro de que desea comenzar a editar los siguientes datos del sistema?",
            "body": "Cualquier cambio afectará a TODOS los portales y clientes. ",
            "btn": "Entiendo",
            "codeError": "Confirmación del código sin éxito"
        },
        "teamSelectionModal": {
            "header": "Asignar equipo",
            "searchLabel": "Seleccionar equipo(s)",
            "searchPlaceholder": "Busca aquí",
            "selectedTeams": "Equipos actualmente seleccionados",
            "teams": "equipos"
        },
        "processDependenciesInfoSidebar": {
            "dependencies": "Dependencias",
            "introDesc": "Es esencial identificar las dependencias, ya que resaltan procesos administrados por otros departamentos o grupos que son cruciales para la continuidad de sus operaciones. ",
            "examples": "Ejemplos",
            "payroll": "Nómina (RRHH)",
            "payrollInfo": "Dependencia: Financiamiento (Finanzas)",
            "proposalDevelop": "Desarrollo de Propuestas (Ventas)",
            "proposalDevelopInfo": "Dependencia: Embalaje (Marketing)",
            "employee": "Incorporación de empleados (RRHH)",
            "employeeInfo": "Dependencia: Operaciones de Seguridad (TI)",
            "conclusion": "Al reconocer estas dependencias, puede prepararse y coordinarse mejor con los departamentos relevantes para garantizar una continuidad perfecta de sus procesos críticos."
        },
        "criticalDateInfoSidebar": {
            "importantDates": "Fechas importantes para la continuidad",
            "introDesc": "Al planificar la continuidad, es fundamental identificar y agregar fechas críticas a su proceso. ",
            "timeReporting": "Informes de tiempo",
            "timeReportingInfo": "Frecuencia: Semanal (Todos los miércoles)",
            "financialReporting": "Informes financieros (presentación trimestral)",
            "financialReportingInfo": "Frecuencia: Trimestral (dentro de los 4 días hábiles posteriores al final del trimestre)",
            "backup": "Pagos de retención de respaldo al IRS",
            "backupInfo": "Frecuencia: Quincenal (Todos los viernes)",
            "irsTax": "Informes de impuestos del IRS para proveedores (1099-MISC) y retención de respaldo (formulario 945)",
            "irsTaxInfo": "Frecuencia: Anual (31 de enero, dentro de los 3 días hábiles)",
            "conclusion": "Garantizar que estas fechas críticas sean parte de su planificación de continuidad ayudará a mantener operaciones fluidas y cumplimiento durante las interrupciones."
        },
        "continuityStrategiesInfoSidebar": {
            "introDesc": "Una estrategia de continuidad describe los métodos mediante los cuales se espera que un proceso continúe durante un incidente. ",
            "work": "Trabaja desde cualquier lugar",
            "workInfo": "Continuar trabajando desde casa o un espacio remoto similar.",
            "temporary": "Espacio de trabajo temporal",
            "temporaryInfo": "Reubicar operaciones enteras a una ubicación temporal.",
            "move": "Mover el trabajo",
            "moveInfo": "Transfiera el trabajo a un proveedor externo u otro equipo interno.",
            "suspend": "Suspender",
            "suspendInfo": "Suspender temporalmente el trabajo.",
            "conclusion": "Implementar y validar estas estrategias de continuidad con anticipación ayudará a garantizar que su empresa pueda mantener las operaciones durante interrupciones inesperadas."
        }
    }
}
