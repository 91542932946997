{
    "businessContinuity": {
        "planner": "[[Planner]]",
        "bcPlanner": {
            "businessContinuity": "Continuité des Activités",
            "continuityOfOperationPlanning": "Continuité des opérations",
            "emergencyOperations": "Opérations d'urgence",
            "disasterRecovery": "Reprise après sinistre ",
            "businessContinuityNoPlanner": "Continuité de l'activité",
            "continuityOfOperationPlanningNoPlanner": "Continuité des opérations",
            "emergencyOperationsNoPlanner": "Opérations d'urgence",
            "introductionHeader": "Construisez votre plan. Commençons.",
            "introductionSecondaryDescription": "Vous avez été sélectionné comme administrateur de votre organisation.",
            "introductionDescription": "Vous serez guidé dans la mise en place de votre plan de continuité [[Departments]]. Vous trouverez ci-dessous les étapes pour compléter votre plan. Vous recevrez des données préchargées pour construire votre plan.",
            "responseTeamsDescription": "Les organisations doivent mettre en place des équipes d'intervention de continuité afin de gérer les urgences et de continuer à fonctionner pendant les perturbations imprévues. Ces équipes engagées, dotées de compétences et de ressources spécialisées, veillent à ce que les décisions soient prises rapidement et que les actions soient organisées pour maintenir la continuité des activités et réduire les pertes.",
            "buildAndManageDescription": "Identifiez les [[Departments]] de votre organisation pour allouer les ressources de manière appropriée et établir une communication claire, garantissant la résilience et minimisant les perturbations potentielles lors d'un incident.",
            "sendEmailDescription": "Avertissez votre équipe en envoyant un e-mail de présentation.",
            "trackDepartmentsDescription": "Une fois que vous avez construit et assigné vos [[Departments]], suivez leur progression.",
            "departmentSecondaryDescription": "Vous avez été sélectionné comme chef d'équipe pour le [[Department]] suivant",
            "departmentSecondaryDescriptionSme": "Vous avez été sélectionné comme expert en la matière pour le [[Department]] suivant",
            "departmentIntroductionDescription": "Vous serez guidé dans la mise en place de votre plan de continuité des activités [[Department]]. Vous trouverez ci-dessous les étapes pour compléter votre plan. Vous recevrez des données préchargées pour construire votre plan.",
            "departmentDetails": "[[Department]] Détails",
            "oosDescription": "Prévoir une prise en charge ordonnée et prédéfinie des responsabilités lors d'une urgence ou d'un événement. Ils devraient être développés pour soutenir directement les opérations quotidiennes.",
            "doaDescription": " Les délégations de pouvoir permettent d'assurer la continuité des [[processes]] de l'entité et d'assurer une réponse rapide à toute situation d'urgence nécessitant la mise en œuvre du plan.",
            "departmentResponseTeamsDescription": "L'entité a pré-identifié des équipes de continuité des opérations liées à la réalisation de ce plan. Les équipes suivantes ont été désignées et font partie intégrante de ce plan.",
            "buildManageDescription": "Les [[processes]] organisationnels déterminés comme étant des activités critiques sont utilisés pour identifier les tâches et les ressources de soutien qui doivent être incluses dans le processus de planification de la continuité de l'organisation.",
            "processesTrackDescription": "Une fois que vous avez construit et assigné vos [[processes]], suivez leur progression.",
            "processesTrackDescriptionSme": "Ici, vous pouvez suivre",
            "departmentInformation": "[[Department]] Informations",
            "departmentDescriptionLabel": "[[Department]] Descriptif",
            "departmentAlternateLabel": "[[Team Lead]] Suppléant",
            "departmentDescription": "La description du [[Department]] définit ce que fait votre [[Department]]. Il s'agit d'un aperçu concis d'une ligne expliquant pourquoi l'organisation existe et pourquoi l'identité de votre coopérative est importante.",
            "departmentTeamLeadAlternateDescription": "Désigner un [[Team Lead]] suppléant pour ce département afin d'assurer la continuité opérationnelle en cas d'incident.",
            "myDepartments": "Mes [[Departments]]",
            "backToMyDepartments": "Retour à Mes [[Departments]]",
            "myDepartmentsSecondaryDescription": "Sélectionnez parmi vos [[departments]] ci-dessous pour commencer.",
            "myDepartmentsDescription": "Une fois que vous aurez sélectionné un [[department]], vous commencerez à élaborer votre plan pour ce [[department]]. Vous pourrez revenir sur cette rubrique à tout moment pour basculer vers un autre département si besoin.",
            "departmentDescriptionStepSecondary": "Saisissez la description de votre [[department]].",
            "departmentDescriptionStepLead": "Vous pouvez choisir de saisir une description concise sur une seule ligne de votre [[department]] ou de votre secteur d'activité.",
            "departmentAlternateStepSecondary": "Définissez ici votre remplaçant.",
            "departmentAlternateStepLead": "Sélectionnez une personne comme chef d'équipe suppléant qui sera disponible et prête à assumer les responsabilités de chef d'équipe en votre absence. Un suppléant n'est pas obligé de continuer et peut être défini ultérieurement.",
            "selectPlanType": "Sélectionnez un forfait",
            "eopIntroductionSecondaryDescription": "Vous avez été sélectionné comme responsable administratif ou responsable des annexes de votre organisation.",
            "eopIntroductionDescription": "Vous serez guidé dans la mise en place de votre plan d’opérations d’urgence. ",
            "eopSupersessionDescription": "Documentez le remplacement de ce document.",
            "eopGeneralQuestionsDescription": "Cette section abordera diverses questions générales liées à la planification des opérations d'urgence (EOP).",
            "eopResponseTeamsDescription": "Les équipes d'intervention sont essentielles aux opérations ou à l'organisation pour la mise en œuvre de ce plan. ",
            "mobileBrowserWarning": "Pour le moment, Preparis Planner ne prend pas en charge les navigateurs mobiles. Veuillez passer à un navigateur de bureau pour continuer.",
            "annexBuildMangeDescription": "Les annexes organisationnelles pour les activités critiques sont utilisées pour identifier les agences principales et de soutien ainsi que les tâches dont elles sont responsables.",
            "annexesTrackDescription": "Réponse Une fois que vous avez construit et attribué vos annexes, suivez leur progression.",
            "disasterRecoveryNoPlanner": "reprise après sinistre",
            "itdrIntroductionSecondaryDescription": "Vous avez été sélectionné comme administrateur informatique de votre organisation.",
            "itdrIntroductionDescription": "Vous serez guidé dans la mise en place de votre plan de reprise après sinistre. Vous trouverez ci-dessous les étapes à suivre pour compléter votre plan.",
            "crisisManagementTeam": "Équipes de gestion de crise",
            "itdrCrisisManagementTeamDescription": "Ces équipes jouent un rôle crucial dans la planification de la continuité en garantissant qu'une organisation peut réagir efficacement et se remettre de diverses crises ou perturbations. ",
            "incidentResponseTeams": "Équipes de réponse aux incidents",
            "itdrIncidentResponseTeamsDescription": "Constituez vos équipes de réponse aux incidents qui seront nécessaires pour mettre en œuvre votre plan de reprise après sinistre et gérer la réponse.",
            "itdrBuildAndManageDescription": "Identifiez les [[technologies]] clés utilisées au sein de votre organisation pour créer efficacement des runbooks de reprise après sinistre.",
            "itdrsendEmailDescription": "Fixez une date d'échéance, puis informez votre équipe en envoyant un e-mail d'introduction.",
            "trackTechnologiesDescription": "Une fois que vous avez développé et attribué vos [[technologies]], suivez leur progression.",
            "itdrTechnologyOwnerSecondaryDescription": "Vous avez été sélectionné comme PME [[technology]] pour votre organisation.",
            "itdrTechnologyOwnerDescription": "Vous serez guidé dans la mise en place de votre [[technology]]. ",
            "itdrVendorsDescription": "Sélectionnez les fournisseurs qui prennent en charge ou fournissent les services requis par cette [[technology]].",
            "itdrUpstreamDependencies": "Dépendances en amont",
            "itdrUpstreamDependenciesDescription": "Identifiez et documentez les dépendances en amont qui maintiennent cette [[technology]].",
            "itdrApplicationDetailsAndAttachments": "Détails [[technology]] et pièces jointes",
            "itdrApplicationDetailsAndAttachmentsDescription": "Documentez les détails clés et joignez les pièces justificatives pour cette [[technology]].",
            "itdrTaskSets": "Ensembles de tâches",
            "itdrTaskSetsDescription": "Définissez des ensembles de tâches avec des procédures détaillées, étape par étape, pour la récupération ou la restauration de la [[technology]].",
            "itdrEstimatedRecoveryTime": "Temps de récupération estimé",
            "itdrEstimatedRecoveryTimeDescription": "Examinez et ajustez le temps de récupération estimé pour la restauration [[technology]], en fournissant un calendrier réaliste basé sur des évaluations calculées."
        },
        "bcDashboard": "Tableau de bord",
        "bcAdmin": "Administrateur",
        "admin": {
            "confirmReset": "Êtes-vous sûr(e) de vouloir réinitialiser vos données? Elles ne seront pas récupérables.",
            "title": "Administration",
            "departmentPlanTemplateFileName": "Modèleplan[[department]].docx",
            "downloadTemplateFailed": "Impossible de télécharger le modèle de plan de [[department]].",
            "saveDepartmentPlanTemplateFailed": "Impossible de sauvegarder le modèle de plan de [[department]]",
            "saveDepartmentPlanTemplateSuccess": "Le modèle de plan de [[department]] a été sauvegardé avec succès.",
            "freshStartHeader": "Nouveau départ",
            "manageIndustries": "Gérer les secteurs",
            "manageIndustriesDescription": "Créer de nouveaux secteurs, gérer des modèles de [[department]] et de [[processes]] et mettre à jour les impacts des [[processes]], les OTR et les stratégies. (Pour les administrateurs Preparis uniquement.)",
            "freshStartDescription": "Vous souhaitez prendre un nouveau départ avec votre planning ? En appuyant sur le bouton Réinitialiser, vous effacerez toutes les données de planification. Sachez que cette action effacera définitivement vos données, les rendant irrécupérables. Soyez prudent, car cette décision est irréversible.",
            "resetSuccess": "Réinitialisation des données de continuité des activités réussie",
            "resetFailed": "Échec de la réinitialisation des données de continuité des activités",
            "browseIndustries": "Parcourir les secteurs",
            "manageGenericIndustry": "Gérer le secteur générique",
            "genericIndustry": "Secteur générique",
            "showMTD": "Afficher le temps d'arrêt maximal tolérable",
            "showMTDDescription": "El tiempo de inactividad máximo tolerable representa la cantidad total de tiempo que la empresa está dispuesta a aceptar por una interrupción del [[process]] empresarial.",
            "template": {
                "industries": "Secteurs",
                "industry": "Secteur",
                "newIndustryFromGeneric": "Nouveau secteur générique",
                "newIndustryFromScratch": "Nouveau secteur à partir de zéro",
                "newIndustry": "Nouveau secteur",
                "renameIndustry": "Renommer le secteur",
                "copyGenericIndustry": "Copier le secteur générique",
                "industryName": "Nom du secteur",
                "msgSuccessSaveIndustry": "{{ industryName }} sauvegardé avec succès",
                "msgFailSaveIndustry": "Erreur lors de la sauvegarde du secteur",
                "duplicateIndustryName": "Ce nom de secteur est déjà utilisé",
                "newIndustryFromCsv": "Importer le secteur",
                "errorGettingDepartmentsMsg": "Erreur lors de la récupération des [[departments]]",
                "errorGettingProcessesMsg": "Erreur lors de la récupération des [[processes]]",
                "errorGettingProcessDetails": "Erreur lors de la récupération des détails du [[processes]]",
                "errorGettingIndustriesMsg": "Erreur lors de la récupération des secteurs",
                "errorGettingContinuityStrategy": "Erreur lors de la récupération de la stratégie de continuité",
                "newDepartment": "Nouveau [[department]]",
                "department": "[[Department]]",
                "newProcess": "Nouveau [[processes]]",
                "process": "[[Processes]]",
                "departments": "[[Departments]]",
                "processes": "[[Processes]]",
                "continuityStrategy": "Stratégie de continuité",
                "details": "Détails",
                "errorInvalidIndustryFileType": "Type de fichier invalide",
                "noeFileSelectedMsg": "Aucun fichier sélectionné",
                "uploadIndustrySuccess": "Secteur importé avec succès",
                "uploadIndustryDescription": "Télécharger un fichier de configuration du secteur",
                "uploadIndustry": "Importer le secteur",
                "dropFileInstruction": "Déposer un fichier de configuration ici",
                "loadingIndustryFile": "Chargement du fichier",
                "selectedFileLabel": "Fichier sélectionné",
                "noFileSelectedLabel": "Aucun fichier sélectionné",
                "noFileSelectedMsg": "Veuillez sélectionner un fichier"
            },
            "revisionPeriodHeader": {
                "continuityOfOperationPlanning": "Cycle de Révision COOP",
                "emergencyOperations": "Cycle de Révision EOP",
                "businessContinuity": "Cycle de révision",
                "disasterRecovery": "Cycle de révision"
            },
            "revisionPeriodDescription": "Le démarrage d’un nouveau cycle de révision conservera toutes les données existantes mais réinitialisera la progression de votre planificateur sur Non commencé. Vous ne pouvez créer qu’un seul cycle futur.",
            "btnStartNewRevisionPeriod": "Nouveau cycle de révision",
            "lblRevisionPeriodName": "Nom",
            "lblRevisionPeriodStartDate": "Date de début",
            "lblRevisionPeriodEndDate": "Date de fin",
            "msgErrorLoadingCurrentPortalRevision": "Erreur lors du chargement du cycle de révision actuel",
            "spinnerMsgStartingNewRevisionPeriod": "Sauvegarde du cycle de révision de la continuité des activités...",
            "currentPeriodText": "Cycle actuel : « {{ periodName }} » a commencé {{ periodStartDate }}",
            "nextPeriodText": "Cycle suivant : « {{ periodName }} » commence {{ periodStartDate }}",
            "msgSuccessCreatingNewRevisionPeriod": "Création d’un nouveau cycle de révision",
            "msgErrorCreatingNewRevisionPeriod": "Échec de la création d’un nouveau cycle de révision",
            "newRevisionPeriodModal": {
                "title": "Nouveau cycle de révision",
                "defaultName": "{{ date }} Cycle de révision",
                "msgInvalidDateRange": "La date de début doit être antérieure à la date de fin"
            },
            "bcImpactSeverityDescriptions": {
                "editImpactSeverityDescriptions": "Modifier les descriptions de gravité d'impact",
                "impactSeverityDescriptionsSettingUpdated": "[[Setting]] des descriptions de gravité d'impact mis à jour",
                "impactSeverityDescriptions": "Descriptions de gravité d'impact"
            },
            "incidentCommunications": {
                "menu": "Communications d'incident",
                "title": "Communications d'incident",
                "description": "Veuillez configurer ces boutons en ajoutant un groupe à chaque bouton. Ces boutons seront ajoutés à chaque incident et peuvent être sélectionnés pour envoyer à chaque individu une conférence téléphonique, un e-mail ou un SMS.",
                "msgLabel": "Configuration des communications d'incident",
                "msgErrorLoadingSetting": "Erreur lors du chargement de la configuration des communications d'incident",
                "msgErrorDuplicateGroups": "Des groupes en double sont sélectionnés",
                "confirmRemoveGroup": {
                    "header": "Supprimer le groupe de communication d'incident",
                    "body": "Voulez-vous vraiment supprimer le groupe de communication d'incident?",
                    "text": "Supprimer"
                },
                "selectPlaceholder": "Sélectionnez un groupe",
                "selectedTeamMembers": "Membres de l'équipe sélectionnés",
                "noGroupSelected": "Aucun groupe sélectionn"
            },
            "planWatermark": "Planifier le filigrane",
            "bcPlanWatermarkIsOnSettingUpdated": "Le filigrane du plan BC est activé mis à jour",
            "visualTimelineOfRto": "Chronologie visuelle du RTO",
            "rtoDescription": "Les objectifs de temps de récupération définissent la durée la plus longue pendant laquelle une ressource système peut rester indisponible avant qu'il n'y ait un impact inacceptable sur les autres ressources système et les [[processes]] de l'entreprise.",
            "rtoHeader": "Définir les intervalles RTO",
            "rtoWithMtdHeader": "Définir les intervalles RTO et MTD",
            "saveRtoErrorMessage": "Vous avez un RTO invalide.",
            "saveRto": "Enregistrer le RTO",
            "saveRtoDescription": "En modifiant les RTO des systèmes, toutes les données d'impact [[process]] enregistrées seront perdues, ce qui désactivera également la présélection des impacts conformément aux meilleures pratiques. ",
            "saveImpactCategory": "Enregistrer la catégorie d'impact",
            "saveImpactCategoryDescription": "En modifiant les catégories d'impact du système, toutes les données d'impact [[process]] enregistrées peuvent être perdues. ",
            "cancelHeader": "Modifications non enregistrées",
            "cancelDescription": "Vous avez des changements non enregistrés. ",
            "resetRto": "Réinitialiser le RTO",
            "resetRtoDescription": "Cela réinitialisera les RTO du système à leurs valeurs par défaut, ce qui permettra de présélectionner les valeurs d'impact conformément aux meilleures pratiques. ",
            "addCategory": "ajouter une catégorie",
            "editImpactCategories": " Modifier les catégories d'impact",
            "resetImpactCategoriesHeader": "Réinitialiser les catégories d'impact",
            "resetImpactCategoriesMsg": "Cela réinitialisera les catégories d'impact du système à leurs valeurs par défaut et toutes les catégories personnalisées seront supprimées.  ",
            "resetImpactSeverity": "Réinitialiser la gravité de l'impact",
            "resetImpactSeverityDescription": "Cela réinitialisera les noms de niveau de gravité à leurs valeurs par défaut.  ",
            "confirmDisableBcScenarioBasedStrategy": {
                "header": "Désactiver la stratégie basée sur un scénario",
                "body": "Voulez-vous vraiment désactiver les stratégies basées sur un scénario BC?"
            },
            "confirmDisableBcLocationBasedStrategy": {
                "header": "Désactiver la stratégie basée sur la localisation",
                "body": "Toutes les stratégies associées à l'[[location]] seront supprimées."
            },
            "updateScenarioBasedSettingToastr": {
                "success": "Stratégie de C.-B. basée sur des scénarios mise à jour.",
                "failed": "Échec de la mise à jour de la stratégie BC basée sur un scénario"
            },
            "updateLocationBasedSettingToastr": {
                "success": "Stratégie de C.-B. basée sur la localisation mise à jour.",
                "failed": "Échec de la mise à jour de la stratégie BC basée sur la localisation"
            },
            "updatingStrategy": "Mettre à jour le cadre de la stratégie de continuité des activités",
            "errorInBCTemplateHeader": "Erreur(s) dans le modèle",
            "errorInBCTemplateBody": "Le modèle que vous tentez de télécharger contient des erreurs.<br />Veuillez corriger les erreurs dans le modèle et réessayer ou contacter le support technique.<br />L'erreur: <b>{{error}}</b>",
            "errorValidatingBCTemplateHeader": "Erreur lors de la validation du modèle",
            "errorValidatingBCTemplateBody": "Au moins un [[department]] doit être configuré avant de pouvoir télécharger un modèle personnalisé.",
            "strategiesHeader": "Stratégies",
            "scenarioBasedStrategies": "Stratégies de continuité basées sur des scénarios",
            "locationBasedStrategies": "Stratégies de continuité basées sur la localisation",
            "planTypeHeader": "Types de forfaits",
            "msgPlanTypeUpdatedSuccess": "Type de plan mis à jour",
            "msgPlanTypeUpdatedFail": "Échec de la mise à jour du type de plan",
            "msgPlanTypeRequired": "Au moins 1 type de plan est requis"
        },
        "welcome": {
            "welcomeBc": "Bienvenue, {{userProfileName}}",
            "welcomeManagementDescription": "Construisons votre équipe de continuité des activités. Nous avons mis une vidéo à votre disposition ci-dessous qui explique ce que vous êtes sur le point de faire.",
            "welcomeTeamLeadDescription": "En tant que chef d’équipe, vous avez été sélectionné(e) pour créer le plan de continuité des activités pour {{functionalAreaName}}. Cliquer sur la vidéo ci-dessous pour obtenir de brèves instructions sur la façon de démarrer avec le [[planner]] Preparis.",
            "errorRetrievingVideo": "Erreur lors de la récupération de la vidéo",
            "welcomeTeamMemberDescription": "En tant qu’expert en la matière, vous avez été sélectionné(e) pour créer le plan de continuité des activités pour {{businessProcessName}}.  Cliquer sur la vidéo ci-dessous pour obtenir de brèves instructions sur la façon de démarrer avec le [[planner]] Preparis."
        },
        "buildYourTeam": {
            "buildYourTeam": "Construire votre équipe",
            "buildYourTeamDescription": "Vous trouverez ci-dessous une liste des [[departments]] qui sont communs au sein de votre secteur. Cliquer sur un [[department]] pour affecter un chef d’équipe qui sera chargé de valider les [[processes]] dans ce domaine et ajouter tous les [[departments]] supplémentaires qui pourraient manquer.",
            "teamLeads": "Chefs d’équipe",
            "contactTeamLeadsAndAlternates": [
                "Contacter les [[Team Leads]]",
                "et suppléants"
            ],
            "teamLeadModal": {
                "headerLabel": "Sélectionner un chef d’équipe pour {{name}}",
                "searchUserLabel": "Rechercher un chef d'équipe",
                "currentUserLabel": "Chef d’équipe actuel"
            },
            "teamMembers": "Membres de l’équipe",
            "msgSuccessSaveFunctionalArea": "{{functionalAreaName}} sauvegardé avec succès",
            "msgFailSaveFunctionalArea": "Échec de la sauvegarde du [[department]].",
            "msgFailSaveFunctionalAreaPermission": "Votre connexion n’a pas l’autorisation de sauvegarder ce [[department]].",
            "msgFailSaveFunctionalAreaDuplicateName": "Un [[department]] existant porte déjà ce nom.",
            "removePortalFunctionalAreaSuccess": "{{functionalAreaName}} éliminé",
            "removePortalFunctionalAreaError": "Échec de l’élimination de {{functionalAreaName}}",
            "unassignPortalFunctionalAreaSuccess": "{{userName}} a annulé l’affectation de {{functionalAreaName}}",
            "unassignPortalFunctionalAreaError": "Échec de l’annulation de l’affectation de {{userName}} de {{functionalAreaName}}",
            "assignPortalFunctionalAreaSuccess": "{{userName}} affecté à {{functionalAreaName}}",
            "confirmRemoveFunctionalArea": {
                "header": "Éliminer le [[department]]",
                "body": "Êtes-vous sûr(e) de vouloir éliminer « {{ functionalAreaName }} »",
                "text": "Éliminer"
            },
            "removeDepartmentSuccess": "Le [[department]] a été éliminé avec succès",
            "removeDepartmentError": "Erreur lors de l’élimination du [[department]]",
            "confirmRemovePlan": {
                "header": "Éliminer le plan",
                "body": "Êtes-vous sûr(e) de vouloir éliminer « {{ planName }} »",
                "text": "Éliminer"
            },
            "newFunctionalArea": "Nouveau [[department]]",
            "renameFunctionalArea": "Renommer le [[department]]",
            "newPlan": "Nouveau plan",
            "msgLimitedResult": "Afficher les 100 premiers",
            "noPermission": "L’utilisateur n’a pas les autorisations appropriées",
            "titleFunctionalArea": "[[Department]]",
            "functionalAreaDescription": "Ci-dessous, vous pouvez modifier le nom du [[department]] qui reflète étroitement les fonctions commerciales au sein de votre société. Vous verrez également une liste des [[processes]] opérationnels associés à ce [[department]]. Cela vous fournira plus d’informations lors de la constitution de votre équipe",
            "newFunctionalAreaDescription": "Saisir le nom du nouveau [[department]] ci-dessous et ajouter les [[processes]] applicables dans la section des [[processes]]. Vous pouvez également choisir d’ignorer la section des [[processes]] et permettre au chef d’équipe responsable d’ajouter les [[processes]] nécessaires ultérieurement. Cliquer simplement sur créer pour sauvegarder votre saisie.",
            "lblFunctionalAreaName": "Nom du [[department]]",
            "functionalAreaName": "Saisir le nom du [[department]]",
            "lblProcesses": "[[Processes]]",
            "duplicateFunctionalAreaName": "Un [[department]] ayant le même nom existe déjà.",
            "validateProcess": "Valider les [[processes]] pour {{functionalAreaName}}",
            "validateProcessDescription": "Vous trouverez ci-dessous la liste de démarrage des [[processes]] dans votre région. Éliminer ceux qui ne sont pas de votre responsabilité ou de celle de votre équipe. Ajouter tous les autres [[processes]] manquants.",
            "titlePlan": "Plan",
            "planName": "Nom",
            "lblPlanName": "Saisir le nom du plan",
            "duplicatePlanName": "Un plan avec le même nom existe déjà.",
            "confirmUnassignTeamLead": {
                "header": "Annuler l’affectation d’un chef d’équipe",
                "body": "Êtes-vous sûr(e) de vouloir annuler l’affectation de « {{ userName }} » de ce [[department]]?",
                "text": "Annuler l’affectation"
            },
            "assignTeamLead": "Affecter un chef d’équipe",
            "unassignTeamLead": "Annuler l’affectation d’un chef d’équipe",
            "noTeamLeadAssigned": "Aucun chef d’équipe affecté",
            "setDueDate": {
                "header": "Date d’échéance",
                "body": "Certains [[departments]] ont déjà une date d’échéance, souhaitez-vous fixer cette date d’échéance <b>{{dueDate}}</b> pour tous les [[departments]]?",
                "allFunctionalAreas": "Oui",
                "newFunctionalAreas": "Non",
                "msgConfirmBody": "Êtes-vous sûr(e) de vouloir fixer cette nouvelle date d’échéance <b>{{dueDate}}</b> pour tous les [[departments]]?"
            },
            "msgDueDateRequired": "La date d’échéance est requise pour tous les [[departments]]",
            "introductoryEmailDisclaimer": "Le contexte changera par nom d’utilisateur et [[department]]",
            "welcomeManagementDescription": "Cliquer sur la vidéo ci-dessous pour obtenir de brèves instructions sur la façon de démarrer avec le [[planner]] Preparis.",
            "reviewDepartmentsTooltip": "S’assurer que tous les [[departments]] essentiels de votre organisation sont pris en compte. Pour ajouter un nouveau [[department]], cliquer sur la fiche Nouveau [[department]].",
            "assignTeamLeaderTooltip": "Pour chaque [[department]] affiché ci-dessous, sélectionner une personne responsable des [[processes]] du [[department]]. Pour trouver la personne appropriée, cliquer sur affecter un chef d’équipe et rechercher son nom dans la barre de recherche.",
            "isYourTeamCompleteTooltip": "Après avoir affecté un chef d’équipe à chaque [[department]], cliquer sur suivant pour passer à la dernière étape.",
            "selectDueDateTooltip": "Sélectionner une date d’échéance pour l’affectation. Cliquer sur une date d’échéance individuelle pour apporter des modifications.",
            "reviewEmailTooltip": "Vérifier le modèle de courriel et apporter les modifications nécessaires en cliquant sur Modifier. Les modèles de courriel applicables seront envoyés aux utilisateurs en fonction de leur statut (utilisateur existant ou nouvel utilisateur). Si des modifications sont apportées, veuillez noter qu’elles s’appliqueront à tous les destinataires des courriels.",
            "sendAndFinishTooltip": "Informons votre équipe en leur envoyant un courriel de présentation. Cliquer sur Envoyer et terminer pour terminer la création de votre équipe de continuité des activités.",
            "reviewDepartments": "1. Passer les [[departments]] en revue",
            "assignTeamLeader": "2. Affecter un chef d’équipe",
            "isYourTeamComplete": "3. Confirmer que votre équipe est complète",
            "selectDueDate": "1. Sélectionner une date d’échéance",
            "reviewEmail": "2. Vérifier le courriel",
            "sendAndFinish": "3. Envoyer et terminer",
            "emailDescription": "Les modifications apportées aux courriels ci-dessous ne seront pas sauvegardées pour une utilisation future. Si vous apportez des modifications, veuillez vous assurer que les deux modèles sont correctement mis à jour. Veuillez noter que la modification des informations entre crochets peut entraîner une distorsion des informations correspondantes.",
            "manageDepartment": "Gérer le [[Department]]",
            "bcmLicenseOverage": "Vous avez atteint la limite pour Business Continuity Manager. ",
            "teamLeadLicenseOverage": "Vous avez atteint la limite de [[Team Leads]]. ",
            "teamMemberLicenseOverage": "Vous avez atteint la limite pour Expert en la matière. ",
            "navigateToSettingDepartment": "Accédez au [[Department]] lié pour modifier le nom du [[planner]] [[Department]]",
            "buildManageSecondaryDescription": "Définissez ici vos départements.",
            "buildManageDescription": "Un \"[[department]]\" peut être une entité organisationnelle, un groupe d'entités ou un secteur de l'organisation. Chaque \"[[department]]\" défini doit se voir attribuer un chef d'équipe chargé de définir les processus du département, d'exécuter l'analyse d'impact et de définir le plan.",
            "introductionEmail": "Attribuez une date d'échéance et informez les chefs d'équipe",
            "introductionEmailSecondaryDescription": "Une date limite pour l'achèvement des activités d'élaboration du plan doit être définie. Vous pouvez également envoyer un e-mail (ou sauter) aux chefs d'équipe annonçant leur affectation.",
            "introductionEmailSecondaryDescriptionUpdated": "Une date limite pour l'achèvement des activités d'élaboration du plan doit être définie. Vous pouvez également envoyer un e-mail (ou sauter) aux chefs d'équipe annonçant leur affectation.",
            "trackDepartmentSecondaryDescription": "Suivez et ouvrez les [[Departments]] de votre équipe ici.",
            "disasterRecoveryIntroductionEmailTitle": "Attribuer une date d'échéance et informer les propriétaires de [[Technology]]",
             "disasterRecoveryIntroductionEmailDescription": "Une date limite d'achèvement des activités d'élaboration du plan doit être définie. Vous pouvez également envoyer un courriel (ou un saut de page) aux propriétaires pour leur annoncer leur affectation."
        },
        "validateProcess": {
            "confirmUnassignTeamMember": {
                "header": "Annuler l’affectation d’un [[team member]]",
                "body": "Êtes-vous sûr(e) de vouloir annuler l’affectation de « {{ userName }} » de ce [[processes]]?",
                "text": "Annuler l’affectation"
            },
            "confirmUnassignTeamMemberAndAlternate": {
                "header": "Annuler l'affectation de [[team member]] et de son suppléant",
                "body": "Etes-vous sûr de vouloir annuler l'attribution '{{ userName }}' et '{{ alternateUserName }}' de ce [[processes]]?",
                "text": "Annuler l'attribution"
            },
            "confirmRemoveProcess": {
                "header": "Éliminer le [[processes]]",
                "body": "Êtes-vous sûr(e) de vouloir éliminer « {{ processName }} »?",
                "text": "Éliminer"
            },
            "removeProcessSuccess": "Le [[processes]] a été éliminé avec succès",
            "removeProcessError": "Erreur lors du [[processes]] d’élimination",
            "teamMemberModal": {
                "headerLabel": "Sélectionner un expert en la matière pour {{name}}",
                "searchUserLabel": "Rechercher un membre de l’équipe",
                "currentUserLabel": "Expert actuel en la matière",
                "teamMembers": "Membres de l’équipe"
            },
            "titleNewProcess": "Nouveau [[processes]]",
            "processName": "Nom du [[processes]]",
            "newProcess": "Nouveau [[processes]]",
            "assignTeamMember": "Déléguer",
            "unassignTeamMember": "Annuler l’affectation",
            "noTeamMemberAssigned": "Aucun délégué affecté",
            "removeProcess": "Éliminer le [[processes]]",
            "renameProcess": "Renommer le [[processes]]",
            "titleProcess": "[[Processes]]",
            "lblProcessName": "Saisir le nom du [[processes]]",
            "duplicateProcessName": "Un [[processes]] ayant le même nom existe déjà.",
            "msgSuccessSaveProcess": "{{processName}} sauvegardé avec succès",
            "msgFailSaveProcess": "Échec de la sauvegarde {{processName}}.",
            "msgFailSaveProcessPermission": "Votre connexion n’a pas l’autorisation de sauvegarder ce [[processes]].",
            "msgFailSaveProcessDuplicateName": "Un [[processes]] existant porte déjà ce nom.",
            "removePortalProcessSuccess": "{{processName}} éliminé",
            "removePortalProcessError": "Échec de l’élimination de {{processName}}",
            "unassignProcessUserSuccess": "Annulation de l’affectation de {{userName}} de {{processName}}",
            "unassignProcessUserError": "Échec de l’annulation de l’affectation de {{userName}} de {{processName}}",
            "assignProcessUserSuccess": "{{userName}} affecté à {{processName}}",
            "assignProcessUserError": "Échec de l’affectation de {{userName}} à {{processName}}",
            "AssignmentsDescription": "Afin d’évaluer l’impact sur notre société, nous avons besoin de votre contribution. Pour chaque [[processes]], veuillez considérer et sélectionner ensuite l’impact le plus approprié sur l’ensemble de la société si le [[processes]] ne peut pas être exécuté dans les délais indiqués. Veuillez continuer votre évaluation pour chacun des cinq types d’impact. Nous avons effectué une première évaluation basée sur notre expérience et les meilleures pratiques du secteur.",
            "functionalAreas": "[[Departments]]",
            "noProcessesForDepartment": "Aucun [[processes]] n’a été défini pour ce [[department]]",
            "plans": "[[Plans]]",
            "teamLead": "Chef d’équipe",
            "setDueDate": {
                "header": "Date d’échéance",
                "body": "Certains [[processes]] ont déjà une date d’échéance, souhaitez-vous fixer cette date d’échéance {{dueDate}} pour tous les [[processes]]?",
                "allProcesses": "Oui",
                "newProcesses": "Non",
                "msgConfirmBody": "Êtez-vous sûr(e) de vouloir fixer cette nouvelle date d’échéance {{dueDate}} pour tous les [[processes]]?"
            },
            "msgDueDateRequired": "La date d’échéance est requise pour tous les [[processes]]",
            "introductoryEmailDisclaimer": "Le contexte changera par nom d’utilisateur et [[processes]]",
            "reviewGetStarted": "Réviser et commencer",
            "trackYourProgress": "Suivre la progression",
            "reviewProcess": "1. Réviser les [[processes]]",
            "assignExpert": "2. Affecter un expert",
            "reviewGetStartedTooltip": "Veuillez réviser vos affectations ci-dessous ainsi que les dates d’échéance applicables. Pour commencer, veuillez cliquer sur Démarrer à côté du [[department]] de votre choix et où vous trouvez à tout moment. Après avoir cliqué sur Démarrer, vous visionnerez une courte vidéo d’introduction qui vous fournira des instructions supplémentaires.",
            "trackYourProgressTooltip": "Une fois qu’une mission est commencée, vous serez en mesure de suivre la progression de chaque [[department]]. Vous pouvez également suivre la progression des [[processes]] au sein de chaque [[department]] depuis cet écran.",
            "reviewProcessTooltip": "Veuillez vous assurer que tous les [[processes]] essentiels de ce [[department]] sont pris en compte. Pour ajouter un [[processes]] manquant, cliquer sur la fiche Nouveau [[processes]]. Pour éliminer ceux qui ne s’appliquent pas à votre [[department]], cliquer sur les points de suspension en haut à droite de la fiche de [[processes]], puis sélectionner Éliminer.",
            "assignExpertTooltip": "Pour chaque [[processes]] affiché ci-dessous, vous avez été automatiquement désigné(e) comme SME (expert en la matière). Vous pouvez réaffecter n’importe quel [[processes]] de la liste à d’autres SME. Pour ce faire, cliquer sur le nom de la fiche de [[processes]] que vous souhaitez mettre à jour. Pour localiser le nouveau SME, rechercher son nom dans la barre de recherche.",
            "isYourTeamCompleteTooltip": "Une fois que vous avez affecté un expert en la matière à chaque [[processes]], cliquer sur Suivant pour passer à la dernière étape.",
            "selectDueDateTooltip": "Sélectionner une date d’échéance pour l’affectation. Cliquer sur une date d’échéance individuelle pour apporter des modifications.",
            "sendAndFinishTooltip": "Informons votre équipe en leur envoyant un courriel de présentation. Cliquer sur Envoyer et terminer pour terminer la constitution de votre équipe d’experts en continuité des activités.",
            "reviewEmailTooltip": "Vérifier le modèle de courriel et apporter les modifications nécessaires en cliquant sur le bouton Modifier. Les modèles de courriel applicables seront envoyés aux utilisateurs en fonction de leur statut (utilisateur existant ou nouvel utilisateur). Si des modifications sont apportées, veuillez noter que les modifications s’appliqueront à tous les destinataires recevant le courriel.",
            "portalProcessLocationModal": {
                "header": "Choisissez l'[[location]]",
                "exceedMaxPortalProcessLocations": "Nombre maximal d'[[locations]] pour le [[process]] dépassé",
                "assignPortalProcessLocationError": "échec de l'attribution de l'[[location]] pour le [[processes]]"
            },
            "portalProcessDependenciesModal": {
                "header": "Choisissez la dépendance",
                "exceedMaxPortalProcessDependencies": "Dépendances maximales pour le [[process]] dépassées",
                "assignPortalProcessProcessDependencyError": "Échec de l'attribution de la dépendance pour le [[process]]",
                "PortalProcessProcessDependencySaveSuccessfully": "[[Process]] enregistrement de la dépendance avec succès",
                "PortalProcessProcessDependencyDeletedSuccessfully": "[[Process]] dépendance supprimée avec succès",
                "PortalProcessProcessDependencyDeletedUnSuccessfully": "Impossible de supprimer la dépendance [[process]]",
                "currentSelectedProcessDependencies": "Dépendances du [[processes]] actuel sélectionné",
                "availableProcessDependencies": "Dépendances [[Process]] disponibles",
                "addDependencies": "Ajouter des dépendances"
            },
            "removeLocationConfirmationModal": {
                "header": "Retirer {{locationName}}",
                "body": "Voulez-vous vraiment supprimer cet [[location]] de ce [[processes]]?  {{locationName}} sera perdu."
            },
            "removeDependencyConfirmationModal": {
                "header": "Retirer {{dependencyName}}",
                "body": "Voulez-vous vraiment supprimer cette dépendance de ce [[process]]?"
            },
            "removeContinuityStrategyConfirmationModal": {
                "header": "Supprimer la stratégie de continuité",
                "body": "Voulez-vous vraiment supprimer cette stratégie de continuité?"
            },
            "dependenciesPageHeader": "Quels sont les [[processes]] dépendants pour {{ name }}?",
            "dependenciesPageDescription": "Sélectionnez tous les [[processes]] dans votre [[departments]] ou dans d'autres qui doivent être exécutés pour que ce [[process]] soit terminé avec succès.",
            "locationsPageHeader": "Où ce [[processes]] est-il utilisé?",
            "locationsPageDescription": "Sélectionnez les [[locations]] où ce [[processes]] est exécuté afin que des stratégies de continuité puissent être attribuées.",
            "selectProcessSecondaryDescription": "Définissez vos [[Processes]] ici.",
            "selectProcessDescriptionBC": "Un processus métier définit une fonction ou un ensemble de fonctions exécutées dans le cadre des opérations commerciales quotidiennes dans votre département ou secteur d'activité. Vous pouvez \"attribuer\" la responsabilité d'un processus à un sujet ou vous pouvez en conserver la responsabilité principale.",
            "selectProcessDescriptionCOOP": " Une fonction essentielle définit une fonction ou un ensemble de fonctions exécutées dans le cadre des opérations quotidiennes de votre service ou organisation. Vous pouvez \"attribuer\" la responsabilité d'une fonction essentielle à un sujet ou vous pouvez conserver la responsabilité principale.",
            "tracKProcessesSecondaryDescription": "Suivez vos [[Processes]] ici.",
            "tracKProcessesDescription": "Suivez et complétez vos [[processes]] [[Departments]].",
            "noPrimary": "Aucun élément principal attribué",
            "noAlternate": "Aucun remplaçant attribué",
            "addPrimary": "Attribuer le primaire",
            "addAlternate": "Attribuer un remplaçant",
            "unAssignBothUserSuccess": "{{ userName }} et {{ alternate }} n'ont pas été attribués à partir de {{ processName }}",
            "unAssignBothProcessUserError": "Échec de la désattribution {{ userName }} et {{ alternate }} depuis {{ processName }}"
        },
        "impact": {
            "impactTitle": "Quel est l’impact sur <b>{{name}}</b>?",
            "impactDescription": "Afin d’évaluer l’impact sur votre société, nous avons besoin de votre contribution. Nous avons fourni une évaluation initiale basée sur notre expérience et les meilleures pratiques du secteur. Veuillez passer en revue nos sélections et apporter les ajustements nécessaires en sélectionnant l’impact approprié sur votre société globale si le [[processes]] ne peut pas être achevé dans les délais indiqués. Continuer l’évaluation pour chacune des six catégories d’impact jusqu’à ce qu’elles soient toutes terminées pour obtenir votre Objectif de temps de récupération dans la section suivante.",
            "category": {
                "financial": "Impact financier",
                "financialSeverityLegend": {
                    "insignificant": "Réduction potentielle de la trésorerie disponible",
                    "significant": "Réduction potentielle du capital d’exploitation disponible",
                    "catastrophic": "Potentiel de réduction de la disponibilité pour poursuivre les opérations"
                },
                "memberImpact": "Impact membre",
                "[[client]]Impact": "Impact [[client]]",
                "[[customer]]": "impact membre, [[client]], consommateur",
                "[[customer]]Impact": "Impact consommateur",
                "[[customer]]SeverityLegend": {
                    "insignificant": "Membre, [[client]], impact [[client]] - insignifiant",
                    "significant": "impact membre, [[client]], consommateur - significatif",
                    "catastrophic": "impact membre, [[client]], consommateur - catastrophique"
                },
                "employee": "Impact sur le moral et la rétention des employés",
                "employeeSeverityLegend": {
                    "insignificant": "Impact sur le moral et la rétention des employés - insignifiant",
                    "significant": "Impact sur le moral et la rétention des employés - significatif",
                    "catastrophic": "Impact sur le moral et la rétention des employés - catastrophique"
                },
                "reputational": "Impact sur la marque ou la réputation",
                "reputationalSeverityLegend": {
                    "insignificant": "Impact sur la marque ou la réputation - insignifiant",
                    "significant": "Impact sur la marque ou la réputation - significatif",
                    "catastrophic": "Impact sur la marque ou la réputation - catastrophique"
                },
                "operational": "Impact sur la prestation des services",
                "operationalSeverityLegend": {
                    "insignificant": "Impact sur la prestation des services - insignifiant",
                    "significant": "Impact sur la prestation des services - significatif",
                    "catastrophic": "Impact sur la prestation des services - catastrophique"
                },
                "compliance": "Impact réglementaire de la conformité",
                "complianceSeverityLegend": {
                    "insignificant": "Impact de la réglementation sur la conformité - insignifiant",
                    "significant": "Impact réglementaire de la conformité - significatif",
                    "catastrophic": "Impact de la réglementation sur la conformité - catastrophique"
                }
            },
            "severity": {
                "none": "Sans impact",
                "insignificant": "Insignifiant",
                "significant": "Significatif",
                "catastrophic": "Catastrophique"
            },
            "validationInfo": "Veuillez examiner chaque catégorie d’impact avant de passer à l’étape suivante",
            "saveErrorMsg": "Erreur lors de la sauvegarde de l’analyse d’impact",
            "impactAnalysis": "Analyse d’impact",
            "impactCategories": "Catégories d’impact",
            "viewImpactCategories": "1. Voir les catégories d’impact",
            "reviewOurSelections": "2. Consulter nos sélections",
            "makeChanges": "3.  Apporter des modifications",
            "viewImpactCategoriesTooltip": "Les catégories d’impact sont des domaines d’impact qui peuvent affecter votre société pendant une crise. Tenir compte de l’impact sur chaque catégorie d’impact indiquée si le [[processes]] opérationnel ne peut pas être achevé à temps.",
            "reviewOurSelectionsTooltip": "Sur la base de notre expérience et des meilleures pratiques du secteur, nous avons fourni une évaluation initiale pour chaque catégorie d’impact. Cette évaluation est basée sur le [[processes]] individuel et l’impact sur la société dans son ensemble. Passer en revue nos sélections pour chaque catégorie et les niveaux de gravité sélectionnés pour chaque jour d’impact.",
            "makeChangesTooltip": "Effectuer les ajustements nécessaires en examinant chaque catégorie d’impact et en sélectionnant le niveau de gravité approprié pour chaque jour d’impact si le [[processes]] ne peut pas être terminé. Lorsque chaque catégorie est terminée, cliquer sur suivant pour obtenir votre objectif de temps de récupération."
        },
        "rto": {
            "rtoTitle": " À quoi sert <b>l’objectif de temps de récupération</b> <b>{{processName}}</b>",
            "rtoDescription": "En fonction des sélections que vous avez fournies dans l’analyse d’impact, votre temps de récupération pour <b>{{processName}}</b> est illustré ci-dessous. Pour modifier facilement vos sélections d’impact, cliquer sur le nom de la catégorie d’impact à côté du graphique ci-dessous pour apporter des modifications. Le OTR peut également être mis à jour manuellement sur le côté droit de l’écran.",
            "rto": "OTR",
            "returnToImpactAnalysis": "Retour à l’analyse d’impact",
            "missingRto": "OTR manquant",
            "countOfProcesses": "Nombre de [[processes]]",
            "recoveryTimeObjective": "1. Objectif de temps de récupération",
            "impactAnalysis": "2. Analyse d’impact",
            "impactCategories": "3. Catégories d’impact",
            "recoveryTimeObjectiveTooltip": "En fonction des sélections que vous avez fournies dans la section précédente, votre objectif de temps de récupération (OTR) est indiqué ci-dessous. Vous pouvez mettre à jour manuellement votre OTR en cliquant sur l’image.",
            "impactAnalysisTooltip": "Le graphique d’analyse d’impact est une représentation visuelle des catégories d’impact et de leurs niveaux de gravité tout au long de chaque journée d’impact. Ces niveaux ont été sélectionnés dans la section précédente.",
            "impactCategoriesTooltip": "Si vous souhaitez apporter des modifications à l’une des données de catégorie d’impact affichées sur le graphique d’analyse d’impact, vous pouvez le faire en cliquant sur le nom de la catégorie d’impact."
        },
        "processAnalysis": {
            "AssignmentsDescription": "Afin d’évaluer l’impact sur notre société, nous avons besoin de votre contribution. Pour chaque [[processes]], veuillez considérer et sélectionner ensuite l’impact le plus approprié sur l’ensemble de la société si le [[processes]] ne peut pas être exécuté dans les délais indiqués. Veuillez continuer votre évaluation pour chacun des cinq types d’impact. Nous avons effectué une première évaluation basée sur notre expérience et les meilleures pratiques du secteur.",
            "task": "Tâche",
            "teamMember": "Membre de l’équipe",
            "process": "[[Processes]]"
        },
        "criticalDates": {
            "criticalDatesTitle": "Quelles sont les dates critiques pour {{ name }}?",
            "criticalDateSecondary": "Une date critique est une date ou une période spécifique et récurrente au sein d'un processus qui nécessite une attention accrue ou peut nécessiter une augmentation de l'objectif de temps de récupération (RTO) lors d'un incident.",
            "criticalDatesDescription": "Afin d’évaluer la priorité de ce [[processes]], lors d’une perturbation potentielle, il est utile de savoir quand il est généralement exécuté. Sélectionner une catégorie de date critique qui s’applique à votre [[processes]] opérationnel et modifier les dates le cas échéant. Vous pouvez également ajouter une nouvelle date critique si elle n’est pas indiquée dans la liste ci-dessous.",
            "newCriticalDate": "Nouvelle date critique",
            "name": "Nom de la date critique",
            "frequency": "Fréquence",
            "frequencyTypes": {
                "weekly": "Hebdomadaire",
                "biWeekly": "Bihebdomadaire",
                "monthly": "Mensuel",
                "quarterly": "Trimestriel",
                "semiAnnually": "Semestriellement",
                "annually": "Annuellement",
                "oneTimeEvent": "Une fois"
            },
            "atTheEndOfFrequencyTypes": {
                "weekly": "À la fin de la semaine",
                "biWeekly": "Au bout de deux semaines",
                "monthly": "À la fin du mois",
                "quarterly": "À la fin du trimestre",
                "semiAnnually": "Fin juin et fin décembre",
                "annually": "À la fin de l’année",
                "oneTimeEvent": "Une fois"
            },
            "onSpecificDayFrequencyTypes": {
                "weekly": "Chaque {{ datePartDesc }}",
                "biWeekly": "Toutes les 2 semaines le {{ datePartDesc }}",
                "monthly": "Le {{ datePartDesc }}",
                "quarterly": "Tous les 3 mois le {{ datePartDesc }}",
                "semiAnnually": "Tous les 6 mois le {{ datePartDesc }}",
                "annually": "Le {{ datePartDesc }}",
                "oneTimeEvent": "Le {{ datePartDesc }}"
            },
            "beginningOn": "Commençant le",
            "dayOfWeek": "Jour de la semaine",
            "beginningOnDate": "Commençant le {{ dateFormat }}",
            "sinceDate": "Depuis {{ dateFormat }}",
            "specificDate": "Date spécifique",
            "lastDayOf": "Dernier jour de...",
            "plusOrMinus": "Plus ou moins un nombre de jours avant ou après",
            "nextOccurrence": "Prochaine occurrence",
            "nextOccurrencePhrase": "Entre {{startDate}} et {{endDate}}",
            "addEditModal": {
                "addCriticalDate": "Ajouter une date critique",
                "editCriticalDate": "Modifier la date critique",
                "description": "Ci-dessous, vous pouvez modifier le nom de la date critique et configurer la fréquence, le type et la date de début.",
                "duplicateCriticalDatesName": "Une date critique ayant le même nom existe déjà",
                "criticalDateName": "Nom de la date critique",
                "exampleCriticalDate": "Exemple de date critique"
            },
            "confirmRemove": {
                "header": "Éliminer la date critique",
                "body": "Êtes-vous sûr(e) de vouloir éliminer « {{ name }} »?",
                "text": "Éliminer"
            },
            "confirmDeselect": {
                "header": "Désélectionner la date critique",
                "body": "Êtes-vous sûr(e) de vouloir désélectionner « {{ name }} »?",
                "text": "Désélectionner"
            },
            "saveSuccessMsg": "Dates critiques sauvegardées avec succès",
            "saveErrorMsg": "Erreur lors de la sauvegarde des dates critiques",
            "loadingErrorMsg": "Erreur lors du chargement des informations de date critique",
            "clickToAdd": "Cliquer pour ajouter",
            "nextOccurrenceMsg": "Entre {{ beginning }} et {{ end }}",
            "daysOfWeek": {
                "mondayAbbr": "lun.",
                "tuesdayAbbr": "mar.",
                "wednesdayAbbr": "mer.",
                "thursdayAbbr": "jeu.",
                "fridayAbbr": "ven.",
                "saturdayAbbr": "sam.",
                "sundayAbbr": "dim."
            },
            "examples": {
                "seeExampleWeeklyPayroll": "Voir exemple : Paie hebdomadaire",
                "seeExampleMonthlyRent": "Voir exemple : Paiement mensuel du loyer",
                "seeExampleOnetimeInvoice": "Voir exemple : Facture unique",
                "payroll": "Paie",
                "rentPayment": "Paiement du loyer",
                "invoice1320": "Facture 1320"
            },
            "criticalDates": "Dates critiques",
            "viewExamples": "1. Voir des exemples",
            "createCriticalDates": "2. Créer de nouvelles dates critiques",
            "reviewCriticalDates": "3. Passer en revue vos dates critiques",
            "viewExamplesTooltip": "Lors d’une perturbation, il est utile de savoir quand ce [[processes]] est effectué. Il est également important de répertorier toutes les autres dates critiques pour poursuivre ce [[processes]] opérationnel en cas de perturbation potentielle. Nous vous avons fourni trois exemples à consulter",
            "createCriticalDatesTooltip": "Pour ajouter une nouvelle date critique, vous pouvez copier une fiche exemple ou créer une nouvelle fiche.",
            "reviewCriticalDatesTooltip": "Avez-vous saisi toutes les dates nécessaires pour ce [[processes]] opérationnel? Si vous avez saisi toutes les dates critiques nécessaires, cliquez sur Suivant pour continuer à créer la stratégie de continuité pour ce [[processes]] opérationnel.",
            "errorLoadingCriticalDates": "Échec du chargement des dates critiques",
            "month": {
                "1": "Janvier",
                "2": "Février",
                "3": "Mars",
                "4": "Avril",
                "5": "Mai",
                "6": "Juin",
                "7": "Juillet",
                "8": "Août",
                "9": "Septembre",
                "10": "Octobre",
                "11": "Novembre",
                "12": "Décembre"
            }
        },
        "continuityStrategy": {
            "continuityStrategyTitle": "Stratégie de continuité pour {{ name }}",
            "continuityStrategyForDesc": "La stratégie de continuité pour {{ name }} est de",
            "responsibleEmployeesTemporaryLocationDesc": "Employés autorisés à approuver l’espace de travail temporaire",
            "responsibleEmployeesMoveTheWorkDesc": "Les employés qui contacteront le fournisseur et pourront autoriser le transfert",
            "responsibleEmployeesSuspendDesc": "Employés autorisés à suspendre le [[processes]]",
            "designatedEmployeesWorkFromHomeDesc": "Employés qui seront nécessaires",
            "designatedEmployeesTemporaryLocationDesc": "Employés désignés pour travailler dans l’espace de travail temporaire",
            "equipmentNeededLabel": "Sélectionner les équipements dont chaque employé aura besoin ou ajouter ceux qui manquent",
            "selectedEquipments": "Équipements dont chaque employé aura besoin",
            "saveSuccessMsg": "Stratégie de continuité sauvegardée",
            "saveErrorMsg": "Échec de la sauvegarde de la stratégie de continuité",
            "saveEquipmentSuccessMsg": "Équipement {{names}} ajouté",
            "saveEquipmentErrorMsg": "Échec de l’ajout de l’équipement {{names}}",
            "removeEquipmentSuccessMsg": "Équipement {{names}} éliminé",
            "removeEquipmentErrorMsg": "Échec de l’élimination de l’équipement {{names}}",
            "saveVendorSuccessMsg": "Fournisseur {{names}} ajouté",
            "saveVendorErrorMsg": "Échec de l’ajout du fournisseur {{names}}",
            "removeVendorSuccessMsg": "Fournisseur {{names}} éliminé",
            "removeVendorErrorMsg": "Échec de l’élimination du fournisseur {{names}}",
            "saveAssigneeSuccessMsg": "Employé autorisé {{names}} ajouté",
            "saveAssigneeErrorMsg": "Échec de l’ajout de l’employé autorisé {{names}}",
            "removeAssigneeSuccessMsg": "Employé autorisé {{names}} éliminé",
            "removeAssigneeErrorMsg": "Échec de l’élimination de l’employé autorisé {{names}}",
            "saveAssigneesSuccessMsg": "Employés autorisés {{names}} ajoutés",
            "saveAssigneesErrorMsg": "Échec de l’ajout des employés autorisés {{names}}",
            "removeAssigneesSuccessMsg": "Employés autorisés {{names}} éliminés",
            "removeAssigneesErrorMsg": "Échec de l’élimination des employés autorisés {{names}}",
            "saveEmployeeSuccessMsg": "Employé désigné {{names}} ajouté",
            "saveEmployeeErrorMsg": "Échec de l’ajout d’un employé désigné {{names}}",
            "removeEmployeeSuccessMsg": "Employé désigné {{names}} éliminé",
            "removeEmployeeErrorMsg": "Échec de l’élimination de l’employé désigné {{names}}",
            "saveEmployeesSuccessMsg": "Employés désignés {{names}} ajoutés",
            "saveEmployeesErrorMsg": "Échec de l’ajout des employés désignés {{names}}",
            "removeEmployeesSuccessMsg": "Employés désignés {{names}} éliminés",
            "removeEmployeesErrorMsg": "Échec de l’élimination des employés désignés {{names}}",
            "saveLocationSuccessMsg": "[[Location]] {{names}} mis à jour",
            "saveLocationErrorMsg": "Échec de la mise à jour de l’[[location]] {{names}}",
            "removeLocationSuccessMsg": "[[Location]] {{names}} éliminé",
            "removeLocationErrorMsg": "Échec de l’élimination de l’[[location]] {{names}}",
            "saveTechnologySuccessMsg": "[[Technology]] ou [[application]] {{names}} ajoutée",
            "saveTechnologyErrorMsg": "Échec de l’ajout de la [[technology]] ou de l’[[application]] {{names}}",
            "removeTechnologySuccessMsg": "[[Technology]] ou [[application]] {{names}} éliminée",
            "removeTechnologyErrorMsg": "Échec de l’élimination de la [[technology]] ou de l’[[application]] {{names}}",
            "saveInstructionsSuccessMsg": "Instructions supplémentaires sauvegardées",
            "saveInstructionsErrorMsg": "Échec de la sauvegarde des instructions supplémentaires",
            "loadErrorMsg": "Erreur lors du chargement de la stratégie de continuité",
            "missingRtoImpactErrorMsg": "Avant d’affecter la stratégie de continuité, veuillez affecter le OTR",
            "vendorsSelected": "{0} fournisseurs sélectionnés",
            "selectAVendor": "Sélectionnez un fournisseur",
            "technologiesSelected": "{0} [[technologies]] sélectionnées",
            "selectATechnology": "Sélectionnez une [[technology]]",
            "strategyTypes": {
                "workFromAnywhere": "Travailler depuis n’importe où",
                "temporaryLocation": "Espace de travail temporaire",
                "moveTheWork": "Déplacer le travail",
                "suspend": "Suspendre",
                "9c004e84-4eb4-44f1-a008-cd33f5211014": "Travailler depuis n’importe où",
                "704acb8f-ed9b-4f11-ab37-0f0139895d5b": "Espace de travail temporaire",
                "e56b1600-219c-4985-b8fa-2b31228d201c": "Déplacer le travail",
                "984817b1-c6ee-4c5e-ace7-e558843bdfcb": "Suspendre"
            },
            "equipmentTypeModal": {
                "title": "Type d’équipement",
                "desc": "Ajouter ou modifier un type d’équipement à utiliser pour cette stratégie de continuité",
                "typeNamePlaceholder": "Nom du type d’équipement",
                "equipments": "[[Equipments]]",
                "equipmentInUseError": "L’équipement est affecté à une stratégie de continuité active",
                "deleteEquipmentHeader": "Éliminer l’équipement",
                "deleteEquipmentMessage": "Êtes-vous sûr(e) de vouloir éliminer cet équipement?",
                "duplicateEquipmentNameError": "Nom de l’équipement en double"
            },
            "searchForVendor": "Rechercher un fournisseur",
            "vendorModal": {
                "title": "Fournisseur",
                "desc": "Ajouter ou modifier le fournisseur à utiliser pour cette stratégie de continuité",
                "typeNamePlaceholder": "Nom du fournisseur",
                "contactName": "Nom du contact",
                "contactNamePlaceholder": "Prénom et nom",
                "contactPhoneNumber": "numéro de téléphone de contact",
                "contactPhoneNumberPlaceholder": "(123) 456-7890",
                "additionalNotes": "Remarques supplémentaires",
                "additionalNotesPlaceholder": "Instructions pour contacter le fournisseur",
                "deleteVendorHeader": "Éliminer le fournisseur",
                "deleteVendorMessage": "Êtes-vous sûr(e) de vouloir éliminer ce fournisseur?",
                "addVendor": "Ajouter un fournisseur",
                "vendors": "Fournisseurs",
                "vendorInUseError": "Le fournisseur est affecté à un plan actif",
                "duplicateVendorNameError": "Nom du fournisseur en double",
                "vendorAddress": "Adresse du vendeur",
                "contractStartDate": "Date de début du contrat",
                "contractExpirationDate": "Date de fin du contrat",
                "bulkUploadVendors": "Fournisseurs de téléchargement en masse",
                "bulkUploadContacts": "Contacts de téléchargement en masse"
            },
            "agencyModal": {
                "title": "Agence",
                "desc": "Ajouter ou modifier l'agence à utiliser pour cette stratégie de continuité",
                "typeNamePlaceholder": "Nom d'Agence",
                "contactName": "Nom du Contact",
                "contactNamePlaceholder": "Nom et Prénom",
                "contactPhoneNumber": "Numéro de Téléphone de Contact",
                "contactPhoneNumberPlaceholder": "(123) 456-7890",
                "additionalNotes": "Notes Complémentaires",
                "additionalNotesPlaceholder": "Instructions pour contacter l'agence",
                "deleteAgencyHeader": "Supprimer l'agence",
                "deleteAgencyMessage": "Êtes-vous sûr de vouloir supprimer cette agence ?",
                "addAgency": "Ajouter une Agence",
                "agencies": "Agences",
                "agencyInUseError": "L'agence est affectée à une stratégie de continuité active",
                "duplicateAgencyNameError": "Nom de l'agence en double",
                "agencyAddress": "Adresse de l'Agence",
                "bulkUploadAgencies": "Agences de Téléchargement Groupé",
                "bulkUploadContacts": "Contacts de Téléchargement Groupé",
                "new": "Ajouter une Nouvelle Agence",
                "agencyType": "Type d'Agence",
                "selectType": "Sélectionnez un type d'agence",
                "rolesFilter": "Filtrer les rôles"
            },
            "agencyAssignModal": {
                "assignAgency": "Attribuer une agence",
                "assignLocation": "Attribuer un emplacement",
                "resultLabel": "Sélectionnez l'agence",
                "resultLabelLocation": "Sélectionnez l'emplacement",
                "search": "Cherche ici",
                "currentAgenciesLabel": "Agences actuelles",
                "assignButton": "Attribuer",
                "currentAgency": "Agence actuelle",
                "resultLabelSingle": "Sélectionnez l'agence"
            },
            "confirmRemoveVendor": {
                "header": "Éliminer le fournisseur du [[processes]]",
                "body": "Êtes-vous sûr(e) de vouloir éliminer ce fournisseur de ce [[processes]]?",
                "text": "Éliminer"
            },
            "searchForTechnology": "Rechercher une [[application]] ou une [[technology]]",
            "confirmRemoveTechnology": {
                "header": "Éliminer l’[[application]] ou la [[technology]] du [[processes]]",
                "body": "Êtes-vous sûr(e) de vouloir éliminer cette [[application]] ou cette [[technology]] de ce [[processes]]?",
                "text": "Éliminer"
            },
            "technologyModal": {
                "title": "[[[[Technology]]]] ou [[Application]]",
                "desc": "Sélectionnez toute [[application]] ou [[technology]] qui sera nécessaire ou ajoutez celles qui manquent.",
                "deleteTechnologyHeader": "Supprimer [[Technology]]",
                "deleteTechnologyMessage": "Êtes-vous sûr de vouloir supprimer cette [[technology]] ?",
                "technologies": "[[Technologies]]",
                "technologyInUseError": "[[Technology]] est affecté à un plan actif",
                "technology": "[[Technology]]",
                "duplicateTechnologyNameError": "Nom de [[technology]] en double",
                "seconds": "Secondes",
                "minutes": "Minutes",
                "hours": "Heures",
                "days": "Jours",
                "second": "Deuxième",
                "minute": "Minute",
                "hour": "Heure",
                "day": "Jour",
                "selectRPOTime": "Sélectionnez l'heure",
                "bulkUpload": "Téléchargement groupé",
                "manageTechnologyTypes": "Gérer les types de [[Technology]]",
                "addTechnology": "Ajouter une nouvelle [[Technology]]",
                "typeNamePlaceholder": "[[Technologys]]",
                "technologyType": "[[Technology]] Type",
                "technologyTypes": "[[Technology]] Types",
                "technologyUsed": "[[Technology]] Utilisé",
                "technologyTypesTitle": "Gérez vos types de [[technology]] ici",
                "tier": "Étage",
                "technologyTypeDeleteHeader": "Supprimer le type [[Technology]]",
                "technologyTypeDeleteBody": "Êtes-vous sûr de vouloir supprimer ce type [[Technology]] ?",
                "technologyTypeSavedSuccessfully": "Le type [[Technology]] a été enregistré avec succès",
                "technologyTypeSavedUnSuccessfully": "Le type [[Technology]] a été enregistré sans succès",
                "technologyTypeDeletedSuccessfully": "Le type [[Technology]] a été supprimé avec succès",
                "technologyTypeDeletedUnSuccessfully": "Le type [[Technology]] a été supprimé sans succès"
              },
            "instructionsModal": {
                "title": "instructions supplémentaires",
                "instructionsPLaceHolder": "Saisir des instructions supplémentaires"
            },
            "addEmployee": "Ajouter un employé",
            "pickOrAddVendor": "Sélectionner un fournisseur ou ajouter un nouveau fournisseur dans la liste",
            "pickOrAddTechnology": "Sélectionner n’importe quelle [[application]] ou [[technology]] qui sera nécessaire ou ajouter celles qui manquent",
            "pickOrAddLocation": "Sélectionnez un [[location]] ou ajoutez un nouvel emplacement dans la liste.",
            "searchForLocationPlaceholder": "[[Location]] de l’espace de travail temporaire",
            "externalLocationModal": {
                "title": "Emplacement de l’espace de travail",
                "desc": "Ajouter ou modifier un emplacement d’espace de travail temporaire"
            },
            "companyLocation": "Emplacement de la société",
            "vendorLocation": "Emplacement temporaire",
            "addVendor": "Ajouter un fournisseur",
            "confirmRemoveAssignee": {
                "header": "Éliminer l’employé du [[processes]]",
                "body": "Êtes-vous sûr(e) de vouloir éliminer cet employé de ce [[processes]]?",
                "text": "Éliminer"
            },
            "assigneeModal": {
                "headerLabel": "Sélectionner un employé",
                "searchUserLabel": "Rechercher un employé",
                "currentUserLabel": "Employés actuellement affectés"
            },
            "managerModal": {
                "headerLabel": "Sélectionnez un gestionnaire",
                "searchUserLabel": "Rechercher un gestionnaire",
                "currentUserLabel": "Gestionnaire actuellement affecté",
                "resultLabel": "Gestionnaires"
            },
            "continuityStrategy": "Stratégie de continuité",
            "vendorAlreadySelected": "Fournisseur déjà sélectionné",
            "selectedVendors": "Fournisseurs sélectionnés",
            "technologyAlreadySelected": "[[Application]] ou [[technology]] déjà sélectionnée",
            "selectedTechnologies": "[[Applications]] ou [[technologies]] sélectionnées",
            "selectedLocation": "[[Location]] sélectionné",
            "continuityStrategiesByRto": "Stratégies de continuité par OTR",
            "strategy": "Stratégie",
            "missingRequiredFields": "Veuillez réviser les champs manquants avant de continuer",
            "continuityStrategyInstructions": "Pour terminer le plan de continuité des activités de ce [[processes]], sélectionner une stratégie de continuité ci-dessous et remplir les sections applicables. Une fois terminé, cliquer sur Terminé pour finaliser ce [[processes]] opérationnel.",
            "lblAdditionalInstructions": "instructions supplémentaires",
            "noAdditionalInstructions": "Aucune instruction supplémentaire",
            "updateInstructions": "Instructions de mise à jour",
            "uploadDocument": "Télécharger le document",
            "noAttachments": "Aucune pièce jointe",
            "lblAdditionalQuestions": "questions supplémentaires",
            "noAdditionalQuestions": "Aucune questions supplémentair",
            "createStrategy": "Créer une stratégie",
            "strategyCreated": "Stratégie créée",
            "strategyDeleted": "Stratégie supprimée"
        },
        "noAssignedFunctionalArea": "Aucun [[department]] affecté",
        "noAssignedProcess": "Aucun [[processes]] affecté",
        "searchUsers": "Saisir le nom, le rôle, le [[department]] ou le groupe à rechercher",
        "noAssignedUserError": "Veuillez faire une sélection avant de continuer",
        "sendIntroductoryEmail": "Envoyer un courriel de présentation",
        "sendTeamLeadEmailTitle": "Maintenant que vous avez créé votre équipe, envoyons-leur un courriel. Un modèle de courriel a été fourni pour révision sur le côté droit de l’écran. Lorsque vous êtes prêt(e) à envoyer le courriel, attribuez une date d’échéance et cliquez sur Envoyer et terminer.",
        "sendTeamMemberEmailTitle": "Maintenant que vous avez créé votre équipe, envoyons-leur un courriel. Un modèle de courriel a été fourni pour révision sur le côté droit de l’écran. Lorsque vous êtes prêt(e) à envoyer le courriel, attribuez une date d’échéance et cliquez sur Envoyer et terminer.",
        "titleAssignments": "Affectations {{userProfileName}}",
        "functionalAreas": "[[Departments]]",
        "titleNavigatePlan": "Plans de continuité des activités",
        "introductoryVideo": "Vidéo de présentation",
        "plans": {
            "plans": "[[Plans]]",
            "generatePlansByDepartment": "Générer des [[plans]] par [[department]]",
            "lastGeneratedDate": "Date de la dernière génération",
            "planTemplate": "Modèle de plan",
            "generatePlans": "Générer des [[plans]]",
            "errGeneratePlanCanceled": "La génération du plan a été annulée.",
            "errorDownloadingPlan": "Une erreur inattendue s’est produite lors du téléchargement du plan.",
            "errGeneratePlanNoTemplate": "Impossible de démarrer la génération du [[plan]]. Le modèle de [[Plan]] n’est pas attribué.",
            "noPlans": "Aucun plan.",
            "generateDepartmentPlans": "Générer tous les plans [[Department]]",
            "generateTechnologyPlans": "Générer tous les plans [[Technology]]",
            "generateLocationPlans": "Générer des plans de localisation",
            "generateCompanyPlan": "Générer un plan d'entreprise",
            "continuityOfOperationPlanning": "Plan COG",
            "emergencyOperations": "Plan EOP",
            "continuityOfGovernment": "Continuité du gouvernement (COG)",
            "businessContinuity": "Régime d'entreprise",
            "disasterRecovery": "Régime d'entreprise",
            "templateName": "Nom du Modèle",
            "goToTemplates": "Aller aux modèles"
        },
        "functions": "Fonctions",
		"essentialFunctions": "Fonctions essentielles",
        "processes": "[[Processes]]",
        "manageFunctionalAreas": "Gérer les [[departments]]",
        "viewFunctionalAreas": "Voir les [[departments]]",
        "summary": "Résumé",
        "titleOverview": "Aperçu de l’équipe",
        "overviewDescription": "Ci-dessous figure une liste des [[departments]] avec leurs chefs d’équipe respectifs qui sont désormais chargés de compléter leurs sections individuelles du plan de continuité. Si vous vous êtes affecté vous-même le rôle de chef d’équipe pour un [[department]], vous pouvez commencer à tout moment en cliquant sur démarrer. Sinon, vous pouvez afficher la progression de chaque [[department]] en consultant la section de statut.",
        "overviewDescriptionHeader": "Description, énoncé de mission ou vision du service",
        "descriptionSavedSuccessfully": "Description enregistrée avec succès",
        "descriptionSavedUnsuccessfully": "Description enregistrée sans succès",
        "teamLeadDescription": "Vous trouverez ci-dessous une liste des [[departments]] affectés dans lesquels vous êtes un expert. Cliquez sur le bouton Démarrer pour commencer à valider les [[processes]] au sein du [[department]] affecté et déléguer ceux qui sont nécessaires.",
        "processesHeader": "[[Processes]]",
        "reviewAndManage": "1. Réviser et gérer",
        "trackTeamsProgress": "2. Suivre la progression de votre équipe",
        "reviewAndManageTooltip": "Passer en revue vos [[departments]] et les chefs d’équipe précédemment sélectionnés. Apporter des modifications si nécessaire en cliquant sur le bouton Gérer les [[departments]].",
        "trackTeamsProgressTooltip": "Suivre les progrès de la planification de la continuité des activités de votre équipe. Une fois les devoirs terminés, une coche apparaîtra",
        "teamLead": "Chef d’équipe",
        "sendAndFinish": "Envoyer et terminer",
        "lblSelectDueDate": "Sélectionner la date d’échéance",
        "lblDueDate": "Date d’échéance",
        "exampleEmail": "Exemple de courriel",
        "msgWelcomeAgilityPlanner": "Bienvenue dans le [[planner]] Preparis! Voici un aperçu de l’équipe que vous avez créée. Dans cette section, vous pouvez effectuer les opérations suivantes :",
        "msgWelcomeAgilityPlannerTeamLead": "Bienvenue dans le [[planner]] Preparis! Vous trouverez ci-dessous un aperçu de vos missions. Dans cette section, vous pouvez effectuer les opérations suivantes :",
        "msgWelcomeAgilityPlannerTeamMember": "Bienvenue dans le [[planner]] Preparis! Vous trouverez ci-dessous un aperçu de vos missions. Dans cette section, vous pouvez effectuer les opérations suivantes :",
        "reviewAssignmentsTeamMember": "1. Examiner les affectations",
        "getStartedTeamMember": "2. Commencer",
        "reviewAssignmentsTeamMemberTooltip": "Passer en revue vos [[processes]] affectés ci-dessous ainsi que les dates d’échéance applicables. Les [[processes]] répertoriés dans cette section vous ont été affectés en tant qu’expert en la matière par votre chef d’équipe. ",
        "getStartedTeamMemberTooltip": "Commencer vos missions à tout moment en cliquant sur le bouton Démarrer pour un [[processes]] particulier. Après avoir cliqué sur le bouton Démarrer, vous serez dirigé(e) vers une courte vidéo d’introduction qui vous fournira des instructions supplémentaires.",
        "msgFailSendEmail": "Échec de l’envoi des courriels de présentation",
        "msgSuccessSendEmail": "Courriel envoyé à {{name}}",
        "msgDueDateSaved": "Date d’échéance mise à jour",
        "lblSentEmailOn": "Envoyé le",
        "emailTemplate": {
            "userName": "John Doe",
            "departmentNames": "Ressources humaines",
            "processNames": "Paie",
            "loginName": "john.doe@preparis.com",
            "placeHolders": {
                "recipientName": "Nom du destinataire",
                "senderName": "Nom de l’expéditeur",
                "departmentNames": "Noms du [[department]]",
                "processNames": "Noms du [[processes]]",
                "portalUrl": "Lien vers le portail Preparis",
                "activateUrl": "Lien d’activation du compte utilisateur",
                "userName": "Connexion au compte utilisateur",
                "technologyNames": "Nom du [[technologies]]"
            },
            "annexNames": "annexes suivantes",
            "technologyNames": "following technologies"
        },
        "status": {
            "none": "Pas commencé",
            "inProcess": "En cours",
            "complete": "Achevé"
        },
        "sentDate": "Date d’envoi",
        "percentComplete": "Pourcentage achevé",
        "dashboard": {
            "overallProgressAndIssueTracking": "Progression globale et suivi des problèmes",
            "progressByDepartment": "Progression par [[department]]",
            "progressByProcess": "Progression par [[processes]]",
            "bcDashboardTitle": "Tableau de bord du plan de continuité des activités",
            "quickView": "Aperçu rapide",
            "assignedTeamMember": "Membre d’équipe affecté",
            "progress": "Progression",
            "errorLoadingProcesses": "Erreur de chargement des [[processes]]",
            "viewAll": "Afficher tout",
            "bcAnalytics": "Analytique",
            "authorizedEmployees": "Employés autorisés",
            "affectedEmployees": "Employés concernés",
            "temporaryWorkspaceAddress": "Adresse de l’espace de travail temporaire",
            "errorLoadingData": "Erreur lors du chargement des données du tableau de bord",
            "issues": "[[Issues]]",
            "blank": "Vide",
            "processGlobalSearchPlaceholder": "Rechercher dans toutes les colonnes",
            "selectableColumns": "Colonnes sélectionnables",
            "plannerDashboard": "Tableau de bord du planificateur",
            "technologyToProcess": "[[Technology]] à traiter",
            "technologyToTechnology": "De [[Technology]] à [[Technology]]",
            "total": "Total",
            "gaps": "Lacunes",
            "technologyNoRunbook": "[[Technologies]] attribuées sans Runbook",
            "gapType": "Type d'espace",
            "criticalVendors": "Fournisseurs critiques",
            "disasterRecoveryTeamsToTechnology": "Des équipes de reprise après sinistre à la [[technology]]",
            "progressByOwner": "Progrès par propriétaire",
            "progressByTier": "Progression par niveau",
            "technologyOwner": "Propriétaire de la [[Technology]]"
        },
        "bcIssue": {
            "userSelection": {
                "searchUserLabel": "Changer de propriétaire",
                "currentUserLabel": "Propriétaire actuel"
            },
            "issue": "Remarque ou problème",
            "issues": "Remarques ou problèmes",
            "addNewIssue": "Ajouter une nouvelle remarque ou un problème",
            "createIssue": "Créer une remarque ou un problème",
            "manageIssues": "Gérer les remarques ou les problèmes",
            "viewIssues": "Afficher les remarques ou les problèmes",
            "changeOwner": "Changer de propriétaire",
            "lblTitle": "Titre",
            "lblDescription": "Description",
            "lblIssueStatus": "Statut",
            "lblIssueOwner": "Propriétaire",
            "lblManagementAttention": "Attention de la gestion",
            "lblManagementAttentionAbbr": "Gestion",
            "lblRevisionItemType": "Catégorie",
            "lblFunctionalArea": "[[Department]]",
            "lblProcess": "[[Processes]]",
            "lblComments": "Commentaires",
            "newComment": "Nouveau commentaire",
            "addComment": "Ajouter un commentaire",
            "commentOn": "sur",
            "bcIssueStateType_6171be3d-bd24-4f9c-a9cd-d571c513780d": "Ouvert",
            "bcIssueStateType_8c7d2b2e-c08c-4ef0-881c-e386d86c2245": "Fermé",
            "bcIssueStateType_1ed8f21b-edcc-4408-9e7c-ebb6447bafe0": "Archivé",
            "getIssueCommentsErrorMsg": "Erreur lors de l’obtention des commentaires sur le problème",
            "loadingIssuesErrorMsg": "Erreur lors du chargement des problèmes",
            "ownerModal": {
                "headerLabel": "Sélectionner un propriétaire pour le problème",
                "searchUserLabel": "Rechercher le propriétaire",
                "currentUserLabel": "Propriétaire actuel",
                "successMsg": "Le propriétaire du problème a été remplacé par {{ newOwnerName }}",
                "errorMsg": "Erreur lors du changement de propriétaire"
            },
            "saveIssueComment": {
                "successMsg": "Sauvegarde de la remarque ou du commentaire de problème effectuée avec succès",
                "errorMsg": "Erreur lors de la sauvegarde de la remarque ou du commentaire de problème"
            },
            "saveIssue": {
                "successMsg": "La remarque ou problème ont été sauvegardés avec succès",
                "errorMsg": "Erreur lors de la sauvegarde de la remarque ou du problème",
                "errorPopulateRequiredFieldsMsg": "Remplir tous les champs obligatoires"
            },
            "confirmDeleteIssueCommentModal": {
                "header": "Supprimer la remarque ou commentaire du problème",
                "body": "Êtes-vous sûr(e) de vouloir supprimer la remarque ou le commentaire de problème créé le {{ createdOn }}?",
                "text": "Supprimer",
                "successMsg": "Suppression réussie de la remarque ou du commentaire sur le problème",
                "errorMsg": "Erreur lors de la suppression de la remarque ou du commentaire sur le problème"
            },
            "noIssues": "Aucun problème",
            "businessContinuityIssues": "[[Issues]] de continuité des activités",
            "attentionOnlyTitle": "Needs Management Attention",
            "allIssueTitle": "Nombre total",
            "msgErrorLoadingFunctionalAreas": "Erreur lors du chargement des [[departments]]",
            "status": {
                "6171be3d-bd24-4f9c-a9cd-d571c513780d": "Ouvert",
                "8c7d2b2e-c08c-4ef0-881c-e386d86c2245": "Fermé"
            },
            "lblDisplayClosed": "Afficher fermé"
        },
        "bcRevisionItemTypes": {
            "9a8ca3b0-c29c-429a-bae3-5106ebc9eef6": "Construire votre équipe",
            "8075edbe-224e-4af0-accf-095cc08a650d": "Envoyer un courriel au chef d’équipe",
            "3001b73a-eff1-442a-9bf0-31ef0002f995": "Valider le [[processes]]",
            "e7bd0842-eadc-483f-b9c5-9a3d5047b1f4": "Envoyer un courriel d’affectation",
            "d731177f-8663-44e8-a243-b058b4bf9527": "Évaluation de l’impact",
            "be1d75e6-dac5-459f-ae5c-d565b20022de": "OTR",
            "a190d9cc-bf54-4cd4-80ba-9d1ac8028b3e": "Dates critiques",
            "056594f5-b6f0-46fb-9855-1303b611e293": "Stratégie de continuité",
            "6c046473-8b67-4037-b8e5-d806800d0b06": "Impact financier",
            "70d4a412-d41f-4c98-962f-7a45ffeebeeb": "Impact [[client]]",
            "886647d0-77ba-4cc1-9366-70d15506f63e": "Impact employé",
            "c3c00b14-80dd-4325-8849-2aebd26bd2d5": "Impact marque",
            "881236ae-0445-4c49-9356-30910be262b4": "Impact services",
            "92a17340-8be5-455d-81be-7cc58228eaa5": "Impact conformité",
            "79272c21-fcdf-4624-94c4-e6c95ba7c03d": "Dépendances de [[Process]]",
            "951b6fb4-14db-4cd2-92ab-f2d24aa38b4f": "[[Locations]] de [[Process]]",
            "f8e8fb86-f246-4892-a9cc-a889b99fe2b1": "Délégations de pouvoir",
            "8169c5dd-85c0-4861-9983-1c7bf24e72fb": "Ordre de succession",
            "b2c8a5da-8e90-4657-8e9b-000962841860": "[[Department]] [[teams]]",
            "5ddccee3-fe67-4206-8f80-4ac92b7b6382": "[[Locations]]",
            "8ee5ce4f-5fba-43a9-95b1-42f668a6e97c": "[[Department]] descriptions",
            "3cba5e70-bd1b-4cb8-8eea-dce7e515db94": "[[Team]] Responsable suppléant",
            "b7b3c55a-bcb9-4846-ab25-faa09ddc6254": "Organisationnel [[teams]]",
            "184fb5cd-a848-4b5e-9384-94057e62a2b1": "Suivre les [[Departments]]",
            "78eab4a2-ac01-415a-b5f9-a859b64387a4": "Suivre les [[Processes]]",
            "139e56cf-346e-4bdd-90b5-02e55fd3d25d": "Agences annexes",
            "7fdf1dcc-df3d-4394-b9e6-96ca9a91b290": "Liste des mesures de l’annexe",
            "94caacc3-832a-4aec-b5da-63a1679e4eff": "Questions générales",
            "f7518654-5f95-47c7-a01c-6cd833423f2e": "Suivre les annexes",
            "451cd7cd-e99a-42ff-aa2e-213063801e55": "Ordre de succession organisationnel",
            "d6686ed4-ef96-48f4-8f58-3c98227baaa7": "Délégation de pouvoir organisationnelle",
            "dfd9839e-9f89-4d18-8442-cf4920068326": "Sections de l’annexe",
            "fd9bc961-2d0b-4fc3-932a-894f55463717": "Remplacement",
            "9866e964-4fd7-4b93-a247-afd1b2abae50": "Construisez et gérez vos annexes",
            "deb3139e-5f75-441d-982c-cf0ae4d61c5f": "Construisez et gérez vos technologies",
            "bb95d0b1-482b-4c98-8006-165082f396b5": "E-mail de présentation",
            "2c9d3e1d-b028-4859-b2cd-40860d103670": "Fournisseurs de technologie",
            "637b9412-ead5-4656-9bfd-9c856ac152aa": "Ensembles de tâches technologiques",
            "9f52df24-261e-4967-85ad-65c5299fa6fe": "Dépendances technologiques",
            "7c484880-6dc1-4413-8954-010db5e6ee52": "Temps de récupération technologique",
			"ba765aaf-562e-4f9a-8e18-e9748a13f180": "Envoyer des e-mails aux propriétaires de technologie",
			"f07ab2cd-4f19-428f-8b9f-54982abfa079": "Technologies de suivi",
			"4c04b488-542b-49aa-9b99-851a44f4e22b": "Détails de l'application technologique"
        },
        "bcProgressStatusTypes": {
            "notStarted": "Pas commencé",
            "inProcess": "En cours",
            "complete": "Achevé"
        },
        "bcCustomFields": {
            "customFieldsLabel": "Questions personnalisées",
            "technologyTypes": "Types de [[Technology]]",
            "help": {
                "selectTechnologyTypesToolTip": "Liste des types de [[Technology]] disponibles."
            }
        },
        "ordersOfSuccession": {
            "title": "Ordres de succession",
            "description": "Ci-dessous, vous pouvez maintenir les ordres de succession au sein de votre organisation. ",
            "newSuccession": "Nouvelle Succession",
            "AddPrimary": "Ajouter principal",
            "AddAlternate": "Ajouter une alternative",
            "AddAlternates": "Ajouter des suppléants",
            "selectPrimaryUser": {
                "headerLabel": "Sélectionnez un agent principal pour cet ordre de succession",
                "searchUserLabel": "Rechercher un mandataire principal pour cet ordre de succession",
                "currentUserLabel": "Agent principal actuel",
                "resultLabel": "Agents"
            },
            "selectAlternateUser": {
                "headerLabel": "Sélectionnez un agent suppléant pour cet ordre de succession",
                "searchUserLabel": "Rechercher un agent suppléant pour cet ordre de succession",
                "currentUserLabel": "Agent suppléant actuel",
                "resultLabel": "Agents"
            },
            "orderSavedSuccessfully": "L'ordre de succession a été enregistré avec succès",
            "orderSavedUnsuccessfully": "L'ordre de succession a été enregistré sans succès",
            "removeOrderOfSuccession": "Supprimer l'ordre de succession",
            "removeOrderOfSuccessionDescription": "Voulez-vous vraiment supprimer cet ordre de succession ?",
            "successOnDelete": "L'ordre de succession a été supprimé avec succès",
            "failedToDelete": "L'ordre de succession a été supprimé sans succès",
            "titleDescription": "A descriptive name of the order of succession.",
            "primaryDescription": "The primary person that is for this Order of Succession.",
            "alternatesDescription": "An ordered list of who would succeed in an event the primary person is unable to perform their duties.",
            "alternate": "Alterner",
            "titleDetails": "Détails de l'ordre de succession",
            "description2": "Veuillez ajouter l'ordre de succession par défaut pour cette industrie.",
            "oosSecondaryDescription": "Définissez ici vos ordres de succession.",
            "oosDescription": "Gérez les ordres de succession qui soutiennent votre département. ",
            "oosOrgDescription": "Ci-dessous, vous pouvez gérer les ordres de succession au sein de votre organisation."
        },
        "authorityDelegation": {
            "headerSummary": "Délégations de pouvoir",
            "header": "Délégation de pouvoirs",
            "title": "Titre",
            "triggerCondition": "Déclencher",
            "triggersPlaceholder": "Entrez vos déclencheurs ici",
            "limitation": "Limites",
            "limitationsPlaceholder": "Entrez vos limites ici",
            "actingPerson": "Personne intérimaire",
            "delegatedPerson": "Personne déléguée",
            "modalMsg": "{{field}} pour la délégation de pouvoir",
            "saveSuccess": "Délégation de pouvoir enregistrée avec succès",
            "saveError": "Délégation de pouvoir enregistrée sans succès",
            "titleVerbiage": "C'est là que vous enregistrez une fonction particulière qu'un individu est autorisé à exécuter.",
            "triggersVerbiage": "C'est ici que vous enregistrez les points de déclenchement auxquels vous auriez besoin de transférer les responsabilités d'un agent intérimaire à un agent délégué.",
            "limitationsVerbiage": "C'est ici que vous enregistrez les limites qu'un agent délégué devrait respecter lorsque les responsabilités sont transférées d'un agent intérimaire.",
            "actingVerbiage": "C'est ici que vous enregistrez les noms de la ou des personne(s) par intérim/principale(s) responsable(s) de la délégation spécifiée.",
            "delegatedVerbiage": "C'est ici que vous enregistrez les noms des personnes déléguées/secondaires qui sont responsables de la délégation spécifiée.",
            "addActing": "Nouveau par intérim",
            "addDelegated": "Nouvelle délégation",
            "headerList": "Délégations de pouvoir",
            "titleListVerbiage": "Ci-dessous, vous pouvez maintenir les délégations de pouvoir au sein de votre organisation. ",
            "confirmDeleteHeader": "Supprimer {{ name }}",
            "confirmDeleteVerbiage": "Voulez-vous vraiment supprimer cette délégation de pouvoir?",
            "successOnDelete": "La délégation a été supprimée avec succès",
            "failedToDelete": "La délégation a été supprimée sans succès",
            "actives": "Actifs",
            "delegates": "Délégués",
            "lastUpdated": "Dernière mise à jour: {{date}}",
            "backToSummary": "Retour au Sommaire",
            "markComplete": "Marquer comme terminé",
            "searchUser": {
                "headerLabel": "Sélectionnez un mandataire pour cette délégation de pouvoir",
                "searchUserLabel": "Rechercher un mandataire pour cette délégation de pouvoir",
                "currentUserLabel": "Agents actuels",
                "resultLabel": "Agents"
            },
            "limitationsTooltip": "C'est ici que vous enregistrez les limites qu'un agent délégué devrait respecter lorsque les responsabilités sont transférées d'un agent intérimaire.",
            "actionPersonTooltip": "C'est ici que vous enregistrez les noms de la ou des personne(s) par intérim/principale(s) responsable(s) de la délégation spécifiée.",
            "delegatedPersonTooltip": "C'est ici que vous enregistrez les noms des personnes déléguées/secondaires qui sont responsables de la délégation spécifiée.",
            "authorityDescription": "C'est là que vous enregistrez une fonction particulière qu'un individu est autorisé à exécuter.",
            "triggersDescription": "C'est ici que vous enregistrez les points de déclenchement auxquels vous auriez besoin de transférer les responsabilités d'un agent intérimaire à un agent délégué.",
            "limitationsDescription": "C'est ici que vous enregistrez les limites qu'un agent délégué devrait respecter lorsque les responsabilités sont transférées d'un agent intérimaire.",
            "primaryDescription": "C'est ici que vous enregistrez les noms de la ou des personne(s) par intérim/principale(s) responsable(s) de la délégation spécifiée.",
            "delegatesDescription": "C'est ici que vous enregistrez les noms des personnes déléguées/secondaires qui sont responsables de la délégation spécifiée.",
            "actingAgent": "Agent par intérim",
            "delegatingAgent": "Agent délégant",
            "actingAgentDesc": "C'est ici que vous enregistrez les noms de la ou des personnes agissant/principales qui sont responsables de la délégation spécifiée.",
            "backToDepartments": "Retour aux Départements",
            "headerDetails": "Détails de la délégation de pouvoirs",
            "titleListVerbiage2": "Veuillez ajouter les délégations d'autorité par défaut pour cette industrie.",
            "authorityOfDelegationSecondaryDescription": "Définissez ici vos délégations de pouvoir.",
            "authorityOfDelegationDescription": "Gérer les délégations de pouvoir du département. ",
            "authorityOfDelegationOrgDescription": "Ci-dessous, vous pouvez gérer les délégations d’autorité au sein de votre organisation. "
        },
        "summarySidebar": {
            "question01": "Que sont les [[processes]]?",
            "answer0100": "Les [[processes]] sont les responsabilités/tâches que votre organisation doit accomplir pour être considérée comme \"opérationnelle\". ",
            "answer0401": "Les [[processes]] sont les responsabilités/tâches que votre organisation doit accomplir pour être considérée comme \"opérationnelle\". ",
            "answer0402": "Diverses [[processes]]:",
            "answer0403": "Effectuer un audit quotidien du journal des comptes fournisseurs (comptabilité)",
            "answer0404": "Créer et soumettre des audits quotidiens des avantages sociaux (Ressources humaines)",
            "answer0405": "Détecter et supprimer les incendies urbains, ruraux et sauvages (incendie)",
            "answer0406": "Répondre aux appels au 911 et aux accidents de la route (SMU)",
            "answer0407": "Délivrer des médicaments aux patients tuberculeux/VIH (Santé)",
            "answer0408": "Inspecter et entretenir le système d'eau/eaux usées (Travaux publics)",
            "question02": "Que sont les délégations de pouvoir ?",
            "answer0201": "Dans presque toutes les organisations, il y a du personnel désigné avec le pouvoir de faire certaines choses que très peu de personnes au sein de l'organisation sont autorisées à faire. ",
            "answer0202": "Astuce : La section Délégation de pouvoir est TRÈS similaire à la section Ordres de succession de votre plan ; ",
            "answer0203": "Voici quelques exemples d'autorités à considérer qui pourraient s'appliquer:",
            "answer0204": "Qui peut signer des chèques, des contrats ou d'autres documents juridiques?",
            "answer0205": "Qui peut accorder une autorisation de voyage, de vacances ou de congé prolongé?",
            "answer0206": "Qui peut effectuer des achats de certains montants au-delà des limites d'achat normales ?",
            "answer0207": "Qui peut fermer le lieu de travail et/ou déterminer une ouverture différée, et pour quelle durée ?",
            "answer0208": "Qui peut embaucher et/ou licencier du personnel ?",
            "answer0209": "Existe-t-il des pouvoirs ou des capacités spéciales exercées par un personnel spécifique au sein de l'organisation ? ",
            "question03": "Que sont les ordres de succession ?",
            "answer0301": "Les ordres de succession peuvent être résumés par la question: \"Qui vient ensuite dans la chaîne de commandement ?\"",
            "answer0302": "Au sein de chaque organisation, il y a du personnel clé qui a certaines fonctions, rôles et responsabilités autorisés spécifiques à leur poste. ",
            "answer0303": "La mise en place d'un ordre de succession facilitera une transition ordonnée et prédéfinie des postes de direction au sein de votre organisation. ",
            "answer0304": "Astuce : Des successions par-identifiées sont créées pour vous, et vous n'avez qu'à affecter du personnel. ",
            "question04": "Quelles sont les fonctions essentielles?"
        },
        "planTemplates": {
            "header": "Modèles de plans",
            "continuityOfOperationPlanning": "Planification de la continuité des opérations (COOP)",
            "emergencyOperations": "Plan d'opérations d'urgence (EOP)",
            "continuityOfGovernment": "Continuité du gouvernement (COG)",
            "disasterRecovery": "Planification de reprise après sinistre (DRP)",
            "businessContinuity": "Planification de la continuité des activités (PCA)",
            "lossOfServicesEmergency": "Plan d'opération d'urgence en cas de perte de services (LSEOP)",
            "tabInfo": "Ci-dessous, vous pouvez voir vos modèles actuellement utilisés.",
            "newTemplates": "Nouveaux modèles",
            "assignedTemplates": "Modèles utilisés",
            "unAssignedTemplates": "Modèles non utilisés",
            "draftTemplates": "Brouillons non publiés",
            "confirmDeleteHeader": "Supprimer {{ name }}",
            "confirmDeleteVerbiage": "Voulez-vous vraiment supprimer ce modèle du système?",
            "successOnDelete": "Le modèle de plan a été supprimé avec succès",
            "failedToDelete": "Le modèle de plan a été supprimé sans succès",
            "exportTemplate": "Exportation d'un modèle de plan...",
            "successOnExport": "Le modèle de plan est exporté",
            "failedOnExport": "Échec de l'exportation du modèle de plan",
            "importTemplate": "Modèle d'importation",
            "uploadPlanTemplate": "Télécharger le modèle de plan",
            "dropFileInstruction": "Déposez ou cliquez pour sélectionner un fichier de modèle de plan",
            "loadingTemplate": "Importation d'un modèle de plan",
            "successOnImport": "Le modèle de plan est importé",
            "failedOnImport": "Échec de l'importation du modèle de plan",
            "successOnSave": "Le modèle de plan a été enregistré avec succès",
            "failedToSave": "Le modèle de plan a été supprimé sans succès",
			"duplicateTemplateName": "Un modèle de plan portant le même nom existe déjà, veuillez choisir un nom différent.",
            "createDuplicate": "Créer un doublon",
            "duplicateTemplate": "Dupliquer le modèle",
            "assignDefaultTemplatesDescription": [
                "Créer un plan de {{entityType}} par défaut",
                "(sera attribué à tous les {{entityType}}s non attribués)",
                "(sera attribué à tous les {{entityType}} non attribués)"
            ],
            "assignTemplate": "Attribuer un Modèle",
            "availableDepartments": "Départements Disponibles",
            "departmentsAssignedSuccessfully": "Départements attribués avec succès",
            "departmentsAssignedUnsuccessfully": "Départements assignés sans succès",
            "lastUpdated": "Dernière mise à jour: {{name}} - {{date}}",
            "availableCompanies": "Entreprises Disponibles",
            "availableLocations": "[[Locations]] Disponibles",
            "selectedCompanies": "Entreprises Sélectionnées",
            "selectedLocations": "[[Locations]] Sélectionnés",
            "selectedDepartments": "Départements Sélectionnés",
            "targetsAssignedSuccessfully": "{{targetType}} attribués avec succès",
            "targetsAssignedUnsuccessfully": "{{targetType}} assignés sans succès",
            "availableTechnologies": "[[Technologies]] disponibles",
            "selectedTechnologies": "[[Technologies]] sélectionnées",
            "successOnDuplicate": "Le modèle de plan a été dupliqué avec succès",
            "failedToDuplicate": "Le modèle de plan a été dupliqué sans succès",
            "templateDetails": "Détails du modèle",
            "setTemplateAsDefault": "Définir le modèle par Défaut",
            "setTemplateDefaultConfirmation": "Ce modèle sera désormais utilisé comme modèle par défaut pour {{planName}}",
            "promoteModalHeader": "Promouvoir le Modèle",
            "promoteModalBody": "Êtes-vous sûr de vouloir promouvoir ce modèle en modèle système pour ce secteur ? <br/> {{industryName}} type d'industrie.",
            "planLevel": "Niveau du forfait",
            "dataTables": "Tableaux de données",
            "insertTable": "Insérer un tableau",
            "selectModalVerbiage": "Sélectionnez ci-dessous pour afficher et insérer votre tableau de données.",
            "planLevels": {
                "department": "[[Department]]",
                "location": "[[Location]]",
                "company": "Entreprise",
                "companyITDR": "Entreprise",
                "continuityOfGovernment": "Organisation (COG)",
                "continuityOfOperationPlanning": "[[Department]]",
                "emergencyOperations": "Opérations d'Urgence",
                "technology": "[[Technology]]"
            },
            "enterName": "Entrez le nom ici",
            "duplicatePublishedWarning": "Cela dupliquera la version publiée de ce modèle. Tout projet de modification ne sera pas inclus.",
            "unpublishedChanges": "Modifications non publiées"
        },
        "planBuilder": {
            "planTemplate": {
                "draftVersion": "Brouillon",
                "planTemplateTargetType": "Modèle de plan de {{planTargetType}}",
                "discard": "Ignorer toutes les modifications / Revenir à la dernière publication",
                "planTemplate": "Modèle de plan",
                "publishTemplate": "Publier le modèle",
                "createNewSection": "Créer une nouvelle rubrique",
                "sectionSettings": "[[Settings]] de section",
                "saveSection": "Enregistrer la section",
                "removeSection": "Supprimer la rubrique",
                "removeSectionDescription": "Voulez-vous vraiment supprimer cette section ?",
                "unsavedChanges": "Modifications non enregistrées",
                "unsavedChangesDescription": "Vous avez des changements non enregistrés. ",
                "revertDescription": "Voulez-vous vraiment rétablir ce brouillon à l'original et recommencer ? Cette opération est irréversible ?",
                "publishDescription": "Êtes-vous sûr de vouloir publier ?",
                "pageBreakTrueLabel": "Commencer sur une nouvelle page",
                "pageBreakFalseLabel": "Continuer sous la dernière section",
                "noSections": "Aucune rubrique n'existe",
                "addNewSection": "Ajouter une nouvelle rubrique",
                "insertTag": "Insérer une balise",
                "insertTagDescription": "Sélectionnez parmi les balises ci-dessous à insérer.",
                "insertElementDescription": "Sélectionnez ci-dessous pour afficher et insérer votre tableau de données.",
                "tags": {
                    "departmentLead": "Chef de Département",
                    "departmentLeadAlternate": "Chef de service suppléant",
                    "departmentName": "Nom du Département",
                    "departmentDescription": "Description du Département",
                    "departmentDueDate": "Date d'échéance du Département",
                    "companyAddress": "Adresse de la Société",
                    "companyPhone": "Numéro de Téléphone Principal de l'Entreprise",
                    "companyName": "Nom de l'Entreprise",
                    "departmentLeadDescription": "Le chef de département.",
                    "departmentLeadAlternateDescription": "Le chef de département suppléant.",
                    "departmentNameDescription": "Le nom du département.",
                    "departmentDueDateDescription": "La date limite pour que le chef d'équipe finisse de définir son département.",
                    "companyAddressDescription": "L'adresse où se trouve le bureau principal.",
                    "companyPhoneDescription": "Le numéro de téléphone principal de l'entreprise.",
                    "companyNameDescription": "Le nom de l'entreprise.",
                    "departmentDescriptionDescription": "La description du département",
                    "eMALocation": "Emplacement EMA",
                    "eMALocationDescription": "Où se trouve votre Agence de gestion des urgences.",
                    "eOCLocation": "Emplacement du COU",
                    "eOCLocationDescription": "Où se trouve votre centre des opérations d’urgence.",
                    "massCare": "Soins de Masse",
                    "massCareDescription": "Quel organisme / département est responsable des soins de masse et de l’alimentation.",
                    "trainingTesting": "Tests de Formation",
                    "trainingTestingDescription": "Quel organisme ou ministère est responsable de votre plan des opérations d’urgence.",
                    "unmetNeeds": "Besoins non Satisfaits",
                    "unmetNeedsDescription": "L’organisme ou le ministère qui coordonne est responsable des besoins non satisfaits.",
                    "supersession": "Remplacement",
                    "supersessionDescription": "Renseignements sur la supersession du plan des opérations d’urgence.",
                    "planGenerationDate": "Date de génération du plan",
                    "planGenerationDateDescription": "Sortie de la date et de l’heure de génération du document de plan.",
                    "planTemplatePublished": "Modèle de plan publié",
                    "planTemplatePublishedDescription": "Affichage du date et de l’heure de la dernière publication du modèle de plan.",
                    "technologyERT": "Temps de récupération estimé",
                    "technologyERTDescription": "Temps de récupération estimé attribué dans le runbook [[technology]].",
                    "technologyName": "Nom de la [[Technology]]",
                    "technologyNameDescription": "Le nom de la [[technology]].",
                    "technologyOwnerAlternate": "Substitut de la propriétaire de la [[technology]]",
                    "technologyOwnerAlternateDescription": "Le nom de l’autre propriétaire de la [[technology]].",
                    "technologyOwner": "Propriétaire de la [[Technology]]",
                    "technologyOwnerDescription": "Le nom du propriétaire principal de la [[technology]].",
                    "technologyDueDate": "Date d’échéance de la [[Technology]]",
                    "technologyDueDateDescription": "Date d’échéance pour que la propriétaire de la [[technology]] termine la définition de la [[technology]]."
                },
                "elements": {
                    "customFields": "Questions et réponses stratégiques",
                    "vendors": "Fournisseurs ou fournisseurs clés",
                    "assessments": "Évaluations des Risques",
                    "criticalDates": "Dates critiques",
                    "portalTeams": "[[Teams]] du [[department]]",
                    "rTO": "Stratégies de Récupération des [[process]]",
                    "attachments": "Pièces jointes",
                    "technologies": "[[Technologies]]",
                    "processDependencies": "[[Process]] Dépendances",
                    "employeeDeployment": "Déploiement des employés",
                    "subjectMatterExperts": "Membres de l’[[Team]] de Continuité",
                    "customFieldsDes": "Toutes les questions et réponses sur la stratégie de processus du [[department]] incluent la stratégie associée.",
                    "vendorsDes": "Tous les [[process]] du [[department]], y compris le fournisseur, les dates de contrat, les adresses et les contacts.",
                    "assessmentsDes": "Toutes les menaces pour le ministère issues de la dernière évaluation des risques réalisée pour le ministère. ",
                    "criticalDatesDes": "Tous traitent les dates critiques dans tous les [[department]]s.",
                    "portalTeamsDes": "Un tableau qui répertorie toutes les [[teams]] associées au [[department]], inclut leurs membres ou postes et les responsabilités correspondantes.",
                    "rTODes": "Répertorie tous les [[departments]] et leurs [[processes]], les tableaux sont regroupés par stratégie de continuité.",
                    "attachmentsDes": "Toutes les pièces jointes aux [[process]] du [[department]] incluent le temps de récupération du [[process]]. ",
                    "technologiesDes": "Tous les [[department]]s Application/[[Technology]] incluent l'objectif de point de récupération (RPO), les [[process]] associés et le temps de récupération.",
                    "processDependenciesDes": "Tous les [[Process]] de [[department]] qui ont une dépendance incluent l'objectif de point de récupération (RPO), le nom du [[Process]] dépendant et l'objectif de temps de récupération (RTO) du [[process]] dépendant.",
                    "employeeDeploymentDes": "Tous les employés de l'entreprise affectés à un [[process]] de service ordonné par RTO. ",
                    "subjectMatterExpertsDes": "Un tableau qui répertorie tous les experts en la matière et leurs coordonnées ainsi que les [[process]] qu'ils soutiennent au sein du [[department]].",
                    "departments": "[[Departments]]",
                    "departmentsDes": "Tous les [[Departments]] de l'entreprise avec leur description",
                    "equipment": "[[Equipment]] Dépendances",
                    "equipmentDes": "Tous les [[Equipment]] de l'entreprise requis pour la continuité des [[Process]] dans tous les [[departments]] incluent le temps de récupération de [[Process]]",
                    "teamLeaders": "Chefs de [[Department]]",
                    "teamLeadersDes": "Tous les chefs de [[department]] et leurs coordonnées",
                    "businessContinuityManagers": "Responsables de la continuité des activités",
                    "businessContinuityManagersDes": "Tous les responsables de la continuité d’activité de l’entreprise et leurs coordonnées",
                    "delegationOfAuthority": "Délégations de pouvoirs",
                    "delegationOfAuthorityDes": "Ce tableau répertoriera toutes les délégations de pouvoirs au sein du [[department]], les déclencheurs, les limitations, y compris les membres actifs et délégués.",
                    "ordersOfSuccession": "Ordres de succession",
                    "ordersOfSuccessionDes": "Ce tableau répertoriera tous les ordres de succession au sein du [[department]].",
                    "maximumTolerableDowntime": "Temps d’arrêt maximal tolérable",
                    "maximumTolerableDowntimeDes": "Le tableau des MTD (Temps d’arrêt maximal tolérable) dans les [[processes]] de continuité d’activité.",
                    "processLocations": "[[Process]] [[Locations]]",
                    "processLocationsDes": "Ce tableau répertorie tous les [[processes]] au sein du [[department]] et les [[locations]] où elles sont effectuées.",
                    "annexes": "Annexes",
                    "annexesDes": "Les annexes définies dans votre plan d’opérations d’urgence.",
                    "emergencyOperations": {
                        "portalTeams": "Réponse [[Teams]]",
                        "portalTeamsDes": "Ce tableau dresse la liste de toutes les [[teams]] d’intervention du Plan des opérations d’urgence, y compris les postes, les coordonnées des membres et leurs responsabilités."
                    },
                    "iTDRTechnologyAttachments": "Pièces jointes",
                    "iTDRTechnologyAttachmentsDes": "Liste complète de tous les accessoires pour la [[technology]] sélectionnée.",
                    "iTDRTechnologySupportingVendors": "Soutenir les fournisseurs",
                    "iTDRTechnologySupportingVendorsDes": "Un tableau répertorie tous les fournisseurs qui prennent en charge la [[technology]] sélectionnée, y compris les coordonnées du fournisseur et les informations sur les SLA technologiques.",
                    "iTDRTechnologyTaskSets": "Ensembles de tâches",
                    "iTDRTechnologyTaskSetsDes": "Un tableau répertorie les tâches pour la récupération de la [[technology]] sélectionnée regroupées par ensemble de tâches, y compris le temps attribué, les équipes, les membres et les tâches.",
                    "iTDRTechnologyDetails": "Détails de la [[technology]]",
                    "iTDRTechnologyDetailsDes": "Un tableau répertorie toutes les questions personnalisées de type de [[technology]], leurs réponses et les instructions supplémentaires.",
                    "iTDRUpstreamTechnologyDependencies": "Dépendances technologiques en amont",
                    "iTDRUpstreamTechnologyDependenciesDes": "Répertorie toutes les [[technologies]] dépendantes en amont de cette [[technology]], l’objectif de point de récupération (RPO), le nom de la technologie et l’objectif de point de récupération (RPO).",
                    "iTDRTechnologyDisasterRecoveryTeams": "Équipes de reprise après sinistre",
                    "iTDRTechnologyDisasterRecoveryTeamsDes": "Un tableau qui répertorie toutes les équipes de reprise après sinistre associées à la [[technology]], comprend les noms des membres, les postes et les responsabilités correspondantes.",
                    "iTDRCompanyTechnologiesAttachments": "Pièces jointes",
                    "iTDRCompanyTechnologiesAttachmentsDes": "Liste de toutes les pièces jointes technologiques au sein de votre organisation, y compris le nom de la [[technology]] et le nom de la pièce jointe.",
                    "iTDRCompanytechnologiesByVendors": "[[Technology]] par fournisseur",
                    "iTDRCompanytechnologiesByVendorsDes": "Un tableau répertorie tous les fournisseurs qui prennent en charge une [[technology]] au sein de l’organisation.",
                    "iTDRCompanyTechnologiesByOwner": "[[Technology]] par propriétaire",
                    "iTDRCompanyTechnologiesByOwnerDes": "Tableau répertoriant toutes les [[technologies]] avec des plans de récupération, regroupés par propriétaires de [[technologies]] respectifs.",
                    "iTDRCompanyDisasterRecoveryTeams": "Équipes de reprise après sinistre",
                    "iTDRCompanyDisasterRecoveryTeamsDes": "Ce tableau dressera la liste de toutes les Équipes de reprise après sinistre au sein de l’organisation, y compris les postes, les coordonnées des membres et leurs responsabilités."
                }
            }
        },
        "bcPlanBuilder": "Créateur de plans",
        "teams": {
            "title": "Équipes",
            "titleVerbiage": "Ci-dessous les équipes d'organisation.",
            "titleVerbiage2": "Vous trouverez ci-dessous les équipes qui ont été associées à votre département.",
            "addTeam": "Nouvelle Équipe",
            "linked": "Lié",
            "confirmDeleteHeader": "Supprimer {{ name }}",
            "confirmDeleteVerbiage": "Voulez-vous vraiment supprimer cette équipe?",
            "successOnDelete": "L'équipe a été supprimée avec succès",
            "failedToDelete": "L'équipe a été supprimée sans succès",
            "assignMembers": "Attribuer des membres",
            "newTeam": "Nouvelle Équipe",
            "createNew": "Créer un Nouveau",
            "associateTeam": "Associer une Équipe",
            "selectATeam": "Sélectionnez une équipe",
            "members": "Membres",
            "addMembers": "Ajouter des Membre(s)",
            "responsibilities": "Responsabilités",
            "noMembersAdded": "Aucun membre ajouté",
            "addMember": "Ajouter un Membre",
            "position": "Position",
            "positionPlaceholder": "Entrez la position ici",
            "responsibilitiesPlaceholder": "Entrez les responsabilités ici",
            "assignMember": "Attribuer un membre",
            "enterTeamName": "Entrez le nom de l'équipe ici",
            "TeamDetailsTitle": "Détails des Équipes",
            "teamName": "Nom de l'équipe",
            "successOnSave": "L'équipe a été sauvée avec succès",
            "failedToSave": "L'équipe a été sauvée sans succès",
            "noDescription": "Pas de description",
            "slideOut": {
                "team": "Équipe",
                "teamName": "Entrez le nom officiel de l'équipe au sein de votre organisation. Exemples: \"Équipe de gestion des incidents\", \"Équipe de gestion de crise\" ou \"Équipe de planification\".",
                "teamDescription": "Fournissez un bref résumé des rôles et responsabilités de l’équipe au sein de votre organisation. Incluez les fonctions clés, les objectifs principaux et tous les domaines d’intérêt critiques.",
                "members": "Membres",
                "memberName": "Nom du membre de l'équipe",
                "position": "Le travail ou la position du membre au sein de l'équipe.",
                "responsibilities": "La responsabilité du membre ou du poste au sein de l'équipe et du groupe."
            },
            "createPosition": {
                "confirmDeleteVerbiage": "Voulez-vous vraiment supprimer ce poste?",
                "successOnDeletePosition": "Le poste a été supprimé avec succès",
                "failedToDeletePosition": "Le poste a été supprimé sans succès",
                "searchUserLabel": "Rechercher un membre de l'équipe",
                "resultLabel": "Sélectionnez un membre",
                "currentUserLabel": "Membre actuel de l'équipe"
            },
            "teamLinkedSuccessfully": "Équipe partenaire performante",
            "removeTeamConfirmationHeader": "Supprimer l'équipe",
            "removeTeamConfirmationMessage": "Voulez-vous vraiment supprimer cette équipe du service?",
            "teamRemovedSuccessfully": "Équipe supprimée avec succès",
            "teamRemovedUnsuccessfully": "Équipe supprimée sans succès",
            "positions": "Postes",
            "titleVerbiage3": "Veuillez ajouter les équipes par défaut pour ce secteur.",
            "addPositions": "Ajouter des Postes",
            "addPosition": "Ajouter un Poste",
            "noPositionsAdded": "Aucun poste ajouté",
            "organizationalTeams": "Équipes organisationnelles",
            "organizationalTeamsDescription": "La création d'équipes d'intervention de continuité est essentielle pour que les organisations gèrent efficacement les crises et maintiennent les opérations en cas de perturbations inattendues. ",
            "departmentalTeamsDescription": "Gérer les équipes qui soutiennent ce [[Department]]. ",
            "technologyTeamsDescription": "Les équipes de reprise après sinistre jouent un rôle essentiel dans la gestion et l'atténuation des perturbations des systèmes et services informatiques.",
            "departmentalTeams": "Equipes Départementales",
            "technologyTeams": "Équipes de reprise après sinistre",
            "organizationalTeamsSecondaryDescription": "Ajoutez vos équipes organisationnelles ici.",
            "departmentalTeamsSecondaryDescription": "Ajoutez vos équipes [[Department]] ici.",
            "technologyTeamsSecondaryDescription": "Identifiez ici vos équipes de reprise après sinistre",
            "associateModal": {
                "title": "Attribuer une Équipe",
                "switchDes": "Attribuer à tous les [[Departments]]",
                "assignToAllSuccessfully": "L'équipe a été affectée à tous les [[departments]] avec succès",
                "assignToAllUnsuccessfully": "L'équipe a été affectée à tous les [[departments]] sans succès",
                "setTeamAsDefault": "Attribuez une équipe à tous les [[Departments]].",
                "setTeamDefaultConfirmation": "Êtes-vous sûr de vouloir affecter cette équipe à chaque [[department]] ?"
            }
        },
        "agencies": "Agences",
        "annex": {
            "annexBuildManage": "Construire",
            "annexSecondaryDescription": "Sélectionnez les annexes que vous souhaitez inclure dans votre plan",
            "addFromLibrary": "Ajouter depuis la bibliothèque",
            "creatFromScratch": "Créer à partir de zéro",
            "addAnnexDescription": "Ajoutez une annexe pour commencer",
            "annexTitle": "Titre de l'annexe",
            "selectAnnex": "Sélectionnez l'annexe",
            "selectAnnexSecondaryDescription": "Sélectionnez ci-dessous les annexes que vous souhaitez utiliser dans votre plan.",
            "annexes": "Annexes",
            "edit": "Modifier l'annexe",
            "trackDescription": "Recherchez et complétez vos pièces jointes ici.",
            "dueDateRequired": "Date d'échéance requise pour toutes les annexes.",
            "removeAnnex": "Supprimer l'annexe",
            "removeAnnexDescription": "Êtes-vous sûr de vouloir supprimer cette annexe ?",
            "manageLibrary": "Gérer la bibliothèque",
            "selectAnnexesToDeleteDescription": "Sélectionnez ci-dessous les annexes que vous souhaitez supprimer de la bibliothèque.",
            "removeSystemAnnex": "Supprimer l'annexe de la bibliothèque",
            "removeSystemAnnexesDescription": "Etes-vous sûr de vouloir supprimer ces annexes de la bibliothèque??",
            "removeSystemAnnexDescription": "Êtes-vous sûr de vouloir supprimer cette annexe système de la bibliothèque ? Ça ne peut pas être annulé.",
            "setDueDate": {
                "header": "Date d’échéance",
                "body": "Certains annexes ont déjà une date d’échéance, souhaitez-vous fixer cette date d’échéance {{dueDate}} pour tous les annexes?",
                "allProcesses": "Oui",
                "newProcesses": "Non",
                "msgConfirmBody": "Êtez-vous sûr(e) de vouloir fixer cette nouvelle date d’échéance {{dueDate}} pour tous les annexes?"
            },
            "configureAnnex": {
                "label": "Configurer l'annexe",
                "removeAgency": "Supprimer l'agence",
                "removeAgencyDescription": "Êtes-vous sûr de vouloir supprimer cette agence de ce rôle? Les actions attribuées à cette agence resteront dans la liste d'actions.",
                "taskAgenciesSecondaryDescription": "Identifiez vos agences ici.",
                "annexSectionsDescription": "Vous trouverez ci-dessous les sections de contenu qui seront incluses dans votre annexe. Vous pouvez modifier, réorganiser ou choisir de ne pas inclure.",
                "saveAnnexSystemLibrary": "Enregistrer l'annexe dans la bibliothèque système",
                "removeAction": "Supprimer l'action",
                "removeActionDescription": "Êtes-vous sûr de vouloir supprimer cette action ?",
                "responsibilitiesSecondaryDescription": "Identifiez ici les rôles et responsabilités de votre agence.",
                "addAction": "Ajouter une action",
                "saveNewSystemAnnex": "Êtes-vous sûr de vouloir enregistrer cette annexe dans la bibliothèque système ?",
                "overWriteSystemAnnex": "Etes-vous sûr de vouloir écraser une annexe existante dans la bibliothèque système avec cette version ? ",
                "saveSystemAnnexSuccess": "Annexe enregistrée dans la bibliothèque système",
                "saveSystemSuccessFail": "Échec de l'enregistrement de l'annexe dans la bibliothèque système",
                "sectionsDescription": "Documentez l’objectif, la portée, les concepts, la gestion financière et les autorités de référence qui sont essentiels à cette annexe.",
                "agenciesDescription": "Documentez les agences assignées qui remplissent les rôles de coordination, principaux et de soutien dans cette annexe.",
                "responsibilitiesDescription": "Identifier et documenter les responsabilités de l'agence au cours des multiples phases de cette annexe."
            }
        },
        "supersession": {
            "supersession": "Remplacement",
            "supersessionSecondaryDescription": "Une déclaration de remplacement doit préciser si ce plan ou tout document contenu dans ce plan remplacera tout élément antérieur.",
            "supersessionSaveSuccessfully": "Dépassement enregistré",
            "supersessionSaveUnsuccessfully": "Échec de l'enregistrement du remplacement",
			"noSupersession": "Pas de remplacement"
        },
        "generalQuestions": {
            "generalQuestions": "Questions générales",
            "description": "Vous répertorierez également les entités à partir des informations que vous saisissez dans votre base de données de contacts, dans les parties départements et installations de l'outil.",
            "what": "Quoi",
            "whatDescription": "Attribuez ici vos agences appropriées",
            "where": "Où",
            "whereDescription": "Attribuez ici vos emplacements appropriés",
            "assignedAgency": "Agence assignée",
            "assignAgency": "Attribuer une agence",
            "assignedLocation": "Emplacement attribué",
            "assignLocation": "Attribuer un emplacement",
            "questions": {
                "massCare": "Soins de masse",
                "massCareDes": "Quelle agence/département responsable des soins de masse",
                "unmet": "Besoins non satisfaits",
                "unmetDes": "Quelle agence/département coordonne les besoins non satisfaits ?",
                "EOC": "COU",
                "EOCDes": "Où se trouvent vos centres d’opérations d’urgence (COU) ?",
                "EMA": "EMA",
                "EMADes": "Où se trouve votre agence de gestion des urgences (EMA) ?",
                "training": "Tests et formation",
                "trainingDes": "Quelle agence/département est responsable de la formation ?"
            },
            "saveSuccess": "Réponse aux questions enregistrée",
            "saveFailed": "Échec de l'enregistrement de la réponse aux questions"
        },
        "customFields": {
            "customFieldTitle": "Questions sur la stratégie du plan de continuité",
            "fieldName": "Nom de champ",
            "fieldType": "Type de question",
            "isRequired": "Obligatoire",
            "fieldTypes": {
                "5f31785c-4d3c-4d68-a834-da37c2bc0a76": "Numérique",
                "0f6f2372-1e4c-4d67-ad13-615a0540488c": "Texte court",
                "b9847d16-19b5-4ed4-a4e3-d7f07533ab5a": "Texte long",
                "d8bd3d80-d25d-4056-8754-a740f2396792": "Vrai ou faux",
                "aad6f035-05f9-42d3-b6ea-682371340875": "Sélectionner un",
                "76c83029-8439-42ad-90e6-7f0ff54b732c": "Sélectionner plusieurs",
                "1eccbe40-1b3b-461b-95df-35f1fe6a8af2": "Date/heure"
            },
            "isRequiredOptions": {
                "true": "Oui",
                "false": "Non"
            },
            "displaySequence": "Séquence d’affichage",
            "fieldDomain": "Options",
            "success": {
                "deleteCustomFieldSuccess": "Suppression du champ personnalisé effectuée avec succès",
                "saveCustomFieldsDisplayOrderSuccess": "Sauvegarde de l’ordre d’affichage effectuée avec succès",
                "saveCustomFieldSuccess": "Sauvegarde du champ personnalisé effectué avec succès"
            },
            "errors": {
                "getCustomFieldsError": "Échec du chargement des champs personnalisés",
                "saveCustomFieldsDisplayOrderError": "Erreur lors de la sauvegarde du nouvel ordre d’affichage",
                "deleteCustomFieldError": "Erreur lors de la suppression du champ personnalisé",
                "customFieldWithSameNameError": "Erreur lors de la sauvegarde du champ personnalisé avec le même nom",
                "saveCustomFieldError": "Erreur de la sauvegarde du champ personnalisé",
                "customFieldOptionWithSameNameError": "Erreur lors de la sauvegarde du champ personnalisé avec des noms d’options en double",
                "customFieldTypeSelectOneWithoutOptions": "Des options sont requises pour les champs personnalisés « Sélectionner un »",
                "customFieldsInvalid": "Veuillez consulter les champs personnalisés"
            },
            "help": {
                "fieldNameTooltip": "Nom d’étiquette du champ personnalisé",
                "fieldTypeTooltip": "Type de données à collecter avec ce champ personnalisé",
                "isRequiredTooltip": "Désigner si ce champ personnalisé est obligatoire",
                "editCustomFieldInstructions": "Modifiez le type de données à collecter et si vous souhaitez ou non exiger ce champ personnalisé.",
                "addCustomFieldInstructions": "Ajouter un champ personnalisé pour collecter des informations.",
                "optionsTooltip": "Liste des options dans l’ordre d’affichage."
            },
            "editCustomField": "Modifier les champs personnalisés existants",
            "addCustomField": "Ajouter un nouveau champ personnalisé",
            "newCustomField": "Nouveau champ personnalisé",
            "confirmRemoveCustomField": {
                "header": "Éliminer le champ personnalisé",
                "body": "Êtes-vous sûr(e) de vouloir éliminer ce champ personnalisé?",
                "text": "Éliminer"
            },
            "confirmSaveCustomField": {
                "header": "Sauvegarder le champ personnalisé",
                "body": "Êtes-vous sûr(e) de vouloir sauvegarder ce champ personnalisé",
                "text": "Sauvegarder"
            },
            "customFields": "Champs personnalisés",
            "optionName": "Nom de l’option",
            "addOption": "Ajouter une option",
            "default": "Par défaut",
            "restore": "Restaurer",
            "options": "Options",
            "makeDefault": "Rendre par défaut",
            "saveDisplayOrder": {
                "cancelDisplayOrder": "Annuler la commande",
                "saveDisplayOrder": "Sauvegarder la commande",
                "header": "Sauvegarder le nouvel ordre d’affichage",
                "body": "Êtes-vous sûr(e) de vouloir modifier l’ordre dans lequel les champs sont affichés?",
                "text": "Sauvegarder l’ordre d’affichage"
            },
            "customFieldsActivity": "Activité des champs personnalisés",
            "deprecatedOption": "Option obsolète",
            "customFieldsDescription": "Des questions personnalisées seront ajoutées au flux de votre planificateur et les réponses s'afficheront dans votre plan et votre gestionnaire d'incidents.",
            "businessContinuity": "Continuité de l'activité",
            "disasterRecovery": "reprise après sinistre",
            "bcCustomQuestionsTitle": "Questions personnalisées sur la continuité des activités",
            "bcCustomQuestionsDescription": "Gérez ici vos questions douanières relatives à la continuité des activités.",
            "drCustomQuestionsTitle": "Questions personnalisées sur la reprise après sinistre",
            "drCustomQuestionsDescription": "Gérez ici vos questions personnalisées de reprise après sinistre par type de [[technology]].",
            "technology": "[[Technology]]",
            "technologyTypes": "Types de [[Technology]]",
            "continuityOfOperationPlanning": "Continuité",
            "customQuestionsDescription": "Gérez ici vos questions de continuité douanière."
        },
        "bulkUpload": {
            "vendor": {
                "msgBulkUploadInstructions": "Vous souhaitez ajouter ou modifier plusieurs fournisseurs ? ",
                "lblAddMultipleVendorsHeader": "Ajout de plusieurs nouveaux fournisseurs",
                "lblAddMultipleVendorsDetail": "Téléchargez cette feuille de calcul contenant uniquement les informations d'en-tête et remplissez les informations de votre nouveau fournisseur, un fournisseur par ligne, avant de l'enregistrer au format .CSV sur votre ordinateur.",
                "btnBlankVendorsFile": "Fichier de fournisseur vierge",
                "lblEditMultipleVendorsHeader": "Modifier ou supprimer plusieurs fournisseurs",
                "lblEditMultipleVendorsDetail": "Téléchargez cette feuille de calcul contenant les informations de votre fournisseur actuel et modifiez-la si nécessaire avant de l'enregistrer au format .CSV sur votre ordinateur.",
                "btnCurrentVendors": "Fournisseurs actuels",
                "lblEditMultipleVendorsNotes": "REMARQUE : Pour supprimer des fournisseurs, modifiez leur statut sur Supprimer. ",
                "fileName": "BulkVendorUpload.csv",
                "msgVendorExportSuccessful": "L'exportation des fournisseurs s'est terminée avec succès.",
                "errorVendorExportFailed": "Impossible d'exporter un fichier de fournisseurs.",
                "msgUploadVendorsSuccess": "Téléchargement groupé pour les fournisseurs réussi.",
                "lblNoFileSelected": "Aucun fichier spécifié."
            },
            "vendorContact": {
                "msgBulkUploadInstructions": "Vous avez plusieurs contacts que vous souhaitez ajouter ou modifier ? ",
                "lblAddMultipleContactsHeader": "Ajout de plusieurs nouveaux contacts",
                "lblAddMultipleContactsDetail": "Téléchargez cette feuille de calcul contenant uniquement les informations d'en-tête et remplissez les informations de votre nouveau contact, un contact par ligne, avant de l'enregistrer au format .CSV sur votre ordinateur.",
                "btnBlankContactsFile": "Fichier de contacts vierge",
                "lblEditMultipleContactsHeader": "Modifier ou supprimer plusieurs contacts",
                "lblEditMultipleContactsDetail": "Téléchargez cette feuille de calcul contenant vos coordonnées actuelles et modifiez-la si nécessaire avant de l'enregistrer au format .CSV sur votre ordinateur.",
                "btnCurrentContacts": "Contacts actuels",
                "lblEditMultipleContactsNotes": "REMARQUE : Pour supprimer des contacts, modifiez leur statut sur Supprimer. ",
                "fileName": "BulkVendorContactUpload.csv",
                "msgContactExportSuccessful": "L'exportation des contacts s'est terminée avec succès.",
                "errorContactExportFailed": "Impossible d'exporter un fichier de contacts.",
                "msgUploadContactsSuccess": "Téléchargement groupé des contacts réussi.",
                "lblNoFileSelected": "Aucun fichier spécifié."
            },
            "agency": {
                "msgBulkUploadInstructions": "Vous souhaitez ajouter ou modifier plusieurs agences ? ",
                "lblAddMultipleHeader": "Ajout de plusieurs nouvelles agences",
                "lblAddMultipleDetail": "Téléchargez cette feuille de calcul contenant uniquement les informations d'en-tête et remplissez les informations de votre nouvelle agence, une agence par ligne, avant de l'enregistrer au format .CSV sur votre ordinateur.",
                "btnBlankFile": "Fichier d'agence vierge",
                "lblEditMultipleHeader": "Modifier ou supprimer plusieurs agences",
                "lblEditMultipleDetail": "Téléchargez cette feuille de calcul contenant les informations actuelles de votre agence et modifiez-la si nécessaire avant de l'enregistrer au format .CSV sur votre ordinateur.",
                "btnCurrent": "Agences actuelles",
                "lblEditMultipleNotes": "REMARQUE : Pour supprimer des agences, changez leur statut en Supprimer. ",
                "fileName": "BulkAgencyUpload.csv",
                "msgExportSuccessful": "L'exportation des agences s'est terminée avec succès.",
                "errorExportFailed": "Impossible d'exporter un fichier d'agences.",
                "msgUploadSuccess": "Téléchargement groupé réussi pour les agences.",
                "lblNoFileSelected": "Aucun fichier spécifié."
            },
            "agencyContact": {
                "msgBulkUploadInstructions": "Vous avez plusieurs contacts que vous souhaitez ajouter ou modifier ? ",
                "lblAddMultipleHeader": "Ajout de plusieurs nouveaux contacts",
                "lblAddMultipleDetail": "Téléchargez cette feuille de calcul contenant uniquement les informations d'en-tête et remplissez les informations de votre nouveau contact, un contact par ligne, avant de l'enregistrer au format .CSV sur votre ordinateur.",
                "btnBlankFile": "Fichier de contacts vierge",
                "lblEditMultipleHeader": "Modifier ou supprimer plusieurs contacts",
                "lblEditMultipleDetail": "Téléchargez cette feuille de calcul contenant vos coordonnées actuelles et modifiez-la si nécessaire avant de l'enregistrer au format .CSV sur votre ordinateur.",
                "btnCurrent": "Contacts actuels",
                "lblEditMultipleNotes": "REMARQUE : Pour supprimer des contacts, modifiez leur statut sur Supprimer. ",
                "fileName": "BulkVendorContactUpload.csv",
                "msgExportSuccessful": "L'exportation des contacts s'est terminée avec succès.",
                "errorExportFailed": "Impossible d'exporter un fichier de contacts.",
                "msgUploadSuccess": "Téléchargement groupé des contacts réussi.",
                "lblNoFileSelected": "Aucun fichier spécifié."
            }
        },
        "departmentAlternate": {
            "MemberModal": {
                "headerLabel": "Sélectionnez un membre suppléant pour <b>{{name}}</b>",
                "searchUserLabel": "Rechercher un remplaçant",
                "currentUserLabel": "Alternant actuel",
                "resultLabel": "Suppléants"
            },
            "alternateSaveSuccessfully": "Autre enregistré",
            "alternateSaveUnsuccessfully": "Échec de l'enregistrement de l'alternative",
            "alternateRequired": "Le remplaçant est requis",
            "alternate": "Alterner",
            "noAlternate": "Pas d'alternative"
        },
        "technology": {
            "manageTechnology": "[[Technologies]]",
            "technologyVendors": "Fournisseur",
            "technologyDependencies": "[[Technology]] Dépendances",
            "technologyApplicationDetails": "[[Technology]] Détails",
            "technologyTasks": "[[Technology]] Tâches",
            "technologyRecoveryTime": "[[Technology]] Temps de récupération",
            "configureTechnology": "Configurer [[Technology]]",
            "technologyBuildManage": "Créez et gérez vos [[Technologies]]",
            "technologyDescription": "La création et la gestion des [[technologies]] pour un plan de reprise après sinistre informatique implique une approche systématique pour garantir que les [[technologies]] critiques peuvent être restaurées rapidement et efficacement en cas de sinistre.",
            "technologySecondaryDescription": "Identifiez vos [[technologies]] métier critiques en fonction de leur importance pour les fonctions organisationnelles.",
            "searchPlaceHolder": "Rechercher ici",
            "removeTechnology": "Supprimer [[Technology]]",
            "owner": "Propriétaire",
            "addTechnology": "Ajouter [[Technology]]",
            "filterByTier": "Filtrer par niveau",
            "filterByType": "Filtrer par type",
            "manageTechnologies": "Gérer les [[Technologies]]",
            "MemberSelectionModal": {
              "headerLabel": "Attribuer un propriétaire",
              "searchUserLabel": "Sélectionnez les membres",
              "currentUserLabel": "Membre sélectionné",
              "customCurrentUserLabel": "Employés actuellement sélectionnés",
              "resultLabel": "Membres",
              "assignButton": "Ajouter"
            },
            "removeConfirmation1": "Êtes-vous sûr de vouloir supprimer cette [[technology]] de votre planification IT/DR ? ",
            "removeConfirmation2": "Êtes-vous sûr de vouloir supprimer ces [[technologies]] de votre planification IT/DR ? ",
            "unAssignConfirmation": "L’affectation alternative sera effacée en raison de l’absence d’affectation principale. ",
            "unAssignConfirmationHeader": "L'affectation alternative sera supprimée",
            "trackDescription": "Suivez et complétez vos [[technologies]] ici.",
            "noTechnologies": "Non [[Technologies]]",
            "name": "Nom",
            "status": "Statut",
            "dueDate": "Date d'échéance",
            "technologySME": "[[Technology]] Propriétaire",
            "open": "Ouvrir",
            "edit": "Modifier",
            "selectTechnologies": "Sélectionnez [[Technologies]]",
            "vendors": {
              "title": "Fournisseur",
              "description": "Sélectionnez les fournisseurs qui prennent en charge ou fournissent les services requis par cette [[technology]].",
              "secondaryDesc": "Identifiez les fournisseurs de soutien.",
              "addVendor": "Ajouter un fournisseur",
              "confirmDeleteHeader": "Retirer {{ name }}",
              "confirmDeleteVerbiage": "Êtes-vous sûr de vouloir supprimer ce fournisseur de cette [[technology]] ?",
              "vendorDetails": "Détails du fournisseur",
              "lblAdditionalInstructions": "Accords SLA",
              "updateInstructions": "Mettre à jour les accords SLA",
              "instructionsModal": {
                "title": "Détails du SLA",
                "instructionsPLaceHolder": "Entrez les détails du SLA"
              },
              "successOnSave": "Le fournisseur a été enregistré avec succès",
              "failedToSave": "Le vendeur n'a pas été enregistré sans succès",
              "successOnDelete": "Le fournisseur a été supprimé avec succès",
              "failedToDelete": "Le fournisseur n'a pas été supprimé sans succès",
              "modalHeader": "Sélectionnez le fournisseur"
            },
            "taskSets": {
              "title": "Ensembles de tâches",
              "description": "La récupération d'une [[technology]] dans un plan de reprise après sinistre informatique implique une série d'étapes bien définies pour minimiser les temps d'arrêt et \n ",
              "secondaryDesc": "Personnalisez ces étapes en fonction des besoins et des caractéristiques spécifiques de cette [[technology]].",
              "tasks": "Tâches",
              "addTask": "Ajouter une tâche",
              "task": "Tâche",
              "removeTask": "Supprimer la tâche",
              "removeTaskDescription": "Êtes-vous sûr de vouloir supprimer cette tâche ?",
              "removeTaskSet": "Supprimer l'ensemble de tâches",
              "removeTaskSetDescription": "Êtes-vous sûr de vouloir supprimer cet ensemble de tâches ?",
              "successOnSaveTaskSet": "L'ensemble de tâches a été enregistré avec succès",
              "failedToSaveTaskSet": "L'ensemble de tâches n'a pas été enregistré sans succès",
              "successOnDeleteTaskSet": "L'ensemble de tâches a été supprimé avec succès",
              "failedToDeleteTaskSet": "L'ensemble de tâches n'a pas été supprimé",
              "successOnSaveTask": "La tâche a été enregistrée avec succès",
              "failedToSaveTask": "La tâche n'a pas été enregistrée sans succès",
              "successOnDeleteTask": "La tâche a été supprimée avec succès",
              "failedToDeleteTask": "La tâche n'a pas été supprimée",
              "newTaskSet": "Nouvel ensemble de tâches",
              "teams": "Équipes",
              "taskSetRecoveryTime": "Temps de récupération de l'ensemble de tâches",
              "taskSet": "Ensemble de tâches",
              "assignTime": "Attribuer du temps",
              "assignTimeTitle": "Attribuer du temps",
              "assignMember": "Attribuer un membre",
              "assignTeam": "Attribuer une équipe",
              "memberSaveSuccessfully": "Le membre a été enregistré avec succès",
              "memberSaveUnsuccessfully": "Le membre n'a pas été sauvé",
              "memberSaveSuccessfully2": "Les membres ont été enregistrés avec succès",
              "memberSaveUnsuccessfully2": "Les membres ont été sauvés sans succès",
              "teamSaveSuccessfully": "L'équipe a été sauvegardée avec succès",
              "teamSaveUnsuccessfully": "L'équipe a été sauvée sans succès",
              "teamSaveSuccessfully2": "Les équipes ont été sauvées avec succès",
              "teamSaveUnsuccessfully2": "Les équipes ont été sauvées sans succès",
              "assigneeModal": {
                "headerLabel": "Sélectionnez un membre",
                "searchUserLabel": "Rechercher un membre",
                "currentUserLabel": "Membres actuellement affectés",
                "resultLabel": "Membres"
              }
            },
            "upstreamDependencies": {
              "pageDescription": "Comprendre les dépendances en amont permet aux organisations d'évaluer les risques, de hiérarchiser les ressources et d'élaborer des plans d'urgence pour atténuer l'impact des perturbations sur les opérations critiques lors de scénarios de reprise après sinistre.",
              "sub-text": "Identifiez toutes les [[technologies]] dont dépend cette [[technology]].",
              "addDependency": "Nouvelle dépendance",
              "successOnSave": "La dépendance a été enregistrée avec succès",
              "failedToSave": "La dépendance n'a pas été enregistrée sans succès",
              "successOnSavePlural": "Les dépendances ont été enregistrées avec succès",
              "failedToSavePlural": "Les dépendances n'ont pas été enregistrées sans succès",
              "successOnDelete": "La dépendance a été supprimée avec succès",
              "failedToDelete": "La dépendance n'a pas été supprimée",
              "removeDependencyConfirmationModal": {
                "header": "Retirer {{dependencyName}}",
                "body": "Êtes-vous sûr de vouloir supprimer cette dépendance de cette [[technology]] ?"
              }
            },
            "recoveryTime": {
              "title": "Temps de récupération estimé",
              "pageDescription": "Le choix de votre temps de récupération estimé est une décision cruciale pour les organisations qui cherchent à assurer la continuité de leurs activités face à d’éventuelles catastrophes ou perturbations. ",
              "sub-text": "Sélectionnez le temps de récupération estimé pour votre [[technology]].",
              "earliestRTO": "Premier RTO pour cette [[technology]]",
              "estimatedTime": "Votre temps de récupération estimé en fonction de vos tâches",
              "successOnSave": "Le temps de récupération a été enregistré avec succès",
              "failedToSave": "Le temps de récupération n’a pas été enregistré"
            },
            "myTechnologies": {
              "header": "Mes [[Technologies]]",
              "subHeader": "Sélectionnez parmi vos [[technologies]] ci-dessous pour commencer.",
              "description": "Une fois que vous avez sélectionné une [[technology]], vous commencerez à suivre le processus de création de votre Runbook IT/DR pour cette [[technology]]. "
            },
            "backToMyTechnologies": "Retour à mes [[Technologies]]",
            "setDueDate": {
              "header": "Date d'échéance",
              "body": "Certaines [[technologies]] ont déjà une date d'échéance, souhaitez-vous fixer cette date d'échéance <b>{{dueDate}}</b> à toutes les [[technologies]] ?",
              "allTechnologies": "Oui",
              "newTechnologies": "Non",
              "msgConfirmBody": "Êtes-vous sûr de vouloir définir cette nouvelle date d'échéance <b>{{dueDate}}</b> à toutes les [[technologies]] ?"
            },
            "sendEmail": {
              "dueDateRequired": "Date d'échéance requise pour toutes les [[technologies]]"
            },
            "detailsAndAttachments": {
              "label": "[[Technology]] Détails et pièces jointes",
              "additionalDetails": "Détails supplémentaires",
              "enterAdditionalDetails": "Entrez des détails supplémentaires",
              "additionalDetailsSavedSuccessfully": "Détails supplémentaires enregistrés avec succès",
              "customQuestionSavedSuccessfully": "Questions personnalisées enregistrées avec succès"
            },
            "technologyAnalytics": {
              "label": "[[Technology]] Analyses",
              "assignedOwner": "Propriétaire attribué",
              "progress": "Progrès",
              "rpo": "RPO",
              "earliestRto": "Premier RTO",
              "technologyType": "[[Technology]] Type",
              "vendors": "Vendeurs",
              "assignedDisasterRecoveryTeams": "Équipes de récupération assignées",
              "processes": "[[Processus]]"
            }
          },
        "confirmationCodeModal": {
            "header": "Êtes-vous sûr de vouloir commencer à modifier les données système suivantes ?",
            "body": "Tout changement affectera TOUS les portails et clients. ",
            "btn": "Je comprends",
            "codeError": "Échec de la confirmation du code"
        },
        "teamSelectionModal": {
            "header": "Attribuer une équipe",
            "searchLabel": "Sélectionnez une ou plusieurs équipes",
            "searchPlaceholder": "Cherche ici",
            "selectedTeams": "Équipes actuellement sélectionnées",
            "teams": "Équipes"
        },
        "processDependenciesInfoSidebar": {
            "dependencies": "Dépendances",
            "introDesc": "Les dépendances sont essentielles à identifier, car elles mettent en évidence des processus gérés par d'autres départements ou groupes qui sont cruciaux pour la continuité de vos opérations. ",
            "examples": "Exemples",
            "payroll": "Paie (RH)",
            "payrollInfo": "Dépendance : Financement (Finance)",
            "proposalDevelop": "Développement de propositions (ventes)",
            "proposalDevelopInfo": "Dépendance : Packaging (Marketing)",
            "employee": "Intégration des employés (RH)",
            "employeeInfo": "Dépendance : Opérations de sécurité (IT)",
            "conclusion": "En reconnaissant ces dépendances, vous pouvez mieux vous préparer et vous coordonner avec les services concernés pour assurer une continuité transparente de vos processus critiques."
        },
        "criticalDateInfoSidebar": {
            "importantDates": "Dates importantes pour la continuité",
            "introDesc": "Lors de la planification de la continuité, il est crucial d'identifier et d'ajouter des dates critiques à votre processus. ",
            "timeReporting": "Rapports de temps",
            "timeReportingInfo": "Fréquence : hebdomadaire (tous les mercredis)",
            "financialReporting": "Rapports financiers (dépôt trimestriel)",
            "financialReportingInfo": "Fréquence : Trimestriel (dans les 4 jours ouvrables suivant la fin du trimestre)",
            "backup": "Sauvegarde des retenues de paiements à l'IRS",
            "backupInfo": "Fréquence : Bihebdomadaire (tous les vendredis)",
            "irsTax": "Déclaration fiscale IRS pour les fournisseurs (1099-MISC) et retenue à la source (formulaire 945)",
            "irsTaxInfo": "Fréquence : Annuellement (31 janvier, dans les 3 jours ouvrables)",
            "conclusion": "En veillant à ce que ces dates critiques fassent partie de votre planification de continuité, vous contribuerez à maintenir le bon fonctionnement et la conformité en cas de perturbations."
        },
        "continuityStrategiesInfoSidebar": {
            "introDesc": "Une stratégie de continuité décrit les méthodes par lesquelles un processus est censé se poursuivre pendant un incident. ",
            "work": "Travaillez de n'importe où",
            "workInfo": "Continuez à travailler à domicile ou dans un espace distant similaire.",
            "temporary": "Espace de travail temporaire",
            "temporaryInfo": "Déplacez des opérations entières vers un emplacement temporaire.",
            "move": "Déplacez le travail",
            "moveInfo": "Transférez le travail à un fournisseur externe ou à une autre équipe interne.",
            "suspend": "Suspendre",
            "suspendInfo": "Suspendre temporairement les travaux.",
            "conclusion": "La mise en œuvre et la validation préalable de ces stratégies de continuité contribueront à garantir que votre entreprise puisse maintenir ses opérations en cas de perturbations inattendues."
        }
    }
}
