{
    "cre": {
        "locations": "[[Locations]]",
        "location": "[[Location]]"
    },
    "common": {
        "locations": "[[Locations]]",
        "location": "[[Location]]",
        "defaultLocation": "Default [[Location]]"
    },
    "panicTriggers": {
        "addCustomizedByLocation": "Add Trigger Customized By [[Locations]]"
    },
    "reports": {
        "labels": {
            "Tenant": "Tenant",
            "Primary Location": "Primary [[Location]]",
            "Location": "[[Location]]"
        }
    },
    "manage": {
        "nav": {
            "lblLocations": "[[Locations]]"
        },
        "locations": {
            "btnLocationCreate": "Add New [[Location]]",
            "btnSaveLocation": "Save [[Location]]",
            "duplicateLocationName": "[[Location]] with same name already exists.",
            "errorLocationReferenced": "[[Location]] is referenced as primary [[location]] by users",
            "errorRemoveLocationFailed": "An error has occurred removing [[location]].",
            "errorSaveLocationFailed": "An error has occurred saving [[location]].",
            "lblAreYouSureRemoveLocation": "Are you sure you want to delete this [[location]]?",
            "lblBuildingAssignedToTenantsAreYouSureRemoveLocation": "The [[location]] is associated to tenants. Deleting the [[location]] will remove it from tenants. Are you sure you want to remove this [[location]]?",
            "lblLocationActivity": "[[Location]] Activity",
            "lblLocationInfo": "[[Location]] Information",
            "lblLocationListTitle": "[[Locations]]",
            "lblLocationMembers": "Users Associated to this [[Location]]",
            "lblLocationName": "[[Location]] Name",
            "lblNewLocation": "New [[Location]]",
            "msgRemoveLocationSuccess": "[[Location]] has been successfully removed.",
            "msgSaveLocationSuccess": "[[Location]] {0} has been successfully saved.",
            "msgUpdateGeofence": "the [[location]] address has changed, would you like to update the geofence for this [[location]]?",
            "deleteLocation": "Remove [[Location]]",
            "removeLocation": "Remove [[location]]"
        },
        "roles": {
            "lblEntityMgmtExplain": "Allow users to create/modify/delete [[locations]], groups, and [[departments]].",
            "lblIncludeCrisisDescription": "Display the contact information for users with this role on the Crisis Team page for each [[location]]."
        },
        "bulkUploadConfig": {
            "lblDefaultLocation": "Default [[Location]]",
            "defaultLocationPlaceHolder": "Select Default [[Location]]",
            "defaultLocationTooltip": "This [[location]] will be assigned to new users that do not have a [[location]] specified in their bulk upload file."
        }
    }
}
