{
    "risk": {
        "lblRiskMenuTitle": "[[Risk]]",
        "lblRiskSetupMenuTitle": "Configurar",
        "msgNoRiskConfiguration": "Los ajustes de RPN de [[risk]] no están configurados",
        "headerMenu": {
            "riskLanding": "Tablero de control",
            "riskSetup": "Configurar",
            "riskDistribution": "Distribución",
            "riskThreats": "Amenazas",
            "rpnConfiguration": "Configuración",
            "riskAnalytics": "Datos analíticos",
            "assessments": "Evaluaciones",
            "assessment": "Evaluación",
            "assessmentDetails": "Detalles de la evaluación",
            "riskInitiative": "Iniciativas",
            "initiativeDetails": "Detalles de la iniciativa"
        },
        "threats": {
            "typicalImpactDescription": "Descripción típica del impacto",
            "rpnConfiguration": "Configuración RPN",
            "riskAnalytics": "Análisis de [[risk]]s",
            "assessments": "Evaluaciones",
            "impactDescription": "Descripción del impacto",
            "threats": "Amenazas",
            "newThreat": "Nueva amenaza",
            "deleteThreat": "Eliminar amenaza",
            "selectType": "Seleccione un tipo",
            "duplicateThreatTypeName": "Nombre de tipo duplicado",
            "systemThreat": "Amenaza del sistema",
            "newSystemThreat": "Nueva amenaza del sistema",
            "system": {
                "header": "Eliminar amenaza",
                "body": "¿Desea eliminar esta amenaza solo de este portal o de todos los portales?",
                "removeFromThisPortal": "Eliminar de este portal",
                "removeFromAllPortals": "Eliminar de todos los portales"
            },
            "portal": {
                "header": "Eliminar amenaza",
                "body": "¿Está seguro de que desea quitar esta amenaza?",
                "ok": "OK"
            },
            "copyThreatAsNew": "Copiar amenaza como nueva"
        },
        "rpnConfiguration": {
            "formulaPlaceholder": "Seleccione una fórmula",
            "statusPlaceholder": "Seleccione un estado",
            "approvalSystem": "Sistema de aprobación",
            "prePopulateAnswers": "Usar respuestas de evaluaciones anteriores",
            "prePopulatePlaceholder": "Pre-rellenar",
            "formulaSaveSuccessfully": "La fórmula se ha guardado correctamente.",
            "formulaSaveUnSuccessfully": "La fórmula se ha guardado sin éxito.",
            "riskBandConfiguration": "Las bandas de [[risk]] organizan sus números de prioridad de [[risk]] en términos claros. Establezca el número de bandas de [[risk]] que desea utilizar para sus evaluaciones de [[risk]].",
            "equations": {
                "e0783df6-7ea6-496e-a3ae-0bd61e3d06d2": {},
                "e0783df6-7ea6-496e-a3ae-0bd61e3d06d": {},
                "13fcb83c-d1a2-4135-9e79-50bddff5dcdc": {},
                "f84ff46e-15a3-4348-b958-9ef58a434738": {},
                "610af901-b8cd-4ec2-8673-ca62dcf6c595": {},
                "dc52ab2d-ae1f-4e80-b11c-fc921cb6e2af": {}
            },
            "riskBandsKeys": {
                "1": "Bajo",
                "2": "Medio",
                "3": "Alto",
                "4": "Medio-bajo",
                "5": "Medio-alto"
            },
            "verbiage": {
                "riskBand": "Las bandas de [[risk]] organizan sus números de prioridad de [[risk]] en términos claros. Establezca el número de bandas de [[risk]] que desea utilizar para sus evaluaciones de [[risk]].",
                "approvalSystem": "Si se establece en Sí, una evaluación no se considerará finalizada hasta que un aprobador la haya revisado y dado su aprobación. Una vez que el participante envíe sus respuestas, se enviará un correo electrónico al aprobador notificándolo.",
                "usePriorAssessmentResponses": "Si se establece en Sí, las respuestas de las evaluaciones anteriores se utilizarán para las nuevas evaluaciones. La persona que realiza la evaluación tiene la capacidad de modificar los valores según sea necesario antes de enviar sus respuestas.",
                "equation": "Seleccione la ecuación de evaluación que desea utilizar para sus evaluaciones de continuidad comercial. Se aplica en el cálculo del RPN, que indica el perfil de [[risk]] relativo."
            },
            "impact": "Impacto",
            "probability": "Probabilidad",
            "mitigation": "Mitigación",
            "multiplier": "Multiplicador",
            "detection": "Detección",
            "riskBands": "Bandas de [[risk]]",
            "assessmentEquation": "Ecuación de evaluación",
            "numberOfRiskBands": "Número de bandas de [[risk]]"
        },
        "assessment": {
            "welcomeAssessment": "Bienvenido al asistente de creación de evaluación",
            "typeQuestion": "¿Qué tipo de evaluación le gustaría realizar?",
            "selectAnApprover": "Seleccione un aprobador",
            "selectApprover": "Seleccionar aprobador",
            "reviewEmail": "Revisar correo electrónico",
            "pickTargetType": "Elegir su tipo de objetivo",
            "pickTargets": "Elegir sus objetivos",
            "sendAssessments": "Enviar evaluaciones",
            "selectedTargets": "Objetivos seleccionados",
            "pickTargetTypeDescription": "Seleccione el tipo de evaluación que le gustaría crear.",
            "pickTargetsDescription": "Seleccione el {{entity}} sobre el que desea hacer una evaluación.",
            "sendAssessmentsDescription": "Establezca el destinatario, el aprobador y la fecha de vencimiento de la evaluación, luego haga clic en Enviar y finalizar para enviar la invitación de la evaluación.",
            "selectRecipient": "Seleccionar destinatario",
            "sendAssessmentRequirementsError": "Asegúrese de que cada evaluación tenga un destinatario, un aprobador y una fecha de vencimiento.",
            "create": "Crear evaluación",
            "riskPriorityNumber": "Número de prioridad de [[risk]]",
            "assessmentInvalid": "La evaluación no es válida",
            "saveAndContinue": "Guardar y continuar",
            "selectStatus": "Seleccionar estado",
            "targetName": "Nombre de objetivo",
            "welcomeAssessmentUser": "Bienvenido: {{user}}",
            "createAssessmentWizard": "Crear asistente de evaluación",
            "welcomeAssessmentDescription": "A continuación, verá la cantidad de amenazas comerciales que se le han asignado para analizar dentro de esta evaluación. Revise cada amenaza y responda a los factores de [[risk]] lo mejor que pueda, estos valores se utilizan para calcular el valor del Número de prioridad de [[risk]] (RPN) del objetivo.",
            "welcomeAssessmentConditions": [
                "Sus respuestas se guardan automáticamente, puede tomar un descanso mientras realiza la evaluación.",
                "Tendrá la oportunidad de revisar y editar sus respuestas antes de enviarlas."
            ],
            "threatsToAssess": "Amenazas a evaluar",
            "timeForAssessments": "Debería tomar menos de:",
            "getStarted": "Comenzar",
            "minutesToCompleteAssessment": "{{minutes}} minutos",
            "minuteToCompleteAssessment": "{{minute}} minuto",
            "estimatedTimeToCompleteAssessment": "Tiempo estimado para completar",
            "accountingThreats": "{{completed}} de {{available}}",
            "removeAssessment": "Eliminar evaluación",
            "removeAssessmentDescription": "¿Está seguro de que desea eliminar esta evaluación?",
            "searchTargetName": "Nombre del objetivo de búsqueda",
            "searchRecipientName": "Buscar nombre del destinatario",
            "searchPriorityNumber": "Número de prioridad de búsqueda",
            "selectCreationDate": "Seleccionar fecha de creación",
            "deleteAssessment": "Eliminar evaluación",
            "completeAssessment": "Evaluación completa",
            "statusList": {
                "open": "Abierto",
                "waitingApproval": "Esperando aprobación",
                "abandoned": "Abandonado",
                "closed": "Cerrado",
                "archived": "Archivado",
                "deleted": "Eliminado",
                "inProgress": "En curso"
            },
            "sendEmailDescription": {
                "selectDueDate": "",
                "selectApprover": "",
                "reviewEmail": "",
                "sendFinish": ""
            },
            "loadingAssessment": "Cargando evaluación...",
            "assessmentExistHelperText": "Cuando los resultados de la búsqueda se resaltan en amarillo, significa que tienen una evaluación abierta. Si se crea una nueva evaluación, las anteriores se cerrarán y se marcarán como abandonadas.",
            "assessmentNotFound": "No se puede encontrar la evaluación.",
			"assessmentFailedStart": "No se pudo iniciar la evaluación.",
            "targetType": {
                "Department": "[[Department]]",
                "Location": "[[Location]]",
                "Company": "Empresa"
            }
        },
        "threatDetail": {
            "maximum": "Máximo",
            "minimum": "Mínimo",
            "submitAssessment": "Enviar evaluación",
            "factorsMinMax": {
                "impact": {
                    "min": "La menor cantidad de impacto",
                    "max": "Alto impacto",
                    "verbiage": "El impacto de [[risk]] proporciona una estimación de las posibles pérdidas relacionadas con un [[risk]]."
                },
                "probability": {
                    "min": "Probabilidad mínima",
                    "max": "Muy probable",
                    "verbiage": "La probabilidad de [[risk]] es determinar qué tan probable es que suceda un [[risk]]. Esta probabilidad podría basarse en lo que ha sucedido en el pasado."
                },
                "detection": {
                    "min": "Tiempo de advertencia prolongado",
                    "max": "Poco o ningún tiempo de advertencia",
                    "verbiage": "La detección de [[risk]]s se utiliza para medir la capacidad de encontrar [[risk]]s antes de que sucedan."
                },
                "mitigation": {
                    "min": "Pocas o ninguna mitigación en curso",
                    "max": "Altamente Mitigado",
                    "verbiage": "La mitigación de [[risk]]s es cuando una empresa reduce los [[risk]]s para su negocio haciendo que las operaciones sean más eficientes y exponiéndose a menos [[risk]]s."
                },
                "multiplier": {
                    "min": "Mínimo ",
                    "max": "Máximo ",
                    "verbiage": "Úsela para mostrar cómo se siente acerca de las amenazas comerciales."
                }
            }
        },
        "assessmentDetails": {
            "assessmentDetails": "Detalles de la evaluación",
            "approveAssessment": "Aprobar evaluación",
            "completionDate": "Fecha de finalización",
            "riskScore": "Puntuación de [[risk]]",
            "threatHazard": "Amenaza/Peligro",
            "emailRecipient": "Destinatario de correo electrónico",
            "emailApprover": "Aprobador de correo electrónico",
            "assessmentSubmittedSuccessfully": "La evaluación se ha enviado con éxito.",
            "assessmentSubmittedUnsuccessfully": "La evaluación no se ha enviado con éxito",
            "mainVerbiage": "A continuación, verá la cantidad de amenazas que se le han asignado para analizar dentro de esta evaluación. Revise cada amenaza y responda a los factores de [[risk]] lo mejor que pueda y agregue comentarios cuando se necesite claridad, estos valores se utilizan para calcular el valor del Número de Prioridad de [[Risk]] (RPN) del {{targetType}}.",
            "titleVerbiage": "Evaluación de [[risk]]s para {{target}}",
            "dirtyMessageTitle": "Guardar cambios",
            "dirtyMessageBody": "¿Le gustaría guardar sus cambios para {{threatName}} ?",
            "msgSaveAssessmentResultFailed": "No se pudo guardar la evaluación.",
            "assessmentComplete": "Esta evaluación ya ha sido respondida, gracias.",
            "declineAssessmentDescription": "¿Está seguro de que desea rechazar esta evaluación? Al destinatario se le enviarán los comentarios presentados a continuación.",
            "declineAssessment": "Rechazar evaluación",
            "assessmentDeclined": "Evaluación rechazada",
            "approvalComments": "Comentarios de aprobación",
            "archiveMessage": "¿Está seguro de que desea archivar esta evaluación?",
            "abandonMessage": "¿Está seguro de que desea abandonar esta evaluación?",
            "closeMessage": "¿Está seguro de que desea cerrar esta evaluación?"
        },
        "analytics": {
            "highestRiskValue": "Valor de [[risk]] más alto",
            "columnsSelected": "{{columns}} columnas seleccionadas",
            "searchAssessment": "Buscar evaluación",
            "targetTypeName": "Tipo de objetivo",
            "closeDate": "Fecha de cierre"
        },
        "initiative": {
            "newInitiative": "Nueva iniciativa",
            "listHelperText": "¡Bienvenido a Preparis Risk! A continuación se muestra una lista de iniciativas de [[risk]] en las que puede crear y realizar un seguimiento del resultado de su ciclo de evaluación de [[risk]]s.",
            "removeInitiative": "Eliminar iniciativa",
            "removeInitiativeDescription": "¿Está seguro de que desea eliminar esta integración?",
            "searchCompletionDate": "Fecha de finalización de la búsqueda"
        },
        "initiativeDetails": {
            "initiatives": "Iniciativas",
            "name": "Nombre",
            "dueDate": "Fecha límite",
            "assessmentFinding": "Resultados de la evaluación",
            "goal": "Meta",
            "findingsPlaceholder": "Ingrese los resultados de su evaluación aquí",
            "findingsGoal": "Ingrese sus metas aquí",
            "modalMsg": "{{field}} por la iniciativa",
            "selectAssessment": "Seleccionar evaluaciones",
            "targets": "Objetivos",
            "rpn": "NPR",
            "type": "Tipo",
            "creationDate": "Fecha de creación",
            "selectAStatus": "Seleccione un estado",
            "assessmentSelectVerbiage": "Seleccione las evaluaciones que desea incluir dentro de esta Iniciativa de [[Risk]].",
            "targetTypeName": "Tipo de objetivo",
            "completedOn": "Completado el",
            "initiativeSavedSuccessfully": "La iniciativa se ha guardado con éxito.",
            "initiativeSavedUnsuccessfully": "La iniciativa no se ha guardado con éxito",
            "topVerbiage": "Las iniciativas de [[risk]] le permiten documentar lo que se descubrió durante el período de evaluación y establecer metas para la mitigación futura."
        },
        "dashboard": {
            "assessmentsByRpn": "Evaluaciones por Número de Prioridad de [[Risk]]",
            "riskBand": "Banda de [[risk]]",
            "riskMatrix": "Matriz de [[risk]]",
            "riskOverTime": "[[Risk]] a lo largo del tiempo",
            "helperText": "Dentro del gráfico Evaluación por Número de Prioridad de [[Risk]] (RPN), elija la evaluación que desea ver.",
            "rpnOverTimeDescription": "Dentro del gráfico Evaluación por Número de Prioridad de [[Risk]] (RPN), elija la evaluación que desea ver."
        },
        "selectPortalFunctionalAreaForDepartmentModal": {
            "headerLabel": "[[Department]] del [[Planner]] asociado",
            "searchItemLabel": "[[Departments]] del [[Planner]] de búsqueda",
            "currentItemLabel": "[[Department]] del [[Planner]] asociado actualmente",
            "resultLabel": "[[Departments]] del [[Planner]]",
            "explanationText": "El nombre del [[department]] del [[Planner]] seleccionado se cambiará para que coincida con el nombre del [[department]].",
            "matchingNameFAAssociationMessage": "Se encontró un [[Department]] de [[Planner]] con el mismo nombre '{{name}}'. ¿Le gustaría vincular estos [[departments]]?",
            "UnAssociateMessage": "¿Está seguro de que desea desvincular el '{{name}}' de este [[department]]?",
            "errFailedToLoad": "Error al cargar los [[departments]] de [[Planner]] disponibles",
            "linkDescription": "Crear nuevo [[department]] bc {{departmentName}} y vincularlo al [[department]]."
        }
    }
}
