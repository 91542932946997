{
    "department": "[[Department]]",
    "idleTimeout": {
        "extendSession": "Stay Logged In",
        "signOut": "Sign out",
        "title": "Your session is about to expire due to inactivity."
    },
    "overview": {
        "overview": "Overview",
        "noDataMessage": "The selected [[scope]] and [[team]] does not contain data."
    },
    "hierarchy": "Hierarchy",
    "administration": "Administration",
    "complianceCalendar": {
        "complianceCalendar": "Compliance Calendar",
        "previousWeek": "Previous Week",
        "nextWeek": "Next Week",
        "today": "Today",
        "week": "Week",
        "month": "Month",
        "day": "Day",
        "next": "Next",
        "previous": "Previous",
        "allDayEvents": "All Day Events",
        "noAllDayEvent": "No events",
        "assignments": "Assignments",
        "all": "All",
        "mine": "Mine"
    },
    "scopes": "[[Scopes]]",
    "teams": "[[Teams]]",
    "teamMembers": "[[Team Members]]",
    "items": "[[Items]]",
    "issues": "[[Issues]]",
    "externalResources": "[[External Resources]]",
    "assets": "[[Assets]]",
    "pendingApprovals": "Pending Approvals",
    "plans": {
        "plans": "[[Plans]]",
        "download": "Download",
        "generate": "Generate",
        "name": "Name",
        "numberOfItems": "Number of [[Items]]",
        "lastStructureUpdate": "Last Structure Update",
        "lastGeneration": "Last Generation",
        "daysSinceGeneration": "Days Since Generation",
        "lblDateTimeFromTo": "Run [[Report]] for last"
    },
    "support": {
        "description": "<p>Contact Customer Support</p><p>Tell us how we can help.</p>",
        "email": "EMAIL US @",
        "phone": "CALL US @",
        "phoneOption": "OPTION 1"
    },
    "reports": {
        "reports": "[[Reports]]",
        "required": "{{ fieldName }} is required",
        "all": "All",
        "select": "Select",
        "portal": "Portal",
        "dashboard": {
            "dashboard": "Dashboard",
            "admin": "Admin",
            "messaging": "Messaging",
            "training": "Training",
            "lblUserByStatus": "User by Status",
            "lblUserLoginActivity": "User Login Activity",
            "lblMessagesSent": "Messages Sent",
            "lblRecentMessages": "Recent Messages",
            "lblCourseCompletion": "Course Completions",
            "lblMostCompletedCourses": "Most Completed Courses",
            "lblTotal": "Total",
            "lblLast30Days": "(last 30 days)",
            "lblLast3Months": "(last 3 months)",
            "lblLast3Messages": "(last 3 messages)",
            "lblLast6Months": "(last 6 months)",
            "lblTop5courses": "(top 5 courses)",
            "lblTotalResponses": "Total Responses",
            "lblTotalAudience": "Total Audience"
        },
        "errors": {
            "renderingReport": "Error Rendering [[Report]]",
            "loadingReport": "Error Loading [[Report]] Parameters",
            "fromDateIsAfterToDate": "From date is after To date",
            "dateRangeMax": "Max date range cannot be more than {{maxMonthRange}} {{units}}"
        },
        "labels": {
            "Role": "Role",
            "Department": "[[Department]]",
            "Location": "[[Location]]",
            "Groups": "Groups",
            "Group": "Group",
            "Status": "Status",
            "Select Format": "Select Format",
            "Response Required": "Response Required",
            "Category": "Category",
            "Course": "Course",
            "Include": "Include",
            "Completed Date": "Completed Date",
            "Assignee": "Assignee",
            "Compliance Category": "Compliance Category",
            "External Resource Type": "[[External Resource]] Type",
            "External Resource": "[[External Resource]]",
            "Asset": "[[Asset]]",
            "Item": "[[Item]]",
            "Plan": "[[Plan]]",
            "Assignment Type": "Assignment Type",
            "Team": "[[Team]]",
            "Scope": "[[Scope]]",
            "Include Sub Teams": "Include Sub [[Teams]]",
            "Include Resolved Issues": "Include Resolved [[Issues]]",
            "Include All Expired": "Include All Expired",
            "Include Archived": "Include Archived",
            "Incident Types": "Incident Types",
            "bcFunctionalArea": "[[Department]]",
            "bcProcess": "[[Process]]",
            "bcDueDateFrom": "Due Date from",
            "bcDueDateTo": "Due Date to",
            "datetime_to": "To Date",
            "datetime_from": "From Date",
            "To Date": "To Date",
            "From Date": "From Date",
            "to Date": "To Date",
            "from Date": "From Date",
            "Since Date": "Since Date",
            "Primary Location": "Primary [[Location]]",
            "User": "User",
            "only display if days past due greater than (zero displays all)": "only display if days past due greater than (zero displays all)",
            "Only display if days past due greater than (zero displays all)": "Only display if days past due greater than (zero displays all)",
            "Only display if days pasrt due greater than (zero displays all)": "Only display if days past due greater than (zero displays all)",
            "Sent From Date": "Sent From Date",
            "Sent To Date": "Sent To Date",
            "bcAssignee": "Assignee",
            "bcProgressStatus": "Progress Status",
            "bcStrategyType": "Strategy Types",
            "bcImpactTimespanType": "RTO",
            "Admin": "Admin",
            "Deleted Users": "Deleted Users",
            "Registration Status": "Registration Status",
            "User Login Activity": "User Login Activity",
            "User Update History": "User Update History",
            "Users": "Users",
            "Incidents": "Messaging",
            "Message User Latest Responses": "Message User Latest Responses",
            "Message User Responses By Contact Method": "Message User Responses By Contact Method",
            "Training": "Training",
            "Certification Status": "Certification Status",
            "Course Completion": "Course Completion",
            "Course Status by User": "Course Status by User",
            "Learner Report": "Learner [[Report]]",
            "External Resources By Type": "External Resources By Type",
            "External Resources List": "External Resources List",
            "External Resource ": "External Resource",
            "IQ Resource Changes": "IQ Resource Changes",
            "iQ Asset Changes": "iQ Asset Changes",
            "iQ External Resource Changes": "iQ External Resource Changes",
            "iQ Item Changes": "iQ Item Changes",
            "iQ Plan Changes": "iQ Plan Changes",
            "Issues": "[[Issues]]",
            "Issues by Compliance": "[[Issues]] by Compliance",
            "Issues by Priority": "[[Issues]] by Priority",
            "Issues by Team": "[[Issues]] by Team",
            "Issue Action Items": "[[Issue]] Action Items",
            "Items": "[[Items]]",
            "Assignment Count By Team Summary": "Assignment Count By Team Summary",
            "Assignments by Compliance Category Detail": "Assignments by Compliance Category Detail",
            "Assignments By Physical Location": "Assignments By Physical Location",
            "Assignments by Team Detail": "Assignments by Team Detail",
            "Assignments by Team Summary": "Assignments by Team Summary",
            "Assignments By Type": "Assignments By Type",
            "Assignments Emergency Distributions": "Assignments Emergency Distributions",
            "Management": "Management",
            "Assignments Readiness Chart and Summary": "Assignments Readiness Chart and Summary",
            "Team Member Readiness Chart": "[[Team Member]] Readiness Chart",
            "Verification History": "Verification History",
            "Plans": "Plans",
            "Plan Content Cross Reference": "Plan Content Cross Reference",
            "Plan Recovery Structure By Team": "Plan Recovery Structure By Team",
            "Plan Summary By Team": "Plan Summary By Team",
            "Processes Status": "[[Processes]] Status",
            "Teams": "Teams",
            "Section Mission Statements": "Section Mission Statements",
            "Team Mission Statements": "Team Mission Statements",
            "Scope Mission Statements": "Scope Mission Statements",
            "Team Objectives Summary": "Team Objectives Summary",
            "Team Summary Report": "Team Summary [[Report]]",
            "User Assignments List": "User Assignments List",
            "User Assignments List By Team": "User Assignments List By Team",
            "User Assignments Summary": "User Assignments Summary",
            "User Detail by Name": "User Detail by Name",
            "User Detail by Team": "User Detail by Team",
            "User Notifications Detail": "User Notifications Detail",
            "User Notifications Summary": "User Notifications Summary",
            "Users Not Assigned to Teams": "Users Not Assigned to Teams",
            "Business Continuity Planner Reports": "[[Planner]] [[Reports]]",
            "Assignment Report": "Assignment [[Report]]",
            "Assignments by Person Report": "Assignments by Person [[Report]]",
            "BIA Severity Levels": "[[BIA]] Severity Levels",
            "Department Status": "[[Department]] Status",
            "Equipment Report": "[[Equipment]] [[Report]]",
            "Strategy And RTO Employee Report": "Strategy And RTO Employee [[Report]]",
            "Strategy And RTO Report": "Strategy And RTO [[Report]]",
            "Strategy By RTO Report": "Strategy By RTO [[Report]]",
            "Technology and Application Report by RTO": "[[Technology]] and [[Application]] [[Report]] by RTO",
            "Technology Report": "[[Technology]] [[Report]]",
            "Vendor Report": "Vendor [[Report]]",
            "bcPlanActivationIssue": "BC Plan Activation [[Issue]]",
            "bcReportingTimeInterval": "BC Reporting Time Interval",
            "timeIntervals": {
                "t15min": "15 min",
                "t30min": "30 min",
                "t1hr": "1 hr",
                "t2hrs": "2 hrs",
                "t4hrs": "4 hrs",
                "t8hrs": "8 hrs",
                "t12hrs": "12 hrs",
                "t24hrs": "24 hrs"
            },
            "bcProcessActivationStatus": "[[Process]] Activation Status",
            "incidentName": "Incident Name",
            "messagingJobType": "Message Type",
            "messagingJobTypes": {
                "sms": "SMS",
                "emails": "Email",
                "voiceMails": "Voice Mails",
                "conferenceCalls": "Conference call",
                "pushMessages": "Push",
                "desktop": "Desktop"
            },
            "moreItemsAvailable": "There are {{ itemsCount }} more items available. Please narrow down your search.",
            "Incident Manager": "Incident Manager",
            "Missed RTOs": "Missed RTOs",
            "Progress over time": "Progress over time",
            "Progress over time by RTO": "Progress over time by RTO",
            "Incident Communication": "Incident Communication",
            "Escalated Issues": "Escalated [[Issues]]",
            "sort_by": "Sort By",
            "Audit": "Audit",
            "Resource Type": "Resource Type",
            "Data Accessed": "Data Accessed",
            "Login": "Login",
            "Unauthorized Access": "Unsuccessful Login",
            "Application Technology Dependency Report": "[[Application]] [[Technology]] Dependency [[Report]]",
            "Gap Report": "Gap [[Report]]",
            "Process Dependency Report": "[[Process]] Dependency [[Report]]",
            "Vendor Dependency Report": "Vendor Dependency [[Report]]",
            "Tree Report": "[[Processes]] Dependency Tree [[Report]]",
            "bcApplicationTechnology": "[[Application]]/[[Technology]]",
            "bcVendor": "Vendor",
            "options": "Options",
            "escalated": "Escalated",
            "owner": "Owner",
            "Department Schedule": "[[Department]] Schedule",
            "Location Schedule": "[[Location]] Schedule",
            "Assessment Report": "Assessment [[Report]]",
            "Risk": "[[Risk]]",
            "Target Type": "Target Type",
            "Target Name": "Target Name",
            "Assessment Status": "Assessment Status",
            "Assessment Report By Status": "Assessment [[Report]] By Status",
            "Initiative Report": "Initiatives [[Report]]",
            "select_riskInitiativeStatus": "Status",
            "search_riskInitiativeName_toId": "Name",
            "select_assessmentTargetType": "Target Type",
            "Threats Report": "Threats [[Report]]",
            "select_threatType_Id": "Threat Type",
            "search_threatName_toId": "Threat Name",
            "generic": "Generic",
            "banking": "Banking",
            "legal": "Legal",
            "government": "Government",
            "technology": "[[Technology]]",
            "Technologies Tiers": "[[Technologies]] Tiers",
            "Technology to Technology Tier Gap Report": "[[Technology]] to [[Technology]] Tier Gap",
            "Technology Vendor Dependency Report": "[[Technology]] Vendor Dependency"
        },
        "validValues": {
            "Waiting For Approval": "Waiting For Approval",
            "Closed": "Closed",
            "In Progress": "In Progress",
            "Deleted": "Deleted",
            "Archived": "Archived",
            "Open": "Open",
            "All": "All",
            "User": "User",
            "User Email": "User Email",
            "User Address": "User Address",
            "User Phone": "User Phone",
            "User Profile": "User Profile",
            "User Role": "User Role",
            "Location": "Location",
            "Location Address": "Location Address",
            "Location Phone": "Location Phone",
            "Department": "[[Department]]",
            "Group": "Group",
            "Role": "Role",
            "Active": "Active",
            "Pending": "Pending",
            "Locked": "Locked",
            "Inactive": "Inactive",
            "Resolved": "Resolved",
            "Completed": "Completed",
            "Complete": "Complete",
            "Today": "Today",
            "Last 7 Days": "Last 7 Days",
            "Last 30 Days": "Last 30 Days",
            "Last 60 Days": "Last 60 Days",
            "Last 90 Days": "Last 90 Days",
            "Last 365 Days": "Last 365 Days",
            "Over 365 Days": "Over 365 Days",
            "Not Taken": "Not Taken",
            "Incomplete": "Incomplete",
            "PDF": "PDF",
            "CSV (comma delimited)": "CSV (comma delimited)",
            "Yes": "Yes",
            "No": "No",
            "Title": "Title",
            "Status": "Status",
            "Owner": "Owner",
            "Departments": "[[Department]]",
            "Processes": "[[Processes]]",
            "Vendors": "Vendors",
            "Vendor Addresses": "Vendor Addresses",
            "Vendor Contacts": "Vendor Contacts",
            "Technology": "[[Technology]]",
            "Equipment": "[[Equipment]]"
        },
        "contentCategories": {
            "Terrorism": "Terrorism",
            "Health": "Health",
            "Other": "Other",
            "Cyber Threats": "Cyber Threats",
            "Natural Disasters": "Natural Disasters",
            "Test Category": "Test Category",
            "Additional Resources Parent Category": "Additional Resources Parent Category",
            "First Aid": "First Aid",
            "Workplace Threats": "Workplace Threats"
        },
        "courses": {
            "Earthquake": "Earthquake",
            "Medical Emergency: Non-Traumatic ": "Medical Emergency: Non-Traumatic ",
            "Chemical Spill ": "Chemical Spill ",
            "Explosions ": "Explosions ",
            "Guess WHo": "Guess WHo",
            "Blackout ": "Blackout ",
            "Tornado ": "Tornado ",
            "Medical Emergency: Traumatic ": "Medical Emergency: Traumatic ",
            "Workplace Violence ": "Workplace Violence ",
            "Fire Evacuation": "Fire Evacuation",
            "Information Security": "Information Security",
            "Anthrax": "Anthrax",
            "Tornado": "Tornado",
            "Large Event ": "Large Event ",
            "Fire Evacuation ": "Fire Evacuation ",
            "Dirty Bomb ": "Dirty Bomb ",
            "Explosions": "Explosions",
            "Tenant Warden": "Tenant Warden",
            "Certification 1: Introduction ": "Certification 1: Introduction ",
            "Dirty Bomb": "Dirty Bomb",
            "Pandemic Flu ": "Pandemic Flu ",
            "Office Creeper ": "Office Creeper ",
            "Anthrax ": "Anthrax ",
            "Active Shooter 2013": "Active Shooter 2013",
            "Winter Storm": "Winter Storm",
            "Hurricanes ": "Hurricanes ",
            "Office Creeper": "Office Creeper",
            "Bomb Threat": "Bomb Threat",
            "Certification 3: Taking Action ": "Certification 3: Taking Action ",
            "Certification 2: Your Preparedness Program ": "Certification 2: Your Preparedness Program ",
            "Hurricanes": "Hurricanes",
            "Blackout": "Blackout",
            "Active Shooter": "Active Shooter",
            "Flood ": "Flood ",
            "Winter Storm ": "Winter Storm ",
            "Mars Module 2.1": "Mars Module 2.1",
            "Bomb Threat ": "Bomb Threat ",
            "Mars Module 1.1": "Mars Module 1.1",
            "Certification": "Certification",
            "Earthquake ": "Earthquake ",
            "Guess Who": "Guess Who"
        },
        "incidentTypes": {
            "Not Set": "Not Set",
            "Exercise": "Exercise",
            "Panic": "Panic"
        }
    },
    "storageLocation": "Storage Location",
    "export": "Export",
    "bulkResend": "Bulk Resend",
    "established": "Established",
    "notestablished": "Not Established",
    "daysPastDue": "Days Past Due",
    "expired": "Expired",
    "scope": {
        "scopeName": "[[Scope]] Name",
        "coordinatorName": "Coordinator Name",
        "mission": "Mission",
        "assumptions": "Assumptions",
        "planHeader": "[[Plan]] Header",
        "confirmSaveHeader": "Save",
        "confirmSaveBody": "Are you sure you want to save this [[scope]]?",
        "confirmRemoveHeader": "Remove [[Scope]]",
        "confirmRemoveBody": "Are you sure you want to remove this [[scope]]?",
        "scopesFilterDesc": "Refine results by: [[Scope]] Name, [[Plan]] Header or Coordinator Name",
        "newScope": "New [[Scope]]",
        "viewTeams": "View [[Teams]]",
        "actions": "Actions",
        "cannotRemoveError": "Cannot remove [[scope]] with active [[teams]]"
    },
    "teamMember": {
        "teamMemberFilterDesc": "Refine results",
        "accountableForTeam": "This person is accountable for the [[team]].",
        "cannotBeAccountableForTeam": "This person only has access to assignments and cannot be accountable for the [[team]]",
        "cannotBeApproverForTeam": "This person only has access to assignments and cannot approve for the [[team]]",
        "confirmMakeAccountableHeader": "[[Team]] Accountability",
        "confirmMakeAccountable": "{{oldname}} is currently accountable for this [[team]]. Are you sure you want to make {{newname}} accountable for this [[team]]?",
        "confirmMakeAccountableButton": "OK",
        "complianceSchedule": "Compliance Schedule",
        "teamMember": "[[Team Member]]",
        "assignment": "Assignment",
        "isAccountable": "Accountable for [[Team]]",
        "comments": "Comments",
        "confirmSaveHeader": "Save [[Team Member]]",
        "confirmRemoveHeader": "Remove [[Team Member]]",
        "assignmentsTitle": "Member Assignments",
        "recoveryPlanCount": "Copies of the [[Plans]] issued to this [[member]]",
        "recoveryPlanIssueDate": "[[Plan]] [[Issue]] Date",
        "issuedRecoveryPlanDateRequired": "[[Plan]] [[Issue]] Date is required when user has been issued at least 1 copy of the [[plan]].",
        "issuedTeamPlanDateRequired": "[[Team]] [[Plan]] [[Issue]] Date is required when user has been issued at least 1 copy of the [[plan]].",
        "teamPlanCount": "Copies of the [[Team]] [[Plans]] issued to this [[member]]",
        "teamPlanIssueDate": "[[Team]] [[Plan]] [[Issue]] Date",
        "plansIssuedTitle": "[[Plans]] Issued",
        "teamMemberDoesNotExist": "[[Team Member]] does not exist.",
        "member": "Member",
        "verify": "Verify",
        "establish": "Establish",
        "verificationSuccess": "[[Team Member]] verification successful.",
        "unableToVerify": "Unable to verify [[Team Member]], please try again later.",
        "name": "Name",
        "type": "Type",
        "jobTitle": "Job Title",
        "mailCode": "Mail Code",
        "verifiedDate": "Verified Date",
        "verifiedStatus": "Status",
        "teamMembers": "[[Team Members]]",
        "newTeamMember": "New [[Team Member]]",
        "removeError": "Failed to remove [[team member]]",
        "removeAccountableTeamMemberError": "Cannot remove [[team member]] who is accountable for the [[team]]",
        "removeTeamMemberWithAssignmentError": "Cannot remove [[team member]] with assignments",
        "members": "Members",
        "lastVerified": "Last Verified",
        "nextVerification": "Next Verification",
        "daysPastDue": "Days Past Due",
        "dueDate": "Due Date",
        "isApprover": "Approver for [[Team]]",
        "reassignTasks": {
            "reassignTasks": "Reassign Tasks",
            "modalHeader": "Reassign [[Team Member]] Tasks",
            "reassign": "Reassign",
            "assignToTeamMember": "Assign To [[Team Member]]",
            "applyToAllTeams": "Apply to All Teams",
            "successReassigningTasksMsg": "Success reassigning [[team member]]'s {{ count }} tasks",
            "errorReassigningTasksMsg": "Error reassigning [[team member]]'s tasks",
            "errorReassigningTasksToFromUnkownUserMsg": "Error reassigning [[team member]]'s tasks to or from an unknown user",
            "errorReassigningTasksToSameUnderlyingUserMsg": "Error reassigning [[team member]]'s tasks to the same user",
            "applyToAllTeamsTooltip": "When applied to all teams, all iQ tasks belonging to this [[team member]], across all teams, are assigned to the designated user. If the designated user is not already a [[member]] of the other teams, he or she is added as a member to those teams."
        },
        "export": {
            "allTeamMembers": "All [[Team Members]]",
            "displayedTeamMembers": "Displayed [[Team Members]]",
            "header": "Export [[Team Members]]",
            "body": "Do you want to export only the displayed {{ x }} users or all [[team members]]?",
            "successMsg": "Export successfully generated",
            "errorMsg": "Error generating export",
            "fileName": "BulkTeamMemberUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Bulk Upload [[Team Members]]",
            "msgBulkUploadInstructions": "Have multiple [[team members]] you want to add or modify? Choose a correctly formatted file from your computer to start the [[process]].",
            "msgDropFile": "Drop File Here or Click to Select a File",
            "lblSelectedFile": "Selected file to upload",
            "lblNoFileSelected": "No file specified.",
            "lblNeedStartingPoint": "Need a Starting Point",
            "lblAddMultipleHeader": "Adding multiple new [[team members]]",
            "lblAddMultipleDetail": "Download this spreadsheet containing header information only and fill in your new [[team member]]'s information, one user per row, before saving it in .CSV format to your computer.",
            "btnBlankFile": "Blank [[Team Member]] File",
            "lblEditMultipleHeader": "Edit or Delete multiple [[team members]]",
            "lblEditMultipleDetail": "Download this spreadsheet containing your current [[team member]] information and modify as needed before saving it in .CSV format to your computer.",
            "btnCurrent": "Current [[Team Members]]",
            "lblEditMultipleNotes": "NOTE: To delete users, change their status to Delete. Removing them from the spreadsheet will not delete them from the system. The bulk upload can be completed even if you do not fill in every field for a user.",
            "lblUpload": "Submit",
            "btnCancel": "Cancel",
            "errorUserExportFailed": "Unable to export a [[team member]] file.",
            "msgUserExportSuccessful": "[[Team Member]] export completed successfully.",
            "msgTestBulkUploadFile": "This file will only be validated because the file name contains the word \"test\"; updates will not be processed.",
            "msgUploadSuccess": "Bulk upload successful.",
            "msgValidatingFile": "Verifying file...",
            "errorInvalidBulkFileType": "The selected file has an incorrect file extension it must have an .CSV extension.",
            "errorExportFailed": "Unable to export a [[team member]] file.",
            "msgExportSuccessful": "Export completed successfully."
        },
        "accountable": "Accountable"
    },
    "delete": "Delete",
    "save": "Save",
    "undo": "Undo",
    "replace": "Replace",
    "reset": "Reset",
    "choose": "Choose",
    "errorMessages": {
        "saveError": "Failed to save {{name}}",
        "removeError": "Failed to remove {{name}}",
        "scopeNameExist": "[[Scope]] with same name already exists",
        "msgValidateEmailFormat": "Please use a valid email format",
        "msgValidateMaxLength": "{{0}} has a maximum length of {{1}} character(s)",
        "msgValidateMaxValue": "The value of {{0}} cannot be greater than {{1}}",
        "msgValidateMinLength": "{{0}} has a required minimum length of {{1}} character(s)",
        "msgValidateMinValue": "The value of {{0}} cannot be lower than {{1}}",
        "msgValidateRequired": "Please enter all required fields",
        "msgValidateRequiredBefore": "The value of {{0}} cannot be before {{1}}",
        "externalResourceContactPrimary": "At least one(1) primary contact is required",
        "loadingError": "Failed to load {{name}}",
        "notFoundError": "{{name}} Not Found",
        "verifyError": "Failed to verify {{ name }}",
        "isInvalidPhoneNumber": "Please enter a valid phone number",
        "formIsDirty": "Please save your changes first",
        "dateRangeMax": "Date range exceeds max range",
        "fromDateIsAfterToDate": "From date is after To date",
        "generatePlan": "Failed to generate [[plan]]",
        "noBusinessContinuityPlan": "There are no plans to preview",
        "planItemsMinimumRequirement": "At least one (1) Document or [[Report]] must be in this [[Plan]] Structure",
        "planItemsOnlyDocumentUsedAsCoverPage": "At least one (1) document that is not used as a cover page or one (1) [[report]] should be in the [[Plan]] Structure.",
        "checkInError": "Failed to check in {{name}}",
        "declineError": "Failed to decline {{name}}",
        "approveError": "Failed to approve {{name}}",
        "itemInUsedByPlan": "Failed to remove this [[item]]. [[Item]] is in used by a [[Plan]].",
        "errorMsgInvalidFirstTeamMember": "User with only access to assignments cannot be the first [[team member]] of a [[team]].",
        "duplicateFilename": "Duplicate filename",
        "archiveError": "{{name}} NOT archived",
        "unArchiveError": "{{name}} NOT restored",
        "deleteAttachmentError": "Failed to remove attachment {{ docName }}",
        "genericError": "An error has occurred",
        "errorRemovingMessageTemplate": "Error removing saved message",
        "errorUploadFileMessage": "Failed to upload attachment {{ docName }}",
        "invalidDate": "Invalid Date",
        "errorAssigningResourceToSelf": "Error assigning to self",
        "errorArchiveResource": "Error archiving",
        "msgInvalidPersonalPhoneNumber": "Invalid phone number format for Personal Phone (will receive all SMS and Voice Calls). For non-US numbers, please include country code. E.g. +44",
        "msgInvalidAdditionalPersonalPhoneNumber": "Invalid phone number format for Additional Personal Phone (will receive all SMS and Voice Calls). For non-US numbers, please include country code. E.g. +44",
        "msgInvalidMobilePhoneNumber": "Invalid phone number format for Mobile Phone (will receive all SMS and Voice Calls). For non-US numbers, please include country code. E.g. +44",
        "msgInvalidOfficePhoneNumber": "Invalid phone number format for Office Phone. For non-US numbers, please include country code. E.g. +44",
        "mgDuplicateSubdomain": "Subdomain name is not unique",
        "maliciousFileType": {
            "header": "Warning",
            "body": "Potential malicious file detected. Are you sure you want to upload this file?"
        },
        "blockMaliciousFileType": {
            "header": "Error",
            "body": "Malicious file detected. you can not upload this file."
        },
        "fileTypeNotSupported": "File type not supported",
        "noRecordsFound": "No records found",
        "deleteEntity": {
            "panicTrigger": "{{entityName}} in use by Panic Configuration",
            "uii": "{{entityName}} in use by User Initiated Incident",
            "alertSubscription": "{{entityName}} in use by [[Alert]] Subscription",
            "savedMessage": "{{entityName}} in use by Saved Message",
            "documentPermission": "{{entityName}} in use by Document Permission",
            "bulkUploadDefault": "{{entityName}} is default for Bulk Upload",
            "incidentCommunicationGroup": "{{entityName}} is used in incident communication configuration"
        },
        "noAssessmentsFound": "No assessments found",
        "analyticsNoAvailable": "Analytics no available",
        "noInitiativesFound": "No initiatives found",
        "noElementsFound": "No elements found",
        "errorUpdatingPageStatus": "Error updating page status"
    },
    "successMessages": {
        "saveSuccess": "{{name}} saved",
        "removeSuccess": "{{name}} removed",
        "verifySuccess": "{{name}} verified",
        "checkInSuccess": "{{name}} checked in",
        "declineSuccess": "{{name}} declined",
        "approveSuccess": "{{name}} approved",
        "archiveSuccess": "{{name}} archived",
        "unArchiveSuccess": "{{name}} restored",
        "deleteAttachmentSuccess": "Attachment {{ docName }} removed.",
        "successUploadFileMessage": "Attachment {{ docName }} is uploaded"
    },
    "issue": {
        "issue": "[[Issue]]",
        "name": "Name",
        "description": "Description",
        "priority": "Priority",
        "dateOpened": "Date Opened",
        "confirmRemoveHeader": "Remove [[Issue]]",
        "confirmResolveHeader": "Resolve [[Issue]]",
        "confirmResolve": "Are you sure you want to resolve this [[issue]]?",
        "resolveError": "Unable to resolve [[issue]], please try again later.",
        "resolvedBy": "by",
        "hasBeenResolved": "This [[issue]] has been resolved.",
        "assignee": "Assignee",
        "resolutionDetails": "Resolution Detail",
        "resolveSuccess": "[[Issue]] has been resolved.",
        "targetResolutionDate": "Target Resolution Date",
        "targetResolutionPastDue": "Past Due",
        "resolutionDate": "Resolution Date",
        "comments": "Comments",
        "initiatedBy": "Initiated By",
        "resolve": "Resolve",
        "issuesFilterDesc": "Search...",
        "newIssue": "New [[Issue]]",
        "reopen": "Reopen",
        "issueName": "[[Issue]] Name",
        "assignedTo": "Assigned To",
        "opened": "Opened",
        "targetResolution": "Target Resolution",
        "daysPastDue": "Days Past Due",
        "status": "Status",
        "closed": "Resolved",
        "open": "Open",
        "issues": "[[Issues]]",
        "notFoundError": "[[Issue]] Not Found",
        "pastDue": "Past Due",
        "due": "Due",
        "showResolvedIssues": "Show Resolved [[Issues]]",
        "hideResolvedIssues": "Hide Resolved [[Issues]]",
        "issueWithSameNameError": "An [[issue]] with the name {{ issueName }} already exists.",
        "moveSuccess": "[[Issue]] moved",
        "moveError": "[[Issue]] not moved",
        "moveErrorDuplicateName": "[[Issue]] not moved because an [[issue]] with the same name already exists on the destination team"
    },
    "item": {
        "item": "[[Item]]",
        "items": "[[Items]]",
        "scope": "[[Scope]]",
        "team": "[[Team]]",
        "name": "Name",
        "description": "Description",
        "type": "Type",
        "assignedTo": "Assigned To",
        "archived": "Archived",
        "lastVerified": "Last Verified",
        "nextVerification": "Next Verification",
        "lastUpdated": "Last Updated",
        "lastPublished": "Last Published",
        "verifiedStatus": "Status",
        "nextUpdate": "Next Update",
        "daysPastDue": "Days Past Due",
        "priority": "Priority",
        "status": "Status",
        "itemType": "Type",
        "itemPriority": "Priority",
        "itemComplianceCat": "Compliance Category",
        "complianceSchedule": "Compliance Schedule",
        "storageInformation": "Storage Information",
        "associatedDocuments": "Associated Document",
        "comments": "Comments",
        "primaryStorageLocation": "Primary Storage Location",
        "secondaryStorageLocation": "Secondary Storage Location",
        "recoveryDestination": "Recovery Destination",
        "addStorageLocation": "Add Storage Location",
        "editStorageLocation": "Edit Storage Location",
        "addComplianceCategory": "Add Compliance Category",
        "editComplianceCategory": "Edit Compliance Category",
        "createComplianceCategory": "Create New Compliance Category",
        "appendComplianceCategory": "Append Compliance Category",
        "itemTypes": {
            "53e27db8-f119-4b73-9e84-a8b598fbd415": "Document",
            "92c579b0-36cf-46d2-b006-77d2f962c0ce": "Storage [[Item]]",
            "2b727081-d9b1-4f92-b8b4-4e275fe9a396": "Task/Reminder",
            "08b4edfc-1f95-425e-a24b-016ba5cad509": "Other"
        },
        "newItem": "New [[Item]]",
        "verify": "Verify",
        "establish": "Establish",
        "itemsfilterPlaceholder": "Refine results by: Name, Type, or Assigned To Name",
        "documentAvailableForPlan": "[[Plan]] Availability",
        "no": "No",
        "currentTeamOnly": "Current [[Team]] Only",
        "allTeamsAndScopes": "All [[Teams]] And [[Scopes]]",
        "itemWithSameNameError": "An [[item]] with the name {{ itemName }} already exists.",
        "storageWithSameNameError": "A Storage Location with the name {{ storageName }} already exists.",
        "confirmRemoveHeader": "Remove [[Item]]",
        "removeWithDocumentConfirm": "Remove this [[item]] will also remove the document associated to this [[item]]. Are you sure you want to remove this [[item]]?",
        "dueDate": "Due Date",
        "itemWithoutDocumentWarning": "This [[item]] does not have a document and cannot be verified or checked in",
        "thisTeamOnly": "This [[Team]] Only",
        "thisTeamAndSubTeams": "This [[Team]] and Sub [[Teams]]",
        "allTeamsThisScope": "All [[Teams]] this [[Scope]]",
        "allTeamsAllScopes": "All [[Teams]] All [[Scopes]]",
        "moveSuccess": "[[Item]] moved",
        "moveError": "[[Item]] not moved",
        "moveErrorDuplicateName": "[[Item]] not moved because an [[item]] with the same name already exists on the destination team",
        "export": {
            "allTeamMembers": "All Items",
            "displayedTeamMembers": "Displayed Items",
            "header": "Export Items",
            "body": "Do you want to export only the displayed {{ x }} items or all [[team members]]?",
            "successMsg": "Export successfully generated",
            "errorMsg": "Error generating export",
            "fileName": "BulkItemUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Bulk Upload Items",
            "msgBulkUploadInstructions": "Have multiple items you want to add or modify? Choose a correctly formatted file from your computer to start the process.",
            "msgDropFile": "Drop File Here or Click to Select a File",
            "lblSelectedFile": "Selected file to upload",
            "lblNoFileSelected": "No file specified.",
            "lblNeedStartingPoint": "Need a Starting Point",
            "lblAddMultipleHeader": "Adding multiple new items",
            "lblAddMultipleDetail": "Download this spreadsheet containing header information only and fill in your new item's information, one user per row, before saving it in .CSV format to your computer.",
            "btnBlankFile": "Blank Item File",
            "lblEditMultipleHeader": "Edit or Delete multiple items",
            "lblEditMultipleDetail": "Download this spreadsheet containing your current item information and modify as needed before saving it in .CSV format to your computer.",
            "btnCurrent": "Current Items",
            "lblEditMultipleNotes": "NOTE: To delete items, change their status to Delete. Removing them from the spreadsheet will not delete them from the system. The bulk upload can be completed even if you do not fill in every field for an item. To see which fields are optional, refer to your user guide or help area in the top right corner of this window.",
            "lblUpload": "Submit",
            "btnCancel": "Cancel",
            "errorUserExportFailed": "Unable to export an item file.",
            "msgUserExportSuccessful": "Item export completed successfully.",
            "msgTestBulkUploadFile": "This file will only be validated because the file name contains the word \"test\"; updates will not be processed.",
            "msgUploadSuccess": "Bulk upload successful.",
            "msgValidatingFile": "Verifying file...",
            "errorInvalidBulkFileType": "The selected file has an incorrect file extension it must have an .CSV extension.",
            "errorExportFailed": "Unable to export an item file.",
            "msgExportSuccessful": "Export completed successfully."
        }
    },
    "itemDocument": {
        "dropFile": "Drop File Here",
        "selectFile": "Select File",
        "selectFiles": "Select Files",
        "uploadingFile": "Uploading File",
        "uploadDocument": "Upload Document",
        "uploadDocuments": "Upload Documents",
        "selectDocument": "Select Document",
        "showHideDocument": "Toggle to Hide or Show Documents",
        "uploadFile": "Choose a file from your computer to upload, or drag and drop your file onto the window.",
        "confirmRemoveHeader": "Remove Document",
        "removeConfirm": "Are you sure you want to remove this document?",
        "homeFolderName": "Home",
        "saveSuccess": "Document {{name}} saved",
        "saveFail": "Fail to save Document",
        "removeSuccess": "Document removed",
        "removeFail": "Fail to remove document",
        "downloadDocument": "Download Document",
        "documentVersions": "Document Versions",
        "removeDocument": "Remove Document",
        "createdDate": "Created Date",
        "createdBy": "Created By",
        "compare": "Compare",
        "documentComparisonTypeNotSupport": "Only Microsoft Word documents are supported for comparison.",
        "documentComparisonFailed": "Failed to compare documents",
        "verified": "Verified",
        "compared": " (compared)",
        "noAttachments": "No Attachments",
        "show": "Show",
        "hide": "Hide"
    },
    "confirmMessages": {
        "saveConfirm": "Are you sure you want save this {{name}}?",
        "removeConfirm": "Are you sure you want to remove this {{name}}?"
    },
    "removeAttachmentConfirmation": {
        "header": "Remove Attachment",
        "body": "Are you sure you want to remove attachment {{ docName }}?",
        "btnText": "Remove"
    },
    "removeExerciseConfirmation": {
        "header": "Remove Exercise",
        "body": "Are you sure you want to remove exercise {{ exerciseName }}?",
        "btnText": "Remove"
    },
    "team": {
        "teamName": "[[Team]] Name",
        "responsibilities": "Responsibilities",
        "mission": "Mission",
        "assemblyTimeObjective": "Assembly Time Objective (ATO)",
        "recoveryTimeObjective": "Recovery Time Objective (RTO)",
        "recoveryPointObjective": "Recovery Point Objective (RPO)",
        "confirmRemoveHeader": "Remove [[Team]]",
        "confirmSaveHeader": "Save [[Team]]",
        "teamFilterDesc": "Refine results by [[team]] name",
        "newTeam": "New [[Team]]",
        "teamCountMessage": "Showing {{ x }} of {{ y }} total",
        "confirmMoveMessage": "Are you sure you want to move {{ childTeamName }} to {{ toTeamName }}?",
        "editTeams": "Edit [[Teams]]",
        "newTeamHeader": "New [[Team]]",
        "newTeamName": "New [[Team]] Name",
        "addTeam": "Add [[Team]]",
        "teamWithSameNameError": "A [[team]] with the name {{ teamName }} already exists.",
        "cannotRemoveError": "Cannot remove [[team]] with active sub [[teams]] or [[team members]]",
        "copyTeam": "Copy [[Team]]",
        "copyTeamModal": {
            "header": "Copy [[Team]] options",
            "selectDestination": "Select destination [[scope]] or [[team]]",
            "selectCopyOptions": "Options",
            "copyTeamMembers": "Copy all [[Team Members]]",
            "copyHierarchy": "Include Sub-[[teams]]",
            "deepCopy": "Deep Copy",
            "copyItems": "Copy all [[Items]]",
            "copyIssues": "Copy all [[Issues]]",
            "copyAssets": "Copy all [[Assets]]",
            "copyExternalResources": "Copy all [[External Resources]]",
            "copyTeamSuccess": "Successfully copied [[team]] {{teamName}}",
            "copyTeamError": "Failed to copy [[team]] {{teamName}}"
        },
        "comments": "Comments",
        "comment": {
            "saveError": "Comment not saved",
            "deleteError": "Comment not removed"
        }
    },
    "help": {
        "scope": {
            "scopeName": "The [[scope]] name is the top-most grouping of [[teams]]. It must be unique and is printed in the header of each [[report]]. It may be up to 50 characters long.",
            "mission": "The mission statement explains the purpose of the [[scope]] and is printed at the beginning of the mission statement [[report]]. It may be up to 4,000 characters long.",
            "assumptions": "Assumptions are printed in the mission statement [[report]]. They may be up to 4,000 characters long.",
            "coordinatorName": "The coordinator name is the person responsible for recovery planning of this [[scope]].  The name is printed on each recovery [[plan]]'s front cover."
        },
        "team": {
            "teamName": "The [[team]] name must be unique within each [[scope]]. It may be up to 50 characters long",
            "mission": "The mission statement explains the purpose of the [[team]]. It is also displayed in the [[team]] mission [[report]]. It may be up to 4,000 characters long.",
            "responsibilities": "Responsibilities describe the [[team]]'s primary objectives. It is printed in the [[team]] mission [[report]]. It may be up to 4,000 characters long.",
            "planHeader": "The [[plan]] header is printed on the front cover page of the [[team]]'s recovery [[plan]]. It may be up to 50 characters long.",
            "assemblyTimeObjective": "The ATO is the planned elapsed time between an event notification and [[team]] assembly. [[Team]] assembly is defined as the moment in time that the [[team]] is positioned to address its recovery mission and responsibilities for its internal users and external [[customers]]. It is represented in hours and minutes.",
            "recoveryTimeObjective": "The RTO is the planned elapsed time between an event notification and full [[team]] operation at a level supporting its internal users and external [[customers]]. It is represented in hours and minutes.",
            "recoveryPointObjective": "The RPO is the maximum amount of time, prior to an event, in which data loss is acceptable. Essential data and information must be restored up until at least this point in time. It is represented in hours and minutes. (Leave blank if all data and information must be restored.)",
            "copyTeamModal": {
                "copyHierarchy": "Copy only one [[team]] or the [[team]] and all sub-[[teams]]",
                "deepCopy": "Copy [[Team Members]] and selected resource types ([[Assets]], [[Items]], [[Issues]], [[External Resources]]) for copied [[Teams]]",
                "copyTeamMembers": "[[Team Members]] must be copied for coping resources ([[Assets]], [[Items]], [[Issues]], [[External Resources]])",
                "copyItems": "Copy all [[Items]] for copied [[Teams]]",
                "copyIssues": "Copy all [[Issues]] for copied [[Teams]]",
                "copyAssets": "Copy all [[Assets]] for copied [[Teams]]",
                "copyExternalResources": "Copy all [[External Resources]] for copied [[Teams]]. [[External Resources]] must be copied in order to copy [[Assets]] as an [[Asset]] may rely on the [[External Resource]]."
            }
        },
        "teamMember": {
            "member": "[[Member]] represents the user account belonging to this [[team member]].",
            "assignment": "Assignment represents this [[team member]]'s responsibilities on the [[team]].",
            "planCount": "The number of copies of the [[plan]] that have been issued to this [[team member]].",
            "teamPlanCount": "The number of copies of the [[team]]'s [[plan]] that have been issued to this [[team member]].",
            "planIssueDate": "The date that the copies of the [[plan]] were issued to this [[team member]].",
            "teamPlanIssueDate": "The date that the copies of the [[team]] [[plan]] were issued to this [[team member]].",
            "isResponsible": "This person is accountable for the [[team]].",
            "expired": "Expired, needs verification.",
            "verified": "Verified"
        },
        "issue": {
            "descriptionTooltip": "Description of the [[issue]] may be up to 512 characters long.",
            "assigneeTooltip": "Assignee is responsible for seeing that the [[issue]] gets resolved.",
            "targetResolutionDateTooltip": "Target resolution date is the date this [[issue]] needs to be resolved by.",
            "resolutionDateTooltip": "Resolution date is the date this [[issue]] was marked resolved.",
            "priorityTooltip": "Priority is the level this [[issue]] adversely affects the recovery effort.",
            "nameTooltip": "Name identifies the [[issue]] and must be unique per [[team]].",
            "dateOpened": "Date opened is when this [[issue]] was recorded.",
            "initiatedBy": "The user that initiated the [[issue]]."
        },
        "externalResource": {
            "externalResourceName": "Name of a company, location or individual that is outside the organization. It may be 50 characters in length.",
            "typeTooltip": "Type of external company, location or individual outside the organization.",
            "isCritical": "Is this [[external resource]] critical to the recovery [[process]]?",
            "isRecoveryLocation": "Is this [[external resource]] a recovery location?",
            "addExternalResourceType": "Add a new [[external resource]] type",
            "editExternalResourceType": "Edit [[external resource]] type",
            "compareWithVerified": "Compare last saved version with verified version"
        },
        "item": {
            "primaryStorageLocationTooltip": "The primary storage location is the the intended place to store the recovery [[item]].",
            "secondaryStorageLocationTooltip": "The secondary storage location is an additional place to store the recover [[item]].",
            "recoveryDestinationTooltip": "The recovery destination is the place to relocate the storage [[item]] to during an emergency.",
            "documentAvailableForPlanTooltip": "Denotes the level this document [[item]] is available to be used in [[plans]] for other [[teams]] and/or [[scopes]]",
            "nameTooltip": "Name identifies the recovery [[item]] and must be unique per [[team]]. It may be up to 50 characters long.",
            "descriptionTooltip": "Description of the recovery [[item]] may be up to 512 characters long.",
            "itemTypeTooltip": "Type of recovery [[item]].",
            "itemPriorityTooltip": "Priority of the recovery [[item]] in the event of an emergency.",
            "addStorageLocationInstructions": "Identify and describe the new storage location.",
            "editStorageLocationInstructions": "Re-identify or describe the existing storage location.",
            "addComplianceCategoryInstructions": "Identify and describe the new compliance category.",
            "editComplianceCategoryInstructions": "Re-identify or describe the existing compliance category.",
            "compareWithVerified": "Compare last saved version with verified version"
        },
        "asset": {
            "nameTooltip": "Name of [[asset]] needed in the recovery effort. It may be up to 50 characters long.",
            "assetLocationSiteTooltip": "Place where [[asset]] is required to be located during recovery effort.",
            "compareWithVerified": "Compare last saved version with verified version"
        },
        "assetAllocation": {
            "allocationCountTooltip": "Count of assets required at the recovery site within an associated time frame.",
            "requirementTypeTooltip": "Time frame for gathering required assets at the recovery site."
        },
        "establishByCard": {
            "dateCreatedTooltip": "Date this resource was first defined.",
            "establishByDateTooltip": "Establish the existence of this resource by this date."
        },
        "planStructure": {
            "planHeader": "The contents of this field are printed on the front cover page of each recovery [[plan]] that is generated for the [[scope]]. ",
            "compareWithVerified": "Compare last saved version with verified version",
            "planCover": "This area may be used to override the text that appears on the default recovery [[plan]] cover page. When overriding the [[plan]] cover text, you may need to insert hard carriage returns at the beginning of this area (by pressing the Enter key) in order to center the text between the [[plan]] cover and [[plan]] date fields. This area may contain a maximum of 4,000 characters of information. If this area is empty, then the default system [[plan]] cover text will be used.",
            "useFirstDocumentAsCoverPage": "You are allowed to supply your own cover page to the [[plan]] that is generated. By selecting 'Yes' the first document in your [[plan]] structure will be used as the cover page."
        },
        "notificationSetting": {
            "verificationFrequencyTooltip": "Select the verification frequency to target with this notification",
            "notificationOverdueValueTooltip": "Specify the value of time after the due date to trigger this notification",
            "notificationDueSoonValueTooltip": "Specify the value of time before the due date to trigger this notification",
            "iQEmailFromNameTooltip": "Specify the value of the From Name for all notification emails",
            "datepartTypeTooltip": "Specify the unit of time for the value above",
            "jobTypeTooltip": "Select which type of notification to send",
            "editOverdueNotificationSetting": "Edit the overdue notification [[setting]] for {{ a }} activities",
            "addOverdueNotificationSetting": "Add an overdue notification [[setting]] for {{ a }} activities",
            "editDueSoonNotificationSetting": "Edit the due soon notification [[setting]] for {{ a }} activities",
            "addDueSoonNotificationSetting": "Add a due soon notification [[setting]] for {{ a }} activities"
        },
        "complianceCategory": {
            "nameTooltip": "Name identifies the compliance category and must be unique across the system. It may be up to 50 characters long.",
            "descriptionTooltip": "Description of the compliance category may be up to 512 characters long.",
            "complianceCatTooltip": "Compliance category that requires this recovery resource. Select all that apply."
        },
        "storageLocation": {
            "nameTooltip": "Name identifies the storage location and must be unique across the system. It may be up to 50 characters long.",
            "descriptionTooltip": "Description of the storage location may be up to 512 characters long."
        },
        "externalResourceType": {
            "nameTooltip": "Name identifies the [[external resource]] type and must be unique across the system. It may be up to 50 characters long.",
            "descriptionTooltip": "Description of the [[external resource]] type may be up to 512 characters long."
        },
        "incident": {
            "incidentAttachmentsTooltip": "Attach documents to the incident."
        }
    },
    "done": "Done",
    "finished": "Finished",
    "scopeTeamModal": {
        "header": "Please make your selection",
        "requireScopeDescription": "Select a [[Scope]] to continue. This will affect [[teams]] that you are able to view. Don't worry. You can change this later.",
        "requireTeamDescription": "Select a [[Team]] to continue. This will affect [[team]] resources that you are able to view. Don't worry. You can change this later.",
        "requireScopeIdAndTeamIdError": "Please make your selection(s) before continuing."
    },
    "newTeamModal": {
        "header": "Create New [[Team]]",
        "confirmButtonText": "Continue"
    },
    "complianceSchedule": {
        "verify": "Verify",
        "lastUpdated": "Last Verified",
        "lastUpdatedTooltip": "Last verified represents the date when the last verification occurred.",
        "nextUpdate": "Next Verification",
        "nextUpdateTooltip": "Next verification represents the date when the next verification should occur by.",
        "teamMember": "[[Team Member]]",
        "teamMemberTooltip": "[[Team Member]] who is primarily responsible for verifying the readiness of the resource. [[Team Members]] in this list must already be verified themselves.",
        "updateFrequency": "Update Frequency",
        "updateFrequencyTooltip": "Update frequency represents the interval of time between each verification.",
        "notVerified": "Never been verified. Please verify.",
        "updateFrequencyOptions_7": "Weekly",
        "updateFrequencyOptions_30": "Monthly",
        "updateFrequencyOptions_31": "Monthly on the last day of the month",
        "updateFrequencyOptions_90": "Quarterly",
        "updateFrequencyOptions_91": "Quarterly on the last day of the month",
        "updateFrequencyOptions_180": "Semiannual",
        "updateFrequencyOptions_181": "Semiannual on last day of the month",
        "updateFrequencyOptions_365": "Annual",
        "updateFrequencyOptions_365000": "N/A",
        "complianceSchedule": "Compliance Schedule",
        "updateLastVerified": "Update Last Verification Date",
        "updateLastVerifiedTooltip": "Update Last Verification Date",
        "nextVerificationDateBeforeLastVerified": "Next Verification Date cannot be before Last Verified date. Adjusting verification interval start date."
    },
    "comments": {
        "addComment": "Add {{ name }}",
        "newComment": "New {{ name }}",
        "on": "on",
        "deleteComment": "Delete",
        "deleteCommentHeader": "Delete {{ name }}",
        "deleteCommentBody": "Are you sure you want to delete this {{ name }}?"
    },
    "select": "Select",
    "iQ": "iQ",
    "No": "No",
    "new": "New",
    "change": "change",
    "scopeTeamModalTooltip": "Change [[Scope]] and/or [[Team]]",
    "ComplianceCategoriesSelector": {
        "complianceCategory": "Compliance Category",
        "complianceCategoryTooltip": "Compliance Category",
        "createComplianceCategory": "+",
        "complianceCategoryWithSameNameError": "A Compliance Category with the name {{ name }} already exists."
    },
    "iqPriorities": {
        "086deec5-b113-4cc9-8024-d1f1ddb62e8d": "Critical",
        "0b75aa95-3100-43b0-9cf7-560fb4607a47": "High",
        "31b51337-4a66-4cce-9c54-0daa8c6831c9": "Medium",
        "b7e4e27d-b0de-4936-858a-3fa07e0f3b68": "Low"
    },
    "view": "View",
    "noComplianceCategory": "No Compliance Category",
    "noItemsPopoverText": "No [[Items]]",
    "externalResource": {
        "scopeAssignment": "[[Scope]] Assignment",
        "resourceName": "Resource Name",
        "type": "Type",
        "assignedTo": "Assigned To",
        "recoveryLocationSite": "Recovery Site",
        "verifiedDate": "Verified Date",
        "status": "Status",
        "externalResources": "[[External Resources]]",
        "externalResourcesFilterPlaceholder": "Refine results by: Resource Name or Assigned To",
        "newExternalResource": "New [[External Resource]]",
        "externalResource": "[[External Resource]]",
        "externalResourceName": "[[External Resource]] Name",
        "isCritical": "This is a crisis resource",
        "isRecoveryLocation": "This is a Recovery Site",
        "contacts": "Contacts",
        "resourceDetails": "Resource Details",
        "comments": "Comments",
        "address": "Street",
        "city": "City",
        "state": "State",
        "zip": "Zip/Postal Code",
        "country": "Country",
        "webSite": "Website",
        "primary": "Primary",
        "name": "Name",
        "phone": "Phone",
        "email": "Email",
        "addContact": "Add Contact",
        "firstName": "First Name",
        "lastName": "Last Name",
        "confirmRemoveHeader": "Remove [[External Resource]]",
        "removeConfirm": "Are you sure you want to remove this [[external resource]]?",
        "addType": "Add [[External Resource]] Type",
        "editType": "Edit [[External Resource]] Type",
        "externalResourceTypeDescription": "Description",
        "externalResourceTypeName": "Name",
        "verify": "Verify",
        "establish": "Establish",
        "contact": "Contact",
        "usedByAssetsAsLocationSite": "This [[external resource]] is currently used by [[assets]] as locations site",
        "confirmRemoveContactHeader": "Remove Contact",
        "confirmRemoveContactBody": "Are you sure you want to remove this contact?",
        "primaryContact": "Primary Contact",
        "update": "Update",
        "confirmNewPrimaryContactHeader": "Update Primary Contact",
        "confirmNewPrimaryContactBody": "A primary contact already exists. This contact will be the new primary contact.",
        "lastVerified": "Last Verified",
        "nextVerification": "Next Verification",
        "daysPastDue": "Days Past Due",
        "dueDate": "Due Date",
        "externalResourceWithSameNameError": "An [[external resource]] with the name {{ externalResourceTypeName }} already exists.",
        "getExternalResourceTypesError": "Error retrieving [[external resource]] types",
        "filterExternalResourceTypesDescription": "Refine results by name",
        "newExternalResourceType": "New [[External Resource]] Type",
        "externalResourceType": "[[External Resource]] Type",
        "externalResourceTypes": "[[External Resource]] Types",
        "confirmRemoveExternalResourceType": {
            "header": "Remove [[External Resource]] Type",
            "body": "Are you sure you want to remove this {{ objectType }} named {{ name }}?",
            "text": "Remove"
        },
        "confirmSaveExternalResourceType": {
            "header": "Save [[External Resource]] Type",
            "body": "Are you sure you want to save this {{ objectType }} named {{ name }}?",
            "text": "Save"
        },
        "externalResourceTypeAssignedToResourceError": "Cannot remove {{ name }} with [[external resources]] assigned this type",
        "moveSuccess": "[[External Resource]] moved",
        "moveError": "[[External Resource]] not moved",
        "moveErrorDuplicateName": "[[External Resource]] not moved because an [[external resource]] with the same name already exists on the destination team",
        "moveTeamTooltip": "Select the destination team. If an [[external resource]] is defined as a recovery site and has an asset assigned to it, it may only be moved to teams within the same scope."
    },
    "asset": {
        "asset": "[[Asset]]",
        "assetName": "[[Asset]] Name",
        "assignedTo": "Assigned To",
        "storageLocation": "Storage Location",
        "verifiedDate": "Verified Date",
        "status": "Status",
        "assetFilterPlaceHolder": "Refine results by: [[Asset]] Name or Assigned To",
        "newAsset": "New [[Asset]]",
        "verify": "Verify",
        "establish": "Establish",
        "locationSite": "Recovery Site",
        "complianceSchedule": "Compliance Schedule",
        "assetRequirement": "[[Asset]] Requirement",
        "allocation": {
            "assetRequirement": "[[Asset]] Requirement",
            "allocationCount": "Count",
            "allocationType": "Type",
            "confirmRemoveHeader": "Remove Allocation Assignment",
            "confirmRemoveMessage": "Are you sure that you want to remove this [[asset]] requirement of count \"{{ x }}\" and type \"{{ y }}\"?",
            "add": "Add Requirement"
        },
        "comments": "Comments",
        "comment": {
            "saveError": "Comment not saved",
            "deleteError": "Comment not removed"
        },
        "saveSuccess": "[[Asset]] saved",
        "saveError": "[[Asset]] not saved",
        "confirmRemoveHeader": "Remove [[Asset]]",
        "confirmRemoveMessage": "Are you sure that you want to remove this {{ name }}?",
        "lastVerified": "Last Verified",
        "nextVerification": "Next Verification",
        "daysPastDue": "Days Past Due",
        "assetWithSameNameError": "An [[asset]] with the name {{ assetName }} already exists.",
        "dueDate": "Due Date",
        "moveSuccess": "[[Asset]] moved",
        "moveError": "[[Asset]] not moved",
        "moveErrorDuplicateName": "[[Asset]] not moved because an [[asset]] with the same name already exists on the destination team",
        "moveRecoverySiteTooltip": "Select the recovery site. If an [[asset]] is moved to a team belonging to a different scope, a new recovery site must be selected from within that scope."
    },
    "userAssignment": {
        "title": "Assignments",
        "teamMembers": "[[Team Members]]",
        "items": "[[Items]]",
        "issues": "[[Issues]]",
        "externalResources": "[[External Resources]]",
        "assets": "[[Assets]]"
    },
    "yes": "Yes",
    "no": "No",
    "allowed": "Allowed",
    "notAllowed": "Not Allowed",
    "confirmDialog": {
        "confirmSaveAndVerify": {
            "header": "Save Verify {{ objectType }}",
            "body": "You have unsaved changes on the page. Do you want to save and verify the {{ name }}?",
            "text": "Save and Verify"
        },
        "confirmVerify": {
            "header": "Verify {{ objectType }}",
            "body": "Do you want to verify the {{ name }}?",
            "text": "Verify"
        },
        "confirmEstablish": {
            "header": "Establish {{ objectType }}",
            "body": "Do you want to establish the {{ name }}?",
            "text": "Establish",
            "establishedDateInPast": "Selected Establish by date is in the past. When saved the {{ name }} will be established on {{ date }}."
        },
        "confirmApprove": {
            "header": "Approve {{ objectType }}",
            "body": "Do you want to Approve the {{ name }}?",
            "text": "Approve"
        },
        "confirmCheckIn": {
            "header": "Check in {{ objectType }}",
            "body": "Do you want to Check in the {{ name }}?",
            "text": "Check In"
        },
        "confirmDecline": {
            "header": "Decline {{ objectType }}",
            "body": "Do you want to Decline the {{ name }}?",
            "text": "Decline"
        },
        "remove": {
            "header": "Remove {{ name }}",
            "body": "Are you sure you want to remove this {{ name }}?",
            "text": "Remove"
        },
        "confirmSave": {
            "header": "Save {{ objectType }}",
            "body": "Do you want to save the {{ name }}?",
            "text": "Save"
        },
        "confirmUpdateLastVerified": {
            "header": "Update Last Verified Date",
            "body": "Do you want to update Last Verification date?",
            "text": "Update"
        },
        "confirmUpdateLastVerifiedDateOutOfOrder": {
            "header": "Update Last Verified Date",
            "body": "Next Verification Date cannot be before or equal to the Last Verified date. Verification interval start date will be adjusted.",
            "text": "Update"
        },
        "confirmAssignToSelf": {
            "header": "Assign {{ objectType }} to Me",
            "body": "Do you want to assign \"{{ name }}\" to you?",
            "text": "Assign"
        },
        "confirmArchiveResource": {
            "header": "Archive {{ objectType }}",
            "body": "Do you want to archive \"{{ name }}\"",
            "text": "Archive"
        },
        "confirmUnArchiveResource": {
            "header": "Restore {{ objectType }}",
            "body": "Do you want to restore \"{{ name }}\"",
            "text": "Restore"
        },
        "confirmUnsavedChanges": {
            "header": "Unsaved Changes",
            "body": "You have unsaved changes on this page. Are you sure that you want to discard them?",
            "text": "Discard Changes"
        }
    },
    "rowCountMessage": "Showing {{ x }} of {{ y }} total",
    "noRowCountMessage": "Showing 0 of 0 total",
    "more": "More",
    "all": "All",
    "readMore": "Read More",
    "readLess": "Read Less",
    "common": {
        "rpo": "RPO",
        "scope": "[[Scope]]",
        "team": "[[Team]]",
        "complianceCategory": "Compliance Category",
        "search": "Search",
        "comment": "Comment",
        "noComments": "No Comments Available",
        "noAttachments": "No Attachments",
        "note": "Note",
        "edit": "Edit",
        "deselect": "Deselect",
        "name": "Name",
        "save": "Save",
        "add": "Add",
        "requireApproval": "Require Approval",
        "checkIn": "Check In",
        "decline": "Decline",
        "approve": "Approve",
        "type": "Type",
        "approvedBy": "Approved by {{ name }}",
        "checkedInBy": "Checked In by {{ name }}",
        "declinedBy": "Declined by {{ name }}",
        "msgFileExceedMaxSize": "File size exceed the max limit of {{ size }}MB",
        "msgErrorDownloadFile": "Failed to download attachment {{ fileName }}",
        "msgErrorAttachmentExists": "Attachment with same name already exists, would you like to replace the existing attachment or save as a new attachment?",
        "msgAttachmentWithSameNameExist": "Attachment with same file name already exist.",
        "msgVirusDetected": "Virus detected in this file",
        "msgFailProcessFile": "Unable to process file, please try again.",
        "msgFailCloudmersiveAPI": "Unable to validate file, please try again later.",
        "geolocations": "Geolocations",
        "next": "Next",
        "previous": "Previous",
        "no": "No",
        "yes": "Yes",
        "cancel": "Cancel",
        "accept": "Accept",
        "close": "Close",
        "copy": "Copy",
        "back": "Back",
        "roles": "Roles",
        "or": "Or",
        "ok": "OK",
        "more": "more",
        "finish": "Finish",
        "andAreIn": "and are in",
        "tenants": "Tenants",
        "groups": "Groups",
        "departments": "[[Departments]]",
        "locations": "Locations",
        "companies": "Companies",
        "everyone": "Everyone",
        "noSearchResult": "No search result",
        "role": "Role",
        "group": "Group",
        "tenant": "Tenant",
        "department": "[[Department]]",
        "location": "[[Location]]",
        "entities": "Entities",
        "enabled": "Enabled",
        "disabled": "Disabled",
        "date": "Date",
        "time": "Time",
        "timezone": "Timezone",
        "remove": "Remove",
        "others": "Others",
        "submit": "Submit",
        "update": "Update",
        "confirmation": "Confirm",
        "sms": "SMS",
        "email": "Email",
        "default": "Default",
        "pageSize": "Page Size",
        "pageOptionShowAll": "Show All",
        "prev": "Prev",
        "deleted": "Deleted",
        "attachments": "Attachments",
        "assignToSelf": "Assign to Me",
        "archive": "Archive",
        "delete": "Delete",
        "selected": "Selected",
        "includeArchived": "Include Archived",
        "archived": "Archived",
        "restore": "Restore",
        "loadMore": "Load More",
        "all": "All",
        "unknown": "Unknown",
        "_emptyString": "",
        "reload": "Reload",
        "reloading": "Reloading",
        "showAdvancedFilter": "Show Advanced Filter",
        "showSimpleFilter": "Hide Advanced Filter",
        "filterStartDate": "Start Date",
        "filterEndDate": "End Date",
        "listSortAriaLabel": "Activate to sort",
        "listSortAriaLabelDesc": "Activate to sort in descending order",
        "listSortAriaLabelAsc": "Activate to sort in ascending order",
        "lblNA": "N/A",
        "defaultLocation": "Default [[Location]]",
        "both": "Both",
        "displayingXOutOfY": "Displaying {{displaying}} out of {{total}}",
        "additionalLocations": "Additional Locations",
        "multiple": "Multiple",
        "lblNewFolder": "Create New Folder",
        "lblUploadFile": "Upload Document",
        "lblUploadFiles": "Upload Documents",
        "lblSelectFile": "Select Document",
        "lblDeleteDocument": "Delete Document",
        "lblDeleteFolder": "Delete Folder",
        "lblMoveDocument": "Move Document",
        "lblMoveFolder": "Move Folder",
        "lblBookmarkDocument": "Bookmark Document",
        "lblDownloadDocument": "Download Document",
        "lblBookmarkName": "Bookmark Name",
        "lblNewDocumentBookmark": "Create Document Bookmark",
        "lblNewPageBookmark": "Create Page Bookmark",
        "msgSuccessAddBookmark": "Bookmark {{ name }} created successfully",
        "msgErrorAddBookmark": "Failed to create bookmark {{ name }}",
        "completed": "Completed {{date}}",
        "bookmark": "Bookmark",
        "document": "Document",
        "refresh": "Refresh",
        "showingXofX": "Showing {{count}} of {{total}}",
        "enterprise": "Enterprise",
        "realEstate": "Real Estate",
        "undo": "Undo",
        "new": "New",
        "export": "Export",
        "createdOn": "Created on",
        "lastModifiedOn": "Last modified on",
        "Required": "(required)",
        "lastModifiedBy": "Last modified by",
        "createdBy": "Created by",
        "selectOne": "Select one",
        "create": "Create",
        "upload": "Upload",
        "resetTable": "Reset table",
        "msgSecurityErrorTitle": "Security Error",
        "msgSecurityErrorMessage": "You do not have permission to access the requested page",
        "msgBcIndustryErrorMessage": "Your company does not have industry specified for business continuity planning",
        "msgBcAssignedProcessErrorMessage": "You don't have any assigned [[processes]] for this [[department]]",
        "msgBcAssignedDepartmentErrorMessage": "You don't have any assigned [[departments]]",
        "msgBcAssignedFunctionalAreasErrorMessage": "You don't have any assigned [[departments]]",
        "msgBcPlanErrorMessage": "There is no business continuity plan created for business continuity planning",
        "msgNoAssignments": "You don't have any assignments for business continuity plan",
        "msgGeneralErrorMessage": "System Error. Please try again later.",
        "msgNoIncidentErrorMessage": "There is no active incident associated to activation plans.",
        "msgRequireOpenIncidentErrorMessage": "This incident is resolved.",
        "msgRequireResolvedIncidentErrorMessage": "This incident is still active.",
        "msgBcManagerErrorMessage": "Your company does not have business continuity manager specified for planning",
        "deactivated": "Deactivated",
        "active": "Active",
        "backToTop": "Back to top",
        "none": "None",
        "move": "Move",
        "preview": "Preview",
        "feet": "Feet",
        "mile": "Mile",
        "meter": "Meter",
        "kilometer": "Kilometer",
        "height": "Height",
        "width": "Width",
        "today": "Today",
        "showMore": "Show More...",
        "showLess": "Show Less",
        "miles": "Miles",
        "meters": "Meters",
        "kilometers": "Kilometers",
        "exit": "Exit",
        "exitSave": "Exit & Save",
        "members": "[[Members]]",
        "dashboard": "Dashboard",
        "assign": "Assign",
        "delegate": "Delegate",
        "unassign": "Unassign",
        "unassigned": "Unassigned",
        "rename": "Rename",
        "loading": "Loading...",
        "started": "Started",
        "status": "Status",
        "revisit": "Revisit",
        "continue": "Continue",
        "start": "Start",
        "navigate": "Navigate",
        "resend": "Resend",
        "view": "View",
        "help": "Help",
        "closed": "Closed",
        "open": "Open",
        "title": "Title",
        "owner": "Owner",
        "description": "Description",
        "issues": "[[Issues]]",
        "reports": "[[Reports]]",
        "generate": "Generate",
        "download": "Download",
        "generateAll": "Generate All Plans",
        "downloadAll": "Download All",
        "clearFilters": "Clear Filters",
        "inactive": "Inactive",
        "noRecords": "No Records",
        "created": "Created",
        "updated": "Updated",
        "removed": "Removed",
        "saved": "Saved",
        "downloading": "Downloading...",
        "uploading": "Uploading...",
        "deleting": "Removing...",
        "chart": "Chart",
        "descriptions": "Descriptions",
        "end": "End",
        "never": "Never",
        "on": "On",
        "until": "Until",
        "at": "At",
        "assigned": "Assigned",
        "hide": "Hide",
        "unhide": "Unhide",
        "showHidden": "Show Hidden",
        "comments": "Comments",
        "criticalDate": "Critical Date",
        "strategy": "Strategy",
        "goToDetails": "Go To Details",
        "getStarted": "Get Started",
        "viewDetails": "View Details",
        "clear": "Clear",
        "apply": "Apply",
        "empty": "Empty",
        "aToZ": "A To Z",
        "recovered": "Recovered",
        "notActivated": "Not Activated",
        "saveAndClose": "Save and Close",
        "nA": "N/A",
        "saving": "Saving",
        "translate": "Translate",
        "address": "Address",
        "state": "State",
        "zip": "Zip",
        "country": "Country",
        "city": "City",
        "primary": "Primary",
        "contacts": "Contacts",
        "phone": "Phone",
        "website": "Website",
        "select": "Select",
        "language": {
            "en": "English",
            "es": "Spanish",
            "fr": "French",
            "pt": "Portuguese"
        },
        "reset": "Reset",
        "hours": "Hours",
        "days": "Days",
        "day": "Day",
        "hour": "Hour",
        "original": "Original",
        "current": "Current",
        "edits": "Edits",
        "expiration": "Expiration",
        "copyToClipboard": "Copy to clipboard",
        "addLocation": "Add [[Location]]",
        "addDependency": "Add Dependency",
        "survey": "Survey",
        "system": "System",
        "dependencies": "Dependencies",
        "processes": "[[Processes]]",
        "thereArentMoreItemsAvailable": "There aren't more items available to select",
        "loadingMap": "Loading map...",
        "success": "Success",
        "schedule": "Schedule",
        "updatedBy": "Updated By",
        "updatedDate": "Updated Date",
        "launch": "Launch",
        "resolved": "Resolved",
        "selectedItemsLabel": "{0} items selected",
        "support": "Support",
        "low": "Low",
        "medium": "Medium",
        "high": "High",
        "formula": "Formula",
        "company": "Company",
        "continueConfirmation": "Do you want to continue?",
        "target": "Target",
        "approver": "Approver",
        "dueDate": "Due Date",
        "recipient": "Recipient",
        "searchResults": "Search Results",
        "headerLabel": "Select User",
        "searchUserLabel": "Search User",
        "currentUserLabel": "Current User",
        "resultLabel": "Result",
        "notAvailable": "Not Available",
        "abandoned": "Abandoned",
        "abandon": "Abandon",
        "waitingApproval": "Waiting Approval",
        "inProgress": "In Progress",
        "selectColumns": "Select Columns",
        "msgrExportSuccessful": "{{targetType}} export completed successfully.",
        "awaitingApproval": "Awaiting Approval",
        "pastDue": "Past Due",
        "closedOn": "Close Date",
        "errorExportFailed": "Unable to export a {{targetType}} file.",
        "unAssociate": "Unassociate",
        "associate": "Associate",
        "approved": "Approved",
        "assessments": "Assessments",
        "paginationReport": "Showing {first} to {last} of {{totalRowCount}}",
        "overTime": "Over Time",
        "alternates": "Alternates",
        "authority": "Authority",
        "triggers": "Triggers",
        "limitations": "Limitations",
        "primaries": "Primary(s)",
        "delegates": "Delegates",
        "warning": "Warning",
        "unSavedChangesWarning": "You have unsaved changes, are you sure you want to leave this page?",
        "undoWarning": "You have unsaved changes, are you sure you want to undo all changes?",
        "itemsToComplete": "Items to complete",
        "details": "Details",
        "duplicate": "Duplicate",
        "available": "Available",
        "heading": "Heading",
        "body": "Body",
        "revert": "Revert",
        "reverted": "Reverted",
        "publish": "Publish",
        "draft": "Draft",
        "version": "Version",
        "published": "Published",
        "landscape": "Landscape",
        "portrait": "Portrait",
        "enterTitle": "Enter title here",
        "enterDescription": "Enter description here",
        "tags": "Tags",
        "orientation": "Orientation",
        "pageBreak": "Page Break",
        "coverPage": "Cover Page",
        "promote": "Promote",
        "generalOptions": "General Options",
        "markCompleteContinue": "Mark Complete & Continue",
        "sendEmailContinue": "Send Email & Continue",
        "keyPersonnel": "Key Personnel",
        "responseTeams": "Response Teams",
        "buildManage": "Build & Manage",
        "track": "Track",
        "skip": "Skip",
        "missionStatement": "Mission Statement",
        "introduction": "Introduction",
        "discard": "Discard",
        "general": "General",
        "makeSystem": "Make System",
        "annex": "Annex",
        "sections": "Sections",
        "taskedAgencies": "Tasked Agencies",
        "responsibilities": "Responsibilities",
        "what": "What",
        "where": "Where",
        "action": "Action",
        "noAssignee": "No Assignee",
        "emailSent": "Email sent",
        "bulkUpload": "Bulk Upload",
        "replace": "Replace",
        "run": "Run",
        "alternate": "Alternate",
        "assignMember": "Assign Member",
        "home": "Home",
        "attachment": "Attachment",
        "technologies": "[[Technologies]]",
        "DateAdded": "Date Added",
        "selectedOf": "{{selected}} of {{available}}",
        "technology": "[[Technology]]",
        "minutes": "Minutes",
        "examples": "Examples",
        "usedInPlans": "Used In Plans",
        "undefined": "Undefined",
        "tier": "Tier",
        "assignedOwner": "Assigned Owner",
        "markComplete": "Mark Complete",
        "done": "Done",
        "unclassified": "Unclassified",
        "checking": "Checking..."
    },
    "geolocations": {
        "lblShowAddress": "Show Address",
        "options": "Options",
        "showGeofences": "Show Geofences",
        "hideGeofences": "Hiding Geofences",
        "showLocations": "Show Locations",
        "hideLocations": "Hiding Locations",
        "showUserAddress": "Show User Addresses",
        "hideUserAddress": "Hiding User Addresses",
        "filterByZip": "Postal Code",
        "showPOIs": "Show POIs",
        "hidePOIs": "Hiding POIs",
        "location": "[[Location]]",
        "filterByLocationOption": "Include user [[location]] options"
    },
    "geofence": {
        "new": "New Geofence",
        "name": "Geofence Name",
        "information": "Information",
        "address": "Geofence Address",
        "findAddress": "Find Address",
        "create": "Create Geofence",
        "update": "Update Geofence",
        "locationAddress": "Location Address",
        "remove": "Remove Geofence",
        "errorGeocodeFailed": "Unable to resolve the provided address, please correct the address and try again.",
        "msgGeocodeSuccess": "address resolved successfully.",
        "noGeofenceError": "No active geofence, please create a geofence to save.",
        "errorInvalidPolygon": "This geofence is not supported, please ensure the border of geofence does not cross",
        "msgSaveGeofenceSuccess": "Geofence {{ name }} has been successfully saved.",
        "errorSaveGeofenceFailed": "Failed to save geofence {{ name }}",
        "errorReverseGeocodeFailed": "Unable to resolve the provided location to address",
        "msgRemoveGeofenceSuccess": "Geofence {{ name }} has been successfully removed.",
        "errorRemoveGeofenceFailed": "Failed to remove geofence {{ name }}",
        "AreYouSureDeleteGeofence": "Are you sure you want to delete this geofence?",
        "createGeofenceFromPostalCode": "Create geofence from this zip code",
        "msgFailCreateGeofenceFromPostalCode": "Failed to create geofence from postal code",
        "userAddress": "User Address",
        "userMobileLocation": "User Mobile Location",
        "userAssociatedLocation": "User Associated [[Location]]",
        "radius": "Radius",
        "width": "Width",
        "height": "Height",
        "msgInvalidRadius": "Invalid radius",
        "msgInvalidDimension": "Invalid width or height",
        "circle": "Circle",
        "rectangle": "Rectangle",
        "polygon": "Polygon",
        "postalCode": "Zip Code",
        "newGeofence": "New Geofence"
    },
    "establishByCard": {
        "dateCreated": "Date Created",
        "establishByDate": "Establish By Date",
        "establishOnDate": "Establish On Date",
        "establishByDatePastDue": "Past Due",
        "establishByDateDetails": "Establish By Date"
    },
    "charts": {
        "labels": {
            "verified": "Verified",
            "overview": "Overview",
            "preparisScore": "Preparis Score",
            "pastDue": "Past Due",
            "categoryScores": "Category Scores",
            "score": "Score",
            "notEstablished": "Not Established",
            "categoryScore": "Category Score",
            "total": "Total",
            "categoryProgress": "Category Progress"
        },
        "colors": {
            "verified": "#28a745",
            "unverified": "#ffc107",
            "pastDue": "#dc3545",
            "radarLevels": {
                "default": {
                    "backgroundColor": "rgba(0, 0, 0, 0.0)",
                    "borderColor": "rgba(0, 0, 0, 0)",
                    "pointBackgroundColor": "rgba(0, 0, 0, 0)",
                    "pointBorderColor": "rgba(0, 0, 0, 0)",
                    "pointHoverBackgroundColor": "rgba(0, 0, 0, 0)",
                    "pointHoverBorderColor": "rgba(0, 0, 0, 0)"
                },
                "first": {
                    "backgroundColor": "rgba(212, 239, 223, 0.2)",
                    "borderColor": "rgba(39, 174, 96, 1)",
                    "pointBackgroundColor": "rgba(39, 174, 96, 1)",
                    "pointBorderColor": "#fff",
                    "pointHoverBackgroundColor": "#fff",
                    "pointHoverBorderColor": "rgba(39, 174, 96, 1)"
                }
            }
        }
    },
    "planStructure": {
        "plan": "Plan",
        "itemsInRecoveryPlan": "Items In This [[Plan]] Structure",
        "availableItems": "Available Items",
        "name": "Name",
        "type": "Type",
        "tocIndent": "TOC Indent",
        "filename": "Filename",
        "allDocuments": "All Documents",
        "scopeRelatedDocuments": "[[Scope]] Related Documents",
        "teamRelatedDocuments": "[[Team]] Related Documents",
        "teamAndSubTeamRelatedDocuments": "[[Team]] and Sub[[Team]] Related Documents",
        "allReports": "All [[Reports]]",
        "add": "Add",
        "description": "Description",
        "document": "Document",
        "toc": "Table of Content",
        "report": "[[Report]]",
        "remove": "Remove",
        "planStructure": "[[Plan]] Structure",
        "planHeader": "[[Plan]] Header",
        "planCover": "[[Plan]] Cover Body Override",
        "generate": "Generate",
        "insertToc": "Insert Table of Content",
        "lastGenerationDate": "Last Generation Date",
        "overrideNextGenDate": "Override Date",
        "overrideNextGeneration": "Override Next Generation Date",
        "planGenerationSchedule": "[[Plan]] Generation Schedule",
        "scheduleFrequency": "Schedule Frequency",
        "schedulePlanGeneration": "Schedule [[Plan]] Generation",
        "planGenUseLastDayOfMonth": "The Last Day Of The Month",
        "jobStartDate": "Job Start Date",
        "nextGeneration": "Next [[Plan]] Generation",
        "generatingPlan": "Generating [[Plan]]",
        "generatingAttachmentsZip": "Generating Attachments Zip",
        "generateConfirmation": {
            "header": "Generate [[Plan]]",
            "body": "Do you want to generate your [[plan]] using the current version of the [[plan]] or the last verified version of the [[plan]]?",
            "useLatest": "Use Latest",
            "useVerified": "Use Verified"
        },
        "invalidDateDialog": {
            "header": "Invalid Override Date",
            "body": "The Next [[Plan]] Generation Date must be in the future",
            "text": "OK"
        },
        "useFirstDocumentAsCoverPage": "Use First Document As Cover Page",
        "confirmRemoveHeader": "Remove team plan"
    },
    "complianceCategories": {
        "filterDescription": "Refine results by name",
        "newComplianceCategory": "New Compliance Category",
        "complianceCategories": "Compliance Categories",
        "name": "Category Name",
        "description": "Description",
        "confirmRemoveComplianceCategory": {
            "header": "Remove Compliance Category",
            "body": "Are you sure you want to remove this {{ objectType }} named {{ name }}?",
            "text": "Remove"
        },
        "confirmSaveComplianceCategory": {
            "header": "Save Compliance Category",
            "body": "Are you sure you want to save this {{ objectType }} named {{ name }}?",
            "text": "Save"
        },
        "complianceCategoryWithDependentsError": "Cannot remove {{ name }} when other records are assigned this category"
    },
    "storageLocations": {
        "storageLocations": "Storage Locations",
        "filterDescription": "Refine results by name",
        "newStorageLocation": "New Storage Location",
        "name": "Storage Location",
        "description": "Description",
        "getStorageLocationError": "Error retrieving storage locations",
        "confirmRemoveStorageLocation": {
            "header": "Remove Storage Location",
            "body": "Are you sure you want to remove this {{ objectType }} named {{ name }}?",
            "text": "Remove"
        },
        "confirmSaveStorageLocation": {
            "header": "Save Storage Location",
            "body": "Are you sure you want to save this {{ objectType }} named {{ name }}?",
            "text": "Save"
        },
        "storageLocationWithItemsError": "Cannot remove {{ storageName }} with [[items]] assigned to it"
    },
    "timeInterval": {
        "week": "Week",
        "7": "Week",
        "month": "Month",
        "30": "Month",
        "quarter": "Quarter",
        "90": "Quarter",
        "halfYear": "6 Month",
        "180": "6 Month",
        "year": "Year",
        "365": "Year"
    },
    "saveAndEstablish": {
        "header": "Save {{ name }} Options",
        "body": "The {{ name }} will be saved and established on {{ date }}",
        "text": "Save and Establish",
        "establishedDateInPast": "Selected Establish by date is in the past. When saved the {{ name }} will be established on {{ date }}.",
        "establishedDateToday": "Selected Establish by date is Today. The {{ name }} will be established on {{ date }}."
    },
    "updateVerificationDate": {
        "header": "Update {{ name }} {{dateType}} date",
        "body": "Please select new {{ name }} {{dateType}} date",
        "dateType_next": "Next Verification",
        "dateType_last": "Last Verified"
    },
    "saveAndCheckIn": {
        "header": "Save {{ name }} Options",
        "body": "Do you want to save and check in the {{ name }} or just save the {{ name }}?",
        "saveAndCheckIn": "Save and Check In",
        "saveNotCheckIn": "Save but do not Check In"
    },
    "settings": {
        "settings": "[[Settings]]",
        "resourceRequireApproval": "Require approvals for [[team]] resources by default",
        "resourceRequireApprovalTooltip": "When enabled, [[team]] resources such as items will by default require approvals from a designated approver in order to be verified.",
        "forceNextVerificationBasedOnLastVerified": "Override Standard Date Calculation",
        "portalApprovalSettingSaveSuccess": "Portal Resource Approval [[Setting]] Saved",
        "portalApprovalSettingSaveError": "Error Saving Portal Resource Approval [[Setting]]",
        "portalSettingSaveSuccess": "Portal [[Setting]] Saved",
        "portalSettingSaveError": "Error Saving Portal [[Setting]]",
        "forceNextVerificationBasedOnLastVerifiedWarning": "Warning! If enabled, the Next Verification Date will be calculated based solely on the Last Verified Date and Verification Frequency. Enabling this [[setting]] will not override existing data",
        "assignResourceToSelf": "Allow [[team members]] to assume ownership of [[team]] resources",
        "assignResourceToSelfTooltip": "When enabled, [[team members]] can assign [[team]] resources to themselves"
    },
    "notificationSetting": {
        "notificationSetting": "Notification [[Setting]]",
        "notificationSettings": "Notification [[Settings]]",
        "jobType": "Notification Type",
        "verificationFrequency": "Verification Frequency",
        "notificationValue": "Number",
        "datepartType": "Interval",
        "add": "Add Notification",
        "dueSoon": "Due Soon",
        "overdue": "Overdue",
        "months": "Month(s)",
        "days": "Day(s)",
        "confirmRemoveHeader": "Remove Notification [[Setting]]",
        "confirmRemoveMessage": "Are you sure you want to remove this {{ x }} notification of {{ y }} {{ z }}?",
        "saveSuccess": "Notification [[setting]] for {{ a }} of {{ b }} within {{ c }} {{ d }} saved",
        "saveError": "Notification [[setting]] for {{ a }} of {{ b }} within {{ c }} {{ d }} not saved",
        "removeSuccess": "Notification [[setting]] for {{ a }} of {{ b }} within {{ c }} {{ d }} removed",
        "removeError": "Notification [[setting]] for {{ a }} of {{ b }} within {{ c }} {{ d }} not removed",
        "notificationTypes": {
            "eeca9d34-c1bd-4a24-8562-caaef0e1f510": "Due Soon",
            "e69761d6-02ac-4156-8f46-6b56fd99614a": "Overdue"
        },
        "datepartTypes": {
            "f8b89b3c-8c9e-47c8-9a2e-9c5417d1a838": "Month(s)",
            "baba0540-0282-4aca-a53a-fb064ce2d509": "Day(s)"
        },
        "dueSoonDescription": "Send notification {{ a }} {{ b }} before due date.",
        "overdueDescription": "Send notification {{ a }} {{ b }} after due date.",
        "dueTodayDescription": "Send notification on due date.",
        "dueSoonTitle": "Notifications for Upcoming Activities",
        "dueTodayTitle": "Notifications for Activities on Due Date",
        "overdueTitle": "Notifications for Overdue Activities",
        "addNotificationSettingForDatepart": "Add {{ a }} Notification [[Setting]]",
        "customizeNotificationSettingForDatepart": "Customize {{ a }} Notification [[Setting]]",
        "editNotificationSetting": "Edit Notification [[Setting]]",
        "addNotificationSetting": "Add Notification [[Setting]]",
        "confirmSave": {
            "header": "Save Notification [[Setting]]",
            "body": "Are you sure you want to save this {{ notificationType }} notification [[setting]] for {{ verificationFrequency }} activities?",
            "text": "Save"
        },
        "confirmRemove": {
            "header": "Remove Notification [[Setting]]",
            "body": "Are you sure you want to remove this {{ notificationType }} notification [[setting]] for {{ verificationFrequency }} activities?",
            "text": "Remove"
        },
        "default": "default",
        "confirmChangePortalSettingHeader": "Change Portal Email [[Setting]]",
        "confirmChangePortalSettingMessage": "Are you sure you want to {{ action }} {{ settingName }}?",
        "enable": "Enable",
        "disable": "Disable",
        "portalEmailSettingSaveSuccess": "Portal Email [[Setting]] Saved",
        "portalEmailSettingSaveError": "Error Saving Portal Email [[Setting]]",
        "notifications": "Notifications",
        "portalSetting": "Portal [[Setting]]",
        "enableReminderEmails": "Send Reminder Emails for [[Team Members]]",
        "enableSummaryEmails": "Send as Summary Email",
        "enableReminderEmailsTooltip": "When enabled, reminders for upcoming tasks are emailed to [[team members]] each day.",
        "enableSummaryEmailsTooltip": "When enabled, daily reminders for upcoming tasks are summarized in one email.",
        "savePortalSettingSuccess": "Portal [[setting]] saved",
        "savePortalSettingError": "Portal [[setting]] not saved",
        "iQEmailFromName": "Notification Email From Name"
    },
    "iqVerificationSettings": {
        "verificationIntervalThresholdLabel": "Verification Interval Threshold",
        "intervalOptions": {
            "d": "Day(s)",
            "m": "Month(s)"
        }
    },
    "sendMessage": {
        "disableEnablePrefix": "Hide the response message prefix included in SMS and email",
        "createdBy": "Created by",
        "sendMessage": "Send Message",
        "sendMessageEndDate": "End Date",
        "sendMessageSendAt": "Send At",
        "eventName": "Event Name",
        "options": "Options",
        "messages": "Messages",
        "filters": "Filters",
        "review": "Review",
        "incidentName": "Incident Name",
        "incidentType": "Incident Type",
        "howToContact": "Select how you want to contact recipients",
        "requireResponse": "Require recipients to respond",
        "yesWithCustom": "Yes, with custom responses (max: {{maximumCustomResponses}})",
        "requireResponseYes": "Yes  (Preparis will add instructions to your message asking users to reply back with {{responses}})",
        "customResponses": "Custom Responses",
        "customResponse": "Custom Response",
        "customResponseDefinition": "Response Definition",
        "advanced": "Advanced",
        "personalEmail": "Personal email",
        "personalPhone": "Personal phone",
        "additionalPersonalPhone": "Additional Personal Phone",
        "personalContact": "Send this message to recipient's personal contact info",
        "autoResend": "Automatically re-send message",
        "autoSendToManager": "Automatically Send a Message to the Recipient’s Manager",
        "autoTranslate": "Automatically translate message",
        "sms": "SMS",
        "voice": "Voice",
        "phone": "Phone",
        "pushMessage": "Push Message",
        "email": "Email",
        "emergencyMessage": "Emergency Message",
        "sendAfter": "send after",
        "for": "For",
        "ifRecipients": "if recipients",
        "minutes": "Minutes",
        "hour": "Hour",
        "hours": "Hours",
        "day": "Day",
        "responded": "responded",
        "hasNotResponded": "has not responded",
        "times": "time(s),",
        "newMessage": "New message",
        "isSimulation": "Send message as a simulation",
        "usersCanReplyViaLink": "Allow users to reply via a link",
        "unknown": "Unknown",
        "subject": "Subject",
        "message": "Message",
        "ctr": "Call to record",
        "tts": "Text to speech",
        "call": "Call",
        "phoneNumber": "Phone number",
        "preview": "Preview",
        "bodyPrepend": "Reply to this msg ({{responsesAsString}}). ",
        "bodyPrepend-en": "Reply to this msg ({{responsesAsString}}). ",
        "bodyPrepend-es": "Responder a este mensaje ({{responsesAsString}}). ",
        "bodyPrepend-fr": "Répondante Ã  ce msg ({{responsesAsString}}). ",
        "bodyPrepend-pt": "Responder a esta mensagem ({{responsesAsString}}). ",
        "bodyPrependWithLink-en": "Reply to this msg ({{responsesAsString}}). Or click: https://t.preparisapp.com/zGKHt. ",
        "bodyPrependWithLink-es": "Responder a este mensaje ({{responsesAsString}}). o haga clic: https://t.preparisapp.com/zGKHt. ",
        "bodyPrependWithLink-fr": "Répondante Ã  ce msg ({{responsesAsString}}). ou cliquez: https://t.preparisapp.com/zGKHt. ",
        "bodyPrependWithLink-pt": "Responder a esta mensagem ({{responsesAsString}}). ou clique: https://t.preparisapp.com/zGKHt. ",
        "orClick": " or click",
        "remaining": "Remaining",
        "copyToOthers": "Copy to others",
        "copySubjectToOthers": "Copy subject to others",
        "copyBodyToOthers": "Copy message to others",
        "usersWhoAreIn": "Users who are in",
        "numberOfRecipients": "Number of recipients",
        "allUsers": "All users",
        "manuallyAdd": "Manually add",
        "andOr": "and/or",
        "noSelections": "No selections have been made",
        "autoPopulateKey": "Auto populate CUSTOM RESPONSE",
        "clickAbove": "Click on any option above to start selecting recipients",
        "filterHint01": "Select from any categories to create your list. A user must match one of the values in each category you select to be included. You can also manually add recipients.",
        "example": "Example",
        "filterExample01": "If you select options from multiple categories, a user must match all criteria to be included. For example if you select Role: Program Manager and Locations: Atlanta, Nashville and [[Department]] Marketing. A user must be in the marketing [[department]] in Atlanta or Nashville with a role of Program Manager to receive this message.",
        "selectNone": "Select None",
        "selectAll": "Select All",
        "viewSelections": "view selections",
        "deleteShape": "Delete shape",
        "zoomToShape": "Zoom to shape",
        "geofenceOptions": "Message Geofence Options",
        "geofenceChoices": "How would you like to create your geofence?",
        "existingGeofence": "Select from geofences",
        "newGeofence": "Create from Address",
        "geofences": "Geofences",
        "contactMethods": "Contact methods",
        "included": "Included",
        "excluded": "Excluded",
        "search": "Search",
        "searchByUser": "Search by user",
        "addByPhoneNumber": "Add by phone number",
        "addByEmail": "Add by email",
        "addByType": "Add by type",
        "manuallyAddedRecipients": "Manually added recipients",
        "invalidPhoneNumber": "Invalid phone number",
        "allUsersSelected": "All users have been selected",
        "errorGettingData": "Error getting data",
        "registeredRecipients": "Registered recipients",
        "noRecipientsError": "No recipient has been added",
        "sentTo": "Messages sent to",
        "automaticFollowUp": "Automatic follow-up",
        "sendNow": "Send now",
        "saveForLater": "Save for later",
        "schedule": "Schedule",
        "scheduleToSend": "Send Later",
        "responses": "Responses",
        "sendToPersonalContact": "Send to personal contact",
        "toWhom": "To whom do you want to send this message?",
        "toWhomMaxOneHundred": "To whom do you want to send this message? (Max of 100 participants)",
        "disabledForOverOneHundredParticipants": "Some options are disabled for over 100 participants",
        "everyoneLabel": "Send a message to everyone associated to this incident",
        "everyoneDescription": "Anyone who is affected by this incident, was sent a previous message, or included in a conference call about this incident will be pre-populated as recipients",
        "limitedLabel": "Only send message to a subset of user",
        "limitedDescription": "Only send to those whose last response was",
        "newLabel": "Send a message to a new set of users",
        "newDescription": "You'll be free to choose message recipients instead of them pre-populating",
        "incidentCreatorLabel": "Send a message to incident creator",
        "incidentCreatorDescription": "created this incident",
        "followUp": "Follow-Up",
        "scheduleMessageSuccess": "Message scheduled",
        "scheduleMessageError": "Error scheduling message",
        "deleteScheduledMessageSuccess": "Scheduled message deleted",
        "deleteScheduledMessageError": "Error deleting scheduled message",
        "scheduledMessage": "Scheduled message",
        "confirmDeleteScheduledMessageHeader": "Remove scheduled message",
        "confirmDeleteScheduledMessageDescription": "Are you sure you want to remove this scheduled message?",
        "confirmDeleteMessageTemplateHeader": "Remove saved message",
        "confirmDeleteMessageTemplateDescription": "Are you sure you want to remove this saved message?",
        "getScheduleMessageError": "Error getting scheduled message",
        "updateScheduledMessage": "Update scheduled message",
        "followUpMessage": "Follow-up",
        "saveAs": "How do you want to save this message?",
        "overwrite": "Overwrite current",
        "saveAsNew": "Save as new",
        "saveMessageName": "Save message name",
        "savedMessages": "Saved messages",
        "pendingMessages": "Pending messages",
        "pin": "Click to pin",
        "unpin": "Click to unpin",
        "removeMessageTemplate": "Remove saved message",
        "send": "Send",
        "removeTemplateHeader": "Remove saved message",
        "removeTemplateBody": "Are you sure you want to remove this message template?",
        "noOfRecipient": "# of recipients",
        "responseRequired": "Response required",
        "noPendingMessage": "No pending message",
        "sendMessageSuccess": "Your message is on its way. Would you like to save this to be used again later?",
        "sendMessageSuccessAlt": "Your message is on its way",
        "contacts": "Contacts",
        "includeOfficeContactInfo": "Also call recipients' office phone?",
        "invalidEmail": "Invalid email",
        "confirmCancelHeader": "Cancel Send Message",
        "confirmCancelBody": "Are you sure you want to cancel send message?",
        "callRecipientOfficePhone": "Call office phone",
        "noDupeResponses": "No duplicate responses",
        "selections": "Selections",
        "recipients": "Recipients",
        "composeNewMessage": "Compose new message",
        "progress": "Progress",
        "dupeNameError": "This name already exist",
        "successRemovingMessageTemplate": "Saved message removed",
        "allCountries": "All countries",
        "noManuallyAddedRecipients": "No manually added recipients",
        "manuallyAddedLocations": "Manually added locations",
        "manuallyAddedDepartments": "Manually added [[departments]]",
        "manuallyAddedGroups": "Manually added groups",
        "manuallyAddedTenants": "Manually added tenants",
        "manuallyAddedRoles": "Manually added roles",
        "notifyResponseWatchers": "Notify Watchers",
        "submitForApproval": "Submit for approval",
        "pendingMessageName": "Pending message name",
        "messageSubmittedForApproval": "Message submitted for approval",
        "removePendingMessageHeader": "Remove pending message",
        "removePendingMessageBody": "Are you sure you want to remove this pending message?",
        "successDeletePendingMessage": "Pending message removed",
        "newSendMessage": "Send a new message",
        "noReply": "No Reply",
        "approvePendingMessage": "Approve pending message",
        "updatePendingMessage": "Update pending message",
        "declinePendingMessage": "Decline pending message",
        "newEvent": "New event",
        "approveAndSend": "Approve & Send",
        "permissionError": "You do not have permission to make changes this pending message",
        "everyoneWhose": "Everyone whose last response was",
        "everyoneInThisEvent": "Everyone in this event",
        "sendEdit": "Send/Edit",
        "detectStartStep": "Auto-detect Starting Step",
        "detectStartStepDescription": "The application can automatically detect which step in the Send Message [[process]] to begin with based on the data in your Saved Message. This can reduce the number of clicks it takes to send your message. By specifying 'No' the first step in the Send Message [[process]] will always be used.",
        "startWithMessageTemplate": "Start with a saved message (optional)",
        "startWithMessageTemplateDescription": "Recipients from saved messages WILL NOT be applied to this message",
        "incidentCreator": "Incident creator",
        "desktop": "Desktop",
        "desktopSeverity": "Severity",
        "desktopSeverityAlarm": "[[Alert]]",
        "desktopSeverityWarning": "Warning",
        "desktopSeverityInfo": "Information",
        "desktopSendSilently": "Send Silently",
        "simulationMessage": "This is a simulation",
        "errorDesktopAlertWithNoResponse": "Cannot send a desktop message as an [[Alert]] when a response is not required from the user.",
        "sendAMessage": "Send a message",
        "entityRemoved": "One or more selections have been removed from the system since this message was last saved. Please make sure all selections are up to date. The following were removed:",
        "createANewMessage": "Create A New Message",
        "alertDeclare": "[[Alert]]/Declare",
        "createANewMessaage": "Create A New Message",
        "requireRecipientToRespondTooltip": "(Preparis will add instructions to your message asking users to reply back with OK or NOT)",
        "selectDeliveryMethod": "Select Delivery Method",
        "push": "Push",
        "copyText": "Copy Text",
        "selectedResponses": "Selected Responses",
        "automaticReply": {
            "automaticReply": "Auto Reply",
            "setUpAutomaticReplies": "Set Up Automatic Replies",
            "lblAutomaticReplies": "Automatic Reply Messages",
            "configuredAutomaticReplies": "Set Up Automatic Replies"
        },
        "repeatOn": "Repeat on",
        "recurringScheduleEndDateError": "End date must be after scheduled date above",
        "noScheduledDaysOfTheWeekError": "At least one(1) day of the week must be selected",
        "translatingMessage": "Translating message...",
        "responseWatchers": {
            "lblWatchers": "Watchers",
            "lblResponseWatchers": "Response Watchers",
            "msgMissingResponseWatchers": "Please select at least one response watcher."
        },
        "timeInvalid": "The time of the scheduled message must be in the future.",
        "uploadNewDocument": "Compose a New Message",
        "title": "Messages",
        "searchPlaceholder": "Search for a Saved Message",
        "addNew": "Compose a New Message",
        "createFolder": "New Folder",
        "searchTitle": "Search Results",
        "or": "or",
        "sendOrEdit": "Send/Edit",
        "buttonPin": "Pin",
        "buttonUnPin": "Unpin",
        "pinned": "Pinned",
        "pending": "Pending",
        "messageDetails": "Message Details",
        "selectAllWarning": "You have selected 'Select All'. Are you sure you want to add all users to the recipients list for this message?",
        "selectAllWarning2": "You have potentially selected a large group of people to add to this message. Are you sure you want to do this?"
    },
    "googleMapAPITermAndService": "Map API Term of Use",
    "portalResources": {
        "resourceTypes": {
            "2e2016a0-4990-4276-b2f3-01950edc13e1": "Employee Tools",
            "e956d676-9d9f-4ab3-80b0-21e670e720ad": "Templates",
            "9904f935-b860-44fc-8a7d-2a91475e9fbb": "Infographics",
            "47905bed-1e55-4efd-baab-63014481c1cd": "Reference Sheets",
            "e9730817-b71b-4273-9e2b-650191be2d61": "RSS Feed",
            "bb073508-593c-40bb-8c39-6bf5aa801c0c": "Tabletop",
            "5bb91d95-051d-48b3-b1bc-7a8f4558c1b6": "News &  Briefs",
            "8e499ec0-a8b1-4de0-b830-89adcec08483": "Training",
            "f2a1fa9e-bfbd-4051-a95b-95f4b518a17a": "Protocols",
            "4cbb9953-a1d9-47ab-9c86-b810a6fcf898": "Blog",
            "40e1b2f9-e47a-4c6c-93fb-c4684f88705c": "Checklist",
            "62c2f132-76ad-47f8-bd43-dbe02abba987": "Executive Briefs",
            "148a1689-577b-4ca5-b47b-e124ab249b42": "Threat"
        },
        "distributionTypes": {
            "5729f1b5-be13-4f33-aa73-7843b5bc2fce": "Verticals And Explicit",
            "d11232ba-5525-40d5-8062-57caca5fa498": "Explicit Only"
        },
        "resourceType": "Resource Type",
        "icon": "Content Item Icon",
        "document": "Content Item Document",
        "categories": "Categories",
        "requiresACL": "Requires ACL",
        "distributionType": "Distribution Type",
        "name": "Name",
        "title": "Title",
        "description": "Description",
        "newItem": "New Item",
        "resourcesFilterPlaceholder": "Filter",
        "confirmRemoveHeader": "Remove Content Item",
        "remove": "Remove",
        "contentItem": "Content Item",
        "sortOrder": "Order",
        "select": "Select",
        "viewOrderLists": "View Order Lists",
        "viewOrderList": "View Order List",
        "availableContentItems": "Available Content Items",
        "availableTrainingCourses": "Available Training Courses",
        "selectedContentItems": "Selected Content Items",
        "selectedTrainingCourses": "Selected Training Courses",
        "vertical": {
            "3089683e-a1c9-4c91-9b87-1e3888a9cfcf": "standard",
            "f1d39814-6de9-481b-9abe-d655d396a64d": "CRE"
        }
    },
    "publicAlertSubscription": {
        "lblPageHeader": "Public [[Alert]] Subscriptions",
        "lblPageDescription": "Manage subscriptions to public [[alert]] messages.",
        "publicAlertSubscription": "Subscription",
        "publicAlertSubscriptions": "Subscriptions",
        "subscriptionName": "Subscription Name",
        "incidentType": "Incident Type",
        "groupEntity": "Group",
        "filterPlaceHolder": "Refine results by subscription name",
        "newSubscription": "New Subscription",
        "sendSms": "Send SMS",
        "sendEmail": "Send Email",
        "sendVoice": "Send Voice",
        "isPush": "Enable Push",
        "categoryType": "Category Types",
        "certaintyType": "Certainty Types",
        "severityType": "Severity Types",
        "urgencyType": "Urgency Types",
        "eventType": "Event Types",
        "allTypes": "All Types",
        "zoneCountryFilter": "Zone Country Filter",
        "zoneRegionFilter": "Zone Region Filter",
        "zoneTypeFilter": "Zone Type Filter",
        "automaticallySendMessages": "Automatically Send Messages",
        "describe": "Describe",
        "filterTypes": "Filter Types",
        "filterLocations": "Filter Locations",
        "availableZones": "Available Zones",
        "selectedZones": "Selected Zones",
        "rowCountMessage": "Showing {{ x }} of {{ y }} subscriptions",
        "createNewSubscription": "Create New Subscription",
        "errorMessages": {
            "selectAtLeastOneChannel": "Please select at least one communication channel before saving.",
            "selectAtLeastOneRegion": "Please select at least one region before saving.",
            "requiredFieldMissing": "Please fill out all required fields."
        },
        "simple": "Simple",
        "advanced": "Advanced",
        "autoSend": "Auto Send",
        "confirmSave": {
            "header": "Save Public [[Alert]] Subscription",
            "body": "Are you sure you want to save this {{ objectType }} named {{ name }}?",
            "text": "Save Subscription"
        },
        "confirmRemove": {
            "header": "Remove Public [[Alert]] Subscription",
            "body": "Are you sure you want to remove this {{ objectType }} named {{ name }}?",
            "text": "Remove Subscription"
        },
        "help": {
            "subscriptionName": "Name to uniquely label this subscription.",
            "incidentType": "Type of incident that best categorizes the filtered event types of this subscription.",
            "sendSms": "Send public [[alerts]] that match this subscription via SMS.",
            "sendEmail": "Send public [[alerts]] that match this subscription via email.",
            "sendVoice": "Send public [[alerts]] that match this subscription via voicemail.",
            "isPush": "Send push notifications for public [[alerts]] that match this subscription.",
            "groupEntity": "Designate the group that will receive and act upon the public [[alerts]] that match this subscription.",
            "categoryType": "Subscribe to these category types.",
            "certaintyType": "Subscribe to these certainty types.",
            "severityType": "Subscribe to these severity types.",
            "urgencyType": "Subscribe to these urgency types.",
            "eventType": "Subscribe to these event types.",
            "zoneCountryFilter": "Filter available zones by country.",
            "zoneRegionFilter": "Filter available zones by region.",
            "zoneTypeFilter": "Filter available zones by type.",
            "autoSend": "Automatically send messages as public [[alerts]] are generated."
        }
    },
    "incidentTypeTriggers": {
        "manageIncidentTypeTriggers": "Manage Incident Triggers",
        "incidentTypeTriggersDescription": "Incidents can be triggered through 3rd Party APIs into the Preparis system. This is accomplished by mapping an Incident Type sent by the 3rd Party API to a Saved Message.",
        "incidentTypeTriggers": "Incident Triggers",
        "help": {
            "incidentTypeTriggers": "Unless they are currently in use, nearly all incident triggers can be removed."
        },
        "incidentTypeTriggersName": "Triggers",
        "incidentTypeHeader": "Incident Type",
        "apiHeader": "API",
        "savedMessageHeader": "Saved Message",
        "noTriggers": "No Incident Triggers have been added",
        "addNewTrigger": "Add New Incident Trigger",
        "errorDuplicatedIncidentTypes": "Cannot use an Incident Type as a trigger multiple times.",
        "errorNonSendableSavedMessages": "Saved message(s) ({{messageNames}}) cannot be sent because all required fields are not filled out. Please select alternate saved message(s)."
    },
    "incidentTypes": {
        "manageIncidentTypes": "Manage Incident Types",
        "incidentTypesDescription": "Add additional incident types that can be used to classify incidents when Creating and Updating Incidents in the Portal and also when creating User Initiated Incidents through the mobile application.",
        "noIncidentTypes": "No Incident Type has been added",
        "incidentType": "Incident Type",
        "incidentTypes": "Incident Types",
        "systemDefaultIncidentTypes": "System Default Incident Types",
        "addNewIncidentType": "Add New Incident Type",
        "addSystemIncidentType": "Add System Incident Type",
        "msgRequiredFieldsWarning": "Please enter all required fields, or remove them prior to saving.",
        "msgSaveIncidentTypesSuccess": "Incident Types successful saved.",
        "msgDuplicateIncidentTypeNameWarning": "One or more Incident Type has the same name. Please check your Incident Type name.",
        "incidentTypeName": "Incident Type Name",
        "saveSuccess": "Incident types successfully saved.",
        "help": {
            "incidentTypes": "Unless they are currently in use, nearly all incident types can be removed. In addition, custom incident types can be renamed. (Pre-populated incident types are readonly.) The default \"Not Set\" incident type is both required and readonly, it cannot be renamed or removed."
        },
        "errors": {
            "messagingIncidentTypeWithGroupDependencies": "One or more removed incident types are currently being used as a user initiated group [[setting]].",
            "messagingIncidentTypeWithChannelDependencies": "One or more removed incident types are currently being used as a user initiated channel [[setting]].",
            "messagingIncidentTypeWithPublicAlertDependencies": "One or more removed incident types are are currently being used with a public [[alert]] [[setting]].",
            "messagingIncidentTypeCannotRemoveSystemDefault": "\"Not Set\" is a system default and cannot be removed.",
            "messagingIncidentTypeNamesAreNotUnique": "Names are not unique"
        }
    },
    "emailTypes": {
        "acb4f38f-3642-472a-8de2-dcead28584a2": "Personal",
        "d440a213-3ab4-4fb9-b306-5f567141ac53": "Secondary",
        "d2b1d156-bdf9-4223-9368-825a448025ad": "Primary"
    },
    "phoneTypes": {
        "9da9c256-8283-4a0a-bd3d-10df73e1f43b": "Security",
        "20509910-5326-4057-b21f-5c694b314692": "Office",
        "5322a58a-8683-449b-a562-b0577de3fa74": "Emergency",
        "5eec12d5-595f-4123-a613-c63e701ae993": "Personal",
        "33f27328-8fee-4835-b020-cd6d48bb39cd": "Mobile",
        "391dca7b-aac3-4875-94e2-2ef680c7f35f": "Additional Personal"
    },
    "responses": {
        "lblUnknown": "Unknown",
        "lblNoResponse": "No Response",
        "lblNotOk": "NOT",
        "lblOk": "OK"
    },
    "publicAlerts": {
        "dateTimeSent": "Date Sent",
        "msgType": "Type",
        "category": "Category",
        "urgency": "Urgency",
        "severity": "Severity",
        "certainty": "Certainty",
        "dateTimeExpiration": "Expires On",
        "recentPublicAlerts": "Recent Public [[Alerts]]",
        "status": "Status",
        "expired": "Expired",
        "active": "Active",
        "recentAlerts": "Recent [[Alerts]]",
        "instruction": "Instruction",
        "description": "Description",
        "publicAlerts": "Public [[Alerts]]",
        "responseType": "Response Type",
        "showMore": "Show More",
        "showLess": "Show Less",
        "filters": "Filters",
        "areas": "Areas",
        "headline": "Headline",
        "expiresOn": "Expires On",
        "sentOn": "Sent On",
        "sendMessage": "Send Message",
        "errors": {
            "errorLoadingRecentAlerts": "Error loading recent [[alerts]]",
            "errorLoadingAlertInfo": "Error loading more [[alert]] info"
        },
        "rowCountMessage": "Showing {{ displayedCount }} of {{ totalCount }} {{ status }} [[alerts]]",
        "showAdvanced": "Show Advanced",
        "showSimple": "Show Simple",
        "previousDays": {
            "2": "Past 48 Hours",
            "3": "Past 72 Hours",
            "4": "Past 4 Days",
            "5": "Past 5 Days"
        },
        "noAlertsToDisplayMessage": "No {{ status }} [[alerts]] over the {{ timeSpan }}",
        "subscriptions": "Subscriptions",
        "subscription": "Subscription"
    },
    "messageSettings": {
        "errorRequiredFields": "Please enter all required fields.",
        "errorResetMessageFailed": "An error has occurred resetting message [[settings]].",
        "errorSavMessageFailed": "An error has occurred saving message [[settings]].",
        "lblAreYouSureResetSettings": "Are you sure you want to reset message [[settings]]?",
        "lblEscalateMessageContent1": "If you want to give message recipients time to respond before sending the message by another delivery method, set this to Yes. You'll then be able to specify the order of delivery and the wait time between each delivery method before being sent.",
        "lblEscalateMessageContent2": "If set to no, recipient will be notified by all delivery methods at the same time.",
        "lblEscalateMessageContent3": "Note: This [[setting]] only applies to messages that require a response and have more than one delivery method selected.",
        "lblEscalateMessageTitle": "Message Escalation",
        "lblIncludeOfficePhoneContent": "If set to Yes, when a user sends a new message AND has selected to Voice (call recipients). the option to call a recipient's office phone will be pre-selected. Users will be able to override the default and deselect per message if desired.",
        "lblIncludeOfficePhoneTitle": "Include office phone for voice calls by default?",
        "lblPersonalContactContent": "If set to Yes, when a user sends a new message, the option to send to message recipient's personal contact info will be pre-selected. Users will be able to override this default and deselect per message if desired.",
        "lblPersonalContactTitle": "Send message to user's personal contact info?",
        "lblSettingsActivity": "[[Settings]] Activity",
        "lblSettingsTitle": "Message [[Settings]]",
        "lblAutoArchive": "Auto Archive Incident History Records",
        "lblAutoArchiveContent": "If set to Yes, resolved incident history records will be archive automatically. You will be given the opportunity to choose the archive interval and whether to resolve and archive records that are old but still Active.",
        "lblForceResolution": "Resolve and Archive Older Active Incidents",
        "lblIntervalDays": "Archive Incidents Older Than",
        "msgResetMessageSuccess": "Message [[settings]] has been successfully reset.",
        "msgSaveMessageSuccess": "Message [[settings]] have been successfully saved.",
        "lblPersonalContactPhoneTitle": "Send message to user's personal phone?",
        "lblPersonalContactPhoneContent": "If set to Yes, when a user sends a new message, the option to send to message recipient's personal phone info will be pre-selected. Users will be able to override this default and deselect per message if desired.",
        "personalContactEmailTitle": "Send message to user's personal email?",
        "lblPersonalContactEmailContent": "If set to Yes, when a user sends a new message, the option to send to message recipient's personal email info will be pre-selected. Users will be able to override this default and deselect per message if desired.",
        "lblOverwriteDefaultUserResponses": "If you overwrite the default user responses, a message response options will have these values",
        "lblDesablePrefixSmsVoice": "Only applicable if you are requesting a response",
        "btnSave": "Save",
        "btnUndo": "Undo",
        "btnReset": "Reset",
        "lblCustomResponses": "Overwrite the default user responses?",
        "lblDisablePrefix": "Hide the response message prefix included in SMS and email.",
        "confirmReset": {
            "body": "Are you sure you want to reset message [[settings]]?",
            "header": "Confirm",
            "btnText": "OK"
        },
        "allowUsersToRespondViaLink": "Allow users to respond via a link by default",
        "allowUsersToRespondViaLinkDesc": "If set to Yes, newly composed messages will allow users to respond via a link by default, which will add web links to outbound SMS and email incident messages that allows users to respond by clicking a link",
        "thenWait": "Then Wait",
        "deliveryMethod": "Delivery Method",
        "delayOptions": {
            "doNotWait": "Do not wait",
            "minute": "minute",
            "minutes": "minutes"
        },
        "deliveryMethodOptions": {
            "sms": "SMS",
            "emails": "Email",
            "pushMessages": "Push",
            "desktop": "Desktop",
            "voiceMails": "Voice"
        },
        "errorMessageCascadingChannelsDups": "Duplicate delivery methods are selected",
        "errorMessageAllCascadingChannelsMustSelect": "Missing delivery method",
        "sendAlertEmailAsUrgent": "Send [[alert]] emails as urgent.",
        "sendAlertEmailAsUrgentDesc": "If set to yes, all [[alert]] emails will be sent as urgent with high importance for any compatible email clients, regular system generated emails are not included",
        "allowOfficePhoneSMS": "Allow SMS to be sent to eligible office phones.",
        "allowOfficePhoneSMSDesc": "If set to yes, office phone numbers will be included in messages as long as the phone is opted-in and does not have an extension."
    },
    "conferenceCall": {
        "conferenceCall": "Conference call",
        "selectContactTip": "If you choose Mobile, Office, or Personal, participants are automatically called at the contact method selected when you start the call",
        "selectHowYouWantToContact": "Select how you want to contact participants for this conference call (you may only pick 1 option)",
        "newConferenceCall": "New Conference Call",
        "mobileTip": "Best way to reach users",
        "mobile": "Mobile",
        "office": "Office",
        "personal": "Personal",
        "optionsConferenceDetailDescription": "Select where to send conference call details for attendees",
        "noCall": "Don't Call",
        "noCallTip": "Only send conference call detail",
        "personalEmail": "Personal email",
        "personalPhone": "Personal phone",
        "finalRecipientCountErrorMsg": "Max recipients: {{maxRecipient}}",
        "startConferenceCall": "Start Conference Call",
        "contactParticipants": "Contact participants",
        "sendConferenceCallDetailTo": "Send conference call detail to",
        "optionsPersonalInfo": "Send conference call details to personal contact info",
        "success": "Conference call successfully started",
        "confirmCancelHeader": "Cancel Conference Call",
        "confirmCancelBody": "Are you sure you want to cancel this conference call?",
        "followUpConferenceCall": "Follow-up",
        "optionsPersonalInfoDescription": "Send this message to recipient's personal contact info",
        "scheduleConferenceCall": "Schedule Conference Call",
        "scheduleConferenceCallError": "Error scheduling Conference Call",
        "scheduleConferenceCallSuccess": "Conference Call successfully scheduled",
        "scheduledConferenceCalls": "Scheduled Conference Calls",
        "noScheduledConferenceCalls": "No scheduled Conference Calls",
        "dateCreated": "Date created",
        "scheduledDate": "Scheduled date",
        "scheduledTime": "Scheduled time",
        "createdBy": "Created By",
        "deleteScheduledConferenceCallBody": "Are you sure you want to remove this scheduled Conference Call?",
        "deleteScheduledConferenceCall": "Remove scheduled Conference Call",
        "updateScheduledConferenceCall": "Update scheduled Conference Call",
        "scheduledConferenceCall": "Scheduled Conference Call",
        "searchScheduledConCallsPlaceholder": "Search scheduled conference calls",
        "everyoneInThisEvent": "Everyone in this event",
        "additionalMessage": "Additional message",
        "additionalMessageDescription": "This is an optional message that will be added to the end of the message that participants receive in the notification of a Conference Call",
        "additionalSmsMessagePreview": "An emergency conference call has been setup by {{userProfileName}}: +{{portalConferenceCallNumber}} ID: <em title=\"Pin will be generated when the call is started\">pin</em> One-touch: tel:+{{portalConferenceCallNumber}},,<em title=\"Pin will be generated when the call is started\">pin</em>#.",
        "additionalEmailBodyPreview": "An emergency conference call has been setup by {{userProfileName}}. Please call the following number to join the conference call:<br /><br />+{{portalConferenceCallNumber}}<br /><br />Conference ID: <em title=\"Pin will be generated when the call is started\">pin</em><br />One-touch: tel:+{{portalConferenceCallNumber}},,<em title=\"Pin will be generated when the call is started\">pin</em>#",
        "optionsRecordCall": "Select if you want to record this conference call",
        "recordCallDisclaimer": "Applicable laws may require that callers hear a call-recording notification before a call is recorded. It is your responsibility to make sure your company's call recording procedures comply with the applicable laws and regulations. Preparis has no responsibility for your company's compliance.",
        "scheduledConferenceCallInvalid": "The time of the scheduled conference call must be in the future."
    },
    "customFields": {
        "fieldName": "Name",
        "fieldType": "Field Type",
        "isRequired": "Required",
        "fieldTypes": {
            "5f31785c-4d3c-4d68-a834-da37c2bc0a76": "Numeric",
            "0f6f2372-1e4c-4d67-ad13-615a0540488c": "Short Text",
            "b9847d16-19b5-4ed4-a4e3-d7f07533ab5a": "Long Text",
            "d8bd3d80-d25d-4056-8754-a740f2396792": "True or False",
            "aad6f035-05f9-42d3-b6ea-682371340875": "Select One",
            "76c83029-8439-42ad-90e6-7f0ff54b732c": "Select Many",
            "1eccbe40-1b3b-461b-95df-35f1fe6a8af2": "Date/Time"
        },
        "isRequiredOptions": {
            "true": "Yes",
            "false": "No"
        },
        "displaySequence": "Display Sequence",
        "fieldDomain": "Options",
        "success": {
            "deleteCustomFieldSuccess": "Success deleting custom field",
            "saveCustomFieldsDisplayOrderSuccess": "Success saving display order",
            "saveCustomFieldSuccess": "Success saving custom field"
        },
        "errors": {
            "getCustomFieldsError": "Failed to load custom fields",
            "saveCustomFieldsDisplayOrderError": "Error saving new display order",
            "deleteCustomFieldError": "Error deleting custom field",
            "customFieldWithSameNameError": "Error saving custom field with same name",
            "saveCustomFieldError": "Error saving custom field",
            "customFieldOptionWithSameNameError": "Error saving custom field with duplicate option names",
            "customFieldTypeSelectOneWithoutOptions": "Options are required for \"Select One\" custom fields",
            "customFieldsInvalid": "Please review custom fields"
        },
        "help": {
            "fieldNameTooltip": "Label name of custom field",
            "fieldTypeTooltip": "Type of data to collect with this custom field",
            "isRequiredTooltip": "Designate if this custom field is required",
            "editCustomFieldInstructions": "Edit which type of data to collect and whether or not to require this custom field.",
            "addCustomFieldInstructions": "Add a custom field to collect information.",
            "optionsTooltip": "List options in display order."
        },
        "editCustomField": "Edit Existing Custom Fields",
        "addCustomField": "Add New Custom Field",
        "newCustomField": "New Custom Field",
        "confirmRemoveCustomField": {
            "header": "Remove Custom Field",
            "body": "Are you sure you want to remove this custom field?",
            "text": "Remove"
        },
        "confirmSaveCustomField": {
            "header": "Save Custom Field",
            "body": "Are you sure you want to save this custom field",
            "text": "Save"
        },
        "customFields": "Custom Fields",
        "optionName": "Option Name",
        "addOption": "Add Option",
        "default": "Default",
        "restore": "Restore",
        "options": "Options",
        "makeDefault": "Make Default",
        "saveDisplayOrder": {
            "cancelDisplayOrder": "Cancel Order",
            "saveDisplayOrder": "Save Order",
            "header": "Save New Display Order",
            "body": "Are you sure that you want to change the order in which the fields are displayed?",
            "text": "Save Display Order"
        },
        "customFieldsActivity": "Custom Fields Activity",
        "deprecatedOption": "Deprecated Option",
        "customFieldsDescription": "Custom Questions will be added to your planner flow and the answers will display in your plan and incident manager.",
        "businessContinuity": "Business Continuity",
        "disasterRecovery": "Disaster Recovery",
        "bcCustomQuestionsTitle": "Business Continuity Custom Questions",
        "bcCustomQuestionsDescription": "Manage your business continuity customs questions here.",
        "drCustomQuestionsTitle": "Disaster Recovery Custom Questions",
        "drCustomQuestionsDescription": "Manage your disaster recovery custom questions here by [[technology]] type.",
        "technology": "[[Technology]]",
        "technologyTypes": "[[Technology]] Types"
    },
    "iqStatus": {
        "0": "",
        "1": "Not Established"
    },
    "cre": {
        "locations": "[[Locations]]",
        "location": "[[Location]]"
    },
    "apiManagement": {
        "newApi": "New",
        "thirdPartyIntegrations": "3rd Party Integrations",
        "integrationName": "Name",
        "appId": "App Id",
        "apiKey": "Api Key",
        "addApiHeader": "New Integration",
        "confirmButtonText": "Save",
        "deleteApi": "Delete",
        "confirmRemoveHeader": "Delete Integration",
        "removeConfirm": "This integration will be removed from all companies currently using it. Are you sure you want to remove this integration?",
        "errorUnableToLoad": "Unable to load 3rd Party Integrations at this time.",
        "errorUnableToDelete": "Unable to delete this 3rd Party Integration at this time.",
        "successDelete": "Successfully deleted the 3rd Party Integration",
        "successCreate": "Successfully created the 3rd Party Integration"
    },
    "panicTriggers": {
        "panicTriggerConfiguration": "Panic Trigger Configuration",
        "panicTriggerLocations": "Panic Trigger Locations",
        "managePanicTriggers": "Manage Panic Triggers",
        "panicTriggersDescription": "Incidents can be triggered through mobile application Panic button. This page allows to configure the saved message the incident.",
        "lblEnablePanic": "Enable Panic",
        "editMessageTemplate": "Edit Trigger",
        "deleteMessageTemplate": "Delete Trigger",
        "addMessageTemplate": "Add Default Trigger",
        "addCustomizedByLocation": "Add Trigger Customized By Locations",
        "contacts": "Contacts",
        "filters": "Filters",
        "locations": "Locations",
        "groups": "Groups",
        "recipients": "Recipients",
        "btnSaveAndConfigureMessage": "Accept and Configure Message",
        "btnConfigureMessage": "Configure Message",
        "deleteConfirmation": {
            "header": "Remove Panic Trigger",
            "body": "Are you sure you want to remove this Panic Trigger?",
            "text": "Remove"
        },
        "enablePanicConfirmation": {
            "header": "Enable Panic",
            "body": "Are you sure you want to enable Panic?",
            "text": "Enable"
        },
        "disablePanicConfirmation": {
            "header": "Disable Panic",
            "body": "Are you sure you want to disable Panic?",
            "text": "Disable"
        },
        "disablePanicAndRemovePanicButtonsConfirmation": {
            "header": "Remove [[Alert]] Buttons and Disable Panic",
            "body": "<p>A Panic or Scheduled Check-in [[alert]] button currently exists on the mobile application. (See Mobile Initiated [[Alerts]] [[Settings]].)</p><p>Disabling Panic will remove these buttons. Are you sure you want to remove these buttons and disable Panic?</p>",
            "text": "Remove Buttons and Disable"
        },
        "locationsNotAvailable": "There are no locations available to create a Panic Trigger.",
        "panicEnabledSuccess": "Panic is enabled",
        "panicDisabledSuccess": "Panic is disabled",
        "panicEnableFailed": "Failed to enable Panic",
        "panicDisableFailed": "Failed to disable Panic"
    },
    "panic": {
        "panic": "Panic",
        "initiator": "Initiator",
        "lastLocation": "Last location",
        "status": "Status",
        "startTime": "Start time",
        "resolutionTime": "Resolution time",
        "gotToIncidentDetail": "Incident details",
        "updateStatus": "Update status",
        "archiveSelected": "Archive selected",
        "active": "Active",
        "scheduledCheckIn": "Scheduled Check-In",
        "archived": "Archived",
        "resolved": "Resolved",
        "scheduled": "Scheduled",
        "noPanic": "No panic",
        "resolutionReasons": {
            "mistake": "Mistake",
            "clear": "Clear",
            "checkIn": "CheckIn",
            "custom": "Custom"
        },
        "resolve": "Resolve",
        "comments": "Comments",
        "noComments": "No comments",
        "resolveComments": "Resolve comments",
        "reason": "Reason",
        "noLastLocationRecorded": "No last location recorded",
        "archiveBody": "Are you sure you want to archive this?",
        "archive": "Archive",
        "scheduledCheckInMinutes": "Scheduled check-in in {{minutes}} minutes",
        "panicDashboard": "Panic Dashboard",
        "showHistory": "Show Breadcrumb",
        "options": "Options",
        "showLocations": "Show Locations",
        "showPOIs": "Show POIs",
        "geolocations": "Geolocations",
        "showUsers": "Show Other Users",
        "remaining": "Remaining",
        "resolveCommentsRequired": "Resolve comments required",
        "clickToView": "Click to view",
        "scheduledTime": "Schedule Created At",
        "buttonName": "[[Alert]] Name",
        "emergencyButtonDashboard": "Mobile Initiated [[Alerts]]"
    },
    "branding": {
        "branding": "Branding",
        "brandingDescription": "Substitute some terms in the portal. User must logout and login again to see new terms in the portal.",
        "term": "Term",
        "brandedTerm": "Branded Term",
        "brandedTermCamel": "Capitalized",
        "brandedTermLower": "Lower Case",
        "brandedTermCamelPl": "Capitalized Plural",
        "brandedTermLowerPl": "Lower Case Plural",
        "brandedTerms": "Branded Terms",
        "addBrandedTerm": "Add Term",
        "brandingActivity": "Branding Activity"
    },
    "users": {
        "users": "Users",
        "lastName": "Last Name",
        "firstName": "First Name",
        "email": "Email",
        "role": "Role",
        "language": "Language",
        "status": "Status",
        "usersFilterPlaceHolder": "Refine results by: Name or Email",
        "newUser": "New User",
        "existingUser": "Existing user",
        "isSelfRegistered": "Opted In",
        "bulkResendInviteModel": {
            "header": "Resend Invites to Pending Users",
            "body": "There are {{ x }} displayed users who are in a pending status. Do you want to resend invites to only the displayed pending users or all pending users? Sending a new invite will invalidate all existing invitations.",
            "allPending": "All Pending",
            "displayedPending": "Displayed Pending"
        },
        "individualResendInviteModel": {
            "header": "Resend Invite to Pending User",
            "body": "Are you sure you want to resend the invitation to this {{ userName }}?",
            "text": "Okay"
        },
        "invitationSentMsg": "Invitation has been sent.",
        "invitationSentError": "Unable to resend invitation.",
        "invitationsSentMsg": "Invitations have been sent to designated pending users.",
        "invitationsSentError": "Unable to resend invitations to designated pending users.",
        "resendInvite": "Resend Invite",
        "ssoResendInviteError": "SSO Only, no registration required.",
        "export": {
            "allUsers": "All Users",
            "displayedUsers": "Displayed Users",
            "header": "Export Users",
            "body": "Do you want to export only the displayed {{ x }} users or all users?",
            "successMsg": "Export successfully generated",
            "errorMsg": "Error generating export",
            "fileName": "BulkUserUpload.csv"
        },
        "bulkUpload": {
            "lblBulkUpload": "Bulk Upload Users",
            "msgBulkUploadInstructions": "Have multiple users you want to add or modify? Choose a correctly formatted file from your computer to start the process.",
            "msgDropFile": "Drop File Here or Click to Select a File",
            "lblSelectedFile": "Selected file to upload",
            "lblNoFileSelected": "No file specified.",
            "lblNeedStartingPoint": "Need a Starting Point",
            "lblAddMultipleHeader": "Adding multiple new users",
            "lblAddMultipleDetail": "Download this spreadsheet containing header information only and fill in your new user's information, one user per row, before saving it in .CSV format to your computer.",
            "btnBlankFile": "Blank User File",
            "lblEditMultipleHeader": "Edit or Delete multiple users",
            "lblEditMultipleDetail": "Download this spreadsheet containing your current user information and modify as needed before saving it in .CSV format to your computer.",
            "btnCurrent": "Current Users",
            "lblEditMultipleNotes": "NOTE: To delete users, change their status to Delete. Removing them from the spreadsheet will not delete them from the system. The bulk upload can be completed even if you do not fill in every field for a user.",
            "lblUpload": "Submit",
            "btnCancel": "Cancel",
            "errorUserExportFailed": "Unable to export a user file.",
            "msgUserExportSuccessful": "User export completed successfully.",
            "msgTestBulkUploadFile": "This file will only be validated because the file name contains the word \"test\"; updates will not be processed.",
            "msgUploadSuccess": "Bulk upload successful.",
            "msgExportSuccessful": "Export successfully generated.",
            "errorExportFailed": "Unable to export file.",
            "msgValidatingFile": "Verifying file...",
            "errorInvalidBulkFileType": "The selected file has an incorrect file extension it must have an .CSV extension."
        },
        "errorUserLimitReached": "User Limit Reached"
    },
    "variableContentTag": {
        "insert": "Insert",
        "companyName": "Company name",
        "address": "Address",
        "startDate": "Start date",
        "initiatedByName": "Initiated by name",
        "resolvedDate": "Resolved date",
        "resolvedByName": "Resolved by name",
        "comments": "Comments",
        "resolveReason": "Resolve reason",
        "resolveComments": "Resolve comments",
        "insertButtonTooltip": "Select a value in the list to add the parameter to the message. The actual values will be substituted when the message is sent."
    },
    "bulkUploadHistory": {
        "dateTimeSubmitted": "Date Submitted",
        "totalCount": "Total",
        "validCount": "Valid",
        "errorCount": "Errors",
        "originalInputFileName": "Input File",
        "canonicalCsvFileName": "[[Report]] File",
        "errorCsvFileName": "Error File",
        "title": "Bulk Upload History"
    },
    "home": {
        "accessDocuments": "Access Documents",
        "gotoTraining": "Go to Training",
        "gotoResources": "Go to Resources",
        "incidentHistory": "Incident History",
        "manageCrisisTeam": "Manage Crisis Team",
        "callInAnnouncement": "Call-In Announcement",
        "conferenceCalls": "Conference Call",
        "crisisTeam": "Crisis Team",
        "viewAllCrisisTeams": "View all Crisis Teams",
        "cyber": "Cyber",
        "documents": "Documents",
        "emergencyContacts": "Emergency contacts",
        "featuredCourses": "Featured courses",
        "firstAid": "First aid",
        "health": "health",
        "incidentManagement": "[[Incident Manager]]",
        "incidents": "incidents",
        "learn": " Learn",
        "naturalDisasters": "Natural disasters",
        "newIncident": "New Incident",
        "recentNews": "Recent news",
        "resources": "Resources",
        "respondToIncident": "Respond to incident",
        "sendEmergencyMessage": "Send Message",
        "splashMessage": "Splash Message",
        "terrorism": "Terrorism",
        "training": "Training",
        "workplace": "Workplace",
        "featureCoursesInstructions": "Take our online training courses to build your knowledge and preparedness.",
        "incidentOptions": "Communicate effectively before, during and after an incident",
        "incidentMgmtSummary": "Access critical information, tools and teams",
        "resourceList": "THREATS, CHECKLISTS, TABLETOPS, AND MORE",
        "resourceInstructions": "Browse our detailed information to learn how best to prepare and respond effectively and safely.",
        "mainPhone": "Main",
        "securityPhone": "Security",
        "emergencyPhone": "Emergency Call-In",
        "announcementsPhone": "Announcements",
        "teamMembers": "Crisis Team Members ({{count}})",
        "viewCrisisTeamPage": "View Team Page",
        "iq": "iQ",
        "complianceCalendar": "Compliance Calendar",
        "plans": "Plans",
        "iqScore": "iQ Score",
        "reports": "[[Reports]]",
        "featuredResources": "Feature resources",
        "announcement": "Announcement",
        "noneOnRecord": "None on record",
        "messaging": "Messaging",
        "sinkSendMessageDescription": "Send two-way emergency messages featuring voice, SMS text, email, push and desktop notifications.",
        "sinkAlertDescription": "Select locations to be placed on [[alert]] status with Preparis.",
        "communicationsManagement": "Communications Management",
        "communicationsManagementDescription": "Communicate effectively before, during and after an incident",
        "resourcesDescription": "Browse our detailed information to learn how best to prepare and respond effectively and safely",
        "viewResources": "View Resources",
        "resourcesSubtitle": "Threats, Checklists, Tabletops, & More",
        "trainingDescription": "Take our online training courses to build your knowledge and preparedness",
        "moreInformation": "More Information",
        "sendNotifications": "Send Notifications",
        "msgLoadingWeatherData": "Loading weather information...",
        "viewDocuments": "View Documents",
        "viewHistory": "View History",
        "bcPlanningDescription": "Plan, test, and be prepared for an incident",
        "bcDashboardDescription": "Review your team's progress along with selected recovery time objectives",
        "goToDashboard": "Go To Dashboard",
        "bcPlannerDescription": "Let's begin planning! Build your team of experts",
        "startPlanning": "Start Planning",
        "agilityPlanner": "Preparis [[Planner]]",
        "buildYourPlan": "Build Your Plan",
        "documentsDescription": "Store important documentation in one cloud-based location.",
        "bcIncidentManagement": "Report New Incident",
        "bcIncidentManagementDescription": "Report and quickly manage your incident",
        "bcIncidentHistoryDescription": "View current or past incident history",
        "safetyPassTitle": "Safety Pass",
        "safetyPassDescriptions": "Digital health pre-screening, facility entry verification, safety data monitoring, and close contact tracing"
    },
    "documents": {
        "documents": "Documents",
        "lblName": "Name",
        "lblOwner": "Owner",
        "lblDateTimeModified": "Last Modified",
        "fileDetails": "Document Details",
        "folderDetails": "Folder Details",
        "lblSelectDocumentForDetail": "Select a file or folder to view its details",
        "lblSelectDocumentForPermission": "Select a folder to view its permissions",
        "lblType": "Type",
        "lblCreatedBy": "Owner/Created By",
        "lblCreatedOn": "Created On",
        "lblLastModifiedBy": "Modified By",
        "lblLastModifiedOn": "Modified On",
        "lblSize": "Size",
        "lblFolder": "Folder",
        "lblDetails": "Details",
        "lblPermissions": "Permissions",
        "lblIsPrivate": "Private document",
        "lblSetDocumentPrivate": "Set Document Private",
        "lblCurrentPermissions": "Current Permissions",
        "lblNewPermissions": "New Permissions",
        "msgIsPrivate": "This document is private and can only be accessed by the owner of the document and document administrator",
        "lblPages": "Pages",
        "lblInherited": "( Inherited )",
        "successRenameDocumentMessage": "Document information is updated",
        "successRenameFolderMessage": "Folder information is updated",
        "errorRenameDocumentMessage": "Failed to update document information",
        "errorRenameFolderMessage": "Failed to update folder information",
        "errorDocumentNameExist": "Document with same name already exists, please enter a different document name.",
        "errorFolderNameExist": "Folder with same name already exists, please enter a different folder name.",
        "errorCheckDocumentStatus": "Unable to verify document status.",
        "errorCheckFolderStatus": "Unable to verify folder status.",
        "msgErrorDownloadFileNoContent": "File content is missing or corrupted!",
        "successCreateFolderMessage": "Folder {{ name }} is created",
        "errorCreateFolderMessage": "Failed to created folder {{ name }}",
        "lblAreYouSureDeleteDocument": "Are you sure you want to delete ",
        "msgAccessDenied": "You do not have the permissions to delete this folder",
        "msgCannotDeleteRoot": "Root folder can not be deleted",
        "msgFolderHasContents": "This folder cannot be deleted because it is not empty, please remove all folder and documents in the folder before deleting the folder.",
        "successDeleteDocumentMessage": "File {{ name }} was deleted.",
        "successDeleteFolderMessage": "Folder {{ name }} was deleted.",
        "errorDeleteDocumentMessage": "Failed to delete file {{ name }}",
        "errorDeleteFolderMessage": "Failed to delete folder {{ name }}",
        "successMoveFileMessage": "File {{ name1 }} is moved to folder {{ name2 }}",
        "successMoveFolderMessage": "Folder {{ name1 }} is moved under folder {{ name2 }}",
        "errorMoveFileMessage": "Failed to move file {{ name1 }} to folder {{ name2 }}",
        "errorMoveFolderMessage": "Failed to move folder {{ name1 }} under folder {{ name2 }}",
        "noPermissionMoveFileMessage": "You cannot move this file to folder {{ name1 }}",
        "noPermissionMoveFolderMessage": "You cannot move this folder to folder {{ name1 }}",
        "msgDocumentExists": "Document with same name already exists in the selected folder, please rename the document or select a different document.",
        "msgFolderExists": "Folder with same name already exists in the selected folder, please rename the folder or select a different folder.",
        "successUploadFileMessage": "File {{ name }} is uploaded",
        "errorUploadFileMessage": "Failed to upload file {{ name }}",
        "msgSameFolder": "Cannot move folder under itself",
        "msgCrisisTeamPage": "Allow access to the document from any of the following crisis teams pages.",
        "msgLoadingCrisisTeams": "Loading Crisis Teams",
        "msgNoCrisisTeam": "No crisis team",
        "successSetDocumentPrivateMsg": "Document privacy updated successfully",
        "failSetDocumentPrivateMsg": "Failed to update document privacy",
        "successDeleteCrisisTeamMessage": "Document is removed from Crisis Team page for {{ name }}",
        "errorDeleteCrisisTeamMessage": "Failed to remove document from  Crisis Team page for {{ name }}",
        "successAddCrisisTeamsMessage": "Crisis Teams are added",
        "warningDocumentExist": "Attachment with same name already exists, would you like to replace the existing attachment or save as a new attachment?",
        "warningReplaceDocument": "Are you sure you want to replace the existing document with the selected document?",
        "lblViewOnlyAccess": "View Only Access",
        "lblEditAccess": "Edit Access",
        "lblCrisisTeamPages": "Crisis Team Pages",
        "errorAddCrisisTeamsMessage": "Failed to add Crisis Teams",
        "successSetDocumentAccessGroupsMessage": "Document access permission is saved",
        "errorSetDocumentAccessGroupsMessage": "Failed to save document access permission",
        "successSetFolderAccessGroupsMessage": "Folder access permission is saved",
        "errorSetFolderAccessGroupsMessage": "Failed to save folder access permission",
        "lblCrisisTeams": "Crisis Teams",
        "lblConfirmDeleteCrisisTeam": "Are you sure you want to remove this document from {{ name }} Crisis Team page?",
        "lblConfirmPrivateDocument": "<p>Are you sure you want to make this document private?</p><p> A private document is only accessible by the owner and user with document administration role.</p>",
        "lblCurrentDocumentIsPrivate": [
            "The current document is private.",
            "A private document is only accessible by the owner and user with document administration role."
        ],
        "lblConfirmNonPrivateDocument": "<p>Are you sure you want this document to inherit access permissions from its containing folder?</p><p> A non private document will inherit access groups from it's folder by default.</p>",
        "lblMoveDocumentTo": "Move To",
        "lblNewFolder": "Create New Folder",
        "lblDocumentDescription": "Document description",
        "folderName": "Folder Name",
        "lblEveryoneHasAccess": "You have selected to give everyone view access to this file",
        "lblViewAccessGroupsHeader": "Update VIEW ONLY Permissions for: {{ name }}",
        "lblEditAccessGroupsHeader": "Update EDIT Permissions for: {{ name }}",
        "lblViewAccessGroupInformation": "Select from any category below or pick from just one to set who can view the file.",
        "lblEditAccessGroupInformation": "Select from any category below or pick from just one to set who can edit the file.",
        "lblAccessFor": "Grant access to users who are in",
        "lblAccessGroupNone": "No users",
        "lblKeepPermissions": "Keep current permissions",
        "lblUseNewPermissions": "Use new permissions",
        "noDocuments": "No Documents",
        "msgPermissionChanges": "Permission changed related message goes here",
        "msgNoPermissionToMoveDocument": "You do not have sufficient permissions to move to this folder",
        "msgFolderItself": "You can not move the folder to itself",
        "msgSameAsParentFolder": "This is the same parent folder",
        "msgKeepPermissionWarnings": "keeping current permissions may have the [[risk]] of conflicts with the new folder permissions, this can make the document or folder inaccessible to users except document administrators.",
        "msgFolderPermissionWarnings": "Changing the permissions of this folder will affect the inherited permissions of all files in the folder, but the permissions of all subfolders will remain the same",
        "folderPath": "Path",
        "successUploadFilesMessage": "Files were uploaded successfully",
        "errorUploadFilesMessage": "Failed to upload files",
        "errorDocumentNameExistInReplace": "Document with same name already exists, please upload a document with a different name.",
        "lblReplaceTitle": "Replace Document",
        "fileType": {
            "unknown": "Unknown",
            "MP3": "Audio File",
            "MP4": "Audio File",
            "BMP": "Bitmap Image",
            "CSS": "CSS Stylesheet",
            "CSV": "Excel Document",
            "DOC": "Word Document",
            "DOCX": "Word Document",
            "HTM": "Html File",
            "HTML": "Html File",
            "HTMLS": "Html File",
            "ICO": "Icon Image",
            "JPE": "jpeg Image",
            "JPEG": "jpeg Image",
            "JPG": "jpeg File",
            "JS": "Javascript File",
            "PDF": "Acrobat PDF File",
            "PNG": "png Image",
            "GIF": "gif Image",
            "PPT": "Powerpoint Document",
            "RTF": "Text File",
            "TEXT": "Text Document",
            "TIF": "Tif Image",
            "TIFF": "Tif Image",
            "TXT": "Text Document",
            "Unknown": "Unknown",
            "WAV": "Wave Audio",
            "XLC": "Excel Document",
            "XLM": "Excel Document",
            "XLS": "Excel Document",
            "XLSX": "Excel Document",
            "XLT": "Excel Document",
            "PPTX": "PowerPoint Document",
            "XML": "Xml File",
            "ZIP": "Compressed Files",
            "RAR": "Compressed Files",
            "EXE": "Application"
        },
        "itemsCategories": {
            "image": "Image",
            "wordDocument": "Word Document",
            "spreadsheet": "Spreadsheet",
            "document": "Document",
            "folder": "Folder",
            "message": "Message",
            "audio": "Audio",
            "text": "Text"
        },
        "uploadNewDocument": "Upload New Document",
        "title": "Documents",
        "searchPlaceholder": "Search for a document",
        "addNew": "Upload New Document",
        "createFolder": "New Folder",
        "searchTitle": "Search Results",
        "or": "or",
        "allUsers": "All users",
        "lblPermissionsHeader": "Document Permissions"
    },
    "myPreparis": {
        "lblMyPreparis": "My Preparis",
        "lblMyDocuments": "My Documents",
        "lblMyBookmarks": "My Bookmarks",
        "lblDeleteBookmark": "Delete Bookmark",
        "lblNavigatePage": "Navigate to Page",
        "lblAreYouSureDeleteBookmark": "Are you sure you want to remove bookmark {{ name }}?",
        "msgSuccessDeleteBookmark": "Bookmark {{bookmark}} is deleted",
        "msgErrorDeleteBookmark": "Failed to delete bookmark {{bookmark}}",
        "lblSelectBookmarkForDetail": "Select a bookmark to view its details",
        "lblBookmarkDetails": "Bookmark Details",
        "lblNote": "Note",
        "bookmarkTypePage": "Page",
        "lblBookmarkOn": "Bookmarked On",
        "msgSuccessUpdateBookmark": "Bookmark {{ name }} is updated",
        "msgErrorUpdateBookmark": "Failed to update bookmark {{ name }}"
    },
    "resources": {
        "resources": "Resources",
        "threats": "Threats",
        "tabletops": "Tabletops",
        "checklists": "Checklists",
        "webinars": "Webinars",
        "more": "More",
        "featuredThreats": "Featured threats",
        "otherFeaturedResources": "Other featured resources",
        "byCategory": "By category",
        "sort": "Sort",
        "searchCurrentSelections": "Search current selections",
        "featured": "Featured",
        "noResources": "No Resources",
        "supportingResources": "Supporting Resources",
        "relatedResources": "Related Resources",
        "newResources": "New Resources",
        "webinarsDescription": "Preparis regularly hosts webinar sessions on the most critical threats to businesses and buildings, created by a team of subject matter experts across an array of industries. Click below to view some of our most popular and recent sessions:",
        "threatsDescriptionOverview": "Foundational information on a wide array of threats, including signs or symptoms, preparation methods, response steps, and recovery guidelines.",
        "checklistsDescriptionOverview": "Action-based checklists covering a range of threats and policy topics geared toward helping you build your business continuity program.",
        "tabletopsDescriptionOverview": "Test your plans and level of preparedness in a group [[setting]] using our tabletop exercise drills.",
        "moreResourcesDescriptionOverview": "Learn more about your role and protocols as well as find additional information and tools that may help you during a crisis, such as infographics, templates, and reference sheets.",
        "webinarsDescriptionOverview": "Webinar sessions on the most critical threats to businesses and buildings, created by a team of subject matter experts across an array of industries.",
        "inTheSpotLight": "In the spotlight"
    },
    "training": {
        "training": "Training",
        "brandNewCourses": "Brand new courses",
        "featuredCourses": "Featured courses",
        "noNewCourses": "No new courses",
        "noFeaturedCourses": "No featured courses",
        "noTrainingCourses": "No training courses",
        "startedOn": "Started on",
        "completedOn": "Completed on",
        "resume": "Resume",
        "retake": "Retake",
        "launch": "Launch",
        "similarCourses": "Similar courses",
        "noSimilarCourses": "No similar courses",
        "courseHistory": "Course history",
        "firstAccessDate": "First access date",
        "lastAccessDate": "Last access date",
        "completedDate": "Completed date",
        "errorLaunchingTrainingCourse": "An error has occurred while launching this training course",
        "filter": "Filter",
        "incomplete": "Incomplete",
        "completed": "Completed",
        "inProgress": "In progress",
        "status": "Status",
        "completionCertificate": "Completion certificate"
    },
    "portal": {
        "navigate": {
            "navigatePortal": "Navigate Portal",
            "changePortal": "Change Portal",
            "msgSwitchPortalFailed": "Failed to switch to portal {{ portalName }}.",
            "msgSwitchPortalSucceed": "Switch to portal {{ portalName }} succeeded",
            "msgErrorRetrievingPortalList": "Failed to retrieve a list of portals.",
            "portalName": "Portal name...",
            "searchResults": "Only the first 50 records are displayed"
        },
        "modules": {
            "errorSavePortalModules": "Failed to update portal modules.",
            "msgSavePortalSettingsSuccess": "Portal [[settings]] are saved.",
            "lblModulesSetup": "Modules Setup",
            "lblAlwaysAllowed": "Always Allowed",
            "lblAllowed": "Allowed",
            "lblNotAllowed": "Not Allowed"
        },
        "portal": "Portal",
        "portals": "Portals",
        "newPortal": "New Portal",
        "name": "Name",
        "parentName": "Parent Name",
        "parentPortalName": "Parent Portal Name",
        "verticalType": "Vertical Type",
        "portalSubdomain": "Portal Subdomain",
        "portalName": "Portal Name",
        "numberOfUsersAssignedTo": "# of Users Assigned to",
        "callInNumberSetup": "Call-In Number Setup",
        "childPortal": "Child Portal",
        "modulesSetup": "Modules Setup",
        "portalsFilterPlaceHolder": "Refine results by: Name or Parent",
        "errorLoadingPortals": "Error loading list of portals",
        "createPortal": "Create Portal",
        "createChildPortal": "Create Child Portal",
        "savePortalSuccess": "Portal has been successfully saved",
        "savePortalError": "Portal changes were not saved",
        "industry": "Industry",
        "callInNumber": {
            "errorRegisteringNewNumber": "There was a problem registering your new number.",
            "successRegisteringNewNumber": "Success registering new number.",
            "errorDeregisteringExistingNumber": "There was a problem de-registering your old number.",
            "phoneNumberReleaseSuccess": "{{ currentPhoneNumber }} has been released.",
            "modalTitle": "Call-In Announcements Setup",
            "newTelephoneNumberInstructions": "Configure a new Call-In Announcements telephone number.",
            "editTelephoneNumberInstructions": "Change your Call-In Announcements telephone number.",
            "announcementsNumber": "Announcements Number",
            "loading": "Loading",
            "releaseNumber": "Release Number",
            "noNumberAssigned": "No number assigned",
            "availableNumbers": "Available Numbers",
            "chooseFromAvailableNumbers": "Choose from the following phone numbers:",
            "saveAndUpdate": "Save and Update",
            "current": "Current",
            "new": "New",
            "selectAvailableNumber": "Select an available number below",
            "containsNumber": "Contains",
            "country": "Country",
            "areaCode": "Area Code",
            "numberType": "Number Type",
            "local": "Local",
            "tollFree": "Toll-Free",
            "phoneNumberContains": "Enter the desired digits (or characters)",
            "search": "Search",
            "saveSuccessMsg": "New call-in number saved for {{ portalName }}",
            "configureNewNumber": "Configure New Number"
        },
        "portalSetting": {
            "portalSettings": "Portal [[Settings]]",
            "filterSettingsPlaceHolder": "Filter on [[setting]] name...",
            "settingName": "Name",
            "settingValue": "Value",
            "portalSpecific": "Portal Specific",
            "showAllSettings": "Show All [[Settings]]",
            "saveSuccess": "Portal [[settings]] are saved.",
            "saveError": "Failed to update portal [[settings]]."
        },
        "portalIndustry": "Industry",
        "lblSelectIndustry": "Please select an industry",
        "msgFailSaveIndustry": "Failed to save portal industry"
    },
    "scheduledMessages": {
        "scheduledMessages": "Scheduled Messages",
        "searchScheduledMessagesPlaceholder": "Search scheduled messages",
        "newScheduledMessage": "New Scheduled Message",
        "dateCreated": "Date Created",
        "scheduledDate": "Scheduled Date",
        "scheduledTime": "Scheduled Time",
        "timeZone": "Time Zone",
        "createdBy": "Created By",
        "errorLoadingScheduledMessagesMsg": "Error while loading scheduled messages.",
        "deleteScheduledMessageTooltip": "Delete scheduled message",
        "confirmDelete": {
            "header": "Remove scheduled message",
            "body": "Are you sure you want to remove this scheduled message?",
            "text": "Remove"
        },
        "noScheduledMessages": "No scheduled messages"
    },
    "manage": {
        "nav": {
            "lblOverview": "Overview",
            "lblManage": "Manage",
            "lblSetup": "Setup",
            "lblMessaging": "Messaging",
            "lblSystem": "System",
            "lblRoles": "Roles",
            "lblUsers": "Users",
            "lblLocations": "Locations",
            "lblDepartments": "[[Departments]]",
            "lblGroups": "Groups",
            "lblTenants": "Tenants",
            "lblGeofences": "Geofences",
            "lblPOIs": "Point of Interest",
            "lblMessageSettings": "Messaging [[Settings]]",
            "lblUserInitiatedIncident": "User Initiated Incidents",
            "lblIncidentTypes": "Incident Types",
            "lblAlertSubscription": "[[Alert]] Subscriptions",
            "lblIncidentTriggers": "Incident Triggers",
            "lblPanicConfiguration": "Panic Configuration",
            "lblMobileEmergencyButtons": "Mobile Initiated [[Alerts]] [[Settings]]",
            "lblCompanyProfile": "Company Profile",
            "lblSecurity": "Security",
            "lblSystemEmails": "System Emails",
            "lblCustomFields": "Custom Fields",
            "lblBranding": "Customization",
            "lblSelfRegistration": "Opt-In",
            "lblConfigureBulkUpload": "Configure Bulk Upload"
        },
        "manageEntitySidebar": {
            "lblNA": "N/A",
            "lblCreatedOn": "Created On",
            "lblCreatedBy": "Created By",
            "lblLastModifiedOn": "Last Modified On",
            "lblLastModifiedBy": "Last Modified By",
            "lblUserCount": "# of Users"
        },
        "manageMembers": {
            "manageMembers": "Manage [[members]]",
            "lblManageMembersHeader": "Manage [[Members]] for {{entityTypeName}}",
            "lblManageMembersDirections": "Select user(s) and then use the left and right arrows to configure who is part of this {{entityTypeName}}.",
            "btnSaveAndUpdate": "Save and update",
            "updateMembersSuccess": "[[Members]] successfully updated",
            "usersNotInEntity": "Users not in {{entityTypeName}}",
            "currentEntityMembers": "Current [[Members]] in {{entityTypeName}}"
        },
        "bulkUpload": {
            "lblBulkUpload": "Bulk Upload",
            "lblBulkUploadUsers": "Bulk Upload Users",
            "msgBulkUploadInstructions": "Have multiple {{entityTypeName}}s you want to add or modify? Choose a correctly formatted file from your computer to start the process.",
            "msgBulkUploadAddModifyInstructions": "Have multiple {{pluralName}} you want to add or modify? Choose a correctly formatted file from your computer to start the process.",
            "msgDropFile": "Drop File Here or Click to Select a File",
            "lblSelectedFile": "Selected file to upload",
            "lblNoFileSelected": "No file specified.",
            "lblNeedStartingPoint": "Need a Starting Point",
            "lblAddMultipleUsersHeader": "Adding multiple new users",
            "lblAddMultipleUsersDetail": "Download this spreadsheet containing header information only and fill in your new user's information, one user per row, before saving it in .CSV format to your computer.",
            "btnBlankUserFile": "Blank User File",
            "btnBlankFile": "Blank {{name}} File",
            "lblUpload": "Submit",
            "btnCancel": "Cancel",
            "errorUserExportFailed": "Unable to export a user file.",
            "msgUserExportSuccessful": "User export completed successfully.",
            "msgTestBulkUploadFile": "This file will only be validated because the file name contains the word \"test\"; updates will not be processed.",
            "msgUploadUsersSuccess": "Bulk upload successful.",
            "msgValidatingFile": "Verifying file...",
            "errorInvalidBulkFileType": "The selected file has an incorrect file extension it must have an .CSV extension.",
            "btnBlankEntitiesFile": "Blank {{entityTypeName}} File",
            "lblEditMultipleEntitiesDetail": "Download this spreadsheet containing your current [[location]] information and modify as needed before saving it in .CSV format to your computer.",
            "lblEditMultipleDetail": "Download this spreadsheet containing your current {{name}} information and modify as needed before saving it in .CSV format to your computer.",
            "lblAddMultipleDetail": "Download this spreadsheet containing header information only and fill in your new {{pluralName}} information, one {{name}} per row, before saving it in .CSV format to your computer.",
            "lblEditMultipleEntitiesHeader": "Edit or Delete multiple {{entityTypeName}}s",
            "lblEditMultipleHeader": "Edit or Delete multiple {{pluralName}}",
            "lblAddMultipleHeader": "Adding multiple new {{name}}",
            "lblEditMultipleEntitiesNotes": "NOTE: To delete {{entityTypeName}}s, change their status to Delete. Removing them from the spreadsheet will not delete them from the system. The bulk upload can be completed even if you do not fill in every field for a {{entityTypeName}}. To see which fields are optional, refer to your user guide or help area in the top right corner of this window.",
            "lblDeleteNote": "NOTE: To delete {{pluralName}}, change their status to Delete. Removing them from the spreadsheet will not delete them from the system. The bulk upload can be completed even if you do not fill in every field for a {{name}}. To see which fields are optional, refer to your user guide or help area in the top right corner of this window.",
            "btnCurrentEntities": "Current {{entityTypeName}}s",
            "btnCurrent": "Current {{pluralName}}",
            "lblAddMultipleEntitiesHeader": "Adding multiple new {{entityTypeName}}",
            "lblAddMultipleEntitiesDetail": "Download this spreadsheet containing header information only and fill in your new {{entityTypeName}}'s information, one {{entityTypeName}} per row, before saving it in .CSV format to your computer.",
            "msgExportSuccessful": "Export completed successfully.",
            "errorExportFailed": "Unable to export a user file.",
            "fileName": "Bulk{{entityTypeName}}Upload.csv"
        },
        "roles": {
            "btnCreateRole": "New Role",
            "btnSaveRole": "Save Role",
            "errorCannotDeleteWithUsers": "This role cannot be deleted because there are users with this role.",
            "errorCannotDeleteDefaultForBulkUpload": "This role cannot be deleted because it is set as default role for Bulk Upload.",
            "errorDeleteRoleFailed": "An error has occurred delete Role.",
            "errorRequiredFields": "Please enter all required fields.",
            "errorSaveRoleFailed": "An error has occurred saving Role.",
            "errorUnableToLoadRole": "Unable to load Role details, please try again later.",
            "lblAccessReports": "Access [[Reports]]",
            "lblAccessReportsDesc": "Allow users to run and export [[reports]].",
            "lblAccessAdminReportsDesc": "Allow users to run and export admin [[reports]].",
            "lblAccessIncidentsReportsDesc": "Allow users to run and export incidents [[reports]].",
            "lblAccessTrainingReportsDesc": "Allow users to run and export training [[reports]].",
            "lblAccessIncidentManagementReportsDesc": "Allow users to run and export incident management [[reports]].",
            "lblAddUploadDocs": "Add/Upload Documents",
            "lblAddUploadOnly": "Add/Upload Only",
            "lblAddUploadOnlyDesc": "Allow users to ONLY add/upload new documents and assign permissions to those documents.",
            "lblAllowed": "Allowed",
            "lblAlwaysAllowed": "Always Allowed",
            "lblAreYouSureRemoveRole": "Are you sure you want to remove this role?",
            "associatedRole": "Associated Role",
            "lblBasedOnRole": "Based on Role",
            "lblReportIncident": "Report Incident",
            "lblReportIncidentDesc": "Allow user to report incidents from the mobile application",
            "lblPanicDesc": "Allow user to report panic incidents from the mobile application",
            "lblManageMessages": "Saved Messages Administration",
            "lblManageMessagesDesc": "Allow users to globally manage all saved messages in alerts, including the ability to add, modify, and delete any saved messages as well as to create, modify, and delete message folders.",
            "lblCommunication": "Communication",
            "lblSendMessageDesc": "Allow users to send message.",
            "lblConfigureMessageDesc": "Allow users to create, update message but not allow to send the message.",
            "lblConferenceCallDesc": "Allow users to create conference call.",
            "lblCallInAnnouncementDesc": "Allow users to create call-in announcement.",
            "lblSplashMessageDesc": "Allow users to create splash message.",
            "lblCompanySettings": "Company [[Settings]]",
            "lblCompanySettingsDesc": "Manage [[Settings]] That Apply to the entire Company",
            "lblCompanySettingsExplain": "Allow users to change the company name and email, voice display number, branding, security [[settings]], and system emails.",
            "lblCorePerms": "Core Permissions (Not Editable)",
            "lblCrisisTeamContacts": "Crisis Team Contacts",
            "lblDocumentMgmt": "Document Administration",
            "lblDocumentMgmtDesc": "Allow users to globally manage all documents in the portal, including ability to add, modify, and delete any document as well as to create, modify, and delete folders.",
            "lblDocumentMgmtiQDisclaimer": "iQ permission is required in order to access iQ related folders and documents in the portal.",
            "lblDocuments": "Documents",
            "lblEditEntities": "Edit Entities",
            "lblEntityMgmt": "Entity Management",
            "lblEntityMgmtExplain": "Allow users to create/modify/delete locations, groups, and [[departments]].",
            "lblHelp": "Help",
            "lblIncidentMgmt": "Incident Management",
            "lblIncidentMgmtDesc": "Allow users to create/modify/resolve incidents.",
            "lblIncludeCrisisDescription": "Display the contact information for users with this role on the Crisis Team page for each [[location]].",
            "lblIncludeCrisisTeamPage": "Include on Crisis Team Page",
            "lblManagePlansDesc": "Allow users to view, activate, deactivate, standby, or stand down [[department]] plans",
            "lblViewPlansDesc": "Provide access to users to view available [[department]] plans and [[processes]]",
            "lblListItems": "items",
            "lblLocalEmergencyContact": "Local Emergency Contact",
            "lblLoginAccess": "Login Access",
            "lblManage": "MANAGE",
            "lblManageDocuments": "Manage Documents",
            "lblManagePortal": "Manage Portal",
            "lblManageUsers": "Manage Users",
            "lblMngIncidents": "Manage Incidents",
            "lblAlerts": "[[Alerts]]",
            "lblIncidentManagement": "[[Incident Manager]]",
            "lblManagePlans": "Plan Management",
            "lblViewPlans": "View Only",
            "lblLiteMessaging": "Lite Messaging",
            "lblMsgSettings": "Message [[Settings]]",
            "lblMsgSetup": "Messaging Setup",
            "lblMsgSetupDesc": "Allow users to create saved messages, set message defaults and escalations.",
            "lblMyPreparis": "My Preparis",
            "lblMyProfile": "My Profile",
            "lblNamePlaceholder": "Enter a name for this new role",
            "lblNeverAllowed": "Never Allowed",
            "lblNewRoleTitle": "New Custom Role",
            "lblNone": "None",
            "lblNotAllowed": "Not Allowed",
            "lblNumbersDisplayed": "of",
            "permissionAndFeatures": "Permissions and Features",
            "lblReports": "[[Reports]]",
            "lblResources": "Resources",
            "lblRoleFeaturesPermissions": "Role Features & Permissions",
            "lblRoleList": "Roles",
            "roleName": "Role Name",
            "lblSearch": "Search",
            "lblSelectRoleType": "- Select Role -",
            "lblSendMessages": "Send Messages",
            "lblSendMessagesDesc": "Allow users to send emergency messages",
            "lblConfigureMessages": "Configure Messages",
            "lblMessageAdmin": "Message Admin",
            "lblMessageAdminDesc": "Allow users to message other users who are based in other locations.",
            "lblConferenceCalls": "Create Conference Calls",
            "lblCallInAnnouncements": "Create Call-In Announcements",
            "lblSplashMessages": "Create Splash Messages",
            "lblUserInitiateIncident": "User Initiate Incident",
            "lblPanic": "User Initiate Panic",
            "lblStatus": "Status",
            "lblTraining": "Training",
            "lblTrainingClasses": "Training Classes",
            "lblTrainingClassesDesc": "Allow users to view and take training classes.",
            "lblType": "Type",
            "lblUserCannotLogin": "Users cannot log in.",
            "userCount": "# of Users",
            "lblUserMgmtDesc": "User Management",
            "lblUserMgmtExplain": "Allow users to create/modify/delete users and roles.",
            "lblRoleMgmtDesc": "Role Management",
            "lblRoleMgmtExplain": "Allow users to create/modify/delete roles.",
            "lblUserReceivesMessages": "Users can only receive messages.",
            "lblViewEditDocs": "View and Edit Documents",
            "lblViewEditDocsDesc": "Note: Individual document access based on the rights assigned to each individual document",
            "lblViewReports": "View [[Reports]]",
            "lblViewAdminReports": "View Admin [[Reports]]",
            "lblViewIncidentsReports": "View Messaging [[Reports]]",
            "lblViewTrainingReports": "View Training [[Reports]]",
            "lblViewIncidentManagementReports": "View [[Incident Manager]] [[Reports]]",
            "lblViewTraining": "View Training",
            "viewPermissions": "View Permissions and Features",
            "lbliQ": "iQ",
            "lbliQAdmin": "iQ Administration",
            "lbliQAdminDesc": "Allow users to manage all scopes, [[teams]], [[team members]], [[team]] resources and administration features in iQ",
            "lbliQManagement": "iQ Management",
            "lbliQManagementDesc": "Allow users to view all scopes, manage all [[teams]], [[team members]] and [[team]] resources without administration features in iQ",
            "lbliQTeamAccess": "iQ [[Team]] Access",
            "lbliQTeamAccessDesc": "Allow users to manage [[team members]] and [[team]] resources for the associated [[teams]] in iQ",
            "lbliQAssignmentAccess": "iQ Assignment Access",
            "lbliQAssignmentAccessDesc": "Allow users to manage assigned [[team]] resources only in iQ",
            "lblBusinessContinuity": "Business Continuity",
            "lblContinuity": "Continuity",
            "lblBusinessContinuityManagement": "Business Continuity Management",
            "lblBusinessContinuityManagementDesc": "Allow users to start a business continuity assessment and assign [[team leads]]",
            "lblBusinessContinuityAudit": "Business Continuity View Only",
            "lblBusinessContinuityAuditDesc": "Allow users to view all business continuity plan information without making changes",
            "lblRisk": "[[Risk]]",
            "lblRiskAdmin": "Risk Administration",
            "lblRiskAdminDesc": "Allow users to manage all features in [[Risk]]",
            "lblRiskPartner": "[[Risk]] Partner",
            "lblRiskPartnerDesc": "Allow users to access [[Risk]] features as partner",
            "msgDeleteRoleSuccess": "Role has been successfully deleted.",
            "msgSaveRoleSuccess": "Role {{roleBundleName}} has been successfully saved.",
            "msgSaveRoleSuccessLicenseOverage": "Role {{roleBundleName}} has been successfully saved but with License Overage. Please contact your customer success [[team member]]..",
            "phSearchRoles": "Refine results by: Role name",
            "msgErrorUpdateiQRole": "Cannot change iQ permission because users with this role are accountable for iQ [[teams]]",
            "msgErrorRemoveiQRole": "Cannot remove iQ permission because users with this role has iQ assignments",
            "roles": "Roles",
            "lblRoleName": "Role Name",
            "msgErrorDuplicateRoleName": "A role already exists with this name",
            "role": "Role",
            "removeRole": "Remove Role",
            "lblRecoverProfiles": "Recovery Profiles",
            "lblViewRecoverProfiles": "View Recovery Profiles",
            "lblViewRecoverProfilesDesc": "Allow users to view recovery profiles",
            "lblEditRecoveryProfiles": "Edit Recovery Profiles",
            "lblEditRecoveryProfilesDesc": "Allow users to edit recovery profiles",
            "lblEditRecoveryProfileContacts": "Edit Recovery Profile contacts",
            "lblEditRecoveryProfileContactsDesc": "Allow users to edit recovery profile contacts",
            "lblEditRecoveryProfileGenerators": "Edit Recovery Profile Generators",
            "lblEditRecoveryProfileGeneratorsDesc": "Allow users to edit recovery profile generators",
            "lblEditRecoveryProfileSites": "Edit Recovery Profile Sites",
            "lblEditRecoveryProfileSitesDesc": "Allow users to edit recovery profile sites",
            "lblAgilitySafetyPass": "Preparis Safety Pass",
            "lblSafetyPass": "Safety Pass",
            "lblSafetyPassDesc": "Allow users to view and click on the Preparis Safety Pass section"
        },
        "locations": {
            "btnLocationCreate": "Add New [[Location]]",
            "btnMultiLocationCreate": "Bulk Upload",
            "btnSaveLocation": "Save [[Location]]",
            "btnViewAll": "ViewAll",
            "duplicateLocationName": "[[Location]] with same name already exists.",
            "errorLocationReferenced": "[[Location]] is referenced as primary [[location]] by users",
            "errorRemoveLocationFailed": "An error has occurred removing [[location]].",
            "errorSaveLocationFailed": "An error has occurred saving [[location]].",
            "lblAddressTitle": "Physical address",
            "lblAreYouSureRemoveLocation": "Are you sure you want to remove this [[location]]?",
            "lblEmergencyPhone": "Emergency Call-In Number",
            "lblFax": "Fax Number",
            "lblListItems": "items",
            "lblLocationActivity": "[[Location]] Activity",
            "lblLocationInfo": "[[Location]] information",
            "lblLocationListTitle": "Locations",
            "lblLocationMembers": "Users associated to this [[Location]]",
            "lblLocationName": "[[Location]] Name",
            "lblMainPhone": "Main Phone Number",
            "lblNewLocation": "New [[Location]]",
            "lblNumbersDisplayed": "of",
            "lblSecurityPhone": "Security Number",
            "lblOptionalManager": "Manager",
            "lblUserCount": "# of Users",
            "lblConfirmUpdateGeofence": "Update Geofence",
            "msgUpdateGeofence": "The [[location]] address has changed, would you like to update the geofence for this [[location]]?",
            "menuSortByName": "Name",
            "msgBulkUploadSubmitted": "Bulk upload has been submitted for processing.",
            "msgRemoveLocationSuccess": "[[Location]] has been successfully removed.",
            "msgSaveLocationSuccess": "[[Location]] {{entityName}} was successfully saved.",
            "phSearchLocations": "Refine results by: Name, Address, City or State",
            "name": "Name",
            "address": "Address",
            "city": "City",
            "stateProvinceRegion": "State/Province/Region",
            "country": "Country",
            "lblAddress2": "Address 2 (Suite, Bldg, etc)",
            "lblState": "State/province/region",
            "lblZip": "Zip Code",
            "email": "Email",
            "status": "Status",
            "editGeofence": "Edit Geofence",
            "createGeofence": "Create Geofence",
            "deleteLocation": "Remove [[Location]]",
            "saveAndFinish": "Save and finish",
            "removeLocation": "Remove [[location]]",
            "exceededMaxPortalLocations": "You have reached the maximum number of locations",
            "lblAvailableSeats": "Available Seats",
            "lblNotes": "Notes",
            "lblEmergencyReadiness": "Emergency Readiness",
            "lblLocationType": "[[Location]] Type",
            "selectEmergencyReadiness": "Select an Emergency Readiness",
            "selectLocationType": "Select a [[Location]] Type"
        },
        "groups": {
            "bulkUpload": "Bulk Upload",
            "btnGroupCreate": "Add New Group",
            "btnSaveGroup": "Save Group",
            "btnSaveGroupAddMember": "Save and add [[members]]",
            "duplicateGroupName": "Group with same name already exists.",
            "errorRemoveGroupFailed": "An error has occurred removing group.",
            "errorRequiredFields": "Please enter all required fields.",
            "errorSavGroupFailed": "An error has occurred saving group.",
            "lblAreYouSureDeleteGroup": "Are you sure you want to delete this group?",
            "lblGroupActivity": "Group Activity",
            "lblGroupMembers": "Group [[Members]]",
            "lblGroupName": "Group Name",
            "lblListItems": "items",
            "lblNewGroup": "New Group",
            "lblNumbersDisplayed": "of",
            "lblType": "Group Type",
            "lblUserCount": "# of Users",
            "menuSortByName": "Name",
            "msgBulkUploadSubmitted": "Bulk upload has been submitted for processing.",
            "msgRemoveGroupSuccess": "Group has been successfully removed.",
            "msgSaveGroupSuccess": "Group {{entityName}} has been successfully saved.",
            "phSearchGroups": "Refine results by: Name or Description",
            "msgGroupInUsedByUserInitiatedMessages": "This group is currently in used for User Initiated Incidents. Please disassociate before deleting this group.",
            "groups": "Groups",
            "name": "Name",
            "description": "Description",
            "group": "Group",
            "groupType": {
                "groupType": "Group type",
                "1": "Business Continuity",
                "2": "Corporate Incident Management",
                "3": "Local Incident Management",
                "4": "Disaster Recovery",
                "Other": "Other",
                "5": "Other"
            },
            "saveAndFinish": "Save and finish",
            "deleteGroup": "Delete Group",
            "pleaseSelect": "Please select",
            "newGroupTypeName": "New Group Type Name",
            "duplicateGroupTypeName": "Duplicate Group Type Name",
            "deleteGroupTypeHeader": "Delete Group Type",
            "deleteGroupTypeBody": "Are you sure want to delete this Group Type?",
            "groupTypes": "Group Types",
            "groupTypeName": "Group Type Name",
            "countOfGroups": "Count of Groups",
            "deleteGroupTypeInUseError": "Unable to delete Group Type. In use by at least one (1) Group.",
            "newGroupType": "New Group Type",
            "groupTypeNameIsRequired": "Group Type Name is required",
            "manageGroupTypes": "Manage Group Types"
        },
        "departments": {
            "btnMultiDepartmentCreate": "Bulk Upload",
            "btnDepartmentCreate": "Add New [[Department]]",
            "btnSaveDepartment": "Save [[Department]]",
            "btnSaveDepartmentAddMember": "Save and Add [[Member]]",
            "duplicateDepartmentName": "[[Department]] with same name already exists.",
            "errorRemoveDepartmentFailed": "An error has occurred deleting [[department]].",
            "errorRequiredFields": "Please enter all required fields.",
            "errorSavDepartmentFailed": "An error has occurred saving [[department]].",
            "lblAreYouSureDeleteDepartment": "Are you sure you want to delete this [[department]]?",
            "lblDepartmentActivity": "[[Department]] Activity",
            "lblDepartmentListTitle": "[[Departments]]",
            "lblDepartmentMembers": "[[Department]] [[Members]]",
            "lblDepartmentName": "[[Department]] Name",
            "lblDepartmentTitle": "[[Department]] Information",
            "lblListItems": "items",
            "lblNewDepartment": "New [[Department]]",
            "lblNumbersDisplayed": "of",
            "lblUserCount": "# of Users",
            "menuSortByName": "Name",
            "msgBulkUploadSubmitted": "Bulk upload has been submitted for processing.",
            "msgRemoveDepartmentSuccess": "[[Department]] has been successfully removed.",
            "msgSaveDepartmentSuccess": "[[Department]] {{entityName}} has been successfully saved.",
            "phSearchDepartments": "Refine results by: Name or Description",
            "departments": "[[Departments]]",
            "department": "[[Department]]",
            "name": "Name",
            "description": "Description",
            "deleteDepartment": "Delete [[Department]]",
            "unassociated": "Not associated",
            "renameAndAssociateDepartmentToFAConfirmation": {
                "header": "Rename And Associate",
                "body": "Are you sure you want to rename the [[Planner]] [[Department]] and create the association?"
            },
            "lblBCPortalFunctionalAreaAssociated": "Associated [[Planner]] [[Department]]",
            "createNewPortalFunctionalAreaTooltip": "Create new [[Planner]] [[Department]] and associate to this [[department]]",
            "createNewPlannerFunctionalArea": "Create New Planner Department"
        },
        "tenants": {
            "btnAddBuilding": "Add building",
            "btnAddTenant": "Add New Tenant",
            "btnMultiTenantCreate": "Bulk Upload",
            "btnSaveTenantAddBuilding": "Save and Add Building",
            "duplicateTenantName": "Tenant with same name already exists",
            "errorRemoveTenantFailed": "An error has occurred deleting tenant.",
            "errorSaveTenantFailed": "An error has occurred saving tenant.",
            "lblAddBuilding": "Add building",
            "lblBuilding": "Building",
            "lblBuildings": "Buildings",
            "lblDeleteTenant": "Delete Tenant",
            "lblDeleteTenantModalMessage": "Are you sure you want to delete this tenant?",
            "lblFloor": "Floor(s)",
            "lblNewTenant": "New Tenant",
            "lblRemoveBuilding": "Remove building",
            "lblRemoveBuildingModalMessage": "Are you sure you want to remove this building?",
            "lblSelectBuilding": "Select building",
            "lblTenantName": "Tenant name",
            "msgBulkUploadSubmitted": "Bulk upload has been submitted for processing.",
            "msgRemoveBuildingFailed": "Error removing building",
            "msgRemoveBuildingSuccess": "Building successfully removed",
            "msgRemoveTenantSuccess": "Successfully removed tenant",
            "msgSaveTenantSuccess": "Tenant {{entityName}} has been successfully saved.",
            "msgUserAssociatedWithThisBuilding": "This building cannot be removed because there are users associated to this building",
            "phSearchTenants": "Refine results by: Name or Description",
            "lblMainPhoneNumber": "Main phone number",
            "lblEditBuilding": "Edit building",
            "errorUsersAssociation": "There are users associated with this tenant. Cannot delete this tenant at this time.",
            "errorBuildingAssociation": "There are buildings associated with this tenant. Cannot delete this tenant at this time.",
            "tenants": "Tenants",
            "name": "Name",
            "description": "Description",
            "tenantMembers": "Tenant [[Members]]",
            "tenantActivity": "Tenant activity",
            "tenant": "Tenant"
        },
        "geofences": {
            "geofences": "Geofences",
            "btnGeofenceCreate": "Create New Geofence",
            "name": "Name",
            "type": "Type",
            "location": "Location",
            "circle": "Circle",
            "rectangle": "Rectangle",
            "polygon": "Polygon"
        },
        "overview": {
            "btnNew": "New",
            "btnViewAll": "view all",
            "lblCompanyProfile": "Company profile",
            "lblManage": "Manage",
            "lblMessagingSettings": "Messaging [[settings]]",
            "lblMessagingSetup": "Messaging setup",
            "lblSavedMessages": "Saved messages",
            "lblSecurity": "Security",
            "lblStatus": "Status",
            "lblSystemEmails": "System emails",
            "lblSystemSettings": "System [[settings]]",
            "lblTotal": "Total",
            "lblUserCount": "# of Users",
            "txtMessagingSetupDescription": "[[Settings]] for messaging behavior as well as saved messages that can be reused are accessed here.",
            "txtSystemSettings": "[[Settings]] contain core system functionality that applies to the entire portal.",
            "lblCustomFields": "Custom Fields",
            "lblBranding": "Customization",
            "lblUserDeleted": "Deleted",
            "lblUserInactive": "Inactive",
            "lblUserLocked": "Locked",
            "lblUserPending": "Pending",
            "lblUserActive": "Active"
        },
        "POI": {
            "pointsOfInterest": "Points of Interest",
            "name": "Name",
            "latitude": "Latitude",
            "longitude": "Longitude",
            "address": "Address",
            "btnCreatePOI": "Create New Point of Interest",
            "remove": "Remove Point of Interest",
            "confirmRemove": "Are you sure you want to remove this point of interest?",
            "msgRemovePOISuccess": "Point of interest {{ name }} has been successfully removed.",
            "msgRemovePOIFailed": "Failed to remove point of interest {{ name }}",
            "noPOIError": "No active point of interest, please create a point of interest to save.",
            "msgSavePOISuccess": "Point of interest {{ name }} has been successfully saved.",
            "msgSavePOIFailed": "Failed to save point of interest {{ name }}",
            "createPOI": "Create point of interest from this location",
            "new": "New Point of Interest"
        },
        "companyProfile": {
            "btnSaveCompany": "Save Company",
            "duplicateCompanyName": "Company with same name already exists.",
            "errorRequiredFields": "Please enter all required fields.",
            "errorSavCompanyFailed": "An error has occurred saving Company.",
            "errorSavCompanyLogoFailed": "Failed to save company logo.",
            "lblChangeLogo": "Change logo",
            "lblCompanyActivity": "Company Activity",
            "lblCompanymentEmailFromAddress": "Email - From Address",
            "lblCompanymentEmailFromName": "Email - From Name",
            "lblCompanymentVoiceNumber": "Voice Number Display",
            "lblCompanyName": "Company Name",
            "lblSubdomainName": "Subdomain Name",
            "lblCompanyTitle": "Company Profile",
            "lblIndustry": "Industry",
            "msgSaveCompanyLogoSuccess": "Company logo has been successfully saved.",
            "msgSaveCompanySuccess": "Company {{entityName}} has been successfully saved.",
            "msgSaveCompanySuccessSSOFailed": "Company {{entityName}} has been saved but SSO [[Settings]] failed to save.",
            "msgSaveCompanySuccessAPIFailed": "Company {{entityName}} has been saved but 3rd Party Integration [[Settings]] failed to save.",
            "lblCompanySSoSettingIdpUrl": "Idp Url",
            "lblCompanySSoSettingIsIdpMetadataUrlPublic": "Idp Metadata Url is Public",
            "lblCompanySSoSettingIdpMetadataUrl": "Idp Metadata Url",
            "lblCompanySSoSettingIdpMetadata": "Idp Metadata (XML)",
            "lblCompanySSoSettingReturnUrl": "Company Return Url",
            "lblCompanySSoSettingUseSso": "Single Sign On",
            "lblCompanySSoSettingLoginUrl": "SSO Login Url",
            "lblCompanySSoSettingAcsUrl": "ACS Consumer Url",
            "lblCompanySSoSettingAcsValidator": "ACS Consumer Url RegEx Validator",
            "lblCompanySSoSettingAudience": "Audience",
            "lblCompanySSoSettingRecipient": "Recipient",
            "lblCompanySSoSettingAllowNonSso": "Allow Logins Without SSO",
            "lblCompanySSoSettingAllowNonSsoRoles": "Roles Allowed to Login Without SSO",
            "lblCompanyApiSettingApi": "3rd Party Integrations",
            "lblCompanyApiSettingNoneAvailable": "There are no 3rd Party Integrations available for your company.",
            "lblApiNotAllowed": "Not Allowed",
            "lblApiActive": "Active",
            "lblApiInactive": "Inactive",
            "companyProfile": "Company Profile",
            "msgInvalidImageType": "Invalid image file type, please select a valid image file.",
            "deleteCompanyHeader": "Delete Company",
            "deleteCompanyBody": "Are you sure you want to delete this company? Please type the word '{{deleteCompanyVerificationPhrase}}' to enable the Delete button.",
            "deleteCompanyVerificationPhrase": "delete",
            "msgSuccessDeletingCompany": "Company has been successfully deleted.",
            "deletePortalErrors": {
                "7016": "Portal has Call-In announcement phone number assigned.",
                "7017": "Portal has active child portal(s)",
                "msgErrorDeletingCompany": "Error deleting portal."
            },
            "lblUploadMetadata": "Upload Metadata",
            "lblChangeMetadata": "Change Metadata",
            "tooltipMetadataEmpty": "Empty",
            "msgInvalidFileType": "Invalid metadata file type, expecting an XML file.",
            "lblCompanyHRIntegration": "HR Integration",
            "lblSubscriptionCode": "ADP Subscription code:",
            "btnGenerate": "Generate",
            "ldapConfiguration": {
                "lblLDAPConfiguration": "LDAP configuration",
                "lblConfigLDAPCode": "LDAP Code",
                "lblLDAPUrl": "Url",
                "lblReadonlyAdminUserName": "Read only admin user name",
                "lblReadonlyAdminPassword": "Read only admin password",
                "lblAllowedDomain": "Allowed Domain",
                "lblSearchGroup": "Search Group",
                "lblSearchFieldName": "Search field name",
                "lblEmailFieldName": "Email field name",
                "lblCertStores": "Certificate Stores",
                "lblCertPEMFilePath": "Certificate PEM File Path",
                "tooltipCertStores": "Provide one or coma separated list of certificate stores only if secure LDAPS is configured. Example: root,my",
                "tooltipCertPEMFilePath": "Provide path to PEM file containing certificate only if secure LDAPS is configured and the certificate is not in the Certificate Stores",
                "confirmDisable": {
                    "header": "Disable LDAP authentication",
                    "body": "Disabling LDAP authentication will result in the Desktop Application to not authenticate users, and desktop messages will not be delivered.",
                    "text": "Disable"
                }
            },
            "headerLogo": "Header Logo",
            "footerLogo": "Footer Logo",
            "poweredByLogo": "Powered By Logo",
            "noLogoApplied": "No Logo Applied"
        },
        "security": {
            "errorRequiredFields": "Please enter all required fields.",
            "errorResetSecurityFailed": "An error has occurred resetting security [[settings]].",
            "errorSavSecurityFailed": "An error has occurred saving security [[settings]].",
            "lblAdditionalSettings": "Additional Security [[Settings]]",
            "lblAllowUserManageLocation": "Allow users the ability to manage their locations",
            "lblAllowUserManagePassword": "Allow users the ability to manage their own passwords",
            "lblAllowUserManageProfile": "Allow users the ability to manage their contact information",
            "lblHideUserEmail": "Hide user's email (username) from displaying in system emails",
            "lblLowercaseLetters": "Lowercase Letters (0-10)",
            "lblMinimumLength": "Minimum Length (1-15)",
            "lblMobilePhoneRequired": "Require user to provide default mobile phone number",
            "lblNumbers": "Numbers (0-10)",
            "lblPasswordSecurity": "Customize password security requirements",
            "lblPasswordSecurityInfoPopoverTitle": "Default Password Security",
            "lblPasswordSettings": "Password [[Settings]]",
            "lblSecurityActivity": "Security Activity",
            "lblSecurityTitle": "Security",
            "lblSecurityDescription": "Manage custom password rules and other system security [[settings]]",
            "lblSymbols": "Symbols (0-10)",
            "lblUppercaseLetters": "Uppercase Letters (0-10)",
            "msgPasswordSecurityInfoPopoverText": "By default all passwords have a minimum length of 6 unless changed",
            "msgResetSecuritySuccess": "Security [[settings]] has been successfully reset.",
            "msgSaveSecuritySuccess": "Security [[settings]] have been successfully saved.",
            "lblAllowOptOutSms": "Allow users to opt out of SMS messages",
            "lblAllowOptOutEmail": "Allow users to opt out of Email messages",
            "lblAllowOptOutVoice": "Allow users to opt out of Voice messages",
            "confirmReset": {
                "body": "Are you sure you want to reset security [[settings]]?",
                "header": "Confirm",
                "btnText": "OK"
            },
            "maximumPasswordReuse": "Maximum Password Reuse",
            "neverExpires": "Never expires",
            "invalidSettings": "You have invalid [[settings]]"
        },
        "systemEmail": {
            "btnEdit": "Edit",
            "btnSave": "Save",
            "btnOk": "OK",
            "errorRevertEmailFailed": "Unable to revert the email to the default system email. Please try again.",
            "lblBody": "Email Body",
            "lblBulkUploadFailureEmail": "Bulk Upload Failure",
            "lblBulkUploadIncorrectPasswordEmail": "Bulk Upload Incorrect Password",
            "lblBulkUploadResultEmail": "Bulk Upload Results",
            "lblBulkUploadUnknownUserEmail": "Bulk Upload Unknown User Name",
            "lblChangedPasswordEmail": "Changed Password",
            "lblConfirmation": "Confirm",
            "lblEmailChangedEmail": "Email Changed",
            "lblEmailListLabel": "View template for:",
            "lblForgotPasswordEmail": "Forgot Password",
            "lblInviteUserEmail": "New User Registration",
            "lblLastModifiedBy": "Last Modified By",
            "lblLastModifiedOn": "Last Modified On",
            "lblLoading": "Please wait...",
            "lblNA": "N/A",
            "lblResetTemplateLink": "Reset to system default",
            "lblSubject": "Email Subject",
            "lblSystemEmailActivity": "System Email Activity",
            "lblSystemEmailsTitle": "System Emails",
            "lblTemplateLastModified": "Template Last Modified",
            "lblUserGroupAdditionEmail": "User Group Addition",
            "lblUserGroupRemovalEmail": "User Group Removal",
            "lblUsingCustom": "This template has been customized.",
            "msgRevertEmailSuccess": "The system email has been reverted to the default system email.",
            "msgSavedSystemEmailSuccess": "The system email has been successfully saved",
            "confirmReset": {
                "body": "Are you sure you want to revert back to the default system email?",
                "header": "Confirm",
                "btnText": "OK"
            },
            "sendMessageEmail": "Send Message Email",
            "insert": "Insert",
            "reset": "Reset",
            "resizeImageDescription": "Adjust the width and height of your image to your liking. You have the option to preserve the image aspect ratio so it won't look distorted.",
            "doYouWantResizeImageDescription": "We notice that your image is quite large. Would you like to resize it?",
            "preserveAspectRatio": "Preserve Aspect Ratio",
            "bcTeamLeadIntroductoryEmail": "Business Continuity Introductory Email For [[Team Lead]]",
            "bcTeamMemberIntroductoryEmail": "Business Continuity Introductory Email For [[Team Member]]",
            "bcNewTeamLeadIntroductoryEmail": "Business Continuity Introductory Email For [[Team Lead]] (New User)",
            "bcNewTeamMemberIntroductoryEmail": "Business Continuity Introductory Email For [[Team Member]] (New User)",
            "bcTeamLeadDueDateReminderEmail": "Business Continuity Due Date Soon Email For [[Team Lead]]",
            "bcTeamLeadDueDateNowEmail": "Business Continuity Due Date Now Email For [[Team Lead]]",
            "bcTeamMemberDueDateReminderEmail": "Business Continuity Due Date Soon Email For [[Team Member]]",
            "bcTeamMemberDueDateNowEmail": "Business Continuity Due Date Now Email For [[Team Member]]",
            "notificationEmergencyResponseEmail": "Notification Emergency Response Email",
            "riskNewAssessmentRecipientEmail": "[[Risk]] Assessment Recipient Email (New User)",
            "riskAssessmentRecipientEmail": "[[Risk]] Assessment Recipient Email",
            "riskNewAssessmentApproverEmail": "[[Risk]] Assessment Approver Email (New User)",
            "riskAssessmentApproverEmail": "[[Risk]] Assessment Approver Email",
            "riskAssessmentRecipientDueDateNowEmail": "[[Risk]] Assessment Due Date Now Email",
            "riskAssessmentRecipientDueDateReminderEmailBody": "[[Risk]] Assessment Due Date Soon Email",
            "bcNewAnnexLeadIntroductoryEmail": "Introductory Email for Annex Lead (New User)",
            "bcAnnexLeadIntroductoryEmail": "Introductory Email for Annex Leads",
            "bcAnnexLeadDueDateReminderEmail": "Due Date Soon Email For Annex Leads",
            "bcAnnexLeadDueDateNowEmail": "Due Date Now Email For Annex Leads",
            "managerNotificationEmergencyResponseEmail": "Manager Notification Emergency Response Email",
            "bcTechnologyNewLeadEmail": "Introductory Email for [[Technology]] Leads (New User)",
            "bcTechnologyLeadEmail": "Introductory Email for [[Technology]] Leads"
        },
        "messageSettings": {
            "errorRequiredFields": "Please enter all required fields.",
            "errorResetMessageFailed": "An error has occurred resetting message [[settings]].",
            "errorSavMessageFailed": "An error has occurred saving message [[settings]].",
            "lblAreYouSureResetSettings": "Are you sure you want to reset message [[settings]]?",
            "lblEscalateMessageContent1": "If you want to give message recipients time to respond by SMS, Email or Push messages before they are called, set this to Yes. You'll then be able to select the amount of time to wait before calling.",
            "lblEscalateMessageContent2": "If set to no, recipient will be called the same time they receive text/email/push messages.",
            "lblEscalateMessageContent3": "Note: [[Setting]] ONLY applies to messages that require a response and have SMS and/or Email and/or Push AND voice selected.",
            "lblEscalateMessageTitle": "Escalate message when response IS required (SMS/Email/Push to Voice)?",
            "lblIncludeOfficePhoneContent": "If set to Yes, when a user sends a new message AND has selected to Voice (call recipients). the option to call a recipient's office phone will be pre-selected. Users will be able to override the default and deselect per message if desired.",
            "lblIncludeOfficePhoneTitle": "Include office phone for voice calls by default?",
            "lblPersonalContactContent": "If set to Yes, when a user sends a new message, the option to send to message recipient's personal contact info will be pre-selected. Users will be able to override this default and deselect per message if desired.",
            "lblPersonalContactTitle": "Send message to user's personal contact info?",
            "lblSettingsActivity": "[[Settings]] Activity",
            "lblSettingsTitle": "Message [[Settings]]",
            "msgResetMessageSuccess": "Message [[settings]] has been successfully reset.",
            "msgSaveMessageSuccess": "Message [[settings]] have been successfully saved.",
            "lblPersonalContactPhoneTitle": "Send message to user's personal phone?",
            "lblPersonalContactPhoneContent": "If set to Yes, when a user sends a new message, the option to send to message recipient's personal phone info will be pre-selected. Users will be able to override this default and deselect per message if desired.",
            "personalContactEmailTitle": "Send message to user's personal email?",
            "lblPersonalContactEmailContent": "If set to Yes, when a user sends a new message, the option to send to message recipient's personal email info will be pre-selected. Users will be able to override this default and deselect per message if desired."
        },
        "userInitiatedMessage": {
            "lblPageHeader": " User Initiated Incidents [[Settings]]",
            "lblPageDescription": "Manage which users will receive messages from user initiated incidents.",
            "lblDefaultGroup": "Default Group",
            "lblDefaultGroupTip": "Users in the selected group will receive messages by EMAIL, SMS, and PUSH MESSAGES by default. This [[setting]] can be overridden per incident type below.",
            "lblIncidentTypes": "Incident Types",
            "lblDefaultGroupPerIncident": "Default Group For ",
            "lblDefaultGroupPerIncidentTip": "Users in the selected group will always receive messages for this incident type. Additional groups can receive messages using the Optional Locations [[settings]] below.",
            "lblOptionLocations": "Optional Locations",
            "lblOptionLocationsTip": "Additional groups can receive messages based on the reporting users default [[location]].",
            "btnAddNewOptionalLocation": "Add New Optional Location",
            "msgUserInitiatedMessagesSettingsSaved": "User Initiated [[Settings]] saved.",
            "msgUserInitiatedMessagesSettingsNotSaved": "There was an error saving User Initiated [[Settings]]. Please try again later.",
            "msgRequiredFieldError": "You have field(s) that require selections.",
            "lblSendTo": "Send messages to",
            "lblChannelsPerIncidentTip": "Select the methods in which the user will receive messages. Users who do not have these methods will not receive messages.",
            "lblEmail": "Email",
            "lblSms": "SMS",
            "lblVoice": "Voice",
            "lblPush": "Push Messages",
            "lblLocation": "Location",
            "lblGroup": "Group",
            "lblChangesHaveBeenMadeHeader": "Changes Have Been Made",
            "lblChangesHaveBeenMadeMessage": "You have made changes on this page. If you navigate away from this page without first saving your data, the changes will be lost.",
            "lblNoGroupsHeader": "No <strong>Group</strong> Has Been Setup",
            "lblNoGroupsDescription": "To manage User Initiated Incidents [[Settings]], at least one(1) or more groups must be setup.",
            "btnGoToCreateGroup": "Create A Group",
            "lblGroupsRemoved": "NOTE: Groups with no users assigned have been removed from the list.",
            "lblGroupsAndLocationsRemoved": "NOTE: Groups and Locations with no users assigned have been removed from the list.",
            "msgCannotDisableAllChannels": "At least one(1) contact methods must be enabled.",
            "confirmReset": {
                "body": "Changing the the Default Group will affect any Incident Types without a specifically configured Group to also be updated.",
                "header": "Are you sure you want to change the Default Group?",
                "btnText": "OK"
            }
        },
        "user": {
            "lblDefaultNewUserTitle": "New User",
            "btnSaveAndFinish": "Save and Finish",
            "lblUserActivity": "User Activity",
            "lblUserStatus": "Status",
            "lblIQAssignments": "iQ Assignments",
            "lblInitRegSent": "Initial Registration Sent",
            "lblUserFirstLogin": "User's First Login",
            "lblUsersLastTermAcceptance": "User's Last Term Acceptance",
            "lblUserLastLogin": "User's Last Login",
            "lblUserRole": "Role",
            "lblUniqueId": "Unique Id",
            "lblSelfRegistered": "Opted In",
            "errorSaveUser": "Failed to save user information",
            "lblSendRegEmail": "Send Registration Email",
            "menuPickRole": "Select a role...",
            "errorIQAssignmentsRequireLogin": "User must have a role with Login capabilities when having iQ assignments",
            "errorCannotInactivateWithIQAssignments": "User cannot be made inactive because it has iQ assignments. Remove all iQ assignments before attempting to make the user inactive.",
            "errorCannotDeleteWithIQAssignments": "User with iQ assignments cannot be deleted. Remove all iQ assignments before attempting to delete the user.",
            "errorCannotDeleteBCManager": "User is the only Business Continuity Manager and cannot be deleted. Please assign a new Business Continuity Manager before attempting to delete the user.",
            "errorCannotDeleteWithPlannerAssignment": "User with [[planner]] assignments cannot be deleted because there is no business continuity manager in the portal",
            "lblAreYouSureUpgradeUser": "Are you sure you want to upgrade this opted in user? This change cannot be reversed",
            "lblAreYouSureDeleteUser": "Are you sure you want to delete this user?",
            "lblAreYouSureDeleteUserWithBCAssignments": "User's assignments in Preparis [[Planner]] will be reassigned to other [[team leads]] or managers, Are you sure you want to delete this user?",
            "msgDeleteUserSuccess": "User successfully deleted.",
            "msgCannotDeleteEssentialContactUser": "User cannot be deleted. It is an Essential Contact.",
            "errorDeleteUserFailed": "An error occurred attempting to delete this user.",
            "lblManageUserPasswordHeader": "Reset Password",
            "lblInitiateUserPasswordReset": "Send user an email that allows the user to create their own password.",
            "lblManuallySetUserPassword": "Manually set password (no email sent, you will need to verbally communicate the password).",
            "lblResetAndSendEmail": "Reset & Send Email",
            "lblManuallySetPassword": "Manually Set Password",
            "msgNoUser": "Could not request password reset, user is empty.",
            "msgRequestPasswordResetFailed": "The password could not be reset for this user.",
            "msgRequestPasswordResetSuccess": "The user will receive an email with instructions to reset their password.",
            "lblAreYouSureResendInvite": "Are you sure you want to resend the invitation to this user?",
            "msgInvitationSent": "The user's invitation has been sent.",
            "errorInvitationSent": "Unable to resend the user's invitation.",
            "lblLastInviteNotSent": "no invitations sent",
            "lblLastInviteSent": "last invitation sent",
            "btnResendInvite": "Resend Invite",
            "lblAreYouSureUnlock": "Are you sure you want to unlock this user?",
            "msgUserUnlocked": "User has been successfully unlocked.",
            "errorUserUnlocked": "Unable to unlock this user.",
            "btnUnlock": "Unlock",
            "BCMLicenseOverage": "You have reached the limit for Business Continuity Manager. Please contact your customer success [[team member]].",
            "msgSaveUserSuccessLicenseOverage": "User {{firstName}} {{lastName}} has been successfully saved but with License Overage. Please contact your customer success [[team member]]."
        },
        "selfRegistration": {
            "lblSelfRegistrationTitle": "Opt-In",
            "lblSelfRegistrationDescription": "Manage opt-in configurations and [[settings]]",
            "lblSelfRegistrationFlag": "Allow users to Opt-In",
            "lblSelfRegistrationUrl": "Opt-in Url / QR Code",
            "lblSelfRegistrationCode": "Registration Code",
            "lblRequireAddress": "Allow users to supply an address",
            "lblUserRole": "Select a role for Opt-In users",
            "lblUserLocations": "Select which [[location]](s) opt-in users can receive messages from",
            "lblLocations": "Locations",
            "lblSelectNone": "select none",
            "lblSelectAll": "select all",
            "lblConfirmDeleteTitle": "Remove Account",
            "lblConfirmDelete": "Are you sure you want to remove your account?",
            "optInUrlTooltip": "Copy link or download QR Code and provide to Opt-In users",
            "registrationCodeTooltip": "Provide Opt-In users this code to complete registration",
            "copyOptInUrlTooltip": "Copy opt-in url",
            "downloadOptInQrCodeTooltip": "Download QR Code",
            "requireAddress": "Yes, require users to supply an address",
            "allowAddress": "Yes, allow users to supply an address",
            "noAddress": "No, do not ask for an address",
            "allLocations": "Any locations",
            "selectedLocations": "Selected locations",
            "msgSaveSuccess": "Opt-In [[settings]] saved",
            "msgSaveFail": "Failed to save Opt-In [[settings]]",
            "confirmReset": "Are you sure you want to reset Opt-In [[settings]] to default",
            "msgResetFail": "Failed to reset Opt-In [[settings]]",
            "msgLocationRequired": "Please select at least one [[location]] for user to sign up",
            "msgRoleBundleRequired": "Role for Opt-In user is required",
            "msgNoRoles": "No qualified roles for Opt-In users, please create at least one message only role.",
            "lblNoRoles": "No available roles"
        },
        "mobileEmergencyButtons": {
            "alerts": "[[Alerts]]",
            "manageMobileEmergencyButtons": "Mobile Initiated [[Alerts]] [[Settings]]",
            "manageMobileEmergencyButtonsDescription": "Mobile initiated [[alert]] buttons can be configured on the mobile application. This page allows you to configure configure these buttons and the users that will be communicated with when the button is pressed.",
            "sharedSettings": "Custom [[Alert]] Shared [[Settings]]",
            "sharedSettingsDescription": "Custom [[alert]] shared [[settings]] are only applicable to Custom [[Alert]] buttons. More specialized buttons like Panic, Scheduled Check-in and Report Incident are managed by other company [[settings]].",
            "buttonShouldBePress": "Allow users to press an [[alert]] only when near a company [[location]]",
            "howCloseShouldTheLocation": "Identify how close users should be to a company [[location]] for the [[alert]] to be triggered",
            "canSamePersonPressResolve": "Allow users who activate an [[alert]] to resolve the incident",
            "emergencyButtonsConfiguration": "Mobile Initiated [[Alerts]] Configuration",
            "saveSuccess": "Mobile initiated [[alert]] buttons saved successfully",
            "saveError": "Error saving mobile initiated [[alert]] buttons",
            "loadError": "Error loading mobile initiated [[alert]] buttons",
            "invalidContactMethodsError": "At least one contact method is required",
            "addButton": "Add Button",
            "configurationModal": {
                "title": "Configure {{ x }} Mobile Initiated [[Alert]] Button",
                "lblButtonType": "Select button type",
                "lblButtonName": "Provide a name for the mobile initiated [[alert]] button",
                "lblButtonNameTooltip": "[[Alert]] button names are limited to 50 characters",
                "lblCohortTypeToNotify": "Who should be notified when this button is pressed?",
                "lblTypesOfNearbyUsers": "How would you like to identify people nearby?",
                "lblProximity": "How close should nearby recipients be?",
                "lblCustomButtonType": "Custom",
                "lblPanicButtonType": "Panic",
                "lblScheduledCheckinButtonType": "Scheduled Check-in",
                "lblReportIncidentButtonType": "Report Incident",
                "lblCohortProximityType": "People Nearby",
                "lblCohortDesignatedRecipientsType": "A Specific Set of People",
                "lblSearchUser": "Search",
                "lblAddByPhoneNumber": "Add by Phone Number",
                "lblAddByEmail": "Add by Email",
                "lblSelectedRecipients": "Selected Recipients",
                "lblAddByEntity": "Add by Type",
                "lblEntityTypeRoles": "Roles",
                "lblEntityTypeLocations": "Locations",
                "lblEntityTypeDepartments": "[[Departments]]",
                "lblEntityTypeGroups": "Groups",
                "panicDesc": "<p>A Panic button will be placed on the mobile home screen.</p><p>Pressing the button will trigger an incident and send a message to a designated list of recipients. This messages and recipient list can be customized per [[location]].</p><p>To configure the intended Panic messages and recipient lists, visit <a href=\"{{panicConfigurationLink}}\" target=\"_blank\">Panic Configuration</a>.</p>",
                "scheduledCheckinDesc": "<p>A Scheduled Check-in button will be placed on the mobile home screen.</p><p>Pressing the button will trigger a count down that creates a Panic incident and sends a message to a designated list of recipients. This message and recipient list can be customized per [[location]].</p><p>To configure the associated Panic message and recipient list, visit <a href=\"{{panicConfigurationLink}}\" target=\"_blank\">Panic Configuration</a>.</p>",
                "reportIncidentDesc": "<p>A Report Incident button will be placed on the mobile home screen.</p><p>Pressing the button will allow users the ability to freely type a description and location of the incident in addition to taking a photo.</p><p>To configure the Report Incident recipient list, visit the <a href=\"{{manageSettingsLink}}\" target=\"_blank\">User Initiated Incidents [[Setting]]</a></p>",
                "duplicatePanicButtonError": "A panic button already exists",
                "duplicateScheduledCheckInError": "A scheduled check-in button already exists",
                "duplicateReportIncidentError": "A Report Incident button already exists",
                "duplicateAlertButtonError": "{{ buttonName }} button already exists",
                "saveSuccess": "Mobile button saved successfully.",
                "saveError": "Error while saving mobile button.",
                "removeSuccess": "Mobile button removed successfully.",
                "removeError": "Error while removing mobile button.",
                "selectUnitOfMeasure": "Select Unit of Measure",
                "byUserAddresses": "By User Addresses",
                "byAssignedLocation": "By Assigned [[Location]]",
                "byMobileLocation": "By Mobile Location",
                "lblIncidentType": "Incident Type"
            },
            "confirmRemove": {
                "header": "Remove Mobile Initiated [[Alert]] Button",
                "body": "Are you sure you want to remove the {{ buttonName }} mobile initiated [[alert]] button from the mobile application?",
                "text": "Remove"
            }
        },
        "bulkUploadConfig": {
            "configureBulkUploadTitle": "Bulk Upload Configuration",
            "lblDisableEmailAttachments": "Include source, result, and detailed error report files into notification email",
            "disableEmailAttachmentsTooltip": "Email Bulk Upload source file, result file, and detailed error file as notification email attachment",
            "lblDisableEmailErrors": "Include errors file without user personal details into notification email",
            "disableEmailErrorsTooltip": "Include Bulk Upload errors file without user personal details as notification email attachment.",
            "lblNotificationEmails": "Notification Emails",
            "notificationEmailsTooltip": "Email addresses to send the notifications about bulk upload results.",
            "lblDefaultRole": "Default Role",
            "defaultRolePlaceHolder": "Select Default Role",
            "defaultRoleTooltip": "This role will be assigned to new users that do not have a role specified in their bulk upload file.",
            "lblDefaultLocation": "Default [[Location]]",
            "defaultLocationPlaceHolder": "Select Default [[Location]]",
            "defaultDropdownPlaceholder": "None Selected",
            "defaultLocationTooltip": "This [[location]] will be assigned to new users that do not have a [[location]] specified in their bulk upload file.",
            "invalidEmailFormatError": "Invalid Email Format. The value cannot be added to emails list."
        }
    },
    "login": {
        "lblLoggingIn": "Logging In...",
        "lblLoginId": "Email",
        "phUsername": "Enter your email address",
        "phRegistrationCode": "Enter company registration code",
        "lblPassword": "Password",
        "lblConfirmPassword": "Confirm Password",
        "phPassword": "Enter your password",
        "btnLogin": "Login",
        "btnBackToLogin": "back to login",
        "lblSupport": "Support",
        "btnForgotPortalUrl": "Forgot Portal Url?",
        "btnForgotPassword": "Forgot Password?",
        "btnRegister": "Opt-In",
        "msgChangedPasswordSuccess": "Your password has been successfully changed.",
        "msgSessionTimeout": "Your session has expired due to inactivity. Please sign in again.",
        "lblNeedHelp": "Need Help?",
        "msgRequestSendPortalsLinksSuccess": "We sent you an email with the links for available Portals. You should receive it in a few minutes.",
        "msgRequestSendPortalsLinksFailed": "Failed to send an Email.",
        "lblResetPassword": "Reset Password",
        "lblChangePassword": "Change Password",
        "lblCreatePassword": "Create Password",
        "lblRegisterAccount": "Create Account",
        "lblRegisterProfile": "Contact Information",
        "lblContactInfo": "Contact Information",
        "msgRegisterInstructions": "Please enter the company registration code you would like to register with",
        "msgResetPasswordInstructions": "Enter your email and then click below to submit a request to reset your password.",
        "msgRegisterAccountInstructions": "Create a user name and password that you will use to access your profile information",
        "btnRequestPasswordReset": "Request Password Reset",
        "msgForgotPassword1": "If provided user name is valid, in a few minutes you should receive an email so you can create a new password.",
        "msgForgotPassword2": "If you don't see one, check out your spam folder. If you still haven't received one,",
        "msgForgotPassword3": "try again",
        "msgForgotPassword4": "or contact your administrator.",
        "lblNewPassword": "New Password",
        "lblConfirmNewPassword": "Confirm New Password",
        "btnChangePassword": "Change Password",
        "btnCreatePassword": "Create Password",
        "passwordErrorMessage": "Password does not meet complexity rules.",
        "msgUsernameExist": "email already exist in the system, please use another email.",
        "errors": {
            "999": "System Error. Please try again later.",
            "1005": "Unable to login, please check your email or password.",
            "1006": "Either the link has expired or the link is invalid. Please request a new link.",
            "1010": "You have exceeded the maximum bad login attempts.  Your account has been temporarily locked.  Please try again later.",
            "1015": "The email address you entered doesn't match an active user account.",
            "1017": "Unable to use multi-factor authentication, user does not have a primary phone",
            "1019": "Authentication pin is invalid.",
            "1022": "User Must Login via SSO",
            "1023": "User must login using Portal Url",
            "1024": "User must login using Portal Url or SSO",
            "1025": "Password has expired. Please change your password now.",
            "msgMFAFailed": "Multi-factor authentication failed.",
            "tacDeclined": "Terms And Conditions declined.",
            "msgTACFailed": "Terms And Conditions failed.",
            "msgValidateEmailFormat": "Email entered isn't a valid email address.",
            "msgInvalidUsername": "The email address you entered doesn't match an active user account."
        }
    },
    "mfa": {
        "lblMultifactorTitle": "Multifactor Authentication",
        "lblMfaSecurityCode": "Security Code",
        "lblRequired": "(required)",
        "msgMultifactorInstructions": "A security code has been sent to your registered phone number. Please enter it below to continue with your sign in.",
        "msgMultifactorWithExpirationInstructions": "A security code has been sent to your registered phone number. Please enter it below to continue with your sign in. The security code will expire in {{mfaPinExpirationMinutes}} minutes."
    },
    "splashMessage": {
        "msgMustAgreeToSplash": "You must acknowledge this message by checking the box.",
        "lblAcknowledgedSplash": "Acknowledged"
    },
    "recoveryProfiles": {
        "recoveryProfile": "Recovery Profile",
        "recoveryProfiles": "Recovery Profiles",
        "recoveryProfilesFilterPlaceHolder": "Filter on profile name...",
        "export": "Export",
        "name": "Name",
        "essentialContactsDesc": "Key personnel who are authorized to initiate an Agility Response when needed.",
        "generator": "Generator",
        "comments": "Comments",
        "generatorDesc": "Power requirements needed to recover your office space",
        "sites": "Sites",
        "sitesDesc": "Mobile office sites as well as alternative retail space options",
        "technologyAndConnectivity": "[[Technology]] & Connectivity",
        "technologyAndConnectivityDesc": "[[Technology]] [[equipment]] [[applications]] for recovery",
        "generatorSize": "Generator Size",
        "generatorPhase": "Generator Phase",
        "generatorVoltage": "Generator Voltage",
        "totalAmperage": "Total Amperage",
        "requiredCable": "Required Cable",
        "powerService": "Power Service",
        "generatorTitle": "Provide power requirements needed to recover your office space",
        "requestChange": "Request Change",
        "site": "Site",
        "primary": "Primary",
        "errorExportingRecoveryProfile": "Error exporting recovery profile",
        "rename": "Rename",
        "renameRecoveryProfile": "Rename Recovery Profile",
        "renameRecoveryProfileTooltip": "A recovery profile name may be up to 200 characters in length. It must be unique.",
        "errorRenamingRecoveryProfile": "Error renaming recovery profile",
        "successRenamingRecoveryProfile": "Recovery profile successfully renamed",
        "duplicateProfileName": "Recovery profile names must be unique",
        "errorLoadingProfiles": "Error loading recovery profiles",
        "errorCreatingProfile": "Error creating recovery profile",
        "manuallyCreate": "Manually Create",
        "migrateMyAgilityDocuments": "Migrate Documents",
        "errorMigratingMyAgilityDocuments": "Error migrating Documents",
        "successMigratingMyAgilityDocuments": "Successfully requested Documents migration",
        "validation": {
            "duplicateProfileNameMessage": "This name has already been used",
            "missingValues": "Please fill out all required values"
        },
        "recoverySites": {
            "recoverySites": "Recovery Sites",
            "recoverySitesFilterPlaceHolder": "Filter on site name...",
            "recoverySitesLoadingError": "Error loading recovery sites",
            "unitType": "Unit Type",
            "unitNumber": "Unit Number",
            "streetNumber": "Street Number",
            "streetName": "Street Name",
            "city": "City",
            "state": "State",
            "postalCode": "Postal Code",
            "country": "Country",
            "contactName": "Contact Name",
            "phone": "Phone",
            "comments": "Comments",
            "saveChanges": {
                "header": "Save Recovery Site",
                "body": "Are you sure that you want to save the changes?",
                "text": "Save"
            },
            "errorLoadingRecoverySite": "Error loading recovery site",
            "errorSavingRecoverySite": "Error saving recovery site",
            "successSavingRecoverySite": "Success saving recovery site",
            "errorDeletingRecoverySite": "Error deleting recovery site",
            "successDeletingRecoverySite": "Success deleting recovery site",
            "filterPlaceHolder": "Filter on site name...",
            "deleteSite": {
                "header": "Delete Recovery Site",
                "body": "Are you sure that you want to delete this site?",
                "text": "Delete"
            },
            "manuallyCreate": {
                "header": "Manually Create Recovery Profile",
                "body": "Are you sure that you want to manually create a new profile?",
                "text": "Manually Create"
            },
            "siteName": "Site Name",
            "newSite": "New Site",
            "recoverySitesDescription": "Determine primary and secondary mobile office sites as well as alternative retail space options.",
            "checkRequiredFields": "Please check all required fields"
        },
        "technicalInfo": {
            "description": "With your membership, you have access to the [[equipment]] listed below",
            "saveSuccess": "Comment saved",
            "comment": "Comment",
            "technologyRecovery": "[[Technology]] Recovery",
            "comments": "Comments"
        },
        "recoveryGenerator": {
            "saveGeneratorSuccess": "Generator saved",
            "errorLoadingGeneratorInfo": "Error loading generator info",
            "checkRequiredFields": "Please check all required fields",
            "saveChanges": {
                "header": "Save Recovery Generator",
                "body": "Are you sure that you want to save the changes?",
                "text": "Save"
            }
        },
        "errorRetrievingRecoveryProfile": "Error loading recovery profile",
        "recoveryProfilesDescription": "Your recovery profiles help you quickly recover your organization during a disaster.",
        "activityInformation": "Activity information",
        "essentialContacts": {
            "essentialContacts": "Essential Contacts",
            "name": "Name",
            "title": "Title",
            "primary": "Primary",
            "email": "Email",
            "mobilePhone": "Mobile phone",
            "officePhone": "Office phone",
            "personalPhone": "Personal phone",
            "addContact": "Add contact",
            "essentialContactsDescription": "Key personnel who are authorized to initiate an Agility Response when needed",
            "isPrimaryContact": "Is primary contact",
            "newContact": "New Contact",
            "essentialContact": "Essential Contact",
            "removeEssentialContact": "Remove Essential Contact",
            "removeEssentialContactDescription": "Are you sure you want to remove this Essential Contact?",
            "removeEssentialContactSuccess": "Essential Contact successfully removed",
            "saveEssentialContactSuccess": "Essential Contact successfully saved",
            "essentialContactActivity": "Essential Contact Activity",
            "noEssentialContacts": "No Essential Contacts"
        },
        "documents": {
            "documents": "Documents",
            "manageYourDocuments": "Manage your online plan documents",
            "manageYourDocumentsInstruction": "Manage your online plan documents here.",
            "manageYourDocumentsInstruction2": "You are currently allowed to have {{maxUploads}} documents. If you would like to upload more documents please contact your Account Manager.",
            "manageYourDocumentInstruction": "Manage your online plan document here.",
            "manageYourDocumentInstruction2": "You are currently allowed to have 1 document. If you would like to upload more documents please contact your Account Manager.",
            "document": "Document",
            "noDocuments": "No documents",
            "uploadSuccessful": "Upload successful",
            "uploadFailed": "Upload successful",
            "maxUploadsReached": "Max ({{maxUploads}}) reached",
            "documentDeleted": "Document deleted",
            "documentDeletedError": "Document was not deleted",
            "getDocumentsError": "Unable to retrieve documents"
        },
        "deleteConfirmation": {
            "header": "Remove Recovery Profile",
            "body": "Are you sure you want to remove Recovery profile '{{ recoveryProfileName }}'?",
            "text": "Remove"
        },
        "confirmMigrateDocumentsForAllRPS": {
            "header": "Migrate myAgility documents for all recovery profiles",
            "body": "Are you sure you want to migrate documents form myAgility for all Recovery profiles? All existing documents with the names matching migrated documents names will be overwritten.",
            "text": "Migrate"
        },
        "confirmMigrateDocumentsForOneRP": {
            "header": "Migrate myAgility documents for recovery profile",
            "body": "Are you sure you want to migrate documents form myAgility for Recovery profile '{{ recoveryProfileName }}'? All existing documents with the names matching migrated documents names will be overwritten.",
            "text": "Migrate"
        },
        "salesforceId": "Enter Salesforce Account Id",
        "associatePortalToSalesforceTooltip": "Account Id from Salesforce",
        "associate": "Associate",
        "associatePortalToSalesforceAccount": "Associate the portal with Salesforce account",
        "associateToSalesforceAccount": "Associate Portal with Salesforce Account",
        "msgSuccessAssociatedToSalesforceAccount": "Successfully associated Portal to Salesforce Account",
        "msgErrorAssociatedToSalesforceAccount": "Error associating Portal to Salesforce Account",
        "alert": {
            "clarifyAlertAction": {
                "header": "[[Alert]] Status",
                "body": "Please confirm that you wish to go on [[Alert]] status. If you select Yes, a Declare Manager will be in touch with you shortly by phone or email.",
                "text": "Yes"
            },
            "title": "Select Covered Locations to put on [[Alert]]",
            "searchTooltip": "Search covered locations",
            "confirmAlert": {
                "header": "Send [[alert]] request for covered locations",
                "body": "Are you sure you want to send [[alert]] request for {{ numLocations }} covered [[location]](s)?",
                "text": "[[Alert]]"
            },
            "msgSuccessAlert": "Successfully sent [[alert]] request",
            "msgErrorAlert": "Failed to send [[alert]] request",
            "msgSelectRecoveryProfiles": "Select at least one covered [[location]]"
        }
    },
    "passwordPolicies": {
        "lblPasswordPolicyHeader": "Your password must contain the following:",
        "msgPasswordPolicyFail": "Password does not meet complexity rules.",
        "msgPasswordPolicyLoading": "Loading policies...",
        "msgPasswordPolicyMinCharacters": "At least {{number}} characters",
        "msgPasswordPolicyMinLowercaseCharacters": "At least {{number}} lowercase letter(s)",
        "msgPasswordPolicyMinNumberCharacters": "At least {{number}} number(s)",
        "msgPasswordPolicyMinUppercaseCharacters": "At least {{number}} uppercase letter(s)",
        "msgPasswordPolicyNoneFound": "No password policies found.",
        "msgPasswordPolicySpecialCharacters": "At least {{number}} special character(s)",
        "msgPasswordsMustMatch": "Passwords do not match.",
        "passwordReuseError": "This password has been used too many times"
    },
    "profile": {
        "btnChangePassword": "Change Password",
        "btnSaveProfile": "Save and Finish",
        "btnValidateAddress": "Validate Address",
        "lblGroups": "Groups",
        "lblDepartments": "[[Departments]]",
        "lblNoOtherLocations": "No other locations",
        "lblNonSelected": "None Selected",
        "lblDefaultLocation": "DEFAULT (user's main [[location]] if they work in multiple locations)",
        "lblOfficePhone": "Office Phone (Does not receive SMS)",
        "lblOfficePhoneAllowSMS": "Office Phone",
        "lblOfficePhoneExt": "Extension",
        "lblPersonalEmail": "Personal Email",
        "lblPersonalPhone": "Personal Phone (SMS enabled phone recommended)",
        "lblAdditionalPersonalPhone": "Additional Personal Phone",
        "lblMessagingEmail": "Email (will receive all email messages)",
        "lblMobilePhone": "Mobile Phone (will receive all SMS and Voice Calls)",
        "lblPersonal": "Other",
        "lblDefaultContact": "Default",
        "lblWorkTitle": "Work Title",
        "lblUserFirstName": "First Name",
        "lblUserLastName": "Last Name",
        "lblAccessMyBookmarks": "Access My Bookmarks",
        "lblAccessMyDocuments": "Access My Documents",
        "lblProfileInformation": "Profile Information",
        "lblMyProfileHeader": "My Profile",
        "lblMyCrisisTeam": "My Crisis Team",
        "lblEmailAddress": "Email",
        "lblCoreInformation": "Core Information",
        "lblNoDepartmentMembership": "You are not a [[member]] of any [[department]]",
        "lblNoGroupMembership": "You are not a [[member]] of any group",
        "lblQuickLinks": "Quick Links",
        "msgSaveProfile": "Your profile as been saved",
        "errorSaveUserCustomFieldsFailed": "Failed to save user custom fields.",
        "errorSaveUserProfile": "Failed to save user profile.",
        "lblChangePhoto": "change photo",
        "lblUserProfileImage": "User profile image",
        "lblTenant": "Tenant",
        "lblSms": "SMS",
        "lblVoice": "Voice",
        "lblAddressMain": "Main Address",
        "lblAddressMainCountry": "Country",
        "lblAddressMainAddress1": "Address",
        "lblAddressMainAddress2": "Address 2 (suite, bldg, etc)",
        "lblAddressMainCity": "City",
        "lblAddressMainState": "State/Province/Region",
        "lblAddressMainZip": "Zip Code",
        "lblAddressMainAdditionalLocationInfo": "Additional Location Info",
        "lblAddressMainDepartment": "[[Department]]",
        "lblAddressMainFloor": "Floor",
        "lblUserLanguage": "Language",
        "menuPickCountry": "Select a country...",
        "errorCommunicationPreferencesMinimumOne": "At least one (1) communication preference must be active",
        "errorNoPhoneNumberCannotDisableEmail": "No default phone number has been entered, email communication preference cannot be disabled",
        "errorNoEmailsCannotDisableVoiceSms": "No default email has been entered, both SMS and VOICE cannot be disabled",
        "errorNoContactMethodsSupplied": "At least one contact method must be supplied for a user",
        "errorCustomFieldsInvalid": "Populate required custom fields",
        "errorUnableToLoadCountries": "Unable to retrieve countries",
        "lblWelcomeToPreparis": "Welcome To Preparis",
        "lblInitialLoginMessage": "Thank you for choosing Preparis. We see this is your first time logging in. Please take a moment to review the information on this page to ensure it is accurate.",
        "lblOptInMobileSms": "Receive mobile SMS messages?",
        "lblOptInMobileVoice": "Receive mobile voice messages?",
        "lblOptInPersonalSms": "Receive personal SMS messages?",
        "lblOptInAdditionalPersonalSms": "Receive additional personal SMS messages?",
        "lblOptInPersonalVoice": "Receive personal voice messages?",
        "lblOptInAdditionalPersonalVoice": "Receive additional personal voice messages?",
        "lblOptInPersonalEmail": "Receive personal email messages?",
        "lblOptInBusinessEmail": "Receive office email messages?",
        "lblOptInWorkSms": "Receive office SMS messages?",
        "lblOptInWorkVoice": "Receive office voice messages?",
        "lblManuallySetPassword": "Manually Set Password",
        "lblFloor": "Floor",
        "lblAdditionalInfo": "Additional Information",
        "lblAdditionalLocations": "Other Locations User Works",
        "lblUserActiveStatus": "Active",
        "lblUserDeleteStatus": "Deleted",
        "lblUserInactiveStatus": "Inactive",
        "lblUserPendingStatus": "Pending",
        "lblUserLockedStatus": "Locked",
        "lblUserStatusLegendActiveMessageOnly": "(user will receive messages)",
        "lblUserStatusLegendDefault": "(user registered and can log in)",
        "lblUserStatusLegendInactive": "(user cannot access the portal nor receive messages)",
        "lblUserStatusLegendPending": "(user has not accepted their invite)",
        "msgManuallySetPassword1": "Normally new users are sent an invitation email that contains a link allowing them to create their own password.",
        "msgManuallySetPassword2": "If you enter a password here, you will need to verbally communicate the password.",
        "msgUserPasswordChangedSuccess": "User password changed successfully.",
        "msgUserPasswordChangedFail": "Failed to change user password.",
        "msgSaveUser": "User {{firstName}} {{lastName}} has been successfully saved.",
        "msgSaveUserImageSuccess": "User photo has been successfully saved.",
        "errorSavUserImageFailed": "Failed to save user photo.",
        "magUserNameExist": "UserName already in use by another user",
        "msgInvalidName": "Please replace the generated name to upgrade this opted in user",
        "save": "Save",
        "initialLogin": {
            "btnText": "OK"
        }
    },
    "register": {
        "lblMyInformation": "My Information",
        "lblContactInformation": "Contact Information",
        "lblAddress": "Address",
        "lblAddress2": "Address 2",
        "lblCity": "City",
        "lblState": "State",
        "lblZip": "Zip Code",
        "lblCountry": "Country",
        "lblLocationBuilding": "Locations/Buildings to receive notifications about",
        "firstName": "First name",
        "lastName": "Last name",
        "lblLastName": "Last Name",
        "lblFirstName": "First Name",
        "lblMobilePhone": "Mobile Phone",
        "lblReceiveSMS": "Receive SMS Message",
        "lblReceiveVoice": "Receive Voice Message",
        "lblReceiveEmail": "Receive Email Message",
        "lblLoading": "Loading...",
        "lbSelectLocations": "Which locations would you like to receive important notifications about?",
        "lblValidateAddress": "Validate Address",
        "msgRegisterCodeInvalid": "Unable to register with registration code, please try another registration code",
        "msgRegisterCodeFail": "Fail to register with registration code, please try again later.",
        "msgLocationRequired": "At least one [[location]] is required for receiving notifications",
        "msgCommunicationMethodRequired": "At least one communication method is required for receiving notifications",
        "msgRegisterFailed": "Failed to register user account, please try again later.",
        "msgRegistrationComplete": "Congratulations! You are now signed up to receive important notifications from {{ portalName }}. To make changes to your preferences at any time you can login at {{ portalUrl }}",
        "msgRegisterUser": "Registering user account, please wait...",
        "msgSaveUser": "Saving user information, please wait...",
        "msgSaveUserSuccess": "User information successfully saved",
        "msgSaveUserFailed": "Failed to save user information, please try again later.",
        "msgRemoveUser": "Removing user account, please wait...",
        "msgRemoveUserSuccess": "Your account is removed.",
        "msgRemoveUserFail": "Failed to remove your account, please try again later."
    },
    "layout": {
        "aAllCallinAnnouncements": "All Call-In Announcements",
        "aAllSplashMessage": "All Splash Messages",
        "aCommunicationsDashboard": "Communications Dashboard",
        "aCreateSlashMsg": "Create a Splash Message",
        "aCrisisTeam": "Crisis Team",
        "aEmergencyContacts": "Emergency Contacts",
        "aRecordCallinAnnouncement": "Record a Call-In Announcement",
        "aSendEmergencyMessage": "Send Message",
        "aStartConcall": "Start a Conference Call",
        "lblCommunicateIncident": "Communicate About an Event",
        "lblHelpAndAssistance": "Help & Assistance",
        "lblIncidentHistory": "Incident History",
        "lblIncidents": "Incidents",
        "lblManageIncidents": "Manage Communications",
        "lblNewIncident": "New Incident",
        "lblQuickSnapshot": "Quick Snapshot",
        "lblViewOverview": "View Overview",
        "aPrivacy": "Privacy",
        "aScheduledMessages": "Scheduled Messages",
        "aSupport": "Support",
        "aTermsOfService": "Terms of Service",
        "lblAccessAndPermissions": "Access & Permission",
        "lblAdditionalResources": "More Resources",
        "lblBookmark": "Bookmark",
        "lblChecklists": "Checklists",
        "lblCompanyProfile": "Company Profile",
        "lblDepartments": "[[Departments]]",
        "lblDocuments": "DOCUMENTS",
        "lblEntities": "Entities",
        "lblFeaturedResources": "Featured Resource",
        "lblGroups": "Groups",
        "lblHelp": "Help",
        "lblHome": "Home",
        "lblInTouch": "We are always in touch with our client.",
        "lblLoading": "Loading",
        "lblManage": "Manage",
        "lblMessagingSettings": "Messaging [[Settings]]",
        "lblMessagingSetup": "Messaging Setup",
        "lblMyPreparis": "My Preparis",
        "lblMyProfile": "My Profile",
        "lblNavigatePortal": "Navigate Portal",
        "lblNewResources": "New Resources",
        "lblReports": "[[Reports]]",
        "lblResources": "Resources",
        "lblResourceTypes": "Resource Types",
        "lblRoles": "Roles",
        "lblSavedMessages": "Saved Messages",
        "lblSecurity": "Security",
        "lblSettings": "[[Settings]]",
        "lblSignout": "Sign out",
        "lblSystemEmails": "System Emails",
        "lblSystemSettings": "System [[Settings]]",
        "lblTabletops": "Tabletops",
        "lblTenants": "Tenants",
        "lblThreats": "Threats",
        "lblTraining": "TRAINING",
        "lblUserInitiatedMessages": "User Initiated Incidents",
        "lblUsers": "Users",
        "lblWebinars": "Webinars",
        "lblHierarchy": "Hierarchy",
        "lblComplianceCalendar": "Compliance Calendar",
        "lblScopes": "[[Scopes]]",
        "lblTeams": "[[Teams]]",
        "lblTeamMembers": "[[Team Members]]",
        "lblItems": "[[Items]]",
        "lblIssues": "[[Issues]]",
        "lblExternalResources": "[[External Resources]]",
        "lblAssets": "[[Assets]]",
        "lblPlans": "[[Plans]]",
        "lblAdministration": "Administration",
        "lblNotifications": "Notifications",
        "lblStorageLocations": "Storage Locations",
        "lblComplianceCategories": "Compliance Categories",
        "lblExternalResourceTypes": "[[External Resource]] Types",
        "lblIq": "iQ",
        "lblPortalSnapshot": "Portals Snapshot",
        "lblPortalsManagement": "Portals Management",
        "lblPortalManagement": "Portal Management",
        "lblPortalResources": "Portal Resources",
        "lblPortalResourceViews": "Portal Resource View",
        "lblBulkUploadHistory": "Bulk Upload History",
        "lblApiManagement": "Api Management",
        "lblApiManagementView": "3rd Party Integrations",
        "lblPlannerManagement": "[[Planner]] Management",
        "lblManageIndustries": "Manage Industries",
        "lblCustomFields": "Custom Fields",
        "panic": "Panic Dashboard",
        "lblEmergencyButtonDashboard": "Mobile Initiated [[Alerts]]",
        "lblBranding": "Customization",
        "lblSelfRegistration": "Opt-In",
        "scheduledConferenceCalls": "Scheduled Conference Calls",
        "messaging": "Messaging",
        "lblRecoveryProfiles": "RECOVERY PROFILES",
        "recoveryProfiles": "Recovery Profiles",
        "lblGeofences": "Geofences",
        "lblPointsOfInterest": "Points of Interest",
        "lblIncidentTypes": "Incident Types",
        "lblPublicAlertSubscriptions": "[[Alert]] Subscriptions",
        "lblIncidentTriggers": "Incident Triggers",
        "lblPanicConfig": "Panic Configuration",
        "lblPublicAlerts": "Public [[Alerts]]",
        "lblGeolocations": "Geolocations",
        "lblPortals": "Portals",
        "alerts": "[[Alerts]]",
        "navLinkAlertsForOverview": "[[Alerts]]",
        "planManagement": "Plan Management",
        "training": "Training",
        "reports": "[[Reports]]",
        "settings": "[[Settings]]",
        "alert": "[[Alert]]",
        "sendMessage": "Send Message",
        "admin": "Admin",
        "privacy": "Privacy",
        "aboutUs": "About Us",
        "incidentManagement": "[[Incident Manager]]",
        "lblMobileEmergencyButtons": "Mobile Initiated [[Alerts]] [[Settings]]",
        "lblConfigureBulkUpload": "Configure Bulk Upload",
        "planner": "[[Planner]]",
        "exerciseManager": "[[Exercise Manager]]",
        "lblChangeLanguage": "Change language",
        "lblExercises": "Exercises",
        "lblExerciseDashboard": "Dashboard",
        "lblExerciseRoadmap": "Roadmap",
        "lblExerciseScenarioManager": "Scenarios",
        "lblExerciseReports": "[[Reports]]",
        "lblExerciseSystemScenarios": "System Scenarios",
        "lblExerciseExerciseTypes": "Exercise Types",
        "lblExerciseThreadHazards": "Threat/Hazards",
        "lblIncidentManagementHistory": "Incident History",
        "risk": "[[Risk]]",
        "language": {
            "en": "English",
            "es": "Español",
            "fr": "Français",
            "pt": "Portuguese"
        },
        "changeLanguageError": "An unexpected error has occurred on the server.  Please contact technical support.",
        "calendar": {
            "dayNames": {
                "sunday": "Sunday",
                "monday": "Monday",
                "tuesday": "Tuesday",
                "wednesday": "Wednesday",
                "Thursday": "Thursday",
                "friday": "Friday",
                "saturday": "Saturday"
            },
            "dayNamesShort": {
                "sunday": "Sun",
                "monday": "Mon",
                "tuesday": "Tue",
                "wednesday": "Wed",
                "thursday": "Thu",
                "friday": "Fri",
                "saturday": "Sat"
            },
            "dayNamesMin": {
                "sunday": "Su",
                "monday": "Mo",
                "tuesday": "Tu",
                "wednesday": "We",
                "thursday": "Th",
                "friday": "Fr",
                "saturday": "Sa"
            },
            "monthNames": {
                "january": "January",
                "february": "February",
                "march": "March",
                "april": "April",
                "may": "May",
                "june": "June",
                "july": "July",
                "august": "August",
                "september": "September",
                "october": "October",
                "november": "November",
                "december": "December"
            },
            "monthNamesShort": {
                "january": "Jan",
                "february": "Feb",
                "march": "Mar",
                "april": "Apr",
                "may": "May",
                "june": "Jun",
                "july": "Jul",
                "august": "Aug",
                "september": "Sep",
                "october": "Oct",
                "november": "Nov",
                "december": "Dec"
            },
            "today": "Today",
            "clear": "Clear",
            "weekHeader": "Wk"
        },
        "footerCopyRight": "Copyright© 2007-{{year}} Preparis. All rights reserved."
    },
    "callinAnnouncements": {
        "btnCall": "Call",
        "btnDeactivate": "Deactivate",
        "btnMakeActive": "Make Active",
        "btnNewAnnouncement": "New Announcement",
        "btnSaveNewAnnouncement": "Save Announcement",
        "errorAnnouncementDeleted": "There was a problem deleting this announcement, please try again later.",
        "errorAtleastOneRecordingOption": "You must specify at least one recording option",
        "errorCallToRecordPhoneNumberRequired": "Please specify a phone number for your recording.",
        "errorUnableToRecordMessage": "Unable to record message at this time, please try again later.",
        "lblActivatedOn": "Activated On",
        "lblActiveAnnouncement": "Active Announcement",
        "lblActiveAnnouncementDtl": "Users will hear this message when they call-in.",
        "lblActiveAnnouncementNote": "There can only be 1 active announcement at a time.",
        "lblAnnouncementActive": "Announcement activation complete",
        "lblAnnouncementActivity": "Call-In Announcement Activity",
        "lblAnnouncementDeactivated": "Announcement deactivation successful.",
        "lblAreYourSureActivate": "Activate Call-In Announcement",
        "lblAreYouSureActivateWithoutReplace": "{{selectedDescriptionEncoded}} will become the active call-in announcement.",
        "lblAreYouSureActiveWithReplace": "{{selectedDescriptionEncoded}} will become the active call-in announcement. {{activeDescriptionEncoded}} will be deactivated.",
        "lblCallToRecord": "Call to Record",
        "lblCallToRecordInstructions": "Enter the phone number you wish to be called and then hit call. You'll receive a call in a few moments to then record your message. After recording your message, you'll be able to listen to it and re-record it if desired.",
        "lblChooseRecordingOption": "Choose one of the following options to record your announcement.",
        "lblConfirmDelete": "Confirm Delete",
        "lblDefaultNewAnnouncementTitle": "New Call-In Announcement",
        "lblHeader": "Call-In Announcements for {{callInAnnouncementPhoneNumber}}",
        "lblInactiveAnnouncement": "Inactive Announcements",
        "lblLastModifiedOn": "Last Modified On",
        "lblLoading": "Please wait...",
        "lblMessageColHdr": "Message",
        "lblMessageNameDescColHdr": "Name/Description",
        "lblNameDesc": "Name/Description",
        "lblNoActiveAnnouncementsBody": "The system default message will be used stating that there is no announcement at this time.",
        "lblNoActiveAnnouncementsHdr": "No Active Announcements",
        "lblNone": "None",
        "lblNote": "Note",
        "lblRecordedBy": "Recorded By",
        "lblRecordedByColHdr": "Recorded By",
        "lblRecordedColHdr": "Recorded",
        "lblRecording": "Recording",
        "lblRecordingCanceled": "Recording has been cancelled.",
        "lblStatusColHdr": "Status",
        "lblTextToSpeech": "Text to Speech",
        "mgsCallinNotSetupHdr": "Your Call-In Announcements Are Not Setup",
        "msgAnnouncementDeleted": "Announcement has been successfully deleted.",
        "msgAnnouncementSaved": "Call-In Announcement has been successfully saved.",
        "msgCallinNotSetup": "Integrated call-in announcements make it easier and faster to keep your crisis teams and employees up-to-date with developing information during an incident. Call-In announcements are a great platform for communicating updates to winter storm information and office closures. By simply recording a message and activating it, your people can call-in to hear the latest information.",
        "msgCallinNotSetup2": "Request to add this service at no additional charge by contacting",
        "callinAnnouncements": "Call-In Announcements",
        "removeConfirm": {
            "header": "Remove Call-In Announcement",
            "body": "Are you sure you want to remove this Call-In Announcement?",
            "text": "Yes"
        },
        "deactivateConfirm": {
            "header": "Deactivate Call-In Announcement",
            "body": "{{description}} call-in announcement will be deactivated. The default call-in announcement will be used unless you make another announcement active.",
            "text": "Yes"
        },
        "showAdvanced": "Allow Multiple Active Call-In Announcements",
        "addNewOption": "Add New Child Option",
        "addNewSiblingRight": "Add New Option to the right",
        "addNewSiblingLeft": "Add New Option to the left",
        "addNewSiblingAbove": "Add New Option above",
        "addNewSiblingBelow": "Add New Option below",
        "copyOption": "Copy Option",
        "editOption": "Edit {{ optionPrompt }}",
        "callinAnnouncement": "Call-In Announcement",
        "callInAnnouncementOption": "Call-In Announcement Option",
        "lblCallInAnnouncementValue": "Press {{optionValue}}",
        "lblInstructions": "Instructions",
        "resetToDefaultInstructions": "Reset to Default Instructions",
        "newCallinOptionModal": {
            "headerNew": "Create New Call In Option",
            "headerEdit": "Edit '{{ optionPrompt }}' Call In Option",
            "optionValue": "Value",
            "optionPrompt": "Prompt",
            "optionInstructions": "Instructions",
            "optionCallInAnnouncement": "Call In Announcement",
            "selectCallInAnnouncementConfirmation": {
                "header": "Select Call In Announcement for option",
                "body": "Are you sure you want to select '{{ announcementDescription }}' for the option?",
                "text": "Select"
            },
            "btnUnselect": "Unselect",
            "btnSelect": "Select"
        },
        "saveCallinAnnouncementOptionConfirm": {
            "header": "Save Call In Announcement Option",
            "body": "Are you sure you want to save Call In Announcement Option '{{ optionPrompt }}'",
            "text": "Save"
        },
        "deleteCallinAnnouncementOptionConfirm": {
            "header": "Delete Call In Announcement Option",
            "body": "Are you sure you want to delete Call In Announcement Option '{{ optionPrompt }}'",
            "text": "Delete"
        },
        "deleteAllCallinAnnouncementOptionsConfirm": {
            "header": "Delete All Call In Announcement Options",
            "body": "Switching to single active Call-In Announcement mode will delete Call In Announcement options. Are you sure you want to switch to single active Call-In Announcement mode and delete the options?",
            "text": "Delete and switch"
        },
        "errors": {
            "optionWithSamePromptError": "A Call In option with the prompt '{{ optionPrompt }}' already exists."
        },
        "copyCallinAnnouncementOption": {
            "header": "Copy '{{ optionPrompt }}' Call In Announcement Option",
            "body": "Select target Call In Announcement option",
            "copySubHierarchy": "Copy sub-hierarchy",
            "errorSameOption": "This option is being copied",
            "errorDuplicatePrompt": "'{{ optionPrompt }}' already has a child option with the same prompt",
            "errorMaxChildNodesNumber": "'{{ optionPrompt }}' already has {{ maxChildNodes }} child options"
        },
        "msgCopyCallinAnnouncement": {
            "success": "Call In Announcement option is copied",
            "error": "Failed to copy Call In Announcement option"
        }
    },
    "iq": {
        "common": {
            "moveError": "iQ resource not moved",
            "moveTeamTooltip": "Select the destination team.",
            "assignToTeamMember": "Assign to a [[team member]] on the destination [[team]]."
        },
        "itemType": {
            "Document": "Document",
            "Storage Item": "Storage Item",
            "Other": "Other",
            "Task/Reminder": "Task/Reminder"
        },
        "columnsSelected": "{0} columns selected"
    },
    "incidentManagement": {
        "lblManageIncident": "Manage Incident",
        "createNewIncident": "Create New Incident",
        "recentIncidents": "Recent Incidents",
        "noRecentIncidents": "No Recent/Active Incidents",
        "lblIncidentDetails": "Incident Details",
        "lblIncidentRetrospective": "Incident Retrospective",
        "lblNewIncident": "Create New Incident",
        "communicationLog": "Communication Log",
        "addIncidentNotes": "Add Incident Notes",
        "incidentNotes": "Incident Notes",
        "addNotes": "Add Notes",
        "resolveIncident": "Resolve Incident",
        "viewPlans": "View Plans",
        "lblDepartmentPlans": "[[Department]] Plans",
        "standByAll": "Stand By All",
        "standBySelected": "Stand By Selected",
        "standDownSelected": "Stand Down Selected",
        "standDownAll": "Stand Down All",
        "activateAll": "Activate All",
        "activateSelected": "Activate Selected",
        "deactivateSelected": "Deactivate Selected",
        "deactivateAll": "Deactivate All",
        "active": "Active",
        "inactive": "Inactive",
        "standBy": "Stand By",
        "StandDown": "Stand Down",
        "StandDownAll": "Stand Down All",
        "Active": "Active",
        "Stand By": "Stand By",
        "departmentPlanActivationHeader": "[[Department]] Plan Activation",
        "locationPlanActivationHeader": "[[Location]] Plan Activation",
        "departmentPlanActivationBodyMsg": "Are you sure you want to activate all [[departments]]?",
        "locationPlanActivationBodyMsg": "Are you sure you want to activate all locations?",
        "departmentPlanActivationAssignedBodyMsg": "Are you sure you want to activate all your assigned [[departments]]?",
        "locationPlanActivationAssignedBodyMsg": "Are you sure you want to activate all your assigned locations?",
        "departmentPlanStandByHeader": "[[Department]] Plan Stand By",
        "locationPlanStandByHeader": "[[Location]] Plan Stand By",
        "departmentPlanStandByBodyMsg": "Are you sure you want to set to Stand By all the [[departments]]?",
        "locationPlanStandByBodyMsg": "Are you sure you want to set to Stand By all the locations?",
        "departmentPlanStandByAssignedBodyMsg": "Are you sure you want to set to Stand By all your assigned [[departments]]?",
        "locationPlanStandByAssignedBodyMsg": "Are you sure you want to set to Stand By all your assigned locations?",
        "departmentPlanDeactivateAllHeader": "[[Department]] Plan Deactivation",
        "locationPlanDeactivateAllHeader": "[[Location]] Plan Deactivation",
        "departmentPlanDeactivateAllBody": "Are you sure you want to deactivate all the [[department]]?",
        "locationPlanDeactivateAllBody": "Are you sure you want to deactivate all the [[location]]?",
        "departmentPlanDeactivateAssignedBodyMsg": "Are you sure you want to deactivate all your assigned [[department]]?",
        "locationPlanDeactivateAssignedBodyMsg": "Are you sure you want to deactivate all your assigned [[location]]?",
        "departmentPlanStandDownAllHeader": "[[Department]] Plan Stand Down",
        "locationPlanStandDownAllHeader": "[[Location]] Plan Stand Down",
        "departmentPlanStandDownAllBody": "Are you sure you want to Stand Down all the [[departments]]?",
        "locationPlanStandDownAllBody": "Are you sure you want to Stand Down all the locations?",
        "departmentPlanStandDownAssignedBodyMsg": "Are you sure you want to Stand Down all your assigned [[departments]]?",
        "locationPlanStandDownAssignedBodyMsg": "Are you sure you want to Stand Down all your assigned locations?",
        "notDepartmentPlansAvailable": "There are no Plans available.",
        "startHere": "Start here",
        "selectedMembers": "Selected Members",
        "incidentHistory": "Incident History",
        "plansActivated": "Plan(s) Activated",
        "pagingDescription": "{{ pageStart }}-{{ pageEnd }} of {{ totalCount }}",
        "enterExerciseMode": "Enter Exercise Mode",
        "exitExerciseMode": "Exit Exercise Mode",
        "exerciseMode": "Exercise Mode",
        "leaveExerciseTitle": "Exercise Mode",
        "areYouSureLeaveExercise": "Are you sure you sure you want to leave the exercise?",
        "liveIncidents": "Live Incidents",
        "incidentExercises": "Incident Exercises",
        "exercise": "Exercise",
        "portalExerciseAssociateToIncident": "Exercise already associated to another incident",
        "dependencies": {
            "dependentProcesses": "Dependent [[Processes]]",
            "noDependentProcesses": "No Dependent [[Processes]]",
            "dependencyListIntoMessage": "Dependency List Into Message",
            "dependencies": "Dependencies"
        },
        "states": {
            "viewAll": "View All",
            "active": "Active",
            "resolved": "Resolved",
            "archived": "Archived",
            "activated": "Activated",
            "deactivated": "Deactivated"
        },
        "conferenceCallStarted": "Conference Call Started",
        "emailSent": "E-mail Sent",
        "messageSent": "Message Sent",
        "export": {
            "allIncidents": "All Incidents",
            "displayedIncidents": "Displayed Incidents",
            "header": "Export Incidents",
            "body": "Do you want to export only the displayed {{ x }} incidents or all incidents?",
            "successMsg": "Export successfully generated",
            "errorMsg": "Error generating export",
            "fileName": "Incidents.csv"
        },
        "noNotes": "No Incident Notes",
        "actionPlans": "BC Action Plans",
        "planActivationProcessStateTypes": {
            "notStarted": "Not Started",
            "inProgress": "In Progress",
            "skip": "Skip",
            "done": "Ready"
        },
        "authorized": "Authorized to Contact Vendors",
        "contacted": "Contacted Vendor(s)",
        "mobilized": "Mobilized Employees",
        "verified": "Verified",
        "applications": "[[Application]](s)",
        "equipment": "[[Equipment]]",
        "confirmReadiness": "Confirm Readiness",
        "passDue": "Pass Due",
        "activateDetails": {
            "activateDetails": "Activate Details",
            "calendarDescription": "This calendar will filter the upcoming critical dates."
        },
        "noCriticalDate": "No Critical Date",
        "noAssignees": "No Authorized Employees",
        "noEmployees": "No Mobilized Employees",
        "noEquipment": "No Verified [[Equipment]]",
        "noApplications": "No Verified Applications",
        "noVendors": "No Contact Vendors",
        "noLocation": "No [[Location]]",
        "msgUpdateStatusSuccess": "Activation status saved",
        "msgUpdateStatusFailed": "Failed to save activation status",
        "msgActionPlanReady": "Action plan for {{ process }} is ready",
        "communicationSidebar": {
            "include": "Include",
            "includeApplicationList": "Application List into message",
            "includeEquipmentList": "[[Equipment]] List into message",
            "includeAdditionalInstructions": "Additional Instructions into message",
            "attachments": "Attachments",
            "emailAttachmentsSizeError": "Email Attachments size exceeds allowed {{maxSize}} Mb. Email cannot be sent.",
            "loadingMessageParticipantsError": "Error loading communication participants"
        },
        "dashboard": {
            "overallProgress": "Overall Progress",
            "progressByRto": "Progress By RTO",
            "escalatedActionPlans": "Escalated Action Plans",
            "escalatedActionPlansDescription": "Action Plans with Escalated [[Issues]]",
            "departmentProgress": "[[Department]] Progress",
            "locationProgress": "[[Location]] Progress",
            "escalatedIssues": "Escalated [[Issues]]",
            "allIssues": "All [[Issues]]"
        },
        "issues": {
            "issue": "[[Issue]]",
            "issuesListTitle": "My [[Issues]]",
            "reportIssue": "Report [[Issue]]",
            "issueTitle": "Name of [[issues]]",
            "resolved": "Resolved",
            "dateTimeCreated": "Date/Time",
            "lblOwner": "Owner",
            "lblCreatedOn": "Created on",
            "lblStatus": "Status",
            "lblDepartment": "[[Department]]",
            "lblProcess": "[[Process]]",
            "lblCategory": "Category",
            "lblDescription": "Description",
            "addComment": "Add Comment",
            "escalateToManagement": "Escalate to Management",
            "resolveIssue": "Resolve [[issue]]",
            "reopenIssue": "Reopen [[issue]]",
            "edit": "Edit",
            "status": {
                "70cd9cf8-947a-43a1-8f19-f27b58da065e": "Open",
                "2e70fb00-5551-42f8-8057-a3a45dcfbb19": "Resolved"
            },
            "lblChangeStatus": {
                "70cd9cf8-947a-43a1-8f19-f27b58da065e": "Resolve [[Issue]]",
                "2e70fb00-5551-42f8-8057-a3a45dcfbb19": "Reopen [[Issue]]"
            },
            "comment": "Comment",
            "msgErrorLoadingComments": "Error loading Plan Activation [[Issue]] comments.",
            "escalatedIssuesOnly": "Escalated [[Issues]] Only",
            "issueDetails": "[[Issue]] Details"
        },
        "incidentRetrospective": {
            "incidentName": "Incident Name",
            "incidentType": "Incident Type",
            "incidentDuration": "Incident Duration",
            "incidentCreator": "Incident Created By",
            "description": "Description",
            "activePlans": "Activated Plans",
            "issues": "[[Issues]]",
            "Communications": "Communications",
            "executiveSummary": "Executive Summary",
            "retrospective": "Retrospective",
            "incidentNotes": "Incident Notes",
            "department": "[[Department]]",
            "location": "[[Location]]",
            "process": "[[Process]]",
            "title": "Title",
            "teamLead": "[[Team Lead]]",
            "deliveryMethod": "Delivery Method",
            "dateSent": "Date Sent",
            "totalRecipients": "Total Recipients",
            "viewPlans": "View Plans",
            "publishIncidentRetrospective": "Publish Incident Retrospective",
            "unResolveIncident": "Reactivate Incident",
            "startNote": "Start Doing This",
            "continueNote": "Continue Doing This",
            "stopNote": "Stop Doing This",
            "incidentInvalid": "The incident id is invalid",
            "theIncidentNeedsToBeResolve": "The incident needs to be resolve to see its retrospective",
            "generateAuditReport": "Audit [[Report]]",
            "errorGeneratingAuditReport": "Error generating Audit [[Report]]",
            "modalHeader": "Publish Incident Retrospective",
            "modalBody": "Are you sure you want to publish this retrospective?",
            "publish": "Publish",
            "draft": "Draft",
            "version": "Version",
            "modalEditor": "{{field}} for the retrospective",
            "retrospectiveSaveSuccessfully": "Retrospective was saved successfully",
            "retrospectiveSaveUnsuccessfully": "Retrospective was saved unsuccessfully",
            "summaryPlaceholder": "Enter executive summary information that pertains to the incident/exercise",
            "startPlaceholder": "e.g., We should schedule more exercises",
            "continuePlaceholder": "e.g., Communicating effectively",
            "stopPlaceholder": "e.g., Using the same scenario for exercises",
            "incidentReactivatedSuccessfully": "Incident reactivated successfully",
            "incidentReactivatedUnsuccessfully": "Incident reactivated unsuccessfully",
            "reactivateModalHeader": "Reactivate Incident",
            "reactivateModalBody": "Are you sure you want to reactivate this incident?"
        },
        "contactTeamLead": "Contact [[Team Lead]]",
        "contactSubjectMatterExpert": "Contact SME",
        "subjectMatterExpert": "SME",
        "viewCommunicationLog": "View Communication log"
    },
    "exerciseManager": {
        "scenarios": "Scenarios",
        "systemScenarios": "System Scenarios",
        "launchExercise": "Launch Exercise",
        "exerciseManager": "[[Exercise Manager]]",
        "assignedScenarios": "Assigned Scenarios",
        "assignedExercises": "Assigned Exercises",
        "newExerciseType": "New Exercise Type",
        "newSystemExerciseType": "New System Exercise Type",
        "newThreadHazard": "New Threat/Hazard",
        "newSystemThreadHazard": "New System Threat/Hazard",
        "systemExerciseType": "System Exercise Type",
        "systemThreadHazard": "System Threat/Hazard",
        "roadmap": {
            "theWeekOf": "The week of...",
            "exerciseRoadmap": "Exercise Roadmap",
            "weekOf": "Week of",
            "roadmapDescription": "This Roadmap provides a quick view to your scheduled exercises. To schedule a new exercise open the right panel and select the plan you want to schedule to start the [[process]].",
            "scheduleExerciseDescription": "Schedule the plan you would like to perform a exercise or test on.",
            "openExercise": "Open Exercise",
            "firstScheduled": "Go to first scheduled exercise",
            "noFirstScheduledExercise": "No future exercises have been scheduled for {{name}}",
            "weekOption": "4 Weeks",
            "monthOption": "12 Months"
        },
        "scenarioManager": {
            "scenarioName": "Scenario Name",
            "exerciseType": "Exercise Type",
            "hazardType": "Threat/Hazard",
            "description": "Description",
            "assumptions": "Assumptions",
            "objectives": "Objectives",
            "expectations": "Expectations",
            "metrics": "Metrics",
            "selectAExerciseType": "Select a Exercise Type",
            "selectAHazardType": "Select a Threat/Hazard Type",
            "successOnSave": "The scenario was successfully saved",
            "clear": "Clear",
            "60024": "An existing exercise scenario already has this name"
        },
        "sideMenu": {
            "deleteScenario": "Delete Scenario",
            "loadScenario": "Load Scenario",
            "successOnDelete": "The scenario was successfully deleted",
            "failedToDelete": "The scenario was unsuccessfully deleted",
            "removeConfirmationHeader": "Remove Scenario",
            "removeConfirmationBody": "Are you sure you want to remove scenario {{ scenarioName }}?",
            "sideMenuTitleInfo": "Create or load a Scenario so that it could be used in a future exercises.",
            "scenarios": "Scenarios",
            "overrideBody": "A scenario with the same name already exists",
            "numberOfProcesses": "Number of [[processes]]",
            "failedToGetExerciseTargetsInfo": "There was an error while trying to get the exercise targets information.",
            "notScheduled": "Not scheduled",
            "newScenario": "Create Scenario",
            "copyScenario": "Copy Scenario as new"
        },
        "modalEditor": "{{field}} for the scenario",
        "exercises": {
            "exercises": "Exercises",
            "updateScenario": "Update Scenario",
            "exerciseDate": "Exercise Date",
            "datePlaceholder": "Select the Start and End Date",
            "exerciseName": "Exercise Name",
            "details": "Details",
            "selectedTesters": "Selected Testers",
            "modalEditor": "{{field}} for the exercise",
            "startTime": "Start Time",
            "endTime": "End Time",
            "startAndEndDate": "Start/End Date",
            "startDatePlaceholder": "Select a Start Date",
            "endDatePlaceholder": "Select a End Date",
            "processes": "[[Processes]]",
            "successOnSave": "The exercise was successfully saved",
            "60033": "Overlapping exercise schedule",
            "60035": " Exercise is associated to a incident",
            "departmentInvalid": "The [[department]] is invalid",
            "locationInvalid": "The [[location]] is invalid",
            "testerAddedSuccessfully": "Tester added successfully",
            "testerRemoveSuccessfully": "Tester removed successfully",
            "testerAddedUnsuccessfully": "Tester added unsuccessfully",
            "testerRemoveUnsuccessfully": "Tester removed unsuccessfully",
            "scheduleAnExerciseFor": "Schedule an Exercise for",
            "deleteExerciseSuccess": "Exercise removed",
            "deleteExerciseFail": "Failed to remove exercise",
            "saveScenario": "Save Scenario"
        },
        "exerciseTypeModal": {
            "title": "Exercise Type",
            "desc": "Add or edit an exercise type to be used for this [[exercise manager]]",
            "typeNamePlaceholder": "Exercise Type Name",
            "exerciseTypes": "Exercises",
            "exerciseTypeInUseError": "Exercise is assigned to active scenarios or exercises",
            "duplicateExerciseTypeNameError": "Duplicate exercise type name",
            "systemExerciseTypeEditMessage": "System exercise types cannot be edited.",
            "system": {
                "header": "Remove Exercise",
                "body": "Do you want to remove this exercise type only from this portal or all portals?",
                "removeFromThisPortal": "Remove from this portal",
                "removeFromAllPortals": "Remove from all portals"
            },
            "portal": {
                "header": "Remove Exercise",
                "body": "Are you sure you want to remove this exercise type?",
                "ok": "OK"
            }
        },
        "threadHazardModal": {
            "title": "Threat/Hazard",
            "desc": "Add or edit an thread/hazard to be used for this [[exercise manager]]",
            "threadHazardNamePlaceholder": "Threat/Hazard Name",
            "threadHazards": "thread/Hazards",
            "threadHazardInUseError": "threadHazard is assigned to active scenarios or exercises",
            "duplicateThreadHazardNameError": "Duplicate thread/hazard name",
            "systemThreadHazardEditMessage": "System thread/hazards cannot be edited.",
            "system": {
                "header": "Remove Threat/Hazard",
                "body": "Do you want to remove this thread/hazard only from this portal or all portals?",
                "removeFromThisPortal": "Remove from this portal",
                "removeFromAllPortals": "Remove from all portals"
            },
            "portal": {
                "header": "Remove Threat/Hazard",
                "body": "Are you sure you want to remove this thread/hazard?",
                "ok": "OK"
            }
        }
    },
    "selectPortalFunctionalAreaForDepartmentModal": {
        "headerLabel": "Associate [[Planner]] [[Department]]",
        "searchItemLabel": "Search [[Planner]] [[Departments]]",
        "currentItemLabel": "Currently associated [[Planner]] [[Department]]",
        "resultLabel": "[[Planner]] [[Departments]]",
        "explanationText": "The selected [[Planner]] [[Department]] name will be changed to match the [[Department]] name.",
        "matchingNameFAAssociationMessage": "A [[Planner]] [[Department]] with the same name '{{name}}' was found. Would you like to link these [[departments]]?",
        "UnAssociateMessage": "Are you sure you would like to unlink the '{{name}}' from this [[department]]?",
        "errFailedToLoad": "Error loading available [[Planner]] [[departments]]",
        "linkDescription": "Create new bc [[department]] {{departmentName}} and link it to the [[department]]."
    },
    "textEditor": {
        "tags": "Tags",
        "element": "Element"
    }
}
