{
    "risk": {
        "lblRiskMenuTitle": "[[Risk]]",
        "lblRiskSetupMenuTitle": "Mise en place",
        "msgNoRiskConfiguration": "Les paramètres RPN de [[risk]] ne sont pas configurés",
        "headerMenu": {
            "riskLanding": "Tableau de bord",
            "riskSetup": "Mise en place",
            "riskDistribution": "Répartition",
            "riskThreats": "Menaces",
            "rpnConfiguration": "Configuration",
            "riskAnalytics": "Analytique",
            "assessments": "Évaluations",
            "assessment": "Évaluation",
            "assessmentDetails": "Détails de l'évaluation",
            "riskInitiative": "Initiatives",
            "initiativeDetails": "Détails de l'initiative"
        },
        "threats": {
            "typicalImpactDescription": "Description typique de l'impact",
            "rpnConfiguration": "Configuration RPN",
            "riskAnalytics": "Analyse des [[risk]]s",
            "assessments": "Évaluations",
            "impactDescription": "Description de l'impact",
            "threats": "Menaces",
            "newThreat": "Nouvelle menace",
            "deleteThreat": "Supprimer la menace",
            "selectType": "Sélectionner un type",
            "duplicateThreatTypeName": "Nom de type en double",
            "systemThreat": "Menace de système",
            "newSystemThreat": "Nouvelle menace de système",
            "system": {
                "header": "Éliminer la menace",
                "body": "Voulez-vous éliminer cette menace uniquement de ce portail ou de tous les portails?",
                "removeFromThisPortal": "Supprimer de ce portail",
                "removeFromAllPortals": "Supprimer de tous les portails"
            },
            "portal": {
                "header": "Éliminer la menace",
                "body": "Êtes-vous sûr(e) de vouloir éliminer cette menace?",
                "ok": "OK"
            },
            "copyThreatAsNew": "Copier la menace comme nouvelle"
        },
        "rpnConfiguration": {
            "formulaPlaceholder": "Sélectionner une formule",
            "statusPlaceholder": "Sélectionner un statut",
            "approvalSystem": "Système d'approbation",
            "prePopulateAnswers": "Utiliser les réponses d'évaluation antérieures",
            "prePopulatePlaceholder": "Pré-remplir",
            "formulaSaveSuccessfully": "La formule a été enregistrée avec succès",
            "formulaSaveUnSuccessfully": "La formule n’a pas été enregistrée avec succès",
            "riskBandConfiguration": "Les bandes de risque organisent vos numéros de priorité de risque en termes clairs. Définissez le nombre de bandes de risque que vous souhaitez utiliser pour vos évaluations des risques.",
            "equations": {
                "e0783df6-7ea6-496e-a3ae-0bd61e3d06d2": {},
                "e0783df6-7ea6-496e-a3ae-0bd61e3d06d": {},
                "13fcb83c-d1a2-4135-9e79-50bddff5dcdc": {},
                "f84ff46e-15a3-4348-b958-9ef58a434738": {},
                "610af901-b8cd-4ec2-8673-ca62dcf6c595": {},
                "dc52ab2d-ae1f-4e80-b11c-fc921cb6e2af": {}
            },
            "riskBandsKeys": {
                "1": "Faible",
                "2": "Moyen",
                "3": "Élevé",
                "4": "Faible à moyen",
                "5": "Moyen à élevé"
            },
            "verbiage": {
                "riskBand": "Les bandes de [[risk]] organisent vos numéros de priorité de [[risk]] en termes clairs. Définissez le nombre de bandes de [[risk]] que vous souhaitez utiliser pour vos évaluations des [[risk]]s.",
                "approvalSystem": "Si la valeur est Oui, une évaluation ne sera pas considérée comme terminée tant qu'un approbateur ne l'aura pas examinée et n'aura pas donné son approbation. Une fois que le participant a envoyé ses réponses, un courriel sera envoyé à l'approbateur pour le notifier.",
                "usePriorAssessmentResponses": "Si la valeur est Oui, les réponses des évaluations précédentes seront utilisées pour les nouvelles évaluations. La personne qui effectue l'évaluation a la possibilité de modifier les valeurs au besoin avant d’envoyer ses réponses.",
                "equation": "Sélectionnez l'équation d'évaluation que vous souhaitez utiliser pour vos évaluations de la continuité des activités. Il est appliqué dans le calcul RPN, qui indique le profil de [[risk]] relatif."
            },
            "impact": "Impact",
            "probability": "Probabilité",
            "mitigation": "Atténuation",
            "multiplier": "Multiplicateur",
            "detection": "Détection",
            "riskBands": "Bandes de [[risk]]",
            "assessmentEquation": "Équation d'évaluation",
            "numberOfRiskBands": "Nombre de bandes de [[risk]]"
        },
        "assessment": {
            "welcomeAssessment": "Bienvenue dans l'assistant de création d'évaluation",
            "typeQuestion": "Quel type d'évaluation souhaitez-vous effectuer?",
            "selectAnApprover": "Sélectionner un approbateur",
            "selectApprover": "Sélectionner l'approbateur",
            "reviewEmail": "Vérifier le courriel",
            "pickTargetType": "Choisir votre type de cible",
            "pickTargets": "Choisir vos cibles",
            "sendAssessments": "Envoyer des évaluations",
            "selectedTargets": "Cibles sélectionnées",
            "pickTargetTypeDescription": "Veuillez sélectionner le type d'évaluation que vous souhaitez créer.",
            "pickTargetsDescription": "Veuillez sélectionner le{{entity}} sur lequel vous souhaitez faire une évaluation.",
            "sendAssessmentsDescription": "Veuillez définir le destinataire, l'approbateur et la date d'échéance de l'évaluation, puis cliquer sur Envoyer et terminer pour envoyer l'invitation à l'évaluation.",
            "selectRecipient": "Sélectionner le destinataire",
            "sendAssessmentRequirementsError": "Veuillez vous assurer que chaque évaluation a un destinataire, un approbateur et une date d'échéance",
            "create": "Créer une évaluation",
            "riskPriorityNumber": "Numéro de priorité de [[risk]]",
            "assessmentInvalid": "L'évaluation n’est pas valide",
            "saveAndContinue": "Enregistrer continuer",
            "selectStatus": "Sélectionner le statut",
            "targetName": "Nom de cible",
            "welcomeAssessmentUser": "Bienvenue, {{user}}",
            "createAssessmentWizard": "Créer un assistant d'évaluation",
            "welcomeAssessmentDescription": "Vous trouverez ci-dessous le nombre de menaces commerciales que vous avez été chargé d'analyser dans le cadre de cette évaluation. Veuillez examiner chaque menace et répondre aux facteurs de [[risk]] du mieux que vous pouvez. Ces valeurs sont utilisées pour calculer la valeur du numéro de priorité de [[risk]] (RPN) de la cible.",
            "welcomeAssessmentConditions": [
                "Vos réponses sont automatiquement enregistrées, vous pouvez faire une pause pendant l'exécution de l'évaluation.",
                "Vous aurez la possibilité de revoir et de modifier vos réponses avant de les envoyer."
            ],
            "threatsToAssess": "Menaces à évaluer",
            "timeForAssessments": "Devrait prendre moins de :",
            "getStarted": "Démarrer",
            "minutesToCompleteAssessment": "{{minutes}} minutes",
            "minuteToCompleteAssessment": "{{minute}} minute",
            "estimatedTimeToCompleteAssessment": "Temps estimé pour terminer",
            "accountingThreats": "{{completed}} sur {{available}}",
            "removeAssessment": "Éliminer l'évaluation",
            "removeAssessmentDescription": "Êtes-vous sûr(e) de vouloir éliminer cette évaluation?",
            "searchTargetName": "Rechercher le nom de la cible",
            "searchRecipientName": "Rechercher le nom du destinataire",
            "searchPriorityNumber": "Rechercher le numéro de priorité",
            "selectCreationDate": "Sélectionner la date de création",
            "deleteAssessment": "Supprimer l'évaluation",
            "completeAssessment": "Terminer l’évaluation",
            "statusList": {
                "open": "Ouvert",
                "waitingApproval": "En attente d'approbation",
                "abandoned": "Abandonné",
                "closed": "Fermé",
                "archived": "Archivé",
                "deleted": "Supprimé",
                "inProgress": "En cours"
            },
            "sendEmailDescription": {
                "selectDueDate": "",
                "selectApprover": "",
                "reviewEmail": "",
                "sendFinish": ""
            },
            "loadingAssessment": "Chargement de l'évaluation en cours...",
            "assessmentExistHelperText": "Lorsque les résultats de la recherche sont surlignés en jaune, cela signifie qu'ils ont une évaluation ouverte. Si une nouvelle évaluation est créée, les anciennes seront fermées et marquées comme abandonnées.",
            "assessmentNotFound": "Impossible de trouver l'évaluation.",
			"assessmentFailedStart": "Échec du démarrage de l'évaluation.",
            "targetType": {
                "Department": "[[Department]]",
                "Location": "[[Location]]",
                "Company": "Société"
            }
        },
        "threatDetail": {
            "maximum": "Maximum",
            "minimum": "Minimum",
            "submitAssessment": "Envoyer l'évaluation",
            "factorsMinMax": {
                "impact": {
                    "min": "Le moins d'impact",
                    "max": "Le plus d’impact",
                    "verbiage": "L'impact de [[risk]] fournit une estimation des pertes possibles liées à un [[risk]]."
                },
                "probability": {
                    "min": "Probabilité minimale",
                    "max": "Très probable",
                    "verbiage": "La probabilité de [[risk]] consiste à déterminer la probabilité qu'un [[risk]] se produise. Cette possibilité pourrait être basée sur ce qui s'est passé dans le passé."
                },
                "detection": {
                    "min": "Temps d'avertissement prolongé",
                    "max": "Peu ou pas de temps d'avertissement",
                    "verbiage": "La détection des [[risk]]s est utilisée pour mesurer la capacité à détecter les [[risk]]s avant qu'ils ne surviennent."
                },
                "mitigation": {
                    "min": "Peu ou pas d'atténuation en place",
                    "max": "Très atténué",
                    "verbiage": "L'atténuation des [[risk]]s se produit lorsqu'une société réduit les [[risk]]s pour son activité en rendant ses opérations plus efficaces et en s'exposant à moins de [[risk]]s."
                },
                "multiplier": {
                    "min": "Minimum ",
                    "max": "Maximum ",
                    "verbiage": "À utiliser pour montrer ce que vous pensez de la menace commerciale."
                }
            }
        },
        "assessmentDetails": {
            "assessmentDetails": "Détails de l'évaluation",
            "approveAssessment": "Approuver l'évaluation",
            "completionDate": "Date d’achèvement",
            "riskScore": "Cote de [[risk]]",
            "threatHazard": "Menace/risque",
            "emailRecipient": "Destinataire du courriel",
            "emailApprover": "Approbateur du courriel",
            "assessmentSubmittedSuccessfully": "L'évaluation a été envoyée avec succès",
            "assessmentSubmittedUnsuccessfully": "L'évaluation n’a pas été envoyée avec succès",
            "mainVerbiage": "Vous trouverez ci-dessous le nombre de menaces que vous avez été chargé d'analyser dans le cadre de cette évaluation. Veuillez examiner chaque menace et répondre aux facteurs de [[risk]] du mieux que vous pouvez et ajouter des commentaires aux fins de clarté. Ces valeurs sont utilisées pour calculer la valeur du numéro de priorité de [[risk]] (RPN) du {{targetType}}.",
            "titleVerbiage": "Évaluation des risques pour {{target}}",
            "dirtyMessageTitle": "Enregistrer les modifications",
            "dirtyMessageBody": "Souhaitez-vous enregistrer vos modifications pour {{threatName}}?",
            "msgSaveAssessmentResultFailed": "Échec de l'enregistrement de l'évaluation.",
            "assessmentComplete": "Cette évaluation a déjà été achevée, merci.",
            "declineAssessmentDescription": "Êtes-vous sûr(e) de vouloir refuser cette évaluation? Le destinataire recevra les commentaires envoyés ci-dessous.",
            "declineAssessment": "Refuser l’évaluation",
            "assessmentDeclined": "Évaluation refusée",
            "approvalComments": "Commentaires d'approbation",
            "archiveMessage": "Êtes-vous sûr(e) de vouloir archiver cette évaluation?",
            "abandonMessage": "Êtes-vous sûr(e) de vouloir abandonner cette évaluation?",
            "closeMessage": "Êtes-vous sûr(e) de vouloir fermer cette évaluation?"
        },
        "analytics": {
            "highestRiskValue": "Valeur de [[risk]] la plus élevée",
            "columnsSelected": "{{columns}} colonnes sélectionnées",
            "searchAssessment": "Rechercher l’évaluation",
            "targetTypeName": "Type de cible",
            "closeDate": "Date de fermeture"
        },
        "initiative": {
            "newInitiative": "Nouvelle initiative",
            "listHelperText": "Bienvenue chez Preparis Risk! Vous trouverez ci-dessous une liste d'initiatives de [[risk]] où vous pouvez créer et suivre les résultats de votre cycle d'évaluation des [[risk]]s.",
            "removeInitiative": "Éliminer l'initiative",
            "removeInitiativeDescription": "Êtes-vous sûr(e) de vouloir éliminer cette initiative?",
            "searchCompletionDate": "Rechercher la date d’achèvement"
        },
        "initiativeDetails": {
            "initiatives": "Initiatives",
            "name": "Nom",
            "dueDate": "Date d’échéance",
            "assessmentFinding": "Résultats de l'évaluation",
            "goal": "Objectif",
            "findingsPlaceholder": "Saisir ici les résultats de votre évaluation",
            "findingsGoal": "Saisir vos objectifs ici",
            "modalMsg": "{{field}} pour l'initiative",
            "selectAssessment": "Sélectionner les évaluations",
            "targets": "Cibles",
            "rpn": "RPN",
            "type": "Type",
            "creationDate": "Date de création",
            "selectAStatus": "Sélectionner un statut",
            "assessmentSelectVerbiage": "Sélectionner les évaluations à inclure dans cette initiative de gestion des [[risk]]s.",
            "targetTypeName": "Type de cible",
            "completedOn": "Terminé le",
            "initiativeSavedSuccessfully": "L'initiative a été enregistrée avec succès",
            "initiativeSavedUnsuccessfully": "L'initiative n’a pas été enregistrée avec succès",
            "topVerbiage": "Les initiatives de gestion des [[risk]]s vous permettent de documenter ce qui a été découvert au cours de la période d'évaluation et de définir des objectifs d'atténuation future."
        },
        "dashboard": {
            "assessmentsByRpn": "Évaluations par numéro de priorité des [[risk]]s",
            "riskBand": "Bande de [[risk]]",
            "riskMatrix": "Matrice des [[risk]]s",
            "riskOverTime": "[[Risk]] dans le temps",
            "helperText": "Dans le tableau Évaluation par numéro de priorité des [[risk]]s (RPN), choisissez l'évaluation que vous souhaitez afficher.",
            "rpnOverTimeDescription": "Dans le tableau Évaluation par numéro de priorité des [[risk]]s (RPN), choisissez l'évaluation que vous souhaitez afficher."
        }
    }
}
