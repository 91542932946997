{
    "cre": {
        "locations": "[[Locations]]",
        "location": "[[Location]]"
    },
    "common": {
        "locations": "[[Locations]]",
        "location": "[[Location]]",
        "defaultLocation": "[[Location]] par défaut"
    },
    "panicTriggers": {
        "addCustomizedByLocation": "Ajouter un déclencheur personnalisé par [[location]]"
    },
    "reports": {
        "labels": {
            "Tenant": "Locataire",
            "Primary Location": "[[Location]] principal",
            "Location": "[[Location]]"
        }
    },
    "manage": {
        "nav": {
            "lblLocations": "[[Locations]]"
        },
        "locations": {
            "btnLocationCreate": "Ajouter un nouveau [[location]]",
            "btnSaveLocation": "Sauvegarder le [[location]]",
            "duplicateLocationName": "Un [[location]] du même nom existe déjà.",
            "errorLocationReferenced": "[[Location]] référencé comme [[location]] principal par les utilisateurs",
            "errorRemoveLocationFailed": "Une erreur s’est produite lors de la suppression du [[location]].",
            "errorSaveLocationFailed": "Une erreur s’est produite lors de la sauvegarde du [[location]].",
            "lblAreYouSureRemoveLocation": "Êtes-vous sûr(e) de vouloir supprimer ce [[location]]?",
            "lblBuildingAssignedToTenantsAreYouSureRemoveLocation": "Ce [[location]] est associé à des locataires. L’élimination du [[location]] l’éliminera des locataires. Êtes-vous sûr(e) de vouloir éliminer ce [[location]]?",
            "lblLocationActivity": "Activité du [[location]]",
            "lblLocationInfo": "Informations sur le [[location]]",
            "lblLocationListTitle": "[[Locations]]",
            "lblLocationMembers": "Utilisateurs associés à ce [[location]]",
            "lblLocationName": "Nom du [[location]]",
            "lblNewLocation": "Nouveau [[location]]",
            "msgRemoveLocationSuccess": "Le [[location]] a été éliminé avec succès.",
            "msgSaveLocationSuccess": "Le [[location]]{0} a été sauvegardé avec succès.",
            "msgUpdateGeofence": "l’adresse du [[location]] a changé, souhaitez-vous mettre à jour le géo-repérage de ce [[location]]?",
            "deleteLocation": "Éliminer le [[location]]",
            "removeLocation": "Éliminer le [[location]]"
        },
        "roles": {
            "lblEntityMgmtExplain": "Permettre aux utilisateurs de créer/modifier/supprimer des [[locations]], des groupes et des départements.",
            "lblIncludeCrisisDescription": "Afficher les coordonnées des utilisateurs ayant ce rôle sur la page Équipe de crise de chaque [[location]]."
        },
        "bulkUploadConfig": {
            "lblDefaultLocation": "[[Location]] par défaut",
            "defaultLocationPlaceHolder": "Sélectionner le [[location]] par défaut",
            "defaultLocationTooltip": "Ce [[location]] sera attribué aux nouveaux utilisateurs n’ayant pas de [[location]] précisé dans leur fichier de téléchargement groupé."
        }
    }
}
