{
    "incident": {
        "incident": "Incident",
        "incidents": "Incidents",
        "incidentHistory": "Historique des incidents",
        "communicate": "Communiquer",
        "communicateToAll": "Communiquer à tous",
        "crisisTeam": "Équipe de crise",
        "history": "Historique",
        "incidentName": "Nom de l’incident",
        "incidentNotes": "Notes d’incident",
        "incidentType": "Type d’incident",
        "originalSender": "Expéditeur d’origine",
        "originalLocation": "[[Location]] d’origine",
        "created": "Créé",
        "startedDate": "Commencé",
        "resolved": "Résolu",
        "criteria": "Affectant les utilisateurs présents",
        "roles": "Rôles",
        "groups": "Groupes",
        "departments": "[[Departments]]",
        "locations": "[[Locations]]",
        "incidentResolved": "Résolu",
        "incidentAttachments": "Pièces jointes",
        "detailsTabText": "Détail",
        "communicationLogTabText": "Journal de communication",
        "btnArchive": "Archiver",
        "btnUnArchive": "Restaurer",
        "btnSaveAsNewAttachment": "Sauvegarder en tant que nouvelle pièce jointe",
        "btnReplaceExistingAttachment": "Remplacer la pièce jointe existante",
        "btnUploadAttachment": "Téléverser la pièce jointe",
        "uploadingFile": "Téléversement du fichier",
        "dropFile": "Déposer le fichier ici",
        "dropFiles": "Déposez les fichiers ici",
        "msgNoteAdded": "Note ajoutée",
        "msgNoteAddError": "Échec de l’ajout de la note",
        "msgNoteDeleted": "Remarque supprimée",
        "msgNoteDeleteError": "Échec de la suppression de la note",
        "btnSendMessage": "Envoyer le message",
        "btnConferenceCall": "Conférence téléphonique",
        "recipients": "Destinataires",
        "messageContent": "Contenu du message",
        "attachments": "Pièces jointes",
        "totalRecipients": "Destinataires totaux",
        "totalMessages": "Total des messages",
        "totalResends": "Total des renvois",
        "totalDevices": "Nombre total d’appareils",
        "remainingResends": "Restant",
        "summary": "Résumé",
        "responseSummary": "Résumé de la réponse",
        "email": "Courriel",
        "phone": "Téléphone",
        "contactPreference": "Préférence de contact",
        "responseDateTime": "Date de réponse",
        "response": "Réponse",
        "cancelResendSuccess": "L’annulation du renvoi a été effectuée avec succès",
        "cancelResendFailed": "Échec de l’annulation du renvoi",
        "recipientMessage": "Message du destinataire",
        "respondedFrom": "A répondu de",
        "allResponses": "Toutes les réponses",
        "allRoles": "Tous les rôles",
        "allLocations": "Tous les [[locations]]",
        "allDepartments": "Tous les [[departments]]",
        "allGroups": "Tous les groupes",
        "allTenants": "Tous les locataires",
        "unknown": "Inconnu",
        "noResponse": "Pas de réponse",
        "name": "Nom",
        "message": "Message",
        "messages": "Messages",
        "events": "Événements",
        "location": "[[Location]]",
        "lastResponseFrom": "Dernière réponse de",
        "latestResponseDate": "Dernière date de réponse",
        "updateResponse": "Mettre à jour la réponse",
        "lblSearch": "Rechercher",
        "btnExport": "Exportation",
        "refresh": "Actualiser",
        "details": "Détails",
        "affectedUsers": "Utilisateurs concernés",
        "startDate": "Date de début",
        "createIncident": "Créer un incident",
        "btnCreateEvent": "Nouvel évènement",
        "lblAdditionalRoles": "Rôles ajoutés manuellement",
        "lblAdditionalLocations": "[[Locations]] ajoutés manuellement",
        "lblAdditionalGroups": "Groupes ajoutés manuellement",
        "lblAdditionalDepartments": "[[Departments]] ajoutés manuellement",
        "lblAdditionalTenants": "Locataires ajoutés manuellement",
        "invalidUserResponseType": "Type de réponse invalide",
        "saveEventNameChangeSuccess": "Renommer l’événement terminé avec succès",
        "saveEventNameChangeFailed": "La modification du nom de l’événement a échoué",
        "desktop": "Bureau",
        "mobileApp": "Application mobile",
        "errorLoadingResponseSummaryMsg": "Erreur lors du chargement du résumé de la réponse",
        "errorLoadingMessageRecipients": "Erreur lors du chargement des destinataires du message",
        "errorLoadingMessageRecipientSummary": "Erreur lors du chargement du résumé du destinataire du message",
        "errorLoadingMessageJobDetails": "Erreur lors du chargement des détails des tâches de message individuelles",
        "archiveConfirmation": {
            "header": "Êtes-vous sûr(e) de vouloir archiver cet incident?",
            "body": "Une fois qu’un incident est archivé, vous ne pourrez plus le voir dans la liste des incidents actifs.",
            "btnText": "Oui",
            "text": "Oui"
        },
        "unArchiveConfirmation": {
            "header": "Êtes-vous sûr(e) de vouloir restaurer cet incident?",
            "body": "En restaurant l’incident, vous pourrez le voir dans la liste des incidents actifs.",
            "btnText": "Oui",
            "text": "Oui"
        },
        "resolveConfirmation": {
            "header": "Êtes-vous sûr(e) de vouloir résoudre cet incident?",
            "body": "Une fois qu’un incident est résolu, il peut être archivé.",
            "btnText": "OK"
        },
        "deleteDocumentConfirmation": {
            "header": "Éliminer la confirmation",
            "body": "Êtes-vous sûr(e) de vouloir supprimer la pièce jointe {{ docName }}?",
            "btnText": "OK"
        },
        "cancelConfirmation": {
            "header": "Annuler l’incident?",
            "body": "Êtes-vous sûr(e) de vouloir annuler cet incident? Toutes les modifications seront perdues.",
            "text": "Oui"
        },
        "cancelResendConfirmation": {
            "header": "Confirmer l’annulation du renvoi",
            "body": "Êtes-vous sûr(e) de vouloir annuler les renvois restants de ce message?",
            "text": "Oui"
        },
        "attachmentDialog": {
            "select": "Sélectionner",
            "selectDocument": "Sélectionner un document",
            "selectFile": "Sélectionner le fichier",
            "chooseFile": "Choisir le fichier",
            "replaceExisting": "",
            "saveAsNew": ""
        },
        "viewParticipants": "Afficher les destinataires",
        "responsesSettings": {
            "errors": {
                "definitionsAreRequired": "Des définitions de réponse sont requises.",
                "keysAreRequired": "Les clés de réponse sont requises.",
                "duplicatesAreNotAllowed": "Les clés de réponse en double ne sont pas autorisées.",
                "definitionIsRequiredIfKeyIsNumeric": "Une définition de réponse est requise si la clé de réponse est numérique."
            }
        },
        "showArchived": "Afficher les archives",
        "showCurrent": "Afficher actuel",
        "newIncident": "Nouvel incident",
        "status": "Statut",
        "type": "Type",
        "dateCreated": "Date de création",
        "participantsCriteria": "Critères des participants",
        "messageTypes": "Types de messages",
        "numberOfRecipients": "Nombre de destinataires",
        "viewCriteria": "Afficher les critères",
        "lblSms": "Message texte",
        "lblDesktop": "Bureau",
        "lblMobile": "Mobile",
        "lblVoice": "Voix",
        "lblConferenceCall": "Conférence téléphonique",
        "lblPushMessage": "Message instantané",
        "lblEmail": "Courriel",
        "incidentsFilterPlaceHolder": "Affiner les résultats par : Statut, type, nom ou critères",
        "restoreSuccess": "Incident restauré avec succès",
        "archiveSuccess": "Incident archivé avec succès",
        "restoreError": "Erreur lors de la tentative de restauration de l’incident",
        "archiveError": "Erreur lors de la tentative d’archivage de l’incident",
        "incidentCommunication": "Communication",
        "nav": {
            "lblIncidents": "Incidents",
            "lblOverview": "Aperçu",
            "lblCommunicate": "Communiquer",
            "lblDashboard": "Tableau de bord",
            "lblSendEmergencyMessage": "Envoyer le message",
            "lblStartAConferenceCall": "Démarrer une conférence téléphonique",
            "lblSplashMessages": "Messages de démarrage",
            "lblScheduledConferenceCalls": "Conférences téléphoniques programmées",
            "lblPublicAlerts": "[[Alerts]] publiques",
            "lblGeolocations": "Géolocalisations",
            "lblHistory": "Historique",
            "lblCrisisTeam": "Équipe de crise",
            "lblPanicDashboard": "Tableau de bord du mode Panique",
            "lblCallInAnnouncements": "Annonces d’appels",
            "lblScheduledMessages": "Messages programmés",
            "lblEmergencyButtonDashboard": "[[Alerts]] lancée par mobile"
        },
        "incidentLocation": "Géolocalisation",
        "overview": {
            "lblActiveAlerts": "Messages de démarrage actifs",
            "lblIncidentHistory": "Historique des incidents",
            "lblSendEmergencyMessage": "Envoyer le message",
            "lblNotAnEmergency": "Pas une urgence? Communiquez sur un événement avec l'une de ces options:",
            "lblStartAConferenceCall": "Démarrer une conférence téléphonique",
            "lblCreateCallInAnnouncement": "Créer une annonce d’appel",
            "lblCallInAnnouncements": "Annonces d’appels",
            "lblCreateSplashMessage": "Créer un message de démarrage",
            "lblNew": "Nouveau",
            "lblViewAll": "Afficher tout",
            "lblHelpfulToolsAndResources": "Outils et ressources utiles",
            "lblSplashMessages": "Message de démarrage",
            "loadingIncidentHistoryError": "Erreur lors du chargement de l’historique des incidents récents",
            "downloadFileNoContentError": "Aucun contenu disponible au téléchargement",
            "downloadResourceError": "Erreur lors du téléchargement de la ressource",
            "loadingSplashMessagesError": "Erreur lors du chargement des messages de démarrage",
            "loadingCallInAnnoucementsError": "Erreur lors du chargement des annonces d’appel",
            "lblNoSplashMessage": "Il n’y a aucun message de démarrage actif."
        },
        "messageProcessingStatus": {
            "lblSubmitting": "Soumission",
            "lblSending": "Envoi en cours",
            "lblCompleted": "Terminé",
            "lblTitle": "Votre message est en route.",
            "btnViewCommLog": "Afficher le journal de communication"
        },
        "messaging": "Messagerie",
        "recentMessages": "Messages récents",
        "dateTime": "Date heure",
        "sender": "Expéditeur",
        "crisisTeams": {
            "btnDownload": "Télécharger",
            "lblCrisisTeams": "Équipes de crise",
            "lblCrisisTeamTotalMembers": "Total des membres",
            "btnFinishedViewing": "Visualisation terminée",
            "lblMembers": "[[Members]]",
            "lblCrisisTeamSelected": "Aucune équipe de crise sélectionnée",
            "lblTeamDocuments": "Documents d’équipe",
            "lblLocationInfo": "Informations sur l’[[location]]",
            "lblMainPhone": "Principal",
            "lblSecurityPhone": "Sécurité",
            "lblEmergencyPhone": "Appel d’urgence",
            "lblAnnouncementsPhone": "Annonces",
            "lblTeamMembers": "Membres de l’équipe de crise",
            "lblMember": "Membre",
            "lblPhoneNumbers": "Numéros de téléphone",
            "lblEmail": "Courriel",
            "lblFloor": "Étage",
            "lblRole": "Rôle",
            "lblPhone": "Téléphone",
            "lblOffice": "Bureau",
            "lblNoCrisisTeamMembers": "Aucun membre n’a été ajouté à cette équipe.",
            "lblTeamDocumentsTitle": "Documents d’équipe",
            "msgNoDescription": "Aucune description",
            "msgNoDocument": "Aucun document n’a été ajouté à cette équipe.",
            "phSearchFilter": "Affiner les résultats par : [[Location]]"
        },
        "splashMessage": {
            "splashMessage": "Message de démarrage",
            "splashMessages": "Messages de démarrage",
            "newSplashMessage": "Nouveau message de démarrage",
            "numberOfResponses": "N<sup>bre</sup> de réponses",
            "displayedToUsersIn": "Affiché aux utilisateurs dans",
            "message": "Message",
            "expires": "Expire",
            "startTime": "Heure de début",
            "displayDate": "Afficher la date",
            "title": "Titre",
            "status": "Statut",
            "errorLoadingSplashMessages": "Erreur lors du chargement des messages de démarrage",
            "errorLoadingSplashMessage": "Erreur lors du chargement du message de démarrage",
            "filterSplashMessagesPlaceholder": "Affiner les résultats par : Titre, message ou affiché dans",
            "active": "Actif",
            "inactive": "Inactif",
            "never": "Jamais",
            "preview": "Aperçu",
            "confirmDeactivate": {
                "header": "Confirmer la désactivation",
                "body": "<p>Êtes-vous sûr(e) de vouloir désactiver :<strong>{{ name }}</strong>?</p><p>Certains utilisateurs n’ont peut-être pas encore répondu. Une fois désactivé, le message ne s’affichera plus.</p>",
                "text": "Oui, désactiver"
            },
            "confirmActivate": {
                "header": "Réactiver le message de démarrage",
                "body": "Êtes-vous sûr(e) de vouloir réactiver ce message de démarrage?",
                "text": "Oui, réactiver"
            },
            "confirmSave": {
                "header": "Sauvegarder le message de démarrage",
                "body": "Êtes-vous sûr(e) de vouloir sauvegarder ce message de démarrage?",
                "text": "Sauvegarder"
            },
            "confirmSaveMidStream": {
                "header": "Message de démarrage déjà affiché",
                "body": "Ce message de démarrage a déjà été montré à certains utilisateurs. Continuer?",
                "text": "Continuer"
            },
            "confirmDelete": {
                "header": "Supprimer le message de démarrage",
                "body": "Êtes-vous sûr(e) de vouloir éliminer ce message de démarrage?",
                "text": "Éliminer"
            },
            "successChangeStatus": "Modification du statut du message de démarrage avec succès",
            "errorChangeStatus": "Erreur lors de la modification du statut du message de démarrage",
            "manual": "Manuel",
            "expired": "Expiré",
            "helpAcknowledgementType": "Utiliser l’option OUI/NON ou CASE À COCHER AVEC OK pour confirmer la lecture. Utiliser l’option OK par défaut à des fins d’information.",
            "lblAcknowledgementType": "Type d’accusé de réception",
            "lblCancelSplashMessage": "Annuler le message de démarrage",
            "lblOptionsSubtitle": "Options",
            "lblDetailsSubtitle": "Détails du message",
            "lblDisplaySubtitle": "Affichage du message",
            "lblDontExpire": "N’expire pas",
            "lblEdit": "Modifier",
            "lblExpireMessage": "Le message expire le",
            "lblExpireMessageDontExpireInfo": "(Le message s’affichera jusqu’à cette date lorsqu’il se désactivera automatiquement même si certains utilisateurs n’ont pas répondu.)",
            "lblExpireMessageExpireOnInfo": "(Le message continuera à s’afficher pour les utilisateurs n’ayant pas répondu jusqu’à ce que vous désactiviez manuellement le message.)",
            "lblExpireOn": "Expire le",
            "lblMessageContent": "Contenu du message",
            "lblMessageTitle": "Titre du message",
            "lblNumberRecipients": "Destinataires totaux",
            "lblNoResponses": "Aucun utilisateur n’a répondu à ce message.",
            "lblOptions": "OPTIONS",
            "lblRecipients": "DESTINATAIRES",
            "lblRecipientsSubtitle": "Choisir les destinataires",
            "lblShowMessage": "Afficher le message sur",
            "lblShowOnDate": "Afficher sur",
            "lblShowOnInfo": "(Le message sera affiché aux utilisateurs sélectionnés ce jour-là)",
            "lblSendMessageTo": "Afficher aux utilisateurs présents",
            "lblSplashDetails": "Détails de démarrage",
            "lblSplashMessageTitle": "Nouveau message de démarrage",
            "lblSplashRecipients": "Destinataires de démarrage",
            "lblSummary": "RÉSUMÉ",
            "lblSummarySubtitle": "Résumé",
            "msgOptions": "Saisir les informations suivantes, puis cliquer sur le bouton suivant pour passer à l’étape suivante.",
            "msgRecipients": "Sélectionner l’une des catégories ci-dessous ou en choisir une seule pour décider qui devrait voir ce message de démarrage.",
            "msgRecipientsTip1": "Sélectionner dans n’importe quelle catégorie pour déterminer qui verra ce message de démarrage. Un utilisateur doit correspondre à l’une des valeurs de chaque catégorie que vous sélectionnez pour être inclus. Ou vous pouvez choisir d’autoriser tout le monde à afficher ce message de démarrage.",
            "msgRecipientsTip2": "Si Atlanta et Chicago sont sélectionnés sous [[Location]], et que Comptabilité et ventes sont sélectionnés sous [[Departments]], ce message de démarrage ne sera vu que par les utilisateurs situés à Atlanta OU à Chicago, mais travaillant également SOIT dans le [[department]] Comptabilité ou Ventes.",
            "msgSaveNewSplashFail": "Échec de la création du message de démarrage, veuillez réessayer plus tard.",
            "msgSaveNewSplashSuccess": "Nouveau message de démarrage créé avec succès.",
            "msgSaveUpdateSplashFail": "Échec de la mise à jour du message de démarrage, veuillez réessayer plus tard.",
            "msgSaveUpdateSplashSuccess": "Message de démarrage sauvegardé avec succès.",
            "msgSplashBodyRequiredError": "Le champ de contenu du message est vide",
            "msgDeleteSplashSuccess": "Message de démarrage éliminé avec succès",
            "msgDeleteSplashFail": "Échec de l’élimination du message de démarrage.",
            "msgSummary": "Veuillez vérifier les informations ci-dessous pour vous assurer que tout est correct avant de cliquer sur Sauvegarder.",
            "msgUserRequired": "Vous devez sélectionner au moins une catégorie.",
            "msgErrorLoadingSplashMessage": "Impossible de charger le message de démarrage.",
            "phMessageTitle": "Saisir un titre qui s’affichera dans l’en-tête du message de démarrage",
            "confirmCancelSplashMessage": {
                "header": "Annuler le message de démarrage",
                "body": "Êtes-vous sûr(e) de vouloir annuler ce message de démarrage?",
                "text": "Oui"
            },
            "btnSaveFinished": "Sauvegarder et terminer",
            "lblMessageSubtitle": "Message",
            "lblPreviewSplashMessage": "Aperçu du message",
            "lblStatus": "Statut",
            "lblAffectingUsers": "Afficher le message aux utilisateurs présents",
            "lblAndAreIn": "et sont dans",
            "lblEveryone": "Tout le monde",
            "lblSplashResponsesSubtitle": "Réponses de démarrage",
            "lblNumUsersResponded": "N<sup>bre</sup> d’utilisateurs qui ont répondu",
            "phSearchFilter": "Affiner les résultats par : Nom, rôle ou [[location]]",
            "response": {
                "name": "Nom",
                "role": "Rôle",
                "location": "[[Location]]",
                "responded": "A répondu",
                "response": "Réponse"
            },
            "responseCountMessage": "Affiche{{ x }} réponses des utilisateurs sur {{ y }}",
            "startEndDateError": "La date d’expiration doit être postérieure à la date de début"
        },
        "dashboard": {
            "sendMessageDescription": "En cas d’urgence, envoyer un message à ceux qui ont besoin d’être informés. Après avoir envoyé un message, vous serez en mesure de suivre l’incident et les réponses (si sélectionné) pour rester au courant des événements.",
            "conferenceCallDescription": "Besoin de parler à un groupe de personnes pour discuter d’un incident? Nous avons ce qu’il vous faut! Avec notre conférence téléphonique, plus besoin d’attendre que les gens appellent, car notre service appelle tous ceux qui sont sélectionnés et les joint immédiatement à la conférence téléphonique lorsqu’ils répondent.",
            "splashMessagesDescription": "Les messages de démarrage sont un excellent outil à utiliser pour communiquer une variété de situations, des incidents aux rappels importants. Si vous créez un message de démarrage, ceux que vous avez désignés pour recevoir le message de démarrage seront présentés lors de la connexion au message. Vous pourrez également suivre leur réponse.",
            "currentlyActive": "Actuellement actif",
            "viewAllSplashMessages": "Afficher tous les messages de démarrage",
            "callInAnnouncementsDescription": "L’utilisation d’une annonce d’appel est un autre excellent outil à utiliser pour communiquer des informations. Enregistrer un message qui sera entendu par toutes les personnes appelant le numéro précisé. Les annonces d’appel sont idéales pour les fermetures de bureaux ou lorsque Internet est en panne ou n’est pas fiable.",
            "noCallInAnnouncements": "Aucune annonce d’appel",
            "viewAllCallInAnnouncements": "Afficher toutes les annonces d’appel",
            "newCallInAnnouncement": "Nouvelle annonce d’appel"
        },
        "locationDescription": "Description de l’[[location]]",
        "enableWatchIncidentBtn": "Voir l'incident",
        "stopWatchingIncidentBtn": "Arrêter de regarder l'incident",
        "enableWatchIncident": "Notification d'intervention d'urgence activée avec succès",
        "disableWatchIncident": "Notification d'intervention d'urgence désactivée avec succès",
        "updateWatchIncidentFailed": "Une erreur s'est produite lors de la mise à jour de la notification d'intervention d'urgence",
        "viewCommunicationLog": "Afficher le journal des communications",
        "exceptionExport": "Exportation d'exceptions",
        "sendToAll": "Envoyé à tous les utilisateurs",
        "manuallyAdded": "Utilisateurs ajoutés manuellement"
    }
}
