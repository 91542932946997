{
    "businessContinuity": {
        "planner": "[[Planner]]",
        "bcPlanner": {
            "businessContinuity": "Business Continuity",
            "continuityOfOperationPlanning": "Continuity of Operations",
            "emergencyOperations": "Emergency Operations",
            "disasterRecovery": "Disaster Recovery",
            "businessContinuityNoPlanner": "Business Continuity",
            "continuityOfOperationPlanningNoPlanner": "Continuity of Operations",
            "emergencyOperationsNoPlanner": "Emergency Operations",
            "introductionHeader": "Build Your Plan. Let's Get Started.",
            "introductionSecondaryDescription": "You have been selected as the admin for your organization.",
            "introductionDescription": "You will be guided through setting up your [[Departments]] continuity plan. Below are the steps to complete your plan. You will be provided with preloaded data to build your plan.",
            "responseTeamsDescription": "Organizations need to set up continuity response teams in order to handle emergencies and keep running during unplanned disruptions. These committed teams, which have specialized skills and resources, make sure that decisions are made quickly and actions are organized to maintain business continuity and reduce losses.",
            "buildAndManageDescription": "Identify your organization's [[departments]] to appropriately allocate resources and establish clear communication, ensuring resilience and minimizing potential disruptions during an incident.",
            "sendEmailDescription": "Notify your team by sending an introduction email.",
            "trackDepartmentsDescription": "Once you have built out and assigned your [[departments]], track their progress.",
            "departmentSecondaryDescription": "You have been selected as a team leader for the following [[department]]",
            "departmentSecondaryDescriptionSme": "You have been selected as a subject matter expert for the following [[department]]",
            "departmentIntroductionDescription": "You will be guided through setting up your [[department]] business continuity plan. Below are the steps to complete your plan. You will be provided with preloaded data to build your plan.",
            "departmentDetails": "[[Department]] Details",
            "oosDescription": "Provide for an orderly and predefined assumption of responsibilities during an emergency or event. They should be developed to directly support day-to-day operations.",
            "doaDescription": "Delegations of authority ensure that the [[processes]] of the entity can continue and ensure a rapid response to any emergency situation requiring plan implementation.",
            "departmentResponseTeamsDescription": "The entity has pre-identified continuity of operations teams that are related to carrying out of this plan. The following teams have been designated and are integral to this plan.",
            "buildManageDescription": "Organizational [[processes]] determined to be critical activities are used to identify supporting tasks and resources that must be included in the organization's continuity planning process.",
            "processesTrackDescription": "Once you have built out and assigned your [[processes]], track their progress.",
            "processesTrackDescriptionSme": "Here you are able to track & complete your [[processes]].",
            "departmentInformation": "[[Department]] Information",
            "departmentDescriptionLabel": "[[Department]] Description",
            "departmentAlternateLabel": "[[Team Lead]] Alternate",
            "departmentDescription": "The [[department]] description defines what your [[department]] does. It is a concise, one-line overview of why the organization exists and why your co-op's identity matters.",
            "departmentTeamLeadAlternateDescription": "Designate an alternate [[Team Lead]] for this department to ensure operational continuity in the event of an incident.",
            "myDepartments": "My [[Departments]]",
            "backToMyDepartments": "Back To My [[Departments]]",
            "myDepartmentsSecondaryDescription": "Select from your [[departments]] below to get started.",
            "myDepartmentsDescription": "Once you select a [[department]] you will start going through the process of building your plan for that [[department]]. You will be able to come back to this section at any time to switch to another department if needed.",
            "departmentDescriptionStepSecondary": "Enter your [[department]] description.",
            "departmentDescriptionStepLead": "You may choose to enter a concise, one-line description of your [[department]] or area of the business.  The description is not required to continue and may be added at a later time.",
            "departmentAlternateStepSecondary": "Define your alternate here.",
            "departmentAlternateStepLead": "Select an individual as your Alternate Team Lead who will be available and prepared to assume Team Lead responsibilities in your absence. An Alternate is not required to continue and may be defined at a later time.",
            "selectPlanType": "Select a plan",
            "eopIntroductionSecondaryDescription": "You have been selected as the admin or annex lead for your organization.",
            "eopIntroductionDescription": "You will be guided through setting up your emergency operations plan. Below are the steps to complete your plan. You will be provided with preloaded data to build your plan.",
            "eopSupersessionDescription": "Document the supersession for this document.",
            "eopGeneralQuestionsDescription": "This section will address various general questions related to Emergency Operations Planning (EOP).",
            "eopResponseTeamsDescription": "Response Teams are key to the operations or the organization for carrying out of this plan. The following teams have been designated and are integral to this plan.",
            "mobileBrowserWarning": "At this time, Preparis Planner does not support mobile browsers. Please switch to a desktop browser to continue.",
            "annexBuildMangeDescription": "Organizational annexes for critical activities are used to identify primary and supporting agencies and the tasks they are responsible for.",
            "annexesTrackDescription": "Response Once you have built out and assigned your annexes, track their progress.",
            "disasterRecoveryNoPlanner": "Disaster Recovery",
            "itdrIntroductionSecondaryDescription": "You have been selected as the IT admin for your organization.",
            "itdrIntroductionDescription": "You will be guided through setting up your disaster recovery plan. Below are the steps to complete your plan.",
            "crisisManagementTeam": "Crisis Management Teams",
            "itdrCrisisManagementTeamDescription": "These teams play a crucial role in continuity planning by ensuring that an organization can effectively respond to and recover from various crises or disruptions. These teams are shared among all plan types.",
            "incidentResponseTeams": "Incident Response Teams",
            "itdrIncidentResponseTeamsDescription": "Build your incident response teams that will be needed to put your DR plan into action and manage response.",
            "itdrBuildAndManageDescription": "Identify the key [[technologies]] utilized within your organization to effectively build disaster recovery runbooks.",
            "itdrsendEmailDescription": "Set a due date and then notify your team by sending out an introduction email that.",
            "trackTechnologiesDescription": "Once you have built out and assigned your [[technologies]], track their progress.",
            "itdrTechnologyOwnerSecondaryDescription": "You have been selected as the [[technology]] SME for your organization.",
            "itdrTechnologyOwnerDescription": "You will be guided through setting up your [[technology]]. Below are the steps to complete your plan.",
            "itdrVendorsDescription": "Select the vendors that support or provide services that are required by this [[technology]].",
            "itdrUpstreamDependencies": "Upstream Dependencies",
            "itdrUpstreamDependenciesDescription": "Identify and document upstream dependencies that maintain this [[technology]].",
            "itdrApplicationDetailsAndAttachments": "[[Technology]] Details and Attachments",
            "itdrApplicationDetailsAndAttachmentsDescription": "Document key details and attach supporting documents for this [[technology]].",
            "itdrTaskSets": "Task Sets",
            "itdrTaskSetsDescription": "Define task sets with detailed, step-by-step procedures for the recovery or restoration of the [[technology]].",
            "itdrEstimatedRecoveryTime": "Estimated Recovery Time",
            "itdrEstimatedRecoveryTimeDescription": "Review and adjust the estimated recovery time for [[technology]] restoration, providing a realistic timeline based on calculated assessments."
        },
        "bcDashboard": "Dashboard",
        "bcAdmin": "Admin",
        "admin": {
            "confirmReset": "Are you sure you want to reset your data? It will not be recoverable.",
            "title": "Administration",
            "departmentPlanTemplateFileName": "DepartmentPlanTemplate.docx",
            "downloadTemplateFailed": "Unable to download [[department]] plan template.",
            "saveDepartmentPlanTemplateFailed": "Unable to to save [[department]] plan template",
            "saveDepartmentPlanTemplateSuccess": "[[Department]] plan template nsuccessfully saved.",
            "deleteDepartmentPlanTemplateFailed": "Unable to to delete [[department]] plan template",
            "deleteDepartmentPlanTemplateSuccess": "[[Department]] plan template successfully deleted.",
            "downloadTemplate": "Download Template",
            "uploadTemplate": "Upload Template",
            "deleteTemplate": "Reset Template",
            "confirmDeleteDepartmentPlanTemplate": {
                "header": "Reset [[Department]] Plan Template",
                "body": "Are you sure you want to reset the [[Department]] Plan Template?",
                "text": "Reset"
            },
            "freshStartHeader": "Fresh Start",
            "manageIndustries": "Manage Industries",
            "manageIndustriesDescription": "Create new industries, manage [[department]] and [[process]] templates, and update [[process]] impacts, RTO and strategies. (For Preparis administrators only.)",
            "freshStartDescription": "Would you like to have a fresh start with your Planning? By pressing the Reset button, you will clear all planning data. Be advised that this action will permanently delete your data, rendering it unrecoverable. Exercise caution, as this decision is irreversible.",
            "resetSuccess": "Business Continuity Data Reset Successful",
            "resetFailed": "Business Continuity Data Reset Failed",
            "browseIndustries": "Browse Industries",
            "manageGenericIndustry": "Manage Generic Industry",
            "genericIndustry": "Generic Industry",
            "showMTD": "Show Maximum Tolerable Downtime",
            "showMTDDescription": "Maximum Tolerable Downtime represents the total amount of time the company is willing to accept for a business [[process]] disruption.",
            "enableMTDSuccess": "Allow maximum tolerable downtime enabled",
            "enableMTDFail": "Failed to enable allow maximum tolerable downtime",
            "disableMTDSuccess": "Allow maximum tolerable downtime disabled",
            "disableMTDFail": "Failed to disable allow maximum tolerable downtime",
            "template": {
                "industries": "Industries",
                "industry": "Industry",
                "newIndustryFromGeneric": "New Industry from Generic",
                "newIndustry": "New Industry",
                "renameIndustry": "Rename Industry",
                "copyGenericIndustry": "Copy Generic Industry",
                "industryName": "Industry Name",
                "msgSuccessSaveIndustry": "{{ industryName }} saved successfully",
                "msgFailSaveIndustry": "Error saving industry",
                "duplicateIndustryName": "This industry name is already being used",
                "newIndustryFromCsv": "Import Industry",
                "errorGettingDepartmentsMsg": "Error retrieving [[departments]]",
                "errorGettingProcessesMsg": "Error retrieving [[processes]]",
                "errorGettingProcessDetails": "Error retrieving [[process]] details",
                "errorGettingIndustriesMsg": "Error retrieving industries",
                "errorGettingContinuityStrategy": "Error retrieving continuity strategy",
                "newDepartment": "New [[Department]]",
                "department": "[[Department]]",
                "newProcess": "New [[Process]]",
                "process": "[[Process]]",
                "departments": "[[Departments]]",
                "processes": "[[Processes]]",
                "continuityStrategy": "Continuity Strategy",
                "details": "Details",
                "errorInvalidIndustryFileType": "Invalid file type",
                "noeFileSelectedMsg": "No file selected",
                "uploadIndustrySuccess": "Industry imported successfully",
                "uploadIndustryError": "Error importing industry",
                "uploadIndustryDescription": "Upload an industry configuration file",
                "uploadIndustry": "Import Industry",
                "dropFileInstruction": "Drop a configuration file here",
                "loadingIndustryFile": "Loading file",
                "selectedFileLabel": "Selected File",
                "noFileSelectedLabel": "No file selected",
                "noFileSelectedMsg": "Please select a file"
            },
            "revisionPeriodHeader": {
                "continuityOfOperationPlanning": "COOP Review Cycle",
                "emergencyOperations": "EOP Review Cycle",
                "businessContinuity": "Review Cycle",
                "disasterRecovery": "Review Cycle"
            },
            "revisionPeriodDescription": "Starting new Review Cycle will retain all existing data but reset your planner progress to Not Started. You can create only one future cycle.",
            "btnStartNewRevisionPeriod": "New Review Cycle",
            "lblRevisionPeriodName": "Name",
            "lblRevisionPeriodStartDate": "Start Date",
            "lblRevisionPeriodEndDate": "End Date",
            "strategiesHeader": "Strategies",
            "scenarioBasedStrategies": "Scenario Based Continuity Strategies",
            "locationBasedStrategies": "[[Location]] Based Continuity Strategies",
            "planTypeHeader": "Plan Types",
            "msgPlanTypeUpdatedSuccess": "Plan Type updated",
            "msgPlanTypeUpdatedFail": "Failed to update plan type",
            "msgPlanTypeRequired": "At least 1 plan type is required",
            "msgErrorLoadingCurrentPortalRevision": "Error loading current Review Cycle",
            "spinnerMsgStartingNewRevisionPeriod": "Saving business continuity Review Cycle...",
            "currentPeriodText": "Current cycle: '{{ periodName }}' started {{ periodStartDate }}",
            "nextPeriodText": "Next cycle: '{{ periodName }}' starts {{ periodStartDate }}",
            "msgSuccessCreatingNewRevisionPeriod": "Created new Revision Cycle",
            "msgErrorCreatingNewRevisionPeriod": "Failed to create new Revision Cycle",
            "newRevisionPeriodModal": {
                "title": "New Review Cycle",
                "defaultName": "{{ date }} Review Cycle",
                "msgInvalidDateRange": "Start date must be before end date"
            },
            "bcImpactSeverityDescriptions": {
                "editImpactSeverityDescriptions": "Edit Impact Severity Descriptions",
                "impactSeverityDescriptionsSettingUpdated": "Impact severity descriptions [[setting]] updated",
                "impactSeverityDescriptions": "Impact Severity Descriptions",
                "impactSeverities": "Impact Severities",
                "enableSeverityDescription": "Allow user to provide description for severity for each categories"
            },
            "incidentCommunications": {
                "menu": "Incident Communications",
                "title": "Incident Communications",
                "description": "Please configure these buttons by adding a group to each button. These buttons will be added to each incident and can be selected to send each individual a Conference Call, Email or SMS.",
                "msgLabel": "Incident Communications configuration",
                "msgErrorLoadingSetting": "Error loading Incident Communications configuration",
                "msgErrorDuplicateGroups": "There are duplicate groups selected",
                "confirmRemoveGroup": {
                    "header": "Remove Incident Communication Group",
                    "body": "Are you sure you want to remove the Incident Communication Group?",
                    "text": "Remove"
                },
                "selectPlaceholder": "Select Group",
                "selectedTeamMembers": "Selected [[Team Members]]",
                "noGroupSelected": "No Group Selected"
            },
            "planWatermark": "Plan Watermark",
            "bcPlanWatermarkIsOnSettingUpdated": "BC Plan Watermark Is On updated",
            "visualTimelineOfRto": "Visual Timeline of RTO",
            "rtoDescription": "Recovery Time Objectives defines the largest amount of time that a system resource can remain unavailable before there is an unacceptable impact on other system resources and business [[processes]]. You can define your own Recovery Time Objectives and supply timeline information for each. ",
            "rtoHeader": "Define RTO Intervals",
            "rtoWithMtdHeader": "Define RTO and MTD Intervals",
            "saveRtoErrorMessage": "You have invalid RTO.",
            "saveRto": "Save RTO",
            "saveRtoDescription": "By changing the systems RTOs, all saved [[process]] Impact data will be lost, and this will also disable impacts being preselected as per best practices. Are you sure you want to change your RTOs?",
            "saveImpactCategory": "Save Impact Category",
            "saveImpactCategoryDescription": "By changing the system Impact Categories, all saved [[process]] impact data may be lost. Are you sure you want to save your changes?",
            "cancelHeader": "Unsaved Changes",
            "cancelDescription": "You have unsaved changes. Are you sure you want to cancel?",
            "resetRto": "Reset RTO",
            "resetRtoDescription": "This will reset the system RTOs to their defaults, which will enable impacts values being preselected as per best practices. All custom RTOs and all saved [[process]] impact values will be lost. Are you sure you want to reset your RTOs to the default ranges?",
            "addCategory": "Add Category",
            "editImpactCategories": " Edit Impact Categories",
            "resetImpactCategoriesHeader": "Reset Impact Categories",
            "resetImpactCategoriesMsg": "This will reset the system Impact Categories to their defaults and any custom categories will be removed. Are you sure you want to reset your Impact Categories? ",
            "resetImpactSeverity": "Reset Impact Severity",
            "resetImpactSeverityDescription": "This will reset the Severity Level names to their default values. Are you sure you want to reset to the default severity names? ",
            "confirmDisableBcScenarioBasedStrategy": {
                "header": "Disable Scenario Based Strategy",
                "body": "Are you sure you want to disable BC Scenario Based Strategies?"
            },
            "confirmDisableBcLocationBasedStrategy": {
                "header": "Disable [[Location]] Based Strategy",
                "body": "All strategies associated to the [[location]] will be removed. \n\n Are you sure you want to disable BC [[Location]] Based Strategies?"
            },
            "updateScenarioBasedSettingToastr": {
                "success": "Scenario Based BC Strategy updated.",
                "failed": "Failed to update Scenario Based BC Strategy"
            },
            "updateLocationBasedSettingToastr": {
                "success": "[[Location]] Based BC Strategy updated.",
                "failed": "Failed to update [[Location]] Based BC Strategy"
            },
            "updatingStrategy": "Updating Business Continuity Strategy [[Setting]]",
            "errorInBCTemplateHeader": "Error(s) in the Template",
            "errorInBCTemplateBody": "The template you are attempting to upload has some errors.<br />Please correct the errors within the template and try again or contact technical support.<br />The error: <b>{{error}}</b>",
            "errorValidatingBCTemplateHeader": "Error Validating Template",
            "errorValidatingBCTemplateBody": "At least one [[department]] needs to be configured before you can upload a custom template.",
            "exportIndustryError": "Failed to export industry file",
            "exportTechnologyAnalyticsError": "Failed to export technology analytics file"
        },
        "copyMoveProcessModal": {
            "headerLabel": "Select a Destination and [[Team Lead]] for {{name}}",
            "searchUserLabel": "Search for a [[Team Member]]",
            "currentUserLabel": "Current [[Team Lead]]",
            "resultLabel": "[[Team Members]]",
            "assignButton": "Save",
            "selectADepartment": "Select a [[Department]]"
        },
        "welcome": {
            "welcomeBc": "Welcome, {{userProfileName}}",
            "welcomeManagementDescription": "Let's build your Business Continuity [[Team]]. We've provided a video below to explain what you are about to do.",
            "welcomeTeamLeadDescription": "As a Team Leader, you have been selected to create the business continuity plan for {{functionalAreaName}}. Click on the video below for brief instructions on how to get started with the PreparisPreparis [[Planner]].",
            "errorRetrievingVideo": "Error retrieving video",
            "welcomeTeamMemberDescription": "As a valuable Subject Matter Expert, you have been selected to create the business continuity plan for {{businessProcessName}}.  Click on the video below for brief instructions on how to get started with the Preparis [[Planner]]."
        },
        "buildYourTeam": {
            "buildYourTeam": "Build your [[team]]",
            "buildYourTeamDescription": "Below you will find a list of [[departments]] that are common within your industry. Click on a [[department]] to assign a Team Leader who will be responsible for validating the [[processes]] within that area and add any additional [[departments]] that may be missing.",
            "teamLeads": "[[Team Leads]]",
            "contactTeamLeadsAndAlternates": [
                "Contact [[Team Leads]]",
                "and Alternates"
            ],
            "teamLeadModal": {
                "headerLabel": "Select a [[Team Lead]] for {{name}}",
                "searchUserLabel": "Search for [[Team Lead]]",
                "currentUserLabel": "Current [[Team Lead]]",
                "resultLabel": "[[Team Members]]"
            },
            "teamMembers": "[[Team Members]]",
            "msgSuccessSaveFunctionalArea": "{{functionalAreaName}} successfully saved",
            "msgFailSaveFunctionalArea": "Failed to save [[department]].",
            "msgFailSaveFunctionalAreaPermission": "Your login does not have permission to save this [[department]].",
            "msgFailSaveFunctionalAreaDuplicateName": "An existing [[department]] already has this name.",
            "removePortalFunctionalAreaSuccess": "{{functionalAreaName}} removed",
            "removePortalFunctionalAreaError": "Failed to remove {{functionalAreaName}}",
            "unassignPortalFunctionalAreaSuccess": "{{userName}} unassigned from {{functionalAreaName}}",
            "unassignPortalFunctionalAreaError": "Failed to unassign {{userName}} from {{functionalAreaName}}",
            "assignPortalFunctionalAreaSuccess": "{{userName}} assigned to {{functionalAreaName}}",
            "assignPortalFunctionalAreaSuccessLicenseOverage": "{{userName}} assigned to {{functionalAreaName}} successfully but with License Overage. Please contact your customer success [[team member]].",
            "confirmRemoveFunctionalArea": {
                "header": "Remove [[Department]]",
                "body": "Are you sure you want to remove '{{ functionalAreaName }}'",
                "text": "Remove"
            },
            "removeDepartmentSuccess": "[[Department]] removed successfully",
            "removeDepartmentError": "Error removing [[department]]",
            "confirmRemovePlan": {
                "header": "Remove Plan",
                "body": "Are you sure you want to remove '{{ planName }}'",
                "text": "Remove"
            },
            "newFunctionalArea": "New [[Department]]",
            "renameFunctionalArea": "Rename [[Department]]",
            "newPlan": "New Plan",
            "msgLimitedResult": "Display the top 100",
            "noPermission": "User does not have the proper permissions",
            "titleFunctionalArea": "[[Department]]",
            "functionalAreaDescription": "Below you can edit the name of the [[department]] to closely reflect the business functions within your business. You will also see a list of the business [[processes]] that are associated with this [[department]]. This will provide more insight when building your [[team]]",
            "newFunctionalAreaDescription": "Enter the name of the new [[department]] below and add any applicable [[processes]] in the [[processes]] section. You can also choose to skip the [[processes]] section and allow the responsible Team Leader to add the [[processes]] that are needed at a later time. Just click create to save your entry.",
            "lblFunctionalAreaName": "[[Department]] Name",
            "functionalAreaName": "Enter [[department]] name",
            "lblProcesses": "[[Processes]]",
            "duplicateFunctionalAreaName": "[[Department]] with same name already exists.",
            "duplicateProcessName": "[[Process]] with same name already exists.",
            "validateProcess": "Validate the [[processes]] for {{functionalAreaName}}",
            "validateProcessDescription": "Below is the starter list of the [[processes]] in your area. Remove those that are not your or your [[team]]'s responsibility. Add any other [[processes]] that are missing.",
            "titlePlan": "Plan",
            "planName": "Name",
            "lblPlanName": "Enter plan name",
            "duplicatePlanName": "Plan with same name already exists.",
            "confirmUnassignTeamLead": {
                "header": "Unassign [[Team Lead]]",
                "body": "Are you sure you want to unassign '{{ userName }}' from this [[department]]?",
                "text": "Unassign"
            },
            "assignTeamLead": "Assign [[Team Lead]]",
            "unassignTeamLead": "Unassign [[Team Lead]]",
            "noTeamLeadAssigned": "No [[Team Lead]] Assigned",
            "setDueDate": {
                "header": "Due Date",
                "body": "Some [[departments]] already have a due date, do you want to set this due date {{dueDate}}</b> to all [[departments]]?",
                "allFunctionalAreas": "Yes",
                "newFunctionalAreas": "No",
                "msgConfirmBody": "Are you sure you want to set this new due date <b>{{dueDate}}</b> to all [[departments]]?"
            },
            "msgDueDateRequired": "Due date is required for all [[departments]]",
            "introductoryEmailDisclaimer": "The context will change per user name and [[department]]",
            "welcomeManagementDescription": "Click on the video below for brief instructions on how to get started with the Preparis [[Planner]].",
            "reviewDepartmentsTooltip": "Ensure all the essential [[departments]] in your organization are accounted for. To add a new [[department]] click on the New [[Department]] card.",
            "assignTeamLeaderTooltip": "For each [[department]] displayed below, select a person responsible for the [[departments]]' [[processes]]. To find the appropriate person click on Assign [[Team Lead]] and search their name within the search bar.",
            "isYourTeamCompleteTooltip": "Once you have assigned a [[Team Lead]] to each [[department]] click on next to continue to the last step.",
            "selectDueDateTooltip": "Select an assignment due date. Click on an individual due date to make changes.",
            "reviewEmailTooltip": "Review the email template and make changes as needed by clicking Edit. The applicable email templates will be sent to users based on their status (Existing User or New User). If changes are made, please note that they will apply to all email recipients.",
            "sendAndFinishTooltip": "Let's notify your team by sending them an introductory e-mail. Click on Send & Finish to complete building your Business Continuity Team.",
            "reviewDepartments": "1. Review the [[Departments]]",
            "assignTeamLeader": "2. Assign a [[Team Lead]]",
            "isYourTeamComplete": "3. Confirm your Team is Complete",
            "selectDueDate": "1. Select a Due Date",
            "reviewEmail": "2. Review E-mail",
            "sendAndFinish": "3. Send & Finish",
            "emailDescription": "Any changes made to the emails below will not be saved for future use. If making changes, please ensure both templates are updated accurately. Please note changing any information within the brackets may cause pertaining information to become distorted.",
            "bcmLicenseOverage": "You have reached the limit for Business Continuity Manager. Please contact your customer success [[team member]].",
            "teamLeadLicenseOverage": "You have reached the limit for [[Team Leads]]. Please contact your customer success [[team member]].",
            "teamMemberLicenseOverage": "You have reached the limit for Subject Matter Expert. Please contact your customer success [[team member]].",
            "navigateToSettingDepartment": "Navigate to linked [[Department]] to change [[Planner]] [[Department]] name",
            "manageDepartment": "Manage [[department]]",
            "buildManageSecondaryDescription": "Define your departments here.",
            "buildManageDescription": "A \"[[department]]\" may be an organizational entity, group of entities, or an area of the organization.  Each defined \"department\" should be assigned a Team Lead responsible for defining the department's processes, executing the Impact Analysis, and defining the plan for continued operations.",
            "introductionEmail": "Assign a Due Date and Notify Team Leads",
            "introductionEmailSecondaryDescription": "A due date for completion of the plan development activities must be defined.  You may also send an email (or skip) to the Team Leads announcing their assignment.",
            "introductionEmailSecondaryDescriptionUpdated": "A due date for completion of the plan development activities must be defined.  You may also send an email (or skip) to the Team Leads announcing their assignment.",
            "trackDepartmentSecondaryDescription": "Track and open your team's [[Departments]] here.",
            "disasterRecoveryIntroductionEmailTitle": "Assign a Due Date and Notify [[Technology]] Owners",
            "disasterRecoveryIntroductionEmailDescription": "A due date for completion of the plan development activities must be defined. You may also send an email (or skip) to the owners announcing their assignment."
        },
        "validateProcess": {
            "confirmUnassignTeamMember": {
                "header": "Unassign [[Team Member]]",
                "body": "Are you sure you want to unassign '{{ userName }}' from this [[process]]?",
                "text": "Unassign"
            },
            "confirmUnassignTeamMemberAndAlternate": {
                "header": "Unassign [[Team Member]] and Alternate",
                "body": "Are you sure you want to unassign '{{ userName }}' and '{{ alternateUserName }}' from this [[process]]?",
                "text": "Unassign"
            },
            "confirmRemoveProcess": {
                "header": "Remove [[Process]]",
                "body": "Are you sure you want to remove '{{ processName }}'?",
                "text": "Remove"
            },
            "removeProcessSuccess": "[[Process]] removed successfully",
            "removeProcessError": "Error removing [[process]]",
            "teamMemberModal": {
                "headerLabel": "Select a Subject Matter Expert for {{name}}",
                "searchUserLabel": "Search for a [[Team Member]]",
                "currentUserLabel": "Current Subject Matter Expert",
                "resultLabel": "[[Team Members]]"
            },
            "titleNewProcess": "New [[Process]]",
            "processName": "[[Process]] Name",
            "newProcess": "New [[Process]]",
            "assignTeamMember": "Delegate",
            "unassignTeamMember": "Unassign",
            "noTeamMemberAssigned": "No Delegate Assigned",
            "removeProcess": "Remove [[Process]]",
            "renameProcess": "Rename [[Process]]",
            "titleProcess": "[[Process]]",
            "lblProcessName": "Enter [[process]] name",
            "duplicateProcessName": "[[Process]] with same name already exists.",
            "msgSuccessSaveProcess": "{{processName}} successfully saved",
            "msgFailSaveProcess": "Failed to save {{processName}}.",
            "msgFailSaveProcessPermission": "Your login does not have permission to save this [[process]].",
            "msgFailSaveProcessDuplicateName": "An existing [[process]] already has this name.",
            "removePortalProcessSuccess": "{{processName}} removed",
            "removePortalProcessError": "Failed to remove {{processName}}",
            "unassignProcessUserSuccess": "{{userName}} unassigned from {{processName}}",
            "unassignProcessUserError": "Failed to unassign {{userName}} from {{processName}}",
            "assignProcessUserSuccess": "{{userName}} assigned to {{processName}}",
            "assignPortalProcessSuccessLicenseOverage": "{{userName}} assigned to {{processName}} successfully but with License Overage. Please contact your customer success [[team member]].",
            "assignProcessUserError": "Failed to assign {{userName}} to {{processName}}",
            "AssignmentsDescription": "In order to gauge the impact to our business we need your input, For each [[process]], please consider and then select the most appropriate impact to the overall business if the [[process]] cannot be executed in the time frames listed. Continue your assessment for each of the five impact types. We have made an initial assessment based on our experience and industry best practice.",
            "functionalAreas": "[[Departments]]",
            "noProcessesForDepartment": "No [[processes]] have been defined for this [[department]]",
            "plans": "Plans",
            "teamLead": "[[Team Lead]]",
            "setDueDate": {
                "header": "Due Date",
                "body": "Some [[processes]] already have a due date, do you want to set this due date {{dueDate}} to all [[processes]]?",
                "allProcesses": "Yes",
                "newProcesses": "No",
                "msgConfirmBody": "Are you sure you want to set this new due date {{dueDate}} to all [[processes]]?"
            },
            "msgDueDateRequired": "Due date is required for all [[processes]]",
            "introductoryEmailDisclaimer": "The context will change per user name and [[process]]",
            "reviewGetStarted": "Review and Get Started",
            "trackYourProgress": "Track the Progress",
            "reviewProcess": "1. Review the [[Processes]]",
            "assignExpert": "2. Assign an Expert",
            "reviewGetStartedTooltip": "Review your assignments below along with applicable due dates. To get started, click Start next to the [[department]] of your choosing and being at any time. Once you have clicked Start, you will watch a short introductory video that will provide you with additional instructions.",
            "trackYourProgressTooltip": "Once an assignment is started, you will be able to track the progress for each [[department]]. You can also track the progress of [[processes]] within each [[department]] from this screen.",
            "reviewProcessTooltip": "Ensure all the essential [[processes]] in this [[department]] are accounted for. To add a missing [[process]], click on the New [[Process]] card. To remove any that are not applicable to your [[department]], click the ellipsis on the top right side of the process card, and select Remove.",
            "assignExpertTooltip": "For each [[process]] displayed below, you have been automatically assigned as the SME (subject matter expert). You can reassign any process on the list to other SME's. To do so, click the name within the [[process]] card that you will like to update. To locate the new SME, search their name in the search bar.",
            "isYourTeamCompleteTooltip": "Once you have assigned a Subject Matter Expert to each [[process]], click Next to continue to the last step.",
            "selectDueDateTooltip": "Select an assignment due date. Click on an individual due date to make changes.",
            "sendAndFinishTooltip": "Let's notify your [[team]] by sending them an introductory e-mail. Click on Send & Finish to complete building your Business Continuity [[Team]] of experts.",
            "reviewEmailTooltip": "Review the e-mail template and make changes as needed by clicking the edit button. The applicable e-mail templates will be sent to users based on their status (Existing User or New User). If changes are made, please note that the changes will apply to all recipients receiving the e-mail.",
            "portalProcessLocationModal": {
                "header": "Choose [[Location]]",
                "exceedMaxPortalProcessLocations": "Maximum locations for the [[process]] exceeded",
                "assignPortalProcessLocationError": "failed to assign [[location]] for the [[process]]"
            },
            "portalProcessDependenciesModal": {
                "header": "Choose Dependency",
                "exceedMaxPortalProcessDependencies": "Maximum dependencies for the [[process]] exceeded",
                "assignPortalProcessProcessDependencyError": "Failed to assign dependency for the [[process]]",
                "PortalProcessProcessDependencySaveSuccessfully": "[[Process]] dependency save successfully",
                "PortalProcessProcessDependencyDeletedSuccessfully": "[[Process]] dependency deleted successfully",
                "PortalProcessProcessDependencyDeletedUnSuccessfully": "Failed to delete [[process]] dependency",
                "currentSelectedProcessDependencies": "Current Selected [[Process]] Dependencies",
                "availableProcessDependencies": "Available [[Process]] Dependencies",
                "addDependencies": "Add Dependencies"
            },
            "removeLocationConfirmationModal": {
                "header": "Remove {{locationName}}",
                "body": "Are you sure you want to remove this [[location]] from this [[process]]? All existing assignments for {{locationName}} will be lost."
            },
            "removeDependencyConfirmationModal": {
                "header": "Remove {{dependencyName}}",
                "body": "Are you sure you want to remove this dependency from this [[process]]?"
            },
            "removeContinuityStrategyConfirmationModal": {
                "header": "Remove Continuity Strategy",
                "body": "Are you sure you want to remove this continuity strategy?"
            },
            "dependenciesPageHeader": "What are the dependent [[processes]] for {{ name }}?",
            "dependenciesPageDescription": "Select all the [[processes]] in your or other [[departments]] that must be executed in order for this [[process]] to be successfully completed. ",
            "locationsPageHeader": "Where is this [[process]] used?",
            "locationsPageDescription": "Select the locations where this [[process]] is being executed so that continuity strategies can be assigned.",
            "selectProcessSecondaryDescription": "Define your [[processes]] here.",
            "selectProcessDescriptionBC": "A business process defines a function or set of functions executed as part of day-to-day business operations in your department or area of the business.  You may \"assign\" responsibility for a Process to a subject matter or you may retain primary responsibility.",
            "selectProcessDescriptionCOOP": " An essential function defines a function or set of functions executed as part of day-to-day operations in your department or organization.  You may \"assign\" responsibility for an Essential Function to a subject matter or you may retain primary responsibility.",
            "tracKProcessesSecondaryDescription": "Track your [[Processes]] here.",
            "tracKProcessesDescription": "Track and complete your [[Departments]] processes.",
            "noPrimary": "No Primary Assigned",
            "noAlternate": "No Alternate Assigned",
            "addPrimary": "Assign Primary",
            "addAlternate": "Assign Alternate",
            "unAssignBothUserSuccess": "{{ userName }} and {{ alternate }} were unassigned from {{ processName }}",
            "unAssignBothProcessUserError": "Failed to unassign {{ userName }} and {{ alternate }} from {{ processName }}"
        },
        "impact": {
            "impactTitle": "What is the impact on <b>{{name}}</b>?",
            "impactDescription": "In order to gauge the impact on your business, we need your input. We have provided an initial assessment based on our experience and industry best practices. Review our selections and make adjustments as needed by selecting the appropriate impact on your overall business if the process cannot be completed in the time frames listed. Continue the assessment for each of the six impact categories until they are all completed to obtain your Recovery Time Objective in the next section.",
            "category": {
                "financial": "Financial Impact",
                "financialSeverityLegend": {
                    "insignificant": "Potential reduction to cash on hand",
                    "significant": "Potential reduction in available operating capital",
                    "catastrophic": "Potential to reduce availability to continue operations"
                },
                "memberImpact": "[[Member]] Impact",
                "clientImpact": "[[Client]] Impact",
                "customer": "[[Member]], [[Customer]], [[Client]] Impact",
                "customerImpact": "[[Customer]] Impact",
                "customerSeverityLegend": {
                    "insignificant": "[[Member]], [[Customer]], [[Client]] Impact - insignificant",
                    "significant": "[[Member]], [[Customer]], [[Client]] Impact - significant",
                    "catastrophic": "[[Member]], [[Customer]], [[Client]] Impact - catastrophic"
                },
                "employee": "Employee Morale and Retention Impact",
                "employeeSeverityLegend": {
                    "insignificant": "Employee Morale and Retention Impact - insignificant",
                    "significant": "Employee Morale and Retention Impact - significant",
                    "catastrophic": "Employee Morale and Retention Impact - catastrophic"
                },
                "reputational": "Brand or Reputational Impact",
                "reputationalSeverityLegend": {
                    "insignificant": "Brand or Reputational Impact - insignificant",
                    "significant": "Brand or Reputational Impact - significant",
                    "catastrophic": "Brand or Reputational Impact - catastrophic"
                },
                "operational": "Delivery of Services Impact",
                "operationalSeverityLegend": {
                    "insignificant": "Delivery of Services Impact - insignificant",
                    "significant": "Delivery of Services Impact - significant",
                    "catastrophic": "Delivery of Services Impact - catastrophic"
                },
                "compliance": "Compliance Regulatory Impact",
                "complianceSeverityLegend": {
                    "insignificant": "Compliance Regulatory Impact - insignificant",
                    "significant": "Compliance Regulatory Impact - significant",
                    "catastrophic": "Compliance Regulatory Impact - catastrophic"
                }
            },
            "severity": {
                "none": "No Impact",
                "insignificant": "Insignificant",
                "significant": "Significant",
                "catastrophic": "Catastrophic",
                "noDescription": "No description"
            },
            "validationInfo": "Please review each impact categories before proceeding to the next step",
            "saveErrorMsg": "Error saving impact analysis",
            "impactAnalysis": "Impact Analysis",
            "impactCategories": "Impact Categories",
            "viewImpactCategories": "1. View Impact Categories",
            "reviewOurSelections": "2. Review Our Selections",
            "makeChanges": "3.  Make Changes",
            "viewImpactCategoriesTooltip": "Impact Categories are impact areas that can affect your business during a crisis. Consider the impact on each Impact Category shown if the business [[process]] cannot be completed on time.",
            "reviewOurSelectionsTooltip": "Based on our experience and industry best practice, we have provided an initial assessment for each Impact Category. This assessment is based on the individual [[process]] and the impact on the overall business. Review our selections for each category and the severity levels selected for each impact day.",
            "makeChangesTooltip": "Make adjustments as needed by reviewing each Impact Category and selecting the appropriate severity level for each impact day if the [[process]] cannot be completed. Once you have completed each category, click next to obtain your Recovery Time Objective."
        },
        "rto": {
            "rtoTitle": " What is the <b>Recovery Time Objective</b> for <b>{{processName}}</b>?",
            "rtoDescription": "Based on the selections you provided in the Impact Analysis your recovery time for <b>{{processName}}</b> is shown below. To easily edit your impact selections click on the name of the Impact Category next to the graph below to make changes. You can also manually update the RTO on the right side of the screen.",
            "rto": "RTO",
            "returnToImpactAnalysis": "Return to Impact Analysis",
            "missingRto": "Missing RTO",
            "countOfProcesses": "Count of [[Processes]]",
            "recoveryTimeObjective": "1. Recovery Time Objective",
            "impactAnalysis": "2. Impact Analysis",
            "impactCategories": "3. Impact Categories",
            "recoveryTimeObjectiveTooltip": "Based on the selections you provided in the previous section your Recovery Time Objective (RTO) is shown below. You can manually update your RTO by clicking on the image.",
            "impactAnalysisTooltip": "The Impact Analysis graph is a visual representation of the Impact Categories and their severity levels throughout each impact day. These levels were selected in the previous section.",
            "impactCategoriesTooltip": "If you would like to make changes to any of the Impact Category data shown on the Impact Analysis graph, you may do so by clicking on the Impact Category name."
        },
        "mtd": {
            "mtd": "MTD"
        },
        "processAnalysis": {
            "AssignmentsDescription": "In order to gauge the impact to our business we need your input, For each [[process]], please consider and then select the most appropriate impact to the overall business if the [[process]] cannot be executed in the time frames listed. Continue your assessment for each of the five impact types. We have made an initial assessment based on our experience and industry best practice.",
            "task": "Task",
            "teamMember": "[[Team Member]]",
            "process": "[[Process]]"
        },
        "criticalDates": {
            "criticalDatesTitle": "What are the critical dates for {{ name }}?",
            "criticalDateSecondary": "A critical date is a specific, recurring date or time period within a process that requires heightened attention or may necessitate an increase in the Recovery Time Objective (RTO) during an incident.",
            "criticalDatesDescription": "In order to gauge the priority of this [[process]], during a potential disruption, it is helpful to know when it is typically performed. Select a critical date category that applies to your business [[process]] and edit any dates if applicable. You may also add a new critical date if it is not provided in the list below.",
            "newCriticalDate": "New Critical Date",
            "name": "Critical Date Name",
            "frequency": "Frequency",
            "frequencyTypes": {
                "weekly": "Weekly",
                "biWeekly": "Bi-weekly",
                "monthly": "Monthly",
                "quarterly": "Quarterly",
                "semiAnnually": "Semi-annually",
                "annually": "Annually",
                "oneTimeEvent": "Once",
                "semiMonthly": "Semi-monthly"
            },
            "atTheEndOfFrequencyTypes": {
                "weekly": "At the end of the week",
                "biWeekly": "At the end of two weeks",
                "monthly": "At the end of the month",
                "quarterly": "At the end of the quarter",
                "semiAnnually": "At the end of June and December",
                "annually": "At the end of the year",
                "oneTimeEvent": "Once"
            },
            "onSpecificDayFrequencyTypes": {
                "weekly": "Every {{ datePartDesc }}",
                "biWeekly": "Every 2 weeks on {{ datePartDesc }}",
                "monthly": "On the {{ datePartDesc }}",
                "quarterly": "Every 3 months on the {{ datePartDesc }}",
                "semiAnnually": "Every 6 months on the {{ datePartDesc }}",
                "annually": "On {{ datePartDesc }}",
                "oneTimeEvent": "On {{ datePartDesc }}"
            },
            "beginningOn": "Beginning on",
            "dayOfWeek": "Day of Week",
            "beginningOnDate": "Beginning on {{ dateFormat }}",
            "sinceDate": "Since {{ dateFormat }}",
            "specificDate": "Specific Date",
            "lastDayOf": "Last Day of...",
            "firstHalfOfMonth": "1st And The 15th Of Each Month",
            "secondHalfOfMonth": "15th And The Last Day Of Month",
            "plusOrMinus": "Plus or minus a number of days before or after",
            "nextOccurrence": "Next Occurrence",
            "nextOccurrencePhrase": "Between {{startDate}} and {{endDate}}",
            "addEditModal": {
                "addCriticalDate": "Add Critical Date",
                "editCriticalDate": "Edit Critical Date",
                "description": "Below you can edit the name of the critical date and configure the frequency, type and beginning date.",
                "duplicateCriticalDatesName": "Critical Date with same name already exists",
                "criticalDateName": "Critical Date Name",
                "exampleCriticalDate": "Example Critical Date"
            },
            "confirmRemove": {
                "header": "Remove Critical Date",
                "body": "Are you sure you want to remove '{{ name }}'?",
                "text": "Remove"
            },
            "confirmDeselect": {
                "header": "Deselect Critical Date",
                "body": "Are you sure you want to deselect '{{ name }}'?",
                "text": "Deselect"
            },
            "saveSuccessMsg": "Critical dates saved successfully",
            "saveErrorMsg": "Error saving critical dates",
            "loadingErrorMsg": "Error while loading critical date info",
            "clickToAdd": "Click to add",
            "nextOccurrenceMsg": "Between {{ beginning }} and {{ end }}",
            "daysOfWeek": {
                "mondayAbbr": "Mon",
                "tuesdayAbbr": "Tues",
                "wednesdayAbbr": "Wed",
                "thursdayAbbr": "Thurs",
                "fridayAbbr": "Fri",
                "saturdayAbbr": "Sat",
                "sundayAbbr": "Sun"
            },
            "examples": {
                "seeExampleWeeklyPayroll": "See example: Weekly Payroll",
                "seeExampleMonthlyRent": "See example: Monthly Rent Payment",
                "seeExampleOnetimeInvoice": "See example: One-time Invoice",
                "payroll": "Payroll",
                "rentPayment": "Rent Payment",
                "invoice1320": "Invoice 1320"
            },
            "criticalDates": "Critical Dates",
            "viewExamples": "1. View Examples",
            "createCriticalDates": "2. Create New Critical Dates",
            "reviewCriticalDates": "3. Review your Critical Dates",
            "viewExamplesTooltip": "During a disruption, it is helpful to know when this [[process]] is performed. It is also important to list any other critical dates to continue this business [[process]] during a potential disruption. We have provided three examples for you to view",
            "createCriticalDatesTooltip": "To add a new Critical Date, you can copy an example card or create a new card.",
            "reviewCriticalDatesTooltip": "Have you captured all the necessary dates for this business [[process]]? If you have captured all the necessary Critical Dates, click Next to continue creating the continuity strategy for this business [[process]].",
            "errorLoadingCriticalDates": "Failed to load Critical Dates",
            "month": {
                "1": "January",
                "2": "February",
                "3": "March",
                "4": "April",
                "5": "May",
                "6": "June",
                "7": "July",
                "8": "August",
                "9": "September",
                "10": "October",
                "11": "November",
                "12": "December"
            },
            "subscribe": "Subscribe",
            "subscribeToCalendar": "Subscribe to calendar",
            "subscribeCalendarDescriptions": "<p>You can add an iCal link to pretty much any calendaring program, such as Outlook, Google Calendar or Apple Calendar.</p>\n<p>How to Add an iCal Link to the Outlook Client:</p>\n<ul>\n    <li>Open Outlook and go to your calendar</li>\n    <li>In the sidebar, right-click Other Calendars and then select Add Calendar > From Internet</li>\n    <li>In the box that pops up, paste your iCal link and click \"OK.\"</li>\n    <li>The shared calendar will now appear under \"Other Calendars.\"</li>\n</ul>",
            "subscriptionLinkCopied": "iCal link copied to clipboard"
        },
        "continuityStrategy": {
            "continuityStrategyTitle": "Continuity Strategy for {{ name }}",
            "continuityStrategyForDesc": "The Continuity Strategy for {{ name }} is to",
            "responsibleEmployeesTemporaryLocationDesc": "Employees authorized to approve the temporary workspace",
            "responsibleEmployeesMoveTheWorkDesc": "Employees Who Will Contact The Vendor",
            "responsibleEmployeesSuspendDesc": "Employees Authorized To Suspend The [[Process]] And Contact The Vendor",
            "designatedEmployeesWorkFromHomeDesc": "Employees who will be needed",
            "designatedEmployeesTemporaryLocationDesc": "Employees designated to work at the temporary workspace",
            "equipmentNeededLabel": "Select the [[equipment]] that each employee will need or add any that are missing",
            "selectedEquipments": "[[Equipments]] that each employee will need",
            "saveSuccessMsg": "Continuity Strategy saved",
            "saveErrorMsg": "Failed to save Continuity Strategy",
            "saveEquipmentSuccessMsg": "[[Equipment]] {{names}} added",
            "saveEquipmentErrorMsg": "Failed to add [[equipment]] {{names}}",
            "removeEquipmentSuccessMsg": "[[Equipment]] {{names}} removed",
            "removeEquipmentErrorMsg": "Failed to remove [[equipment]] {{names}}",
            "saveVendorsSuccessMsg": "Vendors {{names}} added",
            "saveVendorSuccessMsg": "Vendor {{names}} added",
            "saveVendorErrorMsg": "Failed to add vendor {{names}}",
            "saveVendorsErrorMsg": "Failed to add vendors {{names}}",
            "removeVendorSuccessMsg": "Vendor {{names}} removed",
            "removeVendorErrorMsg": "Failed to remove vendor {{names}}",
            "saveAssigneeSuccessMsg": "Authorized employee {{names}} added",
            "saveAssigneeErrorMsg": "Failed to add authorized employee {{names}}",
            "removeAssigneeSuccessMsg": "Authorized employee {{names}} removed",
            "removeAssigneeErrorMsg": "Failed to remove authorized employee {{names}}",
            "saveAssigneesSuccessMsg": "Authorized employees {{names}} added",
            "saveAssigneesErrorMsg": "Failed to add authorized employees {{names}}",
            "removeAssigneesSuccessMsg": "Authorized employees {{names}} removed",
            "removeAssigneesErrorMsg": "Failed to remove authorized employees {{names}}",
            "saveEmployeeSuccessMsg": "Designated employee {{names}} added",
            "saveEmployeeErrorMsg": "Failed to add designated employee {{names}}",
            "removeEmployeeSuccessMsg": "Designated employee {{names}} removed",
            "removeEmployeeErrorMsg": "Failed to remove designated employee {{names}}",
            "saveEmployeesSuccessMsg": "Designated employees {{names}} added",
            "saveEmployeesErrorMsg": "Failed to add designated employees {{names}}",
            "removeEmployeesSuccessMsg": "Designated employees {{names}} removed",
            "removeEmployeesErrorMsg": "Failed to remove designated employees {{names}}",
            "saveLocationSuccessMsg": "Location {{names}} updated",
            "saveLocationErrorMsg": "Failed to update [[location]] {{names}}",
            "removeLocationSuccessMsg": "Location {{names}} removed",
            "removeLocationErrorMsg": "Failed to remove [[location]] {{names}}",
            "saveTechnologySuccessMsg": "[[Technology]] or [[application]] {{names}} added",
            "saveTechnologiesSuccessMsg": "[[Technologies]] or [[applications]] {{names}} added",
            "saveTechnologyErrorMsg": "Failed to add [[technology]] or [[application]] {{names}}",
            "saveTechnologiesErrorMsg": "Failed to add [[technologies]] or [[applications]] {{names}}",
            "removeTechnologySuccessMsg": "[[Technology]] or [[application]] {{names}} removed",
            "removeTechnologyErrorMsg": "Failed to remove [[technology]] or [[application]] {{names}}",
            "saveInstructionsSuccessMsg": "Additional instructions saved",
            "saveInstructionsErrorMsg": "Failed to save additional instructions",
            "saveQuestionSuccessMsg": "additional question saved",
            "saveQuestionErrorMsg": "Failed to save additional question",
            "loadErrorMsg": "Error loading Continuity Strategy",
            "missingRtoImpactErrorMsg": "Before assigning the continuity strategy, please assign the RTO",
            "vendorsSelected": "{0} vendors selected",
            "selectAVendor": "Select a vendor",
            "technologiesSelected": "{0} [[technologies]] selected",
            "selectATechnology": "Select a [[technology]]",
            "strategyTypes": {
                "workFromAnywhere": "Work from Anywhere",
                "temporaryLocation": "Temporary Workspace",
                "moveTheWork": "Move the Work",
                "suspend": "Suspend",
                "9c004e84-4eb4-44f1-a008-cd33f5211014": "Work from Anywhere",
                "704acb8f-ed9b-4f11-ab37-0f0139895d5b": "Temporary Workspace",
                "e56b1600-219c-4985-b8fa-2b31228d201c": "Move the Work",
                "984817b1-c6ee-4c5e-ace7-e558843bdfcb": "Suspend"
            },
            "equipmentTypeModal": {
                "title": "[[Equipment]] Type",
                "desc": "Add or edit an [[equipment]] type to be used for this continuity strategy",
                "typeNamePlaceholder": "[[Equipment]] Type Name",
                "equipments": "[[Equipments]]",
                "equipmentInUseError": "[[Equipment]] is assigned to an active continuity strategy",
                "deleteEquipmentHeader": "Remove [[Equipment]]",
                "deleteEquipmentMessage": "Are you sure you want to remove this [[equipment]]?",
                "duplicateEquipmentNameError": "Duplicate [[equipment]] name",
                "systemEquipmentTypeEditMessage": "System [[equipment]] types cannot be edited."
            },
            "searchForVendor": "Search for vendor",
            "vendorModal": {
                "title": "Vendor",
                "desc": "Add or edit vendor to be used for this continuity strategy",
                "typeNamePlaceholder": "Vendor Name",
                "contactName": "Contact Name",
                "contactNamePlaceholder": "First and Last Name",
                "contactPhoneNumber": "Contact Phone Number",
                "contactPhoneNumberPlaceholder": "(123) 456-7890",
                "additionalNotes": "Additional Notes",
                "additionalNotesPlaceholder": "Instructions for contacting the vendor",
                "deleteVendorHeader": "Remove Vendor",
                "deleteVendorMessage": "Are you sure you want to remove this vendor?",
                "addVendor": "Add Vendor",
                "vendors": "Vendors",
                "vendorInUseError": "Vendor is assigned to an active plan",
                "duplicateVendorNameError": "Duplicate vendor name",
                "vendorAddress": "Vendor Address",
                "contractStartDate": "Contract Start Date",
                "contractExpirationDate": "Contract Expiration Date",
                "bulkUploadVendors": "Bulk Upload Vendors",
                "bulkUploadContacts": "Bulk Upload Contacts"
            },
            "agencyModal": {
                "title": "Agency",
                "desc": "Add or edit agency to be used for this continuity strategy",
                "typeNamePlaceholder": "Agency Name",
                "contactName": "Contact Name",
                "contactNamePlaceholder": "First and Last Name",
                "contactPhoneNumber": "Contact Phone Number",
                "contactPhoneNumberPlaceholder": "(123) 456-7890",
                "additionalNotes": "Additional Notes",
                "additionalNotesPlaceholder": "Instructions for contacting the agency",
                "deleteAgencyHeader": "Remove Agency",
                "deleteAgencyMessage": "Are you sure you want to remove this agency?",
                "addAgency": "Add Agency",
                "agencies": "Agencies",
                "agencyInUseError": "Agency is assigned to an active continuity strategy",
                "duplicateAgencyNameError": "Duplicate Agency name",
                "agencyAddress": "Agency Address",
                "bulkUploadAgencies": "Bulk Upload Agencies",
                "bulkUploadContacts": "Bulk Upload Contacts",
                "new": "Add New Agency",
                "agencyType": "Agency Type",
                "selectType": "Select an agency type",
                "rolesFilter": "Roles Filter"
            },
            "agencyAssignModal": {
                "assignAgency": "Assign Agency",
                "assignLocation": "Assign Location",
                "resultLabel": "Select Agencies",
                "resultLabelLocation": "Select Location",
                "search": "Search here",
                "currentAgenciesLabel": "Current Agencies",
                "currentAgency": "Current Agency",
                "resultLabelSingle": "Select Agency",
                "assignButton": "Assign"
            },
            "confirmRemoveVendor": {
                "header": "Remove Vendor from [[Process]]",
                "body": "Are you sure that you want to remove this vendor from this [[process]]?",
                "text": "Remove"
            },
            "searchForTechnology": "Search for [[application]] or [[technology]]",
            "confirmRemoveTechnology": {
                "header": "Remove [[Application]] or [[Technology]] from [[Process]]",
                "body": "Are you sure that you want to remove this [[application]] or [[technology]] from this [[process]]?",
                "text": "Remove"
            },
            "technologyModal": {
                "title": "[[Technology]] or [[Application]]",
                "desc": "Select any [[application]] or [[technology]] that will be needed or add any that are missing",
                "deleteTechnologyHeader": "Remove [[Technology]]",
                "deleteTechnologyMessage": "Are you sure you want to remove this [[technology]]?",
				"technologies": "[[Technologies]]",
                "technologyInUseError": "[[Technology]] is assigned to an active plan",
                "technology": "[[Technology]]",
                "duplicateTechnologyNameError": "Duplicate [[technology]] name",
                "seconds": "Seconds",
                "minutes": "Minutes",
                "hours": "Hours",
                "days": "Days",
                "second": "Second",
                "minute": "Minute",
                "hour": "Hour",
                "day": "Day",
                "selectRPOTime": "Select Time",
                "bulkUpload": "Bulk Upload",
                "manageTechnologyTypes": "Manage [[Technology]] Types",
                "addTechnology": "Add  New [[Technology]]",
                "typeNamePlaceholder": "[[Technologies]]",
                "technologyType": "[[Technology]] Type",
                "technologyTypes": "[[Technology]] Types",
                "technologyUsed": "[[Technology]] Used",
                "technologyTypesTitle": "Manage your [[technology]] types here",
                "tier": "Tier",
                "technologyTypeDeleteHeader": "Delete [[Technology]] Type",
                "technologyTypeDeleteBody": "Are you sure you want to delete this [[Technology]] Type?",
                "technologyTypeSavedSuccessfully": "The [[Technology]] Type has been saved successfully",
                "technologyTypeSavedUnSuccessfully": "The [[Technology]] Type has been saved Unsuccessfully",
                "technologyTypeDeletedSuccessfully": "The [[Technology]] Type has been deleted successfully",
                "technologyTypeDeletedUnSuccessfully": "The [[Technology]] Type has been deleted Unsuccessfully"
            },
            "instructionsModal": {
                "title": "Additional Instructions",
                "instructionsPLaceHolder": "Enter additional instructions"
            },
            "addEmployee": "Add Employee",
            "pickOrAddVendor": "Select a vendor or add a new vendor from the list",
            "pickOrAddTechnology": "Select any [[application]] or [[technology]] that will be needed or add any that are missing",
            "pickOrAddLocation": "Select a [[location]] or add a new location from the list.",
            "searchForLocationPlaceholder": "Temporary Workspace [[Location]]",
            "externalLocationModal": {
                "title": "Workspace Location",
                "desc": "Add or edit a temporary workspace location"
            },
            "companyLocation": "Company Location",
            "vendorLocation": "Temporary Location",
            "addVendor": "Add Vendor",
            "confirmRemoveAssignee": {
                "header": "Remove Employee from [[Process]]",
                "body": "Are you sure that you want to remove this employee from this [[process]]?",
                "text": "Remove"
            },
            "assigneeModal": {
                "headerLabel": "Select an Employee",
                "searchUserLabel": "Search for an Employee",
                "currentUserLabel": "Currently Assigned Employees",
                "resultLabel": "[[Team Members]]"
            },
            "managerModal": {
                "headerLabel": "Select a Manager",
                "searchUserLabel": "Search for a Manager",
                "currentUserLabel": "Currently Assigned Manager",
                "resultLabel": "Managers"
            },
            "incidentManagement": {
                "headerLabel": "Select an Employee",
                "searchUserLabel": "Search for an Employee",
                "currentUserLabel": "Currently Selected Employees",
                "customCurrentUserLabel": "Currently Selected Employees",
                "resultLabel": "Employees",
                "assignButton": "Select"
            },
            "continuityStrategy": "Continuity Strategy",
            "vendorAlreadySelected": "Vendor already selected",
            "selectedVendors": "Selected Vendors",
            "technologyAlreadySelected": "[[Application]] or [[Technology]] already selected",
            "selectedTechnologies": "Selected [[Applications]] ot [[Technologies]]",
            "selectedLocation": "Selected [[Location]]",
            "continuityStrategiesByRto": "Continuity Strategies by RTO",
            "strategy": "Strategy",
            "missingRequiredFields": "Please review the missing fields before continuing",
            "continuityStrategyInstructions": "To complete the Business Continuity Plan for this [[process]], select a continuity strategy below, and complete the applicable sections. Once completed, click Done to finalize this business [[process]].",
            "lblAdditionalInstructions": "Additional Instructions",
            "noAdditionalInstructions": "No additional instructions",
            "updateInstructions": "Update Instructions",
            "uploadDocument": "Upload Document",
            "noAttachments": "No Attachments",
            "lblAdditionalQuestions": "Additional Information",
            "noAdditionalQuestions": "No additional Information",
            "createStrategy": "Create Strategy",
            "strategyCreated": "Strategy created",
            "strategyDeleted": "Strategy deleted"
        },
        "noAssignedFunctionalArea": "No assigned [[departments]]",
        "noAssignedProcess": "No assigned [[process]]",
        "searchUsers": "Enter name, role, [[department]] or group to search",
        "searchDependencies": "Enter name or [[department]] to search",
        "noAssignedUserError": "Please make a selection before continuing",
        "sendIntroductoryEmail": "Send an introductory e-mail",
        "sendTeamLeadEmailTitle": "Now that you have created your [[team]], let's send them an e-mail. A template e-mail has been provided for your review on the right side of the screen. When you are ready to send the e-mail, assign a due date, and click on Send & Finish.",
        "sendTeamMemberEmailTitle": "Now that you have created your [[team]], let's send them an e-mail. A template e-mail has been provided for your review on the right side of the screen. When you are ready to send the e-mail, assign a due date, and click on Send & Finish.",
        "titleAssignments": "{{userProfileName}} Assignments",
        "functionalAreas": "[[Departments]]",
        "titleNavigatePlan": "Business Continuity Plans",
        "introductoryVideo": "Introductory Video",
        "plans": {
            "plans": "Plans",
            "generatePlansByDepartment": "Generate Plans By [[Department]]",
            "generatePlansByLocation": "Generate Plans By [[Location]]",
            "generatePlanCompanyWideTooltip": "Save to Documents & Download",
            "lastGeneratedDate": "Last Generated Date",
            "planTemplate": "[[Department]] Plan Template",
            "generatePlans": "Generate [[Plans]]",
            "errGeneratePlanCanceled": "Plan generation was canceled.",
            "errorDownloadingPlan": "An unexpected error occurred downloading the plan.",
            "errGeneratePlanNoTemplate": "Cannot start [[plan]] generation. [[Plan]] template is not assigned.",
            "noPlans": "No plans",
            "generateDepartmentPlans": "Generate All [[Department]] Plans",
            "generateTechnologyPlans": "Generate All [[Technology]] Plans",
            "generateLocationPlans": "Generate [[Location]] Plans",
            "generateCompanyPlan": "Generate Company Plan",
            "continuityOfOperationPlanning": "COG Plan",
            "emergencyOperations": "EOP Plan",
            "continuityOfGovernment": "Continuity of Government (COG)",
            "businessContinuity": "Company Plan",
            "disasterRecovery": "Company Plan",
            "templateName": "Template Name",
            "goToTemplates": "Go To Templates"
        },
        "functions": "Functions",
		"essentialFunctions":"Essential Functions",
        "processes": "[[Processes]]",
        "manageFunctionalAreas": "Manage [[Departments]]",
        "viewFunctionalAreas": "View [[Departments]]",
        "summary": "Summary",
        "titleOverview": "Team Overview",
        "overviewDescription": "Below is a list of the [[departments]] with their respective Team Leaders who are now in charge of completing their individual sections of the continuity plan. If you have assigned yourself as a Team Leader for a [[department]], you can begin at any time by clicking start. Otherwise, you can view the progress of each individual Department by viewing the status section.",
        "overviewDescriptionHeader": "Department's Description, Mission Statement or Vision",
        "descriptionSavedSuccessfully": "Description saved successfully",
        "descriptionSavedUnsuccessfully": "Description saved unsuccessfully",
        "processesHeader": "[[Processes]]",
        "teamLeadDescription": "Below is a list of assigned [[departments]] that you are an expert in. Click on the start button to begin validating the [[processes]] within the assigned [[department]] and delegate any that are needed.",
        "reviewAndManage": "1. Review and Manage",
        "trackTeamsProgress": "2. Track your [[Team]]'s Progress",
        "reviewAndManageTooltip": "Review your [[departments]] and the Team Leaders previously selected. Make changes when needed by clicking the Manage [[Departments]] button.",
        "trackTeamsProgressTooltip": "Track your [[team]]'s business continuity planning progress. When assignments are done, a checkmark will appear",
        "teamLead": "[[Team Lead]]",
        "sendAndFinish": "Send & Finish",
        "lblSelectDueDate": "Select Due Date",
        "lblDueDate": "Due Date",
        "exampleEmail": "Example Email",
        "msgWelcomeAgilityPlanner": "Welcome to the Preparis [[Planner]]! Below is an overview of the [[team]] you created. In this section you can do the following:",
        "msgWelcomeAgilityPlannerTeamLead": "Welcome to the Preparis [[Planner]]! Below is an overview of your assignments. In this section you can do the following:",
        "msgWelcomeAgilityPlannerTeamMember": "Welcome to the Preparis [[Planner]]! Below is an overview of your assignments. In this section you can do the following:",
        "reviewAssignmentsTeamMember": "1. Review Assignments",
        "getStartedTeamMember": "2. Get Started",
        "reviewAssignmentsTeamMemberTooltip": "Review your assigned [[processes]] below along with applicable due dates. The [[processes]] listed in this section have been assigned to you as the Subject Matter Expert by your Team Leader. ",
        "getStartedTeamMemberTooltip": "Begin your assignments at any time by clicking on the Start button for a particular [[process]]. Once you have clicked on the Start button you will be navigated to a short introductory video that will provide you with additional instructions.",
        "msgFailSendEmail": "Failed to send introductory emails",
        "msgSuccessSendEmail": "Email sent to {{name}}",
        "msgDueDateSaved": "Due date updated",
        "msgSettingDueDate": "Updating due date",
        "msgSettingDueDates": "Updating due dates",
        "msgResendEmail": "Sending Email...",
        "lblSentEmailOn": "Sent on",
        "emailTemplate": {
            "userName": "John Doe",
            "departmentNames": "Human Resources",
            "processNames": "Payroll",
            "loginName": "john.doe@preparis.com",
            "placeHolders": {
                "recipientName": "Recipient Name",
                "senderName": "Sender Name",
                "departmentNames": "[[Department]] Names",
                "processNames": "[[Process]] Names",
                "portalUrl": "Preparis Recover Portal Link",
                "activateUrl": "User Account Activation Link",
                "userName": "User Account Login",
                "technologyNames": "[[Technology]] Names"
            },
            "annexNames": "following annexes",
            "technologyNames": "following technologies"
        },
        "status": {
            "none": "Not Started",
            "inProcess": "In Progress",
            "complete": "Complete",
            "new": "New"
        },
        "sentDate": "Sent Date",
        "percentComplete": "Percent Complete",
        "dashboard": {
            "overallProgressAndIssueTracking": "Overall Progress and [[Issue]] Tracking",
            "progressByDepartment": "Progress by [[Department]]",
            "progressByProcess": "Progress by [[Process]]",
            "bcDashboardTitle": "Business Continuity Plan Dashboard",
            "quickView": "Quick View",
            "assignedTeamMember": "Assigned [[Team Member]]",
            "progress": "Progress",
            "errorLoadingProcesses": "Error loading [[processes]]",
            "viewAll": "View All",
            "bcAnalytics": "Analytics",
            "authorizedEmployees": "Authorized Employees",
            "affectedEmployees": "Affected Employees",
            "temporaryWorkspaceAddress": "Temporary Workspace Address",
            "errorLoadingData": "Error loading dashboard data",
            "issues": "[[Issues]]",
            "blank": "Blank",
            "processGlobalSearchPlaceholder": "Search across all columns",
            "selectableColumns": "Selectable Columns",
            "plannerDashboard": "Planner Dashboard",
            "technologyToProcess": "[[Technology]] to Process",
            "technologyToTechnology": "[[Technology]] to [[Technology]]",
            "total": "Total",
            "gaps": "Gaps",
            "technologyNoRunbook": "Assigned [[Technologies]] with no Runbook",
            "gapType": "Gap Type",
            "criticalVendors": "Critical Vendors",
            "disasterRecoveryTeamsToTechnology": "Disaster Recovery Teams to [[Technology]]",
            "progressByOwner": "Progress by Owner",
            "progressByTier": "Progress by Tier",
            "technologyOwner": "[[Technology]] Owner"
        },
        "bcIssue": {
            "userSelection": {
                "searchUserLabel": "Change Owner",
                "currentUserLabel": "Current Owner"
            },
            "issue": "Note or [[Issue]]",
            "issues": "Notes or [[Issues]]",
            "addNewIssue": "Add New Note or [[Issue]]",
            "createIssue": "Create Note or [[Issue]]",
            "manageIssues": "Manage Notes or [[Issues]]",
            "viewIssues": "View Notes or [[Issues]]",
            "changeOwner": "Change owner",
            "lblTitle": "Title",
            "lblDescription": "Description",
            "lblIssueStatus": "Status",
            "lblIssueOwner": "Owner",
            "lblManagementAttention": "Management Attention",
            "lblManagementAttentionAbbr": "Mgmt",
            "lblRevisionItemType": "Category",
            "lblFunctionalArea": "[[Department]]",
            "lblProcess": "[[Process]]",
            "lblComments": "Comments",
            "newComment": "New Comment",
            "addComment": "Add Comment",
            "commentOn": "on",
            "bcIssueStateType_6171be3d-bd24-4f9c-a9cd-d571c513780d": "Open",
            "bcIssueStateType_8c7d2b2e-c08c-4ef0-881c-e386d86c2245": "Closed",
            "bcIssueStateType_1ed8f21b-edcc-4408-9e7c-ebb6447bafe0": "Archived",
            "getIssueCommentsErrorMsg": "Error getting [[Issue]] Comments",
            "loadingIssuesErrorMsg": "Error loading the [[Issues]]",
            "ownerModal": {
                "headerLabel": "Select an owner for the issue",
                "searchUserLabel": "Search for owner",
                "currentUserLabel": "Current owner",
                "resultLabel": "[[Team Members]]",
                "successMsg": "Successfully changed the [[Issue]] Owner to {{ newOwnerName }}",
                "errorMsg": "Error changing owner"
            },
            "saveIssueComment": {
                "successMsg": "Successfully saved the Note or [[Issue]] Comment",
                "errorMsg": "Error saving the Note or [[Issue]] Comment"
            },
            "saveIssue": {
                "successMsg": "Successfully saved the Note or [[Issue]]",
                "errorMsg": "Error saving the Note or [[Issue]]",
                "errorPopulateRequiredFieldsMsg": "Populate all required fields"
            },
            "confirmDeleteIssueCommentModal": {
                "header": "Delete Note or [[Issue]] Comment",
                "body": "Are you sure you want to delete Note or [[Issue]] Comment created on {{ createdOn }}?",
                "text": "Delete",
                "successMsg": "Successfully deleted the Note or [[Issue]] Comment",
                "errorMsg": "Error deleting the Note or [[Issue]] Comment"
            },
            "noIssues": "No [[Issues]]",
            "businessContinuityIssues": "Business Continuity [[Issues]]",
            "attentionOnlyTitle": "Needs Management Attention",
            "allIssueTitle": "Total Number",
            "msgErrorLoadingFunctionalAreas": "Error loading [[Departments]]",
            "status": {
                "6171be3d-bd24-4f9c-a9cd-d571c513780d": "Open",
                "8c7d2b2e-c08c-4ef0-881c-e386d86c2245": "Closed"
            },
            "lblDisplayClosed": "Show Closed"
        },
        "bcRevisionItemTypes": {
            "9a8ca3b0-c29c-429a-bae3-5106ebc9eef6": "Build Your [[Team]]",
            "8075edbe-224e-4af0-accf-095cc08a650d": "Send [[Team Lead]] Email",
            "3001b73a-eff1-442a-9bf0-31ef0002f995": "Validate [[Process]]",
            "e7bd0842-eadc-483f-b9c5-9a3d5047b1f4": "Send Assignment Email",
            "d731177f-8663-44e8-a243-b058b4bf9527": "Impact Assessment",
            "be1d75e6-dac5-459f-ae5c-d565b20022de": "RTO",
            "a190d9cc-bf54-4cd4-80ba-9d1ac8028b3e": "Critical Dates",
            "056594f5-b6f0-46fb-9855-1303b611e293": "Continuity Strategy",
            "6c046473-8b67-4037-b8e5-d806800d0b06": "Impact Financial",
            "70d4a412-d41f-4c98-962f-7a45ffeebeeb": "Impact [[Client]]",
            "886647d0-77ba-4cc1-9366-70d15506f63e": "Impact Employee",
            "c3c00b14-80dd-4325-8849-2aebd26bd2d5": "Impact Brand",
            "881236ae-0445-4c49-9356-30910be262b4": "Impact Services",
            "92a17340-8be5-455d-81be-7cc58228eaa5": "Impact Compliance",
            "79272c21-fcdf-4624-94c4-e6c95ba7c03d": "Portal [[Process]] Dependencies",
            "951b6fb4-14db-4cd2-92ab-f2d24aa38b4f": "Portal [[Process]] Locations",
            "f8e8fb86-f246-4892-a9cc-a889b99fe2b1": "Delegations of Authority",
            "8169c5dd-85c0-4861-9983-1c7bf24e72fb": "Order of Succession",
            "b2c8a5da-8e90-4657-8e9b-000962841860": "[[Department]] [[teams]]",
            "5ddccee3-fe67-4206-8f80-4ac92b7b6382": "Locations",
            "8ee5ce4f-5fba-43a9-95b1-42f668a6e97c": "[[Department]] Description",
            "3cba5e70-bd1b-4cb8-8eea-dce7e515db94": "[[Team]] Lead Alternate",
            "b7b3c55a-bcb9-4846-ab25-faa09ddc6254": "Organizational [[teams]]",
            "184fb5cd-a848-4b5e-9384-94057e62a2b1": "Track [[Departments]]",
            "78eab4a2-ac01-415a-b5f9-a859b64387a4": "Track [[Processes]]",
            "139e56cf-346e-4bdd-90b5-02e55fd3d25d": "Annex Agencies",
            "7fdf1dcc-df3d-4394-b9e6-96ca9a91b290": "Annex Action List",
            "94caacc3-832a-4aec-b5da-63a1679e4eff": "General Questions",
            "f7518654-5f95-47c7-a01c-6cd833423f2e": "Track Annexes",
            "451cd7cd-e99a-42ff-aa2e-213063801e55": "Organizational Order of Succession",
            "d6686ed4-ef96-48f4-8f58-3c98227baaa7": "Organizational Delegation of Authority",
            "dfd9839e-9f89-4d18-8442-cf4920068326": "Annex Sections",
            "fd9bc961-2d0b-4fc3-932a-894f55463717": "Supersession",
            "9866e964-4fd7-4b93-a247-afd1b2abae50": "Build & Manage your Annexes",
            "deb3139e-5f75-441d-982c-cf0ae4d61c5f": "Build & Manage your Technologies",
            "bb95d0b1-482b-4c98-8006-165082f396b5": "Introduction Email",
            "2c9d3e1d-b028-4859-b2cd-40860d103670": "Technology Vendors",
            "637b9412-ead5-4656-9bfd-9c856ac152aa": "Technology Task Sets",
            "9f52df24-261e-4967-85ad-65c5299fa6fe": "Technology Dependencies",
            "7c484880-6dc1-4413-8954-010db5e6ee52": "Technology Recovery Time",
			"ba765aaf-562e-4f9a-8e18-e9748a13f180": "Send Technology Owner Emails",
			"f07ab2cd-4f19-428f-8b9f-54982abfa079": "Track technologies",
			"4c04b488-542b-49aa-9b99-851a44f4e22b": "Technology Details"
        },
        "bcProgressStatusTypes": {
            "notStarted": "Not Started",
            "inProcess": "In Progress",
            "complete": "Complete",
            "new": "New"
        },
        "customFields": {
            "customFieldTitle": "Custom Questions",
            "customFieldsDescription": "Custom Questions will be added to your planner flow and the answers will display in your plan and incident manager.",
            "fieldName": "Name",
            "fieldType": "Question Type",
            "isRequired": "Required",
            "technologyTypes": "[[Technology]] Types",
            "success": {
                "deleteCustomFieldSuccess": "Success deleting custom question",
                "saveCustomFieldsDisplayOrderSuccess": "Success saving display order",
                "saveCustomFieldSuccess": "Success saving custom question"
            },
            "errors": {
                "getCustomFieldsError": "Failed to load custom questions",
                "saveCustomFieldsDisplayOrderError": "Error saving new display order",
                "deleteCustomFieldError": "Error deleting custom question",
                "customFieldWithSameNameError": "Error saving custom question with same name",
                "saveCustomFieldError": "Error saving custom question",
                "customFieldOptionWithSameNameError": "Error saving custom question with duplicate option names",
                "customFieldTypeSelectOneWithoutOptions": "Options are required for \"Select One\" custom questions",
                "customFieldsInvalid": "Please review custom questions"
            },
            "help": {
                "fieldNameTooltip": "Label name of custom question",
                "fieldTypeTooltip": "Type of data to collect with this custom question",
                "isRequiredTooltip": "Designate if this custom question is required",
                "editCustomFieldInstructions": "Edit which type of data to collect and whether or not to require this custom question.",
                "addCustomFieldInstructions": "Add a custom question to collect information.",
                "optionsTooltip": "List options in display order.",
                "selectTechnologyTypesToolTip": "List of [[technology]] types available."
            },
            "editCustomField": "Edit Existing Custom Questions",
            "addCustomField": "Add New Custom Question",
            "newCustomField": "Add New Question",
            "confirmRemoveCustomField": {
                "header": "Remove Custom Question",
                "body": "Are you sure you want to remove this custom question?",
                "text": "Remove"
            },
            "confirmSaveCustomField": {
                "header": "Save Custom Question",
                "body": "Are you sure you want to save this custom question",
                "text": "Save"
            },
            "customFields": "Custom Questions",
            "saveDisplayOrder": {
                "cancelDisplayOrder": "Cancel Order",
                "saveDisplayOrder": "Save Order",
                "header": "Save New Display Order",
                "body": "Are you sure that you want to change the order in which the questions are displayed?",
                "text": "Save Display Order"
            },
            "businessContinuity": "Business Continuity",
            "continuityOfOperationPlanning": "Continuity",
            "disasterRecovery": "Disaster Recovery",
            "customQuestionsTitle": "Continuity Custom Questions",
            "bcCustomQuestionsTitle": "Business Continuity Custom Questions",
            "customQuestionsDescription": "Manage your continuity customs questions here.",
            "bcCustomQuestionsDescription": "Manage your business continuity customs questions here.",
            "drCustomQuestionsTitle": "Disaster Recovery Custom Questions",
            "drCustomQuestionsDescription": "Manage your disaster recovery custom questions here by [[technology]] type.",
            "technology": "[[Technology]]"
        },
        "bulkUpload": {
            "vendor": {
                "msgBulkUploadInstructions": "Have multiple vendors you want to add or modify? Choose a correctly formatted file from your computer to start the [[process]].",
                "lblAddMultipleVendorsHeader": "Adding multiple new vendors",
                "lblAddMultipleVendorsDetail": "Download this spreadsheet containing header information only and fill in your new vendor's information, one vendor per row, before saving it in .CSV format to your computer.",
                "btnBlankVendorsFile": "Blank Vendor File",
                "lblEditMultipleVendorsHeader": "Edit or Delete multiple vendors",
                "lblEditMultipleVendorsDetail": "Download this spreadsheet containing your current vendor information and modify as needed before saving it in .CSV format to your computer.",
                "btnCurrentVendors": "Current Vendors",
                "lblEditMultipleVendorsNotes": "NOTE: To delete vendors, change their status to Delete. Removing them from the spreadsheet will not delete them from the system. The bulk upload can be completed even if you do not fill in every field for a vendor. To see which fields are optional, refer to your user guide or help area in the top right corner of this window.",
                "fileName": "BulkVendorUpload.csv",
                "msgVendorExportSuccessful": "Vendors export completed successfully.",
                "errorVendorExportFailed": "Unable to export a vendors file.",
                "msgUploadVendorsSuccess": "Bulk upload for vendors successful.",
                "lblNoFileSelected": "No file specified."
            },
            "vendorContact": {
                "msgBulkUploadInstructions": "Have multiple contacts you want to add or modify? Choose a correctly formatted file from your computer to start the [[process]].",
                "lblAddMultipleContactsHeader": "Adding multiple new contacts",
                "lblAddMultipleContactsDetail": "Download this spreadsheet containing header information only and fill in your new contact's information, one contact per row, before saving it in .CSV format to your computer.",
                "btnBlankContactsFile": "Blank Contact File",
                "lblEditMultipleContactsHeader": "Edit or Delete multiple contacts",
                "lblEditMultipleContactsDetail": "Download this spreadsheet containing your current contact information and modify as needed before saving it in .CSV format to your computer.",
                "btnCurrentContacts": "Current Contacts",
                "lblEditMultipleContactsNotes": "NOTE: To delete contacts, change their status to Delete. Removing them from the spreadsheet will not delete them from the system. The bulk upload can be completed even if you do not fill in every field for a contact. To see which fields are optional, refer to your user guide or help area in the top right corner of this window.",
                "fileName": "BulkVendorContactUpload.csv",
                "msgContactExportSuccessful": "Contacts export completed successfully.",
                "errorContactExportFailed": "Unable to export a contacts file.",
                "msgUploadContactsSuccess": "Bulk upload for contacts successful.",
                "lblNoFileSelected": "No file specified."
            },
            "agency": {
                "msgBulkUploadInstructions": "Have multiple agencies you want to add or modify? Choose a correctly formatted file from your computer to start the [[process]].",
                "lblAddMultipleHeader": "Adding multiple new agencies",
                "lblAddMultipleDetail": "Download this spreadsheet containing header information only and fill in your new agency's information, one agency per row, before saving it in .CSV format to your computer.",
                "btnBlankFile": "Blank Agency File",
                "lblEditMultipleHeader": "Edit or Delete multiple agencies",
                "lblEditMultipleDetail": "Download this spreadsheet containing your current agency information and modify as needed before saving it in .CSV format to your computer.",
                "btnCurrent": "Current Agencies",
                "lblEditMultipleNotes": "NOTE: To delete agencies, change their status to Delete. Removing them from the spreadsheet will not delete them from the system. The bulk upload can be completed even if you do not fill in every field for an agency. To see which fields are optional, refer to your user guide or help area in the top right corner of this window.",
                "fileName": "BulkAgencyUpload.csv",
                "msgExportSuccessful": "Agencies export completed successfully.",
                "errorExportFailed": "Unable to export a agencies file.",
                "msgUploadSuccess": "Bulk upload for agencies successful.",
                "lblNoFileSelected": "No file specified."
            },
            "agencyContact": {
                "msgBulkUploadInstructions": "Have multiple contacts you want to add or modify? Choose a correctly formatted file from your computer to start the [[process]].",
                "lblAddMultipleHeader": "Adding multiple new contacts",
                "lblAddMultipleDetail": "Download this spreadsheet containing header information only and fill in your new contact's information, one contact per row, before saving it in .CSV format to your computer.",
                "btnBlankFile": "Blank Contact File",
                "lblEditMultipleHeader": "Edit or Delete multiple contacts",
                "lblEditMultipleDetail": "Download this spreadsheet containing your current contact information and modify as needed before saving it in .CSV format to your computer.",
                "btnCurrent": "Current Contacts",
                "lblEditMultipleNotes": "NOTE: To delete contacts, change their status to Delete. Removing them from the spreadsheet will not delete them from the system. The bulk upload can be completed even if you do not fill in every field for a contact. To see which fields are optional, refer to your user guide or help area in the top right corner of this window.",
                "fileName": "BulkVendorContactUpload.csv",
                "msgExportSuccessful": "Contacts export completed successfully.",
                "errorExportFailed": "Unable to export a contacts file.",
                "msgUploadSuccess": "Bulk upload for contacts successful.",
                "lblNoFileSelected": "No file specified."
            }
        },
        "processMoveCopy": {
            "processCopySuccessfully": "The [[process]] {{name}} has been copied successfully",
            "processCopyUnsuccessfully": "The [[process]] {{name}} has been copied unsuccessfully",
            "processMoveSuccessfully": "The [[process]] {{name}} has been moved successfully",
            "processMoveUnsuccessfully": "The [[process]] {{name}} has been moved unsuccessfully",
            "processCollision": "A [[process]] with the same name already exist in the target [[department]]"
        },
        "authorityDelegation": {
            "headerSummary": "Delegations of Authority",
            "header": "Delegation of Authority",
            "title": "Title",
            "triggerCondition": "Trigger",
            "triggersPlaceholder": "Enter your triggers here",
            "limitation": "Limitations",
            "limitationsPlaceholder": "Enter your limitations here",
            "modalMsg": "{{field}} for the delegation of authority",
            "saveSuccess": "Delegation of authority saved successfully",
            "saveError": "Delegation of authority saved unsuccessfully",
            "titleVerbiage": "This is where you record a particular function that an individual is authorized to perform.",
            "triggersVerbiage": "This is where you record the trigger points at which you would need to transition an Acting Agent's responsibilities to a Delegated Agent.",
            "limitationsVerbiage": "This is where you record the limitations that a Delegated Agent would have to abide by when responsibilities are being transitioned from an Acting Agent.",
            "actingVerbiage": "This is where you record the names of the acting/primary person(s) who are responsible for the specified Delegation.",
            "delegatedVerbiage": "This is where you record the names of the delegated/secondary person(s) who are responsible for the specified Delegation.",
            "addActing": "New Acting",
            "addDelegated": "New Delegation",
            "headerList": "Delegations of Authority",
            "titleListVerbiage": "Below you can maintain the delegations of authority within your organization. In this section you can edit, create and delete delegations of authority records.",
            "confirmDeleteHeader": "Delete {{ name }}",
            "confirmDeleteVerbiage": "Are you sure you want to delete this Delegation of Authority?",
            "successOnDelete": "The delegation was successfully deleted",
            "failedToDelete": "Failed to delete the delegation",
            "actingAgents": "Acting Agents",
            "delegatedAgents": "Delegated Agents",
            "assignActingAgent": "Assign Acting Agent",
            "noActingAgent": "No Acting Agent",
            "noDelegatedAgent": "No Delegated Agent",
            "assignDelegatedAgent": "Assign Delegated Agent",
            "lastUpdated": "Last updated: {{date}}",
            "backToSummary": "Back to Summary",
            "markComplete": "Mark Complete",
            "searchUser": {
                "headerLabel": "Select an agent for this Delegations of Authority",
                "searchUserLabel": "Search an agent for this Delegations of Authority",
                "currentUserLabel": "Current agents",
                "resultLabel": "Agents"
            },
            "authorityDescription": "This is where you record a particular function that an individual is authorized to perform.",
            "triggersDescription": "This is where you record the trigger points at which you would need to transition an Acting Agent's responsibilities to a Delegated Agent.",
            "limitationsDescription": "This is where you record the limitations that a Delegated Agent would have to abide by when responsibilities are being transitioned from an Acting Agent.",
            "delegatesDescription": "This is where you record the names of the delegated/secondary person(s) who are responsible for the specified Delegation.",
            "actingAgent": "Acting Agent",
            "actingAgentDesc": "This is where you record the names of the acting/primary person(s) who are responsible for the specified Delegation",
            "delegatingAgent": "Delegating Agent",
            "backToDepartments": "Back to Departments",
            "headerDetails": "Delegation of Authority Details",
            "titleListVerbiage2": "Please add the default delegations of authority for this industry.",
            "authorityOfDelegationSecondaryDescription": "Define your delegations of authority here.",
            "authorityOfDelegationDescription": "Manage the department's delegations of authority. Delegations of authority define which team member(s) to transfer task responsibility to when needed.",
            "authorityOfDelegationOrgDescription": "Below you can maintain the delegations of authority within your organization. In this section you can edit, create and delete delegations of authority records."
        },
        "ordersOfSuccession": {
            "title": "Orders of Succession",
            "description": "Below you can maintain the orders of succession within your organization. In this section you can edit, create and delete orders of succession records.",
            "newSuccession": "New Succession",
            "AddPrimary": "Assign Primary",
            "AddAlternate": "Assign Alternate",
            "AddAlternates": "Assign Alternates",
            "NoPrimary": "No Primary Assigned",
            "NoAlternate": "No Alternate Assigned",
            "selectPrimaryUser": {
                "headerLabel": "Select a primary agent for this order of succession",
                "searchUserLabel": "Search a primary agent for this order of succession",
                "currentUserLabel": "Current primary agent",
                "resultLabel": "Agents"
            },
            "selectAlternateUser": {
                "headerLabel": "Select an alternate agent for this order of succession",
                "searchUserLabel": "Search an alternate agent for this order of succession",
                "currentUserLabel": "Current alternate agent",
                "resultLabel": "Agents"
            },
            "orderSavedSuccessfully": "The Order of Succession has been saved successfully",
            "orderSavedUnsuccessfully": "The Order of Succession has been saved Unsuccessfully",
            "removeOrderOfSuccession": "Remove Order of Succession",
            "removeOrderOfSuccessionDescription": "Are you sure you want to remove this Order of Succession",
            "successOnDelete": "Order of Succession successfully deleted",
            "failedToDelete": "Failed to the delete order of succession",
            "alternatesDescription": "An ordered list of who would succeed in an event the primary person is unable to perform their duties.",
            "alternate": "Alternate",
            "primaryDescription": "This is where you record the names of the acting/primary person(s) who are responsible for the specified Delegation.",
            "titleDetails": "Order of Succession Details",
            "description2": "Please add the default order of successions for this industry.",
            "oosSecondaryDescription": "Define your orders of succession here.",
            "oosDescription": "Manage the orders of succession that support your department. Orders of succession document the line of individuals for an office in case the primary person is not present.",
            "oosOrgDescription": "Below you can maintain the orders of succession within your organization. In this section you can edit, create and delete orders of succession records."
        },
        "summarySidebar": {
            "question01": "What are [[Processes]]?",
            "answer0100": "[[Processes]] are the responsibilities / tasks your organization must complete to be considered \"operational.\" Each individual organization has its own unique list of [[Processes]], and they all serve a distinct purpose in ensuring the organization's overall mission of continuity of operations.",
            "answer0101": "[[Processes]] are the responsibilities / tasks your organization must complete to be considered \"operational.\" Each individual organization has its own unique list of Essential Functions, and they all serve a distinct purpose in ensuring the organization's overall mission of continuity of Operations.",
            "answer0102": "Various [[Processes]]:",
            "answer0103": "Conduct daily audit of accounts payable log (Accounting)",
            "answer0104": "Create and submit daily audits of employee benefits (Human Resources)",
            "answer0105": "Detect and suppress urban, rural, and wild land fires (Fire)",
            "answer0106": "Respond to 911 calls and vehicular injuries (EMS)",
            "answer0107": "Issue medications to Tuberculosis/HlV patients (Health)",
            "answer0108": "Inspect and maintain water/wastewater system (Public Works)",
            "question02": "What are Delegations of Authority?",
            "answer0201": "Within almost every organization, there are designated personnel with the authority to do certain things that very few within the organization are authorized to do. An important part of your continuity plan is to identify what special authorities exist within your organization, and the personnel authorized to conduct them. Then, create a list of \"backup\" personnel who can conduct that authority should the primary person not be available during a continuity event. Be sure to pay especially close attention to any authorities that are granted to only one person within the organization, as this would be a single point of failure within your plan if they were not available.",
            "answer0202": "Tip: The Delegation of Authority section is VERY similar to the Orders of Succession section within your plan; however, the major difference is: The Delegations section focuses on personnel who could assume specific capabilities / authorities, while Succession focuses on personnel who can assume a person's specific title.",
            "answer0203": "Here are some examples of authorities for you to consider that might apply:",
            "answer0204": "Who can sign checks, contracts, or other legal documents?",
            "answer0205": "Who can grant travel, vacation, or extended leave authorization?",
            "answer0206": "Who can make purchases of certain amounts above and beyond normal purchase limits?",
            "answer0207": "Who can close the workplace and/or determine a delayed opening, and for what period of time?",
            "answer0208": "Who can hire and/or lay-off personnel?",
            "answer0209": "Are there special authorities or capabilities performed by specific personnel within the organization? If so, what are the specific authorities they conduct, and who holds the capability to perform them? If one of these personnel were not available during a continuity event, is there an established a line of backup personnel who could perform these authorities in their absence?",
            "question03": "What are Orders of Succession?",
            "answer0301": "Orders of Succession can be summed up by the question, \"Who comes next in the chain of command?\"",
            "answer0302": "Within every organization there are key personnel who have certain authorized duties, roles, and responsibilities specific to their position. Your continuity plan should identify these key personnel and create an Order of Succession identifying who is authorized to assume their organizational role in the event they are unavailable or impacted during an event.",
            "answer0303": "Having an Order of Succession in place will help facilitate an orderly and pre-defined transition of leadership positions within your organization. Orders of Succession are an essential part of a continuity plan and should be of sufficient depth to ensure that an organization is able to perform its essential functions through any event.",
            "answer0304": "Tip: Some per-identified successions are created for you, and all you need to do is assign personnel. You may also add additional Orders of Successions as needed, depending on the structure and complexity of your organization. To do so, click the \"Add new Succession\" button at the top of the page."
        },
        "planTemplates": {
            "header": "Plan Templates",
            "continuityOfOperationPlanning": "Continuity of Operations Planning (COOP)",
            "emergencyOperations": "Emergency Operations Plan (EOP)",
            "continuityOfGovernment": "Continuity of Government (COG)",
            "businessContinuity": "Business Continuity Planning (BCP)",
            "disasterRecovery": "Disaster Recovery Planning (DRP)",
            "lossOfServicesEmergency": "Loss of Services Emergency Operation Plan (LSEOP)",
            "tabInfo": "Below you can view your templates that are currently in use. From here you have the ability to edit, delete, assign or duplicate these templates.",
            "newTemplates": "New Templates",
            "assignedTemplates": "Templates In Use",
            "unAssignedTemplates": "Templates Not In Use",
            "draftTemplates": "Unpublished Drafts",
            "confirmDeleteHeader": "Delete {{ name }}",
            "confirmDeleteVerbiage": "Are you sure you want to delete this template from the system?",
            "successOnDelete": "The plan template was successfully deleted",
            "failedToDelete": "Failed to delete the plan template",
            "exportTemplate": "Exporting Plan Template...",
            "successOnExport": "Plan template is exported",
            "failedOnExport": "Failed to export plan template",
            "importTemplate": "Import Template",
            "uploadPlanTemplate": "Upload Plan Template",
            "dropFileInstruction": "Drop or click to select a plan template file",
            "loadingTemplate": "Importing Plan Template",
            "successOnImport": "Plan template is imported",
            "failedOnImport": "Failed to import plan template",
            "successOnSave": "The plan template was successfully saved",
            "failedToSave": "The plan template was unsuccessfully deleted",
			"duplicateTemplateName": "Plan Template with same name already exist, please choose a different name.",
            "createDuplicate": "Create Duplicate",
            "duplicateTemplate": "Duplicate Template",
            "copyOf": "Copy of {{template}}",
            "assignDefaultTemplatesDescription": [
                "Make Default {{entityType}} Plan",
                "(will assign to all unassigned {{entityType}}s)",
                "(will assign to all unassigned {{entityType}})"
            ],
            "assignTemplate": "Assign Template",
            "selectedDepartments": "Selected Departments",
            "availableDepartments": "Available Departments",
            "targetsAssignedSuccessfully": "{{targetType}} assigned successfully",
            "targetsAssignedUnsuccessfully": "{{targetType}} assigned unsuccessfully",
            "lastUpdated": "Last updated: {{name}} - {{date}}",
            "availableCompanies": "Available Companies",
            "availableLocations": "Available Locations",
            "selectedCompanies": "Selected Companies",
            "selectedLocations": "Selected Locations",
            "availableTechnologies": "Available [[Technologies]]",
            "selectedTechnologies": "Selected [[Technologies]]",
            "successOnDuplicate": "The plan template was successfully duplicated",
            "failedToDuplicate": "The plan template was unsuccessfully duplicated",
            "templateDetails": "Template Details",
            "setTemplateAsDefault": "Set template as Default",
            "setTemplateDefaultConfirmation": "This template will now be used as the default template for {{planName}}",
            "promoteModalHeader": "Promote Template",
            "promoteModalBody": "Are you sure you want to promote this template to a system template for this industry? <br/> This will apply this template to all client portals with the {{industryName}} industry type.",
            "planLevel": "Plan Level",
            "dataTables": "Data Tables",
            "insertTable": "Insert Table",
            "selectModalVerbiage": "Select from below to view and insert your data table.",
            "planLevels": {
                "department": "[[Department]]",
                "location": "[[Location]]",
                "company": "Company",
                "companyITDR": "Company",
                "continuityOfGovernment": "Organization (COG)",
                "continuityOfOperationPlanning": "[[Department]]",
                "emergencyOperations": "Emergency Operations",
                "technology": "[[Technology]]"
            },
            "enterName": "Enter name here",
            "duplicatePublishedWarning": "This will duplicate the Published version of this template. Any draft changes will not be included.",
            "unpublishedChanges": "Unpublished changes"
        },
        "planBuilder": {
            "planTemplate": {
                "draftVersion": "Draft Version",
                "planTemplateTargetType": "{{planTargetType}} Plan Template",
                "discard": "Discard all changes / Revert to last published",
                "planTemplate": "Plan Template",
                "publishTemplate": "Publish Template",
                "createNewSection": "Create new section",
                "sectionSettings": "Section [[Settings]]",
                "saveSection": "Save Section",
                "removeSection": "Remove Section",
                "removeSectionDescription": "Are you sure that you want to remove this section?",
                "unsavedChanges": "Unsaved changes",
                "unsavedChangesDescription": "You have unsaved changes. Your changes will be discarded. Are you sure you want to switch section?",
                "revertDescription": "Are you sure you want to revert this draft to the original and start over, this cannot be undone?",
                "publishDescription": "Are you sure that you want to publish?",
                "pageBreakTrueLabel": "Start on new page",
                "pageBreakFalseLabel": "Continue under last Section",
                "noSections": "No sections exist",
                "addNewSection": "Add new section",
                "insertTag": "Insert Tag",
                "insertTagDescription": "Select from the tags below to insert.",
                "insertElementDescription": "Select from below to view and insert your data table.",
                "tags": {
                    "departmentLead": "Department Lead",
                    "departmentLeadAlternate": "Department Lead Alternate",
                    "departmentName": "Department Name",
                    "departmentDescription": "Department Description",
                    "departmentDueDate": "Department Due Date",
                    "companyAddress": "Company Address",
                    "companyPhone": "Company Main Phone Number",
                    "companyName": "Company Name",
                    "departmentLeadDescription": "The department lead.",
                    "departmentLeadAlternateDescription": "The department lead alternate.",
                    "departmentNameDescription": "The name of the department.",
                    "departmentDueDateDescription": "The due date for the team lead to finish defining their department.",
                    "companyAddressDescription": "The address where the main office is located.",
                    "companyPhoneDescription": "The main phone number for the company.",
                    "companyNameDescription": "The name of the company.",
                    "departmentDescriptionDescription": "The description of the department",
                    "eMALocation": "EMA Location",
                    "eMALocationDescription": "Where your Emergency Management Agency is located.",
                    "eOCLocation": "EOC Location",
                    "eOCLocationDescription": "Where your Emergency Operations Center is located.",
                    "massCare": "Mass Care",
                    "massCareDescription": "Which agency/department is responsible for mass care & feedings.",
                    "trainingTesting": "Training Testing",
                    "trainingTestingDescription": "Which agency/department is responsible for your Emergency Operations Plan.",
                    "unmetNeeds": "Unmet Needs",
                    "unmetNeedsDescription": "Which agency/department coordinates is responsible for unmet needs.",
                    "supersession": "Supersession",
                    "supersessionDescription": "Emergency Operations Plan supersession information.",
                    "planGenerationDate": "Plan Generation Date",
                    "planGenerationDateDescription": "Output the date and time when the plan document was generated.",
                    "planTemplatePublished": "Plan Template Published",
                    "planTemplatePublishedDescription": "Output the date and time when the plan template was last published.",
                    "technologyERT": "Estimated Recovery Time",
                    "technologyERTDescription": "The estimated recovery time assigned within the [[technology]] runbook.",
                    "technologyName": "[[Technology]] Name",
                    "technologyNameDescription": "The [[technology]] name.",
                    "technologyOwnerAlternate": "[[Technology]] Owner Alternate",
                    "technologyOwnerAlternateDescription": "The name of the alternate [[technology]] owner.",
                    "technologyOwner": "[[Technology]] Owner",
                    "technologyOwnerDescription": "The name of the primary [[technology]] owner.",
                    "technologyDueDate": "[[Technology]] Due Date",
                    "technologyDueDateDescription": "The due date for the [[technology]] owner to finish defining the [[technology]]."
                },
                "elements": {
                    "customFields": "Strategy Questions and Answers",
                    "vendors": "Key Vendors or Suppliers",
                    "assessments": "Risk Assessments",
                    "criticalDates": "Critical Dates",
                    "portalTeams": "[[Department]] [[Teams]]",
                    "rTO": "[[Process]] Recovery Strategies",
                    "attachments": "Attachments",
                    "technologies": "[[Technologies]]",
                    "processDependencies": "[[Process]] Dependencies",
                    "employeeDeployment": "Employee Deployment",
                    "subjectMatterExperts": "Continuity [[Team]] Members",
                    "customFieldsDes": "All [[department]] [[process]] strategy questions and answers, includes the associated strategy.",
                    "vendorsDes": "All [[department]] [[process]], including vendor, contract dates, addresses and contacts.",
                    "assessmentsDes": "All threats for the department from the last completed risk assessment for the [[department]]. Includes threat and typical impact description.",
                    "criticalDatesDes": "All [[process]] critical dates across all [[departments]].",
                    "portalTeamsDes": "A table that lists all [[Teams]] associated with the [[department]], includes their members or positions and the corresponding responsibilities.",
                    "rTODes": "Lists all [[departments]] and their [[processes]], tables are grouped by continuity strategy. Includes the [[process]]'s authorized parties, vendors, designated workers, and necessary [[equipment]].",
                    "attachmentsDes": "All [[department]] [[process]] attachments, includes the process recovery time. ",
                    "technologiesDes": "All [[department]] Application/[[Technology]], includes Recovery Point Objective (RPO), associated [[processes]] and Recovery Time.",
                    "processDependenciesDes": "All [[department]] [[Process]] that have a dependency, includes Recovery Point Objective (RPO), dependent [[Process]] name and dependent process Recovery Time Objective (RTO).",
                    "employeeDeploymentDes": "All Company employees that are assigned to a [[department]] [[process]] ordered by RTO. Includes the [[process]]'s authorized parties, vendors, designated workers, and locations.",
                    "subjectMatterExpertsDes": "A table that lists all Subject Matter Experts and their contact information along with the processes they support within the [[department]].",
                    "departments": "[[Departments]]",
                    "departmentsDes": "All [[Departments]] within the company along with their description",
                    "equipment": "[[Equipment]] Dependencies",
                    "equipmentDes": "All company [[Equipment]] that is required for [[process]] continuity across all [[Departments]], includes [[process]] recovery time",
                    "teamLeaders": "[[Department]] Leaders",
                    "teamLeadersDes": "All [[department]] leaders and their contact information",
                    "businessContinuityManagers": "Business Continuity Managers",
                    "businessContinuityManagersDes": "All company business continuity managers and their contact information",
                    "delegationOfAuthority": "Delegations of Authorities",
                    "delegationOfAuthorityDes": "This table will list all delegations of authorities within the [[department]], triggers, limitations, including active and delegated members.",
                    "ordersOfSuccession": "Orders Of Succession",
                    "ordersOfSuccessionDes": "This table will list all orders of succession within the [[department]].",
                    "maximumTolerableDowntime": "Maximum Tolerable Downtime",
                    "maximumTolerableDowntimeDes": "The table of MTD (Maximum Tolerable Downtime) in the business continuity processes.",
                    "processLocations": "[[Process]] [[Locations]]",
                    "processLocationsDes": "This table lists all the [[processes]] within the [[department]] and the [[locations]] they are performed.",
                    "annexes": "Annexes",
                    "annexesDes": "The defined annexes within your Emergency Operations Plan.",
                    "emergencyOperations": {
                        "portalTeams": "Response [[Teams]]",
                        "portalTeamsDes": "This table will list all Emergency Operations Plan response [[teams]], including positions, member contact information and their responsibilities."
                    },
                    "iTDRTechnologyAttachments": "Attachments",
                    "iTDRTechnologyAttachmentsDes": "All list of all attachments for the selected [[technology]].",
                    "iTDRTechnologySupportingVendors": "Supporting Vendors",
                    "iTDRTechnologySupportingVendorsDes": "A table lists all vendors that support the selected [[technology]], includes vendor contact information and [[technology]] SLA information.",
                    "iTDRTechnologyTaskSets": "Task Sets",
                    "iTDRTechnologyTaskSetsDes": "A table lists tasks for the recovery of the selected [[technology]] grouped by Task Set, includes Assigned Time, Teams, Members and Tasks.",
                    "iTDRTechnologyDetails": "[[Technology]] Details",
                    "iTDRTechnologyDetailsDes": "A table lists all [[technology]] type custom questions, their answers and any addition instructions.",
                    "iTDRUpstreamTechnologyDependencies": "Upstream [[Technology]] Dependencies",
                    "iTDRUpstreamTechnologyDependenciesDes": "Lists all upstream dependent [[technologies]] for this [[technology]], recovery point objective (RPO), [[technology]] name and recovery point objective (RPO).",
                    "iTDRTechnologyDisasterRecoveryTeams": "Disaster Recovery Teams",
                    "iTDRTechnologyDisasterRecoveryTeamsDes": "A table that lists all disaster recovery teams associated to the [[technology]], includes member names, positions and the corresponding responsibilities.",
                    "iTDRCompanyTechnologiesAttachments": "Attachments",
                    "iTDRCompanyTechnologiesAttachmentsDes": "A list of all [[technology]] attachments within your organization, including the [[technology]] name and attachment name.",
                    "iTDRCompanytechnologiesByVendors": "Technology by Vendor",
                    "iTDRCompanytechnologiesByVendorsDes": "A table lists all vendors that support a [[technology]] within the organization.",
                    "iTDRCompanyTechnologiesByOwner": "[[Technology]] by Owner",
                    "iTDRCompanyTechnologiesByOwnerDes": "A table listing all [[technologies]] with recovery plans, grouped by their respective [[technology]] owners.",
                    "iTDRCompanyDisasterRecoveryTeams": "Disaster Recovery Teams",
                    "iTDRCompanyDisasterRecoveryTeamsDes": "This table will list all Disaster Recovery Teams within the organizational, including positions, member contact information and their responsibilities."
                }
            }
        },
        "bcPlanBuilder": "Plan Builder",
        "teams": {
            "title": "Teams",
            "titleVerbiage": "Below are the organizational teams.",
            "titleVerbiage2": "Below are the teams that have been associated to your department.",
            "addTeam": "New Team",
            "linked": "Linked",
            "confirmDeleteHeader": "Delete {{ name }}",
            "confirmDeleteVerbiage": "Are you sure you want to delete this Team?",
            "successOnDelete": "The team was successfully deleted",
            "failedToDelete": "The team was unsuccessfully deleted",
            "assignMembers": "Assign Members",
            "newTeam": "New Team",
            "createNew": "Create New",
            "associateTeam": "Associate a Team",
            "selectATeam": "Select a team",
            "members": "Members",
            "addMembers": "Add Member(s)",
            "responsibilities": "Responsibilities",
            "noMembersAdded": "No members added",
            "addMember": "Add Member",
            "position": "Position",
            "positionPlaceholder": "Enter position here",
            "responsibilitiesPlaceholder": "Enter responsibilities here",
            "assignMember": "Assign Member",
            "enterTeamName": "Enter Team Name here",
            "TeamDetailsTitle": "Team Details",
            "teamName": "Team Name",
            "successOnSave": "The team was successfully saved",
            "failedToSave": "The team was unsuccessfully saved",
            "noDescription": "No Description",
            "slideOut": {
                "team": "Team",
                "teamName": "Enter the official name of the team within your organization. Examples include \"Incident Management Team\", \"Crisis Management Team\", or \"Planning Team\".",
                "teamDescription": "Provide a brief summary of the team’s roles and responsibilities within your organization. Include key functions, main objectives, and any critical areas of focus.",
                "members": "Members",
                "memberName": "Team Member’s Name",
                "position": "The member's job or position within the team.",
                "responsibilities": "The member's or position's responsibility within the team and group."
            },
            "createPosition": {
                "confirmDeleteVerbiage": "Are you sure you want to delete this Position?",
                "successOnDeletePosition": "The position was successfully deleted",
                "failedToDeletePosition": "Failed to delete the position",
                "searchUserLabel": "Search For A Team Member",
                "resultLabel": "Select Member",
                "currentUserLabel": "Current Team Member"
            },
            "teamLinkedSuccessfully": "Team associated successfully",
            "removeTeamConfirmationHeader": "Remove Team",
            "removeTeamConfirmationMessage": "Are you sure you want to remove this team from the department?",
            "teamRemovedSuccessfully": "Team removed successfully",
            "teamRemovedUnsuccessfully": "Team removed unsuccessfully",
            "positions": "Positions",
            "titleVerbiage3": "Please add the default teams for this industry.",
            "addPositions": "Add Position(s)",
            "addPosition": "Add Position",
            "noPositionsAdded": "No positions added",
            "organizationalTeams": "Organizational Teams",
            "organizationalTeamsDescription": "Creating continuity response teams is essential for organizations to effectively manage crises and maintain operations during unexpected disruptions. These dedicated teams, equipped with specialized skills and resources, ensure rapid decision-making and coordinated actions, safeguarding continuity and minimizing potential losses.",
            "departmentalTeamsDescription": "Manage the teams that support this [[Department]]. Teams are comprised of people, positions, and responsibilities, and are used to plan and address incidents.",
            "technologyTeamsDescription": "Disaster Recovery Teams play a critical role in managing and mitigating disruptions to IT systems and services",
            "departmentalTeams": "Departmental Teams",
            "technologyTeams": "Disaster Recovery Teams",
            "organizationalTeamsSecondaryDescription": "Add your organizational teams here.",
            "departmentalTeamsSecondaryDescription": "Add your [[Department]] teams here.",
            "technologyTeamsSecondaryDescription": "Identify your disaster recovery teams here",
            "associateModal": {
                "title": "Assign Team",
                "switchDes": "Assign to all [[Departments]]",
                "assignToAllSuccessfully": "The team has been assign to all [[departments]] successfully",
                "assignToAllUnsuccessfully": "The team has been assign to all [[departments]] Unsuccessfully",
                "setTeamAsDefault": "Assign Team to all [[Departments]].",
                "setTeamDefaultConfirmation": "Are you sure you want to assign this team to every [[department]]?"
            }
        },
        "agencies": "Agencies",
        "annex": {
            "annexBuildManage": "Build & Manage your Annexes",
            "annexSecondaryDescription": "Select the annexes that you want to include in your plan & then assign your annex lead",
            "addFromLibrary": "Add From Library",
            "creatFromScratch": "Create From Scratch",
            "addAnnexDescription": "Add an annex to get started",
            "annexTitle": "Annex Title",
            "selectAnnex": "Select Annex",
            "selectAnnexSecondaryDescription": "Select the annexes below that you would like to use in your plan.",
            "annexes": "Annexes",
            "edit": "Edit Annex",
            "view": "View Annex",
            "trackDescription": "Track & complete your annexes here.",
            "dueDateRequired": "Due Date Required for all annexes.",
            "removeAnnex": "Remove Annex",
            "removeAnnexDescription": "Are you sure that you want to remove this Annex?",
            "duplicateAnnexTitle": "Annex with same name already exist.",
            "manageLibrary": "Manage Library",
            "selectAnnexesToDeleteDescription": "Select the annexes below that you would like to delete from library.",
            "removeSystemAnnex": "Remove Annex From Library",
            "removeSystemAnnexesDescription": "Are you sure that you want to remove these Annexes from library?",
            "removeSystemAnnexDescription": "Are you sure you want to delete this system annex from the library? This cannot be undone.",
            "setDueDate": {
                "header": "Due Date",
                "body": "Some annexes already have a due date, do you want to set this due date {{dueDate}} to all annexes?",
                "allProcesses": "Yes",
                "newProcesses": "No",
                "msgConfirmBody": "Are you sure you want to set this new due date {{dueDate}} to all annexes?"
            },
            "configureAnnex": {
                "label": "Configure Annex",
                "removeAgency": "Remove Agency",
                "removeAgencyDescription": "Are you sure you want to remove this agency from this role? Actions assigned to this agency will remain in the action list.",
                "taskAgenciesSecondaryDescription": "Identify your agencies here.",
                "annexSectionsDescription": "Below are the sections of content that will be included in your annex. You can edit, reorder, or choose to not include.",
                "saveAnnexSystemLibrary": "Save Annex to System Library",
                "removeAction": "Remove Action",
                "removeActionDescription": "Are you sure you want to delete this action?",
                "responsibilitiesSecondaryDescription": "Identify your agencies roles and responsibilities here.",
                "addAction": "Add Action",
                "saveNewSystemAnnex": "Are you sure you want to save this annex to system library?",
                "overWriteSystemAnnex": "Are you sure you want to overwrite an existing annex in the system library with this version? This cannot be undone.",
                "saveSystemAnnexSuccess": "Annex saved to system library",
                "saveSystemSuccessFail": "Failed to save annex to system library",
                "sectionsDescription": "Document the purpose, scope, concepts, financial management, and reference authorities that are key for this annex.",
                "agenciesDescription": "Document assigned agencies that play coordinating, primary, and support roles within this annex.",
                "responsibilitiesDescription": "Identify and document agency responsibilities during multiple phases of this annex."
            }
        },
        "supersession": {
            "supersession": "Supersession",
            "supersessionSecondaryDescription": "A supersession statement should clarify if this plan or any documents inside this plan will replace anything prior.",
            "supersessionSaveSuccessfully": "Supersession saved",
            "supersessionSaveUnsuccessfully": "Failed to saved Supersession",
			"noSupersession": "No Supersession"
        },
        "generalQuestions": {
            "generalQuestions": "General Questions",
            "description": "You will also list entities from information you enter in your contacts database, departments, and facilities portions of the tool.",
            "what": "What",
            "whatDescription": "Assign your appropriate agencies here",
            "where": "Where",
            "whereDescription": "Assign your appropriate locations here",
            "assignedAgency": "Assigned Agency",
            "assignAgency": "Assign Agency",
            "assignedLocation": "Assigned Location",
            "assignLocation": "Assign Location",
            "questions": {
                "massCare": "Mass Care",
                "massCareDes": "What Agency/Department responsible for mass care & feedings?",
                "unmet": "Unmet Needs",
                "unmetDes": "What Agency/Department coordinates unmet needs?",
                "EOC": "EOC",
                "EOCDes": "Where is your Emergency Operations Centers located (EOC)?",
                "EMA": "EMA",
                "EMADes": "Where is your emergency management agency located (EMA)?",
                "training": "Testing and Training",
                "trainingDes": "What Agency/Department is responsible for Training & Testing?"
            },
            "saveSuccess": "Questions answer saved",
            "saveFailed": "Failed to save Questions answer"
        },
        "departmentAlternate": {
            "MemberModal": {
                "headerLabel": "Select a Alternate Member for {{name}}",
                "searchUserLabel": "Search for a Alternate",
                "currentUserLabel": "Current Alternate",
                "resultLabel": "Alternates"
            },
            "alternateSaveSuccessfully": "Alternate saved",
            "alternateSaveUnsuccessfully": "Failed to saved Alternate",
            "alternateRequired": "The alternate is required",
            "alternate": "Alternate",
            "noAlternate": "No Alternate"
        },
        "technology": {
            "manageTechnology": "[[Technologies]]",
            "technologyVendors": "Vendor",
            "technologyDependencies": "[[Technology]] Dependencies",
            "technologyApplicationDetails": "[[Technology]] Details",
            "technologyTasks": "[[Technology]] Tasks",
            "technologyRecoveryTime": "[[Technology]] Recovery Time",
            "configureTechnology": "Configure [[Technology]]",
            "technologyBuildManage": "Build & Manage Your [[Technologies]]",
            "technologyDescription": "Building and managing [[technologies]] for an IT Disaster Recovery plan involves a systematic approach to ensure that critical [[technologies]] can be restored quickly and efficiently in the event of a disaster.",
            "technologySecondaryDescription": "Identify your critical business [[technologies]] based on their importance to organizational functions.",
            "searchPlaceHolder": "Search here",
            "removeTechnology": "Remove [[Technology]]",
            "owner": "Owner",
            "addTechnology": "Add [[Technology]]",
            "filterByTier": "Filter by Tier",
            "filterByType": "Filter by Type",
            "manageTechnologies": "Manage [[Technologies]]",
            "MemberSelectionModal": {
                "headerLabel": "Assign Owner",
                "searchUserLabel": "Select Member(s)",
                "currentUserLabel": "Selected Member",
                "customCurrentUserLabel": "Currently Selected Employees",
                "resultLabel": "Members",
                "assignButton": "Add"
            },
            "removeConfirmation1": "Are you sure you want to remove this [[technology]] from your IT/DR planning? This will remove all the planning data.",
            "removeConfirmation2": "Are you sure you want to remove these [[technologies]] from your IT/DR planning? This will remove all the planning data.",
            "unAssignConfirmation": "The alternate assignment will be cleared due to no primary assignment. Would you like to proceed?",
            "unAssignConfirmationHeader": "Alternate assignment will be removed",
            "trackDescription": "Track & complete your [[technologies]] here.",
            "noTechnologies": "No [[Technologies]]",
            "name": "Name",
            "status": "Status",
            "dueDate": "Due Date",
            "technologySME": "[[Technology]] Owner",
            "open": "Open",
            "edit": "Edit",
            "selectTechnologies": "Select [[Technologies]]",
            "vendors": {
                "title": "Vendor",
                "description": "Select the vendors that support or provide services that are required by this [[technology]].",
                "secondaryDesc": "Identify the supporting Vendors.",
                "addVendor": "Add Vendor",
                "confirmDeleteHeader": "Remove {{ name }}",
                "confirmDeleteVerbiage": "Are you sure you want to remove this vendor from this [[technology]]?",
                "vendorDetails": "Vendor Details",
                "lblAdditionalInstructions": "SLA Agreements",
                "updateInstructions": "Update SLA Agreements",
                "instructionsModal": {
                    "title": "SLA Details",
                    "instructionsPLaceHolder": "Enter SLA details"
                },
                "successOnSave": "The vendor was successfully saved",
                "failedToSave": "The vendor was unsuccessfully saved",
                "successOnDelete": "The vendor was successfully deleted",
                "failedToDelete": "The vendor was unsuccessfully deleted",
                "modalHeader": "Select Vendor"
            },
            "taskSets": {
                "title": "Task Sets",
                "description": "Recovering a [[technology]] in an IT Disaster Recovery plan involves a series of well-defined steps to minimize downtime and \n restore functionality. The specific steps may vary based on the nature of the [[technology]], its dependencies, and the overall IT infrastructure.",
                "secondaryDesc": "Customize these steps based on the specific needs and characteristics for this [[technology]].",
                "tasks": "Tasks",
                "addTask": "Add Task",
                "task": "Task",
                "removeTask": "Remove Task",
                "removeTaskDescription": "Are you sure you want to remove this task?",
                "removeTaskSet": "Remove Task Set",
                "removeTaskSetDescription": "Are you sure you want to remove this task set?",
                "successOnSaveTaskSet": "The task set was successfully saved",
                "failedToSaveTaskSet": "The task set was unsuccessfully saved",
                "successOnDeleteTaskSet": "The task set was successfully deleted",
                "failedToDeleteTaskSet": "The task set was unsuccessfully deleted",
                "successOnSaveTask": "The task was successfully saved",
                "failedToSaveTask": "The task was unsuccessfully saved",
                "successOnDeleteTask": "The task was successfully deleted",
                "failedToDeleteTask": "The task was unsuccessfully deleted",
                "newTaskSet": "New Task Set",
                "teams": "Teams",
                "taskSetRecoveryTime": "Task Set Recovery Time",
                "taskSet": "Task Set",
                "assignTime": "Assign time",
                "assignTimeTitle": "Assign Time",
                "assignMember": "Assign member",
                "assignTeam": "Assign team",
                "memberSaveSuccessfully": "The member was successfully saved",
                "memberSaveUnsuccessfully": "The member was unsuccessfully saved",
                "memberSaveSuccessfully2": "The members were successfully saved",
                "memberSaveUnsuccessfully2": "The members were unsuccessfully saved",
                "teamSaveSuccessfully": "The team was successfully saved",
                "teamSaveUnsuccessfully": "The team was unsuccessfully saved",
                "teamSaveSuccessfully2": "The teams were successfully saved",
                "teamSaveUnsuccessfully2": "The teams were unsuccessfully saved",
                "assigneeModal": {
                    "headerLabel": "Select a Member",
                    "searchUserLabel": "Search for a Member",
                    "currentUserLabel": "Currently Assigned Members",
                    "resultLabel": "Members"
                }
            },
            "upstreamDependencies": {
                "pageDescription": "Understanding upstream dependencies allows organizations to assess risks, prioritize resources, and develop contingency plans to mitigate the impact of disruptions on critical operations during disaster recovery scenarios.",
                "sub-text": "Identify all the [[technologies]] that this [[technology]] depends on.",
                "addDependency": "New Dependency",
                "successOnSave": "The dependency was successfully saved",
                "failedToSave": "The dependency was unsuccessfully saved",
                "successOnSavePlural": "The dependencies were successfully saved",
                "failedToSavePlural": "The dependencies were unsuccessfully saved",
                "successOnDelete": "The dependency was successfully deleted",
                "failedToDelete": "The dependency was unsuccessfully deleted",
                "removeDependencyConfirmationModal": {
                    "header": "Remove {{dependencyName}}",
                    "body": "Are you sure you want to remove this dependency from this [[technology]]?"
                }
            },
            "recoveryTime": {
                "title": "Estimated Recovery Time",
                "pageDescription": "Choosing your estimated recovery time is a crucial decision for organizations looking to ensure business continuity in the face of potential disasters or disruptions. The estimated recovery time refers to the projected duration it would take to restore critical systems, data, and functionalities after a disaster or disruptive event.",
                "sub-text": "Select the estimated recovery time for your [[technology]].",
                "earliestRTO": "Earliest RTO for this [[technology]]",
                "estimatedTime": "Your estimated recovery time based on your tasks",
                "successOnSave": "The Recovery Time was successfully saved",
                "failedToSave": "The Recovery Time was unsuccessfully saved"
            },
            "myTechnologies": {
                "header": "My [[Technologies]]",
                "subHeader": "Select from your [[technologies]] below to get started.",
                "description": "Once you select a [[technology]], you will start going through the process of building your IT/DR Runbook for that [[technology]]. You will be able to return to this section at any time to switch to another [[technology]] if needed"
            },
            "backToMyTechnologies": "Back To My [[Technologies]]",
            "setDueDate": {
                "header": "Due Date",
                "body": "Some [[technologies]] already have a due date, do you want to set this due date <b>{{dueDate}}</b> to all [[technologies]]?",
                "allTechnologies": "Yes",
                "newTechnologies": "No",
                "msgConfirmBody": "Are you sure you want to set this new due date <b>{{dueDate}}</b> to all [[technologies]]?"
            },
            "sendEmail": {
                "dueDateRequired": "Due date required for all [[technologies]]"
            },
            "detailsAndAttachments": {
                "label": "[[Technology]] Details and Attachments",
                "additionalDetails": "Additional Details",
                "enterAdditionalDetails": "Enter additional details",
                "additionalDetailsSavedSuccessfully": "Additional details saved successfully",
                "customQuestionSavedSuccessfully": "Custom questions saved successfully"
            },
            "technologyAnalytics": {
                "label": "[[Technology]] Analytics",
                "assignedOwner": "Assigned Owner",
                "progress": "Progress",
                "rpo": "RPO",
                "earliestRto": "Earliest RTO",
                "technologyType": "[[Technology]] Type",
                "vendors": "Vendors",
                "assignedDisasterRecoveryTeams": "Assigned Recovery Teams",
				"processes": "[[Processes]]"
			}
        },
        "confirmationCodeModal": {
            "header": "Are you sure you want to start editing this following system data?",
            "body": "Any change WILL affect ALL portals and clients. Please enter in the save code to continue.",
            "btn": "I understand",
            "codeError": "Code confirmation unsuccessfully"
        },
        "teamSelectionModal": {
            "header": "Assign Team",
            "searchLabel": "Select Team(s)",
            "searchPlaceholder": "Search here",
            "selectedTeams": "Currently selected Teams",
            "teams": "Teams"
        },
        "processDependenciesInfoSidebar": {
            "dependencies": "Dependencies",
            "introDesc": "Dependencies are essential to identify, as they highlight processes managed by other departments or groups that are crucial for the continuity of your operations. Ensuring these dependencies are available during an incident is key to maintaining processes and functions.",
            "examples": "Examples",
            "payroll": "Payroll (HR)",
            "payrollInfo": "Dependency: Funding (Finance)",
            "proposalDevelop": "Proposal Development (Sales)",
            "proposalDevelopInfo": "Dependency: Packaging (Marketing)",
            "employee": "Employee Onboarding (HR)",
            "employeeInfo": "Dependency: Security Operations (IT)",
            "conclusion": "By recognizing these dependencies, you can better prepare and coordinate with relevant departments to ensure seamless continuity of your critical processes."
        },
        "criticalDateInfoSidebar": {
            "importantDates": "Important Dates for Continuity",
            "introDesc": "When planning for continuity, it's crucial to identify and add critical dates to your process. These dates are recurring or specific time periods that can influence the priority of continuity efforts during an incident. Here are some examples to consider.",
            "timeReporting": "Time Reporting",
            "timeReportingInfo": "Frequency: Weekly (Every Wednesday)",
            "financialReporting": "Financial Reporting (Quarterly Filing)",
            "financialReportingInfo": "Frequency: Quarterly (Within 4 business days of the quarter-end)",
            "backup": "Backup Withholding Payments to IRS",
            "backupInfo": "Frequency: Bi-weekly (Every Friday)",
            "irsTax": "IRS Tax Reporting for Vendors (1099-MISC) and Backup Withholding (Form 945)",
            "irsTaxInfo": "Frequency: Annually (January 31, within 3 business days)",
            "conclusion": "Ensuring these critical dates are part of your continuity planning will help maintain smooth operations and compliance during disruptions."
        },
        "continuityStrategiesInfoSidebar": {
            "introDesc": "A continuity strategy outlines the methods by which a process is expected to continue during an incident. It's important to validate these strategies, along with designated personnel and requirements, before any disruption occurs. Here are some common continuity strategies:",
            "work": "Work from Anywhere",
            "workInfo": "Continue work from home or a similar remote space.",
            "temporary": "Temporary Workspace",
            "temporaryInfo": "Relocate entire operations to a temporary location.",
            "move": "Move the Work",
            "moveInfo": "Transfer work to an outside vendor or another internal team.",
            "suspend": "Suspend",
            "suspendInfo": "Temporarily suspend the work.",
            "conclusion": "Implementing and validating these continuity strategies in advance will help ensure your business can maintain operations during unexpected disruptions."
        },
		"analyticsTabs": {

		}
    }
}
