import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-mfa-dialog',
	templateUrl: './mfa-dialog.component.html',
	styleUrls: ['./mfa-dialog.component.scss']
})
export class MfaDialogComponent implements OnInit {
	@Input() mfaPinExpirationMinutes: number;
	mfaSecurityCode: string;
	mfaForm: UntypedFormGroup;

	constructor(private formBuilder: UntypedFormBuilder,
		private translateSvc: TranslateService,
		public dynamicDialogRef: DynamicDialogRef,
		public dynamicDialogConfig: DynamicDialogConfig) {
		this.mfaPinExpirationMinutes = this.dynamicDialogConfig.data.mfaPinExpirationMinutes;
		this.dynamicDialogConfig.header = this.translateSvc.instant('mfa.lblMultifactorTitle');
	}

	get msgMultifactorInstructions() {
		return this.mfaPinExpirationMinutes > 0
			? this.translateSvc.instant('mfa.msgMultifactorWithExpirationInstructions', { mfaPinExpirationMinutes: this.mfaPinExpirationMinutes })
			: this.translateSvc.instant('mfa.msgMultifactorInstructions');
	}

	ngOnInit() {
		this.buildControls();
	}

	buildControls() {
		this.mfaForm = this.formBuilder.group({
			mfaSecurityCode: ['', Validators.required]
		});
	}

	accept() {
		if (this.mfaForm.valid) {
			this.dynamicDialogRef.close({
				mfaSecurityCode: this.mfaSecurityCode
			});
		}
	}

	cancel() {
		this.dynamicDialogRef.close();
	}
}
